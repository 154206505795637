import { GroupContainer } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import {
  BoardGroupSkeleton,
  Props as BoardGroupSkeletonProps,
} from 'src/components/BoardGroup/BoardGroupSkeleton';
import { ViewType } from 'src/types/viewType.types';

import { Container, Content } from './BoardContent.styles';

interface Props {
  viewType: ViewType;
}

type GroupsData = Partial<BoardGroupSkeletonProps>;

const CARDS_PER_GROUP_LIST_VIEWTYPE: Array<GroupsData> = [
  { nbCards: 3 },
  { nbCards: 3 },
  {
    nbCards: 0,
    withTitle: false,
  },
];
const CARDS_PER_GROUP_KANBAN_VIEWTYPE: Array<GroupsData> = [
  { nbCards: 3 },
  { nbCards: 3 },
  { nbCards: 3 },
];

export const BoardContentSkeleton: FC<React.PropsWithChildren<Props>> = ({ viewType }) => {
  const skeletonGroups: Array<GroupsData> = useMemo(() => {
    return viewType === ViewType.Kanban
      ? CARDS_PER_GROUP_KANBAN_VIEWTYPE
      : CARDS_PER_GROUP_LIST_VIEWTYPE;
  }, [viewType]);

  return (
    <Container>
      <Content viewType={viewType}>
        <GroupContainer viewType={viewType}>
          {skeletonGroups.map((groupProps, idx) => (
            <BoardGroupSkeleton
              // eslint-disable-next-line react/no-array-index-key
              key={`group-${idx}`}
              viewType={viewType}
              {...groupProps}
            />
          ))}
        </GroupContainer>
      </Content>
    </Container>
  );
};
