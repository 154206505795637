import { useSubscription } from '@apollo/client';
import { ReleaseSubscriptionDocument, ReleaseNotesDocument } from '@cycle-app/graphql-codegen';

import { RELEASE_NOTES_INITIAL_PAGINATION_SIZE } from 'src/constants/releases.constants';
import { updateQuery } from 'src/utils/update-cache/update-query.util';

export const useReleaseSubscription = (releaseId?: string | null) => {
  return useSubscription(ReleaseSubscriptionDocument, {
    variables: {
      id: releaseId as string,
    },
    onSubscriptionData({ subscriptionData: { data } }) {
      const releaseNotes = data?.release?.releaseNotes;
      if (!releaseNotes || !releaseId) return;

      updateQuery({
        query: ReleaseNotesDocument,
        variables: {
          releaseId,
          isOther: false,
          cursor: '',
          size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
        },
        update: draft => {
          if (draft?.node?.__typename !== 'Release') return;
          // eslint-disable-next-line no-param-reassign
          draft.node.releaseNotes.edges = releaseNotes.edges.filter((e) => e.node.isOther === false);
        },
      });

      updateQuery({
        query: ReleaseNotesDocument,
        variables: {
          releaseId,
          isOther: true,
          cursor: '',
          size: RELEASE_NOTES_INITIAL_PAGINATION_SIZE,
        },
        update: draft => {
          if (draft?.node?.__typename !== 'Release') return;
          // eslint-disable-next-line no-param-reassign
          draft.node.releaseNotes.edges = releaseNotes.edges.filter((e) => e.node.isOther === true);
        },
      });
    },
  });
};
