import { DotsMenu } from '@cycle-app/ui';
import { TrashIcon, LinkIcon, ExportIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC } from 'react';

import { mappingZindex } from 'src/constants/zIndex.constant';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

export const NotionEditorMentionViewActions: FC<React.PropsWithChildren<Pick<NodeViewRendererProps, 'editor' | 'getPos' | 'node'>>> = ({
  editor,
  getPos,
  node,
}) => {
  const [isDropdownVisible, { toggleCallback: toggleDropdown }] = useOptimizedBooleanState(false);
  return (
    <DotsMenu
      placement="bottom-end"
      visible={isDropdownVisible}
      forceFocus={isDropdownVisible}
      setVisible={toggleDropdown}
      zIndex={mappingZindex[Layer.Dropdown]}
      options={[
        {
          label: 'Open',
          value: 'open',
          icon: <ExportIcon />,
          onSelect: () => {
            if (node.attrs?.url) {
              window.open(node.attrs.url, '_blank');
            }
          },
        },
        {
          label: 'Copy link',
          value: 'copy',
          icon: <LinkIcon />,
          onSelect: () => {
            if (node.attrs?.url) {
              copyToClipboard({
                text: node.attrs.url,
                notification: 'URL copied to clipboard',
              });
            }
          },
        },
        {
          label: 'Remove',
          value: 'delete',
          icon: <TrashIcon />,
          variant: 'danger',
          onSelect: () => deleteNodeRange({
            editor,
            node,
            getPos,
          }),
        },
      ]}
    />
  );
};
