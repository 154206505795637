import { useState } from 'react';

import { CommandCustomerAction } from 'src/components/CommandBar/CommandK/CommandCustomerAction/CommandCustomerAction';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { CommandActionCategory } from 'src/types/commandbar.types';

import { openCustomerModal } from '../modals/useCustomerModal';

export const CATEGORY_ID = 'people';

export const useCustomersResult = (enabled: boolean) => {
  const [defaultSearch, setDefaultSearch] = useState('');
  const {
    customers, loading: isLoading,
  } = useCustomers({
    defaultSearch,
    skip: !enabled,
  });

  const formatResults = (results: typeof customers): CommandActionCategory[] => [{
    id: CATEGORY_ID,
    label: 'People',
    actions: results
      .map(customer => ({
        id: customer.id,
        label: customer.displayName,
        renderLabel: ({ searchTextDebounced }) => <CommandCustomerAction searchText={searchTextDebounced} customer={customer} />,
        onSelect: () => {
          openCustomerModal(customer.id);
        },
        filtered: true,
      })),
  }];

  return {
    searchCustomers: setDefaultSearch,
    defaultResult: formatResults(customers),
    isLoading,
  };
};
