import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import ContentEditable from 'src/components/ContentEditable/ContentEditable';

export const EditableTitle = styled(ContentEditable)`
  outline: none;
  ${typo.headerLarge};
  cursor: text;
  min-height: 32px;
`;
