export const DROPZONE_MAX_FILES = 5;

// They must be the same as in the `generateBinaryContent` function
// in the `packages/api/src/domain/doc/createDocFromFile.ts` file
export const DROPZONE_ACCEPTED_EXTENSIONS = [
  'jpg', 'jpeg', 'png', 'gif',
  'mov', 'mp4',
  'mp3', 'wav', 'm4a',
  'pdf',
  'txt', 'vtt',
];

export const MEDIA_MIME_TYPES = [
  'image', 'audio', 'video',
];

export const IMAGE_EXTENSIONS = [
  'jpg', 'jpeg', 'png', 'gif', 'webp', 'svg',
];

export const MEDIA_EXTENSIONS = [
  ...IMAGE_EXTENSIONS,
  'mp4', 'webm', 'mov', 'avi', 'mkv',
  'mp3', 'wav', 'ogg',
];
