import { IntegrationType } from '@cycle-app/graphql-codegen';

import { useProductIntegrations } from '../api/useProductIntegrations';

export const useMeetingsIntegration = () => {
  const {
    getIntegration, isLoading,
  } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Meeting);
  const provider = integration?.provider?.__typename === 'Meeting' ? integration.provider : null;
  return {
    integration,
    isInstalled: !!provider,
    isLoading,
    provider,
  };
};
