import { DownIcon } from '@cycle-app/ui/icons';

import { TextButton } from './Ask.styles';

export const DocsButton = ({
  docCount, onClick,
}: {
  docCount: number;
  onClick: VoidFunction;
}) => {
  return (
    <TextButton onClick={onClick}>
      {`Based on ${docCount} feedback quote${docCount > 1 ? 's' : ''}`}
      <DownIcon size={12} />
    </TextButton>
  );
};
