import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.home.card.bg};
  border-radius: 12px;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Title = styled.div`
  ${typo.headerLight}
  align-items: center;
  display: flex;
  gap: 8px;
`;
