import { ApolloCache, Reference } from '@apollo/client';
import { produce } from 'immer';

import { useBoardSections } from '../api/useBoardSections';

export const useBoardLinkCache = () => {
  const { mappingBoardLinks } = useBoardSections();
  const addBoardLinkInCache = (cache: ApolloCache<unknown>, variables: { sectionId: string; boardLinkId: string }) => {
    cache.modify({
      id: variables.sectionId,
      fields: {
        boardLinks: (boardLinks: { edges: { node?: Reference }[] }, { toReference }) => {
          return produce(boardLinks, draftBoardLinks => {
            draftBoardLinks.edges.splice(0, 0, { node: toReference(variables.boardLinkId) });
          });
        },
      },
    });
  };

  const removeBoardLink = (cache: ApolloCache<unknown>, boardLinkId: string) => {
    const sectionId = mappingBoardLinks[boardLinkId]?.section.id;
    if (sectionId) {
      cache.modify({
        id: sectionId,
        fields: {
          boardLinks: (boardLinks: { edges: { node: Reference }[] }, { readField }) => {
            return produce(boardLinks, draftBoardLinks => {
              const boardLinkIndex = draftBoardLinks.edges.findIndex(edge => {
                return readField<string>('id', edge.node) === boardLinkId;
              });
              if (boardLinkIndex >= 0) {
                draftBoardLinks.edges.splice(boardLinkIndex, 1);
              }
              return draftBoardLinks;
            });
          },
        },
      });
    }
  };

  const removeBoardLinkFromCache = (
    cache: ApolloCache<unknown>,
    variables:
    { boardId: string; boardLinkId?: never } |
    { boardId?: never; boardLinkId: string },
  ) => {
    if (variables.boardLinkId) {
      removeBoardLink(cache, variables.boardLinkId);
    } else {
      const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === variables.boardId);
      if (boardLink?.id) {
        removeBoardLink(cache, boardLink.id);
      }
    }
  };

  return {
    addBoardLinkInCache,
    removeBoardLinkFromCache,
  };
};
