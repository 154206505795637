import { Navigation } from '@cycle-app/ui';
import { memo } from 'react';

import { Container } from './Sidebar.styles';
import { SidebarBoard, ScrollableSection } from './SidebarBoard/SidebarBoard';

export const SidebarMobile = memo(() => {
  return (
    <Container>
      <Navigation>
        <ScrollableSection collapsed={false}>
          <SidebarBoard collapsed={false} />
        </ScrollableSection>
      </Navigation>
    </Container>
  );
});
