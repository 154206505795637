import { useDocThreads } from 'src/hooks/api/queries/useDocThreads';
import { useThreadsPanel } from 'src/reactives/comments.reactive';

export const useShowResolveThreadButton = (docId: string | undefined, blockId: string | null, threadId: string | undefined) => {
  const { section } = useThreadsPanel();

  const {
    threads, resolvedIds,
  } = useDocThreads(docId, { section });

  const openMainThread = threads.find(
    t => t.comments?.edges[0]?.node.blockId === null &&
      !resolvedIds.includes(t.id),
  );

  return section === 'open' ||
    blockId !== null ||
    threadId === openMainThread?.id ||
    threadId === undefined ||
    (blockId === null && !openMainThread);
};
