import { useEditor } from '@tiptap/react';
import { useEffect, useState } from 'react';

import { displayExtensions } from '../editorExtensions/editorExtensions';
import useRealtimeEditorConfig from './doc/useRealtimeEditorConfig';

export const useEditorTextContent = (docId: string | undefined | null) => {
  const [text, setText] = useState<string | null>(null);

  const { yDoc } = useRealtimeEditorConfig({ docId });

  const editor = useEditor({
    immediatelyRender: true,
    extensions: displayExtensions({
      collaboration: {
        document: yDoc,
      },
    }),
  });

  useEffect(() => {
    if (editor && yDoc) {
      setTimeout(() => {
        yDoc.once('update', () => {
          setText(editor.getText());
          yDoc.destroy();
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, yDoc]);

  return text;
};
