import { DocChildFragment } from '@cycle-app/graphql-codegen';

import type { Insight } from 'src/hooks/insight/useDocInsights.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';


export const copyQuotesToClipboard = (items: Insight[] | DocChildFragment[]) => {
  copyToClipboard({
    text: items.map(item => {
      const data = item.__typename === 'Doc' ? item.docSource : item;
      const customerName = data?.doc?.customer?.displayName || 'Unknown';
      const company = data?.doc?.customer?.company?.name || 'No company';
      const quote = data?.content;
      return `<p><i>"${decodeHtmlEntities(quote || '')}"</i><br><b>${customerName}</b> from <b>${company}</b></p>`;
    }).join('\n'),
    notification: 'Quotes copied to clipboard!',
    asHtml: true,
  });
};

function decodeHtmlEntities(str: string) {
  const element = document.createElement('div');
  element.innerHTML = str;
  return element.textContent || '';
}
