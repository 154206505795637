import { typo } from '@cycle-app/ui';
import { small } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Arrow } from './Arrow';

interface Props {
  $isRTL?: boolean;
}

export const Root = styled(motion.div)`
  transform: translate(0, -50%);
  position: absolute;
`;

export const StyledArrow = styled(Arrow)<Props>`
  position: absolute;
  ${p => (!p.$isRTL && css`transform: rotate(180deg); right: 0;`)}
`;

export const Container = styled.div<Props>`
  align-items: center;
  color: ${p => p.theme.colors.onboardingSlack.congratulations.tooltip.color};
  display: flex;
  z-index: 2;
  height: 28px;

  ${p => p.$isRTL && css`flex-direction: row-reverse;`}
`;

export const ArrowContainer = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.onboardingSlack.congratulations.tooltip.color};
  display: flex;
  overflow: hidden;
  position: relative;
  width: var(--arrow-width);
  height: 100%;
`;

export const Title = styled.div`
  ${typo.body500}

  > [data-popover] {
    align-items: center;
    border-radius: 7px;
    display: flex;
    height: 28px;
    gap: 4px;
    padding: 2px 12px;
    pointer-events: all;
    cursor: pointer;
    background-color: ${p => p.theme.colors.onboardingSlack.congratulations.tooltip.bg};
  }
`;

export const Label = styled.span`
  ${small} {
    display: none;
  }
`;
