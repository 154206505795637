import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useInstallIntegration } from 'src/hooks';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { setOnboardingSlackStep } from 'src/reactives';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';

import {
  Container, Title, Text, ButtonsGroup, NextButton, Footer,
} from '../OnboardingSlack.styles';
import { Image } from './StepInstall.styles';

export const StepInstall = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    integration, isInstalled,
  } = useSlackIntegration();
  const install = useInstallIntegration();
  if (isInstalled) {
    setOnboardingSlackStep(productId, OnboardingSlackStep.Reactji);
    return null;
  }
  return (
    <Container>
      <Title>
        Connect Slack to Cycle
      </Title>
      <Text>
        Create Cycle feedback from Slack, personalize your
        <br />
        notifications, and close the feedback loop in public
      </Text>
      <Image src="/images/illustration-onboarding-slack-step-1.png" alt="illustration" />
      <Footer>
        <ButtonsGroup>
          <NextButton onClick={() => integration?.type && install(integration.type, integration)}>
            Start
          </NextButton>
        </ButtonsGroup>
      </Footer>
    </Container>
  );
};
