import { audioExtension as extension } from '@cycle-app/editor-extensions';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { EditorAudioView } from 'src/components/EditorAudioView';

export const audioExtension = extension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(EditorAudioView);
  },
});
