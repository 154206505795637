import { Language, Tone } from '@cycle-app/graphql-codegen';
import { PenFilledIcon, AiTextIcon, RotateIcon, CloseIcon } from '@cycle-app/ui/icons';
import { generateJSON } from '@tiptap/core';
import type { MutableRefObject } from 'react';

import { useEditorContext } from 'src/contexts/editorContext';
import { resetEditorAi, useEditorAiValue, setEditorAi } from 'src/reactives';
import type { EditorAiAction } from 'src/types/editor.types';
import { formatSuggestion } from 'src/utils/ai.util';

import { ButtonsContainer, Button } from './EditorAiMenu.styles';


type EditorAiNextStepButtonsProps = {
  askAi: (
    actionParam: EditorAiAction,
    data?: Language | Tone | string
  ) => Promise<void>;
  lastDataUsed: MutableRefObject<Language | Tone | null>;
};

export const EditorAiNextStepButtons = ({
  askAi, lastDataUsed,
}: EditorAiNextStepButtonsProps) => {
  const {
    suggestion, isPromptCommand, action,
  } = useEditorAiValue();
  const editor = useEditorContext(ctx => ctx.editor);

  if (!suggestion) return null;

  const insertBelow = () => {
    editor
      .chain()
      .focus()
      .insertContentAt(
        editor.state.selection.$to.pos,
        generateJSON(formatSuggestion(suggestion), editor.extensionManager.extensions).content,
        { updateSelection: false },
      )
      .run();
    resetEditorAi();
  };

  return (
    <ButtonsContainer>
      {!isPromptCommand && (
        <Button
          onClick={() => {
            editor.chain().focus().insertContent(formatSuggestion(suggestion)).run();
            resetEditorAi();
          }}
        >
          <PenFilledIcon />
          Replace selection
        </Button>
      )}
      <Button onClick={insertBelow}>
        <AiTextIcon />
        Insert below
      </Button>
      <Button
        onClick={async () => {
          if (!action) return;
          setEditorAi({ suggestion: '' });
          await askAi(action, lastDataUsed.current || undefined);
        }}
      >
        <RotateIcon />
        Retry
      </Button>
      <Button onClick={resetEditorAi}>
        <CloseIcon />
        Cancel
      </Button>

    </ButtonsContainer>
  );
};
