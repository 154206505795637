import { Tooltip } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import DocOptions from 'src/components/DocOptions/DocOptions';
import { useDoc } from 'src/hooks';
import { useDocId } from 'src/hooks/usePathParams';
import { useVerifyQuotesOpen, useFeatureReleaseNoteOpen } from 'src/reactives/docRightPanel.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import {
  RightSide,
  Container,
  LeftActions,
  StyledActionButton,
  Separator,
  BreadCrumb,
  StyledDocParentDropdown,
  DocTitle,
  RightActions,
} from './DocPanelHeader.styles';
import { ToggleRightPanel } from './ToggleRightPanel';
import DocPanelRealtime from '../DocPanelRealtime/DocPanelRealtime';

interface Props {
  className?: string;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
  onDeleteDoc: VoidFunction;
  onOpenCoverInputFile: VoidFunction;
  prevNextButtons?: ReactNode;
  processButton?: ReactNode;
  disabled?: boolean;
}

const DocPanelHeader: FC<Props> = ({
  className,
  onClose,
  onExpand,
  onDeleteDoc,
  onOpenCoverInputFile,
  prevNextButtons,
  processButton,
  disabled = false,
}) => {
  const docId = useDocId();
  const { doc: docBase } = useDoc(docId);
  const doctype = useGetDocType(docBase?.doctype.id);
  const canHaveParent = (doctype?.parents?.edges ?? []).length > 0;
  const isMobile = useIsMobile();
  const isVerifyQuotesOpen = useVerifyQuotesOpen();
  const isFeatureReleaseNoteOpen = useFeatureReleaseNoteOpen();

  if (isMobile) return null;

  return (
    <Container className={className} $disabled={disabled}>
      {(onClose || onExpand) && (
        <LeftActions>
          {onClose && (
            <StyledActionButton
              size="L"
              onClick={onClose}
              tooltip="Close"
              tooltipPlacement="bottom"
            >
              <CloseIcon />
            </StyledActionButton>
          )}
          {onExpand && (
            <StyledActionButton
              size="L"
              onClick={onExpand}
              tooltip="Open full page"
              tooltipPlacement="bottom"
            >
              <ExpandIcon />
            </StyledActionButton>
          )}
          <Separator />
        </LeftActions>
      )}

      {prevNextButtons}

      <BreadCrumb>
        <span>
          {docBase && canHaveParent && (
            <>
              <StyledDocParentDropdown
                docId={docBase.id}
                docTypeId={docBase.doctype.id}
                size="M"
                showIcon={false}
                minimal
                showLinearAutoCreate
              />
              <span>/</span>
            </>
          )}
          {docBase && (
            <Tooltip
              content={docBase.title}
              placement="bottom"
              withPortal
              displayFullTitle
              disabled={!docBase.title}
            >
              <DocTitle>{docBase.title}</DocTitle>
            </Tooltip>
          )}
        </span>
      </BreadCrumb>

      <RightSide>
        <DocPanelRealtime />
        {processButton}
        <RightActions className="h-7">
          {docBase && (
            <DocOptions
              doc={docBase}
              context="doc-panel"
              onAddCoverClicked={onOpenCoverInputFile}
              onDeleteDoc={onDeleteDoc}
              buttonSize="L"
            />
          )}
          {!isVerifyQuotesOpen && !isFeatureReleaseNoteOpen && <ToggleRightPanel />}
        </RightActions>
      </RightSide>
    </Container>
  );
};

export default DocPanelHeader;
