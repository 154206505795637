/**
 *
 * @param text
 * @param readonly if false, the user is able to tap space and the end.
 * Usage: ```<input value={slugify(value, false)} onChange={onChange} />```
 * @returns
 */
export function slugify(text: string, readonly = true): string {
  const slug = text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, ''); // Trim - from start of text
  return readonly
    ? slug.replace(/-+$/, '') // Trim - from end of text
    : slug;
}
