import { Toasters } from '@cycle-app/ui';
import { useMemo } from 'react';

import { useHotkeyListener, UseHotkeyParams } from 'src/hooks/useHotkeyListener';
import { useIsSmallScreen } from 'src/reactives';
import { useGetToasters } from 'src/reactives/toasters.reactive';
import { AnyShortcut } from 'src/types/shortcuts.types';
import { restartToasterTimers, stopToasterTimers } from 'src/utils/toasters.utils';

import { Container } from './AppToasters.styles';
import { useGetLayerDialogModal, useGetLayerModal } from '../../reactives/layer.reactive';

export const AppToasters = () => {
  const {
    queue, areShortcutsEnabled,
  } = useGetToasters();
  const isSmall = useIsSmallScreen();
  const modal = useGetLayerModal();
  const dialogModal = useGetLayerDialogModal();
  const hasModal = modal.visible || dialogModal.visible;

  const callbacks = useMemo(() => queue.reduce<UseHotkeyParams<AnyShortcut>['callbacks']>((acc, item, index) => {
    item.shortcuts?.forEach(shortcut => {
      if (shortcut?.key && (!shortcut.lastOnly || index === queue.length - 1)) {
        acc[shortcut.key] = shortcut.action;
      }
    });
    return acc;
  }, {}), [queue]);

  useHotkeyListener({
    callbacks,
    shortcuts: Object.keys(callbacks) as AnyShortcut[],
  });

  return (
    <Container $areShortcutsEnabled={areShortcutsEnabled}>
      <Toasters
        toasters={queue}
        onMouseEnter={stopToasterTimers}
        onMouseLeave={restartToasterTimers}
        filter={props => {
          // hide if: not closable + modal + small screen.
          return !!props.onClose || !hasModal || !isSmall;
        }}
        isInert={props => !props.onClose && hasModal}
      />
    </Container>
  );
};
