import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { MoreHorizIcon } from '@cycle-app/ui/icons';
import { useMemo } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useGetInboxDefaultUrl } from 'src/hooks/inbox';
import { useGetInsightDefaultUrl } from 'src/hooks/insight/useGetInsightDefaultUrl';
import { useGetRoadmapDefaultUrl } from 'src/hooks/roadmap/useGetRoadmapDefaultUrl';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { history } from 'src/providers';
import { getPermission, setLimitationsModal } from 'src/reactives';
import { SectionParent, getSectionIcon, getSectionName } from 'src/utils/section.utils';

import { SectionTooltip } from './SectionTooltip';
import { Caret, DoubleCaret, SectionButton } from './ViewBreadcrumb.styles';
import { BreadcrumbSize } from './ViewBreadcrumb.utils';

type Props = {
  size: BreadcrumbSize;
  sectionParent: SectionParent;
};

export const SectionDropdown = ({
  size, sectionParent,
}: Props) => (
  <>
    <ToggleDropdown
      placement="bottom-start"
      content={props => <SectionList hide={props.hide} />}
      button={props => (
        <SectionTooltip>
          <SectionButton
            $isSmall={size < 2}
            iconEnd={size > 1 && <DoubleCaret />}
            {...props}
          >
            {size < 2 ? <MoreHorizIcon /> : getSectionName(sectionParent)}
          </SectionButton>
        </SectionTooltip>
      )}
    />
    <Caret />
  </>
);

const SectionList = ({ hide }: { hide: VoidFunction }) => {
  const getLastViewUrl = useGetLastViewUrl();
  const isRoadmapEnabled = useIsRoadmapsEnabled();
  const { navigate } = useNavigateToSettings();

  const sectionOptions = useMemo(() => {
    const sectionParents: SectionParent[] = ['inbox', 'insight'];
    if (isRoadmapEnabled) sectionParents.push('roadmap');

    const options: SelectOption<SectionParent>[] = sectionParents.map(sectionParent => ({
      icon: getSectionIcon(sectionParent),
      label: getSectionName(sectionParent),
      value: sectionParent,
      data: sectionParent,
    }));

    if (!isRoadmapEnabled) {
      options.push({
        icon: getSectionIcon('roadmap'),
        label: 'Try roadmaps',
        value: 'roadmap',
      });
    }

    return options;
  }, [isRoadmapEnabled]);

  return (
    <SelectPanel
      style={{ width: 244 }}
      hideSearch
      options={sectionOptions}
      onOptionChange={(option, event) => {
        hide();

        if (option.value === 'roadmap' && !isRoadmapEnabled) {
          if (!getPermission().canReadSettings) {
            setLimitationsModal({ action: 'SETTINGS_READ' });
            return;
          }
          navigate(PageId.SettingsRoadmaps);
          return;
        }

        if (!option.data) return;
        const url = getLastViewUrl(option.data);
        if (event?.metaKey) {
          window.open(url, '_blank');
        } else {
          history.push(getLastViewUrl(option.data));
        }
      }}
    />
  );
};

const useGetLastViewUrl = () => {
  const getInboxDefaultUrl = useGetInboxDefaultUrl();
  const getInsightDefaultUrl = useGetInsightDefaultUrl();
  const getRoadmapDefaultUrl = useGetRoadmapDefaultUrl();
  return (sectionParent: SectionParent) => {
    if (sectionParent === 'inbox') return getInboxDefaultUrl();
    if (sectionParent === 'insight') return getInsightDefaultUrl();
    return getRoadmapDefaultUrl();
  };
};
