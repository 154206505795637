import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiTranslateIcon = (props:SvgProps) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="earth, globe, world" clipPath="url(#clip0_1627_18728)">
      <path id="Icon (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M2.31763 5.42282C2.15087 5.9181 2.06048 6.44849 2.06048 7C2.06048 9.72802 4.27198 11.9395 7 11.9395C9.72802 11.9395 11.9395 9.72802 11.9395 7C11.9395 4.89442 10.6221 3.09655 8.76652 2.38574L8.34878 4.05637C8.18875 4.69635 7.68949 5.19632 7.04973 5.35726L5.71376 5.69335C5.65297 5.70864 5.60183 5.7496 5.57363 5.80558C5.18095 6.58507 4.17894 6.81984 3.48088 6.2959L2.31763 5.42282ZM2.84127 4.33359L4.19251 5.34777C4.30005 5.42848 4.4544 5.39231 4.5149 5.27223C4.69796 4.90884 5.02994 4.64295 5.42454 4.54368L6.76052 4.2076C6.97632 4.15331 7.14472 3.98467 7.1987 3.76879L7.61635 2.09856C7.41443 2.07343 7.20873 2.06048 7 2.06048C5.25409 2.06048 3.71975 2.96629 2.84127 4.33359ZM0.875 7C0.875 3.61726 3.61726 0.875 7 0.875C10.3827 0.875 13.125 3.61726 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125C3.61726 13.125 0.875 10.3827 0.875 7ZM5.81539 7.34966C6.08527 7.08075 6.45761 6.94063 6.83782 6.96489L7.34605 6.99733C7.66206 7.0175 7.96705 7.12118 8.22988 7.29779L9.07277 7.8642C9.69297 8.28097 9.90716 9.09351 9.57299 9.76185C9.32131 10.2652 8.80685 10.5832 8.24408 10.5832H7.64192C7.04379 10.5832 6.48524 10.2842 6.15346 9.78656L5.64768 9.02789C5.29585 8.50015 5.36609 7.79734 5.81539 7.34966ZM6.76232 8.14797C6.72134 8.14536 6.68122 8.16046 6.65213 8.18944C6.60371 8.23768 6.59614 8.31343 6.63406 8.3703L7.13984 9.12898C7.25176 9.29685 7.44016 9.39768 7.64192 9.39768H8.24408C8.35782 9.39768 8.46179 9.33342 8.51266 9.23169C8.5802 9.09661 8.53691 8.93239 8.41156 8.84816L7.56867 8.28176C7.48002 8.22218 7.37714 8.18721 7.27054 8.18041L6.76232 8.14797Z" />
    </g>
    <defs>
      <clipPath id="clip0_1627_18728">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
