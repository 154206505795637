import { produce } from 'immer';

import { make } from 'src/utils/reactives.util';

const {
  setValue,
  getValue,
  hookWithSelector,
} = make({
  defaultState: {
    // Set of docs that have their children expanded
    // Docs are identified by their path in the view: boardSlug/docId/docId/.../docId
    // Collapsed by default
    expandedDocs: new Set<string>(),
    // Set of collapsed groups
    // Groups are identified by their path in the view: boardSlug/docId/docId/.../docTypeId
    // Expanded by default
    collapsedGroups: new Set<string>(),
    // Set of docs whose list of children is not fully loaded
    haveNextPage: new Set<string>(),
  },
});

export type NestedListPath = (string | undefined | null)[];

export const getPathValue = (path: NestedListPath) => path.filter(Boolean).join('/');

export const useDocChildrenExpanded = (path: NestedListPath | undefined | null) => hookWithSelector(
  state => (path ? state.expandedDocs.has(getPathValue(path)) : false),
);

export const useGroupCollapsed = (path: NestedListPath) => hookWithSelector(
  state => state.collapsedGroups.has(getPathValue(path)),
);

export const toggleDocChildren = (path: NestedListPath) => setValue({
  expandedDocs: produce(getValue().expandedDocs, draft => {
    const pathValue = getPathValue(path);
    const action = draft.has(pathValue) ? 'delete' : 'add';
    draft[action](pathValue);
  }),
});

export const toggleGroup = (path: NestedListPath) => setValue({
  collapsedGroups: produce(getValue().collapsedGroups, draft => {
    const pathValue = getPathValue(path);
    const action = draft.has(pathValue) ? 'delete' : 'add';
    draft[action](pathValue);
  }),
});

export const useHasNextPage = (
  path: NestedListPath | undefined | null,
) => hookWithSelector(ctx => (path ? ctx.haveNextPage.has(getPathValue(path)) : false));

export const setHasNextPage = (path: NestedListPath, hasNextPage: boolean) => setValue({
  haveNextPage: produce(getValue().haveNextPage, draft => {
    const pathValue = getPathValue(path);
    const action = hasNextPage ? 'add' : 'delete';
    draft[action](pathValue);
  }),
});
