import { CompanyFragment } from '@cycle-app/graphql-codegen/generated';
import { ComponentPropsWithRef, HTMLAttributes, forwardRef, useState, useEffect } from 'react';

import { Container, ImageContainer, Size, Logo, Spinner } from './CompanyLogo.styles';
import { BuildingIcon } from '../../../icons';

export interface CompanyLogoProps extends HTMLAttributes<unknown> {
  company?: {
    logo?: CompanyFragment['logo'];
    name?: CompanyFragment['name'];
  } | null;
  isEditable?: boolean;
  isLoading?: boolean;
  size?: Size;
  src?: string | null;
}

export const CompanyLogo = forwardRef<ComponentPropsWithRef<typeof Container>['ref'], CompanyLogoProps>(({
  company,
  isEditable,
  isLoading = false,
  size = 'M',
  src,
  ...props
}, ref) => {
  const [logoSrc, setLogoSrc] = useState<string | null>(src ?? company?.logo ?? null);

  useEffect(() => {
    setLogoSrc(src ?? company?.logo ?? null);
  }, [company?.logo, src]);

  return (
    <Container $size={size} $hasCursor={isEditable} ref={ref} {...props}>
      <ImageContainer>
        {isLoading && (
          <Logo as="div">
            <Spinner />
          </Logo>
        )}

        {logoSrc && !isLoading && (
          <Logo
            alt="logo"
            src={logoSrc}
            onError={() => setLogoSrc(null)}
          />
        )}

        {!logoSrc && !isLoading && (
          <Logo as="div">
            {company && !!company.name?.trimStart()[0]
              ? company.name.trimStart()[0]
              : <BuildingIcon />}
          </Logo>
        )}
      </ImageContainer>
    </Container>
  );
});
