import { FC, forwardRef, InputHTMLAttributes, ReactNode } from 'react';

import { Container, StyledInput, RadioInputDot } from './RadioInput.styles';
import { Label, CheckboxInputText } from '../CheckboxInput/CheckboxInput.styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value: string | number;
  label?: ReactNode;
}
export const RadioInput: FC<React.PropsWithChildren<Props>> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    ...inputProps
  } = props;
  return (
    <Container
      className={className}
      $isDisabled={!!inputProps.disabled}
    >
      <StyledInput
        ref={ref}
        type="radio"
        {...inputProps}
      />
      <Label htmlFor={inputProps.id}>
        <RadioInputDot />
        <CheckboxInputText>{label ?? inputProps.value}</CheckboxInputText>
      </Label>
    </Container>
  );
});

export { RadioInputDot };
