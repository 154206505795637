import { useQuery } from '@apollo/client';
import { MeetingCalendarEventsDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useProductBase } from '../api/useProduct';

export const useMeetingsEvents = () => {
  const product = useProductBase();
  const {
    data: upcomingData, loading: isUpcomingLoading,
  } = useQuery(MeetingCalendarEventsDocument, {
    // Always get fresh data.
    fetchPolicy: 'cache-and-network',
    // Avoid a request when updating an event (in instance, toggle record)
    nextFetchPolicy: 'cache-first',
    variables: {
      productId: product?.id,
      after: true,
    },
  });
  const {
    data: pastData, loading: isPastLoading,
  } = useQuery(MeetingCalendarEventsDocument, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      productId: product?.id,
      after: false,
    },
  });
  const upcoming = useMemo(() => {
    return nodeToArray(upcomingData?.getCalendarEvents).filter(e => {
      // Keep only events that are recordable
      return e.url;
    });
  }, [upcomingData?.getCalendarEvents]);

  const {
    ongoing, passed,
  } = useMemo(() => {
    const now = new Date().getTime();
    return {
      ongoing: nodeToArray(pastData?.getCalendarEvents)
        .filter(e => {
          return (
            e.url && // Keep only events that are recordable
            new Date(e.endTime).getTime() >= now
          );
        })
        .sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime()),
      passed: nodeToArray(pastData?.getCalendarEvents)
        .filter(e => {
          return (
            e.doc?.id && // Keep only events that were recorded.
            new Date(e.endTime).getTime() < now
          );
        })
        .sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime()),
    };
  }, [pastData?.getCalendarEvents]);
  return {
    events: {
      ongoing,
      passed,
      upcoming,
    },
    isLoading: isPastLoading || isUpcomingLoading,
  };
};
