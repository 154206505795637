import { ViewType, Property, DocInGroupFragment, StatusType } from '@cycle-app/graphql-codegen';
import { Group, NewDocPosition, ViewCardSkeleton, InfiniteScroll } from '@cycle-app/ui';
import { DownArrowIcon } from '@cycle-app/ui/icons';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import memoize from 'fast-memoize';
import {
  forwardRef, HTMLAttributes, memo, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { DocItem, DocItemHandle } from 'src/components/DocItem';
import { BOARD_CONTENT_ID } from 'src/constants/board.constant';
import { MESSAGE_CREATE_DOC_DISABLED_HAS_NEXT_PAGE, MESSAGE_CREATE_DOC_DISABLED_BOARD_CONFIG } from 'src/constants/boardGroups.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { DocProvider } from 'src/contexts/docContext';
import { useGetGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import useManageHiddenGroups from 'src/hooks/api/mutations/boardConfig/useManageGroupby';
import { BoardGroup as BoardGroupType } from 'src/hooks/api/useBoardGroups';
import { useMoreDocsInGroup } from 'src/hooks/api/useMoreDocsInGroup';
import { usePossibleDoctypes } from 'src/hooks/boards/usePossibleDoctypes';
import { DndItemType } from 'src/hooks/dnd/useGroupsDnd';
import { useGetStatus } from 'src/hooks/status/useGetStatus';
import { useGetDocItemProps } from 'src/hooks/useGetDocItemProps';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useParentPage } from 'src/hooks/usePageId';
import { useDocUrl } from 'src/hooks/useUrl';
import { getAuth } from 'src/reactives/auth.reactive';
import { useGetBoardDnd } from 'src/reactives/boardDnd.reactive';
import { setBoardGroupState, useGetBoardGroupEditing, setBoardGroupEditing } from 'src/reactives/boardGroup.reactive';
import { useBoardNewDocPositionState, setBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { setDnd } from 'src/reactives/dnd.reactive';
import { useGetLastView } from 'src/reactives/lastView.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useDefaultNotStartedStatus } from 'src/reactives/productStatus.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { SIZE_DOCS_PAGE } from 'src/utils/pagination.util';

import { ContentList, MoreButton } from './BoardGroup.styles';
import { BoardGroupName } from './BoardGroupName';
import BoardGroupNewDoc from './BoardGroupNewDoc';
import BoardGroupToolbar from './BoardGroupToolbar';
import { NewInboxToolbar } from './NewInboxToolbar';

const INITIAL_GROUPS_IN_BOARD_VIEW = 5;

const GROUP_BY_RULES_WITH_DISABLED_RENAMING: Array<Property['__typename']> = [
  'CreatorDefinition',
  'AssigneeDefinition',
  'DoctypeDefinition',
];

export interface Props extends HTMLAttributes<HTMLDivElement> {
  groupCount?: number;
  groupIndex?: number;
  group: BoardGroupType;
  groupId: string;
  boardConfigId: string | undefined;
  groupByProperty?: Property;
  items: string[];
  activeId?: string | null;
  activeType: DndItemType;
  viewType: ViewType;
  getDoc: (docId: string) => DocInGroupFragment | undefined;
  withGroupBy: boolean;
  setSortableRef?: (node: HTMLElement | null) => void;
  setDraggableNodeRef?: (node: HTMLElement | null) => void;
  setDroppableNodeRef?: (node: HTMLElement | null) => void;
  sortableListeners?: DraggableSyntheticListeners;
  isDragging?: boolean;
  isDraggable?: boolean;
  asPlaceholder?: boolean;
  collapse?: boolean;
  toggleCollapse?: (groupId: string) => void;
}

const BoardGroup = forwardRef<DocItemHandle[], Props>(({
  groupCount = 0,
  groupIndex,
  group,
  groupId,
  boardConfigId,
  groupByProperty,
  items,
  activeId,
  activeType,
  viewType,
  getDoc,
  withGroupBy,
  setSortableRef,
  setDroppableNodeRef,
  setDraggableNodeRef,
  sortableListeners,
  isDragging,
  asPlaceholder,
  isDraggable,
  collapse,
  toggleCollapse,
  ...htmlProps
}, forwardedRef) => {
  const hasFilterPreventingNewDoc = useBoardConfig(ctx => ctx.hasFilterPreventingNewDoc);
  const isSortByOldest = useBoardConfig(ctx => ctx.isSortByOldest);
  const isViewSortByMostRecent = useBoardConfig(ctx => ctx.isSortByMostRecent);
  const isSortByDate = useBoardConfig(ctx => ctx.isSortByDate);
  const parentPage = useParentPage();
  const isInboxSection = parentPage === 'inbox';
  const { isNewInbox } = useGetLastView();
  const getStatus = useGetStatus();
  const {
    groupId: newDocGroupId,
    draftPosition: draftDocPosition,
  } = useBoardNewDocPositionState();
  const getGroup = useGetGroup();

  const { selected } = useGetSelection();

  const boardGroupEditing = useGetBoardGroupEditing();
  const readOnly = boardGroupEditing.id !== groupId;

  const docItemRefs = useRef<DocItemHandle[]>([]);
  const isOnboarding = useIsOnboarding();

  useImperativeHandle(forwardedRef, () => docItemRefs.current, [docItemRefs]);

  useEffect(() => {
    setDnd({ enabled: readOnly });
  }, [readOnly]);

  const isList = viewType === ViewType.List;
  const isKanban = viewType === ViewType.Kanban;
  const isSortByMostRecent = isViewSortByMostRecent || (isNewInbox && !isSortByDate);

  const {
    moreDocs,
    loading: loadingMoreDocs,
  } = useMoreDocsInGroup(groupId);
  const { hideBoardConfigGroupValue } = useManageHiddenGroups(boardConfigId ?? '');

  const [viewRef, isGroupInBoardView] = useInView({
    root: document.getElementById(BOARD_CONTENT_ID),
    rootMargin: '800px',
    initialInView: groupIndex ? groupIndex < INITIAL_GROUPS_IN_BOARD_VIEW : true,
  });

  const groupListRef = useRef<HTMLElement | null>(null);
  const setGroupListRef: React.RefCallback<HTMLElement | null> = useCallback((node) => {
    viewRef(node);
    groupListRef.current = node;
  }, [viewRef]);

  const hasNextPage = group.pageInfo?.hasNextPage;
  const showMore = !isOnboarding && withGroupBy && isList && groupCount > 1 && !loadingMoreDocs && hasNextPage;

  // Fetch more docs after docs leave the group
  useEffect(() => {
    if (hasNextPage && Object.keys(group.docs).length < SIZE_DOCS_PAGE) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      moreDocs(group.pageInfo?.endCursor ?? '');
    }
  }, [group.docs, group.pageInfo?.endCursor, hasNextPage, moreDocs]);

  const scrollableElement = isList ? document.getElementById(BOARD_CONTENT_ID) : groupListRef.current;

  const inputGroupNameRef = useRef<HTMLInputElement>(null);

  const onHideGroup = useCallback(() => hideBoardConfigGroupValue(groupId, { updateDocGroups: true }), [groupId, hideBoardConfigGroupValue]);

  const onNewDocClicked = useCallback((pos: NewDocPosition) => setBoardNewDocPositionState({
    groupId,
    draftPosition: pos,
  }), [groupId]);

  const draftPosition =
    isSortByMostRecent
      ? 'top'
      : 'bottom';

  const onCreateDocFromMenu = useCallback(() => {
    if (collapse) toggleCollapse?.(groupId);
    setBoardNewDocPositionState({
      groupId,
      draftPosition: isSortByDate ? draftPosition : 'top',
    });
  }, [collapse, groupId, toggleCollapse, draftPosition, isSortByDate]);

  const onRenameGroup = useCallback(() => {
    setBoardGroupEditing({ id: groupId });
  }, [groupId]);

  const onMouseEnterGroup = useCallback(() => {
    if (draftDocPosition) return;
    setBoardGroupState({ hoverGroupId: groupId });
  }, [groupId, draftDocPosition]);

  const onMouseLeaveGroup = useCallback(() => {
    if (draftDocPosition) return;
    setBoardGroupState({ hoverGroupId: null });
  }, [draftDocPosition]);

  const onLoadMoreDocs = useCallback(async () => {
    if (withGroupBy && (isKanban || groupCount === 1)) {
      await moreDocs(group.pageInfo?.endCursor ?? '');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      group?.more?.exec?.();
    }
  }, [withGroupBy, isKanban, moreDocs, group.pageInfo?.endCursor, group?.more, groupCount]);

  const {
    canUpdateGroup, canReorderGroups,
  } = useGetPermission();

  const groupFiltered = getGroup(groupId);
  const { getPossibleDoctypes } = usePossibleDoctypes();
  const possibleDoctypes = useMemo(() => getPossibleDoctypes({
    groupId,
  }), [getPossibleDoctypes, groupId]);

  const hasMoreData = (group?.pageInfo?.hasNextPage ?? false) && !!Object.keys(group?.docs).length;

  const defaultNotStartedStatus = useDefaultNotStartedStatus();
  const isHidingDisabled = !canReorderGroups ||
    (isInboxSection && groupByProperty?.__typename === 'StatusDefinition' && group.statusId === defaultNotStartedStatus?.id);

  const isRenamingDisabled = !canUpdateGroup ||
    GROUP_BY_RULES_WITH_DISABLED_RENAMING.includes(groupByProperty?.__typename) ||
    (group.statusId && getStatus(group.statusId)?.type !== StatusType.Custom);
  const isCreatingDoc = draftDocPosition !== null;
  const isDocCreationDisabled =
    !possibleDoctypes.length ||
    (
      groupFiltered?.node.propertyValue?.__typename === 'Doctype' &&
      !possibleDoctypes.find(doctype => doctype.id === groupFiltered?.node?.propertyValue?.id)
    ) ||
    isOnboarding ||
    hasFilterPreventingNewDoc ||
    (groupByProperty?.__typename === 'CreatorDefinition' && group.attributeValueId !== getAuth().userId);

  const { dragging: dndIsActive } = useGetBoardDnd();

  const reasonCreateDocDisabled: string | null = useMemo(() => {
    if (isDocCreationDisabled) return MESSAGE_CREATE_DOC_DISABLED_BOARD_CONFIG;
    if (hasNextPage) return isSortByMostRecent ? null : MESSAGE_CREATE_DOC_DISABLED_HAS_NEXT_PAGE;
    return null;
  }, [hasNextPage, isDocCreationDisabled, isSortByMostRecent]);

  const docCount = items.length;

  const filteredItems = useMemo(() => items.filter((itemId) => {
    const isSelected = selected.includes(itemId);
    return activeType !== 'item' || !isSelected || activeId === itemId;
  }), [activeId, activeType, items, selected]);

  const isMobile = useIsMobile();
  // Using useDocUrl directly in DocItem would trigger a re-render each time the route changes
  const { getDocItemUrlFromView } = useDocUrl();

  const makeMemoizedSetRef = useMemo(() => memoize(
    (index: number) => (docItemHandle: DocItemHandle) => {
      if (docItemHandle !== null) {
        // eslint-disable-next-line no-param-reassign
        docItemRefs.current[index] = docItemHandle;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const getDocItemProps = useGetDocItemProps(viewType);

  const showLoadingMore = loadingMoreDocs || !!group.more?.loading;

  const { initialGroupId } = useBoardNewDocPositionState();
  const hasNestedCreateDoc = initialGroupId === `${groupId}`;

  let toolbar: ReactNode;

  if (isNewInbox && !isDocCreationDisabled) {
    toolbar = (
      <NewInboxToolbar
        groupId={groupId}
        onCreateDoc={isDocCreationDisabled || (isSortByOldest && hasNextPage) ? undefined : onCreateDocFromMenu}
        draftPosition={isSortByDate ? draftPosition : undefined}
      />
    );
  }

  if (!isNewInbox) {
    toolbar = (
      <BoardGroupToolbar
        groupId={groupId}
        onCreateDoc={isDocCreationDisabled || (isSortByOldest && hasNextPage) ? undefined : onCreateDocFromMenu}
        onHideGroup={!isHidingDisabled ? onHideGroup : undefined}
        onRenameGroup={!isRenamingDisabled ? onRenameGroup : undefined}
        dropdownPlacement="bottom-end"
        isGroupByProperty={!!groupByProperty}
        draftPosition={isSortByDate ? draftPosition : undefined}
      />
    );
  }

  const showTopForm = !isDocCreationDisabled && newDocGroupId === groupId && draftDocPosition === 'top';
  const showBottomForm = !isDocCreationDisabled && newDocGroupId === groupId && draftDocPosition === 'bottom';

  return (
    <Group
      data-groupid={groupId}
      groupId={groupId}
      groupName={group.name}
      groupCategory={group.category}
      inputName={(
        <BoardGroupName
          ref={inputGroupNameRef}
          groupName={group.name}
          readOnly={readOnly}
          setReadOnly={() => setBoardGroupEditing({ id: null })}
          attributeValueId={group.attributeValueId}
          docGroupId={groupId}
          isList={isList}
          statusId={group.statusId}
          tooltipProps={{
            disabled: false,
          }}
        />
      )}
      setListRef={setGroupListRef}
      setSortableRef={setSortableRef}
      setDraggableNodeRef={setDraggableNodeRef}
      setDroppableNodeRef={setDroppableNodeRef}
      sortableListeners={sortableListeners}
      viewType={viewType}
      onNewDocClick={possibleDoctypes.length ? onNewDocClicked : undefined}
      withGroupBy={withGroupBy}
      dragActive={!!activeId}
      isDragging={isDragging}
      asPlaceholder={asPlaceholder}
      isDraggable={isDraggable}
      onMouseEnter={onMouseEnterGroup}
      onMouseLeave={onMouseLeaveGroup}
      reasonCreateDocDisabled={reasonCreateDocDisabled}
      createDocHidden={isOnboarding || (isCreatingDoc && newDocGroupId === groupId) || hasNestedCreateDoc}
      onRenameGroup={!isRenamingDisabled ? () => {
        setBoardGroupEditing({ id: null });
        onRenameGroup();
      } : undefined}
      onHideGroup={!isHidingDisabled ? onHideGroup : undefined}
      readOnly={readOnly}
      toolbar={toolbar}
      collapse={collapse}
      toggleCollapse={toggleCollapse}
      docCount={`${docCount}${hasNextPage ? '+' : ''}`}
      showDocCount={isInboxSection && !!group.name}
      isNewInbox={isNewInbox}
      newDocPosition={draftPosition}
      {...htmlProps}
    >
      <InfiniteScroll
        id={groupId}
        disabled={(isList && withGroupBy && groupCount > 1) ||
          dndIsActive ||
          items.length < SIZE_DOCS_PAGE}
        isLoading={showLoadingMore}
        loadMore={onLoadMoreDocs}
        hasMoreData={hasMoreData}
        direction="vertical"
        scrollableElement={scrollableElement}
        loader={(
          <ContentList $isNewInbox={isNewInbox}>
            {Array(3).fill(0).map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ViewCardSkeleton key={i} viewType={viewType} />
            ))}
          </ContentList>
        )}
      >
        {(!!filteredItems.length || showMore || showLoadingMore || showBottomForm || showTopForm) && (
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <ContentList $isNewInbox={isNewInbox}>
              {showTopForm && (
                <BoardGroupNewDoc
                  targetPosition="top"
                  groupId={groupId}
                  statusId={group.statusId}
                  viewType={viewType}
                />
              )}

              {filteredItems.map((itemId, itemIndex) => {
                const doc = getDoc(itemId);
                return doc && (
                  <DocProvider
                    key={itemId}
                    value={doc}
                    isLastChild={itemIndex === filteredItems.length - 1}
                    groupId={groupId}
                  >
                    <DocItem
                      itemId={itemId}
                      docIndex={itemIndex}
                      isGroupInBoardView={isGroupInBoardView}
                      ref={makeMemoizedSetRef(itemIndex)}
                      docUrl={getDocItemUrlFromView({
                        doc,
                        doctype: doc.doctype,
                        docDocSource: doc.docSource?.doc,
                      })}
                      groupId={groupId}
                      asPlaceholder={itemId === activeId}
                      isLazy
                      isSelectable={!isMobile}
                      isGroupDragging={isDragging}
                      isNewInbox={isNewInbox}
                      {...getDocItemProps(doc)}
                    />
                  </DocProvider>
                );
              })}

              {showMore && (
                <MoreButton onClick={() => moreDocs(group.pageInfo?.endCursor ?? '')}>
                  <DownArrowIcon size={12} />
                  {`Load ${SIZE_DOCS_PAGE} more`}
                </MoreButton>
              )}

              {showBottomForm && (
                <BoardGroupNewDoc
                  targetPosition="bottom"
                  groupId={groupId}
                  statusId={group.statusId}
                  viewType={viewType}
                  lastChildId={filteredItems[filteredItems.length - 1]}
                />
              )}
            </ContentList>
          </SortableContext>
        )}
      </InfiniteScroll>
    </Group>
  );
});

export default memo(BoardGroup);
