import { Skeleton } from '@cycle-app/ui';

import { Label, StyledCheckboxInput, StyledLinearIcon, Team, Type } from './DocLinearAutoCreate.styles';

interface Props {
  id: string;
  checked: boolean;
  className?: string;
  isLoading: boolean;
  onChange: (checked: boolean) => void;
  teamName: string | undefined;
  type: 'issue' | 'project';
  size?: 'S' | 'M';
}

export const DocLinearAutoCreateCheckbox = ({
  id, checked, className, isLoading, onChange, teamName, type, size,
}: Props) => {
  return (
    <StyledCheckboxInput
      id={id}
      checked={checked}
      className={className}
      onChange={() => onChange(!checked)}
      stopPropagation
      label={(
        <Label>
          <StyledLinearIcon style={{ color: undefined }} />
          {' '}
          <Type>{type}</Type>
          {isLoading && <Skeleton width={34} />}
          {!isLoading && teamName && (
            <Team>
              {`· ${teamName}`}
            </Team>
          )}
        </Label>
      )}
      $size={size}
    />
  );
};
