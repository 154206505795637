import { ShinyCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;

  width: 100%;
  height: 100%;

  inset: 0;
  background-color: inherit;
`;

export const Line = styled.div`
  ${ShinyCss};
  border-radius: 2px;
  width: 100%;
  height: 10px;
  background-color: ${p => p.theme.colors.background.tertiary};

  :last-child {
    width: 68%;
  }
`;
