import { typo, ToggleInput } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Main = styled.div`
  max-width: 473px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledCaretIcon = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const Heading = styled.h3`
  ${typo.body500}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SourcesBox = styled.div`
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  overflow: hidden;
`;

export const SourceItem = styled(ToggleInput)`
  label {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px;
    width: 100%;
  }

  &:not(:first-child) {
    border-top: 1px solid ${p => p.theme.colors.border.primary};
  }
`;

export const SourceLabel = styled.div`
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SourceIcon = styled.div`
  width: 16px;
  display: flex;
  align-items: center;
`;
