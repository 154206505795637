import { ThemeType } from '@cycle-app/graphql-codegen';
import { Button, typo } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

export const Action = styled(Button).attrs({
  size: 'S',
  variant: 'secondary',
})`
  color: ${p => (p.theme.themeType === ThemeType.Sunrise ? Color.Cycle : Color.White)};
  ${typo.body500}

  &:disabled {
    color: ${p => p.theme.colors.text.disabled};
  }
`;
