import { FileType } from '../types/files.types';

export const getFileType = ({ type }: File): FileType => {
  if (type.includes('image')) return 'image';
  if (type.includes('video')) return 'video';
  if (type.includes('audio')) return 'audio';
  return 'file';
};

export const readFileSrc = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const imgSrc = e.target?.result;
    if (typeof imgSrc !== 'string') return reject(new Error("Couldn't read this file"));
    return resolve(imgSrc);
  };

  reader.readAsDataURL(file);
});

export const readImageSize = (file: File): Promise<{ width: number; height: number }> => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => {
    resolve({
      width: img.width,
      height: img.height,
    });
  };
  readFileSrc(file).then(src => {
    img.src = src;
  }).catch(reject);
});

type BlobUrlToFileParams = {
  blobUrl: string;
  fileName?: string;
  mime?: string;
};

export const blobUrlToFile = async ({
  blobUrl, fileName, mime,
}:BlobUrlToFileParams) => {
  const response = await fetch(blobUrl);

  if (!response.ok) throw new Error(`Failed to fetch data from ${blobUrl}`);

  const blobData = await response.blob();
  const file = new File(
    [blobData],
    fileName || 'File without name',
    { type: mime || 'text/plain' },
  );

  return file;
};
