import { LinkLinearIssueDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import useSafeMutation from 'src/hooks/useSafeMutation';

type linkParams = {
  variables: {
    docId: string;
    issueId: string;
  };
};

export const useLinkLinearIssue = () => {
  const { getIntegration } = useProductIntegrations();

  const isActive = !!getIntegration(IntegrationType.Linear)?.provider;

  const [linkLinearIssue] = useSafeMutation(LinkLinearIssueDocument);

  const link = useCallback((params: linkParams) => (isActive
    ? linkLinearIssue(params)
    : undefined
  ), [isActive, linkLinearIssue]);

  return {
    link,
  };
};
