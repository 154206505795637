import { typo, Dropdown, Button, ActionButton, BOX_SHADOW_COLOR_Z2 } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { NewDocContainer } from 'src/app/NewDoc/NewDoc.styles';
import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP } from 'src/constants/editor.constants';

const ACTION_SIDE_PADDING = 8;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  flex-wrap: wrap;
  width: ${EDITOR_WIDTH_DESKTOP}px;
  width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  max-width: 100%;
  padding: 0 ${EDITOR_MARGIN}px 0 ${EDITOR_MARGIN - ACTION_SIDE_PADDING}px;
  margin: auto;

  ${mobile} {
    flex-direction: column;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  ${NewDocContainer} & {
    padding-bottom: 12px;
  }
`;

export const EditButton = styled(ActionButton)`
  position: absolute;
  top: -6px;
  right: -6px;
  opacity: 0;
  width: 16px;
  height: 16px;
  padding: 0;
  background: ${p => p.theme.colors.docPanel.bg};
  box-shadow:
    0 0 0 1px ${p => p.theme.colors.border.primary},
    0px 1px 2px ${BOX_SHADOW_COLOR_Z2};
  :hover {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Action = styled(Button).attrs({ variant: 'secondary' })`
  ${typo.body400}
  gap: 4px;
  border-radius: 4px;
  padding: 2px ${ACTION_SIDE_PADDING}px;
  border: 0;
  user-select: none;

  --color: ${p => p.theme.colors.button.secondaryV2.color};

  :hover, :focus {
    --color: ${p => p.theme.colors.button.secondaryV2.colorHover};
    --bg: ${p => p.theme.colors.button.secondaryV2.bgHover};
  }

  :active {
    --color: ${p => p.theme.colors.button.secondaryV2.colorHover};
    --bg: ${p => p.theme.colors.button.secondaryV2.bgActive};
  }

  ${p => p.active && css`
    --color: ${p.theme.colors.button.secondaryV2.colorHover};
    --bg: ${p.theme.colors.button.secondaryV2.bgHover};
  `}

  > svg {
    height: 14px;
  }

  :hover ${EditButton} {
    opacity: 1;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  width: 400px;
  padding: 16px;
`;
