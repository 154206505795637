import { ViewType } from '@cycle-app/graphql-codegen';
import { TextButton, ActionButton, typo, ring } from '@cycle-app/ui';
import { hover } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DocParent from '../DocParent/DocParent';
import { ParentIcon } from '../DocParent/DocParent.styles';

type AddParentBtnProps = {
  context: 'doc-item' | 'doc-panel';
  viewType?: ViewType;
};

export const AddParentBtn = styled(TextButton) <AddParentBtnProps>`
  gap: 6px;
  background: ${p => p.theme.colors.card.bg};
  border-radius: 6px;
  padding: 0 6px;
  height: 24px;
  ${typo.caption400}

  :hover, :focus, &.force-focus {
    ${ParentIcon} {
      color: ${p => p.theme.colors.card.parent.colorHover};
    }
  }

  ${p => p.context === 'doc-item' && css`
    color: ${p.theme.colors.text.disabled};
    background: ${p.viewType === ViewType.Kanban ? 'transparent' : p.theme.colors.card.bg};
    :hover, :focus {
      color: ${p.theme.colors.docItem.actionButton.colorHover};
      background: ${p.theme.colors.docItem.actionButton.bgHover};
    }
    :active {
      color: ${p.theme.colors.docItem.actionButton.colorHover};
      background: ${p.theme.colors.docItem.actionButton.bgActive};
    }
  `};

  width: auto;
  svg {
    width: 16px;
    height: 16px;
    flex: none;
  }
`;

export const EditParentBtnContainer = styled.div<{ $absolute?: boolean }>`
  ${p => p.$absolute && css`
    position: absolute;
    right: 4px;
  `};
`;

export const EditParentBtn = styled(ActionButton)`
  ${ring}
  --ring-color: ${p => p.theme.colors.actionButton.outline};
  background-color: ${p => p.theme.colors.background.primary};
  &:hover {
    background-color: ${p => p.theme.colors.background.hover};
    svg {
      color: ${p => p.theme.colors.card.parent.colorHover};
    }
  }
`;

export const StyledDocParent = styled(DocParent)`
  position: relative;

  ${EditParentBtn} {
    display: none;
    opacity: 0;
  }

  ${hover} {
    ${EditParentBtn}.force-focus {
      display: flex;
      opacity: 1;
    }
    &:hover {
      ${EditParentBtn} {
        display: flex;
        opacity: 1;
      }
    }
  }

  ${p => p.context === 'doc-item' && css`
    ${EditParentBtn} {
      display: flex;
      opacity: 1;
    }
  `}
`;
