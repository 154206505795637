import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const WarningAltIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      // eslint-disable-next-line max-len
      d="M7 5.18268V6.95287M7 8.75H7.00583M5.5313 2.58565L2.01339 8.37859C1.2973 9.5578 2.12569 11.0833 3.48209 11.0833H10.5179C11.8743 11.0833 12.7027 9.5578 11.9866 8.37859L8.46872 2.58565C7.79205 1.47145 6.20795 1.47145 5.5313 2.58565ZM7.14583 8.75C7.14583 8.83056 7.08056 8.89583 7 8.89583C6.91944 8.89583 6.85416 8.83056 6.85416 8.75C6.85416 8.66944 6.91944 8.60417 7 8.60417C7.08056 8.60417 7.14583 8.66944 7.14583 8.75Z"
      stroke="currentcolor"
      strokeWidth="1.16667"
      strokeLinecap="round"
    />
  </Svg>
);
