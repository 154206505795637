import { ProductCustomersDocument, ProductCustomersQueryVariables, ProductCustomersFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useSafeAsyncLazyQuery } from './useSafeAsyncLazyQuery';

export const useCustomerExistenceCheck = (productId?: string) => {
  const [fetch] = useSafeAsyncLazyQuery<{ node: ProductCustomersFragment }, ProductCustomersQueryVariables>(
    ProductCustomersDocument,
    {
      fetchPolicy: 'network-only',
    },
  );

  const checkCustomer = (searchText: string) => {
    if (!productId) return null;
    return fetch({
      variables: {
        productId,
        cursor: '',
        size: 1,
        searchText,
      },
    }).then(response => {
      const match = nodeToArray(response?.node.customers).find(customer => customer.email === searchText);
      return match || null;
    });
  };

  return checkCustomer;
};
