import { Role, BillingPlan } from '@cycle-app/graphql-codegen';
import * as FullStory from '@fullstory/browser';

import { isProductionEnv } from 'src/utils/env.util';
import { isCycleUser } from 'src/utils/users.util';

const WORKSPACE_SLUG = [
  'airsaas',
  'lazy',
  'appchoose',
  'contrast',
  'figures',
  'jeudimerci',
  'mokacare',
  'riaktr',
  'nodalview',
  'florence',
  'kraaft',
  'makesense',
  'wequityapp',
  'epoch',
  'smovin',
  'fabriq',
];

type InitFullStoryParams = {
  me?: {
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  role?: Role;
  plan?: BillingPlan;
  isOnboarding?: boolean;
  productSlug: string;
};

export const initFullStory = ({
  me, role, plan, isOnboarding, productSlug,
}: InitFullStoryParams) => {
  if (
    !isProductionEnv() ||
    !me?.id ||
    !me?.email ||
    isCycleUser(me) ||
    WORKSPACE_SLUG.includes(productSlug) ||
    (!isOnboarding && role !== Role.Maker) ||
    (!isOnboarding && plan === BillingPlan.Free)
  ) return;

  if (!FullStory.isInitialized()) {
    FullStory.init({ orgId: 'RAGVN' });
  }

  FullStory.identify(me.id, {
    displayName: `${me.firstName} ${me.lastName}`,
    email: me.email,
  });
};
