import styled from 'styled-components';

import { body400, body500 } from '../../theme/typo';
import { Container as ActionButtonContainer } from '../Buttons/ActionButton/ActionButton.styles';

export const MainButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 4px 12px 4px 8px;
`;

export const DotsMenuContainer = styled.div`
  left: -4px;
  position: relative;
  overflow: hidden;
  flex: none;
  width: 0;
  opacity: 0;
  transition: width 0.10s linear, opacity 0.10s linear;
  

  :has(.force-focus) {
    width: 30px;
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  width: fit-content;
  height: 32px;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  transition: background 0.10s linear, transform 0.10s linear;

  --headerBg: ${p => p.theme.colors.group.feedbackList.bg};
  --headerBgHover: ${p => p.theme.colors.group.feedbackList.bgHover};
  --headerBgActive: ${p => p.theme.colors.group.feedbackList.bgActive};

  ${ActionButtonContainer} {
    --bgHover: var(--headerBgHover);
    --bgActive: var(--headerBgActive);
  }
  
  background: var(--headerBg);

  /* stylelint-disable-next-line selector-type-no-unknown */
  :hover:not(:has(${DotsMenuContainer}:hover)) {
    background: var(--headerBgHover);
  }

  :hover ${DotsMenuContainer} {
    width: 30px;
    opacity: 1;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  :hover:has(${MainButton}:active) {
    background: var(--headerBgActive);
    transform: scale(0.98);
  }
`;

export const Title = styled.div`
  ${body500}
`;

export const Count = styled.div`
  ${body400}
  opacity: 0.5;
`;
