import { useSubscription, gql } from '@apollo/client';
import {
  NewNotificationSubscription,
  NewNotificationDocument,
  NewNotificationSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';

export const useNewNotificationSubscription = () => {
  const { product } = useProduct('cache-only');

  return useSubscription<
  NewNotificationSubscription,
  NewNotificationSubscriptionVariables
  >(
    NewNotificationDocument,
    {
      onSubscriptionData({
        client: { cache }, subscriptionData: { data },
      }) {
        if (!data?.newNotification || !product) return;

        const newNotificationRef = cache.writeFragment({
          data: data.newNotification,
          fragment: gql`
            fragment NewNotification on Notification {
              id
            }
          `,
        });

        cache.modify({
          id: cache.identify(product),
          fields: {
            notificationsNotRead: (count) => (data.newNotification.read ? count : count + 1),
            notifications: (notifications) => ({
              ...notifications,
              edges: [{
                __typename: 'NotificationEdge',
                node: newNotificationRef,
              }].concat(notifications.edges),
            }),
          },
        });
      },
    },
  );
};
