import { Tooltip } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import ProductsMenu from 'src/components/ProductsMenu/ProductsMenu';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import {
  PageName,
  Logo,
  Workspace,
} from '../SidebarHeader.styles';
import { Container, Button, Caret } from './SidebarHeaderProduct.styles';

export const SidebarHeaderProduct = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const product = useProductBase();
  const collapsed = useSidebarCollapsed();
  const isMobile = useIsMobile();
  return (
    <Container>
      <DropdownLayer
        content={<ProductsMenu hide={hideDropdown} />}
        visible={isDropdownVisible}
        hide={hideDropdown}
      >
        <Tooltip
          content={product?.name}
          placement="right"
          disabled={!collapsed}
          withPortal
        >
          <Button
            className={isDropdownVisible ? 'force-focus' : ''}
            onClick={toggleDropdown}
            $collapsed={collapsed}
          >
            <Workspace>
              {product?.logo && <Logo bgSrc={product?.logo?.url} />}
              {!collapsed && (
                <PageName>
                  <h1>{product?.name}</h1>
                </PageName>
              )}
            </Workspace>
            {!isMobile && !collapsed && (
              <Caret>
                <CaretIcon direction={isDropdownVisible ? 'top' : 'bottom'} />
              </Caret>
            )}
          </Button>
        </Tooltip>
      </DropdownLayer>
    </Container>
  );

  function hideDropdown() {
    setIsDropdownVisible(false);
  }

  function toggleDropdown() {
    setIsDropdownVisible(!isDropdownVisible);
  }
};
