import { FC } from 'react';
import { createPortal } from 'react-dom';

import { PortalId } from 'src/types/portal.types';
import { getPortalElement } from 'src/utils/elements.util';

interface Props {
  portalId: PortalId;
}

const Portal: FC<React.PropsWithChildren<Props>> = ({
  portalId, children,
}) => {
  const portal = getPortalElement(portalId);

  if (!portal) {
    throw new Error(`Element #${`portal-${portalId}`} not present in document`);
  }

  return createPortal(children, portal);
};

export default Portal;
