import { Input } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FakeInput = styled.div`
  width: 100%;

  border-radius: 6px;
  background-color: ${p => p.theme.colors.boardConfig.filters.fakeInputBg};

  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
`;

export const Attribute = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  max-width: 180px;
  > svg {
    vertical-align: sub;
    margin-right: 4px;
    flex: none;
  }
`;

export const Placeholder = styled.div`
  color: ${p => p.theme.colors.text.disabled};
`;

export const FilterInput = styled(Input)`
  input {
    padding: 5px;
    border: none;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DeleteBtnContainer = styled.div`
  margin-left: auto;
  svg {
    width: 12px;
    height: 12px;
  }
`;
