import { Language } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';

export const LANGUAGES_OPTIONS: SelectOption<Language>[] = Object.values(Language).map(langCode => ({
  value: langCode,
  label: getLanguageName(langCode),
})).sort((a, b) => a.label.localeCompare(b.label));

export function getLanguageName(code: Language) {
  switch (code) {
    case Language.Da: return 'Danish';
    case Language.De: return 'German';
    case Language.En: return 'English';
    case Language.Es: return 'Spanish';
    case Language.Fr: return 'French';
    case Language.Hi: return 'Hindi';
    case Language.Id: return 'Indonesian';
    case Language.It: return 'Italian';
    case Language.Ja: return 'Japanese';
    case Language.Ko: return 'Korean';
    case Language.Nl: return 'Dutch';
    case Language.No: return 'Norwegian';
    case Language.Pl: return 'Polish';
    case Language.Pt: return 'Portuguese';
    case Language.Ru: return 'Russian';
    case Language.Sv: return 'Swedish';
    case Language.Ta: return 'Tamil';
    case Language.Tr: return 'Turkish';
    case Language.Uk: return 'Ukrainian';
    case Language.Zh: return 'Chinese';
    default: return '';
  }
}
