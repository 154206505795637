import { MotionProps, Variants } from 'framer-motion';

export const motionProps: MotionProps = {
  initial: 'exit',
  exit: 'exit',
  animate: 'enter',
};

export const variantsContent: Variants = {
  exit: {
    y: 100,
    scale: 0.5,
    opacity: 0,
    transition: {
      duration: 0.15,
      delay: 0,
    },
  },
  enter: {
    y: 0,
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.15,
      delay: 0.3,
    },
  },
};
