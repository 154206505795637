import { ReactNode, useState } from 'react';

import { DocSearchInsights } from 'src/components/DocSearch/DocSearchInsights';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { useOptimizedBooleanState } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { Layer } from 'src/types/layers.types';

export type InsightsSearchProps = {
  parentId: string;
  visible: boolean;
  hide: VoidFunction;
  createModal: (props: {
    visible: boolean;
    hide: VoidFunction;
    quote: string;
  }) => ReactNode;
  layer?: Layer;
};

export const InsightsSearch = ({
  parentId,
  visible,
  hide,
  createModal,
  layer = Layer.DropdownZ1,
}: InsightsSearchProps) => {
  const changeDocParent = useChangeDocParent();
  const [quote, setQuote] = useState('');

  const [isCreateModalVisible, {
    setFalseCallback: hideCreateModal,
    setTrueCallback: showCreateModal,
  }] = useOptimizedBooleanState(false);

  const offsetAdapter = useTippyOffsetAdapter();

  return (
    <DropdownLayer
      withPortal
      layer={layer}
      placement="bottom"
      visible={visible}
      hide={hide}
      content={(
        <DocSearchInsights
          suggestionDocId={parentId}
          onClickCreate={text => {
            hide();
            setQuote(text ?? '');
            showCreateModal();
          }}
          onAdd={docId => {
            hide();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            changeDocParent({
              docId,
              parentId,
            });
          }}
          onOptionsChange={offsetAdapter.forceUpdate}
        />
      )}
      {...offsetAdapter.tippyProps}
    >
      {createModal({
        visible: isCreateModalVisible,
        hide: hideCreateModal,
        quote,
      })}
    </DropdownLayer>
  );
};
