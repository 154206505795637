import { ViewType } from '@cycle-app/graphql-codegen';
import { Emoji, Skeleton } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { forwardRef, useMemo } from 'react';

import { useNavigate, useOptimizedBooleanState } from 'src/hooks';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';

import { SearchContainer } from '../DocSearch/DocSearch.styles';
import {
  ResultLabel,
  StyledSelectLine,
  ResultTitle,
  ListContainer,
} from '../DocSearchDropdown/DocSearchDropdown.styles';
import { DropdownLayer } from '../DropdownLayer';
import { Container, StyledDocParent, StyledSearchResults } from './DocItemDraftParent.styles';

interface Props {
  parentId: string;
  docParents: {
    id: string;
    title: string;
    doctype: {
      id: string;
    };
  }[];
  onSelect: (id: string) => void;
  viewType: ViewType;
}

export const DocItemDraftParent = forwardRef<HTMLDivElement, Props>(({
  onSelect, parentId, docParents, viewType,
}, ref) => {
  const { docTypes } = useGetDocTypes();
  const [isDropdownVisible, {
    setTrueCallback: openDropdown, setFalseCallback: closeDropdown,
  }] = useOptimizedBooleanState(false);
  const optionsValues = useMemo(() => docParents.map(doc => doc.id), [docParents]);
  const { navigateToDocFullPage } = useNavigate();
  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues,
    value: optionsValues[0],
    onSelect: (value) => {
      if (value) onSelect(value);
      closeDropdown();
    },
    autoFocus: true,
    defaultIndex: 0,
    enabled: isDropdownVisible,
  });
  if (!docParents.length) return null;
  const selectedDoc = docParents.find(doc => doc.id === parentId);
  const readonly = docParents.length <= 1;
  return (
    <Container
      ref={ref}
      onClick={e => {
        if (!selectedDoc) return;
        e.preventDefault();
        e.stopPropagation();
        navigateToDocFullPage(selectedDoc, undefined, true);
      }}
      $isListing={viewType === ViewType.List}
    >
      <DropdownLayer
        visible={isDropdownVisible}
        hide={closeDropdown}
        placement="bottom-start"
        content={(
          <SearchContainer $isSearching={false}>
            <ListContainer {...listProps}>
              <StyledSearchResults $hasMarginBottom>
                {docParents.map(doc => (
                  <StyledSelectLine
                    key={doc.id}
                    isSelected={selected === doc.id}
                    startSlot={<Emoji emoji={docTypes[doc.doctype.id]?.emoji} />}
                    label={(
                      <ResultLabel>
                        <ResultTitle>{doc.title}</ResultTitle>
                      </ResultLabel>
                    )}
                    {...itemProps(doc.id)}
                  />
                ))}
              </StyledSearchResults>
            </ListContainer>
          </SearchContainer>
        )}
      >
        {selectedDoc ? (
          <StyledDocParent
            context="doc-item"
            doc={selectedDoc}
            onClickEdit={readonly ? undefined : (e) => {
              e.stopPropagation();
              openDropdown();
            }}
            showTitle
            showPublicId={false}
          />
        ) : <Skeleton height={24} width={70} />}
      </DropdownLayer>
    </Container>
  );
});
