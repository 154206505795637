import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetViewDuplicateModal,
  setValue: setViewDuplicateModal,
} = make<{
  duplicateViewId: string | null;
}>({
  defaultState: {
    duplicateViewId: null,
  },
});

export const {
  hookValue: useGetViewExportModal,
  setValue: setViewExportModal,
} = make<{
  exportViewId: string | null;
}>({
  defaultState: {
    exportViewId: null,
  },
});

export const {
  hookValue: useGetViewDeleteModal,
  setValue: setViewDeleteModal,
} = make<{
  deleteViewId: string | null;
}>({
  defaultState: {
    deleteViewId: null,
  },
});
