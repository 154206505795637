import { typo, ToggleInput, Button } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const StyledToggleInput = styled(ToggleInput)`
  margin-right: 8px;
`;

const meetingCss = css`
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 14px 16px;
  gap: 16px;
`;

export const Meeting = styled.div`
  ${meetingCss}
  border: 1px solid ${p => p.theme.colors.border.primary};
  ${p => p.theme.isDark && `background-color: ${p.theme.baseColors.Grey800};`}
`;

export const MeetingOngoing = styled.div`
  ${meetingCss}
  background-color: hsl(var(--cycle));
  color: ${p => p.theme.baseColors.White};
`;

export const MeetingHeading = styled.div`
  ${typo.body500}
`;

export const MeetingAction = styled.div`
  align-items: center;
  display: flex;
  flex: none;
`;

interface StyledLinkProps {
  $light?: boolean;
}

export const StyledLink = styled.a<StyledLinkProps>`
  align-items: center;
  color: ${p => (p.$light ? p.theme.baseColors.Blue300 : p.theme.colors.button.nospacePrimary.color)};
  display: inline-flex;
  gap: 8px;
  margin-top: 4px;
  word-break: break-all;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: ${p => (p.$light ? p.theme.baseColors.Blue400 : p.theme.colors.button.nospacePrimary.colorHover)};
  }
`;

export const NowLabel = styled.div`
  ${typo.caption500}
  align-items: center;
  color: ${p => p.theme.baseColors.Blue300};
  display: flex;
  gap: 4px;
`;

// TO FIX: use new button
export const ButtonTernary = styled(Button)`
  color: hsl(var(--cycle));
  font-weight: 500;

  ${p => p.theme.isDark && `
    --bg: #FFF;

    &:hover, &:active, &:focus {
      --bg: ${p.theme.baseColors.Grey200};
    }

    &:active, &:focus {
      outline: 2px solid hsl(var(--cycle300));
    }
  `}
`;
