import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { body400, body500, caption500 } from '../../../theme/typo';
import { boxShadowZ1, boxShadowZ2, ring2 } from '../../../utils/styles.util';

export type Variant =
  | 'primary'
  | 'light'
  | 'warning'
  | 'secondary'
  | 'ternary'
  | 'outlined'
  | 'outlined-alt'
  | 'warning-secondary'
  | 'nospace'
  | 'nospace-primary'
  | 'alt';
export type Size = 'S' | 'M' | 'L';

type ChildrenProps = {
  $hasContentStart: boolean;
};

export const Children = styled.span<ChildrenProps>`
  display: inline-flex;
  align-items: center;
  justify-content: ${p => (p.$hasContentStart ? 'flex-start' : 'center')};
  gap: 8px;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export interface ContainerProps {
  $variant: Variant;
  $size: Size;
  $isLoading?: boolean;
  $isGrouped?: boolean;
  $useCycleColor?: boolean;
  $iconOnly?: boolean;
  $active?: boolean;
  $full?: boolean;
}

export const ButtonCss = css<ContainerProps>`
  --radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: var(--radius);
  background: var(--bg);
  color: var(--color);
  box-sizing: border-box;
  border: 1px solid var(--border);
  cursor: pointer;
   /* Required to position the spinner */
  position: relative;
  transition: box-shadow 100ms linear;

  &:disabled {
    pointer-events: none;
  }

  ${p => p.$isLoading && css`
    color: transparent;
    pointer-events: none;

    ${Children} {
      opacity: 0;
    }

    ${SpinnerContainer} {
      color: var(--color);
    }
  `}

  ${p => p.$size === 'S' && css`
    ${caption500};
    padding: 1px 8px;

    ${p.$iconOnly && css`padding: 8px;`}
  `}

  ${p => p.$size === 'M' && css`
    ${body500};
    padding: 3px 16px;

    ${p.$iconOnly && css`padding: 8px;`}
  `}

  ${p => p.$size === 'L' && css`
    ${body500};
    padding: 7px 24px;

    ${p.$iconOnly && css`padding: 12px;`}
  `}

  ${p => p.$variant === 'primary' && css`
    --bg: ${p.$useCycleColor ? 'hsl(var(--cycle))' : p.theme.colors.button.noColor.bg};
    --color: ${p.$useCycleColor ? p.theme.colors.text.white : p.theme.colors.button.noColor.color};
    --border: transparent;
    ${boxShadowZ1};

    &:hover, &:focus {
      --bg: ${p.$useCycleColor ? 'hsl(var(--cycle600))' : darken(0.1, p.theme.colors.button.noColor.bg)};
      ${boxShadowZ2};
      outline: none;
    }

    &:active {
      --bg: ${p.$useCycleColor ? 'hsl(var(--cycle700))' : darken(0.2, p.theme.colors.button.noColor.bg)};
      ${boxShadowZ2};
    }

    &:disabled {
      --bg: ${p.theme.colors.background.grey};
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'light' && css`
    --bg: ${p.theme.colors.button.light.bg};
    --color: ${p.theme.colors.text.secondary};
    --border: transparent;
    ${body400};
    padding-top: 0;
    padding-bottom: 0;

    &:hover, &:focus {
      --bg: ${p.theme.colors.button.light.bgHover};
      --color: ${p.theme.colors.text.primary};
      outline: none;
    }

    &:active {
      --bg: ${p.theme.colors.button.light.bgActive};
    }

    &:disabled {
      --color: ${p.theme.colors.text.disabled};
      --bg: ${p.theme.colors.background.disabled};
    }
  `};

  ${p => p.$variant === 'warning' && css`
    --bg: ${p.theme.colors.background.red};
    --color: ${p.theme.colors.text.white};
    --border: transparent;

    &:hover, &:focus {
      --bg: ${darken(0.05, p.theme.colors.background.red)};
    }

    &:active {
      --bg: ${darken(0.1, p.theme.colors.background.red)};
    }

    &:disabled {
      --bg: ${p.theme.colors.background.grey};
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'secondary' && css`
    --bg: ${p.theme.colors.button.secondary.bg};
    --color: ${p.$useCycleColor && p.theme.colors.button.secondary.enableUserColor ? 'hsl(var(--cycle))' : p.theme.colors.button.secondary.color};
    --border: transparent;

    &:hover {
      --bg: ${p.theme.colors.button.secondary.bgHover};
    }
    &:active, &:focus {
      --bg: ${p.theme.colors.button.secondary.bgFocus};
      outline: none;
    }
    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'ternary' && css`
    --bg: ${p.theme.colors.button.ternary.bg};
    --color: ${p.theme.colors.button.ternary.color};
    --border: transparent;
    ${body400};

    &:hover {
      --bg: ${p.theme.colors.button.ternary.bgHover};
    }
    &:focus {
      --bg: ${p.theme.colors.button.ternary.bgFocus};
      outline: 2px solid hsl(var(--cycle));
      outline-offset: -2px;
    }
    &:active {
      --bg: ${p.theme.colors.button.ternary.bgActive};
    }
    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'warning-secondary' && css`
    --bg: transparent;
    --color: ${p.theme.colors.button.warningSecondary.color};
    --border: transparent;

    &:hover {
      --bg: ${p.theme.colors.button.secondary.bgHover};
    }
    &:active, &:focus {
      --bg: ${p.theme.colors.button.secondary.bgFocus};
      outline: none;
    }
    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'outlined' && css`
    --bg: ${p.theme.colors.button.outlined.bg};
    --border: transparent;
    --color: ${p.theme.colors.button.outlined.color};
    ${body400};

    ${p.$active === false && css`
      &:hover, &:focus {
        --bg: ${p.theme.colors.button.outlined.bgHover};
        --color: ${p.theme.colors.button.outlined.colorHover};
      }
    `}
    ${p.$active === true && css`
      --bg: ${p.theme.colors.button.outlined.bgActive};
      --color: ${p.theme.colors.button.outlined.colorActive};
      ${ring2}
    `}
    ${p.$active === undefined && css`
      &:active {
        --bg: ${p.theme.colors.button.outlined.bgActive};
        --color: ${p.theme.colors.button.outlined.colorActive};
        ${ring2}
      }
    `};

    &:disabled {
      --bg: ${p.theme.colors.background.disabled};
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'outlined-alt' && css`
    --bg: ${p.theme.colors.button.outlinedAlt.bg};
    --border: ${p.theme.colors.button.outlinedAlt.border};
    --color: ${p.theme.colors.button.outlinedAlt.color};


    :hover, :focus {
      --bg: ${p.theme.colors.button.outlinedAlt.bgHover};

      outline: none;
    }
    :active {
      --bg: ${p.theme.colors.button.outlinedAlt.bgActive};

    }
    :disabled {
      --color: ${transparentize(0.5, p.theme.colors.button.outlinedAlt.color)};
      --bg: ${p.theme.colors.button.outlinedAlt.bg};
    }
  `};

  ${p => (p.$variant === 'nospace' || p.$variant === 'nospace-primary') && css`
    --bg: transparent;
    --border: transparent;
    --color: ${p.$variant === 'nospace-primary' ? p.theme.colors.button.nospacePrimary.color : p.theme.colors.text.secondary};
    padding: 0;
    outline-offset: 2px;

    &:hover, &:focus {
      --color: ${p.$variant === 'nospace-primary' ? p.theme.colors.button.nospacePrimary.colorHover : p.theme.colors.text.primary};
    }

    &:active {
      --color: hsl(var(--cycle));
    }

    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'alt' && css`
    --bg: ${p.theme.colors.button.alt.bg};
    --border: ${p.theme.colors.button.alt.border};
    --color: ${p.theme.colors.button.alt.color};

    :hover, :focus {
      --bg: ${p.theme.colors.button.alt.bgHover};

      outline: none;
    }
    :active {
      --bg: ${p.theme.colors.button.alt.bgActive};
    }
    :disabled {
      --color: ${transparentize(0.5, p.theme.colors.button.alt.color)};
      --bg: ${p.theme.colors.button.alt.bg};
    }
  `};

  ${p => p.$isGrouped && css`
    box-shadow: none;
    margin-right: -1px;
    border-radius: 0;

    &:first-child {
      border-start-start-radius: var(--radius);
      border-end-start-radius: var(--radius);
    }

    &:last-child {
      border-start-end-radius: var(--radius);
      border-end-end-radius: var(--radius);
    }
  `}

  ${p => p.$full && css`width: 100%;`}

  svg {
    flex: unset;
  }
`;

export const Container = styled.button`${ButtonCss}`;
