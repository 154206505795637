import { typo, ActionButton } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import DocPrimaryAttributes from 'src/components/DocPrimaryAttributes/DocPrimaryAttributes';
import { ViewType } from 'src/types/viewType.types';

import DocAssignee from '../DocAssignee/DocAssignee';
import { DocCustomer } from '../DocCustomer/DocCustomer';

export const StyledDocAssignee = styled(DocAssignee)`
  height: 20px;
  background-color: ${p => p.theme.colors.docItem.actionButton.bgHover};
  border-radius: 4px;
`;

export const StyledDocCustomer = styled(DocCustomer)`
  --bg: ${p => p.theme.colors.docItem.actionButton.bgHover};
  --bgHover: ${p => p.theme.colors.docItem.actionButton.bgHover};
  height: 20px;
  padding-left: 0;
  padding-right: 0;

  > * {
    transform: scale(0.8);
  }
`;

const AddPropertyButton = styled(ActionButton)`
  opacity: 0;
  transition: opacity .2s ease-in-out;
`;

interface ContainerProps {
  viewType?: ViewType;
  nbAttributes: number;
  readOnly: boolean;
  $context: 'doc-item' | 'doc-panel';
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 6px;

  flex-wrap: wrap;

  ${props => props.viewType === ViewType.List && css`
    flex-direction: row-reverse;
    gap: 8px;
    overflow: hidden;
    height: 20px;
  `}

  ${p => p.readOnly && css`
    pointer-events: none;
  `};

  &:hover {
    ${AddPropertyButton} {
      opacity: 1;
    }
  }

  ${p => p.$context === 'doc-panel' && css`
    gap: 6px;
  `};
`;

export const Info = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 4px;
`;

export const Url = styled.p`
  ${typo.caption400};
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TooltipSubtitle = styled.p`
  ${typo.caption400};
  color: ${p => p.theme.colors.tooltip.textLight};
`;

export const StyledDocPrimaryAttributes = styled(DocPrimaryAttributes)`
  display: contents;
`;
