import { ThemeType } from '@cycle-app/graphql-codegen';

export const convertLegacyLocalStorageValue = (value: string): ThemeType => {
  if (value === ThemeType.Eclipse || value === ThemeType.Nightfall || value === ThemeType.Sunrise) return value as ThemeType;

  const convertor = {
    eclipse: ThemeType.Eclipse,
    nightfall: ThemeType.Nightfall,
    sunrise: ThemeType.Sunrise,
  };

  return value in convertor ? convertor[value as 'eclipse' | 'nightfall' | 'sunrise'] : ThemeType.Sunrise;
};
