import styled from 'styled-components';

export const FormGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const FormGroup2Columns = styled(FormGroup)`
  flex-wrap: wrap;
  > * {
    min-width: calc(50% - 6px);
  }
`;
