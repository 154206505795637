import { useState } from 'react';

interface UseToggle {
  (initialValue?: boolean): [boolean, () => void];
}

export const useToggle: UseToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  return [value, () => setValue(!value)];
};
