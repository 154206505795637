import { typo, Button } from '@cycle-app/ui';
import { CardContainerBlock } from '@cycle-app/ui/components/IntegrationCard/IntegrationCard.styles';
import styled from 'styled-components';

import { PortalModalStyled } from '../DialogModal/DialogModal.styles';

export const StyledPortalModal = styled(PortalModalStyled)`
  width: 510px;
  padding: 24px;
`;

export const StyledButton = styled(Button)`
  padding: 0;
  &:hover, &:focus {
    --bg: transparent;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ModalFooter = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 22px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ModalTitle = styled.span`
  ${typo.headerLight}
`;

export const ModalIntegrationsGrid = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  ${CardContainerBlock} {
    border: 1px solid ${p => p.theme.colors.home.integrations.modal.border};
    box-shadow: none;
  }
`;
