import { useMemo } from 'react';

import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { useLastDocMentionDocTypeIdUsed } from 'src/reactives/lastView.reactive';

export const useLastUsedDocType = () => {
  const { doctypes } = useProductDoctypesFull();
  const docTypeId = useLastDocMentionDocTypeIdUsed();

  const lastUsedDocType = useMemo(() => {
    const getDefaultDocType = () => {
      const insightDocType = doctypes.find(d => d.name.match(/insight/i));

      return insightDocType || doctypes[0];
    };

    if (docTypeId && doctypes.length) {
      const docType = doctypes.find(d => d.id === docTypeId);

      return docType || getDefaultDocType();
    }

    if (!docTypeId && doctypes.length) return getDefaultDocType();

    return null;
  }, [docTypeId, doctypes]);

  return lastUsedDocType;
};
