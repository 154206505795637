import { Spinner } from '@cycle-app/ui';
import { ERROR_CODE } from '@cycle-app/utilities';

import { useCompanyUpdate } from 'src/hooks/api/mutations/customers/useCompanyUpdate';
import { useEnhancedForm, ErrorMap } from 'src/hooks/form/useEnhancedForm';

import { ProfileInput } from './Profile.styles';

type FormData = {
  name: string;
};

const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    code: ERROR_CODE.COMPANY_WITH_NAME_ALREADY_EXISTS,
    fieldName: 'name',
    renderMessage: () => 'A company with this name already exists',
  },
];

type Props = {
  companyId: string;
  name: string;
  hide: VoidFunction;
};

export const CompanyNameForm = ({
  companyId, name, hide,
}: Props) => {
  const { updateCompany } = useCompanyUpdate();

  const {
    handleSubmit, reset, register, displayFieldsErrors, formState: {
      errors, isSubmitting,
    },
  } = useEnhancedForm<FormData>({
    defaultValues: { name },
  });

  return (
    <form onSubmit={handleSubmit(async data => {
      if (isSubmitting) return;

      const result = await updateCompany({
        companyId,
        name: data.name,
      });

      if (result.errors) {
        displayFieldsErrors(result.errors, mutationErrorsMap, true);
        return;
      }

      hide();
    })}
    >
      <ProfileInput
        data-1p-ignore
        autoFocus
        placeholder="figma.com"
        iconAfter={isSubmitting && <Spinner />}
        error={errors.name?.message}
        {...register('name', {
          required: 'You must have at least 3 characters.',
          min: 3,
          onBlur: () => {
            hide();
            reset();
          },
        })}
        onKeyUp={e => {
          if (e.key === 'Escape') {
            e.stopPropagation();
            hide();
            reset();
          }
        }}
      />
    </form>
  );
};
