import { SelectOption } from '@cycle-app/ui';
import { FC, useCallback } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { CustomerFromFilter, selectableCustomerToOption } from 'src/utils/boardConfig/filtersData.util';
import { SelectionIcon } from 'src/utils/customers.util';

import { Placeholder } from '../../Filter.styles';
import { Container, MoreValues } from '../FilterElements.styles';
import CustomerFilterElementsDropdownContent from './CustomerFilterElementsDropdownContent';

interface Props {
  className?: string;
  dropdownLayer?: Layer;
  filterPropertyRuleId?: string;
  panelTitle: string;
  placeholder: string;
  options: CustomerFromFilter[];
  selectedOptions: CustomerFromFilter[];
  onOptionsAdded: (newOptions: SelectOption[]) => void;
  onOptionRemoved: (optionValue: string) => void;
  disabled?: boolean;
}
const CustomerFilterElements: FC<React.PropsWithChildren<Props>> = ({
  className = '',
  dropdownLayer = Layer.DropdownBoardConfig,
  placeholder,
  options,
  onOptionsAdded,
  onOptionRemoved,
  selectedOptions,
  filterPropertyRuleId,
  disabled = false,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);
  const onUnselectOption = useCallback((o: SelectOption) => onOptionRemoved(o.value), [onOptionRemoved]);
  const onSelectOption = useCallback((o: SelectOption) => onOptionsAdded([
    ...selectedOptions.map(selectableCustomerToOption),
    {
      ...o,
      selected: true,
    }]),
  [onOptionsAdded, selectedOptions]);

  return (
    <DropdownLayer
      layer={dropdownLayer}
      placement="bottom-start"
      visible={isDropdownVisible}
      hide={hideDropdown}
      width={490}
      disabled={disabled}
      withWrapper={false}
      content={(
        <CustomerFilterElementsDropdownContent
          filterPropertyRuleId={filterPropertyRuleId}
          selectedCount={selectedOptions.length}
          selectedOptions={selectedOptions}
          defaultOptions={options}
          onSelectOption={onSelectOption}
          onUnselectOption={onUnselectOption}
        />
      )}
    >
      <Container
        className={className}
        forceFocus={isDropdownVisible}
        onClick={toggleDropdown}
        disabled={disabled}
      >
        {selectedOptions?.[0]
          ? (
            <>
              <SelectionIcon size="S" customer={selectedOptions[0].value} />
              <span className="truncate">
                {selectedOptions[0].value.name?.trim() || selectedOptions[0].value.email}
              </span>
              {selectedOptions.length > 1 && (
                <MoreValues>{`+${selectedOptions.length - 1}`}</MoreValues>
              )}
            </>
          )
          : (<Placeholder>{placeholder}</Placeholder>)}
      </Container>
    </DropdownLayer>
  );
};

export default CustomerFilterElements;
