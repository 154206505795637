import { RightIcon, CloseIcon, ReleaseIcon } from '@cycle-app/ui/icons';

import { useSidebarCollapsed } from '../../hooks';
import { useChangelog } from '../../hooks/releases/useChangelog';
import { useNavigateToSettings } from '../../hooks/useNavigateToSettings';
import { PageId } from '../../hooks/useUrl';
import { setChangelogDiscoverHide, useChangelogDiscover } from '../../reactives/changelogDiscover.reactive';
import { CollapsableNavigationItem } from '../CollapsableNavigationItem';
import bg from './bg.svg';
import {
  Bg, ButtonClose, Container, Content, Cta, Title,
} from './SidebarChangelog.styles';

export const SidebarChangelog = () => {
  const isSidebarCollapsed = useSidebarCollapsed();
  const {
    changelog, isLoading,
  } = useChangelog();
  const { navigate: navigateToSettings } = useNavigateToSettings({ useLimitationModal: true });
  const isVisible = useChangelogDiscover();
  const isPublished = changelog?.isPublished;
  if (isLoading || isPublished || !isVisible) return null;

  if (isSidebarCollapsed) {
    return (
      <CollapsableNavigationItem
        icon={<ReleaseIcon />}
        label="Enable changelog"
      />
    );
  }

  return (
    <Container>
      <ButtonClose onClick={setChangelogDiscoverHide}>
        <CloseIcon size={12} />
      </ButtonClose>
      <Bg src={bg} />
      <Content>
        <Title>Introducing Changelog</Title>
        <Cta
          variant="nospace-primary"
          size="M"
          onClick={() => navigateToSettings(PageId.SettingsReleases)}
          iconEnd={<RightIcon />}
        >
          Enable now
        </Cta>
      </Content>
    </Container>
  );
};
