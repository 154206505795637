import { DownloadToaster } from 'src/components/DownloadToaster';
import { getDownloads, removeDownload, setDownload } from 'src/reactives/downloads.reactive';
import { download } from 'src/utils/download.utils';
import { addToaster } from 'src/utils/toasters.utils';

/**
 * Download a file and show a toaster with the download progress,
 * a cancel/close button and a retry button in case of error.
 */
export const downloadWithToaster = (options: {
  url: string;
  baseName: string;
}) => {
  // Prevent multiple downloads of the same file
  if (getDownloads()[options.url]) return;

  let closeToaster: VoidFunction | null = null;

  const startDownload = () => {
    const cancel = download({
      ...options,
      onStart: (total) => {
        setDownload(options.url, {
          baseName: options.baseName,
          total,
          loaded: 0,
          startTime: Date.now(),
          cancel,
          hasError: false,
        });
        if (!closeToaster) {
          closeToaster = addToaster({
            duration: 'infinite',
            closable: false,
            message: (
              <DownloadToaster
                url={options.url}
                retry={startDownload}
                close={() => {
                  closeToaster?.();
                  removeDownload(options.url);
                }}
              />
            ),
          });
        }
      },
      onProgress: (loaded: number) => {
        setDownload(options.url, { loaded });
      },
      onError: () => {
        setDownload(options.url, {
          hasError: true,
        });
      },
    });
  };

  startDownload();
};
