import {
  ToggleLoopCloseddNotificationDocument,
  ToggleSlackFeedbackCreatedNotificationDocument,
  ToggleSlackStatusCanceledNotificationDocument,
  ToggleSlackStatusCompletedNotificationDocument,
  ToggleSlackStatusNotStartedNotificationDocument,
  ToggleSlackStatusStartedNotificationDocument,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';
import { useSlackIntegration } from './useSlackIntegration';

export const useSlackNotificationToggle = () => {
  const {
    provider, integration,
  } = useSlackIntegration();
  const [toggleFeedbackMutation] = useSafeMutation(ToggleSlackFeedbackCreatedNotificationDocument);
  const [toggleStatusNotStartedMutation] = useSafeMutation(ToggleSlackStatusNotStartedNotificationDocument);
  const [toggleStatusStartedMutation] = useSafeMutation(ToggleSlackStatusStartedNotificationDocument);
  const [toggleStatusCompletedMutation] = useSafeMutation(ToggleSlackStatusCompletedNotificationDocument);
  const [toggleStatusCanceledMutation] = useSafeMutation(ToggleSlackStatusCanceledNotificationDocument);
  const [toggleLoopCloseMutation] = useSafeMutation(ToggleLoopCloseddNotificationDocument);

  const toggleFeedback = (enabled: boolean) => (
    provider?.id && integration?.id && toggleFeedbackMutation({
      optimisticResponse: {
        toggleSlackIntegrationPublicNotification: {
          id: provider.id,
          feedbackCreatedNotification: enabled,
        },
      },
      variables: {
        providerId: provider.id,
        notification: { feedbackCreated: enabled },
      },
    })
  );

  const toggleStatusNotStarted = (enabled: boolean) => (
    provider?.id && integration?.id && toggleStatusNotStartedMutation({
      optimisticResponse: {
        toggleSlackIntegrationPublicNotification: {
          id: provider.id,
          statusNotStartedNotification: enabled,
        },
      },
      variables: {
        providerId: provider.id,
        notification: { statusNotStarted: enabled },
      },
    })
  );

  const toggleStatusStarted = (enabled: boolean) => (
    provider?.id && integration?.id && toggleStatusStartedMutation({
      optimisticResponse: {
        toggleSlackIntegrationPublicNotification: {
          id: provider.id,
          statusStartedNotification: enabled,
        },
      },
      variables: {
        providerId: provider.id,
        notification: { statusStarted: enabled },
      },
    })
  );

  const toggleStatusCompleted = (enabled: boolean) => (
    provider?.id && integration?.id && toggleStatusCompletedMutation({
      optimisticResponse: {
        toggleSlackIntegrationPublicNotification: {
          id: provider.id,
          statusCompletedNotification: enabled,
        },
      },
      variables: {
        providerId: provider.id,
        notification: { statusCompleted: enabled },
      },
    })
  );

  const toggleStatusCanceled = (enabled: boolean) => (
    provider?.id && integration?.id && toggleStatusCanceledMutation({
      optimisticResponse: {
        toggleSlackIntegrationPublicNotification: {
          id: provider.id,
          statusCanceledNotification: enabled,
        },
      },
      variables: {
        providerId: provider.id,
        notification: { statusCanceled: enabled },
      },
    })
  );

  const toggleLoopClosed = (enabled: boolean) => (
    provider?.id && integration?.id && toggleLoopCloseMutation({
      optimisticResponse: {
        toggleSlackIntegrationPublicNotification: {
          id: provider.id,
          loopClosedNotification: enabled,
        },
      },
      variables: {
        providerId: provider.id,
        notification: { loopCloosed: enabled },
      },
    })
  );

  return {
    toggleFeedback,
    toggleStatusNotStarted,
    toggleStatusStarted,
    toggleStatusCompleted,
    toggleStatusCanceled,
    toggleLoopClosed,
  };
};
