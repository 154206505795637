import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { CornerDownRightIcon } from '@cycle-app/ui/icons';
import { FC, useEffect } from 'react';

import DocAction from 'src/components/DocAction/DocAction';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { Layer } from 'src/types/layers.types';

import { StyledDocHierarchy } from './DocChildren.styles';

interface Props {
  className?: string;
  doc: DocBaseFragment;
  context?: 'doc-item';
}

const DocChildren: FC<React.PropsWithChildren<Props>> = ({
  className,
  doc,
  context,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItemHoverId(null);
    }
  }, [isDropdownVisible]);

  const childrenCount = doc.childrenCount - doc.insightsCount;

  return (
    <DropdownLayer
      className={className}
      visible={isDropdownVisible}
      hide={hideDropdown}
      disableDocPreview={false}
      placement="bottom-start"
      exitAnimation={false}
      layer={Layer.DocItemDropdown}
      content={(
        <StyledDocHierarchy
          docId={doc.id}
          doctypeId={doc.doctype.id}
          onClickChildren={e => {
            if (e?.metaKey) return;
            hideDropdown();
          }}
        />
      )}
    >
      <DocAction
        forceFocus={isDropdownVisible}
        tooltipPlacement="top"
        label={childrenCount > 0 ? 'Children' : 'Add children'}
        buttonIcon={<CornerDownRightIcon size={17} />}
        counter={childrenCount}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown();
        }}
        context={context}
      />
    </DropdownLayer>
  );
};

export default DocChildren;
