import { Button, typo } from '@cycle-app/ui';
import { RightIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Caret = styled(RightIcon)`
  color: ${p => p.theme.colors.text.disabled};
  flex: none;
`;

export const ContentLabel = styled.div`
  color: ${p => p.theme.colors.text.secondary};
`;

export const ContentSelectContainer = styled.div`
  flex: none;
  width: 150px;
`;

export const StyledButton = styled(Button)`
  ${typo.body400}
  padding-left: 12px;
  padding-right: 12px;
  height: 36px;
`;
