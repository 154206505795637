import styled, { css } from 'styled-components';

import { Image } from '../ImageInput/ImageInput.styles';

export const UploadPreview = styled(Image)`
  border-radius: 2px;
  object-fit: contain;
`;

const overlayCss = css`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color .1s;
`;

// Used when there is no logo defined.
export const Overlay = styled.div`
  ${overlayCss}
  svg {
    transition: transform .1s, color .1s;
  }
`;

export const Preview = styled.img`
  display: block;
  max-width: 60%;
  object-fit: cover;
`;

export const UploadIconContainer = styled.div`
  align-items: center;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 100px;
  color: ${p => p.theme.colors.text.disabled};
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
  transition: background-color .1s;
`;

// Used when there is a logo defined.
export const OverlayHover = styled.div`
  ${overlayCss}
  backdrop-filter: blur(4px);
  background-color: ${p => (p.theme.isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')};
  opacity: 0;
  transition: opacity .1s;

  ${UploadIconContainer} {
    background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey400 : 'rgba(0, 0, 0, 0.2)')};
    color: #FFF;
  }
`;

export const UploadButton = styled.button`
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${p => (p.theme.isDark ? p.theme.colors.border.primary : p.theme.colors.border.hover)};
  cursor: pointer;
  display: flex;
  margin-top: 8px;
  min-height: 48px;
  overflow: hidden;
  padding: 14px;
  position: relative;
  width: 100%;
  justify-content: center;

  &:hover ${Overlay},
  &:focus-visible ${Overlay} {
    background-color: ${p => (p.theme.isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')};

    ${UploadIconContainer} {
      background-color: transparent;
    }

    svg {
      color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey400 : p.theme.baseColors.Grey700)};
      transform: scale(1.4);
    }
  }

  &:hover ${OverlayHover},
  &:focus-visible ${OverlayHover} {
    opacity: 1;
  }
`;
