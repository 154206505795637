import { SelectLine } from '@cycle-app/ui';
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';

import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useUpdateReleaseNote } from 'src/hooks/releases/useUpdateReleaseNote';

import { DraftTag, SelectPanel } from './ReleaseNoteCard.styles';

export const ReleaseNoteDraftDropdown = ({
  noteId, button, placement,
}: Pick<ToggleDropdownProps, 'button' | 'placement'> & {
  noteId: string;
}) => {
  return (
    <ToggleDropdown
      placement={placement}
      button={button}
      content={props => (
        <ReleaseNoteDraftPanel
          noteId={noteId}
          hide={props.hide}
        />
      )}
    />
  );
};

const ReleaseNoteDraftPanel = ({
  noteId, hide,
}: {
  noteId: string;
  hide: VoidFunction;
}) => {
  const { releaseNote } = useReleaseNote(noteId);
  const { updateReleaseNote } = useUpdateReleaseNote();
  if (!releaseNote) return null;
  return (
    <SelectPanel>
      <SelectLine
        label={<DraftTag>Draft</DraftTag>}
        onClick={async () => {
          hide();
          await nextFrame();
          if (!releaseNote.isPublished) return;
          await updateReleaseNote(releaseNote, { isPublished: false });
        }}
      />
      <SelectLine
        label={<DraftTag $isPublished>Ready</DraftTag>}
        onClick={async () => {
          hide();
          await nextFrame();
          if (releaseNote.isPublished) return;
          await updateReleaseNote(releaseNote, { isPublished: true });
        }}
      />
    </SelectPanel>
  );
};
