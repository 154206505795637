import { DocChildFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Tag, Tooltip, SelectOption } from '@cycle-app/ui';
import { UnlinkIcon, TrashIcon, ExpandIcon, LinkIcon } from '@cycle-app/ui/icons';
import { getDocFullUrl, getDocSlug } from '@cycle-app/utilities';
import { MouseEvent, memo, useCallback, FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { DocQuotes } from 'src/components/DocInsights';
import { DocSource } from 'src/components/DocSource/DocSource';
import { DocStatus } from 'src/components/DocStatus';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { Events, Methods, Sources } from 'src/constants/analytics.constants';
import { PageId, routing } from 'src/constants/routing.constant';
import { useLocation, useNavigateToDocFullOrPanel, useRouteMatch } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useProductBase } from 'src/hooks/api/useProduct';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { usePageId, useParentPage } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { Location } from 'src/types/routes.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { getDocKey, shouldShowSource } from 'src/utils/doc.util';
import { isCustom, getDocTypeName } from 'src/utils/docType.util';

import { Container, DocTitle, Side } from './DocHierarchyItem.styles';

interface Props {
  parentId?: string;
  doc: DocChildFragment;
  onDocRemove?: (doc: DocChildFragment) => void;
  refefresDeleteQueries?: boolean;
  hideDocCustomer?: boolean;
  isModal?: boolean;
  onClick?: (e?: MouseEvent) => void;
  showDocId?: boolean;
  showStatus?: boolean;
  showDoctype?: boolean;
  showQuotes?: boolean;
  isStatusEditable?: boolean;
  to?: Location;
  className?: string;
}

const DocHierarchyItem: FC<Props> = ({
  parentId,
  doc,
  onDocRemove,
  hideDocCustomer,
  onClick,
  showDocId = false,
  showStatus = false,
  showDoctype = false,
  showQuotes = false,
  isStatusEditable = false,
  to,
  className,
}) => {
  const docType = useGetDocType(doc.doctype.id);
  const navigateToDoc = useNavigateToDocFullOrPanel();
  const [modalDeleteVisible, {
    setFalseCallback: hideModalDelete,
    toggleCallback: toggleModalDelete,
  }] = useOptimizedBooleanState(false);

  const getUrl = useUrl();
  const product = useProductBase();

  const changeDocParent = useChangeDocParent();

  const docKey = getDocKey(product?.key, doc.publicId);
  const docName = isCustom(docType) ? docKey : getDocTypeName(docType).toLowerCase();
  const fullDocUrl = getDocFullUrl(getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) }));
  const docUrl = useRelativeDocUrl(getDocSlug(doc));
  const location = useLocation();
  const fromStarredBoard = location.state?.fromStarredBoard;

  const onTagDocIdClicked = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    copyToClipboard({
      text: fullDocUrl,
      notification: `Link to ${docKey} copied to clipboard!`,
    });
    trackAnalytics(Events.DocShared, {
      method: Methods.UI,
      source: Sources.DocPanel,
    });
  }, [docKey, fullDocUrl]);

  const options = useMemo(() => {
    const result: SelectOption[] = [];

    result.push({
      label: 'Open',
      value: 'open',
      icon: <ExpandIcon />,
      onSelect: () => {
        onClick?.();
        navigateToDoc(doc, {
          isDocRelative: true,
          fromStarredBoard,
        });
      },
    });

    result.push({
      label: 'Copy link',
      value: 'copyLink',
      icon: <LinkIcon />,
      onSelect: () => {
        copyToClipboard({
          text: fullDocUrl,
          notification: `Link to ${docName} copied to clipboard!`,
        });
      },
    });

    if (parentId && docType?.type !== DoctypeType.Insight) {
      result.push({
        label: 'Unlink',
        value: 'unlink',
        icon: <UnlinkIcon />,
        onSelect: () => changeDocParent({
          docId: doc.id,
          parentId: undefined,
        }),
      });
    }

    result.push({
      label: 'Delete',
      value: 'delete',
      icon: <TrashIcon />,
      variant: 'danger',
      onSelect: toggleModalDelete,
    });

    return result;
  }, [changeDocParent, doc, docName, docType?.type, fromStarredBoard, fullDocUrl, navigateToDoc, onClick, parentId, toggleModalDelete]);

  if (!docType) return null;

  return (
    <>
      <Link
        to={to || docUrl}
        onMouseEnter={() => {
          setDocItemHoverId(doc.id);
          if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: doc.id });
        }}
        onMouseLeave={() => setDocItemHoverId(null)}
        onClick={e => {
          e.preventDefault();
          navigateToDoc(doc, {
            isDocRelative: true,
            fromStarredBoard,
          }, e.metaKey);
          onClick?.(e);
        }}
      >
        <Container className={className}>
          {showDocId && isCustom(docType) && (
            <Tooltip
              placement="top"
              title="Feature ID"
              content="Copy link"
              withPortal
            >
              <Tag color="grey" onClick={onTagDocIdClicked}>
                {docKey}
              </Tag>
            </Tooltip>
          )}

          {showStatus && doc.status && (
            <DocStatus
              docId={doc.id}
              statusId={doc.status.id}
              docTypeId={doc.doctype.id}
              hideLabel
              isDisabled={!isStatusEditable}
            />
          )}

          {showDoctype && (
            <DocTypeIcon doctype={docType} size={12} />
          )}

          <DocTitle>
            {doc.title}
          </DocTitle>

          {shouldShowSource(doc, docType) && doc.source && <DocSource doctypeId={doc.doctype.id} docId={doc.id} source={doc.source} showName={false} />}

          {showQuotes && (
            <DocQuotes
              doc={doc}
              layer={Layer.DropdownModalZ3}
              context="doc-item"
              isReadOnly
            />
          )}

          {!hideDocCustomer && !!docType.customer && <DocCustomer doc={doc} isCompact />}

          <Side>
            <DocAssignee
              docId={doc.id}
              assignee={doc.assignee}
              layer={Layer.DropdownModalZ3}
              isRemovable={docType.type === DoctypeType.Custom}
              isDisabled={docType.type === DoctypeType.Insight && !!doc.docSource}
              docTypeName={docType.name}
              docTypeType={docType.type}
              context="doc-item"
            />
            <DotsMenuLayer
              layer={Layer.DropdownModalZ3}
              placement="bottom-end"
              options={options}
            />
          </Side>
        </Container>
      </Link>

      {modalDeleteVisible && (
        <DocDeleteWarningModal
          docId={doc.id}
          onConfirm={() => onDocRemove?.(doc)}
          onHide={hideModalDelete}
        />
      )}
    </>
  );
};

export default memo(DocHierarchyItem);

const useRelativeDocUrl = (docSlug: string) => {
  const currentPageId = usePageId();
  const parentPage = useParentPage();
  const getUrl = useUrl();
  const currentParams = useRouteMatch(routing[currentPageId])?.params;

  const pageId = (() => {
    if (currentPageId === PageId.DocFullPage) return PageId.DocFullPage;
    if (parentPage === 'home') return PageId.HomeDoc;
    if (parentPage === 'inbox') return PageId.InboxDoc;
    if (parentPage === 'settings') return PageId.DocFullPage;
    if (currentParams?.boardSlug) return PageId.Doc;
    // Safer to return the doc full page in case we missed condition.
    return PageId.DocFullPage;
  })();

  return getUrl(pageId, {
    docSlug,
    boardSlug: currentParams?.boardSlug,
  });
};
