// Represent the 'none' option
export const REMOVE_VALUE = 'REMOVE_VALUE';

// Represent each doctype button
export const CREATE_VALUE_PREFIX = 'CREATE_VALUE_PREFIX';

// Represent the `+X` doctype button
export const CREATE_VALUE_MORE = 'CREATE_VALUE_MORE';

// Represent the max displayed doc type buttons
export const DOC_TYPES_AMOUNT_LIMIT = 3;
