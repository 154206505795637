import { ChangelogBaseFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { Helmet } from 'react-helmet';
import { FormProvider } from 'react-hook-form';
import { Prompt } from 'react-router-dom';

import { ChangelogBuilderContent } from './ChangelogBuilderContent';
import { ChangelogBuilderSidebar } from './ChangelogBuilderSidebar';
import { Container } from './ChangelogBuilderSidebar.styles';
import { ChangelogBuilderTopBar } from './ChangelogBuilderTopBar';
import { useChangelogBuilderForm } from '../../hooks/releases/useChangelogBuilderForm';
import { ChangelogTitle } from '../PageTitle/PageTitle';

type Props = {
  changelog: ChangelogBaseFragment;
};

export const ChangelogBuilder = ({ changelog }: Props) => {
  const form = useChangelogBuilderForm({ changelog });
  return (
    <FormProvider {...form}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          // eslint-disable-next-line max-len
          href="https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Hind:wght@300;400;500;600;700&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Lora:ital,wght@0,400..700;1,400..700&family=Manrope:wght@200..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100..800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Prompt
        when={form.formState.isDirty}
        message="Are you sure you want to leave this page? You have unsaved changes that will be lost."
      />
      <ChangelogTitle />
      <div className="flex h-full bg-grey-100 dark:bg-black">
        <ChangelogBuilderSidebar />
        <div className="flex grow flex-col">
          <ChangelogBuilderTopBar />
          <ChangelogBuilderContent />
        </div>
      </div>
    </FormProvider>
  );
};

export const ChangelogBuilderLoader = () => {
  return (
    <div className="flex h-full bg-grey-100 dark:bg-black">
      <Container>
        <div className="px-4 pt-4">
          <Skeleton height={26} />
        </div>
      </Container>
      <div className="grow" />
    </div>
  );
};
