import { useMemo } from 'react';

import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useIsWelcomePage } from 'src/hooks/useIsWelcomePage';
import { getLayer } from 'src/reactives/layer.reactive';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutCommandbar } from 'src/types/shortcuts.types';

import { toggleCommandBar } from '../modals/useCommandBarModal';

export const useCommandbarShortcutListener = () => {
  const isWelcomePage = useIsWelcomePage();
  const callbacks = useMemo(() => ({
    [ShortcutCommandbar.ToggleCommandBar]: () => {
      if (getActiveProductTour()) return;
      toggleCommandBar();
    },
  }), []);

  useHotkeyListener({
    callbacks,
    shortcuts: Object.values(ShortcutCommandbar),
    enabled: () => !isWelcomePage,
    preventCallback: () => {
      const layers = getLayer();
      if (layers[Layer.Commandbar]) {
        return false;
      }
      return layers[Layer.Dropdown] || layers[Layer.Modal] || layers[Layer.DialogModal] || layers[Layer.Zoom];
    },
  });
};
