import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';
import { useGetLastRoadmapBoard } from 'src/reactives/lastView.reactive';

import { useRoadmapSection } from './useRoadmapSection';

export const useGetRoadmapDefaultUrl = () => {
  const { getUrl } = useNavigate();
  const {
    boardSlug, boardId,
  } = useGetLastRoadmapBoard();
  const { boards } = useRoadmapSection();
  return () => (boards.find(board => board.id === boardId)
    ? getUrl(PageId.RoadmapView, { boardSlug })
    : getUrl(PageId.Roadmap));
};
