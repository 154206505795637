import { useApolloClient } from '@apollo/client';
import { UserNodeDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { extract } from 'src/types/graphql.types';

export const useGetUserFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((userId: string) => {
    const result = cache.readQuery({
      query: UserNodeDocument,
      variables: {
        userId,
      },
    });
    return extract('Me', result?.node) ?? extract('OtherUser', result?.node);
  }, [cache]);
};
