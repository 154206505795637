import { Tooltip } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { CustomerNameForm } from './CustomerNameForm';
import { EmptyButton, ProfileEditButton } from './Profile.styles';

type Props = {
  customerId: string;
};

export const CustomerName = ({ customerId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { customer } = useCustomer(customerId);

  if (isEditing) {
    return (
      <CustomerNameForm
        customerId={customerId}
        name={customer?.name}
        email={customer?.email}
        hide={() => setIsEditing(false)}
      />
    );
  }

  if (!customer?.name) {
    return (
      <EmptyButton onClick={() => setIsEditing(true)}>
        Add name…
      </EmptyButton>
    );
  }

  return (
    <>
      {customer.name}
      {!isDefaultCustomer(customer) && (
        <Tooltip
          content="Edit name"
          placement="top"
          withPortal
          withWrapper={false}
        >
          <ProfileEditButton
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(true);
            }}
          >
            <PenFilledIcon />
          </ProfileEditButton>
        </Tooltip>
      )}
    </>
  );
};
