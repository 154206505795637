import { useChangelog } from 'src/hooks/releases/useChangelog';

import { ChangelogBuilder, ChangelogBuilderLoader } from '../../ChangelogBuilder';
import { ErrorPage } from '../../ErrorPage';

export const ChangelogView = () => {
  const {
    changelog, isLoading,
  } = useChangelog();

  if (isLoading) return <ChangelogBuilderLoader />;

  if (!changelog) return <ErrorPage />;

  return <ChangelogBuilder changelog={changelog} />;
};
