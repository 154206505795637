import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
  NodeWithPos,
} from '@tiptap/core';

import {
  HIGHLIGHT_DATA_ID,
  HIGHLIGHT_DATA_TYPE,
  HIGHLIGHT_EXTENSION_NAME,
  HIGHLIGHT_TAG_NAME,
} from '../constants/editor.constants';

export type MarkType = 'insight' | 'comment';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlightMark: {
      cleanDraftHighlights: () => ReturnType;
      cleanPublishedHighlights: (attrs: {
        publishedIds: string[];
        type: MarkType;
        section?: 'open' | 'closed' | 'all';
      }) => ReturnType;
      setHighlightMark: (attrs: {
        id: string;
        type: MarkType;
        isResolved?: boolean;
      }) => ReturnType;
      publishHighlightMark: (attrs: {
        id: string;
        type: MarkType;
        isResolved?: boolean;
      }) => ReturnType;
      resolveHighlightMark: (attrs: {
        id: string;
        type: MarkType;
        isResolved?: boolean;
      }) => ReturnType;
      toggleHighlightMark: (attrs: { id: string }) => ReturnType;
      unsetHighlightMark: (attrs?: { ids: string[] }) => ReturnType;
    };
  }
}

const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

export const getHighlightMarkExtension = () => Mark.create({
  name: HIGHLIGHT_EXTENSION_NAME,

  inclusive: false,

  addOptions() {
    return {
      HTMLAttributes: {
        [HIGHLIGHT_DATA_ID]: true,
        [HIGHLIGHT_DATA_TYPE]: true,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute(HIGHLIGHT_DATA_ID),
        renderHTML: attributes => ({
          [HIGHLIGHT_DATA_ID]: attributes.id,
        }),
      },
      isDraft: {
        default: null,
      },
      type: {
        default: null,
        parseHTML: element => element.getAttribute(HIGHLIGHT_DATA_TYPE),
        renderHTML: attributes => ({
          [HIGHLIGHT_DATA_TYPE]: attributes.type,
        }),
      },
      isResolved: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: HIGHLIGHT_TAG_NAME,
        getAttrs: node => typeof node !== 'string' && node.getAttribute(HIGHLIGHT_DATA_ID) !== null && null,
      },
    ];
  },

  priority: 9999,

  renderHTML({ HTMLAttributes }) {
    return [HIGHLIGHT_TAG_NAME, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      cleanPublishedHighlights: (attrs) => ({
        tr, state,
      }) => {
        const { publishedIds } = attrs;
        const toRemove: { pos: number; node: NodeWithPos['node'] }[] = [];
        // Looks like `tr.doc` is `any` since https://github.com/ProseMirror/prosemirror-transform/commit/bfb756f05bbd4c1d31a9dfcd9e8f85a2171cdc61
        // but in their source code they use `tr.doc` everywhere.
        // It is also recommanded to use `tr.doc` instead of `state.doc`
        // Eg. https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/commands/focus.ts#L63
        (tr.doc as typeof state.doc).nodesBetween(tr.doc.resolve(0).pos, tr.doc.resolve(tr.doc.content.size).pos, (node, pos) => {
          if (
            node.marks.some(
              mark => mark.type.name === this.name &&
              ((attrs.type === 'insight' && (!mark.attrs.type || mark.attrs.type === 'insight')) ||
               (attrs.type === 'comment' && mark.attrs.type === 'comment')) &&
              ((attrs.section === 'closed' && mark.attrs?.isResolved) || (attrs.section === 'open' && !mark.attrs?.isResolved)) &&
              !publishedIds.includes(mark.attrs?.id),
            )
          ) {
            toRemove.push({
              pos,
              node,
            });
          }
        });
        toRemove.forEach(({
          pos, node,
        }) => tr.removeMark(pos, pos + node.nodeSize, node.marks.find(mark => mark.type.name === this.name)));
        return true;
      },
      cleanDraftHighlights: () => ({
        tr, state,
      }) => {
        const toRemove: { pos: number; textNode: NodeWithPos['node'] }[] = [];
        (tr.doc as typeof state.doc).nodesBetween(tr.doc.resolve(0).pos, tr.doc.resolve(tr.doc.content.size).pos, (node, pos) => {
          if (
            node.marks.find(mark => mark.type.name === this.name && mark.attrs?.isDraft === true)
          ) {
            toRemove.push({
              pos,
              textNode: node,
            });
          }
        });
        toRemove.forEach(({
          pos, textNode,
        }) => tr.removeMark(pos, pos + textNode.nodeSize, textNode.marks.find(mark => mark.type.name === this.name)));
        return true;
      },
      setHighlightMark: (attrs) => ({ commands }) => commands.setMark(this.name, {
        id: attrs.id,
        isDraft: true,
        type: attrs.type,
        isResolved: attrs?.isResolved,
      }),
      publishHighlightMark: (attrs) => ({ commands }) => commands.setMark(this.name, {
        id: attrs.id,
        isDraft: false,
        type: attrs.type,
        isResolved: attrs?.isResolved,
      }),
      // Used to set a comment mark as resolved
      resolveHighlightMark: (attrs) => ({
        tr, state,
      }) => {
        const doc = tr.doc as typeof state.doc;
        doc.nodesBetween(
          doc.resolve(0).pos,
          doc.resolve(doc.content.size).pos,
          (node, pos) => {
            const mark = node.marks.find(m => m.type.name === this.name && m.attrs?.id === attrs.id);
            if (!mark) return;
            tr.addMark(pos, pos + node.nodeSize, this.type.create({
              id: attrs.id,
              isDraft: false,
              type: attrs.type,
              isResolved: attrs.isResolved,
            }));
          },
        );
        return true;
      },
      toggleHighlightMark: (attrs) => ({ commands }) => {
        const isActive = this.editor.isActive(this.name);
        return commands.toggleMark(this.name, isActive ? undefined : attrs);
      },
      unsetHighlightMark: (attrs) => ({
        chain, tr, state,
      }) => {
        if (attrs?.ids) {
          const toRemove: { pos: number; textNode: NodeWithPos['node'] }[] = [];
          (tr.doc as typeof state.doc).nodesBetween(tr.doc.resolve(0).pos, tr.doc.resolve(tr.doc.content.size).pos, (node, pos) => {
            if (
              node.marks.find(mark => mark.type.name === this.name && attrs.ids.includes(mark.attrs?.id))
            ) {
              toRemove.push({
                pos,
                textNode: node,
              });
            }
          });
          toRemove.forEach(({
            pos, textNode,
          }) => tr.removeMark(pos, pos + textNode.nodeSize, textNode.marks.find(mark => mark.type.name === this.name)));
          return true;
        }
        return chain()
          .selectAll()
          .unsetMark(this.name)
          // Focus start helps to not keep the all content selected
          .setTextSelection(0)
          .run();
      },
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
      }),
    ];
  },
});
