import { InfoColorType } from '@cycle-app/utilities';

import { Color } from '../theme/baseColors';

export const getColorTypeByPercentage = (percentage: number): InfoColorType => {
  if (percentage >= 100) return 'danger';

  if (percentage >= 75) return 'warning';

  return 'default';
};

const ColorTypes: Record<Exclude<InfoColorType, 'default'>, string> = {
  danger: Color.Red600,
  warning: Color.Orange600,
};

export const getColorFromType = (type: Exclude<InfoColorType, 'default'>) => ColorTypes[type];
