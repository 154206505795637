import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMaybeMeV2 } from './api/useMe';

declare global {
  interface Window {
    intercomSettings: Record<string, string>;
    Intercom: (action: string, params?: Record<string, string | boolean>) => void;
  }
}

export const useIntercom = () => {
  const location = useLocation();
  const { me } = useMaybeMeV2();

  const setup = useRef(false);

  useEffect(() => {
    if (
      !setup.current &&
      import.meta.env.VITE_INTERCOM_KEY &&
      window.Intercom &&
      me?.email !== 'archiveduser@cycle.app'
    ) {
      window.intercomSettings = {
        app_id: import.meta.env.VITE_INTERCOM_KEY,
        alignment: 'right',
      };

      if (me) {
        window.Intercom('boot', {
          name: `${me.firstName} ${me.lastName}`,
          email: me.email,
        });
      }

      setup.current = true;
    }
  }, [me]);

  useEffect(() => {
    if (import.meta.env.VITE_INTERCOM_KEY && window.Intercom) {
      window.Intercom('update');
    }
  }, [location.pathname]);
};
