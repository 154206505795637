import { SlackIcon, IntercomIcon, EmailIcon, ZapierIcon, ChromeIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

const FeedbackIcons = styled.div`
  display: inline-flex;
  gap: 8px;
  vertical-align: middle;
  margin-left: 8px;

  svg {
    width: 14px;
  }
`;

export const features = [
  {
    id: 'feedback',
    name: (
      <div style={{ whiteSpace: 'nowrap' }}>
        <span>Capture feedback from</span>
        <FeedbackIcons>
          <SlackIcon />
          <IntercomIcon />
          <EmailIcon />
          <ZapierIcon />
          <ChromeIcon />
        </FeedbackIcons>
      </div>
    ),
    tooltip: (
      <>
        <p>
          Use Zapier to integrate with Tally, Typeform, Sprig, Productboard, Dovetail, Canny,
          Notion, Airtable, Google Sheet, specific webhooks, Gong, Modjo, Google Meet, Zoom, Grain & more.
        </p>
        <p>
          Coming soon: Front, Freshdesk & Make.
        </p>
      </>
    ),
  },
  {
    id: 'insight',
    name: 'Extract insights from feedback',
    tooltip: 'Insights are the relevant bits of text you extract from raw feedback & connect to your roadmap items.',
  },
  {
    id: 'roadmap',
    name: 'Advanced multi-level roadmaps',
    tooltip: 'Pick your favorite hierarchy & create multi-level roadmaps with up to 5 doc types.',
  },
  {
    id: 'release',
    name: 'Manage your releases',
    tooltip: 'Create releases, write release notes, and publish change log.',
  },
  {
    id: 'ai',
    name: 'Cycle AI: 50 monthly queries',
    tooltip: 'Use AI to automatically find insights in feedback and write PRDs.',
  },
  {
    id: 'public-api',
    name: 'Public API',
    tooltip: 'Query your Cycle docs (feedback, insights, roadmap items) through the Cycle API and create custom integrations & automations.',
  },
  {
    id: 'sources',
    name: 'Integrate with Notion, Linear, GitHub, Figma, Miro',
    tooltip: 'Accelerate cross-team collaboration with rich embeddings.',
  },
  {
    id: 'rt',
    name: 'Real-time collaboration',
    tooltip: 'Work together in real-time, @mention teammates, communicate through in-line comments.',
  },
  {
    id: 'collab',
    name: 'Unlimited free collaborators',
    tooltip: 'Invite sales, customer success, design & engineering teams for free.',
  },
  {
    id: 'transcripts',
    name: 'Unlimited transcripts',
    tooltip: 'Transcribe video & audio files up to 100 MB.',
  },
  {
    id: 'support',
    name: 'Unlimited optical character recognition',
    tooltip: 'Extract text from any PDF or image up to 20MB.',
  },
  {
    id: 'imports',
    name: 'Data imports & exports',
    tooltip: 'Import your product data (feedback, roadmap, backlog).',
  },
];
