import { TOURS } from 'src/constants/productTour.constant';
import { ProductToursState, TourStatus } from 'src/types/productTour.types';
import { make } from 'src/utils/reactives.util';

export const {
  getValue: getTours,
  setValue: setTours,
  hookValue: useTours,
  resetValue: resetTours,
} = make<ProductToursState>({
  defaultState: {
    active: undefined,
    tours: TOURS,
    status: TourStatus.OFF,
  },
});

/// /////////////////
// HOOKS
/// /////////////////
export const useActiveProductTour = () => {
  const {
    active, tours,
  } = useTours();

  return active?.name
    ? tours.find(t => t.name === active.name) || null
    : null;
};

/// /////////////////
// GETTERS
/// /////////////////
export const getActiveProductTour = () => {
  const {
    active, tours,
  } = getTours();

  return active?.name
    ? tours.find(t => t.name === active.name) || null
    : null;
};
