import { useDroppable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { FC } from 'react';

import { getSortableStyle } from 'src/utils/dnd.util';

import SidebarBoardSection, { Props as SectionProps } from './SidebarBoardSection';
import { DroppableArea } from './SidebarBoardSection.styles';

interface Props extends SectionProps {
  droppableEnabled: boolean;
  isOver?: boolean;
}

const SidebarBoardSectionSortable: FC<React.PropsWithChildren<Props>> = ({
  section,
  droppableEnabled,
  isOver,
  ...props
}) => {
  const {
    setDraggableNodeRef,
    setDroppableNodeRef,
    attributes,
    listeners,
    transform,
    transition,
  } = useSortable({
    id: section.id,
    data: { type: 'group' },
  });
  const style = getSortableStyle({
    transform,
    transition,
  });

  const { setNodeRef: setDroppableRef } = useDroppable({
    id: section.id,
    data: {
      groupId: section.id,
      type: 'group-droppable',
    },
  });

  return (
    <DroppableArea
      ref={setDroppableRef}
      isOver={droppableEnabled && isOver}
    >
      <SidebarBoardSection
        {...props}
        {...listeners}
        {...attributes}
        section={section}
        style={style}
        setDroppableNodeRef={setDroppableNodeRef}
        setDraggableNodeRef={setDraggableNodeRef}
      />
    </DroppableArea>
  );
};

export default SidebarBoardSectionSortable;
