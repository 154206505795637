import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 100%;
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0 0 80px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.div`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
`;

export const Subtitle = styled.div`
    ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Action = styled(Button).attrs({
  size: 'M',
  variant: 'primary',
})`
  padding: 4px 12px;
  border: none;
`;
