import { typo, ActionButton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { DocParentDropdown } from 'src/components/DocParentDropdown';
import { EditParentBtn } from 'src/components/DocParentDropdown/DocPanelParent.styles';

export const RightSide = styled.div`
  display: flex;
  justify-content: end;
  margin-left: 8px;
  gap: 16px;
`;

export const LeftActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledActionButton = styled(ActionButton)`
  padding: 5px;
  ${p => p.disabled && css`
    color: ${p.theme.colors.actionButton.colorDisabled};
  `}
`;

export const Separator = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  height: 16px;
  width: 1px;
  background-color: ${p => p.theme.colors.docPanel.header.breadCrumbSeparatorColor};
`;

export const BreadCrumb = styled.div`
  flex: 1;
  width: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: ${p => p.theme.colors.docPanel.header.breadCrumbSeparatorColor};

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > * {
      display: inline-flex;
      vertical-align: middle;
    }
  }

  ${mobile} {
    display: none;
  }
`;

export const StyledDocParentDropdown = styled(DocParentDropdown)`
  margin-right: 2px;
  ${typo.body400}

  &:hover {
    background-color: ${p => p.theme.colors.background.hover};

    ${EditParentBtn} {
      position: absolute;
      top: -16px;
      right: -10px;
      padding: 2px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const DocTitle = styled.div`
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
  
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
`;

export const RightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    padding: 5px;
  }
`;

export const Container = styled.div<{ $disabled: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  gap: 8px;
  padding: 12px;

  ${p => p.$disabled && css`
    ${BreadCrumb}, ${RightSide} {
      pointer-events: none;
      opacity: 0.5;
    }
  `}
`;
