// import { AddIcon } from '@cycle-app/ui/icons';
import { Editor } from '@tiptap/react';

import { ColumnTableMenu } from './ColumnTableMenu';
import { RowTableMenu } from './RowTableMenu';
// import { AddButton } from './TableMenu.styles';

export type TableMenuProps = {
  editor: Editor;
};

export const TableMenu = ({ editor }: TableMenuProps) => {
  return (
    <>
      <ColumnTableMenu position="top" editor={editor} />
      {/* <ColumnTableMenu position="bottom" editor={editor} /> */}
      <RowTableMenu position="left" editor={editor} />
      {/* <RowTableMenu position="right" editor={editor} /> */}
      {/* <AddColButton editor={editor} /> */}
    </>
  );
};

/**
 * To keep for table v2 iteration
 */
// const AddColButton = ({ editor }: TableMenuProps) => {
//   const getTable = () => {
//     const { $anchor } = editor.state.selection;
//     const dom = editor.view.domAtPos($anchor.start()) as { node: HTMLElement; offset: number };
//     return dom.node.closest('table');
//   };
//   const getDomRect = () => getTable()?.getBoundingClientRect() || new DOMRect(0, 0, 0, 0);

//   return (
//     <>
//       <BubbleMenu
//         tippyOptions={{
//           placement: 'right',
//           interactive: true,
//           getReferenceClientRect: getDomRect,
//           offset: [0, 0],
//         }}
//         shouldShow={() => editor.isActive('table')}
//         editor={editor}
//       >
//         <AddButton
//           onClick={() => editor.chain().addColumnAfter().focus().run()}
//           style={{
//             width: '24px',
//             height: getDomRect().height || '24px',
//             transform: 'translateX(2px)',
//           }}
//         >
//           <AddIcon size={12} />
//         </AddButton>
//       </BubbleMenu>
//       <BubbleMenu
//         tippyOptions={{
//           placement: 'bottom',
//           interactive: true,
//           getReferenceClientRect: getDomRect,
//           offset: [0, 0],
//         }}
//         shouldShow={() => editor.isActive('table')}
//         editor={editor}
//       >
//         <AddButton
//           onClick={() => editor.chain().addRowAfter().focus().run()}
//           style={{
//             width: getDomRect().width || '24px',
//             height: '24px',
//             transform: 'translateY(2px)',
//           }}
//         >
//           <AddIcon size={12} />
//         </AddButton>
//       </BubbleMenu>
//     </>
//   );
// };
