import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

export const getTableExtension = () => [
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableCell.extend({
    content: '(paragraph|bulletList|orderedList)+',
  }),
  TableHeader.extend({
    content: 'paragraph+',
  }),
];
