import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { body500, caption500 } from '../../../theme/typo';

export type SizeTextButton = 10 | 12 | 14;

export interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: SizeTextButton;
  noPointerEvents?: boolean;
}

export const TextButtonCss = css<TextButtonProps>`
  --bg: ${p => p.theme.colors.background.transparent};
  --bgHover: ${p => p.theme.colors.background.hover};
  --bgActive: ${p => p.theme.colors.background.hover};
  --color: ${p => p.theme.colors.text.secondary};
  --colorHover: ${p => p.theme.colors.text.primary};

  ${body500};

  background-color: var(--bg);
  color: var(--color);
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 4px;

  width: 100%;

  padding: 2px 16px;
  border-radius: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover, &:focus, &.force-focus {
    background-color: var(--bgHover);
    color: var(--colorHover);
  }

  &:active {
    background-color: var(--bgActive);
    color: var(--colorHover);
  }

  ${p => p.disabled && css`
    /* @todo to update when design is ready */
    pointer-events: none;
    opacity: 0.5;
  `};

  ${p => p.noPointerEvents && css`
    pointer-events: none;
  `}

  svg {
    width: 8px;
    height: 8px;
  }

  ${p => p.size === 10 && css`
    padding: 2px 4px;
    ${caption500};
  `};

  ${p => p.size === 12 && css`
    /* Size not defined by design system  */
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  `};
`;

export const Container = styled.button`${TextButtonCss}`;
