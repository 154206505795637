import { useQuery } from '@apollo/client';
import { BoardWithDraftConfigDocument, BoardWithDraftConfigFragment } from '@cycle-app/graphql-codegen';

export default function useDraftBoardConfig(boardId?: string | null) {
  return useQuery<{ node: BoardWithDraftConfigFragment }>(BoardWithDraftConfigDocument, {
    variables: {
      boardId,
    },
    skip: !boardId,
  });
}
