import { AiState, UpdateDocAiStateDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateDocAiState = () => {
  const [mutate] = useSafeMutation(UpdateDocAiStateDocument);

  const updateDocAiState = (docId: string, aiState: AiState) => mutate({
    variables: {
      docId,
      aiState,
    },
    optimisticResponse: {
      updateDocAiState: {
        id: docId,
        aiState,
      },
    },
  });

  return {
    updateDocAiState,
  };
};
