import { ActionButton, typo, ShyScrollbarCss } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { FOOTER_HEIGHT } from 'src/components/MobileFooter/MobileFooter.styles';
import { HEADER_PRIMARY_HEIGHT } from 'src/components/MobileHeader/MobileHeader.styles';

export const InboxContainer = styled.div<{ $variant?: 'primary' | 'secondary' }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  ${mobile} {
    padding-top: ${HEADER_PRIMARY_HEIGHT}px;
    padding-bottom: ${FOOTER_HEIGHT}px;
  }

  > header {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 16px 24px;
    height: 60px;
    z-index: 2;

    ${mobile} {
      display: none;
    }
  }

  > header > div:first-child {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  > header > div:last-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  ${mobile} {
    header {
      padding: 16px;
    }
  }

  ${p => p.$variant === 'primary' && `
    background: ${p.theme.colors.background.primary};
  `};
`;

export const ViewContainer = styled.div<{ $isScrollable?: boolean }>`
  ${p => p.$isScrollable && ShyScrollbarCss}
  flex: 1;
`;

export const Title = styled.h1`
  ${typo.headerLight};
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Action = styled(ActionButton)`
  --colorHover: ${p => p.theme.colors.text.secondary};
  margin-top: 1px;
`;
