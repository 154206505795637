import { UpdateReleaseDocument, UpdateReleaseMutationVariables } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateRelease = () => {
  const [mutate, { loading }] = useSafeMutation(UpdateReleaseDocument, {
    errorPolicy: 'all',
  });

  const updateRelease = (variables: UpdateReleaseMutationVariables) => mutate({
    variables,
  });

  return {
    updateRelease,
    isUpdatingRelease: loading,
  };
};
