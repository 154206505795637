import { CollapseSidebarIcon } from '@cycle-app/ui/icons';

import { useDoc } from 'src/hooks';
import { useDocId } from 'src/hooks/usePathParams';
import { useGetDocPanel, setDocPanel, closePanel, getDocPanel, Content } from 'src/reactives/docRightPanel.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getInsightChildren, isFeedback } from 'src/utils/docType.util';

import { StyledActionButton } from './DocPanelHeader.styles';

export const ToggleRightPanel = () => {
  const docId = useDocId();
  const { doc: docBase } = useDoc(docId);
  const doctype = useGetDocType(docBase?.doctype.id);
  const docPanel = useGetDocPanel();

  const openPanel = () => {
    const { content } = getDocPanel();
    const showInsights = isFeedback(doctype) || getInsightChildren(doctype);
    const newContent = !showInsights && content === Content.Insights ? Content.Comments : content;
    setDocPanel({
      isExpanded: true,
      content: newContent ?? Content.Comments,
    });
  };

  return (
    <StyledActionButton
      disabled={!doctype}
      onClick={() => {
        if (getDocPanel().isExpanded) {
          closePanel();
        } else {
          openPanel();
        }
      }}
    >
      <CollapseSidebarIcon size={18} direction={docPanel.isExpanded ? 'top' : 'bottom'} />
    </StyledActionButton>
  );
};
