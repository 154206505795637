import { ComponentPropsWithRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { setLayer } from 'src/reactives/layer.reactive';
import { getZoom, setZoom } from 'src/reactives/zoom.reactve';
import { Layer } from 'src/types/layers.types';

type Props = {
  onZoomOut?: VoidFunction;
};

export const useZoomableProps = ({ onZoomOut }: Props = {}) => {
  const getZoomableProps = ({
    isDisabled = false,
    onMouseDown,
    onMouseUp,
    ...imageProps
  }: ComponentPropsWithRef<'img'> & { isDisabled?: boolean }): ComponentPropsWithRef<'img'> => ({
    alt: '',
    draggable: false,
    onMouseDown: (e) => {
      if (isDisabled) return;
      if (!imageProps.src) return;

      // only zoom on left click or single touch
      if (e.button !== 0) return;

      // prevent zooming while previous zoom is still animated
      if (getZoom().src) return;

      const img = e.currentTarget;
      setZoom({
        src: imageProps.src,
        getRect: () => img.getBoundingClientRect(),
        onZoomOut: onZoomOut ?? null,
      });
      setLayer(Layer.Zoom, true);

      scrollIntoView(e.currentTarget, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'nearest',
      });

      onMouseDown?.(e);
    },
    onMouseUp: (e) => {
      e.stopPropagation();
      onMouseUp?.(e);
    },
    ...imageProps,
  });

  return getZoomableProps;
};
