import { ProductBaseFragment } from '@cycle-app/graphql-codegen';

import { Label, Logo } from './Product.styles';

export type ProductProps = {
  product: ProductBaseFragment;
  filter?: string;
};

export const ProductLabel = ({ product }: ProductProps) => {
  return (
    <Label>
      {product.logo?.url && <Logo src={product.logo.url} alt="" />}
      <span>{product.name}</span>
    </Label>
  );
};
