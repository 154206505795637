import { useCallback, useState } from 'react';

type HookResult = [boolean, {
  setTrueCallback: VoidFunction;
  setFalseCallback: VoidFunction;
  setValueCallback: (newValue: boolean) => void;
  toggleCallback: VoidFunction;
}];
export default function useOptimizedBooleanState(initialValue: boolean): HookResult {
  const [currentState, setCurrentState] = useState(initialValue);

  const setTrueCallback = useCallback(() => setCurrentState(true), []);
  const setFalseCallback = useCallback(() => setCurrentState(false), []);
  const toggleCallback = useCallback(() => setCurrentState(state => !state), []);

  return [currentState, {
    setTrueCallback,
    setFalseCallback,
    setValueCallback: setCurrentState,
    toggleCallback,
  }];
}
