import { Spinner, ShyScrollbar, NavigationItem } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Container = styled(ShyScrollbar)`
  margin: 3px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  padding: 13px;
`;

export const StyledSpinner = styled(Spinner)`
  text-align: center;
  width: 100%;
`;

export const CategoryNavigationItem = styled(NavigationItem)<{ $collapsed: boolean }>`
  ${p => p.$collapsed && css`
    svg {
      transform: rotate(-90deg);
    }
  `};
`;

export const TemplateNavigationItem = styled(NavigationItem)`
  ${p => !p.isActive && css`
    &:hover {
      background-color: ${p.theme.colors.background.hover};
      color: ${p.theme.colors.text.primary};
    }
  `}
`;
