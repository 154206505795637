import { Input } from '@cycle-app/ui';
import { FC } from 'react';
import { useForm, FieldErrors } from 'react-hook-form';

import { Form, Submit, Inputs } from './NewPasswordForm.styles';
import { validatePassword } from '../../utils/password';


interface Props {
  className?: string;
  submitLabel: string;
  onSubmit: (password: string) => void;
  isLoading: boolean;
  showSubmit?: boolean;
  Wrapper?: FC<React.PropsWithChildren<unknown>>;
}

interface FormData {
  password: string;
  passwordConfirm: string;
}

const NewPasswordForm: FC<Props> = ({
  className,
  submitLabel,
  isLoading,
  onSubmit,
  showSubmit = true,
  Wrapper,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  const newPassword = watch('password');

  return (
    <Form
      className={className}
      onSubmit={handleSubmit(onFormSubmit, onError)}
    >
      {Wrapper ? <Wrapper>{renderForm()}</Wrapper> : renderForm()}
    </Form>
  );

  function renderForm() {
    return (
      <>
        <Inputs>
          <Input
            id="password"
            type="password"
            label="Password"
            autoComplete="off"
            placeholder="At least 10 char."
            {...register('password', {
              validate: (value) => {
                const { error } = validatePassword(value);
                return error;
              },
            })}
            error={errors.password?.message}
          />
          <Input
            id="password-confirm"
            type="password"
            label="Password (confirmation)"
            autoComplete="off"
            placeholder="At least 10 char."
            {...register('passwordConfirm', {
              required: true,
              minLength: 8,
              validate: (value) => {
                if (value !== newPassword) return 'The confirmation does not match';
                return undefined;
              },
            })}
            error={errors.passwordConfirm?.message}
          />
        </Inputs>

        {showSubmit && (
          <Submit
            type="submit"
            size="M"
            isLoading={isLoading}
          >
            {submitLabel}
          </Submit>
        )}
      </>
    );
  }

  function onFormSubmit({
    password,
    passwordConfirm,
  }: FormData) {
    if (passwordConfirm !== password) {
      setError('passwordConfirm', { message: 'The passwords does not match' });
    } else {
      onSubmit(password);
    }
  }

  function onError({
    password,
    passwordConfirm,
  }: FieldErrors<FormData>) {
    if (password?.type === 'required') {
      setError('password', { message: 'Please provide your password.' });
    }
    if (password?.type === 'minLength') {
      setError('password', { message: 'Please set a password longer than 7 characters.' });
    }
    if (passwordConfirm?.type === 'required') {
      setError('passwordConfirm', { message: 'Please provide your password.' });
    }
    if (passwordConfirm?.type === 'minLength') {
      setError('passwordConfirm', { message: 'Please set a password longer than 7 characters.' });
    }
  }
};

export default NewPasswordForm;
