import { getFigmaOrMiroEmbed } from '@cycle-app/utilities';
import { Editor, Range } from '@tiptap/react';

import { EditorContextValue } from 'src/contexts/editorContext';

const SUPPORTED_EMBED_DOMAINS = [
  'arcade.software',
  'claap.io',
  'codepen.io',
  'descript.com',
  'docs.google.com',
  'figma.com',
  'framer.com',
  'github.com',
  'grain.com',
  'graphy.app',
  'invisionapp.com',
  'loom.com',
  'miro.com',
  'pitch.com',
  'share.goabstract.com',
  'tella.tv',
  'twitter.com',
  'whimsical.com',
  'youtube.com',
];
export const isEmbedSupported = (url: string) => SUPPORTED_EMBED_DOMAINS.some(domain => url.includes(domain));

interface IframelyResult {
  url: string;
  html: string;
}
const getIframelyHtml = async (url: string): Promise<IframelyResult> => {
  const apiKey = import.meta.env.VITE_IFRAMELY_API_KEY;
  const encodedUrl = encodeURIComponent(url);
  const fetchApi = await fetch(`//iframe.ly/api/oembed?url=${encodedUrl}&api_key=${apiKey}`);
  return fetchApi.json();
};

interface AddEmbedParams extends Pick<EditorContextValue, 'onError'> {
  editor: Editor;
  url: string;
  range?: Range;
  onSuccess?: VoidFunction;
}
type AddEmbed = (p: AddEmbedParams) => Promise<void>;
export const addEmbed: AddEmbed = async ({
  editor,
  url,
  onError,
  range,
  onSuccess,
}): Promise<void> => {
  if (!isEmbedSupported(url)) {
    onError?.('This url is not supported');
    return;
  }

  const iframleyContent = await getIframelyHtml(url);
  const {
    isEmbeddable: isEmbeddableFigmaOrMiro, url: urlFigmaOrMiroToEmbed,
  } = getFigmaOrMiroEmbed(url);

  if (!iframleyContent.html && !isEmbeddableFigmaOrMiro) {
    onError?.("Oops, looks like we can't embed this. Sorry, we'll look into it!");
    return;
  }

  onSuccess?.();

  const isEmbeddableByIframely = !!iframleyContent.html;

  // eslint-disable-next-line no-nested-ternary
  const content = isEmbeddableByIframely
    ? iframleyContent
    : urlFigmaOrMiroToEmbed
      ? {
        url: urlFigmaOrMiroToEmbed,
        html: '',
      }
      : undefined;

  if (!content) return;

  editor
    .chain()
    .focus()
    .deleteRange({
      from: range?.from ?? 0,
      to: range?.to ?? 0,
    })
    .setIframely({
      url: content.url,
      savedHtml: content.html,
    })
    .createParagraphNear()
    .focus()
    .run();

  iframely?.load();
};
