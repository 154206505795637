import {
  AddGithubIssueAssigneesDocument,
  AddGithubIssueAssigneesMutationVariables,
  RemoveGithubIssueAssigneesDocument,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

interface ChangeParams extends AddGithubIssueAssigneesMutationVariables {
  action: 'add' | 'remove';
}

export const useChangeAssigneesGithubIssue = () => {
  const [addMutation, { loading: isAddLoading }] = useSafeMutation(AddGithubIssueAssigneesDocument);
  const [removeMutation, { loading: isRemoveLoading }] = useSafeMutation(RemoveGithubIssueAssigneesDocument);

  const change = ({
    action, providerId, publicId, repo, assignees,
  }: ChangeParams) => {
    if (!providerId || !publicId || !repo || !assignees) throw Error('Data are missing');

    if (action === 'add') {
      return addMutation({
        variables: {
          providerId,
          assignees,
          publicId,
          repo,
        },
      });
    }

    return removeMutation({
      variables: {
        providerId,
        assignees,
        publicId,
        repo,
      },
    });
  };

  return {
    change,
    isLoading: isAddLoading || isRemoveLoading,
  };
};
