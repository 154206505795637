import styled from 'styled-components';

import { Input } from '../Input/Input';

export const Container = styled.div`
  border: none;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(6, 32px);
  margin: 0;
  padding: 0;
`;

export const StyledInput = styled(Input)`
  input {
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
  }
`;
