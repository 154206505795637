import { useQuery } from '@apollo/client';
import { GroupNodeDocument, GroupNoFilteredNodeDocument } from '@cycle-app/graphql-codegen';
import { useAsyncCallback } from 'react-async-hook';

import { defaultGroupPagination, defaultPagination } from 'src/utils/pagination.util';

export const useMoreDocsInGroup = (groupId: string) => {
  const { fetchMore } = useQuery(GroupNodeDocument, {
    skip: true, // Never trigger the query, cache already available with useBoardGroups
    variables: {
      ...defaultGroupPagination,
      groupId,
    },
  });

  const fetchMoreGroup = async (cursorGroup: string) => {
    await fetchMore({
      variables: {
        ...defaultGroupPagination,
        groupId,
        cursorGroup,
      },
    });
  };

  const {
    loading,
    execute: moreDocs,
  } = useAsyncCallback(fetchMoreGroup);

  return {
    moreDocs,
    loading,
  };
};

export const useMoreDocsInGroupNoFiltered = (groupId: string) => {
  const { fetchMore } = useQuery(GroupNoFilteredNodeDocument, {
    skip: true, // Never trigger the query, cache already available with useBoardGroups
    variables: {
      ...defaultPagination,
      groupId,
    },
  });

  const fetchMoreGroup = async (cursor: string) => {
    if (!groupId) return;
    await fetchMore({
      variables: {
        ...defaultPagination,
        groupId,
        cursor,
      },
    });
  };

  const {
    loading,
    execute: moreDocs,
  } = useAsyncCallback(fetchMoreGroup);

  return {
    moreDocs,
    loading,
  };
};
