import { ActionButton, Info, Emoji } from '@cycle-app/ui';
import { CloseIcon, InfoIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { FC, useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { DocMentionCreationForm } from 'src/components/DocMentionCreationForm/DocMentionCreationForm';
import { useEditorContext } from 'src/contexts/editorContext';
import { useLastUsedDocType } from 'src/hooks/api/queries/docTypes/useLastUsedDocType';
import { useAttributesFromDoc } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromDoc';
import { useDocsCreate } from 'src/hooks/doc/useDocsCreate';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { CreateDocMentionFormValues, CreateDocMentionFormCustomField } from 'src/types/doc.types';
import { Layer } from 'src/types/layers.types';
import { getCustomAttributesFormValueValue } from 'src/utils/attributes.util';
import { insertDocMentions } from 'src/utils/editor/editor.utils';

import { StyledPortalModal, HeaderModal, Title } from './DocMentionCreationModal.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DocMentionCreationModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
}) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const doc = useEditorContext(ctx => ctx.doc);
  const docAttributes = useAttributesFromDoc(doc);
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const docType = useLastUsedDocType();
  const {
    createDocs, isLoading,
  } = useDocsCreate();

  const customFields = useMemo(() => {
    if (!isOpen) return [];

    return docType?.attributeDefinitions?.edges.map(attributeField => {
      const inheritAttributeData = docAttributes.find(docAttribute => docAttribute.attributeDefinitionId === attributeField.node.id);
      const customFieldData: CreateDocMentionFormCustomField = {
        id: attributeField.node.id,
        type: attributeField.node.__typename,
        name: attributeField.node.name,
        inheritAttributeValueFromDocContext: inheritAttributeData
          ? getCustomAttributesFormValueValue(inheritAttributeData.value, attributeField.node)
          : undefined,
      };

      if (
        attributeField.node.__typename === 'AttributeMultiSelectDefinition' ||
        attributeField.node.__typename === 'AttributeSingleSelectDefinition'
      ) {
        customFieldData.options = attributeField.node.values.edges;
      }

      return customFieldData;
    }) || [];
  }, [docAttributes, docType?.attributeDefinitions?.edges, isOpen]);

  if (!docType) return null;

  const {
    selectionValues, selectionType,
  } = getDocTitlesFromSelectedText(editor.state);

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledPortalModal layer={Layer.DropdownBoardConfig} hide={onClose}>
          <HeaderModal>
            <Title>
              Create new
              <Emoji emoji={docType.emoji} />
              {docType.name}
            </Title>
            <ActionButton onClick={onClose}>
              <CloseIcon />
            </ActionButton>
          </HeaderModal>
          {selectionValues.length > 1 && (
            <Info icon={<InfoIcon />}>
              {`You will create ${selectionValues.length} docs`}
            </Info>
          )}
          <DocMentionCreationForm
            docType={docType}
            onSubmit={onFormSubmit}
            isLoading={isLoading}
            titles={selectionValues}
            customFields={customFields}
            hiddenFields={['description']}
            onCancel={onClose}
          />
        </StyledPortalModal>
      )}
    </AnimatePresence>
  );

  async function onFormSubmit(values: CreateDocMentionFormValues) {
    if (!docType || !selectionType || isLoading) return;

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      assignee, parentDocId, title,
      ...customFieldValues
    } = values;

    const attributes = Object.entries(customFieldValues).map(([attributeDefinitionId, value]) => {
      const customField = customFields.find(c => c.id === attributeDefinitionId);

      switch (customField?.type) {
        case 'AttributeDateDefinition':
          return {
            attributeDefinitionId,
            value: { date: value },
          };
        case 'AttributeEmailDefinition':
          return {
            attributeDefinitionId,
            value: { email: value },
          };
        case 'AttributeNumberDefinition':
          return {
            attributeDefinitionId,
            value: { number: value },
          };
        case 'AttributePhoneDefinition':
          return {
            attributeDefinitionId,
            value: { phone: value },
          };
        case 'AttributeSingleSelectDefinition':
          return {
            attributeDefinitionId,
            value: { select: value?.value },
          };
        case 'AttributeTextDefinition':
          return {
            attributeDefinitionId,
            value: { text: value },
          };
        case 'AttributeUrlDefinition':
          return {
            attributeDefinitionId,
            value: { url: value },
          };
        case 'AttributeCheckboxDefinition':
          return {
            attributeDefinitionId,
            value: { checkbox: value || false },
          };
        case 'AttributeMultiSelectDefinition':
        default:
          return null;
      }
    }).filter(isPresent);

    const docDoctype = getDocType(doc?.doctype.id);

    const docIds = await createDocs({
      titles: selectionValues.length === 1 ? [values.title || ''] : selectionValues,
      assigneeId: values.assignee?.id || undefined,
      parentDocId: values.parentDocId,
      docTypeId: docType.id,
      customerId: (docDoctype?.customer && doc?.customer?.id) || undefined,
      parentId: values.parentDocId,
      sourceId: doc?.source?.id || undefined,
      attributes,
    });

    insertDocMentions({
      editor,
      docIds,
      selectionType,
    });
    onClose();
  }
};
