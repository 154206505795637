import { LucideProps, LucideIcon } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
import React, { lazy, Suspense, LazyExoticComponent, forwardRef } from 'react';

import { Fallback } from './Icon.styles';
import type { IconName } from '../../types/icon.types';

interface IconProps extends Omit<LucideProps, 'ref'> {
  name: IconName;
}

export const IconNames = Object.keys(dynamicIconImports) as IconName[];

type LazyIconMap = Record<IconName, LazyExoticComponent<LucideIcon>>;

const lazyIconMap = Object.entries(dynamicIconImports).reduce<LazyIconMap>((previous, current) => {
  return {
    ...previous,
    [current[0] as IconName]: lazy(current[1]),
  };
}, {} as LazyIconMap);

export const Icon = forwardRef<SVGSVGElement, IconProps>(({
  name,
  strokeWidth = 2,
  ...props
}, ref) => {
  const LucideIconComponent = lazyIconMap[name];

  return (
    <Suspense fallback={<Fallback />}>
      <LucideIconComponent strokeWidth={strokeWidth} {...props} ref={ref} />
    </Suspense>
  );
});
