import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useOpenBoardConfig } from 'src/hooks/boards/useOpenBoardConfig';

import { StyledButton } from './ViewHeader.styles';
import { setBoardConfigAnalyticsOpen } from '../../reactives/boardConfigAnalytics.reactive';

export const ViewSettings = () => {
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const notFound = useBoardConfig(ctx => ctx.notFound);
  const isAnalytics = useBoardConfig(ctx => ctx.isAnalytics);
  const openBoardConfig = useOpenBoardConfig();
  if (!boardId || notFound) return null;
  return (
    <StyledButton
      onClick={async () => {
        if (isAnalytics) {
          setBoardConfigAnalyticsOpen();
        } else {
          await openBoardConfig(boardId);
        }
      }}
    >
      Settings
    </StyledButton>
  );
};
