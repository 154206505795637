import { make } from 'src/utils/reactives.util';

type GetRect = () => DOMRect;

export const {
  getValue: getZoom,
  setValue: setZoom,
  hookValue: useZoom,
  resetValue: resetZoom,
} = make<{
  src: string | null;
  getRect: GetRect | null;
  isZoomed: boolean;
  onZoomOut: VoidFunction | null;
}>({
  defaultState: {
    src: null,
    getRect: null,
    isZoomed: false,
    onZoomOut: null,
  },
});

export const toggleZoom = () => setZoom({ isZoomed: !getZoom().isZoomed });
