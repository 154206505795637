import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const StatusButton = styled.div<{ $isDisabled: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
  ${p => p.$isDisabled && css`
    cursor: default;
  `}
`;

const Bullet = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const DraftBullet = styled(Bullet)`
  background-color: ${p => p.theme.colors.releases.tag.colorDraft};
`;

export const ReadyBullet = styled(Bullet)`
  background-color: ${p => p.theme.colors.releases.tag.colorPublished};
`;

export const StatusBullet = styled(Bullet)<{ $publicStatus: ReleasePublicStatus }>`
  background-color: ${p => {
    if (p.$publicStatus === ReleasePublicStatus.Editing) return p.theme.colors.releases.tag.colorDraft;
    if (p.$publicStatus === ReleasePublicStatus.Published) return p.theme.colors.releases.tag.colorPublished;
    return p.theme.colors.actionButton.bgActive;
  }};
`;

export const PreviewModal = styled(PortalModal)<{ $alignLeft: boolean }>`
  position: absolute;
  top: 64px;
  right: 24px;
  max-height: inherit;
  background: ${p => p.theme.colors.docPanel.bg};
  padding: 0;

  ${p => p.$alignLeft && css`
    top: 12px;
    right: calc(50vw + 16px);
  `}
`;

export const PreviewContainer = styled.div`
  min-height: 147px;
  max-height: calc(100vh - 147px);
  width: 567px;
  max-width: 40vw;
  & .ProseMirror {
    padding: 20px !important;
  }
`;

export const PreviewHeader = styled.div`
  padding: 20px 20px 0 20px;
`;
