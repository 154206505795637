import { formatDuration, intervalToDuration } from 'date-fns';
import { useEffect, useState, ReactNode } from 'react';

import { Container, Title, TextSecondary } from './TrialPlan.styles';

const ONE_MINUTE = 1_000 * 60;

type TrialPlanProps = {
  endOfTrial?: string | null;
  title?: ReactNode;
  upgradeLink?: ReactNode;
};

const useTrialCountdown = (endOfTrial: string) => {
  const [durationLeft, setDurationLeft] = useState(intervalToDuration({
    start: new Date(endOfTrial),
    end: new Date(),
  }));

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (!durationLeft.hours) {
      interval = setInterval(() => {
        const newDuration = intervalToDuration({
          start: new Date(endOfTrial),
          end: new Date(),
        });
        setDurationLeft(newDuration);
      }, ONE_MINUTE);
    }

    return () => {
      if (!interval) return;
      clearInterval(interval);
    };
  }, [durationLeft.hours, endOfTrial]);

  return durationLeft.days
    ? formatDuration(durationLeft, { format: ['days'] })
    : formatDuration(durationLeft, { format: ['hours', 'minutes'] });
};

export const TrialPlan = ({
  endOfTrial,
  title = 'Trial',
  upgradeLink,
}: TrialPlanProps) => {
  return (
    <Container>
      <header>
        <Title>
          {title}
        </Title>

        {upgradeLink}
      </header>

      {endOfTrial && (
        isTrialEnded(endOfTrial)
          ? <TextSecondary>Ended</TextSecondary>
          : <CountDown endOfTrial={endOfTrial} />
      )}
    </Container>
  );
};

// The trial is ended if we have passed the end date
const isTrialEnded = (endOfTrial: string) => new Date() > new Date(endOfTrial);

const CountDown = ({ endOfTrial }: { endOfTrial: string }) => {
  const countdown = useTrialCountdown(endOfTrial);
  return (
    <TextSecondary>
      {`Ends in ${countdown}`}
    </TextSecondary>
  );
};

const CountDownText = ({ endOfTrial }: { endOfTrial: string }) => {
  const countdown = useTrialCountdown(endOfTrial);
  return <>{`Your trial ends in ${countdown}`}</>;
};

export const TrialPlanText = ({ endOfTrial }: Pick<TrialPlanProps, 'endOfTrial'>) => {
  if (!endOfTrial) return null;
  return isTrialEnded(endOfTrial)
    ? <>Ended</>
    : <CountDownText endOfTrial={endOfTrial} />;
};
