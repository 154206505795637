import styled from 'styled-components';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { CommentEditor } from 'src/components/Editor';

export const StyledDropdown = styled(DropdownLayer)`
  width: 400px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const StyledCommentEditor = styled(CommentEditor)`
  padding: 8px;
  border-radius: 6px;
  background: ${p => p.theme.colors.background.secondary};
  .ProseMirror {
    padding: 0 !important;
  }
`;
