import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MakeIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 17 17" data-no-fill xmlns="https://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <mask id="c" width="5" height="13" x="12" y="2" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
          <path fill="#fff" d="M13.303 2.99h2.78c.212 0 .384.172.384.383v10.263a.384.384 0 0 1-.384.384h-2.78a.384.384 0 0 1-.384-.384V3.373c0-.211.173-.384.384-.384Z" />
        </mask>
        <g mask="url(#c)">
          <path fill="url(#d)" d="M16.044 1.837 9.34 4.31l4.005 10.862 6.704-2.472-4.005-10.862Z" />
        </g>
        <mask id="e" width="9" height="13" x="0" y="2" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
          <path fill="#fff" d="m5.149 3.125-4.608 9.17a.384.384 0 0 0 .17.514l2.484 1.248c.19.095.42.02.516-.17l4.608-9.17a.384.384 0 0 0-.171-.515L5.664 2.954a.382.382 0 0 0-.515.17Z" />
        </mask>
        <g mask="url(#e)">
          <path fill="url(#f)" d="M7.16.011-3.65 4.684 1.7 17.057l10.808-4.673L7.16.011Z" />
        </g>
        <mask id="g" width="6" height="13" x="7" y="2" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
          <path fill="#fff" d="m9.025 3.158-1.972 9.994a.384.384 0 0 0 .302.45l2.724.55a.384.384 0 0 0 .453-.301l1.972-9.995a.384.384 0 0 0-.301-.45l-2.725-.55a.384.384 0 0 0-.453.302Z" />
        </mask>
        <g mask="url(#g)">
          <path fill="url(#h)" d="M11.885 1.055 3.334 4.203 7.673 15.99l8.55-3.148-4.338-11.787Z" />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient id="d" x1="9.403" x2="16.905" y1="10.281" y2="7.515" gradientUnits="userSpaceOnUse">
        <stop stopColor="color(display-p3 .6902 .1765 .9137)" />
        <stop offset=".02" stopColor="color(display-p3 .6902 .1765 .9137)" />
        <stop offset=".8" stopColor="color(display-p3 .4275 0 .8)" />
        <stop offset="1" stopColor="color(display-p3 .4275 0 .8)" />
      </linearGradient>
      <linearGradient id="f" x1="2.875" x2="10.94" y1="9.099" y2="5.612" gradientUnits="userSpaceOnUse">
        <stop stopColor="color(display-p3 1 0 1)" />
        <stop offset=".17" stopColor="color(display-p3 .9137 .0471 .9765)" />
        <stop offset=".54" stopColor="color(display-p3 .7529 .1373 .9294)" />
        <stop offset=".73" stopColor="color(display-p3 .6902 .1765 .9137)" />
        <stop offset="1" stopColor="color(display-p3 .6902 .1765 .9137)" />
      </linearGradient>
      <linearGradient id="h" x1="1.076" x2="17.608" y1="11.81" y2="5.724" gradientUnits="userSpaceOnUse">
        <stop stopColor="color(display-p3 1 0 1)" />
        <stop offset=".02" stopColor="color(display-p3 1 0 1)" />
        <stop offset=".09" stopColor="color(display-p3 .9137 .0471 .9765)" />
        <stop offset=".23" stopColor="color(display-p3 .7529 .1373 .9294)" />
        <stop offset=".3" stopColor="color(display-p3 .6902 .1765 .9137)" />
        <stop offset=".42" stopColor="color(display-p3 .6431 .1686 .8902)" />
        <stop offset=".63" stopColor="color(display-p3 .5255 .149 .8353)" />
        <stop offset=".85" stopColor="color(display-p3 .3765 .1294 .7647)" />
        <stop offset="1" stopColor="color(display-p3 .3765 .1294 .7647)" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M.5.5h16v16H.5z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M.5 2.797h16v11.405H.5z" />
      </clipPath>
    </defs>
  </Svg>
);
