import { useEffect, useState } from 'react';

import { useProduct } from 'src/hooks';

export const useWithAutopilot = () => {
  const { product } = useProduct();

  const isAutopilotEnabled = product?.extractInsights || product?.linkToRoadmap || product?.markFeedbackAsProcessed;

  const [withAutopilot, setWithAutopilot] = useState(isAutopilotEnabled);

  useEffect(() => {
    setWithAutopilot(isAutopilotEnabled);
  }, [isAutopilotEnabled]);

  return {
    withAutopilot,
    setWithAutopilot,
    isAutopilotEnabled,
  };
};
