import { GITHUB_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { IntegrationType } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { Events, Methods } from 'src/constants/analytics.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useLinkGithubIssue } from 'src/hooks/api/mutations/integrations/useLinkGithubIssue';
import { useGithubIssueByUrl } from 'src/hooks/api/queries/integrations/useGithubIssueByUrl';
import { useDocId } from 'src/hooks/usePathParams';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { GITHUB_ISSUE_REGEX } from 'src/utils/embeddable.util';
import { parseGithubIssueLink } from 'src/utils/integrations.utils';

/**
 * Hook to embed a github issue in a tiptap editor
 * Side effects: undo the last action, insert the issue, and link the issue to the doc
 * @param url - the url of the issue
 * @param isEmbedding - whether the user is currently trying to embed the issue
 * @param onCompleted - callback to run when the issue is embedded
 * @returns an object containing the loading state and whether the url matches
 * */
export const useEmbedGithubIssue = (url: string, isEmbedding: boolean, onCompleted: () => boolean) => {
  const docId = useDocId();
  const editor = useEditorContext(ctx => ctx.editor);
  const linkMutation = useLinkGithubIssue();

  const match = GITHUB_ISSUE_REGEX.test(url);

  const {
    issue, isLoading, error,
  } = useGithubIssueByUrl(url, !match);

  useEffect(() => {
    if (!isEmbedding || !docId || !issue) return;

    queueMicrotask(() => {
      editor.commands.undo();
      editor
        .chain()
        .focus()
        .insertContent({
          type: GITHUB_EXTENSION_NAME,
          attrs: { id: issue.id },
        })
        .createParagraphNear()
        .command(onCompleted)
        .run();
    });

    const repo = parseGithubIssueLink(url).repository;

    if (repo) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
      linkMutation.link({
        variables: {
          docId,
          publicId: issue.publicId,
          repo,
        },
      });
    }

    trackAnalytics(Events.IntegrationIssueMentioned, {
      type: IntegrationType.Linear,
      method: Methods.Paste,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmbedding, docId, issue]);

  return {
    isLoading,
    match,
    error,
  };
};
