import { CornerDownRightIcon } from '@cycle-app/ui/icons';

import DocAction, { DocActionProps } from 'src/components/DocAction/DocAction';
import { useDocContext } from 'src/contexts/docContext';
import { toggleDocChildren, useDocChildrenExpanded } from 'src/reactives/docChildren.reactive';

export const DocChildrenList = (props: DocActionProps) => {
  const basePath = useDocContext(ctx => ctx.path);
  const count = useDocContext(ctx => ctx.childrenCount - ctx.insightsCount);
  const isExpanded = useDocChildrenExpanded(basePath);
  return (
    <DocAction
      context="doc-item"
      tooltipPlacement="top"
      label={count > 0 ? 'Children' : 'Add children'}
      buttonIcon={<CornerDownRightIcon size={17} />}
      counter={count}
      forceFocus={isExpanded}
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        toggleDocChildren(basePath);
      }}
      {...props}
    />
  );
};
