import {
  Input, Tag, typo, Avatar, Button, SelectPanel,
} from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
`;

export const Rows = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 60px;
  padding: 0 16px;
  border-radius: 6px;
  background-color: ${p => p.theme.colors.background.secondaryElevated};
`;

export const UserTag = styled(Tag)`
  ${typo.body500}
  align-items: baseline;
  border: none;
  display: inline-flex;
  gap: 4px;
  height: auto;
  max-width: 170px;
`;

export const UserAvatarTag = styled(Avatar)`
  align-self: center;
  height: 16px;
  padding: 0;
  width: 16px;
`;

export const Logo = styled.img`
  align-self: center;
  border-radius: 50%;
  height: 16px;
  object-fit: contain;
  width: 16px;
`;

export const WarningLine = styled.div`
  margin-top: 16px;
  > span {
    display: inline-flex;
    align-items: center;
    gap: 2px;
  }
`;

export const Label = styled.label`
  ${typo.body500};
`;

const upperFirst = css`
  display: inline-block;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledSelectPanel = styled(SelectPanel)`
  .highlight span {
    ${upperFirst}
  }
`;

export const SelectButton = styled(Button).attrs({ size: 'M' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  padding: 7px 8px;

  background-color: transparent;
  color: ${p => p.theme.colors.text.primary};
  font-weight: 400;

  &[disabled] {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const RoleLabel = styled.span`
  ${upperFirst}
`;

export const BillingInfoMessage = styled.p`
  color: ${p => p.theme.colors.text.secondary};

  svg {
    margin-right: 8px;
    vertical-align: middle;
  }
`;
