import { Control } from 'react-hook-form';

import { CustomPropertyFormField } from 'src/components/CustomPropertyFormField';
import { Layer } from 'src/types/layers.types';
import { CustomPropertyFormData } from 'src/types/property.types';

type CustomPropertiesFormFieldsProps<T> = {
  data: CustomPropertyFormData[];
  control: Control<T & any>;
  autoFocus?: boolean;
  layer?: Layer;
  disabled?: boolean;
};

/**
 * Issue with compiler
 * see here: https://www.ashbyhq.com/blog/engineering/generic-arrow-function-in-tsx
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const CustomPropertiesFormFields = <ControlTypes,>({
  data,
  control,
  autoFocus,
  layer,
  disabled,
}: CustomPropertiesFormFieldsProps<ControlTypes>) => {
  return (
    <>
      {data
        .filter(d => !d.disabled)
        .map((customFieldData) => (
          <CustomPropertyFormField
            key={customFieldData.id}
            control={control}
            data={customFieldData}
            autoFocus={autoFocus}
            layer={layer}
            disabled={disabled}
          />
        ))}
    </>
  );
};
