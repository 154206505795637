import styled, { css } from 'styled-components';

const size = css`
  display: inline-flex;
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
`;

export const Container = styled.span`
  ${size}
`;

export const Bullet = styled.span<{ $color: string }>`
  ${size}
  border-radius: 50%;
  background-color: ${(p) => p.$color};
`;
