import styled, { keyframes, css } from 'styled-components';

export const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const rotationCss = css`
  animation-name: ${rotateAnimation};
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const waveAnimation = keyframes`
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
`;

export const waveCss = css`
  animation: ${waveAnimation} 2.5s infinite;
  transform-origin: 70% 70%;
`;

const maskAnimation = keyframes`
  0% { --x1: -80%; --x2: -10%; --x3: 60% }
  100% { --x1: 40%; --x2: 110%; --x3: 180% }
`;

export const fadeAnimationCss = css`
  @property --x1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: -80%;
  }
  @property --x2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: -10%;
  }
  @property --x3 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 60%;
  }
  mask-image: linear-gradient(to right, rgb(0, 0, 0) var(--x1), rgba(255, 255, 255, 0.35) var(--x2), rgb(0, 0, 0) var(--x3));
  animation: ${maskAnimation} 2s ease-in-out infinite alternate;
`;

export const FadeAnimation = styled.div`
  ${fadeAnimationCss}
`;
