export const getOtherUserId = (meId: string) => {
  const decodedMe = b64ToUtf8(meId);
  const id = decodedMe.split('_')[1];

  return utf8ToB64(`OtherUser_${id}`);
};

function utf8ToB64(str: string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function b64ToUtf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}
