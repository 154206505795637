import { ToggleNotificationTriggerDocument, NotificationTrigger } from '@cycle-app/graphql-codegen/generated';
import { useCallback } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

export function useToggleNotificationTrigger() {
  const product = useProductBase();
  const [toggleTriggerMutation] = useSafeMutation(ToggleNotificationTriggerDocument);

  return useCallback((trigger: NotificationTrigger) => product && toggleTriggerMutation({
    variables: {
      productId: product.id,
      ...trigger,
    },
    optimisticResponse: {
      toggleNotificationTrigger: {
        __typename: 'NotificationTrigger',
        id: trigger.id,
        isActive: !trigger.isActive,
      },
    },
  }), [toggleTriggerMutation, product]);
}
