import styled from 'styled-components';

export const CreateLabel = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  gap: 10px;

  svg {
    height: 12px;
    width: 12px;
    margin-left: 2px;
  }
`;
