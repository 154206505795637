import { DocChat } from 'src/components/DocComments/DocChat';
import useDocComments from 'src/hooks/api/queries/useDocComments';

export const InlineChat = ({
  docId, threadId, firstCommentId, blockId, hide, onSend, isCreate = false, isResolved, className,
}: {
  docId: string;
  threadId?: string;
  firstCommentId?: string;
  blockId: string | null;
  hide: VoidFunction;
  onSend?: (isReply: boolean) => void;
  isCreate?: boolean;
  isResolved: boolean;
  className?: string;
}) => {
  const {
    comments, data, loading,
  } = useDocComments({
    docId,
    blockId,
    firstCommentId,
    resolved: isResolved,
  });

  return (
    <DocChat
      threadId={threadId}
      docId={docId}
      blockId={blockId}
      comments={comments}
      // eslint-disable-next-line no-nested-ternary
      commentsCount={data ? comments.length : (isCreate ? 0 : 1)}
      isCommentsLoading={!data && loading}
      maxHeight="500px"
      hide={hide}
      onSend={onSend}
      autoFocus
      showHeader={!isCreate}
      isResolved={isResolved}
      className={className}
    />
  );
};
