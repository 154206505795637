import { ViewType } from '@cycle-app/graphql-codegen';
import { Card } from '@cycle-app/ui';
import { memo, forwardRef } from 'react';

import { NestedChildren } from 'src/components/NestedChildren';
import { useDocContext } from 'src/contexts/docContext';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { isFeedback } from 'src/utils/docType.util';

import { DocItemHandle, DocItemProps } from './DocItem.types';
import { DocItemCard } from './DocItemCard';
import { DocItemContainer } from './DocItemContainer';
import { DocItemCoverZone } from './DocItemCoverZone';
import { DocItemInView } from './DocItemInView';
import { DocItemLink } from './DocItemLink';
import { DocItemSortable } from './DocItemSortable';
import { DocItemTouchContainer } from './DocItemTouchContainer';

const DocItem = forwardRef<DocItemHandle, DocItemProps>(({
  docIndex,
  isGroupInBoardView = true,
  isLazy = false,
  className,
  docUrl,
  groupId,
  asPlaceholder,
  itemId,
  isGroupDragging,
  isNewInbox,
  ...cardProps
}, ref) => {
  const docId = useDocContext(ctx => ctx.id);
  const docType = useDocContext(ctx => getDocType(ctx.doctype.id));
  const {
    updateDocCover, isUploadingCover,
  } = useDocCoverMutations(docId);

  const {
    viewType, isDragging, isSelectable, showChildren,
  } = cardProps;

  return (
    <DocItemContainer
      ref={ref}
      docIndex={docIndex}
      isGroupInBoardView={isGroupInBoardView}
      isLazy={isLazy}
      groupId={groupId}
      viewType={viewType}
      skeleton={height => (isNewInbox ? <div style={{ height }} /> : <Card style={{ height }} />)}
      isGroupDragging={isGroupDragging}
    >
      {isFocused => (
        <DocItemSortable
          itemId={itemId}
          isDragging={isDragging}
        >
          {asPlaceholder ? (
            <DocItemCard
              asPlaceholder
              isReadOnly={false}
              isNewInbox={isNewInbox}
              {...cardProps}
            />
          ) : (
            <div>
              <DocItemInView
                className={className}
                groupId={groupId}
                isDragging={isDragging}
                isSelectable={isSelectable}
              >
                <DocItemTouchContainer>
                  <DocItemCoverZone
                    isUploading={isUploadingCover}
                    disabled={isDragging || isFeedback(docType)}
                    viewType={viewType}
                  >
                    <DocItemLink
                      docUrl={docUrl}
                      groupId={groupId}
                      isDisabled={!docUrl}
                    >
                      <DocItemCard
                        {...cardProps}
                        isFocused={isFocused}
                        updateDocCover={updateDocCover}
                        isReadOnly={false}
                        isNewInbox={isNewInbox}
                      />
                    </DocItemLink>
                  </DocItemCoverZone>
                </DocItemTouchContainer>
              </DocItemInView>

              {showChildren && viewType === ViewType.List && !isDragging && (
                <NestedChildren />
              )}
            </div>
          )}
        </DocItemSortable>
      )}
    </DocItemContainer>
  );
});

export default memo(DocItem);
