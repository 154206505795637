import { StatusCategory } from '@cycle-app/graphql-codegen';
import { SelectPanel, Tag } from '@cycle-app/ui';
import { Label as LineLabel, LineShortcut } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine.styles';
import { Key } from '@cycle-app/ui/components/Shortcut/Shortcut.styles';
import styled, { css } from 'styled-components';

import { ReleaseNoteHeader } from 'src/components/ReleaseNote/ReleaseNote.styles';

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledTag = styled(Tag)<{ $category?: StatusCategory }>`
  ${p => p.$category && css`
    --bg: ${p.theme.colors.components.FeedbackStatus[p.$category].bg};
    --bgHover: ${p.theme.colors.components.FeedbackStatus[p.$category].bgHover};
    --bgActive: ${p.theme.colors.components.FeedbackStatus[p.$category].bgActive};
  `}
`;

export const StyledSelectPanel = styled(SelectPanel)`
  ${LineLabel} {
    flex: 1;
  }
  ${LineShortcut} {
    opacity: 1;
    justify-content: flex-start;
    width: 65px;
  }
  ${Key} {
    font-weight: 500;
    padding: 0;
    min-width: 0;
    text-transform: uppercase;
    background: transparent;
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const DocStatusText = styled.span`
  /* stylelint-disable-next-line no-duplicate-selectors */
  ${ReleaseNoteHeader} & {
    @media (max-width: 1480px) {
      display: none;
    }
  }
`;
