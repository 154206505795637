import { useRect } from '@cycle-app/utilities';

import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP, QUOTE_CARD_WIDTH } from 'src/constants/editor.constants';

// Width below which cards must be collapsed
const CONTAINER_WIDTH_LIMIT = EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 3 + QUOTE_CARD_WIDTH;

type Props = {
  disabled: boolean;
};

/**
 * Hook to determine if quote cards should be collapsed based on the width of the container
 */
export const useQuoteCardsCollapsed = ({ disabled }: Props) => {
  const [ref, collapsed] = useRect(rect => rect.width < CONTAINER_WIDTH_LIMIT, { disabled });
  return {
    quoteCardsCollapsed: collapsed,
    containerRef: ref,
  };
};
