import { BaseEmoji, emojiIndex } from 'emoji-mart';

import { COMMANDS } from 'src/constants/editor.constants';

export const DEFAULT_EMOJI = 'large_blue_diamond';

export const getNativeEmoji = (emojiKey: string | null | undefined) => {
  if (!emojiKey) return '';

  const emojiKeyParsed = emojiKey.replaceAll(COMMANDS.EMOJI, '');

  const emoji = emojiIndex.emojis[emojiKeyParsed] as BaseEmoji;
  return emoji?.native ?? '';
};
