import { typo, Tag } from '@cycle-app/ui';
import styled from 'styled-components';

export const TagStyled = styled(Tag)`
  ${typo.body500}
  border: none;
  display: inline-flex;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  max-width: unset;
`;
