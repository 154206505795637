import styled from 'styled-components';

import { Slot, Name } from 'src/utils/customers.util';

export const StyledSlot = styled(Slot)`
  width: 100%;
  overflow: hidden;
`;

export const StyledName = styled(Name)`
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: auto;
`;

export const Email = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Label = styled.div`
  overflow: hidden;
  display: flex;
  gap: 8px;
  white-space: nowrap;
`;
