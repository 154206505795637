import { AiState } from '@cycle-app/graphql-codegen';
import { StatusIcon, Emoji, Skeleton } from '@cycle-app/ui';
import { ParentAltIcon } from '@cycle-app/ui/icons';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { useNavigate, useParams } from 'src/hooks';
import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import { useUpdateDocAiState } from 'src/hooks/doc/useUpdateDocAiState';
import { useEditorTextContent } from 'src/hooks/useEditorTextContent';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { closeAiStateModal, useGetAiStateModal } from 'src/reactives/aiStateModal.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getDocFromCache } from 'src/utils/cache.utils';
import { getDocTypeName, isInsight } from 'src/utils/docType.util';

import { ModalContent, Preview, PreviewTitle, Quote } from './AiStateTag.styles';

export const AiStateModal = () => {
  const {
    docId, docTypeId,
  } = useGetAiStateModal();
  if (!docId || !docTypeId) return null;
  return (
    <AiStateModalOpen
      docId={docId}
      docTypeId={docTypeId}
    />
  );
};

const AiStateModalOpen = ({
  docId, docTypeId,
}: {
  docId: string;
  docTypeId: string;
}) => {
  const docType = useGetDocType(docTypeId);
  const docTypeName = getDocTypeName(docType).toLowerCase();
  const doc = getDocFromCache(docId);
  const {
    removeDoc, loading: isRemoving,
  } = useRemoveDoc();
  const { updateDocAiState } = useUpdateDocAiState();
  const { canDeleteInsight } = useGetPermission();
  const params = useParams();
  const { navigateToDocPanelParent } = useNavigate();
  return (
    <DialogModal
      type="default"
      title={`Verify AI-generated ${docTypeName}`}
      info={(
        <ModalContent>
          <Preview>
            {isInsight(docType) ? (
              <>
                <PreviewTitle>
                  <ParentAltIcon size={10} />
                  {doc?.status?.category && (
                    <StatusIcon
                      size={16}
                      withBackground
                      category={doc?.status?.category}
                    />
                  )}
                  {doc?.parent?.title}
                </PreviewTitle>
                <InsightQuote docId={docId} />
              </>
            ) : (
              <PreviewTitle>
                <ParentAltIcon size={10} />
                <Emoji emoji={docType?.emoji} size={12} />
                {doc?.title}
              </PreviewTitle>
            )}
          </Preview>
          {`Are you sure you want to verify this AI-generated ${docTypeName}?`}
        </ModalContent>
      )}
      hide={closeAiStateModal}
      variantCancelButton="secondary"
      variantSubmitButton="primary"
      cancelLabel="Discard"
      confirmLabel="Verify"
      loading={false}
      isCancelLoading={isRemoving}
      onCancel={async () => {
        // Check for permission
        if (isInsight(docType) && !canDeleteInsight) {
          setLimitationsModal({ action: 'INSIGHT_DELETE' });
          return;
        }
        await removeDoc(docId);
        // Close doc panel if needed
        if (params.docSlug?.includes(docId)) navigateToDocPanelParent();
      }}
      onConfirm={() => updateDocAiState(docId, AiState.UserValidated)}
      autoHide
    />
  );
};

const InsightQuote = ({ docId }: { docId: string }) => {
  const content = useEditorTextContent(docId);
  if (content === null) return <Skeleton height={24} />;
  if (!content.trim()) return null;
  return <Quote>{content}</Quote>;
};
