import { useApolloClient } from '@apollo/client';
import { ReleaseNoteBaseDocument } from '@cycle-app/graphql-codegen';

import { extract } from 'src/types/graphql.types';

export const useReleaseNoteAsync = () => {
  const client = useApolloClient();

  const getReleaseNote = async (releaseNoteId: string) => {
    const result = await client.query({
      query: ReleaseNoteBaseDocument,
      variables: {
        id: releaseNoteId,
      },
    });
    return extract('ReleaseNote', result.data?.node);
  };

  return {
    getReleaseNote,
  };
};
