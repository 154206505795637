import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { setAuth } from 'src/reactives/auth.reactive';

export const useAuthFromUrl = () => {
  const location = useLocation();
  const [tokenFromUrl, meIdFromUrl, from] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return [
      params.get('token'),
      params.get('userId'),
      params.get('from'),
    ];
  }, [location.search]);

  useEffect(() => {
    if (tokenFromUrl && meIdFromUrl) {
      setAuth({
        token: tokenFromUrl,
        userId: meIdFromUrl,
      });
    }
  }, [meIdFromUrl, tokenFromUrl]);

  return {
    location,
    meIdFromUrl,
    tokenFromUrl,
    from,
  };
};
