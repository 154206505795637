import { useRef, memo } from 'react';

import { ReadOnlyEditor } from 'src/components/Editor/Editors/ReadOnlyEditor';
import { useEditorAiValue } from 'src/reactives';
import { formatSuggestion } from 'src/utils/ai.util';

import { SuggestionContainer } from './EditorAiMenu.styles';

export const EditorAiSuggestionContainer = memo(() => {
  const container = useRef<HTMLDivElement>(null);
  const { suggestion } = useEditorAiValue();

  if (!suggestion) return null;

  return (
    <SuggestionContainer ref={container}>
      <ReadOnlyEditor content={formatSuggestion(suggestion)} interactive={false} />
    </SuggestionContainer>
  );
});
