import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  a.vjs-control.vjs-cloudinary-button.vjs-button {
    display: none !important;
  }
`;
