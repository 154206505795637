/**
 * @param name - the name of the field in the store
 * @returns the data we need to check if the field is the one we want to update
 * @link https://www.apollographql.com/docs/react/caching/cache-field-behavior/#storefieldname
 */
export const parseStoreFieldName = (name: string) => {
  try {
    const start = name.indexOf('{');
    const end = name.lastIndexOf('}');
    return JSON.parse(name.substring(start, end + 1));
  } catch {
    return {};
  }
};
