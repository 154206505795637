/* eslint-disable no-continue */
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';
import { parseToRgb, rgb } from 'polished';

import { editorColorOptions } from 'src/components/TextColorDropdown';

// Colors are encoded in the HTML as #RRGGBB
const editorColors = editorColorOptions.map(color => rgb(parseToRgb(color.value)).toUpperCase());

/** Remove all colors from the pasted HTML that are not in supported by the editor */
export const clearColors = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  for (const el of div.getElementsByTagName('*')) {
    let style = el.getAttribute('style')?.replace(/background-color:[^";]*;? ?/g, '');
    if (!style) continue;
    const matchColors = style.match(/color:[^";]*;? ?/);
    if (!matchColors) continue;
    for (const matchColor of matchColors) {
      if (editorColors.some(color => matchColor.includes(color))) continue;
      style = style.replace(matchColor, '');
    }
    el.setAttribute('style', style);
  }
  return div.innerHTML;
};

export const getNodeContents = async (node: Node | null, cb: (text: string) => void) => {
  if (!node) return;

  // Select the node's contents
  const sel = window.getSelection();
  if (!sel) return;
  const range = document.createRange();
  range.selectNodeContents(node);
  sel.removeAllRanges();
  sel.addRange(range);
  // Wait for the next frame to ensure the selection is updated
  await nextFrame();

  // Callback to exectute after the selection. In instance, call a copy to clipboard.
  cb(sel.toString());

  // Clear the selection
  sel.removeAllRanges();
};
