import { AnimatePresence } from 'framer-motion';
import { FC, useCallback, useEffect, useRef } from 'react';

import DocPreview from 'src/components/DocPreview/DocPreview';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useDocDocSource } from 'src/hooks';
import { useDocDoctype } from 'src/hooks/doc/useDocDoctype';
import { setSearchPreview, useGetSearchPreview } from 'src/reactives/commandbar.reactive';
import { useGetDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { setUserPreferences } from 'src/reactives/userPreferences.reactive';
import { Layer } from 'src/types/layers.types';

import { StyledModal } from './DocPreviewModal.styles';

export const DocPreviewModal: FC = () => {
  const searchPreview = useGetSearchPreview();
  const { docIdPreview } = useGetDocIdPreview();
  const {
    doc, isInsight,
  } = useDocDoctype(docIdPreview || searchPreview.docIdPreview);

  const {
    docSource, blockId, isLoading: isDocSourceLoading,
  } = useDocDocSource({ docId: !!doc?.id && isInsight ? doc.id : '' });
  const onHideModal = useCallback(() => {
    setDocIdPreview({ docIdPreview: null });
    setSearchPreview({ docIdPreview: null });
  }, []);
  const pointerX = useRef(0);

  useEffect(() => {
    if (docIdPreview) {
      // Stop showing the hint popup once the user has previewed a doc
      setUserPreferences({
        isDocPreviewHintEnabled: false,
      });
    }
  }, [docIdPreview]);

  useEffect(() => {
    if (searchPreview.docIdPreview) {
      // Stop showing the hint popup once the user has previewed a doc
      setUserPreferences({
        isSearchDocPreviewHintEnabled: false,
      });
    }
  }, [searchPreview.docIdPreview]);

  useEffect(() => {
    const trackPointerX = (e: MouseEvent) => {
      pointerX.current = e.pageX;
    };
    window.addEventListener('mousemove', trackPointerX);
    window.addEventListener('blur', onHideModal);
    return () => {
      window.removeEventListener('blur', onHideModal);
      window.removeEventListener('mousemove', trackPointerX);
    };
  }, [onHideModal]);

  const docId = docSource?.id || docIdPreview || searchPreview.docIdPreview;

  return (
    <AnimatePresence>
      {!!docId && (
        <StyledModal
          hide={onHideModal}
          $alignLeft={window.innerWidth - pointerX.current <= window.innerWidth / 2}
          noMask
          disableOverlayPointerEvents
          layer={Layer.PreviewModal}
        >
          <BoardConfigContextProvider skip>
            {isInsight && isDocSourceLoading
              ? null
              : <DocPreview blockId={blockId} id={docId} hideAttributes={isInsight} />}
          </BoardConfigContextProvider>
        </StyledModal>
      )}
    </AnimatePresence>
  );
};
