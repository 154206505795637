import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';

import { useDocTypeInsight } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { Layer } from 'src/types/layers.types';

import { DocOptions } from '../DocOptions';

interface Props {
  blockId?: string | null;
  className?: string;
  doc: DocBaseFragment;
  isOpen?: boolean;
  editParent?: boolean;
  editCover?: boolean;
  onCoverUpdateStart?: VoidFunction;
  onAddCoverClicked?: VoidFunction;
  layer?: Layer;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  onUpdateQuoteSelect?: VoidFunction;
}

export const InsightCardOptions: FC<React.PropsWithChildren<Props>> = ({
  className,
  doc,
  editParent,
  layer,
  onAddCoverClicked,
  onUpdateQuoteSelect,
}) => {
  const changeDocParent = useChangeDocParent();
  const { insight } = useDocTypeInsight();
  const possibleDoctypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);

  const updateDocParent = (parentId: string | undefined) => changeDocParent({
    docId: doc.id,
    parentId,
  });

  return (
    <div className={className}>
      <DocOptions
        commonDoctypeParents={possibleDoctypes.map(doctype => doctype.id)}
        doc={doc}
        keepDropdownOnSelect
        layer={layer}
        onParentUpdated={editParent ? updateDocParent : undefined}
        onAddCoverClicked={onAddCoverClicked}
        onUpdateQuoteSelect={onUpdateQuoteSelect}
      />
    </div>
  );
};
