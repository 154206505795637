import isEqual from 'lodash/isEqual';
import { useSyncExternalStore } from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import { history } from 'src/providers';
import { PathParams } from 'src/types/routes.types';
import { getPathParams } from 'src/utils/routing.utils';

/**
 * Hook to get a route param from the current route
 * The component will only re-render when the value changes
 */
export const useParamsSelector = <T>(
  selector: (params: PathParams) => T,
): T => {
  return useSyncExternalStore(
    history.listen,
    () => selector(getPathParams(history.location.pathname)),
  );
};

/**
 * Hook to get all route params from the current route
 * The component will not re-render if state or search params change
 */
export const useParams = () => {
  return useSyncExternalStoreWithSelector(
    history.listen,
    () => getPathParams(history.location.pathname),
    null,
    (params: PathParams) => params,
    isEqual,
  );
};

export const useProductSlug = () => useParamsSelector(p => p.productSlug);
export const useBoardSlug = () => useParamsSelector(p => p.boardSlug);
export const useBoardId = () => useParamsSelector(p => p.boardId);
export const useDocSlug = () => useParamsSelector(p => p.docSlug);
export const useDocId = () => useParamsSelector(p => p.docId);
export const useProvider = () => useParamsSelector(p => p.provider);
export const useDoctypeId = () => useParamsSelector(p => p.doctypeId);
export const useDashboardId = () => useParamsSelector(p => p.dashboardId);
export const useCustomerId = () => useParamsSelector(p => p.customerId);
export const useCompanyId = () => useParamsSelector(p => p.companyId);

export const useAppContext = () => useParamsSelector(p => {
  if (!p.boardSlug) return 'doc-fullpage';
  if (!p.docSlug) return 'specific-board';
  return 'specific-board-doc-panel';
});
