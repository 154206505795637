import { ErrorToaster } from '@cycle-app/ui';
import { motion, AnimatePresence } from 'framer-motion';

import { useGetErrorToasters } from 'src/reactives/toasters.reactive';
import { closeErrorToaster } from 'src/utils/errorToasters.utils';

import { motionProps, variantsContent } from './ErrorToasters.motion';
import { Container } from './ErrorToasters.styles';

export const ErrorToasters = () => {
  const { queue } = useGetErrorToasters();

  return (
    <AnimatePresence>
      {queue.length && (
        <Container>
          {queue.map((toasterConfig) => (
            <motion.div
              key={toasterConfig.id}
              variants={variantsContent}
              layout
              {...motionProps}
            >
              <ErrorToaster
                {...toasterConfig}
                onClose={toasterConfig.closable
                  ? () => closeErrorToaster(toasterConfig.id)
                  : undefined}
              />
            </motion.div>
          ))}
        </Container>
      )}
    </AnimatePresence>
  );
};
