import { produce } from 'immer';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

// Keys are thread ids, values are draft comments
// We use the `docId` for the main thread, and `docId-blockId` for inline threads
type DraftCommentsState = Record<string, string>;

const {
  hookValue,
  getValue,
  setValue,
} = make<DraftCommentsState>({
  defaultState: {},
  localKey: LocalKey.DraftComments,
  mergeTarget: 'initial',
});

export const useDraftComment = (id: string) => {
  const draftComments = hookValue();
  const draftComment = draftComments[id];

  const setDraftComment = useDebouncedCallback((comment: string) => {
    if (comment === draftComment) return;

    if (comment === '<p></p>') {
      deleteDraftComment();
      return;
    }

    setValue({
      ...draftComments,
      [id]: comment,
    });
  }, INPUT_ONCHANGE_DEBOUNCE);

  const deleteDraftComment = () => {
    setDraftComment.cancel();
    if (!draftComment) return;
    const {
      [id]: _, ...rest
    } = draftComments;
    setValue(rest);
  };

  return {
    draftComment,
    setDraftComment,
  };
};

export const deleteDraftComment = (id: string) => {
  setValue(produce(getValue(), draft => {
    // eslint-disable-next-line no-param-reassign
    delete draft[id];
  }));
};

export const deleteDraftComments = (docIds: string[]) => {
  const newValue = { ...getValue() };
  const keys = Object.keys(newValue);
  for (const key of keys) {
    if (docIds.some(docId => key.startsWith(docId))) {
      delete newValue[key];
    }
  }
  setValue(newValue);
};
