import { ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Action = styled(ActionButton)`
  --colorHover: var(--color);
`;

export const StarAction = styled(Action)`
  color: ${p => p.theme.colors.text.yellow} !important;
`;
