import {
  AddBoardFromBoardConfigDocument,
  AddBoardFromBoardConfigMutationVariables,
  ProductBySlugDocument,
  ProductBySlugQueryVariables,
  ProductFragment,
  SectionType,
} from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';

import { useMergeQuery } from 'src/hooks/useMergeQuery';
import { defaultPagination } from 'src/utils/pagination.util';
import { addBoardToSection } from 'src/utils/update-cache/boards-cache.util';

import useSafeMutation from '../../useSafeMutation';
import { useProduct } from '../useProduct';

export default function useForkBoardMutation(boardId?: string) {
  const { product } = useProduct();
  const mergeProduct = useMergeQuery<{ product: ProductFragment }, ProductBySlugQueryVariables>({
    query: ProductBySlugDocument,
  });

  const [addBoardFromBoardConfigMutation, { loading }] = useSafeMutation(AddBoardFromBoardConfigDocument);

  const forkBoardFromBoardConfig = useCallback(async (params: AddBoardFromBoardConfigMutationVariables) => addBoardFromBoardConfigMutation({
    variables: {
      ...params,
      ...defaultPagination,
    },
    update: (_, { data }) => {
      if (!product || !data?.addBoardFromBoardConfig) return;

      const forkedBoardSection = product.boardSections.edges
        .find(s => s.node.boards.edges.find(({ node }) => node.id === boardId))?.node;

      // The board section at index 0 is the special 'inbox' section
      const firstSection = product.boardSections.edges[1]?.node;
      const defaultSection = product.boardSections.edges.find(({ node }) => node.type === SectionType.Default)?.node;
      const sectionId = forkedBoardSection?.id ?? defaultSection?.id ?? firstSection?.id;
      if (!sectionId) return;

      mergeProduct(
        {
          slug: product.slug,
          ...defaultPagination,
        },
        {
          product: {
            boardSections: {
              edges: addBoardToSection(product.boardSections.edges, {
                atIndex: 0,
                sectionId,
                board: data.addBoardFromBoardConfig,
              }),
            },
          },
        },
        'replace',
      );
    },
  }), [addBoardFromBoardConfigMutation, boardId, product, mergeProduct]);

  return useMemo(() => ({
    forkBoardFromBoardConfig,
    loadingForkBoard: loading,
  }), [forkBoardFromBoardConfig, loading]);
}
