import { useGetLearnMorePopup, setLearnMorePopup } from 'src/reactives';

export const useSlackTour = () => {
  return {
    open: () => setLearnMorePopup('slack', true),
    close: () => setLearnMorePopup('slack', false),
  };
};

export const useIsSlackTourActive = () => {
  const isOpen = useGetLearnMorePopup('slack');
  return {
    isActive: isOpen,
  };
};
