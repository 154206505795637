import { Spinner } from '@cycle-app/ui';

import { ProductLabel } from './ProductLabel';
import { useProductBase } from './useProductBase';

export const ProductCurrent = () => {
  const { product } = useProductBase();
  if (!product) return <Spinner />;
  return <ProductLabel product={product} />;
};
