import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { FC } from 'react';

import { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { FeedbackQuoteCreateForm } from 'src/components/InsightCreateForm';
import { Layer } from 'src/types/layers.types';

import { Loader, StyledDropdownLayer } from './InsightCreateModal.styles';

export type FeedbackQuoteCreateModalProps = {
  dropdownLayer?: Layer;
  dropdownProps?: Partial<DropdownLayerProps>;
  isLoading?: boolean;
  isOpen: boolean;
  blockId?: string;
  defaultContent?: string;
  defaultCover?: string;
  feedbackDoc: DocBaseFragment;
  formLayer?: Layer;
  hide: VoidFunction;
  hideTitleField?: boolean;
  onInsightCreated?: (props: {
    parentDoc: DocBaseFragment | null | undefined;
  }) => void;
  onInsightCreating?: VoidFunction;
  parentDocId?: string | null;
  withoutParent?: boolean;
  linearId?: string;
  linearUrl?: string;
  linearDisabled?: boolean;
};

export const FeedbackQuoteCreateModal: FC<React.PropsWithChildren<FeedbackQuoteCreateModalProps>> = ({
  children,
  dropdownLayer = Layer.DropdownModalZ2,
  dropdownProps,
  hide,
  isLoading,
  isOpen,
  blockId,
  defaultContent,
  defaultCover,
  feedbackDoc,
  formLayer,
  hideTitleField,
  onInsightCreated,
  onInsightCreating,
  parentDocId,
  withoutParent,
  linearId,
  linearUrl,
  linearDisabled,
}) => {
  return (
    <StyledDropdownLayer
      visible={isOpen}
      layer={dropdownLayer}
      hide={hide}
      placement="bottom-end"
      content={isOpen && (isLoading ? (
        <Loader>
          <Skeleton height={24} />
          <Skeleton height={24} />
        </Loader>
      ) : (
        <FeedbackQuoteCreateForm
          hide={hide}
          onInsightCreated={(parentDoc) => {
            onInsightCreated?.({ parentDoc });
            hide();
          }}
          onInsightCreating={onInsightCreating}
          initialParentDocId={parentDocId}
          withoutParent={withoutParent}
          feedbackDoc={feedbackDoc}
          defaultContent={defaultContent}
          defaultCover={defaultCover}
          blockId={blockId}
          hideTitleField={hideTitleField}
          layer={formLayer}
          linearId={linearId}
          linearUrl={linearUrl}
          linearDisabled={linearDisabled}
        />
      ))}
      {...dropdownProps}
      popperOptions={{
        ...(dropdownProps?.popperOptions || {}),
        modifiers: [...(dropdownProps?.popperOptions?.modifiers || []), {
          name: 'flip',
          enabled: false,
        }, {
          name: 'offsetAdapter',
          enabled: true,
          phase: 'read',
          requires: ['popperOffsets'],
          options: {
            offset: 24,
          },
          fn: ({
            state, options,
          }) => {
            const { rects } = state;
            const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
            const overflowY = options.offset + rects.reference.y + rects.reference.height + rects.popper.height - viewportHeight;
            if (overflowY > 0 && state.modifiersData?.popperOffsets?.y) {
              // eslint-disable-next-line operator-assignment, no-param-reassign
              state.modifiersData.popperOffsets.y = Math.max(options.offset, state.modifiersData.popperOffsets.y - overflowY);
            }
            if (viewportHeight <= rects.popper.height) {
              state.elements.popper.setAttribute('data-scroll', 'true');
            }
          },
        }],
      }}
    >
      {children}
    </StyledDropdownLayer>
  );
};
