import { useQuery } from '@apollo/client';
import { ProductNotificationsDocument } from '@cycle-app/graphql-codegen';
import { motionProps } from '@cycle-app/ui/components/Popover/Popover.motion';
import { motion } from 'framer-motion';
import { useState, useCallback, FC, useMemo } from 'react';

import { SCROLL_CONTAINER_ID } from 'src/constants/notifications.constants';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useGetNotifications, closeNotifications } from 'src/reactives/notifications.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { openSettingsNotifications } from 'src/reactives/settingsModal.reactive';
import { extract } from 'src/types/graphql.types';
import { SIZE_NOTIFICATIONS_PAGE } from 'src/utils/pagination.util';

import { NotificationCard } from './NotificationCard/NotificationCard';
import { NotificationCardLazy } from './NotificationCardLazy';
import {
  StyledDropdownLayer, EmptyState, DropdownContent, Heading, SettingsButton, StyledInfiniteScroll, SettingsCloseButton,
} from './NotificationCenter.styles';

const openSettings = () => {
  closeNotifications();
  openSettingsNotifications();
};

interface Props {
  className?: string;
  offset?: [number, number];
}

const NotificationCenter: FC<React.PropsWithChildren<Props>> = ({
  className,
  offset = [0, 20],
  children,
}) => {
  const isMobile = useIsMobile();
  const product = useProductBase();
  const { isNotifCenterVisible } = useGetNotifications();
  const { markNotificationAsRead } = useProductMutations();

  const [isDropdownAnimationComplete, setDropdownAnimationComplete] = useState(false);
  const [isListAnimationComplete, setListAnimationComplete] = useState(false);

  const {
    fetchMore,
    ...query
  } = useQuery(ProductNotificationsDocument, {
    skip: !product?.id || !isDropdownAnimationComplete,
    fetchPolicy: 'cache-first',
    variables: {
      productId: product?.id as string,
      size: SIZE_NOTIFICATIONS_PAGE,
      cursor: '',
    },
  });

  const node = extract('Product', query.data?.node);

  const pageInfo = useMemo(() => node?.notifications.pageInfo, [node?.notifications.pageInfo]);
  const notifications = useMemo(() => node?.notifications, [node?.notifications]);

  const markNotifsAsRead = useCallback(async () => {
    if (product?.id && notifications) {
      await markNotificationAsRead(product.id, notifications);
    }
  }, [markNotificationAsRead, product?.id, notifications]);

  const hideNotifCenter = useCallback(async () => {
    closeNotifications();
    if ((node?.notificationsNotRead ?? 0) > 0) {
      await markNotifsAsRead();
    }
  }, [markNotifsAsRead, node?.notificationsNotRead]);

  const loadMore = useCallback(() => {
    if (!pageInfo?.hasNextPage || !pageInfo.endCursor) return null;
    return fetchMore({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
  }, [fetchMore, pageInfo]);

  return (
    <StyledDropdownLayer
      offset={isMobile ? [0, 0] : offset}
      placement="right-start"
      visible={isNotifCenterVisible}
      hide={hideNotifCenter}
      motionProps={{
        ...motionProps,
        onAnimationComplete: variant => {
          if (variant === 'enter') setDropdownAnimationComplete(true);
          if (variant === 'exit') setDropdownAnimationComplete(false);
        },
      }}
      popperOptions={{
        modifiers: [{
          name: 'preventOverflow',
          options: {
            mainAxis: false,
          },
        }],
      }}
      content={(
        <DropdownContent id={SCROLL_CONTAINER_ID}>
          <BoardConfigContextProvider>
            <StyledInfiniteScroll
              className={className}
              isLoading={false}
              hasMoreData={pageInfo?.hasNextPage ?? false}
              loadMore={loadMore}
              rootMargin="300px"
            >
              <motion.div
                style={{
                  minHeight: 80,
                }}
                variants={{
                  pending: {
                    height: 80,
                  },
                  loaded: {
                    height: 'auto',
                  },
                }}
                animate={query.data ? 'loaded' : 'pending'}
                transition={{
                  duration: 0.15,
                }}
                onAnimationComplete={variant => {
                  setListAnimationComplete(variant === 'loaded');
                }}
              >
                {notifications?.edges?.length === 0 && (
                  <EmptyState>You have no notifications yet</EmptyState>
                )}
                {notifications?.edges?.map(({ node: notification }) => (
                  <NotificationCardLazy
                    key={notification.id}
                    skip={!isListAnimationComplete}
                  >
                    <NotificationCard
                      notification={notification}
                      onClickLink={markNotifsAsRead}
                    />
                  </NotificationCardLazy>
                ))}
              </motion.div>
            </StyledInfiniteScroll>
          </BoardConfigContextProvider>

          <header>
            {isMobile && (
              <SettingsCloseButton onClick={closeNotifications}>
                Close
              </SettingsCloseButton>
            )}

            <Heading>
              Notifications
            </Heading>

            {!isMobile && (
              <SettingsButton onClick={openSettings}>
                Preferences
              </SettingsButton>
            )}
          </header>
        </DropdownContent>
      )}
    >
      {children}
    </StyledDropdownLayer>
  );
};

export default NotificationCenter;
