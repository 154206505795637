import { typo, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Bottom = styled(Top)<{ $hasMinHeight: boolean }>`
  min-height: ${p => (p.$hasMinHeight ? '24px' : '0')};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h1`
  ${typo.headerLarge600}
`;

export const SubTitle = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Action = styled(ActionButton)`
  --colorHover: ${p => p.theme.colors.text.secondary};
`;
