import { Button } from '@cycle-app/ui';
import { LinkIcon, SyncIcon, CodeBorderIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { setLinearImportNextStep } from 'src/reactives/linearImport.reactive';

import { Intro, IntroTitle, IntroList, IntroItem, IconContainer } from './LinearImportModal.styles';

export const LinearOnboardingIntro = () => {
  return (
    <Intro>
      <IntroTitle>Linear integration</IntroTitle>

      <IntroList>
        <Item
          icon={<LinkIcon size={20} />}
          title="Connect with Linear"
          description="Link any Cycle doc to a Linear project or issue."
        />
        <Item
          icon={<SyncIcon size={18} />}
          title="Sync statuses"
          description="When a Cycle doc is linked to a Linear project or issue, their statuses are in sync."
        />
        <Item
          icon={<CodeBorderIcon size={18} />}
          title="Embed Linear projects and issues"
          description="Visualize the content and properties of any Linear project or issue, directly from Cycle docs."
        />
      </IntroList>

      <Button
        size="M"
        onClick={setLinearImportNextStep}
      >
        Continue
      </Button>
    </Intro>
  );
};

const Item = ({
  icon, title, description,
}: {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
}) => (
  <IntroItem>
    <IconContainer>{icon}</IconContainer>
    <div>
      <div className="select-text text-body font-medium">
        {title}
      </div>
      <div className="select-text text-secondary">
        {description}
      </div>
    </div>
  </IntroItem>
);
