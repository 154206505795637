import { Color, SectionType } from '@cycle-app/graphql-codegen';
import { PlaceHolderIcon, RoadmapIcon, BulbLight, InboxIcon } from '@cycle-app/ui/icons';
import { useTheme } from 'styled-components';

interface Props {
  className?: string;
  sectionType: SectionType | undefined;
  color: Color | null | undefined;
}

export const BoardDefaultIcon = ({
  className, sectionType, color,
}: Props) => {
  const theme = useTheme();
  const colorValue = color ? theme.nuances[color].main : undefined;
  const style = colorValue ? { color: colorValue } : undefined;
  const iconProps = {
    className,
    style,
  };
  
  if (sectionType === SectionType.Feedback) return <InboxIcon {...iconProps} />;
  if (sectionType === SectionType.Insights) return <BulbLight {...iconProps} />;
  if (sectionType === SectionType.Roadmaps) return <RoadmapIcon {...iconProps} />;
  return <PlaceHolderIcon {...iconProps} />;
};
