import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { ImportFileIcon } from 'src/components/Dropzone/ImportFileIcon';

export const CycleLogoContainer = styled.div`
  border-radius: 2px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.background.blue};
  svg {
    --color1: white;
    --color2: white;
  }
`;

export const StyledImportFileIcon = styled(ImportFileIcon)`
  color: ${p => p.theme.colors.text.secondary};
`;

export const ModalUrlContent = styled.div`
  padding: 8px;
`;

export const Info = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 4px;
`;

export const Url = styled.p`
  ${typo.caption400};
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TooltipSubtitle = styled.p`
  ${typo.caption400};
  color: ${p => p.theme.colors.tooltip.textLight};
`;
