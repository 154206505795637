import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { Label } from 'src/components/BoardConfigForm/BoardConfigForm.styles';

export const SoonBadge = styled.span.attrs({
  children: 'Soon',
})`
  ${typo.caption500}
  color: ${p => p.theme.colors.text.disabled};
  background-color: ${p => p.theme.colors.background.secondary};
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;

  ${Label} & {
    background-color: ${p => p.theme.colors.background.secondaryElevated};
  }
`;
