import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';

import { Container, Counter, ActionVariant } from './ActionButton.styles';
import { Spinner } from '../../Spinner/Spinner.styles';
import { Tooltip, TooltipPlacement } from '../../Tooltip/Tooltip';

export interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  counter?: number;
  formatCounter?: (counter: number) => number | string;
  tooltip?: ReactNode;
  tooltpTitle?: ReactNode;
  tooltipPlacement?: TooltipPlacement;
  tooltipDisabled?: boolean;
  tooltipVisible?: boolean;
  size?: 'S' | 'M' | 'L' | 'XL';
  variant?: ActionVariant;
  onColoredBg?: boolean;
  forceFocus?: boolean;
  ariaLabel?: string;
  isLoading?: boolean;
  iconEnd?: ReactNode;
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(({
  className = '',
  children,
  counter,
  formatCounter = (c: number) => c,
  size = 'M',
  tooltip,
  tooltipPlacement,
  tooltipDisabled,
  tooltipVisible,
  tooltpTitle,
  variant,
  onColoredBg = false,
  forceFocus,
  ariaLabel,
  isLoading = false,
  iconEnd,
  ...buttonProps
}, ref) => {
  const button = (
    <Container
      {...buttonProps}
      className={`${className} ${forceFocus ? 'force-focus' : ''}`}
      type="button"
      $hasCounter={!!counter}
      data-counter={!!counter}
      $size={size}
      $variant={variant}
      $onColoredBg={onColoredBg}
      aria-label={ariaLabel}
      ref={ref}
    >
      {isLoading ? <Spinner /> : children}
      {!!counter && (<Counter>{formatCounter(counter)}</Counter>)}
      {iconEnd}
    </Container>
  );

  if (tooltip) {
    return (
      <Tooltip
        content={tooltip}
        title={tooltpTitle}
        placement={tooltipPlacement}
        disabled={tooltipDisabled}
        visible={tooltipVisible}
        withPortal
      >
        {button}
      </Tooltip>
    );
  }

  return button;
});
