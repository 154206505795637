import { ActionButton } from '@cycle-app/ui';
import { AddIcon, ExportIcon2, DuplicateIcon } from '@cycle-app/ui/icons';

import { InsightCreateModal } from 'src/components/InsightCreateModal';
import { copyQuotesToClipboard } from 'src/components/InsightsList/InsightsList.utils';
import { InsightsSearch } from 'src/components/InsightsSearch';
import { useDoc, useOptimizedBooleanState } from 'src/hooks';
import { useDocInsightChildren } from 'src/hooks/insight/useDocInsightChildren';
import { useDocId } from 'src/hooks/usePathParams';
import { showExportQuotesModal } from 'src/reactives/docPanel.reactive';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

export const QuotesActions = () => {
  const docId = useDocId();
  const insightDoctypeId = useInsightDocType()?.id;
  if (!docId || !insightDoctypeId) return null;
  return (
    <QuotesActionsButtons
      docId={docId}
      insightDoctypeId={insightDoctypeId}
    />
  );
};

type Props = {
  docId: string;
  insightDoctypeId: string;
};

export const QuotesActionsButtons = ({
  docId, insightDoctypeId, 
}: Props) => {
  const { doc } = useDoc(docId);
  
  const { insights } = useDocInsightChildren({
    docId,
    doctypeId: insightDoctypeId,
  });
  
  const [isSearchDropdownVisible, {
    setFalseCallback: hideSearchDropdown,
    setTrueCallback: showSearchDropdown,
  }] = useOptimizedBooleanState(false);

  return (
    <div className="flex h-4 items-center gap-2 border-l border-grey-200 pl-4 dark:border-grey-800">
      {insights.length > 0 && (
        <>
          <ActionButton
            className="size-6 p-0 outline-none focus-visible:ring"
            tooltip="Copy quotes"
            tooltipPlacement="top"
            onClick={() => copyQuotesToClipboard(insights)}
          >
            <DuplicateIcon size={16} />
          </ActionButton>

          <ActionButton
            className="size-6 p-0 outline-none focus-visible:ring"
            tooltip="Export quotes"
            tooltipPlacement="top"
            onClick={showExportQuotesModal}
          >
            <ExportIcon2 size={16} />
          </ActionButton>
        </>
      )}

      <InsightsSearch
        parentId={docId}
        visible={isSearchDropdownVisible}
        hide={hideSearchDropdown}
        createModal={modalProps => (
          <InsightCreateModal
            hide={modalProps.hide}
            hideParentDoc
            isOpen={modalProps.visible}
            parentDoc={doc}
            defaultContent={modalProps.quote}
          >
            <ActionButton
              className="size-6 p-0 outline-none focus-visible:ring"
              tooltip="Add quote"
              tooltipPlacement="top"
              onClick={() => requestInsightCreate(showSearchDropdown)}
              forceFocus={isSearchDropdownVisible || modalProps.visible}
            >
              <AddIcon size={14} />
            </ActionButton>
          </InsightCreateModal>
        )}
      />
    </div>
  );
};
