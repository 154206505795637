import { ColorPalettePicker } from '@cycle-app/ui';

import { ColorInput, ColorValue, ItemContainer, ItemLabel, StyledToggleDropdown } from './ChangelogBuilderInputColor.styles';
import { colorPaletteSaveColor, useGetColorPaletteSavedColors } from '../../reactives/colorPaletteSavedColors.reactive';

type Props = {
  color: string;
  error?: string;
  label: string;
  onChange: (value: string) => void;
};

export const ChangelogBuilderInputColor = ({
  onChange, error, color, label,
}: Props) => {
  const { savedColors } = useGetColorPaletteSavedColors();
  return (
    <ItemContainer>
      <ItemLabel>
        {label}
      </ItemLabel>
      <StyledToggleDropdown
        placement="right-end"
        width={264}
        style={{
          width: '115px',
          flex: 'none',
        }}
        button={buttonProps => (
          <ColorInput
            onFocus={() => {
              // Hide all dropdowns in case the user uses tab to focus each input.
              document.dispatchEvent(new KeyboardEvent('keyup', { code: 'Escape' }));
              buttonProps.trigger();
            }}
            iconBefore={<ColorValue style={{ backgroundColor: color }} />}
            onChange={e => {
              const { value } = e.currentTarget;
              // If the user manually edit the input, # can be missing.
              onChange(value.startsWith('#') ? value : `#${value}`);
            }}
            value={color.replace('#', '')}
            iconPadding={34}
            error={error}
          />
        )}
        content={(
          <ColorPalettePicker
            color={color}
            onChange={onChange}
            savedColors={savedColors}
            onAddColor={colorPaletteSaveColor}
          />
        )}
      />
    </ItemContainer>
  );
};
