import { Color } from '@cycle-app/graphql-codegen';
import { search } from 'fast-fuzzy';
import { ComponentPropsWithRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from '../../constants/inputs.constant';
import { SearchIcon } from '../../icons/action/SearchIcon';
import { IconName, IconNameOutput } from '../../types/icon.types';
import { Icon, IconNames, getIconNameOutput } from '../Icon';
import {
  Container, StyledColorPicker, Separator, SearchInput, Icons, IconButton,
} from './IconPicker.styles';

export const DEFAULT_ICON_COLOR = Color.A;

export type IconPickerProps = ComponentPropsWithRef<typeof Container> & {
  color?: Color | null;
  onIconChange: (icon: IconNameOutput) => void;
  onColorChange: (color: Color) => void;
};

export const IconPicker = ({
  color,
  onIconChange,
  onColorChange,
  ...props
}: IconPickerProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [filter] = useDebounce(searchValue, INPUT_ONCHANGE_DEBOUNCE);

  const iconNames = filter
    ? search(filter, IconNames, { threshold: 0.8 })
    : IconNames;

  return (
    <Container {...props}>
      <StyledColorPicker
        color={color}
        onClick={onColorChange}
        small
      />

      <Separator />

      <SearchInput
        iconBefore={<SearchIcon />}
        placeholder="Search"
        autoFocus
        defaultValue={searchValue}
        onChange={e => setSearchValue(e.target.value.trim())}
      />

      <Icons>
        {iconNames.map(iconName => (
          <LazyIconButton
            key={iconName}
            color={color}
            name={iconName}
            onClick={() => onIconChange(getIconNameOutput(iconName))}
          />
        ))}
      </Icons>
    </Container>
  );
};

type LazyIconButtonProps = ComponentPropsWithRef<typeof IconButton> & {
  color?: Color | null;
  name: IconName;
};

export const LazyIconButton = ({
  color, name, ...props
}: LazyIconButtonProps) => {
  const [buttonRef, isInView] = useInView();
  return (
    <IconButton
      type="button"
      ref={buttonRef}
      $color={color}
      {...props}
    >
      {isInView && (
        <Icon
          name={name}
          size={16}
          color="currentColor"
        />
      )}
    </IconButton>
  );
};
