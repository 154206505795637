import { ImageIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { Dropzone, DropzoneInner } from './CoverDropzoneOverlay.styles';

interface Props {
  className?: string;
  label: string;
  noBorderRadius?: boolean;
  horizontalLayout?: boolean;
}
const CoverDropzoneOverlay: FC<Props> = ({
  className,
  label,
  noBorderRadius,
  horizontalLayout = false,
}) => (
  <Dropzone
    className={className}
    noBorderRadius={noBorderRadius}
  >
    <DropzoneInner horizontalLayout={horizontalLayout}>
      <ImageIcon />
      <div>{label}</div>
    </DropzoneInner>
  </Dropzone>
);

export default CoverDropzoneOverlay;
