import { ThemeType } from '@cycle-app/graphql-codegen';
import { Button, typo, boxShadowZ3 } from '@cycle-app/ui';
import styled from 'styled-components';

const smallHeight = '@media (max-height : 580px)';

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  :before {
    content: '';
    position: absolute;
    inset: 0;
    background-position: 50% calc(50% - 150px);
    background-repeat: no-repeat;
    pointer-events: none;
    background-image: url(/images/releases-bg.png);
    ${p => p.theme.themeType === ThemeType.Nightfall && 'filter: invert(100%)'};
  }

  ${smallHeight} {
    :before, :after {
      display: none;
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${smallHeight} {
    top: 50%;
  }

  > p {
    ${typo.body500}
    text-align: center;
    margin-bottom: 24px;
    max-width: 550px;
  }
`;

export const StyledButton = styled(Button).attrs({ size: 'L' })`
  height: 32px;
  padding: 4px 12px;
`;

export const EmptyDocItems = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 150px));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
export const EmptyDocItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 38px;
  background: ${p => (p.theme.isDark ? p.theme.colors.background.secondary : p.theme.colors.background.primary)};
  border-radius: 10px;
  padding: 0 14px;
  ${boxShadowZ3}
  :first-child {
    margin-left: -32px;
  }
  :last-child {
    margin-right: -32px;
  }
  :after {
    content: '';
    height: 6px;
    width: 70px;
    background: ${p => p.theme.colors.background.tertiary};
    border-radius: 3px;
  }
`;
