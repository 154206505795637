import { useQuery } from '@apollo/client';
import { DocResultFragment, SearchDocDocument, StatusCategory } from '@cycle-app/graphql-codegen';
import { StatusIcon, Tooltip, Tag } from '@cycle-app/ui';
import { nodeToArray, getDocSlug, capitalize, getHighlightHash } from '@cycle-app/utilities';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import searchPreview from 'src/components/CommandBar/searchPreview';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { PageId } from 'src/constants/routing.constant';
import { SEARCH_INITIAL_PAGINATION_SIZE, SEARCH_NEXT_PAGINATION_SIZE } from 'src/constants/search.constants';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useUrl } from 'src/hooks/useUrl';
import { useSectionFilter, useStatusFilter } from 'src/reactives/commandbar.reactive';
import { getDocType, useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { useGetStatuses } from 'src/reactives/productStatus.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';
import { getDocKey } from 'src/utils/doc.util';
import { findFeedback, findInsight, isCustom, getDocTypeName, isInsight } from 'src/utils/docType.util';

import { closeCommandBar } from '../modals/useCommandBarModal';

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const docCategories = ['docs', 'feedback', 'insights', 'roadmaps'] as const;
export type DocCategory = typeof docCategories[number];

export const useDocSearch = ({
  text = '',
  category = 'docs',
  ...params
}: {
  text?: string;
  skip?: boolean;
  category?: DocCategory;
}) => {
  const history = useHistory();
  const getUrl = useUrl();
  const product = useProductBase();
  const doctypeIds = useSearchDocTypeIds(category);
  const statusIds = useSearchStatusIds();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const skip = params.skip || !product?.id || doctypeIds?.length === 0;

  const {
    loading,
    fetchMore,
    ...query
  } = useQuery(SearchDocDocument, {
    variables: {
      text,
      productId: product?.id ?? '',
      doctypeIds,
      statusIds,
      size: SEARCH_INITIAL_PAGINATION_SIZE,
      cursor: '',
    },
    fetchPolicy: 'cache-and-network',
    skip,
  });

  const data = loading ? query.previousData : query.data;
  const hasNextPage = query.data?.searchDoc?.pageInfo?.hasNextPage ?? false;
  const endCursor = query.data?.searchDoc?.pageInfo?.endCursor ?? '';

  const result = useMemo<CommandActionCategory[]>(() => {
    const getDocLinkTo = (doc: DocResultFragment) => {
      const docType = getDocType(doc.doctype.id);
      if (isInsight(docType)) {
        if (doc.docSource?.doc) {
          return getUrl(PageId.DocFullPage, {
            docSlug: getDocSlug(doc.docSource.doc),
            hash: getHighlightHash({
              docId: doc.id,
              blockId: doc.docSource.blockId,
            }),
          });
        }
        return undefined;
      }
      return getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
    };

    return [{
      id: category,
      label:
      // eslint-disable-next-line no-nested-ternary
      category === 'docs'
        ? 'Recently created'
        : category === 'roadmaps'
          ? 'Docs'
          : capitalize(category),
      actions: nodeToArray(data?.searchDoc)
        // filter out results from previous data that does not match current search variables
        .filter(d => {
          const { doc } = d;
          if (!doc.status) return false;
          return statusIds.includes(doc.status.id) && doctypeIds.includes(doc.doctype.id);
        })
        .map((d) => {
          const { doc } = d;
          return {
            id: doc.id,
            label: doc.title.trim() || 'Untitled',
            icon: (
              <IconsContainer>
                {d.highlightIndex ? (
                  <Tag
                    color="grey"
                    start={(
                      <Tooltip
                        content={getDocTypeName(getDocType(doc.doctype.id))}
                        placement="top"
                        withPortal
                        withWrapper={false}
                      >
                        <DocTypeIcon
                          doctype={getDocType(doc.doctype.id)}
                          size={12}
                        />
                      </Tooltip>
                    )}
                  >
                    {getDocKey(product?.key, d.doc?.publicId)}
                  </Tag>
                ) : (
                  <Tooltip
                    content={getDocTypeName(getDocType(doc.doctype.id))}
                    placement="top"
                    withPortal
                    withWrapper={false}
                  >
                    <DocTypeIcon doctype={getDocType(doc.doctype.id)} />
                  </Tooltip>
                )}

                {isRoadmapsEnabled && doc.status?.category && (
                  <Tooltip
                    content={doc.status.value}
                    placement="top"
                    withPortal
                    withWrapper={false}
                  >
                    <StatusIcon category={doc.status?.category} withBackground />
                  </Tooltip>
                )}
              </IconsContainer>
            ),
            linkTo: getDocLinkTo(doc),
            onClick: (e) => {
              if (!e.metaKey) closeCommandBar();
            },
            onMouseEnter: () => searchPreview.onMouseEnter(doc.id),
            onMouseLeave: searchPreview.onMouseLeave,
            onSelect: () => {
              const url = getDocLinkTo(doc);
              if (url) {
                history.push(url);
                closeCommandBar();
              }
            },
            filtered: true,
            richContent: d.__typename === 'DocSearch' ? d.highlightContent || undefined : undefined,
            richTitle: d.__typename === 'DocSearch' ? d.highlightTitle || undefined : undefined,
          };
        }) ?? [],
    }];
  }, [category, data?.searchDoc, doctypeIds, getUrl, history, statusIds]);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMore = useCallback(async () => {
    if (!hasNextPage || !endCursor || !product?.id) return;
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        text,
        productId: product.id,
        doctypeIds,
        statusIds,
        cursor: endCursor,
        size: SEARCH_NEXT_PAGINATION_SIZE,
      },
    });
    setIsLoadingMore(false);
  }, [doctypeIds, endCursor, fetchMore, hasNextPage, product?.id, statusIds, text]);

  return {
    result,
    loading,
    isLoadingMore,
    loadMore,
    hasNextPage,
  };
};

const useSearchDocTypeIds = (category: DocCategory) => {
  const sectionFilter = useSectionFilter();

  const docTypes = Object.values(useGetDocTypes().docTypes);

  let ids: string[] = [];

  if (['docs', 'feedback'].includes(category) && sectionFilter.feedback) {
    const feedbackDocTypeId = findFeedback(docTypes)?.id;
    if (feedbackDocTypeId) ids.push(feedbackDocTypeId);
  }

  if (['docs', 'insights'].includes(category) && sectionFilter.insights) {
    const insightsDocTypeId = findInsight(docTypes)?.id;
    if (insightsDocTypeId) ids.push(insightsDocTypeId);
  }

  if (['docs', 'roadmaps'].includes(category) && sectionFilter.roadmaps) {
    const roadmapsDocTypeIds = docTypes.filter(isCustom).map(d => d.id);
    ids = ids.concat(roadmapsDocTypeIds);
  }

  return ids;
};

const useSearchStatusIds = () => {
  const statuses = useGetStatuses();
  const statusFilter = useStatusFilter();
  const statusIds: string[] = [];
  if (statusFilter[StatusCategory.NotStarted]) statusIds.push(...statuses.notStarted.map(s => s.id));
  if (statusFilter[StatusCategory.Started]) statusIds.push(...statuses.started.map(s => s.id));
  if (statusFilter[StatusCategory.Completed]) statusIds.push(...statuses.completed.map(s => s.id));
  if (statusFilter[StatusCategory.Canceled]) statusIds.push(...statuses.canceled.map(s => s.id));
  return statusIds;
};
