import { nodeToArray } from '@cycle-app/utilities';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const useGetAllDocsInGroup = () => {
  const docQuery = useBoardConfig(ctx => ctx.boardConfig?.docQuery);

  return (groupId: string) => {
    if (docQuery?.__typename === 'BoardQueryWithGroupBy') {
      const group = nodeToArray(docQuery.docGroups).find(g => g.id === groupId);
      return nodeToArray(group?.docs);
    }

    if (docQuery?.__typename === 'BoardQueryWithSwimlaneBy') {
      const swimlanes = nodeToArray(docQuery.swimlanes);
      const groups = swimlanes.flatMap(s => nodeToArray(s.docGroups));
      const group = groups.find(g => g.id === groupId);
      return nodeToArray(group?.docs);
    }

    return nodeToArray(docQuery?.docGroup.docs);
  };
};
