import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { getDocSlug } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { useEditorAi } from 'src/reactives';
import { useActiveProductTour } from 'src/reactives/productTours.reactive';
import { getSelection } from 'src/reactives/selection.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutDocPanel } from 'src/types/shortcuts.types';
import { getOpenedLayersAbove } from 'src/utils/layers.util';

export const useDocShortcutListener = (doc: DocBaseFragment | null) => {
  const {
    navigate, navigateToDocPanelParent,
  } = useNavigate();
  const pageId = usePageId();
  const tour = useActiveProductTour();
  const [{
    visible: isAiVisible, suggestion: aiSuggestion,
  }, setEditorAi] = useEditorAi();

  const callbacks = useMemo(() => ({
    [ShortcutDocPanel.CloseDoc]: () => {
      if (isAiVisible && aiSuggestion) {
        setEditorAi({ isWarningVisible: true });
      }
      if (
        // We can select docs in the side panel. Espace is the shortcut for unselect.
        !!getSelection().selected.length ||
        pageId === PageId.DocFullPage ||
        getOpenedLayersAbove(Layer.DocPanel) ||
        !!tour ||
        !!isAiVisible
      ) {
        return;
      }
      navigateToDocPanelParent();
    },

    [ShortcutDocPanel.ExpandDoc]: () => {
      if (doc) {
        navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
      }
    },

  }), [doc, navigate, navigateToDocPanelParent, pageId, tour]);

  useHotkeyListener({
    callbacks,
    shortcuts: Object.values(ShortcutDocPanel),
    disableOnLayers: [Layer.Dropdown, Layer.Modal],
  });
};
