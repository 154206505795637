import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { LinearProjectsDocument, LinearProjectsQuery } from '@cycle-app/graphql-codegen';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isPresent } from 'ts-is-present';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';

export const useLinearProjects = (
  integrationId: string | null | undefined,
  searchText = '',
  options?: QueryHookOptions,
) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
  }, [searchText]);

  const query = useQuery(LinearProjectsDocument, {
    fetchPolicy: 'cache-and-network',
    ...options,
    skip: !integrationId || options?.skip,
    variables: {
      integrationId: integrationId as string,
      searchText: searchText.trim() || null,
    },
    onCompleted: () => {
      setIsFetching(false);
    },
  });

  const projects = useMemo(() => {
    return getProjects(query.data ?? query.previousData);
  }, [query.data, query.previousData]);

  return {
    projects,
    isLoading: query.loading,
    error: query.error,
    isFetching,
  };
};

export const useSearchLinearProjects = () => {
  const [refetch, {
    data, loading: isSearchLoading,
  }] = useLazyQuery(LinearProjectsDocument);

  const projects = useMemo(() => getProjects(data), [data]);

  const search = useCallback((searchText: string, integrationId: string) => {
    refetch({
      variables: {
        integrationId,
        searchText,
      },
    });
  }, [refetch]);

  const searchDebounced = useDebouncedCallback(search, INPUT_ONCHANGE_DEBOUNCE);

  return {
    isSearchLoading,
    projects,
    searchDebounced,
  };
};

const getProjects = (data?: LinearProjectsQuery) => {
  if (data?.node?.__typename !== 'Integration') return [];
  if (data.node.provider?.__typename !== 'Linear') return [];
  return data.node.provider.projects.filter(isPresent);
};
