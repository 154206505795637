import { useQuery } from '@apollo/client';
import { ProductDoctypesDocument } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';

import { extract } from 'src/types/graphql.types';

export const useDocTypes = (productId?: string) => {
  const {
    data, loading, refetch,
  } = useQuery(ProductDoctypesDocument, {
    skip: !productId,
    variables: {
      productId,
    },
  });

  return {
    docTypes: nodeToArray(extract('Product', data?.node)?.doctypes),
    isLoading: loading,
    refetch,
  };
};
