import { Reference } from '@apollo/client';
import { RemoveQuoteDocument } from '@cycle-app/graphql-codegen';

import { useSafeMutation } from 'src/hooks';

export const useDiscardQuote = () => {
  const [mutate] = useSafeMutation(RemoveQuoteDocument);

  const discard = (docId: string, quoteId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    mutate({
      variables: {
        id: quoteId,
      },
      optimisticResponse: {
        removeQuote: {
          id: quoteId,
        },
      },
      update: (cache, { data }) => {
        if (!data?.removeQuote) return;
        cache.modify({
          id: cache.identify({ id: docId }),
          fields: {
            quotes: (refs, { readField }) => ({
              ...refs,
              edges: refs.edges.filter(
                (edge: { node: Reference }) => readField('id', edge.node) !== data.removeQuote?.id,
              ),
            }),
            quotesCount: (value) => (value > 0 ? value - 1 : 0),
          },
        });
      },
    });
  };

  return {
    discard,
  };
};
