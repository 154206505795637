import { useLanguage } from 'src/reactives/language.reactive';

import { useMe } from '../api/useMe';

export const useUserLanguage = () => {
  const { me } = useMe();
  const [language, setLanguage] = useLanguage(me.id);

  return {
    language,
    setLanguage,
  };
};
