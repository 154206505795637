import { typo, Button, ring, boxShadowZ2 } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div<{ $isSelected?: boolean }>`
  border-radius: 12px;
  height: 261px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  ${ring}
  --ring-color: ${p => p.theme.colors.roadmaps.template.border};

  ${p => p.$isSelected && `
    --ring-size: 2px;
    --ring-color: hsl(var(--cycle));
  `};
`;

export const Content = styled.div<{ $layout?: 'vertical'; $height?: number }>`
  flex: none;
  height: ${p => (p.$height ? `${p.$height}px` : '213px')};
  padding: 20px 8px;
  overflow: hidden;
  position: relative;
  display: grid;
  row-gap: 8px;
  align-content: center;
  justify-content: space-evenly;
  justify-items: center;
  grid-auto-flow: column;
  background-color: ${p => p.theme.colors.roadmaps.template.bg};
  ${p => p.$layout === 'vertical' && `
    grid-auto-flow: row;
    justify-items: start;
    row-gap: 16px;
  `};
`;

export const Footer = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 10px 0 12px;
  height: 48px;
  border-top: 1px solid ${p => p.theme.colors.roadmaps.template.border};
  background-color: ${p => p.theme.colors.roadmaps.template.footer};
`;

export const Title = styled.p`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
`;

const StyledButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'L',
})<{
  $isSelected?: boolean;
}>`
  padding: 0 12px;
  height: 28px;
  border-radius: 9999px;
  gap: 6px;
  :focus {
    --bg: ${p => p.theme.colors.button.secondary.bg};
  }
  :hover {
    --bg: ${p => p.theme.colors.button.secondary.bgHover};
  }
  :active {
    --bg: ${p => p.theme.colors.button.secondary.bgActive};
  }

  ${p => p.$isSelected && `
    padding: 0 12px 0 8px;
    --color: #fff;
    &, :focus {
      --bg: hsl(var(--cycle));
    }
    :hover {
      --bg: hsl(var(--cycle600))
    }
    :active {
      --bg: hsl(var(--cycle700));
    }
  `};
`;

export { StyledButton as Button };

export const Badge = styled.div`
  padding: 0 8px;
  height: 20px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.roadmaps.template.badge.bg};
  color: ${p => p.theme.colors.roadmaps.template.badge.color};
  ${typo.caption600};
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Arrow = styled.div`
  width: 3px;
  height: 100%;
  background-color: ${p => p.theme.colors.roadmaps.template.arrow};
`;

export const DocTypeItem = styled.div<{ $layout?: 'vertical' }>`
  background-color: ${p => p.theme.colors.roadmaps.template.docType.bg};
  padding: 0 12px;
  height: 32px;
  border-radius: 9px;
  ${boxShadowZ2};
  ${typo.body500};
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: scale 0.1s linear;
  :hover {
    scale: 1.1;
  }
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
  ${p => p.$layout === 'vertical' && `
    transform-origin: center left;
  `};
`;

export const SocialProof = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Count = styled.div`
  ${typo.caption400};
  color: ${p => p.theme.colors.text.disabled};
`;
