import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

export const LetterAIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <rect className="stroke--color" x="1.62549" y="1.625" width="12.75" height="12.75" rx="1.375" stroke="black" strokeWidth="1.25" />
    <path d="M6.12601 11.2131H4.78735L7.22466 4.78687H8.7729L11.2136 11.2131H9.87493L8.02582 6.10474H7.97173L6.12601 11.2131ZM6.16996 8.69344H9.82084V9.6285H6.16996V8.69344Z" fill="black" />
  </Svg>
);
