import { GithubOption } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { Layer } from 'src/types/layers.types';

type Props = {
  isVisible: boolean;
  children: JSX.Element;
  onClose: () => void;
  statuses: GithubOption[];
  selectedStatus: string;
  onChange: (option: GithubOption | 'none') => void;
  isLoading: boolean;
};

export const GithubStatusesSelectDropdown: FC<React.PropsWithChildren<Props>> = ({
  isVisible,
  onClose,
  children,
  statuses,
  selectedStatus,
  onChange,
  isLoading,
}) => {
  const options: SelectOption[] = useMemo(() => ([
    {
      label: 'None',
      value: 'none',
      selected: !selectedStatus,
    },
    ...(statuses.map(s => ({
      label: s.name,
      value: s.id,
      selected: selectedStatus === s.id,
    })) || []),
  ]), [statuses, selectedStatus]);

  return (
    <DropdownLayer
      layer={Layer.DropdownModal}
      visible={isVisible}
      hide={onClose}
      placement="bottom-start"
      content={(
        <SelectPanel
          options={options}
          hideSearch
          onOptionChange={(newOption) => {
            if (newOption.value === 'none') {
              onChange('none');
              return;
            }
            const selectedNewStatus = statuses.find(s => s.id === newOption.value);

            if (!selectedNewStatus || selectedNewStatus?.name === selectedStatus) return;

            onChange(selectedNewStatus);
          }}
          isLoading={isLoading}
        />
      )}
    >
      {children}
    </DropdownLayer>
  );
};
