import { ring2 } from '@cycle-app/ui';
import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { DragHandle, DragSection } from 'src/components/EditorDragMenu';

export const Container = styled(NodeViewWrapper)`
  position: relative;
  border: none;
  ${(p) => (p.$hasMinHeight ? css`min-height: 162px;` : css`height: 450px;`)}

  ${DragSection} {
    left: -14px;
  }

  &:hover ${DragHandle} {
    display: flex;
  }
`;

type ContentProps = {
  $isSelected: boolean;
};

export const Content = styled.div<ContentProps>`
  height: fit-content;
  margin-top: 2px;
  border-radius: 6px;
  overflow: hidden;
  ${(p) => p.$isSelected && css`
    ${ring2}
  `}
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
