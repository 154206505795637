import { isSafari, getBrowser } from '@cycle-app/utilities';
import { useEffect, useState } from 'react';

/**
 * Hook to get clipboard read permission state and listen to changes
 */
export const useClipboardReadPermission = () => {
  const [clipboardPermission, setClipboardPermission] = useState<PermissionState | null>(isSafari ? 'prompt' : null);

  useEffect(() => {
    let permissionStatus: PermissionStatus | null = null;

    const onChange = (e: Event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const state = (e.target as any)?.state;
      if (!state) return;
      setClipboardPermission(state);
    };

    if (getBrowser() !== 'firefox' && !isSafari) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator.permissions.query({ name: 'clipboard-read' as PermissionName })
        .then(status => {
          permissionStatus = status;
          setClipboardPermission(permissionStatus.state);
          permissionStatus.addEventListener('change', onChange);
        });
    }

    return () => {
      permissionStatus?.removeEventListener('change', onChange);
    };
  }, []);

  return clipboardPermission;
};
