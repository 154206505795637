import { Button, ActionButton, typo } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const StyledButton = styled(Button)`
  margin-top: 6px;
  margin-top: 32px;
`;

export const StyledModal = styled(PortalModal)`
  height: 382px;
  width: 433px;
`;

export const Title = styled.h3`
  ${typo.headerSmall}
  margin-top: 36px;
`;

export const Content = styled.div`
  text-align: center;
  padding: 0 10px;
`;

export const ImgContainer = styled.div`
  align-items: center;
  display: flex;
  height: 106px;
  justify-content: center;
  margin: 20px auto 0;
  position: relative;
  width: 154px;
`;

export const Check = styled.div`
  align-items: center;
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Green900 : 'hsla(120, 45%, 91%, 1)')};
  border-radius: 100%;
  bottom: 0;
  color:  ${p => (p.theme.isDark ? p.theme.baseColors.Green300 : p.theme.baseColors.Green800)};
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(19%,0%);
  width: 40px;
`;

export const Img = styled.img`
  height: 106px;
  width: 154px;
`;
