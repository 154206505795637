import {
  FilterPropertyRuleDocParentFragment,
  FilterPropertyRuleDoctypeFragment,
  OperatorIsInOrNot,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const useDoctypesFromBoardConfig = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  const doctypesFromFilter = useMemo(
    () => {
      const doctypeFilters = nodeToArray(boardConfig?.filterProperties)
        .find(f => f.__typename === 'FilterPropertyRuleDoctype') as FilterPropertyRuleDoctypeFragment | undefined;
      const allDoctypes = nodeToArray(doctypeFilters?.doctypeRule.values);
      const selectedDoctypes = allDoctypes.filter(f => f.selected);
      const finalDoctypes = selectedDoctypes.length > 0
        ? selectedDoctypes
        : allDoctypes;

      return finalDoctypes.map(({ value }) => value);
    },
    [boardConfig?.filterProperties],
  );

  const parentFilter = useMemo(
    () => nodeToArray(boardConfig?.filterProperties)
      .find(f => f.__typename === 'FilterPropertyRuleDocParent') as FilterPropertyRuleDocParentFragment | undefined,
    [boardConfig?.filterProperties],
  );

  if (!boardConfig) return [];

  const doctypesFromParent: { id: string }[] = [];
  if (
    parentFilter?.docParentRule.__typename === 'RuleDocParentMultipleValues' &&
    parentFilter.docParentRule.operator === OperatorIsInOrNot.Is
  ) {
    // We can have the choice between the doctypes.
    nodeToArray(parentFilter.docParentRule.selectedValues).forEach(node => {
      doctypesFromParent.push(...nodeToArray(node.value.doctype.children));
    });
  }

  if (!doctypesFromParent.length) {
    return doctypesFromFilter;
  }

  return doctypesFromFilter.filter(doctype => doctypesFromParent.some(d => doctype.id === d.id));
};
