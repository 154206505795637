import { InfoColorType } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { body400, body500 } from '../../theme/typo';

export const Container = styled.div<{ $colorType?: InfoColorType; $hasHover: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;

  width: 100%;
  max-width: 100%;
  padding: 16px;
  border-radius: 8px;

  ${p => {
    switch (p.$colorType) {
      case 'danger':
        return css`
          color: ${p.theme.colors.ui.info.danger.text};
          background-color: ${p.theme.colors.ui.info.danger.bg};
          ${p.$hasHover && css`
            &:hover {
              background-color: ${p.theme.colors.ui.info.danger.bgHover};
              cursor: pointer;
            }
          `}
        `;
      default:
        return css`
          color: ${p.theme.colors.ui.info.default.text};
          background-color: ${p.theme.colors.ui.info.default.bg};
          ${p.$hasHover && css`
            &:hover {
              background-color: ${p.theme.colors.ui.info.default.bgHover};
              cursor: pointer;
            }
          `}
        `;
    }
  }}

  > svg {
    flex: none;
    width: 14px;
    height: 14px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
`;

export const P = styled.p<{ $colorType?: InfoColorType }>`
  ${body400}

  ${p => {
    switch (p.$colorType) {
      case 'danger':
        return css`
          color: ${p.theme.colors.ui.info.danger.text};
        `;
      default:
        return css`
          color: ${p.theme.colors.ui.info.default.text};
        `;
    }
  }}

  > a {
    text-decoration: underline;
    display: inline;
    text-underline-offset: 4px;
  }
`;

export const Title = styled.p<{ $colorType?: InfoColorType }>`
  ${body500}
  flex: 1;

  ${p => {
    switch (p.$colorType) {
      case 'danger':
        return css`
          color: ${p.theme.colors.ui.info.danger.title};
        `;
      default:
        return css`
          color: ${p.theme.colors.ui.info.default.title};
        `;
    }
  }}
`;
