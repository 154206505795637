import { useQuery } from '@apollo/client';
import { UserNodeDocument } from '@cycle-app/graphql-codegen';
import { Tooltip, Avatar, Skeleton } from '@cycle-app/ui';
import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react';
import { FC } from 'react';

import { extract } from 'src/types/graphql.types';
import { getUserLabelShortname } from 'src/utils/users.util';

import {
  MentionNodeView,
  TooltipContent,
} from './MentionView.styles';

const MentionView: FC<React.PropsWithChildren<NodeViewRendererProps>> = ({ node }) => {
  const query = useQuery(UserNodeDocument, {
    variables: {
      userId: node.attrs.id,
    },
  });

  const mate = extract('Me', query.data?.node) ?? extract('OtherUser', query.data?.node);

  if (query.loading) return <NodeViewWrapper><Skeleton width={80} height={20} /></NodeViewWrapper>;

  if (!mate) return <NodeViewWrapper />;

  const tooltip = getUserLabelShortname(mate, mate.__typename === 'Me');
  return (
    <MentionNodeView $color={mate.color}>
      <Tooltip
        placement="top"
        withPortal
        color={mate.color}
        size="S"
        content={(
          <TooltipContent>
            <Avatar
              user={mate}
              oppositeColor
            />
            <div>
              {tooltip}
            </div>
          </TooltipContent>
        )}
      >
        {`@${mate.firstName || mate.email}`}
      </Tooltip>
    </MentionNodeView>
  );
};

export default MentionView;
