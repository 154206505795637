import { ExtendTrialDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { addToaster } from 'src/utils/toasters.utils';

import useSafeMutation from './useSafeMutation';

export const useExtendTrial = () => {
  const [mutate, { loading }] = useSafeMutation(ExtendTrialDocument);

  const extendTrial = useCallback(async (productId: string, nbDays: number) => {
    const result = await mutate({
      variables: {
        productId,
        nbDays,
      },
    });

    const isSuccess = !!result.data?.extendFreeTrial;

    if (isSuccess) {
      addToaster({
        message: `The free trial on this workspace has been extended by ${nbDays} days.`,
      });
    }

    return result;
  }, [mutate]);

  return {
    extendTrial,
    isLoading: loading,
  };
};
