import { ThemeType } from '@cycle-app/graphql-codegen';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

interface ThemeState {
  colorTheme: ThemeType;
  syncWithSystem: boolean;
}

export const {
  hookState: useThemeConfig,
  hookValue: useGetThemeConfig,
  setValue: setThemeConfig,
  getValue: getThemeConfig,
  resetValue: resetThemeConfig,
} = make<ThemeState>({
  defaultState: {
    colorTheme: ThemeType.Sunrise,
    syncWithSystem: false,
  },
  localKey: LocalKey.Theme,
  crossTabSync: true,
});

export const useIsEclipseTheme = () => {
  const { colorTheme } = useGetThemeConfig();
  return /^eclipse$/i.test(colorTheme);
};

export const toggleTheme = () => setThemeConfig({
  colorTheme: getThemeConfig().colorTheme === ThemeType.Nightfall ? ThemeType.Sunrise : ThemeType.Nightfall,
  syncWithSystem: false,
});

// Migration Eclipse -> Sunrise
if (getThemeConfig().colorTheme === ThemeType.Eclipse) {
  setThemeConfig({ colorTheme: ThemeType.Sunrise });
}
