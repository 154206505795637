import 'emoji-mart/css/emoji-mart.css';
import { Color } from '@cycle-app/ui/theme/baseColors';
import * as EmojiMart from 'emoji-mart';
import { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, ButtonProps } from '../Buttons';
import { Dropdown, DropdownProps } from '../Dropdown/Dropdown';

export type EmojiData = EmojiMart.EmojiData;

interface Props {
  className?: string;
  onSelect?(emoji: EmojiData): void;
  dropdownProps?: Partial<DropdownProps>;
  forceFocus?: boolean;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
}

export const EmojiPicker: FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  onSelect,
  dropdownProps,
  forceFocus,
  variant = 'nospace',
  size,
}) => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown
      visible={visible}
      hide={() => setVisible(false)}
      content={(
        <EmojiMart.Picker
          autoFocus
          native
          emojiSize={20}
          showPreview={false}
          showSkinTones={false}
          color={Color.Cycle}
          theme={getEmojiPickerTheme()}
          onSelect={onEmojiSelect}
          i18n={{
            categories: {
              recent: 'Recent',
            },
          }}
        />
      )}
      {...dropdownProps}
    >
      <Button
        variant={variant}
        size={size}
        className={`${className} ${forceFocus && visible ? 'force-focus' : ''}`}
        onClick={() => setVisible(!visible)}
      >
        {children}
      </Button>
    </Dropdown>
  );

  function onEmojiSelect(emoji: EmojiData) {
    onSelect?.(emoji);
    setVisible(false);
  }

  function getEmojiPickerTheme() {
    const themeToApply = theme.colors.emojiPicker.theme;
    if (themeToApply === 'light' || themeToApply === 'dark') {
      return themeToApply;
    }
    return 'auto';
  }
};
