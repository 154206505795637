import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { AddIcon as AddIconUI } from '../../../icons';
import { body500 } from '../../../theme/typo';
import { transitionCss } from '../../../utils/transition.util';

const bottomLineCSS = css`
  content: "";
  height: 2px;
  width: 100%;
  background: hsl(var(--cycle));
  position: absolute;
  left: 0;
  bottom: 1px;
  opacity: 0;
  transform: scale(0.75);
  ${transitionCss(['transform', 'opacity'])}
`;

export const Content = styled(motion.div) <{ $hasText: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.components.Tab.bg};
  gap: 4px;
  text-align: left; /** For the tooltip content */
  min-height: 32px;
`;

export const Wrapper = styled.div`
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
`;

interface ContainerProps {
  $isActive: boolean;
  $isDragging: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${body500}
  position: relative;
  cursor: ${p => (p.$isDragging ? 'grabbing' : 'pointer')};
  flex-shrink: 0;
  padding: 0 0 8px 0;
  color: ${p => (p.$isActive
    ? p.theme.colors.components.Tab.textActive
    : p.theme.colors.components.Tab.text)};

  &:focus,
  &:hover:not(:has(button:hover)) {
    outline: none;
    color: ${p => (p.$isActive
    ? p.theme.colors.components.Tab.textActive
    : p.theme.colors.components.Tab.textHover)};

    > ${Content} {
      background-color: ${p => p.theme.colors.components.Tab.bgHover};
    }
  }

  &:active:not(:has(button:hover)) {
    > ${Content} {
      background-color: ${p => p.theme.colors.components.Tab.bgActive};
    }
  }

  &:after {
    ${bottomLineCSS}
    ${p => p.$isActive && css`
      opacity: 1;
      transform: scale(1);
    `}
  }
`;

export const AddIcon = styled(AddIconUI)`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  margin: 4px;
`;

interface AddButtonProps {
  $isAnimated: boolean;
}

export const AddButton = styled(motion.div)
  /* eslint-disable @typescript-eslint/indent */
  .attrs<AddButtonProps>(({ $isAnimated }) => ({
    initial: {
      opacity: 0,
      width: '0px',
    },
    animate: $isAnimated ? {
      opacity: 1,
      width: 'auto',
    } : {},
    transition: {
      duration: 0.15,
    },
  })) <AddButtonProps>`
  /* eslint-enable @typescript-eslint/indent */
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  &:hover {
    background-color: ${p => p.theme.colors.components.Badge.bg};
    border-radius: 4px;
  }
`;
