import { DocBaseFragment, DoctypeRelativeFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji, SelectOption, Warning } from '@cycle-app/ui';
import { CaretIcon, NextArrowIcon } from '@cycle-app/ui/icons';
import { useCallback, useState, useMemo, useEffect, ReactNode } from 'react';
import { Placement } from 'tippy.js';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useUpdateDocDoctype } from 'src/hooks/api/mutations/updateDocHooks';
import { useAllDoctypesFromBoardConfig } from 'src/hooks/api/useAllDoctypesFromBoardConfig';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { setLastDoctypeIdUsed } from 'src/reactives/lastView.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocTypeName, showDocTypeEmoji } from 'src/utils/docType.util';

import {
  DoctypeTag,
  DoctypeTagContent,
  UpdatePreview,
  UpdateInfo,
  DocTag,
  UpdatePreviewDoctypes,
  UpdatePreviewDoctypeTag,
} from './DoctypesOptions.styles';

export type DoctypesOptionsEditableProps = {
  className?: string;
  doc?: Partial<DocBaseFragment>;
  doctype?: DoctypeRelativeFragment;
  possibleDoctypes?: Array<DoctypeRelativeFragment>;
  tooltipDisabled?: boolean;
  tooltipContent?: string;
  dropdownPlacement?: Placement;
  onSelect?: (doctype: DoctypeRelativeFragment) => void;
  showTriangle?: boolean;
  layer?: Layer;
  showLeaveBoardWarnings?: boolean;
  context?: 'doc-item' | 'doc-panel';
  excludeBuiltIn?: boolean;
  children?: (buttonProps: {
    onClick: VoidFunction;
    'data-active': boolean;
    disabled: boolean;
  }) => ReactNode;
};

export const DoctypesOptionsEditable = ({
  className,
  doc,
  doctype: doctypeProps,
  possibleDoctypes,
  tooltipDisabled,
  tooltipContent,
  dropdownPlacement = 'top',
  onSelect,
  showTriangle = false,
  layer = Layer.Dropdown,
  showLeaveBoardWarnings = true,
  context = 'doc-item',
  excludeBuiltIn = true,
  children,
}: DoctypesOptionsEditableProps) => {
  const {
    updateDocDoctype,
    loading,
  } = useUpdateDocDoctype();
  const { docTypes: productDocTypes } = useGetDocTypes();
  const {
    doctypes: boardConfigDoctypes,
    notCompatibleValues,
  } = useAllDoctypesFromBoardConfig();
  const isDraft = doc?.isDraft;
  const docId = doc?.id;

  const doctypes = useMemo(() => {
    if (possibleDoctypes) {
      return possibleDoctypes.filter(d => productDocTypes[d.id]);
    }
    if (boardConfigDoctypes.length && !isDraft) {
      return boardConfigDoctypes.filter(d => productDocTypes[d.id]);
    }
    return Object.values(productDocTypes);
  }, [boardConfigDoctypes, possibleDoctypes, productDocTypes, isDraft]);

  const [isDropdownVisible, {
    toggleCallback: onToggleDropdown,
    setFalseCallback: onHideDropdown,
  }] = useOptimizedBooleanState(false);
  const [doctypeIdToChange, setDoctypeIdToChange] = useState<string | null>(null);

  const registerDoctypeIdToChange = useCallback((option: SelectOption) => setDoctypeIdToChange(option.value), []);
  const resetDoctypeIdToChange = useCallback(() => setDoctypeIdToChange(null), []);
  const confirmChangeDoctype = useCallback(async () => {
    if (!docId || !doctypeIdToChange) return;
    onHideDropdown();
    await updateDocDoctype({
      docId,
      doctypeId: doctypeIdToChange,
      isNotCompatible: notCompatibleValues.includes(doctypeIdToChange),
    });
  }, [docId, doctypeIdToChange, onHideDropdown, updateDocDoctype, notCompatibleValues]);

  const doctype = doctypeProps || doctypes[0];

  const doctypesOptions: SelectOption[] = useMemo(
    () => doctypes
      ?.filter(({ type }) => !excludeBuiltIn || type === DoctypeType.Custom)
      .map((d) => ({
        value: d.id,
        label: d.name,
        end: !doc?.isDraft && showLeaveBoardWarnings && notCompatibleValues.includes(d.id) && (
          <Warning tooltip={`This will make the ${doctype?.name.toLowerCase()} leave the view`} />
        ),
        icon: showDocTypeEmoji(d) && d.emoji ? <Emoji emoji={d.emoji} /> : undefined,
        onSelect: () => {
          onSelect?.(d);
          onHideDropdown();
        },
      })) ?? [],
    [doctypes, notCompatibleValues, onHideDropdown, onSelect, doc?.isDraft, showLeaveBoardWarnings, excludeBuiltIn, doctype?.name],
  );

  const shouldUpdateDocTypeOnChange = !!docId;

  const onSelectChange = useCallback(async (option: SelectOption) => {
    if (isDraft && docId) {
      onHideDropdown();
      await updateDocDoctype({
        docId,
        doctypeId: option.value,
      });
    } else if (shouldUpdateDocTypeOnChange) {
      if (option.value !== doc?.doctype?.id) {
        registerDoctypeIdToChange(option);
      }
    }
  }, [docId, isDraft, onHideDropdown, updateDocDoctype, shouldUpdateDocTypeOnChange, registerDoctypeIdToChange, doc]);

  useEffect(() => {
    if (isDraft && doctype?.id) {
      setLastDoctypeIdUsed(doctype.id);
    }
  }, [isDraft, doctype?.id]);

  const doctypeToChange = useMemo(() => doctypesOptions.find(option => option.value === doctypeIdToChange), [doctypesOptions, doctypeIdToChange]);

  if (!doctype) return null;

  const showEmoji = showDocTypeEmoji(doctype);

  return (
    <>
      <DropdownSelectLayer
        layer={layer}
        placement={dropdownPlacement}
        options={doctypesOptions}
        visible={isDropdownVisible}
        hide={onHideDropdown}
        onChange={onSelectChange}
        selectedValue={doctype.id}
      >
        {children?.({
          onClick: () => {
            if (loading) return;
            onToggleDropdown();
          },
          'data-active': isDropdownVisible,
          disabled: loading,
        }) ?? (
          <DoctypeTag
            className={className}
            onClick={() => {
              if (loading) return;
              onToggleDropdown();
            }}
            tooltip={{
              placement: 'top',
              disabled: tooltipDisabled || context === 'doc-panel',
              title: tooltipContent || (
                <DoctypeTagContent>
                  {showEmoji && <Emoji emoji={doctype.emoji} size={12} />}
                  {getDocTypeName(doctype)}
                </DoctypeTagContent>
              ),
              content: loading ? undefined : 'Click to update',
              withPortal: true,
            }}
            color="grey"
          >
            <DoctypeTagContent $disabled={loading}>
              {showEmoji && <Emoji emoji={doctype.emoji} size={12} />}
              {(context === 'doc-panel' || !showEmoji) && getDocTypeName(doctype)}
              {showTriangle && (
                <CaretIcon direction="bottom" size={10} />
              )}
            </DoctypeTagContent>
          </DoctypeTag>
        )}
      </DropdownSelectLayer>

      {!isDraft && doctypeIdToChange && doc && doctypeToChange && (
        <DialogModal
          title="Update doc type"
          info={(
            <>
              <UpdatePreview>
                <DocTag icon={showEmoji && <Emoji emoji={doctype?.emoji} />}>{doc._docKey}</DocTag>
                <UpdatePreviewDoctypes>
                  <UpdatePreviewDoctypeTag icon={showEmoji && <Emoji emoji={doctype?.emoji} />}>{getDocTypeName(doctype)}</UpdatePreviewDoctypeTag>
                  <NextArrowIcon />
                  <UpdatePreviewDoctypeTag icon={doctypeToChange.icon}>{doctypeToChange.label}</UpdatePreviewDoctypeTag>
                </UpdatePreviewDoctypes>
              </UpdatePreview>
              <UpdateInfo>
                Are you sure you want to update its doc type?
                <br />
                It might lose some properties and hierarchy links.
              </UpdateInfo>
            </>
          )}
          confirmLabel="Update"
          useHighMaskLayer
          hide={resetDoctypeIdToChange}
          onConfirm={confirmChangeDoctype}
        />
      )}
    </>
  );
};
