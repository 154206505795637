import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetRoadmaps,
  getValue: getRoadmaps,
  setValue: setRoadmaps,
} = make({
  defaultState: {
    isTemplateModalOpen: false,
  },
});

export const closeRoadmapsTemplateModal = () => setRoadmaps({ isTemplateModalOpen: false });
export const openRoadmapsTemplateModal = () => setRoadmaps({ isTemplateModalOpen: true });
