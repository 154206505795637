import { Spinner } from '@cycle-app/ui';
import { CommentChatIcon } from '@cycle-app/ui/icons';
import { useRect } from '@cycle-app/utilities';

import { useProductAddOn } from 'src/hooks';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';

import classes from './HomeViewMeetings.module.css';
import { Container, Title } from './HomeViewMeetings.styles';
import { HomeViewMeetingsEmpty } from './HomeViewMeetingsEmpty';
import { HomeViewMeetingsEnabled } from './HomeViewMeetingsEnabled';

const title = (
  <Title>
    <CommentChatIcon />
    My meetings
  </Title>
);

export const HomeViewMeetings = () => {
  const integration = useMeetingsIntegration();
  const meetingsAddon = useProductAddOn('MEETINGS');

  const [ref, isCompact] = useRect(rect => rect.width < 350);

  const isLoading = integration.isLoading || meetingsAddon.isLoading;
  const isInstalled = meetingsAddon.isEnabled && integration.isInstalled;

  return (
    <Container
      className={classes.container}
      ref={ref}
    >
      {title}
      {isLoading && <Spinner />}
      {!isLoading && !isInstalled && <HomeViewMeetingsEmpty />}
      {!isLoading && isInstalled && isCompact !== undefined && <HomeViewMeetingsEnabled isCompact={isCompact} />}
    </Container>
  );
};
