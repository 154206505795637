/* eslint-disable max-len */
import { FC } from 'react';

import { Svg, SvgProps, FILL_INHERIT_CLASS_NAME } from '../../components/Svg/Svg.styles';

export const DocumentAddHoverIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 2.5C17.6642 2.5 18 2.83579 18 3.25V8.58333C18 8.73804 18.0615 8.88642 18.1709 8.99581C18.2803 9.10521 18.4286 9.16667 18.5833 9.16667H23.9167C24.3309 9.16667 24.6667 9.50245 24.6667 9.91667C24.6667 10.3309 24.3309 10.6667 23.9167 10.6667H18.5833C18.0308 10.6667 17.5009 10.4472 17.1102 10.0565C16.7195 9.66577 16.5 9.13587 16.5 8.58333V3.25C16.5 2.83579 16.8358 2.5 17.25 2.5Z"
      fill="#C4C4C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91667 4C7.40833 4 6.92082 4.20193 6.56138 4.56138C6.20193 4.92082 6 5.40833 6 5.91667V24.5833C6 25.0917 6.20193 25.5792 6.56138 25.9386C6.92082 26.2981 7.40834 26.5 7.91667 26.5H21.25C21.7583 26.5 22.2458 26.2981 22.6053 25.9386C22.9647 25.5792 23.1667 25.0917 23.1667 24.5833V10.2273L16.9393 4H7.91667ZM5.50072 3.50072C6.14147 2.85997 7.01051 2.5 7.91667 2.5H17.25C17.4489 2.5 17.6397 2.57902 17.7803 2.71967L24.447 9.38634C24.5877 9.52699 24.6667 9.71775 24.6667 9.91667V24.5833C24.6667 25.4895 24.3067 26.3585 23.6659 26.9993C23.0252 27.64 22.1562 28 21.25 28H7.91667C7.01051 28 6.14147 27.64 5.50072 26.9993C4.85997 26.3585 4.5 25.4895 4.5 24.5833V5.91667C4.5 5.01051 4.85997 4.14147 5.50072 3.50072Z"
      fill="#C4C4C4"
    />
    <path
      className={FILL_INHERIT_CLASS_NAME}
      d="M29.5 22.5C29.5 26.9183 25.9183 30.5 21.5 30.5C17.0817 30.5 13.5 26.9183 13.5 22.5C13.5 18.0817 17.0817 14.5 21.5 14.5C25.9183 14.5 29.5 18.0817 29.5 22.5Z"
    />
    <path
      className="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5001 18.75C21.8139 18.75 22.0682 19.0044 22.0682 19.3182V25.6818C22.0682 25.9956 21.8139 26.25 21.5001 26.25C21.1863 26.25 20.9319 25.9956 20.9319 25.6818V19.3182C20.9319 19.0044 21.1863 18.75 21.5001 18.75Z"
      fill="white"
    />
    <path
      className="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.75 22.4998C17.75 22.186 18.0044 21.9316 18.3182 21.9316H24.6818C24.9956 21.9316 25.25 22.186 25.25 22.4998C25.25 22.8136 24.9956 23.068 24.6818 23.068H18.3182C18.0044 23.068 17.75 22.8136 17.75 22.4998Z"
      fill="white"
    />
  </Svg>
);
