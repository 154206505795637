import { SelectPanel, SelectLine } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledSelectPanel = styled(SelectPanel)`
  min-width: 250px;
  max-width: 300px;
  max-height: 290px;
  gap: 0;
`;

export const PanelHeader = styled.div`
  margin: 0 2px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const PanelFooter = styled(SelectLine)`
  margin: 0 -12px;
  padding: 0 16px;
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 8px;
`;
