import styled, { css } from 'styled-components';

export const Steps = styled.div`
  display: flex;
  align-items: center;
`;

interface StepProps {
  $isActive: boolean;
  $isClickable: boolean;
}

export const Step = styled.div<StepProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  background-color: ${p => p.theme.colors.background.hover};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;

  ${p => p.$isClickable && css`
    cursor: pointer;

    ${!p.$isActive && css`
      &:hover {
        color: ${p.theme.colors.text.primary};
      }
    `}
  `}

  ${p => p.$isActive && css`
    background-color: hsl(var(--cycle));
    color: white;
    pointer-events: none;
  `}
`;

export const Bar = styled.div`
  background-color: ${p => p.theme.colors.background.hover};
  height: 1px;
  width: 40px;
`;
