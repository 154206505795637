import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { Container } from './GithubDescription.styles';

type Props = {
  description: string;
};

export const GithubDescription: FC<React.PropsWithChildren<Props>> = ({ description }) => (
  <Container>
    <ReactMarkdown>{description}</ReactMarkdown>
  </Container>
);
