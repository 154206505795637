import {
  CommentFragment,
  EditDocCommentDocument,
  EditDocCommentMutation,
  EditDocCommentMutationVariables,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { getMeFromCache } from 'src/utils/cache.utils';

export const useEditComment = () => {
  const [mutate, { loading }] = useSafeMutation(EditDocCommentDocument);

  const editComment = useCallback((docId: string, {
    createdAt, ...variables
  }: EditDocCommentMutationVariables & Pick<CommentFragment, 'createdAt'>) => mutate({
    variables,
    optimisticResponse: getOptimisticResponse(docId, {
      ...variables,
      createdAt,
    }),
  }), []);

  return {
    editComment,
    isEditingComment: loading,
  };
};

const getOptimisticResponse = (docId: string, variables: EditDocCommentMutationVariables & Pick<CommentFragment, 'createdAt'>): EditDocCommentMutation | undefined => {
  const creator = getMeFromCache();
  if (!creator) return undefined;
  return {
    editComment: {
      __typename: 'Comment',
      id: variables.commentId,
      content: variables.content,
      creator,
      updatedAt: new Date().toISOString(),
      createdAt: variables.createdAt,
      doc: {
        __typename: 'Doc',
        id: docId,
      },
    },
  };
};
