import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Trigger = styled.div`
  cursor: pointer;
`;

export const ProgressionLabelContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 6px;
`;

export const Text = styled.p`
  ${typo.tiny400}
`;
