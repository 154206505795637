import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import * as typo from '../../theme/typo';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 24px;
  padding: 20px;
  box-shadow: 0px 2px 5px ${p => p.theme.colors.billing.section.shadow};
  border: 1px solid ${p => p.theme.colors.billing.section.border};
  background-color: ${p => p.theme.colors.billing.section.bg};
  user-select: text;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
`;

export const Makers = styled.div`
  margin-left: auto;
  overflow: hidden;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: 8px;

  ${mobile} {
    flex-direction: column;
  }
`;

export const Body = styled.div`
  ${typo.body400}
`;

interface PlanStatusProps {
  $status: 'active' | 'failed' | 'canceled';
}

export const PlanStatus = styled.div<PlanStatusProps>`
  ${typo.body500}
  --bg: rgba(84, 180, 127, 0.08);
  --dot: #54B47F;
  background: var(--bg);
  padding: 2px 10px;
  border-radius: 50px;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;

  ${p => (p.$status === 'failed' || p.$status === 'canceled') && `
    --bg: rgba(233, 16, 16, 0.08);
    --dot: rgba(233, 16, 16, 1);
  `}

  &::before {
    background-color: var(--dot);
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }
`;
