import { typo, SelectPanel } from '@cycle-app/ui';
import styled from 'styled-components';

export const Title = styled.div`
  ${typo.body500}
  text-align: center;
`;

export const StyledSelectPanel = styled(SelectPanel)`
  padding-bottom: 16px;
`;
