import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ShortcutIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.625 12.375H2.75C2.28587 12.375 1.84075 12.1906 1.51256 11.8624C1.18437 11.5342 1 11.0891 1 10.625V2.75C1 2.28587 1.18437 1.84075 1.51256 1.51256C1.84075 1.18437 2.28587 1 2.75 1H10.625C11.0891 1 11.5342 1.18437 11.8624 1.51256C12.1906 1.84075 12.375 2.28587 12.375 2.75V3.625H10.625V2.75H2.75V10.625H3.625V12.375Z" />
    <path d="M7.5625 7.125C7.44647 7.125 7.33519 7.17109 7.25314 7.25314C7.17109 7.33519 7.125 7.44647 7.125 7.5625C7.125 7.67853 7.17109 7.78981 7.25314 7.87186C7.33519 7.95391 7.44647 8 7.5625 8H8V7.5625C8 7.44647 7.95391 7.33519 7.87186 7.25314C7.78981 7.17109 7.67853 7.125 7.5625 7.125Z" />
    <path d="M12.2469 7.25314C12.3289 7.33519 12.375 7.44647 12.375 7.5625C12.375 7.67853 12.3289 7.78981 12.2469 7.87186C12.1648 7.95391 12.0535 8 11.9375 8H11.5V7.5625C11.5 7.44647 11.5461 7.33519 11.6281 7.25314C11.7102 7.17109 11.8215 7.125 11.9375 7.125C12.0535 7.125 12.1648 7.17109 12.2469 7.25314Z" />
    <path d="M7.125 11.9375C7.125 12.0535 7.17109 12.1648 7.25314 12.2469C7.33519 12.3289 7.44647 12.375 7.5625 12.375C7.67853 12.375 7.78981 12.3289 7.87186 12.2469C7.95391 12.1648 8 12.0535 8 11.9375V11.5H7.5625C7.44647 11.5 7.33519 11.5461 7.25314 11.6281C7.17109 11.7102 7.125 11.8215 7.125 11.9375Z" />
    <path d="M8.875 8.875H10.625V10.625H8.875V8.875Z" />
    <path d="M12.2469 12.2469C12.1648 12.3289 12.0535 12.375 11.9375 12.375C11.8215 12.375 11.7102 12.3289 11.6281 12.2469C11.5461 12.1648 11.5 12.0535 11.5 11.9375V11.5H11.9375C12.0535 11.5 12.1648 11.5461 12.2469 11.6281C12.3289 11.7102 12.375 11.8215 12.375 11.9375C12.375 12.0535 12.3289 12.1648 12.2469 12.2469Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.25 4.5H6.25C5.78587 4.5 5.34075 4.68437 5.01256 5.01256C4.68437 5.34075 4.5 5.78587 4.5 6.25V13.25C4.5 13.7141 4.68437 14.1592 5.01256 14.4874C5.34075 14.8156 5.78587 15 6.25 15H13.25C13.7141 15 14.1592 14.8156 14.4874 14.4874C14.8156 14.1592 15 13.7141 15 13.25V6.25C15 5.78587 14.8156 5.34075 14.4874 5.01256C14.1592 4.68437 13.7141 4.5 13.25 4.5ZM11.9375 10.625C12.1971 10.625 12.4508 10.702 12.6667 10.8462C12.8825 10.9904 13.0508 11.1954 13.1501 11.4352C13.2494 11.6751 13.2754 11.939 13.2248 12.1936C13.1741 12.4482 13.0491 12.682 12.8656 12.8656C12.682 13.0491 12.4482 13.1741 12.1936 13.2248C11.939 13.2754 11.6751 13.2494 11.4352 13.1501C11.1954 13.0508 10.9904 12.8825 10.8462 12.6667C10.702 12.4508 10.625 12.1971 10.625 11.9375V11.5H8.875V11.9375C8.875 12.1971 8.79802 12.4508 8.6538 12.6667C8.50958 12.8825 8.3046 13.0508 8.06477 13.1501C7.82494 13.2494 7.56104 13.2754 7.30644 13.2248C7.05184 13.1741 6.81798 13.0491 6.63442 12.8656C6.45087 12.682 6.32586 12.4482 6.27522 12.1936C6.22458 11.939 6.25057 11.6751 6.34991 11.4352C6.44925 11.1954 6.61747 10.9904 6.83331 10.8462C7.04915 10.702 7.30291 10.625 7.5625 10.625H8V8.875H7.5625C7.30291 8.875 7.04915 8.79802 6.83331 8.6538C6.61747 8.50958 6.44925 8.3046 6.34991 8.06477C6.25057 7.82494 6.22458 7.56104 6.27522 7.30644C6.32586 7.05184 6.45087 6.81798 6.63442 6.63442C6.81798 6.45087 7.05184 6.32586 7.30644 6.27522C7.56104 6.22458 7.82494 6.25057 8.06477 6.34991C8.3046 6.44925 8.50958 6.61747 8.6538 6.83331C8.79802 7.04915 8.875 7.30291 8.875 7.5625V8H10.625V7.5625C10.625 7.30291 10.702 7.04915 10.8462 6.83331C10.9904 6.61747 11.1954 6.44925 11.4352 6.34991C11.6751 6.25057 11.939 6.22458 12.1936 6.27522C12.4482 6.32586 12.682 6.45087 12.8656 6.63442C13.0491 6.81798 13.1741 7.05184 13.2248 7.30644C13.2754 7.56104 13.2494 7.82494 13.1501 8.06477C13.0508 8.3046 12.8825 8.50958 12.6667 8.6538C12.4508 8.79802 12.1971 8.875 11.9375 8.875H11.5V10.625H11.9375Z" />
  </Svg>
);
