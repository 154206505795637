import { Dropdown, DropdownProps } from '@cycle-app/ui';
import { isTouchDevice } from '@cycle-app/utilities';
import { FC, useEffect, useRef } from 'react';
import { Instance } from 'tippy.js';

import Portal from 'src/components/Portal/Portal';
import { mappingZindex } from 'src/constants/zIndex.constant';
import useDropdown from 'src/hooks/useDropdown';
import { setClosingArea } from 'src/reactives/closingArea.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';

import { ClosingArea } from './DropdownLayer.styles';

/**
 * The purpose of this component is to serve <Dropdown /> from @cycle-app/ui
 * and update the local state Layer on mount/hide
 */

export interface DropdownLayerProps extends DropdownProps {
  layer?: Layer;
  closingArea?: boolean;
  disableDocPreview?: boolean;
}

export const DropdownLayer: FC<React.PropsWithChildren<DropdownLayerProps>> = ({
  layer = Layer.Dropdown,
  visible,
  hide: hideProps,
  onMount: onMountProps,
  onHide: onHideProps,
  closingArea = true,
  disableDocPreview = true,
  ...props
}) => {
  const docPreviewEnabledInitialValue = useRef<boolean>(getDocIdPreview().enabled);

  const dropdownProps = useDropdown({
    layer,
    hide: hideProps,
    onDropdownMounted: (instance: Instance) => {
      setDocIdPreview({ enabled: !disableDocPreview });
      onMountProps?.(instance);
    },
    onDropdownHidden: () => {
      if (docPreviewEnabledInitialValue.current === true && disableDocPreview) {
        setDocIdPreview({ enabled: true });
      }
      onHideProps?.();
    },
  });

  useEffect(() => {
    if (!closingArea) return;
    setClosingArea({ displayed: visible ?? false });
  }, [closingArea, visible]);

  // Ensure that ClosingArea is hidden when Dropdown is unmounted
  useEffect(() => () => {
    setClosingArea({ displayed: false });
  }, []);

  return (
    <>
      <Dropdown
        {...props}
        {...dropdownProps}
        visible={visible}
        zIndex={mappingZindex[layer]}
        // Prevent Tippy from closing ClosingArea before it stops the event propagation
        hide={isTouchDevice() && closingArea ? undefined : dropdownProps.hide}
      />
      {closingArea && visible && (
        <Portal portalId={PortalId.DropdownClosingArea}>
          <ClosingArea
            layer={layer}
            onClick={e => {
              e.stopPropagation();
              setClosingArea({ displayed: false });
              if (isTouchDevice()) dropdownProps.hide();
            }}
          />
        </Portal>
      )}
    </>
  );
};

export default DropdownLayer;
