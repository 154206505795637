import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GoogleFormIcon: FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <path d="M9.51706 0H3.0692C2.46194 0 1.93115 0.533099 1.93115 1.14299V14.857C1.93115 15.4663 2.46194 16 3.0692 16H12.9306C13.5372 16 14.0686 15.4669 14.0686 14.857V4.57134L11.4134 2.66677L9.51706 0Z" fill="#673AB7" />
    <path d="M6.48274 11.8089H11.0343V11.0473H6.48274V11.8089ZM6.48274 6.85674V7.61831H11.0343V6.85674H6.48274ZM5.79966 7.23816C5.79966 7.54279 5.57218 7.84742 5.19304 7.84742C4.81391 7.84742 4.58643 7.61895 4.58643 7.23816C4.58643 6.85738 4.81391 6.62891 5.19304 6.62891C5.57218 6.62891 5.79966 6.93289 5.79966 7.23816ZM5.79966 9.3712C5.79966 9.67582 5.57218 9.98045 5.19304 9.98045C4.81391 9.98045 4.58643 9.75198 4.58643 9.3712C4.58643 8.99041 4.81391 8.76194 5.19304 8.76194C5.57218 8.76194 5.79966 9.06657 5.79966 9.3712ZM5.79966 11.4281C5.79966 11.7327 5.57218 12.0373 5.19304 12.0373C4.81391 12.0373 4.58643 11.8089 4.58643 11.4281C4.58643 11.0473 4.81391 10.8188 5.19304 10.8188C5.57218 10.8188 5.79966 11.1234 5.79966 11.4281ZM6.48274 9.9043H11.0343V9.14209H6.48274V9.9043Z" fill="#F1F1F1" />
    <path d="M9.82031 4.2666L14.0685 8.45715V4.57123L9.82031 4.2666Z" fill="url(#paint0_linear_3374_3635)" />
    <path d="M9.51709 0V3.42834C9.51709 4.0376 10.0479 4.57134 10.6551 4.57134H14.0686L9.51709 0Z" fill="#B39DDB" />
    <path d="M3.0692 0C2.46194 0 1.93115 0.533099 1.93115 1.14299V1.21915C1.93115 0.609256 2.46194 0.076157 3.0692 0.076157H9.51706V0H3.0692Z" fill="white" fillOpacity="0.2" />
    <path d="M12.9306 15.9233H3.0692C2.46194 15.9233 1.93115 15.3902 1.93115 14.7803V14.8564C1.93115 15.4657 2.46194 15.9994 3.0692 15.9994H12.9306C13.5372 15.9994 14.0686 15.4663 14.0686 14.8564V14.7803C14.0686 15.3902 13.5372 15.9233 12.9306 15.9233Z" fill="#311B92" fillOpacity="0.2" />
    <path d="M10.6546 4.57171C10.048 4.57171 9.5166 4.03861 9.5166 3.42871V3.50487C9.5166 4.11412 10.0474 4.64786 10.6546 4.64786H14.0681V4.57171H10.6546Z" fill="#311B92" fillOpacity="0.1" />
    <path d="M9.51706 0H3.0692C2.46194 0 1.93115 0.533099 1.93115 1.14299V14.857C1.93115 15.4663 2.46194 16 3.0692 16H12.9306C13.5372 16 14.0686 15.4669 14.0686 14.857V4.57134L9.51706 0Z" fill="url(#paint1_radial_3374_3635)" />
    <defs>
      <linearGradient id="paint0_linear_3374_3635" x1="11.9442" y1="4.60011" x2="11.9442" y2="8.47318" gradientUnits="userSpaceOnUse">
        <stop stopColor="#311B92" stopOpacity="0.2" />
        <stop offset="1" stopColor="#311B92" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient id="paint1_radial_3374_3635" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.31514 0.426993) scale(156.558 157.238)">
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </Svg>
);
