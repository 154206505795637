import { TemplateType } from '@cycle-app/graphql-codegen';

export type TemplateDocType = {
  name: string;
  emoji: string;
  icons?: string[];
};

export type TemplateDocTypeKey = 'outcome' | 'goal' | 'dev' | 'problem' | 'feature' | 'improvement' | 'bug' | 'devTask' | 'experiment' | 'fact' | 'objective' | 'impact';
export type TemplateHierarchy = TemplateDocTypeKey[][];
export type Template = {
  type: TemplateType;
  name: string;
  isRecommended?: boolean;
  hierarchy: TemplateHierarchy;
  layout?: 'vertical';
  socialProof?: {
    tooltip: string;
    img: string;
    count: number;
  };
};

export const docTypes: Record<TemplateDocTypeKey, TemplateDocType> = {
  outcome: {
    name: 'Outcome',
    emoji: '🎯',
  },
  goal: {
    name: 'Goal',
    emoji: '⛰️',
  },
  dev: {
    name: 'Dev',
    emoji: '💻',
  },
  problem: {
    name: 'Problem',
    emoji: '🧐',
    icons: ['insight'],
  },
  feature: {
    name: 'Feature',
    emoji: '🌱',
    icons: ['insight', 'release'],
  },
  improvement: {
    name: 'Improvement',
    emoji: '🍄',
    icons: ['insight', 'release'],
  },
  bug: {
    name: 'Bug',
    emoji: '🐞',
    icons: ['insight', 'release'],
  },
  devTask: {
    name: 'Dev task',
    emoji: '💻',
  },
  experiment: {
    name: 'Experiment',
    emoji: '🧪',
  },
  fact: {
    name: 'Fact',
    emoji: '🧩',
  },
  objective: {
    name: 'Objective',
    emoji: '🎯',
  },
  impact: {
    name: 'Impact',
    emoji: '💪',
    icons: ['insight'],
  },
};

export const templates: Template[] = [
  {
    type: TemplateType.OutcomeFeature,
    name: 'Outcome > Feature',
    hierarchy: [
      ['outcome', 'feature'],
    ],
    isRecommended: true,
    socialProof: {
      tooltip: 'Chosen by eFounders, Mention, Passionfroot & 90 other teams',
      img: '/images/social-proof-1.png',
      count: 90,
    },
  },
  {
    type: TemplateType.FeatureDev,
    name: 'Feature > Dev',
    hierarchy: [
      ['feature', 'dev'],
    ],
    isRecommended: true,
    socialProof: {
      tooltip: 'Chosen by June, Swan, Dopt & 60 other teams',
      img: '/images/social-proof-2.png',
      count: 60,
    },
  },
  {
    type: TemplateType.GoalOutcomeFeature,
    name: 'Goal > Outcome > Feature',
    hierarchy: [
      ['goal', 'outcome', 'feature'],
    ],
    isRecommended: true,
    socialProof: {
      tooltip: 'Chosen by Elba, Lazy, Mention & 75 other teams',
      img: '/images/social-proof-3.png',
      count: 75,
    },
  },
  {
    type: TemplateType.GoalOutcomeFeatureDev,
    name: 'Goal > Outcome > Feature > Dev',
    hierarchy: [
      ['goal', 'outcome', 'feature', 'dev'],
    ],
  },
  // {
  //   type: TemplateType.ProblemSolution_1,
  //   name: 'Problem / Solution',
  //   isRecommended: true,
  //   hierarchy: [
  //     ['problem', 'feature'],
  //   ],
  //   socialProof: {
  //     tooltip: 'Chosen by eFounders, Mention, Passionfroot & 90 other teams',
  //     img: '/images/social-proof-1.png',
  //     count: 90,
  //   },
  // },
  // {
  //   type: TemplateType.BuildRunBug,
  //   name: 'Features + Run',
  //   isRecommended: true,
  //   layout: 'vertical',
  //   hierarchy: [
  //     ['feature'],
  //     ['improvement'],
  //     ['bug'],
  //   ],
  //   socialProof: {
  //     tooltip: 'Chosen by June, Swan, Dopt & 60 other teams',
  //     img: '/images/social-proof-2.png',
  //     count: 60,
  //   },
  // },
  // {
  //   type: TemplateType.ProblemSolution_2,
  //   name: 'Problem / Solution + Run',
  //   isRecommended: true,
  //   hierarchy: [
  //     ['problem', 'feature'],
  //     ['improvement'],
  //     ['bug'],
  //   ],
  //   socialProof: {
  //     tooltip: 'Chosen by Elba, Lazy, Mention & 75 other teams',
  //     img: '/images/social-proof-3.png',
  //     count: 75,
  //   },
  // },
  // {
  //   type: TemplateType.ObjectiveFeatureTask,
  //   name: 'Problem / Solution & Tasks',
  //   hierarchy: [
  //     ['problem', 'feature', 'devTask'],
  //   ],
  // },
  // {
  //   type: TemplateType.BuildRunBugDelivery,
  //   name: 'Features + Run & Tasks',
  //   hierarchy: [
  //     ['feature', 'devTask'],
  //     ['improvement', 'devTask'],
  //     ['bug', 'devTask'],
  //   ],
  // },
  // {
  //   type: TemplateType.ProblemSolution_3,
  //   name: 'Atomic Research',
  //   hierarchy: [
  //     ['problem', 'feature'],
  //     ['experiment', 'fact'],
  //   ],
  // },
  // {
  //   type: TemplateType.ImpactMapping,
  //   name: 'Impact Mapping',
  //   hierarchy: [
  //     ['objective', 'impact', 'feature'],
  //     ['improvement'],
  //     ['bug'],
  //   ],
  // },
];
