/* eslint-disable no-continue */
import { DocBaseFragment, OperatorIsInOrNot } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import groupBy from 'lodash/groupBy';
import { isPresent } from 'ts-is-present';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useUpdateDocsGroup, useGetGroup, useGetGroupNoFiltered } from 'src/hooks/api/cache/cacheGroupHooks';
import { useMoveDocs } from 'src/hooks/api/mutations/useMoveDoc';
import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { setBulkLoading } from 'src/reactives/selection.reactive';
import { isStatusDefinition } from 'src/types/graphql.types';
import { getDocFromCache } from 'src/utils/cache.utils';

import { useStatusToaster } from './useStatusToaster';
import { useUpdateDocsStatusMutation } from './useUpdateDocsStatusMutation';

export const useUpdateDocsStatus = () => {
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const addStatusToaster = useStatusToaster();
  const { groups } = useBoardGroups();
  const moveMutation = useMoveDocs();
  const updateMutation = useUpdateDocsStatusMutation();
  const getGroup = useGetGroup();
  const getGroupNoFiltered = useGetGroupNoFiltered();
  const updateGroup = useUpdateDocsGroup();
  const isCompatible = useIsCompatible();

  const updateDocsStatus = async (docIds: string[], statusId: string, undoable = true) => {
    const docsByStatus = groupBy(docIds.map(getDocFromCache).filter(isPresent), 'status.id');
    const undo = () => {
      return Promise.all(Object.entries(docsByStatus).map(([prevStatusId, docsFromGroup]) => {
        return updateDocsStatus(docsFromGroup.map(doc => doc.id), prevStatusId, false);
      }));
    };

    if (!isCompatible(statusId)) {
      filterGroups(docIds);
      await updateMutation.updateDocsStatus(docIds, statusId);
    } else if (isStatusDefinition(groupByProperty)) {
      await moveDocs(docIds, statusId);
    } else {
      await updateMutation.updateDocsStatus(docIds, statusId);
    }

    addStatusToaster(docIds, statusId, undoable ? undo : undefined);
  };

  const filterGroups = (docIds: string[]) => {
    if (!groups) return;
    for (const group of Object.keys(groups).map(id => (groupByProperty ? getGroup(id) : getGroupNoFiltered(id))).filter(isPresent)) {
      if (boardConfig && (group.node.__typename === 'DocGroupWithPropertyValue' || group.node.__typename === 'DocGroupWithoutPropertyValue')) {
        updateGroup({
          groupData: group,
          updatedDocs: group.node.docs.edges
            .filter(edge => !docIds.includes(edge.node.id))
            .map(edge => edge.node),
          boardConfigId: boardConfig.id,
        });
      }
    }
  };

  const moveDocs = (docIds: string[], statusId: string) => {
    if (!groups || !boardConfig) return null;
    const groupIds = Object.keys(groups);

    const [docs, previousGroupIds, hiddenDocs] = docIds.reduce<[DocBaseFragment[], string[], DocBaseFragment[]]>((acc, docId) => {
      const doc = getDocFromCache(docId);
      if (!doc) return acc;
      const groupId = groupIds.find(id => !!groups[id]?.docs[doc.id]);
      if (!groupId) {
        acc[2].push(doc);
        return acc;
      }
      if (doc.status?.id === statusId) return acc;
      acc[0].push(doc);
      acc[1].push(groupId);
      return acc;
    }, [[], [], []]);

    const previousGroups = previousGroupIds.map(id => groups[id]);
    const groupIdsOfNewValue = previousGroups.map(previousGroup => {
      return groupIds.find(id => {
        return groups[id]?.attributeValueId === statusId && groups[id]?.swimlaneIndex === previousGroup?.swimlaneIndex;
      });
    });

    const promises: (Promise<unknown> | null)[] = [];

    const docsByNewGroup = groupIdsOfNewValue.reduce<Record<string, DocBaseFragment[]>>((acc, id, index) => {
      if (!id) return acc;
      const doc = docs[index];
      if (!doc) return acc;
      acc[id] = [...(acc[id] ?? []), doc];
      return acc;
    }, {});

    // If the docs come from hidden groups, update their statuses
    if (hiddenDocs.length) {
      promises.push(updateMutation.updateDocsStatus(hiddenDocs.map(doc => doc.id), statusId));
    }

    for (const [groupIdOfNewValue, newDocsInNewGroup] of Object.entries(docsByNewGroup)) {
      if (!groupIdOfNewValue) {
        const ids = newDocsInNewGroup.map(d => d.id);
        filterGroups(ids);
        promises.push(updateMutation.updateDocsStatus(ids, statusId));
        continue;
      }

      const docsFromNewGroup = Object.values(groups[groupIdOfNewValue]?.docs ?? {});

      // If the docs come from hidden groups (when undoing), add them to the new group
      if (hiddenDocs.length) {
        const groupOfNewValue = getGroup(groupIdOfNewValue);
        if (groupOfNewValue) {
          updateGroup({
            groupData: groupOfNewValue,
            updatedDocs: [
              ...hiddenDocs,
              ...nodeToArray(groupOfNewValue.node.docs),
            ],
            boardConfigId: boardConfig.id,
          });
        }
        continue;
      }

      promises.push(moveMutation.moveDocs({
        groupId: groupIdOfNewValue,
        previousGroupIds,
        docsMoved: newDocsInNewGroup,
        docsListUpdated: [...newDocsInNewGroup, ...docsFromNewGroup],
        position: {
          before: docsFromNewGroup.length ? (docsFromNewGroup[0]?.id ?? '') : '',
        },
        groupByProperty,
        boardConfigId: boardConfig?.id,
      }));
    }

    if (promises.length > 0) setBulkLoading({ isLoading: true });
    return Promise.all(promises);
  };

  return {
    updateDocsStatus,
    loading: moveMutation.loading || updateMutation.loading,
  };
};

const useIsCompatible = () => {
  const filterProperties = useBoardConfig(ctx => ctx.boardConfig?.filterProperties);
  const docQuery = useBoardConfig(ctx => ctx.boardConfig?.docQuery);
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);

  return (statusId: string) => {
    // Is the doc hidden because of hidden group
    if (isStatusDefinition(groupByProperty)) {
      if (docQuery?.__typename === 'BoardQueryWithGroupBy') {
        const groups = nodeToArray(docQuery.docGroups);
        if (!groups.some(g => g.propertyValue?.id === statusId)) return false;
      }
      if (docQuery?.__typename === 'BoardQueryWithSwimlaneBy') {
        const swimlanes = nodeToArray(docQuery.swimlanes);
        const groups = swimlanes.flatMap(s => nodeToArray(s.docGroups));
        if (!groups.some(g => g.propertyValue?.id === statusId)) return false;
      }
    }

    // Is the doc hidden because of filter property
    return nodeToArray(filterProperties).every(property => {
      if (property.__typename !== 'FilterPropertyRuleStatus') return true;
      return nodeToArray(property.statusRule.values)
        .filter(value => value.selected)
        .some(value => (property.statusRule.operator === OperatorIsInOrNot.Is
          ? value.value.id === statusId
          : value.value.id !== statusId
        ));
    });
  };
};
