import { TextButton, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledTextButton = styled(TextButton)`
  overflow: visible;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.caption400};

  padding: 2px 8px;
  border-radius: 6px;
  --bgHover: ${p => p.theme.colors.board.textButton.bgHover};
  --bgActive: ${p => p.theme.colors.board.textButton.bgHover};
  --colorHover: ${p => p.theme.colors.board.textButton.colorHover};
`;
