import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export type PasteData = {
  text: string;
  pos: number;
};

export type EventHandlerParams = {
  handlePaste?: (data: PasteData) => void;
};

/**
 * This extension is used to handle events like paste in a tiptap editor.
 * @param handlePaste - Callback function that is called when a paste event is triggered.
 */
export const getEventHandlerExtension = ({ handlePaste }: EventHandlerParams) => Extension.create({
  name: 'eventHandler',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handlePaste(view, event) {
            const anchor = view.state.selection.$anchor;
            const text = event.clipboardData?.getData('text').trim();
            if (text && anchor.depth > 0) {
              handlePaste?.({
                text,
                pos: anchor.before(),
              });
            }
            return false;
          },
        },
      }),
    ];
  },
});
