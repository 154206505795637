import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
  NextLink,
} from '@apollo/client';
import { print, GraphQLError, OperationDefinitionNode } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';

export class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation, forward?: NextLink): Observable<FetchResult> {
    const operationType = (operation.query.definitions.find((def) => def.kind === 'OperationDefinition') as OperationDefinitionNode).operation;

    if (operationType !== 'subscription' && forward) {
      return forward(operation);
    }

    return new Observable((sink) => this.client.subscribe<FetchResult>(
      {
        ...operation,
        query: print(operation.query),
      },
      {
        next: sink.next.bind(sink),
        complete: sink.complete.bind(sink),
        error: (err) => {
          console.log('error', err);
          if (err instanceof Error) {
            return sink.error(err);
          }

          if (err instanceof CloseEvent) {
            return sink.error(
              // reason will be available on clean closes
              new Error(
                `Socket closed with event ${err.code} ${err.reason || ''}`,
              ),
            );
          }

          return sink.error(
            new Error(
              (err as GraphQLError[])
                .map(({ message }) => message)
                .join(', '),
            ),
          );
        },
      },
    ));
  }
}
