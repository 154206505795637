import { RemoveReleaseDocument } from '@cycle-app/graphql-codegen';

import { useReleasesContext } from 'src/contexts/releasesContext';
import type { UseReleaseNotesReturn } from 'src/hooks/releases/useReleaseNotes';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { useReleaseNotesCache } from './useReleaseNotesCache';


type RemoveOptions = {
  releasesNoteToMove: {
    main: UseReleaseNotesReturn;
    other: UseReleaseNotesReturn;
  };
  releasesNoteNoValue: {
    main: UseReleaseNotesReturn;
    other: UseReleaseNotesReturn;
  };
};

export const useRemoveRelease = () => {
  const [mutate] = useSafeMutation(RemoveReleaseDocument);
  const releasesNoValue = useReleasesContext(ctx => ctx.releaseNoValue);
  const { prenpendReleaseNotes } = useReleaseNotesCache();

  const removeRelease = (releaseId: string, options: RemoveOptions) => {
    return mutate({
      variables: {
        id: releaseId,
      },
      optimisticResponse: {
        removeRelease: {
          id: releaseId,
        },
      },
      update: (cache, { data }) => {
        cache.evict({ id: data?.removeRelease?.id });
        cache.gc();

        if (!releasesNoValue) return;

        prenpendReleaseNotes({
          releaseId: releasesNoValue.id,
          mainReleaseNotes: options.releasesNoteToMove.main.list,
          otherReleaseNotes: options.releasesNoteToMove.other.list,
        });
      },
    });
  };

  return { removeRelease };
};
