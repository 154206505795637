import { useApolloClient, useSubscription } from '@apollo/client';
import { NbMonthlyDocsSubscription, NbMonthlyDocsDocument, NbMonthlyDocsSubscriptionVariables } from '@cycle-app/graphql-codegen';

import { useProduct } from './useProduct';

export const useNbMonthlyDocsUpdatedSubscription = (productId?: string | null) => {
  const { cache } = useApolloClient();
  const { product } = useProduct('cache-only');

  return useSubscription<NbMonthlyDocsSubscription, NbMonthlyDocsSubscriptionVariables>(NbMonthlyDocsDocument, {
    variables: {
      productId: productId as string,
    },
    onSubscriptionData({ subscriptionData: { data } }) {
      if (!productId || !data || !product) return;
      cache.modify({
        id: cache.identify(product),
        fields: {
          nbMonthlyDocs: () => data?.nbMonthlyDocs,
        },
      });
    },
  });
};
