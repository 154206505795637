import { makeVar } from 'src/utils/makeVar.utils';
import { make } from 'src/utils/reactives.util';

interface DocItem {
  disableHover: boolean;
  unfocusCurrentDoc: VoidFunction;
}

export const {
  getValue: getDocItem,
  setValue: setDocItem,
  hookValue: useGetDocItem,
  resetValue: resetDocItem,
} = make<DocItem>({
  defaultState: {
    disableHover: false,
    unfocusCurrentDoc: () => {
      // Intentional empty function
    },
  },
});

export const {
  hook: useDocItemHoverId,
  selectorHook: useDocItemHoverIdSelector,
  get: getDocItemHoverId,
  set: setDocItemHoverId,
  reset: resetDocItemHoverId,
} = makeVar<string | null>(null);
