import { ColorTheme } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { Size, Color } from './Button.types';
import * as typo from '../../theme/typo';
import { boxShadowZ1, ring } from '../../utils/styles.util';

export const BaseButton = styled.button<{ $size: Size; $color: Color; $active: boolean }>`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  min-height: 28px;
  border-radius: 6px;
  ${typo.body500}

  :focus-visible {
    ${ring}
    --ring-size: 3px;
    --ring-color: ${p => (p.theme.isDark ? ColorTheme.Blue600 : ColorTheme.Blue300)};
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[aria-busy="true"] {
    pointer-events: none;
    justify-content: center;
    > * { opacity: 0; }
    > :first-child {
      /* setting the loader style */
      opacity: 1;
      position: absolute;
    }
  }

  ${p => p.$size === 'L' && `
    min-height: 32px;
    padding: 4px 12px;
  `};

  ${p => p.$size === 'S' && `
    gap: 6px;
    min-height: 24px;
    border-radius: 5px;
    ${typo.caption500}}
  `};

  ${p => p.$color === 'primary' && `
    --bg: ${ColorTheme.Cycle};
    --text: ${ColorTheme.White};
    --text-naked: ${ColorTheme.Cycle};
    --outline: ${ColorTheme.Cycle};
    ${p.theme.isDark && `
      --bg: ${ColorTheme.Cycle};
      --text: ${ColorTheme.White};
      --text-naked: ${ColorTheme.Cycle};
      --outline: ${ColorTheme.Cycle};
    `}
  `};

  ${p => p.$color === 'secondary' && `
    --bg: ${ColorTheme.Blue100};
    --text: ${ColorTheme.Cycle};
    --text-naked: ${ColorTheme.Blue800};
    --outline: ${ColorTheme.Cycle};
    ${p.theme.isDark && `
      --bg: ${ColorTheme.Blue800};
      --text: ${ColorTheme.White};
      --text-naked: ${ColorTheme.Blue800};
      --outline: ${ColorTheme.Cycle};
    `}
  `};

  ${p => p.$color === 'gray' && `
    --bg: ${ColorTheme.Grey200};
    --text: ${ColorTheme.Grey800};
    --text-naked: ${ColorTheme.Grey800};
    --outline: ${ColorTheme.Grey700};
    ${p.theme.isDark && `
      --bg: ${ColorTheme.Grey800};
      --text: ${ColorTheme.White};
      --text-naked: ${ColorTheme.Grey800};
      --outline: ${ColorTheme.Grey800};
    `}
  `};

  ${p => p.$color === 'danger' && `
    --bg: ${ColorTheme.Red600};
    --text: ${ColorTheme.White};
    --text-naked: ${ColorTheme.Red600};
    --outline: ${ColorTheme.Red600};
    ${p.theme.isDark && `
      --bg: ${ColorTheme.Red500};
      --text: ${ColorTheme.White};
      --text-naked: ${ColorTheme.Red600};
      --outline: ${ColorTheme.Red500};
    `}
  `};

  ${p => p.$color === 'warning' && `
    --bg: ${ColorTheme.Orange600};
    --text: ${ColorTheme.White};
    --text-naked: ${ColorTheme.Orange600};
    --outline: ${ColorTheme.Orange600};
    ${p.theme.isDark && `
      --bg: ${ColorTheme.Orange500};
      --outline: ${ColorTheme.Orange500};
      --text: ${ColorTheme.White};
      --text-naked: ${ColorTheme.Orange500};
    `}
  `};

  ${p => p.$color === 'premium' && `
    --bg: ${ColorTheme.Black};
    --text: ${ColorTheme.White};
    --text-naked: ${ColorTheme.Black};
    --outline: ${ColorTheme.Black};
    ${p.theme.isDark && `
      --bg: ${ColorTheme.Grey800};
      --text: ${ColorTheme.White};
      --text-naked: ${ColorTheme.Grey800};
      --outline: ${ColorTheme.Grey800};
    `}
  `};
`;

export const DefaultButton = styled(BaseButton)`
  border: 1px solid ${ColorTheme.Transparent};
  background: var(--bg);
  color: var(--text);
  --mix: ${p => (p.theme.isDark ? '#fff' : '#000')};
  :hover {
    background: color-mix(in srgb, var(--bg), var(--mix) 10%);
  }
  :active {
    background: color-mix(in srgb, var(--bg), var(--mix) 20%);
  }
  ${p => p.$active && css`
    background: color-mix(in srgb, var(--bg), var(--mix) 20%);
  `}
`;

export const NakedButton = styled(BaseButton)` 
  color: var(--text-naked);
  --mix: ${p => (p.theme.isDark ? '#000' : '#fff')};
  :hover {
    background: color-mix(in srgb, var(--bg), var(--mix) 90%);
  }
  :active {
    background: color-mix(in srgb, var(--bg), var(--mix) 80%);
  }
  ${p => p.$active && css`
    background: color-mix(in srgb, var(--bg), var(--mix) 80%);
  `}
`;

export const OutlinedButton = styled(NakedButton)` 
  border: 1px solid var(--outline);
  ${boxShadowZ1}
`;
