import { ActionButton, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Title = styled.h1`
  ${typo.headerLarge600}
`;

export const Action = styled(ActionButton)`
  --colorHover: ${p => p.theme.colors.text.secondary};
`;
