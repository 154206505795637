import { InfoColorType } from '@cycle-app/utilities';
import { ReactNode, FC } from 'react';

import { Container, Content, Title, P } from './Info.styles';

type InfoProps = {
  icon?: ReactNode;
  iconRight?: ReactNode;
  type?: InfoColorType;
  title?: ReactNode;
  onclick?: VoidFunction;
};

export const Info: FC<React.PropsWithChildren<InfoProps>> = ({
  children, icon, iconRight, type = 'default', title, onclick,
}) => (
  <Container
    $colorType={type}
    $hasHover={!!onclick}
    onClick={onclick}
  >
    {icon}
    <Content>
      {!!title && <Title $colorType={type}>{title}</Title>}
      <P $colorType={type}>{children}</P>
    </Content>
    {iconRight}
  </Container>
);
