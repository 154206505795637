import keyBy from 'lodash/keyBy';

export const nodeToArray = <T>(entry?: { edges: { node?: T }[] } | null): NonNullable<T>[] => {
  try {
    return entry?.edges.reduce<NonNullable<T>[]>((acc, { node }) => {
      if (node == null) return acc;
      return [...acc, node as NonNullable<T>];
    }, []) ?? [];
  } catch (err) {
    // TODO: log in sentry
    console.error(err);
    return [];
  }
};

type NodeToObject = <T>(entry?: { edges: { node: T }[] } | null) => Record<string, T>;

export const nodeToObject: NodeToObject = (entry) => keyBy(nodeToArray(entry), 'id');
