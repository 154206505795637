import { ReactNode, useRef } from 'react';
import { Instance } from 'tippy.js';

import { ToggleButtonProps } from 'src/components/DropdownLayer';
import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { getLayer } from 'src/reactives/layer.reactive';
import { AutomationType } from 'src/types/automation.types';
import { Layer } from 'src/types/layers.types';

import { StyledToggleDropdown, StyledDropdownLayer } from './DocLinear.styles';
import { LinearSearch, LinearSearchProps } from './LinearSearch';
import { LinearSkeletons } from './LinearSkeletons';
import { PreviewIssue } from './PreviewIssue';
import { PreviewProject } from './PreviewProject';

export type LinearSelectProps = Pick<LinearSearchProps, 'onSelect' | 'onCreate'> & {
  docId?: string;
  type?: AutomationType;
  button: (props: ToggleButtonProps) => ReactNode;
  onUnlink: VoidFunction;
};

export const LinearSelect = ({
  docId, type, button, onSelect, onCreate, onUnlink,
}: LinearSelectProps) => {
  const integrationId = useDocLinearContext(ctx => ctx.integrationId);
  const previewTippyInstance = useRef<Instance>();
  const isHidePreviewEnabled = useRef(false);
  const hidePreview = () => {
    isHidePreviewEnabled.current = true;
    previewTippyInstance.current?.hide();
    isHidePreviewEnabled.current = false;
  };

  return (
    <StyledToggleDropdown
      placement="bottom"
      withPortal
      layer={Layer.DropdownModalZ3}
      button={buttonProps => (
        <StyledDropdownLayer
          animation={false}
          layer={Layer.DropdownModalZ3}
          delay={[400, 0]}
          placement="bottom"
          withPortal
          withWrapper={false}
          content={type === 'issue'
            ? (
              <PreviewIssue
                hide={hidePreview}
                onClickChange={buttonProps.trigger}
                onUnlink={onUnlink}
              />
            )
            : (
              <PreviewProject
                hide={hidePreview}
                onClickChange={buttonProps.trigger}
                onUnlink={onUnlink}
              />
            )}
          interactive
          interactiveBorder={16}
          isShowDisabled={() => type === null}
          isHideDisabled={() => getLayer()[Layer.DropdownModalZ4] && !isHidePreviewEnabled.current}
          onMount={instance => { previewTippyInstance.current = instance; }}
        >
          {button(buttonProps)}
        </StyledDropdownLayer>
      )}
      content={contentProps => integrationId && (
        <LinearSearch
          docId={docId}
          defaultSearchType={type}
          hide={contentProps.hide}
          integrationId={integrationId}
          onSelect={onSelect}
          onCreate={onCreate}
          skeletons={<LinearSkeletons />}
        />
      )}
    />
  );
};
