import { useEffect, useRef, useState, FC } from 'react';

import { Container, StyledInput } from './CodeInput.styles';

interface Props {
  autoFocus?: boolean;
  className?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const codeRegex = /[0-9]{6}/;

export const CodeInput: FC<React.PropsWithChildren<Props>> = ({
  autoFocus, className, onChange, disabled,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [values, setValues] = useState([...Array(6).keys()].map(() => ''));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputs.current = Array.from(ref.current?.querySelectorAll('input') || []);
  }, []);

  return (
    <Container
      ref={ref}
      className={className}
      onPaste={e => {
        e.preventDefault();
        const txt = e.clipboardData.getData('Text');
        if (txt.match(codeRegex)) {
          setValues(txt.split(''));
          onChange(txt);
        }
      }}
    >
      {values.map((v, i) => (
        <StyledInput
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          disabled={disabled}
          onChange={e => {
            const { value } = e.target;
            const currentValues = [...values];
            currentValues.splice(i, 1, value);
            setValues(currentValues);
            if (currentValues.filter(s => s.trim()).length === 6) {
              onChange(currentValues.join(''));
            }
            if (value) {
              inputs.current[i + 1]?.focus();
            }
          }}
          onKeyDown={e => {
            if (e.code === 'Backspace' && !v) {
              inputs.current[i - 1]?.focus();
            }
          }}
          autoFocus={autoFocus && i === 0}
          value={v}
          type="number"
          min="0"
          max="9"
          pattern="[0-9]{1}"
        />
      ))}
    </Container>
  );
};
