import { Skeleton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }
`;

export const SkeletonTag = styled(Skeleton)`
  width: 48px;
  height: 12px;
`;

export const DocTypeButton = styled.button`
  display: flex;
  align-items: center;
  padding: 1px 1px 0;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  background: ${p => p.theme.colors.tag.bgAlt};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
`;

export const DocTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
