import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { body400, headerLarge600 } from '../../theme/typo';
import { Modal } from '../Modal/Modal';

export const Root = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  height: 100vh;
  width: 100vw;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px;
  background: ${p => p.theme.colors.onboarding.background.body};
  overflow-y: auto;
  ${mobile} {
    padding: 24px;
  }
`;

export const Header = styled.div`
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  ${mobile} {
    margin-bottom: 70px;
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 460px;
  ${mobile} {
    max-width: 100%;
    height: 100%;
  }
`;

export const Body = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const Footer = styled.div<{ $isFixed?: boolean }>`
  flex: none;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  ${p => p.$isFixed && css`
    position: absolute;
    bottom: 32px;
    left: 50%;
    width: 460px;
    max-width: 460px;
    transform: translate(-50%, 0);
  `}

  ${mobile} {
    bottom: 0;
    left: 0;
    max-width: 100%;
    position: relative;
    transform: none;
    width: auto;
  }
`;

export const FooterRight = styled.div`
  display: flex;
  gap: 24px;
  margin-left: auto;
`;

export const Aside = styled.div`
  position: relative;
  background: ${p => p.theme.colors.onboarding.background.aside};
  ${mobile} {
    display: none;
  }
`;

export const Title = styled.h1`
  ${headerLarge600}
  margin-bottom: 8px;
  `;

export const SubTitle = styled.h2`
  margin-bottom: 8px;
  color: ${p => p.theme.colors.text.blue};
`;

export const Description = styled.div`
  ${body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-bottom: 24px;
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ModalRoot = styled(Modal)<{ $isFixedFooter?: boolean }>`
  width: 1019px;
  height: 729px;
  max-width: 100vw;
  max-height: 100vh;
  margin-left: 24px;
  margin-right: 24px;
  padding: ${p => (p.$isFixedFooter ? '32px 32px 86px' : '32px')};
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05), 0px 35px 70px rgba(0, 0, 0, 0.15);
  position: relative;

  ${mobile} {
    border-radius: 0px;
    width: 100vw;
    height: 100vh;
    margin-left: 0;
    margin-right: 0;
    padding: 32px;
  }
`;

export const ModalMain = styled(Main)`
  padding: 0px;
  overflow-y: none;
`;
