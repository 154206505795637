import { DocThreadsDocument, UnresolveThreadDocument, UnresolveThreadMutationVariables } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUnresolveThread = () => {
  const [mutate, { loading }] = useSafeMutation(UnresolveThreadDocument);

  const unresolveThread = useCallback((
    docId: string,
    variables: UnresolveThreadMutationVariables,
  ) => mutate({
    variables,
    refetchQueries: [{
      query: DocThreadsDocument,
      variables: {
        id: docId,
        resolved: false,
      },
    }, {
      query: DocThreadsDocument,
      variables: {
        id: docId,
        resolved: true,
      },
    }],
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return {
    unresolveThread,
    isUnresolvingThread: loading,
  };
};
