import { typo, boxShadowZ2, Button, TextButton, CycleLogo } from '@cycle-app/ui';
import { CheckIcon, SlackIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import PortalModal from '../PortalModal/PortalModal';

export const StyledPortalModal = styled(PortalModal)`
  width: 640px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
`;

export const StyledLogo = styled(CycleLogo)`
  ${p => p.color !== 'blue' && css`--color1: ${p.theme.colors.text.primary};`}
  width: 48px;
  height: 48px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledSlackLogo = styled(SlackIcon)`
  width: 48px;
  height: 48px;
`;

export const Title = styled.h3`
  ${typo.headerLarge600}
  text-align: center;
  margin-top: 24px;
`;

export const Description = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
  margin-top: 12px;
  width: 480px;
  max-width: 100%;
`;

export const Features = styled.div`
  ${typo.body400}
  ${boxShadowZ2}
  border-radius: 8px;
  padding: 28px 16px 16px;
  margin-top: 32px;
  width: 450px;
  max-width: 100%;
`;

export const FeaturesList = styled.div`
  display: flex;

  ul {
    flex-grow: 1;
    flex-shrink: 0;
  }

  li {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  li:not(:first-child) {
    margin-top: 8px;
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;

export const ContactSales = styled(Button)`
  margin-top: 38px;
`;

export const Cancel = styled(TextButton)`
  --bgHover: transparent;
  margin-top: 16px;
  font-weight: 400;
  justify-content: center;
`;

export const Logos = styled.div`
  margin-top: 64px;
  display: flex;
  gap: 40px;
`;
