import {
  CheckboxInput, CheckboxInputText, CheckboxSquare, typo, ShyScrollbar, SelectPanel, Button,
} from '@cycle-app/ui';
import { ReloadIcon, ArrowsAllSidesIcon } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const StyledReloadIcon = styled(ReloadIcon)`
  color: ${p => (p.theme.isDark ? p.theme.colors.text.secondary : 'rgba(89, 101, 120, 0.65)')};
`;

export const StyledArrowsAllSidesIcon = styled(ArrowsAllSidesIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;

export const Main = styled.form`
  margin-top: 32px;
`;

export const InteListContainer = styled(ShyScrollbar)`
  max-height: 415px;
`;

export const InteList = styled.ul`
  margin-bottom: 2px;

  li + li {
    margin-top: 20px;
  }
`;

const imageLogoCss = css`
  img {
    height: 16px;
    object-fit: contain;
    width: 16px;
  }
`;

export const InteLabel = styled.div`
  ${typo.body500}
  align-items: center;
  display: flex;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  ${imageLogoCss}
`;

export const InteLabelBadge = styled(motion.div)`
  ${typo.caption500}
  color: ${p => p.theme.colors.text.secondary};
  flex: none;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translate(0, -50%);
  transition-duration: .3s;
`;

export const InteName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface StyledCheckboxInputProps {
  $isSelected: boolean;
}

export const StyledCheckboxInput = styled(CheckboxInput) <StyledCheckboxInputProps>`
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  box-shadow: inset 0 0 0 2px transparent;
  height: 48px;
  position: relative;
  transition: box-shadow .1s, background-color .1s;

  label {
    padding: 12px;
    gap: 15px;
  }

  &:hover, &:focus-within {
    background-color: ${p => (p.theme.isDark ? p.theme.colors.background.hoverDark : p.theme.baseColors.Grey200)};
  }

  ${CheckboxInputText} {
    overflow: hidden;
  }

  ${CheckboxSquare} {
    display: none;
  }

  ${p => p.$isSelected && css`
    border-color: transparent;
    box-shadow: inset 0 0 0 2px hsl(var(--cycle));
  `}
`;

export const ButtonOther = styled(Button)`
  ${typo.body500}
  --radius: 8px;
  --color: ${p => p.theme.colors.text.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  height: 48px;
  padding: 12px;

  &.force-focus {
    background-color: ${p => p.theme.colors.button.ternary.bg};
  }

  > span {
    justify-content: flex-start;
  }
`;

export const StyledSelectPanel = styled(SelectPanel)`
  svg, img {
    width: 16px;
    flex: none;
  }
`;

export const InteBubble = styled(motion.div)`
  align-items: center;
  border-radius: 84.8px;
  display: flex;
  height: 88px;
  justify-content: center;
  width: 88px;
  box-shadow: 0px 0px 2.444px 0px rgba(0, 0, 0, 0.07), 0px 3.667px 9.167px 0px rgba(0, 0, 0, 0.05);

  ${StyledArrowsAllSidesIcon} {
    color: ${p => (p.theme.isDark ? p.theme.colors.text.secondary : p.theme.baseColors.Grey300)};
  }

  ${p => (p.theme.isDark ? `
    background: ${p.theme.baseColors.Grey800};
  ` : `
    background: #FFF;
  `)}

  > svg, > img {
    flex: none;
    height: 48px;
    width: 48px;
  }
`;

export const Aside = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

export const IntegrationSliderContainer = styled.div`
  height: 88px;
  position: relative;
  width: 88px;
`;

export const IntegrationSliderList = styled.ul`
  transition: transform .3s;

  li {
    opacity: 1;
    transition: opacity .3s;
  }

  li + li {
    margin-top: 27px;
  }
`;

export const InputContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  height: 60px;
  padding: 12px 14px;

  input {
    --borderColorFocus: transparent;
    height: 36px;
  }

  input:focus {
    background-color: ${p => (p.theme.isDark ? p.theme.colors.input.bgAlt : p.theme.baseColors.Grey200)} !important;
  }
`;