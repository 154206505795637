import { Button, typo, ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  height: 44px;
  border-bottom: 1px solid ${p => p.theme.colors.home.separator};
`;

export const AddCommentContainer = styled.div`
  flex: none;
  border-bottom: 1px solid ${p => p.theme.colors.home.separator};
`;

export const HeaderButton = styled(Button).attrs({
  size: 'S',
  variant: 'light',
})`
  ${typo.caption400}
  height: 20px;
  padding: 0 4px;
  --bg: transparent;
  color: ${p => p.theme.colors.text.secondary};
  > span {
    gap: 4px;
  }
`;

export const Label = styled.div`
  padding: 0 8px;
`;

export const DropdownContent = styled.div`
  padding: 8px 0;
`;

export const Content = styled.div`
  flex: 1;
  ${ShyScrollbarCss}
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 6px 16px 16px;
`;
