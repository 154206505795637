import { ThemeType } from '@cycle-app/graphql-codegen';
import {
  ActionButton, ActionButtonProps, Button,
  typo, boxShadowZ1, truncate,
} from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';
import { FloatingPenButtonContainer } from '../FloatingPenButton/FloatingPenButton.styles';

interface StyledActionButtonProps extends ActionButtonProps {
  $isSelected?: boolean;
  $context?: 'doc-item';
}

export const StyledActionButton = styled(ActionButton)<StyledActionButtonProps>`
  ${p => p.$isSelected && css`
    ${typo.body500}
    --color: ${p.theme.colors.text.primary};
    --colorHover: ${p.theme.colors.text.primary};
  `}
  white-space: nowrap;

  > div {
    display: flex;
    align-items: center;
  }

  ${p => p.$context === 'doc-item' && !p.$isSelected && css`
    height: 24px;
    min-width: 24px;
    border-radius: 6px;
    --color: ${p.theme.colors.docItem.actionButton.color};
    --colorHover: ${p.theme.colors.docItem.actionButton.colorHover};
    --bgHover: ${p.theme.colors.docItem.actionButton.bgHover};
    --bgActive: ${p.theme.colors.docItem.actionButton.bgActive};

    ${p.counter && `
      --color: ${p.theme.colors.docItem.actionButton.colorAlt};
    `};
  `}

  ${p => p.$context === 'doc-item' && p.$isSelected && css`
    height: 24px;
    --bgHover: transparent;
    --bgActive: transparent;
  `};
`;

interface EditButtonContainerProps {
  $isCompact?: boolean;
  $context?: 'doc-item';
}
export const EditButtonContainer = styled(FloatingPenButtonContainer) <EditButtonContainerProps>`
  ${p => p.$context !== 'doc-item' && css`
    &:hover ${StyledActionButton} {
      color: ${p.theme.colors.text.primary};
      background-color: ${p.theme.colors.actionButton.bgHover};
    }
  `}

  ${p => p.$context !== 'doc-item' && p.$isCompact && css`
    padding: 0;

    ${StyledActionButton} {
      padding-bottom: 0;
      padding-left: 2px;
      padding-top: 0;
    }

    &:hover ${StyledActionButton} {
      background-color: transparent;
    }
  `}

  ${mobile} {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const TooltipCustomerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TooltipCustomerContentUpdateLabel = styled.div`
  color: ${p => p.theme.colors.tooltip.textLight};
`;

export const CreateSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 0 4px;
`;

export const OpenCustomerButton = styled(ActionButton)`
  --size: 10px;
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  color: ${p => p.theme.colors.text.secondary};
  gap: 4px;
  height: 20px;
  ${typo.caption400}
  ${boxShadowZ1}
  ${p => p.theme.themeType === ThemeType.Nightfall && css`
    border: none;
    background-color: ${rgba(p.theme.colors.background.secondary, 0.5)};
    :hover, :active {
      background-color: ${p.theme.colors.background.tertiary};
    }
  `}
`;

export const StyledCustomerCompanyAction = styled(CustomerCompanyAction)`
  --bg: ${p => p.theme.colors.actionButton.bgHover};
  border-radius: 50%;
  height: var(--customer-company-logo-size);
  min-height: auto;
  width: 100%;
`;

interface ActionButtonCursorProps {
  $isCompact?: boolean;
  $context?: 'doc-item';
}

export const ActionButtonCursor = styled.span<ActionButtonCursorProps>`
  ${p => !p.$isCompact && p.$context !== 'doc-item' && css`cursor: not-allowed;`}
`;

export const Name = styled.span`
  ${truncate}
`;

type CreateButtonProps = {
  hoverDisabled: boolean;
  isSelected: boolean;
};
export const CreateButton = styled(Button) <CreateButtonProps>`
  background-color: ${p => (p.isSelected
    ? p.theme.colors.background.hover
    : p.theme.colors.background.tertiary)};
  color: ${p => p.theme.colors.text.primary};
  padding: 2px 8px;

  &:hover, &:active, &:focus {
    background-color: ${p => p.theme.colors.background.hover};
  }
`;
