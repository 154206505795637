import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { DropArea as DropAreaComponent } from 'src/components/Editor/ContainerDropFile/ContainerDropFile.styles';

export const DropContainer = styled.div`
  position: relative;
  height: 126px;
  width: 100%;
`;

export const DropArea = styled(DropAreaComponent)`
  border: none;
  background-color: ${p => p.theme.colors.background.secondary};

  &:hover {
    border: none;
  }
`;

export const Or = styled.div`
  ${typo.body400};
  color: ${p => p.theme.colors.text.disabled};
`;
