import { Button, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const OngoingCta = styled(Button)`
  padding-left: 4px;
  padding-right: 4px;
  ${p => p.theme.isDark && `
    --color: ${p.theme.baseColors.Blue400};
  `}
`;

export const UpcomingCta = styled(Button)`
  ${typo.body500}
  --color: ${p => p.theme.colors.text.secondary};
  --bg: transparent;
  padding-left: 4px;
  padding-right: 4px;
`;
