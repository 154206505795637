import { ThemeType } from '@cycle-app/graphql-codegen';
import { typo, truncate, Button } from '@cycle-app/ui';
import styled from 'styled-components';

import { InboxContainer } from '../Views/Inbox/Inbox.styles';

export const Container = styled.div`
  box-shadow: none;
  padding: 12px 16px;
  margin: 0px 24px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background-color: ${p => p.theme.colors.home.card.bg};

  > * { flex: none }

  /* stylelint-disable-next-line no-duplicate-selectors */
  ${InboxContainer} & {
    background-color: hsl(var(--cycle), ${p => (p.theme.themeType === ThemeType.Nightfall ? 0.15 : 0.1)});
  }
`;

export const Slack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: ${p => p.theme.colors.home.integrations.logo.bg};
  border-radius: 8px;
  margin-right: 8px;
`;

export const Text = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  ${typo.caption400};
  flex: 1;
  p {
    ${truncate}
  }
  overflow: hidden;
`;

export const Title = styled.p`
  color: ${p => p.theme.colors.text.primary};
  ${typo.body500};
`;

export const LearnMoreButton = styled(Button)`
  :focus {
    background: transparent;
  }
  :hover, :active {
    background: hsla(var(--cycle), 0.05);
  }
`;
