import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 6px 10px;
`;

export const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 175px;
  flex-shrink: 0;
  gap: 8px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Company = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 130px;
  flex-shrink: 0;
  gap: 8px;
  overflow: hidden;
  white-space: nowrap;

  ${mobile} {
    display: none;
  }

`;

export const Email = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
