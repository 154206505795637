import { Button } from '@cycle-app/ui';
import { RightIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { Section } from './ChangelogBuilderSidebar.styles';

export const StyledSection = styled(Section)`
  padding-bottom: 0;
  padding-top: 0;
`;

interface ToggleButtonProps {
  $isOpened: boolean;
}

export const ToggleButton = styled(Button)<ToggleButtonProps>`
  height: 58px;

  ${p => p.$isOpened && `
    svg {
      transform: rotate(-90deg);
    }
  `}
`;

export const Caret = styled(RightIcon)`
  color: ${p => p.theme.colors.text.secondary};
  flex: none;
`;
