import { Editor, ReactRenderer } from '@tiptap/react';
import { SuggestionKeyDownProps } from '@tiptap/suggestion';
import { ComponentType } from 'react';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import { Layer } from 'src/types/layers.types';
import { clientRectWithFallback } from 'src/utils/editor/editor.utils';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

import { SuggestionPluginProps } from '../plugins/suggestionPlugin';

interface MentionParams {
  readOnly?: boolean;
  isMobile?: boolean;
}

type Return = {
  onStart?: (props: SuggestionPluginProps) => void;
  onUpdate?: (props: SuggestionPluginProps) => void;
  onExit?: (props: SuggestionPluginProps) => void;
  onKeyDown?: (props: SuggestionKeyDownProps) => boolean;
};

export const renderMentionDropdown = (renderer: ComponentType<React.PropsWithChildren<unknown>>, params: MentionParams): Return => {
  const {
    readOnly, isMobile,
  } = params;
  // To avoid rendering the dropdown if last char is # in readonly editor
  if (readOnly) return {};

  let reactRenderer: ReactRenderer | undefined;
  let popup: TippyInstance<TippyProps> | undefined;

  return {
    onStart: (props) => {
      reactRenderer = new ReactRenderer(renderer, {
        props: {
          ...props,
          hide: () => {
            popup?.hide();
          },
        },
        editor: props.editor as Editor,
      });

      popup = getTippyPopup({
        props,
        reactRenderer,
        layer: Layer.DropdownZ1,
        options: {
          hideOnClick: false,
          onHide: () => {
            if (props.editor && props.pluginKey) {
              props.editor.view.dispatch(
                props.editor.view.state.tr.setMeta(
                  props.pluginKey,
                  {
                    active: false,
                    key: null,
                    range: {},
                    query: null,
                    text: null,
                    composing: false,
                  },
                ),
              );
            }
          },
          ...(isMobile && {
            placement: 'right-start',
            maxWidth: 330,
            popperOptions: {
              modifiers: [
                {
                  name: 'flip',
                  options: {
                    fallbackPlacements: ['bottom', 'top'],
                  },
                },
              ],
            },
          }),
        },
      })?.[0];
    },
    onUpdate(props) {
      reactRenderer?.updateProps(props);
      popup?.setProps({
        getReferenceClientRect: clientRectWithFallback(props.clientRect),
      });
    },
    onKeyDown(props) {
      if (!popup) return true;
      if (props.event.key === 'Tab' || props.event.key === 'ArrowRight') {
        if (!popup?.state.isDestroyed) {
          props.event.stopImmediatePropagation();
          popup?.hide();
        }
        return true;
      }

      return onKeyDown({
        ...props,
        popupInstance: popup,
      });
    },
    onExit() {
      popup?.destroy();
      reactRenderer?.destroy();
    },
  };
};
