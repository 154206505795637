import { BulkCreateDocDocument } from '@cycle-app/graphql-codegen';
import { isPresent } from 'ts-is-present';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSafeMutation } from 'src/hooks';
import { getLinearImportDocTypes, getLinearImportSelection } from 'src/reactives/linearImport.reactive';

import { docsData, importQueue } from './LinearImportModal.utils';
import { addLinearImportInProgressToaster } from './LinearImportToasters';

export const useStartImportLinear = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [bulkCreateDoc] = useSafeMutation(BulkCreateDocDocument);
  return async () => {
    const {
      selectedProjectIds, selectedIssueIds,
    } = getLinearImportSelection();
    const {
      projectsDocTypeId, issuesDocTypeId,
    } = getLinearImportDocTypes();

    const docsWithLinkedProject = selectedProjectIds
      .map(projectId => {
        if (projectsDocTypeId === null) return null;
        const data = docsData.get(projectId);
        return {
          productId,
          id: btoa(`Doc_${crypto.randomUUID()}`),
          doctypeId: projectsDocTypeId,
          title: data?.title ?? '',
          automationLink: data?.automationLink ?? '',
          automationId: projectId,
        };
      });

    const docsWithLinkedIssue = selectedIssueIds
      .map(issueId => {
        if (issuesDocTypeId === null) return null;
        const data = docsData.get(issueId);
        return {
          productId,
          id: btoa(`Doc_${crypto.randomUUID()}`),
          doctypeId: issuesDocTypeId,
          title: data?.title ?? '',
          automationLink: data?.automationLink ?? '',
          automationId: issueId,
        };
      });

    const newDocs = [...docsWithLinkedProject, ...docsWithLinkedIssue].filter(isPresent);

    if (newDocs.length === 0) return;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    const result = await bulkCreateDoc({
      variables: {
        productId,
        newDocs,
      },
    });

    const uuid = result.data?.bulkCreateDoc;
    if (!uuid) return;
    importQueue.add(uuid);
    addLinearImportInProgressToaster();
  };
};
