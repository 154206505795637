import { ActionButton } from '@cycle-app/ui';
import { UndoIcon, CloseIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { Label, Title } from './LabelWithReset.styles';

type LabelWithResetProps = {
  label: ReactNode;
  id?: string;
  showReset: boolean;
  showRemove?:boolean;
  reset: VoidFunction;
  remove?: VoidFunction;
};

export const LabelWithReset = ({
  id, showReset, label, reset, showRemove, remove,
}: LabelWithResetProps) => {
  return (
    <div className="align-items flex justify-between">
      {id && <Label htmlFor={id}>{label}</Label>}
      {!id && <Title>{label}</Title>}
      <div className="align-items flex gap-1">
        {showRemove && (
          <ActionButton size="M" tooltip="Remove" tooltipPlacement="top" onClick={remove}>
            <CloseIcon size={12} />
          </ActionButton>
        )}
        {showReset && (
          <ActionButton size="M" tooltip="Reset" tooltipPlacement="top" onClick={reset}>
            <UndoIcon size={14} />
          </ActionButton>
        )}
      </div>
    </div>
  );
};
