import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import {
  DocQuotesDocument,
  DocQuotesSubscription,
  DocQuotesSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

export const useDocQuotesSubscription = (
  feedbackId?: string | null,
  options?: Pick<SubscriptionHookOptions<DocQuotesSubscription>, 'onSubscriptionData'>,
) => {
  return useSubscription<DocQuotesSubscription, DocQuotesSubscriptionVariables>(
    DocQuotesDocument,
    {
      ...options,
      variables: {
        feedbackId: feedbackId as string,
      },
    },
  );
};
