import { focusEndContenteditable } from '@cycle-app/utilities';
import { FC, useCallback, KeyboardEvent, useRef, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { CLASSNAME_USED_FOR_FOCUS } from 'src/constants/editor.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';

import { StyledContentEditable } from './DocPanelEditableTitle.styles';

interface Props {
  className?: string;
  title: string | undefined;
  onTitleUpdated: (title: string) => void;
  onNext?: VoidFunction;
  onEscape?: VoidFunction;
  onEnter?: VoidFunction;
  focusEndOnMount?: boolean;
  id?: string;
  isDisabled?: boolean;
}
const DocPanelEditableTitle: FC<Props> = ({
  className,
  title = '',
  onTitleUpdated,
  onNext,
  onEscape,
  onEnter,
  focusEndOnMount,
  id,
  isDisabled = false,
}) => {
  const debouncedOnTitleUpdated = useDebouncedCallback(onTitleUpdated, INPUT_ONCHANGE_DEBOUNCE);
  const inputRef = useRef<HTMLHeadingElement>(null);

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Escape' || isDisabled) return;
    e.stopPropagation();
    onEscape?.();
  }, [onEscape, isDisabled]);

  useEffect(() => {
    if (inputRef.current && focusEndOnMount && !isDisabled) {
      focusEndContenteditable(inputRef.current);
    }
  }, [id, focusEndOnMount, isDisabled]);

  return (
    <div {...onEscape && { onKeyDown }}>
      <StyledContentEditable
        ref={inputRef}
        key={id}
        className={`${className} ${CLASSNAME_USED_FOR_FOCUS}`}
        initialValue={title}
        placeholder="Untitled"
        onChange={debouncedOnTitleUpdated}
        onNext={onNext}
        onEnter={onEnter}
        focusEndOnMount={focusEndOnMount}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default DocPanelEditableTitle;
