import { DeleteBoardModal } from 'src/app/Main/Board/DeleteBoardModal/DeleteBoardModal';
import DuplicateBoardModal from 'src/app/Main/Board/DuplicateBoardModal/DuplicateBoardModal';
import { BoardExportModal } from 'src/components/BoardExportModal';
import {
  setViewDeleteModal, setViewDuplicateModal, setViewExportModal,
  useGetViewDeleteModal, useGetViewDuplicateModal, useGetViewExportModal,
} from 'src/reactives/viewModals.reactive';

export const ViewModals = () => {
  const { duplicateViewId } = useGetViewDuplicateModal();
  const { exportViewId } = useGetViewExportModal();
  const { deleteViewId } = useGetViewDeleteModal();
  return (
    <>
      {exportViewId && (
        <BoardExportModal
          boardId={exportViewId}
          onHide={() => setViewExportModal({ exportViewId: null })}
        />
      )}

      {duplicateViewId && (
        <DuplicateBoardModal
          boardId={duplicateViewId}
          onHide={() => setViewDuplicateModal({ duplicateViewId: null })}
          showViewEmoji
        />
      )}

      {deleteViewId && (
        <DeleteBoardModal
          boardId={deleteViewId}
          onHide={() => setViewDeleteModal({ deleteViewId: null })}
        />
      )}
    </>
  );
};
