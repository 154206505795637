import { useSortable } from '@dnd-kit/sortable';
import { forwardRef } from 'react';

import { DocItemHandle } from 'src/components/DocItem';
import { useGetDnd } from 'src/reactives/dnd.reactive';
import { getSortableStyle } from 'src/utils/dnd.util';

import BoardGroup, { Props as BoardGroupProps } from './BoardGroup';

interface Props extends BoardGroupProps {
  disableDnd: boolean;
}

const BoardGroupSortable = forwardRef<DocItemHandle[], Props>(({
  group,
  groupId,
  disableDnd,
  ...props
}, forwardedRef) => {
  const { enabled } = useGetDnd();
  const isDraggable = enabled && !disableDnd && !!group.attributeValueId;
  const {
    setNodeRef,
    setDraggableNodeRef,
    setDroppableNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: groupId,
    disabled: !isDraggable,
    data: {
      type: 'group',
      isDraggable,
    },
  });
  const style = getSortableStyle({
    transition,
    transform,
  });

  return (
    <BoardGroup
      {...props}
      {...attributes}
      ref={forwardedRef}
      group={group}
      groupId={groupId}
      style={style}
      setSortableRef={setNodeRef}
      setDraggableNodeRef={setDraggableNodeRef}
      setDroppableNodeRef={setDroppableNodeRef}
      sortableListeners={listeners}
      asPlaceholder={isDragging}
      isDraggable={isDraggable}
    />
  );
});

BoardGroupSortable.displayName = 'BoardGroupSortable';
export default BoardGroupSortable;
