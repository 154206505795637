import { StatusCategory, ViewType } from '@cycle-app/graphql-codegen';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { FC, HTMLAttributes, ReactNode } from 'react';

import { AddIcon, TriangleIcon } from '../../icons';
import { Placement } from '../Dropdown/Dropdown';
import { StatusIcon } from '../StatusIcon';
import {
  Container,
  Content,
  NewDocContainer,
  NewDocButton,
  Header,
  IndicatorBtn,
  Toolbar,
  SortableElement,
  Variant,
  Count,
  GroupStatus,
  GroupTitle,
  GroupHeaderContainer,
  StyledGroupHeader,
} from './Group.styles';
import { Tooltip } from '../Tooltip/Tooltip';

export type NewDocPosition = 'top' | 'bottom';

export interface GroupProps extends HTMLAttributes<HTMLDivElement> {
  dataTestId?: string;
  groupId?: string;
  groupName?: string | ReactNode;
  groupCategory?: StatusCategory;
  inputName?: ReactNode;
  viewType: ViewType;
  variant?: Variant;
  onNewDocClick?: (position: NewDocPosition) => void;
  setContainerRef?: (node: Element | null) => void;
  setListRef?: (node: HTMLElement | null) => void;
  setSortableRef?: (node: HTMLElement | null) => void;
  setDraggableNodeRef?: (node: HTMLElement | null) => void;
  setDroppableNodeRef?: (node: HTMLElement | null) => void;
  sortableListeners?: DraggableSyntheticListeners;
  loading?: boolean;
  withGroupBy?: boolean;
  dragActive?: boolean;
  isDragging?: boolean;
  isDraggable?: boolean;
  asPlaceholder?: boolean;
  toolbar?: ReactNode;
  reasonCreateDocDisabled?: string | null;
  createDocHidden?: boolean;
  collapse?: boolean;
  collapsedContent?: ReactNode;
  toggleCollapse?: (groupId: string) => void;
  tooltip?: string;
  tooltipPlacement?: Placement;
  tooltipDisabled?: boolean;
  docCount?: string | null;
  showDocCount?: boolean;
  onHideGroup?: VoidFunction;
  onRenameGroup?: VoidFunction;
  readOnly?: boolean;
  isNewInbox?: boolean;
  newDocPosition?: NewDocPosition;
}

export const Group: FC<React.PropsWithChildren<GroupProps>> = ({
  dataTestId,
  setContainerRef,
  setListRef,
  groupId,
  groupName,
  groupCategory,
  inputName = null,
  collapsedContent,
  onNewDocClick,
  viewType,
  variant = 'default',
  loading,
  withGroupBy,
  children,
  dragActive,
  setSortableRef,
  setDraggableNodeRef,
  setDroppableNodeRef,
  sortableListeners,
  isDragging,
  isDraggable,
  asPlaceholder,
  toolbar,
  reasonCreateDocDisabled = null,
  createDocHidden = false,
  collapse = false,
  toggleCollapse,
  tooltip,
  tooltipPlacement,
  tooltipDisabled,
  docCount,
  showDocCount = false,
  onHideGroup,
  onRenameGroup,
  readOnly,
  isNewInbox,
  newDocPosition = 'bottom',
  ...containerProps
}) => {
  const isList = viewType === ViewType.List;

  const draggingInteraction = isDragging || asPlaceholder;
  const hideItems = collapse || (isList && draggingInteraction);

  const newDocTooltip = newDocPosition === 'top' ? 'Create at top' : undefined;
  const content = (
    <Container
      data-testid={dataTestId}
      ref={setContainerRef}
      viewType={viewType}
      collapse={collapse}
      isLoading={loading}
      isDragging={isDragging}
      isDraggable={isDraggable}
      asPlaceholder={asPlaceholder}
      variant={variant}
      {...containerProps}
    >
      {isNewInbox && (groupName || inputName) && (
        <GroupHeaderContainer>
          {withGroupBy ? (
            <StyledGroupHeader
              $statusCategory={groupCategory}
              icon={groupCategory && <StatusIcon category={groupCategory} />}
              title={groupName}
              count={showDocCount && docCount}
              form={!readOnly && inputName}
              onClick={() => toggleCollapse?.(groupId ?? '')}
              onRename={onRenameGroup}
              onHide={onHideGroup}
            />
          ) : <div />}
          {toolbar}
        </GroupHeaderContainer>
      )}

      {!isNewInbox && (groupName || inputName) && (
        <Header
          ref={isList ? setDraggableNodeRef : undefined}
          viewType={viewType}
          collapse={collapse}
          $statusCategory={groupCategory}
          {...sortableListeners}
        >
          {withGroupBy && (
            <IndicatorBtn onClick={() => toggleCollapse?.(groupId ?? '')}>
              <TriangleIcon direction={collapse ? 'top' : 'bottom'} />
            </IndicatorBtn>
          )}

          <GroupTitle>
            {groupCategory && (
              <GroupStatus>
                <StatusIcon category={groupCategory} />
              </GroupStatus>
            )}
            {collapse ? (collapsedContent ?? (
              <h2>
                {groupName}
              </h2>
            )) : inputName}
          </GroupTitle>

          {showDocCount && docCount != null && <Count>{docCount}</Count>}

          {!collapse && (
            <Toolbar $withGroupBy={withGroupBy}>
              {toolbar}
            </Toolbar>
          )}
        </Header>
      )}

      {!hideItems && (
        <>
          {children && (
            <Content
              id={groupId}
              ref={setListRef}
              viewType={viewType}
              $smooth={!dragActive}
              variant={variant}
            >
              {children}
            </Content>
          )}

          {!isNewInbox && !isDragging && !createDocHidden && onNewDocClick && (
            <NewDocContainer viewType={viewType} variant={variant}>
              <NewDocButton
                onClick={() => onNewDocClick(newDocPosition)}
                disabled={!!reasonCreateDocDisabled}
                tooltip={reasonCreateDocDisabled ? `❗️ ${reasonCreateDocDisabled}` : newDocTooltip}
                tooltipPlacement={isList || newDocPosition === 'top' ? 'top-start' : 'top'}
                data-action="new-doc"
              >
                <AddIcon size={9} />
                New
              </NewDocButton>
            </NewDocContainer>
          )}
        </>
      )}
    </Container>
  );

  return (
    <SortableElement ref={isList ? setDroppableNodeRef : setSortableRef} collapse={collapse}>
      {tooltip
        ? (
          <Tooltip
            content={tooltip}
            placement={tooltipPlacement}
            disabled={tooltipDisabled ?? !tooltip}
            withPortal
          >
            {content}
          </Tooltip>
        )
        : content}
    </SortableElement>
  );
};
