import { Spinner } from '@cycle-app/ui';
import { FC } from 'react';

import { closeCommandBar } from 'src/hooks/modals/useCommandBarModal';

import { CloseButton, HeaderTitle, Loading, MobileHeader } from './CommandMobileHeader.styles';

interface Props {
  isLoading?: boolean;
}

export const CommandMobileHeader: FC<React.PropsWithChildren<Props>> = ({ isLoading }) => {
  return (
    <MobileHeader>
      <CloseButton onClick={closeCommandBar}>Close</CloseButton>
      <HeaderTitle>Search</HeaderTitle>
      {isLoading && (
        <Loading>
          <Spinner />
        </Loading>
      )}
    </MobileHeader>
  );
};
