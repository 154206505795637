import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SunIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_92_41092)">
      <path
        // eslint-disable-next-line max-len
        d="M7.99998 1.99999V1.33333M7.99998 14.6667V14M12.2399 3.76003L12.7132 3.2867M3.28675 12.7133L3.76008 12.24M14 8.00001H14.6666M1.33331 8.00001H1.99998M12.2399 12.24L12.7132 12.7133M3.28675 3.2867L3.76008 3.76003M10.357 5.64297C11.6587 6.94472 11.6587 9.05527 10.357 10.357C9.05526 11.6588 6.9447 11.6588 5.64296 10.357C4.34121 9.05527 4.34121 6.94472 5.64296 5.64297C6.9447 4.34122 9.05526 4.34122 10.357 5.64297Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_92_41092">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
