import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ShortcutAppIcon: FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <g clipPath="url(#clip0_3264_74564)">
      <path d="M5.30686 -0.0273438L-0.0263672 5.30588L5.30686 10.6391L10.6401 5.30588L5.30686 -0.0273438Z" fill="url(#paint0_linear_3264_74564)" />
      <path d="M10.6404 5.30566L5.30713 10.6389L10.6404 15.9721L15.9736 10.6389L10.6404 5.30566Z" fill="url(#paint1_linear_3264_74564)" />
      <path d="M5.30688 -0.0273438L10.64 5.30577L15.9734 -0.0273438H5.30688Z" fill="url(#paint2_linear_3264_74564)" />
      <path d="M5.30697 10.6396L-0.0263672 15.9728H10.6401L5.30697 10.6396Z" fill="url(#paint3_linear_3264_74564)" />
      <path d="M5.30697 10.6396L-0.0263672 15.9728H10.6401L5.30697 10.6396Z" fill="url(#paint4_linear_3264_74564)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_3264_74564" x1="7.93506" y1="7.90795" x2="2.90197" y2="2.92509" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3B95C9"  />
        <stop offset="1" stopColor="#59B1E3" />
      </linearGradient>
      <linearGradient id="paint1_linear_3264_74564" x1="8.3521" y1="8.23297" x2="13.2342" y2="13.3667" gradientUnits="userSpaceOnUse">
        <stop stopColor="#59B1E3" />
        <stop offset="1" stopColor="#9BD0F0" />
      </linearGradient>
      <linearGradient id="paint2_linear_3264_74564" x1="15.0732" y1="-1.15737" x2="5.09225" y2="4.17995" gradientUnits="userSpaceOnUse">
        <stop stopColor="#59B1E3" />
        <stop offset="1" stopColor="#9BD0F0" />
      </linearGradient>
      <linearGradient id="paint3_linear_3264_74564" x1="10.9543" y1="11.4969" x2="0.974874" y2="17.5541" gradientUnits="userSpaceOnUse">
        <stop stopColor="#59B1E3" />
        <stop offset="1" stopColor="#9BD0F0" />
      </linearGradient>
      <linearGradient id="paint4_linear_3264_74564" x1="7.93866" y1="13.2958" x2="5.01948" y2="16.2654" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3B95C9"  />
        <stop offset="1" stopColor="#9BD0F0" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_3264_74564">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
