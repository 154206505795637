import { RefObject, useEffect, useState } from 'react';

export type MousePosition = {
  /** x position of the mouse relative to the container */
  x: number | null;
  /** y position of the mouse relative to the container */
  y: number | null;
  /** true if the mouse is outside the container */
  out: boolean;
};

type Props = {
  /* Ref to the container */
  rootRef: RefObject<HTMLElement>;
  /* If true, the hook will not listen to mouse events */
  isDisabled?: boolean;
};

/**
 * Hook to track the mouse position relative to a container
 * It be used in a top-level component, since that will cause a huge chunk of the React tree to re-render very often
 * Use this hook in the small “leaf node” components near the bottom of the tree
 */
export const useMousePosition = ({
  rootRef, isDisabled,
}: Props): MousePosition => {
  const [pos, setPos] = useState<MousePosition>({
    x: null,
    y: null,
    out: true,
  });

  useEffect(() => {
    const container = rootRef.current;
    const rect = container?.getBoundingClientRect();
    const onMouseMove = (e: MouseEvent) => setPos({
      x: e.clientX - (rect?.left ?? 0),
      y: e.clientY - (rect?.top ?? 0),
      out: false,
    });
    const onMouseLeave = () => setPos(prev => ({
      ...prev,
      out: true,
    }));
    if (isDisabled) {
      setPos(prev => ({
        ...prev,
        x: null,
        y: null,
      }));
    } else {
      container?.addEventListener('mousemove', onMouseMove);
      container?.addEventListener('mouseleave', onMouseLeave);
    }
    return () => {
      container?.removeEventListener('mousemove', onMouseMove);
      container?.removeEventListener('mouseleave', onMouseLeave);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  return pos;
};
