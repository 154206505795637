import { MateFragment } from '@cycle-app/graphql-codegen';

import { useProductPresence } from 'src/hooks/realtime/useProductPresence';
import { MemberViewing } from 'src/types/realTime.type';

type UseMemberViewingReturn = {
  status: 'online' | 'offline' | 'pending';
  viewing: MemberViewing | null;
  id: string | null;
};

export const useMemberViewing = (user: MateFragment): UseMemberViewingReturn => {
  const onlineUsers = useProductPresence();
  const onlineUser = onlineUsers.find(u => u.id === user.id);

  // eslint-disable-next-line no-nested-ternary
  const viewing = onlineUser?._docId
    ? MemberViewing.DOC
    : onlineUser?._boardId
      ? MemberViewing.BOARD
      : null;

  const id = onlineUser?._docId || onlineUser?._boardId || null;

  const isPending = !user.jobTitle;

  return {
    // eslint-disable-next-line no-nested-ternary
    status: isPending
      ? 'pending'
      : onlineUser
        ? 'online'
        : 'offline',
    viewing,
    id,
  };
};
