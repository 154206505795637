import { TextButton, Input, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { ViewType } from 'src/types/viewType.types';

export const Container = styled.div<{ viewType: ViewType }>`
  flex-shrink: 0;

  ${p => (p.viewType === ViewType.Kanban && css`
    width: 270px;
    padding-top: 9px;
  `)}
  ${p => (p.viewType === ViewType.List && css`
    width: 100%;
  `)}

`;

export const NewGroupButton = styled(TextButton)`
  width: 100%;
  padding: 2px 8px;
  border-radius: 6px;
  ${typo.caption400};
  --bgHover: ${p => p.theme.colors.board.textButton.bgHover};
  --bgActive: ${p => p.theme.colors.board.textButton.bgHover};
  --colorHover: ${p => p.theme.colors.board.textButton.colorHover};
`;

export const StyledInput = styled(Input)`
  width: 100%;

  & input {
    height: 32px;
  }
`;
