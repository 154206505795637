import { Button, Shortcuts, ShortcutComponents, boxShadowZ1 } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

export const StyledShortcuts = styled(Shortcuts)`
  ${ShortcutComponents.Key} {
    background-color: ${Color.Grey800};
    color: ${Color.Grey400};
  }
`;

export const ProcessButton = styled(Button).attrs({
  size: 'L',
  variant: 'secondary',
})<{
  $isActive: boolean;
  $parent: 'doc-item' | 'doc-panel';
}>`
  padding: 6px;
  border: 1px solid ${p => p.theme.colors.docProcess.border};
  color: ${p => p.theme.colors.docProcess.color};
  ${boxShadowZ1}

  ${p => p.$isActive && `
    color: ${p.theme.colors.docProcess.colorProcessed};
  `};

  &,:focus {
    background-color: ${p => p.theme.colors.docProcess.bg};
  }

  :hover {
    background-color: ${p => p.theme.colors.docProcess.bgHover};
  }
  
  :active {
    background-color: ${p => p.theme.colors.docProcess.bgActive};
  }

  ${p => p.$parent === 'doc-item' && `
    margin-left: 4px;
    padding: 4px;
  `};
`;
