import { NavigationItemProps } from '@cycle-app/ui';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';
import { useSidebarCollapsed } from 'src/hooks';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';

import { Label } from './SidebarFooterItem.styles';

export const Item = ({
  label, onClick, ...props
}: NavigationItemProps) => {
  const isOnboarding = useIsOnboarding();
  const collapsed = useSidebarCollapsed();
  return (
    <CollapsableNavigationItem
      isSidebarCollapsed={collapsed}
      isSidebarColor
      asPlaceholder={isOnboarding}
      placeholderScale={[0.5, 0.3]}
      label={<Label>{label}</Label>}
      tooltipContent={label}
      onClick={onClick}
      animate
      collapsed={collapsed}
      {...props}
    />
  );
};
