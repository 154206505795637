import { ThemeType } from '@cycle-app/graphql-codegen';
import { UserIcon, LogoutIcon, DarkIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';

import MyAvatar from 'src/components/MyAvatar/MyAvatar';
import { useMe } from 'src/hooks/api/useMe';
import { useLogout } from 'src/hooks/useLogout';
import { useIsMobile, useIsSmallScreen } from 'src/reactives/responsive.reactive';
import { toggleSettingsModal } from 'src/reactives/settingsModal.reactive';
import { toggleTheme, useIsEclipseTheme, useGetThemeConfig } from 'src/reactives/theme.reactive';

import {
  Container,
  Options,
  UserInfo,
  Username,
  UserEmail,
  HeaderItem,
  StyledSelectLine,
} from './UserMenu.styles';

type UserMenuProps = {
  onItemClicked: VoidFunction;
};

export const UserMenu = ({ onItemClicked }: UserMenuProps) => {
  const isEclipseTheme = useIsEclipseTheme();
  const { me } = useMe();
  const logout = useLogout();
  const isMobile = useIsMobile();
  const isSmallScreen = useIsSmallScreen();
  const { colorTheme } = useGetThemeConfig();

  const menuItems = useMemo(() => [
    {
      id: 'header-item',
      onSelect: isMobile ? undefined : toggleSettingsModal,
    },
    ...isSmallScreen ? [] : [{
      id: 'account',
      icon: (<UserIcon />),
      label: 'Account settings',
      onSelect: toggleSettingsModal,
      section: 1,
    }],
    {
      id: 'theme',
      icon: <DarkIcon />,
      label: 'Dark mode',
      onClick: toggleTheme,
      checked: colorTheme === ThemeType.Nightfall,
      section: 1,
    },
    {
      id: 'logout',
      icon: (<LogoutIcon />),
      label: 'Log out',
      onSelect: logout,
      variant: 'danger' as const,
      section: 3,
    },
  ], [isMobile, isSmallScreen, colorTheme, logout]);

  const optionsValues = useMemo(
    () => menuItems.map(item => item.id),
    [menuItems],
  );

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    value: null,
    optionsValues,
    onSelect: (itemId) => {
      const item = menuItems.find(i => i.id === itemId);
      if (!item?.onSelect) return;
      item.onSelect();
      onItemClicked();
    },
  });

  const groups = useMemo(() => groupBy(menuItems.slice(1), 'section'), [menuItems]);

  return (
    <Container {...listProps}>
      <Options>
        <HeaderItem
          selected={selected === 'header-item'}
          {...itemProps('header-item')}
        >
          <MyAvatar oppositeColor={isEclipseTheme} />
          <UserInfo>
            <Username>
              {`${me.firstName} ${me.lastName}`}
            </Username>
            <UserEmail>
              {me.email}
            </UserEmail>
          </UserInfo>
        </HeaderItem>
      </Options>

      {Object.entries(groups).map(([section, items]) => (
        <Options key={section}>
          {items.map(item => {
            const lineProps = itemProps(item.id);
            return (
              <StyledSelectLine
                key={item.id}
                isSelected={selected === item.id}
                startSlot={item.icon}
                label={item.label}
                variant={item.variant}
                selectType="toggle"
                checked={item.checked}
                {...lineProps}
                onClick={item.onClick ?? lineProps.onClick}
              />
            );
          })}
        </Options>
      ))}
    </Container>
  );
};
