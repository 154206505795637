import { ReleaseBaseFragment, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import keyBy from 'lodash/keyBy';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { PageId, routing } from 'src/constants/routing.constant';
import { useRouteMatch } from 'src/hooks';
import { usePaginatedReleases } from 'src/hooks/releases/useReleases';
import { useUrl } from 'src/hooks/useUrl';

import { BoardConfigContextProvider } from './boardConfigContext';

type ReleasesProviderProps = {
  productId: string;
};

type ReleasesContextValue = {
  productId: string;
  isLoading: boolean;
  isLoadingMore: boolean;
  releases: Record<string, ReleaseBaseFragment>;
  releasesList: Array<ReleaseBaseFragment>;
  releaseNoValue: ReleaseBaseFragment | null;
  hasNextPage: boolean;
  endCursor: string;
  loadMore: () => Promise<void>;
  publishedCount: number;
};

const ReleasesContext = createContext<ReleasesContextValue>({} as ReleasesContextValue);

export const ReleasesProvider: FC<React.PropsWithChildren<ReleasesProviderProps>> = ({
  productId, children,
}) => {
  const history = useHistory();
  const getUrl = useUrl();

  const matchReleases = !!useRouteMatch({
    path: routing[PageId.Releases],
    exact: true,
  });

  const query = usePaginatedReleases(productId, {
    onCompleted: (data) => {
      // If we are on releases page, we open the first created release in the list
      // The release at index 0 is the "No release"
      const edges = data.node.releases?.edges;
      if (!edges) return;
      const index = edges.length > 1 ? 1 : 0;
      const releaseId = edges?.[index]?.node.id;
      if (matchReleases && releaseId) {
        history.replace(getUrl(PageId.Release, { releaseId }));
      }
    },
  });

  const value = useMemo(() => ({
    ...query,
    productId,
    releases: keyBy(query.releasesList, 'id'),
    publishedCount: query.releasesList.filter(release => release.publicStatus === ReleasePublicStatus.Published).length,
    /**
     * We assume from backend rules that there will always be one release without
     * date. This one is the default release where the release notes will be
     * transferred when deleting a release containing release notes
     */
    releaseNoValue: query.releasesList.find(r => !r.date) || null,
  }), [query, productId]);

  return (
    <ReleasesContext.Provider value={value}>
      <BoardConfigContextProvider>
        {children}
      </BoardConfigContextProvider>
    </ReleasesContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useReleasesContext = <T extends unknown = ReleasesContextValue>(selector?: ContextSelector<ReleasesContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(ReleasesContext);
  if (!isWrappedWithContext) throw new Error('useReleasesContext must be used within a ReleasesProvider');
  return useContextSelector(ReleasesContext, selector ?? (ctx => ctx as T));
};

export const useReleaseBase = (id?: string | null) => useReleasesContext(ctx => (id ? ctx.releases[id] : null));
