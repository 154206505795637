import { BillingPlan, ProductFragment } from '@cycle-app/graphql-codegen';

import { ProductStatus } from '../types/product.types';

export const getProductStatus = (
  product: Partial<ProductFragment> | null | undefined,
  isSuperAdmin?: boolean,
): NonNullable<ProductStatus> => {
  if (!product?.id) {
    return 'unavailable';
  }

  if (!isSuperAdmin && product?.plan === BillingPlan.Free) return 'trial-ended';

  if (product.endOfFreeTrial) {
    if (!isSuperAdmin && new Date() > new Date(product.endOfFreeTrial)) {
      return 'trial-ended';
    }
    return 'trial';
  }

  if (product.subscriptionPeriodEnd) {
    if (!isSuperAdmin && product.subscriptionPeriodEnd && new Date() > new Date(product.subscriptionPeriodEnd)) {
      return 'pro-ended';
    }
    if (!isSuperAdmin) {
      return 'pro-cancelled';
    }
  }

  return 'pro';
};
