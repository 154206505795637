import { CreateLinearProjectDocument, CreateLinearProjectMutationVariables } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useSafeMutation from 'src/hooks/useSafeMutation';

type Variables = CreateLinearProjectMutationVariables & {
  teamIds: string[];
};

export const useCreateLinearProject = () => {
  const [mutate, { loading }] = useSafeMutation(CreateLinearProjectDocument);

  const providerId = useWorkspaceContext(ctx => ctx.linearProviderId);

  const createLinearProject = (variables: Variables) => {
    return mutate({
      variables,
      optimisticResponse: {
        createLinearProject: {
          __typename: 'LinearProject',
          id: 'optimistic',
          name: variables.name,
          progress: 0,
          url: '',
          color: '',
          icon: variables.icon,
          issues: [],
          teams: variables.teamIds.map(id => ({
            __typename: 'LinearTeam',
            id,
          })),
        },
      },
      update: (cache, { data }) => {
        const project = data?.createLinearProject;
        if (!project) return;
        cache.modify({
          id: cache.identify({ id: providerId }),
          fields: {
            projects: (projects) => {
              return [
                ...projects,
                {
                  __ref: cache.identify(project),
                },
              ];
            },
          },
        });
      },
    });
  };

  return {
    createLinearProject,
    isLoading: loading,
  };
};
