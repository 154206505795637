/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
import { Tag, typo, boxShadowZ1 } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
  color: ${p => p.theme.colors.text.secondary};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.components.EditorIntegrationCard.border};
  background: ${p => p.theme.colors.components.InsightCard.card.bg};
  ${boxShadowZ1}
`;

export const PreviewTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const Quote = styled.div`
  /* Line clamp */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  
  position: relative;
  padding-left: 14px;
  :before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    border-radius: 10px;
    height: 100%;
    background: ${p => p.theme.colors.border.primary};
  }
`;
