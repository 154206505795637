import { AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';

import { RIGHT_PANEL_WIDTH } from 'src/constants/docPanel.constants';
import { useIsTablet } from 'src/reactives';
import { useGetDocPanel, useVerifyQuotesOpen } from 'src/reactives/docRightPanel.reactive';

import { Wrapper, Container, Content } from './VerifyQuotes.styles';

type Props = {
  children: ReactNode;
};

export const VerifyQuotesContainer = ({ children }: Props) => {
  const isVerifyQuotesOpen = useVerifyQuotesOpen();
  const docPanel = useGetDocPanel();
  const isTablet = useIsTablet();
  return (
    <AnimatePresence>
      {isVerifyQuotesOpen && (
        <Wrapper
          {...(isTablet ? {
            style: {
              zIndex: 10,
              position: 'absolute',
              inset: 0,
              width: '100%',
            },
            variants: {
              visible: {
                opacity: 1,
              },
              hidden: {
                opacity: 0,
              },
            },
          } : {
            style: {
              zIndex: 10,
              marginRight: docPanel.isExpanded ? -RIGHT_PANEL_WIDTH : 0,
            },
            variants: {
              visible: {
                opacity: 1,
                width: 'auto',
              },
              hidden: {
                opacity: 0,
                width: docPanel.isExpanded ? RIGHT_PANEL_WIDTH : 0,
              },
            },
          })}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Container
            style={{
              width: isTablet ? '100%' : '480px',
              maxWidth: '100%',
            }}
          >
            <Content>
              {children}
            </Content>
          </Container>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
