import { useSubscription } from '@apollo/client';
import {
  NbAiQueriesDocument,
  NbAiQueriesSubscription,
  NbAiQueriesSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useProductBase } from './useProduct';

export const useAiQueriesSubscription = () => {
  const product = useProductBase();

  return useSubscription<NbAiQueriesSubscription, NbAiQueriesSubscriptionVariables>(
    NbAiQueriesDocument,
    {
      variables: {
        productId: product?.id || '',
      },
    },
  );
};
