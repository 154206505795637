import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetInsightForm,
  getValue: getInsightForm,
  setValue: setInsightForm,
} = make({
  localKey: LocalKey.Linear,
  crossTabSync: true,
  defaultState: {
    useFeedbackContentAsQuote: false,
  },
});
