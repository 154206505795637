import { typo, transitionCss, TextButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const SettingsContainer = styled.div`
  min-width: 300px;
  color: ${p => p.theme.colors.text.primary};
  background-color: ${p => p.theme.colors.popup.bg};
  ${transitionCss(['color', 'background-color'])}
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 12px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const Label = styled.div`
  ${typo.body500}
`;

export const ItemButton = styled(TextButton).attrs({
  variant: 'nospace',
})`
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
  padding: 2px 6px;
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 8px 16px 10px;
  border-top: 1px solid ${p => p.theme.colors.popup.separator};
  &, ${ItemButton} {
    ${typo.body400}
    color: ${p => p.theme.colors.text.secondary};
  }
`;
