import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { body400 } from '../../theme/typo';
import { ShyScrollbarCss } from '../ShyScrollbar/ShyScrollbar.styles';

export interface SectionProps {
  $full?: boolean;
}

const scrollbarOffset = 14;

export const Section = styled.div<SectionProps>`
  margin-top: 8px;
  margin-bottom: 8px;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  gap: 4px;
  padding: 0;

  ${body400};

  flex: ${(props) => (props.$full ? 1 : 0)};
  ${p => p.$full && css`
    ${ShyScrollbarCss};
    margin-right: -${scrollbarOffset}px;
    padding-right: ${scrollbarOffset}px;
  `};

  ${mobile} {
    margin-top: 0px;
    padding-bottom: 24px;
    padding-top: 24px;
  }
`;
