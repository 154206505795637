import { ThemeType } from '@cycle-app/graphql-codegen';
import { Button, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

const smallHeight = '@media (max-height : 630px)';

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;

  :before, :after {
    content: '';
    position: absolute;
    inset: 0;
    background-position: 50% calc(50% - 160px);
    background-repeat: no-repeat;
    pointer-events: none;
  }

  :before {
    background-image: url(/images/releases-bg.png);
    ${p => p.theme.themeType === ThemeType.Nightfall && 'filter: invert(100%)'};
  }

  :after {
    background-image: url(/images/insights-empty.svg);
  }

  ${smallHeight} {
    :before, :after {
      display: none;
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  top: calc(50% + 70px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${smallHeight} {
    top: 50%;
  }

  h1 {
    ${typo.headerMedium}
    margin-bottom: 12px;
  }

  > p {
    ${typo.body400}
    color: ${p => p.theme.colors.text.secondary};
    text-align: center;
    margin-bottom: 16px;
    max-width: 550px;
  }
`;

export const LearnMoreButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'S',
})`
  margin-top: 58px;
  height: 28px;
  padding: 0 12px;
  ${typo.caption400}
  border-radius: 50px;

  ${p => css`
    border: 1px solid ${p.theme.colors.releases.learnMore.border};
    --color: ${p.theme.colors.releases.learnMore.color};
    --bg: ${p.theme.colors.releases.learnMore.bg};
    :hover, :focus {
      --bg: ${p.theme.colors.releases.learnMore.bgHover};
    }
    :active {
      --bg: ${p.theme.colors.releases.learnMore.bgActive};
    }
  `}
`;
