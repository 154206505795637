import { ArchiveProductDocument, Role } from '@cycle-app/graphql-codegen';
import { CycleLogo, Button } from '@cycle-app/ui';
import { LogoutIcon } from '@cycle-app/ui/icons';
import { Redirect } from 'react-router-dom';

import { ErrorToasters } from 'src/app/ErrorToasters/ErrorToasters';
import { AppToasters } from 'src/components/AppToasters';
import { UpgradeModal } from 'src/components/UpgradeModal';
import { useLogout, useProduct, useProductRole, useSafeMutation } from 'src/hooks';
import { useProducts } from 'src/hooks/api/useProducts';
import { useIntercom } from 'src/hooks/useIntercom';
import { useNavigate } from 'src/hooks/useNavigate';
import { PageId } from 'src/hooks/useUrl';
import { setUpgradeModal } from 'src/reactives/upgradeModal.reactive';
import { openIntercom } from 'src/utils/intercom.utils';
import { addToaster } from 'src/utils/toasters.utils';

import {
  Root,
  Container,
  StyledToastersContainer,
  StyledErrorContainer,
  Main,
  Footer,
  SubTitle,
  Title,
  LogoutButton,
} from './ProductEmptyState.styles';

export const TrialEnded = () => {
  const { products } = useProducts();
  const { getUrl } = useNavigate();
  const productPro = products.find(p => p.plan === 'STANDARD');
  if (productPro && products.length > 1) return <Redirect to={getUrl(PageId.Main, { productSlug: productPro.slug })} />;
  return (
    <Layout
      title="Your free trial has ended"
      subtitle="To keep using Cycle, you need to upgrade."
    />
  );
};

export const ProEnded = () => {
  return (
    <Layout
      title="Your subscription has ended"
      subtitle="To keep using Cycle, you need to upgrade your subscription."
    />
  );
};

const Layout = ({
  title, subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  const logout = useLogout();
  const { product } = useProduct();
  useIntercom();

  const {
    role, isLoading: isRoleLoading,
  } = useProductRole();

  const [archiveProduct, { loading }] = useSafeMutation(ArchiveProductDocument, {
    onCompleted: (data) => {
      if (data.updateProduct?.id) {
        addToaster({
          title: 'We received your request to archive the workspace. We’ll be on it!',
          duration: 5000,
        });
      }
    },
  });

  return (
    <>
      <Root>
        <Container>
          <Main>
            <CycleLogo size={100} color="blue" />

            <Title>{title}</Title>

            <SubTitle>{subtitle}</SubTitle>

            <Button
              size="M"
              onClick={() => setUpgradeModal({ isOpened: true })}
            >
              Upgrade
            </Button>

            <Button
              size="M"
              variant="secondary"
              isLoading={loading}
              disabled={isRoleLoading || role === Role.Collaborator}
              onClick={async () => {
                if (!product) return;
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                archiveProduct({ variables: { productId: product.id } });
              }}
            >
              Archive
            </Button>
          </Main>

          <Footer>
            <Button
              variant="secondary"
              size="M"
              onClick={() => openIntercom()}
            >
              Any questions? Say hi!
            </Button>
          </Footer>

          <LogoutButton onClick={logout} size="L">
            <LogoutIcon size={16} />
            Log out
          </LogoutButton>
        </Container>
      </Root>

      <StyledToastersContainer>
        <AppToasters />
      </StyledToastersContainer>

      <StyledErrorContainer>
        <ErrorToasters />
      </StyledErrorContainer>

      {product?.id && <UpgradeModal productId={product.id} />}
    </>
  );
};
