import { UpdateReleaseBugAndImprovementsDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

import { getPermission, setLimitationsModal } from '../../reactives';

export const useUpdateReleaseBugAndImprovements = () => {
  const [mutate, { loading }] = useSafeMutation(UpdateReleaseBugAndImprovementsDocument, {
    errorPolicy: 'all',
  });

  const updateRelease = ({
    id, showBugAndImprovements,
  }: { id: string; showBugAndImprovements: boolean }) => {
    if (!getPermission().canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return null;
    }
    return mutate({
      variables: {
        id,
        showBugAndImprovements,
      },
      optimisticResponse: {
        updateRelease: {
          id,
          showBugAndImprovements,
        },
      },
    });
  };

  return {
    isUpdatingRelease: loading,
    updateRelease,
  };
};
