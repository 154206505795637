import { FC } from 'react';

import { useEditorContext } from 'src/contexts/editorContext';
import { useCanAutomateLinear } from 'src/hooks/doc/useCanAutomateLinear';
import { getDocType } from 'src/reactives/docTypes.reactive';

export type LinearEmbedDescriptionProps = {
  embedName?: string;
};

export const LinearDiscoverEmbedDescription: FC<React.PropsWithChildren<LinearEmbedDescriptionProps>> = ({ embedName }) => {
  const canAutomateLinear = useCanAutomateLinear();
  const doctypeName = useEditorContext(ctx => getDocType(ctx.doc?.doctype.id)?.name);
  return canAutomateLinear ? (
    <div>
      This looks like a
      {` ${embedName}.`}
      <br />
      Link it to the
      {` ${doctypeName} `}
      to sync its status with Cycle.
    </div>
  ) : (
    <div>
      This looks like a
      {` ${embedName}. `}
      Embed it to visualize its details in Cycle.
    </div>
  );
};
