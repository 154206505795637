import { LinearStatus } from 'src/components/LinearStatus/LinearStatus';
import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { useLinearProjectIssues } from 'src/hooks/api/queries/integrations/useLinearProjectIssues';
import { issueInfos } from 'src/utils/linear.utils';

import { Infos, IssueTitle, ProjectIssue, PreviewList } from './DocLinear.styles';
import { LinearSkeletons } from './LinearSkeletons';

export const PreviewProjectIssues = ({ skeleton }: { skeleton?: JSX.Element }) => {
  const projectId = useDocLinearContext(ctx => ctx.automationId);
  const integrationId = useDocLinearContext(ctx => ctx.integrationId);
  const query = useLinearProjectIssues(projectId, integrationId);
  if (query.isLoading && !query.issues) return skeleton || <LinearSkeletons />;
  if (!query.issues?.length) return null;
  return (
    <PreviewList>
      {query.issues.map(issue => (
        <ProjectIssue key={issue.id}>
          <Infos>{issueInfos(issue.url)}</Infos>
          {issue.status && <LinearStatus status={issue.status} size={14} />}
          <IssueTitle>{issue.title}</IssueTitle>
        </ProjectIssue>
      ))}
    </PreviewList>
  );
};
