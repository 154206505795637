import { ExportBoardDocsDocument } from '@cycle-app/graphql-codegen';

import DialogModal from 'src/components/DialogModal/DialogModal';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { addToaster } from 'src/utils/toasters.utils';

type BoardExportModalProps = {
  boardId: string;
  onHide: VoidFunction;
};

export const BoardExportModal = ({
  boardId,
  onHide,
}: BoardExportModalProps) => {
  const [exportBoardDocs, { loading }] = useSafeMutation(ExportBoardDocsDocument);
  return (
    <DialogModal
      hide={onHide}
      type="default"
      title="Export in CSV"
      onConfirm={async () => {
        await exportBoardDocs({ variables: { boardId } });
        addToaster({
          title: "CSV export is in progress, you'll receive an email very soon",
          icon: '📬',
        });
      }}
      info={(
        <>
          Are you sure you want to export this view data into CSV?
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You'll receive an email from Cycle when it's ready.
        </>
      )}
      width="auto"
      loading={loading}
      variantSubmitButton="primary"
      variantCancelButton="secondary"
      confirmLabel="Export"
    />
  );
};
