import { MateFragment } from '@cycle-app/graphql-codegen';
import { nodeToObject } from '@cycle-app/utilities';
import { usePresenceChannel } from '@harelpls/use-pusher';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useProduct } from 'src/hooks/api/useProduct';
import { useDocId } from 'src/hooks/usePathParams';
import { Channel, getChannelName } from 'src/utils/presence.util';

export const useDocPresence = (): Array<MateFragment> => {
  const docId = useDocId();
  const { product } = useProduct();
  const { members } = usePresenceChannel(getChannelName(
    Channel.Doc,
    docId ?? '',
  ));

  return useMemo(() => {
    const productUsers = nodeToObject(product?.users);
    return Object.keys(members ?? {}).map(userId => productUsers[userId]).filter(isPresent);
  }, [members, product]);
};
