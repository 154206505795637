import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { lowlight } from 'lowlight';

import BlockCodeView from 'src/components/Editor/NodeViews/BlockCode/BlockCodeView';

export const getCodeBlockExtension = () => CodeBlockLowlight.extend({
  addNodeView() {
    return ReactNodeViewRenderer(BlockCodeView);
  },
}).configure({ lowlight });
