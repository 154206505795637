import { useEffect } from 'react';

import { featureToasterContents } from 'src/components/FeatureToasterContent';
import { setFeaturesShowed, useFeatures } from 'src/reactives/features.reactive';
import { addToaster } from 'src/utils/toasters.utils';

import { getNewFeaturesToasterId } from '../constants/features.constants';

export const useNewFeaturesToaster = () => {
  const [{ queue }] = useFeatures();

  useEffect(() => {
    queue
      .forEach(feature => {
        const ToasterContent = featureToasterContents[feature];
        if (!ToasterContent) return;
        addToaster({
          id: getNewFeaturesToasterId(feature),
          duration: 'infinite',
          children: <ToasterContent />,
          onAfterClosed: () => setFeaturesShowed(feature),
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
