import { ReactRenderer } from '@tiptap/react';
import Emoji, { EmojiStorage, emojis } from '@tiptap-pro/extension-emoji';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import { Layer } from 'src/types/layers.types';
import { clientRectWithFallback } from 'src/utils/editor/editor.utils';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

import EmojiList from './EmojiList';

export const getEmojisExtension = () => Emoji.configure({
  emojis,
  enableEmoticons: true,
  suggestion: {
    items({
      editor, query,
    }) {
      const emojisInStorage = (editor.storage.emoji as EmojiStorage).emojis;

      if (query.length === 0) return emojisInStorage.slice(0, 20);

      return emojisInStorage
        .filter(({
          shortcodes, tags,
        }) => {
          return (
            shortcodes.find(shortcode => shortcode.startsWith(query.toLowerCase())) ||
          tags.find(tag => tag.startsWith(query.toLowerCase()))
          );
        })
        .slice(0, 5);
    },

    render() {
      let reactRenderer: ReactRenderer | undefined;
      let popup: TippyInstance<TippyProps> | undefined;

      return {
        onStart: (props) => {
          reactRenderer = new ReactRenderer(EmojiList, {
            props,
            editor: props.editor,
          });

          popup = getTippyPopup({
            props,
            reactRenderer,
            layer: Layer.DropdownZ1,
          })?.[0];
        },
        onUpdate(props) {
          reactRenderer?.updateProps(props);
          popup?.setProps({
            getReferenceClientRect: clientRectWithFallback(props.clientRect),
          });
        },
        onKeyDown({ event }) {
          if (!popup) return true;
          if (event.key === 'Escape') {
            popup?.hide();
            return true;
          }

          return onKeyDown({
            event,
            popupInstance: popup,
          });
        },

        onExit() {
          popup?.destroy();
          reactRenderer?.destroy();
        },
      };
    },
  },
});
