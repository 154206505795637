import styled, { css } from 'styled-components';

export const TabList = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
  top: 1px;
`;

export const Tab = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;
  position: relative;
  border-radius: 4px;
  outline: none;
  color: ${p => p.theme.colors.text.secondary};

  &:hover {
    color: ${p => p.theme.colors.components.Tab.textHover};
    background: ${p => p.theme.colors.components.Tab.bgHover};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: transparent;
  }

  ${p => p.$active && css`
    font-weight: 500;
    color: ${p.theme.colors.text.primary};
    &::after {
      background-color: hsl(var(--cycle));
    }
  `}

  ${p => p.disabled && css`
    cursor: not-allowed;
  `}
`;
