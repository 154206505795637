import { FileUploadedData } from '@cycle-app/utilities/src/types/files.types';
import { Node, mergeAttributes } from '@tiptap/core';

import { FILE_EXTENSION_NAME } from '../constants';
import { getEditorImageNode } from './imageExtension';

type SetFileAttributes = {
  file?: FileUploadedData;
  title?: string;
  dataId?: string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [FILE_EXTENSION_NAME]: {
      setFile: (options: SetFileAttributes) => ReturnType;
    };
  }
}

export const getEditorFileNode = (data: SetFileAttributes) => ({
  type: FILE_EXTENSION_NAME,
  attrs: {
    ...data,
  },
});

export const fileExtension = Node.create({
  name: FILE_EXTENSION_NAME,
  draggable: true,
  group: 'block',

  atom: false,

  addAttributes() {
    return {
      file: {
        default: null,
        parseHTML: e => {
          const attr = e.getAttribute('file');
          if (attr) {
            try {
              return JSON.parse(attr);
            } catch {
              return '';
            }
          }
          return '';
        },
        renderHTML: e => ({
          file: e.file ? JSON.stringify(e.file) : '',
        }),
      },
      dataId: {
        default: '',
      },
    };
  },

  addCommands() {
    return {
      setFile: (options) => ({ commands }) => {
        if (options?.file?.type === 'image') {
          // Do not use `commands.setImage` as we need to set the dataId.
          // (we cannot override the arguments of setImage)
          return commands
            .insertContent([getEditorImageNode({
              src: options.file.src,
              title: options.title,
              dataId: options.dataId,
            })]);
        }
        return commands
          .insertContent([
            {
              type: this.name,
              attrs: options,
            },
          ]);
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'react-component',
      mergeAttributes(HTMLAttributes),
    ];
  },
});
