import { useApolloClient } from '@apollo/client';
import { DocNodeDocument } from '@cycle-app/graphql-codegen';
import { useEvent } from '@harelpls/use-pusher';
import { useCallback } from 'react';

import { getDocFromCache } from 'src/utils/cache.utils';
import { getOtherUserId } from 'src/utils/me.util';

import { UserLocation, PresenceChannel } from './types';
import { useGetUserFromCache } from '../api/cache/cacheUser';
import { useMaybeMe } from '../api/useMe';

export const useLocationEvent = (channel: PresenceChannel, eventName: string) => {
  const { cache } = useApolloClient();
  const getUser = useGetUserFromCache();
  const me = useMaybeMe();
  const meOtherUserId = getOtherUserId(me?.id ?? '');

  useEvent<UserLocation>(channel, eventName, useCallback((data) => {
    if (!data) return;

    const {
      userId, boardId, docId,
    } = data;

    const user = getUser(userId);

    if (user?._docId && !docId) {
      const doc = getDocFromCache(user._docId);
      if (!doc?._viewers) {
        return;
      }
      cache.writeQuery({
        query: DocNodeDocument,
        data: {
          node: {
            ...doc,
            _viewers: doc._viewers?.filter(viewerId => viewerId !== userId),
          },
        },
      });
    } else if (docId) {
      const doc = getDocFromCache(docId);
      if (!doc?._viewers || doc._viewers.includes(userId) || userId === meOtherUserId) return;

      cache.writeQuery({
        query: DocNodeDocument,
        data: {
          node: {
            ...doc,
            _viewers: [
              ...doc._viewers as string[],
              userId,
            ],
          },
        },
      });
    }

    cache.modify({
      id: userId,
      fields: {
        _boardId: () => boardId,
        _docId: () => docId,
      },
    });
  }, [cache, getUser, meOtherUserId]));
};
