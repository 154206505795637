import { SelectPanel, SelectOption, SelectPanelProps } from '@cycle-app/ui';
import { Helper } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { ReactNode } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { FieldLabel, FieldButton, Caret } from './Form.styles';

type Props = Pick<SelectPanelProps, 'onSelectOption' | 'onUnselectOption' | 'onSelectAll' | 'onUnselectAll'> & {
  label: string;
  layer?: Layer;
  options: SelectOption[];
  value?: ReactNode;
  isLoading: boolean;
  error?: string;
};

export const MultiSelectField = ({
  layer, label, options, value, isLoading, error, ...props
}: Props) => {
  return (
    <div>
      <FieldLabel>{label}</FieldLabel>
      <ToggleDropdown
        placement="bottom-start"
        layer={layer}
        withPortal
        withWrapper={false}
        button={buttonProps => (
          <FieldButton
            {...buttonProps}
            isLoading={isLoading}
            iconEnd={<Caret direction={buttonProps['data-active'] ? 'top' : 'bottom'} />}
            $hasError={!!error}
          >
            {value || 'Choose from list'}
          </FieldButton>
        )}
        content={() => (
          <SelectPanel
            {...props}
            isMulti
            toggleAllValuesVariant="toggle"
            options={options}
          />
        )}
      />

      {error && (
        <Helper role="alert" $hasError>{error}</Helper>
      )}
    </div>
  );
};
