import { Button } from '@cycle-app/ui';
import styled from 'styled-components';

const IconButton = styled(Button).attrs({
  variant: 'nospace',
})`
  border-radius: 4px;
  padding: 4px;
  :hover, :focus {
    background: ${p => p.theme.colors.actionButton.bgHover};
  }
  :active {
    background: ${p => p.theme.colors.actionButton.bgActive};
  }
`;

export const IconInput = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 40px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  flex: none;
`;
