import { getPermission, setLimitationsModal } from '../reactives';

/**
 * @example
 * ```js
 * requestInsightUpdate(callback);
 * // or
 * const can = requestInsightUpdate();
 * if (!can) return;
 * callback();
 * ```
 */
export const requestInsightUpdate = (callback?: VoidFunction) => {
  if (!getPermission().canUpdateInsight) {
    setLimitationsModal({ action: 'INSIGHT_UPDATE' });
    return false;
  }
  callback?.();
  return true;
};
