import { typo, Shortcut } from '@cycle-app/ui';
import { Key } from '@cycle-app/ui/components/Shortcut/Shortcut.styles';
import styled from 'styled-components';

export const ShortcutsContainer = styled.span`
  ${typo.body400}
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
  white-space: nowrap;
  gap: 4px;
`;

export const ShortcutStyled = styled(Shortcut)`
  > div ${Key} {
    border: 1px solid ${p => p.theme.colors.border.primary};
    color: ${p => p.theme.colors.text.disabled};
  }
`;
