import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

export const Container = styled.div`
  background: ${p => (p.theme.isDark ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)')};
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: grid;
  grid-template-columns: 180px auto;
  height: 100%;
  left: 70px;
  overflow: hidden;
  position: absolute;
  top: 45px;
  width: 100%;
  z-index: 1;
`;

export const Sidebard = styled.div`
  background: ${p => p.theme.colors.lightOnboarding.asideApp.sidebar.bg};
  padding: 20px;
  width: 180px;
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const SidebarNavs = styled.div`
  margin-top: 24px;
`;

export const SidebarNavitem = styled.div`
  --bg: ${p => p.theme.colors.lightOnboarding.asideApp.sidebarItem.bg};
  --color: ${p => p.theme.colors.lightOnboarding.asideApp.sidebarItem.color};
  align-items: center;
  color: ${p => p.theme.colors.lightOnboarding.asideApp.sidebarItem.color};
  display: flex;
  gap: 8px;
  padding-left: 1px;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const SidebarNavitemLabel = styled.div`
  background: var(--bg);
  border-radius: 5px;
  height: 6px;
  width: 50.78px;
`;
