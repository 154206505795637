import { Editor } from '@tiptap/react';

import { offscreenRect } from 'src/utils/editor/editor.utils';

export const getDomRect = (editor: Editor, direction: 'column' | 'row') => {
  const { $anchor } = editor.state.selection;
  const dom = editor.view.domAtPos($anchor.start());

  if (!(dom.node instanceof HTMLElement)) {
    return offscreenRect;
  }

  const closestCell = dom.node.closest('td') || dom.node.closest('th');
  const closestTable = dom.node.closest('table');

  if (!closestCell || !closestTable) {
    return offscreenRect;
  }

  const cellRect = closestCell.getBoundingClientRect();
  const tableRect = closestTable.getBoundingClientRect();

  const x = direction === 'column' ? cellRect.x : tableRect.x;
  const y = direction === 'column' ? tableRect.y : cellRect.y;
  const height = direction === 'column' ? tableRect.height : cellRect.height;
  const width = direction === 'column' ? cellRect.width : tableRect.width;

  return new DOMRect(x, y, width, height);
};
