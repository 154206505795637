import { ReactNode, useEffect, useRef } from 'react';
import { z } from 'zod';

import { VIDEO_PLAYBACK_RATES } from 'src/constants/video.constants';
import { useOptimizedBooleanState } from 'src/hooks';
import { useStateWithStorage } from 'src/hooks/useStateWithStorage';
import { useScript } from 'src/reactives';
import { LocalKey } from 'src/types/localStorage.types';
import { Script } from 'src/types/scripts.types';
import { getAppCloudNameFromUrl } from 'src/utils/cloudinary.utils';
import { logError } from 'src/utils/errors.utils';

import { useCloudinaryScript } from './VideoPlayer.hook';
import { Container } from './VideoPlayer.styles';

type VideoPlayerProps = {
  src: string;
  dataId?: string;
  errorFallback?: ReactNode;
  className?: string;
  fluid?: boolean;
};

export const VideoPlayer = ({
  src, dataId, className, errorFallback = null, fluid = true,
}: VideoPlayerProps) => {
  useCloudinaryScript();
  const { isLoaded } = useScript(Script.CLOUDINARY);
  const videoRef = useRef(null);
  const [isVideoLoaded, { setTrueCallback: setTrueIsVideoLoaded }] = useOptimizedBooleanState(false);
  const [hasError, {
    setTrueCallback: setTrueError,
    setFalseCallback: setFalseError,
  }] = useOptimizedBooleanState(false);

  /**
   * This effect loads cloudinary player
   */
  useEffect(() => {
    if (!videoRef.current || !isLoaded || isVideoLoaded) return;
    try {
      const cloudName = getAppCloudNameFromUrl(src);

      if (!cloudName) throw new Error('Cloudinary URL invalid');
      if (!window.cloudinary) {
        setTrueError();
        const err = new Error('Cloudinary not loaded');
        logError(err);
        throw err;
      }

      const player = window.cloudinary.videoPlayer(videoRef.current, {
        cloud_name: cloudName,
        secure: true,
      });
      player.source(src);
      setFalseError();
      setTrueIsVideoLoaded();
    } catch (error) {
      logError(error);
      setTrueError();
    }
  }, [dataId, isLoaded, isVideoLoaded, setFalseError, setTrueError, setTrueIsVideoLoaded, src]);

  const [playbackRate, setPlaybackRate] = useStateWithStorage(1, {
    key: LocalKey.VideoPlaybackRate,
    schema: z.number()
      .min(Math.min(...VIDEO_PLAYBACK_RATES))
      .max(Math.max(...VIDEO_PLAYBACK_RATES)),
  });

  const videoClasseNames = ['cld-video-player'];
  if (fluid) videoClasseNames.push('cld-fluid');

  return (
    <Container className={className}>
      {hasError
        ? errorFallback
        : (
          <video
            className={videoClasseNames.join(' ')}
            ref={videoRef}
            controls
            data-cld-playback-rates={`[${VIDEO_PLAYBACK_RATES.join(',')}]`}
            onCanPlay={e => {
              if (!(e.target instanceof HTMLVideoElement)) return;
              e.target.playbackRate = playbackRate;
            }}
            onRateChange={e => {
              if (!(e.target instanceof HTMLVideoElement)) return;
              if (e.target.playbackRate === playbackRate) return;
              setPlaybackRate(e.target.playbackRate);
            }}
          />
        )}
    </Container>
  );
};
