import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const ColorIcon = styled.span<{ $color: string }>`
  border: 1px solid ${p => p.theme.colors.border.hover};
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: 'A';
    ${typo.caption500}
    color: ${p => p.$color};
  }
`;
