import { useQuery } from '@apollo/client';
import { ProductYcDealDocument } from '@cycle-app/graphql-codegen';

import { extract } from '../../types/graphql.types';

export const useProductYcDeal = ({ productId }: { productId: string }) => {
  const {
    data, loading: isLoading,
  } = useQuery(ProductYcDealDocument, {
    variables: {
      productId,
    },
  });

  const product = extract('Product', data?.node);
  return {
    isYcDeal: product?.isYcDeal,
    isLoading,
  };
};
