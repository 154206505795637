import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { SocialContainer, SocialContainerSeparator } from './ChangelogBuilderContent.styles';

export const ChangelogBuilderContentSocial = () => {
  const { watch } = useChangelogBuilderFormContext();
  const text = watch('socialLinkText');
  const url = watch('socialLinkURL');
  const contentSection = watch('contentSection');
  const subscriptionToggled = watch('subscribeToggled');
  const subscriptionLabel = watch('subscribeLabel');

  if (!text && !url && !subscriptionToggled) return null;

  return (
    <SocialContainer $contentSection={contentSection}>
      {subscriptionToggled && (
        <div>{subscriptionLabel}</div>
      )}

      {subscriptionToggled && text && url && (<SocialContainerSeparator />)}

      {text && url && (
        <a
          target="_blank"
          rel="noreferrer"
          href={url}
        >
          {text}
        </a>
      )}
    </SocialContainer>
  );
};
