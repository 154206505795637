import { Skeleton } from '@cycle-app/ui';
import { FC, ReactNode } from 'react';

import { Action, Text, Container } from './SlackActionItem.styles';

interface Props {
  icon?: ReactNode;
  text?: string;
  action?: ReactNode;
  isLoading?: boolean;
}

export const SlackActionItem: FC<React.PropsWithChildren<Props>> = ({
  icon, text, action, isLoading,
}) => {
  return isLoading
    ? <Container><Skeleton height={8} /></Container>
    : (
      <Container>
        {icon}
        {text && <Text>{text}</Text>}
        {action && <Action>{action}</Action>}
      </Container>
    );
};
