import { getGithubIssuesExtension as getExtension } from '@cycle-app/editor-extensions';
import { PluginKey } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';

import { GithubIssueView } from 'src/components/Editor/NodeViews/GithubIssue/GithubIssueView';
import { COMMANDS } from 'src/constants/editor.constants';
import { renderIntegrationMentionSuggestion } from 'src/utils/integrations.utils';

import GithubIssuesDropdown from './GithubIssuesDropdown';

type GithubMentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

const extension = getExtension().extend<GithubMentionOptions>({
  addOptions(): GithubMentionOptions {
    return {
      ...this.parent?.(),
      suggestion: {
        allowSpaces: true,
        char: COMMANDS.GITHUB_ISSUE,
        render: () => renderIntegrationMentionSuggestion(this.name, GithubIssuesDropdown),
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(GithubIssueView);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${this.name}`),
      }),
    ];
  },
});

export const getGithubIssuesExtension = () => extension;
