import { Button, typo } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

export const Description = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const LearnMoreButton = styled(Button).attrs({
  size: 'L',
  variant: 'nospace',
})`
  color: ${p => (p.theme.isDark ? Color.White : Color.Cycle)};
  padding: 0 8px;
  transform: translateX(-4px);
  border-radius: 6px;

  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }
`;
