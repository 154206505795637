import { useQuery } from '@apollo/client';
import { BoardNodeDocument } from '@cycle-app/graphql-codegen';

import { useBoardId } from 'src/hooks/usePathParams';
import { extract } from 'src/types/graphql.types';
import { defaultPagination } from 'src/utils/pagination.util';

export const useBoard = (boardId?: string | null, skip = false) => {
  const boardIdParams = useBoardId();

  const boardIdToFetch = boardId || boardIdParams;

  const { data } = useQuery(BoardNodeDocument, {
    fetchPolicy: 'cache-first',
    skip: skip || !boardIdToFetch,
    variables: {
      id: boardIdToFetch as string,
      ...defaultPagination,
    },
  });

  return extract('Board', data?.node);
};
