import { FC, ReactNode } from 'react';

import { Text, Icon } from './HelpText.styles';

interface Props {
  className?: string;
  children: ReactNode;
}

export const HelpText: FC<React.PropsWithChildren<Props>> = ({
  className, children,
}) => {
  return (
    <Text className={className}>
      <Icon />
      {children}
    </Text>
  );
};
