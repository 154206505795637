import { CaretIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { ProductCurrent, ProductsPanel } from '../Product';
import { ItemButton } from './Settings.styles';

export const ProductSelect = ({
  button, onChange, className, ...props
}: Partial<ToggleDropdownProps> & {
  onChange?: (slug?: string) => void;
  className?: string;
}) => {
  return (
    <ToggleDropdown
      layer={Layer.DropdownModalZ2}
      withPortal={false}
      {...props}
      button={buttonProps => (typeof button === 'function' ? button(buttonProps) : (
        <ItemButton {...buttonProps}>
          <ProductCurrent />
          <CaretIcon
            size={8}
            direction={buttonProps['data-active'] ? 'top' : 'bottom'}
          />
        </ItemButton>
      ))}
      content={contentProps => (
        <ProductsPanel
          className={className}
          onDone={contentProps.hide}
          onChange={onChange}
        />
      )}
    />
  );
};
