import { ReactRenderer } from '@tiptap/react';
import { SuggestionProps } from '@tiptap/suggestion';
import tippy, { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { setLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';
import { clientRectWithFallback } from 'src/utils/editor/editor.utils';
import { getOpenedLayersAbove } from 'src/utils/layers.util';

interface Params {
  props: SuggestionProps;
  reactRenderer: ReactRenderer;
  layer?: Layer;
  options?: Partial<TippyProps>;
}

export const getTippyPopup = ({
  props,
  reactRenderer,
  layer = Layer.Dropdown,
  options = {},
}: Params) => tippy('body', {
  getReferenceClientRect: clientRectWithFallback(props.clientRect),
  appendTo: () => document.body,
  content: reactRenderer.element,
  showOnCreate: true,
  interactive: true,
  trigger: 'manual',
  placement: 'bottom-start',
  onMount: () => setLayer(layer, true),
  onHidden: () => setLayer(layer, false),
  zIndex: mappingZindex[layer],
  // @ts-ignore return false | void
  // eslint-disable-next-line consistent-return
  onHide() {
    if (getOpenedLayersAbove(layer)) {
      // Prevent close if a layer above is opened.
      // In instance the language dropdown in the transcript action.
      return false;
    }
  },
  ...options,
});

interface OnKeyDownParams {
  event: KeyboardEvent;
  popupInstance: TippyInstance<TippyProps>;
}

export const onKeyDown = ({
  event,
  popupInstance,
}: OnKeyDownParams) => {
  if (event.code === 'Escape') {
    popupInstance.hide();
    return true;
  }
  return ['ArrowUp', 'ArrowDown', 'Enter'].includes(event.code);
};
