import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { caption600, body400 } from '../../theme/typo';
import { ring2 } from '../../utils/styles.util';
import { ErrorVariant } from '../Inputs/Input/Input.styles';
import { Tag } from '../Tag/Tag';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const Property = styled.div`
  position: relative;

  svg {
    position: absolute;
    pointer-events: none;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
  }
`;

export const PropertyInput = styled.input`
  border: none;
  padding: 0 8px 0 24px;
  border-radius: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${body400};
  color: ${p => p.theme.colors.text.secondary};
  background-color: transparent;
  max-width: 128px;
  cursor: pointer;

  &:focus {
    cursor: text;
    ${ring2}
  }
`;

export const PropertyTag = styled(Tag)`
  ${body400}
  cursor: pointer;
  color: ${p => p.theme.colors.text.disabled};
`;

export const InputBase = styled.div``;

interface InputTextProps {
  inError?: boolean;
  withWarning?: boolean;
  typeUrl?: boolean;
}
export const InputText = styled(PropertyInput)<InputTextProps>`
  padding-left: 8px;
  ${p => p.inError && ErrorVariant};
  ${p => p.typeUrl && css`
    padding-right: ${p.withWarning ? 48 : 28}px;
  `};
  ${p => !p.typeUrl && p.withWarning && css`
    padding-right: 28px;
  `};
`;

export const InputSelect = styled.div``;

export const ErrorContainer = styled(motion.div)`
  position: absolute;
  top: -34px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ErrorMessage = styled.div`
  ${caption600};
  padding: 6px 8px;
  color: ${p => p.theme.colors.text.white};
  background-color: ${p => p.theme.colors.toaster.bg};
  border-radius: 4px;
  line-height: 1;
`;

export const End = styled.div`
  margin-left: auto;
`;

export const EndInput = styled.div`
  position: absolute;
  right: 8px;

  display: flex;
  align-items: center;
  gap: 4px;

  button, a {
    width: 20px;
    height: 20px;
  }
`;
