import { slugify } from '@cycle-app/utilities';

export const getBoardSlug = (board?: { name: string; id: string } | null) => {
  if (!board) return '';
  return `${slugify(board.name || '')}-${board.id}`;
};

export const getBoardId = (boardSlug?: string): string | undefined => {
  const splitSlug = boardSlug?.split('-');
  return splitSlug?.[splitSlug.length - 1];
};

export const getDocId = (docSlug?: string): string | undefined => {
  const splitSlug = docSlug?.split('-');
  return splitSlug?.[splitSlug.length - 1];
};
