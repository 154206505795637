import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SlidersIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <Svg
    {...props}
    ref={ref}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    data-no-fill
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M12.7501 10.5V3.00001M12.7501 10.5C13.9932 10.5 15.0001 11.5069 15.0001 12.75C15.0001 13.9931 13.9932 15 12.7501 15C11.5069 15 10.5001 13.9931 10.5001 12.75C10.5001 11.5069 11.5069 10.5 12.7501 10.5ZM5.25006 15V9.00001M5.25006 9.00001C4.00694 9.00001 3.00006 7.99313 3.00006 6.75001C3.00006 5.50688 4.00694 4.50001 5.25006 4.50001M5.25006 9.00001C6.49319 9.00001 7.50006 7.99313 7.50006 6.75001C7.50006 5.50688 6.49319 4.50001 5.25006 4.50001M5.25006 4.50001V3.00001"
      stroke="currentcolor"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
));
