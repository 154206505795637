import { SelectPanel, typo, TextButton } from '@cycle-app/ui';
import styled from 'styled-components';

import DocPreviewHint from 'src/components/DocPreviewHint/DocPreviewHint';

export const StyledSelectPanel = styled(SelectPanel)`
  width: 697px;
  max-width: 100%;
`;

export const StyledDocPreviewHint = styled(DocPreviewHint)`
  border-top: 1px solid ${p => p.theme.colors.border.primary};
  padding-top: 8px;
`;

export const Title = styled.span`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
`;

export const Header = styled.div`
  display: flex;
  gap: 4px;
`;

const HeaderBtn = styled(TextButton)`
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
  ${typo.body400};
`;

export const ShowAllBtn = styled(HeaderBtn)`
  margin-left: auto;
`;

export const HideAllBtn = styled(HeaderBtn)``;
