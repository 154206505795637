import {
  GithubAssigneeFragment,
  GithubRepositoryFragment,
  IntegrationFullFragment,
  IntegrationType,
  LinearAssigneeBaseFragment,
  LinearStatusBaseFragment,
  LinearTeamBaseFragment,
  SourceType,
} from '@cycle-app/graphql-codegen';
import { ReactRenderer } from '@tiptap/react';
import { SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion';
import { ComponentType } from 'react';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import { CHROME_STORE_CYCLE_APP_URL, ZAPIER_CYCLE_APP_URL } from 'src/constants/integrations.constants';
import {
  Integration,
  FrontEndIntegration,
  IntegrationMentionRendererProps,
} from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { clientRectWithFallback } from 'src/utils/editor/editor.utils';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

const GITHUB_LINK_PREFIX = 'https://github.com/';

export const parseGithubIssueLink = (link: string) => {
  if (!link.startsWith(GITHUB_LINK_PREFIX)) throw new Error('Not valid Github issue link');

  const [owner, repository, , publicId] = link.replace(GITHUB_LINK_PREFIX, '').split('/');

  return {
    owner,
    repository,
    publicId,
  };
};

export const integrationNameTitles: Partial<Record<Integration, string>> = {
  [IntegrationType.Meeting]: 'Call recording',
  [IntegrationType.Figma]: 'Figma',
  [IntegrationType.Github]: 'Github Issues',
  [IntegrationType.Hubspot]: 'Hubspot',
  [IntegrationType.Intercom]: 'Intercom',
  [IntegrationType.Linear]: 'Linear',
  [IntegrationType.Mail]: 'Mail',
  [IntegrationType.Notion]: 'Notion',
  [IntegrationType.Slack]: 'Slack',
  [IntegrationType.Zapier]: 'Zapier',
  [IntegrationType.Extension]: 'Extension',
  [IntegrationType.Gong]: 'Gong',
  [IntegrationType.Zendesk]: 'Zendesk',
  [IntegrationType.Salesforce]: 'Salesforce',
};

export const isGithubRepository = (
  repo: GithubRepositoryFragment | null | undefined,
): repo is GithubRepositoryFragment & { name: string } => !!repo?.name;

export const isGithubAssignee = (
  assignee: GithubAssigneeFragment | null | undefined,
): assignee is GithubAssigneeFragment => !!assignee;

export const isGithubLabel = (
  label: string | null | undefined,
): label is string => !!label;

export const isLinearTeam = (
  team?: LinearTeamBaseFragment | null,
): team is LinearTeamBaseFragment & { name: string } => !!team?.name;

export const isLinearStatus = (
  status?: LinearStatusBaseFragment | null,
): status is LinearStatusBaseFragment & { name: string } => !!status?.name;

export const isLinearAssignee = (
  assignee?: LinearAssigneeBaseFragment | null,
): assignee is LinearAssigneeBaseFragment & { name: string } => !!assignee?.name;

export const renderIntegrationMentionSuggestion = (extensionName: string, renderer: ComponentType<React.PropsWithChildren<IntegrationMentionRendererProps>>) => {
  let reactRenderer: ReactRenderer | undefined;
  let popup: TippyInstance<TippyProps> | undefined;

  return {
    onStart: (props: SuggestionProps) => {
      reactRenderer = new ReactRenderer(renderer, {
        props: {
          ...props,
          extensionName,
        },
        editor: props.editor,
      });

      popup = getTippyPopup({
        props,
        reactRenderer,
        layer: Layer.DropdownZ1,
        options: {
          hideOnClick: false,
        },
      })?.[0];
    },
    onUpdate(props: SuggestionProps) {
      reactRenderer?.updateProps(props);
      popup?.setProps({
        getReferenceClientRect: clientRectWithFallback(props.clientRect),
      });
    },
    onKeyDown({ event }: SuggestionKeyDownProps) {
      if (!popup) return true;
      return onKeyDown({
        event,
        popupInstance: popup,
      });
    },
    onExit() {
      popup?.destroy();
      reactRenderer?.destroy();
    },
  };
};

export type IntegrationsByType = Record<Integration, IntegrationFullFragment>;

export const sourceIntegrationMapping = {
  [SourceType.Hubspot]: IntegrationType.Hubspot,
  [SourceType.Intercom]: IntegrationType.Intercom,
  [SourceType.Mail]: IntegrationType.Mail,
  [SourceType.Slack]: IntegrationType.Slack,
  [SourceType.Web]: IntegrationType.Extension,
  [SourceType.Cycle]: FrontEndIntegration.CYCLE,
  [SourceType.Zapier]: IntegrationType.Zapier,
  [SourceType.Zoom]: IntegrationType.Meeting,
  [SourceType.GoogleMeet]: IntegrationType.Meeting,
  [SourceType.MicrosoftTeams]: IntegrationType.Meeting,
  [SourceType.Gong]: IntegrationType.Gong,
  [SourceType.Zendesk]: IntegrationType.Zendesk,
  [SourceType.Salesforce]: IntegrationType.Salesforce,
};

export const executeIntegration = (type: Integration, integration?: IntegrationFullFragment) => {
  if (type === 'EXTENSION') {
    window.open(CHROME_STORE_CYCLE_APP_URL, '_blank');
    return;
  }

  if (type === 'ZAPIER') {
    window.open(ZAPIER_CYCLE_APP_URL, '_blank');
    return;
  }

  if (type === 'MAIL' && integration?.provider?.__typename === 'Mail') {
    copyToClipboard({
      text: integration.provider.mail,
      notification: 'Email copied to clipboard',
    });
  }
};
