import { getToasters, setToasters, ToasterItem } from 'src/reactives/toasters.reactive';

export const TOASTER_DEFAULT_DURATION = 4000;

const timers: Record<string, number> = {};

export const stopToasterTimers = () => {
  setToasters({ areTimersEnabled: false });
  for (const timer of Object.values(timers)) {
    clearTimeout(timer);
  }
};

export type AddToasterParam = Omit<ToasterItem, 'id' | 'duration'> & {
  // When no duration is specified in add method we want to set a default duration of DEFAULT_DURATION
  // -> "infinite duration" is an edge case that must be explicitely specified
  duration?: number | 'infinite';
  closable?: boolean;
  id?: string;
};

export const closeToaster = (toasterId?: string) => {
  setToasters({
    queue: getToasters().queue.filter(toaster => toaster.id !== toasterId),
  });

  if (toasterId && timers[toasterId]) {
    clearTimeout(timers[toasterId]);
    delete timers[toasterId];
  }
};

export const restartToasterTimers = () => {
  setToasters({ areTimersEnabled: true });
  for (const item of getToasters().queue) {
    if (item.duration && item.id) {
      timers[item.id] = window.setTimeout(
        () => closeToaster(item.id),
        item.duration,
      );
    }
  }
};

export const addToaster = ({
  duration: requestedDuration,
  closable = true,
  id,
  ...toasterConfig
}: AddToasterParam) => {
  const {
    queue, areTimersEnabled,
  } = getToasters();

  // Don't add multiple toasters with the same id
  if (queue.some(item => item.id === id)) return () => { /* Intentional empty function */ };

  // When no duration param is given we want to avoid an infinite duration
  const toasterDuration: number | undefined = requestedDuration === 'infinite'
    ? undefined
    : (requestedDuration ?? TOASTER_DEFAULT_DURATION);

  const newToaster: ToasterItem = {
    onClose: closable ? closeToaster : undefined,
    ...toasterConfig,
    id: id ?? crypto.randomUUID(),
  };

  setToasters({
    queue: [...queue, {
      ...newToaster,
      duration: toasterDuration,
    }],
  });

  if (newToaster.id && toasterDuration && areTimersEnabled) {
    // Close toaster after `duration`ms
    timers[newToaster.id] = window.setTimeout(
      () => closeToaster(newToaster.id),
      toasterDuration,
    );
  }

  return () => closeToaster(newToaster.id);
};
