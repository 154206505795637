import { useBoardConfig } from 'src/contexts/boardConfigContext';

import BoardContentWithSwimlane from './BoardContentWithSwimlane';
import { BoardContentWithSwimlaneSkeleton } from './BoardContentWithSwimlaneSkeleton';

const BoardContentWithSwimlaneLoader = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  if (!boardConfig || boardConfig.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') {
    return <BoardContentWithSwimlaneSkeleton />;
  }
  return <BoardContentWithSwimlane />;
};

export default BoardContentWithSwimlaneLoader;
