import { ParentDefinition, AssigneeDefinition } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

// TODO: Remove this once we can get the AssigneeDefinition easily from the API
export const useDirtyAssigneeDefinition = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  return useMemo(
    () => {
      if (!boardConfig) return undefined;
      const assigneeAttribute = boardConfig.properties.edges.find(({ node }) => node.property.__typename === 'AssigneeDefinition')?.node;
      return assigneeAttribute?.property as AssigneeDefinition;
    },
    [boardConfig],
  );
};

export const useDirtyParentDefinition = (): ParentDefinition => ({
  __typename: 'ParentDefinition',
  id: 'parent',
});
