import { Input } from '@cycle-app/ui';
import { forwardRef, ChangeEvent } from 'react';

import { ACCEPTED_COVER_IMAGE_FORMAT, IMAGE_SIZE_LIMIT } from 'src/constants/upload.constant';
import { getFileSizeWithUnit } from 'src/utils/files.util';
import { addToaster } from 'src/utils/toasters.utils';

interface Props {
  disabled?: boolean;
  onCoverChanged: (file: File) => void;
}
const CoverImageInputFile = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    disabled,
    onCoverChanged,
  } = props;
  return (
    <Input
      ref={ref}
      id="cover"
      disabled={disabled}
      type="file"
      maxLength={1}
      onClick={e => e.stopPropagation()}
      accept={Object.keys(ACCEPTED_COVER_IMAGE_FORMAT).join(',')}
      onChange={onInputChange}
      inputHidden
    />
  );

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return null;

    if (selectedFile.size > IMAGE_SIZE_LIMIT) {
      addToaster({
        title: 'An error occured',
        message: `Only one image or GIF file can be added, maximum size ${getFileSizeWithUnit(IMAGE_SIZE_LIMIT, true)}`,
      });
      return null;
    }

    return onCoverChanged(selectedFile);
  }
});

export default CoverImageInputFile;
