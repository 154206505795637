import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const ImageContainer = styled.div`
  background-color: ${p => (p.theme.isDark ? 'hsla(0, 0%, 0%, 0.16)' : p.theme.baseColors.Blue100)};
  display: flex;
  justify-content: center;
  margin-top: -12px;
  margin-left: -12px;
  width: calc(100% + 56px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: -1;
`;

export const Image = styled.img`
  width: 207px;
  height: 106px;
  object-fit: contain;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 8px 8px;
`;

export const Title = styled.h4`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.text.primary};
`;

export const Description = styled.p`
  color: ${p => p.theme.colors.text.secondary};
  padding-left: 8px;
  padding-right: 8px;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const Cta = styled(Button)`
  padding-left: 8px;
  padding-right: 8px;
`;
