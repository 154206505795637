import orderBy from 'lodash/orderBy';
import range from 'lodash/range';

import { useProductPresence } from 'src/hooks';
import { useUsers } from 'src/hooks/api/useUsers';

import {
  MemberAvatarSkeleton,
  MembersContainerWrapper,
} from './HomeMembersSection.styles';
import { Member } from './Member';

export const HomeMembersSection = () => {
  const productPresence = useProductPresence();
  const membersViewing = productPresence.map(user => user.id);

  const {
    users,
    loading: isUsersLoading,
  } = useUsers();

  if (isUsersLoading) return <LoadingState />;

  if (!users.length) return null;

  const usersWithFlags = users
    .filter(user => user.__typename !== 'Me')
    .map(user => ({
      ...user,
      isPending: !user.jobTitle,
      isOnline: membersViewing.includes(user.id),
    }));

  const sortedUsers = orderBy(usersWithFlags,
    ['isPending', 'isOnline'],
    ['asc', 'desc']);

  return (
    <MembersContainerWrapper>
      {sortedUsers.map((user, i) => <Member style={{ zIndex: i + 1 }} key={user.id} user={user} />)}
    </MembersContainerWrapper>
  );
};

const LoadingState = () => (
  <MembersContainerWrapper>
    {range(1).map(i => (
      <MemberAvatarSkeleton style={{ zIndex: i + 1 }} key={i} />
    ))}
  </MembersContainerWrapper>
);
