import { UploadIcon } from '@cycle-app/ui/icons';
import { isFileSystemAccessAPIEnabled } from '@cycle-app/utilities';
import { forwardRef } from 'react';
import { DropzoneInputProps } from 'react-dropzone';

import { SecondaryButton, Label } from './Dropzone.styles';

type Props = DropzoneInputProps & {
  open: VoidFunction;
};

export const UploadButton = forwardRef<HTMLButtonElement, Props>(({
  open, ...props
}, ref) => {
  if (isFileSystemAccessAPIEnabled) {
    return (
      <SecondaryButton
        ref={ref}
        iconStart={<UploadIcon size={16} />}
        onClick={e => {
          e.stopPropagation();
          open();
        }}
      >
        Upload
      </SecondaryButton>
    );
  }

  return (
    <>
      <Label htmlFor="dropzone-input">
        <UploadIcon size={16} />
        Upload
      </Label>

      <input
        {...props}
        id="dropzone-input"
        type="file"
        style={{ display: 'none' }}
      />
    </>
  );
});
