import { PusherProviderProps } from '@harelpls/use-pusher';

import { getAuth } from 'src/reactives/auth.reactive';

export const pusherConfig = (): PusherProviderProps => ({
  auth: {
    headers: { Authorization: `Bearer ${getAuth().token}` },
  },
  authEndpoint: `${import.meta.env.VITE_HTTP_API_URI}/pusher/auth`,
  clientKey: import.meta.env.VITE_PUSHER_API_KEY,
  cluster: import.meta.env.VITE_PUSHER_CLUSTER,
});
