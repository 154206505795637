import { slugify } from '../slugify.utils';

type GetDocSlugParams = {
  title: string | undefined;
  id: string;
};

export const getDocSlug = ({
  title, id,
}: GetDocSlugParams) => `${title ? slugify(title) : ''}-${id}`;

export const getDocIdFromSlug = (docSlug: string) => {
  const parts = docSlug.split('-');

  /**
   * If there is not at least title and id in slug is not a valid doc slug
   */
  if (parts.length < 2) return null;

  return parts[parts.length - 1];
};
