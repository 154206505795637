import { Node } from '@tiptap/core';

import { NODE_TABLE_OF_CONTENT_NAME } from '../constants/editor.constants';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [NODE_TABLE_OF_CONTENT_NAME]: {
      addTableOfContentNode: () => ReturnType;
    };
  }
}

export const getEditorTableOfContentJSON = () => ({
  type: NODE_TABLE_OF_CONTENT_NAME,
});

export const getTableOfContentNode = () => Node.create({
  name: NODE_TABLE_OF_CONTENT_NAME,
  atom: true,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${NODE_TABLE_OF_CONTENT_NAME}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', {
      ...HTMLAttributes,
      'data-type': NODE_TABLE_OF_CONTENT_NAME,
    }];
  },

  addCommands: () => ({
    addTableOfContentNode: () => ({ commands }) => commands.insertContent(getEditorTableOfContentJSON()),
  }),
});
