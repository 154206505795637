import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Placeholder = styled.div`
  ${typo.body500}
  align-items: center;
  justify-content: center;
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : '#F7F7F7')};
  border-radius: 100px;
  color: ${p => (p.theme.isDark ? p.theme.colors.text.primary : 'hsl(var(--cycle))')};
  display: flex;
  flex: none;
  height: 32px;
  padding: 0 16px;
  width: 72px;
`;

export const Logo = styled.img`
  max-height: 50px;
  max-width: 90px;
`;
