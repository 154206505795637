import { useBoardSlug } from 'src/hooks/usePathParams';

import { useInboxSection } from './useInboxSection';

export const useCurrentInboxView = () => {
  const boardSlug = useBoardSlug();
  const { boards } = useInboxSection();
  if (!boardSlug) return undefined;
  return boards.find(board => boardSlug.includes(board.id));
};
