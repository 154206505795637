import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { useEditorContext } from 'src/contexts/editorContext';
import { INLINE_COMMENTS_PLUGIN_NAME, InlineCommentsPlugin } from 'src/editorExtensions/InlineCommentsPlugin';
import { useDocThreads } from 'src/hooks/api/queries/useDocThreads';
import { setThreadsPanel, useThreadsPanel } from 'src/reactives/comments.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { extract } from 'src/types/graphql.types';
import { Layer } from 'src/types/layers.types';

import { InlineChat } from './InlineChat';
import { StyledDropdownLayer, StyledPortalModal } from './InlineComments.styles';
import { useHandleUndoResolve } from './useHandleUndoResolve';

type State = {
  blockId: string | null;
  isCreate: boolean;
  overridePosition: DOMRect | undefined;
  isVisible: boolean;
  isResolved: boolean;
};

const DEFAULT_STATE: State = {
  blockId: null,
  isCreate: false,
  overridePosition: undefined,
  isVisible: false,
  isResolved: false,
};

export const InlineComments = () => {
  const isMobile = useIsMobile();
  const editor = useEditorContext(ctx => ctx.editor);
  const doc = useEditorContext(ctx => ctx.doc);
  const ref = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<State>(DEFAULT_STATE);
  const { section } = useThreadsPanel();

  const hide = () => {
    setThreadsPanel({ openBlockId: undefined });
    setState(DEFAULT_STATE);
  };

  const onDelete = useCallback((blockId: string | null) => {
    if (!blockId) return;
    editor.commands.unsetHighlightMark({ ids: [blockId] });
    hide();
  }, [editor.commands]);

  const onResolve = useCallback((blockId: string | null, isResolved: boolean) => {
    if (!blockId) return;
    editor.commands.resolveHighlightMark({
      id: blockId,
      type: 'comment',
      isResolved,
    });
    hide();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sets callbacks in a reactive for the threads panel
  useEffect(() => {
    setThreadsPanel({
      onDelete,
      onResolve,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Register the plugin and remove draft highlights
  useEffect(() => {
    const cleanup = () => editor.unregisterPlugin(INLINE_COMMENTS_PLUGIN_NAME);
    if (!ref.current || editor.isDestroyed) return cleanup;

    const plugin = InlineCommentsPlugin({
      editor,
      element: ref.current,
      onOpen: (tippyInstance, props) => {
        setThreadsPanel({
          openBlockId: props.id,
          hoverBlockId: undefined,
        });
        setState({
          blockId: props.id,
          isCreate: !!props.create,
          overridePosition: tippyInstance.props.getReferenceClientRect?.() ?? undefined,
          isVisible: true,
          isResolved: !!props.isResolved,
        });
      },
      onMouseover: (props) => {
        setThreadsPanel({ hoverBlockId: props.id });
      },
      onMouseout: () => {
        setThreadsPanel({ hoverBlockId: undefined });
      },
    });

    queueMicrotask(() => {
      editor.commands.cleanDraftHighlights?.();
      editor.registerPlugin(plugin);
    });

    return cleanup;
  }, [editor]);

  // Fetch the threads for the current doc and remove marks for the deleted ones
  useDocThreads(doc?.id, {
    fetchPolicy: 'network-only',
    section,
    skip: section === 'all',
    onCompleted: (data) => {
      const threads = nodeToArray(extract('Doc', data?.node)?.threads);
      // The blockId of a thread is present in the comment node
      const blockIds = threads
        .map(node => node?.comments?.edges[0]?.node.blockId)
        .filter(isPresent);
      if (!blockIds) return;
      editor.commands.cleanPublishedHighlights?.({
        publishedIds: blockIds,
        type: 'comment',
        section,
      });
    },
  });

  useHandleUndoResolve();

  const content = doc && state.blockId && (
    <InlineChat
      docId={doc.id}
      blockId={state.blockId}
      isCreate={state.isCreate}
      hide={hide}
      onSend={isReply => {
        if (!state.blockId || isReply) return;
        editor.commands.publishHighlightMark({
          id: state.blockId,
          type: 'comment',
          isResolved: false,
        });
        setState(prev => ({
          ...prev,
          isCreate: false,
        }));
      }}
      isResolved={state.isResolved}
    />
  );

  return (
    <div
      ref={ref}
      style={{
        pointerEvents: 'all',
        visibility: 'hidden',
      }}
    >
      {isMobile && state.blockId && state.isVisible && (
        <StyledPortalModal
          isFullOnMobile
          animate={false}
          layer={Layer.Modal}
          hide={() => {
            if (state.isCreate) editor.commands.undo();
            hide();
          }}
        >
          {content}
        </StyledPortalModal>
      )}

      {!isMobile && state.blockId && state.isVisible && (
        <StyledDropdownLayer
          placement="bottom"
          visible
          hide={() => {
            if (state.isCreate) editor.commands.undo();
            hide();
          }}
          overridePosition={state.overridePosition}
          offsetY={10}
          content={content}
        />
      )}
    </div>
  );
};
