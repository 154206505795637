import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import {
  DocTargetsSubscription,
  DocTargetsDocument,
  DocTargetsSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

export const useDocTargetsSubscription = (
  sourceDocId?: string | null,
  options?: Pick<SubscriptionHookOptions<DocTargetsSubscription>, 'onSubscriptionData'>,
) => {
  return useSubscription<DocTargetsSubscription, DocTargetsSubscriptionVariables>(
    DocTargetsDocument,
    {
      ...options,
      variables: {
        sourceDocId: sourceDocId as string,
      },
    },
  );
};
