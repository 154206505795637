import { useRect } from '@cycle-app/utilities';
import { memo } from 'react';

import { SectionParent } from 'src/utils/section.utils';

import { SectionDropdown } from './SectionDropdown';
import { Container } from './ViewBreadcrumb.styles';
import { getBreadcrumbSize } from './ViewBreadcrumb.utils';
import { ViewDropdown } from './ViewDropdown';
import { ViewsButton } from './ViewsButton';

export const ViewBreadcrumb = memo(({ sectionParent }: { sectionParent: SectionParent }) => {
  const [ref, size] = useRect(rect => getBreadcrumbSize(rect.width));
  return (
    <Container ref={ref}>
      {size != null && (
        <>
          {size > 0 && <SectionDropdown size={size} sectionParent={sectionParent} />}
          {size > 1 && <ViewsButton size={size} sectionParent={sectionParent} />}
          <ViewDropdown sectionParent={sectionParent} />
        </>
      )}
    </Container>
  );
});
