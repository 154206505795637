import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  gap: 4px;
`;

export const FirstAssignee = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
`;

export const SecondText = styled(FirstAssignee)`
  color: ${p => p.theme.colors.text.disabled};
`;
