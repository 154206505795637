import { MainPageId, PageId } from 'src/constants/routing.constant';
import { resetAuth } from 'src/reactives/auth.reactive';
import { resetCommandbar } from 'src/reactives/commandbar.reactive';
import { resetCreateDoc } from 'src/reactives/createDoc.reactive';
import { resetInitial } from 'src/reactives/initial.reactive';
import { resetLastInboxBoard, resetLastInsightBoard, resetLastRoadmapBoard, resetLastView } from 'src/reactives/lastView.reactive';
import { resetOnboarding as resetLightOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { resetSidebar } from 'src/reactives/sidebar.reactive';
import { resetToasters } from 'src/reactives/toasters.reactive';
import client from 'src/services/apollo/client';

import { useNavigate } from './useNavigate';

export const useLogout = (redirectPageId: MainPageId = PageId.Login) => {
  const { navigate } = useNavigate();

  return () => {
    // Remove token and preferences
    resetAuth();
    resetInitial();
    resetLastView();
    resetLastInboxBoard();
    resetLastInsightBoard();
    resetLastRoadmapBoard();
    resetSidebar();
    resetCommandbar();
    resetCreateDoc();
    resetLightOnboarding();
    resetToasters();

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.clearStore();

    navigate(redirectPageId);
  };
};
