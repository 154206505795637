import { ExportDocCustomersDocument, ExportQuotesFromCompanyDocument, ExportQuotesFromCustomerDocument } from '@cycle-app/graphql-codegen';

import DialogModal from 'src/components/DialogModal/DialogModal';
import useSafeMutation from 'src/hooks/useSafeMutation';
import {
  useCompanyExportQuotes, closeCompanyExportQuotes, closeCustomerExportQuotes, useCustomerExportQuotes, useCompanyStatusCategory, useCustomerStatusCategory,
} from 'src/reactives/customerCompanyProfile.reactive';
import { hideExportQuotesModal, useGetExportQuotesModal } from 'src/reactives/docPanel.reactive';
import { addToaster } from 'src/utils/toasters.utils';

export const ExportQuotesFromDoc = ({ docId }: { docId?: string }) => {
  const { isVisible } = useGetExportQuotesModal();
  if (!isVisible || !docId) return null;
  return <ExportQuotesFromDocVisible docId={docId} />;
};

const ExportQuotesFromDocVisible = ({ docId }: { docId: string }) => {
  const [exportDocCustomers, { loading }] = useSafeMutation(ExportDocCustomersDocument, { variables: { docId } });
  return (
    <ExportQuotesDialog
      hide={hideExportQuotesModal}
      onConfirm={exportDocCustomers}
      loading={loading}
    />
  );
};

export const ExportQuotesFromCompany = (props: { companyId: string }) => {
  const isOpen = useCompanyExportQuotes();
  if (!isOpen) return null;
  return <ExportQuotesFromCompanyVisible {...props} />;
};

const ExportQuotesFromCompanyVisible = ({ companyId }: { companyId: string }) => {
  const statusCategory = useCompanyStatusCategory();
  const [exportQuotesFromCompany, { loading }] = useSafeMutation(ExportQuotesFromCompanyDocument, {
    variables: {
      companyId,
      statusCategory,
    },
  });
  return (
    <ExportQuotesDialog
      hide={closeCompanyExportQuotes}
      onConfirm={exportQuotesFromCompany}
      loading={loading}
    />
  );
};

export const ExportQuotesFromCustomer = (props: { customerId: string }) => {
  const isOpen = useCustomerExportQuotes();
  if (!isOpen) return null;
  return <ExportQuotesFromCustomerVisible {...props} />;
};

const ExportQuotesFromCustomerVisible = ({ customerId }: { customerId: string }) => {
  const statusCategory = useCustomerStatusCategory();
  const [exportQuotesFromCustomer, { loading }] = useSafeMutation(ExportQuotesFromCustomerDocument, {
    variables: {
      customerId,
      statusCategory,
    },
  });
  return (
    <ExportQuotesDialog
      hide={closeCustomerExportQuotes}
      onConfirm={exportQuotesFromCustomer}
      loading={loading}
    />
  );
};

const ExportQuotesDialog = ({
  onConfirm,
  hide,
  loading,
}: {
  onConfirm: () => Promise<unknown>;
  hide: VoidFunction;
  loading: boolean;
}) => {
  return (
    <DialogModal
      hide={hide}
      type="default"
      title="Export quotes in CSV"
      onConfirm={async () => {
        await onConfirm();
        addToaster({
          title: "CSV export is in progress, you'll receive an email very soon",
          icon: '📬',
        });
      }}
      info={(
        <>
          Are you sure you want to export quotes into CSV?
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You'll receive an email from Cycle when it's ready.
        </>
      )}
      width="auto"
      loading={loading}
      variantSubmitButton="primary"
      variantCancelButton="secondary"
      confirmLabel="Export"
    />
  );
};
