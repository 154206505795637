import { typo, Skeleton, truncate } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  padding: 0 12px;
  gap: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }
`;

export const LoadingState = styled(Skeleton)`
  width: 100px;
  height: 16px;
`;

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const DocTitle = styled.span`
  flex: 1;
  ${truncate}
`;
