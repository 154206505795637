import { boxShadowZ1 } from '@cycle-app/ui';
import styled from 'styled-components';

import { SlackMessageEmoji } from 'src/components/OnboardingSlack/SlackMessage/SlackMessage.styles';

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const ImageBox = styled.div`
  ${boxShadowZ1}
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  height: 115px;
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    max-height: unset;
    max-width: unset;
    width: 180px;
  }
`;

export const StyledSlackMessageEmoji = styled(SlackMessageEmoji)`
  background-color: rgba(29, 155, 209, 0.1);
  box-shadow: rgb(29, 155, 209) 0px 0px 0px 1.5px inset;
  color: rgb(18, 100, 163);
  height: 24px;
  left: 33%;
  top: 61%;
  width: 40px;
`;
