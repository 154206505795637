import { BOX_SHADOW_COLOR_Z4, typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 340px;
  height: 52px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px 16px 16px 12px;
  background-color: ${p => p.theme.colors.bulkActions.bg};

  box-shadow:
    0 0 0 1px ${p => p.theme.colors.bulkActions.outline},
    0px 6px 15px ${BOX_SHADOW_COLOR_Z4};
`;

export const DeselectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding: 0;
  border-radius: 4px;

  background-color: hsl(var(--cycle));
  color: white;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
  }
  &:active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
  }
`;

export const Label = styled.div`
  flex: 1;
  ${typo.caption500}
`;

export const ActionButtons = styled.div`
  display:flex;
  gap: 16px;
`;
