import { typo, Button } from '@cycle-app/ui';
import { small } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Root = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin-top: 16px;
  padding: 0 24px;
  position: relative;
  flex: none;
`;

export const TemplateContainer = styled.div`
  position: absolute;
  width: 152px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;

  ${small} {
    position: relative;
    transform: translate(0, 0);
    left: 0;
  }
`;

export const Tabs = styled.div`
  height: 32px;
  border-radius: 8px;
  padding: 2px;
  background-color: ${p => (p.theme.isDark ? p.theme.colors.body.bg : p.theme.baseColors.Grey200)};
  display: flex;
  gap: 2px;
`;

interface TabProps {
  $isActive: boolean;
}

export const Tab = styled(Button)<TabProps>`
  flex-grow: 1;
  ${typo.body500}

  &:active {
    --color: ${p => p.theme.colors.text.primary};
  }

  ${p => (p.$isActive ? `
    --bg: ${p.theme.isDark ? 'rgba(49, 49, 49, 1)' : p.theme.colors.background.primary};
    --color: ${p.theme.colors.text.primary};
  ` : `
    &:hover {
      --bg: ${p.theme.isDark ? p.theme.baseColors.Grey900 : 'transparent'};
    }
  `)}

  ${p => p.theme.isDark && `
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
    `}
`;
