import { AiIcon } from '@cycle-app/ui/icons';

import { Top } from './CommandAction.styles';

type Props = {
  search: string;
};

export const AskAction = ({ search }: Props) => {
  return (
    <Top>
      <AiIcon hasGradient />
      <span className="truncate">
        {'Ask Cycle for '}
        <span className="select-text text-body font-medium">
          {search}
        </span>
      </span>
    </Top>
  );
};
