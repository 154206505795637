import { useProduct } from 'src/hooks';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';
import { useProductStatus } from 'src/reactives/product.reactive';
import { setUpgradeModal } from 'src/reactives/upgradeModal.reactive';

import {
  EmptyState,
  PreviewContainer,
  Title,
  Paragraph,
  EnableButton,
  InsightsProgressBar,
  InsightsCount,
  Link,
} from './AnalysesPage.styles';

export const AnalyseEmptyState = () => {
  const productAddOn = useProductAddOnRequest();
  const { productStatus } = useProductStatus();
  const { product } = useProduct();
  const insightsCount = product?.nbActiveInsights || 0;
  return (
    <EmptyState>
      <PreviewContainer>
        <div className="flex flex-col items-center gap-3">
          <Title>
            Customer voice dashboard
          </Title>
          <Paragraph>
            Visualize your customer quotes in a whole new way with a powerful and interactive dashboard.
          </Paragraph>
        </div>

        {(() => {
          if (productStatus === 'trial') {
            return (
              <div className="flex flex-col items-center gap-3">
                <EnableButton
                  onClick={() => setUpgradeModal({ isOpened: true })}
                >
                  Upgrade
                </EnableButton>
                <DemoLink />
              </div>
            );
          }

          if (insightsCount < 25) {
            return (
              <div className="flex flex-col items-center gap-3">
                <p>
                  You need at least 25 insights to enable your dashboard
                </p>
                <div className="flex flex-col items-center gap-3">
                  <InsightsProgressBar
                    $percentage={insightsCount * 4}
                    $colorType="default"
                  />
                  <InsightsCount>
                    {`${insightsCount}/25`}
                  </InsightsCount>
                </div>
              </div>
            );
          }

          return (
            <div className="flex flex-col items-center gap-3">
              <EnableButton
                onClick={() => productAddOn.request('ANALYSES')}
                isLoading={productAddOn.isRequesting}
              >
                Request access
              </EnableButton>
              <DemoLink />
            </div>
          );
        })()}
      </PreviewContainer>
    </EmptyState>
  );
};

const DemoLink = () => (
  <Link
    href="https://www.loom.com/share/729ecdc28bb14c1fb7a8c76c113ca666?sid=5ff7e0b8-9196-4d13-9680-daf02042a3c2"
    target="_blank"
  >
    View demo
  </Link>
);
