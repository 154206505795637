import { ShyScrollbarCss, InfiniteScroll } from '@cycle-app/ui';
import styled from 'styled-components';

export const List = styled(InfiniteScroll)`
  ${ShyScrollbarCss}
  scroll-snap-type: y mandatory;
`;

export const EmptyStateContent = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const EmptyStateContentLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
  transition: color 0.2s ease-in-out;

  & svg {
    margin-top: 2px;
  }

  &:hover {
    color: ${p => p.theme.colors.text.primary};

    & svg {
      transform: translateX(4px);
    }
  }
`;
