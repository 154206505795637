import { ResetPasswordDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { getAuth } from 'src/reactives/auth.reactive';

export const useResetPassword = () => {
  const [resetPasswordMutation, { loading }] = useSafeMutation(ResetPasswordDocument);

  const reset = useCallback(async (password: string, tokenFromParam?: string) => {
    const { token: tokenFromReactive } = getAuth();

    const token = tokenFromParam ?? tokenFromReactive;
    if (!token) return null;

    return resetPasswordMutation({
      variables: {
        password,
        token,
      },
    });
  }, [resetPasswordMutation]);

  return {
    reset,
    loading,
  };
};
