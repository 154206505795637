import { make } from 'src/utils/reactives.util';

interface NotificationsResult {
  isNotifCenterVisible: boolean;
}

export const {
  hookState: useNotifications,
  hookValue: useGetNotifications,
  getValue: getNotifications,
  setValue: setNotifications,
  resetValue: resetNotifications,
} = make<NotificationsResult>({
  defaultState: {
    isNotifCenterVisible: false,
  },
});

export const openNotifications = () => setNotifications({ isNotifCenterVisible: true });

export const closeNotifications = () => setNotifications({ isNotifCenterVisible: false });

export const toggleNotifications = () => setNotifications({ isNotifCenterVisible: !getNotifications().isNotifCenterVisible });

// Used to navigate to a comment from the notification center
export const {
  hookValue: useGetCommentNavigation,
  setValue: setCommentNavigation,
} = make<{
  // Used to open the corresponding thread list in the right panel and scroll to the corresponding thread item
  sidebarCommentId?: string;
  // Used to scroll to and open a thread in the editor
  threadId?: string;
  // Used to scroll to a comment item in the opened thread
  editorCommentId?: string;
}>({
  defaultState: {
    sidebarCommentId: undefined,
    threadId: undefined,
    editorCommentId: undefined,
  },
});
