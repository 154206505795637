import { Tooltip, TooltipProps } from '@cycle-app/ui';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { GroupNameTitle, TitleContent } from './BoardGroup.styles';

export type BoardGroupNameReadOnlyProps = {
  groupName: string;
  tooltipProps?: Partial<TooltipProps>;
};

export const BoardGroupNameReadOnly = ({
  groupName, tooltipProps,
}: BoardGroupNameReadOnlyProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const checkOverflow = () => {
    const textWidth = ref.current?.getBoundingClientRect().width ?? 0;
    const containerWidth = ref.current?.parentElement?.getBoundingClientRect().width ?? 0;
    setIsOverflow(textWidth > containerWidth);
  };

  const checkOverflowDebounced = useDebouncedCallback(checkOverflow, 500);

  useEffect(() => {
    checkOverflow();
  }, [groupName]);

  useEffect(() => {
    window.addEventListener('resize', checkOverflowDebounced);
    return () => {
      window.removeEventListener('resize', checkOverflowDebounced);
    };
  }, [checkOverflowDebounced]);

  return (
    <GroupNameTitle>
      <Tooltip
        content={groupName}
        placement="top"
        {...tooltipProps}
        disabled={!isOverflow || tooltipProps?.disabled}
      >
        <TitleContent>
          <span ref={ref}>{groupName}</span>
        </TitleContent>
      </Tooltip>
    </GroupNameTitle>
  );
};
