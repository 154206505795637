import { UpdateReleaseNotesDocument } from '@cycle-app/graphql-codegen';
import orderBy from 'lodash/orderBy';
import { isPresent } from 'ts-is-present';

import { getDocFromCache } from 'src/utils/cache.utils';

import useSafeMutation from '../useSafeMutation';
import { useReleaseNoteAsync } from './useReleaseNoteAsync';
import { useReleaseNotesCache } from './useReleaseNotesCache';

export const useUpdateReleaseNotes = () => {
  const [mutation] = useSafeMutation(UpdateReleaseNotesDocument);
  const { getReleaseNote } = useReleaseNoteAsync();
  const {
    prenpendReleaseNotes, removeReleaseNotes,
  } = useReleaseNotesCache();

  const updateReleaseNotes = async (variables: { docIds: string[]; releaseId: string }) => {
    const {
      releaseId, docIds,
    } = variables;
    const releaseNotes = (
      await Promise.all(docIds.map(id => {
        const doc = getDocFromCache(id);
        return doc?.releaseNote?.id
          ? getReleaseNote(doc.releaseNote.id)
          : Promise.resolve(null);
      }))
    ).filter(isPresent);

    // Orders we sent in the cache and the mutation are not the same.
    const orderedNotesInCache = orderBy(releaseNotes, ['position'], ['desc']);
    const orderedNotesForMutation = orderBy(releaseNotes, ['position'], ['asc']);
    const docIdsOrdered = orderedNotesForMutation.map(note => note.doc?.id).filter(isPresent);

    // We cannot add docIdsWithoutReleaseNote in the cache is the back creates notes for each.
    // When the user navigates in the release, the new notes will appear.
    const docIdsWithoutReleaseNote = docIds.filter(docId => !releaseNotes.some(note => note.doc?.id === docId));

    const currentReleaseIds = new Set(orderedNotesInCache.map(releaseNote => releaseNote.release.id));
    const result = await mutation({
      optimisticResponse: {
        updateReleaseNotes: 'temp-id',
      },
      variables: {
        docIds: [...docIdsWithoutReleaseNote, ...docIdsOrdered],
        releaseId,
      },
      update: () => {
        const mainReleaseNotes = orderedNotesInCache.filter(note => !note.isOther);
        const otherReleaseNotes = orderedNotesInCache.filter(note => note.isOther);
        if (currentReleaseIds.size) {
          currentReleaseIds.forEach(currentReleaseId => {
            removeReleaseNotes({
              releaseId: currentReleaseId,
              mainReleaseNotes,
              otherReleaseNotes,
            });
          });
        }
        prenpendReleaseNotes({
          releaseId,
          mainReleaseNotes,
          otherReleaseNotes,
        });
      },
    });

    return result;
  };

  return { updateReleaseNotes };
};
