import { typo, truncate } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  height: 24px;
  overflow: hidden;
`;

export const Img = styled.img`
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: block;
  border-radius: 4px;
  object-fit: cover;
  overflow: hidden;
`;

export const Name = styled.div`
  ${typo.body500};
  ${truncate}
`;
