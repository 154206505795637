export const focusEndContenteditable = (el: HTMLElement) => {
  const range = document.createRange();
  range.selectNodeContents(el);
  range.collapse(false);

  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
};

/**
 * Use scrollIntoViewIfNeeded if available
 * Fallback to scrollIntoView (Firefox)
 * https://developer.mozilla.org/fr/docs/Web/API/Element/scrollIntoViewIfNeeded
 */
export const scrollIntoView = (
  element: null | HTMLElement & {
    scrollIntoViewIfNeeded?: (alignToTop?: boolean) => void;
  },
  alignToTop: boolean,
) => {
  if (!element) return;
  if ('scrollIntoViewIfNeeded' in element) {
    element.scrollIntoViewIfNeeded?.(alignToTop);
  } else {
    element.scrollIntoView(alignToTop);
  }
};
