import {
  AddTemplateDocument,
  AddTemplateMutationVariables,
  ChangeDoctypeDoctypeTemplateDocument,
  ChangeDoctypeDoctypeTemplateMutationVariables,
  ChangeDoctypeTemplateTitleDocument,
  ChangeDoctypeTemplateContentDocument,
  DoctypeNodeDocument,
  DoctypeFragment,
  ChangeCategoryTemplateDocument,
  DoctypeTemplateCategory,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useAddTemplate = (doctype?: DoctypeFragment) => {
  const [add, { loading }] = useSafeMutation(AddTemplateDocument);

  return {
    add: async (variables: AddTemplateMutationVariables) => {
      await add({
        variables,
        update: (cache, { data }) => {
          if (!variables.doctypeId || !data?.addDoctypeTemplate?.id || !doctype) return;
          cache.writeQuery({
            query: DoctypeNodeDocument,
            variables: {
              id: variables.doctypeId,
            },
            data: {
              node: {
                ...doctype,
                template: {
                  id: data.addDoctypeTemplate.id,
                  ...variables,
                  contentJSON: variables.contentJSON ?? '',
                },
              },
            },
          });
        },
      });
    },
    loading,
  };
};

export const useChangeDoctypeTemplate = (doctype?: DoctypeFragment) => {
  const [changeTemplate, { loading }] = useSafeMutation(ChangeDoctypeDoctypeTemplateDocument);

  return {
    changeDoctypeTemplate: async (variables: ChangeDoctypeDoctypeTemplateMutationVariables) => {
      await changeTemplate({
        variables,
        update: (cache) => {
          if (!variables.doctypeId || !doctype) return;

          cache.writeQuery({
            query: DoctypeNodeDocument,
            variables: {
              id: variables.doctypeId,
            },
            data: {
              node: {
                ...doctype,
                template: null,
              },
            },
          });
        },
      });
    },
    loading,
  };
};

interface UpdateTemplateParams {
  doctypeTemplateId: string;
  title: string;
  content: string;
  contentJSON: string;
  category?: DoctypeTemplateCategory;
}

export const useUpdateTemplate = () => {
  const [updateTitle, { loading: loadingTitle }] = useSafeMutation(ChangeDoctypeTemplateTitleDocument);
  const [updateContent, { loading: loadingContent }] = useSafeMutation(ChangeDoctypeTemplateContentDocument);
  const [updateCategory, { loading: loadingCategory }] = useSafeMutation(ChangeCategoryTemplateDocument);

  return {
    loading: loadingTitle || loadingContent || loadingCategory,
    update: async ({
      doctypeTemplateId,
      title,
      content,
      contentJSON,
      category,
    }: UpdateTemplateParams) => {
      await updateTitle({
        variables: {
          doctypeTemplateId,
          title,
        },
      });
      await updateContent({
        variables: {
          doctypeTemplateId,
          content,
          contentJSON,
        },
      });
      if (category) {
        await updateCategory({
          variables: {
            doctypeTemplateId,
            category,
          },
        });
      }
    },
  };
};
