import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div<{ $noBackground?:boolean }>`
  background-color: ${p => (p.$noBackground ? 'transparent' : p.theme.colors.background.primary)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled(motion.div)`
  max-width: 100px;
  max-height: 100px;
  animation: ${fadeIn} 0.3s ease-in;
`;

export const Svg = styled.svg`
  animation: ${rotate} 2s infinite linear;
`;
