import { AnimatePresence } from 'framer-motion';

import { navigateToCompanyPage, navigateToCustomerPage } from 'src/hooks';
import { useCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { useCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { closeProfileModal } from 'src/hooks/modals/useProfileModal';
import { Layer } from 'src/types/layers.types';

import { CompanyProfile } from './CompanyProfile';
import { CustomerProfile } from './CustomerProfile';
import { StyledModal } from './Profile.styles';
import { motionProps, motionVariants } from './ProfileModal.motion';

export const ProfileModal = () => {
  const customerId = useCustomerModal();
  const companyId = useCompanyModal();
  const isOpen = !!customerId || !!companyId;
  return (
    <AnimatePresence>
      {isOpen && (
        <StyledModal
          placement="right"
          layer={Layer.ModalZ2}
          hide={closeProfileModal}
          motionVariants={motionVariants}
          motionProps={motionProps}
        >
          {!!customerId && (
            <CustomerProfile
              customerId={customerId}
              onClose={closeProfileModal}
              onExpand={() => navigateToCustomerPage(customerId)}
            />
          )}

          {!!companyId && (
            <CompanyProfile
              companyId={companyId}
              onClose={closeProfileModal}
              onExpand={() => navigateToCompanyPage(companyId)}
            />
          )}
        </StyledModal>
      )}
    </AnimatePresence>
  );
};
