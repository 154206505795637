import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Editor } from '@tiptap/react';
import { AnimatePresence } from 'framer-motion';
import { memo, isValidElement, PropsWithChildren, useCallback } from 'react';

import Portal from 'src/components/Portal/Portal';
import { mappingZindex } from 'src/constants/zIndex.constant';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';
import { getParsedJSON } from 'src/utils/json.util';

import { PreviewTemplateContainer, PreviewTemplateModal } from './Editor.styles';
import { ReadOnlyEditor } from './ReadOnlyEditor';

// Prevent small glitch effect when the user clicks on the "Add template" button.
const PreviewMemo = memo<PropsWithChildren<{ show: boolean }>>(({ children }) => (
  isValidElement(children) && children) || null, (prevProps, nexProps) => prevProps.show === nexProps.show);

type Props = {
  docType?: DoctypeFragment;
  editor: Editor | null;
  onUpdate?: (p: { html: string; json: string; text: string }) => void;
};

export const useEditorTemplate = ({
  docType, editor, onUpdate,
}: Props) => {
  const templateJson = docType?.template?.contentJSON ?? '';
  const templateHTML = docType?.template?.content ?? '';
  const parsedJson = getParsedJSON(templateJson);
  const skip = !editor || !templateJson;

  const [isPreviewTemplateModalOpened, {
    setTrueCallback: openPreviewTemplateModal, setFalseCallback: closePreviewTemplateModal,
  }] = useOptimizedBooleanState(false);

  const applyTemplate = useCallback(() => {
    closePreviewTemplateModal();
    if (parsedJson) {
      editor?.commands.setContent(parsedJson);
    } else {
      editor?.commands.clearContent();
    }
    onUpdate?.({
      html: templateHTML,
      json: templateJson,
      text: editor?.getText() ?? '',
    });
  }, [closePreviewTemplateModal, editor, onUpdate, parsedJson, templateHTML, templateJson]);

  const previewTemplateModal = skip ? null : (
    <PreviewMemo show={isPreviewTemplateModalOpened}>
      <AnimatePresence>
        {isPreviewTemplateModalOpened && editor.isEmpty && (
          <Portal portalId={PortalId.Modal}>
            <PreviewTemplateContainer>
              <PreviewTemplateModal zIndex={mappingZindex[Layer.Modal]} noMask>
                <ReadOnlyEditor content={parsedJson} />
              </PreviewTemplateModal>
            </PreviewTemplateContainer>
          </Portal>
        )}
      </AnimatePresence>
    </PreviewMemo>
  );

  return {
    applyTemplate,
    previewTemplateModal,
    openPreviewTemplateModal,
    closePreviewTemplateModal,
  };
};
