import { MeFragment, ProductBaseFragment, ProductUpdateIntegrationsDocument } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { PlaceHolderIcon, CycleStandardIcon } from '@cycle-app/ui/icons';
import { AnimatePresence, Variants } from 'framer-motion';
import random from 'lodash/random';
import { useEffect, useRef, useState } from 'react';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { useOptimizedBooleanState, useSafeMutation } from 'src/hooks';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import {
  Aside,
  ButtonOther,
  SourceIconBg,
  Main,
  Source,
  SourceBg,
  SourceBgBar,
  SourceLabel,
  SourceList,
  SourceListContainer,
  SourceName,
  StyledCheckboxInput,
  StyledSelectPanel,
} from './OnboardingSources.styles';
import { sources } from './sources';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Footer } from '../OnboardingLayout/OnboardingLayout.styles';

interface Props {
  me: MeFragment;
  product: Pick<ProductBaseFragment, 'name' | 'logo' | 'id'>;
}

const initialList = sources.slice(0, 9);
const othersList = sources.slice(9).sort((a, b) => a.label.localeCompare(b.label));

const container: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 0.1,
    },
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.1,
    },
  },
};

export const OnboardingSources = ({ product }: Props) => {
  const [selected, setSelected] = useState<Array<string>>([]);
  const [list, setList] = useState(initialList.map(l => ({
    ...l,
    x: random(5, 85),
    y: random(5, 85),
  })));
  const [dropdownRect, setDropdownRect] = useState<DOMRect | null>(null);

  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const [updateIntegrations, { loading }] = useSafeMutation(ProductUpdateIntegrationsDocument);
  const [isOthersOpen, {
    setTrueCallback: setOpenOthers, setFalseCallback: setCloseOthers,
  }] = useOptimizedBooleanState(false);

  // Scroll to bottom each time we update the length of the list.
  useEffect(() => {
    listContainerRef.current?.scrollTo(0, listContainerRef.current.scrollHeight);
  }, [list.length]);

  const toggleValue = (value: string) => () => {
    setSelected(values => (
      values.includes(value)
        ? values.filter(v => v !== value)
        : [...values, value]));
  };

  return (
    <OnboardingLayout
      title="Where do you currently capture feedback?"
      aside={(
        <Aside>
          <AnimatePresence>
            {selected.map(id => {
              const source = list.find(s => s.id === id);
              // Do not show custom sources the were added by the user.
              return source && sources.some(s => s.id === source.id) && (
                <Source
                  variants={container}
                  exit="hidden"
                  initial="hidden"
                  animate="show"
                  key={source.id}
                  style={{
                    position: 'absolute',
                    left: `${source.x}%`,
                    top: `${source.y}%`,
                  }}
                >
                  {source.icon}
                  <SourceIconBg>
                    {source.icon}
                  </SourceIconBg>
                  <SourceBg />
                  <SourceBgBar />
                </Source>
              );
            })}
          </AnimatePresence>
          <Source
            variants={container}
            initial="show"
          >
            <CycleStandardIcon variant="primary" />
            <SourceIconBg><CycleStandardIcon variant="primary" /></SourceIconBg>
            <SourceBg />
            <SourceBgBar />
          </Source>
        </Aside>
      )}
      main={(
        <Main
          onSubmit={async (e) => {
            e.preventDefault();
            const result = await updateIntegrations({
              variables: {
                productId: product.id,
                integrations: selected,
              },
            });
            if (result.data?.updateProduct?.id) {
              setOnboarding({ screen: LightOnboardingScreen.Linear });
            }
          }}
        >
          <SourceListContainer ref={listContainerRef}>
            <SourceList>
              {list.map(source => (
                <li key={source.id}>
                  <StyledCheckboxInput
                    $isSelected={selected.includes(source.id)}
                    checked={selected.includes(source.id)}
                    id={source.id}
                    onChange={toggleValue(source.id)}
                    label={(
                      <SourceLabel>
                        {source.icon}
                        <SourceName>{source.label}</SourceName>
                      </SourceLabel>
                    )}
                  />
                </li>
              ))}
              <li>
                <DropdownLayer
                  visible={isOthersOpen}
                  hide={() => {
                    setCloseOthers();
                  }}
                  {...dropdownRect && {
                    getReferenceClientRect: () => dropdownRect,
                  }}
                  placement="right"
                  popperOptions={{
                    modifiers: [{
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['top'],
                      },
                    }],
                  }}
                  content={(
                    <StyledSelectPanel
                      options={othersList.filter(source => !list.find(({ id }) => id === source.id)).map(source => ({
                        label: source.label,
                        icon: source.icon,
                        value: source.id,
                      }))}
                      onOptionChange={({ value }) => {
                        const source = sources.find(({ id }) => id === value);
                        if (source) {
                          setList(currentList => [...currentList, {
                            ...source,
                            x: random(5, 85),
                            y: random(5, 85),
                          }]);
                          toggleValue(value)();
                        }
                      }}
                      clearSearchOnCreate
                      onCreateOption={async (value) => {
                        const v = value.trim();
                        if (list.some(s => s.id === v)) return;
                        setList(currentList => [...currentList, {
                          id: v,
                          label: v,
                          icon: <PlaceHolderIcon />,
                          x: random(5, 85),
                          y: random(5, 85),
                        }]);
                        toggleValue(v)();
                      }}
                    />
                    )}
                >
                  <ButtonOther
                    forceFocus={isOthersOpen}
                    full
                    variant="secondary"
                    onClick={(event) => {
                      setDropdownRect(event.currentTarget.getBoundingClientRect());
                      setOpenOthers();
                    }}
                  >
                    Other...
                  </ButtonOther>
                </DropdownLayer>
              </li>
            </SourceList>
          </SourceListContainer>
          <Footer>
            <Button disabled={!selected.length} isLoading={loading} type="submit" size="M">Next</Button>
          </Footer>
        </Main>
      )}
    />
  );
};
