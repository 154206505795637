import { Button, Skeleton, typo, CustomerAvatar, SelectLine } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const FieldLabel = styled.div`
  ${typo.body500}
  padding-bottom: 8px;
`;

const fieldCss = css`
  padding: 8px 12px;
  border-radius: 6px;
  border-width: 2px;
  border-color: transparent;
`;

export const FieldButton = styled(Button).attrs({
  variant: 'outlined',
  size: 'L',
})<{
  $hasError?: boolean;
}>`
  ${fieldCss}
  width: 100%;
  height: 40px;
  justify-content: flex-start;
  color: ${p => p.theme.colors.text.primary};
  background-color: ${p => p.theme.colors.input.bgAlt};
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 4px;
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    cursor: wait;
  }
  ${p => p.$hasError && `
    border-width: 2px;
    border-color: ${p.theme.colors.border.red};
  `}
  > span {
    overflow: hidden;
  }
`;

export const SkeletonInput = styled(Skeleton)`
  height: 40px;
`;

export const StyledCustomerAvatar = styled(CustomerAvatar)`
  margin-right: 8px;
`;

export { StyledCustomerAvatar as CustomerAvatar };

export const CreateCustomerLine = styled(SelectLine)`
  color: ${p => p.theme.colors.text.secondary};
  margin-left: -8px;
  margin-right: -8px;

  svg {
    height: 8px;
    width: 8px;
  }
`;

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
  flex: none;
`;
