import { typo, Skeleton, boxShadowZ1, truncate, StatusIcon } from '@cycle-app/ui';
import { NextArrowIcon } from '@cycle-app/ui/icons';
import { NodeViewWrapper } from '@tiptap/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DocParentDropdown } from 'src/components/DocParentDropdown';
import { EditParentBtn } from 'src/components/DocParentDropdown/DocPanelParent.styles';

export const DocMentionNodeView = styled(NodeViewWrapper)`
  margin: -4px 0;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  top: 3.5px;
`;

export const LinkItem = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 24px;
  border-radius: 4px;
  padding: 0 6px 0 4px;
  max-width: 345px;

  > svg {
    color: ${p => p.theme.colors.text.disabled};
  }
  
  > * {
    flex: none;
  }

  :after {
    position: absolute;
    content: ' ';
    width: calc(100% - 4px);
    height: 1px;
    bottom: 0;
    left: 2px;
    background: ${p => p.theme.colors.docMention.bg};
  }

  :hover {
    background: ${p => p.theme.colors.docMention.bg};
    :after {
      display: none;
    }
  }
`;

export const LinkIcon = styled(NextArrowIcon)`
  transform: rotate(-45deg);
`;

export const TitleContainer = styled.div`
  flex: 1;
  ${truncate};
  ${typo.body500};
`;

export const ParentElementContainer = styled.div`
  display: flex;
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    top: 100%;
    left: 0;
  }
  `;

export const StyledDocParentDropdown = styled(DocParentDropdown)`
  ${boxShadowZ1}
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};

  ${EditParentBtn} {
    position: absolute;
    top: -16px;
    right: -10px;
    padding: 2px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const LoadingState = styled(Skeleton)`
  width: 100px;
  height: 16px;
`;

export const StyledStatusIcon = styled(StatusIcon)`
  width: 18px;
  height: 18px;
`;
