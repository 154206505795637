import { UnionIcon, DuplicateIcon, TrashIcon } from '@cycle-app/ui/icons';
import { TweetEmbed } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useEffect } from 'react';

import { EditorDragMenu } from 'src/components/EditorDragMenu';
import { useEditorContext } from 'src/contexts/editorContext';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import { Container, Content, Iframe } from './IframelyView.styles';
import { addToaster } from '../../../../utils/toasters.utils';

interface Props extends NodeViewRendererProps {
  selected: boolean;
}

const IframelyView: FC<React.PropsWithChildren<Props>> = ({
  node,
  selected,
  getPos,
}) => {
  const editor = useEditorContext(ctx => ctx.editor);

  const html = node.attrs.savedHtml as string;
  const url = node.attrs.url as string;

  useEffect(() => {
    // Fix iframely.load with dirty setTimeout
    if (node.attrs.url) {
      setTimeout(() => {
        iframely?.load();
      });
    }
  }, [node.attrs.url]);

  if (!node.attrs.url) {
    return null;
  }

  return (
    <Container $hasMinHeight={!!html}>
      <Content $isSelected={selected}>
        {editor.isEditable && (
          <EditorDragMenu
            tooltip={{
              content: 'Click to open menu',
              title: 'Move to reorder',
            }}
            options={[
              {
                label: 'Open',
                value: 'open',
                icon: <UnionIcon />,
                onSelect: () => window.open(node.attrs.url, '_blank'),
              },
              {
                label: 'Copy',
                value: 'copy',
                icon: <DuplicateIcon />,
                onSelect: async () => {
                  await navigator.clipboard.writeText(node.attrs.url);
                  addToaster({ message: 'Link copied to clipboard' });
                },
              },
              {
                label: 'Delete',
                onSelect: () => deleteNodeRange({
                  editor,
                  node,
                  getPos,
                }),
                value: 'delete',
                icon: <TrashIcon />,
                variant: 'danger',
              },
            ]}
          />
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        {url.includes('twitter.com')
          ? <TweetEmbed id={/status\/(\d+)/.exec(url)?.[1] ?? ''} />
          : html
            // eslint-disable-next-line react/no-danger
            ? <div dangerouslySetInnerHTML={{ __html: html }} />
            : <Iframe src={url} />}
      </Content>
    </Container>
  );
};

export default IframelyView;
