import { getErrorToasters, setErrorToasters } from 'src/reactives/toasters.reactive';
import { ErrorToasterData } from 'src/types/toaster.types';

const ERROR_TOASTER_DEFAULT_DURATION = 4000;

const timers: Record<string, number> = {};

type AddErrorToasterParam = Omit<ErrorToasterData, 'id' | 'duration'> & {
  // When no duration is specified in add method we want to set a default duration of DEFAULT_DURATION
  // -> "infinite duration" is an edge case that must be explicitely specified
  duration?: number | 'infinite';
};

export const closeErrorToaster = (toasterId: string) => {
  const { queue: currentQueue } = getErrorToasters();

  setErrorToasters({
    queue: currentQueue.filter(toaster => toaster.id !== toasterId),
  });

  if (timers[toasterId]) {
    clearTimeout(timers[toasterId]);
  }
};

export const addErrorToaster = ({
  duration: requestedDuration,
  closable = true,
  ...toasterConfig
}: AddErrorToasterParam) => {
  const { queue: currentQueue } = getErrorToasters();

  // When no duration param is given we want to avoid an infinite duration
  const toasterDuration: number | undefined = requestedDuration === 'infinite'
    ? undefined
    : (requestedDuration ?? ERROR_TOASTER_DEFAULT_DURATION);

  const newToaster: ErrorToasterData = {
    duration: toasterDuration,
    closable,
    ...toasterConfig,
    id: crypto.randomUUID(),
  };

  setErrorToasters({
    queue: [...currentQueue, newToaster],
  });

  if (newToaster.duration) {
    // Close toaster after `duration`ms
    timers[newToaster.id] = window.setTimeout(
      () => closeErrorToaster(newToaster.id),
      newToaster.duration,
    );
  }
};
