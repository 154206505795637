import { AudioViewAttributes } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { useCallback } from 'react';

import { AudioPlayer } from 'src/components/AudioPlayer';
import { EditorNodeEmptyState } from 'src/components/EditorNodeEmptyState';
import { EditorTranscriptStatusState } from 'src/components/EditorTranscriptStatusState';
import { useEditorContext } from 'src/contexts/editorContext';
import { useTranscript } from 'src/hooks/editor/useTranscript';
import { setTranscriptTooltip, useTranscriptTooltip } from 'src/reactives/transcript.reactive';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import { TranscriptTooltip } from '../Editor/TranscriptTooltip';
import { EditorMediaDragMenu } from '../EditorMediaDragMenu';
import { Container } from './EditorAudioView.styles';

interface EditorAudioViewProps extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: AudioViewAttributes) => void;
}
export const EditorAudioView = ({
  node, getPos, selected, updateAttributes,
}: EditorAudioViewProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const isInert = useEditorContext(ctx => ctx.isInert);
  const {
    src, dataId,
  } = node.attrs as AudioViewAttributes;

  const {
    getTranscript, isLoading: isTranscriptLoading, insertTranscript,
  } = useTranscript({
    node,
    getPos,
    dataId,
  });

  const onTranscript = useCallback(() => getTranscript({
    src,
    type: 'audio',
    insertContent: true,
  }), [getTranscript, src]);

  const onDelete = () => deleteNodeRange({
    editor,
    node,
    getPos,
  });

  const dataToTranscript = useTranscriptTooltip(dataId);

  return (
    <TranscriptTooltip
      dataId={dataId}
      transcript={() => {
        if (!dataToTranscript) return;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getTranscript(dataToTranscript).then(insertTranscript);
      }}
    >
      <Container
        $isSelected={!isInert && selected && !isTranscriptLoading}
        $hasFullWidth={!src}
        $isEmpty={!src}
      >
        {editor.isEditable && (
          <EditorMediaDragMenu
            onTranscript={onTranscript}
            isTranscriptLoading={isTranscriptLoading}
            onDelete={onDelete}
            src={src}
            type="audio"
            title={undefined}
          />
        )}
        {src
          ? <AudioPlayer src={src} />
          : (
            <EditorNodeEmptyState
              type="audio"
              dataId={dataId}
              onCancel={onDelete}
              onUploaded={(fileData) => {
                updateAttributes({
                  src: fileData.src,
                  dataId,
                });
                setTranscriptTooltip(dataId, fileData);
              }}
            />
          )}
        {src && isTranscriptLoading && <EditorTranscriptStatusState />}
      </Container>
    </TranscriptTooltip>
  );
};
