import { TippyProps } from '@tippyjs/react';

import { useEditorContext } from 'src/contexts/editorContext';
import { offscreenRect } from 'src/utils/editor/editor.utils';

/**
 * Hook to get the DOMRect of a link in a tiptap editor
 */
export const useGetLinkRect = (url: string, pos: number): TippyProps['getReferenceClientRect'] => {
  const editor = useEditorContext(ctx => ctx.editor);
  return () => {
    const paragraphElement = editor.view.nodeDOM(pos);
    if (!(paragraphElement instanceof HTMLElement)) return offscreenRect;
    const linkElement = paragraphElement.querySelector(`a[href*="${url}"]`);
    if (!linkElement) return offscreenRect;
    return linkElement.getBoundingClientRect();
  };
};
