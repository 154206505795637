type Connection = {
  downlink?: number;
  effectiveType?: string;
  rtt?: number;
  saveData?: boolean;
  type?: string;
};

declare global {
  interface Navigator {
    connection?: Connection;
    mozConnection?: Connection;
    webkitConnection?: Connection;
  }
}

export const getNetworkState = () => {
  const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection;
  return {
    online: navigator.onLine,
    ...connection && {
      downlink: connection?.downlink,
      effectiveType: connection?.effectiveType,
      rtt: connection?.rtt,
      saveData: connection?.saveData,
      type: connection?.type,
    },
  };
};
