import styled from 'styled-components';

import {} from '@cycle-app/utilities';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

import { EmptyStateInitialTimeout } from '../components/EmptyStateInitialTimeout';

export const StyledEmptyStateInitialTimeout = styled(EmptyStateInitialTimeout)`
  position: fixed;
  left: 50%;
  top: calc(50vh);
  transform: translate(-50%, 100px);
  z-index: ${mappingZindex[Layer.LoaderFullpage]};
`;
