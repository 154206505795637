import { wrappingInputRule, textInputRule } from '@tiptap/core';
import { Color } from '@tiptap/extension-color';
import { Heading } from '@tiptap/extension-heading';
import Link from '@tiptap/extension-link';
import { Paragraph } from '@tiptap/extension-paragraph';
import TaskItemTipTap from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextStyle from '@tiptap/extension-text-style';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';

import { PARAGRAPH_CLASS, HEADING_CLASS, PARAGRAPH_TYPE, HEADING_TYPE } from '../constants';
import { DraggingParams } from '../types/formatting.types';
import { getNodeView } from '../utils/draggingNode.utils';

const TaskItemExtensionInputRegex = /^\s*(\[([ |x])\])\s$/;
const TaskItemExtensionInputRegexEmpty = /^\s*(\[\])\s$/;

const TaskItemExtension = TaskItemTipTap.extend({
  addInputRules() {
    return [
      wrappingInputRule({
        find: TaskItemExtensionInputRegex,
        type: this.type,
        getAttributes: match => ({
          checked: match[match.length - 1] === 'x',
        }),
      }),
      wrappingInputRule({
        find: TaskItemExtensionInputRegexEmpty,
        type: this.type,
        getAttributes: () => ({
          checked: false,
        }),
      }),
    ];
  },
}).configure({
  nested: true,
});

const TypographyExtension = Typography.extend({
  addOptions: () => ({
    oneHalf: false,
    oneQuarter: false,
    threeQuarters: false,
  }),
  addInputRules() {
    return [
      ...(this.parent?.() ?? []),
      textInputRule({
        find: /\D(1\/2)\D$/,
        replace: '½',
      }),
      textInputRule({
        find: /^(1\/2)\D$/,
        replace: '½',
      }),
      textInputRule({
        find: /\D(1\/4)\D$/,
        replace: '¼',
      }),
      textInputRule({
        find: /^(1\/4)\D$/,
        replace: '¼',
      }),
      textInputRule({
        find: /\D(3\/4)\D$/,
        replace: '¾',
      }),
      textInputRule({
        find: /^(3\/4)\D$/,
        replace: '¾',
      }),
    ];
  },
});

const getCustomParagraph = (params: DraggingParams) => Paragraph.extend({
  draggable: true,
  addOptions() {
    return {
      HTMLAttributes: {
        class: 'editor-paragraph',
      },
    };
  },
  addNodeView() {
    return () => getNodeView({
      className: `${PARAGRAPH_CLASS}`,
      el: 'p',
      options: params,
    });
  },
});

const getCustomHeading = (params: DraggingParams) => Heading.extend({
  draggable: true,
  addNodeView() {
    return ({ node }) => getNodeView({
      className: HEADING_CLASS,
      el: `h${node.attrs.level}`,
      options: params,
    });
  },
});

type Options = {
  starterKit: Partial<StarterKitOptions>;
  onDragStart?: DraggingParams['onDragStart'];
  onDragEnd?: DraggingParams['onDragEnd'];
  isDnDEnabled: boolean;
};

export const getFormattingExtensions = (options?: Options) => {
  const formattingCustom = options?.isDnDEnabled ? [getCustomParagraph({
    onDragStart: options?.onDragStart,
    onDragEnd: options?.onDragEnd,
    nodeType: PARAGRAPH_TYPE,
  }),
  getCustomHeading({
    onDragStart: options?.onDragStart,
    onDragEnd: options?.onDragEnd,
    nodeType: HEADING_TYPE,
  })] : [];

  return [
    StarterKit.configure({
      ...options?.starterKit,
      paragraph: options?.isDnDEnabled
        ? false
        : {
          HTMLAttributes: {
            class: PARAGRAPH_CLASS,
          },
        },
      heading: !options?.isDnDEnabled && undefined,
    }),
    Link,
    TypographyExtension,
    Underline,
    ...typeof options?.starterKit.bulletList === 'undefined' ? [
    // in instance, in comments we do no want lists
      TaskList,
      TaskItemExtension,
    ] : [],
    Color,
    TextStyle,
    ...formattingCustom,
  ];
};
