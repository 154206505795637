import { Spinner } from '@cycle-app/ui';
import { useForm } from 'react-hook-form';

import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { addToaster } from 'src/utils/toasters.utils';

import { ProfileInput } from './Profile.styles';

type FormData = { name: string };

type Props = {
  customerId: string;
  name: string | null | undefined;
  email: string | null | undefined;
  hide: VoidFunction;
};

export const CustomerNameForm = ({
  customerId, name, email, hide,
}: Props) => {
  const { update } = useCustomerUpdate();

  const {
    handleSubmit, reset, register, formState: {
      errors, isSubmitting,
    },
  } = useForm<FormData>({
    defaultValues: {
      name: name ?? '',
    },
  });

  return (
    <form onSubmit={handleSubmit(async formData => {
      if (isSubmitting) return;

      if (!formData.name && !email) {
        addToaster({
          title: 'An error occured',
          message: 'Customers must have at least a name or an email',
        });
        return;
      }

      await update({
        customerId,
        name: formData.name,
      });

      hide();
    })}
    >
      <ProfileInput
        data-1p-ignore
        autoFocus
        iconAfter={isSubmitting && <Spinner />}
        error={errors.name?.message}
        {...register('name', {
          setValueAs: value => value.trim(),
          onBlur: () => {
            hide();
            reset();
          },
        })}
        onKeyUp={e => {
          if (e.key === 'Escape') {
            e.stopPropagation();
            hide();
            reset();
          }
        }}
      />
    </form>
  );
};
