import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetSidebarResising,
  setValue: setSidebarResising,
  resetValue: resetSidebarResising,
} = make<{
  resizing: boolean;
}>({
  defaultState: {
    resizing: false,
  },
});

export const {
  hookValue: useGetSidebarWidth,
  hookWithSelector: useSidebarWidthSelector,
  getValue: getSidebarWidth,
  setValue: setSidebarWidth,
  resetValue: resetSidebarWidth,
} = make<{
  width: number;
}>({
  defaultState: {
    width: 240,
  },
  localKey: LocalKey.SidebarWidth,
});

export const {
  hookValue: useGetSidebarCollapsed,
  getValue: getSidebarCollapsed,
  setValue: setSidebarCollapsed,
  resetValue: resetSidebarCollapsed,
} = make<{
  collapsed: boolean;
  prevCollapsed: boolean;
}>({
  defaultState: {
    collapsed: false,
    prevCollapsed: false,
  },
  localKey: LocalKey.SidebarCollapsed,
});

export const toggleSidebar = () => {
  const { collapsed } = getSidebarCollapsed();
  setSidebarCollapsed({
    collapsed: !collapsed,
    prevCollapsed: !collapsed,
  });
};

export const resetSidebar = () => {
  resetSidebarResising();
  resetSidebarWidth();
  resetSidebarCollapsed();
};
