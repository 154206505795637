import { typo, ShyScrollbar, CycleLogo } from '@cycle-app/ui';
import { mobile, tablet } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  background: ${p => p.theme.colors.login.wrapper};
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const Root = styled.div`
  ${typo.body400}
  background: ${p => p.theme.colors.login.bg};
  border-radius: 24px;
  box-shadow: ${p => p.theme.colors.login.shadow};
  display: flex;
  height: 729px;
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 48px);
  position: relative;
  padding: 0 32px;
  width: 1024px;

  ${tablet} {
    gap: 32px;
  }

  ${mobile} {
    border-radius: 0px;
    height: 100vh;
    margin-left: 0;
    margin-right: 0;
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
  }
`;

export const Main = styled(ShyScrollbar)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  height: 100%;
  justify-content: center;
  gap: 32px;
  flex-basis: 408px;
`;

export const StyledLogo = styled(CycleLogo)`
  --color1: ${p => (p.theme.colors.text.primary)};
  --color2: ${p => (p.theme.colors.text.primary)};
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;

  ${tablet} {
    margin-top: 24px;
  }
`;
