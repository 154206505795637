import { tiny600 } from '@cycle-app/ui/theme/typo';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: none;
  display: inline-flex;
  gap: 4px;

  > :not(div[data-tippy-root]) {
    display: flex;
    align-items: center;
  }
`;

const baseItemStyle = css`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 7px;
`;

export const Image = styled.img`
  ${baseItemStyle}
`;

export const MoreNumber = styled.span`
  ${baseItemStyle}
  ${tiny600}
  background-color: ${p => p.theme.colors.avatar.bgLight};
  line-height: 20px;
  text-align: center;
`;

export const AvatarsContainer = styled.div`
  display: flex;
  gap: 4px;
`;
