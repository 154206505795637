import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const TextIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M1 1.58337H15V3.91671H1V1.58337Z" />
    <path d="M1 5.08337H10.3333V7.41671H1V5.08337Z" />
    <path d="M15 8.58337H1V10.9167H15V8.58337Z" />
    <path d="M1 12.0834H12.6667V14.4167H1V12.0834Z" />
  </Svg>
);
