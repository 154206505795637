import { Script } from 'src/types/scripts.types';
import { make } from 'src/utils/reactives.util';

type State = {
  loaded: Script[];
};

const {
  hookValue,
  getValue,
  setValue,
} = make<State>({
  defaultState: {
    loaded: [],
  },
});

export const getIsScriptLoaded = (script: Script) => getValue().loaded.includes(script);

export const setScriptLoaded = (script: Script) => {
  if (getIsScriptLoaded(script)) return;

  setValue({ loaded: [...getValue().loaded, script] });
};

export const useScript = (script: Script) => {
  const { loaded } = hookValue();
  return {
    isLoaded: loaded.includes(script),
  };
};
