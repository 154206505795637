import { FileRejection } from 'react-dropzone';

import { make } from 'src/utils/reactives.util';

type FileRejectionWithId = {
  rejection: FileRejection;
  id: string;
};

export const {
  hookValue: useGetDocImport,
  getValue: getDocImport,
  setValue: setDocImport,
  resetValue: resetDocImport,
} = make<{
  /**
   * Number of files that have been accepted by the dropzone
   */
  acceptedTotal: number;
  /*
   * Type/Size errors for files rejected in the frontend
   */
  fileRejections: FileRejectionWithId[];
  /**
   * Ids of the files that are currently being uploaded
   * Used to display a loading spinner in the dropzone file list
   * These ids are not the same as the doc ids, they are just unique keys
   */
  loadingFiles: string[];
  /**
   * Id of the draft that is currently open in the create doc modal
   */
  draftIdOpen: string | null;
  /**
   * Callback to call when the user clicks on the publish button
   * Used to display a loading spinner in the create buttons of the dropzone
   */
  onPublish: VoidFunction | null;
}>({
  defaultState: {
    acceptedTotal: 0,
    fileRejections: [],
    loadingFiles: [],
    draftIdOpen: null,
    onPublish: null,
  },
});

export const discardError = (id: string) => {
  setDocImport({
    fileRejections: getDocImport().fileRejections.filter(item => item.id !== id),
  });
};
