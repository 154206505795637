import { GetImageNodeParams, IMAGE_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { ImageSize } from '@cycle-app/utilities';
import { Fragment, Node } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';

import {
  HEADING_TYPE,
  PARAGRAPH_TYPE,
  BULLET_LIST_TYPE,
  CHECK_LIST_TYPE,
  ORDERED_LIST_TYPE,
} from 'src/constants/editor.constants';

type SelectFile = {
  type: string;
} & GetImageNodeParams;

export interface SelectionData {
  texts: string[];
  files: SelectFile[];
}

export const getInsightContentFromSelection: (state: EditorState) => SelectionData = (state) => {
  const selectedSlice = state.selection.content();
  /**
   * If no children -> No selection
  */
  const selectionNodesCount = selectedSlice.content.childCount || 0;
  if (!selectionNodesCount) {
    return {
      files: [],
      texts: [],
    };
  }

  const texts: string[] = [];
  const files: SelectFile[] = [];

  const nodes = getNodesFromSelection(selectedSlice.content);

  nodes.forEach(n => {
    switch (n.type.name) {
      case IMAGE_EXTENSION_NAME:
        files.push({
          type: IMAGE_EXTENSION_NAME,
          size: n.attrs.size || ImageSize.SMALL,
          src: n.attrs.src || '',
          title: n.attrs.title || '',
        });
        break;
      case CHECK_LIST_TYPE:
      case ORDERED_LIST_TYPE:
      case BULLET_LIST_TYPE:
      case HEADING_TYPE:
      case PARAGRAPH_TYPE:
        // TODO: should recursively check texts in nodes one by one
        texts.push(n.textContent);
        break;
      default:
        break;
    }
  });

  return {
    texts,
    files,
  };
};

/**
 * Will return only supported node from selection
 * Ignore the one we don't support yet
 */
const getNodesFromSelection = (fragment: Fragment) => {
  const nodes: Node[] = [];

  fragment.forEach(node => {
    switch (node.type.name) {
      case IMAGE_EXTENSION_NAME:
      case CHECK_LIST_TYPE:
      case ORDERED_LIST_TYPE:
      case BULLET_LIST_TYPE:
      case HEADING_TYPE:
      case PARAGRAPH_TYPE:
        nodes.push(node);
        break;
      default:
        break;
    }
  });

  return nodes;
};
