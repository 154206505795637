import { IntegrationType } from '@cycle-app/graphql-codegen';

import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetSubdomain,
  setValue: setSubdomain,
} = make<{
  isModalOpen: boolean;
  install: VoidFunction | null;
  type: IntegrationType | null;
}>({
  defaultState: {
    isModalOpen: false,
    install: null,
    type: null,
  },
});

export const openSubdomainModal = (install: VoidFunction, type: IntegrationType) => setSubdomain({
  isModalOpen: true,
  install,
  type,
});

export const closeSubdomainModal = () => setSubdomain({
  isModalOpen: false,
  install: null,
});
