import styled from 'styled-components';

import { caption500 } from '../../theme/typo';

export const Container = styled.span`
  display: inline-flex;
  gap: 3px;
  align-items: center;
  background-color: ${p => p.theme.colors.components.OfflineBadge.bg};
  color: ${p => p.theme.colors.components.OfflineBadge.text};
  border-radius: 310px;
  padding: 2px 5px;
  ${caption500}

  > svg {
    flex-shrink: 0;
  }
`;
