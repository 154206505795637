import { Button, boxShadowZ2, typo, transitionCss, ShyScrollbarCss } from '@cycle-app/ui';
import { ArrowUpCircleIcon } from '@cycle-app/ui/icons';
import { ColorTheme } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { darken } from 'polished';
import styled, { keyframes, css } from 'styled-components';

import { AudioRecorder } from 'src/components/AudioRecorder';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Body = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const DropIcon = styled(ArrowUpCircleIcon)`
  color: ${p => p.theme.colors.text.disabled};
  ${transitionCss(['color'])}
`;

export const gradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

const maskAnimation = keyframes`
  0% { --x: 0% }
  100% { --x: 100% }
`;

export const Header = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1;
  padding: 0 40px;
`;

export const Title = styled.div`
  ${typo.body500}
  margin-top: 10px;
  text-align: center;
`;

export const Description = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.disabled};
  text-align: center;

  position: relative;
  top: 16px;
  display: flex;
  gap: 8px 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;  
  max-width: 700px;
`;

export const DescItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  text-align: center;
  height: 24px;
  svg {
    flex: none;
    position: relative;
    top: 3px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 8px;
`;

export const SecondaryButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'M',
})`
  padding: 0 6px;
  height: 30px;
  svg { flex: none }
  ${p => p.theme.isDark && `
    color: ${ColorTheme.Blue400};
  `}
`;

export const Label = styled.label`
  --bg: ${p => p.theme.colors.button.secondary.bg};
  --color: ${p => (p.theme.isDark ? ColorTheme.Blue400 : ColorTheme.Blue600)};
  --border: transparent;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: var(--bg);
  color: var(--color);
  box-sizing: border-box;
  border: 1px solid var(--border);
  cursor: pointer;
  ${typo.body500};
  padding: 0 6px;
  height: 30px;
  svg { flex: none }
  &:hover {
    --bg: ${p => p.theme.colors.button.secondary.bgHover};
  }
  &:active, &:focus {
    --bg: ${p => p.theme.colors.button.secondary.bgFocus};
    outline: none;
  }
`;

export const Gradient = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
`;

export const GradientBefore = styled.div<{
  $isMouseOut: boolean;
  $isDragActive: boolean;
  $isLoading: boolean;
  $isError: boolean;
}>`
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, #FF3870, #A990FF, #57CFEF, #7FEA96, #FFE974);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
  transition: opacity 0.4s;
  opacity: ${p => (p.theme.isDark ? 0.05 : 0.15)};
  ${p => !p.$isError && !p.$isLoading && !p.$isDragActive && p.$isMouseOut && `
    opacity: 0;
  `}
  ${p => p.$isError && `
    background: #f00;
  `}
`;

export const GradientAfter = styled.div<{
  $isMouseOut: boolean;
  $isDragActive: boolean;
  $isLoading: boolean;
  $isError: boolean;
}>`
  position: absolute;
  inset: 0;

  @property --x {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 0%;
  }
  
  ${p => p.$isMouseOut && p.$isLoading && css`
    background:
      radial-gradient(circle at var(--x) 120%, rgba(0,0,0,0) 0, rgba(0,0,0,0) 150px, ${p.theme.colors.home.card.bg} 350px);
    animation: ${maskAnimation} 2s ease-in-out infinite alternate;
  `}

  ${p => (p.$isDragActive || p.$isError) && `
    background:
      linear-gradient(0deg, rgba(0,0,0,0) 0, ${p.theme.colors.home.card.bg} 80%, ${p.theme.colors.home.card.bg} 100%);
    animation: none;
  `}
`;

export const Container = styled.div`
  margin: 0 32px;
  min-height: 322px;
  background: ${p => p.theme.colors.home.card.bg};
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  ${boxShadowZ2}
`;

export const DropContainer = styled(motion.div)`
  inset: 0;
  position: relative;
  overflow: hidden;
  flex: 1;
  cursor: pointer;
  min-width: 200px;
`;

export const Drop = styled.div<{
  $isDragActive: boolean;
  $isError: boolean;
}>`
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  
  ${p => p.$isDragActive && `
    ${DropIcon}, ${Title} {
      color: inherit !important;
    }
    ${Body} {
      pointer-events: none;
    }
  `}

  ${p => !p.$isError && `
    :has(${SecondaryButton}:hover) {
      ${GradientBefore} {
        opacity: 0;
      }
  `}
`;

export const Counter = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 4px;
`;

export const List = styled(motion.div)`
  flex: 1;
  ${ShyScrollbarCss}
  padding: 1px 5px 5px 5px;
  margin: 0 -17px 0 -5px;
  scrollbar-gutter: stable;
  position: relative;
`;

export const Details = styled.div<{
  $isDetails: boolean;
  $direction: 'row' | 'column';
}>`
  display: flex;
  align-items: stretch;
  gap: 24px;
  height: 100%;

  ${Drop} {
    padding: 70px 40px;
  }

  ${p => p.$isDetails && `
    padding: 24px;
    max-height: 322px;
    ${Header} {
      padding: 0 24px;
    }
    ${DropContainer} {
      border-radius: 10px;
      border: 1px dashed ${p.theme.colors.border.primary};
      flex: none;
    }
    ${Drop} {
      padding: 70px 24px;
    }
    ${Description} {
      display: none;
    }

    ${Title} {
      color: ${p.theme.colors.text.secondary};
    }
  `}

  ${p => p.$direction === 'column' && `
    flex-direction: column;
    max-height: unset;
    ${List} {
      max-height: 275px;
    }
  `}
`;

export const StyledAudioRecorder = styled(AudioRecorder)`
  border: none;
  margin: 0;
`;

export const StyledLinkInputContainer = styled.div`
  width: 450px;
  padding: 20px;
  z-index: 10;
  border-radius: 13px;
`;

export const StyledLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${p => darken(0.2, p.theme.colors.text.red)};
  }
`;
