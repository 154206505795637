import { Input } from '@cycle-app/ui';
import { useCallback, FC, FormEvent } from 'react';

import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { useEditorContext } from 'src/contexts/editorContext';

import { Form } from './LinkInput.styles';

export const LinkInput: FC<React.PropsWithChildren<Pick<ToggleDropdownProps, 'button'>>> = ({ button }) => {
  const editor = useEditorContext(ctx => ctx.editor);

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target as typeof e.target & {
      href: { value: string };
    };
    const href = target.href.value.trim();
    if (!href.startsWith('http')) return;
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href })
      .run();
  }, [editor]);

  return (
    <ToggleDropdown
      offsetY={16}
      placement="bottom-start"
      button={button}
      content={props => (
        <Form
          {...props}
          onSubmit={onSubmit}
        >
          <Input
            name="href"
            placeholder="Paste link"
            autoFocus
          />
        </Form>
      )}
    />
  );
};
