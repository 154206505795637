import { videoExtension as extension } from '@cycle-app/editor-extensions';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { EditorVideoView } from 'src/components/EditorVideoView';

export const videoExtension = extension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(EditorVideoView);
  },
});
