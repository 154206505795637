import { typo, CodeInput, Spinner } from '@cycle-app/ui';
import styled from 'styled-components';

import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const Content = styled.div`
  flex: 1;
  max-width: 408px;
`;

export const Body = styled.div`
  text-align: center;
  width: 100%;
`;
export const StyledButton = styled(NextButton)`
  margin-left: auto;
`;

export const Form = styled.form`
  margin-top: 24px;
`;

export const CodeInputContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

export const StyledCodeInput = styled(CodeInput)`
  input {
    ${typo.headerMedium};
    appearance: textfield;
  }
`;

export const ConfirmFooter = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const ConfirmError = styled.div`
  color: ${p => p.theme.colors.text.red};
  margin-top: 24px;
`;

export const VerifySpinner = styled(Spinner)`
  width: 12px;
  height: 12px;
`;

export const NospaceButton = styled(NextButton)`
  --color: hsl(var(--cycle));
  &:hover, &:focus, &:active {
    --color: hsl(var(--cycle));
  }
  width: auto;
  display: inline-flex;
  padding: 0;
  align-items: center;
  gap: 2px;
`;
