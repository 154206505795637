import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

type AiCounterState = {
  isHidden: boolean;
};

export const { hookState: useAiCounter } = make<AiCounterState>({
  defaultState: {
    isHidden: false,
  },
  localKey: LocalKey.AiCounter,
  crossTabSync: true,
});
