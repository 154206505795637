import { LogoutIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { ErrorToasters } from 'src/app/ErrorToasters/ErrorToasters';
import { AppToasters } from 'src/components/AppToasters';
import { LightOnboardingSteps } from 'src/constants/onboarding.constants';
import { useLogout } from 'src/hooks/useLogout';
import { history } from 'src/providers';
import { useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import {
  Aside,
  Body,
  ContentContainer,
  Content,
  Description,
  Header,
  Main,
  ProgressContainer,
  Root,
  SubTitle,
  StyledProgress,
  StyledToastersContainer,
  Title,
  Wrapper,
  RootAnimated,
  StyledErrorToastersContainer,
  Headline,
  LogoutButton,
  StyledLogo,
} from './OnboardingLayout.styles';

interface Props {
  aside?: ReactNode;
  cover?: ReactNode;
  description?: ReactNode;
  hasLogout?: boolean;
  headline?: ReactNode;
  hideLogo?: boolean;
  isAnimated?: boolean;
  isVerticalCentered?: boolean;
  isContentGrow?: boolean;
  main?: ReactNode;
  subtitle?: ReactNode;
  title?: ReactNode;
  footer?: ReactNode;
  root?: ReactNode;
}

export const OnboardingLayout: FC<React.PropsWithChildren<Props>> = ({
  aside,
  cover,
  description,
  hasLogout = false,
  headline,
  hideLogo,
  isAnimated,
  isVerticalCentered = true,
  isContentGrow,
  main,
  subtitle,
  title,
  footer,
  root,
}) => {
  const { screen } = useGetOnboarding();
  const logout = useLogout(history.location.state?.logoutRedirectPageId);

  function getProgress() {
    return ((LightOnboardingSteps.indexOf(screen as LightOnboardingScreen) + 1) / LightOnboardingSteps.length) * 100;
  }

  return (
    <>
      <Wrapper data-onboarding-step={screen}>
        <Root $isAnimated={isAnimated}>
          <Main $hasHeader={!hideLogo}>
            {hasLogout && (
              <LogoutButton onClick={logout} size="L">
                <LogoutIcon size={16} />
                Log out
              </LogoutButton>
            )}
            {!hideLogo && (
              <Header $isCentered={!aside}>
                <StyledLogo animation="hover" animateColor={false} full size={28} />
              </Header>
            )}
            <ContentContainer $isVerticalCentered={isVerticalCentered} $isContentGrow={isContentGrow}>
              <Content $isVerticalCentered={isVerticalCentered} $isStretch={!!footer}>
                <Body>
                  {cover}
                  {headline && <Headline>{headline}</Headline>}
                  {title && <Title>{title}</Title>}
                  {subtitle && <SubTitle>{subtitle}</SubTitle>}
                  {description && <Description>{description}</Description>}
                  {main}
                </Body>
              </Content>
              {footer}
            </ContentContainer>
          </Main>
          {aside && (
            <Aside>
              <ProgressContainer>
                <StyledProgress isNeutral percentage={getProgress()} type="ring" useCycleColor={false} />
              </ProgressContainer>
              {aside}
            </Aside>
          )}
          {root}
          {isAnimated && (
            <RootAnimated
              initial={{
                opacity: 0,
                translateY: 40,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
              }}
            />
          )}
        </Root>
      </Wrapper>
      <StyledToastersContainer>
        <AppToasters />
      </StyledToastersContainer>
      <StyledErrorToastersContainer
        placeLeft={false}
        $sidebarWidth={0}
      >
        <ErrorToasters />
      </StyledErrorToastersContainer>
    </>
  );
};
