/* eslint-disable no-param-reassign */
import { DRAG_ICON_SVG, DRAG_HANDLE_CLASS, PARAGRAPH_TYPE, HEADING_TYPE } from '../constants';
import { DraggingParams } from '../types/formatting.types';

type GetNodeViewParams = {
  className: string;
  el: string;
  options: DraggingParams;
};
type GetNodeView = (params: GetNodeViewParams) => {
  dom: HTMLElement;
  contentDOM: HTMLDivElement;
};

// Reference to the dragging node element
let draggingNodeRef: HTMLDivElement | null = null;

export const getNodeView: GetNodeView = ({
  className,
  el,
  options,
}) => {
  const dom = document.createElement(el);
  dom.className = className;
  /**
   * This make sure we can't drag from somewhere else than the drag handle
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dom.style['-webkit-user-drag'] = 'none';
  const dragIcon = document.createElement('div');
  dragIcon.draggable = true;
  dragIcon.setAttribute('data-drag-handle', 'true');
  dragIcon.className = DRAG_HANDLE_CLASS;
  dragIcon.innerHTML = DRAG_ICON_SVG;
  dom.appendChild(dragIcon);

  const content = document.createElement('div');
  content.setAttribute('data-content', 'true');
  dom.appendChild(content);

  dragIcon.addEventListener('dragstart', getDragStartCallback(content, options));
  dragIcon.addEventListener('dragend', getDragEndCallback(options));

  return {
    dom,
    contentDOM: content,
  };
};

const getDragStartCallback = (el: HTMLSpanElement, options: DraggingParams) => (event: DragEvent) => {
  options?.onDragStart?.();
  const parent = el.parentElement;
  if (!parent || !event.dataTransfer) return;
  const insideElement = parent.querySelector('[data-content="true"]');
  if (!insideElement) return;
  const clone = insideElement.cloneNode(true);
  if (clone instanceof HTMLDivElement) { // Only for typing
    draggingNodeRef = clone;
    styleCloneNode(clone, {
      width: insideElement.getBoundingClientRect().width,
      type: options.nodeType,
    });
    document.body.appendChild(clone);
    event.dataTransfer.setDragImage(clone, 10, 10);
  }
};

const getDragEndCallback = (options?: DraggingParams) => () => {
  options?.onDragEnd?.();
  if (draggingNodeRef) {
    // Remove the element from the DOM
    draggingNodeRef.remove();
    // Free the reference, so it can be garbage collected
    draggingNodeRef = null;
  }
};

const styleCloneNode = (
  clone: HTMLDivElement,
  options: {
    width: number;
    type: DraggingParams['nodeType'];
  },
) => {
  clone.style.paddingLeft = '18px';
  clone.style.width = `${options.width}px`;

  if (options.type === PARAGRAPH_TYPE) {
    clone.style.fontSize = '14px';
    clone.style.lineHeight = '1.5';
    clone.style.fontWeight = '400';
    clone.style.fontStyle = 'normal';
  }
  if (options.type === HEADING_TYPE) {
    clone.style.lineHeight = '1.5';
  }
};
