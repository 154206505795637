import { useEffect } from 'react';

import { ProductTourModalContent } from 'src/components/ProductTourModalContent';
import { SLACK_TOUR, STEP } from 'src/constants/productTour.constant';
import { useIsSlackTourActive, useSlackTour } from 'src/hooks';
import useQueryParams from 'src/hooks/useQueryParams';
import { Tour } from 'src/types/productTour.types';

import { ImageContainer } from './SlackTourModal.styles';

export const SlackTourModal = () => {
  const { isActive } = useIsSlackTourActive();
  const { open } = useSlackTour();
  const queryParams = useQueryParams();
  const learnMore = queryParams.get('learnMore');

  useEffect(() => {
    if (learnMore === 'slack-tour') open();
  }, [learnMore]);

  if (!isActive) return null;

  return <ModalContent tour={SLACK_TOUR} />;
};

type ModalContentProps = {
  tour: Tour;
};

const ModalContent = ({ tour }: ModalContentProps) => {
  const { close } = useSlackTour();

  return (
    <ProductTourModalContent
      slides={tour.steps
        .map(s => STEP[s])
        .map((s, index) => ({
          id: index,
          title: s.title,
          description: typeof s.text === 'string' ? s.text : s.text?.(),
          image: !s.images?.length ? undefined : (
            <ImageContainer $areImageSmall={s.images.length > 1}>
              {s.images?.map(i => <img key={i} src={i} alt="" draggable="false" />)}
            </ImageContainer>
          ),
          component: s.component,
        }))}
      stop={close}
    />
  );
};
