import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetIntegrations,
  getValue: getIntegrations,
  setValue: setIntegrations,
} = make({
  defaultState: {
    isSourcesModalVisible: false,
  },
});

export const openSourcesModal = () => setIntegrations({ isSourcesModalVisible: true });
export const closeSourcesModal = () => setIntegrations({ isSourcesModalVisible: false });
