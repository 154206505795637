import { Button } from '@cycle-app/ui';
import { useRef, useState, useCallback } from 'react';

import { CommentEditorOutput } from 'src/components/Editor/Editors/CommentEditor';
import { NEW_COMMENT_MAX_HEIGHT } from 'src/constants/comments.constants';
import { useOptimizedBooleanState } from 'src/hooks';
import { useAddComment } from 'src/hooks/api/mutations/comments';
import { useGetDocPanel } from 'src/reactives/docRightPanel.reactive';
import { useDraftComment } from 'src/reactives/draftComments.reactive';
import { fixCommentContent } from 'src/utils/editor/editor.utils';

import {
  AddComment, StyledCommentEditor, Buttons, StyledMyAvatar, Container, Content,
} from './DocCommentNew.styles';

type Props = {
  docId: string;
  isReply: boolean;
  blockId: string | null;
  onSend?: (isReply: boolean) => void;
  autoFocus?: boolean;
  placeholder?: string;
  isResolved: boolean;
};

export const DocCommentNew = ({
  docId,
  isReply,
  blockId = null,
  onSend,
  autoFocus,
  placeholder,
  isResolved,
}: Props) => {
  const draftId = blockId ? `${docId}-${blockId}` : docId;

  const {
    addComment, isAddingComment,
  } = useAddComment(draftId);

  const {
    draftComment, setDraftComment,
  } = useDraftComment(draftId);

  const newComment = useRef<CommentEditorOutput>();
  const docPanel = useGetDocPanel();

  const [isAddCommentFocused, {
    setTrueCallback: setAddCommentFocused,
    setFalseCallback: setAddCommentBlurred,
  }] = useOptimizedBooleanState(false);

  const [isCommentEmpty, setIsCommentEmpty] = useState(draftComment === undefined);

  const onNewCommentUpdated = useCallback((output: CommentEditorOutput) => {
    newComment.current = output;
    setIsCommentEmpty(output.editor.isEmpty);
    setDraftComment(output.html);
  }, [setDraftComment]);

  const sendComment = useCallback(() => {
    if (isAddingComment || !newComment.current || newComment.current?.editor?.isEmpty) return;
    onSend?.(isReply);
    const fixedOutput = fixCommentContent(newComment.current.editor);
    const output = fixedOutput || newComment.current;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addComment({
      docId,
      blockId,
      content: output.html,
      mentionedIds: newComment.current.mentionedUserIds,
    }, isReply, isResolved);
    newComment.current?.editor?.commands.clearContent();
    newComment.current = undefined;
    setIsCommentEmpty(true);
  }, [addComment, blockId, isReply, docId, isAddingComment, onSend, isResolved]);

  return (
    <Container>
      <Content
        $focused={isAddCommentFocused}
        onFocus={setAddCommentFocused}
        onBlur={setAddCommentBlurred}
      >
        <AddComment>
          <StyledMyAvatar />
          <StyledCommentEditor
            placeholder={placeholder ?? (isReply ? 'Add reply…' : 'Add comment…')}
            onUpdate={onNewCommentUpdated}
            maxHeight={NEW_COMMENT_MAX_HEIGHT}
            autofocus={docPanel.autoFocus || autoFocus}
            content={isAddingComment ? '' : draftComment}
            onSubmit={sendComment}
          />
        </AddComment>

        {!isCommentEmpty && (
          <Buttons>
            <Button
              onClick={sendComment}
              isLoading={isAddingComment}
            >
              Send
            </Button>
          </Buttons>
        )}
      </Content>
    </Container>
  );
};
