import { RefObject } from 'react';
import { useTheme } from 'styled-components';

import { useMousePosition } from 'src/hooks/useMousePosition';

import { Gradient, GradientBefore, GradientAfter } from './Dropzone.styles';

type Props = {
  rootRef: RefObject<HTMLElement>;
  isDragActive: boolean;
  isLoading: boolean;
  isError: boolean;
  isMousePositionEnabled?: boolean;
};

/**
 * There is 4 different background animations:
 * - Default: gradient that moves with the mouse
 * - Dragging: gradient that fills the whole dropzone
 * - Uploading: horizontal animation at the bottom
 * - Error: red gradient at the bottom
 */
export const DropzoneBackground = ({
  rootRef, isDragActive, isLoading, isError, isMousePositionEnabled = true,
}: Props) => {
  const theme = useTheme();

  const mousePos = useMousePosition({
    rootRef,
    isDisabled: isDragActive || isLoading || isError || !isMousePositionEnabled,
  });

  return (
    <Gradient>
      <GradientBefore
        $isMouseOut={mousePos.out || !mousePos.x || !mousePos.y}
        $isDragActive={isDragActive}
        $isLoading={isLoading}
        $isError={isError}
      />
      <GradientAfter
        $isMouseOut={mousePos.out}
        $isDragActive={isDragActive}
        $isLoading={isLoading}
        $isError={isError}
        {...mousePos.x && mousePos.y && {
          style: {
            background:
              `radial-gradient(circle at ${mousePos.x}px ${mousePos.y}px, rgba(0,0,0,0) 0, rgba(0,0,0,0) 120px, ${theme.colors.home.card.bg} 250px)`,
          },
        }}
      />
    </Gradient>
  );
};
