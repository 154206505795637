import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const StateToasters = styled.div`
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${mappingZindex[Layer.OfflineWarning]};
  white-space: nowrap;
  
  ${mobile} {
    top: 8px;
    width: auto;
    max-width: calc(100% - 16px);
  }
`;
