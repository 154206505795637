import { ViewType } from '@cycle-app/graphql-codegen';

import { NewDoc, NewDocPlaceholder } from 'src/components/NewDoc';
import { useDocContext } from 'src/contexts/docContext';
import { useSortedCustomDocTypeChildren } from 'src/hooks/useCustomDocTypeChildren';
import { setBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { getPathValue, toggleDocChildren, useDocChildrenExpanded, useHasNextPage } from 'src/reactives/docChildren.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getDocFromCache } from 'src/utils/cache.utils';

export const NestedNewDoc = ({
  docTypeId, lastChildId, canGoUp, onClose, ...props
}: {
  docTypeId: string;
  lastChildId?: string;
  canGoUp: boolean;
  canGoDown: boolean;
  onClose?: VoidFunction;
}) => {
  const docId = useDocContext(ctx => ctx.id);
  const groupId = useDocContext(ctx => ctx.groupId);
  const basePath = useDocContext(ctx => ctx.path);
  const docTypeParentId = useDocContext(ctx => ctx.doctype.id);
  const docTypeParent = useGetDocType(docTypeParentId);

  const docTypeChildren = useSortedCustomDocTypeChildren(docTypeId);
  const lastDocTypeChild = docTypeChildren.length ? docTypeChildren[docTypeChildren.length - 1] : null;

  const lastChildPath = lastChildId ? [...basePath, lastChildId] : null;
  const nextPath = lastChildId ? [...basePath, lastChildId, lastDocTypeChild?.id] : null;

  const isLastChildExpanded = useDocChildrenExpanded(lastChildPath);
  const nextChildHasNextPage = useHasNextPage(nextPath);

  const lastChild = lastChildId ? getDocFromCache(lastChildId) : null;
  const lastChildHasChildren = !!lastChild?.childrenCount;

  const canGoDown =
    props.canGoDown &&
    !nextChildHasNextPage &&
    !!lastDocTypeChild &&
    (!lastChildHasChildren || isLastChildExpanded);

  const goDown = () => {
    if (!canGoDown) return;
    if (!isLastChildExpanded && lastChildPath) toggleDocChildren(lastChildPath);
    if (nextPath) {
      setBoardNewDocPositionState({
        groupId: getPathValue(nextPath),
        draftPosition: 'bottom',
      });
    }
  };

  const goUp = () => {
    if (!canGoUp) return;
    setBoardNewDocPositionState({
      groupId: basePath.length < 3
        ? (groupId ?? null)
        : getPathValue([...basePath.slice(0, -1), docTypeParent?.id]),
      draftPosition: 'bottom',
    });
    onClose?.();
  };

  return (
    <NewDoc
      viewType={ViewType.List}
      parentId={docId}
      possibleDoctypesId={[docTypeId]}
      from="bottom"
      groupId={groupId}
      path={getPathValue([...basePath, docTypeId])}
      goDown={goDown}
      goUp={goUp}
      placeholder={(
        <NewDocPlaceholder
          child={lastDocTypeChild}
          parent={docTypeParent}
          canGoUp={canGoUp}
          canGoDown={canGoDown}
        />
      )}
      onClose={onClose}
    />
  );
};
