import { ChangelogBuilderChipBadge } from './ChangelogBuilderChipBadge';
import { ChangelogBuilderColors } from './ChangelogBuilderColors';
import { ChangelogBuilderFavicon } from './ChangelogBuilderFavicon';
import { Caret, StyledSection, ToggleButton } from './ChangelogBuilderSidebarAdvanced.style';
import { ChangelogBuilderTagsDesign } from './ChangelogBuilderTagsDesign';
import { ChangelogBuilderTypography } from './ChangelogBuilderTypography';
import { ChangelogBuilerDesignReset } from './ChangelogBuilerDesignReset';
import { useChangelogBuilder } from '../../reactives/changelogBuilder.reactive';

export const ChangelogBuilderSidebarAdvanced = () => {
  const [{ isAdvancedOpen }, setState] = useChangelogBuilder();
  return (
    <>
      <StyledSection>
        <ToggleButton
          variant="nospace"
          size="L"
          full
          onClick={() => setState({
            isAdvancedOpen: !isAdvancedOpen,
          })}
          iconEnd={<Caret />}
          $isOpened={isAdvancedOpen}
        >
          Advanced settings
        </ToggleButton>
      </StyledSection>
      {isAdvancedOpen && (
        <div>
          <ChangelogBuilderChipBadge />
          <ChangelogBuilderFavicon />
          <ChangelogBuilderTypography />
          <ChangelogBuilderColors />
          <ChangelogBuilderTagsDesign />
        </div>
      )}
      <ChangelogBuilerDesignReset />
    </>
  );
};
