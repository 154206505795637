import { ThemeType } from '@cycle-app/graphql-codegen';
import { CheckboxInput } from '@cycle-app/ui';

type Props = {
  theme: ThemeType;
  onChange: (color: ThemeType) => void;
  syncWithSystem: boolean;
  setSyncWithSystem: (syncWithSystem: boolean) => void;
};

export const SystemPreferenceCheckbox = ({
  theme, onChange, syncWithSystem, setSyncWithSystem,
}: Props) => {
  const toggleSyncWithSystem = () => {
    // eslint-disable-next-line no-nested-ternary
    onChange(syncWithSystem
      ? theme
      : window.matchMedia('(prefers-color-scheme:dark)').matches
        ? ThemeType.Nightfall
        : ThemeType.Sunrise);
    setSyncWithSystem(!syncWithSystem);
  };

  return (
    <CheckboxInput
      id="use-system-theme"
      label="Use system preference"
      checked={syncWithSystem}
      onChange={toggleSyncWithSystem}
    />
  );
};
