export function removeAccents(text: string): string {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export const normalizeString = (string: string, excludeSpaces = false): string => {
  const normalizedString = removeAccents(string.trim().toLowerCase());

  return excludeSpaces
    ? normalizedString.replace(' ', '')
    : normalizedString;
};

export const isSearchMatching = (search: string, stringToCompare: string): boolean => {
  const normalizedSearch = normalizeString(search);
  const normalizedStringToCompare = normalizeString(stringToCompare, true);

  return normalizedStringToCompare.includes(normalizedSearch);
};

export const capitalize = (string: string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const htmlToText = (html: string) => html.replace(/<[^>]+>/g, '');

export const plural = (string: string, count: number) => {
  return `${string}${count > 1 ? 's' : ''}`;
};
/**
 * Remove accents and diacritics from a string
 * @link https://stackoverflow.com/a/37511463
 */
export const sanitize = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
