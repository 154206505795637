import { typo, ButtonCss, boxShadowZ3, ActionButton } from '@cycle-app/ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SectionsIllustrationCard } from '../IllustationCards';
import PortalModal from '../PortalModal/PortalModal';

export const StyledPortalModal = styled(PortalModal) <{ $hasSmallDimension: boolean }>`
  width: ${p => (p.$hasSmallDimension ? '435px' : '580px')};
  padding: ${p => (p.$hasSmallDimension ? '24px' : '64px')};
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  > main {
    display: flex;
    flex-direction: column;
  }
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Title = styled.h2`
  ${typo.headerLight}
  text-align: center;
`;

export const StyledButton = styled(Link)`
  ${ButtonCss}
`;

export const StyledButtonFull = styled.button`
  ${ButtonCss}
  width: 385px;

  svg {
    margin: 4px;
  }
`;

export const StyledTextButton = styled.button`
  ${ButtonCss}
`;

export const Desc = styled.p`
  ${typo.body400}
  max-width: 350px;
  margin-top: 8px;
  text-align: center;
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledSectionsIllustrationCard = styled(SectionsIllustrationCard)`
  max-width: 185px;
  margin: 60px auto 0;
`;

const addOnContainerCommonCss = css`
  border-radius: 50%;
`;
const addOnContainerCircleCommonCss = css`
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AddOnLogoContainer = styled.div`
  ${addOnContainerCommonCss}
  ${boxShadowZ3}
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid ${p => p.theme.colors.border.primary};
  margin: 30px 0;

  &:before {
    ${addOnContainerCommonCss}
    ${addOnContainerCircleCommonCss}
    width: 100px;
    height: 100px;
    border: 1px solid ${p => p.theme.colors.border.hover};
  }
  &:after {
    ${addOnContainerCommonCss}
    ${addOnContainerCircleCommonCss}
    width: 136px;
    height: 136px;
    border: 1px solid ${p => p.theme.colors.border.secondary};
  }
  & > div {
    ${addOnContainerCommonCss}
    ${addOnContainerCircleCommonCss}
    width: 172px;
    height: 172px;
    border: 1px solid ${p => p.theme.colors.border.secondary};
    opacity: 0.5;
  }

  svg {
    width: 32px;
    height: 32px;
    color: ${p => p.theme.colors.text.secondary};
  }
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }
`;
