import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FeyIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5325 2.95629C17.5325 2.40623 17.3132 1.87866 16.9228 1.48947L15.4241 0L6.6986 8.68908C6.26581 9.11934 5.92244 9.63027 5.68818 10.1927C5.4539 10.7553 5.33331 11.3583 5.33331 11.9672C5.33331 12.5761 5.4539 13.1791 5.68818 13.7416C5.92244 14.3041 6.26581 14.815 6.6986 15.2452L8.16397 16.7075C7.97425 15.8044 8.01362 14.8685 8.27848 13.9844C8.54334 13.1003 9.02535 12.2958 9.68093 11.6439L16.9228 4.42312C17.3132 4.03393 17.5325 3.50636 17.5325 2.95629ZM16.9207 8.67555C17.3124 9.06808 17.5324 9.60004 17.5324 10.1546C17.5324 10.7093 17.3124 11.2413 16.9207 11.6338L13.2552 15.3009C12.7318 15.8212 12.3171 16.4403 12.0349 17.1224C11.7528 17.8043 11.609 18.5356 11.6118 19.2738C11.6127 19.6588 11.6526 20.0427 11.7309 20.4198L10.2861 18.9346C9.40712 18.0538 8.91345 16.8602 8.91345 15.6158C8.91345 14.3712 9.40712 13.1776 10.2861 12.297L15.4239 7.15979L16.9207 8.67555ZM16.8833 19.5316C17.0889 19.7282 17.2522 19.963 17.3637 20.222C17.4752 20.481 17.5326 20.7592 17.5326 21.0402C17.5326 21.3212 17.4752 21.5993 17.3637 21.8583C17.2522 22.1174 17.0889 22.3521 16.8833 22.5487L15.3938 24L13.8796 22.5186C13.0484 21.7054 12.5565 20.6195 12.4994 19.4721C12.4423 18.3246 12.824 17.1974 13.5705 16.3093L16.8833 19.5316Z" fill="#ADAAE8" />
  </Svg>
);
