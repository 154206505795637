import { useState } from 'react';

import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useAttributesFromDoc } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromDoc';
import { useDoc } from 'src/hooks/api/useDoc';
import { useDocChildrenSubscription } from 'src/hooks/api/useDocChildrenSubscription';
import { useUnlinkedDocChildSubscription } from 'src/hooks/api/useUnlinkedDocChildSubscription';
import { useBoardSlug } from 'src/hooks/usePathParams';
import { useFeatureChildrenDocTypes } from 'src/reactives/docTypes.reactive';

import { FeatureDocHierarchyGroup } from './FeatureDocHierarchyGroup';

type Props = {
  docId: string;
};

export const FeatureDocHierarchy = ({ docId }: Props) => {
  const { doc } = useDoc(docId);
  const childrenDocTypes = useFeatureChildrenDocTypes(doc?.doctype.id);
  const docAttributes = useAttributesFromDoc(doc);
  const boardSlug = useBoardSlug();
  useDocChildrenSubscription(docId);
  useUnlinkedDocChildSubscription(docId);
  const [inputDoctypeId, setInputDoctypeId] = useState<string | null>(null);
  const changeDocParent = useChangeDocParent();

  const onDocLinked = async (childrenId: string, _: string, created: boolean) => {
    if (!doc || created) return;
    await changeDocParent({
      docId: childrenId,
      parentId: docId,
    });
  };

  return (
    <>
      {childrenDocTypes.map(childDocType => (
        <FeatureDocHierarchyGroup
          key={childDocType.id}
          parentId={docId}
          doctypeId={childDocType.id}
          showInput={inputDoctypeId === childDocType.id}
          onDocLinked={onDocLinked}
          setInputDoctypeId={setInputDoctypeId}
          docAttributes={docAttributes}
          sourceId={doc?.source?.id}
          refefresDeleteQueries={!!boardSlug}
        />
      ))}
    </>
  );
};
