import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import intersection from 'rectangle-overlap';

import { BULK_PREVENT_CLASSNAME } from 'src/constants/bulkSelection.constants';
import { PortalId } from 'src/types/portal.types';
import { Axis, Selectable, SelectableData } from 'src/types/rectangle.types';
import { getPortalElement } from 'src/utils/elements.util';
import { getArraySelectable, getRectangle } from 'src/utils/rectangle.util';

interface UseBulkRectangle {
  (p: UseBulkRectangleParams): void;
}

interface UseBulkRectangleParams {
  areaSelector: string;
  targetSelector: string;
  targetIds: string[];
  enabled: boolean;
  onSelectMove?: (selectedItems: SelectableData[]) => void;
  onSelectEnd?: (selectedItems: SelectableData[]) => void;
}

// 👉 Rectangle selection rendering in vanilla to avoid expensive renders
export const useBulkRectangle: UseBulkRectangle = ({
  targetIds,
  enabled,
  areaSelector,
  targetSelector,
  onSelectMove,
  onSelectEnd,
}) => {
  const targets = useRef<Selectable[] | null>(null);
  const origin = useRef<Axis | null>(null);
  const target = useRef<Axis | null>(null);
  const selectedRef = useRef<SelectableData[]>([]);

  const element = getPortalElement(PortalId.Selection);

  /**
   * Refresh targets
   */
  const refreshTargets = useCallback(() => {
    targets.current = getArraySelectable(targetSelector);
  }, [targetSelector]);

  useEffect(() => {
    refreshTargets();
  }, [targetIds]);

  /**
   * When click start
   */
  const onStart = useCallback((e: MouseEvent) => {
    const eventTarget = e.target as Element;
    if (
      eventTarget.nodeName === 'BUTTON' ||
      eventTarget.parentElement?.nodeName === 'BUTTON' ||
      eventTarget.closest(targetSelector) ||
      eventTarget.closest(`.${BULK_PREVENT_CLASSNAME}`) ||
      eventTarget.getAttributeNode('contenteditable')?.value === 'true'
    ) {
      return;
    }

    refreshTargets();
    selectedRef.current = [];
    origin.current = {
      x: e.clientX,
      y: e.clientY,
    };
  }, [targetSelector, refreshTargets]);

  /**
   * When click is down and mouse move
   */
  const onMove = useCallback((e: MouseEvent) => {
    if (!element || !origin.current || !enabled) {
      return;
    }

    target.current = {
      x: e.clientX,
      y: e.clientY,
    };

    const {
      top, left, width, height,
    } = getRectangle(
      {
        x: origin.current.x,
        y: origin.current.y,
      },
      {
        x: target.current.x,
        y: target.current.y,
      },
    );

    element.style.top = `${top}px`;
    element.style.left = `${left}px`;
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
    element.style.borderWidth = '1px';

    const selectedFound = targets.current?.filter((el) => intersection(
      {
        y: top,
        x: left,
        width,
        height,
      },
      {
        y: el.top,
        x: el.left,
        width: el.width,
        height: el.height,
      },
    )).map(({
      id, groupId,
    }) => ({
      id,
      groupId,
    }));

    if (selectedFound) {
      if (selectedFound.map(i => i.id).join('-') !== selectedRef.current.map(i => i.id).join('-')) {
        onSelectMove?.(selectedFound);
      }
      selectedRef.current = selectedFound;
    }
  }, [element, enabled, onSelectMove]);

  const reset = useCallback(() => {
    if (!element) return;

    element.style.width = '0px';
    element.style.height = '0px';
    element.style.borderWidth = '0px';
  }, [element]);

  /**
   * When click is released
   */
  const onEnd = useCallback((e: MouseEvent) => {
    reset();

    const hasClickedOnRoot = e.target instanceof HTMLElement && document.querySelector('#app')?.contains(e.target);

    if ((!origin.current && hasClickedOnRoot) ||
        (e.clientX === origin.current?.x && e.clientY === origin.current?.y)) {
      if (!(e.target instanceof HTMLElement && e.target.closest('.doc-item'))) {
        onSelectEnd?.([]);
      }
    } else if (origin.current) {
      onSelectEnd?.(selectedRef.current);
    }

    origin.current = null;
    target.current = null;
  }, [reset, onSelectEnd]);

  // When hook is disabled
  useEffect(() => {
    if (!enabled) {
      reset();
      origin.current = null;
      target.current = null;
    }
  }, [enabled, reset]);

  /**
   * Listeners mouse
   */

  useLayoutEffect(() => {
    const elementArea = document.querySelector<HTMLElement>(areaSelector);
    elementArea?.addEventListener('mousedown', onStart);
    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onEnd);
    return () => {
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onEnd);
      elementArea?.removeEventListener('mousedown', onStart);
    };
  }, [enabled, onStart, onMove, onEnd, areaSelector]);
};
