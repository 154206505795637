import { ReleaseCreateButton } from 'src/components/ReleaseCreateButton';
import { openReleasesProductTour } from 'src/reactives/releases.reactive';

import { Container, Content, LearnMoreButton } from './ReleasesEmpty.styles';

type ReleasesEmptyProps = {
  fromSettings?: boolean;
};

export const ReleasesEmpty = ({ fromSettings = false }: ReleasesEmptyProps) => {
  return (
    <Container $fromSettings={fromSettings}>
      <Content $fromSettings={fromSettings}>
        <h1>Releases</h1>

        <p>
          Create releases to communicate with customers, prospects, and team.
          <br />
          Just add your first release date and start documenting your release notes.
        </p>

        <ReleaseCreateButton />

        {!fromSettings && (
          <LearnMoreButton onClick={openReleasesProductTour}>
            Learn more about releases
          </LearnMoreButton>
        )}
      </Content>
    </Container>
  );
};
