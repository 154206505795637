import { typo, Button, DropdownCss, truncate } from '@cycle-app/ui';
import { CustomerIconOutline } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const DropdownContainer = styled.div`
  ${DropdownCss};
  cursor: default;
`;

export const Trigger = styled.div<{ $isReadOnly: boolean }>`
  cursor: ${p => (p.$isReadOnly ? 'default' : 'pointer')};
  padding: 2px 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
`;

export const TopContent = styled.div`
  display: flex;
  gap: 12px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  
  div:has(> svg) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Bottom = styled.div`
  display: flex;
  padding: 12px;
  gap: 8px;
  border-top: 1px solid ${p => p.theme.colors.border.surprisingGreyDark};
`;

export const UserInfo = styled.button`
  border: none;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${p => p.theme.colors.actionButton.bgHover};
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    line-height: 20px;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 32px;
`;

export const CustomerName = styled.span`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const CustomerEmail = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const CompanyCta = styled.div<{ $hasBg?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${p => p.$hasBg && css`
    background-color: ${p.theme.colors.background.secondary};
    color: ${p.theme.colors.text.primary};
    font-weight: 500;
    font-size: 12px;
    padding: 0 4px;
    border-radius: 4px;
    cursor: pointer;
`}

  img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const CTA = styled(Button).attrs({
  variant: 'secondary',
  useUserColor: false,
})`
  border: 1px solid ${p => p.theme.colors.border.surprisingGreyDark};
  color: ${p => p.theme.colors.text.primary};

  &:focus {
    background: ${p => p.theme.colors.button.secondary.bg};
  }
`;

export const NoCustomerIcon = styled(CustomerIconOutline)`
  width: 18px;
  height: 18px;
  color: ${p => p.theme.colors.background.active};
`;

interface LabelProps {
  $size?: 'S';
  $muted?: boolean;
}

export const Label = styled.span<LabelProps>`
  ${p => (p.$size === 'S' ? typo.caption500 : typo.body500)}
  color:  ${p => (p.$muted ? p.theme.colors.button.outlined : p.theme.colors.text.primary)};
  margin-left: 6px;
  max-width: 150px;
  ${truncate}
`;

export const CompanyLabel = styled.span<LabelProps>`
 ${p => (p.$size === 'S' ? typo.caption500 : typo.body500)}
  color:  ${p => p.theme.colors.text.primary};
`;

export const CustomerLabel = styled(Label)`
  margin: 0;
`;

export const Text = styled.span<LabelProps>`
  ${p => (p.$size === 'S' ? typo.caption400 : typo.body400)}
  white-space: nowrap;
`;
