import { Tone } from '@cycle-app/graphql-codegen';
import type { SelectOption } from '@cycle-app/ui';

export {
  PARAGRAPH_TYPE, HEADING_TYPE, HORIZONTAL_RULE, BLOCKQUOTE,
  BULLET_LIST_TYPE, CHECK_LIST_TYPE, ORDERED_LIST_TYPE, LIST_ITEM_TYPE,
} from '@cycle-app/editor-extensions';

export const CLASSNAME_USED_FOR_FOCUS = 'doc-title';

export const EDITOR_MAX_CONTENT_WIDTH = 760;
export const EDITOR_WIDTH_DESKTOP = 692;
export const EDITOR_MARGIN = 38;
export const LIST_NESTING_SPACE = 20;
export const QUOTE_CARD_WIDTH = 340;
export const QUOTE_BUTTON_WIDTH = 24;

export const COMMANDS = {
  DOC_MENTION: '#',
  EMOJI: ':',
  GITHUB_ISSUE: '/GitHub issues ',
  SLASH: '/',
  USER_MENTION: '@',
  LINEAR: '/Linear issues ',
  LINEAR_PROJECT: '/Linear project ',
  NOTION: '/Notion ',
} as const;

export type CommandKey = keyof typeof COMMANDS;

export const TONE_OPTIONS: SelectOption[] = Object.values(Tone).map(langCode => ({
  value: langCode,
  label: getToneName(langCode),
})).sort((a, b) => a.label.localeCompare(b.label));

function getToneName(code: Tone) {
  switch (code) {
    case Tone.Academic: return 'Academic';
    case Tone.Business: return 'Business';
    case Tone.Casual: return 'Casual';
    case Tone.ChildFriendly: return 'Child friendly';
    case Tone.Confident: return 'Confident';
    case Tone.Conversational: return 'Conversational';
    case Tone.Creative: return 'Creative';
    case Tone.Emotional: return 'Emotional';
    case Tone.Excited: return 'Excited';
    case Tone.Formal: return 'Formal';
    case Tone.Friendly: return 'Friendly';
    case Tone.Funny: return 'Funny';
    case Tone.Humorous: return 'Humorous';
    case Tone.Informative: return 'Informative';
    case Tone.Inspirational: return 'Inspirational';
    case Tone.Memeify: return 'Memeify';
    case Tone.Narrative: return 'Narrative';
    case Tone.Objective: return 'Objective';
    case Tone.Persuasive: return 'Persuasive';
    case Tone.Poetic: return 'Poetic';
    default: return '';
  }
}
