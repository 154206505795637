import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  img {
    max-height: 150px;
  }
`;

type ImageContainerProps = {
  $areImageSmall: boolean;
};

export const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  flex-direction: row;
  gap: 16px;

  img {
    max-height: ${p => (p.$areImageSmall ? '115px' : '150px')};
  }
`;
