import { Button } from '@cycle-app/ui';
import { twJoin } from 'tailwind-merge';

import { useProductAddOn } from 'src/hooks';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';

import { MeetingButtonInstall } from '../MeetingButtonInstall';
import classes from './HomeViewMeetings.module.css';

export const HomeViewMeetingsEmpty = () => {
  const meetingsAddon = useProductAddOn('MEETINGS');
  const {
    request, isRequesting,
  } = useProductAddOnRequest();
  return (
    <div>
      {
        !meetingsAddon.isEnabled && (
          <Button
            isLoading={isRequesting}
            full
            size="S"
            onClick={() => request('MEETINGS')}
          >
            Install
          </Button>
        )
      }
      {
        meetingsAddon.isEnabled && (
          <div className="flex items-center gap-4">
            <p className={twJoin('text-caption text-secondary', classes.info)}>
              Enable to view your meetings
            </p>
            <MeetingButtonInstall size="S" />
          </div>
        )
      }
    </div>
  );
};
