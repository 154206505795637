import { DocCommentFragment } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { CheckIcon, TrashIcon, PenIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { useMemo } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useDeleteComment, useResolveThread } from 'src/hooks/api/mutations/comments';
import { useCopyCommentLink } from 'src/hooks/useCopyCommentLink';
import { setComments } from 'src/reactives/comments.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocFromCache } from 'src/utils/cache.utils';

import { Container } from './DocCommentMenu.styles';

export const DocCommentMenu = ({
  docId, blockId = null, comment, isFirstComment, onResolve, showResolve,
}: {
  docId: string;
  blockId: string | null;
  comment: DocCommentFragment;
  isFirstComment: boolean;
  onResolve?: VoidFunction;
  showResolve: boolean;
}) => {
  const { deleteComment } = useDeleteComment({ isFirstComment });
  const { resolveThread } = useResolveThread();
  const copyCommentLink = useCopyCommentLink();

  const isMyComment = comment.creator.__typename === 'Me';

  const options = useMemo(() => {
    const result: SelectOption[] = [];

    if (isFirstComment && !blockId && showResolve) {
      result.push({
        label: 'Resolve thread',
        value: 'resolve',
        icon: <CheckIcon />,
        onSelect: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          resolveThread(docId, blockId, { commentId: comment.id });
          onResolve?.();
        },
      });
    }

    if (isMyComment) {
      result.push({
        label: 'Edit',
        value: 'edit',
        icon: <PenIcon />,
        onSelect: () => setComments({ editingId: comment.id }),
      });
    }

    result.push({
      label: 'Copy link',
      value: 'copy',
      icon: <DuplicateIcon />,
      onSelect: () => copyCommentLink(getDocFromCache(docId), comment.id),
    });

    // We can't delete the first comment of a thread
    if (isMyComment && !isFirstComment) {
      result.push({
        label: 'Delete',
        value: 'delete',
        icon: <TrashIcon />,
        onSelect: () => deleteComment(docId, blockId, { commentId: comment.id }),
      });
    }

    return result;
  }, [isFirstComment, blockId, showResolve, isMyComment, resolveThread, docId, comment.id, onResolve, copyCommentLink, deleteComment]);

  if (options.length === 0) return null;

  return (
    <Container>
      <DotsMenuLayer
        layer={Layer.DropdownModalZ1}
        onClick={() => setComments({ focusedId: comment.id })}
        onHide={() => setComments({ focusedId: null })}
        options={options}
      />
    </Container>
  );
};
