import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  ${typo.body500}
`;
