import { Emoji, ActionButton, EmojiPicker, EmojiData } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { useSlackReacji } from 'src/hooks/slack/useSlackReacji';
import { useSlackReacjiUpdate } from 'src/hooks/slack/useSlackReacjiUpdate';

import { HelpText } from '../HelpText';
import { SlackActionItem } from '../SlackActionItem';
import { List } from '../SlackActionItem.styles';
import { ContentHeading, ContentHeader, ContentBody } from '../SlackSettingsModal.styles';
import { EmojiPickerStyled } from './ReacjisTab.styles';

export const ReacjisTab = () => {
  const { emoji } = useSlackReacji();
  const { reacjiUpdate } = useSlackReacjiUpdate();

  const onEmojiSelect = async (value: EmojiData) => {
    if ('id' in value && value.id) {
      await reacjiUpdate(value.id);
    }
  };

  return (
    <div>
      <ContentHeader>
        <ContentHeading>Active reacjis</ContentHeading>
        {emoji ? null : (
          <EmojiPicker
            forceFocus
            dropdownProps={{ placement: 'bottom' }}
            onSelect={onEmojiSelect}
            variant="primary"
          >
            Add reactji
          </EmojiPicker>
        )}
      </ContentHeader>
      <ContentBody>
        {emoji && (
          <List>
            <SlackActionItem
              icon={(
                <EmojiPickerStyled
                  forceFocus
                  dropdownProps={{ placement: 'bottom' }}
                  onSelect={onEmojiSelect}
                >
                  <Emoji emoji={emoji} />
                </EmojiPickerStyled>
              )}
              action={(
                <ActionButton
                  tooltip="You need at least 1 reacji"
                  tooltipPlacement="top"
                  disabled
                >
                  <CloseIcon />
                </ActionButton>
              )}
            />
          </List>
        )}
        <HelpText>Make sure to invite @Cycle in the relevant Slack channels for the reacji to work.</HelpText>
      </ContentBody>
    </div>
  );
};
