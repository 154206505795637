import { FC } from 'react';

import confluence from './confluence.png';

type Props = {
  className?: string;
  size?: number;
};

export const ConfluenceIcon: FC<React.PropsWithChildren<Props>> = ({
  className, size,
}) => (
  <img className={className} width={size || 16} src={confluence} alt="Confluence" />
);
