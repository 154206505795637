import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const RotateIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="rotate clockwise, rotate">
      <path id="Icon" d="M3.31514 8C3.31514 5.42267 5.40448 3.33333 7.98181 3.33333C9.29781 3.33333 10.2503 3.78632 11.1728 4.66667H10C9.63185 4.66667 9.33337 4.96514 9.33337 5.33333C9.33337 5.70152 9.63185 6 10 6H12.1667C12.811 6 13.3334 5.47767 13.3334 4.83333V2.66667C13.3334 2.29848 13.0349 2 12.6667 2C12.2985 2 12 2.29848 12 2.66667V3.61534C10.9186 2.6146 9.68279 2 7.98181 2C4.6681 2 1.98181 4.68629 1.98181 8C1.98181 11.3137 4.6681 14 7.98181 14C10.5953 14 12.8169 12.3294 13.6402 9.99994C13.7629 9.6528 13.581 9.27192 13.2338 9.14922C12.8867 9.02652 12.5058 9.20847 12.3831 9.55561C11.7422 11.369 10.0127 12.6667 7.98181 12.6667C5.40448 12.6667 3.31514 10.5773 3.31514 8Z" />
    </g>
  </Svg>
);
