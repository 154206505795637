import { StatusFragment } from '@cycle-app/graphql-codegen';
import { Tooltip, Popover } from '@cycle-app/ui';
import { getDocSlug } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useCallback } from 'react';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { PageId } from 'src/constants/routing.constant';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { useDocV2 } from 'src/hooks/api/useDoc';
import { useDocSubscription } from 'src/hooks/api/useDocSubscription';
import { useUrl } from 'src/hooks/useUrl';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { useGetStatuses } from 'src/reactives/productStatus.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocTypeName } from 'src/utils/docType.util';

import {
  DocMentionNodeView,
  LinkIcon,
  TitleContainer,
  LinkItem,
  ParentElementContainer,
  LoadingState,
  StyledDocParentDropdown,
  StyledStatusIcon,
} from './DocMentionView.styles';

const DocMentionView: FC<React.PropsWithChildren<NodeViewRendererProps>> = ({ node }) => {
  const getUrl = useUrl();

  const {
    doc, isLoading,
  } = useDocV2(node.attrs.id);
  useDocSubscription(node.attrs.id);

  const isReadOnly = useEditorContext(ctx => ctx.isReadOnly);

  const onDocMentionHovered = useCallback((hoverDocId: string) => {
    setDocItemHoverId(hoverDocId);
    if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: hoverDocId });
  }, []);
  const onDocMentionLeft = useCallback(() => setDocItemHoverId(null), []);
  const onMouseEnter = useCallback(() => onDocMentionHovered(doc?.id ?? ''), [onDocMentionHovered, doc?.id]);

  const docType = useGetDocType(doc?.doctype?.id);
  const docStatus = useGetStatuses().statusById[doc?.status?.id ?? ''] as StatusFragment | undefined;

  return (
    <DocMentionNodeView
      onMouseEnter={!isLoading && !!doc ? onMouseEnter : undefined}
      onMouseLeave={!isLoading && !!doc ? onDocMentionLeft : undefined}
      data-drag-handle
      data-type="doc-mention"
    >
      {(!doc || isLoading) && <LoadingState />}
      {doc && (
        <Popover
          placement="top-start"
          interactive
          disabled={!docType?.parents?.edges?.length || isReadOnly}
          withPortal
          withWrapper={false}
          offset={[0, 0]}
          content={(
            <ParentElementContainer>
              <StyledDocParentDropdown docId={doc.id} docTypeId={doc.doctype.id} />
            </ParentElementContainer>
          )}
        >
          <LinkItem to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) })}>
            <LinkIcon size={17} />

            <Tooltip
              placement="bottom"
              content={getDocTypeName(docType)}
              withPortal
              withWrapper={false}
              zIndex={mappingZindex[Layer.DropdownZ2]}
            >
              <DocTypeIcon doctype={docType} size={14} />
            </Tooltip>

            {doc.status?.category && (
              <Tooltip
                placement="bottom"
                content={docStatus?.value}
                withPortal
                withWrapper={false}
                zIndex={mappingZindex[Layer.DropdownZ2]}
              >
                <StyledStatusIcon withBackground category={doc.status?.category} />
              </Tooltip>
            )}

            <Tooltip
              placement="bottom"
              content={doc.title}
              withPortal
              withWrapper={false}
              zIndex={mappingZindex[Layer.DropdownZ2]}
              displayFullTitle
            >
              <TitleContainer>
                {doc.title}
              </TitleContainer>
            </Tooltip>
          </LinkItem>
        </Popover>
      )}
    </DocMentionNodeView>
  );
};

export default DocMentionView;
