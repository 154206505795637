import { Tooltip } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { CustomerEmailForm } from './CustomerEmailForm';
import { EmptyButton, ProfileEditButton, TextButton } from './Profile.styles';

type Props = {
  customerId: string;
};

export const CustomerEmail = ({ customerId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { customer } = useCustomer(customerId);

  if (isEditing) {
    return (
      <CustomerEmailForm
        customerId={customerId}
        name={customer?.name}
        email={customer?.email}
        hide={() => setIsEditing(false)}
      />
    );
  }

  if (!customer?.email) {
    return (
      <EmptyButton onClick={() => setIsEditing(true)}>
        Add email…
      </EmptyButton>
    );
  }

  return (
    <>
      <TooltipClickToCopy
        title={customer.email}
        value={customer.email}
      >
        {props => (
          <TextButton {...props}>
            {customer.email}
          </TextButton>
        )}
      </TooltipClickToCopy>

      {!isDefaultCustomer(customer) && (
        <Tooltip
          content="Edit email"
          placement="top"
          withPortal
          withWrapper={false}
        >
          <ProfileEditButton
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(true);
            }}
          >
            <PenFilledIcon />
          </ProfileEditButton>
        </Tooltip>
      )}
    </>
  );
};
