import { ViewType } from '@cycle-app/graphql-codegen';
import {
  forwardRef,
  memo,
  MouseEventHandler,
  ReactNode,
  useCallback,
} from 'react';

import { useDocContext } from 'src/contexts/docContext';

import { DocItemHandle } from './DocItem.types';
import { useIsDocFocused } from './useIsDocFocused';
import { useIsDocInGroupView } from './useIsDocInGroupView';

type DocItemContainerProps = {
  docIndex?: number;
  groupId?: string;
  viewType: ViewType;
  isLazy?: boolean;
  isGroupInBoardView?: boolean;
  skeleton: (height?: number) => ReactNode;
  placeholder?: ReactNode;
  children: (isFocused: boolean) => ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isGroupDragging?: boolean;
};

export const DocItemContainer = memo(forwardRef<DocItemHandle, DocItemContainerProps>(({
  docIndex,
  isGroupInBoardView = true,
  isLazy = false,
  groupId,
  viewType,
  children,
  skeleton,
  onClick,
  isGroupDragging,
}, ref) => {
  const isDisabled = useDocContext(ctx => !ctx._docKey);
  const [focusRef, isFocused] = useIsDocFocused(ref);

  const [viewRef, isDocInGroupView, height] = useIsDocInGroupView({
    docIndex,
    groupId: groupId as string,
    viewType,
    skip: !isLazy || !groupId || !isGroupInBoardView,
  });

  const setRef: React.RefCallback<HTMLDivElement> = useCallback(node => {
    focusRef.current = node;
    viewRef(node);
  }, [focusRef, viewRef]);

  const isDocInView = !isLazy || (isDocInGroupView && isGroupInBoardView);

  return (
    <div
      ref={setRef}
      tabIndex={0}
      role="button"
      onClickCapture={onClick}
      style={{
        height: isDocInView ? 'auto' : height,
        pointerEvents: isDisabled ? 'none' : 'unset',
      }}
    >
      {isDocInView || isGroupDragging ? children(isFocused) : skeleton(height)}
    </div>
  );
}));
