import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useProduct } from './useProduct';

export const useAttributes = () => {
  const { product } = useProduct();

  return useMemo(
    () => nodeToArray(product?.attributeDefinitions),
    [product?.attributeDefinitions],
  );
};

export const useBuiltIntSpecificAttributes = () => {
  const { product } = useProduct();
  const builtIntSpecificAttributes = useMemo(
    () => nodeToArray(product?.builtInSpecificAttributeDefinitions),
    [product],
  );
  const customerAttribute = useMemo(() => builtIntSpecificAttributes.find(attribute => (
    attribute.__typename === 'BuiltInCustomerDefinition')), [builtIntSpecificAttributes]);
  return {
    builtIntSpecificAttributes,
    customerAttribute,
  };
};
