import { CreateReleaseDocument, CreateReleaseMutationVariables, ProductReleasesDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

import { getInitialReleasesVariables } from './useReleases';
import { updateQuery } from '../../utils/update-cache/update-query.util';

export const useCreateRelease = (productId?: string) => {
  const [mutate, { loading }] = useSafeMutation(CreateReleaseDocument, {
    errorPolicy: 'all',
  });

  const createRelease = (variables: CreateReleaseMutationVariables) => mutate({
    variables,
    update: (_, { data }) => {
      if (!productId) return;
      updateQuery({
        query: ProductReleasesDocument,
        variables: getInitialReleasesVariables(productId),
        update: draft => {
          const release = data?.createRelease;
          if (!release || draft.node?.__typename !== 'Product') return;
          const index = draft.node.releases.edges.findIndex(r => (r.node.date && release.date ? (r.node.date < release.date) : false));
          const newIndex = index === -1 ? draft.node.releases.edges.length : index;
          draft.node.releases.edges.splice(newIndex, 0, { node: release });
        },
      });
    },
  });

  return {
    createRelease,
    isCreatingRelease: loading,
  };
};
