import { ActionButton, Emoji } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const Container = styled.div<{ $widthSidebar: number; $expanded: boolean }>`
  border-radius: 12px;

  display: flex;

  z-index: ${mappingZindex[Layer.DocPanel]};
  position: absolute;
  top: 16px;
  right: 16px;
  width: ${p => `calc(100% - ${p.$widthSidebar}px - 32px)`};
  height: calc(100% - 32px);
  overflow: clip;

  background-color: ${p => p.theme.colors.docPanel.bg};

  ${p => p.$expanded && css`
    transition: width, height, transform;
    transition-duration: 0.1s;
    transform: translate3d(16px, -16px, 0);
    width: calc(100% - ${p.$widthSidebar}px);
    height: 100%;
  `}
`;

export const MainSection = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: inherit;
`;

export const Overlay = styled.div`
  z-index: ${mappingZindex[Layer.MaskDocPanel]};
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: ${p => p.theme.colors.docPanel.overlay};
`;

export const ToasterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px 16px;
  max-width: -webkit-fill-available; /* stylelint-disable-line value-no-vendor-prefix */
  > * {
    width: fit-content;
  }
`;

export const StyledEmoji = styled(Emoji)`
  > span {
    line-height: 1;
  }
`;

export const PrevNextActions = styled.div<{ isNotInBoard?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${p => p.isNotInBoard && css`
    cursor: not-allowed;
  `}
`;

export const StyledActionButton = styled(ActionButton)`
  padding: 5px;
  ${p => p.disabled && css`
    color: ${p.theme.colors.actionButton.colorDisabled};
  `}
`;

export const PanelsContainer = styled.div`
  display: flex;
  max-width: 100%;
`;
