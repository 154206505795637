import { useQuery } from '@apollo/client';
import {
  TemplateFragment,
  TemplatesDocument,
  TemplatesQuery,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

export default function useDoctypeTemplates() {
  const {
    data,
    loading,
    refetch,
  } = useQuery<TemplatesQuery>(TemplatesDocument);

  const templates = useMemo(
    () => nodeToArray(data?.doctypeTemplates),
    [data?.doctypeTemplates],
  ) as TemplateFragment[];

  return {
    loading,
    templates,
    refetch,
  };
}
