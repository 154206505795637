import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { Footer, NextButton } from '../OnboardingSlack.styles';
import { SlackMessage } from '../SlackMessage/SlackMessage';

export const SubTitle = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
`;

export const EmojiBox = styled.div`
  background-color: ${p => p.theme.colors.background.secondaryElevated};
  border-radius: 20px;
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr;
  justify-items: center;
  max-width: 450px;
  padding: 18px;
  width: 450px;
`;

export const StyledNextButton = styled(NextButton)`
  justify-content: center;
  max-width: 450px;
  width: 450px;
`;

export const StyledSlackMessage = styled(SlackMessage)`
  width: 360px;
  margin-top: var(--s-6);
`;

export const StyledFooter = styled(Footer)`
  margin-top: var(--s-7);
`;
