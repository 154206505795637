import { typo } from '@cycle-app/ui';
import { QuestionOutlineIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Text = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 12px;
`;

export const Icon = styled(QuestionOutlineIcon)`
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 8px;
`;
