import { typo, truncate } from '@cycle-app/ui';
import styled from 'styled-components';

import { EDITOR_MARGIN } from 'src/constants/editor.constants';

export const Card = styled.div`
  border: 1px solid ${p => p.theme.colors.docPanel.releaseNote.border};
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
  height: 56px;
`;

export const Toggle = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0;
  color: ${p => p.theme.colors.text.disabled};
  cursor: pointer;
  flex: 1;
  overflow: hidden;
  :hover {
    color: ${p => p.theme.colors.text.secondary};
  }
  ${p => p.$isDisabled && `
    pointer-events: none;
  `};
  svg {
    flex: none;
  }
`;

export const SectionTitle = styled.div<{ $isDisabled?: boolean }>`
  ${typo.body500}
  display: flex;
  align-items: center;
  gap: 8px;
  &[data-active="true"] {
    color: ${p => p.theme.colors.text.secondary};
  }
  ${p => p.$isDisabled && `
    pointer-events: none;
  `};
`;

export const NoteTitle = styled.div`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
  ${truncate}
`;

export const EditorContainer = styled.div`
  & .ProseMirror {
    padding: 0 !important;
  }
`;

export const EditorHeader = styled.div`
  padding: 20px ${EDITOR_MARGIN}px 0 ${EDITOR_MARGIN}px;
`;
