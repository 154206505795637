import 'src/utils/polyfills.util';
import 'src/utils/whyDidYouRender/whyDidYouRender';
import './styles/tailwind.css';

import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { enableMapSet } from 'immer';
import { createRoot } from 'react-dom/client';

import 'src/utils/editor/patch.utils';
import { App } from 'src/app/App';
import { createApp, createPortals } from 'src/utils/elements.util';
import { isDevEnv } from 'src/utils/env.util';

import { SENTRY_IGNORED_ERRORS } from './constants/sentry.constants';

if (!isDevEnv()) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing({
      tracingOrigins: ['api.dogfood.cycle.app'],
    }), new SentryFullStory(import.meta.env.VITE_SENTRY_ORG_SLUG ?? '', { client: FullStory })],
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE ?? 0),
    ignoreErrors: SENTRY_IGNORED_ERRORS,
  });
}

createApp();
createPortals();
enableMapSet();

const container = document.getElementById('app');
// suggested by Facebook doc
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);
