import styled, { css, DefaultTheme, StyledComponentProps } from 'styled-components';

import { SvgTransientProps } from '../../types/icon.types';
import { getRotate } from '../../utils/icon.util';

export const STROKE_CURRENT_COLOR_CLASS_NAME = 'stroke--currentColor';
export const FILL_CURRENT_COLOR_CLASS_NAME = 'fill--currentColor';
export const FILL_CYCLE_COLOR_CLASS_NAME = 'fill--cycleColor';
export const FILL_USER_COLOR_CLASS_NAME = 'fill--userColor';
export const FILL_INHERIT_CLASS_NAME = 'fill--inherit';
export const FILL_NONE_CLASS_NAME = 'fill--none';

export const Svg = styled.svg<SvgTransientProps>`
  flex: none;
  
  ${p => p.size && css`
    width: ${p.size}px !important;
    height: ${p.size}px !important;
  `}

  ${(props) => props.direction && `
    transform: rotate(${getRotate(props.direction)}deg);
  `};

  transition: transform .1s;

  ${p => !p.hasGradient && css`
    &:not([data-no-fill]) {
      path, .fill--color {
        fill: currentColor;
      }

      .stroke--color {
        stroke: currentColor;
      }
    }

    .${STROKE_CURRENT_COLOR_CLASS_NAME} {
      stroke: currentColor;
    }

    .${FILL_CURRENT_COLOR_CLASS_NAME} {
      fill: currentColor;
    }

    .${FILL_CYCLE_COLOR_CLASS_NAME} {
      fill: hsl(var(--cycle));
    }

    .${FILL_USER_COLOR_CLASS_NAME} {
      fill: ${p.theme.userColors.main};
    }

    .${FILL_INHERIT_CLASS_NAME} {
      fill: inherit;
    }

    .${FILL_NONE_CLASS_NAME} {
      fill: none;
    }
  `}

  ${p => p.hasGradient && css`
    fill: url(#gradient);
  `}
`;

export type SvgProps = Omit<StyledComponentProps<'svg', DefaultTheme, SvgTransientProps, never>, 'ref'>;
