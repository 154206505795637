import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

interface UserPreferencesState {
  isDocPreviewHintEnabled: boolean;
  isSearchDocPreviewHintEnabled: boolean;
  // Flag if we should highlight the Learn More button
  // after the user finishes the slack product tour.
  discoverSlack: boolean;
  isSlackInstallCardVisible: boolean;
  discoverLinearCreationAutomation: boolean;
  slackHidden: boolean;
  callRecordingHidden: boolean;
}

export const {
  getValue: getUserPreferences,
  setValue: setUserPreferences,
  hookState: useUserPreferences,
  hookValue: useUserPreferencesValue,
} = make<UserPreferencesState>({
  defaultState: {
    isDocPreviewHintEnabled: true,
    isSearchDocPreviewHintEnabled: true,
    isSlackInstallCardVisible: true,
    discoverSlack: true,
    discoverLinearCreationAutomation: true,
    callRecordingHidden: false,
    slackHidden: false,
  },
  localKey: LocalKey.UserPreferences,
  crossTabSync: true,
});
