import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const RecordOutlineIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    data-no-fill
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M8.00001 12.6667V14M8.00001 12.6667C5.54786 12.6667 4.09788 11.1634 3.3429 10M8.00001 12.6667C10.4522 12.6667 11.9021 11.1634 12.6571 10M10.6667 4.66667V7.33333C10.6667 8.80609 9.47277 10 8.00001 10C6.52725 10 5.33334 8.80609 5.33334 7.33333V4.66667C5.33334 3.19391 6.52725 2 8.00001 2C9.47277 2 10.6667 3.19391 10.6667 4.66667Z"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
