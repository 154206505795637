import { Breakpoint } from '../types/breakpoints.types';

export const TABLET_MIN_WIDTH = 768;
export const LAPTOP_MIN_WIDTH = 1200;
export const LAPTOP_SMALL_HEIGHT = 900;

export const small = `@media only screen and (max-width : ${TABLET_MIN_WIDTH - 1}px)`;

export const mobile = `@media only screen and (max-width : ${TABLET_MIN_WIDTH - 1}px) and (pointer: coarse)`;

export const tablet = `@media only screen and (max-width : ${LAPTOP_MIN_WIDTH - 1}px)`;

export const hover = '@media (hover: hover)';

export const smallHeight = `@media only screen and (max-height : ${LAPTOP_SMALL_HEIGHT}px)`;

export function getBreakpoint(): Breakpoint {
  const windowWidth = window.innerWidth;

  if (windowWidth < TABLET_MIN_WIDTH) {
    return 'mobile';
  }
  if (windowWidth < LAPTOP_MIN_WIDTH) {
    return 'tablet';
  }
  return 'desktop';
}
