import styled from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const Container = styled.div`
  z-index: ${mappingZindex[Layer.Toaster]};

  display: flex;
  flex-direction: column-reverse;
  gap: 8px;

  /*
  * Dirty hack to prevent toaster get full width
  * Feel free to fix that 🙏
  */
  > * {
    display: flex;
    justify-content: center;
  }
`;
