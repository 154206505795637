import { Shortcut, ShyScrollbar, ShortcutComponents, typo } from '@cycle-app/ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $width: number }>`
  width: ${p => p.$width}px;
  min-width: 280px;
  padding: 8px 0;
  color: ${p => p.theme.colors.sidebar.header.menus.color};
`;

export const List = styled(ShyScrollbar)`
  max-height: 284px;
`;

export const Footer = styled.div`
  margin-top: 8px;
  padding-top: 8px;

  font-size: 12px;
  font-weight: 500;

  border-top: 1px solid ${p => p.theme.colors.dropdown.sep};

  color: ${p => p.theme.colors.text.secondary};
`;

export const ProductMenuShortcut = styled(Shortcut)`
  margin-left: auto;

  ${ShortcutComponents.Key} {
    background-color: ${p => p.theme.colors.sidebar.header.shortcut.bg};
    color: ${p => p.theme.colors.sidebar.header.shortcut.color};
  }
  ${ShortcutComponents.Then} {
    color: ${p => p.theme.colors.sidebar.header.shortcut.color};
  }
`;

const menuItemCss = css<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 3px 16px;
  cursor: pointer;
  background-color: ${p => p.theme.colors.selectItem.bg};
  color: ${p => p.theme.colors.text.secondary};
  ${typo.body400}

  &:hover {
    background-color: ${p => p.theme.colors.selectItem.bgHover};
    color: ${p => p.theme.colors.sidebar.header.menus.color};

    ${ShortcutComponents.Key} {
      background-color: ${p => p.theme.colors.sidebar.header.shortcut.bgHover};
    }
  }

  ${p => p.$isSelected && `
    background-color: ${p.theme.colors.selectItem.bgHover};
    color: ${p.theme.colors.sidebar.header.menus.color};

    ${ShortcutComponents.Key} {
      background-color: ${p.theme.colors.sidebar.header.shortcut.bgHover};
    }
  `}

  svg {
    width: 16px;
  }
`;

export const MenuItem = styled.button<{ $isSelected: boolean }>`
  ${menuItemCss};
`;

export const SettingsLink = styled(Link)<{ $isSelected: boolean }>`
  ${menuItemCss};
`;
