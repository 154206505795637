import { getPermission, setLimitationsModal } from '../reactives';

/**
 * @example
 * ```js
 * requestInsightCreate(callback);
 * // or
 * const can = requestInsightCreate();
 * if (!can) return;
 * callback();
 * ```
 */
export const requestInsightCreate = (callback?: VoidFunction) => {
  if (!getPermission().canCreateInsight) {
    setLimitationsModal({ action: 'INSIGHT_CREATE' });
    return false;
  }
  callback?.();
  return true;
};
