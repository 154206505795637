import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MoonIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M14 8.50322C13.2467 9.02242 12.3336 9.32641 11.3495 9.32641C8.76701 9.32641 6.67346 7.23287 6.67346 4.65034C6.67346 3.66631 6.97742 2.75328 7.49656 2C4.4176 2.26089 2 4.84268 2 7.98914C2 11.3088 4.69114 14 8.01084 14C11.1574 14 13.7392 11.5823 14 8.50322Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
