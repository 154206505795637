export const EMOJI_REGEXP = /:[a-z_-]+:/g;

export function getLineContent(line: readonly string[]) {
  return line
    .map((text, index) => {
      const wordWithEmojis = extractEmojisInWord(text);
      const contentItems = wordWithEmojis.map(item => (new RegExp(EMOJI_REGEXP).test(item)
        ? {
          type: 'emoji',
          attrs: {
            name: extractExternalEmojiName(item),
          },
        }
        : {
          type: 'text',
          text: item,
        }));
      const whiteSpacePrefix = {
        type: 'text',
        text: ' ',
      };
      return index === 0
        ? contentItems
        : [whiteSpacePrefix, ...contentItems];
    })
    .flat();
}

export function extractExternalEmojiName(externalEmoji: string) {
  return externalEmoji
    .replace(/:/g, '')
    .replace(/-/g, '_');
}

export function extractWordsByLine(text: string): string[][] {
  return text
    .split('\nand')
    .map(line => line
      .split(' ')
      .filter(word => word !== ''));
}

export function extractEmojisInWord(word: string): string[] {
  return word
    .replace(EMOJI_REGEXP, match => ` ${match} `)
    .split(' ')
    .filter(item => item !== '');
}
