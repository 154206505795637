import { useSubscription } from '@apollo/client';
import { AiStreamDocument, GenerateSummaryFromCompanyQuotesDocument, GenerateSummaryFromCustomerQuotesDocument } from '@cycle-app/graphql-codegen';
import { ActionButton, Popover } from '@cycle-app/ui';
import { DuplicateIcon, CloseIcon, RefreshIcon, AiIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSafeMutation } from 'src/hooks';
import { useInterval } from 'src/hooks/useInterval';
import {
  useCompanyStatusCategory,
  useCompanySummary,
  closeCompanySummary,
  useCustomerSummary,
  useCustomerStatusCategory,
  closeCustomerSummary,
} from 'src/reactives/customerCompanyProfile.reactive';
import { Layer } from 'src/types/layers.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { SummaryPortalModal, SummaryContainer, AiWriting, SummaryActions } from './Profile.styles';

const STREAM_SPEED_INTERVAL = 15;

export const CompanySummaryModal = (props: { companyId: string }) => {
  const isOpen = useCompanySummary();
  return (
    <AnimatePresence>
      {isOpen && <CompanySummaryModalVisible {...props} />}
    </AnimatePresence>
  );
};

const CompanySummaryModalVisible = ({ companyId }: { companyId: string }) => {
  const statusCategory = useCompanyStatusCategory();
  const [mutate] = useSafeMutation(GenerateSummaryFromCompanyQuotesDocument);

  const generate = async () => {
    const result = await mutate({
      variables: {
        companyId,
        statusCategory,
      },
    });
    return result.data?.generateSummaryFromCompanyQuotes;
  };

  return (
    <SummaryModal
      generate={generate}
      hide={closeCompanySummary}
    />
  );
};

export const CustomerSummaryModal = (props: { customerId: string }) => {
  const isOpen = useCustomerSummary();
  return (
    <AnimatePresence>
      {isOpen && <CustomerSummaryModalVisible {...props} />}
    </AnimatePresence>
  );
};

const CustomerSummaryModalVisible = ({ customerId }: { customerId: string }) => {
  const statusCategory = useCustomerStatusCategory();
  const [mutate] = useSafeMutation(GenerateSummaryFromCustomerQuotesDocument);

  const generate = async () => {
    const result = await mutate({
      variables: {
        customerId,
        statusCategory,
      },
    });
    return result.data?.generateSummaryFromCustomerQuotes;
  };

  return (
    <SummaryModal
      generate={generate}
      hide={closeCustomerSummary}
    />
  );
};

const SummaryModal = ({
  generate, hide,
}: {
  generate: () => Promise<string | null | undefined>;
  hide: VoidFunction;
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [mutationId, setMutationId] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const cursorRect = useRef<DOMRect>(document.body.getBoundingClientRect());

  // To control the writing effect
  const [writing, setWriting] = useState(true);
  // Stream of text to be written
  const stream = useRef('');
  // Current index of the stream
  const streamIndex = useRef(0);
  // HTML to be rendered, substring of the stream
  const [html, setHtml] = useState('');

  useSubscription(AiStreamDocument, {
    variables: { productId },
    skip: !mutationId,
    onSubscriptionData({ subscriptionData: { data } }) {
      const aiStream = data?.aiStream;
      if (aiStream?.mutationId !== mutationId) return;
      stream.current += aiStream.stream;
      if (aiStream.progress === 100) {
        setMutationId(null);
      }
    },
  });

  const summarize = async () => {
    setMutationId(null);
    setWriting(false);
    setHtml('');
    stream.current = '';
    streamIndex.current = 0;
    const uuid = await generate();
    if (!uuid) return;
    setMutationId(uuid);
    setWriting(true);
  };

  // Summarize on mount
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    summarize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Displays the text with speed control
  // The html is used as a buffer, as the stream returned by the subscription is too fast
  useInterval(() => {
    if (streamIndex.current >= stream.current.length) {
      if (!mutationId) setWriting(false);
    } else {
      setHtml(stream.current.slice(0, streamIndex.current));
      streamIndex.current += 1;
    }
  }, STREAM_SPEED_INTERVAL, {
    disabled: !writing,
  });

  // Insert cursor after the last element and scroll to it
  useLayoutEffect(() => {
    if (!containerRef.current) return;

    // Remove previous cursor
    const prevCursor = containerRef.current.querySelector('#cursor');
    if (prevCursor) prevCursor.remove();

    const lastElement = findLastElement(containerRef.current);
    if (lastElement) {
      lastElement.insertAdjacentHTML('beforeend', '<mark id="cursor"></mark>');
      const cursor = lastElement.querySelector('#cursor');
      if (!cursor) return;
      cursor.scrollIntoView({ block: 'nearest' });
      cursorRect.current = cursor.getBoundingClientRect();
    }
  }, [html]);

  return (
    <SummaryPortalModal
      layer={Layer.ModalZ3}
      hide={hide}
    >
      <SummaryActions>
        <ActionButton
          disabled={!html || !!mutationId}
          onClick={() => {
            copyToClipboard({
              text: stream.current,
              notification: 'Summary copied to clipboard',
              asHtml: true,
            });
          }}
        >
          <DuplicateIcon />
        </ActionButton>

        <ActionButton
          disabled={writing}
          onClick={summarize}
        >
          <RefreshIcon />
        </ActionButton>

        <ActionButton
          onClick={hide}
        >
          <CloseIcon />
        </ActionButton>
      </SummaryActions>

      <SummaryContainer
        ref={containerRef}
        $writing={writing}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: `<div>${html}</div>` }}
      />

      <Popover
        placement="bottom-start"
        offset={[-10, 5]}
        withPortal={false}
        withWrapper={false}
        visible={writing && !!html}
        getReferenceClientRect={() => cursorRect.current}
        reference={containerRef}
        content={(
          <AiWriting>
            <AiIcon hasGradient />
            Cycle AI is writing
          </AiWriting>
        )}
      />
    </SummaryPortalModal>
  );
};

/** Find the last element in the tree that contains text */
const findLastElement = (element: HTMLElement): HTMLElement => {
  if (element.lastChild?.nodeType === Node.TEXT_NODE) return element;
  if (element.lastChild instanceof HTMLElement) return findLastElement(element.lastChild);
  return element;
};
