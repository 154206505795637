import { Button } from '@cycle-app/ui';
import { FC, FormEvent, useState } from 'react';

import { Buttons, Title, Form } from './MiniForm.styles';

interface Props {
  cancelDisabled?: boolean;
  cancelLabel?: string;
  className?: string;
  isSubmitDisabled?: boolean;
  onCancel?: VoidFunction;
  onSubmit: () => Promise<void>;
  submitLabel?: string;
  title?: string;
}

const MiniForm: FC<React.PropsWithChildren<Props>> = ({
  cancelDisabled,
  cancelLabel = 'Cancel',
  children,
  className,
  isSubmitDisabled = false,
  onCancel,
  onSubmit,
  submitLabel = 'Submit',
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Form onSubmit={submit} className={className}>
      {title && <Title>{title}</Title>}
      {children}
      <Buttons>
        <Button
          type="button"
          variant="secondary"
          size="M"
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          {cancelLabel}
        </Button>
        <Button
          type="submit"
          size="M"
          isLoading={isSubmitting}
          onClick={submit}
          disabled={isSubmitDisabled}
        >
          {submitLabel}
        </Button>
      </Buttons>
    </Form>
  );

  async function submit(e: FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  }
};

export default MiniForm;
