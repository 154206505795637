import { Spinner } from '@cycle-app/ui';
import { CancelCircleIcon } from '@cycle-app/ui/icons';

import { DropIcon } from './Dropzone.styles';

export const DropzoneIcon = ({
  isMaxFilesError, isLoading,
}: {
  isMaxFilesError: boolean;
  isLoading: boolean;
}) => {
  if (isMaxFilesError) return <CancelCircleIcon size={21} />;
  if (isLoading) return <Spinner />;
  return <DropIcon size={21} />;
};
