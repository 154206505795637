import { Layer } from 'src/types/layers.types';

export const mappingZindex: Record<Layer, number> = {
  [Layer.Root]: 0,
  [Layer.DocItemDropdown]: 3,
  [Layer.MaskDocPanel]: 4,
  [Layer.DocPanel]: 5,

  [Layer.Sidebar]: 20,
  [Layer.MaskModal]: 30,

  [Layer.Dropdown]: 80,
  [Layer.DropdownZ1]: 83,
  [Layer.DropdownZ2]: 85,
  [Layer.DropdownBoardConfig]: 90,
  [Layer.DropdownCustomProperty]: 95,
  [Layer.Selection]: 100,

  [Layer.MaskModalOverDropdown]: 110,
  [Layer.MaskCommandbar]: 115,
  [Layer.Modal]: 120,
  [Layer.DropdownModal]: 121,
  [Layer.DropdownModalZ1]: 122,
  [Layer.DropdownModalZ2]: 123,
  [Layer.ModalZ2]: 130,
  [Layer.DropdownModalZ3]: 131,
  [Layer.ModalZ3]: 140,
  [Layer.DropdownModalZ4]: 141,
  [Layer.MaskCommandbar]: 150,
  [Layer.Commandbar]: 160,
  [Layer.DialogModal]: 170,
  [Layer.PreviewModal]: 180,

  [Layer.Toaster]: 200,
  [Layer.Loader]: 200,
  [Layer.ToasterModal]: 210,

  [Layer.LoaderFullpage]: 1000,
  [Layer.ProductTour]: 2000,
  [Layer.Zoom]: 2500,
  [Layer.OfflineWarning]: 3000,
};
