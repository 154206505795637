import { sanitize } from '@cycle-app/utilities';
import { FC } from 'react';
import Highlighter, { HighlighterProps } from 'react-highlight-words';

export const TextHighlighter: FC<React.PropsWithChildren<HighlighterProps>> = (props) => (
  <Highlighter
    autoEscape
    sanitize={sanitize}
    {...props}
  />
);
