import { useRect } from '@cycle-app/utilities';
import { useState } from 'react';
import shallowequal from 'shallowequal';
import { useThrottledCallback } from 'use-debounce';

import { useIsMobile } from '../reactives';

export const useCarousel = () => {
  const isMobile = useIsMobile();

  const [state, setState] = useState({
    isScrollableRight: false,
    isScrollableLeft: false,
  });

  const [container] = useRect(() => {
    checkScroll();
  }, { disabled: isMobile });

  const checkScroll = () => {
    if (isMobile) return;
    const el = container.current;
    if (!el) return;
    const scrollableSpace = (el.scrollWidth ?? 0) - (el.offsetWidth ?? 0);
    const newState = {
      isScrollableRight: el.scrollLeft < scrollableSpace,
      isScrollableLeft: el.scrollLeft > 0,
    };
    if (shallowequal(newState, state)) return;
    setState(newState);
  };

  const handleScroll = useThrottledCallback(checkScroll, 300);

  const handleScrollClick = (direction: 'left' | 'right') => {
    if (!container.current) return;
    const scrollableWidth = container.current.scrollWidth ?? 0;
    const containerWidth = container.current.offsetWidth - 16;
    const scrollableSpace = scrollableWidth - containerWidth;

    if (scrollableSpace <= 0) return;

    const scrollLeft = container.current.scrollLeft ?? 0;
    const distanceToScroll = (containerWidth * 0.8);

    container.current.scroll({
      behavior: 'smooth',
      left: direction === 'left'
        ? scrollLeft - distanceToScroll
        : scrollLeft + distanceToScroll,
    });
  };

  return {
    container,
    handleScroll,
    handleScrollClick,
    isMobile,
    ...state,
  };
};
