import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';

import { BoardEditForm, BoardEditFormProps } from './BoardEditForm';

type EditBoardModalProps = Omit<BoardEditFormProps, 'actions'> & {
  title: string;
  loading?: boolean;
  onHide: VoidFunction;
  showViewEmoji?: boolean;
};

const BoardEditCommonModal: FC<React.PropsWithChildren<EditBoardModalProps>> = ({
  title,
  loading,
  onHide,
  showViewEmoji,
  children,
  ...props
}) => (
  <PortalModalStyled hide={onHide}>
    <Header>
      <Title>{title}</Title>
      <CloseButtonStyled size="L" onClick={onHide}>
        <CloseIcon />
      </CloseButtonStyled>
    </Header>
    <BoardEditForm isIconHidden={!showViewEmoji} {...props}>
      {children}
      <Actions>
        <Button
          type="button"
          size="M"
          variant="secondary"
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="M"
          isLoading={loading}
        >
          Save
        </Button>
      </Actions>
    </BoardEditForm>
  </PortalModalStyled>
);

export default BoardEditCommonModal;
