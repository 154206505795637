import { Button } from '@cycle-app/ui';
import { StarsIcon } from '@cycle-app/ui/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { memo } from 'react';

import { motionProps, variantsContent } from 'src/components/StateToasters';
import { useIsOffline } from 'src/hooks';
import { useInterval } from 'src/hooks/useInterval';
import { useGetNewVersion, setNewVersion } from 'src/reactives/newVersion.reactive';

import { VersionToaster } from './NewVersionToaster.styles';

let buildDate: string;

const DELAY = 5 * 60_000; // 5 minutes

const refresh = () => window.location.reload();

const checkNewVersion = async () => {
  try {
    const build = await fetch('/build.txt');
    const date = await build.text();
    if (buildDate && buildDate !== date) setNewVersion({ hasNewVersion: true });
    buildDate = date;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Error reading build.txt', error);
  }
};

export const NewVersionToaster = memo(() => {
  const { hasNewVersion } = useGetNewVersion();
  const isOffline = useIsOffline();

  useInterval(
    checkNewVersion,
    DELAY,
    { disabled: isOffline || hasNewVersion },
  );

  if (isOffline || !hasNewVersion) return null;

  return (
    <AnimatePresence>
      <motion.div
        variants={variantsContent}
        {...motionProps}
      >
        <VersionToaster>
          <StarsIcon size={16} />

          <p>New version of Cycle is available</p>

          <Button size="L" variant="nospace" onClick={refresh}>
            Refresh
          </Button>
        </VersionToaster>
      </motion.div>
    </AnimatePresence>
  );
});
