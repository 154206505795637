import { UpdateMeDocument, UpdateMeMutationVariables } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

export const useUpdateMe = () => {
  const [updateMeMutation, {
    loading: isLoading, error,
  }] = useSafeMutation(UpdateMeDocument);

  const updateMe = (variables: UpdateMeMutationVariables) => updateMeMutation({ variables });

  return {
    error,
    updateMe,
    isLoading,
  };
};
