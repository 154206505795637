import { ReactNode, MouseEvent, HTMLAttributes, FC } from 'react';

import { CheckboxInput } from '../../Inputs/CheckboxInput/CheckboxInput';
import { EditableText } from '../../Inputs/EditableText/EditableText';
import { ToggleInput } from '../../Inputs/ToggleInput/ToggleInput';
import { Tooltip, TooltipProps } from '../../Tooltip';
import { VariantOption } from '../option.type';
import { Line, LineStart, LineEnd, LineShortcut, Label } from './SelectLine.styles';

export type SelectType = 'toggle' | 'checkbox';

export interface LineProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  isEditable?: boolean;
  startFocused?: boolean;
  hoverDisabled?: boolean;
  placeholder?: string;
  size?: 'S' | 'M';
  checked?: boolean;
  endSlot?: ReactNode;
  shortcut?: string[];
  variant?: VariantOption;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onChangeLabel?: (value: string) => void;
  startOnlyOnHover?: boolean;
  disableHover?: boolean;
  isDragging?: boolean;
  specialLabel?: boolean;
}

export interface SelectLineProps extends LineProps {
  setNodeRef?: (node: HTMLElement | null) => void;
  label?: string | ReactNode;
  startSlot?: ReactNode;
  disableHover?: boolean;
  selectType?: SelectType;
  tooltipProps?: TooltipProps;
}

export interface SelectLineHandle {
  focus: () => void;
  focusOut: () => void;
}

export const SelectLine: FC<React.PropsWithChildren<SelectLineProps>> = ({
  id,
  size = 'M',
  isDisabled,
  isSelected,
  isEditable,
  startFocused,
  hoverDisabled,
  placeholder = '',
  label = '',
  startSlot,
  endSlot,
  shortcut,
  variant,
  onChangeLabel,
  setNodeRef,
  startOnlyOnHover = false,
  disableHover,
  checked,
  selectType = 'toggle',
  tooltipProps,
  onSelect,
  ...lineProps
}) => {
  const Input = selectType === 'toggle' ? ToggleInput : CheckboxInput;

  const line = (
    <Line
      id={id}
      ref={setNodeRef}
      $size={size}
      $isSelected={isSelected}
      $isDisabled={isDisabled}
      $hoverDisabled={hoverDisabled}
      $isEditing={isEditable && !!onChangeLabel}
      $variant={variant}
      startOnlyOnHover={startOnlyOnHover}
      disableHover={disableHover}
      data-disabled={isDisabled}
      {...lineProps}
    >
      {startSlot && <LineStart>{startSlot}</LineStart>}

      {/* eslint-disable-next-line no-nested-ternary */}
      {isEditable && typeof label === 'string' && onChangeLabel
        ? (
          <EditableText
            value={label}
            placeholder={placeholder}
            startInEditMode={startFocused}
            onChange={onChangeLabel}
          />
        )
        : label
          ? <Label onClick={onSelect}>{label}</Label>
          : null}

      {/* Shortcut made because right now toggleInput is only used for multi select panels */}
      {checked !== undefined
        ? (
          <LineEnd>
            <Input
              id={`input-${selectType}-${id}`}
              checked={checked}
              // Change handled by the click on the entire line
              onChange={() => ({})}
              onClick={e => e.stopPropagation()}
              disabled={isDisabled}
            />
          </LineEnd>
        )
        : (
          <>
            {endSlot && <LineEnd>{endSlot}</LineEnd>}
            {shortcut && <LineShortcut keys={shortcut} />}
          </>
        )}
    </Line>
  );

  if (!tooltipProps) return line;

  return (
    <Tooltip
      withWrapper={isDisabled}
      {...tooltipProps}
    >
      {line}
    </Tooltip>
  );
};
