import { ReactNode, forwardRef } from 'react';
import * as Tippy from 'tippy.js';

import { Container } from './Dropdown.styles';
import { Popover, Props as PopoverProps } from '../Popover/Popover';

export type Placement = Tippy.Placement;

export interface DropdownProps extends PopoverProps {
  className?: string;
  content: ReactNode;
  offsetY?: number;
  width?: number;
  children?: ReactNode;
}

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(({
  className,
  content,
  children,
  placement = 'bottom-end',
  disabled = false,
  onMount: onMountProps,
  onHide: onHideProps,
  visible,
  hide,
  offsetY = 4,
  animation = true,
  width,
  ...popoverProps
}, ref) => (
  <Popover
    controlled
    onMount={onMountProps}
    onHide={onHideProps}
    disabled={disabled}
    placement={placement}
    animation={animation}
    interactive
    hide={hide}
    visible={visible}
    offset={[0, offsetY]}
    content={(
      <Container
        ref={ref}
        $width={width}
        className={className}
        onClick={e => e.stopPropagation()}
      >
        {content}
      </Container>
    )}
    {...popoverProps}
  >
    {children}
  </Popover>
));
