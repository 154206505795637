import { getNotionMentionExtension as getExtension } from '@cycle-app/editor-extensions';
import { PluginKey } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';

import { NotionEditorMentionView } from 'src/components/NotionEditorMentionView/NotionEditorMentionView';
import { NotionSearchDropdown } from 'src/components/NotionSearchDropdown/NotionSearchDropdown';
import { COMMANDS } from 'src/constants/editor.constants';
import { renderIntegrationMentionSuggestion } from 'src/utils/integrations.utils';

type NotionMentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

export const getNotionMentionExtension = () => getExtension().extend<NotionMentionOptions>({
  addOptions(): NotionMentionOptions {
    return {
      ...this.parent?.(),
      suggestion: {
        allowSpaces: true,
        char: COMMANDS.NOTION,
        render: () => renderIntegrationMentionSuggestion(this.name, NotionSearchDropdown),
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(NotionEditorMentionView);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${this.name}`),
      }),
    ];
  },
});
