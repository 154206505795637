import { focusEndContenteditable } from '@cycle-app/utilities';
import { useRef, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useUpdateReleaseNote } from 'src/hooks/releases/useUpdateReleaseNote';
import { focusEditor } from 'src/reactives/releaseNote.reactive';

import { EditableTitle } from './ReleaseNoteEditorTitle.styles';

export const ReleaseNoteEditorTitle = ({
  releaseNoteId, isReadOnly, className,
}: {
  releaseNoteId: string;
  isReadOnly: boolean;
  className?: string;
}) => {
  const {
    releaseNote, isReleaseNoteLoading,
  } = useReleaseNote(releaseNoteId);

  // Focus on the editor content when navigating to a release note
  const ref = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    if (!ref.current || isReadOnly) return;
    focusEndContenteditable(ref.current);
  }, [releaseNoteId, isReadOnly]);

  // Update the release note title on change
  const { updateReleaseNote } = useUpdateReleaseNote();
  const onChange = useDebouncedCallback((title: string) => {
    if (!releaseNote) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateReleaseNote(releaseNote, { title });
  }, INPUT_ONCHANGE_DEBOUNCE);

  const [value, setValue] = useState(releaseNote?.title ?? '');

  // Update the title when the release note is updated and the title is not focused
  useEffect(() => {
    if (document.activeElement === ref.current) return;
    setValue(releaseNote?.title ?? '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseNote?.title]);

  // Update the title when navigating to a release note
  useEffect(() => {
    setValue(releaseNote?.title ?? '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseNoteId, isReleaseNoteLoading]);

  if (isReleaseNoteLoading) return null;

  return (
    <EditableTitle
      ref={ref}
      tag="h1"
      placeholder="Your release note title"
      value={value}
      isDisabled={isReadOnly}
      onNext={focusEditor}
      onEnter={focusEditor}
      onChange={onChange}
      className={className}
    />
  );
};
