import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useProduct } from 'src/hooks';
import { SectionParent, getSectionType } from 'src/utils/section.utils';

export const useSectionBoards = (sectionParent: SectionParent) => {
  const { product } = useProduct();
  return useMemo(() => {
    const node = product?.boardSections.edges.find(e => e.node.type === getSectionType(sectionParent))?.node;
    return {
      sectionId: node?.id,
      boards: nodeToArray(node?.boards),
    };
  }, [product, sectionParent]);
};
