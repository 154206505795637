import styled from 'styled-components';

import DocParent from '../DocParent/DocParent';
import { SearchResults } from '../DocSearchDropdown/DocSearchDropdown.styles';

interface ContainerProps {
  $isListing?: boolean;
}

export const PARENT_MAX_WIDTH = 215;

export const Container = styled.div<ContainerProps>`
  > [data-popover] {
    display: flex;
    ${p => p.$isListing && `max-width: ${PARENT_MAX_WIDTH}px;`}
  }
`;

export const StyledDocParent = styled(DocParent)`
  background: ${p => p.theme.colors.tag.bg};
`;

export const StyledSearchResults = styled(SearchResults)`
  padding-top: 8px;
`;
