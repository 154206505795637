import { HeroSection, ContentSection, Typography } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { Controller } from 'react-hook-form';

import { ChangelogBuilderSelect } from './ChangelogBuilderSelect';
import { Section } from './ChangelogBuilderSidebar.styles';
import { ContentLabel, ContentSelectContainer } from './ChangelogBuilderTypography.styles';
import { LabelWithReset } from './LabelWithReset';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';

const typoOptions: SelectOption[] = [
  {
    value: Typography.Inter,
    label: 'Inter',
  },
  {
    value: Typography.Montserrat,
    label: 'Montserrat',
  },
  {
    value: Typography.Roboto,
    label: 'Roboto',
  },
  {
    value: Typography.SourceSans_3,
    label: 'Source Sans 3',
  },
  {
    value: Typography.Lora,
    label: 'Lora',
  },
  {
    value: Typography.Merriweather,
    label: 'Merriweather',
  },
  {
    value: Typography.PlayfairDisplay,
    label: 'Playfair Display',
  },
  {
    value: Typography.LibreBaskerville,
    label: 'Libre Baskerville',
  },
  {
    value: Typography.WorkSans,
    label: 'Work Sans',
  },
  {
    value: Typography.Hind,
    label: 'Hind',
  },
  {
    value: Typography.Mulish,
    label: 'Mulish',
  },
  {
    value: Typography.JetbrainsMono,
    label: 'Jetbrains Mono',
  },
  {
    value: Typography.Asap,
    label: 'Asap',
  },
  {
    value: Typography.Sora,
    label: 'Sora',
  },
  {
    value: Typography.Manrope,
    label: 'Manrope',
  },
  {
    value: Typography.DmSans,
    label: 'DM Sans',
  },
].sort((a, b) => a.value.localeCompare(b.value));

const heroOptions: SelectOption[] = [
  {
    value: HeroSection.S,
    label: 'Small',
  },
  {
    value: HeroSection.M,
    label: 'Medium',
  },
  {
    value: HeroSection.L,
    label: 'Large',
  },
  {
    value: HeroSection.Xl,
    label: 'Extra large',
  },
];

const contentOptions: SelectOption[] = [
  {
    value: ContentSection.Classic,
    label: 'Classic',
  },
  {
    value: ContentSection.Discreet,
    label: 'Discreet',
  },
  {
    value: ContentSection.ShowOff,
    label: 'Show-off',
  },
  {
    value: ContentSection.Sober,
    label: 'Sober',
  },
  {
    value: ContentSection.Uniform,
    label: 'Uniform',
  },
  {
    value: ContentSection.Unique,
    label: 'Unique',
  },
];

export const ChangelogBuilderTypography = () => {
  const {
    control, watch, formState, resetField,
  } = useChangelogBuilderFormContext();
  const heroSection = watch('heroSection');
  const typography = watch('typography');
  const contentSection = watch('contentSection');
  const isTextDirty =
    heroSection !== formState.defaultValues?.heroSection ||
    contentSection !== formState.defaultValues?.contentSection;
  return (
    <>
      <Section>
        <LabelWithReset
          label="Typography"
          showReset={typography !== formState.defaultValues?.typography}
          reset={() => resetField('typography')}
        />
        <div className="mt-2">
          <Controller
            control={control}
            name="typography"
            render={({ field }) => (
              <ChangelogBuilderSelect
                value={field.value}
                onChange={option => field.onChange(option.value)}
                options={typoOptions}
              />
            )}
          />
        </div>
      </Section>

      <Section>
        <LabelWithReset
          label="Text"
          showReset={isTextDirty}
          reset={() => {
            resetField('heroSection');
            resetField('contentSection');
          }}
        />
        <div className="mt-3">
          <div className="flex items-center justify-between">
            <ContentLabel>Hero section</ContentLabel>
            <ContentSelectContainer>
              <Controller
                control={control}
                name="heroSection"
                render={({ field }) => (
                  <ChangelogBuilderSelect
                    value={field.value}
                    onChange={option => field.onChange(option.value)}
                    options={heroOptions}
                  />
                )}
              />
            </ContentSelectContainer>
          </div>
          <div className="mt-2 flex items-center justify-between">
            <ContentLabel>Content</ContentLabel>
            <ContentSelectContainer>
              <Controller
                control={control}
                name="contentSection"
                render={({ field }) => (
                  <ChangelogBuilderSelect
                    value={field.value}
                    onChange={option => field.onChange(option.value)}
                    options={contentOptions}
                  />
                )}
              />
            </ContentSelectContainer>
          </div>
        </div>
      </Section>
    </>
  );
};
