import { UpdateWebhookDocument, UpdateWebhookMutation, UpdateWebhookMutationVariables, WebhookFragment } from '@cycle-app/graphql-codegen';
import castArray from 'lodash/castArray';
import { isPresent } from 'ts-is-present';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateWebhook = () => {
  const [updateClient] = useSafeMutation(UpdateWebhookDocument);
  return (variables: Omit<UpdateWebhookMutationVariables, 'id'>, webhook: WebhookFragment) => updateClient({
    variables: {
      id: webhook.id,
      ...variables,
    },
    optimisticResponse: getOptimisticResponse(variables, webhook),
  });
};

const getOptimisticResponse = (variables: Omit<UpdateWebhookMutationVariables, 'id'>, webhook: WebhookFragment): UpdateWebhookMutation => {
  const updateWebhook = { ...webhook };
  if ('url' in variables) updateWebhook.url = variables.url ?? '';
  if ('label' in variables) updateWebhook.label = variables.label;
  if ('ressources' in variables) updateWebhook.ressources = castArray(variables.ressources).filter(isPresent);
  return { updateWebhook };
};
