import { useGetIntegrationSyncModal } from 'src/reactives/integrationSyncModal.reactive';

import { IntegrationSyncModalContent } from './IntegrationSyncModalContent';

export const IntegrationSyncModal = () => {
  const { integrationType } = useGetIntegrationSyncModal();
  return integrationType && (
    <IntegrationSyncModalContent integrationType={integrationType} />
  );
};
