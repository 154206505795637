import { Axis, Rect, Selectable } from 'src/types/rectangle.types';

export const getArraySelectable = (targetSelector: string): Selectable[] => {
  const elements = document.querySelectorAll(targetSelector);
  return Array.from(elements).map((el) => {
    const id = el.getAttribute('data-id') as string;
    const groupId = el.getAttribute('data-groupid') as string;
    const {
      top, left, width, height,
    } = el.getBoundingClientRect();
    return {
      id,
      groupId,
      top,
      left,
      width,
      height,
    };
  });
};

export const getRectangle = (axisOrigin: Axis, axisTarget: Axis): Rect => {
  const {
    x: x1, y: y1,
  } = axisOrigin;
  const {
    x: x2, y: y2,
  } = axisTarget;
  return {
    top: y1 > y2 ? y2 : y1,
    left: x1 > x2 ? x2 : x1,
    width: x1 > x2 ? x1 - x2 : x2 - x1,
    height: y1 > y2 ? y1 - y2 : y2 - y1,
  };
};
