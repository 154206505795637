import { StatusCategory } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { AnimatePresence, motion } from 'framer-motion';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useExtractQuotesContext } from 'src/contexts/extractQuotesContext';
import { openVerifyQuotes, useVerifyQuotesOpen } from 'src/reactives/docRightPanel.reactive';
import { useSummaryVisible } from 'src/reactives/summaryVisible.reactive';

import { getExtractDisabledMessage } from '../QuotesSummary';
import { Container, Content, RetryButton } from './DocStateLoaderQuotes.styles';

export const DocStateLoaderQuotes = () => {
  const isVerifyQuotesOpen = useVerifyQuotesOpen();
  const isSummaryVisible = useSummaryVisible();
  return (
    <AnimatePresence>
      {!isVerifyQuotesOpen && !isSummaryVisible && (
        <motion.div
          style={{ zIndex: 'inherit' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <DocStateLoaderQuotesContent />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const DocStateLoaderQuotesContent = () => {
  const {
    status, extractQuotes, quotes,
  } = useExtractQuotesContext();

  const docStatusCategory = useDocPanelContext(ctx => ctx.doc?.status?.category);
  const isExtractDisabled = docStatusCategory === StatusCategory.Completed || docStatusCategory === StatusCategory.Canceled;

  if (status === 'loading') {
    return (
      <Container $isLoading>
        <Content>
          <AiIcon size={14} isAnimated />
          Looking for quotes…
        </Content>
      </Container>
    );
  }

  if (status === 'verify') {
    return (
      <Container onClick={openVerifyQuotes}>
        <Content>
          <AiIcon size={14} />
          {counterLabel(quotes.length)}
        </Content>
      </Container>
    );
  }

  if (status === 'verified') {
    return (
      <Tooltip
        withPortal
        withWrapper={false}
        placement="top"
        content={isExtractDisabled
          ? 'Quote extraction is disabled when feedback is processed'
          : 'Remove existing AI-generated quotes if you want to extract new ones'}
      >
        <Container $isDisabled>
          <Content>
            <AiIcon size={14} />
            No quote to verify
          </Content>
        </Container>
      </Tooltip>
    );
  }

  if (status === 'retry') {
    return (
      <Container $isDisabled>
        <Content>
          <AiIcon size={14} />
          No quote to verify
          <RetryButton onClick={extractQuotes}>
            Retry
          </RetryButton>
        </Content>
      </Container>
    );
  }

  if (status === 'extract') {
    return (
      <Tooltip
        withPortal
        withWrapper={false}
        placement="top"
        content={getExtractDisabledMessage(docStatusCategory)}
        disabled={!isExtractDisabled}
      >
        <Container
          onClick={isExtractDisabled ? undefined : extractQuotes}
          $isDisabled={isExtractDisabled}
        >
          <Content>
            <AiIcon size={14} />
            Extract quotes
          </Content>
        </Container>
      </Tooltip>
    );
  }

  return null;
};

const counterLabel = (count = 0) => {
  if (count === 0) return 'No quote to verify';
  if (count === 1) return '1 quote to verify';
  return `${count} quotes to verify`;
};
