import { DocCommentFragment } from '@cycle-app/graphql-codegen';
import { distanceToNow } from '@cycle-app/utilities';
import { ReactNode, useEffect, useRef } from 'react';

import { CommentEditor } from 'src/components/Editor/Editors/CommentEditor';
import { setComments, useGetComments } from 'src/reactives/comments.reactive';
import { setCommentNavigation, useGetCommentNavigation } from 'src/reactives/notifications.reactive';

import { CommentContainer, Comment, Content, Info, UserAvatar } from './DocComment.styles';
import { EditComment } from './EditComment';

export const DocComment = ({
  docId, comment, menu,
}: {
  docId: string;
  comment: DocCommentFragment;
  menu: ReactNode;
}) => {
  const { editorCommentId } = useGetCommentNavigation();
  const ref = useRef<HTMLDivElement>(null);

  // Focus and scroll to the comment when opening the doc from a notification
  useEffect(() => {
    if (editorCommentId === comment.id && ref.current) {
      setCommentNavigation({ editorCommentId: undefined });
      setComments({ focusedId: comment.id });
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    editingId, focusedId,
  } = useGetComments();

  const isEditing = editingId === comment.id;
  const isFocused = focusedId === comment.id;

  return (
    <CommentContainer
      ref={ref}
      className={isFocused ? 'force-hover' : ''}
      sending={comment._sending ?? false}
    >
      <Comment>
        <UserAvatar user={comment.creator} size={16} />
        <Content>
          <Info>
            <strong>{`${comment.creator.firstName} ${comment.creator.lastName}`}</strong>
            <small>{distanceToNow(comment.createdAt)}</small>
            {menu}
          </Info>

          {!isEditing && <CommentEditor content={comment.content} isReadOnly />}
        </Content>
      </Comment>

      {isEditing && (
        <EditComment
          docId={docId}
          comment={comment}
          close={() => setComments({ editingId: null })}
        />
      )}
    </CommentContainer>
  );
};
