import { useQuery } from '@apollo/client';
import { ProductDoctypesDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { extract } from 'src/types/graphql.types';
import { findFeedback, findInsight } from 'src/utils/docType.util';

import { useProduct, useProductBase } from './useProduct';
/**
 * If you don't need doctypes hierarchy (children/parents) or attributeDefinitions
 * just use doctypes from product (useProduct hook)
 */
export const useProductDoctypesFull = () => {
  const product = useProductBase();
  const {
    data, loading,
  } = useQuery(ProductDoctypesDocument, {
    skip: !product?.id,
    variables: {
      productId: product?.id ?? '',
    },
  });

  const doctypes = useMemo(() => {
    return nodeToArray(extract('Product', data?.node)?.doctypes);
  }, [data]);

  return {
    productId: product?.id,
    productSlug: product?.slug,
    doctypesConnection: extract('Product', data?.node)?.doctypes,
    doctypes,
    isLoading: loading,
  };
};

export const useProductDoctypes = () => {
  const { product } = useProduct();
  return nodeToArray(product?.doctypes);
};

export const useFeedbackDocType = () => {
  const docTypes = useProductDoctypes();
  return findFeedback(docTypes);
};

export const useInsightDocType = () => {
  const docTypes = useProductDoctypes();
  return findInsight(docTypes);
};
