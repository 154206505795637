import { ApolloError, useQuery } from '@apollo/client';
import {
  InitialBoardDocument,
  InitialBoardAndDocDocument,
  InitialDocOnlyDocument,
  MeFragment,
} from '@cycle-app/graphql-codegen';
import { useRef, useEffect, useState } from 'react';

import { useAppContext, useBoardId, useDocId, useProductSlug } from 'src/hooks/usePathParams';
import { getAuth } from 'src/reactives/auth.reactive';
import { useInitialState } from 'src/reactives/initial.reactive';
import { defaultPagination } from 'src/utils/pagination.util';

export const useInitialQuery = () => {
  const { userId } = getAuth();
  const [{ loading: loadingState }] = useInitialState();
  const productSlug = useProductSlug();
  const boardId = useBoardId();
  const docId = useDocId();
  const context = useAppContext();

  const skipQuery = !userId || !loadingState;

  const initialBoardRes = useQuery(InitialBoardDocument, {
    skip: context !== 'specific-board' || skipQuery,
    variables: {
      id: userId as string,
      productSlug,
      boardId,
      ...defaultPagination,
    },
    errorPolicy: 'all',
  });

  const initialBoardAndDocRes = useQuery(InitialBoardAndDocDocument, {
    skip: context !== 'specific-board-doc-panel' || skipQuery,
    variables: {
      id: userId as string,
      productSlug,
      boardId,
      docId,
      ...defaultPagination,
    },
    errorPolicy: 'all',
  });

  const initialDocOnlyRes = useQuery(InitialDocOnlyDocument, {
    skip: context !== 'doc-fullpage' || skipQuery,
    variables: {
      id: userId as string,
      productSlug,
      docId,
    },
    errorPolicy: 'all',
  });

  const data =
    initialBoardAndDocRes.data ??
    initialBoardRes.data ??
    initialBoardAndDocRes.data ??
    initialDocOnlyRes.data;

  const productWithSlugFromUrl =
    initialBoardAndDocRes.data?.product ??
    initialBoardRes.data?.product ??
    initialBoardAndDocRes.data?.product ??
    initialDocOnlyRes.data?.product;

  const hasError = useHandleInitialErrors([
    initialBoardRes.error,
    initialBoardAndDocRes.error,
    initialDocOnlyRes.error,
  ]);

  const initialMe = useRef<{ me: MeFragment; productSlug: string | undefined } | null>();
  if (!initialMe.current && data?.me?.__typename === 'Me' && productWithSlugFromUrl) {
    initialMe.current = {
      me: data.me,
      productSlug: productWithSlugFromUrl?.slug,
    };
  }

  return {
    productId: productWithSlugFromUrl?.id,
    productSlug: productWithSlugFromUrl?.slug,
    hasError,
    initialMe: initialMe.current,
  };
};

const useHandleInitialErrors = (maybeErrors: Array<ApolloError | undefined>) => {
  const nbErrors = maybeErrors.filter(err => err !== undefined).length;
  const [hasError, setHasError] = useState(false);

  // Update hasError state only if error === true
  // because useQuery is skipped then and error re-pass to `false`
  useEffect(() => {
    if (nbErrors > 0) {
      setHasError(true);
    }
  }, [nbErrors]);

  return hasError;
};
