import { Reference } from '@apollo/client';
import { ReleaseNoteTagAddDocument, ReleaseNoteTagRemoveDocument } from '@cycle-app/graphql-codegen';

import { useSafeMutation } from '..';
import { getPermission, setLimitationsModal } from '../../reactives';

export const useReleaseNoteTagUpdate = () => {
  const [addMutation] = useSafeMutation(ReleaseNoteTagAddDocument);
  const [removeMutation] = useSafeMutation(ReleaseNoteTagRemoveDocument);

  const addTag = async ({
    releaseNoteId, tagId,
  }: { releaseNoteId: string; tagId: string }) => {
    if (!getPermission().canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return;
    }
    await addMutation({
      variables: {
        releaseNoteId,
        tagId,
      },
      optimisticResponse: {
        addReleaseNoteTag: {
          id: releaseNoteId,
        },
      },
      update: (cache) => {
        cache.modify({
          id: releaseNoteId,
          fields: {
            tags: (currentTags: Reference[], { toReference }) => {
              return [
                ...currentTags,
                toReference(tagId),
              ];
            },
          },
        });
      },
    });
  };

  const removeTag = async ({
    releaseNoteId, tagId,
  }: { releaseNoteId: string; tagId: string }) => {
    if (!getPermission().canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return;
    }
    await removeMutation({
      variables: {
        releaseNoteId,
        tagId,
      },
      optimisticResponse: {
        removeReleaseNoteTag: {
          id: releaseNoteId,
        },
      },
      update: (cache) => {
        cache.modify({
          id: releaseNoteId,
          fields: {
            tags: (currentTags: Reference[], { readField }) => {
              return currentTags.filter(tag => readField('id', tag) !== tagId);
            },
          },
        });
      },
    });
  };

  return {
    addTag,
    removeTag,
  };
};
