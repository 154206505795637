import { Container, AvatarSkeleton, Content, LineSkeleton } from './NotificationCardSkeleton.styles';

export const NotificationCardSkeleton = () => (
  <Container>
    <AvatarSkeleton />
    <Content>
      <LineSkeleton style={{ width: '90%' }} />
      <LineSkeleton style={{ width: '60%' }} />
      <LineSkeleton style={{ width: '70%' }} />
    </Content>
  </Container>
);
