import { useSyncExternalStore } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { history } from 'src/providers';
import { getPageIdFromPathname, getParentFromPathname } from 'src/utils/routing.utils';

export { PageId };

/**
 * Hook to get the current pageId with optional selector
 * @example const pageId = usePageId()
 * @example const docPageId = usePageId(pageId => [PageId.InboxDoc, PageId.InsightDoc, PageId.RoadmapDoc].includes(pageId));
 */
export function usePageId(): PageId;
export function usePageId<T>(selector: (pageId: PageId) => T): T;
export function usePageId<T>(selector?: (pageId: PageId) => T): T | PageId {
  return useSyncExternalStore(
    history.listen,
    () => {
      const pageId = getPageIdFromPathname(history.location.pathname);
      return selector ? selector(pageId) : pageId;
    },
  );
}

/**
 * Hook to get the current parent page with optional selector
 */
export function useParentPage(): string;
export function useParentPage<T>(selector: (parentPage: string) => T): T;
export function useParentPage<T>(selector?: (parentPage: string) => T): T | string {
  return useSyncExternalStore(
    history.listen,
    () => {
      const parentPage = getParentFromPathname(history.location.pathname);
      return selector ? selector(parentPage) : parentPage;
    },
  );
}

export const useIsDocPanelPage = () => usePageId(pageId => [PageId.InboxDoc, PageId.InsightDoc, PageId.RoadmapDoc].includes(pageId));
