import { Tooltip } from '@cycle-app/ui';
import { ReactNode } from 'react';

import { copyToClipboard } from 'src/utils/clipboard.utils';

type Props = {
  title: ReactNode;
  value: string | number;
  notification?: string;
  children: (props: {
    onClick: VoidFunction;
  }) => ReactNode;
};

export const TooltipClickToCopy = ({
  title,
  value,
  notification = `${title} successfully copied to clipboard`,
  children,
}: Props) => {
  return (
    <Tooltip
      title={title}
      content="Click to copy"
      placement="top"
      withPortal
      withWrapper={false}
    >
      {children({
        onClick: () => {
          copyToClipboard({
            text: value.toString(),
            notification,
          });
        },
      })}
    </Tooltip>
  );
};
