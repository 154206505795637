import { LinearTeamBaseFragment } from '@cycle-app/graphql-codegen';
import { CaretIcon } from '@cycle-app/ui/icons';
import { useEffect, FC, useMemo } from 'react';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { isLinearTeam } from 'src/utils/integrations.utils';

import { TeamSelect, LoadingState } from './LinearTeamsSearchDropdown.styles';

type Props = {
  selectedTeamId: string;
  isDisabled: boolean;
  onSelect: (repository: LinearTeamBaseFragment) => void;
};

export const LinearTeamsSearchDropdown: FC<React.PropsWithChildren<Props>> = ({
  onSelect, selectedTeamId, isDisabled,
}) => {
  const {
    teams, isLoading,
  } = useLinearTeams();
  const [isDropdownVisible, {
    toggleCallback: onToggleDropdown,
    setFalseCallback: onHideDropdown,
  }] = useOptimizedBooleanState(false);

  const options = useMemo(() => {
    if (!teams?.length) return [];

    return teams
      .filter(isLinearTeam)
      .map(team => ({
        value: team.id,
        label: team.name,
      }));
  }, [teams]);

  const selectedTeam = teams && selectedTeamId
    ? teams.find(r => r?.id === selectedTeamId)
    : undefined;

  useEffect(() => {
    if (selectedTeamId || !teams?.[0]?.id) return;

    onSelect(teams[0]);
  }, [onSelect, teams, selectedTeamId]);

  if (isLoading) return renderSelectButton();

  return (
    <DropdownSelectLayer
      layer={Layer.DropdownBoardConfig}
      placement="bottom"
      options={options}
      visible={isDropdownVisible}
      hide={onHideDropdown}
      onChange={(option) => {
        const repo = teams?.find(r => r?.id === option.value);

        if (repo && !isDisabled) {
          onSelect(repo);
          onHideDropdown();
        }
      }}
    >
      {renderSelectButton()}
    </DropdownSelectLayer>
  );

  function renderSelectButton() {
    return (
      <TeamSelect onClick={() => {
        if (isDisabled) return;
        onToggleDropdown();
      }}
      >
        {(isLoading || !selectedTeam?.name)
          ? <LoadingState />
          : (
            <>
              {selectedTeam?.name}
              <CaretIcon />
            </>
          )}
      </TeamSelect>
    );
  }
};
