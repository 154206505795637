import { gql } from '@apollo/client';
import {
  InviteProductUserDocument,
  MateBaseFragment,
  ProductBySlugDocument,
  RemoveUserFromProductDocument,
  Role,
  UpdateDefaultAssigneeDocument,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import omit from 'lodash/omit';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { addToaster } from 'src/utils/toasters.utils';
import { updateQuery } from 'src/utils/update-cache/update-query.util';

import { useCurrentBilling } from '../..';

export default function useProductMembersMutations() {
  const { product } = useProduct();
  const currentBilling = useCurrentBilling();

  const [addUserMutation, { loading: isAddUserLoading }] = useSafeMutation(InviteProductUserDocument);
  const [updateUserDefaultAssigneeMutation, { loading: isUpdateUserDefaultAssigneeLoading }] = useSafeMutation(UpdateDefaultAssigneeDocument);
  const [removeUserMutation, { loading: isRemoveUserLoading }] = useSafeMutation(RemoveUserFromProductDocument, {
    onCompleted: () => trackAnalytics(Events.UserDeleted),
  });

  const addUser = useCallback((email: string, role: Role) => {
    if (!product?.id) return null;

    return addUserMutation({
      variables: {
        productId: product.id,
        email,
        role,
      },
      errorPolicy: 'all',
      update(cache, { data }) {
        if (!data?.inviteProductUser || !product) return;

        const isUserAlreadyInvited =
        product.users.edges.some(user => user.node.id === data.inviteProductUser?.id) ||
        product.notSignedUpUsers.edges.some(user => user.node.id === data.inviteProductUser?.id);

        if (isUserAlreadyInvited) return;

        if (currentBilling && role === Role.Maker) {
          const billing = cache.identify(currentBilling);
          if (billing) {
            cache.modify({
              id: billing,
              fields: {
                nbMakers: (currentMakers) => currentMakers + 1,
              },
            });
          }
        }

        updateQuery({
          query: ProductBySlugDocument,
          variables: {
            slug: product.slug,
          },
          update: (draft) => {
            draft.product?.notSignedUpUsers.edges.push({
              node: {
                ...omit(data.inviteProductUser, ['productRole', '_compatibleWithBoardConfig', '_boardId', '_docId']),
                role: Role.User,
              },
            });
          },
        });
      },
    });
  }, [addUserMutation, product, currentBilling]);

  const removeUser = useCallback((userId: string, role: Role) => {
    if (!product?.id) return null;
    const isDefaultAssignee = userId === product.defaultAssignee?.id;
    return removeUserMutation({
      variables: {
        userId,
        productId: product.id,
      },
      update(cache, { data }) {
        if (!data?.removeUserFromProduct) return;
        if (currentBilling && role === Role.Maker) {
          const billing = cache.identify(currentBilling);
          if (billing) {
            cache.modify({
              id: billing,
              fields: {
                nbMakers: (currentMakers) => currentMakers - 1,
              },
            });
          }
        }
        if (isDefaultAssignee) {
          // If we remove a default assignee, the first member of a product is the new one.
          const firstUser = [
            ...nodeToArray(product?.users),
            ...nodeToArray(product?.notSignedUpUsers),
          ].find(user => user.id !== userId);
          if (firstUser) {
            cache.modify({
              id: cache.identify(product),
              fields: {
                defaultAssignee: () => {
                  return cache.writeFragment({
                    fragment: gql`fragment Mate on User { id }`,
                    data: { id: firstUser.id },
                  });
                },
              },
            });
          }
        }
        cache.evict({ id: cache.identify(data.removeUserFromProduct) });
        cache.gc();
      },
    });
  }, [removeUserMutation, currentBilling, product]);

  const updateUserDefaultAssignee = (user: Pick<MateBaseFragment, 'id' | 'firstName' | 'lastName'>) => {
    if (!product?.id) return null;
    return updateUserDefaultAssigneeMutation({
      variables: {
        userId: user.id,
        productId: product.id,
      },
      update: (_, { data }) => {
        if (!data?.updateDefaultAssignee?.id) return;
        addToaster({
          message: `${user.firstName} ${user.lastName} is now the default assignee`,
        });
      },
    });
  };

  return {
    addUser,
    removeUser,
    updateUserDefaultAssignee,
    isAddUserLoading,
    isRemoveUserLoading,
    isUpdateUserDefaultAssigneeLoading,
  };
}
