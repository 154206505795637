import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { QuickIntegrationPanel } from './QuickIntegrationPanel';
import { QuickIntegrationsByType } from './QuickIntegrations.types';

type Props = {
  integrations: QuickIntegrationsByType;
  children: ToggleDropdownProps['button'];
};

export const QuickIntegrationDropdown = ({
  children, integrations,
}: Props) => (
  <ToggleDropdown
    placement="bottom-start"
    layer={Layer.DropdownModalZ3}
    button={children}
    content={props => (
      <QuickIntegrationPanel
        {...props}
        integrations={integrations}
      />
    )}
  />
);
