import { Button, typo } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

export const StyledButton = styled(Button).attrs({ variant: 'light' })<{ $isSmall?: boolean }>`
  ${typo.body500};
  --color: ${p => p.theme.colors.text.secondary};
  height: 28px;
  padding: 0 8px;
  border-radius: 8px;
  --bg: transparent;
  :hover, :focus, &[data-active="true"]  {
    --bg: ${p => (p.theme.isDark ? ColorTheme.Grey900 : ColorTheme.Grey200)};
    --color: ${p => p.theme.colors.text.primary};
  }
  :active {
    --bg: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey300)};
    --color: ${p => p.theme.colors.text.primary};
  }
  ${p => p.$isSmall && `
    width: 28px;
    height: 24px;
    padding: 0;
  `}
`;
