import { ShyScrollbarCss, Input } from '@cycle-app/ui';
import { small } from '@cycle-app/utilities';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const Modal = styled(PortalModal)`
  padding: 0;
  width: 1126px;
  display: grid;
  grid-template-columns: 474px 1fr;
  grid-template-rows: 100%;

  ${small} {
    display: block;
  }
`;

export const Content = styled.div`
  ${ShyScrollbarCss}
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

export const Preview = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.boardConfig.preview.bg};
  display: flex;
  padding: 0 60px;
  justify-content: center;

  ${small} {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const InputField = styled(Input)`
  width: 100%;
`;
