import { DropdownCss, ShyScrollbar } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled(ShyScrollbar)`
  ${DropdownCss};

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  
  width: 250px;
  max-height: 226px;
  overflow: auto;
  overflow: overlay; /* Wekbit and Blink based browsers */
`;
