import { motion } from 'framer-motion';
import { ComponentProps, FC } from 'react';

type Props = ComponentProps<typeof motion.div> & {
  isDisabled?: boolean;
};

export const HiddenAction: FC<React.PropsWithChildren<Props>> = ({
  children, isDisabled, ...props
}) => (
  <motion.div
    {...props}
    variants={{
      rest: { opacity: isDisabled ? 1 : 0 },
      hover: { opacity: 1 },
    }}
    transition={{
      duration: 0.2,
    }}
  >
    {children}
  </motion.div>
);
