import { MotionProps, Variants, MotionConfig, AnimationDefinition } from 'framer-motion';
import { RefObject, FC } from 'react';

import { motionProps as defaultMotionProps, variantsContent, variantsMask } from './Modal.motion';
import { Container, Mask, Content } from './Modal.styles';
import type { ModalPlacement } from './Modal.styles';

export interface ModalProps {
  animate?: boolean;
  className?: string;
  contentRef?: RefObject<HTMLDivElement>;
  hide?: () => void;
  invisibleMask?: boolean;
  isFullOnMobile?: boolean;
  maskZindex?: number;
  motionProps?: MotionProps;
  motionVariants?: Variants;
  naked?: boolean;
  noMask?: boolean;
  onAnimationComplete?: (definition: AnimationDefinition) => void;
  placement?: ModalPlacement;
  tabIndex?: number;
  zIndex: number;
}

export const Modal: FC<React.PropsWithChildren<ModalProps>> = ({
  animate = true,
  children,
  className,
  contentRef,
  hide,
  invisibleMask,
  isFullOnMobile = false,
  maskZindex,
  motionProps = defaultMotionProps,
  motionVariants,
  naked = false,
  noMask = false,
  onAnimationComplete,
  placement = 'center',
  tabIndex,
  zIndex,
}) => {
  const content = (
    <Content
      ref={contentRef}
      className={className}
      tabIndex={tabIndex}
      variants={motionVariants ?? variantsContent(!naked)}
      $naked={naked}
      $noMask={noMask}
      $zIndex={zIndex}
      {...animate && motionProps}
      onAnimationComplete={onAnimationComplete}
      $isFullOnMobile={isFullOnMobile}
    >
      {children}
    </Content>
  );

  return (
    <MotionConfig reducedMotion="user">
      {noMask
        ? content
        : (
          <Container
            placement={placement}
            zIndex={maskZindex ?? zIndex}
          >
            {maskZindex !== undefined && (
              <Mask
                onClick={hide}
                variants={variantsMask}
                $visible={!invisibleMask}
                {...!invisibleMask && animate && motionProps}
              />
            )}
            {content}
          </Container>
        )}
    </MotionConfig>
  );
};

export { ModalTitle } from './Modal.styles';
