import { OfflineToaster } from '@cycle-app/ui';
import styled, { css, keyframes } from 'styled-components';

const shake = keyframes`
  0% { transform: translateX(1px) }
  10% { transform: translateX(-1px) }
  20% { transform: translateX(-3px) }
  30% { transform: translateX(3px) }
  40% { transform: translateX(1px) }
  50% { transform: translateX(-1px) }
  60% { transform: translateX(-3px) }
  70% { transform: translateX(3px) }
  80% { transform: translateX(-1px) }
  90% { transform: translateX(1px) }
  100% { transform: translateX(1px) }
`;

export const Toaster = styled(OfflineToaster) <{ $hasShake: boolean }>`
 ${p => p.$hasShake && css`
    animation: ${shake} 0.2s;
    animation-iteration-count: 2;
  `}
`;
