import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { ImageMenu } from 'src/components/ImageMenu';

export const Cover = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${mobile} {
    border-radius: 0px;
  }
`;

export const CoverContainer = styled.div<{ withNonConsistentDesign?: boolean }>`
  position: relative;
  margin: 0 24px 24px;
  height: 200px;
  &:hover .image-menu {
    opacity: 1;
  }

  ${p => p.withNonConsistentDesign && css`
    margin: 10px 0 36px 0;
    padding: 0 120px;
    background-color: ${p.theme.colors.background.grey};
    ${Cover} {
      border-radius: 0;
    }
  `};
`;

export const StyledImageMenu = styled(ImageMenu)`
  ${mobile} {
    display: none;
  }
`;
