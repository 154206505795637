import styled from 'styled-components';

export const Field = styled.div`
  background-color: ${({ theme }) => theme.colors.input.bg};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  width: 100%;
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  font-size: 15px;
  line-height: 22px;
  min-height: 40px;
`;
