import { LearnMoreButton } from 'src/components/Integrations/LearnMoreButton';
import { COMMANDS } from 'src/constants/editor.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';

import {
  IconContainer, Panel, PanelItem, ItemLabel, ItemTitle, ItemDesc, InstallButton,
} from './QuickIntegrations.styles';
import { QuickIntegrationsByType } from './QuickIntegrations.types';

type Props = {
  integrations: QuickIntegrationsByType;
  hide: VoidFunction;
};

export const QuickIntegrationPanel = ({
  hide, integrations,
}: Props) => {
  const install = useInstallIntegration();
  const editor = useEditorContext(ctx => ctx.editor);
  return (
    <Panel>
      {Object.values(integrations).map(item => (
        <PanelItem
          key={item.integration.id}
          $disabled={!item.isInstalled}
          onClick={() => {
            if (!item.isInstalled || !item.data.commandKey) return;
            const { commandKey } = item.data;
            if (commandKey === 'LINEAR' || commandKey === 'LINEAR_PROJECT') {
              editor.chain().focus().insertContent('/Linear').run();
            } else {
              editor.chain().focus().insertContent(COMMANDS[commandKey]).run();
            }
            hide();
          }}
        >
          <IconContainer>{item.data.icon}</IconContainer>

          <ItemLabel>
            <ItemTitle>{item.data.title}</ItemTitle>
            <ItemDesc>{`Insert ${item.data.labelOne}`}</ItemDesc>
          </ItemLabel>

          <LearnMoreButton type={item.integration.type} />

          {!item.isInstalled && (
            <InstallButton
              onClick={async e => {
                e.stopPropagation();
                if (!item.integration?.type) return;
                await install(item.integration.type, item.integration);
              }}
            >
              Install
            </InstallButton>
          )}
        </PanelItem>
      ))}
    </Panel>
  );
};
