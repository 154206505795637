import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ArrowUpCircleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <Svg
    {...props}
    ref={ref}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9 12V6M9 6L6.75 8.25M9 6L11.25 8.25M9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25Z"
      stroke="currentcolor"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
));
