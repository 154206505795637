import { Skeleton } from '@cycle-app/ui';

export const LinearImportSkeletons = () => (
  <div className="flex flex-col items-center gap-4">
    {[...Array(8).keys()].map(i => (
      <Skeleton
        key={i}
        height={20}
      />
    ))}
  </div>
);
