import { IconPicker, IconPickerProps } from '@cycle-app/ui';

import { ToggleDropdownProps, ToggleDropdown } from 'src/components/DropdownLayer';

type IconDropdownProps = Omit<ToggleDropdownProps, 'content'> & IconPickerProps;

export const IconDropdown = ({
  color, onIconChange, onColorChange, ...props
}: IconDropdownProps) => {
  return (
    <ToggleDropdown
      withPortal
      withWrapper={false}
      {...props}
      content={({ hide }) => (
        <IconPicker
          color={color}
          onColorChange={onColorChange}
          onIconChange={value => {
            hide();
            // Settimout for a smoother transition
            setTimeout(() => onIconChange(value));
          }}
        />
      )}
    />
  );
};
