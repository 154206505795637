import { make } from 'src/utils/reactives.util';

import { LocalKey } from '../types/localStorage.types';

export interface ColorPaletteSavedColorsResult {
  savedColors: string[];
}

export const {
  hookState: useColorPaletteSavedColors,
  hookValue: useGetColorPaletteSavedColors,
  getValue: getColorPaletteSavedColors,
  setValue: setColorPaletteSavedColors,
  resetValue: resetColorPaletteSavedColors,
} = make<ColorPaletteSavedColorsResult>({
  defaultState: {
    savedColors: [],
  },
  localKey: LocalKey.ColorPaletteSavedColors,
  crossTabSync: true,
});

export const colorPaletteSaveColor = (color: string) => {
  const { savedColors } = getColorPaletteSavedColors();
  if (savedColors.includes(color)) {
    return;
  }
  setColorPaletteSavedColors({
    savedColors: [
      color,
      ...savedColors,
    ].slice(0, 8),
  });
};
