import { ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import { AddIcon } from '@cycle-app/ui/icons';
import { CSSProperties, useState } from 'react';

import { useChangelog } from '../../hooks/releases/useChangelog';
import { useReleaseNoteTags } from '../../hooks/releases/useReleaseNoteTags';
import { useReleaseNoteTagUpdate } from '../../hooks/releases/useReleaseNoteTagUpdate';
import { ChangelogTagLabel } from '../ChangelogTag/ChangelogTag.styles';
import { ChangelogTagsPanel } from '../ChangelogTagsPanel';
import { ToggleDropdown } from '../DropdownLayer';
import { AddButton, ListContainer, StyledSkeleton, Tag, TagItem } from './ReleaseNoteTags.styles';

type Props = {
  releaseNoteId: string;
  isReadonly: boolean;
};

export const ReleaseNoteTags = ({
  isReadonly, releaseNoteId,
}: Props) => {
  const {
    tags, isLoading: isTagsLoading,
  } = useReleaseNoteTags(releaseNoteId);
  const [referenceClient, setReferenceClient] = useState<{ rect: DOMRect | null; id: string | null }>({
    // Postion the dropdown on the button clicked. Add rect so the dropdown does not move as we update the tags.
    rect: null,
    // The dropdown is common to all buttons. Add an id to idententify the button to for forceFocus.
    // AddButton is id=null
    id: null,
  });
  const {
    addTag, removeTag,
  } = useReleaseNoteTagUpdate();
  const {
    changelog, isLoading: isChangelogLoading,
  } = useChangelog();
  const { rect } = referenceClient;
  const isLoading = isTagsLoading || isChangelogLoading;
  return (
    <ToggleDropdown
      {...rect && { getReferenceClientRect: () => rect }}
      offset={[0, 10]}
      placement="bottom-start"
      content={(
        <ChangelogTagsPanel
          onSelectOption={tagId => addTag({
            releaseNoteId,
            tagId,
          })}
          onUnselectOption={tagId => removeTag({
            releaseNoteId,
            tagId,
          })}
          selected={tags.map(tag => tag.id)}
        />
      )}
      button={buttonProps => (
        <ListContainer>
          {!isLoading && tags.map(tag => {
            const style = {
              '--tagColor': changelog?.releaseTagColor,
              '--bg': tag.color,
              '--layerBg': changelog?.backgroundColor,
            } as CSSProperties;
            return (
              <TagItem
                key={tag.id}
                style={style}
                $tagStyle={changelog?.releaseTagStyle || ReleaseTagStyle.Background}
              >
                <Tag
                  $tagStyle={changelog?.releaseTagStyle || ReleaseTagStyle.Background}
                  $isReadonly={isReadonly}
                  onClick={e => {
                    if (isReadonly) return;
                    setReferenceClient({
                      rect: e.currentTarget.getBoundingClientRect(),
                      id: tag.id,
                    });
                    buttonProps.onClick(e);
                  }}
                  type="button"
                  style={style}
                >
                  <ChangelogTagLabel>{tag.value}</ChangelogTagLabel>
                </Tag>
              </TagItem>
            );
          })}
          <li>
            <AddButton
              disabled={isReadonly}
              forceFocus={!referenceClient.id && buttonProps['data-active']}
              onClick={e => {
                if (isReadonly) return;
                setReferenceClient({
                  rect: e.currentTarget.getBoundingClientRect(),
                  id: null,
                });
                buttonProps.onClick(e);
              }}
              {...isReadonly && {
                tooltip: 'Make update or unpublish first',
                tooltipPlacement: 'top',
              }}
            >
              <AddIcon size={10} />
              {isLoading && <StyledSkeleton />}
              {!isLoading && !tags.length && 'Release tag'}
            </AddButton>
          </li>
        </ListContainer>
      )}
    />
  );
};
