// Why use Object literal instead of enum ???
// => https://github.com/microsoft/TypeScript/issues/40793

import { ColorTheme } from '@cycle-app/utilities';
import { rgba } from 'polished';

// @todo Those should not be used anymore
export const LegacyColor = {
  UltraLight: '#F9F9F9',
  Light: '#E5E5E5',
  Grey: '#DFDFE4', // ~grey300 / borderPrimary / backgroundHover

  // Primitive
  Black: '#000000',
  White: '#FFF',
  Transparent: 'transparent',

  // Grey
  Grey100: '#F7F7F7',
  Grey200: '#F0F0F0',
  Grey700: '#707070',
  Grey900: '#242424',

  // Dark
  Dark: '#171717', // Black
  DarkLight: '#2A292B',
  DarkLight2: '#1d1d1e',
  GreyDark: '#656565', // grey700

  // Light
  GreyHyperLight: '#F1F3F4', // grey200
  GreyLight: '#747474',
  GreyLight2: '#A9A9A9', // grey500
  GreyLight3: '#EDEDED', // grey300

  // Default primary
  Blue: '#0F29D0',
  BlueLight: '#E2E6FF',

  Red: '#EE5252',
  Yellow: '#FBAA30',
  Green: '#0BBC5A',

  Beige: '#FFFBF7',

  // Dirty
  DirtyTransparentGrey: rgba(0, 0, 0, 0.15),
  // rgba(0, 0, 0, 0.15)
};

export const Color = ColorTheme;
