import { useQuery } from '@apollo/client';
import { GetRoleByProductDocument, Role } from '@cycle-app/graphql-codegen';

import { useMaybeMe, useMaybeMeV2 } from 'src/hooks/api/useMe';
import { useProduct } from 'src/hooks/api/useProduct';
import useQueryParams from 'src/hooks/useQueryParams';
import { extract } from 'src/types/graphql.types';
import { ProductRole } from 'src/types/role.types';

export const useGlobalRole = () => {
  const me = useMaybeMe();

  return me?.role;
};

export const useProductRole = () => {
  const queryParams = useQueryParams();
  const productId = queryParams.get('productId');
  const { product } = useProduct();
  const {
    me, error, isLoading: isMeLoading,
  } = useMaybeMeV2();
  const {
    data, loading: isRoleLoading,
  } = useQuery(GetRoleByProductDocument, {
    skip: !me || !product,
    variables: {
      userId: me?.id as string,
      roleProductId: productId || product?.id as string,
    },
  });

  return {
    error,
    me,
    isLoading: isMeLoading || isRoleLoading,
    product,
    role: (extract('Me', data?.node)?.productRole as ProductRole) || null,
  };
};

export const useRole = () => {
  const {
    error, me, role: productRole, isLoading, product,
  } = useProductRole();
  const globalRole = useGlobalRole();
  const role = productRole || globalRole || Role.Collaborator;

  return {
    error,
    me,
    isLoading,
    product,
    role,
    globalRole,
  };
};
