import { State, Modifier } from '@popperjs/core';

export const adjustPopperPosition: Modifier<State, { offset: number }>['fn'] = ({
  state, options,
}) => {
  if (!options.offset) return;
  const { rects } = state;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const overflowY = options.offset + rects.reference.y + rects.reference.height + rects.popper.height - viewportHeight;

  if (overflowY > 0 && state.modifiersData?.popperOffsets?.y) {
    // eslint-disable-next-line no-param-reassign
    state.modifiersData.popperOffsets.y = Math.max(options.offset, state.modifiersData.popperOffsets.y - overflowY);
  }

  if (viewportHeight <= rects.popper.height) {
    state.elements.popper.setAttribute('data-scroll', 'true');
  }
};
