import { useCallback, useLayoutEffect, useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useDocId } from 'src/hooks/usePathParams';
import { getDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocPanel } from 'src/reactives/docRightPanel.reactive';
import { setSelection } from 'src/reactives/selection.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';

import { useDocsSelection } from '../useDocsSelection';

interface Props {
  docIds: (string | undefined)[];
}

export const useDocRightPanelShortcutListener = ({ docIds }: Props) => {
  const docId = useDocId();
  const { toggleSelectDoc } = useDocsSelection();

  const handleSelectDoc = useCallback(() => {
    const hoverDocId = getDocItemHoverId();
    if (hoverDocId) {
      toggleSelectDoc(hoverDocId);
    }
  }, [toggleSelectDoc]);

  const handleSelectAllDocs = useCallback(() => {
    const selected = docIds.filter(isPresent);
    if (getDocPanel().isHovered && !!selected.length) {
      setSelection({
        selected,
      });
    }
  }, [docIds]);

  const callbacks = useMemo(() => ({
    [ShortcutBoard.SelectDoc]: handleSelectDoc,
    [ShortcutBoard.SelectAllDocs]: handleSelectAllDocs,
  }), [handleSelectDoc, handleSelectAllDocs]);

  useHotkeyListener({
    callbacks,
    shortcuts: [ShortcutBoard.SelectDoc, ShortcutBoard.SelectAllDocs],
    enabled: () => getDocPanel().isExpanded,
    disableOnLayers: [Layer.Dropdown, Layer.Modal, Layer.ModalZ2, Layer.ModalZ3],
  });

  useLayoutEffect(() => {
    const onEnd = (e: MouseEvent) => {
      const hasClickedOnRoot = e.target instanceof HTMLElement && (
        document.querySelector('#app')?.contains(e.target) ||
        document.querySelector('#portal-doc-panel')?.contains(e.target)
      );
      if (hasClickedOnRoot && !(e.target instanceof HTMLElement && e.target.closest('.doc-item'))) {
        setSelection({ selected: [] });
      }
    };
    document.addEventListener('mouseup', onEnd);
    return () => {
      document.removeEventListener('mouseup', onEnd);
      setSelection({
        selected: [],
        lock: false,
      });
    };
  }, [docId]);
};
