import { EmojiPicker } from '@cycle-app/ui';
import styled from 'styled-components';

export const EmojiPickerStyled = styled(EmojiPicker)`
  border-radius: 4px;
  padding: 4px;

  :hover, :focus {
    background: ${p => p.theme.colors.actionButton.bgHover};
  }

  &.force-focus, :active {
    background: ${p => p.theme.colors.actionButton.bgActive};
  }
`;
