import { typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Header = styled.div`
  position: relative;
  display: flex;
  padding: 32px;
  justify-content: space-between;
  gap: 6px;

  ${mobile} {
    position: relative;
    padding: 22px 16px 16px;
  }
`;

export const Title = styled.h1`
  ${typo.headerMedium}
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Description = styled.div`
  margin-top: 6px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const Role = styled.button`
  ${typo.caption500}
  background-color: ${p => p.theme.colors.background.tertiary};
  border-radius: 4px;
  color: ${p => p.theme.colors.text.secondary};
  height: 20px;
  padding: 0 6px;
  text-transform: lowercase;
  cursor: pointer;

  &:first-letter {
    text-transform: uppercase;
  }
`;
