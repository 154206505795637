import { FC, HTMLAttributes, ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { ObservedDiv } from './InfiniteScroll.styles';

export interface InfiniteScrollProps extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  loadMore?: () => void;
  hasMoreData: boolean;
  direction?: 'vertical' | 'horizontal';
  scrollableElement?: HTMLElement | null;
  disabled?: boolean;
  loader?: ReactNode;
  rootMargin?: string;
}

export const InfiniteScroll: FC<React.PropsWithChildren<InfiniteScrollProps>> = ({
  className,
  children,
  isLoading,
  loadMore,
  hasMoreData,
  direction = 'vertical',
  scrollableElement,
  disabled,
  loader,
  rootMargin = '800px',
  onWheel,
  ...props
}) => {
  // TODO : should useRef to not recreate the uuid at each rendering, but causes bugs in the home page
  const id = props.id ?? crypto.randomUUID();
  const [observedRef, isVisible] = useInView({
    skip: disabled,
    root: scrollableElement ?? document.getElementById(id)?.parentElement,
    rootMargin,
  });

  useEffect(() => {
    if (hasMoreData && isVisible && !isLoading) {
      loadMore?.();
    }
  }, [isVisible, isLoading, hasMoreData, loadMore]);

  return (
    // Stopped spreading all remaining props because currently InfiniteScroll is used with "as" prop in BoardContent
    // and viewType prop is wrongly spread to that component by styled-components. We should avoid using "as" prop when possible
    <div
      id={id}
      className={className}
      onWheel={onWheel}
    >
      {children}
      {isLoading && loader}
      {/* It's important to not render ObservedDiv when loading for two reasons:
        - to avoid triggering loadMore multiple times
        - to reset isVisible and trigger loadMore if ObservedDiv is still visible after next page
      */}
      {hasMoreData && !isLoading && (
        <ObservedDiv ref={observedRef} $direction={direction} />
      )}
    </div>
  );
};
