import { Color } from '@cycle-app/graphql-codegen';
import { RadioInput, RadioInputDot } from '@cycle-app/ui';
import styled from 'styled-components';

export const Dots = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  margin-top: 8px;
`;

interface DotProps {
  $color: Color;
}

export const DotContainer = styled.div`
  align-items: center;
  border-color: transparent;
  border-radius: 100px;
  border-style: solid;
  border-width: 0.33px;
  display: flex;
  height: 20px;
  justify-content: center;
  transition: border-color .1s;
  width: 20px;
`;

export const Dot = styled.div<DotProps>`
  --bg: transparent;
  --border: var(--bg);
  background-color: var(--bg);
  border: 1px solid var(--border);
  border-radius: 30px;
  height: 8px;
  width: 8px;
  transition: filter .1s, background-color .1s, color .1s;

  ${p => p.$color === Color.A && '--bg: #2C45E8;'}
  ${p => p.$color === Color.B && '--bg: #7B40F2;'}
  ${p => p.$color === Color.C && '--bg: #3F8FF7;'}
  ${p => p.$color === Color.D && '--bg: #09BB90;'}
  ${p => p.$color === Color.E && '--bg: #F7CF3F;'}
  ${p => p.$color === Color.F && '--bg: #EA9E10;'}
  ${p => p.$color === Color.G && '--bg: #EA3C10;'}
  ${p => p.$color === Color.H && '--bg: #F240AB;'}
  ${p => p.$color === Color.I && '--bg: #DA40F2;'}
  ${p => p.$color === Color.J && '--bg: #404040;'}
  ${p => p.$color === Color.K && '--bg: #F0F0F0; --border: #999999;'}
`;

export const StyledRadioInput = styled(RadioInput)`
  ${RadioInputDot} {
    display: none;
  }

  input:checked + label ${DotContainer} {
    border-color: ${p => p.theme.baseColors.Grey700};
  }

  input:focus-visible + label ${DotContainer} {
    border-color: hsl(var(--cycle));
  }

  label:hover ${DotContainer} {
    border-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey600 : p.theme.baseColors.Grey400)};
  }
`;
