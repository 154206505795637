import { ThemeType, Color as ColorApi } from '@cycle-app/graphql-codegen';
import merge from 'lodash/merge';

import baseTheme, { BaseTheme } from '../theme/baseTheme';
import {
  COLOR_NUANCES,
  COLOR_THEMES,
} from '../theme/colorThemes/index';

export const getTheme = (
  themeType: ThemeType,
  userColor: ColorApi,
): BaseTheme => {
  const colors = COLOR_THEMES[themeType];
  const nuances = COLOR_NUANCES[themeType];
  const userColors = nuances[userColor];

  return merge({}, baseTheme, {
    colors,
    nuances,
    userColors,
    themeType,
    isDark: themeType === ThemeType.Nightfall,
  });
};
