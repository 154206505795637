import { makeBooleanVar } from 'src/utils/makeVar.utils';

// Used to show/hide the floating quote extraction button at the bottom of the doc
export const {
  hook: useSummaryVisible,
  get: getSummaryVisible,
  toggle: toggleSummaryVisible,
  setTrue: setSummaryVisibleTrue,
  setFalse: setSummaryVisibleFalse,
} = makeBooleanVar(false);
