import { CompanyLogo } from '@cycle-app/ui';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { StyledCustomerCompanyAction, TextButton } from './Profile.styles';

type Props = {
  customerId: string;
};

export const CustomerCompany = ({ customerId }: Props) => {
  const { customer } = useCustomer(customerId);
  const productId = useWorkspaceContext(ctx => ctx.productId);
  if (!customer) return null;
  const company = customer?.company;
  return (
    <StyledCustomerCompanyAction
      customer={customer}
      productId={productId}
      isReadOnly={isDefaultCustomer(customer)}
      isCompact={false}
      {...customer.company && {
        tooltip: 'Open company',
        onCompanyClick: openCompanyModal,
      }}
    >
      {company ? (
        <TextButton
          onClick={() => openCompanyModal(company.id)}
        >
          <CompanyLogo company={company} size="S" />
          {company.name}
        </TextButton>
      ) : (
        <>No company</>
      )}
    </StyledCustomerCompanyAction>
  );
};
