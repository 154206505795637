import { SelectPanel, Button } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { lighten } from 'polished';
import styled from 'styled-components';

export const SectionSelectPanel = styled(SelectPanel)`
  width: 210px;
  ${p => p.theme.isDark && `
    background: ${p.theme.colors.background.tertiary};
  `}
`;

export const FilterButton = styled(Button).attrs({
  variant: 'light',
  size: 'M',
})`
  height: 24px;
  padding: 0 8px;
  gap: 4px;
  color: ${p => p.theme.colors.text.secondary};

  ${p => p.theme.isDark && `
    --bg: ${p.theme.colors.background.tertiary};
    :hover, :focus, :active {
      --bg: ${lighten(0.1, p.theme.colors.background.hoverSoft)};
    }
  `}
`;

export const ArrowIcon = styled(DownIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;
