import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${p => p.theme.colors.inbox.collectMore.bg};
  border-radius: 12px;
  padding: 24px 24px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
`;

export const AvailableSources = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  
  > * {
    height: 16px;
  }
`;

export const Text = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-bottom: 4px;
`;

export const Title = styled.p`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;
