import { ViewType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { DocItem } from 'src/components/DocItem';
import { DocProvider } from 'src/contexts/docContext';
import { useDoc } from 'src/hooks/api/useDoc';
import { useGetDocItemProps } from 'src/hooks/useGetDocItemProps';
import { useParentPage } from 'src/hooks/usePageId';

import { Container } from './DocItemOverlay.styles';

interface Props {
  activeId: string | null;
  direction?: 'left' | 'right';
  viewType: ViewType;
}

const DocItemOverlay: FC<React.PropsWithChildren<Props>> = ({
  activeId,
  direction,
  viewType,
}) => {
  const parentPage = useParentPage();
  const { doc } = useDoc(activeId);
  const getDocItemProps = useGetDocItemProps(viewType);

  if (!doc) return null;

  return (
    <Container>
      <DocProvider value={doc}>
        <DocItem
          isDragging
          direction={direction}
          isNewInbox={parentPage === 'inbox' && viewType === ViewType.List}
          {...getDocItemProps(doc)}
        />
      </DocProvider>
    </Container>
  );
};

export default DocItemOverlay;
