import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';

import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { ReleasesPanel, ReleasesPanelProps } from 'src/components/ReleasesPanel';

type Props = Omit<ToggleDropdownProps, 'content'> & Omit<ReleasesPanelProps, 'hide'>;

export const ReleasesDropdown = ({
  onChange, title, releaseId, releaseNoteId, ...props
}: Props) => {
  return (
    <ToggleDropdown
      {...props}
      content={contentProps => (
        <ReleasesPanel
          hide={contentProps.hide}
          title={title}
          releaseId={releaseId}
          releaseNoteId={releaseNoteId}
          onChange={async newReleaseId => {
            contentProps.hide();
            await nextFrame();
            onChange(newReleaseId);
          }}
        />
      )}
    />
  );
};
