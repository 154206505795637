import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { PortalModal } from '../PortalModal';

export const StyledModal = styled(PortalModal)`
  width: 454px;
  padding: 24px;
  border-radius: 20px;
  text-align: center;
`;

export const ModalHeader = styled.h2`
  ${typo.headerLight}
`;

export const Link = styled.a`
  display: inline-block;
  color: inherit;
  text-decoration: underline;
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 16px;

  > button {
    flex-grow: 1;
  }
`;
