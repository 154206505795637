import { CreateDocsFromFilesDocument, CreateDocFromLinkDocument } from '@cycle-app/graphql-codegen';
import { DragEvent, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Events } from 'src/constants/analytics.constants';
import { DROPZONE_MAX_FILES } from 'src/constants/dropzone.constants';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSafeMutation } from 'src/hooks';
import { useDocImport } from 'src/hooks/api/queries/useDocImport';
import { usePrevious } from 'src/hooks/usePrevious';
import { getDocImport, setDocImport, useGetDocImport } from 'src/reactives/docImport.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { fileValidator } from './fileValidator';

type Props = {
  isMini?: boolean;
  onDiscardLast?: VoidFunction;
  disabled?: boolean;
};

export const useDropzoneProps = ({
  isMini = false,
  onDiscardLast,
  disabled,
}: Props = {}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [createDocsFromFiles] = useSafeMutation(CreateDocsFromFilesDocument);
  const [createDocsFromLink] = useSafeMutation(CreateDocFromLinkDocument);

  // Draft feedback docs created by the backend
  const docsQuery = useDocImport();

  // Unique ids of the files that are currently being uploaded
  const {
    loadingFiles, acceptedTotal, fileRejections,
  } = useGetDocImport();

  // Error state for when the user tries to drop more files than the limit
  const [isMaxFilesError, setIsMaxFilesError] = useState(false);

  // Small version of the drop zone next to a detailed file list
  const [isDetails, setIsDetails] = useState(docsQuery.docs.length > 0);

  // The first loading state that shows the progress
  const isLoading = loadingFiles.length > 0 && (!isDetails || isMini);
  const processCount = Math.max(acceptedTotal - loadingFiles.length, 0);

  // Clear the error state and shows the cursor background when the mouse enters the dropzone
  const onMouseEnter = () => setIsMaxFilesError(false);
  const onDrop = (event: DragEvent<HTMLElement>, fromMini = false) => {
    const { files } = event.dataTransfer;
    const fileDataArray = Array
      .from(files)
      .map(file => `[${file.type} - ${(file.size / (1024 * 1024)).toFixed(2)}mb]`)
      .join(' ');
    trackAnalytics(Events.FileDropped, {
      fileCount: files.length,
      fileTypes: fileDataArray,
      from: fromMini ? 'mini' : 'island',
    });
  };

  // Update the progress state and send the files to the backend
  const handleFiles = (files: File[]) => {
    setDocImport({
      acceptedTotal: getDocImport().acceptedTotal + files.length,
      loadingFiles: files
        .map(() => crypto.randomUUID())
        .concat(getDocImport().loadingFiles),
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createDocsFromFiles({
      variables: {
        productId,
        files,
      },
    });
  };

  const handleLink = (link: string) => {
    setDocImport({
      acceptedTotal: getDocImport().acceptedTotal + 1,
      loadingFiles: [crypto.randomUUID()].concat(getDocImport().loadingFiles),
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createDocsFromLink({
      variables: {
        productId,
        link,
      },
    });
  };

  const prevLoadingFilesCount = usePrevious(loadingFiles.length) ?? 0;
  // When the progress is 100%, switch to the detailed dropzone
  useEffect(() => {
    if (!isDetails &&
        (docsQuery.docs.length > 0 || fileRejections.length > 0 || (prevLoadingFilesCount > loadingFiles.length && acceptedTotal > 0))) {
      setIsDetails(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsQuery.data, fileRejections.length, loadingFiles.length]);

  // If no more files in the detailed dropzone, switch back to the initial dropzone
  useEffect(() => {
    if (!isDetails) return;
    if (!docsQuery.data || docsQuery.docs.length > 0) return;
    if (loadingFiles.length > 0) return;
    if (fileRejections.length > 0) return;
    if (prevLoadingFilesCount > 0) return;
    setIsDetails(false);
    // Reset the progress state
    setDocImport({ acceptedTotal: 0 });
    onDiscardLast?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsQuery.data, fileRejections.length, loadingFiles.length]);

  const dropzoneState = useDropzone({
    disabled,
    autoFocus: true,
    maxFiles: DROPZONE_MAX_FILES,
    validator: fileValidator,
    onDragEnter: e => {
      setIsMaxFilesError(e.dataTransfer.items.length > DROPZONE_MAX_FILES);
    },
    onDragLeave: () => {
      // The max files error is only shown when the user hover the dropzone with too many files
      // Or when the user tries to import too many files with the Upload button
      setIsMaxFilesError(false);
    },
    onDropAccepted: handleFiles,
    onDropRejected: rejections => {
      // This case is when the user import too much files with the Upload button
      if (isMaxFilesError) {
        if (isLoading) setIsMaxFilesError(false);
        return;
      }
      if (rejections.length > DROPZONE_MAX_FILES) {
        if (!isLoading) setIsMaxFilesError(true);
        return;
      }

      setDocImport({
        fileRejections: getDocImport().fileRejections.concat(
          rejections.map(rejection => ({
            rejection,
            id: crypto.randomUUID(),
          })),
        ),
      });
    },
  });

  // The initial state of the dropzone
  const isIdle = !dropzoneState.isDragActive && !isLoading;

  return {
    ...dropzoneState,
    ...docsQuery,
    isIdle,
    isLoading,
    isDetails,
    isMaxFilesError,
    onDrop,
    onMouseEnter,
    loadingFiles,
    processCount,
    handleFiles,
    handleLink,
  };
};
