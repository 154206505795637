import { ViewType } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { KanbanIcon, ListIcon, TableIcon } from '@cycle-app/ui/icons';
import { useMeasure } from '@cycle-app/utilities';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { SoonBadge } from 'src/components/SoonBadge';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import useChangeViewType from 'src/hooks/api/mutations/boardConfig/useChangeViewtype';
import { useBoard } from 'src/hooks/api/useBoard';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { Layer } from 'src/types/layers.types';

import { SelectedValues, Caret } from './BoardConfigForm.styles';

const options: SelectOption[] = [{
  value: ViewType.Kanban,
  label: 'Kanban',
  icon: <KanbanIcon />,
}, {
  value: ViewType.List,
  label: 'List',
  icon: <ListIcon />,
}, {
  value: 'Table',
  label: 'Table',
  icon: <TableIcon />,
  end: <SoonBadge />,
  disabled: true,
}];

interface Props {
  boardId?: string;
  isDisabled?: boolean;
}

export const BoardConfigFormViewType = ({
  boardId: boardIdFromProps, isDisabled = false,
}: Props) => {
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const board = useBoard(boardId);
  const { data } = useDraftBoardConfig(boardIdFromProps || boardId);
  const draftBoardConfig = data?.node?.draftBoardConfig;
  const { updateViewType } = useChangeViewType(draftBoardConfig?.id ?? '', boardIdFromProps || board?.id);

  const {
    rect: itemRect,
    ref: itemRef,
  } = useMeasure<HTMLButtonElement>();

  const isList = draftBoardConfig?.viewType === ViewType.List;

  return (
    <ToggleDropdown
      layer={Layer.DropdownModal}
      placement="bottom-start"
      width={itemRect?.width}
      motionProps={{ transition: { duration: 0 } }}
      button={props => (
        <SelectedValues
          type="button"
          ref={itemRef}
          onClick={isDisabled ? undefined : props.onClick}
          forceFocus={props['data-active']}
        >
          {isList ? <ListIcon /> : <KanbanIcon />}
          {isList ? 'List' : 'Kanban'}
          <Caret direction={props['data-active'] ? 'top' : 'bottom'} />
        </SelectedValues>
      )}
      content={props => (
        <SelectPanel
          hideSearch
          options={options}
          onOptionChange={option => {
            props.hide();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateViewType(option.value as ViewType);
          }}
        />
      )}
      disabled={isDisabled}
    />
  );
};
