import { useEffect } from 'react';

import { setLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

export const useInitLayer = (layer: Layer, enabled = true): void => {
  useEffect(() => {
    if (!enabled) return () => {};
    setLayer(layer, true);
    return () => setLayer(layer, false);
  }, [enabled, layer]);
};
