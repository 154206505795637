import { typo, ActionButton } from '@cycle-app/ui';
import { ContextBlock, ShowMore } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const AddButton = styled(ActionButton).attrs({ variant: 'secondary' })`
  display: flex;
  width: 18px;
  height: 18px;
`;

export const DoctypeInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
`;

export const DoctypeLabel = styled.div`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
`;

export const Items = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    border-radius: 8px;
    border: 1px solid ${p => p.theme.colors.components.InsightCard.card.border};
    box-shadow: 0px 1px 2px 0px #0000000F;

    ${mobile} {
      border-left: none;
    }
  }

  ${ContextBlock} {
    background: transparent;
    padding: 0 0 0 14px;
    .ProseMirror {
      padding: 0 !important;
    }
  }

  ${ShowMore} {
    bottom: 1px;
    background-color: ${p => p.theme.colors.components.InsightCard.quote.bg};
    :before {
      box-shadow: -20px 0px 15px 0px ${p => p.theme.colors.components.InsightCard.quote.bg};
    }
  }
`;
