import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  margin: 48px auto;
`;

export const Title = styled.h3`
  ${typo.body500}
`;

export const Body = styled.p`
  color: ${p => p.theme.colors.text.disabled};
`;
