import { ActionButton, typo, ShyScrollbarCss, Button, truncate } from '@cycle-app/ui';
import { InfoIconOutline, CaretIcon } from '@cycle-app/ui/icons';
import { small } from '@cycle-app/utilities';
import styled from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Modal = styled(PortalModal)<{ $autoFit: boolean }>`
  width: 750px;
  height: 750px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${p => p.$autoFit && `
    height: auto;
    min-height: 620px;
    justify-content: center;
  `}
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Separator = styled.div`
  height: 1px;
  background: ${p => p.theme.colors.border.primary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  flex: 1;
`;

export const Scrollable = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 -20px 0 -12px;
  padding: 0 12px;
  scrollbar-gutter: stable;
  ${ShyScrollbarCss}
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  height: 30px;
  flex: none;
  :hover {
    background: ${p => p.theme.colors.selectItem.bgHover};
    padding: 0 8px;
    margin: 0 -8px;
    border-radius: 6px;
    cursor: pointer;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
`;

export const Name = styled.div`
  ${typo.body500}
  ${truncate}
`;

export const Info = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  ${truncate}
`;

export const InfoCaption = styled(Info)`
  ${typo.caption400}
`;

export const InfoIcon = styled(InfoIconOutline)`
  color: ${p => p.theme.colors.text.secondary};
`;

export const ImportButton = styled(Button).attrs({
  size: 'M',
})`
  :disabled {
    background: hsla(var(--cycle), 0.4);
    color: ${p => p.theme.colors.text.white};
  }
`;

export const SkipButton = styled(Button).attrs({
  size: 'M',
  variant: 'nospace',
})`
  height: 32px;
  color: ${p => p.theme.colors.text.blue};
  ${p => p.theme.isDark && `
    color: ${p.theme.colors.text.primary};
  `}
  :hover {
    background: ${p => p.theme.colors.button.outlinedAlt.bgHover};
  }
  :active {
    background: ${p => p.theme.colors.button.outlinedAlt.bgActive};
  }
`;

export const DocTypeButton = styled(Button).attrs({
  size: 'M',
  variant: 'outlined-alt',
})`
  width: 280px;
  height: 36px;
  padding: 0 12px;
  ${typo.body400}
  &[data-active=true] {
    background: ${p => p.theme.colors.button.outlinedAlt.bgActive};
  }
`;

export const Caret = styled(CaretIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 720px) {
    gap: 24px;
    padding-right: 32px;
  }
`;

export const Index = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  background: ${p => p.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  ${typo.caption600}
  flex: none;
`;

export const Step = styled.div<{
  $isActive: boolean;
  $isDone: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.body500}
  user-select: text;

  ${p => p.$isActive && `
    color: ${p.theme.colors.text.primary};
    ${Index} {
      background: ${p.theme.colors.background.blue};
      color: ${p.theme.colors.text.white};
    }
  `}

    ${p => p.$isDone && `
    ${Index} {
      color: ${p.theme.colors.text.white};
    }
  `}

  :not(:first-child):before {
    content: '';
    display: block;
    margin: 0 16px;
    height: 2px;
    width: 42px;
    background: ${p => p.theme.colors.border.primary};
    @media (max-width: 720px) {
      display: none;
    }
  }
`;

export const Intro = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const IntroTitle = styled.div`
  ${typo.headerLarge600}
`;

export const IntroList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 64px 0 32px;
  width: 520px;
  max-width: 100%;
  ${small} {
    margin: 16px;
  }
`;

export const IntroItem = styled.div`
  border-radius: 8px;
  background: ${p => p.theme.colors.background.secondaryElevated};
  padding: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const IconContainer = styled.div`
  background: ${p => p.theme.colors.modal.content.bg};
  color: ${p => p.theme.colors.text.disabled};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  flex: none;
`;

export const ToasterLink = styled(Button).attrs({
  variant: 'nospace',
  size: 'M',
})`
  ${typo.body400}
`;
