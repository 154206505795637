/**
 * Type guard for errors with a `message` property
 */
export function hasMessage(error: unknown): error is { message: string } {
  if (typeof error !== 'object' || !error) return false;
  if (!('message' in error)) return false;
  return typeof (error as { message: string }).message === 'string';
}

/**
 * Type guard for errors with a `toString` method
 */
export function hasToString(error: unknown): error is { toString: () => string } {
  if (typeof error !== 'object' || !error) return false;
  if (!('toString' in error)) return false;
  return typeof (error as { toString: () => string }).toString === 'function';
}

/**
 * Returns the error message if it exists
 */
export function toString(error: unknown): string | null {
  if (hasToString(error)) return error.toString();
  return null;
}
