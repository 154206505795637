/**
 * Format milliseconds to human readable string, rounded to the nearest unit (e.g. 1 day, 2 hours, 3 min, 4 sec)
 */
export const formatMs = (ms: number) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days > 1 ? `${days} days` : `${days} day`;
  }

  if (hours > 0) {
    return hours > 1 ? `${hours} hours` : `${hours} hour`;
  }

  if (minutes > 0) {
    return `${minutes} min`;
  }

  return `${seconds} sec`;
};
