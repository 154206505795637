import { Tooltip, Shortcut, OfflineBadge } from '@cycle-app/ui';
import { BellActiveIcon, BellIcon } from '@cycle-app/ui/icons';

import NotificationCenter from 'src/app/Main/Board/NotificationCenter/NotificationCenter';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useIsOffline } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { closeMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';
import { useGetNotifications, openNotifications } from 'src/reactives/notifications.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { ShortcutSidebar } from 'src/types/shortcuts.types';

import { Item, TooltipContent } from './Notifications.styles';

export const Notifications = () => {
  const collapsed = useSidebarCollapsed();
  const isMobile = useIsMobile();
  const { product } = useProduct();
  const { notificationsNotRead } = product || {};

  const { isNotifCenterVisible } = useGetNotifications();
  const isOffline = useIsOffline();

  if (isMobile) {
    return renderContent();
  }
  return (
    <NotificationCenter>
      {renderContent()}
    </NotificationCenter>
  );

  function renderContent() {
    if (isOffline) return <OfflineBadge />;

    return (
      <Tooltip
        content={(
          <TooltipContent>
            Notifications
            <Shortcut keys={shortcuts[ShortcutSidebar.OpenNotifCenter]} />
          </TooltipContent>
        )}
        placement="bottom"
        withPortal
      >
        <Item
          onClick={() => {
            openNotifications();
            setTimeout(closeMobileAsideMenu, 250);
          }}
          $collapsed={collapsed}
          $active={isNotifCenterVisible}
          $isMobile={isMobile}
        >
          {notificationsNotRead ? <BellActiveIcon /> : <BellIcon />}
        </Item>
      </Tooltip>
    );
  }
};
