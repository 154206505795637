import { typo } from '@cycle-app/ui';
import { RightIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const OptionCaption = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubmenuTitle = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.disabled};
`;

interface SubmenuIconProps {
  $isActive?: boolean;
}

export const SubmenuIcon = styled(RightIcon)<SubmenuIconProps>`
  color: ${p => (p.$isActive ? p.theme.colors.body.color : p.theme.colors.text.disabled)};
  vertical-align: middle;
  width: 14px;
  height: 14px;
`;
