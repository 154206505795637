import { useEffect } from 'react';

import { LoaderState, setLoader, getLoader } from 'src/reactives/loader.reactive';

type UseLoader = (p: Pick<LoaderState, 'loading'> & Partial<LoaderState>) => void;

export const useLoader: UseLoader = ({
  loading,
  ...loaderState
}) => {
  useEffect(() => {
    if (loading === getLoader().loading) {
      return;
    }
    setLoader({
      loading,
      ...loaderState,
    });
  }, [loading, loaderState]);
};
