import { Button } from '@cycle-app/ui';
import styled from 'styled-components';

interface Props {
  squared: boolean;
}

export const StyledButton = styled(Button)<Props>`
  gap: 6px;
  height: 24px;
  ${p => p.squared && 'width: 24px;'}

  &, :focus {
    --color: ${p => p.theme.colors.text.disabled};
    outline: none;
  }

  :hover, :focus-visible {
    --color: ${p => p.theme.colors.text.secondary};
  }

  svg {
    flex: none;
  }
`;
