import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useAnimation } from 'framer-motion';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';

import { UninstallIntegrationModal } from 'src/app/Main/Settings/SettingsIntegrations/UninstallIntegrationModal';
import { DropdownLayer, useToggleDropdown } from 'src/components/DropdownLayer';
import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useCurrentBilling, useProductIntegrations, useInstallIntegration } from 'src/hooks';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetIntegrationPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { executeIntegration } from 'src/utils/integrations.utils';

import { sourceVariants } from './InboxZeroSources.motion';
import { Container, SourceContainer, SourceButton, AddSourceButton } from './InboxZeroSources.styles';
import { SourcesPanel } from './SourcesPanel';

const openLimitationsModal = () => setLimitationsModal({ action: 'INTEGRATION_ADD' });

export const InboxZeroSources = () => {
  const { sourcesByStatus } = useProductIntegrations();
  const { canAddIntegration } = useGetIntegrationPermission();
  const { canReadSettings } = useGetPermission();
  const billing = useCurrentBilling();
  const controls = useAnimation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const install = useInstallIntegration();

  const installed = useMemo(() => orderBy(
    sourcesByStatus.installed
      .filter(x => (!(billing?.plan === 'FREE' && ['ZAPIER', 'CHROME'].includes(x.integrationType))))
      .map(i => ({
        ...i,
        order: integrationsDataMap[i.integrationType].order,
      })),
    ['order'],
  ), [sourcesByStatus.installed, billing?.plan]);

  const {
    buttonRef,
    isDropdownVisible,
    openDropdown,
    hideDropdown,
  } = useToggleDropdown();

  const half = Math.floor(installed.length / 2);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    controls.start('rest');
  }, [controls, installed]);

  return (
    <Container
      onMouseEnter={() => controls.start('hover')}
      onMouseLeave={() => controls.start('rest')}
      onBlur={() => controls.start('rest')}
    >
      {installed.map((source, index) => {
        const data = integrationsDataMap[source.integrationType];
        const customIndex = index - half;
        return (
          <SourceContainer
            key={source.integrationType}
            variants={sourceVariants(isDropdownVisible)}
            custom={customIndex}
            animate={controls}
            style={{
              zIndex: index >= half ? installed.length - index : index,
            }}
          >
            <Tooltip
              content={source.integrationType === IntegrationType.Meeting
                ? `Click to ${source.integration?.provider?.id ? 'remove' : 'install'}`
                : data.action ?? data.label}
              title={source.integrationType === IntegrationType.Meeting
                ? data.action ?? data.label
                : undefined}
              placement="top"
              withPortal
            >
              <SourceButton onClick={() => {
                if (source.integrationType === IntegrationType.Meeting) {
                  if (source.integration?.provider) {
                    setIsModalVisible(true);
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    install(IntegrationType.Meeting, source.integration);
                  }
                  return;
                }
                executeIntegration(source.integrationType, source.integration);
              }}
              >
                {data.icon}
              </SourceButton>
              {source.integrationType === IntegrationType.Meeting && source.integration?.id && (
                <UninstallIntegrationModal
                  type={IntegrationType.Meeting}
                  id={source.integration.id}
                  isVisible={isModalVisible}
                  hide={() => setIsModalVisible(false)}
                />
              )}
            </Tooltip>
          </SourceContainer>
        );
      })}

      {canReadSettings && sourcesByStatus.uninstalled.length && (
        <SourceContainer
          variants={sourceVariants(isDropdownVisible)}
          custom={installed.length - half}
          animate={controls}
        >
          <DropdownLayer
            placement="bottom"
            offsetY={8}
            visible={isDropdownVisible}
            hide={hideDropdown}
            content={<SourcesPanel hide={hideDropdown} />}
          >
            {!(billing?.plan === 'FREE' && installed.length === 1) && (
              <Tooltip
                content="Add source"
                placement="top"
                withPortal
                style={{ height: 42 }}
              >
                <AddSourceButton
                  ref={buttonRef}
                  data-active={isDropdownVisible}
                  onClick={canAddIntegration ? openDropdown : openLimitationsModal}
                >
                  <AddIcon size={14} />
                </AddSourceButton>
              </Tooltip>
            )}
          </DropdownLayer>
        </SourceContainer>
      )}
    </Container>
  );
};
