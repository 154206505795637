import { InsightCard as InsightCardUI } from '@cycle-app/ui';
import styled from 'styled-components';

export const InsightCard = styled(InsightCardUI)`
  border-radius: 8px;
  background-color: ${p => p.theme.colors.docPanel.hierarchy.bgModal};

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:hover {
    background-color: ${p => p.theme.colors.docPanel.hierarchy.bgHover};
  }
`;
