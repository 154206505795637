import { useSubscription } from '@apollo/client';
import {
  CustomersImportedSubscription,
  CustomersImportedDocument,
  CustomersImportedSubscriptionVariables,
} from '@cycle-app/graphql-codegen';
import { ToasterAction } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { ucFirst } from '@cycle-app/ui/utils/ucFirst.util';

import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { setIntegrationSync, getIntegrationSync } from 'src/reactives/integrationSync.reactive';
import { sourceIntegrationMapping } from 'src/utils/integrations.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { usePageId, PageId } from '../usePageId';
import { usePeopleRefetch } from './queries/customers/usePeopleRefetch';

export const useCustomersImportedSubscription = (productId?: string | null) => {
  const { navigate } = useNavigateToSettings();
  const pageId = usePageId();
  const people = usePeopleRefetch();

  return useSubscription<CustomersImportedSubscription, CustomersImportedSubscriptionVariables>(
    CustomersImportedDocument,
    {
      variables: {
        productId: productId as string,
      },
      onSubscriptionData: async ({ subscriptionData }) => {
        const isPeoplePage = pageId === PageId.SettingsCustomers || pageId === PageId.SettingsCompanies;

        const sourceType = subscriptionData.data?.customersImported;
        if (!sourceType || !getIntegrationSync()[sourceType]?.isSyncing) return;

        const integrationType = sourceIntegrationMapping[sourceType];
        const { learnUrl } = integrationsDataMap[integrationType];

        setIntegrationSync({
          [sourceType]: { isSyncing: false },
        });

        // UX: this will avoid visual flash of the lists when the user navigates to the customers.
        // also we need to update if the user is already on the customers page.
        await people.refetch();

        addToaster({
          id: `${integrationType}-sync`,
          title: `${ucFirst(sourceType)} customer sync done`,
          icon: <CheckIcon />,
          isCompact: false,
          actions: (
            <>
              {!isPeoplePage && (
                <ToasterAction onClick={() => navigate(PageId.SettingsCustomers)}>
                  View customers
                </ToasterAction>
              )}

              {learnUrl && (
                <ToasterAction onClick={() => window.open(learnUrl, '_blank')}>
                  Learn more
                </ToasterAction>
              )}
            </>
          ),
        });
      },
    },
  );
};
