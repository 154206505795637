import { WIDTH_COLLAPSED_SIDEBAR } from '@cycle-app/ui';
import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import { DocStateLoaderQuotes } from 'src/components/DocStateLoaderQuotes';
import { ExportQuotesFromDoc } from 'src/components/ExportQuotesModal';
import Portal from 'src/components/Portal/Portal';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { DocPanelProvider } from 'src/contexts/docPanelContext';
import { ExtractQuotesProvider } from 'src/contexts/extractQuotesContext';
import { useDoc } from 'src/hooks';
import { useDocSubscription } from 'src/hooks/api/useDocSubscription';
import { useDocsPreview } from 'src/hooks/doc/useDocsPreview';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { useDocId, useDocSlug } from 'src/hooks/usePathParams';
import { PageId, useUrl } from 'src/hooks/useUrl';
import { useIsMobile } from 'src/reactives';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { resetHighlight } from 'src/reactives/highlight.reactive';
import { useGetSidebarCollapsed, useGetSidebarWidth } from 'src/reactives/sidebar.reactive';
import { PortalId } from 'src/types/portal.types';
import { isFeedback } from 'src/utils/docType.util';

import { CreateDocModal } from '../CreateDocModal';
import DocPanelBoardPage from './DocPanelBoardPage/DocPanelBoardPage';
import DocPanelFullPage from './DocPanelFullPage/DocPanelFullPage';

interface Props {
  expanded?: boolean;
}

const DocPanel: FC<React.PropsWithChildren<Props>> = ({ expanded = false }) => {
  const isMobile = useIsMobile();
  const getUrl = useUrl();
  const docSlug = useDocSlug();
  if (!expanded && isMobile) return <Redirect to={getUrl(PageId.DocFullPage, { docSlug })} />;
  return <DocPanelPage expanded={expanded} />;
};

const DocPanelPage: FC<React.PropsWithChildren<Props>> = ({ expanded = false }) => {
  const docId = useDocId();
  const isMobile = useIsMobile();
  const { width } = useGetSidebarWidth();
  const { collapsed } = useGetSidebarCollapsed();
  const { doc: docBase } = useDoc(docId);

  const {
    doc,
    isLoading: isDocLoading,
    updateDocCover,
    setDocViewCoverInput,
    ...commonProps
  } = useDocPanelProps();

  const widthSidebar = collapsed ? WIDTH_COLLAPSED_SIDEBAR : width;

  useDocSubscription(docId);

  useDocsPreview();
  useEffect(() => {
    setCommandbar({ docId: docId ?? null });
    return () => setCommandbar({ docId: null });
  }, [docId]);

  useEffect(() => {
    return () => {
      // We can keep highlight states between docs, reset only when a leave the route.
      resetHighlight();
    };
  }, []);

  if (!docId) return null;

  const docPanelBaseProps = {
    isDocLoading,
    widthSidebar: isMobile ? 0 : widthSidebar,
    ...commonProps,
  };
  const coverInputEl = (
    <CoverImageInputFile
      ref={(r) => r && setDocViewCoverInput(r)}
      onCoverChanged={updateDocCover}
    />
  );

  const isFeedbackDoctype = isFeedback(docBase?.doctype);

  const docStateLoaderQuotes = !isMobile && isFeedbackDoctype && <DocStateLoaderQuotes />;

  const content = expanded ? (
    <>
      <DocPanelFullPage
        {...docPanelBaseProps}
      >
        {docStateLoaderQuotes}
      </DocPanelFullPage>
      {coverInputEl}
    </>
  ) : (
    <Portal portalId={PortalId.DocPanel}>
      <DocPanelBoardPage
        {...docPanelBaseProps}
      >
        {docStateLoaderQuotes}
      </DocPanelBoardPage>
      {coverInputEl}
    </Portal>
  );

  if (isFeedbackDoctype) {
    return (
      <>
        <DocPanelProvider docId={docId} doc={doc}>
          <ExtractQuotesProvider docId={docId} doc={doc}>
            {content}
          </ExtractQuotesProvider>
        </DocPanelProvider>
      </>
    );
  }

  return (
    <>
      <DocPanelProvider docId={docId} doc={doc}>
        {content}
      </DocPanelProvider>
      <ExportQuotesFromDoc docId={docId} />
    </>
  );
};

export default DocPanel;

export const DocPanelWithBoardConfig = () => {
  return (
    <BoardConfigContextProvider>
      <DocPanel />
    </BoardConfigContextProvider>
  );
};

export const DocPanelFull = () => {
  return (
    <BoardConfigContextProvider>
      <DocPanel expanded />
      <CreateDocModal />
    </BoardConfigContextProvider>
  );
};
