import { getOS } from '@cycle-app/utilities';
import { useEffect } from 'react';

import { setHotkey } from 'src/reactives/hotkey.reactive';

const os = getOS();

const onKey = (e: KeyboardEvent) => {
  setHotkey({
    mod: os === 'macOS' ? e.metaKey : e.ctrlKey,
    shift: e.shiftKey,
  });
};

const onBlur = () => {
  setHotkey({
    mod: false,
    shift: false,
  });
};

export const useModListener = () => {
  useEffect(() => {
    window.addEventListener('keydown', onKey);
    window.addEventListener('keyup', onKey);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('keydown', onKey);
      window.removeEventListener('keyup', onKey);
      window.removeEventListener('blur', onBlur);
    };
  }, []);
};
