import { hasMessage, toString } from '@cycle-app/utilities';
import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';

import { ErrorMessage } from 'src/constants/errors.constants';
import { SENTRY_IGNORED_ERRORS } from 'src/constants/sentry.constants';
import { ErrorType } from 'src/types/errors.types';

export const logError = (error: unknown, context?: Extras): void => {
  const shouldIgnore = hasMessage(error) && SENTRY_IGNORED_ERRORS.some(pattern => {
    return (typeof pattern === 'string'
      ? error.message === pattern
      : pattern.test(error.message));
  });

  if (shouldIgnore) return;

  // @WARNING console log below is used for debugging api with full story
  if (context) {
    // eslint-disable-next-line no-console
    console.error('error', error, { extra: context });
  } else {
    // eslint-disable-next-line no-console
    console.error('error', error);
  }

  // Sentry expects an Error object
  if (!(error instanceof Error)) {
    Sentry.captureException(
      new Error(`Non-Error rejection: ${JSON.stringify(error)}`),
      { extra: context },
    );
    return;
  }

  Sentry.captureException(error, {
    extra: context,
  });
};

type getErrorInfoReturn = {
  isError: boolean;
  type: ErrorType | null;
  message: string | null;
};

export const getErrorInfo = (error: unknown): getErrorInfoReturn => {
  if (toString(error)?.includes('UNAUTHORIZED')) {
    return {
      isError: true,
      type: ErrorType.AUTHORIZATION,
      message: ErrorMessage.UNAUTHORIZED,
    };
  }
  if (error) {
    return {
      isError: true,
      type: ErrorType.GENERIC,
      message: ErrorMessage._GENERIC,
    };
  }

  return {
    isError: false,
    type: null,
    message: null,
  };
};
