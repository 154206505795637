import { SelectOption } from '@cycle-app/ui';
import { InboxIcon, BulbIcon, DocumentIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useSectionFilter, setSectionFilter } from 'src/reactives/commandbar.reactive';
import { Layer } from 'src/types/layers.types';

import { SectionSelectPanel, FilterButton, ArrowIcon } from './CommandSectionFilter.styles';

export const CommandSectionFilter = () => {
  const sectionFilter = useSectionFilter();

  const isSingle = Object.values(sectionFilter).filter(Boolean).length < 2;

  const options: SelectOption[] = [
    {
      label: 'Feedback',
      value: 'feedback',
      icon: <InboxIcon />,
      selected: sectionFilter.feedback,
      disabled: sectionFilter.feedback && isSingle,
    },
    {
      label: 'Insights',
      value: 'insights',
      icon: <BulbIcon />,
      selected: sectionFilter.insights,
      disabled: sectionFilter.insights && isSingle,
    },
    {
      label: 'Docs',
      value: 'roadmaps',
      icon: <DocumentIcon />,
      selected: sectionFilter.roadmaps,
      disabled: sectionFilter.roadmaps && isSingle,
    },
  ];

  return (
    <ToggleDropdown
      layer={Layer.Commandbar}
      placement="bottom-start"
      button={props => (
        <FilterButton {...props}>
          {sectionFilter.feedback && <InboxIcon size={14} />}
          {sectionFilter.insights && <BulbIcon size={14} />}
          {sectionFilter.roadmaps && <DocumentIcon size={14} />}
          {isSingle && options.find(option => option.selected)?.label}
          <ArrowIcon size={16} />
        </FilterButton>
      )}
      content={() => (
        <SectionSelectPanel
          isMulti
          hideSearch
          selectType="toggle"
          options={options}
          onSelectOption={option => setSectionFilter({ [option.value]: true })}
          onUnselectOption={option => setSectionFilter({ [option.value]: false })}
        />
      )}
    />
  );
};
