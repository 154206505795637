import { ReactNode, useCallback, useEffect, useState } from 'react';

import { EditorContainer, BUBBLE_MENU_NOT_INTERACTIVE_CLASS } from 'src/components/Editor/Editors/Editor.styles';

export const EditorBubbleContainer = ({
  children, className, disabled,
}: {
  disabled?: boolean;
  className?: string;
  children: (onMouseDown: VoidFunction) => ReactNode;
}) => {
  const [isBubbleInteractive, setIsBubbleInteractive] = useState(false);
  const onMouseDown = useCallback(() => setIsBubbleInteractive(false), []);
  const onMouseUp = useCallback(() => setIsBubbleInteractive(true), []);

  // If the mouse is released outside of Content div, the event won't fire from Content onMouseUp prop
  useEffect(() => {
    if (!isBubbleInteractive) {
      document.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isBubbleInteractive, onMouseUp]);

  return (
    <EditorContainer
      disabled={disabled}
      // Workaround to fix BubbleMenu bug: https://github.com/ueberdosis/tiptap/issues/1493#issuecomment-963965589
      className={isBubbleInteractive ? className : `${className} ${BUBBLE_MENU_NOT_INTERACTIVE_CLASS}`}
    >
      {children(onMouseDown)}
    </EditorContainer>
  );
};
