import { ViewType } from 'src/types/viewType.types';

import {
  Top,
  Side,
  RightSide,
  TitleSkeleton,
  BoardConfigSkeleton,
} from './Board.styles';
import { BoardContentSkeleton } from './BoardContent/BoardContentSkeleton';

const BoardSkeleton = () => (
  <>
    <Top>
      <Side>
        <TitleSkeleton />
      </Side>

      <RightSide>
        <BoardConfigSkeleton />
      </RightSide>
    </Top>

    <BoardContentSkeleton viewType={ViewType.List} />
  </>
);

export default BoardSkeleton;
