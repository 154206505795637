import { ActionButton, typo, ShyScrollbarCss } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { FOOTER_HEIGHT } from 'src/components/MobileFooter/MobileFooter.styles';
import { HEADER_PRIMARY_HEIGHT } from 'src/components/MobileHeader/MobileHeader.styles';

export const Container = styled.div<{ $variant?: 'primary' | 'secondary' }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  ${mobile} {
    padding-top: ${HEADER_PRIMARY_HEIGHT}px;
    padding-bottom: ${FOOTER_HEIGHT}px;
  }

  ${p => p.$variant === 'primary' && `
    background: ${p.theme.colors.background.primary};
  `};
`;

export const Header = styled.header`
  flex: none;
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 16px;
  padding: 16px 24px;
  height: 60px;
  z-index: 2;

  ${mobile} {
    display: none;
  }
`;

export const Title = styled.h1`
  ${typo.headerLight};
  color: ${p => p.theme.colors.text.primary};
`;

export const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TopRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ViewContainer = styled.div<{ $isScrollable?: boolean }>`
  ${p => p.$isScrollable && ShyScrollbarCss}
  flex: 1;
`;

export const HeaderActionButton = styled(ActionButton)<{ $isNewInbox: boolean }>`
  width: 28px;
  height: 28px;
  --bg: ${p => p.theme.colors.background.tertiary};
  --color: ${p => p.theme.colors.text.secondary};
  border-radius: 6px;
  ${p => p.$isNewInbox && `
    --bg: ${p.theme.colors.background.secondary};
  `};
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Action = styled(ActionButton)`
  --colorHover: ${p => p.theme.colors.text.secondary};
  margin-top: 1px;
`;
