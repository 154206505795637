import { ActionButton, typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 16px 20px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  margin-top: 9px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1.4;
`;

export const Middle = styled.div`
  flex: 1.4;
  position: relative;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex: 1;
`;

type PlayerContainerProps = {
  $isVisible: boolean;
  $hasCursor?: boolean;
};
export const PlayerContainer = styled.div<PlayerContainerProps>`
  opacity: ${p => (p.$isVisible ? 1 : 0)};
  width: 120px;
  height: 52px;
  position: absolute;
  ${p => p.$hasCursor && css`cursor: pointer;`}
  /* background: pink; */
`;

export const RecordingPlayerContainer = styled(PlayerContainer)`
  /* background: chocolate; */
`;

export const Title = styled.h6`
  ${typo.headerLight}
`;

export const Time = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

type TrashButtonProps = {
  $isVisible: boolean;
};
export const TrashButton = styled(ActionButton).attrs({ size: 'L' })<TrashButtonProps>`
  opacity: ${p => (p.$isVisible ? 1 : 0)};
`;

export const RecordButton = styled.button.attrs({
  type: 'button',
})`
  width: 40px;
  height: 40px;
  background-color: hsla(var(--cycle), 1);
  flex-shrink: 0;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${mobile} {
    width: 28px;
    height: 28px;
  }
`;

export const Stop = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: white;
`;
