import { typo } from '@cycle-app/ui';
import { OfflineIcon as OfflineIconUI } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const OfflineIcon = styled(OfflineIconUI)`
  color: ${p => p.theme.colors.border.grey};
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  ${typo.headerSmall}
  font-weight: 500;
  color: ${p => p.theme.colors.text.disabled};
  margin-bottom: 16px;
`;
