import { ReactNode } from 'react';

import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { Root } from './ChangelogBuilderContentNote.styles';

export const ChangelogBuilderContentNote = ({ children }: { children: ReactNode }) => {
  const { watch } = useChangelogBuilderFormContext();
  const contentSection = watch('contentSection');
  return (
    <Root $contentSection={contentSection}>
      {children}
    </Root>
  );
};
