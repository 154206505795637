import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import ContentEditable from 'src/components/ContentEditable/ContentEditable';

export const StyledContentEditable = styled(ContentEditable)`
  margin-bottom: 10px;
  outline: none;
  width: 100%;
  ${typo.headerLarge};
`;
