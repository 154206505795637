import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DoubleCaretIcon: FC<SvgProps> = ({
  direction = 'bottom', ...props
}) => (
  <Svg
    direction={direction}
    width="16"
    height="16"
    viewBox="2 2 12 12"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    fill="none"
    {...props}
  >
    <path
      d="M5.3335 5.99992L8.00016 3.33325L10.6668 5.99992M10.6668 9.99992L8.00016 12.6666L5.3335 9.99992"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
