import { Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`;

export const Submit = styled(Button)`
  margin-top: 24px;
  margin-bottom: 15px;
  margin-left: auto;
`;
