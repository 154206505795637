import { motion, Transition } from 'framer-motion';
import pick from 'lodash/pick';
import { useRef } from 'react';

import Portal from 'src/components/Portal/Portal';
import { useEscape } from 'src/hooks/useEscape';
import { setLayer } from 'src/reactives/layer.reactive';
import { getZoom, resetZoom, setZoom, toggleZoom, useZoom } from 'src/reactives/zoom.reactve';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';

import { Overlay, ImageWrapper } from './ZoomImage.styles';

const transition: Transition = {
  type: 'spring',
  damping: 28,
  stiffness: 200,
};

export const ZoomImage = () => {
  const {
    isZoomed, src, getRect,
  } = useZoom();

  const loaded = useRef(false);

  const zoomOut = () => {
    loaded.current = true;
    toggleZoom();
    getZoom().onZoomOut?.();
  };

  useEscape(zoomOut, Layer.Zoom);

  return (
    <Portal portalId={PortalId.Zoom}>
      {src && (
        <>
          <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: isZoomed ? 0.8 : 0 }}
            transition={transition}
            onAnimationComplete={(def: { opacity: number }) => {
              if (!getZoom().isZoomed && def.opacity === 0 && loaded.current) {
                resetZoom();
                loaded.current = false;
                setLayer(Layer.Zoom, false);
              }
            }}
          />

          <ImageWrapper
            onLoad={() => {
              setZoom({ isZoomed: true });
            }}
            onMouseDown={e => {
              // only zoom on left click or single touch
              if (e.button !== 0) return;
              zoomOut();
            }}
            style={isZoomed ? {
              inset: 0,
            } : {
              ...pick(getRect?.(), 'top', 'left', 'width', 'height'),
            }}
          >
            <motion.img
              src={src}
              alt=""
              layout
              transition={transition}
              style={isZoomed ? {
                position: 'fixed',
                inset: 0,
                maxWidth: '100%',
                maxHeight: '100%',
                margin: 'auto',
                objectFit: 'scale-down',
                borderRadius: 0,
              } : {
                borderRadius: 6,
                position: 'absolute',
                inset: 0,
                ...pick(getRect?.(), 'width', 'height'),
                objectFit: 'cover',
              }}
            />
          </ImageWrapper>
        </>
      )}
    </Portal>
  );
};
