import styled, { css } from 'styled-components';

import PortalModal from '../PortalModal/PortalModal';

export const StyledModal = styled(PortalModal)<{ $alignLeft: boolean }>`
  position: absolute;
  top: 64px;
  right: 24px;
  max-height: inherit;
  background: ${p => p.theme.colors.docPanel.bg};
  padding: 0;

  ${p => p.$alignLeft && css`
    top: 12px;
    right: calc(50vw + 16px);
  `}
`;
