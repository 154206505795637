import { Skeleton } from '@cycle-app/ui';
import { ComponentProps } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0 0;
  > :first-child {
    display: flex;
    gap: 12px;
    > :first-child {
      flex: none;
    }
  }
`;

const StyledSkeleton = styled(Skeleton)`
  height: 40px;
  border-radius: 7px;
`;

type Props = ComponentProps<typeof Container> & {
  showEmoji?: boolean;
};

export const BoardConfigSkeletons = ({
  showEmoji = false, ...props
}: Props) => (
  <Container {...props}>
    <div>
      {showEmoji && <StyledSkeleton width={40} />}
      <StyledSkeleton />
    </div>
    {[...Array(12).keys()].map(i => (
      <StyledSkeleton key={i} />
    ))}
  </Container>
);
