import { getMentionExtension as getExtension, MENTION_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import { FC } from 'react';

import MentionName from 'src/components/Editor/NodeViews/Mention/MentionView';
import { COMMANDS } from 'src/constants/editor.constants';
import { suggestionPlugin } from 'src/plugins/suggestionPlugin';
import { renderMentionDropdown } from 'src/utils/mention.utils';

import MentionDropdown from './MentionUserDropdown';

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

interface MentionParams {
  readOnly?: boolean;
  onUserMentioned?: (userId: string) => void;
  isMobile?: boolean;
}

export const getMentionExtension = ({
  readOnly = false,
  onUserMentioned,
  isMobile,
}: MentionParams) => getExtension().extend<MentionOptions>({
  addOptions(): MentionOptions {
    return {
      ...this.parent?.(),
      suggestion: {
        char: COMMANDS.USER_MENTION,

        allowSpaces: false,

        command: ({
          editor,
          range,
          props,
        }) => {
          if (!props.id) return;
          onUserMentioned?.(props.id);
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: MENTION_EXTENSION_NAME,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();
        },

        allow: ({
          editor,
          range,
        }) => editor.can().insertContentAt(range, { type: MENTION_EXTENSION_NAME }),

        render: () => renderMentionDropdown(MentionDropdown as FC<React.PropsWithChildren<unknown>>, {
          isMobile,
          readOnly,
        }),
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(MentionName);
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({
        tr, state,
      }) => {
        let isMention = false;
        const { selection } = state;
        const {
          empty, anchor,
        } = selection;

        if (!empty) {
          return false;
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true;
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize);

            return false;
          }
          return false;
        });

        return isMention;
      }),
    };
  },

  addProseMirrorPlugins() {
    return [
      suggestionPlugin({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKeyName: `suggestion-${this.name}`,
      }),
    ];
  },
});
