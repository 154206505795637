import { tablet } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div<{ $cols?: 2 | 3 }>`
  display: grid;
  grid-template-columns: ${p => (p.$cols === 3 ? '1fr 1fr 1fr' : '1fr 1fr')};
  gap: 16px;
  margin-left: 32px;
  margin-right: 32px;

  ${tablet} {
    grid-template-columns: 1fr;
  }
`;
