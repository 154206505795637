import { MeFragment, UserJourney } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { GoogleIcon } from '@cycle-app/ui/icons';

import { GOOGLE_SSO_URL } from 'src/constants/sso.constants';
import { useUpdateMe } from 'src/hooks/user/useUpdateMe';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { isInIframe } from 'src/utils/iframe';
import { isUserOnboardedWithoutWorkspace } from 'src/utils/users.util';

import { Content, Body, ButtonOutline } from './WelcomeSso.styles';
import { Chat } from '../OnboardingLayout/Chat';
import { Title } from '../OnboardingLayout/OnboardingLayout.styles';
import { Policy } from '../OnboardingLayout/Policy';

type OnboardingStepWelcomeProps = {
  me: MeFragment | null;
};

export const WelcomeSso = ({ me }: OnboardingStepWelcomeProps) => {
  const { updateMe } = useUpdateMe();
  return (
    <div className="flex items-center justify-center">
      <Content>
        <Body>
          <Title>Set up your account</Title>
          <div className="mt-16 flex flex-col items-center gap-4">
            <div className="select-text text-body font-medium">
              You need a work email to sign up to Cycle
            </div>
            {!isInIframe() && (
              <ButtonOutline
                size="L"
                variant="outlined-alt"
                full
                onClick={() => {
                  window.location.href = GOOGLE_SSO_URL;
                }}
              >
                <GoogleIcon />
                Continue with Google
              </ButtonOutline>
            )}
            <Button
              full
              onClick={() => {
                if (me?.firstName && me?.lastName && isUserOnboardedWithoutWorkspace(me)) {
                  setOnboarding({ screen: LightOnboardingScreen.Workspace });
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  updateMe({ userJourney: UserJourney.Onboarding });
                  return;
                }
                setOnboarding({ screen: LightOnboardingScreen.AccountVerify });
              }}
              size="L"
            >
              Continue with work email
            </Button>
          </div>
          <Policy />
        </Body>
        <Chat />
      </Content>
    </div>
  );
};
