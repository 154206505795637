import { ConfirmEmailDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

export const useConfirmEmail = () => {
  const [confirmEmailMutation, {
    loading: isLoading, error,
  }] = useSafeMutation(ConfirmEmailDocument);

  const confirmEmail = (email: string, code: string) => confirmEmailMutation({
    variables: {
      email,
      code,
    },
    errorPolicy: 'all',
  });

  return {
    confirmEmail,
    isLoading,
    error,
  };
};
