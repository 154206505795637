import { useFormContext } from 'react-hook-form';

import { ChangelogBuilderFormData } from './useChangelogBuilderForm';

/**
 * The builder is included inside a FormProvider,
 * For performance, create a component for each field of the form:
 *
 * @example
 *
 * ```tsx
 * const Title = () => {
 *  const { watch } = useChangelogBuilderFormContext();
 *  return <div>{watch('title')}</div>
 * }
 *
 * const Subtitle = () => {
 *  const { watch } = useChangelogBuilderFormContext();
 *  return <div>{watch('subtitle')}</div>
 * }
 *
 * ```
 */
export const useChangelogBuilderFormContext = () => {
  return useFormContext<ChangelogBuilderFormData>();
};
