import { BoardWithMinimalConfigFragment, BoardSectionsFragment, BoardSectionFragment } from '@cycle-app/graphql-codegen';
import { insert } from 'ramda';

interface AddBoardSectionArgs {
  atIndex: number;
  sectionId: string;
  board: BoardWithMinimalConfigFragment;
}
export function addBoardToSection(sections: BoardSectionsFragment['edges'], {
  atIndex,
  sectionId,
  board,
}: AddBoardSectionArgs): BoardSectionsFragment['edges'] {
  return sections
    .map(edge => (edge.node.id === sectionId
      ? {
        ...edge,
        node: updateSectionBoards(
          edge.node,
          insert(
            atIndex,
            {
              __typename: 'BoardEdge',
              node: {
                ...board,
                isBuiltIn: false,
              },
            },
            edge.node.boards.edges,
          ),
        ),
      }
      : edge));
}

export function removeBoardFromSection(sections: BoardSectionsFragment['edges'], boardId: string): BoardSectionsFragment['edges'] {
  return sections
    .map(edge => ({
      ...edge,
      node: updateSectionBoards(
        edge.node,
        edge.node.boards.edges.filter(({ node }) => node.id !== boardId),
      ),
    }));
}

/**
 * Private functions
 */

function updateSectionBoards(section: BoardSectionFragment, boardsEdges: BoardSectionFragment['boards']['edges']): BoardSectionFragment {
  return {
    ...section,
    boards: {
      ...section.boards,
      edges: boardsEdges,
    },
  };
}
