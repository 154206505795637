import { AddIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useFeedbackDocType } from 'src/hooks';
import { useGetPermission } from 'src/reactives';

import { CollectMoreFeedback } from '../CollectMoreFeedback';
import {
  Container, Title, Subtitle, Content, ContainerInner, Action,
} from './InboxZero.styles';
import { InboxZeroSources } from './InboxZeroSources';

export const InboxZero: FC<React.PropsWithChildren<{ onClick: VoidFunction }>> = ({ onClick }) => {
  const { canReadSettings } = useGetPermission();
  const feedback = useFeedbackDocType();
  const feedbackName = feedback?.name ?? 'Feedback';

  return (
    <Container>
      <ContainerInner>
        <InboxZeroSources />

        <Content>
          <Title>
            Meet your inbox
          </Title>
          <Subtitle>
            All of your feedback, all in one place ✌️
          </Subtitle>
        </Content>

        <Action
          iconStart={<AddIcon size={14} />}
          onClick={onClick}
        >
          {`New ${feedbackName}`}
        </Action>

        {canReadSettings && <CollectMoreFeedback />}
      </ContainerInner>
    </Container>
  );
};
