import { typo, boxShadowZ1 } from '@cycle-app/ui';
import { mobile, ColorTheme } from '@cycle-app/utilities';
import { darken } from 'polished';
import styled from 'styled-components';

import PortalModalUI from 'src/components/PortalModal/PortalModal';

const MODAL_PADDING = '20px';

export const PortalModal = styled(PortalModalUI)`
  width: 382px;
  padding: ${MODAL_PADDING};
  background-color: ${p => p.theme.colors.components.LearningCenterModal.bg};
  
  ${mobile} {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: inherit;
  > *:not(:first-child) {
    padding-top: 24px;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;

  ${mobile} {
    position: sticky;
    top: -${MODAL_PADDING};
    margin: -${MODAL_PADDING};
    padding: ${MODAL_PADDING};
    z-index: 1;
    background: inherit;
  } 
`;

export const Title = styled.h2`
  ${typo.headerSmall}
`;

export const SubTitle = styled.h3`
  ${typo.headerLight}
`;

export const MoreSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled.li`
  width: 100%;
`;

export const Link = styled.a`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  display: inline-flex;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 4px;

  &:hover, &:active, &:focus {
    cursor: pointer;
    background-color: ${p => p.theme.colors.components.LearningCenterModal.bgHover};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const ArticleCard = styled.a`
  display: flex;
  gap: 12px;
  border-radius: 10px;
  padding: 4px;
  width: 100%;

  &:hover, &:active, &:focus {
    cursor: pointer;
    background-color: ${p => p.theme.colors.components.LearningCenterModal.bgHover};
  }
`;

export const ArticleEmojiContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.background.tertiary};
  border-radius: 6px;
  width: 48px;
  height: 48px;
`;

export const ArticleBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArticleTitle = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const ArticleDescription = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MoreButton = styled.a`
  ${typo.body500}
  color: hsl(var(--cycle));
`;

export const HelpButton = styled.button`
  --bg: ${p => (p.theme.isDark ? ColorTheme.Grey800 : p.theme.colors.button.noColor.bg)};
  --color: ${p => (p.theme.isDark ? ColorTheme.White : p.theme.colors.button.noColor.color)};
  --border: ${p => (p.theme.isDark ? p.theme.colors.border.surprisingGreyDark : 'rgba(0,0,0,0.06)')};
  display: flex;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--border);
  background: var(--bg);
  color: var(--color);
  ${boxShadowZ1}
  ${typo.body500}
  width: 100%;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    --bg: ${p => p.theme.colors.button.outlined.bgHover};
    --color: ${p => p.theme.colors.button.noColor.color};
    --border: ${p => (p.theme.isDark ? p.theme.colors.border.surprisingGreyDark : 'rgba(0,0,0,0.06)')};
  }
  &:focus {
    --bg: ${p => darken(0.08, p.theme.colors.button.outlined.bgHover)};
  }
  &:active {
    --bg: ${p => darken(0.12, p.theme.colors.button.outlined.bgHover)};
  }
`;
