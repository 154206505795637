import { useQuery } from '@apollo/client';
import {
  DocBaseFragment, Property, GroupFilteredFragment, GetSwimlanesDocument, DoctypeType, DocInGroupFragment,
} from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { isPresent } from 'ts-is-present';

import { MESSAGE_CREATE_DOC_DISABLED_BOARD_CONFIG, MESSAGE_CREATE_DOC_DISABLED_INSIGHT } from 'src/constants/boardGroups.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useProduct } from 'src/hooks/api/useProduct';
import { getAuth } from 'src/reactives/auth.reactive';
import { extract } from 'src/types/graphql.types';
import { getDocKey } from 'src/utils/doc.util';
import { defaultPagination } from 'src/utils/pagination.util';

import { useProductDoctypes } from './useProductDoctypes';

export default function useBoardWithSwimlane() {
  const { product } = useProduct();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const loading = useBoardConfig(ctx => ctx.loading);
  const builtInDisplay = useBoardConfig(ctx => ctx.builtInDisplay);
  const hasFilterPreventingNewDoc = useBoardConfig(ctx => ctx.hasFilterPreventingNewDoc);
  const doctypesFromProduct = useProductDoctypes();

  // That query would seem useless, but if we fetchMore using the boardConfig query (which would be ideal), it's not working.
  // -> It's weird but having "hiddenSwimlanes" field (SwimlanebyConfig) in the board config query makes fetchMore triggering
  // a new query without the swimlanes cursor (which prevents the second page from being merged into the cache)
  // to be fetched.
  // TODO: investiguate
  const {
    data,
    fetchMore: fetchMoreSwimlanes,
  } = useQuery(GetSwimlanesDocument, {
    fetchPolicy: 'cache-only',
    skip: !boardId,
    variables: {
      ...defaultPagination,
      boardId: boardId as string,
    },
  });

  if (boardConfig?.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') {
    throw new Error('useBoardWithSwimlane hook must be used in a board config with an active swimlane');
  }

  const dataBoardConfig = extract('Board', data?.node)?.publishedBoardConfig;

  const swimlanes =
    dataBoardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy' &&
      dataBoardConfig.docQuery.swimlanes
      ? dataBoardConfig.docQuery.swimlanes
      : boardConfig.docQuery.swimlanes;
  const groupByProperty = boardConfig.docQuery.groupbyConfig.property as Property;

  const swimlaneIds: string[] = useMemo(() => swimlanes.edges.map(edge => edge.node.id), [swimlanes]);
  const swimlaneFlattenGroups = useMemo(() => swimlanes.edges.flatMap(({ node }) => node.docGroups.edges), [swimlanes]);

  const docsFromGroup = useMemo<Record<string, DocBaseFragment>>(() => swimlaneFlattenGroups
    .flatMap(docGroup => docGroup.node.docs.edges)
    .reduce(
      (acc, docGroupItem) => ({
        ...acc,
        [docGroupItem.node.id]: docGroupItem.node,
      }),
      {},
    ), [swimlaneFlattenGroups]);

  const docsFromSwimlane = useMemo<Record<string, DocInGroupFragment>>(() => swimlanes.edges
    .map(edge => edge.node.swimlaneDoc)
    .filter(isPresent)
    .reduce(
      (acc, d) => ({
        ...acc,
        [d.id]: d,
      }),
      {},
    ), [swimlanes]);

  const docs = useMemo(() => (
    {
      ...docsFromGroup,
      ...docsFromSwimlane,
    }
  ), [docsFromGroup, docsFromSwimlane]);

  /**
   *  Get aggregated data
   * */
  const getDoc = useCallback<(docId: string) => DocInGroupFragment | undefined>((docId) => {
    const doc = docs[docId];
    return doc
      ? {
        ...doc,
        _docKey: getDocKey(product?.key, doc?.publicId),
      } : undefined;
  }, [docs, product?.key]);

  const groups: GroupFilteredFragment[] = useMemo(() => {
    if (boardConfig.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return [];
    return swimlanes.edges[0]?.node.docGroups.edges.map(({ node }) => node) ?? [];
  },
  [boardConfig.docQuery.__typename, swimlanes]);

  const getReasonDocCreationDisabled = useCallback((
    group: GroupFilteredFragment, doctypeChildrenId: string[],
  ): string | undefined => {
    const doctypeChildren = doctypeChildrenId.map(id => doctypesFromProduct.find(doctype => doctype.id === id));
    const insightGroup = doctypeChildren.find(doctype => (
      doctype?.type === DoctypeType.Insight && group.propertyValue?.__typename === 'Doctype' && doctype.id === group.propertyValue.id
    ));
    const insightChildOnly = doctypeChildren.length === 1 && doctypeChildren[0]?.type === DoctypeType.Insight ? doctypeChildren[0] : null;
    if ((insightGroup || insightChildOnly)) {
      return MESSAGE_CREATE_DOC_DISABLED_INSIGHT;
    }
    if (hasFilterPreventingNewDoc) {
      return MESSAGE_CREATE_DOC_DISABLED_BOARD_CONFIG;
    }
    if (groupByProperty?.__typename === 'CreatorDefinition' && group.propertyValue?.id !== getAuth().userId) {
      return MESSAGE_CREATE_DOC_DISABLED_BOARD_CONFIG;
    }
    return undefined;
  }, [hasFilterPreventingNewDoc, groupByProperty, doctypesFromProduct]);

  /*
  * Fetch more data callbacks
  */
  const moreSwimlanes = useAsyncCallback(async () => {
    if (!boardId || moreSwimlanes.loading || boardConfig.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return;

    await fetchMoreSwimlanes({
      variables: {
        cursorSwimlaneGroups: boardConfig.docQuery.swimlanes.pageInfo.endCursor ?? null,
      },
    });
  });

  return {
    boardConfigId: boardConfig.id,
    swimlanebyConfig: boardConfig.docQuery.swimlanebyConfig,
    groups,
    swimlanes,
    swimlaneFlattenGroups,
    swimlaneIds,
    builtInDisplay,
    loading,
    getDoc,
    groupByProperty,

    hasFilterPreventingNewDoc,
    getReasonDocCreationDisabled,

    moreSwimlanes,
  };
}
