import { DoctypeType, UserJourney } from '@cycle-app/graphql-codegen';
import { CommentAltIcon, BulbIcon, QuestionOutlineIcon, SearchIcon } from '@cycle-app/ui/icons';
import { FC, MouseEventHandler, useReducer } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SidebarHeaderProduct } from 'src/app/Main/Sidebar/SidebarHeader/SidebarHeaderProduct';
import { SidebarHeaderUser } from 'src/app/Main/Sidebar/SidebarHeader/SidebarHeaderUser';
import { DocOptions } from 'src/components/DocOptions';
import { Notifications } from 'src/components/Notifications';
import { PageId, routing } from 'src/constants/routing.constant';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useDocInsightsCount, useMe } from 'src/hooks';
import { openCommandBar } from 'src/hooks/modals/useCommandBarModal';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { useNavigate } from 'src/hooks/useNavigate';
import { useUpdateMe } from 'src/hooks/user/useUpdateMe';
import { useGetDocPanel, Content, setDocPanel } from 'src/reactives/docRightPanel.reactive';
import { openLearningCenter } from 'src/reactives/learningCenter.reactive';
import { closeMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { getCommentsCount } from 'src/utils/comments.utils';
import { isFeedback, hasInsightChildren } from 'src/utils/docType.util';

import {
  MobileHeaderHomeContainer,
  MobileHeaderDocContainer,
  Left,
  Right,
  CloseButton,
  Middle,
  HeaderTitle,
  Action,
  StyledActionButton,
  CongratsCard,
  Count,
} from './MobileHeader.styles';
import { DocPanelComments } from '../DocComments/DocComments';

export const MobileHeader = () => {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return <MobileHeaderRouter />;
};

const MobileHeaderRouter = () => {
  return (
    <Switch>
      <Route path={routing[PageId.Main]} exact>
        <MobileHeaderHome hasShadow />
      </Route>
      <Route path={routing[PageId.InboxView]} exact>
        <MobileHeaderHome />
      </Route>
      <Route path={routing[PageId.InsightView]} exact>
        <MobileHeaderHome />
      </Route>
      <Route path={routing[PageId.RoadmapView]} exact>
        <MobileHeaderHome />
      </Route>
      <Route path={routing[PageId.Board]}>
        <MobileHeaderHome />
      </Route>
      <Route path={routing[PageId.DocFullPage]} exact>
        <MobileHeaderDoc />
      </Route>
    </Switch>
  );
};

type MobileHeaderHomeProps = {
  hasShadow?: boolean;
};

const MobileHeaderHome: FC<React.PropsWithChildren<MobileHeaderHomeProps>> = ({ hasShadow }) => {
  const { me } = useMe();
  const { updateMe } = useUpdateMe();
  const [isCongratsOpen, closeCongrats] = useReducer(
    () => false,
    me.userJourney === UserJourney.ProductTour,
  );

  return (
    <MobileHeaderHomeContainer $hasShadow={hasShadow}>
      <Left>
        <SidebarHeaderProduct />
      </Left>
      <Right>
        <StyledActionButton
          highlighted={isCongratsOpen}
          onClick={() => {
            closeCongrats();
            openLearningCenter();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateMe({ userJourney: UserJourney.Done });
          }}
        >
          <QuestionOutlineIcon size={18} />
        </StyledActionButton>

        <StyledActionButton
          onClick={() => {
            openCommandBar();
            closeMobileAsideMenu();
          }}
        >
          <SearchIcon size={16} />
        </StyledActionButton>

        <Notifications />
        <SidebarHeaderUser />
      </Right>

      {isCongratsOpen && (
        <CongratsCard>
          <p role="heading">Congrats ! 🎉</p>
          <p>You’re now ready to play around with Cycle.</p>
          <p>Open the Learning center to get started.</p>
        </CongratsCard>
      )}
    </MobileHeaderHomeContainer>
  );
};

const titleContent: Record<Content, string> = {
  [Content.Activity]: 'Activity',
  [Content.Comments]: 'Comments',
  [Content.Insights]: 'Insights',
};

const MobileHeaderDoc = () => {
  const {
    isExpanded, content,
  } = useGetDocPanel();
  const {
    doc,
    isUploadingCover,
    onOpenCoverInputFile,
  } = useDocPanelProps();
  const { count: docInsightsCount } = useDocInsightsCount({
    docId: doc?.id,
    skip: !!doc?.id && doc?.doctype.type !== DoctypeType.Feedback,
  });
  const { navigateBack } = useNavigate();

  const onInsightsClick = () => setDocPanel({
    isExpanded: true,
    content: Content.Insights,
  });
  const onClose: MouseEventHandler = () => {
    if (!isExpanded) {
      navigateBack();
      return;
    }
    setDocPanel({ isExpanded: false });
  };

  const title = isExpanded
    ? (
      <HeaderTitle>
        {titleContent[content]}
        <Count>
          {docInsightsCount && content === Content.Insights ? ` ${docInsightsCount}` : ''}
          {doc && getCommentsCount(doc) && content === Content.Comments ? ` ${getCommentsCount(doc)}` : ''}
        </Count>
      </HeaderTitle>
    )
    : null;

  const insightsCount = (doc?.insightsCount || doc?.docTargetsCount) ?? 0;
  const insights = doc &&
    !isExpanded &&
    (isFeedback(doc.doctype) || hasInsightChildren(doc.doctype))
    ? (
      <Action onClick={onInsightsClick}>
        <BulbIcon size={16} />
        {insightsCount > 0 && <Count>{insightsCount}</Count>}
      </Action>
    )
    : null;
  const actions = doc && !isExpanded
    ? (
      <BoardConfigContextProvider>
        <DocOptions
          doc={doc}
          context="doc-panel"
          onAddCoverClicked={onOpenCoverInputFile}
          isLoading={isUploadingCover}
        />
      </BoardConfigContextProvider>
    )
    : null;

  return (
    <MobileHeaderDocContainer>
      <Left>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </Left>
      <Middle>
        {title}
      </Middle>
      <Right>
        {doc && (
          <DocPanelComments
            docId={doc.id}
            nbComments={getCommentsCount(doc)}
            tooltipPlacement="bottom"
            size="L"
            showLabel={false}
            buttonIcon={<CommentAltIcon />}
            maxHeight="70vh"
            className="doc-panel-comments"
          />
        )}

        {insights}
        {actions}
      </Right>
    </MobileHeaderDocContainer>
  );
};
