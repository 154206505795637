import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { CommentEditorContainer } from 'src/components/Editor/Editors/Editor.styles';

type ContainerProps = {
  $hasError: boolean;
};

export const Container = styled.div<ContainerProps>`
  border: 2px solid ${p => (p.$hasError ? p.theme.colors.text.red : 'hsla(var(--cycle), 0.6)')};
  border-radius: var(--s-3);
  padding: var(--s-3);
  background: ${p => p.theme.colors.input.bgAlt};
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${CommentEditorContainer} {
    cursor: text;
    .ProseMirror {
      padding: 0 !important;
    }
    .ProseMirror p {
      cursor: text;
    }
  }
`;

export const InputFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
`;

export const Error = styled.div`
  ${typo.caption400};
  color: ${p => p.theme.colors.text.red};
  margin-top: 4px;
  min-height: 18px;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: var(--s-3);
`;
