import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.authLayout.content.bg};
  display: flex;
  height: 100vh;
  width: 100vw;
  color: ${(p) => p.theme.colors.authLayout.content.color};
`;

export const Content = styled.div<{ $hasResponsive: boolean }>`
  position: relative;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  ${mobile} {
    padding: ${p => (p.$hasResponsive ? '12px 32px' : 0)};
  }
`;
