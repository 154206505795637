import { Button } from '@cycle-app/ui';
import { FC } from 'react';

import { Container, Text, OfflineIcon } from './OfflineState.styles';

interface OfflineStateProps {
  hideRetry?: boolean;
}

export const OfflineState: FC<React.PropsWithChildren<OfflineStateProps>> = ({ hideRetry = false }) => (
  <Container>
    <OfflineIcon />
    <Text>
      No internet connection
    </Text>
    {!hideRetry && (
      <Button onClick={() => window.location.reload()}>
        Retry
      </Button>
    )}
  </Container>
);
