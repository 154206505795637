import { ThemeType } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { setThemeConfig, useGetThemeConfig } from 'src/reactives/theme.reactive';

export const useSyncThemeWithSystem = ({
  setTheme, isEnabled,
}: {
  setTheme: (theme: ThemeType) => void;
  isEnabled: boolean;
}) => {
  useEffect(() => {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme:dark)');

    const onChange = (e: MediaQueryListEvent) => {
      if (!isEnabled) return;
      setTheme(e.matches ? ThemeType.Nightfall : ThemeType.Sunrise);
    };

    darkModeQuery.addEventListener('change', onChange);
    return () => darkModeQuery.removeEventListener('change', onChange);
  }, [isEnabled, setTheme]);
};

export const useSyncAppThemeWithSystem = () => {
  const { syncWithSystem } = useGetThemeConfig();
  useSyncThemeWithSystem({
    setTheme: colorTheme => setThemeConfig({ colorTheme }),
    isEnabled: syncWithSystem,
  });
};
