import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CancelCircleIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M12.5 7.49967L7.50004 12.4997M12.5 12.4997L7.50004 7.49967M17.9167 9.99967C17.9167 14.3719 14.3723 17.9163 10 17.9163C5.62779 17.9163 2.08337 14.3719 2.08337 9.99967C2.08337 5.62742 5.62779 2.08301 10 2.08301C14.3723 2.08301 17.9167 5.62742 17.9167 9.99967Z"
      stroke="currentcolor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
