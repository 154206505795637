import { ellipsis, Input, InputComponents } from '@cycle-app/ui';
import styled from 'styled-components';

import { ToggleDropdown } from '../DropdownLayer';

export const StyledToggleDropdown = styled(ToggleDropdown)`
  /* prevent alpha slider tooltip beeing croped */
  overflow: visible;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
`;

export const ItemLabel = styled.div`
  ${ellipsis}
  margin-top: 6px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const ColorInput = styled(Input)`
  ${InputComponents.IconBeforeContainer} {
    pointer-events: none;
  }

  input {
    --borderColor: ${p => (p.theme.isDark ? p.theme.colors.border.primary : p.theme.baseColors.Grey200)};
    --bgColor: transparent;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 36px;
  }

  input:hover {
    --bgColor: tranparent;
  }
`;

export const ColorValue = styled.div`
  flex: none;
  border-radius: 4px;
  border: 1px solid ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.baseColors.Grey200)};
  height: 20px;
  width: 20px;
  transition: background-color .2s;
`;
