import { Input, Tooltip, TextHighlighter, ToggleInput } from '@cycle-app/ui';
import { SearchIcon } from '@cycle-app/ui/icons';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { LinearInfos } from 'src/components/DocLinear/LinearSearch';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useLinearIssues } from 'src/hooks/api/queries/integrations/useLinearIssues';
import {
  useGetLinearImportModal, setLinearImportNextStep,
  useGetLinearImportDocTypes, setLinearImportDocTypes,
  useGetLinearImportSelection, setLinearImportSelection,
} from 'src/reactives/linearImport.reactive';

import { LinearImportHeader } from './LinearImportHeader';
import {
  Content, Separator, Scrollable, Line,
  Label, Name, InfoCaption, InfoIcon,
  ImportButton, SkipButton,
} from './LinearImportModal.styles';
import { docsData } from './LinearImportModal.utils';
import { LinearImportSkeletons } from './LinearImportSkeletons';
import { SelectDocType } from './SelectDocType';
import { useStartImportLinear } from './useStartImportLinear';

export const LinearImportIssues = () => {
  const { isSkippable } = useGetLinearImportModal();
  const { selectedIssueIds } = useGetLinearImportSelection();
  const { issuesDocTypeId } = useGetLinearImportDocTypes();
  const integrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useDebouncedCallback(setSearchText, INPUT_ONCHANGE_DEBOUNCE);
  const query = useLinearIssues(integrationId, searchText);
  const isLoading = query.isLoading && query.issues.length === 0;
  const selectedCount = Object.values(selectedIssueIds ?? {}).filter(Boolean).length;
  const startImport = useStartImportLinear();

  // Save issues titles and links
  useEffect(() => {
    for (const issue of query.issues) {
      docsData.set(issue.id, {
        title: issue.title ?? '',
        automationLink: issue.url ?? '',
      });
    }
  }, [query.issues]);

  const isAllChecked = query.issues.length > 0 && query.issues.every(issue => selectedIssueIds.includes(issue.id));

  return (
    <Content>
      <LinearImportHeader />
      <Separator />

      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center">
          <Label style={{ flex: 'none' }}>Import as</Label>
        </div>

        <SelectDocType
          value={issuesDocTypeId}
          onChange={id => setLinearImportDocTypes({ issuesDocTypeId: id })}
        />
      </div>

      <Separator />

      <Input
        autoFocus
        iconBefore={<SearchIcon size={14} />}
        placeholder="Search"
        onChange={e => setSearchTextDebounced(e.target.value)}
      />

      <Line
        onClick={e => {
          e.stopPropagation();
          setLinearImportSelection({
            selectedIssueIds: !isAllChecked
              ? query.issues.map(issue => issue.id)
              : [],
          });
        }}
      >
        <div className="flex items-center gap-2">
          <Label>
            Select all
            {!query.isLoading && (
              <InfoCaption>
                {getIssuesLabel(query.issues.length)}
              </InfoCaption>
            )}
          </Label>
          {query.issues.length >= 50 && (
            <Tooltip
              content="We can only show 50 issues at once"
              placement="top"
              withWrapper={false}
            >
              <InfoIcon />
            </Tooltip>
          )}
        </div>

        <ToggleInput
          id="import-linear-select-all"
          disabled={query.issues.length === 0}
          checked={isAllChecked}
          onClick={e => e.stopPropagation()}
          onChange={() => {}}
          variant="secondary"
        />
      </Line>

      <Separator style={{ margin: '-8px 0' }} />

      <Scrollable>
        {isLoading && <LinearImportSkeletons />}
        {query.issues.map(issue => (
          <Line
            style={{ opacity: query.isFetching ? 0.5 : 1 }}
            key={issue.id}
            onClick={e => {
              e.stopPropagation();
              setLinearImportSelection({
                selectedIssueIds: !selectedIssueIds.includes(issue.id)
                  ? [...selectedIssueIds, issue.id]
                  : selectedIssueIds.filter(id => id !== issue.id),
              });
            }}
          >
            <Label>
              <LinearInfos
                url={issue.url}
                teamName={issue.team?.name}
                status={issue.status}
              />
              <Name>
                <TextHighlighter
                  searchWords={[searchText]}
                  textToHighlight={issue.title ?? ''}
                  className="highlight"
                />
              </Name>
            </Label>

            <ToggleInput
              id={`import-${issue.id}`}
              checked={selectedIssueIds.includes(issue.id)}
              onClick={e => e.stopPropagation()}
              onChange={() => {}}
              variant="secondary"
            />
          </Line>
        ))}
      </Scrollable>

      <ImportButton
        disabled={selectedCount === 0}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          startImport();
          setLinearImportNextStep();
        }}
      >
        {selectedCount > 0 ? `Import ${getIssuesLabel(selectedCount)}` : 'Import'}
      </ImportButton>

      {isSkippable && (
        <SkipButton
          onClick={() => {
            setLinearImportSelection({ selectedIssuesIds: [] });
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            startImport();
            setLinearImportNextStep();
          }}
        >
          Skip
        </SkipButton>
      )}
    </Content>
  );
};

const getIssuesLabel = (count: number) => {
  if (count === 0) return 'No issues';
  if (count === 1) return '1 issue';
  return `${count} issues`;
};
