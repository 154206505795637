import { QuoteFragment, UpdateQuoteDocument } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import { InsightCardContextEdit } from 'src/components/InsightCardContextEdit';
import { useSafeMutation } from 'src/hooks';

import { QuoteContentReadonly } from './QuoteContentReadonly';

type Props = {
  quote: QuoteFragment;
};

export const QuoteContentEditable = ({ quote }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updateQuote] = useSafeMutation(UpdateQuoteDocument);
  return (
    <>
      {!isEditing && (
        <QuoteContentReadonly
          onClick={() => setIsEditing(true)}
          content={quote.content}
        />
      )}

      {isEditing && (
        <InsightCardContextEdit
          defaultValue={quote.content}
          onCancel={() => {
            setIsEditing(false);
          }}
          onUpdate={content => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateQuote({
              variables: {
                id: quote.id,
                content,
              },
              optimisticResponse: {
                updateQuote: {
                  ...quote,
                  content,
                },
              },
            });
            setIsEditing(false);
          }}
        />
      )}
    </>
  );
};
