import { Reference } from '@apollo/client';
import {
  CustomAttributeDefinitionFragment,
  DocBaseFragment,
  RemoveDocAttributeValueDocument,
  RemoveDocAttributeValueMutation,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { pick } from 'ramda';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useMoveDocs } from 'src/hooks/api/mutations/useMoveDoc';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { customAttributeTypeData, getCurrentSelectedValuesFromDocMulti, getDocAttributeId } from 'src/utils/attributes.util';

import { useRemoveDocFromBoardDocsList } from '../cache/cacheBoardDocsList';
import { useGetGroup, useUpdateDocsGroup } from '../cache/cacheGroupHooks';
import { BoardGroup, useBoardGroups } from '../useBoardGroups';

export const useRemoveDocAttributeValue = () => {
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const { groups } = useBoardGroups();
  const getGroup = useGetGroup();
  const updateDocsGroup = useUpdateDocsGroup();
  const removeDocFromBoard = useRemoveDocFromBoardDocsList();
  const { moveDocs } = useMoveDocs();

  const [removeValue, { loading }] = useSafeMutation(RemoveDocAttributeValueDocument);

  interface RemoveDocAttributeParams {
    attributeDefinition: CustomAttributeDefinitionFragment;
    valueId: string;
    doc: DocBaseFragment | FullDocWithPublicId | null;
    notCompatible?: boolean;
  }

  const removeDocAttributeValue = async ({
    attributeDefinition,
    valueId,
    doc,
    notCompatible,
  }: RemoveDocAttributeParams) => {
    if (!doc) return;
    const currentDocAttribute = nodeToArray(doc.attributes).find(attr => attributeDefinition.id === attr.definition.id);
    const attributeDefinitionData = customAttributeTypeData[attributeDefinition.__typename];

    let optimisticValue = {};
    if (attributeDefinition.__typename === 'AttributeSingleSelectDefinition') {
      optimisticValue = { selectValue: null };
    } else if (attributeDefinition.__typename === 'AttributeMultiSelectDefinition') {
      optimisticValue = {
        selectValues: getCurrentSelectedValuesFromDocMulti(doc, attributeDefinition)
          .filter(value => value.id !== valueId),
      };
    } else if (attributeDefinition.__typename === 'AttributeNumberDefinition') {
      optimisticValue = {
        numberValue: null,
      };
    } else if (attributeDefinition.__typename === 'AttributeEmailDefinition') {
      optimisticValue = {
        emailValue: null,
      };
    } else if (attributeDefinition.__typename === 'AttributeTextDefinition') {
      optimisticValue = {
        textValue: null,
      };
    } else if (attributeDefinition.__typename === 'AttributeUrlDefinition') {
      optimisticValue = {
        urlValue: null,
      };
    } else if (attributeDefinition.__typename === 'AttributeDateDefinition') {
      optimisticValue = {
        dateValue: null,
      };
    } else if (attributeDefinition.__typename === 'AttributePhoneDefinition') {
      optimisticValue = {
        phoneValue: null,
      };
    } else if (attributeDefinition.__typename === 'AttributeCheckboxDefinition') {
      optimisticValue = {
        checkboxValue: {
          __typename: 'AttributeCheckboxValue',
          value: false,
        },
      };
    }

    const optimisticResponse: RemoveDocAttributeValueMutation = {
      removeDocAttributeValue: {
        // @ts-expect-error Wtf Typescript ? This works for the very similar mutation reponse changeDocAttribute
        __typename: attributeDefinitionData.docAttributeTypename,
        id: currentDocAttribute?.id ?? 'temp-id',
        // @ts-expect-error same
        definition: pick(['__typename', 'id', 'name', 'color'], attributeDefinition),
        ...optimisticValue,
      },
    };

    const mutateValue = () => removeValue({
      variables: {
        docId: doc.id,
        valueId,
        attributeDefinitionId: attributeDefinition.id,
      },
      optimisticResponse,
      update: (cache, { data }) => {
        if (!data?.removeDocAttributeValue || !doc) return;

        if (data.removeDocAttributeValue.__typename !== 'DocAttributeMultiSelect' ||
          data.removeDocAttributeValue.selectValues?.length === 0) {
          cache.modify({
            id: cache.identify(doc),
            fields: {
              attributes: (attributes, { readField }) => ({
                ...attributes,
                edges: attributes.edges
                  .filter(({ node: nodeRef }: { node: Reference }) => {
                    const definitionId = readField(
                      'id',
                      readField('definition', nodeRef),
                    );
                    return definitionId !== attributeDefinition.id;
                  }),
              }),
            },
          });
        }

        if (notCompatible) {
          if (boardConfig?.docQuery.__typename === 'BoardQuery') {
            removeDocFromBoard(boardConfig.id, doc.id);
          } else if (boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' && groups) {
            const groupIdOfPreviousValue = Object.keys(groups)
              .find(gId => Object.values(groups[gId]?.docs ?? {}).map(d => d.id).includes(doc.id));
            if (!groupIdOfPreviousValue) return;

            const previousGroup = getGroup(groupIdOfPreviousValue);
            if (!previousGroup) return;

            updateDocsGroup({
              groupData: previousGroup,
              updatedDocs: previousGroup.node.docs.edges.filter(edge => doc.id !== edge.node.id).map(edge => edge.node),
              boardConfigId: boardConfig.id,
            });
          }
        }
      },
    });

    const moveToNoValueColumn = (): Promise<unknown> | null => {
      const shouldMoveToNoValueColumn =
        (
          boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
          boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
        ) &&
        boardConfig?.docQuery.groupbyConfig.property.id === attributeDefinition.id &&
        !notCompatible;

      if (!shouldMoveToNoValueColumn || !groups) return null;
      const previousAttributeId = currentDocAttribute ? getDocAttributeId(currentDocAttribute) : undefined;

      // Make sure they have the same type, as later we need to compare them.
      const docParentId: BoardGroup['swimlaneDocId'] = doc.parent?.id || null;

      const previousGroupId = Object.keys(groups).find(groupId => {
        if (boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          return (
            groups[groupId]?.swimlaneDocId === docParentId &&
            groups[groupId]?.attributeValueId === previousAttributeId
          );
        }
        return groups[groupId]?.attributeValueId === previousAttributeId;
      });
      const hiddenGroups = (
        boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
        boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
      )
        ? nodeToArray(boardConfig.docQuery.groupbyConfig.values).filter(v => v.hidden)
        : [];
      const isNoValueGroupHidden = hiddenGroups.some(group => group?.propertyValue === null);

      if (!previousGroupId) return null;
      const previousGroup = getGroup(previousGroupId);
      if (!previousGroup) return null;

      if (isNoValueGroupHidden) {
        updateDocsGroup({
          groupData: previousGroup,
          updatedDocs: previousGroup.node.docs.edges.filter(edge => doc.id !== edge.node.id).map(edge => edge.node),
          boardConfigId: boardConfig.id,
        });
      }

      const noValueGroupId = Object.keys(groups).find(groupId => {
        if (boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          return (
            groups[groupId]?.swimlaneDocId === docParentId &&
            groups[groupId]?.attributeValueId === undefined
          );
        }
        return groups[groupId]?.attributeValueId === undefined;
      });
      if (!noValueGroupId) return null;

      const docsFromNewGroup = Object.keys(groups[noValueGroupId]?.docs ?? {}).map(docId => groups[noValueGroupId]?.docs[docId]);

      return moveDocs({
        groupId: noValueGroupId,
        previousGroupIds: [previousGroupId],
        docsMoved: [doc],
        addedDoc: doc,
        position: {
          before: docsFromNewGroup.length ? (docsFromNewGroup[0]?.id ?? '') : '',
        },
        groupByProperty,
        boardConfigId: boardConfig.id,
      });
    };

    await (moveToNoValueColumn() ?? mutateValue());
  };

  return {
    removeDocAttributeValue,
    loading,
  };
};
