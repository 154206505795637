import {
  ActionButton, SelectOption, Tag, TextHighlighter, ToggleInput, Tooltip, 
} from '@cycle-app/ui';
import { CloseIcon, ReorderIcon, PenIcon } from '@cycle-app/ui/icons';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import {
  HTMLAttributes, useState, FocusEvent, KeyboardEvent, ReactEventHandler, useRef,
} from 'react';

import { useGetPermission, setLimitationsModal } from 'src/reactives';
import { isElementOutside } from 'src/utils/elements.util';

import { ReorderContainer, SelectLineStyled, EndSlotContainer } from './SelectOptionsManager.styles';

export interface SelectOptionsEditableLineProps extends HTMLAttributes<HTMLDivElement> {
  disableHover?: boolean;
  filterText?: string;
  isDragging?: boolean;
  isMulti: boolean;
  isSelected: boolean;
  isSortable?: boolean;
  listeners?: DraggableSyntheticListeners;
  onDelete?: (optionId: string) => void;
  onEdit?: (optionId: string, value: string) => void;
  onSelect?: ReactEventHandler<HTMLDivElement>;
  option: SelectOption;
  setNodeRef?: (node: HTMLElement | null) => void;
}

export const SelectOptionsEditableLine = ({
  filterText = '',
  isMulti,
  isSelected = false,
  isSortable,
  listeners,
  onDelete,
  onEdit,
  onSelect,
  option,
  ...props
}: SelectOptionsEditableLineProps) => {
  const { canUpdateAttributeOption } = useGetPermission();
  const [mode, setMode] = useState<'default' | 'edit'>('default');
  const [label, setLabel] = useState(option.label);
  const isValueChanged = option.label !== label;
  const textRef = useRef<HTMLSpanElement>(null);
  return (
    <SelectLineStyled
      key={option.value}
      $isFocus={mode === 'edit'}
      label={mode === 'edit' ? label : (
        <Tooltip
          content={label}
          withWrapper={false}
          disabled={!textRef.current?.parentElement ||
            textRef.current.getBoundingClientRect().width <=
            textRef.current.parentElement.getBoundingClientRect().width}
        >
          <span ref={textRef}>
            {filterText ? (
              <TextHighlighter
                searchWords={[filterText]}
                textToHighlight={label}
                className="highlight"
              />
            ) : label}
          </span>
        </Tooltip>
      )}
      isEditable={!!onEdit && mode === 'edit'}
      onChangeLabel={setLabel}
      startSlot={renderStart()}
      endSlot={renderEndSlot()}
      $isValueChanged={isValueChanged}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      startFocused={mode === 'edit'}
      onSelect={onSelect}
      isSelected={isSelected}
      {...props}
    />
  );

  function handleBlur(e: FocusEvent<HTMLDivElement>) {
    const {
      currentTarget, relatedTarget,
    } = e;
    // If blurs outside the current line, reset the value.
    if (isValueChanged && isElementOutside(currentTarget, relatedTarget)) {
      setLabel(option.label);
    }
  }

  function renderEndSlot() {
    const end = isMulti ? (
      <>
        {option.end}
        <ToggleInput
          id={option.value}
          checked={option.selected}
          readOnly
          onClick={e => onSelect?.(e)}
        />
      </>
    ) : option.end;
    if (mode === 'edit') return !!onEdit && end;
    return (
      onDelete && (
        <EndSlotContainer>
          {end}
          <ActionButton
            tooltip="Rename option"
            tooltipPlacement="top"
            onClick={() => {
              if (canUpdateAttributeOption) {
                setMode('edit');
              } else {
                blockAction();
              }
            }}
          >
            <PenIcon />
          </ActionButton>
          <ActionButton
            tooltip="Delete option"
            tooltipPlacement="top"
            onClick={() => onDelete(option.value)}
            variant="warning"
          >
            <CloseIcon />
          </ActionButton>
        </EndSlotContainer>
      ));
  }

  function renderStart() {
    const eventListeners = canUpdateAttributeOption
      ? listeners
      : {
        onMouseDown: blockAction,
        onTouchStart: blockAction,
      };
    const showReorder = isSortable && mode === 'default';
    if (!option.icon && !showReorder) return null;
    return (
      <>
        {option.icon && <Tag icon={option.icon} />}
        {showReorder && <ReorderContainer {...eventListeners}><ReorderIcon /></ReorderContainer>}
      </>
    );
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    const newLabel = label.trim();
    if (e.key === 'Escape' || e.key === 'Enter') {
      if (isValueChanged && newLabel) onEdit?.(option.value, newLabel);
      e.preventDefault();
      e.stopPropagation();
      setMode('default');
      return;
    }
    if (newLabel) onEdit?.(option.value, newLabel);
  }

  function blockAction() {
    setLimitationsModal({ action: 'HANDLE_ATTRIBUTES_OPTIONS' });
  }
};
