import { typo, Button, boxShadowZ1, CodeInput, Spinner } from '@cycle-app/ui';
import { tablet, mobile } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Footer = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
  margin-top: 60px;

  ${tablet} {
    margin-bottom: 24px;
  }
`;

export const SigInChoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 64px;
`;

export const Form = styled.form`
  display: grid;
  gap: 24px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 32px;
  ${typo.headerLarge600};
  text-align: center;
`;

export const ResetPwdHeading = styled(Heading)`
  margin-bottom: 8px;
`;

export const ResetPwdHint = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.body400};
`;

export const ResetPwdActions = styled(Actions)`
  justify-content: flex-end;
`;

export const TextButtonStyled = styled(Button)`
  ${typo.body400}
  width: auto;
`;

export const Anchor = styled(Link)`
  ${typo.body500}
  color: ${p => (p.theme.colors.login.link.userColor ? 'hsl(var(--cycle))' : p.theme.colors.text.disabled)};
`;

export const ButtonOutline = styled(Button)`
  ${boxShadowZ1}
`;

export const BackButton = styled(Button).attrs({
  variant: 'nospace',
  size: 'M',
})`
  position: absolute;
  top: 48px;
  left: 48px;
  --color: ${p => p.theme.colors.text.disabled};
  :hover, :focus {
    --color: ${p => p.theme.colors.text.secondary};
  }
  :active {
    --color: ${p => p.theme.colors.text.primary};
  }

  ${mobile} {
    top: 24px;
    left: 24px;
  }
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  background: ${p => p.theme.colors.border.primary};
  border: 0;
`;

export const CodeButton = styled(Button).attrs({
  variant: 'outlined-alt',
  size: 'M',
})`
  ${boxShadowZ1}
`;

export const Title = styled.p`
  ${typo.headerLarge}
  text-align: center;
  margin-bottom: 32px;
`;

export const ConfirmDescription = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 24px;
  text-align: center;
`;

export const CodeInputContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

export const StyledCodeInput = styled(CodeInput)`
  input {
    ${typo.headerMedium};
    appearance: textfield;
  }
`;

export const ConfirmFooter = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
`;

export const ConfirmError = styled.div`
  color: ${p => p.theme.colors.text.red};
  margin-top: 24px;
  text-align: center;
`;

export const VerifySpinner = styled(Spinner)`
  width: 12px;
  height: 12px;
`;

export const NospaceButton = styled(Button)`
  ${typo.body600}
  --color: hsl(var(--cycle));
  &:hover, &:focus, &:active {
    --color: hsl(var(--cycle));
  }
  width: auto;
  display: inline-flex;
  padding: 0;
  align-items: center;
  gap: 2px;
`;
