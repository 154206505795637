import { Button, typo, SelectPanel } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  ${typo.body400}
  gap: 4px;
  color: ${p => p.theme.colors.text.secondary};

  &.force-focus {
    background-color: ${p => p.theme.colors.button.ternary.bg};
  }
`;

export const StyledSelectPanel = styled(SelectPanel)`
  max-height: 300px;
`;
