import { Feature } from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import { useIsMobile } from 'src/reactives/responsive.reactive';

export const useIsReleasesEnabled = () => {
  const isMobile = useIsMobile();
  const { product } = useProduct();
  return !isMobile && !!product?.features.find(f => f === Feature.Release);
};
