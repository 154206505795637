import { BulkProcessFeedbacksDocument } from '@cycle-app/graphql-codegen';
import { CheckIcon } from '@cycle-app/ui/icons';
import { useCallback } from 'react';

import { addToaster } from 'src/utils/toasters.utils';

import useSafeMutation from './useSafeMutation';

export const useBulkProcessFeedbacks = () => {
  const [mutate, { loading }] = useSafeMutation(BulkProcessFeedbacksDocument);

  const processFeedbacks = useCallback(async (productId: string) => {
    const result = await mutate({ variables: { productId } });

    const isSuccess = !!result.data?.bulkAutopilot;

    if (isSuccess) {
      addToaster({
        message: 'Feedbacks are processing',
        icon: <CheckIcon />,
      });
    }

    return result;
  }, [mutate]);

  return {
    processFeedbacks,
    isLoading: loading,
  };
};
