import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: 8px;
`;

interface GridItemProps {
  $isActive: boolean;
}

export const GridItem = styled.button<GridItemProps>`
  cursor: pointer;
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.colors.background.secondary)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  transition: background-color .1s, border-color .1s;
  border: 2px solid ${p => (p.$isActive ? 'hsl(var(--cycle))' : 'transparent')};

  &:hover {
    background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey850 : p.theme.baseColors.Grey300)};
  }
`;
