import { LinearIssueFullFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { InfosTitle, StyledSelectButton } from 'src/components/LinearEditorMentionView/LinearEditorMentionView.styles';
import { useUpdateLinearIssue } from 'src/hooks/api/mutations/integrations/useUpdateLinearIssue';
import { Layer } from 'src/types/layers.types';

import { EstimationIcon } from './DocLinear.styles';

export const IssueEstimate = ({ issue }: { issue: LinearIssueFullFragment }) => {
  const { updateLinearIssue } = useUpdateLinearIssue();
  const scale = issue.team?.estimationType;
  if (!scale || !ranges[scale]) return null;
  const selected = issue.estimate != null ? estimateOptions(issue.team?.estimationType)[issue.estimate] : null;
  return (
    <div>
      <InfosTitle>Estimate</InfosTitle>
      <ToggleDropdown
        layer={Layer.DropdownModalZ4}
        placement="bottom-start"
        content={props => (
          <SelectPanel
            options={estimateOptions(issue.team?.estimationType)}
            hideSearch
            onOptionChange={o => {
              const estimate = Number(o.value);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              updateLinearIssue(issue.id, { estimate }, {
                ...issue,
                estimate,
              });
              props.hide();
            }}
            onClearValue={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              updateLinearIssue(issue.id, { estimate: null }, {
                ...issue,
                estimate: null,
              });
              props.hide();
            }}
          />
        )}
        button={props => (
          <StyledSelectButton
            {...props}
          >
            <EstimationIcon />
            {selected ? selected.label : 'No estimate'}
          </StyledSelectButton>
        )}
      />
    </div>
  );
};

const estimateOptions = (scale: string | null | undefined): SelectOption[] => {
  if (!scale || !ranges[scale]) return [];
  const range = ranges[scale];
  return [...Array(5).keys()].map(i => ({
    value: `${i + 1}`,
    label: range?.[i] ?? '',
    icon: <EstimationIcon />,
  }));
};

const ranges: Record<string, [string, string, string, string, string]> = {
  linear: ['1 Point', '2 Points', '3 Points', '4 Points', '5 Points'],
  fibonacci: ['1 Point', '2 Points', '3 Points', '5 Points', '8 Points'],
  exponential: ['1 Point', '2 Points', '4 Points', '8 Points', '16 Points'],
  tShirt: ['XS', 'S', 'M', 'L', 'XL'],
};
