import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { NewBadge } from 'src/components/NewBadge';

export const Container = styled.button`
  align-items: center;
  background-color: ${p => p.theme.colors.home.card.bg};
  border-radius: 12px;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  cursor: pointer;
  :active {
    background-color: ${p => p.theme.colors.home.card.bgHover};
  }
`;

export const Title = styled.div`
  ${typo.headerLight}
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const StyledNewBadge = styled(NewBadge).attrs({ size: 'M' })`
  padding: 3px 6px;
`;
