import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PinIcon: FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.7224 1.60637C10.9869 1.87092 10.9869 2.29984 10.7224 2.56439L7.45909 5.82765C7.39071 5.89603 7.30848 5.94898 7.21794 5.98293L4.09207 7.15513L3.45111 7.79609L8.20381 12.5488L8.84476 11.9078L10.017 8.78196C10.0509 8.69141 10.1039 8.60919 10.1722 8.54081L13.4355 5.27754C13.7001 5.01299 14.129 5.01299 14.3935 5.27754C14.6581 5.54209 14.6581 5.97101 14.3935 6.23556L11.234 9.39506L10.0618 12.5209C10.0279 12.6115 9.97492 12.6937 9.90654 12.7621L8.68281 13.9858C8.41826 14.2504 7.98935 14.2504 7.7248 13.9858L2.01409 8.2751C1.74954 8.01055 1.74954 7.58163 2.01409 7.31708L3.23781 6.09336C3.30619 6.02498 3.38841 5.97203 3.47896 5.93808L6.60483 4.76588L9.76434 1.60637C10.0289 1.34182 10.4578 1.34182 10.7224 1.60637Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.8276 10.1723C6.09215 10.4368 6.09215 10.8658 5.8276 11.1303L2.15643 14.8015C1.89188 15.066 1.46296 15.066 1.19841 14.8015C0.933863 14.5369 0.933863 14.108 1.19841 13.8435L4.86958 10.1723C5.13413 9.90774 5.56305 9.90774 5.8276 10.1723Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.35661 1.19841C9.62116 0.933863 10.0501 0.933863 10.3146 1.19841L14.8016 5.6854C15.0662 5.94995 15.0662 6.37887 14.8016 6.64342C14.5371 6.90796 14.1082 6.90796 13.8436 6.64342L9.35661 2.15643C9.09207 1.89188 9.09207 1.46296 9.35661 1.19841Z" fill="currentcolor" />
  </Svg>
);
