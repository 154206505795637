import { QueryFunctionOptions, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  ProductCustomersDocument,
  ProductCustomersFragment,
  ProductCustomersQueryVariables,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import { defaultCustomersPagination } from 'src/utils/pagination.util';

type UseCustomersByProductIdOptions =
  Pick<QueryFunctionOptions<{ node: ProductCustomersFragment }, ProductCustomersQueryVariables>, 'onCompleted'> &
  { defaultSearch?: string; skip?: boolean; fetchPolicy?: WatchQueryFetchPolicy };

export const useCustomers = (options?: UseCustomersByProductIdOptions) => {
  const product = useProductBase();
  return useCustomersByProductId(product?.id, options);
};

export const useCustomersByProductId = (productId?: string, options?: UseCustomersByProductIdOptions) => {
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(ProductCustomersDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: options?.skip || !productId,
    variables: {
      productId: productId as string,
      ...defaultCustomersPagination,
      ...options?.defaultSearch && { searchText: options.defaultSearch },
    },
    onCompleted: options?.onCompleted,
  });

  const customerConnection = data?.node.customers;
  const endCursor = customerConnection?.pageInfo?.endCursor;
  const hasNextPage = customerConnection?.pageInfo?.hasNextPage ?? false;

  const customers = useMemo(() => nodeToArray(customerConnection), [customerConnection]);

  const fetchNextPage = useCallback(async () => {
    if (!hasNextPage) return;
    await fetchMore({
      variables: {
        cursor: endCursor,
      },
    });
  }, [fetchMore, endCursor, hasNextPage]);

  return {
    customers,
    fetchNextPage,
    loading,
    hasNextPage,
    refetch,
  };
};
