import { make } from 'src/utils/reactives.util';

import { Integration } from '../types/integrations.types';

export type IntegrationSyncModalResult = {
  integrationType: Integration | null;
};

export const {
  hookState: useIntegrationSyncModal,
  hookValue: useGetIntegrationSyncModal,
  getValue: getIntegrationSyncModal,
  setValue: setIntegrationSyncModal,
  resetValue: resetIntegrationSyncModal,
} = make<IntegrationSyncModalResult>({
  defaultState: {
    integrationType: null,
  },
});
