import styled from 'styled-components';

import { ShyScrollbarCss } from '../../ShyScrollbar/ShyScrollbar.styles';
import { ErrorVariant, InputCss } from '../Input/Input.styles';

export const StyledTextArea = styled.textarea<{
  $hasError?: boolean;
}>`
  ${InputCss}
  ${ShyScrollbarCss}
  border-radius: 6px;
  width: 100%;
  resize: vertical;
  ${(p) => p.$hasError && ErrorVariant};
`;
