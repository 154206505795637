import { CheckIcon, CloseIcon } from '@cycle-app/ui/icons';
import { blobUrlToFile } from '@cycle-app/utilities';
import { useState } from 'react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { useMaybeMeV2 } from 'src/hooks';
import { getAudioFileName } from 'src/utils/dropzone.utils';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import { StyledAudioRecorder, PrimaryButton, SecondaryButton } from './DropzoneMini.styles';

export const MiniRecorder = ({
  onRecorded,
  close,
  isDisabled = false,
}: {
  onRecorded: (file: File) => void;
  close: VoidFunction;
  isDisabled?: boolean;
}) => {
  const { me } = useMaybeMeV2();
  const [file, setFile] = useState<File | null>(null);

  return (
    <StyledAudioRecorder
      isDisabled={isDisabled}
      height={32}
      showDefaultButton={!file}
      onAudioReady={async (audioUrl: string) => {
        try {
          const recordedFile = await blobUrlToFile({
            blobUrl: audioUrl,
            fileName: getAudioFileName(me),
            mime: 'audio/mpeg',
          });
          setFile(recordedFile);
        } catch {
          addErrorToaster({ message: ErrorMessage.AUDIO_CONVERSION_FAILED });
        }
      }}
      onAudioRemoved={() => {
        setFile(null);
      }}
      buttons={({ removeAudio }) => (
        <>
          {file && (
            <PrimaryButton
              disabled={isDisabled}
              onClick={e => {
                e.stopPropagation();
                onRecorded(file);
                close();
              }}
            >
              <CheckIcon />
            </PrimaryButton>
          )}
          <SecondaryButton
            disabled={isDisabled}
            onClick={() => {
              removeAudio();
              close();
            }}
          >
            <CloseIcon />
          </SecondaryButton>
        </>
      )}
    />
  );
};
