import { make } from 'src/utils/reactives.util';

type GeneralPermissions =
  | 'canCreateAttributeOption'
  | 'canCreateGroup'
  | 'canCreateInsight'
  | 'canCreateRelease'
  | 'canCreateReleaseNote'
  | 'canCreateView'
  | 'canCreateViewCustom'
  | 'canCreateViewCustomSection'
  | 'canDeleteAttributeOption'
  | 'canDeleteInsight'
  | 'canDeleteRelease'
  | 'canDeleteReleaseNote'
  | 'canDeleteView'
  | 'canDeleteViewCustom'
  | 'canDeleteViewCustomSection'
  | 'canExportCSV'
  | 'canInviteCollaborator'
  | 'canInviteMaker'
  | 'canReadSettings'
  | 'canReorderBoards'
  | 'canReorderGroups'
  | 'canTranscribe'
  | 'canUpdateAttributeOption'
  | 'canUpdateFeedbackStatus'
  | 'canUpdateGroup'
  | 'canUpdateInsight'
  | 'canUpdateRelease'
  | 'canUpdateReleaseNote'
  | 'canUpdateUserRoleCollaborator'
  | 'canUpdateUserRoleMaker'
  | 'canUpdateView'
  | 'canUpdateViewCustom'
  | 'canUpdateViewCustomSection'
  | 'canUpgradeBilling'
  | 'canUseAi';

export const {
  hookValue: useGetPermission,
  setValue: setPermission,
  resetValue: resetPermission,
  getValue: getPermission,
} = make<Record<GeneralPermissions | 'isPermissionInit', undefined | boolean>>({
  defaultState: {
    isPermissionInit: false,
    canCreateAttributeOption: undefined,
    canCreateGroup: undefined,
    canCreateInsight: undefined,
    canCreateRelease: undefined,
    canCreateReleaseNote: undefined,
    canCreateView: undefined,
    canCreateViewCustom: undefined,
    canCreateViewCustomSection: undefined,
    canDeleteAttributeOption: undefined,
    canDeleteInsight: undefined,
    canDeleteRelease: undefined,
    canDeleteReleaseNote: undefined,
    canDeleteView: undefined,
    canDeleteViewCustom: undefined,
    canDeleteViewCustomSection: undefined,
    canExportCSV: undefined,
    canInviteCollaborator: true,
    canInviteMaker: undefined,
    canReadSettings: undefined,
    canReorderBoards: undefined,
    canReorderGroups: undefined,
    canTranscribe: undefined,
    canUpdateAttributeOption: undefined,
    canUpdateFeedbackStatus: undefined,
    canUpdateGroup: undefined,
    canUpdateInsight: undefined,
    canUpdateRelease: undefined,
    canUpdateReleaseNote: undefined,
    canUpdateUserRoleCollaborator: undefined,
    canUpdateUserRoleMaker: undefined,
    canUpdateView: undefined,
    canUpdateViewCustom: undefined,
    canUpdateViewCustomSection: undefined,
    canUpgradeBilling: undefined,
    canUseAi: undefined,
  },
});

type BillingPermissions =
  | 'canPlanCreateView'
  | 'canPlanCreateViewCustom'
  | 'canPlanCreateViewCustomSection'
  | 'canUploadNotRestricted'
  | 'canPlanUpdateSlackNotifications'
  | 'canPlanUpdateSlackAutomations'
  | 'canPlanCreateDoctype'
  | 'canPlanCreateInsight';

export const {
  hookValue: useGetBillingPermission,
  getValue: getBillingPermission,
  setValue: setBillingPermission,
  resetValue: resetBillingPermission,
} = make<Record<BillingPermissions, undefined | boolean>>({
  defaultState: {
    canPlanCreateView: undefined,
    canPlanCreateViewCustom: undefined,
    canPlanCreateViewCustomSection: undefined,
    canPlanUpdateSlackAutomations: undefined,
    canPlanUpdateSlackNotifications: undefined,
    canUploadNotRestricted: undefined,
    canPlanCreateDoctype: undefined,
    canPlanCreateInsight: undefined,
  },
});

type IntegrationPermissions = 'canAddIntegration';

export const {
  hookValue: useGetIntegrationPermission,
  setValue: setIntegrationPermission,
  resetValue: resetIntegrationPermission,
} = make<Record<IntegrationPermissions, undefined | boolean>>({
  defaultState: {
    canAddIntegration: undefined,
  },
});

export const resetAllPermissions = () => {
  resetPermission();
  resetBillingPermission();
  resetIntegrationPermission();
};

export const useGetAllPermissions = () => ({
  ...useGetPermission(),
  ...useGetBillingPermission(),
  ...useGetIntegrationPermission(),
});
