import { BoardWithMinimalConfigAndDraftConfigIdFragment, SectionType } from '@cycle-app/graphql-codegen';
import { ReactNode } from 'react';

import { BoardEditForm } from 'src/components/BoardEditCommonModal/BoardEditForm';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { isViewMyInbox, isViewAll } from 'src/utils/inbox.util';

export type InboxEditViewFormProps = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
  children: (isLoading: boolean) => ReactNode;
};

export const InboxEditViewForm = ({
  board, draftBoardConfigId, children,
}: InboxEditViewFormProps) => {
  const boardMutations = useBoardMutations();
  const draftMutations = useDraftBoardConfigMutations(draftBoardConfigId, board.id);
  const isNameDisabled = isViewMyInbox(board) || isViewAll(board);

  return (
    <BoardEditForm
      isNameDisabled={isNameDisabled}
      defaultValues={board}
      sectionType={SectionType.Feedback}
      focusNameOnMount
      onSubmit={async (formData, isDirty) => {
        const promises = [draftMutations.publishBoardConfig()];

        if (isDirty) {
          promises.push(boardMutations.updateBoard({
            ...formData,
            name: isNameDisabled ? board.name : formData.name,
            boardId: board.id,
          }));
        }

        await Promise.all(promises);
      }}
    >
      {children(boardMutations.loading || draftMutations.loading.publish)}
    </BoardEditForm>
  );
};
