/* eslint-disable no-nested-ternary */
import { Variants, Variant } from 'framer-motion';

import { BTN_SIZE } from './InboxZeroSources.styles';

const GAP = 8;

const hoverVariant: Variant = i => ({
  opacity: 1,
  x: i * (BTN_SIZE + GAP),
  y: 0,
  rotate: 0,
  transition: {
    duration: 0.25,
    opacity: { duration: 0.6 },
  },
});

const restVariant: Variant = i => ({
  opacity: i < -1 || i > 1 ? 0 : 1,
  rotate: i < 0 ? -6 : i > 0 ? 6 : 0,
  x: i < 0 ? -28 : i > 0 ? 28 : 0,
  y: i === 0 ? 0 : 5,
  transition: {
    duration: 0.25,
    opacity: { duration: 0.6 },
  },
});

export const sourceVariants = (isDropdownVisible: boolean): Variants => ({
  rest: isDropdownVisible ? hoverVariant : restVariant,
  hover: hoverVariant,
});
