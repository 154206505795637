import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { Controller } from 'react-hook-form';

import { ChangelogBuilderInputColor } from './ChangelogBuilderInputColor';
import { Section } from './ChangelogBuilderSidebar.styles';
import { LabelWithReset } from './LabelWithReset';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';

export const ChangelogBuilderColors = () => {
  const {
    control, resetField, watch, clearErrors, formState,
  } = useChangelogBuilderFormContext();
  const headersColor = watch('headersColor');
  const bodyColor = watch('bodyColor');
  const backgroundColor = watch('backgroundColor');
  const dividerColor = watch('dividerColor');
  const linkColor = watch('linkColor');
  const values = {
    headersColor,
    bodyColor,
    backgroundColor,
    dividerColor,
    linkColor,
  };
  const defaultValues = pick(formState.defaultValues, Object.keys(values));
  const isDirty = !isEqual(values, defaultValues);
  return (
    <Section>
      <LabelWithReset
        label="Colors"
        showReset={isDirty}
        reset={() => {
          (Object.keys(values) as (keyof typeof values)[]).forEach(field => {
            resetField(field);
          });
        }}
      />
      <div className="mt-2 flex flex-col items-stretch gap-2">
        <div>
          <Controller
            control={control}
            name="headersColor"
            render={({
              field, fieldState,
            }) => {
              const color = watch(field.name);
              return (
                <ChangelogBuilderInputColor
                  color={color}
                  error={fieldState.error?.message}
                  label="Headers"
                  onChange={value => {
                    clearErrors(field.name);
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="bodyColor"
            render={({
              field, fieldState,
            }) => {
              const color = watch(field.name);
              return (
                <ChangelogBuilderInputColor
                  color={color}
                  error={fieldState.error?.message}
                  label="Body"
                  onChange={value => {
                    clearErrors(field.name);
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="backgroundColor"
            render={({
              field, fieldState,
            }) => {
              const color = watch(field.name);
              return (
                <ChangelogBuilderInputColor
                  color={color}
                  error={fieldState.error?.message}
                  label="Background"
                  onChange={value => {
                    clearErrors(field.name);
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="dividerColor"
            render={({
              field, fieldState,
            }) => {
              const color = watch(field.name);
              return (
                <ChangelogBuilderInputColor
                  color={color}
                  error={fieldState.error?.message}
                  label="Divider"
                  onChange={value => {
                    clearErrors(field.name);
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="linkColor"
            render={({
              field, fieldState,
            }) => {
              const color = watch(field.name);
              return (
                <ChangelogBuilderInputColor
                  color={color}
                  error={fieldState.error?.message}
                  label="Link"
                  onChange={value => {
                    clearErrors(field.name);
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </Section>
  );
};
