import { ToggleInput } from '@cycle-app/ui';

import { useSlackNotifications } from 'src/hooks/slack/useSlackNotifications';
import { useSlackNotificationToggle } from 'src/hooks/slack/useSlackNotificationToggle';

import { ContentHeader, ContentHeading } from '../SlackSettingsModal.styles';
import { Field, Fields, Text } from './NotificationsTab.styles';

export const NotificationsTab = () => {
  const {
    feedbackCreatedNotification,
    statusNotStartedNotification,
    statusStartedNotification,
    statusCompletedNotification,
    statusCanceledNotification,
    loopCloseNotification,
  } = useSlackNotifications();
  const {
    toggleFeedback, toggleStatusNotStarted, toggleStatusStarted, toggleStatusCompleted, toggleStatusCanceled, toggleLoopClosed,
  } = useSlackNotificationToggle();

  const onChange = (
    fieldName:
    'feedbackCreatedNotification' |
    'statusNotStartedNotification' |
    'statusStartedNotification' |
    'statusCompletedNotification' |
    'statusCanceledNotification' |
    'loopClosedNotification',
  ) => {
    return () => {
      if (fieldName === 'feedbackCreatedNotification') return toggleFeedback(!feedbackCreatedNotification);
      if (fieldName === 'statusNotStartedNotification') return toggleStatusNotStarted(!statusNotStartedNotification);
      if (fieldName === 'statusStartedNotification') return toggleStatusStarted(!statusStartedNotification);
      if (fieldName === 'statusCompletedNotification') return toggleStatusCompleted(!statusCompletedNotification);
      if (fieldName === 'loopClosedNotification') return toggleLoopClosed(!loopCloseNotification);
      return toggleStatusCanceled(!statusCanceledNotification);
    };
  };
  return (
    <div>
      <ContentHeader>
        <ContentHeading>Notifications that will be sent in Slack</ContentHeading>
      </ContentHeader>
      <Text>Cycle bot will send public notifications as a reply to Slack messages that were linked to feedback.</Text>
      <Fields>
        <Field htmlFor="feedbackCreatedNotification">
          Feedback created
          <ToggleInput
            id="feedbackCreatedNotification"
            checked={feedbackCreatedNotification}
            onChange={onChange('feedbackCreatedNotification')}
          />
        </Field>
        <Field htmlFor="statusNotStartedNotification">
          Status changed to Not started
          <ToggleInput
            id="statusNotStartedNotification"
            checked={statusNotStartedNotification}
            onChange={onChange('statusNotStartedNotification')}
          />
        </Field>
        <Field htmlFor="statusStartedNotification">
          Status changed to Started
          <ToggleInput
            id="statusStartedNotification"
            checked={statusStartedNotification}
            onChange={onChange('statusStartedNotification')}
          />
        </Field>
        <Field htmlFor="statusCompletedNotification">
          Status changed to Completed
          <ToggleInput
            id="statusCompletedNotification"
            checked={statusCompletedNotification}
            onChange={onChange('statusCompletedNotification')}
          />
        </Field>
        <Field htmlFor="statusCanceledNotification">
          Status changed to Canceled
          <ToggleInput
            id="statusCanceledNotification"
            checked={statusCanceledNotification}
            onChange={onChange('statusCanceledNotification')}
          />
        </Field>
        <Field htmlFor="loopClosedNotification">
          Loop closed for insights you are assignee or customer of
          <ToggleInput
            id="loopClosedNotification"
            checked={loopCloseNotification}
            onChange={onChange('loopClosedNotification')}
          />
        </Field>
      </Fields>
    </div>
  );
};
