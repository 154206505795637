import { FC } from 'react';

import RealtimeUsers from 'src/components/RealtimeUsers/RealtimeUsers';
import { useDocPresence } from 'src/hooks';

interface Props {
  className?: string;
}

const DocPanelRealtime: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const users = useDocPresence();
  return <RealtimeUsers className={className} users={users} />;
};

export default DocPanelRealtime;
