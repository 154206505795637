import { useCallback } from 'react';

import { categoryKeys } from 'src/constants/status.constants';
import { useProduct } from 'src/hooks/api/useProduct';

export const useGetStatus = () => {
  const { product } = useProduct();
  return useCallback((statusId: string) => {
    for (const key of Object.values(categoryKeys)) {
      const status = product?.status?.[key].edges.find(e => e.node.id === statusId)?.node;
      if (status) return status;
    }
    return null;
  }, [product]);
};
