import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LinkedinIcon = (props: SvgProps) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <path d="M5.18811 2H10.8113C12.7767 2 14 3.38746 14 5.35092V10.6491C14 12.6125 12.7767 14 10.8106 14H5.18811C3.2227 14 2 12.6125 2 10.6491V5.35092C2 3.38746 3.22854 2 5.18811 2Z" stroke="currentcolor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.25519 7.32812V10.5337" stroke="currentcolor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.44073 10.5339V8.28712C7.44073 7.35512 8.197 6.59888 9.129 6.59888C10.0618 6.59888 10.8173 7.35512 10.8173 8.28712V10.5339" stroke="currentcolor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.25592 5.5348V5.55261M5.32808 5.53863C5.32808 5.57871 5.29556 5.6112 5.25548 5.6112C5.2154 5.6112 5.18292 5.57871 5.18292 5.53863C5.18292 5.49855 5.2154 5.46606 5.25548 5.46606C5.29556 5.46606 5.32808 5.49855 5.32808 5.53863Z" stroke="currentcolor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);
