import { TriangleIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

interface ActiveProps {
  $isActive?: boolean;
}

export const SectionSkeletonItem = styled.div<ActiveProps>`
  color: ${p => (p.$isActive ? p.theme.colors.text.secondary : p.theme.colors.background.active)};
  transition: color .1s;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 80%;
`;

export const SectionSkeleton = styled.div<ActiveProps>`
  background-color: ${p => (p.$isActive ? p.theme.colors.text.secondary : p.theme.colors.background.active)};
  border-radius: 30px;
  transition: background-color .1s;
  height: 8px;
  flex-grow: 1;
`;

export const SectionSkeletonList = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > :nth-child(3) ${SectionSkeleton} {
    max-width: 60%;
    flex: 1;
  }

  > :nth-child(2) ${SectionSkeleton}, > :last-child ${SectionSkeleton} {
    max-width: 50%;
    flex: 1;
  }
`;

export const SectionSkeletonIcon = styled(TriangleIcon)<ActiveProps>`
  height: 30px;
  min-width: 30px;

  ${p => p.$isActive && css`
    transform: rotate(0deg);
  `}
`;
