import { useQuery } from '@apollo/client';
import { DocCommentsDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { extract } from 'src/types/graphql.types';

interface Params {
  docId: string;
  firstCommentId?: string;
  skip?: boolean;
  // Set to null to get comments for the main thread
  blockId?: string | null;
  resolved: boolean | undefined;
}

export default function useDocComments({
  docId,
  firstCommentId,
  skip = false,
  blockId,
  resolved,
}: Params) {
  const query = useQuery(DocCommentsDocument, {
    variables: {
      id: docId,
      blockId,
      resolved,
    },
    skip,
    fetchPolicy: 'cache-and-network',
    // Avoid refetching comments when a comment is added, deleted or edited
    nextFetchPolicy: 'cache-first',
  });

  const threads = extract('Doc', query.data?.node)?.threads;

  const comments = useMemo(
    () => {
      const edge = firstCommentId
        ? threads?.edges.find(e => e.node?.comments?.edges.some(c => c.node?.id === firstCommentId))
        : threads?.edges[0];
      return nodeToArray(edge?.node?.comments);
    }, [threads?.edges, firstCommentId],
  );

  return {
    ...query,
    comments,
  };
}
