import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetNewVersion,
  getValue: getNewVersion,
  setValue: setNewVersion,
} = make<{
  hasNewVersion: boolean;
}>({
  defaultState: {
    hasNewVersion: false,
  },
});
