import { FC } from 'react';

import { useSuggestionStatusIds } from 'src/reactives/productStatus.reactive';

import DocSearchDropdown, { DocSearchDropdownProps } from './DocSearchDropdown';

export const InsightDocSearchDropdown: FC<React.PropsWithChildren<DocSearchDropdownProps>> = ({
  searchVariables, ...props
}) => {
  const statusIds = useSuggestionStatusIds();
  return (
    <DocSearchDropdown
      {...props}
      searchVariables={{
        ...searchVariables,
        statusIds,
      }}
    />
  );
};
