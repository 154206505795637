import { LinkIcon, SignalIcon } from '@cycle-app/ui/icons';

import { useOptimizedBooleanState } from 'src/hooks';
import { useMeetingToggleBot } from 'src/hooks/integration/useMeetingToggleBot';
import { addToaster } from 'src/utils/toasters.utils';
import { MaybeUndefined } from 'src/utils/utils';

import {
  ButtonTernary, MeetingAction, MeetingHeading, MeetingOngoing, NowLabel, StyledLink,
} from './MeetingEvent.styles';
import DialogModal from '../../DialogModal/DialogModal';

type Props = {
  botScheduled: boolean;
  id: string;
  name: MaybeUndefined<string>;
  url: MaybeUndefined<string>;
};

const onConfirmBot = () => addToaster({
  id: 'cycle-bot-invited',
  closable: true,
  title: 'The Cycle bot was successfully invited to the meeting. It may take few seconds.',
});

export const MeetingEventOngoing = ({
  name, botScheduled, url, id,
}: Props) => {
  const {
    toggleBot, isLoading,
  } = useMeetingToggleBot({
    onCompleted: result => {
      if (result.toggleScheduledBot?.botScheduled) {
        onConfirmBot();
      }
    },
  });
  const [isStartRecordingOpened, {
    setTrueCallback: openStartRecordingModal, setFalseCallback: closeStartRecordingModal,
  }] = useOptimizedBooleanState(false);
  const [isInviteBotOpened, {
    setTrueCallback: openInviteBotModal, setFalseCallback: closeInviteBotModal,
  }] = useOptimizedBooleanState(false);

  const onToggleBot = () => toggleBot({
    eventId: id,
    scheduled: true,
    force: true,
    optimistic: false,
  });

  return (
    <>
      <MeetingOngoing>
        <div>
          {name && <MeetingHeading>{name}</MeetingHeading>}
          <NowLabel>
            <SignalIcon />
            {botScheduled ? 'Call ongoing…' : 'Now'}
          </NowLabel>
          {url && (
            <StyledLink $light target="_blank" rel="noreferrer" href={url}>
              <LinkIcon />
              {url}
            </StyledLink>
          )}
        </div>
        <MeetingAction>
          {botScheduled && (
            <ButtonTernary
              size="M"
              onClick={openInviteBotModal}
              variant="ternary"
            >
              Invite Cycle bot
            </ButtonTernary>
          )}
          {!botScheduled && (
            <ButtonTernary
              size="M"
              onClick={openStartRecordingModal}
              variant="ternary"
            >
              Start recording
            </ButtonTernary>
          )}
        </MeetingAction>
      </MeetingOngoing>
      {isStartRecordingOpened && (
        <DialogModal
          hide={closeStartRecordingModal}
          title="Start recording"
          info="Are you sure you want to start recording? It will invite the recording bot to your call."
          confirmLabel="Start recording"
          variantCancelButton="secondary"
          variantSubmitButton="primary"
          type="default"
          autoHide={false}
          loading={isLoading}
          onConfirm={async () => {
            await onToggleBot();
            closeStartRecordingModal();
          }}
        />
      )}
      {isInviteBotOpened && (
        <DialogModal
          hide={closeInviteBotModal}
          title="Invite Cycle bot"
          info="Are you sure you want to invite Cycle recording bot to your call? Note that if it's already there, nothing will happen."
          confirmLabel="Invite"
          variantCancelButton="secondary"
          variantSubmitButton="primary"
          type="default"
          autoHide={false}
          loading={isLoading}
          onConfirm={async () => {
            await onToggleBot();
            closeInviteBotModal();
          }}
        />
      )}
    </>
  );
};
