import { DocBaseFragment } from '@cycle-app/graphql-codegen/generated';
import { getHighlightHash } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { useMoreDocsInGroup } from 'src/hooks/api/useMoreDocsInGroup';
import { useLocation } from 'src/hooks/router/useLocation';
import { useNavigate } from 'src/hooks/useNavigate';
import { closeVerifyQuotes, closeFeatureReleaseNote } from 'src/reactives/docRightPanel.reactive';

import { getDocType } from '../reactives/docTypes.reactive';
import { isInsight } from '../utils/docType.util';

type HookResult = {
  navigateToPrevDoc: VoidFunction;
  navigateToNextDoc: VoidFunction;
  isFirstDoc: boolean;
  isLastDoc: boolean;
  loadingNextDoc: boolean;
  shouldLoadMore: boolean;
  loadMore: VoidFunction;
};

type DocInGroup = Pick<DocBaseFragment, 'id' | 'title'>;

export const usePrevNextDoc = (doc: DocBaseFragment | null): HookResult => {
  const { state } = useLocation();
  const groupId = state?.groupId;
  const isDocRelative = state?.isDocRelative;
  // In instance, `docRelativeTo` can be the insight, and `doc` its feedback.
  const docRelativeTo = state?.docRelativeTo;
  const boardGroups = useBoardGroups();
  const withGroupBy = boardGroups?.withGroupBy;
  const group = groupId ? boardGroups.groups?.[groupId] : null;
  const hasNextPage = group?.pageInfo?.hasNextPage;
  const endCursor = group?.pageInfo?.endCursor;
  const docsList = Object.values(group?.docs ?? {});
  const docIndex = docsList.findIndex(item => item.id === (docRelativeTo || doc?.id));
  const prevDocInGroup = docIndex === 0 ? undefined : docsList[docIndex - 1];
  const nextDocInGroup = docIndex === docsList.length - 1 ? undefined : docsList[docIndex + 1];
  let prevDoc: DocInGroup | undefined = prevDocInGroup;
  let nextDoc: DocInGroup | undefined = nextDocInGroup;
  const isFirstDoc = !prevDoc;
  const isLastDoc = !nextDoc;

  let nextHash = '';
  let nextDocRelativeTo = '';
  // If next insight has a feedback
  if (isInsight(getDocType(nextDocInGroup?.doctype.id)) && nextDocInGroup?.docSource?.doc) {
    // Navigate to the feeback
    nextDoc = nextDocInGroup.docSource.doc;
    // On navigate store the insight in location `state.docRelativeTo`
    nextDocRelativeTo = nextDocInGroup.id;
    // Highlights the insight
    nextHash = getHighlightHash({
      docId: nextDocInGroup.id,
      blockId: nextDocInGroup.docSource.blockId,
    });
  }

  let prevHash = '';
  let prevDocRelativeTo = '';
  if (isInsight(getDocType(prevDocInGroup?.doctype.id)) && prevDocInGroup?.docSource?.doc) {
    prevDoc = prevDocInGroup.docSource.doc;
    prevDocRelativeTo = prevDocInGroup.id;
    prevHash = getHighlightHash({
      docId: prevDocInGroup.id,
      blockId: prevDocInGroup.docSource.blockId,
    });
  }

  const moreDocsInGroup = useMoreDocsInGroup(groupId ?? '');
  const loadingNextDoc = !!group?.more?.loading || moreDocsInGroup.loading;

  const loadMore = useCallback(() => {
    if (loadingNextDoc) return;
    if (withGroupBy) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      moreDocsInGroup.moreDocs(endCursor ?? '');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      group?.more?.exec?.();
    }
  }, [endCursor, group?.more, loadingNextDoc, moreDocsInGroup, withGroupBy]);

  const { navigateToDocPanelPage } = useNavigate();

  const navigateToPrevDoc = useCallback(() => {
    if (isDocRelative && !docRelativeTo) return;
    closeVerifyQuotes();
    closeFeatureReleaseNote();
    if (prevDoc && !isFirstDoc) {
      navigateToDocPanelPage({
        ...prevDoc,
        hash: prevHash,
      }, {
        docRelativeTo: prevDocRelativeTo,
        groupId,
        isDocRelative,
      });
    }
  }, [isDocRelative, docRelativeTo, prevDoc, isFirstDoc, navigateToDocPanelPage, prevHash, prevDocRelativeTo, groupId]);

  const navigateToNextDoc = useCallback(() => {
    if (isDocRelative && !docRelativeTo) return;
    closeVerifyQuotes();
    closeFeatureReleaseNote();
    if (hasNextPage && docIndex >= 0 && docIndex >= docsList.length - 2) loadMore();
    if (nextDoc && !isLastDoc) {
      navigateToDocPanelPage({
        ...nextDoc,
        hash: nextHash,
      }, {
        docRelativeTo: nextDocRelativeTo,
        groupId,
        isDocRelative,
      });
    }
  }, [
    isDocRelative, docRelativeTo, hasNextPage, docIndex, docsList.length, loadMore,
    nextDoc, isLastDoc, navigateToDocPanelPage, nextHash, nextDocRelativeTo, groupId]);

  return {
    navigateToPrevDoc,
    navigateToNextDoc,
    isFirstDoc,
    isLastDoc,
    loadingNextDoc,
    shouldLoadMore: !nextDoc && !!hasNextPage && !loadingNextDoc,
    loadMore,
  };
};
