import { FC } from 'react';

import { MobileViewHeader } from 'src/app/Main/Board/BoardContent/BoardContent.styles';
import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal';
import { ErrorPage } from 'src/components/ErrorPage';
import { OfflineState } from 'src/components/OfflineState';
import { ViewBreadcrumb } from 'src/components/ViewBreadcrumb';
import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { matchBoardConfig } from 'src/hoc/matchBoardConfig';
import { useIsOffline } from 'src/hooks';
import { useIsMobile } from 'src/reactives';

import { ViewContainer } from './Roadmap.styles';
import { RoadmapViewContent } from './RoadmapViewContent';

export const RoadmapView: FC<React.PropsWithChildren<unknown>> = matchBoardConfig(({ children }) => {
  const isMobile = useIsMobile();
  const hasError = useBoardConfig(ctx => ctx.hasError);
  const isAnalytics = useBoardConfig(ctx => ctx.isAnalytics);
  const isOffline = useIsOffline();

  if (isOffline && hasError) return <OfflineState />;

  if (hasError) return <ErrorPage />;

  return (
    <ViewContainer id={BULK_SELECTION_AREA_ID} $isScrollable={isAnalytics}>
      {isMobile && (
        <MobileViewHeader>
          <ViewBreadcrumb sectionParent="roadmap" />
        </MobileViewHeader>
      )}
      <RoadmapViewContent />
      <CreateDocModal />
      {children}
    </ViewContainer>
  );
});
