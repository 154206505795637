import { MotionProps, Variants } from 'framer-motion';

export const motionProps: MotionProps = {
  initial: 'exit',
  exit: 'exit',
  animate: 'enter',
};

export const variantsMask: Variants = {
  enter: {
    opacity: 1,
    transition: {
      duration: 0.15,
      ease: 'easeInOut',
      delay: 0,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.15,
      ease: 'easeInOut',
      delay: 0.1,
    },
  },
};

export const variantsContent = (delay: boolean): Variants => ({
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.1,
      delay: delay ? 0.1 : 0,
    },
  },
  exit: {
    scale: 1,
    opacity: 0,
    transition: {
      duration: 0.15,
      delay: 0,
    },
  },
});
