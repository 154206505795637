import styled, { css } from 'styled-components';

import { InsightCompanyCustomer } from '../InsightsList/InsightCompanyCustomer';

interface InsightCompanyCustomerProps {
  $isInline?: boolean;
}

export const StyledInsightCompanyCustomer = styled(InsightCompanyCustomer) <InsightCompanyCustomerProps>`
  ${p => p.$isInline && css`
    margin-top: 0px;
    max-width: calc(100% - 25px);
  `}
`;
