import { CycleLogo, typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const Content = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Body = styled(motion.div)`
  text-align: center;
  margin-top: 40px;
`;

export const Sentence = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  position: absolute;
  width: 450px;
  left: calc(-50%);
  top: calc(100% + 60px);
  transform: rotate(0deg);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;

  svg {
    position: absolute;
    top: -35px;
    left: 40%;
    transform: rotate(5deg);
    stroke: currentcolor;
  }
`;

export const StyledButton = styled(NextButton)`
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const LogoRotate = styled(motion.div)`
  width: 100px;
  height: 100px;
`;

export const StyledLogo = styled(CycleLogo)`
  width: 100px;
  height: 100px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
