// updateLinearIssue($issueId: ID!, $stateId: ID, $assigneeId: ID, $priority: Number, $estimate: Number, $projectId: ID, $integrationId: ID!)

import {
  UpdateLinearIssueDocument,
  UpdateLinearIssueMutationVariables,
  LinearIssueFullFragment,
} from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useSafeMutation from 'src/hooks/useSafeMutation';

type Variables = Omit<UpdateLinearIssueMutationVariables, 'issueId' | 'integrationId'>;

export const useUpdateLinearIssue = () => {
  const integrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  const [mutate, { loading }] = useSafeMutation(UpdateLinearIssueDocument);

  const updateLinearIssue = (
    issueId: string,
    variables: Variables,
    optimisticData: LinearIssueFullFragment,
  ) => {
    if (!integrationId) return null;
    return mutate({
      variables: {
        issueId,
        integrationId,
        ...variables,
      },
      optimisticResponse: {
        updateLinearIssue: optimisticData,
      },
    });
  };

  return {
    updateLinearIssue,
    isLoading: loading,
  };
};
