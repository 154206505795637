import { useProduct } from 'src/hooks';
import { useCheckMonthlyAiQueries } from 'src/hooks/billing/useCheckMonthlyAiQueries';
import { setLimitationsModal, getPermission } from 'src/reactives';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

export const useRequestExtractQuotes = () => {
  const { product } = useProduct();
  const {
    maxAiQueriesReached,
    openUnlimitedAiPrompt,
  } = useCheckMonthlyAiQueries(product?.nbAiQueries);

  return () => {
    const { canUseAi } = getPermission();

    if (!canUseAi) {
      setLimitationsModal({ action: 'USE_AI' });
      return false;
    }

    if (maxAiQueriesReached) {
      openUnlimitedAiPrompt();
      return false;
    }

    return requestInsightCreate();
  };
};
