import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${p => p.theme.colors.components.InsightCardHieraryInsight.card.bg};
  position: relative;
  padding: 16px;
  display: grid;
  gap: 8px;
  height: 110px;
  border-radius: 8px;
`;
