import { SelectPanel } from '@cycle-app/ui';
import { useMemo } from 'react';

import { ACTIVE_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { useProductIntegrations } from 'src/hooks';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { FrontEndIntegration } from 'src/types/integrations.types';

import { Info } from './SourcesPanel.styles';

export const SourcesPanel = ({ hide }: { hide: VoidFunction }) => {
  const install = useInstallIntegration();
  const { sourcesByStatus } = useProductIntegrations();

  const [actives, inactives] = useMemo(() => [
    sourcesByStatus.uninstalled.filter(i => ACTIVE_INTEGRATIONS.includes(i.integrationType)),
    sourcesByStatus.uninstalled.filter(i => !ACTIVE_INTEGRATIONS.includes(i.integrationType)),
  ], [sourcesByStatus.uninstalled]);

  return (
    <SelectPanel
      hideSearch
      onOptionChange={async option => {
        hide();
        const integration = actives.find(source => source.integrationType === option.value)?.integration;
        await install(integration?.type || option.value as FrontEndIntegration, integration);
      }}
      options={[
        ...actives.map(source => {
          const value = source.integrationType;
          const data = integrationsDataMap[source.integrationType];
          return {
            value,
            label: data.label,
            icon: data.icon,
          };
        }),
        ...inactives.map(source => {
          const value = source.integrationType;
          const data = integrationsDataMap[value];
          return {
            value,
            label: data.label,
            icon: data.icon,
            disabled: true,
            end: <Info>Coming soon</Info>,
          };
        }),
      ]}
    />
  );
};
