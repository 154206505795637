import { Language } from '@cycle-app/graphql-codegen';
import { Controller } from 'react-hook-form';

import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { ChangelogBuilderSelect, ChangelogBuilderSelectOption } from './ChangelogBuilderSelect';
import { Section } from './ChangelogBuilderSidebar.styles';
import { LabelWithReset } from './LabelWithReset';

const languageOptions: ChangelogBuilderSelectOption[] = [
  {
    label: 'English',
    value: Language.En,
  },
  {
    label: 'German',
    value: Language.De,
  },
  {
    label: 'French',
    value: Language.Fr,
  },
  {
    label: 'Spanish',
    value: Language.Es,
  },
  {
    label: 'Italian',
    value: Language.It,
  },
  {
    label: 'Dutch',
    value: Language.Nl,
  },
];

export const ChangelogBuilderLanguage = () => {
  const {
    control, formState, resetField, watch,
  } = useChangelogBuilderFormContext();
  const language = watch('language');
  return (
    <Section>
      <LabelWithReset
        label="Language"
        showReset={language !== formState.defaultValues?.language}
        reset={() => resetField('language')}
      />
      <div style={{ marginTop: '8px' }}>
        <Controller
          control={control}
          name="language"
          render={({ field }) => (
            <ChangelogBuilderSelect
              value={field.value}
              onChange={option => field.onChange(option.value)}
              options={languageOptions}
            />
          )}
        />
      </div>
    </Section>
  );
};