import { BoardWithMinimalConfigFragment } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { useBoardConfigDocsSubscription } from 'src/hooks/api/useBoardConfigDocsSubscription';
import { useBoardSubscription } from 'src/hooks/api/useBoardSubscription';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { setSelection } from 'src/reactives/selection.reactive';

export const useInitBoard = ({
  board, boardConfigId,
}: {
  board?: BoardWithMinimalConfigFragment | null;
  boardConfigId?: string;
}) => {
  useBoardSubscription(board?.id);
  useBoardConfigDocsSubscription(boardConfigId);
  useEffect(() => {
    setDocIdPreview({ enabled: true });
    setSelection({ selected: [] });
    if (!board) return;
    setCommandbar({ boardId: board.id });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board?.id]);
};
