import { PortalId } from 'src/types/portal.types';

const createElement = (id: string) => {
  const element = document.createElement('div');
  element.id = id;
  document.body.appendChild(element);
  return element;
};

export const getPortalElement = (portalId: PortalId) => {
  const elementId = `portal-${portalId}`;
  return document.getElementById(elementId) ?? createElement(elementId);
};

export const createPortals = () => Object.values(PortalId).forEach((portalId) => createElement(`portal-${portalId}`));

export const createApp = () => createElement('app');

export const isElementOutside = <T>(parent: HTMLElement, target: T) => !(target instanceof HTMLElement) || !parent.contains(target);
