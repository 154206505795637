import { typo, boxShadowZ1 } from '@cycle-app/ui';
import styled from 'styled-components';

import { Footer } from '../OnboardingSlack.styles';
import { SlackMessageEmoji } from '../SlackMessage/SlackMessage.styles';

export const Name = styled.a`
  text-decoration: underline;
`;

export const Steps = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: var(--s-10);
  max-width: 100%;
  width: 600px;
`;

export const Step = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
`;

export const StepIndex = styled.span`
  ${typo.body500}
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${p => p.theme.colors.border.primary};
  display: block;
  display: flex;
  height: 26px;
  justify-content: center;
  width: 26px;
`;

export const StepImageContainer = styled.div`
  ${boxShadowZ1}
  background-color: ${p => p.theme.colors.background.white};
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  height: 115px;
  margin-top: 16px;
  overflow: hidden;
  position: relative;
  width: 100%;

  > img {
    height: 100%;
    width: 180px;
    max-width: unset;
  }
`;

export const StepTitle = styled.span`
  ${typo.body400}
  display: block;
  margin-top: 21px;
  text-align: center;
`;

export const StyledFooter = styled(Footer)`
  margin-top: var(--s-9);
`;

export const StyledSlackMessageEmoji = styled(SlackMessageEmoji)`
  left: 33%;
  top: 61%;
  background-color: rgba(29, 155, 209, 0.1);
  box-shadow: rgb(29, 155, 209) 0px 0px 0px 1.5px inset;
  color: rgb(18, 100, 163);
  width: 42px;
  height: 24px;
`;
