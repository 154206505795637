import { intersection } from 'ramda';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { resetDocItem, resetDocItemHoverId, getDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';
import { getOpenedLayers } from 'src/utils/layers.util';

interface UseDocsPreviewParams {
  enabled?: boolean;
  disableOnLayers?: Layer[];
  docIdToPreview?: string;
  onKeyUp?: (e: KeyboardEvent) => void;
  onDocPreview?: () => void;
}

const PREVIEW_KEY_CODE = 'Space';

export const useDocsPreview = ({
  enabled = true,
  disableOnLayers,
  docIdToPreview,
  onKeyUp: onKeyUpFromProps,
  onDocPreview,
}: UseDocsPreviewParams = {}) => {
  const location = useLocation();

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.repeat || e.code !== PREVIEW_KEY_CODE || !e.shiftKey) {
      return;
    }
    if (disableOnLayers && intersection(getOpenedLayers(), disableOnLayers).length > 0) {
      return;
    }

    const hoverDocId = getDocItemHoverId();

    if (docIdToPreview || hoverDocId) {
      e.preventDefault();
      setDocIdPreview({ docIdPreview: docIdToPreview ?? hoverDocId });
      onDocPreview?.();
    }
  }, [disableOnLayers, docIdToPreview, onDocPreview]);

  const onKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.code === PREVIEW_KEY_CODE && getDocIdPreview().docIdPreview) {
      setDocIdPreview({ docIdPreview: null });
      onKeyUpFromProps?.(e);
    }
  }, [onKeyUpFromProps]);

  const removeListeners = useCallback(() => {
    window.removeEventListener('keydown', onKeyDown);
    window.removeEventListener('keyup', onKeyUp);
  }, [onKeyDown, onKeyUp]);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('keyup', onKeyUp);
    } else {
      removeListeners();
    }
    return () => {
      removeListeners();
    };
  }, [enabled, onKeyDown, onKeyUp, removeListeners]);

  useEffect(() => {
    resetDocItem();
    resetDocItemHoverId();
  }, [location]);
};
