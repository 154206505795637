import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { body400 } from '../../theme/typo';
import { BOX_SHADOW_COLOR_Z4 } from '../../utils/styles.util';

interface ContainerProps {
  $width?: number;
}

export const DropdownCss = css<ContainerProps>`
  font-size: 0; /* Prevent extra padding */
  > * {
    ${body400}; /* Apply default font-size to children */
  }

  overflow: hidden;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.dropdown.color};
  background-color: ${(props) => props.theme.colors.dropdown.bg};

  /* Safari issue: do not use border-radius and outline simultaneously */
  box-shadow: 0px 6px 15px ${BOX_SHADOW_COLOR_Z4}, 0 0 0 1px rgba(0, 0, 0, 0.06);

  width: ${p => (p.$width ? `${p.$width}px` : 'auto')};
  max-width: calc(100vw - 10px);
`;

export const Container = styled(motion.div) <ContainerProps>`
  ${DropdownCss};
`;
