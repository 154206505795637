import { ApolloCache, gql, InternalRefetchQueriesInclude } from '@apollo/client';
import { CreateCustomerDocument, CreateCustomerMutationVariables, CustomerFragment } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { NormalizedCustomerConnection, NormalizedCustomerEdge } from 'src/types/customers.types';

import { NormalizedCompanyConnection, NormalizedCompanyEdge } from '../../../../types/companies.types';

export const useCustomerCreate = (productId?: string) => {
  const [createCustomer, { loading }] = useSafeMutation(CreateCustomerDocument, { errorPolicy: 'all' });

  const mutate = useCallback(({
    name, email, ...variables
  }: Omit<CreateCustomerMutationVariables, 'productId'>, options?: { refetchQueries?: InternalRefetchQueriesInclude }) => {
    if (!productId) return null;

    return createCustomer({
      variables: {
        productId,
        name: name?.trim() ? name : null,
        email: email?.trim() ? email : null,
        ...variables,
      },
      refetchQueries: options?.refetchQueries,
      update(cache, { data }) {
        if (!data?.createCustomer || !productId) return;

        addCustomerInCache(cache, data.createCustomer, cache.identify({
          __typename: 'Product',
          id: productId,
        }));

        if (variables.companyName) {
          const newCompanyRef = cache.writeFragment({
            data: data.createCustomer.company,
            fragment: gql`
              fragment NewCompany on Fragment {
                id
              }
            `,
          });

          cache.modify({
            id: cache.identify({
              __typename: 'Product',
              id: productId,
            }),
            fields: {
              // @deprecated
              companyCount: (companyCount) => companyCount + 1,
              companies: (companies: NormalizedCompanyConnection, { readField }) => {
                const newEdge = {
                  __typename: 'CompanyEdge',
                  node: newCompanyRef,
                } as NormalizedCompanyEdge;
                const index = companies.edges.findIndex(({ node: nodeRef }) => (readField('name', nodeRef) ?? '') > (variables.companyName ?? ''));
                return {
                  ...companies,
                  edges: index === -1 ? [...companies.edges, newEdge] : [
                    ...companies.edges.slice(0, index),
                    newEdge,
                    ...companies.edges.slice(index),
                  ],
                  count: companies.count + 1,
                };
              },
            },
          });
        }

        if (data.createCustomer.company) {
          cache.modify({
            id: cache.identify(data.createCustomer.company),
            fields: {
              countCustomers: (currentCount) => currentCount + 1,
              customers: (customers: NormalizedCustomerConnection, {
                readField, toReference,
              }) => {
                if (!data.createCustomer?.id) return customers;
                const newEdge = {
                  __typename: 'CustomerEdge',
                  node: toReference(data.createCustomer.id),
                } as NormalizedCustomerEdge;
                const index = customers.edges.findIndex(e => (readField('email', e.node) ?? '') > (email || ''));
                return {
                  ...customers,
                  edges: index === -1 ? [...customers.edges, newEdge] : [
                    ...customers.edges.slice(0, index),
                    newEdge,
                    ...customers.edges.slice(index),
                  ],
                  count: customers.count + 1,
                };
              },
            },
          });
        }
      },
    });
  }, [createCustomer, productId]);

  return {
    mutate,
    isLoading: loading ?? false,
  };
};

export const addCustomerInCache = (
  cache: ApolloCache<unknown>,
  customer: CustomerFragment,
  productId?: string,
) => {
  cache.modify({
    id: productId,
    fields: {
      // @deprecated
      customerCount: (customerCount) => customerCount + 1,
      customers: (customers: NormalizedCustomerConnection, {
        readField, toReference,
      }) => {
        if (!customer?.id) return customers;
        const newEdge = {
          __typename: 'CustomerEdge',
          node: toReference(customer.id),
        } as NormalizedCustomerEdge;

        const index = customers.edges.findIndex(e => (readField('email', e.node) ?? '') > (customer.email || ''));

        return {
          ...customers,
          edges: index === -1 ? [...customers.edges, newEdge] : [
            ...customers.edges.slice(0, index),
            newEdge,
            ...customers.edges.slice(index),
          ],
          count: customers.count + 1,
        };
      },
    },
  });
};
