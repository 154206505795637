import { SidebarMobile } from 'src/app/Main/Sidebar/SidebarMobile';
import { useMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { Container } from './MobileAsideMenu.styles';

export const MobileAsideMenu = () => {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return <MobileAsideMenuContainer />;
};

const MobileAsideMenuContainer = () => {
  const [{ isVisible }] = useMobileAsideMenu();

  return (
    <Container
      animate={{ left: isVisible ? '0' : '-100%' }}
      initial={{ left: '-100%' }}
      transition={{ duration: 0.15 }}
    >
      <SidebarMobile />
    </Container>
  );
};
