import { QuoteFragment, UpdateQuoteDocument } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';
import { MoreHorizIcon, CloseIcon, PenIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useSafeMutation } from 'src/hooks';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';

import { ParentOptionsButton, OptionIcon } from './VerifyQuoteModal.styles';

type Props = {
  quote: QuoteFragment;
  onSelectEdit: VoidFunction;
};

export const QuoteFeatureOptions = ({
  quote, onSelectEdit,
}: Props) => {
  const [updateQuote] = useSafeMutation(UpdateQuoteDocument);
  if (!quote.parent) return null;
  return (
    <ToggleDropdown
      withPortal
      withWrapper={false}
      placement="bottom"
      layer={Layer.DropdownModalZ1}
      button={buttonProps => (
        <ParentOptionsButton
          className="options"
          onClick={buttonProps.onClick}
          forceFocus={buttonProps['data-active']}
        >
          <MoreHorizIcon />
        </ParentOptionsButton>
      )}
      content={contentProps => (
        <SelectPanel
          hideSearch
          options={[{
            value: 'edit',
            label: 'Edit title',
            icon: <OptionIcon><PenIcon size={16} /></OptionIcon>,
            onSelect: onSelectEdit,
          }, {
            value: 'remove',
            label: `Remove ${getDocType(quote.parent?.doctype.id)?.name.toLowerCase()}`,
            icon: <OptionIcon><CloseIcon size={12} /></OptionIcon>,
            onSelect: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
              updateQuote({
                variables: {
                  id: quote?.id,
                  parentId: null,
                },
                optimisticResponse: {
                  updateQuote: {
                    ...quote,
                    parent: null,
                  },
                },
              });
            },
          }]}
          onOptionChange={option => {
            contentProps.hide();
            option.onSelect?.();
          }}
        />
      )}
    />
  );
};
