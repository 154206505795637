import {
  CheckboxInput, CheckboxInputText, typo, ShyScrollbar, SelectPanel, Button,
} from '@cycle-app/ui';
import { ArrowsAllSidesIcon, DovetailIcon } from '@cycle-app/ui/icons';
import { tablet } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const StyledDovetailIcon = styled(DovetailIcon)`
  ${p => p.theme.isDark && `color: ${p.theme.colors.text.primary};`}
`;

export const StyledArrowsAllSidesIcon = styled(ArrowsAllSidesIcon)`
  color: ${p => p.theme.isDark ? p.theme.colors.text.disabled : 'rgba(23, 23, 23, 0.2)'};
`;

export const Main = styled.form`
  margin-top: 32px;
`;

export const SourceListContainer = styled(ShyScrollbar)`
  max-height: 415px;
`;

export const SourceList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 2px;

  > li {
    min-width: 0;
  }

  ${tablet} {
    grid-template-columns: 1fr;
  }
`;

const imageLogoCss = css`
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;

export const SourceLabel = styled.div`
  ${typo.body500}
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  ${imageLogoCss}
`;

export const SourceName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface StyledCheckboxInputProps {
  $isSelected: boolean;
}

export const StyledCheckboxInput = styled(CheckboxInput)<StyledCheckboxInputProps>`
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  transition: background-color .1s;
  height: 48px;

  label {
    padding: 12px;
    gap: 15px;
  }

  ${CheckboxInputText} {
    overflow: hidden;
  }

  ${p => p.$isSelected && css`
    background-color: ${p.theme.colors.lightOnboarding.sources.selected};
    border-color: ${p.theme.colors.lightOnboarding.sources.selectedBorder};
  `}
`;

export const ButtonOther = styled(Button)`
  ${typo.body500}
  --radius: 8px;
  --color: ${p => p.theme.colors.text.primary};
  height: 48px;
  padding: 12px;
  border: 1px solid ${p => p.theme.colors.border.primary};

  &.force-focus {
    background-color: ${p => p.theme.colors.button.ternary.bg};
  }

  > span {
    justify-content: flex-start;
  }
`;

export const StyledSelectPanel = styled(SelectPanel)`
  svg, img {
    width: 16px;
    flex: none;
  }
`;

export const Source = styled(motion.div)`
  width: 80px;
  height: 80px;
  z-index: 0;
  border-radius: 84.8px;
  position: relative;

  ${p => (p.theme.isDark ? `
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.20) -14.58%, rgba(255, 255, 255, 0.16) 15.06%, rgba(255, 255, 255, 0.13) 41.84%, rgba(255, 255, 255, 0.20) 99.71%);
    box-shadow: 0px 0px 12.8px 1.6px rgba(255, 255, 255, 0.20) inset;
    box-shadow: 0px 0px 12.8px 1.6px color(display-p3 1 1 1 / 0.20) inset;
    backdrop-filter: blur(13.59999942779541px);
  ` : `
    background: #FFF;
  `)}

  > svg, > img {
    flex: none;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
`;

export const SourceIconBg = styled.div`
  ${p => p.theme.isDark && 'display: none;'}
  width: 36px;
  height: 36px;
  flex:none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-30%, -30%);
  z-index: 0;

  svg, img {
    width: 36px;
    height: 36px;
  }
`;

export const SourceBg = styled.div`
  ${p => p.theme.isDark && 'display: none;'}
  border-radius: 84.8px;
  background: linear-gradient(270deg, #FFF -14.58%, rgba(255, 255, 255, 0.82) 15.06%, rgba(255, 255, 255, 0.65) 41.84%, #FFF 99.71%);
  mix-blend-mode: lighten;
  box-shadow: 0px 0px 12.8px 1.6px #FFF inset;
  box-shadow: 0px 0px 12.8px 1.6px color(display-p3 1 1 1) inset;
  backdrop-filter: blur(13.59999942779541px);
  inset: 0;
  flex: none;
  position: absolute;
  z-index: 1;
`;

export const SourceBgBar = styled.div`
  ${p => p.theme.isDark && 'display: none;'}
  border-radius: 12.8px;
  opacity: 0.28;
  background: linear-gradient(93deg, #0048FF 26.73%, #00194D 193.09%);
  background: linear-gradient(93deg, color(display-p3 0 0.2784 1) 26.73%, color(display-p3 0.0196 0.0941 0.2902) 193.09%);
  filter: blur(12.800000190734863px);
  width: 55.2px;
  height: 15.2px;
  flex-shrink: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 5px;
  z-index: 1;
`;

export const Aside = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
