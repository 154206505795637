import { SwimlaneFragment, SwimlanebyConfigFragment } from '@cycle-app/graphql-codegen';
import { Emoji, SelectOption, Placement } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useDebouncedCallback } from 'use-debounce';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import useSearchSwimlanesHideable from 'src/hooks/api/useSearchSwimlanesHideable';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { getDocItem, setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';

import {
  StyledSelectPanel,
  StyledDocPreviewHint,
  Header,
  Title,
  HideAllBtn,
  ShowAllBtn,
} from './HiddenSwimlanes.styles';

interface Props {
  className?: string;
  swimlanebyConfig: SwimlanebyConfigFragment;
  onHideSwimlane: (id: string) => void;
  onShowSwimlane: (id: string) => void;
  onHideSwimlanes: () => Promise<unknown>;
  onShowSwimlanes: () => Promise<unknown>;
  placement?: Placement;
  lowerdropdownLayer?: Layer;
  upperDropdownLayer?: Layer;
  isVisible?: boolean;
  hideDropdown?: VoidFunction;
  children: ReactNode;
}

enum Filter {
  All = 'all',
  Hidden = 'hidden',
  Shown = 'shown',
}

const HiddenSwimlanes: FC<React.PropsWithChildren<Props>> = ({
  swimlanebyConfig,
  onHideSwimlane,
  onShowSwimlane,
  onHideSwimlanes,
  onShowSwimlanes,
  lowerdropdownLayer = Layer.DropdownZ1,
  upperDropdownLayer = Layer.DropdownZ2,
  isVisible: isVisibleFromProps,
  hideDropdown: hideDropdownFromProps,
  children,
}) => {
  const onShowAll = useAsyncCallback(onShowSwimlanes);
  const onHideAll = useAsyncCallback(onHideSwimlanes);

  const [isDropdownVisibleFromState, { setFalseCallback: hideDropdownFromState }] = useOptimizedBooleanState(false);
  const isDropdownVisible = isVisibleFromProps ?? isDropdownVisibleFromState;
  const hideDropdown = hideDropdownFromProps ?? hideDropdownFromState;

  const [filter, setFilter] = useState<Filter>(Filter.All);

  const groupConfigId = swimlanebyConfig?.id ?? null;

  const {
    searchResult,
    searchHideableGroups,
  } = useSearchSwimlanesHideable(groupConfigId);

  const onSearchChange = useDebouncedCallback(searchHideableGroups, INPUT_ONCHANGE_DEBOUNCE);

  const getSwimlaneOption = useCallback((swimlane: SwimlaneFragment) => ({
    value: swimlane.id,
    docId: swimlane.propertyValue?.id,
    label: swimlane.propertyValue ? swimlane.propertyValue.title : ' No value',
    selected: !swimlane.hidden,
    icon: swimlane.propertyValue ? <Emoji emoji={swimlane.propertyValue.doctype.emoji} /> : null,
  }), []);

  const allSwimlanesOptions = useMemo(
    () => nodeToArray(swimlanebyConfig?.hiddenSwimlanes).map(getSwimlaneOption),
    [swimlanebyConfig, getSwimlaneOption],
  );
  const displayedSwimlanes = useMemo(() => allSwimlanesOptions.filter(o => o.selected), [allSwimlanesOptions]);
  const displayedSwimlanesCount = displayedSwimlanes.length;
  const hiddenSwimlanes = useMemo(() => allSwimlanesOptions.filter(o => !o.selected), [allSwimlanesOptions]);
  const hiddenSwimlanesCount = hiddenSwimlanes.length;

  const mappingSwimlaneDocId: Record<string, string> = useMemo(
    () => allSwimlanesOptions.reduce((acc, option) => ({
      ...acc,
      [option.value]: option.docId,
    }), {}),
    [allSwimlanesOptions],
  );

  const displayedOptions = useMemo(
    () => {
      if (searchResult) return searchResult.map(getSwimlaneOption);
      return {
        [Filter.All]: allSwimlanesOptions,
        [Filter.Hidden]: hiddenSwimlanes,
        [Filter.Shown]: displayedSwimlanes,
      }[filter];
    },
    [searchResult, allSwimlanesOptions, displayedSwimlanes, hiddenSwimlanes, filter, getSwimlaneOption],
  );

  const onViewTypeChanged = useCallback((o: SelectOption) => setFilter(o.value as Filter), []);

  const onMouseLeaveItem = useCallback(() => setDocItemHoverId(null), []);
  const onMouseEnterItem = useCallback((swimlaneId: string) => {
    const { disableHover } = getDocItem();
    const docId = mappingSwimlaneDocId[swimlaneId] ?? null;
    if (!disableHover) setDocItemHoverId(docId);
    if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: docId });
  }, [mappingSwimlaneDocId]);

  const onSelectOption = useCallback((o: SelectOption) => onShowSwimlane(o.value), [onShowSwimlane]);
  const onUnSelectOption = useCallback((o: SelectOption) => onHideSwimlane(o.value), [onHideSwimlane]);

  return (
    <DropdownLayer
      layer={lowerdropdownLayer}
      visible={isDropdownVisible}
      hide={hideDropdown}
      disableDocPreview={false}
      placement="top-start"
      content={(
        <StyledSelectPanel
          header={(
            <Header>
              <Title>View :</Title>
              <DropdownSelectLayer
                layer={upperDropdownLayer}
                selectedValue={filter}
                options={[
                  {
                    label: 'All',
                    value: Filter.All,
                  },
                  {
                    label: `Shown (${displayedSwimlanesCount})`,
                    value: Filter.Shown,
                  },
                  {
                    label: `Hidden (${hiddenSwimlanesCount})`,
                    value: Filter.Hidden,
                  },
                ]}
                onChange={onViewTypeChanged}
                hideSearch
              />
              <ShowAllBtn
                onClick={onShowAll.execute}
                disabled={hiddenSwimlanesCount === 0}
                isLoading={onShowAll.loading}
              >
                Show all
              </ShowAllBtn>
              <HideAllBtn
                onClick={onHideAll.execute}
                disabled={displayedSwimlanesCount === 0}
                isLoading={onHideAll.loading}
              >
                Hide all
              </HideAllBtn>
            </Header>
          )}
          isMulti
          autoFocusSearch={false}
          onSearchChange={onSearchChange}
          options={displayedOptions}
          onSelectOption={onSelectOption}
          onUnselectOption={onUnSelectOption}
          filterOptionsOnInputChange={false}
          onMouseEnterItem={onMouseEnterItem}
          onMouseLeaveItem={onMouseLeaveItem}
          noPointerEvents={onShowAll.loading || onHideAll.loading}
        >
          <StyledDocPreviewHint />
        </StyledSelectPanel>
      )}
    >
      {children}
    </DropdownLayer>
  );
};

export default HiddenSwimlanes;
