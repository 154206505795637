export enum LightOnboardingScreen {
  Welcome = 'welcome',
  AccountVerify = 'verify',
  AccountInfos = 'infos',
  AccountInfosPasswordless = 'infos-passwordless',
  AccountPreferences = 'preferences',
  Workspace = 'workspace',
  Readonly = 'readonly',
  Readonly1 = 'readonly1',
  Readonly2 = 'readonly2',
  Readonly3 = 'readonly3',
  Readonly4 = 'readonly4',
  BookCall = 'book-call',
  CallBooked = 'call-booked',
  Done = 'done',
  PM = 'pm',
  Sources = 'sources',
  Doctypes = 'doctypes',
  Linear = 'linear',
  UserResearch = 'user-research',
}
