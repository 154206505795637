import { useQuery } from '@apollo/client';
import { TemplateNodeDocument } from '@cycle-app/graphql-codegen';

import { extract } from 'src/types/graphql.types';

export const useTemplate = (templateId?: string | null) => {
  const { data } = useQuery(TemplateNodeDocument, {
    variables: {
      id: templateId,
    },
    skip: !templateId,
  });

  return extract('DoctypeTemplate', data?.node);
};
