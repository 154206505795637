import { FC } from 'react';

import { Svg, SvgProps } from '../components/Svg/Svg.styles';

type Props = SvgProps & {
  isBump?: boolean;
};

export const SignalIcon: FC<React.PropsWithChildren<Props>> = ({
  isBump, ...props
}) => {
  return isBump ? (
    <Svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_3420_17349)">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 0C7.0971 0 7.47924 0.27543 7.61535 0.683772L10.6667 9.83772L11.718 6.68377C11.8541 6.27543 12.2362 6 12.6667 6H15.3333C15.8856 6 16.3333 6.44772 16.3333 7C16.3333 7.55228 15.8856 8 15.3333 8H13.3874L11.6153 13.3162C11.4792 13.7246 11.0971 14 10.6667 14C10.2362 14 9.8541 13.7246 9.71798 13.3162L6.66667 4.16228L5.61535 7.31623C5.47924 7.72457 5.0971 8 4.66667 8H2C1.44772 8 1 7.55228 1 7C1 6.44772 1.44772 6 2 6H3.94591L5.71798 0.683772C5.8541 0.27543 6.23624 0 6.66667 0Z" fill="url(#paint0_linear_3420_17349)" />
      </g>
      <defs>
        <filter id="filter0_d_3420_17349" x="0" y="0" width="17.3333" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3420_17349" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3420_17349" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_3420_17349" x1="8.66667" y1="0" x2="8.66667" y2="14" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg width="16" height="16" viewBox="0 0 16 16" data-no-fill fill="none" xmlns="https://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.99298 3.28645C4.18824 3.48171 4.18824 3.79829 3.99298 3.99355C2.96697 5.01957 2.33325 6.43555 2.33325 8.00049C2.33325 9.56544 2.96697 10.9814 3.99298 12.0074C4.18824 12.2027 4.18824 12.5193 3.99298 12.7145C3.79772 12.9098 3.48114 12.9098 3.28587 12.7145C2.07999 11.5087 1.33325 9.8413 1.33325 8.00049C1.33325 6.15968 2.07999 4.49233 3.28587 3.28645C3.48114 3.09118 3.79772 3.09118 3.99298 3.28645ZM12.0069 3.28645C12.2021 3.09118 12.5187 3.09118 12.714 3.28645C13.9198 4.49233 14.6666 6.15968 14.6666 8.00049C14.6666 9.8413 13.9198 11.5087 12.714 12.7145C12.5187 12.9098 12.2021 12.9098 12.0069 12.7145C11.8116 12.5193 11.8116 12.2027 12.0069 12.0074C13.0329 10.9814 13.6666 9.56544 13.6666 8.00049C13.6666 6.43555 13.0329 5.01957 12.0069 3.99355C11.8116 3.79829 11.8116 3.48171 12.0069 3.28645ZM5.93098 5.22444C6.12624 5.4197 6.12624 5.73629 5.93098 5.93155C5.40094 6.46159 5.07399 7.19238 5.07399 8.00049C5.07399 8.8086 5.40094 9.5394 5.93098 10.0694C6.12624 10.2647 6.12624 10.5813 5.93098 10.7765C5.73571 10.9718 5.41913 10.9718 5.22387 10.7765C4.51396 10.0666 4.07399 9.08447 4.07399 8.00049C4.07399 6.91652 4.51396 5.93435 5.22387 5.22444C5.41913 5.02918 5.73571 5.02918 5.93098 5.22444ZM10.0689 5.22444C10.2641 5.02918 10.5807 5.02918 10.776 5.22444C11.4859 5.93435 11.9258 6.91652 11.9258 8.00049C11.9258 9.08447 11.4859 10.0666 10.776 10.7765C10.5807 10.9718 10.2641 10.9718 10.0689 10.7765C9.8736 10.5813 9.8736 10.2647 10.0689 10.0694C10.5989 9.5394 10.9258 8.8086 10.9258 8.00049C10.9258 7.19238 10.5989 6.46159 10.0689 5.93155C9.8736 5.73629 9.8736 5.4197 10.0689 5.22444ZM6.99992 8C6.99992 7.44771 7.44763 7 7.99992 7C8.5522 7 8.99992 7.44771 8.99992 8C8.99992 8.55228 8.5522 9 7.99992 9C7.44763 9 6.99992 8.55228 6.99992 8Z" fill="currentcolor" />
    </Svg>
  );
};
