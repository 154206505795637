import { ViewType } from '@cycle-app/graphql-codegen';
import {
  Group,
  ShyScrollbar,
  Skeleton,
  boxShadowZ4,
  NewDocPosition,
} from '@cycle-app/ui';
import { ListIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import BoardGroupNewDoc from 'src/components/BoardGroup/BoardGroupNewDoc';
import { BoardGroupSkeleton } from 'src/components/BoardGroup/BoardGroupSkeleton';

import { swimlaneDocCSS } from './SwimlaneDoc/SwimlaneDoc.styles';

export const Container = styled(ShyScrollbar)`
  margin-top: 6px;
  height: calc(100vh - 72px);
`;

export const Content = styled(ShyScrollbar)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 0 32px 60px 24px;

  height: 100%;
`;

export const CollapsedGroupIcon = styled(ListIcon)`
  color: ${p => p.theme.colors.text.secondary};
  width: 12px;
  height: 12px;
`;

export const StyledGroup = styled(Group).attrs({
  viewType: ViewType.Kanban,
  variant: 'swimlane',
  withGroupBy: true,
}) <{ isOver?: boolean; isDragging?: boolean }>`
  ${p => p.collapse && css`
    &:hover {
      ${CollapsedGroupIcon} {
        color: ${p.theme.colors.text.primary};
      }
    }
  `};
  ${p => p.isOver && css`
    margin-right: 20px;
  `};
  ${p => p.isDragging && css`
    ${boxShadowZ4};
    transform: rotate(-1deg);
    height: calc(100vh - 160px);
    opacity: 0.95;
  `};
`;

export const GroupByHeaders = styled.div`
  position: sticky;
  z-index: 3;
  top: 0;
  display: flex;

  .swimlane-last-header {
    padding-right: 32px;
  }
`;

// That fixed div is necessary to avoid SwimlaneDoc to be visible in headers gaps when we scroll right
export const GroupByHeadersBg = styled.div`
  position: fixed;
  z-index: 3;
  height: 35px;
  width: 100%;
  background-color: ${p => p.theme.colors.body.bg};
`;

export const SwimlaneDocGroups = styled.div`
  display: flex;
  margin-top: -8px;
`;

export const Bottom = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
`;

interface StyledBoardGroupNewDocProps {
  $pos: NewDocPosition;
}

export const StyledBoardGroupNewDoc = styled(BoardGroupNewDoc)<StyledBoardGroupNewDocProps>`
  ${p => `margin: ${p.$pos === 'top' ? '8px 0px 0px' : '2px 0px 8px'};`}
`;

export const SwimlaneDocSkeleton = styled.div`
  ${swimlaneDocCSS};
  box-shadow: none;
  display: flex;
  align-items: center;
  gap:8px;
  height: 40px;
  padding: 8px;
  margin-bottom: 8px;
`;

export const EmojiSkeleton = styled(Skeleton)`
  height: 20px;
  width: 20px;
`;

export const DocTitleSkeleton = styled(Skeleton)`
  width: 50%;
`;

export const GroupHeaderSkeleton = styled(BoardGroupSkeleton).attrs({
  nbCards: 0,
  withTitle: false,
})`
  height: 32px;
  padding: 0;
`;
export const DraggableGroup = styled.div`
  position: relative;
`;

export const LinePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  margin-right: 20px;
  height: calc(100vh - 80px);
  border-radius: 8px;
  background-color: hsl(var(--cycle));
`;

export const DraggingColumnDocs = styled.div`
  padding: 64px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const DraggingColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 42px;
`;
