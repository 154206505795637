import { DoctypeType, MateFragment } from '@cycle-app/graphql-codegen';
import { ReactNode, useCallback } from 'react';
import { Placement } from 'tippy.js';

import { DocAssigneesDropdown } from 'src/components/DocAssigneesDropdown/DocAssigneesDropdown';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useRemoveDocAssignee, useUpdateDocAssignee } from 'src/hooks/api/mutations/updateDocHooks';
import { setLastAssigneeIdUsed } from 'src/reactives/lastView.reactive';
import { Layer } from 'src/types/layers.types';

export type AssigneeDropdownProps = {
  layer?: Layer;
  docId?: string;
  onSelect?: (user: MateFragment | null) => void;
  onClear?: VoidFunction;
  isRemovable?: boolean;
  showWarnings?: boolean;
  dropdownPlacement?: Placement;
  hideIncompatibleValues?: boolean;
  docTypeName: string;
  docTypeType?: DoctypeType;
};

export const AssigneeDropdown = ({
  docId,
  layer = Layer.Dropdown,
  onSelect,
  onClear,
  isRemovable = true,
  showWarnings,
  dropdownPlacement,
  hideIncompatibleValues,
  docTypeName,
  docTypeType,
  selectedId,
  showDropdown,
  hideDropdown,
  children,
}: AssigneeDropdownProps & {
  selectedId?: string;
  showDropdown: VoidFunction;
  hideDropdown: VoidFunction;
  children: ReactNode;
}) => {
  const users = useBoardConfig(ctx => ctx.usersForAssignee);
  const assigneeIsRequired = useBoardConfig(ctx => ctx.assigneeIsRequired);

  const { removeDocAssignee } = useRemoveDocAssignee();
  const { updateDocAssignee } = useUpdateDocAssignee();

  const onClearValue = useCallback(async () => {
    hideDropdown();
    onClear?.();
  }, [hideDropdown, onClear]);

  const onSelectUser = useCallback(
    async (user: MateFragment | null) => {
      const selectedUser = users.find(u => u.id === user?.id);
      if (selectedUser) {
        onSelect?.(selectedUser);
      }
      hideDropdown();
      if (docId) {
        if (user) {
          setLastAssigneeIdUsed(user.id);
          await updateDocAssignee(
            {
              docId,
              userId: user.id,
            },
            !selectedUser?._compatibleWithBoardConfig,
          );
        } else {
          setLastAssigneeIdUsed(null);
          await removeDocAssignee({ docId }, assigneeIsRequired);
        }
      }
    },
    [
      users,
      docId,
      hideDropdown,
      onSelect,
      updateDocAssignee,
      removeDocAssignee,
      assigneeIsRequired,
    ],
  );

  return (
    <DocAssigneesDropdown
      layer={layer}
      isVisible
      onShow={showDropdown}
      onHide={hideDropdown}
      onChange={onSelectUser}
      selectedId={selectedId}
      onClear={onClearValue}
      hasWarningOnNoneValue={assigneeIsRequired}
      isRemovable={isRemovable}
      showWarnings={showWarnings}
      dropdownPlacement={dropdownPlacement}
      hideIncompatibleValues={hideIncompatibleValues}
      docTypeName={docTypeName}
      docTypeType={docTypeType}
    >
      {children}
    </DocAssigneesDropdown>
  );
};
