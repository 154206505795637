import { DPA, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'src/constants/policies.constants';

import { Anchor, PolicyParagraph } from './Policy.styles';

export const Policy = ({ className }: { className?: string }) => {
  return (
    <PolicyParagraph className={className}>
      By submitting this form, you agree to
      {' '}
      <Anchor
        href={TERMS_AND_CONDITIONS}
        target="_blank"
      >
        Cycle’s Terms of Use
      </Anchor>
      ,
      {' '}
      <Anchor
        href={PRIVACY_POLICY}
        target="_blank"
      >
        Privacy Policy
      </Anchor>
      {' '}
      and
      {' '}
      <Anchor
        href={DPA}
        target="_blank"
      >
        Data Processing Agreement
      </Anchor>
      . You also consent to receive personalized marketing communications from which you may unsubscribe at any time.
    </PolicyParagraph>
  );
};
