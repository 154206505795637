import { typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { Size } from './ErrorPage.types';

type CommonProps = {
  $size: Size;
};

export const Title = styled.div``;

export const Img = styled.img``;

export const Container = styled.div<CommonProps>`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 16px;

  ${p => p.$size === Size.DEFAULT && css`
    ${Title} {
      ${typo.headerLarge600}
    }

    ${Img} {
      width: 278px;
    }
  `}

  ${p => p.$size === Size.SMALL && css`
    ${Title} {
      ${typo.headerSmall}
    }

    ${Img} {
      width: 150px;
    }
  `}
`;
