import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal';
import { CustomerProfile } from 'src/components/CustomerCompanyProfile';
import { CustomerPageTitle } from 'src/components/PageTitle';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useCustomerId } from 'src/hooks/usePathParams';

import { Container } from './CustomerPage.styles';

export const CustomerPage = () => {
  const customerId = useCustomerId();
  const { customer } = useCustomer(customerId);
  if (!customerId) return null;
  return (
    <Container>
      <CustomerPageTitle customerName={customer?.displayName} />
      <CustomerProfile customerId={customerId} />
      <CreateDocModal />
    </Container>
  );
};
