import { CheckIcon, CloseIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { closeSourcesModal } from 'src/reactives/integrations.reactive';
import { resetMeetingsModalInstalled } from 'src/reactives/meetingsModalInstalled.reactive';
import { openSettingsRecording } from 'src/reactives/settingsModal.reactive';

import {
  Check, CloseButton, Content, Img, ImgContainer, StyledButton, Title,
} from './MettingsModalInstalled.styles';

type Props = {
  children?: ReactNode;
};

const light = '/images/recording-avatars-nobubble-light.png';
const dark = '/images/recording-avatars-nobubble-dark.png';

export const MettingsModalInstalledContent: FC<React.PropsWithChildren<Props>> = () => {
  const { isDark } = useTheme();
  return (
    <>
      <CloseButton onClick={resetMeetingsModalInstalled}>
        <CloseIcon size={12} />
      </CloseButton>
      <Content>
        <ImgContainer>
          <Img src={isDark ? dark : light} alt="" />
          <Check>
            <CheckIcon width="22px" height="22px" />
          </Check>
        </ImgContainer>
        <Title>You have enabled the call recording integration for yourself.</Title>
        <p className="mt-3 text-secondary">
          You can configure the meetings recorded in your personal settings in Cycle.
        </p>
      </Content>
      <StyledButton
        full
        size="M"
        onClick={() => {
          resetMeetingsModalInstalled();
          closeSourcesModal();
          openSettingsRecording();
        }}
      >
        Go to settings
      </StyledButton>
    </>
  );
};
