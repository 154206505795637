import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetStarredSection,
  getValue: getStarredSection,
  setValue: setStarredSection,
} = make<{
  isOpen: boolean;
}>({
  localKey: LocalKey.StarredSection,
  crossTabSync: true,
  defaultState: {
    isOpen: true,
  },
});

export const toggleStarredSection = () => setStarredSection({
  isOpen: !getStarredSection().isOpen,
});
