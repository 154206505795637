import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DropdownLayer, DropdownLayerProps } from 'src/components/DropdownLayer';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { getDocType } from 'src/reactives/docTypes.reactive';

export const FeatureDropdown = ({
  insightId, hide, ...props
}: Omit<DropdownLayerProps, 'content' | 'hide'> & {
  insightId: string;
  hide: VoidFunction;
}) => {
  return (
    <DropdownLayer
      withPortal
      withWrapper
      hide={hide}
      content={(
        <Content
          hide={hide}
          insightId={insightId}
        />
      )}
      {...props}
    />
  );
};

type ContentProps = {
  hide: VoidFunction;
  insightId: string;
};

const Content = ({
  hide, insightId,
}: ContentProps) => {
  const insight = useDocPanelContext(ctx => ctx.doc?.docTargets.edges.find(edge => edge.node?.id === insightId)?.node);
  const docType = getDocType(insight?.doc?.doctype.id);
  if (!docType || !insight?.doc) return null;
  return (
    <DocParentPanel
      docId={insight.doc.id}
      docType={docType}
      showNoneOption={!!insight.doc?.parent}
      hide={hide}
      onChange={hide}
    />
  );
};
