import { SelectPanel } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledSelectPanel = styled(SelectPanel)`
  width: 260px;
`;

export const Placeholder = styled.div`
  width: 260px;
  padding: 4px 8px;
`;
