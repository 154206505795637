import { BoardLinkButton } from './BoardLinkButton';

type ViewNameProps = {
  boardId: string;
  name: string;
  isSmall: boolean;
};

export const ViewName = ({
  boardId, name, isSmall,
}: ViewNameProps) => {
  return (
    <>
      <div>
        {name}
      </div>

      {!isSmall && (
        <BoardLinkButton
          boardId={boardId}
        />
      )}
    </>
  );
};
