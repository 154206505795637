import { CheckCircleFillIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

interface ContainerProps {
  $split?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${p => p.$split && `
    display: flex;
    gap: 28px;
  `}
`;

interface ItemProps {
  $tooltip?: boolean;
}

export const Item = styled.li<ItemProps>`
  display: flex;
  gap: 8px;
  text-align: left;

  & + & {
    margin-top: 6px;
  }

  ${p => p.$tooltip && `
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;
    text-decoration-color: ${p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.baseColors.Grey300};
    text-decoration-style: dashed;
  `}
`;

export const StyledCheckIcon = styled(CheckCircleFillIcon)`
  vertical-align: middle;
  flex: none;
  margin-top: 4px;

  &[data-i="0"] {
    color: rgba(222, 209, 252, 1);
  }
  &[data-i="1"] {
    color: rgba(219, 216, 252, 1);
  }
  &[data-i="2"] {
    color: rgba(216, 222, 252, 1);
  }
  &[data-i="3"] {
    color: rgba(214, 227, 252, 1);
  }
  &[data-i="4"] {
    color: rgba(211, 233, 252, 1);
  }
  &[data-i="5"] {
    color: rgba(208, 239, 252, 1);
  }
  &[data-i="6"] {
    color: rgba(203, 239, 247, 1);
  }
  &[data-i="7"] {
    color: rgba(199, 239, 242, 1);
  }
  &[data-i="8"] {
    color: rgba(194, 238, 237, 1);
  }
  &[data-i="9"] {
    color: rgba(189, 238, 232, 1);
  }
  &[data-i="10"] {
    color: rgba(189, 238, 232, 1);
  }
  &[data-i="11"] {
    color: rgba(180, 238, 222, 1);
  }
`;
