import { Input, Button } from '@cycle-app/ui';
import { FC, ReactNode } from 'react';
import { useForm } from 'react-hook-form';

import { useGithubAssignees } from 'src/hooks/api/queries/integrations/useGithubAssignees';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import { useGithubRepositories } from 'src/hooks/api/queries/integrations/useGithubRepositories';
import { useLastGitHubAssigneeUsed, useLastGitHubDataUsed } from 'src/reactives/githubRepository.reactive';
import { CreateGithubIssueParams, CreateGithubIssueFormValues } from 'src/types/integrations.types';

import { AssigneesField } from './AssigneesField/AssigneesField';
import { Form, FieldsGroup, FormFooter } from './GithubIssueCreationForm.styles';
import { ProjectsField } from './ProjectsField';
import { RepositoriesField } from './RepositoriesField/RepositoriesField';
import { StatusesField } from './StatusesField/StatusesField';

type Props = {
  initialValues: CreateGithubIssueFormValues;
  onSubmit: (params: CreateGithubIssueParams) => void;
  isLoading: boolean;
  submitLabel?: string;
  footerSlot?: ReactNode;
};

export const GithubIssueCreationForm: FC<React.PropsWithChildren<Props>> = ({
  initialValues,
  onSubmit,
  isLoading,
  submitLabel = 'Create',
  footerSlot,
}) => {
  const { repositories } = useGithubRepositories();
  const lastUsedRepositoryId = useLastGitHubDataUsed({ domain: 'repository' });
  const repository = repositories?.find(r => r?.id === lastUsedRepositoryId) ?? repositories?.[0] ?? undefined;

  const {
    projects, statusesByProjects,
  } = useGithubProject();
  const lastUsedProjectId = useLastGitHubDataUsed({ domain: 'project' });
  const project = projects?.find(p => p?.id === lastUsedProjectId) ?? projects?.[0] ?? undefined;
  const projectOptions = statusesByProjects?.[project?.id ?? '']?.options ?? [];

  const lastUsedStatusId = useLastGitHubDataUsed({ domain: 'statusId' });
  const statusId = projectOptions.find(s => s?.id === lastUsedStatusId)?.id ?? projectOptions?.[0]?.id;

  const { assignees } = useGithubAssignees();
  const lastUsedAssigneeIds = useLastGitHubAssigneeUsed();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateGithubIssueFormValues>({
    values: {
      title: initialValues.title,
      repository,
      project,
      description: initialValues.title ? '' : undefined,
      statusId,
      assignees: assignees.filter(a => lastUsedAssigneeIds?.includes(a.id)),
    },
  });

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      {!!initialValues.title && (
        <Input
          id="title"
          type="title"
          label="Issue title"
          autoComplete="off"
          {...register('title', {
            required: true,
            minLength: 3,
          })}
          error={errors.title?.message}
        />
      )}

      <RepositoriesField control={control} />

      <ProjectsField control={control} />

      {!!initialValues.title && (
        <Input
          id="description"
          type="description"
          label="Description"
          autoComplete="off"
          placeholder="Your doc type description"
          {...register('description')}
          error={errors.title?.message}
        />
      )}

      <FieldsGroup>
        <StatusesField control={control} />
        <AssigneesField control={control} />
      </FieldsGroup>

      <FormFooter>
        {footerSlot}
        <Button
          type="submit"
          size="L"
          isLoading={isLoading}
        >
          {submitLabel}
        </Button>
      </FormFooter>

    </Form>
  );

  async function onFormSubmit(data: CreateGithubIssueFormValues) {
    if (!data.repository?.name) return;

    onSubmit({
      title: data.title,
      repoName: data.repository.name,
      description: data.description,
      assignees: data.assignees,
      statusId: data.statusId,
      projectId: data.project?.id,
    });
  }
};
