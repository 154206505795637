export type Direction = 'previous' | 'next';

export function getTargetIndex(direction: Direction, values: string[], previousValue: string | null | undefined): number {
  if (values.length === 1) {
    return 0;
  }

  const previousIndexSelected = values.findIndex((value) => value === previousValue);

  const newIndex = direction === 'next'
    ? previousIndexSelected + 1
    : previousIndexSelected - 1;

  if (newIndex < 0) return values.length - 1;
  if (newIndex > values.length - 1) return 0;

  return Math.min(Math.max(0, newIndex), values.length - 1);
}
