import { DocParentFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { CommentAltIcon, AddMemberAltIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import DocComments from 'src/components/DocComments/DocComments';
import { DocQuotes } from 'src/components/DocInsights';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getCommentsCount } from 'src/utils/comments.utils';

import { Footer } from './QuoteCards.styles';

type Props = {
  doc: DocParentFragment;
};

export const FeatureCardFooter = ({ doc }: Props) => {
  const isOptimisticData = doc.id.includes('temp');
  const docType = useGetDocType(doc?.doctype.id);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);

  if (!doc || isOptimisticData) {
    return (
      <div className="flex items-center gap-2">
        <Skeleton height={20} width={30} />
        <Skeleton height={20} width={30} />
      </div>
    );
  }

  return (
    <Footer>
      <DocComments
        docId={doc.id}
        nbComments={doc ? getCommentsCount(doc) : 0}
        buttonIcon={<CommentAltIcon size={16} />}
        isDropdownVisible={isCommentsVisible}
        onHideDropdown={() => setIsCommentsVisible(false)}
        onToggleDropdown={() => setIsCommentsVisible(prev => !prev)}
      />

      <DocQuotes
        doc={doc}
        context="doc-item"
        layer={Layer.DropdownZ2}
      />

      <div style={{ flex: 1 }} />

      {docType && (
        <DocAssignee
          assignee={doc.assignee}
          tooltipPlacement="top"
          docId={doc.id}
          context="doc-item"
          docTypeName={docType.name}
          docTypeType={docType.type}
          icon={<AddMemberAltIcon size={16} />}
        />
      )}
    </Footer>
  );
};