import { Tooltip } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { CompanyDomainForm } from './CompanyDomainForm';
import { EmptyButton, CompanyLink, ProfileEditButton } from './Profile.styles';

type Props = {
  companyId: string;
};

export const CompanyDomain = ({ companyId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { company } = useCompany(companyId);

  if (isEditing) {
    return (
      <CompanyDomainForm
        companyId={companyId}
        domain={company?.domain}
        logo={company?.logo}
        hide={() => setIsEditing(false)}
      />
    );
  }

  if (company?.domain) {
    const url = company.domain.startsWith('https://')
      ? company.domain
      : `https://${company.domain}`;

    return (
      <CompanyLink
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {url}
        {!company.isDefault && (
          <Tooltip
            content="Edit domain"
            placement="top"
            withPortal
            withWrapper={false}
          >
            <ProfileEditButton
              onClick={(e) => {
                e.preventDefault();
                setIsEditing(true);
              }}
            >
              <PenFilledIcon />
            </ProfileEditButton>
          </Tooltip>
        )}
      </CompanyLink>
    );
  }

  return (
    <EmptyButton onClick={() => setIsEditing(true)}>
      Add domain…
    </EmptyButton>
  );
};
