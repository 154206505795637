import { MotionProps, Variants } from 'framer-motion';

export const motionProps: MotionProps = {
  initial: 'exit',
  exit: 'exit',
  animate: 'enter',
};

export const variantsContent: Variants = {
  exit: {
    scale: 0.9,
    opacity: 0,
    transition: {
      duration: 0.15,
      delay: 0,
    },
  },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.15,
      delay: 0,
    },
  },
};
