import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  ${typo.headerSmall};
`;

export const Buttons = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;
