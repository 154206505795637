import { typo, truncate, ActionButton } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { EditorContentCss } from 'src/styles';

import { EditComment } from '../DocComment/EditComment';
import { InlineChat } from '../Editor/InlineComments/InlineChat';

export const Container = styled.div<{
  $isDisabled?: boolean;
  $isOpen?: boolean;
  $isHover?: boolean;
}>`
  padding: 16px;
  border-bottom: 1px solid ${p => p.theme.colors.home.separator};
  display: flex;
  flex-direction: column;
  gap: 8px;

  :hover {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey100)};
  }
  
  ${p => p.$isHover && `
    background: ${p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey100};
  `}

  ${p => p.$isOpen && `
    background: ${p.theme.isDark ? 'hsl(var(--cycle),0.15)' : 'hsl(var(--cycle100))'};
  `}

  ${p => !p.$isDisabled && `
    cursor: pointer;
  `}

  ${p => p.$isDisabled && `
    :hover {
      background: transparent;
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`;

export const Meta = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;

export const Author = styled.div`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
  ${truncate}
`;

export const Actions = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Comment = styled.div.attrs({ inert: '' })`
  margin: -4px 0;
`;

export const Quote = styled.div.attrs({ inert: '' })`
  ${EditorContentCss}
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
  ${truncate}
  padding-left: 11px;
  margin: 2px 0;
  position: relative;
  ::before {
    content: ' ';
    display: inline-block;
    width: 3px;
    height: 100%;
    background: ${p => (p.theme.isDark ? 'hsl(var(--cycle))' : 'hsl(var(--cycle300))')};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const ReplyCount = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Date = styled.p`
  flex: none;
  ${typo.tiny400}
  color: ${p => p.theme.colors.text.disabled};
`;

export const ResolveButton = styled(ActionButton)<{ $resolved: boolean }>`
  opacity: 0;
  
  ${p => p.$resolved && `
    opacity: 1;
    color: resolved ? 'hsl(var(--cycle))' : 'unset'
  `}

  ${Container}:hover & {
    opacity: 1;
  }
`;

export const ThreadMenu = styled(DotsMenuLayer)`
  opacity: 0;

  ${Container}:hover & {
    opacity: 1;
  }

  &.force-focus {
    opacity: 1;
  }
`;

export const StyledEditComment = styled(EditComment)`
  margin: 0;
`;

export const StyledInlineChat = styled(InlineChat)`
  width: 350px;
`;
