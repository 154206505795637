import { ERROR_CODE } from '@cycle-app/utilities';

export const ErrorMessage = {
  _GENERIC: '🧐 Oops, looks like something went wrong on our side, we’re on it!',
  [ERROR_CODE.AUDIO_NOT_FOUND]: '🧐 Oops, looks like your video does not contain audio',
  [ERROR_CODE.CANT_REMOVE_CUSTOMER_FROM_COMPANY]: 'Cannot update. A company should always have at least one customer',
  [ERROR_CODE.CANT_REMOVE_LAST_CUSTOMER]: 'Cannot delete. A company should always have at least one customer',
  [ERROR_CODE.CUSTOMER_EMAIL_ALREADY_USED]: 'A customer with this email already exists',
  [ERROR_CODE.CUSTOMER_WITH_EMAIL_ALREADY_EXISTS]: 'A customer with this email already exists',
  [ERROR_CODE.INTERNAL_SERVER_ERROR]: 'Server temporary unavailable, please try again later',
  [ERROR_CODE.NOT_AUTHENTICATED]: 'Looks like the server is not authenticated, contact support',
  AUDIO_CONVERSION_FAILED: '🤭 Oops, file conversion failed, try again',
  CALL_FETCH_URL_FAILED: "🧐 Looks like we weren't able to get the installation link, retry later",
  CALL_RECORDING_NOT_INSTALLED: '🧐 Looks like the call recording integration is not installed',
  CALL_RECORDING_UPDATE_FAIL: '🧐 Looks like we can not update the meeting integration, retry later',
  DATE_INVALID: '🤭 Oops, the date looks like invalid',
  DATE_TOO_EARLY: '😅 Oops, date is a bit too old',
  DATE_TOO_LATE: '😅 Oops, date is a bit to much is the future',
  EDITOR_AI: '🧐 Oops, looks like something went wrong with , try again later or contact du support',
  EDITOR_VIDEO_ERROR: '🧐 Looks like we can\'t load this video',
  FILE_NOT_SUPPORTED: 'This file is not supported',
  MICROPHONE_NOT_ACCESSIBLE: '🧐 Looks like we do not have access to your microphone, authorize it and try again',
  NO_PRODUCT: '🧐 Oops, looks like something went wrong with your workspace, try again later or contact du support',
  SERVER_UNAVAILABLE: 'Server temporary unavailable, please try again later',
  TRANSCRIPT_FAILED: '🧐 We were unable to get the transcript, retry later',
  UNAUTHORIZED: '🤭 It seems that you do not have the required authorization',
  NOT_A_BUSINESS_EMAIL: '✉️ Please use a business email ✉️',
  [ERROR_CODE.AUTO_LINK_TO_ROADMAP_CAN_BE_TURN_ON_ONLY_IF_EXTRACT_INSIGHTS_IS_ON]:
    '"Categorize" can only be activated if "Extract quotes" is enabled',
  [ERROR_CODE.MARK_AS_PROCESSED_CAN_BE_TURN_ON_ONLY_IF_EXTRACT_INSIGHTS_IS_ON]:
    '"Mark processed" can only be activated if "Extract quotes" is enabled',
  [ERROR_CODE.PRODUCT_WITH_SLUG_ALREADY_EXISTS]: 'This workspace domain is already used, please try something else.',
};
