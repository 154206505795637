import { NodeViewWrapper } from '@tiptap/react';
import styled from 'styled-components';

import { EDITOR_MARGIN } from 'src/constants/editor.constants';

const BLOCK_CODE_PADDING = 8;

export const Container = styled(NodeViewWrapper)`
  position: relative;
`;

export const Toolbar = styled.div<{ $isReadOnly: boolean }>`
  position: absolute;
  top: ${BLOCK_CODE_PADDING}px;
  right: ${p => (p.$isReadOnly ? 0 : EDITOR_MARGIN) + BLOCK_CODE_PADDING}px;

  display: flex;
  gap: 4px;
`;

export const LanguageContainer = styled.div`
  position: absolute;
  bottom: ${BLOCK_CODE_PADDING}px;
  right: ${EDITOR_MARGIN + BLOCK_CODE_PADDING}px;
`;

export const Language = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  color: white;

  svg {
    width: 10px;
    height: 10px;
  }
`;
