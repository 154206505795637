import { useApolloClient, useQuery } from '@apollo/client';
import { FetchProductIntegrationsDocument, IntegrationFullFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';

import { COLLAB_INTEGRATIONS, SOURCE_INTEGRATIONS } from 'src/constants/integrations.constants';
import { Integration, FrontEndIntegration } from 'src/types/integrations.types';
import { IntegrationsByType } from 'src/utils/integrations.utils';

import { useProductAddOn } from '../useProductAddOns';
import { useProductBase, useIsFreePlan } from './useProduct';

type IntegrationItemProps = {
  integration?: IntegrationFullFragment;
  integrationType: Integration;
};

type IntegrationItemsByStatus = {
  installed: IntegrationItemProps[];
  uninstalled: IntegrationItemProps[];
};

export const useProductIntegrations = () => {
  const product = useProductBase();
  const isFreePlan = useIsFreePlan();
  const callRecordingFlag = useProductAddOn('MEETINGS');

  const {
    data, loading, called,
  } = useQuery(FetchProductIntegrationsDocument, {
    skip: !product?.id,
    variables: {
      productId: product?.id ?? '',
    },
  });
  const client = useApolloClient();

  const integrations = useMemo(() => {
    if (data?.node?.__typename !== 'Product') return [];

    return nodeToArray(data.node.integrations);
  }, [data]);

  const getIntegration = useCallback(
    (type: IntegrationType) => integrations.find(i => i.type === type),
    [integrations],
  );

  const refetch: VoidFunction = useCallback(async () => {
    // refetch from useQuery does not trigger in some cases.
    // await query.refetch({ productId });
    await client.refetchQueries({
      include: [FetchProductIntegrationsDocument],
    });
  }, [client]);

  const integrationsByType = useMemo<IntegrationsByType>(() => Object.fromEntries(integrations.map((i) => [i.type, i])), [integrations]);
  const getIntegrationsByInstallStatus = useCallback(
    (integrationTypes: Integration[]) => integrationTypes.reduce<IntegrationItemsByStatus>((acc, integrationType) => {
      const integration = integrationsByType[integrationType];
      const isIntegrationInstalled = (
        !!integration?.provider ||
        ([
          IntegrationType.Zapier,
          IntegrationType.Extension,
          // FrontEndIntegration.CHROME,
          FrontEndIntegration.CYCLE_API,
          FrontEndIntegration.WEBHOOKS,
          ...(callRecordingFlag.isEnabled ? [IntegrationType.Meeting] : []),
        ].includes(integrationType as FrontEndIntegration) && !isFreePlan)
      );
      acc[isIntegrationInstalled ? 'installed' : 'uninstalled'].push({
        integrationType,
        integration,
      });
      return acc;
    }, {
      installed: [],
      uninstalled: [],
    }),
    [callRecordingFlag, integrationsByType, isFreePlan],
  );
  const sourcesByStatus = useMemo(() => getIntegrationsByInstallStatus(SOURCE_INTEGRATIONS), [getIntegrationsByInstallStatus]);
  const collabByStatus = useMemo(() => getIntegrationsByInstallStatus(COLLAB_INTEGRATIONS), [getIntegrationsByInstallStatus]);

  return {
    isCalled: called,
    isLoading: loading,
    list: integrations,
    product,
    getIntegration,
    refetch,
    sourcesByStatus,
    collabByStatus,
    installedCount:
      sourcesByStatus.installed.length -
      // zappier + chrome
      (isFreePlan ? 0 : 2) +
      collabByStatus.installed.length,
  };
};
