import { Icon } from '@cycle-app/ui';
import {
  BoldIcon,
  BulletListIcon,
  CaretIcon,
  ChecklistIcon,
  CodeIcon,
  CommentAltIcon,
  ConfluenceIcon,
  DetailsIcon,
  DocumentIcon,
  EmbedIcon,
  FigmaIcon,
  FileIcon,
  GithubIcon,
  H1Icon,
  H2Icon,
  H3Icon,
  ImageIcon,
  ItalicIcon,
  JiraIcon,
  LinearIcon,
  LinkIcon,
  LoomIcon,
  MinusIcon,
  MiroIcon,
  NotionIcon,
  NumberedListIcon,
  QuoteIcon,
  SmileyIcon,
  StrikeIcon,
  TableIcon,
  TableOfContentIcon,
  UnderlineIcon,
  UserIcon,
  VideoIcon,
  VolumeFullOutlineIcon,
  ReleaseNoteIcon,
  AiIcon,
} from '@cycle-app/ui/icons';
import { Editor } from '@tiptap/core';
import { ReactNode } from 'react';

import { COMMANDS } from 'src/constants/editor.constants';
import { getPermission, setEditorAi, setEditorNodeOpen, setLimitationsModal } from 'src/reactives';
import { ShortcutEditor } from 'src/types/shortcuts.types';

export enum ActionId {
  AddEmoji = 'add-emoji',
  Ai = 'ai',
  Audio = 'audio',
  Bold = 'bold',
  BulletList = 'bullet-list',
  CheckList = 'check-list',
  Code = 'code',
  Colors = 'colors',
  Comments = 'comments',
  Confluence = 'confluence',
  Details = 'details',
  Divider = 'divider',
  Embed = 'embed',
  File = 'file',
  GithubIssue = 'github-issue',
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Image = 'image',
  Italic = 'italic',
  Jira = 'jira',
  Linear = 'linear',
  LinearProject = 'linear-project',
  Link = 'link',
  MentionDoc = 'mention-feature',
  MentionUser = 'mention-user',
  Notion = 'notion',
  NumberedList = 'ordered-list',
  Quote = 'quote',
  Strike = 'strike',
  Table = 'table',
  TableOfContent = 'table-of-content',
  Transcript = 'transcript',
  TurnTextIntoDocMention = 'turn-text-into-doc-mention',
  TurnTextIntoGithubIssueMention = 'turn-text-into-github-issue-mention',
  TurnTextIntoInsight = 'turn-text-into-insight',
  TurnTextIntoLinearMention = 'turn-text-into-linear-mention',
  Underline = 'underline',
  Video = 'video',
}

export interface Action {
  closeBubbleOnClick?: boolean;
  id: ActionId;
  label?: string;
  labelAlt?: string;
  icon?: ReactNode;
  node?: string;
  nodeParams?: Record<string, string | number>;
  toggle?: (editor: Editor, from: 'bubble-menu' | 'slash-menu' | 'empty-menu', id?: string) => void;
  quickActionIcon?: ReactNode;
  shortcut?: ShortcutEditor;
  // Will use a <ButtonText />
  text?: ReactNode;
  isNew?: boolean;
}

export const actions: Record<ActionId, Action> = {
  [ActionId.Heading1]: {
    id: ActionId.Heading1,
    label: 'Heading 1',
    icon: <H1Icon />,
    node: 'heading',
    nodeParams: {
      level: 1,
    },
    toggle: (editor) => editor.chain().focus().toggleHeading({ level: 1 }).run(),
  },
  [ActionId.Heading2]: {
    id: ActionId.Heading2,
    label: 'Heading 2',
    icon: <H2Icon />,
    node: 'heading',
    nodeParams: {
      level: 2,
    },
    toggle: (editor) => editor.chain().focus().toggleHeading({ level: 2 }).run(),
  },
  [ActionId.Heading3]: {
    id: ActionId.Heading3,
    label: 'Heading 3',
    icon: <H3Icon />,
    node: 'heading',
    nodeParams: {
      level: 3,
    },
    toggle: (editor) => editor.chain().focus().toggleHeading({ level: 3 }).run(),
  },
  [ActionId.Bold]: {
    id: ActionId.Bold,
    label: 'Bold',
    icon: <BoldIcon />,
    node: 'bold',
    toggle: (editor) => editor.chain().focus().toggleBold().run(),
  },
  [ActionId.Italic]: {
    id: ActionId.Italic,
    label: 'Italic',
    icon: <ItalicIcon />,
    node: 'italic',
    toggle: (editor) => editor.chain().focus().toggleItalic().run(),
  },
  [ActionId.Strike]: {
    id: ActionId.Strike,
    label: 'Strike',
    icon: <StrikeIcon />,
    node: 'strike',
    toggle: (editor) => editor.chain().focus().toggleStrike().run(),
  },
  [ActionId.Underline]: {
    id: ActionId.Underline,
    label: 'Underline',
    icon: <UnderlineIcon />,
    node: 'underline',
    toggle: (editor) => editor.chain().focus().toggleUnderline().run(),
  },

  [ActionId.CheckList]: {
    id: ActionId.CheckList,
    label: 'Check list',
    icon: <ChecklistIcon />,
    toggle: (editor) => editor.chain().focus().toggleTaskList().run(),
  },
  [ActionId.BulletList]: {
    id: ActionId.BulletList,
    label: 'Bullet list',
    icon: <BulletListIcon />,
    toggle: (editor) => editor.chain().focus().toggleBulletList().run(),
  },
  [ActionId.NumberedList]: {
    id: ActionId.NumberedList,
    label: 'Numbered list',
    icon: <NumberedListIcon />,
    toggle: (editor) => editor.chain().focus().toggleOrderedList().run(),
  },
  [ActionId.Embed]: {
    id: ActionId.Embed,
    label: 'Embed',
    labelAlt: 'Embed',
    icon: <EmbedIcon />,
    quickActionIcon: (
      <>
        <FigmaIcon />
        <MiroIcon />
        <LoomIcon />
      </>
    ),
  },
  [ActionId.Link]: {
    id: ActionId.Link,
    label: 'Link',
    node: 'link',
    icon: <LinkIcon />,
  },
  [ActionId.Image]: {
    id: ActionId.Image,
    label: 'Image',
    icon: <ImageIcon size={14} />,
    toggle: (editor) => {
      const dataId = crypto.randomUUID();
      const { selection } = editor.state;
      setEditorNodeOpen('fileNodeOpen', dataId);
      return editor.chain().focus().setFile({
        file: {
          alt: '',
          size: 0,
          src: '',
          title: '',
          type: 'image',
          mime: '',
        },
        dataId,
      }).setNodeSelection(selection.from - 1)
        .run();
    },
  },
  [ActionId.Quote]: {
    id: ActionId.Quote,
    label: 'Citation',
    icon: <Icon name="text-quote" size={16} />,
    toggle: (editor) => editor.chain().focus().toggleBlockquote().run(),
  },
  [ActionId.Code]: {
    id: ActionId.Code,
    label: 'Code',
    icon: <CodeIcon />,
    node: 'codeBlock',
    toggle: (editor) => editor.chain().focus().toggleCodeBlock().run(),
  },
  [ActionId.File]: {
    id: ActionId.File,
    label: 'Upload file',
    labelAlt: 'File',
    icon: <FileIcon />,
    toggle: (editor) => {
      const dataId = crypto.randomUUID();
      setEditorNodeOpen('fileNodeOpen', dataId);
      const { selection } = editor.state;
      return editor.chain().focus().setFile({
        file: undefined,
        dataId,
      }).setNodeSelection(selection.from - 1)
        .run();
    },
  },
  [ActionId.MentionUser]: {
    id: ActionId.MentionUser,
    label: 'Mention user',
    labelAlt: 'Mention a user',
    icon: <UserIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.USER_MENTION).run(),
  },
  [ActionId.MentionDoc]: {
    id: ActionId.MentionDoc,
    label: 'Mention feature',
    labelAlt: 'Mention a feature',
    icon: <DocumentIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.DOC_MENTION).run(),
  },
  [ActionId.TurnTextIntoDocMention]: {
    id: ActionId.TurnTextIntoDocMention,
    label: 'Turn into mention',
    labelAlt: 'Turn into mentions',
    icon: <CaretIcon />,
  },
  [ActionId.TurnTextIntoGithubIssueMention]: {
    id: ActionId.TurnTextIntoGithubIssueMention,
    label: 'Turn into GitHub issues',
    labelAlt: 'Turn into GitHub issues',
    icon: <GithubIcon />,
    shortcut: ShortcutEditor.TurnGithubIssue,
  },
  [ActionId.TurnTextIntoLinearMention]: {
    id: ActionId.TurnTextIntoLinearMention,
    label: 'Turn into Linear project/issue',
    labelAlt: 'Turn into Linear project/issue',
    icon: <LinearIcon />,
    shortcut: ShortcutEditor.TurnLinearIssue,
  },
  [ActionId.TurnTextIntoInsight]: {
    id: ActionId.TurnTextIntoInsight,
    label: 'Extract quote',
    text: 'Quote',
    icon: <QuoteIcon />,
    shortcut: ShortcutEditor.TurnIntoInsight,
    toggle: (editor) => editor.chain().setHighlightMark({
      id: crypto.randomUUID(),
      type: 'insight',
    }).run(),
  },
  [ActionId.AddEmoji]: {
    id: ActionId.AddEmoji,
    label: 'Emoji',
    labelAlt: 'Emoji',
    icon: <SmileyIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.EMOJI).run(),
  },
  [ActionId.GithubIssue]: {
    id: ActionId.GithubIssue,
    label: 'GitHub issues',
    icon: <GithubIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.GITHUB_ISSUE).run(),
  },
  [ActionId.Linear]: {
    id: ActionId.Linear,
    label: 'Linear issues',
    icon: <LinearIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.LINEAR).run(),
  },
  [ActionId.LinearProject]: {
    id: ActionId.LinearProject,
    label: 'Linear projects',
    icon: <LinearIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.LINEAR_PROJECT).run(),
  },
  [ActionId.Notion]: {
    id: ActionId.Notion,
    label: 'Notion',
    icon: <NotionIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.NOTION).run(),
  },
  [ActionId.Divider]: {
    id: ActionId.Divider,
    label: 'Divider',
    icon: <MinusIcon />,
    toggle: (editor) => editor.chain().focus().setHorizontalRule().run(),
  },
  [ActionId.Details]: {
    id: ActionId.Details,
    label: 'Toggle list',
    icon: <DetailsIcon size={10} />,
    toggle: (editor) => editor.chain().focus().setDetails().run(),
  },
  [ActionId.Table]: {
    id: ActionId.Table,
    label: 'Table',
    icon: <TableIcon />,
    toggle: (editor) => editor.chain().focus().insertTable().run(),
  },
  [ActionId.TableOfContent]: {
    id: ActionId.TableOfContent,
    label: 'Table of content',
    icon: <TableOfContentIcon />,
    toggle: (editor) => editor.chain().focus().addTableOfContentNode().run(),
  },
  [ActionId.Ai]: {
    id: ActionId.Ai,
    label: 'Cycle AI',
    icon: <AiIcon hasGradient />,
    toggle: (editor, from, editorId) => {
      setEditorAi({
        isPromptCommand: ['slash-menu', 'empty-menu'].includes(from),
        snapshotSelection: editor.state.selection,
        suggestion: '',
        visible: editorId || '',
      });
      if (from === 'bubble-menu') {
        editor.chain().focus().run();
      } else if (from === 'slash-menu') {
        editor.chain().blur().run();
      }
    },
    isNew: true,
  },
  [ActionId.Video]: {
    id: ActionId.Video,
    label: 'Video',
    icon: <VideoIcon />,
    toggle: (editor) => {
      const dataId = crypto.randomUUID();
      const { selection } = editor.state;
      setEditorNodeOpen('videoNodeOpen', dataId);
      return editor.chain().focus().setVideo({
        src: '',
        dataId,
      }).setNodeSelection(selection.from - 1)
        .run();
    },
    isNew: true,
  },
  [ActionId.Audio]: {
    id: ActionId.Audio,
    label: 'Audio',
    icon: <VolumeFullOutlineIcon />,
    toggle: (editor) => {
      const dataId = crypto.randomUUID();
      const { selection } = editor.state;
      setEditorNodeOpen('audioNodeOpen', dataId);
      return editor.chain().focus().setAudio({
        src: '',
        dataId,
      }).setNodeSelection(selection.from - 1)
        .run();
    },
    isNew: true,
  },
  [ActionId.Transcript]: {
    id: ActionId.Transcript,
    label: 'Transcript',
    icon: <ReleaseNoteIcon />,
    toggle: (editor) => {
      if (!getPermission().canTranscribe) {
        setLimitationsModal({ action: 'TRANSCRIPT' });
        return null;
      }
      const dataId = crypto.randomUUID();
      const { selection } = editor.state;
      setEditorNodeOpen('fileNodeOpen', dataId, 'transcript');
      return editor.chain().focus().setFile({
        file: undefined,
        dataId,
      }).setNodeSelection(selection.from - 1)
        .run();
    },
    isNew: true,
  },
  [ActionId.Comments]: {
    closeBubbleOnClick: true,
    id: ActionId.Comments,
    label: 'Add comment',
    icon: <CommentAltIcon />,
    toggle: (editor) => {
      editor.chain().focus().setHighlightMark({
        id: crypto.randomUUID(),
        type: 'comment',
        isResolved: false,
      }).run();
    },
  },
  [ActionId.Colors]: {
    id: ActionId.Colors,
    label: 'Text color',
    icon: 'A',
    text: <CaretIcon size={10} />,
  },
  [ActionId.Jira]: {
    id: ActionId.Jira,
    label: 'Jira',
    icon: <JiraIcon />,
  },
  [ActionId.Confluence]: {
    id: ActionId.Confluence,
    label: 'Confluence',
    icon: <ConfluenceIcon />,
  },
};

export type ActionCategory = 'ai' | 'insight' | 'comment' | 'title' | 'text' | 'elements' | 'lists' | 'mentions' | 'integrations';

export interface ActionCategoryData {
  category: ActionCategory;
  actions: Action[];
}

export const slashActions: Array<ActionCategoryData> = [
  {
    category: 'text',
    actions: [
      actions[ActionId.Heading1],
      actions[ActionId.Heading2],
      actions[ActionId.Heading3],
    ],
  },
  {
    category: 'elements',
    actions: [
      actions[ActionId.CheckList],
      actions[ActionId.BulletList],
      actions[ActionId.NumberedList],
      actions[ActionId.Embed],
      actions[ActionId.Image],
      actions[ActionId.Video],
      actions[ActionId.Audio],
      actions[ActionId.AddEmoji],
      actions[ActionId.Quote],
      actions[ActionId.Code],
      actions[ActionId.File],
      actions[ActionId.Divider],
      actions[ActionId.Details],
      actions[ActionId.TableOfContent],
      actions[ActionId.Table],
      actions[ActionId.Transcript],
    ],
  },
  {
    category: 'mentions',
    actions: [
      actions[ActionId.MentionUser],
      actions[ActionId.MentionDoc],
    ],
  },
  {
    category: 'integrations',
    actions: [
      actions[ActionId.GithubIssue],
      actions[ActionId.Linear],
      actions[ActionId.LinearProject],
      actions[ActionId.Notion],
      actions[ActionId.Jira],
      actions[ActionId.Confluence],
    ],
  },
];

export const toolbarActions: Array<ActionCategoryData> = [
  {
    category: 'insight',
    actions: [
      actions[ActionId.TurnTextIntoInsight],
    ],
  },
  {
    category: 'ai',
    actions: [
      actions[ActionId.Ai],
    ],
  },
  {
    category: 'comment',
    actions: [
      actions[ActionId.Comments],
    ],
  },
  {
    category: 'title',
    actions: [
      actions[ActionId.Heading1],
      actions[ActionId.Heading2],
      actions[ActionId.Heading3],
    ],
  },
  {
    category: 'text',
    actions: [
      actions[ActionId.Bold],
      actions[ActionId.Italic],
      actions[ActionId.Underline],
      actions[ActionId.Strike],
      actions[ActionId.Colors],
    ],
  },
  {
    category: 'elements',
    actions: [
      actions[ActionId.Link],
      actions[ActionId.Quote],
      actions[ActionId.Code],
    ],
  },
  {
    category: 'lists',
    actions: [
      actions[ActionId.BulletList],
      actions[ActionId.NumberedList],
      actions[ActionId.CheckList],
    ],
  },
  {
    category: 'integrations',
    actions: [
      actions[ActionId.TurnTextIntoGithubIssueMention],
      actions[ActionId.TurnTextIntoLinearMention],
      actions[ActionId.TurnTextIntoDocMention],
    ],
  },

];

export const presetComplete = Object.values(ActionId);

export const templateDisabledActions = [
  ActionId.MentionDoc,
  ActionId.MentionUser,
  ActionId.TurnTextIntoDocMention,
  ActionId.TurnTextIntoInsight,
  ActionId.TurnTextIntoGithubIssueMention,
  ActionId.TurnTextIntoLinearMention,
  ActionId.Notion,
  ActionId.Linear,
  ActionId.GithubIssue,
  ActionId.File,
  ActionId.Comments,
];
