import styled, { css } from 'styled-components';

import { boxShadowZ3 } from '../../../utils/styles.util';
import { Card } from '../Card/Card';

export interface ContainerProps {
  draft?: boolean;
  isCreating?: boolean;
  isDragging?: boolean;
  isHover?: boolean;
  isFocused?: boolean;
  asPlaceholder?: boolean;
  direction?: 'left' | 'right';
  isSelected?: boolean;
  selectedLength?: number;
}

function getRotate(direction = 'right'): number {
  return direction === 'right' ? 2 : -2;
}

export const Content = styled.div`
  z-index: 1;
  position: relative;
`;

const ContainerDraggingCss = css<ContainerProps>`
  transform: translate3d(10px, 0, 0) rotate(${p => getRotate(p.direction)}deg);
  border-color: hsl(var(--cycle));
  outline-width: 2px;
`;

const ContainerDraftCss = css`
  color: ${p => p.theme.colors.text.primary};
  background: ${p => p.theme.colors.card.bg};
  border-color: hsla(var(--cycle), 0.15);
`;

const ContainerPlaceholderCss = css`
  color: hsl(var(--cycle));
  background-color: ${p => p.theme.colors.placeholder.bg};

  ${Content} {
    opacity: 0;
  }
`;

const ContainerHoverCss = css`
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.1),
    0 0 0 2px hsla(var(--cycle), 0.35);
`;

const ContainerSelectedCss = css`
  border-color: hsl(var(--cycle));
  ${boxShadowZ3};
  &:hover {
    border-color: hsl(var(--cycle));
  }
`;

const ContainerCreatingCss = css`
  opacity: 0.5;
`;

export const Container = styled(Card)<ContainerProps>`
  border: 2px solid transparent;
  padding: 6px;

  ${p => p.draft && ContainerDraftCss};
  ${p => p.isDragging && ContainerDraggingCss};
  ${p => p.isSelected && ContainerSelectedCss};
  ${p => p.isHover && ContainerHoverCss};
  ${p => p.isCreating && ContainerCreatingCss};
  ${p => p.asPlaceholder && ContainerPlaceholderCss};

  ${p => (p.isDragging || p.asPlaceholder || p.isCreating) && css`
    pointer-events: none;
  `};
`;

export const Counter = styled.div<{ direction?: 'left' | 'right' }>`
  position: absolute;
  top: -8px;
  right: -8px;

  width: 16px;
  height: 16px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: hsl(var(--cycle));
  color: white;

  font-size: 10px;
`;
