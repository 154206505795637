import { DocParentFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { ComponentProps } from 'react';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { Layer } from 'src/types/layers.types';

type Props = Omit<ComponentProps<typeof DocPanelDocAttributes>, 'doc'> & {
  doc: DocParentFragment;
};

export const FeatureCardAttributes = ({
  doc, ...props
}: Props) => {
  const isOptimisticData = doc.id.includes('temp');

  if (!doc || isOptimisticData) {
    return (
      <div className="flex items-center gap-2">
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={80} />
      </div>
    );
  }

  return (
    <DocPanelDocAttributes
      doc={doc}
      showDocIdWithDocType
      layer={Layer.DropdownModal}
      hideStatusLabel
      {...props}
    />
  );
};