import { mergeAttributes, Node } from '@tiptap/core';

import { GITHUB_EXTENSION_NAME, GITHUB_ISSUE_EXTENSION_TAGNAME } from '../constants';

export const getGithubIssuesExtension = () => Node.create({
  name: GITHUB_EXTENSION_NAME,
  draggable: true,

  group: 'block',

  inline: false,

  selectable: true,

  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'mention-github-issue',
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
      },
      state: {
        default: null,
        rendered: false,
      },
      url: {
        default: null,
        rendered: false,
      },
      title: {
        default: null,
        rendered: false,
      },
      description: {
        default: null,
        rendered: false,
      },
      assignees: {
        default: null,
        rendered: [],
      },
      creator: {
        default: null,
        rendered: false,
      },
      cardStatus: {
        default: null,
        rendered: false,
      },
      milestone: {
        default: null,
        rendered: false,
      },
      labels: {
        default: [],
        rendered: false,
      },
      projectId: {
        default: null,
        rendered: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: GITHUB_ISSUE_EXTENSION_TAGNAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      GITHUB_ISSUE_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },
});
