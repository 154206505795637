import styled, { css } from 'styled-components';

export const Label = styled.label`
  color: var(--color);
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const CheckboxInputText = styled.span`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const SquareSize = 16;

export const CheckboxSquare = styled.div`
  background: var(--bg);
  border: 1px solid var(--border);
  border-radius: 4px;
  width: ${SquareSize}px;
  height: ${SquareSize}px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--innerColor);
  }
`;

interface StyledInputProps {
  $useUserColor?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  /* native input should be hidden, but still rendered to be focusable */
  opacity: 0;
  width: 0;
  height: 0;
  /* out of the flow to prevent issues on flex/grid layouts */
  position: absolute;

  &:disabled + ${Label} {
    --bg: ${p => p.theme.colors.background.disabled};
    --color: ${p => p.theme.colors.text.disabled};
    cursor: not-allowed;
  }

  &:focus + ${Label} {
    --border: ${p => (p.$useUserColor ? 'hsl(var(--cycle))' : p.theme.colors.background.blue)};
  }

  &:checked + ${Label} {
    --bg: ${p => (p.$useUserColor ? 'hsl(var(--cycle))' : p.theme.colors.background.blue)};
    --border: ${p => (p.$useUserColor ? 'hsl(var(--cycle))' : p.theme.colors.background.blue)};
    --innerColor: ${p => p.theme.colors.text.white};
  }
`;

interface ContainerProps {
  $isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  --bg: ${p => p.theme.colors.inputRadio.bg};
  --border: ${p => p.theme.colors.border.input};
  --color: ${p => p.theme.colors.text.primary};
  --innerColor: transparent;

  ${p => p.$isDisabled && css`
    pointer-events: none;
    opacity: 0.75;
    cursor: not-allowed;
  `};

  ${p => !p.$isDisabled && css`
    &:hover {
      --border: hsl(var(--cycle));

      ${StyledInput}:checked + ${Label} {
        --bg: hsl(var(--cycle700));
      }
    }
  `}
`;
