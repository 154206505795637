import { QueryHookOptions, useQuery } from '@apollo/client';
import { SearchSuggestionDocsDocument, SearchSuggestionDocsQuery, SearchSuggestionDocsQueryVariables } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSuggestionStatusIds } from 'src/reactives/productStatus.reactive';

export const useSearchSuggestionDocs = (
  variables: Omit<SearchSuggestionDocsQueryVariables, 'productId'>,
  options: QueryHookOptions = {},
) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const statusIds = useSuggestionStatusIds();

  const {
    data, loading,
  } = useQuery<SearchSuggestionDocsQuery>(SearchSuggestionDocsDocument, {
    fetchPolicy: 'cache-and-network',
    ...options,
    variables: {
      productId,
      statusIds,
      ...variables,
    },
  });

  return {
    docs: nodeToArray(data?.suggestDoc),
    isLoading: loading,
  };
};
