import { typo, truncate } from '@cycle-app/ui';
import { ContextBlock, ShowMore } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';
import styled from 'styled-components';

import { Trigger } from 'src/components/DocCompanyCustomer/DocCompanyCustomer.styles';
import { StyledInsightCard } from 'src/components/InsightsList/InsightsList.styles';

export const ContextFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  ${Trigger} {
    padding: 0;
  }
`;

export const StyledInsightCardReadOnly = styled(StyledInsightCard)`
  :hover {
    background: ${p => p.theme.colors.selectItem.bgHover};
  }


  ${ContextBlock} {
    background: transparent;
    padding: 0 0 0 14px;
    .ProseMirror {
      padding: 0 !important;
    }
    p {
      white-space: wrap;
    }
  }

  ${ShowMore} {
    bottom: -5px;
    background-color: ${p => p.theme.colors.components.InsightCard.quote.bg};
    :before {
      box-shadow: -20px 0px 15px 0px ${p => p.theme.colors.components.InsightCard.quote.bg};
    }
  }

  * { cursor: pointer !important; }
`;

export const Parent = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  svg {
    flex: none;
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const ParentTitle = styled.div`
  ${truncate}
`;
