import { make } from './reactive.util';
import { LocalKey } from '../types/localStorage.types';

interface AuthResult {
  userId: string | null;
  token: string | null;
  onboarded: boolean | null;
}

export const {
  hookState: useAuth,
  hookValue: useGetAuth,
  getValue: getAuth,
  setValue: setAuth,
  resetValue: resetAuth,
} = make<AuthResult>({
  defaultState: {
    token: null,
    userId: null,
    onboarded: null,
  },
  localKey: LocalKey.Auth,
});
