import { useSubscription } from '@apollo/client';
import { AskDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { updateAskAnswer, getAskAnswers } from 'src/reactives/ask.reactive';

export const useAskSubscription = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  return useSubscription(AskDocument, {
    variables: {
      productId,
    },
    onSubscriptionData: (result) => {
      const data = result.subscriptionData.data?.ask;
      if (!data) return;
      const answers = Object.values(getAskAnswers().answers);
      const anwserId = answers.find(answer => answer.uuid === data.id)?.id;
      if (!anwserId) return;
      updateAskAnswer(anwserId, {
        id: anwserId,
        status: 'answered',
        uuid: data.id,
        content: {
          answer: data.answer,
          parameters: data.parameters ? {
            feature: data.parameters.feature,
            fromDate: data.parameters.fromDate,
            toDate: data.parameters.toDate,
            companies: data.parameters.companies?.map(companyAnswer => ({
              name: companyAnswer.name,
              company: companyAnswer.company?.__typename === 'Company' ? {
                id: companyAnswer.company.id,
                logo: companyAnswer.company.logo,
              } : null,
            })) || [],
          } : null,
          docs: data.docs?.map(doc => ({ id: doc.id })),
        },
      });
    },
  });
};
