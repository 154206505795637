import { MutationProgressSubscription } from '@cycle-app/graphql-codegen';

import { LINEAR_IMPORT_TOASTER_ID } from 'src/constants/linearImport.constants';
import { closeToaster } from 'src/utils/toasters.utils';

import { addLinearImportDoneToaster } from './LinearImportToasters';

/**
 * Map of project names and issue titles that will be used to create the docs
 * key: project id or issue id
 * value: project name or issue title
*/
export const docsData: Map<string, {
  title: string;
  automationLink: string;
}> = new Map();

/**
 * Set of bulkCreateDoc uuids that are currently in progress
*/
export const importQueue = new Set<string>();

/**
 * Callback for `bulkCreateDoc` mutation progress subscription
 * It will close the `in progress` toaster and open a 'done' toaster when all imports are complete
 */
export const onLinearImportProgress = (result: NonNullable<MutationProgressSubscription['mutationProgress']>) => {
  if (!importQueue.has(result.mutationId)) return;
  if (result.progress < 100) return;
  importQueue.delete(result.mutationId);
  // If an import has been started before the previous ones has been completed, the `in progress` toaster is kept open
  // The `done` toaster is opened when all imports have been completed
  if (importQueue.size > 0) return;
  closeToaster(LINEAR_IMPORT_TOASTER_ID);
  addLinearImportDoneToaster();
};
