import { CycleLogo, typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(NextButton)`
  margin-top: 48px;
`;

interface StyledLogoLoaderProps {
  $isLoading?: boolean;
}

export const StyledLogoLoader = styled(CycleLogo)<StyledLogoLoaderProps>`
  ${p => p.$isLoading && css`--color1: ${p.theme.colors.lightOnboarding.doneLoader.color};`}
  margin-bottom: 36px;

  path {
    transition: color .6s;
  }
`;

export const Body = styled.div`
  position: relative;
`;

export const BodyDefault = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const BodyLoading = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 20px;
`;

export const SubTitle = styled.h2`
  ${typo.headerSmall}
  font-weight: normal;
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 12px;
  max-width: 354px;
`;
