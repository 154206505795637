import { DocsCountByStatusFragment } from '@cycle-app/graphql-codegen';

import { CountDonutChart } from './CountDonutChart';
import { statusColors } from './Profile.constants';
import { CountItem, CountValue, CountLabel, CountItems } from './Profile.styles';

type Props = {
  count?: DocsCountByStatusFragment;
};

export const FeaturesCounters = ({ count }: Props) => {
  return (
    <>
      <CountDonutChart count={count} size={40} />
      <CountItems>
        <CountItem $color={statusColors.notStarted}>
          <CountValue>{count?.notStarted ?? 0}</CountValue>
          <CountLabel>Not started</CountLabel>
        </CountItem>
        <CountItem $color={statusColors.started}>
          <CountValue>{count?.started ?? 0}</CountValue>
          <CountLabel>Started</CountLabel>
        </CountItem>
        <CountItem $color={statusColors.completed}>
          <CountValue>{count?.completed ?? 0}</CountValue>
          <CountLabel>Completed</CountLabel>
        </CountItem>
        <CountItem $color={statusColors.canceled}>
          <CountValue>{count?.canceled ?? 0}</CountValue>
          <CountLabel>Canceled</CountLabel>
        </CountItem>
      </CountItems>
    </>
  );
};
