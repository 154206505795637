import styled from 'styled-components';

import { CellContent } from '../../Table/Table.styles';

export const Avatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
`;

export const StyledCellContent = styled(CellContent)`
  cursor: pointer;
`;
