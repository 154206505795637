import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { ShipPill, Subtitle } from './ChangelogBuilderContent.styles';

export const ChangelogBuilderContentSubtitle = () => {
  const { watch } = useChangelogBuilderFormContext();
  const subtitle = watch('subtitle').trim() || 'What did you ship this week?';
  const shipBadgeColor = watch('shipBadgeColor');
  const heroSection = watch('heroSection');
  const shipIndex = subtitle.indexOf('ship');
  if (shipIndex >= 0) {
    // Replaces only the first "ship"
    return (
      <Subtitle $heroSection={heroSection}>
        {subtitle.substring(0, shipIndex)}
        <ShipPill src={`/images/ship-${shipBadgeColor}.png`} />
        {subtitle.substring(shipIndex).replace('ship', '')}
      </Subtitle>
    );
  }
  return <Subtitle $heroSection={heroSection}>{subtitle}</Subtitle>;
};
