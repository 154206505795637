import { ThemeType } from '@cycle-app/graphql-codegen';
import { Button, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

type ContainerProps = {
  $fromSettings: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: 100%;

  ${p => (p.$fromSettings
    ? css`
      :after {
        content: '';
        position: absolute;
        inset: 0;
        background-position: 50% 0;
        background-repeat: no-repeat;
        pointer-events: none;
      }
    `
    : css`
      :before, :after {
        content: '';
        position: absolute;
        inset: 0;
        background-position: 50% calc(55% - 215px);
        background-repeat: no-repeat;
        pointer-events: none;
      }
      :before {
        background-image: url(/images/releases-bg.png);
        ${p.theme.themeType === ThemeType.Nightfall && 'filter: invert(100%)'};
      }
    `)
}

  :after {
    background-image: url(/images/releases-add-notes.svg);
  }
`;

export const Content = styled.div<ContainerProps>`
  position: absolute;
  top: ${p => (p.$fromSettings ? '260px' : '55%')};
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    ${typo.headerMedium}
    margin-bottom: 12px;
  }

  > p {
    ${typo.body400}
    color: ${p => p.theme.colors.text.secondary};
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const LearnMoreButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'S',
})`
  margin-top: 58px;
  height: 28px;
  padding: 0 12px;
  ${typo.caption400}
  border-radius: 50px;

  ${p => css`
    border: 1px solid ${p.theme.colors.releases.learnMore.border};
    --color: ${p.theme.colors.releases.learnMore.color};
    --bg: ${p.theme.colors.releases.learnMore.bg};
    :hover, :focus {
      --bg: ${p.theme.colors.releases.learnMore.bgHover};
    }
    :active {
      --bg: ${p.theme.colors.releases.learnMore.bgActive};
    }
  `}
`;
