import { UpdateInsightQuoteDocument } from '@cycle-app/graphql-codegen';
import { useRef, useMemo, MouseEvent } from 'react';

import { CommentEditor } from 'src/components/Editor/Editors/CommentEditor';
import { InsightCardContextEdit } from 'src/components/InsightCardContextEdit';
import { useOptimizedBooleanState } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { isElementOutside } from 'src/utils/elements.util';

type useInsightCardEditQuotePropsProps = {
  docTarget?: {
    blockId?: string | null;
    content?: string | null;
    doc?: {
      id: string;
      title: string;
      docSource?: {
        id?: string | null;
        blockId?: string | null;
        content?: string | null;
        doc?: {
          id: string;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const useInsightCardEditQuoteProps = ({ docTarget }: useInsightCardEditQuotePropsProps) => {
  const contextUpdateRef = useRef<HTMLDivElement>(null);
  const [isUpdatingQuote, {
    setTrueCallback,
    setFalseCallback,
  }] = useOptimizedBooleanState(false);
  const [updateQuote] = useSafeMutation(UpdateInsightQuoteDocument);

  const quoteContent = docTarget?.doc?.docSource?.content || docTarget?.content || docTarget?.doc?.title || '';
  const contextEl = <CommentEditor isReadOnly onUpdate={() => { }} content={quoteContent} />;
  const contextUpdateEl = useMemo(() => {
    return (
      <InsightCardContextEdit
        ref={contextUpdateRef}
        defaultValue={quoteContent}
        onCancel={() => {
          setFalseCallback();
        }}
        onUpdate={(newQuote) => {
          /** Check the insight id and if the feedback id are there. */
          if (!docTarget?.doc?.id || !docTarget.doc?.docSource?.doc?.id) {
            console.warn('Missing feedback id');
            return;
          }

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          updateQuote({
            variables: {
              targetId: docTarget.doc.id,
              content: newQuote,
              sourceId: docTarget.doc.docSource.doc.id,
            },
            optimisticResponse: {
              changeDocLink: {
                __typename: 'Doc',
                id: docTarget?.doc?.id ?? '',
                docSource: {
                  __typename: 'DocSource',
                  id: docTarget?.doc?.docSource?.id ?? '',
                  content: newQuote,
                },
              },
            },
            update: (cache, { data }) => {
              if (!data?.changeDocLink?.docSource || !docTarget) return;
              cache.modify({
                id: cache.identify(docTarget),
                fields: {
                  content: (content) => data.changeDocLink?.docSource?.content ?? content,
                },
              });
            },
          });
          setFalseCallback();
        }}
      />
    );
  }, [
    docTarget,
    quoteContent,
    setFalseCallback,
    updateQuote,
  ]);

  const isClickOnEditForm = (e: MouseEvent<HTMLElement>) => contextUpdateRef.current && !isElementOutside(contextUpdateRef.current, e.target);

  return {
    contextUpdateEl: isUpdatingQuote ? contextUpdateEl : undefined,
    startEdit: setTrueCallback,
    isClickOnEditForm,
    contextEl,
  };
};
