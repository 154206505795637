import { CreateGithubIssueDocument, CreateGithubIssueMutationVariables } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCreateGithubIssue = () => {
  const [createMutation, { loading }] = useSafeMutation(CreateGithubIssueDocument);

  const createIssue = ({
    title, repoName, docId, description, assignees, statusId, projectId,
  }: CreateGithubIssueMutationVariables) => {
    if (!title || !repoName || !docId) throw Error('Data are missing');

    return createMutation({
      variables: {
        docId,
        repoName,
        title,
        assignees,
        description,
        statusId,
        projectId,
      },
    });
  };

  return {
    createIssue,
    isLoading: loading,
  };
};
