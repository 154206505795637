import styled from 'styled-components';

import { PortalModalStyled } from 'src/components/DialogModal/DialogModal.styles';

export const Modal = styled(PortalModalStyled)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ConfirmOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: ${p => p.theme.colors.background.secondaryElevated};
  padding: 6px 12px;
  border-radius: 8px;
`;
