import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Info = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  white-space: nowrap;

  &:empty {
    display: none;
  }
`;
