import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CloseIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.7225 2.27748C14.0925 2.64745 14.0925 3.24729 13.7225 3.61726L3.61726 13.7225C3.24729 14.0925 2.64745 14.0925 2.27748 13.7225C1.90751 13.3526 1.90751 12.7527 2.27748 12.3827L12.3827 2.27748C12.7527 1.90751 13.3526 1.90751 13.7225 2.27748Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.27748 2.27748C2.64745 1.90751 3.24729 1.90751 3.61726 2.27748L13.7225 12.3827C14.0925 12.7527 14.0925 13.3526 13.7225 13.7225C13.3526 14.0925 12.7527 14.0925 12.3827 13.7225L2.27748 3.61726C1.90751 3.24729 1.90751 2.64745 2.27748 2.27748Z" />
  </Svg>
);
