import { Tooltip } from '@cycle-app/ui';
import { FC } from 'react';

import { features } from './features';
import { featuresYC } from './features-yc';
import { Container, Item, StyledCheckIcon } from './FeaturesList.styles';

export type FeaturesListProps = {
  tooltip?: boolean;
  split?: boolean;
  className?: string;
  isYC?: boolean;
};

export const FeaturesList: FC<React.PropsWithChildren<FeaturesListProps>> = ({
  className, split, tooltip, isYC,
}) => {
  const f = isYC ? featuresYC : features;
  const list = split ? [f.slice(0, 6), f.slice(6, f.length)] : [f];
  return (
    <Container className={className} $split={split} {...isYC && { style: { marginTop: '34px' } }}>
      {(list).map((l, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ul key={i}>
          {l.map((feature, j) => (
            <Item $tooltip={tooltip && !!feature.tooltip} key={feature.id}>
              <StyledCheckIcon width={12} data-i={(6 * i) + j} />
              {tooltip && feature.tooltip ? (
                <Tooltip placement="top" content={feature.tooltip}>
                  {feature.name}
                </Tooltip>
              ) : (
                <div>
                  {feature.name}
                </div>
              )}
            </Item>
          ))}
        </ul>
      ))}
    </Container>
  );
};
