import fireflies from './fireflies.png';

type Props = {
  className?: string;
  size?: number;
};

export const FirefliesIcon = ({
  className, size,
}: Props) => (
  <img className={className} width={size || 16} src={fireflies} alt="Fireflies icon" />
);
