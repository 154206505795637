import { TrashIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { DeleteBoardModal } from 'src/app/Main/Board/DeleteBoardModal/DeleteBoardModal';
import { useInsightSection } from 'src/hooks/insight/useInsightSection';

import { DeleteButton } from './InsightViewSettings.styles';

export const InsightViewDelete = ({ boardId }: { boardId: string }) => {
  const { boards } = useInsightSection();
  const [visible, setVisible] = useState(false);
  return (
    <>
      <DeleteButton
        size="M"
        variant="warning-secondary"
        iconStart={<TrashIcon />}
        onClick={() => setVisible(true)}
        {...boards.length === 1 && {
          tooltip: 'Last view cannot be deleted',
          tooltipPlacement: 'top',
          disabled: true,
        }}
      >
        Delete view
      </DeleteButton>

      {visible && (
        <DeleteBoardModal
          boardId={boardId}
          onHide={() => setVisible(false)}
        />
      )}
    </>
  );
};
