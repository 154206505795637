import { StatusFragment, UpdateStatusDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateStatus = () => {
  const [mutate, ...result] = useSafeMutation(UpdateStatusDocument);

  const updateStatus = useCallback((status: StatusFragment) => {
    return mutate({
      variables: {
        id: status.id,
        value: status.value,
      },
      optimisticResponse: {
        updateStatus: status,
      },
    });
  }, [mutate]);

  return {
    ...result,
    updateStatus,
  };
};
