import { NotificationChannel, NotificationTrigger, NotificationType, IntegrationType, Feature } from '@cycle-app/graphql-codegen/generated';
import { Button, ActionButton, Skeleton } from '@cycle-app/ui';
import { SlackIcon, CloseIcon } from '@cycle-app/ui/icons';
import memoize from 'fast-memoize';
import { groupBy } from 'ramda';
import { useMemo, Fragment } from 'react';
import { isPresent } from 'ts-is-present';

import { useIsRoadmapsEnabled } from 'src/hooks';
import { useToggleNotificationTrigger } from 'src/hooks/api/mutations/userSettings/useToggleNotificationTrigger';
import { useNotificationTriggers } from 'src/hooks/api/queries/useNotificationTriggers';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { useToggleWithStorage } from 'src/hooks/useStateWithStorage';
import { setLimitationsModal } from 'src/reactives';
import { setFeatureToEnable } from 'src/reactives/features.reactive';
import { getPermission, useGetAllPermissions } from 'src/reactives/permission.reactive';
import { closeSettingsModal } from 'src/reactives/settingsModal.reactive';
import { LocalKey } from 'src/types/localStorage.types';

import {
  SetupSlackInte,
  SetupSlackInteContent,
  Content,
  Section,
  SectionTitle,
  ChannelTitle,
  ChannelValue,
  TriggerDescription,
  StyledToggleInput,
  RoadmapSentence,
  EnableButton,
} from './NotificationsTab.styles';

const ORDERED_CHANNELS: NotificationChannel[] = [NotificationChannel.Email, NotificationChannel.Slack];
const NOTIFICATION_TYPES = Object.values(NotificationType);

// TODO: remove reference to claps once backend is ready
const DESCRIPTION_BY_NOTIFICATION_TYPE: Record<NotificationType, string | null> = {
  WELCOME_NOTIFICATION: null,
  THREAD_CREATED: null,
  THREAD_UPDATED: null,
  USER_MENTIONED_IN_DOC: 'Someone mentioned you in a doc',
  USER_MENTIONED_IN_COMMENT: 'Someone mentioned you in a comment',
  THREAD_REPLIED: 'Someone commented on a doc you follow',
  DOC_CLAPPED: null,
  ASSIGNED_TO_DOC: 'Someone assigned you to a doc',
  DOC_STATUS_CHANGED: 'Status changes for insights you are assignee or customer of',
  LOOP_CLOSED: 'Loop closed for insights you are assignee or customer of',
  MONTHLY_DIGEST: 'Monthly digest',
  WEEKLY_DIGEST: null,
};

const READONLY_TRIGGERS_DESCRIPTION: string[] = [
  'You\'re assignee',
  'You\'re creator',
  'You added a comment',
  'You were mentioned in content',
  'You were mentioned in a comment',
];

export const NotificationsTab = () => {
  const {
    notificationTriggers, loading,
  } = useNotificationTriggers();
  const toggleNotificationTrigger = useToggleNotificationTrigger();
  const { canReadSettings } = useGetAllPermissions();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const triggersByType = useMemo(
    () => groupBy<NotificationTrigger | null | undefined>((trigger) => trigger?.type ?? '')(notificationTriggers ?? []),
    [notificationTriggers],
  );

  const onTriggerClicked = useMemo(
    () => memoize((trigger: NotificationTrigger) => () => toggleNotificationTrigger(trigger)),
    [toggleNotificationTrigger],
  );
  const docStatusTrigger = triggersByType[NotificationType.DocStatusChanged]?.filter(isPresent) || [];
  const docStatusTriggerOrdered = ORDERED_CHANNELS
    .map(channel => docStatusTrigger.find(trigger => trigger.channel === channel))
    .filter(isPresent);

  const loopClosedTrigger = triggersByType[NotificationType.LoopClosed]?.filter(isPresent) || [];
  const loopClosedTriggerOrdered = ORDERED_CHANNELS
    .map(channel => loopClosedTrigger.find(trigger => trigger.channel === channel))
    .filter(isPresent);

  if (loading) {
    return (
      <div className="flex flex-col items-center gap-4">
        {[...Array(10).keys()].map(i => <Skeleton key={i} height={24} />)}
      </div>
    );
  }

  return (
    <>
      {canReadSettings && <SlackDiscoverBanner />}
      <Content>
        {!!docStatusTriggerOrdered?.length && (
          <>
            <Section $hasBorderBottom>
              <SectionTitle>Status notifications you receive</SectionTitle>
              {ORDERED_CHANNELS.map(channel => (
                <ChannelTitle key={channel}>{channel}</ChannelTitle>
              ))}
            </Section>
            <Section>
              <TriggerDescription $disabled={!isRoadmapsEnabled}>
                {DESCRIPTION_BY_NOTIFICATION_TYPE[NotificationType.DocStatusChanged]}
              </TriggerDescription>
              {docStatusTriggerOrdered.map(trigger => trigger && (
                <ChannelValue
                  key={trigger.id}
                  id={trigger.id}
                  checked={isRoadmapsEnabled ? trigger.isActive : false}
                  onChange={isRoadmapsEnabled ? onTriggerClicked(trigger) : () => {}}
                  disabled={!isRoadmapsEnabled}
                />
              ))}
              <TriggerDescription $disabled={!isRoadmapsEnabled}>
                {DESCRIPTION_BY_NOTIFICATION_TYPE[NotificationType.LoopClosed]}
              </TriggerDescription>
              {loopClosedTriggerOrdered.map(trigger => trigger && (
                <ChannelValue
                  key={trigger.id}
                  id={trigger.id}
                  checked={isRoadmapsEnabled ? trigger.isActive : false}
                  onChange={isRoadmapsEnabled ? onTriggerClicked(trigger) : () => {}}
                  disabled={!isRoadmapsEnabled}
                />
              ))}
            </Section>
            {!isRoadmapsEnabled && (
              <div className="flex flex-col items-start">
                <RoadmapSentence>
                  To get updates about insight status changes, you have to enable Roadmaps.
                </RoadmapSentence>
                <EnableButton
                  onClick={() => {
                    if (!getPermission().canReadSettings) {
                      setLimitationsModal({ action: 'SETTINGS_READ' });
                      return;
                    }
                    setFeatureToEnable(Feature.Roadmap);
                    closeSettingsModal();
                  }}
                >
                  Enable Roadmaps
                </EnableButton>
              </div>
            )}
          </>
        )}

        {Object.keys(triggersByType).length > 0 && (
          <>
            <Section $hasBorderBottom>
              <SectionTitle>Other notifications you receive</SectionTitle>
              {ORDERED_CHANNELS.map((i) => (<span key={i} />))}
            </Section>

            <Section>
              {Object
                .keys(triggersByType)
                .map(type => {
                  if (!isNotificationType(type)) return null;
                  const description = DESCRIPTION_BY_NOTIFICATION_TYPE[type];
                  if (!description) return null;
                  return (
                    <Fragment key={type}>
                      <TriggerDescription>{description}</TriggerDescription>
                      {ORDERED_CHANNELS.map(channel => {
                        const trigger = triggersByType[type]?.find(t => t?.channel === channel);
                        if (!trigger) return null;

                        return (
                          <ChannelValue
                            id={trigger.id}
                            key={channel}
                            checked={trigger.isActive}
                            onChange={onTriggerClicked(trigger)}
                          />
                        );
                      })}
                    </Fragment>
                  );
                })}
            </Section>
          </>
        )}

        <Section $hasBorderBottom>
          <SectionTitle>Docs you&apos;re subscribed to</SectionTitle>
          {ORDERED_CHANNELS.map((i) => (<span key={i} />))}
        </Section>

        <Section>
          {READONLY_TRIGGERS_DESCRIPTION.map(description => (
            <Fragment key={description}>
              <TriggerDescription>{description}</TriggerDescription>
              <StyledToggleInput id={description} defaultChecked disabled />
              {ORDERED_CHANNELS.slice(1).map((i) => (<span key={i} />))}
            </Fragment>
          ))}
        </Section>
      </Content>
    </>
  );
};

function isNotificationType(type: string): type is NotificationType {
  return (NOTIFICATION_TYPES as string[]).includes(type) && type !== NotificationType.DocStatusChanged && type !== NotificationType.LoopClosed;
}

const SlackDiscoverBanner = () => {
  const {
    isLoading: isLoadingIntegrations,
    getIntegration,
  } = useProductIntegrations();
  const install = useInstallIntegration();
  const slackIntegration = getIntegration(IntegrationType.Slack);

  const isSlackInstalled = !!slackIntegration?.provider;

  const [isHiddenByUser, toggleIsHiddenByUser] = useToggleWithStorage(false, { key: LocalKey.SlackNotifBannerHidden });

  if (isHiddenByUser || isLoadingIntegrations || isSlackInstalled) return null;

  return (
    <SetupSlackInte>
      <SlackIcon size={32} />

      <SetupSlackInteContent>
        <div>Set up your Slack notifications</div>
        <small>Get your Cycle notifications right from Slack with this integration</small>
      </SetupSlackInteContent>

      <Button
        size="M"
        onClick={() => slackIntegration?.type && install(slackIntegration.type, slackIntegration)}
      >
        Set up
      </Button>

      <ActionButton
        onClick={toggleIsHiddenByUser}
        style={{ marginLeft: 0 }}
      >
        <CloseIcon />
      </ActionButton>
    </SetupSlackInte>
  );
};
