import { Title } from './QuoteCards.styles';

type Props = {
  title: string;
};

export const FeatureTitle = ({ title }: Props) => {
  const trimTitle = title.trim();
  return (
    <Title $empty={!trimTitle}>
      {trimTitle || 'Untitled'}
    </Title>
  );
};
