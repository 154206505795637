import { useWorkspaceContext } from 'src/contexts/workspaceContext';

import { useCompanies } from './useCompanies';
import { useCompaniesCount } from './useCompaniesCount';
import { useCustomers } from './useCustomers';
import { useCustomersCount } from './useCustomersCount';

export const usePeopleRefetch = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const customers = useCustomers({ skip: true });
  const customersCount = useCustomersCount({ skip: true });
  const companies = useCompanies({
    skip: true,
    productId,
  });
  const companiesCount = useCompaniesCount({ skip: true });
  return {
    refetch: () => (
      Promise.all([
        customers.refetch(),
        customersCount.refetch(),
        companies.refetch(),
        companiesCount.refetch(),
      ])
    ),
  };
};
