import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 0 6px;

  width: 100%;
  height: 32px;

  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  
  :hover {
    background: ${p => p.theme.colors.docPanel.hierarchy.bgHover};
  }
`;

export const DocTitle = styled.div`
  ${typo.body400};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

export const Side = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;
