import { TriangleIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';

import { useIsOnboarding, useSidebarCollapsed, useBoardsStarred, useProduct } from 'src/hooks';
import { toggleStarredSection, useGetStarredSection } from 'src/reactives/starredSection.reactive';

import { CollapseButton, BoardSectionNavigationItem } from './SidebarBoardSection.styles';
import { SidebarItemStarredBoard } from '../../SidebarBoard.styles';

export const SidebarBoardStarredSections = () => {
  const isCollapsed = useSidebarCollapsed();
  const isOnboarding = useIsOnboarding();
  const { boards } = useBoardsStarred();
  const { product } = useProduct();
  const { isOpen: isStarredSectionOpen } = useGetStarredSection();

  const boardsById = useMemo(() => {
    return keyBy(
      product?.boardSections.edges.flatMap(
        section => nodeToArray(section.node.boards).map(
          board => ({
            id: board.id,
            board,
            sectionType: section.node.type,
          }),
        ),
      ),
      'id',
    );
  }, [product?.boardSections.edges]);

  if (isOnboarding || !boards.length) return null;

  return (
    <>
      {!isCollapsed && (
        <BoardSectionNavigationItem
          onClick={toggleStarredSection}
          $isOpen={isStarredSectionOpen}
          label="Starred"
          className="item--group"
          postName={(
            <CollapseButton>
              <TriangleIcon size={17} />
            </CollapseButton>
          )}
          isSidebarColor
        />
      )}
      {isStarredSectionOpen && boards
        .map(board => {
          const item = boardsById[board.id];
          if (!item) return null;
          return (
            <SidebarItemStarredBoard
              key={board.id}
              board={item.board}
              linkState={{ fromStarredBoard: true }}
              $isCollapsed={isCollapsed}
            />
          );
        })}
    </>
  );
};
