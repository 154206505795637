import { FC } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useBoardSlug } from 'src/hooks/usePathParams';

/**
 * HOC that renders a view only if its config context matches
 */
export const matchBoardConfig = (Component: FC<React.PropsWithChildren<unknown>>): FC<React.PropsWithChildren<unknown>> => (props) => {
  const boardSlug = useBoardSlug();
  const boardConfigBoardSlug = useBoardConfig(ctx => ctx.boardSlug);
  if (boardSlug !== boardConfigBoardSlug) return null;
  return <Component {...props} />;
};
