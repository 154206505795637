import { ApolloError, QueryHookOptions, useQuery } from '@apollo/client';
import { NotionAttributes } from '@cycle-app/editor-extensions/src';
import {
  IntegrationType,
  FetchNotionPageByIdDocument,
  NotionElementType,
} from '@cycle-app/graphql-codegen/generated';
import { ERROR_CODE } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

export const useNotionPage = (
  page: NotionAttributes | undefined,
  options: Pick<QueryHookOptions, 'errorPolicy'> = {},
) => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Notion);
  const integrationId = integration?.id;
  const isActive = !!integration?.provider;

  const {
    data, loading: isLoading, error,
  } = useQuery(FetchNotionPageByIdDocument, {
    variables: {
      integrationId: integrationId ?? '',
      input: {
        id: page?.id as string,
        type: page?.typename === 'NotionPage' ? NotionElementType.Page : NotionElementType.Database,
      },
    },
    skip: !integrationId || !isActive || !page?.id,
    onError: handleNotionError,
    ...options,
  });

  const result = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Notion'
      ) return null;
      return data.node.provider.search?.[0];
    },
    [data],
  );

  return {
    page: result,
    isLoading,
    error,
  };
};

export const handleNotionError = (error: ApolloError) => {
  if (error.message === ERROR_CODE.NOTION_PAGE_OR_DATABASE_NOT_FOUND) {
    addErrorToaster({
      title: 'This notion page or database is not shared with cycle connection.',
      message: 'Please go to your Notion settings to update access',
      duration: 8000,
    });
    return;
  }
  addErrorToaster({ message: ErrorMessage._GENERIC });
};
