import { NOTION_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { IntegrationType } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { Events, Methods } from 'src/constants/analytics.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useLinkNotionPage } from 'src/hooks/api/mutations/integrations/useLinkNotionPage';
import { useNotionPage } from 'src/hooks/api/queries/integrations/useNotionPage';
import { useDocId } from 'src/hooks/usePathParams';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getNotionPageId } from 'src/utils/embeddable.util';

/**
 * Hook to embed a notion page in a tiptap editor
 * Side effects: undo the last action, insert the page, and link the page to the doc
 * If the page is a notion database, it will not be linked
 * @param url - the url of the page
 * @param isEmbedding - whether the user is currently trying to embed the page
 * @param onCompleted - callback to run when the page is embedded
 * @returns an object containing the loading state and whether the url matches
 * */
export const useEmbedNotionPage = (url: string, isEmbedding: boolean, onCompleted: () => boolean) => {
  const docId = useDocId();
  const editor = useEditorContext(ctx => ctx.editor);
  const linkMutation = useLinkNotionPage();

  const {
    pageId, isDatabase,
  } = getNotionPageId(url);

  const {
    page, isLoading, error,
  } = useNotionPage(pageId ? {
    typename: isDatabase ? 'NotionDatabase' : 'NotionPage',
    id: pageId,
    iconEmoji: '',
    title: '',
    url: '',
  } : undefined);

  useEffect(() => {
    if (!isEmbedding || !docId || !page) return;

    queueMicrotask(() => {
      editor.commands.undo();
      editor
        .chain()
        .focus()
        .insertContent({
          type: NOTION_EXTENSION_NAME,
          attrs: {
            ...page,
            typename: page.__typename,
          },
        })
        .createParagraphNear()
        .command(onCompleted)
        .run();
    });

    if (!isDatabase) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      linkMutation.linkNotionPage({
        docId,
        pageId: page.id,
      });
    }

    trackAnalytics(Events.IntegrationIssueMentioned, {
      type: IntegrationType.Notion,
      method: Methods.Paste,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmbedding, docId, page]);

  return {
    isLoading,
    match: !!pageId,
    error,
  };
};
