/**
 * `Property` is new name for `Attribute`
 *
 * Make sure you don't replicate logic from attributes.util.ts and try to
 * refactor to delete the file at some point
 */
import {
  CustomAttributeDefinitionFragment as CustomPropertyDefinitionFragment,
  DocAttributesFragment as DocPropertiesFragment,
  AddNewDocAttributeValue as AddNewDocPropertyValue,
} from '@cycle-app/graphql-codegen';
import { isPresent } from 'ts-is-present';

import { ObjectWithPropertyDefinition, GetCustomPropertyValueReturn, CustomPropertyFormData } from 'src/types/property.types';

type DocPropertyV2 = DocPropertiesFragment['attributes']['edges'][0]['node'];

export const getPropertiesFromDocType = <T extends ObjectWithPropertyDefinition>(
  docType: T | null | undefined,
): CustomPropertyDefinitionFragment[] => docType?.attributeDefinitions?.edges.map(e => e.node) || [];

export const getCustomPropertyValue = (
  property: DocPropertyV2,
): GetCustomPropertyValueReturn => {
  if (property.__typename === 'DocAttributeCheckbox' && 'checkboxValue' in property) return !!property.checkboxValue?.value;
  if (property.__typename === 'DocAttributeDate' && 'dateValue' in property) return property.dateValue?.value;
  if (property.__typename === 'DocAttributeEmail' && 'emailValue' in property) return property.emailValue?.value;
  if (property.__typename === 'DocAttributeNumber' && 'numberValue' in property) return property.numberValue?.value;
  if (property.__typename === 'DocAttributePhone' && 'phoneValue' in property) return property.phoneValue?.value;
  if (property.__typename === 'DocAttributeText' && 'textValue' in property) return property.textValue?.value;
  if (property.__typename === 'DocAttributeUrl' && 'urlValue' in property) return property.urlValue?.value;
  if (property.__typename === 'DocAttributeSingleSelect' && 'selectValue' in property) return property.selectValue?.id;
  if (property.__typename === 'DocAttributeMultiSelect' && 'selectValues' in property) return property.selectValues?.map(v => v.id);

  return undefined;
};

type getCustomPropertyValuesParamData = Record<string, GetCustomPropertyValueReturn>;
type getCustomPropertyValuesParamOptions = {
  ignoredKeys: string[];
};
export const getCustomPropertyValues = (
  data: getCustomPropertyValuesParamData,
  options?: getCustomPropertyValuesParamOptions,
): Record<string, string> => Object.entries(data).reduce((previous, current) => {
  if (options?.ignoredKeys.length && options.ignoredKeys.includes(current[0])) {
    return previous;
  }

  return {
    ...previous,
    [current[0]]: current[1],
  };
}, {});

/**
 * Will convert data from a getCustomPropertyValues to data submittable
 * to create a doc
 */
export const getSubmittableCustomPropertyValues = (
  customPropertyValues: Record<string, GetCustomPropertyValueReturn>,
  formProperties: CustomPropertyFormData[],
): AddNewDocPropertyValue[] => Object.entries(customPropertyValues).map(([propertyDefinitionId, propertyValue]) => {
  const property = formProperties.find(formProperty => formProperty.definition.id === propertyDefinitionId);

  if (!property) return null;

  if (property.definition.__typename === 'AttributeDateDefinition' && typeof propertyValue === 'string') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { date: new Date(propertyValue).toISOString() },
    } as AddNewDocPropertyValue;
  }
  if (property.definition.__typename === 'AttributeEmailDefinition' && typeof propertyValue === 'string') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { email: propertyValue },
    } as AddNewDocPropertyValue;
  }
  if (property.definition.__typename === 'AttributeNumberDefinition' && typeof propertyValue === 'number') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { number: propertyValue },
    } as AddNewDocPropertyValue;
  }
  if (property.definition.__typename === 'AttributePhoneDefinition' && typeof propertyValue === 'string') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { phone: propertyValue },
    } as AddNewDocPropertyValue;
  }
  if (property.definition.__typename === 'AttributeSingleSelectDefinition' && typeof propertyValue === 'string') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { select: propertyValue },
    } as AddNewDocPropertyValue;
  }
  if (property.definition.__typename === 'AttributeTextDefinition' && typeof propertyValue === 'string') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { text: propertyValue },
    } as AddNewDocPropertyValue;
  }
  if (property.definition.__typename === 'AttributeUrlDefinition' && typeof propertyValue === 'string') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { url: propertyValue },
    } as AddNewDocPropertyValue;
  }
  if (property.definition.__typename === 'AttributeCheckboxDefinition' && typeof propertyValue === 'boolean') {
    return {
      attributeDefinitionId: propertyDefinitionId,
      value: { checkbox: !!propertyValue || false },
    } as AddNewDocPropertyValue;
  }

  return null;
}).filter(isPresent);
