import { useEffect } from 'react';

import { setDocTypes } from 'src/reactives/docTypes.reactive';
import { setStatuses } from 'src/reactives/productStatus.reactive';

import { useProduct } from './api/useProduct';

/**
 * Store product statuses and doc types in reactive variables for quick access
 */
export const useInitProductReactives = () => {
  const { product } = useProduct();
  useEffect(() => {
    setDocTypes(product?.doctypes);
    setStatuses(product?.status);
  }, [product]);
};
