import { typo, ActionButton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { ToastersContainer, ErrorToastersContainer } from 'src/components/LayoutFooter/LayoutFooter.styles';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${p => p.theme.colors.lightOnboarding.background.body};
`;

export const Container = styled.div`
  padding: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  width: 1024px;
  height: 840px;
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 48px);
  border-radius: 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05), 0px 35px 70px rgba(0, 0, 0, 0.06);
  background: ${p => p.theme.colors.lightOnboarding.background.main};

  ${mobile} {
    width: 100vw;
    height: 100vh;
    max-width: unset;
    max-height: unset;
    border-radius: 0;
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-height: 600px;
`;

export const Footer = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  ${typo.headerLarge600}
  color: ${p => p.theme.colors.text.primary};
  margin-top: 20px;
  text-align: center;
`;

export const SubTitle = styled.h2`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-bottom: 32px;
  text-align: center;
`;

export const LogoutButton = styled(ActionButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 24px;
  gap: 8px;
  ${typo.body500}
`;

export const StyledToastersContainer = styled(ToastersContainer)`
  right: 12px;
  bottom: 12px;
`;

export const StyledErrorContainer = styled(ErrorToastersContainer)`
  position: absolute;
  left: 12px;
  bottom: 12px;
`;
