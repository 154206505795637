import { Location } from 'history';
import { useSyncExternalStore } from 'react';
import { useLocation as useRouterLocation } from 'react-router-dom';

import { history } from 'src/providers';
import { LocationState } from 'src/types/routes.types';

export const useLocation = () => useRouterLocation<LocationState>();

export const useLocationSelector = <T>(
  selector: (location: Location<LocationState>) => T,
): T => {
  return useSyncExternalStore(
    history.listen,
    () => selector(history.location),
  );
};
