import { UserJourney } from '@cycle-app/graphql-codegen';
import { LogoutIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { DEFAULT_AVATARS } from 'src/constants/avatars.constants';
import { PageId } from 'src/constants/routing.constant';
import { useMe } from 'src/hooks/api/useMe';
import { useIntercom } from 'src/hooks/useIntercom';
import { useLogout } from 'src/hooks/useLogout';
import { useUrl } from 'src/hooks/useUrl';
import { useGetAuth } from 'src/reactives/auth.reactive';
import { getLastView } from 'src/reactives/lastView.reactive';

import {
  AvatarImg,
  Body,
  Container,
  Content,
  Header,
  Logo,
  LogoutButton,
  RequestButton,
  Title,
  LogoutButtonText,
} from './EmptyState.styles';

export const EmptyStateNotAllowed = () => {
  const { me } = useMe();
  const logout = useLogout();
  const { productSlug: lastProductSlug } = getLastView();
  const {
    token, userId,
  } = useGetAuth();
  const products = useMemo(() => nodeToArray(me.products), [me]);
  const productSlug = products.find(product => product.slug === lastProductSlug)
    ? lastProductSlug
    : products[0]?.slug;
  const getUrl = useUrl();
  useIntercom();
  const isOnboarding = me.userJourney === UserJourney.Onboarding && !!token && !!userId;
  const exitText = isOnboarding ? 'Go back to your onboarding' : 'Logout';
  const wsText = productSlug ? 'Go back to your workspace' : 'Logout';
  const firstName = me.firstName ? ` ${me.firstName}` : '';
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.3 },
      }}
    >
      <Header>
        <Logo animation="hover" full size={32} />
        <LogoutButton size={14} onClick={logout}>
          <LogoutButtonText>
            {`Connected as${firstName} ${firstName ? `(${me.email})` : me.email}`}
          </LogoutButtonText>
          <LogoutIcon />
        </LogoutButton>
      </Header>
      <Body>
        <Content>
          <AvatarImg src={DEFAULT_AVATARS[0]} alt="avatar" />
          <div>
            <Title>
              {`Hey${firstName} 👋,`}
              <br />
              Looks like you don’t have access to this page
            </Title>
            <RequestButton
              size="M"
              onClick={() => {
                if (isOnboarding) {
                  window.location.href = [getUrl(PageId.GetStarted), `token=${token}&userId=${userId}`].join('?');
                  return;
                }
                if (productSlug) {
                  window.location.href = getUrl(PageId.Inbox, { productSlug });
                  return;
                }
                logout();
              }}
            >
              {isOnboarding ? exitText : wsText}
            </RequestButton>
          </div>
        </Content>
      </Body>
    </Container>
  );
};
