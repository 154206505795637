import { HandlerHotkey, isInputTarget } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { getHotkey, setHotkey } from 'src/reactives/hotkey.reactive';
import { Layer } from 'src/types/layers.types';
import { AnyShortcut } from 'src/types/shortcuts.types';
import { getHotkeyParams } from 'src/utils/shortcuts.util';

import useAppHotkeys from './useAppHotkeys';

export interface UseHotkeyParams<Shortcut extends AnyShortcut> {
  callbacks: Partial<Record<Shortcut, VoidFunction>>;
  shortcuts: Shortcut[];
  enabled?: () => boolean;
  preventCallback?: () => boolean;
  disableOnLayers?: Layer[];
}

export function useHotkeyListener<Shortcut extends AnyShortcut>({
  callbacks,
  shortcuts,
  enabled = () => true,
  ...options
}: UseHotkeyParams<Shortcut>) {
  const {
    mapping,
    preShortcutsToListen,
    shortcutsToListen,
  } = getHotkeyParams(shortcuts);

  const preListener = useCallback((event: KeyboardEvent, handler: HandlerHotkey) => {
    if (!isInputTarget(event)) {
      setHotkey({ last: handler.key });
    }
  }, []);

  // Listen post-keys and combinaisons
  const listener = useCallback((event: KeyboardEvent, handler: HandlerHotkey) => {
    const { last } = getHotkey();

    if (last) {
      const shortcut = (mapping.sequential[last] ?? {})[handler.key];
      if (shortcut) {
        callbacks[shortcut]?.();
        event.preventDefault();
        setHotkey({ last: null });
        return;
      }
    }

    const shortcut = mapping.default[handler.key];
    if (!shortcut) return;

    const callback = callbacks[shortcut];
    if (!callback) return;

    event.preventDefault();
    callback();
    setHotkey({ last: null });
  }, [callbacks]);

  const optionsWithDefaultValues = {
    enabled,
    ...options,
  };
  useAppHotkeys(preShortcutsToListen.join(','), preListener, optionsWithDefaultValues);
  useAppHotkeys(shortcutsToListen.join(','), listener, optionsWithDefaultValues);
}
