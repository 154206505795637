import { useQuery } from '@apollo/client';
import {
  FetchDocParentDocument,
  FetchDocParentReturnFragment,
} from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

export type useDocDocParentReturn = {
  parent: FetchDocParentReturnFragment['parent'] | null;
  isLoading: boolean;
};

export const useDocParent = ({ docId }: { docId?: string }): useDocDocParentReturn => {
  const {
    data, loading,
  } = useQuery(FetchDocParentDocument, {
    skip: !docId,
    variables: { docId },
  });

  const parent = useMemo<useDocDocParentReturn['parent']>(() => (data?.node?.__typename === 'Doc'
    ? data.node.parent
    : null), [data]);

  return {
    parent,
    isLoading: loading,
  };
};
