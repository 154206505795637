import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
  0% {
   background-position: 0% 50%;
  }
  50% {
   background-position: 100% 50%;
  }
  100% {
   background-position: 0% 50%;
  }
`;

export type ContainerProps = {
  $size: 'S' | 'M';
};

export const Container = styled.div<ContainerProps>`
  --size: ${p => (p.$size === 'S' ? '10px' : '12px')};
  --weight: ${p => (p.$size === 'S' ? 500 : 600)};
  --line-height: ${p => (p.$size === 'S' ? '18px' : '20px')};
  background: linear-gradient(60deg, #FFCDDB, #E9E3FF, #D5F3FB, #DFFAE5, #FFF9DC);
  background-size: 300% 300%;
  animation: ${gradient} 4s ease infinite;
  border-radius: 4px;
  padding: 0 6px;
  position: relative;

  p {
    font-weight: var(--weight);
    font-size: var(--size);
    line-height: var(--line-height);
    background: linear-gradient(60deg, #FF3870, #A990FF, #57CFEF, #7FEA96, #FFE974);
    background-size: 300% 300%;
    animation: ${gradient} 4s ease infinite;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-text-fill-color: transparent;
  }

  span {
    font-weight: var(--weight);
    font-size: var(--size);
    line-height: var(--line-height);
    color: black;
    position: absolute;
    opacity: 0.3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
