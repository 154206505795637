import { Color } from '@cycle-app/graphql-codegen';
import styled from 'styled-components';

import { CheckedDot } from '../CheckedDot/CheckedDot';

type ThemePreviewProps = {
  $selected: boolean;
  $color: Color;
};
export const ThemePreview = styled.div`
  position: relative;
  background-color: ${p => p.theme.colors.background.hover};
  cursor: pointer;
  height: 100%;

  > svg {
    position: absolute;
    top: 20px;
    left: 20px;
  }
`;

export const ThemePreviewContainer = styled.div<ThemePreviewProps>`
  border: 2px solid ${p => (p.$selected ? p.theme.nuances[p.$color].main : 'transparent')};
  border-radius: 8px;
  height: 144px;
  overflow: hidden;
`;

export const ThemePreviews = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

export const StyledCheckedDot = styled(CheckedDot).attrs({
  hasIcon: true,
})`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
