import { useQuery } from '@apollo/client';
import { CustomerDocsCountDocument } from '@cycle-app/graphql-codegen/generated';

export const useCustomerDocsCount = (customerId: string) => {
  const {
    data, loading,
  } = useQuery(CustomerDocsCountDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      customerId,
    },
  });

  return {
    docsCount: data?.getCustomerDocsCount,
    isLoading: loading,
    isLoaded: !!data,
  };
};
