import { FC } from 'react';

import { Container, Img, Name } from './Logo.styles';

interface Props {
  src: string;
  name: string;
}

export const Logo: FC<React.PropsWithChildren<Props>> = ({
  src, name,
}) => {
  return (
    <Container>
      <Img src={src} />
      <Name>{name}</Name>
    </Container>
  );
};
