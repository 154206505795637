import {
  HideSwimlaneDocument,
  ShowSwimlaneDocument,
  ShowAllSwimlanesDocument,
  HideAllSwimlanesDocument,
  BoardWithConfigDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useGetBoardWithConfigFromCache } from 'src/hooks/api/cache/cacheBoardConfig';
import { useBoard } from 'src/hooks/api/useBoard';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { defaultPagination } from 'src/utils/pagination.util';

interface Args {
  boardConfigId?: string | null;
  swimlaneConfigId: string | null | undefined;
  boardID?: string;
}

export default function useManageHiddenSwimlanes({
  swimlaneConfigId, boardID,
}: Args) {
  const [showSwimlaneMutation, { loading: loadingShow }] = useSafeMutation(ShowSwimlaneDocument);
  const [showAllSwimlanesMutation, { loading: loadingShowAll }] = useSafeMutation(ShowAllSwimlanesDocument);
  const [hideSwimlaneMutation, { loading: loadingHide }] = useSafeMutation(HideSwimlaneDocument);
  const [hideAllSwimlanesMutation, { loading: loadingHideAll }] = useSafeMutation(HideAllSwimlanesDocument);

  const board = useBoard(boardID);
  const boardId = board?.id ?? '';
  const getBoardWithConfigFromCache = useGetBoardWithConfigFromCache(boardId);

  const showSwimlane = useCallback(async (swimlaneId: string) => showSwimlaneMutation({
    variables: { swimlaneId },
    optimisticResponse: {
      showSwimlane: {
        __typename: 'Swimlane',
        id: swimlaneId,
        hidden: false,
      },
    },
  }), [showSwimlaneMutation]);

  const hideSwimlane = useCallback((swimlaneId: string, options: { updateSwimlanes?: boolean } = {}) => hideSwimlaneMutation({
    variables: { swimlaneId },
    optimisticResponse: {
      hideSwimlane: {
        __typename: 'Swimlane',
        id: swimlaneId,
        hidden: true,
      },
    },
    ...options.updateSwimlanes && {
      update: (cache) => {
        if (!boardId) return;

        const boardWithPublishedConfig = getBoardWithConfigFromCache();
        const boardConfig = boardWithPublishedConfig?.publishedBoardConfig;

        if (boardWithPublishedConfig && boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          cache.writeQuery({
            query: BoardWithConfigDocument,
            variables: {
              id: boardId,
              ...defaultPagination,
            },
            data: {
              node: {
                ...boardWithPublishedConfig,
                publishedBoardConfig: {
                  ...boardConfig,
                  docQuery: {
                    ...boardConfig.docQuery,
                    swimlanes: {
                      ...boardConfig.docQuery.swimlanes,
                      edges: boardConfig.docQuery.swimlanes.edges
                        .filter(e => e.node.id !== swimlaneId),
                    },
                  },
                },
              },
            },
          });
        }
      },
    },
  }), [hideSwimlaneMutation, boardId, getBoardWithConfigFromCache]);

  const showAllSwimlanes = useCallback(() => showAllSwimlanesMutation({
    variables: {
      swimlaneByConfigId: swimlaneConfigId ?? '',
      ...defaultPagination,
    },
  }), [showAllSwimlanesMutation, swimlaneConfigId]);

  const hideAllSwimlanes = useCallback(() => hideAllSwimlanesMutation({
    variables: {
      swimlaneByConfigId: swimlaneConfigId ?? '',
    },
  }), [hideAllSwimlanesMutation, swimlaneConfigId]);

  return {
    showSwimlane,
    hideSwimlane,
    showAllSwimlanes,
    hideAllSwimlanes,
    loading:
      loadingShow ||
      loadingHide ||
      loadingShowAll ||
      loadingHideAll,
  };
}
