import { ThemeType } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';

// All colors inspected from slack browser app.
export const SlackMessageEmoji = styled.div`
  align-items: center;
  border-radius: 50px;
  display: flex;
  font-size: 11px;
  font-weight: bold;
  gap: 4px;
  justify-content: center;
  left: 17%;
  padding: 0px 8px;
  position: absolute;
  top: 61%;
  z-index: 1;
  height: 26px;

  ${p => (p.theme.themeType === ThemeType.Nightfall
    ? css`
        background-color: #2D629E;
        color: #B3BDC9;
        width: 44px;
    ` : css`
        background-color: rgba(29, 155, 209, 0.1);
        box-shadow: rgb(29, 155, 209) 0px 0px 0px 1.5px inset;
        color: rgb(18, 100, 163);
        width: 42px;
    `)};
`;

export const SlackMessageContainer = styled.div`
  position: relative;
  width: 360px;

  img {
    display: block;
    width: 100%;
  }
`;
