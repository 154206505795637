import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { InboxEditViewModal } from 'src/components/InboxEditView';
import { InboxViewSettingsModal } from 'src/components/InboxViewSettings';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { isViewMyInbox, isViewAll } from 'src/utils/inbox.util';

type FeedbackViewSettingsModalsProps = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
  onHide?: VoidFunction;
};

export const FeedbackViewSettingsModals: FC<React.PropsWithChildren<FeedbackViewSettingsModalsProps>> = (props) => {
  const { board } = props;
  const { canUpdateView } = useGetPermission();
  const isMyInboxConfigOpen = isViewMyInbox(board);
  const isAllConfigOpen = !isMyInboxConfigOpen && isViewAll(board) && canUpdateView;
  const isViewConfigOpen = !isAllConfigOpen && !board.isBuiltIn && canUpdateView;
  return (
    <>
      {isMyInboxConfigOpen && <InboxEditViewModal {...props} />}
      {isAllConfigOpen && <InboxEditViewModal {...props} />}
      {isViewConfigOpen && <InboxViewSettingsModal {...props} />}
    </>
  );
};
