import Dropcursor from '@tiptap/extension-dropcursor';

const FALLBACK_COLOR = '#7D7D7D'; // Grey600

type GetDropCursorExtensionParams = {
  color?: string;
};

export const getDropCursorExtension = ({ color }: GetDropCursorExtensionParams) => Dropcursor.configure({
  width: 2,
  color: color || FALLBACK_COLOR,
});
