import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  opacity: 0;
  height: 20px;
  margin-left: auto;
  ${mobile} {
    opacity: 1;
  }
`;
