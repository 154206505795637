import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { CustomPropertiesFormFields } from 'src/components/CustomPropertiesFormFields';
import { LinearField } from 'src/components/CustomPropertyFormField/LinearField';
import { DocLinearAutoCreate } from 'src/components/DocLinearAutoCreate';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useDocInsightCreate, useDocTypeInsight, useDocV2, useProduct } from 'src/hooks';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useMe } from 'src/hooks/api/useMe';
import { useUpdateDocAutomation } from 'src/hooks/doc/useUpdateDocAutomation';
import { useEditorTextContent } from 'src/hooks/useEditorTextContent';
import { getDocType, useGetDocType } from 'src/reactives/docTypes.reactive';
import { useGetInsightForm } from 'src/reactives/insightForm.reactive';
import { extract } from 'src/types/graphql.types';
import { Layer } from 'src/types/layers.types';
import { GetCustomPropertyValueReturn } from 'src/types/property.types';
import { doctypeHasAutomation } from 'src/utils/doctype.automation.util';
import { FormGroup2Columns } from 'src/utils/form.util.styles';
import {
  getPropertiesFromDocType,
  getCustomPropertyValue,
  getCustomPropertyValues,
  getSubmittableCustomPropertyValues,
} from 'src/utils/properties.util';

import { FeatureField } from './FeatureField';
import { FeedbackContentAsQuote } from './FeedbackContentAsQuote';
import {
  Footer,
  Form,
  Label,
  FormRow,
} from './FeedbackQuoteCreateForm.styles';
import { InsightCreateFormData } from './InsightCreateForm.types';
import { QuoteField } from './QuoteField';

type FeedbackQuoteCreateFormProps = {
  hide: VoidFunction;
  onInsightCreated?: (parentDoc: DocBaseFragment | null | undefined) => void;
  onInsightCreating?: VoidFunction;
  initialParentDocId?: string | null;
  feedbackDoc: DocBaseFragment;
  defaultContent?: string;
  defaultCover?: string;
  blockId?: string | null;
  hideTitleField?: boolean;
  layer?: Layer;
  withoutParent?: boolean;
  linearId?: string;
  linearUrl?: string;
  linearDisabled?: boolean;
};

export const FeedbackQuoteCreateForm: FC<FeedbackQuoteCreateFormProps> = ({
  hide,
  onInsightCreated,
  onInsightCreating,
  initialParentDocId,
  feedbackDoc,
  defaultContent,
  defaultCover,
  blockId,
  hideTitleField = false,
  layer,
  withoutParent,
  linearId,
  linearUrl,
  linearDisabled,
}) => {
  const { me } = useMe();
  const { product } = useProduct();
  const { insight } = useDocTypeInsight();
  const { useFeedbackContentAsQuote } = useGetInsightForm();

  const { create } = useDocInsightCreate();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const { updateDocAutomation } = useUpdateDocAutomation();
  
  // Inheritable feedback properties
  const feedbackProperties = nodeToArray(feedbackDoc?.attributes);
  const feedbackValues = keyBy(feedbackProperties, 'definition.id');

  // Insight properties, excluding company attributes
  const companyAttributesDefinitionIds = Object.keys(keyBy(nodeToArray(product?.companyAttributeDefinitions), 'id'));
  const insightProperties = getPropertiesFromDocType(insight).filter(p => !companyAttributesDefinitionIds.includes(p.id));

  // Insight properties with initial values inherited from feedback
  const insightPropertiesData = insightProperties.map(p => {
    const feedbackProperty = feedbackValues[p.id];
    const inheritValue = feedbackProperty ? getCustomPropertyValue(feedbackProperty) : null;
    return ({
      id: p.id,
      definition: p,
      inheritValue,
      disabled: !!inheritValue,
    });
  });

  // Initial custom form values
  const initialCustomValues: Record<string, GetCustomPropertyValueReturn> = {};
  for (const value of insightPropertiesData) {
    initialCustomValues[value.id] = value.inheritValue;
  }

  const {
    handleSubmit, register, formState, watch, setValue, getValues, control,
  } = useForm<InsightCreateFormData>({
    values: {
      title: '',
      content: '',
      parentId: initialParentDocId || '',
      customerId: '',
      assignee: '',
      cover: '',
      withLinear: false,
      ...initialCustomValues,
    },
  });

  const parentId = watch('parentId');
  const quoteContent = watch('content');

  // Parent doc automation field state
  const [linearState, setLinearState] = useState<{
    id: string | null;
    url: string | null;
  }>({
    id: null,
    url: null,
  });

  // Parent query
  const {
    doc: parentDoc,
    isLoading: isParentLoading,
  } = useDocV2(parentId, {
    onCompleted: (data) => {
      const doc = extract('Doc', data.node);
      // Update insight withLinear field
      setValue('withLinear', doctypeHasAutomation(doc?.doctype.id));

      // Update parent automation field state
      setLinearState({
        id: linearId ?? parentDoc?.automationId ?? null,
        url: linearUrl ?? parentDoc?.automationUrl ?? null,
      });

      if (!doc?.insightsCount) {
        // Inheritable parent properties
        const parentProperties = nodeToArray(doc?.attributes);
        const parentValues = keyBy(parentProperties, 'definition.id');

        // Update insight custom properties fields
        for (const property of insightPropertiesData) {
          // Do not inherit from feature if the value is inherited from feedback
          if (initialCustomValues[property.id] !== null) continue;
          const parentProperty = parentValues[property.id];
          if (!parentProperty) continue;
          const inheritValue = getCustomPropertyValue(parentProperty);
          if (inheritValue === null) continue;
          setValue(property.id, inheritValue);
        }

        // Reset parent specific fields
        const parentAttributes = nodeToArray(doc?.attributes);
        const parentDocType = getDocType(doc?.doctype.id);
        const parentPropertiesDefinitions = getPropertiesFromDocType(parentDocType).filter(p =>
          !companyAttributesDefinitionIds.includes(p.id) &&
          !insightPropertiesData.find(e => e.id === p.id),
        );
        for (const definition of parentPropertiesDefinitions) {
          const parentProperty = parentAttributes.find(a => a.definition.id === definition.id);
          setValue(definition.id, parentProperty ? getCustomPropertyValue(parentProperty) : null);
        }
      }
    },
  });

  // Parent specific properties to be displayed when the parent is newly created
  const parentAttributes = nodeToArray(parentDoc?.attributes);
  const parentDocType = useGetDocType(parentDoc?.doctype.id);
  const parentPropertiesDefinitions = getPropertiesFromDocType(parentDocType).filter(p =>
    !companyAttributesDefinitionIds.includes(p.id) &&
    !insightPropertiesData.find(e => e.id === p.id),
  );
  const parentPropertiesData = parentPropertiesDefinitions.map(d => {
    const parentProperty = parentAttributes.find(a => a.definition.id === d.id);
    return ({
      id: d.id,
      definition: d,
      inheritValue: parentProperty ? getCustomPropertyValue(parentProperty) : null,
    });
  });

  // Update the quote field based on the useFeedbackContentAsQuote option
  const editorContent = useEditorTextContent(feedbackDoc.id);
  const prevContent = useRef(defaultContent || '');
  useEffect(() => {
    if (useFeedbackContentAsQuote) {
      prevContent.current = getValues('content');
      setValue('content', (defaultContent || editorContent || feedbackDoc.title) ?? '');
    } else {
      setValue('content', prevContent.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFeedbackContentAsQuote, editorContent]);


  const doctypeParentHasAutomation = doctypeHasAutomation(parentDoc?.doctype.id);
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);

  const showLinearAutoCreate =
    isLinearInstalled &&
    !!parentDoc &&
    doctypeParentHasAutomation &&
    !linearState.id; // prevent linear creation if the parent already has an automation

  const onSubmit = async (values: InsightCreateFormData) => {
    onInsightCreating?.();

    let title = 'Insight';
    if (feedbackDoc?.customer) title += ` from ${feedbackDoc.customer.displayName}`;
    if (parentDoc) title += ` on ${parentDoc.title}`;

    const customPropertyValues = getCustomPropertyValues(values, {
      ignoredKeys: ['title', 'parentId', 'content'],
    });

    const properties = getSubmittableCustomPropertyValues(customPropertyValues, insightPropertiesData);

    const promises: Promise<unknown>[] = [];

    promises.push(create({
      title,
      contentJSON: '',
      blockId: blockId || '',
      sourceId: feedbackDoc.source?.id,
      customerId: feedbackDoc.customer?.id || '',
      assignee: feedbackDoc.assignee?.id || me.id || '',
      cover: defaultCover || '',
      docLinkSourceId: feedbackDoc.id,
      doctypeId: insight?.id || '',
      parentId: values.parentId,
      docLinkContent: values.content,
      properties,
      withLinear: showLinearAutoCreate && values.withLinear,
    }, {
      parentDoc,
    }));

    if (parentDoc && !parentDoc.insightsCount) {
      for (const definition of getPropertiesFromDocType(parentDocType)) {
        const formValue = customPropertyValues[definition.id];
        if (formValue === undefined) continue;

        // Do not update property if the value has not changed
        const parentProperty = parentAttributes.find(a => a.definition.id === definition.id);
        const parentValue = parentProperty ? getCustomPropertyValue(parentProperty) : undefined;
        if (parentValue === formValue) continue;

        promises.push(changeDocAttributeValue({
          doc: parentDoc,
          attributeDefinition: definition,
          value: formValue,
        }));
      }
    }

    await Promise.all(promises);

    onInsightCreated?.(parentDoc);

    if (isLinearInstalled && !showLinearAutoCreate && parentDoc) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateDocAutomation(parentDoc.id, linearState.url, linearState.id);
    }
  };

  return (
    <Form
      data-popper-overflow
      onSubmit={handleSubmit(onSubmit)}
      isStale={isParentLoading}
    >
      <FeatureField
        parent={parentDoc}
        isLoading={isParentLoading}
        onChange={(id) => {
          setValue('parentId', id);
        }}
      />

      {!hideTitleField && (
        <QuoteField
          customerId={feedbackDoc.customer?.id}
          register={register}
          error={formState.errors.content?.message || ''}
          autoFocus
        />
      )}

      {!hideTitleField && <FeedbackContentAsQuote />}
      
      <FormGroup2Columns>
        <CustomPropertiesFormFields<InsightCreateFormData>
          data={insightPropertiesData}
          control={control}
          autoFocus={false}
          layer={layer}
        />

        {!parentDoc?.insightsCount && parentPropertiesData.length > 0 && (
          <CustomPropertiesFormFields<InsightCreateFormData>
            data={parentPropertiesData}
            control={control}
            autoFocus={false}
            layer={layer}
          />
        )}

        {!doctypeParentHasAutomation && parentDoc && (
          <LinearField
            docId={parentDoc.id}
            isDisabled={linearDisabled}
            id={linearState.id}
            url={linearState.url}
            onChange={setLinearState}
          />
        )}
      </FormGroup2Columns>

      {showLinearAutoCreate && (
        <FormRow>
          <Label>Linear</Label>
          <Controller
            control={control}
            name="withLinear"
            render={({ field }) => (
              <DocLinearAutoCreate
                checked={field.value}
                doctypeId={parentDoc.doctype.id}
                onChange={checked => field.onChange(checked)}
                style={{ display: 'inline-block' }}
                size="M"
                // prevents closing the form when closing the discover tooltip.
                withPortal={false}
              />
            )}
          />
        </FormRow>
      )}

      <Footer>
        <Button
          size="M"
          variant="secondary"
          onClick={hide}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size="M"
          isLoading={formState.isSubmitting}
          disabled={isParentLoading || !quoteContent || (!parentDoc && !withoutParent)}
          autoFocus
        >
          Confirm
        </Button>
      </Footer>
    </Form>
  );
};
