import { typo, Tag as TagUI, truncate } from '@cycle-app/ui';
import { Content, Name } from '@cycle-app/ui/components/Tag/Tag.styles';
import styled from 'styled-components';

export const Tag = styled(TagUI)`
  ${typo.body500}
  background-color: ${p => p.theme.colors.background.tertiary};
  
  ${Content} {
    /* Add space to not leave the '?' alone on a line */
    max-width: calc(100% - 20px);
    :hover {
      background: unset;
    }
  }

  ${Name} {
    ${truncate}
  }
`;
