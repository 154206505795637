import { BoardType } from '@cycle-app/graphql-codegen';
import { DotsMenuButton, Tooltip, SelectPanel, TooltipPlacement } from '@cycle-app/ui';
import { MoreHorizIcon } from '@cycle-app/ui/icons';
import { ComponentProps, FC } from 'react';

import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { PageId, routing } from 'src/constants/routing.constant';
import { useRouteMatch } from 'src/hooks';
import { useBoardMenuOptions } from 'src/hooks/boards/useBoardMenuOptions';
import { setViewDeleteModal, setViewDuplicateModal, setViewExportModal } from 'src/reactives/viewModals.reactive';
import { Layer } from 'src/types/layers.types';

type ButtonProps = ComponentProps<typeof DotsMenuButton>;

type Props = Omit<ToggleDropdownProps, 'content' | 'button'> & {
  boardId: string;
  boardType: BoardType;
  openBoardConfig?: VoidFunction;
  boardConfigId?: string;
  hideSettings?: boolean;
  tooltipPlacement?: TooltipPlacement;
  onClick?: ButtonProps['onClick'];
  onColoredBg?: ButtonProps['onColoredBg'];
};

const BoardOptions: FC<React.PropsWithChildren<Props>> = ({
  boardId,
  boardType,
  openBoardConfig,
  boardConfigId,
  hideSettings,
  placement = 'bottom',
  tooltipPlacement = 'top',
  onClick,
  onColoredBg,
  className,
  ...props
}) => {
  return (
    <ToggleDropdown
      withPortal
      placement={placement}
      layer={Layer.DropdownModal}
      width={192}
      {...props}
      content={contentProps => (
        <BoardOptionContent
          hide={contentProps.hide}
          boardId={boardId}
          boardType={boardType}
          openBoardConfig={openBoardConfig}
          boardConfigId={boardConfigId}
          hideSettings={hideSettings}
          onDeleteView={id => setViewDeleteModal({ deleteViewId: id })}
          onDuplicateView={id => setViewDuplicateModal({ duplicateViewId: id })}
          onExportView={id => setViewExportModal({ exportViewId: id })}
        />
      )}
      button={buttonProps => (
        <Tooltip
          withPortal
          content="View actions"
          placement={tooltipPlacement}
        >
          <DotsMenuButton
            ariaLabel="View actions"
            onColoredBg={onColoredBg}
            ref={buttonProps.ref}
            forceFocus={buttonProps['data-active']}
            className={className}
            onClick={e => {
              buttonProps.onClick(e);
              onClick?.(e);
            }}
          >
            <MoreHorizIcon />
          </DotsMenuButton>
        </Tooltip>
      )}
    />
  );
};

export default BoardOptions;

const BoardOptionContent = ({
  hide,
  boardId,
  boardType,
  openBoardConfig,
  boardConfigId,
  hideSettings,
  onDeleteView,
  onDuplicateView,
  onExportView,
}: {
  hide: VoidFunction;
  boardId: string;
  boardType: BoardType;
  openBoardConfig?: VoidFunction;
  boardConfigId?: string;
  hideSettings?: boolean;
  onDeleteView: (id: string) => void;
  onDuplicateView: (id: string) => void;
  onExportView: (id: string) => void;
}) => {
  const matchedBoardSlug = useRouteMatch(routing[PageId.Board])?.params.boardSlug;

  const {
    options, onMouseEnterItem, onMouseLeaveItem,
  } = useBoardMenuOptions({
    onDeleteView,
    onDuplicateView,
    onExportView,
    viewId: boardId,
    boardConfigId,
    hideSettings: hideSettings || !matchedBoardSlug?.includes(boardId),
    onOpenBoardConfig: openBoardConfig,
    type: boardType,
  });

  return (
    <>
      <SelectPanel
        hideSearch
        onMouseEnterItem={onMouseEnterItem}
        onMouseLeaveItem={onMouseLeaveItem}
        options={options}
        onOptionChange={option => {
          option.onSelect?.();
          hide();
        }}
      />
    </>
  );
};
