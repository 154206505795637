import { RemoveReleaseNoteDocument } from '@cycle-app/graphql-codegen';

import { routing, PageId } from 'src/constants/routing.constant';
import { useNavigate, useRouteMatch } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useRemoveReleaseNote = (releaseId?: string) => {
  const { navigateToRelease } = useNavigate();
  const matchReleaseNote = useRouteMatch(routing[PageId.ReleaseNote]);

  const [mutate] = useSafeMutation(RemoveReleaseNoteDocument, {
    onCompleted: () => {
      if (matchReleaseNote && releaseId) navigateToRelease(releaseId);
    },
  });

  const removeReleaseNote = (id: string) => mutate({
    variables: {
      id,
    },
    optimisticResponse: {
      removeReleaseNote: {
        id,
      },
    },
    update: (cache, { data }) => {
      cache.evict({ id: data?.removeReleaseNote?.id });
      cache.gc();
    },
  });

  return { removeReleaseNote };
};
