import styled from 'styled-components';

import * as typo from '../../theme/typo';

export const Badge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 16px;
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;

  background: ${p => p.theme.colors.components.Badge.bg};
  color: ${p => p.theme.colors.components.Badge.text};

  ${typo.caption500}
  line-height: 1;
  
  cursor: inherit;
`;
