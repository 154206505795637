import { ActionButton, Tooltip } from '@cycle-app/ui';
import { InfoIconOutline, CaretIcon } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import { ComponentPropsWithRef } from 'react';

import { InsightCardReadOnly } from 'src/components/InsightCardReadOnly';
import { InsightSkeletons } from 'src/components/InsightsList/InsightSkeletons';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { useSearchSuggestionDocTargets } from 'src/hooks/api/queries/useSearchSuggestionDocTargets';
import { useGetDocPanel, Content, useGetInsightSuggestions, toggleInsightSuggestions } from 'src/reactives/docRightPanel.reactive';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';
import { insightName } from 'src/utils/doc.util';

import {
  Container, Header, Title, Count, Cards, Card, EmptyContainer,
} from './InsightSuggestions.styles';
import { SuggestionsModal } from './SuggestionsModal';

export const InsightSuggestions = ({ insightsIds }: { insightsIds?: string[] }) => {
  const docId = useDocPanelContext(ctx => ctx.docId);
  const insightDoctypeId = useInsightDocType()?.id;
  const { content } = useGetDocPanel();

  const query = useSearchSuggestionDocTargets({
    docId,
    doctypeIds: [insightDoctypeId as string],
    hasParent: false,
  }, {
    skip: !insightsIds || !insightDoctypeId || content !== Content.Insights,
  });

  const docs = query.docs.filter(doc => !insightsIds?.includes(doc.id));

  const [
    isModalOpen, {
      setTrueCallback: openModal,
      setFalseCallback: closeModal,
    },
  ] = useOptimizedBooleanState(false);

  if (query.isLoading && !query.data) {
    return (
      <Layout>
        <InsightSkeletons />
      </Layout>
    );
  }

  const firstDoc = docs[0];

  if (!firstDoc) {
    return (
      <Layout>
        <Card>
          <EmptyContainer>
            <p>We haven’t found any recommendation</p>
            <p>{`We’ll let you know if we find relevant ${insightName({ plural: true })}`}</p>
          </EmptyContainer>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout
      count={docs.length}
    >
      <Cards
        onClick={openModal}
      >
        <InsightCardReadOnly doc={firstDoc} showAttributes />

        {docs.length > 1 && <Card />}
        {docs.length > 2 && <Card />}
      </Cards>

      {isModalOpen && (
        <SuggestionsModal
          hide={closeModal}
          docs={docs}
        />
      )}
    </Layout>
  );
};

const Layout = ({
  count, children,
}: ComponentPropsWithRef<'div'> & {
  count?: number;
}) => {
  const { isCollapsed } = useGetInsightSuggestions();
  return (
    <Container $isCollapsed={isCollapsed}>
      <Header
        $isCollapsed={isCollapsed}
        onClick={isCollapsed ? toggleInsightSuggestions : undefined}
      >
        <Title>
          {`Recommended ${insightName({ plural: true })}`}
          {count != null && <Count>{count}</Count>}
          <Tooltip
            content={`We're automatically looking for existing ${insightName({ plural: true })} that could be linked based on similarity of content.`}
            placement="top"
            withWrapper={false}
          >
            <InfoIconOutline size={16} />
          </Tooltip>
        </Title>

        <ActionButton
          onClick={e => {
            e.stopPropagation();
            toggleInsightSuggestions();
          }}
        >
          <CaretIcon size={12} direction={isCollapsed ? 'top' : 'bottom'} />
        </ActionButton>
      </Header>

      <motion.div
        animate={isCollapsed ? 'collapsed' : 'expanded'}
        variants={{
          collapsed: {
            height: 0,
            opacity: 0,
          },
          expanded: {
            height: 'auto',
            opacity: 1,
          },
        }}
      >
        {children}
      </motion.div>
    </Container>
  );
};
