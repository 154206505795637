import { extractWordsByLine, getLineContent, EMOJI_REGEXP } from '@cycle-app/utilities';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Extension } from '@tiptap/react';

/**
 * When copy pasting slack content, both html and text content are copied to the clipboard
 * and Tiptap uses the html content; In html content, emojis are rendered as img but images
 * in our editor are not meant to be emojis.
 * So here when we detect emojis in the text content, we override the default behaviour, replacing
 * emojis in format :emoji: by the corresponding node (extension -> @tiptap-pro/extension-emoji)
 */
export const getPasteEmojisExtension = () => Extension.create({
  priority: 1000,
  name: 'pasteEmojisExtensions',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handlePastedImage'),
        props: {
          handlePaste: (_, event) => {
            const textData = event.clipboardData?.getData('text/plain') ?? '';
            const textContainsEmojis = EMOJI_REGEXP.test(textData);

            if (!textContainsEmojis) return false;

            const [firstLine, ...otherLines] = extractWordsByLine(textData);
            if (!firstLine) return false;

            this.editor.chain()
              .focus()
              .insertContent([
                ...getLineContent(firstLine),
                ...otherLines.length > 0
                  ? otherLines.map(line => ({
                    type: 'paragraph',
                    content: getLineContent(line),
                  }))
                  : [],
              ])
              .run();

            // Returning true prevent the original event
            return true;
          },
        },
      }),
    ];
  },
});
