import { useSortable } from '@dnd-kit/sortable';
import { HTMLAttributes, ReactNode, FC } from 'react';

import { BULK_PREVENT_CLASSNAME } from 'src/constants/bulkSelection.constants';
import { DndItemType } from 'src/hooks/dnd/useGroupsDnd';
import { getSortableStyle } from 'src/utils/dnd.util';

interface RenderProps extends HTMLAttributes<HTMLDivElement> {
  ref: (element: HTMLElement | null) => void;
}

interface Props {
  id: string;
  render: (p: RenderProps) => ReactNode;
  dataType?: DndItemType;
  disabled?: boolean;
  metaData?: Record<string, unknown>;
}

const Sortable: FC<Props> = ({
  id,
  render,
  dataType = 'item',
  disabled,
  metaData = {},
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef: ref,
  } = useSortable({
    id,
    data: {
      type: dataType,
      isDraggable: !disabled,
      ...metaData,
    },
    disabled,
  });

  const style = getSortableStyle({
    transform,
    transition,
  });

  return (
    <>
      {render({
        ref,
        style,
        className: BULK_PREVENT_CLASSNAME,
        ...attributes,
        ...listeners,
      })}
    </>
  );
};

export default Sortable;
