/**
 * Image extension
 */
import { FileUploadedData } from './files.types';

export enum ImageSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export interface FileData {
  src: string;
  title?: string;
  dataId: string;
}

export type VideoViewAttributes = FileData;

export type FileViewAttributes = {
  file?: FileUploadedData;
  // DataId is optional since legacy file node didn't have the info
  dataId?: string;
};

export type AudioViewAttributes = Omit<VideoViewAttributes, 'title'>;
