import { RadioInput, RadioInputDot } from '@cycle-app/ui';
import styled from 'styled-components';

export const List = styled.ul`
  margin-top: 8px;

  li:not(:first-child) {
    margin-top: 8px;
  }
`;

export const TagStyleContainer = styled.div`
  background-color: ${p => (p.theme.isDark ? p.theme.colors.body.bg : p.theme.baseColors.Grey200)};
  display: flex;
  height: 36px;
  padding: 2px;
  border-radius: 8px;

  :has(:focus-visible) {
    background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey850 : p.theme.baseColors.Grey300)};
  }
`;

interface TagStyleLabelProps {
  $checked: boolean;
}

export const TagStyleLabel = styled.div<TagStyleLabelProps>`
  align-items: center;
  background-color: ${p => (p.$checked ? ((p.theme.isDark && p.theme.baseColors.Grey800) || '#FFF') : 'transparent')};
  border-radius: 6px;
  display: flex;
  flex: none;
  height: 32px;
  justify-content: center;
  transition: background-color .1s;
  width: 32px;
`;

export const TagStyleValueDot = styled.div<TagStyleLabelProps>`
  background-color: ${p => (p.$checked ? p.theme.colors.text.primary : p.theme.colors.text.disabled)};
  width: 8px;
  height: 8px;
  border-radius: 8px;
`;

export const TagStyleValueBg = styled.div<TagStyleLabelProps>`
  align-items: center;
  justify-content: center;
  border: 1px solid ${p => (p.$checked ? 'transparent' : p.theme.colors.text.disabled)};
  color: ${p => (p.$checked ? p.theme.colors.text.primary : p.theme.colors.text.disabled)};
  border-radius: 4px;
  display: flex;
  height: 13px;
  width: 13px;
  position: relative;
  overflow: hidden;

  &::before {
    background-color: ${p => (p.$checked ? p.theme.colors.text.disabled : 'transparent')};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.4;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const StyledCheckboxInput = styled(RadioInput)`
  ${RadioInputDot} {
    display: none;
  }
`;
