import { ThemeType } from '@cycle-app/graphql-codegen/generated';
import styled, { css } from 'styled-components';

import { body400, body500, tiny400, caption400 } from '../../../theme/typo';
import { ActionButton } from '../../Buttons';

export const Label = styled.label`
  ${body500};
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;

  div[data-popover=true] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Required = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.disabled};
`;

interface HelperProps {
  $hasError?: boolean;
  $size?: 'S' | 'M';
}

export const Helper = styled.div<HelperProps>`
  --helperColor: ${({ theme }) => theme.colors.text.secondary};
  ${tiny400};
  color: var(--helperColor);
  margin-top: 4px;
  min-height: 18px;

  ${p => p.$hasError && css`
    --helperColor: ${({ theme }) => theme.colors.text.red};
  `}

  ${p => p.$size === 'S' && css`${caption400}`}
  ${p => p.$size === 'M' && css`${body400}`}
`;

interface InputProps {
  $hasIconBefore?: boolean;
  $hasIconAfter?: boolean;
  $iconPadding?: number;
}

const IconSize = 12;

export const Container = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const ErrorVariant = css`
  --borderColor: ${({ theme }) => theme.colors.border.red};
  --borderColorFocus: ${({ theme }) => theme.colors.border.red};
  --bgColor: ${({ theme }) => theme.colors.input.bg};
  --color: ${({ theme }) => theme.colors.text.red};
`;

export const InputCss = css`
  --borderColor: transparent;
  --borderColorFocus: hsla(var(--cycle), 0.8);
  --bgColor: ${({ theme }) => theme.colors.input.bg};
  --color: ${({ theme }) => theme.colors.text.primary};
  --colorPlaceholder: ${p => p.theme.colors.text.disabled};

  color-scheme: ${p => (p.theme.themeType === ThemeType.Nightfall ? 'dark' : 'light')};

  ${body400};
  color: var(--color);
  outline: none;
  border: 2px solid var(--borderColor);
  border-radius: 6px;
  box-sizing: border-box;
  background-color: var(--bgColor);
  padding: 6px 12px;
  width: 100%;
  transition: 100ms border-color linear, 100ms box-shadow linear;
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--colorPlaceholder);
  }

  &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:read-only, &:disabled {
    --color: ${p => p.theme.colors.text.disabled};
    cursor: not-allowed;
  }

  &[type=date]:invalid {
    ${ErrorVariant};
  }

  &:hover {
    --bgColor: ${({ theme }) => theme.colors.input.hover};
  }

  &:not(:read-only):not(:disabled) {
    /* The forceFocus is not to be used in app. Only in Storybook as a display util */
    &:focus, .forceFocus & {
      border-color: var(--borderColorFocus);
      background-color: transparent;
    }
  }
`;

interface StyledInputProps {
  $hasError?: boolean;
  $paddingLeft?: number;
  $paddingRight?: number;
}

export const StyledInput = styled.input<StyledInputProps>`
  ${InputCss};
  ${(p) => p.$hasError && ErrorVariant};
  ${p => p.$paddingLeft && `padding-left: ${p.$paddingLeft + 4}px;`};
  ${p => p.$paddingRight && `padding-right: ${p.$paddingRight + 4}px;`};
`;

export const InputContainer = styled.div<InputProps>`
  position: relative;

  ${(p) => p.$hasIconBefore && css`
    ${StyledInput} {
      padding-left: ${p.$iconPadding ?? 28}px;
    }
  `};

  ${(p) => p.$hasIconAfter && css`
    ${StyledInput} {
      padding-right: ${p.$iconPadding ?? 36}px;
    }
  `};
`;

interface IconProps {
  onClick?: () => void;
}

export const IconBeforeContainer = styled.div<IconProps>`
  position: absolute;
  display: flex;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  color: ${p => p.theme.colors.text.disabled};

  svg {
    width: ${IconSize}px;
    height: ${IconSize}px;
  }
`;

export const ActionIcon = styled(ActionButton)`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;

  &:hover, &:focus, &.force-focus {
    background-color: transparent;
  }
`;

export const EndContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
`;

export const IconContainer = styled.span`
  display: inline-flex;
  color: ${p => p.theme.colors.text.disabled};
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

export const Prefix = styled.div`
  position: absolute;
  left: 0;
  padding-left: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
`;

export const Suffix = styled.div`
  position: absolute;
  padding-right: 12px;
  right: 0;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
`;
