import { ActionButton } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import BoardOptions from 'src/components/BoardOptions/BoardOptions';

const buttonCss = css`
  color: ${p => p.theme.colors.sidebar.boardsSectionItem.color};

  &:hover, &.force-focus {
    background-color: ${p => p.theme.colors.sidebar.boardsSectionItem.bgHover};
    color: ${p => p.theme.colors.sidebar.boardsSectionItem.colorHover};
  }
`;

export const StyledBoardOptions = styled(BoardOptions)`
  ${buttonCss}
`;

export const More = styled.div`
  display: flex;
  gap: 4px;
`;

export const StarButton = styled(ActionButton)`
  ${buttonCss};
`;

export const IconButton = styled(ActionButton)`
  ${buttonCss};
  margin: 0 -4px;
  width: 24px;
  height: 24px;
  svg {
    width: 16px;
    height: 16px;
  }
`;
