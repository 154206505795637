import styled from 'styled-components';

export const Container = styled.div<{ $areShortcutsEnabled: boolean }>`
  .shortcut {
    opacity: ${p => (p.$areShortcutsEnabled ? 1 : 0.3)};
  }

  .toaster:not(:last-child) .shortcut--enabled-last-toaster {
    opacity: 0.3;
  }
`;
