import type { IconName, IconNameOutput } from '../../types/icon.types';

export const getIconName = (string: string) => {
  if (string.startsWith('lucide_')) {
    return string.replace('lucide_', '') as IconName;
  }
  return null;
};

export const getIconNameOutput = (iconName: string) => {
  return `lucide_${iconName}` as IconNameOutput;
};
