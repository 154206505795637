import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const UserVoiceIcon: FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <path d="M11.72 0L9.03998 1.76V11.2C9.03998 12.08 8.59998 12.88 7.91998 13.36C7.27998 12.84 6.91998 12.04 6.91998 11.2V10.68C6.91998 10.16 6.39998 9.6 6.39998 9.08C6.39998 8.72 6.63998 8.32 6.79998 7.96C6.87998 7.8 6.91998 7.64 6.91998 7.48C6.91998 6.88 6.43998 6.4 5.83998 6.4C5.23998 6.4 4.79998 6.88 4.79998 7.48C4.79998 7.64 4.83998 7.84 4.91998 7.96C5.07998 8.32 5.31998 8.72 5.31998 9.08C5.31998 9.6 4.79998 10.16 4.79998 10.68V11.2C4.79998 12.16 5.07998 13.04 5.59998 13.76C4.51998 13.4 3.71998 12.4 3.71998 11.2V10.68C3.71998 10.16 3.19998 9.6 3.19998 9.08C3.19998 8.72 3.43998 8.32 3.59998 7.96C3.67998 7.8 3.71998 7.64 3.71998 7.48C3.71998 6.88 3.23998 6.4 2.63998 6.4C2.07998 6.4 1.59998 6.88 1.59998 7.48C1.59998 7.64 1.63998 7.84 1.71998 7.96C1.87998 8.32 2.11998 8.72 2.11998 9.08C2.11998 9.6 1.59998 10.16 1.59998 10.68V11.2C1.59998 13.84 3.75998 16 6.39998 16C9.03998 16 11.2 13.84 11.2 11.2V2.8L11.72 2.44L12.28 2.8C12.28 2.8 12.28 11.04 12.28 11.08C12.28 12.84 11.76 14.64 9.71998 15.88C11.8 15.88 14.36 13.56 14.4 10.56V1.76L11.72 0Z" fill="#FF6720" />
    <path d="M5.87993 5.63949C6.47639 5.63949 6.95993 5.15596 6.95993 4.55949C6.95993 3.96302 6.47639 3.47949 5.87993 3.47949C5.28346 3.47949 4.79993 3.96302 4.79993 4.55949C4.79993 5.15596 5.28346 5.63949 5.87993 5.63949Z" fill="#FF6720" />
    <path d="M5.87993 2.76059C6.47639 2.76059 6.95993 2.27705 6.95993 1.68059C6.95993 1.08412 6.47639 0.600586 5.87993 0.600586C5.28346 0.600586 4.79993 1.08412 4.79993 1.68059C4.79993 2.27705 5.28346 2.76059 5.87993 2.76059Z" fill="#FF6720" />
    <path d="M2.67998 5.68051C3.27644 5.68051 3.75998 5.19698 3.75998 4.60051C3.75998 4.00404 3.27644 3.52051 2.67998 3.52051C2.08351 3.52051 1.59998 4.00404 1.59998 4.60051C1.59998 5.19698 2.08351 5.68051 2.67998 5.68051Z" fill="#FF6720" />
    <path d="M2.67998 2.79965C3.27644 2.79965 3.75998 2.31612 3.75998 1.71965C3.75998 1.12318 3.27644 0.639648 2.67998 0.639648C2.08351 0.639648 1.59998 1.12318 1.59998 1.71965C1.59998 2.31612 2.08351 2.79965 2.67998 2.79965Z" fill="#FF6720" />
  </Svg>
);
