import { ReactNode } from 'react';

import {
  CardContainer,
  CardBody,
  IntegrationTitle,
  IntegrationName,
  Logos,
  Logo,
  CardSide,
  WorkspaceLogo,
  WorkspaceName,
  Information,
  IntegrationTitleBlock,
  LogoBlock,
  CardContainerBlock,
  CardBodyBlock,
  IntegrationNameBlock,
  CardBodyFooter,
} from './IntegrationCard.styles';
import { Tooltip } from '../Tooltip/Tooltip';

export interface IntegrationCardProps {
  title?: string;
  description?: string;
  logo?: ReactNode;
  badge?: ReactNode;
  logos?: Record<string, ReactNode>;
  information?: ReactNode;
  workspaceName?: string;
  workspaceLogoUrl?: string;
  actions?: ReactNode;
  slot?: ReactNode;
  isSlack?: boolean;
  viewType?: 'block';
}

export const IntegrationCard = ({
  title,
  description,
  logo,
  logos,
  information,
  workspaceLogoUrl,
  workspaceName,
  actions,
  slot,
  isSlack,
  viewType,
  badge,
}: IntegrationCardProps) => (viewType !== 'block' ? (
  <CardContainer>
    <CardBody>
      <IntegrationTitle title={description}>
        <Logos>
          {logo && <Logo>{logo}</Logo>}
          {logos && Object.entries(logos).map(([key, value]) => (
            <Tooltip
              key={key}
              content={key}
              placement="top"
            >
              <Logo>{value}</Logo>
            </Tooltip>
          ))}
        </Logos>
        {title && <IntegrationName>{title}</IntegrationName>}
        {badge}
      </IntegrationTitle>
      <CardSide>
        {information && <Information>{information}</Information>}
        {workspaceLogoUrl && <WorkspaceLogo $src={workspaceLogoUrl} />}
        {workspaceName && <WorkspaceName>{workspaceName}</WorkspaceName>}
        {actions}
      </CardSide>
    </CardBody>

    {slot}
  </CardContainer>
) : (
  <CardContainerBlock $isSlack={isSlack}>
    <CardBodyBlock>
      <IntegrationTitleBlock title={description}>
        <Logos>
          {logo && <LogoBlock>{logo}</LogoBlock>}
        </Logos>
        <CardSide>{actions}</CardSide>
      </IntegrationTitleBlock>
      <CardBodyFooter>
        {title && <IntegrationNameBlock>{title}</IntegrationNameBlock>}
        {slot}
      </CardBodyFooter>
    </CardBodyBlock>
  </CardContainerBlock>
));
