import { typo, truncate, ActionButton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const FreePlanContainer = styled.div<StateProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px;
  border-radius: 10px;
  position: relative;

  color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.text.primary;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.text.primary;
    return p.theme.colors.components.FreePlan.default.text.primary;
  }};

  background: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.bg;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.bg;
    return p.theme.colors.components.FreePlan.default.bg;
  }};

  ${p => p.onClick && `
    cursor: pointer;
  `};

  ${p => p.onClick && !p.danger && !p.warning && `
    :hover {
      background: ${p.theme.colors.components.FreePlan.default.bgHover};
    }
  `}
  

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const FreePlanText = styled.div`
  ${typo.caption400}
  ${truncate}
  max-width: 100%;
  flex: none;
`;

export const FreePlanTextSecondary = styled(FreePlanText) <StateProps>`
  color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.text.secondary;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.text.secondary;
    return p.theme.colors.components.FreePlan.default.text.secondary;
  }};
`;

export const FreePlanTitle = styled(FreePlanText)`
  ${typo.caption500}
`;


const zIndexSidebar = mappingZindex[Layer.Sidebar];

interface ToggleSidebarBtnProps {
  $collapsed: boolean;
}
export const ToggleSidebarButton = styled.button<ToggleSidebarBtnProps>`
  position: fixed;
  top: 44px;
  margin-left: 3px;
  border-radius: 50%;
  background-color: hsl(var(--cycle));
  width: 24px;
  height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;

  transition: transform 200ms ease-in-out;
  transform:
    translateX(calc(-50% + 1px))
    scale(1);

  svg {
    transform: ${p => (p.$collapsed ? 'rotate(-90deg)' : 'rotate(90deg)')};
  }

  &:hover {
    transform:
      translateX(calc(-50% + 1px))
      scale(1.2);
  }
`;

export const HandleResize = styled.div<{ resizing: boolean; resizingDisabled: boolean }>`
  cursor: ${p => (p.resizingDisabled ? 'pointer' : 'ew-resize')};

  position: absolute;
  top: 0;
  right: -4px;

  width: 8px;
  height: 100%;

  &:before {
    content: "";
    display: block;
    position: relative;
    left: 3px;
    width: 2px;
    height: 100%;
    ${p => p.resizing && css`
      background: ${p.theme.colors.sidebar.resize};
    `};
  }

  &:hover {
    &:before {
      background: ${p => p.theme.colors.sidebar.resize};
    }

    ${ToggleSidebarButton} {
      opacity: ${p => (p.resizing ? 0 : 1)};
    }
  }
`;

export const Container = styled.div<{
  $withBorder?: boolean;
}>`
  overflow: visible;
  z-index: ${zIndexSidebar};
  position: relative;

  ${p => p.$withBorder && `
    border-right: 1px solid ${p.theme.colors.sidebar.border};
  `};

  ${mobile} {
    z-index: ${zIndexSidebar};
    height: 100%;
    width: 100%;
    border: 0;
  }
`;

export const SearchButton = styled.button<{ $isCompact: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 28px;
  padding: 0 8px;
  border-radius: 6px;
  margin: 16px 0 8px;
  cursor: pointer;
  text-align: left;
  ${typo.body500}
  color: ${p => p.theme.colors.text.disabled};
  background: ${p => p.theme.colors.background.secondaryElevated};
  :hover {
    background: ${p => p.theme.colors.background.tertiary};
  }
  ${p => p.$isCompact && css`
    justify-content: center;
    height: 32px;
    width: 32px;
    margin: 8px 0 0;
  `};
`;

export const UpgradeButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 22px;
  flex: none;
  width: auto;
  padding: 0 8px;
  border-radius: 4px;
  max-width: 100%;
  gap: 4px;
  margin-bottom: 8px;
  ${typo.caption600}
  ${truncate}

  background-color: ${p => p.theme.colors.components.FreePlan.default.cta.bg};
  color: ${p => p.theme.colors.components.FreePlan.default.cta.text};

  :hover, :focus {
    background-color: ${p => p.theme.colors.components.FreePlan.default.cta.bgHover};
  }

  :active {
    background-color: ${p => p.theme.colors.components.FreePlan.default.cta.bgActive};
  }
`;

export const FooterSection = styled.section`
  justify-content: space-between !important;
  flex-direction: row !important;
`;

export const AiQueriesTitle = styled(FreePlanTitle)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

export const CloseButton = styled(ActionButton)`
  width: 12px;
  height: 12px;
  padding: 2px;
  border-radius: 3px;
`;


interface StateProps {
  warning: boolean;
  danger: boolean;
}
