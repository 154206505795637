import {
  forwardRef, ComponentPropsWithRef, TextareaHTMLAttributes, ReactNode, useEffect, useRef, useImperativeHandle,
} from 'react';

import { StyledTextArea } from './TextArea.styles';
import { InfoIconOutline } from '../../../icons';
import { Tooltip, TooltipProps } from '../../Tooltip/Tooltip';
import { Container, Helper, Label, IconContainer } from '../Input/Input.styles';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, ComponentPropsWithRef<'textarea'> {
  label?: string | ReactNode;
  tooltipLabel?: string;
  tooltipProps?: Omit<TooltipProps, 'content'>;
  tooltipIcon?: ReactNode;
  error?: string;
  helperSize?: 'S' | 'M';
  autoResize?: boolean;
  minHeight?: number;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    className,
    label,
    tooltipLabel,
    tooltipProps = {},
    tooltipIcon = <InfoIconOutline />,
    error,
    helperSize,
    onInput,
    autoResize = false,
    minHeight = 0,
    ...textareaProps
  } = props;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => textAreaRef?.current as HTMLTextAreaElement);

  useEffect(() => {
    if (!autoResize) return;
    const el = textAreaRef?.current;
    if (!el) return;
    setHeight(el);
    el.focus();
    el.selectionStart = el.value.length;
  }, [autoResize]);

  return (
    <Container
      className={className}
      onKeyDown={e => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.code)) e.stopPropagation();
      }}
    >
      {label && (
        <Label
          {...textareaProps.id ? {
            as: 'label',
            htmlFor: textareaProps.id,
          } : {
            as: 'div',
          }}
        >
          {label}

          {tooltipLabel && (
            <Tooltip content={tooltipLabel} {...tooltipProps}>
              <IconContainer>
                {tooltipIcon}
              </IconContainer>
            </Tooltip>
          )}
        </Label>
      )}

      <StyledTextArea
        ref={ref ?? textAreaRef}
        {...textareaProps}
        $hasError={!!error}
        onInput={e => {
          if (autoResize && e.target instanceof HTMLTextAreaElement) {
            setHeight(e.target);
          }
          onInput?.(e);
        }}
        style={{ minHeight }}
      />

      {error && (
        <Helper role="alert" $hasError $size={helperSize}>{error}</Helper>
      )}
    </Container>
  );
});

const setHeight = (el: HTMLTextAreaElement) => {
  /* eslint-disable no-param-reassign */
  el.style.height = '5px'; // Hack to auto resize textarea
  el.style.height = `${el.scrollHeight + 5}px`;
  /* eslint-enable no-param-reassign */
};
