import { SectionType } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useEffect, useMemo } from 'react';

import { getLastInsightBoard, setLastInsightBoard } from 'src/reactives/lastView.reactive';
import { getBoardSlug } from 'src/utils/slug.util';

import { useProduct } from '../api/useProduct';

export const useInsightSection = () => {
  const {
    product,
    loading,
  } = useProduct();

  const sections = useMemo(() => {
    const section = product?.boardSections.edges.find(e => e.node.type === SectionType.Insights)?.node;
    const boards = nodeToArray(section?.boards);
    return {
      isLoading: loading,
      section,
      boards,
    };
  }, [loading, product]);

  useEffect(() => {
    const lastBoardId = getLastInsightBoard().boardId;
    if (sections.boards.some(board => board.id === lastBoardId)) return;
    // Should return all board
    const board = sections.boards[0];
    if (!board) return;
    setLastInsightBoard({
      boardId: board.id,
      boardSlug: getBoardSlug(board),
    });
  }, [sections.boards]);

  return sections;
};
