import { Button, Input, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

import { ToggleDropdown } from '../DropdownLayer';

export const AddButton = styled(Button)`
  margin-top: 8px;
  gap: 4px;
  font-weight: normal;
`;

export const StyledInput = styled(Input)`
  margin-top: 8px;
`;

export const StyledToggleDropdown = styled(ToggleDropdown)`
  border-radius: 12px;
`;

export const StyledActionButton = styled(ActionButton)`
  height: 24px;
  width: 24px;
`;
