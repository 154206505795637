import { CustomerFragment, Profile } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import {
  SlackIcon, IntercomIcon, CycleIcon, HubSpotIcon, ZendeskIcon, SalesforceIcon,
} from '@cycle-app/ui/icons';
import { FC } from 'react';

import { Container, Item } from './CustomerLinkedProfiles.styles';

interface Props {
  customer: CustomerFragment;
}

export const CustomerLinkedProfiles: FC<React.PropsWithChildren<Props>> = ({ customer }) => {
  return (
    <Container>
      {customer.profiles?.map(profile => profile && (
        <Item key={profile.__typename}>
          <Tooltip placement="top" content={getTitle(profile)} withPortal>
            {renderContent(profile)}
          </Tooltip>
        </Item>
      ))}
    </Container>
  );

  function renderContent(profile: Profile) {
    if (profile.__typename === 'ProfileCycle') {
      return renderIcon(profile);
    }
    if (profile.__typename === 'ProfileSlack' || profile.__typename === 'ProfileIntercom' || profile.__typename === 'ProfileHubspot' || profile.__typename === 'ProfileZendesk' || profile.__typename === 'ProfileSalesforce') {
      return <a target="_blank" rel="noreferrer" href={profile.url}>{renderIcon(profile)}</a>;
    }
    return null;
  }

  function getTitle(profile: Profile) {
    if (profile.__typename === 'ProfileCycle') {
      return 'Cycle';
    }
    if (profile.__typename === 'ProfileIntercom') {
      return 'Intercom';
    }
    if (profile.__typename === 'ProfileSlack') {
      return 'Slack';
    }
    if (profile.__typename === 'ProfileHubspot') {
      return 'Hubspot';
    }
    if (profile.__typename === 'ProfileZendesk') {
      return 'Zendesk';
    }
    if (profile.__typename === 'ProfileSalesforce') {
      return 'Salesforce';
    }
    return '';
  }

  function renderIcon(profile: Profile) {
    if (profile.__typename === 'ProfileCycle') {
      return <CycleIcon />;
    }
    if (profile.__typename === 'ProfileIntercom') {
      return <IntercomIcon />;
    }
    if (profile.__typename === 'ProfileSlack') {
      return <SlackIcon />;
    }
    if (profile.__typename === 'ProfileHubspot') {
      return <HubSpotIcon />;
    }
    if (profile.__typename === 'ProfileZendesk') {
      return <ZendeskIcon />;
    }
    if (profile.__typename === 'ProfileSalesforce') {
      return <SalesforceIcon />;
    }
    return null;
  }
};
