import { Skeleton, InfiniteScroll } from '@cycle-app/ui';

import { useCustomerDocs } from 'src/hooks/api/queries/customers/useCustomerDocs';
import { setCustomerStatusCategory, useCustomerStatusCategory } from 'src/reactives/customerCompanyProfile.reactive';
import { useFeedbackDocType } from 'src/reactives/docTypes.reactive';

import { Skeletons, DocsItems, DocItem, FeedbackDocsHeader } from './Profile.styles';
import { StatusCategorySelect } from './StatusCategorySelect';

type Props = {
  customerId: string;
};

export const FeedbackDocsCustomer = ({ customerId }: Props) => {
  const doctype = useFeedbackDocType();
  const statusCategory = useCustomerStatusCategory();

  const {
    docs,
    loadMore,
    pageInfo,
    isLoading,
    isLoaded,
    isPaginationLoading,
  } = useCustomerDocs({
    id: customerId,
    doctypeType: doctype?.type,
    statusCategory,
  }, !doctype);

  return (
    <>
      <FeedbackDocsHeader>
        <StatusCategorySelect
          value={statusCategory}
          onChange={setCustomerStatusCategory}
        />
      </FeedbackDocsHeader>

      <InfiniteScroll
        loadMore={() => {
          if (!pageInfo?.hasNextPage || !pageInfo?.endCursor) return;
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          loadMore(pageInfo.endCursor);
        }}
        hasMoreData={!!pageInfo?.hasNextPage}
        isLoading={isPaginationLoading}
      >
        <DocsItems>
          {docs.map(doc => (
            <DocItem
              key={doc.id}
              doc={doc}
              showStatus
              showQuotes
              isStatusEditable
            />
          ))}

          {((!isLoaded && isLoading) || isPaginationLoading) && (
            <Skeletons>
              <Skeleton height={16} />
              <Skeleton height={16} />
              <Skeleton height={16} />
              <Skeleton height={16} />
            </Skeletons>
          )}
        </DocsItems>
      </InfiniteScroll>
    </>
  );
};
