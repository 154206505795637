import { useSubscription } from '@apollo/client';
import { AiStreamDocument, AiStreamSubscription, AiStreamSubscriptionVariables } from '@cycle-app/graphql-codegen';

import { setEditorAi, getEditorAi, useEditorAiValue } from 'src/reactives';

import { useProduct } from './useProduct';

export const useAiStreamSubscription = () => {
  const { product } = useProduct('cache-only');
  const { visible } = useEditorAiValue();
  return useSubscription<AiStreamSubscription, AiStreamSubscriptionVariables>(AiStreamDocument, {
    variables: {
      productId: product?.id as string,
    },
    skip: !product?.id || !visible || false,
    onSubscriptionData({ subscriptionData: { data } }) {
      if (!data || !product) return;
      setEditorAi({ suggestion: `${getEditorAi().suggestion}${data.aiStream?.stream}` });
    },
  });
};
