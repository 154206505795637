import { Skeleton } from '@cycle-app/ui';

import { Skeletons } from './DocLinear.styles';

export const LinearSkeletons = ({ className } : { className?: string }) => (
  <Skeletons className={className}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Skeletons>
);
