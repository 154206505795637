import { useApolloClient, useQuery } from '@apollo/client';
import { LinearProjectIssuesDocument, LinearProjectIssuesQuery } from '@cycle-app/graphql-codegen';
import { isPresent } from 'ts-is-present';

export const useLinearProjectIssues = (projectId: string | undefined | null, integrationId: string) => {
  const query = useQuery(LinearProjectIssuesDocument, {
    fetchPolicy: 'cache-and-network',
    skip: !projectId,
    variables: {
      integrationId,
      projectId: projectId as string,
    },
  });

  return {
    issues: getIssues(query.data),
    isLoading: query.loading,
  };
};

const getIssues = (data?: LinearProjectIssuesQuery) => {
  if (data?.node?.__typename !== 'Integration') return null;
  if (data.node.provider?.__typename !== 'Linear') return null;
  return data.node.provider.projects[0]?.issues.filter(isPresent) ?? null;
};

export const usePrefetchLinearProjectIssues = () => {
  const client = useApolloClient();
  return (projectId: string, integrationId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.query({
      query: LinearProjectIssuesDocument,
      variables: {
        integrationId,
        projectId,
      },
    });
  };
};
