import styled from 'styled-components';

import { Cover } from '../Cards/ViewCard/ViewCard.styles';
import { MovingBorderLoader } from '../MovingBorderLoader/MovingBorderLoader';

export const Menu = styled.div`
  position: absolute;
  display: flex;
  top: 8px;
  right: 8px;
  border-radius: 6px;
  background: rgba(34, 34, 34, 0.8);
  opacity: 0;
  padding: 4px;
  gap: 4px;

  button {
    width: 20px;
    height: 20px;
  }

  > svg {
    stroke: white;
  }
`;

export const CoverContainer = styled.div`
  position: relatove;

  &:hover ${Menu} {
    opacity: 1;
  }
`;

export const StyledCover = styled(Cover)`
  border-radius: 8px;
  margin-top: 8px;
`;

export const CoverLoader = styled(MovingBorderLoader)`
  position: absolute;
  z-index: 10;
  top: -1px;
  left: -1px;
`;
