import { times } from 'ramda';
import { FC } from 'react';

import { Container, Line } from './EditorSkeleton.styles';

interface Props {
  className?: string;
  nbLines?: number;
}
const EditorSkeleton: FC<Props> = ({
  nbLines = 8,
  ...otherProps
}) => (
  <Container {...otherProps}>
    {times((i) => (<Line key={`line-${i}`} />), nbLines)}
  </Container>
);

export default EditorSkeleton;
