import styled, { css } from 'styled-components';

type DragHandleProps = {
  $forceFocus: boolean;
};

export const DragHandle = styled.span<DragHandleProps>`
  display: none;
  position: absolute;
  top: 0;
  left: 8px;
  width: 22px;
  height: 22px;
  padding: 0;
  cursor: pointer;
  color: ${p => p.theme.colors.text.secondary};
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  ${p => p.$forceFocus && css`
    background-color: ${p.theme.colors.button.light.bg};
  `}

  &:hover {
    background-color: ${p => p.theme.colors.button.light.bg};
    border-radius: 5px;
  }
  &:active {
    background-color: ${p => p.theme.colors.button.light.bgHover};
  }
`;

type DragSectionProps = {
  $forceFocus: boolean;
};

export const DragSection = styled.div<DragSectionProps>`
  position: absolute;
  top: 0;
  left: -38px;
  width: 38px;
  height: 100%;

  ${p => p.$forceFocus && css`
    ${DragHandle} {
      display: flex;
    }
  `}

  &:hover {
    ${DragHandle} {
      display: flex;
    }
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  width: 120px;

  span {
    &:nth-child(1) {
      color: ${p => p.theme.colors.tooltip.color};
    }
    &:nth-child(2) {
      color:${p => p.theme.colors.tooltip.textAlt};
    }
  }
`;
