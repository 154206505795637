import { CustomerFragment, DocBaseFragment } from '@cycle-app/graphql-codegen';

type GetDefaultInsightTitleParams = {
  customerName?: CustomerFragment['name'];
  docTitle?: DocBaseFragment['title'];
};

export const getDefaultInsightTitle = ({
  customerName,
  docTitle,
}: GetDefaultInsightTitleParams) => {
  const name = customerName
    ? ` from ${customerName}`
    : '';
  const title = docTitle
    ? ` on ${docTitle}`
    : '';

  return `Insight${name}${title}`;
};
