import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { DotsMenuProps, SelectOption, Spinner } from '@cycle-app/ui';
import {
  CancelCircleIcon, CheckCircleIcon, PenIcon, TrashIcon, EyeClosedIcon, CalendarEditIcon, LinkArrowIcon, ShareIcon,
} from '@cycle-app/ui/icons';
import { useMemo } from 'react';

import { ReleasePublishModal, ReleaseRemoveModal, ReleaseUnpublishModal } from 'src/components/ReleaseModals';
import { useReleasesContext } from 'src/contexts/releasesContext';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openUpdateRelease, openRemoveRelease, openPublishRelease, openUnpublishRelease, openShareRelease } from 'src/reactives/releases.reactive';

import { StyledDotsMenu } from './ReleaseActionsMenu.styles';
import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { changelogUrl } from '../../utils/changelog.utils';

type Props = Omit<DotsMenuProps, 'options'> & {
  publicStatus: ReleasePublicStatus;
  releaseId: string;
  publicId: string | null;
};

export const ReleaseActionsMenu = ({
  releaseId, publicId, publicStatus,
}: Props) => {
  const {
    editPublished, publish, unpublish, discardChanges, isPublishLoading, isUnpublishLoading,
  } = useReleasePublicStatusUpdate(releaseId);
  const {
    canUpdateRelease, canDeleteRelease,
  } = useGetPermission();
  const isPublished = publicStatus === ReleasePublicStatus.Published;

  const { changelog } = useChangelog();
  const { publishedCount } = useReleasesContext();
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);
  const isChangelogPublished = changelog?.isPublished;
  const url = `${changelogUrl(productSlug, changelog?.domain)}/${publicId ? `release-${publicId}` : ''}`;

  const options = useMemo(() => {
    const result: SelectOption[] = [
      {
        icon: <ShareIcon />,
        label: 'Share the news',
        value: 'share',
        onSelect: () => openShareRelease(releaseId),
        ...!isPublished && {
          disabled: true,
          tooltipContent: 'Publish the changelog and the release first',
        },
      },
    ];


    if (publicStatus === ReleasePublicStatus.Unpublished) {
      const shouldPromptPublishChangelog = publishedCount === 0 && !isChangelogPublished;
      result.push({
        icon: isPublishLoading ? <Spinner /> : <CheckCircleIcon />,
        label: 'Publish release',
        // Keep the menu menu opened while the mutation is loading, unless the publish modal is opened.
        keepDropdownOnSelect: !shouldPromptPublishChangelog,
        value: 'publish',
        onSelect: async () => {
          if (shouldPromptPublishChangelog) {
            openPublishRelease(releaseId)();
            return null;
          }
          return publish();
        },
      });
    }

    if (isPublished) {
      const shouldPromptUnpublishChangelog = publishedCount === 1;
      result.push({
        icon: <PenIcon />,
        label: 'Make updates',
        value: 'update',
        onSelect: editPublished,
      });
      result.push({
        icon: isUnpublishLoading ? <Spinner /> : <EyeClosedIcon />,
        // Keep the menu menu opened while the mutation is loading, unless the unpublish modal is opened.
        keepDropdownOnSelect: !shouldPromptUnpublishChangelog,
        label: 'Unpublish',
        value: 'unpublish',
        onSelect: async () => {
          if (shouldPromptUnpublishChangelog) {
            openUnpublishRelease(releaseId)();
            return null;
          }
          return unpublish();
        },
      });
    }

    if (publicStatus === ReleasePublicStatus.Editing) {
      result.push({
        icon: <CheckCircleIcon />,
        label: 'Publish changes',
        value: 'publish',
        onSelect: publish,
      });
      result.push({
        icon: <CancelCircleIcon />,
        label: 'Discard changes',
        value: 'discard',
        onSelect: discardChanges,
      });
      result.push({
        icon: <EyeClosedIcon />,
        label: 'Unpublish',
        value: 'unpublish',
        onSelect: unpublish,
      });
    }

    return [
      ...result,
      {
        disabled: isPublished,
        icon: <CalendarEditIcon width={14} style={{ marginLeft: '2px' }} />,
        label: 'Edit release info',
        value: 'edit-infos',
        onSelect: canUpdateRelease ? openUpdateRelease(releaseId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
        ...isPublished && {
          tooltipContent: 'Make updates or unpublish first',
        },
      },
      {
        value: 'open',
        label: 'Open in Changelog',
        icon: <LinkArrowIcon size={16} />,
        ...(!isPublished || !isChangelogPublished) && {
          disabled: true,
          tooltipContent: 'Publish the changelog and the release first',
        },
        onSelect: () => {
          window.open(url, '_blank');
        },
      }, {
        disabled: isPublished,
        icon: <TrashIcon />,
        label: 'Delete',
        value: 'delete',
        onSelect: canDeleteRelease ? openRemoveRelease(releaseId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
        ...isPublished && {
          tooltipContent: 'Unpublish first',
        },
      },
    ];
  }, [
    canDeleteRelease,
    canUpdateRelease,
    discardChanges,
    editPublished,
    isChangelogPublished,
    isPublished,
    isPublishLoading,
    isUnpublishLoading,
    publicStatus,
    publish,
    publishedCount,
    releaseId,
    unpublish,
    url,
  ]);

  return (
    <>
      <StyledDotsMenu
        placement="right-start"
        options={options}
        width={170}
      />
      <ReleaseRemoveModal releaseId={releaseId} />
      <ReleasePublishModal releaseId={releaseId} />
      <ReleaseUnpublishModal releaseId={releaseId} />
    </>
  );
};
