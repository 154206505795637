import { ContentSection } from '@cycle-app/graphql-codegen';
import styled from 'styled-components';

interface RootProps {
  $contentSection: ContentSection;
}

export const Root = styled.div<RootProps>`
  margin-top: 40px;

  h4 {
    margin-top: 32px;
  }

  p {
    margin-top: 16px;

    ${p => p.$contentSection === ContentSection.Discreet && `
      font-size: 16px;
    `}
    ${p => p.$contentSection === ContentSection.Uniform && `
      font-size: 18px;
      line-height: 24px;
    `}
    ${p => p.$contentSection === ContentSection.Classic && `
      font-size: 16px;
    `}
    ${p => p.$contentSection === ContentSection.Sober && `
      font-size: 18px;
      line-height: 24px;
    `}
    ${p => p.$contentSection === ContentSection.ShowOff && `
      font-size: 22px;
      line-height: 32px;
    `}
    ${p => p.$contentSection === ContentSection.Unique && `
      font-size: 16px;
    `}
  }
`;

export const Cover = styled.div`
  background-image: url("/images/changelog-cover-preview.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  display: flex;
  height: 360px;
  justify-content: center;
  overflow: hidden;
`;
