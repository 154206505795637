import { BoardConfigSortByFragment, PropertiesFragment } from '@cycle-app/graphql-codegen';
import { AddIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useBoardConfigSortBy } from 'src/hooks/boards/useBoardConfigSortBy';

import { FiltersList, LightButton } from './BoardConfigForm.styles';
import { SortBy } from '../FilterRules/Filter/SortBy';

interface Props {
  boardConfigId: string;
  sortByProperty: BoardConfigSortByFragment['sortByProperty'];
  sortableProperties: BoardConfigSortByFragment['sortableProperties'];
  filterableProperties: PropertiesFragment;
  isFeedbackView?: boolean;
}

export const BoardConfigFormSortBy: FC<React.PropsWithChildren<Props>> = ({
  boardConfigId, sortByProperty, sortableProperties, filterableProperties, isFeedbackView,
}) => {
  const {
    sortByAddFirstAvailable, sortBy,
  } = useBoardConfigSortBy({
    boardConfigId,
    sortByProperty,
    sortableProperties,
    filterableProperties,
    isFeedbackView,
  });
  return (
    <>
      {sortBy && (
        <FiltersList>
          <SortBy
            boardConfigId={boardConfigId}
            sortByProperty={sortByProperty}
            sortableProperties={sortableProperties}
            filterableProperties={filterableProperties}
            isFeedbackView={isFeedbackView}
          />
        </FiltersList>
      )}
      {!sortBy && (
        <LightButton
          onClick={sortByAddFirstAvailable}
          iconStart={<AddIcon size={11} />}
        >
          Add sort
        </LightButton>
      )}
    </>
  );
};
