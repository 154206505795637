// WaveSurfer peaks data structure:
// [[0, 0.2, 0.9, 0.3], // left channel
//  [0, 0.1, 0.8, 0.4]] // right channel
type Peaks = [number[], number[]] | undefined;

/**
 * Extract WaveSurfer peaks from a waveform image
 */
export const getWaveSurferPeaks = async (waveformUrl: string) => new Promise<Peaks>(resolve => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return resolve(undefined);

  const image = new Image();
  image.crossOrigin = 'Anonymous';

  try {
    // Fetch the waveform image
    image.onload = () => {
      // Get the image raw data
      context.canvas.width = image.width;
      context.canvas.height = image.height;
      context.drawImage(image, 0, 0);
      const imageData = context.getImageData(0, 0, image.width, image.height).data;
  
      // Initialize peaks
      const peaks: Peaks = [
        new Array(image.width).fill(0),
        new Array(image.width).fill(0),
      ];
  
      const maxPeak = image.height / 2;
  
      // Loop on x-axis
      for (let i = 0; i < image.width; i += 1) {
        // Find the left channel peak
        for (let j = 0; j < maxPeak; j += 1) {
          const value = imageData[4 * (j * image.width + i)];
          if (value === 0) {
            peaks[0][i] = j / maxPeak - 1;
            break;
          }
        }
  
        // Find the right channel peak
        for (let j = image.height - 1; j > maxPeak; j -= 1) {
          const value = imageData[4 * (j * image.width + i)];
          if (value === 0) {
            peaks[1][i] = j / maxPeak - 1;
            break;
          }
        }
      }
  
      resolve(peaks);
    };
    image.src = waveformUrl;
  } catch (error) {
    console.log('Failed to extract WaveSurfer peaks', error);
    resolve(undefined);
  } finally {
    canvas.remove();
    image.remove();
  }
});
