import Image from '@tiptap/extension-image';

export const PARAGRAPH_TYPE = 'paragraph';
export const BULLET_LIST_TYPE = 'bulletList';
export const CHECK_LIST_TYPE = 'taskList';
export const ORDERED_LIST_TYPE = 'orderedList';
export const LIST_ITEM_TYPE = 'listItem';
export const BLOCKQUOTE = 'blockquote';
export const HEADING_TYPE = 'heading';
export const HORIZONTAL_RULE = 'horizontalRule';

export const DRAG_HANDLE_CLASS = 'drag-icon';

export const PARAGRAPH_CLASS = 'editor-paragraph';
export const HEADING_CLASS = 'editor-heading';

export const IMAGE_EXTENSION_NAME = Image.name;

export const HIGHLIGHT_DATA_ID = 'data-mark-id';
export const HIGHLIGHT_DATA_TYPE = 'data-mark-type';
export const HIGHLIGHT_TAG_NAME = 'mark';
// tiptap extension
export const HIGHLIGHT_EXTENSION_NAME = 'highlightMark';
// prosemirror view plugin
export const HIGHLIGHT_VIEW_PLUGIN_NAME = 'highlightView';

export const NODE_TABLE_OF_CONTENT_NAME = 'tableOfContentNode';

export const FILE_EXTENSION_NAME = 'file';

export const LINEAR_EXTENSION_NAME = 'linear-mention';
export const LINEAR_EXTENSION_PROJECT_NAME = 'linear-mention-project';
export const LINEAR_EXTENSION_TAGNAME = 'linear-tagname';

export const GITHUB_EXTENSION_NAME = 'github-issue';
export const GITHUB_ISSUE_EXTENSION_TAGNAME = 'github-issue-tagname';

export const IFRAMELY_EXTENSION_NAME = 'iframely';
export const IFRAMELY_EXTENSION_TAG_NAME = 'iframely-tag';

export const MENTION_EXTENSION_NAME = 'mention';
export const MENTION_EXTENSION_TAGNAME = 'cycle-editor-mention';

export const MENTION_DOC_EXTENSION_NAME = 'mention-docs';
export const MENTION_DOC_EXTENSION_TAGNAME = 'cycle-editor-mention-doc';

export const NOTION_EXTENSION_NAME = 'notion-mention';
export const NOTION_EXTENSION_TAGNAME = 'notion-tagname';

export const DETAILS_EXTENSION_CLASS_NAME = 'details';

export const VIDEO_EXTENSION_NAME = 'video';
export const VIDEO_EXTENSION_TAGNAME = 'video-tagname';

export const AUDIO_EXTENSION_NAME = 'audio';
export const AUDIO_EXTENSION_TAGNAME = 'audio-tagname';

export const EMOJI_EXTENSION_NAME = 'emoji';

// eslint-disable-next-line max-len
export const DRAG_ICON_SVG = '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 13C10.2652 13 10.5196 12.8946 10.7071 12.7071C10.8946 12.5196 11 12.2652 11 12C11 11.7348 10.8946 11.4804 10.7071 11.2929C10.5196 11.1054 10.2652 11 10 11C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13ZM6 13C6.26522 13 6.51957 12.8946 6.70711 12.7071C6.89464 12.5196 7 12.2652 7 12C7 11.7348 6.89464 11.4804 6.70711 11.2929C6.51957 11.1054 6.26522 11 6 11C5.73478 11 5.48043 11.1054 5.29289 11.2929C5.10536 11.4804 5 11.7348 5 12C5 12.2652 5.10536 12.5196 5.29289 12.7071C5.48043 12.8946 5.73478 13 6 13ZM7 8C7 8.26522 6.89464 8.51957 6.70711 8.70711C6.51957 8.89464 6.26522 9 6 9C5.73478 9 5.48043 8.89464 5.29289 8.70711C5.10536 8.51957 5 8.26522 5 8C5 7.73478 5.10536 7.48043 5.29289 7.29289C5.48043 7.10536 5.73478 7 6 7C6.26522 7 6.51957 7.10536 6.70711 7.29289C6.89464 7.48043 7 7.73478 7 8ZM10 9C10.2652 9 10.5196 8.89464 10.7071 8.70711C10.8946 8.51957 11 8.26522 11 8C11 7.73478 10.8946 7.48043 10.7071 7.29289C10.5196 7.10536 10.2652 7 10 7C9.73478 7 9.48043 7.10536 9.29289 7.29289C9.10536 7.48043 9 7.73478 9 8C9 8.26522 9.10536 8.51957 9.29289 8.70711C9.48043 8.89464 9.73478 9 10 9ZM11 4C11 4.26522 10.8946 4.51957 10.7071 4.70711C10.5196 4.89464 10.2652 5 10 5C9.73478 5 9.48043 4.89464 9.29289 4.70711C9.10536 4.51957 9 4.26522 9 4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3C10.2652 3 10.5196 3.10536 10.7071 3.29289C10.8946 3.48043 11 3.73478 11 4ZM6 5C6.26522 5 6.51957 4.89464 6.70711 4.70711C6.89464 4.51957 7 4.26522 7 4C7 3.73478 6.89464 3.48043 6.70711 3.29289C6.51957 3.10536 6.26522 3 6 3C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4C5 4.26522 5.10536 4.51957 5.29289 4.70711C5.48043 4.89464 5.73478 5 6 5Z" fill="currentColor" /></svg>';
