import { typo } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import { mix } from 'polished';
import styled from 'styled-components';

import { loadingBackgroundCss } from 'src/components/QuotesSummary/QuotesSummary.styles';

export const Container = styled.button<{
  $isLoading?: boolean;
  $isDisabled?: boolean;
}>`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: inherit; 
  height: 40px;
  border-radius: 29px;
  --bg: ${ColorTheme.Cycle};
  color: ${ColorTheme.White};
  background: var(--bg);
  box-shadow: 0px 6px 22px 0px #0000001A;
  padding: 0;
  cursor: pointer;
  :hover {
    background: ${ColorTheme.Blue600};
  }
  :active {
    background: ${ColorTheme.Blue700};
  }
  :disabled {
    cursor: default;
    pointer-events: none;
    filter: grayscale(1);
    color: ${p => p.theme.colors.text.disabled};
  }
  ${p => p.$isLoading && `
    cursor: default;
    ${loadingBackgroundCss}
  `}
  ${p => p.$isDisabled && `
    cursor: default;  
    &, :hover, :active {
      background: ${mix(0.5, p.theme.isDark ? ColorTheme.Grey900 : ColorTheme.White, ColorTheme.Cycle)};
    }
  `}
`;

export const Content = styled.div`
  inset: 0;
  height: 100%;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${typo.body500}
`;

export const RetryButton = styled.div`
  color: ${p => (p.theme.isDark ? 'hsl(var(--cycle400))' : 'hsl(var(--cycle))')};
  filter: none;
  cursor: pointer;
`;
