import { motion } from 'framer-motion';
import styled from 'styled-components';

import { caption400, body600, caption600, caption500 } from '../../theme/typo';
import { boxShadowZ4, boxShadowZ5 } from '../../utils/styles.util';

export const Toast = styled(motion.div)`
  width: 285px;
  background-color: ${p => p.theme.colors.toaster.bg};
  color: ${p => p.theme.colors.text.white};
  border-radius: 8px;
  padding: 8px 12px 10px;
  ${boxShadowZ4};
`;

export const SimpleToast = styled(Toast)`
  display: flex;
  align-items: center;
  gap: 4px;
  width: auto;
  padding: 6px 8px;
  ${caption600};
  ${boxShadowZ5};

  a {
    text-decoration: underline;
  }
`;

export const Content = styled.div``;

export const Title = styled.div`
  margin-bottom: 2px;
  ${body600};
`;

export const Message = styled.div`
  ${caption400};
`;

export const Actions = styled.div<{ $nbActions: number }>`
  display: flex;
  gap: 12px;
  margin-top: 12px;
`;

interface ActionButtonProps {
  $strong?: boolean;
  $loading?: boolean;
}
export const ActionBtn = styled.button<ActionButtonProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 11px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.toaster.buttons.bg};

  white-space: nowrap;
  ${caption500};
  cursor: pointer;
  pointer-events: ${p => (p.$loading ? 'none' : 'initial')};

  &:hover {
    background-color: ${p => p.theme.colors.toaster.buttons.bgHover};
  }

  &:active {
    background-color: ${p => p.theme.colors.toaster.buttons.bgActive};
  }
`;
