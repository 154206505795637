import { transitionCss } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Item = styled.div<{
  disabled?: boolean;
  $collapsed: boolean;
  $active: boolean;
  $isMobile: boolean;
}>`
  ${transitionCss()};
  color: ${p => p.theme.colors.sidebar.header.item.color};
  background-color: ${p => (p.$active
    ? p.theme.colors.sidebar.header.item.bgHover
    : p.theme.colors.sidebar.header.item.bg)};
  min-width: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 4px;
  width: 28px;
  height: 28px;

  ${p => !p.$isMobile && `
    margin-left: 4px;
  `}

  ${p => !p.disabled && css`
    cursor: pointer;

    &.force-focus, &:hover {
      background-color: ${p.theme.colors.sidebar.header.item.bgHover};
      color: ${p.theme.colors.sidebar.header.item.colorHover};
    }
  `};
`;

export const TooltipContent = styled.div`
  display: flex;
  gap: 8px;
`;
