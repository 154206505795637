import { useQuery } from '@apollo/client';
import { FetchGithubIssueByIdDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';

export const useGithubIssue = (issueId?: string, projectId?: string) => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Github);
  const isActive = !!integration?.provider;
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery(FetchGithubIssueByIdDocument, {
    skip: !issueId || !isActive || !integration?.id,
    variables: {
      integrationId: integration?.id ?? '',
      issueId: issueId ?? '',
      projectId,
    },
  });

  const githubIssue = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Github'
      ) return null;

      return data.node.provider.issues[0] || null;
    },
    [data],
  );

  return {
    isActive,
    isLoading,
    issue: githubIssue,
    refetch,
  };
};
