import { UpdateDocAutomationDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateDocAutomation = () => {
  const [mutate] = useSafeMutation(UpdateDocAutomationDocument);

  const updateDocAutomation = (docId: string, link: string | null, id: string | null) => mutate({
    variables: {
      docId,
      link,
      id,
    },
    optimisticResponse: {
      updateDocAutomation: {
        id: docId,
        automationId: id,
        automationUrl: link,
      },
    },
  });

  return {
    updateDocAutomation,
  };
};
