import { DoctypeTemplateCategory } from '@cycle-app/graphql-codegen';
import { PenIcon, TrashIcon } from '@cycle-app/ui/icons';
import { useMemo, FC, useCallback } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { Editor } from 'src/components/Editor';
import { ReadOnlyEditor } from 'src/components/Editor/Editors/ReadOnlyEditor';
import { TemplateCategoriesLabel } from 'src/constants/templates.constants';
import { useUpdateTemplate, useChangeDoctypeTemplate } from 'src/hooks/api/mutations/templatesHooks';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useTemplate } from 'src/hooks/api/useTemplates';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setTemplate, getTemplate } from 'src/reactives/template.reactive';
import { templateDisabledActions } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';
import { getParsedJSON } from 'src/utils/json.util';

import { EditorContainer2 } from '../TemplateModal.styles';
import {
  CancelButton,
  Content,
  EditButton,
  Header,
  RemoveButton,
  SaveButton,
  Title,
  TitleInput,
  CategoryButton,
} from './TemplateEditor.styles';
import useEditTemplate from './useEditTemplate';

interface Props {
  className?: string;
  templateId: string;
  onTemplateUpdated: VoidFunction;
  onTemplateRemoved: VoidFunction;
  modeAdmin?: boolean;
}

const ExistingTemplateEditor: FC<Props> = ({
  className,
  templateId,
  onTemplateUpdated,
  onTemplateRemoved,
  modeAdmin = false,
}) => {
  const doctype = useDoctype();
  const template = useTemplate(templateId);

  const {
    update,
    loading,
  } = useUpdateTemplate();
  const {
    changeDoctypeTemplate, loading: loadingChangeTemplate,
  } = useChangeDoctypeTemplate(doctype);

  const [isEditing, {
    setTrueCallback: enableEditing,
    setFalseCallback: disableEditing,
  }] = useOptimizedBooleanState(modeAdmin);

  const {
    editingTemplate,
    setTitle,
    setContent,
    setContentJSON,
    setCategory,
  } = useEditTemplate({
    template,
    modeAdmin,
  });
  const [isEditionConfirmationModalDisplayed, {
    setTrueCallback: showEditionConfirmationModal,
    setFalseCallback: hideEditionConfirmationModal,
  }] = useOptimizedBooleanState(false);
  const [isDeleteConfirmationModalDisplayed, {
    setTrueCallback: showDeleteConfirmationModal,
    setFalseCallback: hideDeleteConfirmationModal,
  }] = useOptimizedBooleanState(false);

  const onCancelEdition = useCallback(() => {
    if (template) {
      setTitle(template.title);
      setContent(template.content);
      setContentJSON(template.contentJSON);
    }
    if (getTemplate().forceCloseOnUpdate) {
      setTemplate({
        modalVisible: false,
        forceCloseOnUpdate: false,
      });
    } else if (modeAdmin) {
      setTemplate({ mode: 'list' });
    } else {
      disableEditing();
    }
  }, [template, disableEditing, setTitle, setContent, setContentJSON, modeAdmin]);
  const onCancelClicked = useCallback(() => {
    if (editingTemplate.content !== template?.content) {
      showEditionConfirmationModal();
    } else {
      onCancelEdition();
    }
  }, [editingTemplate.content, template?.content, showEditionConfirmationModal, onCancelEdition]);
  const onRemoveTemplate = useCallback(async () => {
    const { docTypeId } = getTemplate();
    if (docTypeId) {
      await changeDoctypeTemplate({
        doctypeId: docTypeId,
        doctypeTemplateId: null,
      });
      onTemplateRemoved();
      hideDeleteConfirmationModal();
    }
  }, [changeDoctypeTemplate, onTemplateRemoved, hideDeleteConfirmationModal]);

  const templateContent = useMemo(
    () => (editingTemplate.contentJSON
      ? getParsedJSON(editingTemplate.contentJSON)
      : undefined),
    [editingTemplate.contentJSON],
  );

  const categories = Object.values(DoctypeTemplateCategory) as DoctypeTemplateCategory[];
  const catOptions = categories.map(c => ({
    label: TemplateCategoriesLabel[c],
    value: c,
  }));

  return (
    <Content className={className}>
      <Header>
        {isEditing
          ? (
            <>
              {modeAdmin && editingTemplate.category && (
                <DropdownSelectLayer
                  layer={Layer.DropdownModalZ3}
                  options={catOptions}
                  selectedValue={editingTemplate.category}
                  placement="bottom-start"
                  onChange={({ value }) => {
                    setCategory(value);
                  }}
                >
                  <CategoryButton size="L">
                    <PenIcon />
                    {TemplateCategoriesLabel[editingTemplate.category]}
                  </CategoryButton>
                </DropdownSelectLayer>
              )}
              <TitleInput
                placeholder="My new doc template"
                value={editingTemplate.title}
                onChange={setTitle}
              />
              <CancelButton
                size="M"
                variant="secondary"
                onClick={onCancelClicked}
              >
                Cancel
              </CancelButton>
              <SaveButton
                size="M"
                disabled={!editingTemplate.title}
                onClick={save}
                isLoading={loading}
              >
                Save
              </SaveButton>
            </>
          )
          : (
            <>
              <Title>{editingTemplate.title}</Title>
              <EditButton
                size={14}
                onClick={enableEditing}
              >
                <PenIcon />
                Edit
              </EditButton>
              {!modeAdmin && (
                <RemoveButton
                  size={14}
                  onClick={showDeleteConfirmationModal}
                >
                  <TrashIcon />
                  Remove
                </RemoveButton>
              )}
            </>
          )}
      </Header>
      {editingTemplate.contentJSON && (
        <EditorContainer2>
          {isEditing
            ? (
              <Editor
                content={templateContent ?? undefined}
                autoFocus
                autoFocusStart
                onUpdate={({
                  html,
                  json,
                }) => {
                  setContent(html);
                  setContentJSON(json);
                }}
                disabledActions={templateDisabledActions}
              />
            )
            : <ReadOnlyEditor content={templateContent ?? undefined} hasWiderWidth />}
        </EditorContainer2>
      )}

      {isEditionConfirmationModalDisplayed && (
        <DialogModal
          title="Are you sure you want to quit without saving ?"
          cancelLabel="Keep editing"
          confirmLabel="Leave"
          hide={hideEditionConfirmationModal}
          onCancel={hideEditionConfirmationModal}
          onConfirm={onCancelEdition}
        />
      )}
      {isDeleteConfirmationModalDisplayed && (
        <DialogModal
          title="Remove content template"
          info="Are you sure you want to remove this content template ?"
          cancelLabel="Cancel"
          confirmLabel="Remove"
          hide={hideDeleteConfirmationModal}
          onCancel={hideDeleteConfirmationModal}
          onConfirm={onRemoveTemplate}
          autoHide={false}
          loading={loadingChangeTemplate}
        />
      )}
    </Content>
  );

  async function save() {
    if (!template?.id || !editingTemplate.contentJSON) return;

    await update({
      doctypeTemplateId: template.id,
      title: editingTemplate.title,
      content: editingTemplate.content,
      contentJSON: editingTemplate.contentJSON,
      category: modeAdmin ? editingTemplate.category : undefined,
    });
    onTemplateUpdated();
  }
};

export default ExistingTemplateEditor;
