import { DoctypeType } from '@cycle-app/graphql-codegen';

import { useSortedDocTypesFromCache } from 'src/hooks/useSortedDocTypes';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getDocFromCache } from 'src/utils/cache.utils';

export const useSortedCustomDocTypeChildren = (docTypeId?: string) => {
  const docType = useGetDocType(docTypeId);
  const ids = docType?.children?.edges
    .filter(e => e.node.type === DoctypeType.Custom)
    .map(d => d.node.id);
  return useSortedDocTypesFromCache(ids);
};

export const useLastCustomDocTypeChild = (docId?: string) => {
  const docTypeParent = docId ? getDocFromCache(docId)?.doctype : undefined;
  const docTypes = useSortedCustomDocTypeChildren(docTypeParent?.id);
  return docTypes.length === 0 ? null : docTypes[docTypes.length - 1];
};
