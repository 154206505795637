import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';
import { FC } from 'react';

import { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { InsightCreateForm } from 'src/components/InsightCreateForm';
import { useMe, useProduct } from 'src/hooks';
import { useDocCommonInsightProperties } from 'src/hooks/doc/useDocCommonProperties';
import { Layer } from 'src/types/layers.types';

import { Loader, StyledDropdownLayer } from './InsightCreateModal.styles';

export type InsightCreateModalProps = ContentProps & {
  dropdownLayer?: Layer;
  dropdownProps?: Partial<DropdownLayerProps>;
  isLoading?: boolean;
  isOpen: boolean;
};

export const InsightCreateModal: FC<React.PropsWithChildren<InsightCreateModalProps>> = ({
  children,
  dropdownLayer = Layer.DropdownModalZ2,
  dropdownProps,
  hide,
  isLoading,
  isOpen,
  ...props
}) => {
  return (
    <StyledDropdownLayer
      visible={isOpen}
      layer={dropdownLayer}
      hide={hide}
      placement="bottom-end"
      content={isOpen && (isLoading ? (
        <Loader>
          <Skeleton height={24} />
          <Skeleton height={24} />
        </Loader>
      ) : (
        <Content
          hide={hide}
          {...props}
        />
      ))}
      {...dropdownProps}
      popperOptions={{
        ...(dropdownProps?.popperOptions || {}),
        modifiers: [...(dropdownProps?.popperOptions?.modifiers || []), {
          name: 'flip',
          enabled: false,
        }, {
          name: 'offsetAdapter',
          enabled: true,
          phase: 'read',
          requires: ['popperOffsets'],
          options: {
            offset: 24,
          },
          fn: ({
            state, options,
          }) => {
            const { rects } = state;
            const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
            const overflowY = options.offset + rects.reference.y + rects.reference.height + rects.popper.height - viewportHeight;
            if (overflowY > 0 && state.modifiersData?.popperOffsets?.y) {
              // eslint-disable-next-line operator-assignment, no-param-reassign
              state.modifiersData.popperOffsets.y = Math.max(options.offset, state.modifiersData.popperOffsets.y - overflowY);
            }
            if (viewportHeight <= rects.popper.height) {
              state.elements.popper.setAttribute('data-scroll', 'true');
            }
          },
        }],
      }}
    >
      {children}
    </StyledDropdownLayer>
  );
};

type ContentProps = {
  defaultContent?: string;
  defaultCustomerId?: string;
  hide: VoidFunction;
  hideParentDoc?: boolean;
  isCustomerReadonly?: boolean;
  parentDoc?: DocBaseFragment | null;
};

const Content: FC<ContentProps> = ({
  defaultContent,
  defaultCustomerId,
  hide,
  hideParentDoc,
  isCustomerReadonly,
  parentDoc,
}) => {
  const { me } = useMe();
  const customPropertiesData = useDocCommonInsightProperties(parentDoc?.attributes || null);
  const { product } = useProduct();
  const customAttributesDefinitions = Object.keys(keyBy(nodeToArray(product?.companyAttributeDefinitions), 'id'));

  return (
    <InsightCreateForm
      hide={hide}
      onInsightCreated={hide}
      parentDoc={parentDoc}
      customCommonPropertiesData={customPropertiesData.common}
      customSpecificPropertiesData={customPropertiesData.specific.filter(x => !customAttributesDefinitions.includes(x.id))}
      defaultAssignee={me.id}
      defaultContent={defaultContent}
      defaultCustomerId={defaultCustomerId}
      hideParentDoc={hideParentDoc}
      isCustomerReadonly={isCustomerReadonly}
    />
  );
};