import { Button, Spinner, typo } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import ImageInput from 'src/components/ImageInput/ImageInput';

export const UploadButton = styled(Button)`
  --border: ${p => p.theme.colors.border.primary};
  height: 36px;
`;

export const LogoInput = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 36px;
`;

export const StyledImageInput = styled(ImageInput)`
  margin-right: auto;
`;

export const Domain = styled.span`
  margin-top: -2px;
`;

export const LogoInputPreview = styled.img`
  display: block;
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 4px;
`;

export const SpinnerContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background: ${p => p.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoSpinner = styled(Spinner)`
  width: 24px;
  height: 24px;
`;

export const Helper = styled.p`
  ${typo.caption};
  margin-top: 4px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledAiIcon = styled(AiIcon)`
  vertical-align: middle;
  color: ${p => p.theme.colors.text.disabled};
  margin-left: 4px;
  transition: color .2s;
  cursor: pointer;

  &:hover, &:focus {
    color: ${p => p.theme.colors.text.primary};
  }
`;