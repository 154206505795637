import { HIGHLIGHT_DATA_ID, HIGHLIGHT_EXTENSION_NAME, HIGHLIGHT_DATA_TYPE, MarkType, HIGHLIGHT_TAG_NAME } from '@cycle-app/editor-extensions';
import { Editor } from '@tiptap/core';
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { AddMarkStep, RemoveMarkStep } from '@tiptap/pm/transform';
import { EditorView } from '@tiptap/pm/view';
import tippy, { Instance } from 'tippy.js';

import { posToDOMRect } from 'src/utils/editor/editor.utils';
import { isElementOutside } from 'src/utils/elements.util';
import { logError } from 'src/utils/errors.utils';

import { getThreadsPanel } from '../reactives/comments.reactive';

export const INLINE_COMMENTS_PLUGIN_NAME = 'inlineComments';
const MARK_TYPE: MarkType = 'comment';

export interface InlineCommentsPluginProps {
  editor: Editor;
  element: HTMLElement;
  onOpen: (instance: Instance, props: {
    id: string;
    create?: boolean;
    isResolved?: boolean;
  }) => void;
  onMouseover: (props: { id: string }) => void;
  onMouseout: VoidFunction;
}

export const InlineCommentsPlugin = (options: InlineCommentsPluginProps) => new Plugin({
  props: {
    transformPasted(slice) {
      slice.content.nodesBetween(0, slice.content.size, node => {
        if (node.marks.find(mark => mark.type.name === HIGHLIGHT_EXTENSION_NAME)) {
          Object.assign(node, { marks: node.marks.filter(mark => mark.type.name !== HIGHLIGHT_EXTENSION_NAME) });
        }
      });
      return slice;
    },
  },
  key: new PluginKey(INLINE_COMMENTS_PLUGIN_NAME),
  view: view => new HighlightView({
    view,
    ...options,
  }),
});

type HighlightViewProps = InlineCommentsPluginProps & {
  view: EditorView;
};

class HighlightView {
  public editor: Editor;

  public element: HTMLElement;

  public view: EditorView;

  public tippy: Instance | undefined;

  public mouseOutSetTimeout = 0;

  public onOpen: InlineCommentsPluginProps['onOpen'];

  public onMouseover: InlineCommentsPluginProps['onMouseover'];

  public onMouseout: InlineCommentsPluginProps['onMouseout'];

  public isDestroyed: boolean;

  constructor({
    editor,
    element,
    onOpen,
    onMouseover,
    onMouseout,
    view,
  }: HighlightViewProps) {
    this.isDestroyed = false;
    this.editor = editor;
    this.element = element;
    this.view = view;
    this.view.dom.addEventListener('click', this.handleClick, true);
    this.view.dom.addEventListener('mouseenter', this.handleMouseover, true);
    this.view.dom.addEventListener('mouseleave', this.handleMouseout, true);
    this.element.addEventListener('mouseenter', this.handlePopperMouseover);
    this.element.addEventListener('mouseleave', this.handlePopperMouseout);
    this.editor.on('transaction', this.handleTransaction);
    this.element.style.visibility = 'visible';
    this.onOpen = onOpen;
    this.onMouseover = onMouseover;
    this.onMouseout = onMouseout;
  }

  handleTransaction = (attrs: { editor: Editor; transaction: Transaction }) => {
    try {
      const { transaction } = attrs;

      const docMarkSteps = transaction.steps.filter(isHighlightStep);
      if (!docMarkSteps.length) return;

      const addMarkStep = docMarkSteps.find(step => (
      // https://prosemirror.net/docs/ref/#transform.Step.toJSON
        (step.toJSON() as { stepType?: string })?.stepType === 'addMark' &&
      step.mark.attrs.id
      ));
      if (!addMarkStep) return;

      const {
        id, type,
      } = addMarkStep.mark.attrs;
      if (!id || !matchMarkType(type)) return;

      const target = [...this.view.dom.querySelectorAll(`[${HIGHLIGHT_DATA_ID}="${id}"]`)].at(-1);
      if (!target) return;

      this.createTooltip();
      if (!this.tippy) return;

      const pos = this.view.posAtDOM(target, -1);
      this.tippy.setProps({
        getReferenceClientRect: () => posToDOMRect(this.view, pos, pos),
      });
      this.tippy.show();
      this.onOpen(this.tippy, {
        id,
        create: true,
      });
    } catch (error) {
      if (error instanceof Error && error.toString().startsWith('NotFoundError')) {
        logError(error, {
          context: 'HighlightViewPlugin',
          tippySTate: this.tippy?.state,
        });
      } else {
        throw error;
      }
    }
  };

  handlePopperMouseover = () => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    window.clearTimeout(this.mouseOutSetTimeout);
  };

  handlePopperMouseout = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    if (
      event instanceof MouseEvent &&
      event.relatedTarget instanceof HTMLElement &&
      isElementOutside(this.element, event.relatedTarget) &&
      matchMarkType(event.relatedTarget.getAttribute(HIGHLIGHT_DATA_TYPE))
    ) {
      this.tippy?.hide();
    }
  };

  handleClick = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }

    const { target } = event;
    if (!(target instanceof HTMLElement)) return;

    const markElement = target.closest(HIGHLIGHT_TAG_NAME);
    if (!markElement) return;

    const markType = markElement.getAttribute(HIGHLIGHT_DATA_TYPE);
    if (!matchMarkType(markType)) return;

    const { section } = getThreadsPanel();
    const isMarkResolved = markElement.getAttribute('isresolved') === 'true';
    const matchSection = section === 'all' || (section === 'open' && !isMarkResolved) || (section === 'closed' && isMarkResolved);
    if (!matchSection) return;

    const id = markElement.getAttribute(HIGHLIGHT_DATA_ID);
    if (!id) return;

    window.clearTimeout(this.mouseOutSetTimeout);

    const node = [...this.view.dom.querySelectorAll(`[${HIGHLIGHT_DATA_ID}="${id}"]`)].at(-1);
    if (!node) return;

    this.createTooltip();
    if (!this.tippy) return;

    const pos = this.view.posAtDOM(node, -1);
    this.tippy.setProps({
      getReferenceClientRect: () => posToDOMRect(this.view, pos, pos),
    });
    this.tippy.show();
    this.onOpen(this.tippy, {
      id,
      isResolved: isMarkResolved,
    });
  };

  handleMouseout = () => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    this.onMouseout?.();
  };

  handleMouseover = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }

    const { target } = event;
    if (!(target instanceof HTMLElement)) return;

    const markType = target.getAttribute(HIGHLIGHT_DATA_TYPE);
    if (!matchMarkType(markType)) return;

    const markElement = target.closest(HIGHLIGHT_TAG_NAME);
    if (!markElement) return;
    const id = markElement.getAttribute(HIGHLIGHT_DATA_ID);
    if (!id) return;
    this.onMouseover?.({ id });
  };

  createTooltip() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) return;

    this.tippy = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'top-start',
      hideOnClick: 'toggle',
      offset: [0, 0],
      popperOptions: {
        strategy: 'fixed',
      },
    });
  }

  cleanup() {
    this.tippy?.destroy();
    this.view.dom.removeEventListener('click', this.handleClick, true);
    this.element.removeEventListener('mouseenter', this.handlePopperMouseover);
    this.element.removeEventListener('mouseleave', this.handlePopperMouseout);
    this.editor.off('transaction', this.handleTransaction);
  }

  destroy() {
    this.isDestroyed = true;
    this.cleanup();
  }
}

const matchMarkType = (markType: string | null) => markType === MARK_TYPE;

const isHighlightStep = (step: unknown): step is (AddMarkStep | RemoveMarkStep) => (
  (step as (Partial<AddMarkStep | RemoveMarkStep>)).mark?.type.name === HIGHLIGHT_EXTENSION_NAME
);
