import { HTMLAttributes, ReactNode, useState } from 'react';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { ViewsPanel } from 'src/components/ViewsPanel';
import { SectionParent } from 'src/utils/section.utils';

type Position = {
  clientX: number;
  clientY: number;
};

type Props = {
  sectionParent: SectionParent;
  target: (props: HTMLAttributes<HTMLElement>) => ReactNode;
};

export const SectionContextMenu = ({
  sectionParent, target,
}: Props) => {
  const [position, setPosition] = useState<Position | null>(null);
  const hide = () => setPosition(null);
  return (
    <>
      {target({
        onContextMenu: e => {
          setPosition({
            clientX: e.clientX,
            clientY: e.clientY,
          });
          e.preventDefault();
        },
      })}

      {position && (
        <DropdownLayer
          style={{ position: 'absolute' }}
          placement="bottom-start"
          visible
          withPortal
          withWrapper={false}
          hide={hide}
          content={(
            <ViewsPanel
              sectionParent={sectionParent}
              hide={hide}
            />
            )}
          overridePosition={(position.clientY && position.clientX && {
            bottom: position.clientY + 1,
            height: 1,
            left: position.clientX,
            right: position.clientX + 1,
            top: position.clientY,
            width: 1,
          }) || undefined}
        />
      )}
    </>
  );
};
