import { MoveBoardLinkInSectionListDocument, MoveBoardLinkInSectionListMutationVariables } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

export const useBoardLinkMoveInSectionList = () => {
  const [mutate] = useSafeMutation(MoveBoardLinkInSectionListDocument);

  const moveBoardLinkInSectionList = (
    variables: MoveBoardLinkInSectionListMutationVariables,
  ) => mutate({
    variables,
  });

  return { moveBoardLinkInSectionList };
};
