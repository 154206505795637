import { MoveReleaseNoteDocument, MoveReleaseNoteMutationVariables } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useMoveReleaseNote = () => {
  const [mutate, { loading }] = useSafeMutation(MoveReleaseNoteDocument);

  const moveReleaseNote = (variables: MoveReleaseNoteMutationVariables) => {
    return mutate({ variables });
  };

  return {
    moveReleaseNote,
    isMovingReleaseNote: loading,
  };
};
