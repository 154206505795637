import { ProductStatus } from 'src/types/product.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useProductStatus,
  setValue: setProductStatus,
} = make<{
  productStatus: ProductStatus;
}>({
  defaultState: {
    productStatus: null,
  },
});
