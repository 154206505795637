import { useQuery } from '@apollo/client';
import { ReleaseNoteBaseDocument, ReleasePublicStatus } from '@cycle-app/graphql-codegen';

import { extract } from 'src/types/graphql.types';

export const useReleaseNote = (releaseNoteId?: string | null) => {
  const query = useQuery(ReleaseNoteBaseDocument, {
    skip: !releaseNoteId,
    variables: {
      id: releaseNoteId as string,
    },
  });
  const releaseNote = extract('ReleaseNote', query.data?.node);
  return {
    isReleaseNoteLoading: query.loading,
    isReadonly: releaseNote?.release.publicStatus === ReleasePublicStatus.Published,
    releaseNote,
  };
};
