import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { motion } from 'framer-motion';
import { setLightness } from 'polished';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

interface ContainerProps {
  $color: ColorApi;
  $hasIcon?: boolean;
  $small?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  $variant?: 'user' | 'property';
}
export const Container = styled.div<ContainerProps>`
  --bg: ${p => (p.$variant === 'property'
    ? setLightness(0.65, p.theme.nuances[p.$color].tag)
    : p.theme.nuances[p.$color].main)};
  background-color: var(--bg);
  border-radius: 50%;
  cursor: ${p => (p.onClick ? 'pointer' : 'initial')};
  width: 24px;
  height: 24px;
  transition: transform .2s;

  ${p => p.$small && css`
    width: 20px;
    height: 20px;
  `};

  ${p => p.$hasIcon && css`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${p.theme.colors.text.white};
    }
  `}

  &:hover {
    transform: scale(1.1);
  }
`;

export const CheckContainer = styled(motion.div).attrs({
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    type: 'spring',
    duration: 0.25,
    bounce: 0,
  },
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;
