import { make } from 'src/utils/reactives.util';

export interface NewDocState {
  title: string;
}

export const {
  hookValue: useGetNewDocTitle,
  getValue: getNewDocTitle,
  setValue: setNewDocTitle,
  resetValue: resetNewDocTitle,
} = make<NewDocState>({
  defaultState: {
    title: '',
  },
  mergeTarget: 'initial',
});
