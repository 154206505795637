import { MotionProps, Variants } from 'framer-motion';

export const motionProps: MotionProps = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
};

export const motionVariants: Variants = {
  enter: {
    x: 0,
    opacity: 1,
    display: 'block',
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  exit: {
    x: 30,
    opacity: 0,
    transition: {
      duration: 0.1,
      ease: 'easeInOut',
    },
  },
};
