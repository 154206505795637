import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ZoomIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M0.431396 8.4314C0.431396 4.01313 4.01312 0.431396 8.4314 0.431396C12.8497 0.431396 16.4314 4.01313 16.4314 8.4314C16.4314 12.8497 12.8497 16.4314 8.4314 16.4314C4.01312 16.4314 0.431396 12.8497 0.431396 8.4314Z" fill="#4A8CFF" />
    <mask id="mask0_530_7513" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="3" y="5" width="11" height="7">
      <path d="M10.7406 7.44663L12.885 5.87962C13.0722 5.72704 13.2149 5.7625 13.2149 6.04456V10.8231C13.2149 11.1398 13.0367 11.1035 12.885 10.9881L10.7406 9.42106V7.44663ZM3.48291 6.05611V9.619C3.48487 10.0058 3.64036 10.376 3.91518 10.6482C4.19001 10.9204 4.56168 11.0723 4.94848 11.0705H10.1444C10.2146 11.0708 10.2822 11.0431 10.3321 10.9936C10.382 10.9442 10.4103 10.8769 10.4107 10.8066V7.24374C10.4088 6.85694 10.2533 6.48675 9.97847 6.21455C9.70365 5.94236 9.33198 5.79044 8.94518 5.79219H3.7493C3.67901 5.79197 3.6115 5.81963 3.56157 5.8691C3.51163 5.91857 3.48335 5.98582 3.48291 6.05611Z" fill="white" />
    </mask>
    <g mask="url(#mask0_530_7513)">
      <path d="M3.07056 5.37982H13.6273V11.4829H3.07056V5.37982Z" fill="white" />
    </g>
  </Svg>
);
