import { SectionType } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useEffect, useMemo } from 'react';

import { getLastInboxBoard, setLastInboxBoard } from 'src/reactives/lastView.reactive';
import { isViewAll, isViewMyInbox } from 'src/utils/inbox.util';
import { getBoardSlug } from 'src/utils/slug.util';

import { useProduct } from '../api/useProduct';
import { useIsOnboarding } from '../useIsOnboarding';

export const useInboxSection = () => {
  const isOnboarding = useIsOnboarding();

  const {
    product,
    loading,
  } = useProduct();

  // set My Inbox as last view if the product does not contain the last view
  useEffect(() => {
    const lastBoardId = getLastInboxBoard().boardId;
    const boards = nodeToArray(product?.boardSections.edges[0]?.node.boards);
    if (boards.some(board => board.id === lastBoardId)) return;
    const board = boards.find(isOnboarding ? isViewAll : isViewMyInbox);
    if (!board) return;
    setLastInboxBoard({
      boardId: board.id,
      boardSlug: getBoardSlug(board),
    });
  }, [isOnboarding, product]);

  return useMemo(() => {
    const section = product?.boardSections.edges.find(e => e.node.type === SectionType.Feedback)?.node;
    const unsortedBoards = nodeToArray(section?.boards);
    const myInboxBoard = unsortedBoards.find(isViewMyInbox);
    const allBoard = unsortedBoards.find(isViewAll);
    const customBoards = unsortedBoards.filter(board => !board.isBuiltIn).reverse();

    return {
      isLoading: loading,
      section,
      myInboxBoard,
      allBoard,
      customBoards,
      boards: unsortedBoards,
    };
  }, [loading, product]);
};
