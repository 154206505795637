import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const Overlay = styled(motion.div)`
  z-index: ${mappingZindex[Layer.Zoom]};
  background: black;
  position: fixed;
  inset: 0;
`;

export const ImageWrapper = styled(motion.div)`
  z-index: ${mappingZindex[Layer.Zoom]};
  position: fixed;
  cursor: zoom-out;
`;
