import { useReactiveVar as hookReactiveVar } from '@apollo/client';

import { LocalKey } from 'src/types/localStorage.types';
import { StateHookResult, ParamsReactive } from 'src/types/state.types';

type HookLocalState = <T>(p: ParamsReactive<T> & {
  localKey?: LocalKey;
  defaultState: T;
  mergeTarget?: 'current' | 'initial';
  setLocalStorageItem?: (updatedState: T) => void;
}) => () => StateHookResult<T>;
export const hookLocalState: HookLocalState = (params) => () => [hookReactiveVar(params.reactive), setLocalState(params)];

type HookGetLocalState = <T>(p: ParamsReactive<T>) => () => T;
export const hookGetLocalState: HookGetLocalState = ({ reactive }) => () => hookReactiveVar(reactive);

type GetLocalState = <T>(p: ParamsReactive<T>) => () => T;
export const getLocalState: GetLocalState = ({ reactive }) => () => reactive();

type SetLocalState = <T>(p: ParamsReactive<T> & {
  defaultState: T;
  mergeTarget?: 'current' | 'initial';
  setLocalStorageItem?: (updatedState: T) => void;
  areEqual?: (a: T, b: T) => boolean;
}) => (value: Record<string, T[keyof T]>) => void;

export const setLocalState: SetLocalState = ({
  reactive,
  defaultState,
  mergeTarget = 'current',
  setLocalStorageItem,
  areEqual,
}) => (newState) => {
  const state = mergeTarget === 'initial' ? defaultState : reactive();
  const updatedState = {
    ...state,
    ...newState,
  };
  if (areEqual?.(state, updatedState)) return;
  reactive(updatedState);
  setLocalStorageItem?.(updatedState);
};

export const resetLocalState = <T>({
  localKey,
  defaultState,
  ...setterParams
}: ParamsReactive<T> & {
  localKey?: LocalKey;
  defaultState: T;
  mergeTarget?: 'current' | 'initial';
}) => {
  const setState = setLocalState({
    defaultState,
    ...setterParams,
  });
  return () => {
    if (localKey) {
      localStorage.removeItem(localKey);
    }
    setState(defaultState as unknown as Record<string, T[keyof T]>);
  };
};
