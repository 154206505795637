import { Property } from '@cycle-app/graphql-codegen';
import { DragOverEvent } from '@dnd-kit/core';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { getSelection } from 'src/reactives/selection.reactive';
import { getIsAtLeastOneFeedbackFromDocIds, getIsAtLeastOneInsightFromDocIds, MinimalDocDocType } from 'src/utils/doc.util';
import { addToaster } from 'src/utils/toasters.utils';

import { GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW } from './useCrossGroupStrategy';

export const useShouldPreventDnD = (
  propertyTypename: Property['__typename'],
  getDoc: (docId: string) => MinimalDocDocType | undefined,
  canUpdateFeedbackStatus = true,
) => {
  const { docTypes } = useGetDocTypes();

  const isSortByDate = useBoardConfig(ctx => ctx.isSortByDate);

  return (
    e: DragOverEvent,
    groupsOrigin?: string[],
    groupTarget?: string,
    isOnDrop?: boolean,
  ) => {
    const isMovingToSameGroup = groupsOrigin?.[0] === groupTarget;
    if (isSortByDate) {
      if (isOnDrop && isMovingToSameGroup) {
        addToaster({
          id: 'sort-by-date-prevent-dnd',
          title: "You can't make that change since this view is sorted by date",
        });
      }
      if (isMovingToSameGroup) {
        // Return only of if isMovingToSameGroup, so else it will continue to other rules below.
        return { prevent: true };
      }
    }

    if (!GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW.includes(propertyTypename)) return { prevent: false };

    const { selected: selectedDocIds } = getSelection();
    const docToCheck = selectedDocIds.length ? selectedDocIds : [String(e.active.id)];

    if (!docToCheck.length) return { prevent: false };

    const isInsightInSelection = getIsAtLeastOneInsightFromDocIds(docToCheck, getDoc, docTypes);
    const isFeedbackInSelection = getIsAtLeastOneFeedbackFromDocIds(docToCheck, getDoc, docTypes);

    if (!isInsightInSelection && !isFeedbackInSelection) return { prevent: false };
    // If only one origin groupe -> Not block DnD
    if (groupsOrigin?.length === 1 && isMovingToSameGroup) return { prevent: false };
    // If one item AND Insight AND same group -> OK
    // If one item AND NOT Insight -> OK
    // If many items of many group => true (cannot DnD)
    // If many items of same group -> Should be same origin and target

    if (isInsightInSelection) {
      addToaster({
        id: 'insight-prevent-dnd',
        title: "You can't make that change since insights' properties are not editable",
      });
    }

    return {
      prevent: isFeedbackInSelection ? !canUpdateFeedbackStatus : true,
      isInsightInSelection,
      isFeedbackInSelection,
    };
  };
};
