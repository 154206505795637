import { CreateGithubIssueMutationVariables, GithubIssueBaseFragment } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import { useCreateGithubIssue } from 'src/hooks/api/mutations/integrations/useCreateGithubIssue';

type CreateIssuesParams = {
  titles: string[];
  repoName: CreateGithubIssueMutationVariables['repoName'];
  docId: CreateGithubIssueMutationVariables['docId'];
  assignees: CreateGithubIssueMutationVariables['assignees'];
  statusId: CreateGithubIssueMutationVariables['statusId'];
  projectId: CreateGithubIssueMutationVariables['projectId'];
};

type Progression = {
  status: 'not-started' | 'in-progress' | 'finished';
  issuesTotal: number;
  issuesSuccess: number;
  issuesFailed: number;
};
const initialProgression: Progression = {
  status: 'not-started',
  issuesTotal: 0,
  issuesSuccess: 0,
  issuesFailed: 0,
};

export const useCreateGithubIssues = () => {
  const { createIssue } = useCreateGithubIssue();
  const [progression, setProgression] = useState<Progression>(initialProgression);

  const createIssues = async ({
    titles, repoName, docId, assignees, statusId, projectId,
  }: CreateIssuesParams): Promise<GithubIssueBaseFragment[]> => {
    if (!titles.length || !repoName || !docId) throw Error('Data are missing');

    setProgression(() => ({
      status: 'in-progress',
      issuesTotal: titles.length,
      issuesSuccess: 0,
      issuesFailed: 0,
    }));

    const newIssues = await Promise.all<GithubIssueBaseFragment>(
      titles.map((title) => new Promise((resolve, reject) => {
        createIssue({
          docId,
          repoName,
          title,
          assignees,
          statusId,
          projectId,
        }).then(newIssue => {
          if (newIssue.data?.createGithubIssue) {
            resolve(newIssue.data.createGithubIssue);
            setProgression(oldProgression => ({
              ...oldProgression,
              issuesSuccess: oldProgression.issuesSuccess + 1,
            }));
          } else {
            reject(new Error('error-during-gh-issue-creation'));
            setProgression(oldProgression => ({
              ...oldProgression,
              issuesFailed: oldProgression.issuesFailed + 1,
            }));
          }
        }).catch(e => {
          reject(new Error(e.message));
          setProgression(oldProgression => ({
            ...oldProgression,
            issuesFailed: oldProgression.issuesFailed + 1,
          }));
        });
      })),
    );

    setProgression(oldProgression => ({
      ...oldProgression,
      status: 'finished',
    }));

    return newIssues;
  };

  return {
    createIssues,
    progression,
  };
};
