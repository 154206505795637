import { Skeleton, CustomerAvatar } from '@cycle-app/ui';
import { CloseIcon, SummaryIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';

import { CustomerLinkedProfiles } from 'src/components/CustomerProfile/CustomerLinkedProfiles';
import { CustomerActionsMenu } from 'src/components/CustomersList/CustomerActionsMenu';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ExportQuotesFromCustomer } from 'src/components/ExportQuotesModal';
import { PartialHidden } from 'src/components/PartialHidden/PartialHidden';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { CustomerCompany } from './CustomerCompany';
import { CustomerEmail } from './CustomerEmail';
import { CustomerName } from './CustomerName';
import { DocsCountCustomer } from './DocsCountCustomer';
import {
  ProfileContainer, ProfileMain, ProfileMainContent, ProfileTopBar, ProfileTopBarContent,
  ProfileHeader, ProfileActions, ExpandButton, CloseButton,
  ImageContainer, StyledCustomerAvatar, ImageSkeleton, Skeletons,
  ProfileInfos, Summary, SummaryTitle, InfosTitle, InfosSubtitle, Left,
  NameContainer, TopBarSubtitle,
} from './Profile.styles';
import { CustomerSummaryModal } from './SummaryModal';
import { TabsCustomer } from './TabsCustomer';
import { useExpandTabsSection } from './useExpandTabsSection';

type Props = {
  customerId: string;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
};

export const CustomerProfile = ({
  customerId, onClose, onExpand,
}: Props) => {
  const {
    customer, loading,
  } = useCustomer(customerId);

  const {
    profileRef, tabPanelsRef, tabsExpanded, handlePan,
  } = useExpandTabsSection();

  const actions = (
    <ProfileActions>
      {onClose && (
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}
      {onExpand && (
        <ExpandButton
          tooltip="Open full page"
          onClick={onExpand}
        >
          <ExpandIcon size={16} />
        </ExpandButton>
      )}
    </ProfileActions>
  );

  const menu = customer && (
    <CustomerActionsMenu customer={customer} />
  );

  const subtitle = customer && (
    <>
      <PartialHidden>
        <NameContainer>
          <CustomerEmail customerId={customerId} />
        </NameContainer>
      </PartialHidden>

      <PartialHidden>
        <CustomerCompany customerId={customerId} />
      </PartialHidden>

      {!!customer.profiles?.length && (
        <PartialHidden>
          <CustomerLinkedProfiles customer={customer} />
        </PartialHidden>
      )}
    </>
  );

  return (
    <ErrorBoundary>
      <BoardConfigContextProvider>
        <ProfileContainer
          ref={profileRef}
          onPan={(e, info) => {
            handlePan(e.target, info.delta.y);
          }}
        >
          <ProfileMain
            animate={{
              height: tabsExpanded ? 28 : 'auto',
            }}
          >
            <ProfileMainContent>
              <ProfileHeader>
                {actions}
                {menu}
              </ProfileHeader>

              <ImageContainer>
                {loading ? <ImageSkeleton /> : (customer && (
                  <StyledCustomerAvatar
                    customer={customer}
                    isReadOnly={isDefaultCustomer(customer)}
                    onCompanyClick={openCompanyModal}
                    loader={<ImageSkeleton />}
                  />
                )
                )}
              </ImageContainer>

              <ProfileInfos>
                {loading ? (
                  <Skeletons>
                    <Skeleton height={16} width={150} />
                    <Skeleton height={16} width={250} />
                  </Skeletons>
                ) : (customer && (
                  <>
                    <InfosTitle>
                      <NameContainer>
                        <CustomerName customerId={customerId} />
                      </NameContainer>
                    </InfosTitle>
                    <InfosSubtitle>
                      <Left>{subtitle}</Left>
                    </InfosSubtitle>
                  </>
                ))}
              </ProfileInfos>

              <Summary>
                <SummaryTitle>
                  <SummaryIcon />
                  Summary
                </SummaryTitle>
                <DocsCountCustomer customerId={customerId} />
              </Summary>
            </ProfileMainContent>
          </ProfileMain>

          <TabsCustomer customerId={customerId} tabPanelsRef={tabPanelsRef} />

          <ExportQuotesFromCustomer customerId={customerId} />
          <CustomerSummaryModal customerId={customerId} />

          <AnimatePresence>
            {tabsExpanded && (
              <ProfileTopBar
                key="topBar"
                initial={{ top: -44 }}
                animate={{ top: 0 }}
                exit={{ opacity: -44 }}
              >
                {actions}

                <ProfileTopBarContent>
                  {loading ? (
                    <Skeleton height={16} width={200} />
                  ) : (
                    <>
                      {customer && (
                        <CustomerAvatar
                          size="S"
                          customer={customer}
                          hideCompany
                        />
                      )}
                      <InfosTitle>{customer?.name}</InfosTitle>
                      <TopBarSubtitle>
                        {subtitle}
                      </TopBarSubtitle>
                    </>
                  )}
                </ProfileTopBarContent>

                {menu}
              </ProfileTopBar>
            )}
          </AnimatePresence>
        </ProfileContainer>
      </BoardConfigContextProvider>
    </ErrorBoundary>
  );
};
