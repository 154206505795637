import { ERROR_CODE } from '@cycle-app/utilities';

export const SENTRY_IGNORED_ERRORS: Array<string | RegExp> = [
  // network error
  'Failed to fetch',
  // benign navigator error with no consequence
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',

  // Prosemirror errors
  'Cannot read properties of null (reading \'nodeType\')',
  'Cannot read properties of null (reading \'matchesNode\')',
  'Cannot read properties of null (reading \'domFromPos\')',

  // Security errors
  /Blocked a frame with origin/i,

  ERROR_CODE.NOT_A_BUSINESS_EMAIL,
];
