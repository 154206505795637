import { ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const StyledActionButton = styled(ActionButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const StyledPortalModal = styled(PortalModal)`
  width: 1040px;
  padding: 48px 48px 65px;
`;
