import { RecordingMode, RecordingOption } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';

export const recordingOptionsLabel: Record<RecordingOption, string> = {
  [RecordingOption.AutoRecord]: 'Auto-record all meetings',
  [RecordingOption.ExternalParticipants]: 'Auto-record all meetings with external participants ',
  [RecordingOption.InternalParticipants]: 'Auto-record all meetings with internal participants',
  [RecordingOption.NoRecord]: 'Don’t auto-record any meetings',
  [RecordingOption.Organizer]: 'Auto-record all meetings I’m organizer',
};

export const recordingOptions: SelectOption<RecordingOption>[] = [
  {
    value: RecordingOption.AutoRecord,
    label: recordingOptionsLabel.AUTO_RECORD,
  },
  {
    value: RecordingOption.ExternalParticipants,
    label: recordingOptionsLabel.EXTERNAL_PARTICIPANTS,
  },
  {
    value: RecordingOption.InternalParticipants,
    label: recordingOptionsLabel.INTERNAL_PARTICIPANTS,
  },
  {
    value: RecordingOption.Organizer,
    label: recordingOptionsLabel.ORGANIZER,
  },
  {
    value: RecordingOption.NoRecord,
    label: recordingOptionsLabel.NO_RECORD,
  },
];

export const recordingModeLabel: Record<RecordingMode, string> = {
  [RecordingMode.Beside]: 'Side-by-side (show active speaker and screen share)',
  [RecordingMode.Hide]: 'Hide (show only screen share)',
  [RecordingMode.Overlap]: 'Overlap (show active speaker and screen share)',
};

export const recordingModeOptions: SelectOption<RecordingOption>[] = [
  {
    value: RecordingMode.Beside,
    label: recordingModeLabel.BESIDE,
  },
  {
    value: RecordingMode.Hide,
    label: recordingModeLabel.HIDE,
  },
  {
    value: RecordingMode.Overlap,
    label: recordingModeLabel.OVERLAP,
  },
];
