import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DuplicateIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_16_1408)">
      <path d="M1 2.4C1 1.6268 1.6268 1 2.4 1H9.4C10.1732 1 10.8 1.6268 10.8 2.4V5.2H13.6C14.3732 5.2 15 5.8268 15 6.6V13.6C15 14.3732 14.3732 15 13.6 15H6.6C5.8268 15 5.2 14.3732 5.2 13.6V10.8H2.4C1.6268 10.8 1 10.1732 1 9.4V2.4ZM6.6 10.8V13.6H13.6V6.6H10.8V9.4C10.8 10.1732 10.1732 10.8 9.4 10.8H6.6ZM9.4 9.4V2.4H2.4V9.4H9.4Z" fill="#171717" />
    </g>
    <defs>
      <clipPath id="clip0_16_1408">
        <rect width="16" height="16" fill="white" transform="translate(0.000488281)" />
      </clipPath>
    </defs>
  </Svg>
);
