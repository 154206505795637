import { PublishedBoardConfigFullFragment } from '@cycle-app/graphql-codegen';

export const isViewEmpty = (boardConfig: PublishedBoardConfigFullFragment | null | undefined): boolean => {
  if (boardConfig?.docQuery.__typename === 'BoardQuery') {
    return boardConfig.docQuery.docGroup.docs.edges.length === 0 &&
        !boardConfig.docQuery.docGroup.docs.pageInfo.hasNextPage;
  }

  if (boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy') {
    for (const group of boardConfig.docQuery.docGroups.edges) {
      if (group.node.docs.edges.length !== 0 || group.node.docs.pageInfo.hasNextPage) {
        return false;
      }
    }
    return true;
  }

  if (boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
    for (const swimlane of boardConfig.docQuery.swimlanes.edges) {
      for (const group of swimlane.node.docGroups.edges) {
        if (group.node.docs.edges.length !== 0 || group.node.docs.pageInfo.hasNextPage) {
          return false;
        }
      }
    }
    return true;
  }

  return false;
};
