import { Color as baseColors } from './baseColors';
import { colors, nuance } from './colorThemes/eclipse';
import { Color as ColorApi, ThemeType } from '../../../graphql-codegen/generated';

const defaultUserColor = ColorApi.A;

// As the default theme — This should stay implicitely typed
const baseTheme = {
  baseColors,
  colors: {
    ...colors,
  },
  nuances: {
    ...nuance,
  },
  userColors: {
    ...nuance[defaultUserColor],
    main: nuance[defaultUserColor].main,
  },
};

export type BaseTheme = typeof baseTheme & {
  themeType: ThemeType;
  isDark: boolean;
};

export default baseTheme;
