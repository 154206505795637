import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AsanaIcon = (props: SvgProps) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <g clipPath="url(#clip0_3264_74388)">
      <mask id="mask0_3264_74388" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <path d="M11.6179 4.11112C11.6179 6.0256 10.0562 7.58454 8.13843 7.58454C6.22062 7.58454 4.65898 6.0256 4.65898 4.11112C4.65898 2.19663 6.22062 0.637695 8.13843 0.637695C10.0836 0.637695 11.6179 2.16928 11.6179 4.11112ZM3.61788 8.43238C1.70007 8.43238 0.138428 9.99132 0.138428 11.9058C0.138428 13.8203 1.70007 15.3792 3.61788 15.3792C5.53569 15.3792 7.09733 13.8203 7.09733 11.9058C7.09733 9.99132 5.56309 8.43238 3.61788 8.43238ZM12.659 8.43238C10.7412 8.43238 9.17953 9.99132 9.17953 11.9058C9.17953 13.8203 10.7412 15.3792 12.659 15.3792C14.5768 15.3792 16.1384 13.8203 16.1384 11.9058C16.1384 9.99132 14.6042 8.43238 12.659 8.43238Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3264_74388)">
        <path d="M8.13871 0.473633C12.988 0.473633 16.9058 4.38465 16.9058 9.22556C16.9058 14.0665 12.988 17.9775 8.13871 17.9775C3.28939 17.9775 -0.628418 14.0665 -0.628418 9.22556C-0.601021 4.38465 3.31679 0.473633 8.13871 0.473633Z" fill="url(#paint0_radial_3264_74388)" />
      </g>
    </g>
    <defs>
      <radialGradient id="paint0_radial_3264_74388" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.1491 9.21789) rotate(-90) scale(8.74845 8.76364)">
        <stop stopColor="#FFB900" />
        <stop offset="0.6" stopColor="#F95D8F" />
        <stop offset="0.9991" stopColor="#F95353" />
      </radialGradient>
      <clipPath id="clip0_3264_74388">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
