import { StatusCategory, Color as ColorApi } from '@cycle-app/graphql-codegen';
import { rgba, opacify, transparentize, lighten, darken } from 'polished';

import { ColorNuance } from '../../types/nuance.types';
import { BOX_SHADOW_COLOR_Z1 } from '../../utils/styles.util';
import { Color, LegacyColor } from '../baseColors';

const text = {
  primary: Color.Black,
  secondary: Color.Grey700,
  hover: Color.Grey800,
  opposite: Color.White,
  disabled: Color.Grey500,
  highlight: Color.Black,

  white: Color.White,
  black: Color.Black,
  blue: Color.Blue600,
  green: Color.Green700,
  yellow: Color.Yellow600,
  orange: Color.Orange600,
  orangered: Color.OrangeRed600,
  red: Color.Red600,
  pink: Color.Pink600,
  purple: Color.Purple600,
  violet: Color.Violet600,
};

const background = {
  primary: Color.White,
  secondary: Color.Grey100,
  // ex. in modals.
  secondaryElevated: Color.Grey100,
  tertiary: Color.Grey200,
  active: Color.Grey400,
  opposite: Color.Black,
  hover: Color.Grey300,
  hoverSoft: Color.Grey100,
  hoverDark: Color.Grey800,

  transparent: 'transparent',
  black: Color.Black,
  white: Color.White,
  grey: Color.Grey100,
  disabled: Color.Grey300,
  highlight: Color.Yellow300,

  blue: Color.Blue600,
  bluelight: rgba(Color.Blue600, 0.1),
  blueDarker: Color.Blue700,
  green: Color.Green700,
  greenlight: rgba(Color.Green700, 0.1),
  yellow: Color.Yellow600,
  yellowlight: rgba(Color.Yellow600, 0.1),
  orange: Color.Orange600,
  orangelight: rgba(Color.Orange600, 0.1),
  orangered: Color.OrangeRed600,
  orangeredlight: rgba(Color.OrangeRed600, 0.1),
  red: Color.Red600,
  redlight: rgba(Color.Red600, 0.1),
  pink: Color.Pink600,
  pinklight: rgba(Color.Pink600, 0.1),
  purple: Color.Purple600,
  purplelight: rgba(Color.Purple600, 0.1),
  violet: Color.Violet600,
  violetlight: rgba(Color.Violet600, 0.1),
  mauve: Color.Violet500,
};

const border = {
  primary: Color.Grey300,
  secondary: Color.Grey100,
  hover: Color.Grey200,
  hoverDarker: Color.Grey500,
  transparent: 'transparent',
  input: Color.Grey400,

  greyLight: Color.Grey300,
  grey: Color.Grey400,
  greyDark: Color.Grey800,

  white: Color.White,
  blue: Color.Blue600,
  green: Color.Green700,
  yellow: Color.Yellow600,
  orange: Color.Orange600,
  orangered: Color.OrangeRed600,
  red: Color.Red400,
  pink: Color.Pink600,
  purple: Color.Purple600,
  violet: Color.Violet600,

  surprisingGrey: rgba(Color.Black, 0.1),
  surprisingGreyDark: rgba(Color.Black, 0.05),
};

// As default theme — eclipse should stay implicitly typed
export const colors = {
  text,
  background,
  border,
  authLayout: {
    content: {
      bg: background.primary,
      color: Color.Black,
    },
    sidebar: {
      bg: LegacyColor.Beige,
      tooltip: {
        bg: Color.White,
        color: Color.Black,
      },
    },
  },
  avatar: {
    bg: Color.White,
    bgOpposite: Color.Black,
    bgLight: Color.Grey300,
    pending: Color.Grey400,
    pendingLight: Color.Grey300,
  },
  board: {
    textButton: {
      bgHover: Color.Grey200,
      colorHover: Color.Grey700,
    },
  },
  bulkActions: {
    bg: background.primary,
    border: border.primary,
    outline: rgba(0, 0, 0, 0.06),
  },
  button: {
    light: {
      bg: background.secondary,
      bgHover: background.tertiary,
      bgActive: Color.Grey300,
    },
    noColor: {
      bg: background.white,
      color: text.primary,
      border: text.primary,
    },
    secondary: {
      bg: background.transparent,
      bgHover: background.tertiary,
      bgFocus: background.hover,
      bgActive: Color.Grey300,
      color: text.primary,
      enableUserColor: true,
    },
    ternary: {
      color: text.primary,
      bg: background.secondary,
      bgHover: background.tertiary,
      bgActive: background.hoverSoft,
      bgFocus: background.hover,
    },
    outlined: {
      bg: Color.Grey200,
      color: text.disabled,
      bgHover: Color.Grey300,
      colorHover: text.primary,
      bgActive: Color.White,
      colorActive: text.primary,
    },
    warningSecondary: {
      color: text.red,
    },
    secondaryV2: {
      bg: background.transparent,
      bgHover: Color.Grey200,
      bgActive: Color.Grey300,
      color: Color.Grey500,
      colorHover: Color.Grey700,
    },
    outlinedAlt: {
      bg: Color.White,
      bgHover: Color.Grey100,
      bgActive: Color.Grey200,
      border: Color.Grey300,
      color: Color.Black,
    },
    alt: {
      bg: Color.Black,
      bgHover: Color.Grey900,
      bgActive: Color.Grey900,
      border: Color.Black,
      color: Color.White,
    },
    nospacePrimary: {
      color: Color.Cycle,
      colorHover: Color.Blue600,
    },
  },
  body: {
    bg: Color.Grey100,
    color: Color.Black,
  },
  card: {
    bg: Color.White,
    bgHover: '#FBFBFB',
    checkbox: {
      bg: Color.White,
      color: Color.Grey400,
      border: rgba(0, 0, 0, 0.06),
    },
    parent: {
      border: rgba(0, 0, 0, 0.06),
      color: text.disabled,
      colorAlt: text.secondary,
      colorHover: Color.Grey700,
      bgHover: Color.Grey200,
      bgHoverAlt: Color.Grey300,
      bgActive: darken(0.05, Color.Grey300),
    },
  },
  docItem: {
    actionButton: {
      color: Color.Grey400,
      colorAlt: Color.Grey500,
      colorHover: Color.Grey700,
      bgHover: Color.Grey200,
      bgActive: Color.Grey300,
    },
  },
  docPanel: {
    bg: background.primary,
    border: Color.Grey200,
    overlay: rgba(0, 0, 0, 0.5),
    header: {
      breadCrumbSeparatorColor: Color.Grey400,
    },
    rightPanel: {
      border: Color.Grey300,
    },
    insightButton: {
      enableUserColor: true,
    },
    insightCard: {
      bgHover: Color.Grey100,
    },
    hierarchy: {
      bg: background.secondary,
      bgModal: lighten(0.01, background.secondary),
      bgHover: background.secondary,
      border: rgba(0, 0, 0, 0.06),
    },
    releaseNote: {
      border: Color.Grey300,
    },
  },
  docProcess: {
    color: Color.Grey700,
    colorProcessed: 'hsl(var(--cycle))',
    bg: Color.White,
    bgHover: Color.Grey100,
    bgActive: Color.Grey200,
    border: 'rgba(0, 0, 0, 0.06)',
  },
  comments: {
    color: Color.Grey800,
  },
  dropdown: {
    bg: background.primary,
    color: text.primary,
    border: LegacyColor.Grey,
    sep: LegacyColor.Grey200,
    outline: rgba(Color.Black, 0.06),
  },
  dropZone: {
    bg: Color.White,
  },
  editor: {
    code: {
      bg: Color.Grey100,
      border: Color.Grey200,
      text: Color.Cycle,
    },
    hr: rgba(Color.Black, 0.12),
    text: Color.Grey900,
    textHighlight: rgba(Color.Black, 0.08),
    filePreview: {
      bg: Color.Grey100,
      bgHover: Color.Grey200,
    },
    discover: {
      bg: Color.White,
    },
    link: 'hsl(var(--cycle))',
  },
  extractInsights: {
    quote: {
      bg: Color.White,
    },
  },
  flow: {
    bg: Color.Grey100,
  },
  flowElement: {
    bg: Color.White,
    color: Color.Black,
    border: Color.Cycle,
    preview: {
      bg: background.primary,
    },
    placeholder: {
      border: Color.Grey300,
      bgHover: Color.Grey200,
    },
  },
  flowPath: {
    stroke: Color.Grey400,
  },
  input: {
    bg: Color.Grey200,
    bgAlt: Color.White,
    hover: Color.Grey300,
  },
  inputRadio: {
    bg: background.primary,
  },
  group: {
    bg: Color.Grey200,
    feedbackList: {
      bg: Color.Grey100,
      bgHover: Color.Grey200,
      bgActive: Color.Grey300,
    },
  },
  modal: {
    border: border.transparent,
    mask: {
      bg: LegacyColor.Black,
    },
    content: {
      bg: background.primary,
      bgNoMask: background.primary,
    },
    icon: {
      warning: {
        bg: background.redlight,
        color: background.red,
      },
    },
  },
  notificationCenter: {
    bg: background.primary,
    color: text.primary,
    border: LegacyColor.Grey,
    card: {
      bgHover: background.secondary,
      boxBgHover: background.hover,
    },
  },
  placeholder: {
    bg: Color.Grey300,
  },
  releases: {
    border: Color.Grey200,
    bg: Color.White,
    bgActive: Color.Grey100,
    icon: Color.Grey400,
    iconActive: Color.Grey500,
    bullet: Color.Grey300,
    bulletActive: Color.Grey500,
    gradient: `
      linear-gradient(180deg, rgba(255, 255, 255, 0) 23.35%, #FFFFFF 100%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
      linear-gradient(90.02deg, #A990FF 0.02%, #57CFEF 52.39%, #7FEA96 99.99%);`,
    learnMore: {
      border: Color.Grey300,
      bg: Color.Grey200,
      bgHover: darken(0.02, Color.Grey200),
      bgActive: Color.Grey300,
      color: Color.Grey700,
    },
    loopClosed: {
      bg: '#DFF3DF',
      border: 'transparent',
      color: Color.Green800,
    },
    card: {
      bg: Color.White,
      placeholder: Color.Blue200,
    },
    textButton: {
      color: Color.Grey500,
    },
    note: {
      date: Color.Grey700,
    },
    tag: {
      colorDraft: '#FFD977',
      colorPublished: '#85D985',
      bg: Color.Grey100,
      bgDraft: '#FFEDBF',
      bgPublished: '#DFF3DF',
    },
    modal: {
      border: Color.Grey100,
    },
  },
  roadmaps: {
    modal: {
      border: Color.Grey200,
      badge: {
        bg: Color.Blue200,
        color: Color.Grey900,
      },
    },
    template: {
      border: Color.Grey200,
      bg: Color.Grey200,
      footer: Color.White,
      arrow: Color.Grey300,
      badge: {
        bg: Color.Grey500,
        color: Color.White,
      },
      docType: {
        bg: Color.White,
      },
    },
  },
  userModal: {
    themePreview: {
      bg: Color.Grey400,
      bgHover: Color.Grey400,
    },
  },
  actionButton: {
    color: text.disabled,
    colorHover: Color.Grey800,
    colorDisabled: Color.Grey300,
    bg: background.transparent,
    bgHover: background.tertiary,
    bgActive: background.hover,
    bgHoverDark: Color.Grey300,
    bgActiveDark: Color.Grey400,
    outline: rgba(Color.Black, 0.06),
    warning: {
      bg: null,
      bgHover: background.redlight,
      colorHover: text.red,
      color: null,
    },
    special: {
      bg: null,
      bgHover: null,
      colorHover: text.yellow,
      color: null,
    },
    onColoredBg: {
      color: Color.White,
      bgHover: Color.White,
      colorHover: text.primary,
    },
    secondary: {
      bg: background.transparent,
      bgHover: Color.Grey300,
      bgActive: darken(0.05, Color.Grey300),
      color: Color.Grey700,
      colorHover: Color.Grey800,
    },
    tertiary: {
      bg: null,
      bgHover: Color.Grey300,
      bgActive: darken(0.05, Color.Grey300),
      color: text.disabled,
      colorHover: Color.Grey800,
    },
  },
  skeleton: {
    bg: Color.Grey400,
  },
  sidebar: {
    bg: Color.Grey900,
    color: Color.Grey500,
    border: Color.Transparent,
    colorHover: Color.White,
    separator: rgba(Color.White, 0.1),
    resize: Color.Grey300,
    header: {
      bg: Color.Transparent,
      menus: {
        color: Color.White,
        emailColor: Color.Grey500,
      },
      title: {
        color: Color.White,
      },
      item: {
        color: text.disabled,
        colorHover: text.hover,
        bg: Color.Transparent,
        bgHover: Color.Grey850,
        caret: {
          color: Color.Grey500,
          colorHover: Color.White,
        },
      },
      shortcut: {
        bg: Color.Grey800,
        bgHover: Color.Grey850,
        color: Color.Grey500,
      },
    },
    item: {
      bgHover: Color.Grey850,
      bgActive: Color.Grey800,
      bgPlaceholder: Color.Grey900,
      dotsMenu: {
        colorHover: Color.White,
        bgHover: Color.Grey900,
      },
      warning: {
        color: Color.Grey500,
        colorHover: Color.White,
        bgHover: Color.Grey900,
      },
    },
    boardItem: {
      color: Color.White,
    },
    shortcut: {
      bg: Color.Grey850,
      color: Color.White,
      bgHover: Color.Grey800,
      colorHover: Color.Grey500,
      active: {
        bgHover: rgba(Color.Black, 0.1),
        colorHover: Color.White,
      },
    },
    activeItem: {
      color: Color.White,
    },
    droppableArea: {
      bg: Color.Grey300,
    },
    newSectionInput: {
      bg: Color.Grey900,
      border: Color.Grey800,
      color: Color.White,
    },
    newBoard: {
      color: Color.Grey700,
      colorHover: Color.Grey400,
    },
    boardsSectionItem: {
      bg: Color.Grey850,
      color: Color.Grey700,
      bgHover: Color.Grey800,
      colorHover: Color.Grey500,
    },
    card: {
      bg: Color.Grey850,
      text: Color.White,
    },
    dropdown: {
      bg: Color.Grey850,
      text: Color.White,
      separator: Color.Grey800,
    },
    mobile: {
      bg: Color.Grey100,
    },
    createDoc: {
      text: 'hsl(var(--cycle))',
      icon: 'hsl(var(--cycle))',
      bg: 'hsla(var(--cycle), 0.2)',
    },
  },
  selectPanel: {
    options: {
      bg: Color.Grey200,
    },
  },
  selectItem: {
    color: text.primary,
    bg: background.transparent,
    bgHover: background.grey,
    bgActive: darken(0.02, background.grey),
    colorDisabled: text.disabled,
  },
  selectItemSpecial: {
    colorHover: LegacyColor.Yellow,
  },
  selectItemDanger: {
    colorHover: Color.Red500,
    bgHover: Color.Red100,
  },
  shortcut: {
    bg: Color.White,
    color: text.primary,
    active: {
      color: text.primary,
      bg: rgba(Color.White, 0.6),
    },
    oposite: {
      bg: rgba(Color.White, 0.5),
      color: LegacyColor.Dark,
    },
    dark: {
      bg: Color.White,
      color: text.primary,
    },
  },
  scrollbar: {
    color: LegacyColor.DirtyTransparentGrey,
    colorHover: LegacyColor.DirtyTransparentGrey,
  },
  tag: {
    color: Color.Grey900,
    border: border.primary,
    bg: Color.Grey100,
    bgHover: Color.Grey200,
    bgActive: Color.Grey300,
    bgAlt: Color.Grey200,
    greyAlt: {
      bg: Color.Grey100,
      bgHover: Color.Grey200,
      bgActive: Color.Grey300,
    },
  },
  toaster: {
    bg: background.black,
    buttons: {
      bgActive: Color.Grey700,
      bgHover: Color.Grey800,
      bg: Color.Grey850,
    },
  },
  tooltip: {
    bg: Color.Grey900,
    bgAlt: Color.Grey800,
    color: Color.White,
    textAlt: Color.Grey400,
    textLight: Color.Grey500,
    shortcut: {
      bg: Color.Grey800,
      text: Color.Grey400,
    },
  },
  boardConfig: {
    filters: {
      fakeInputBg: background.tertiary,
      element: {
        bg: background.primary,
        bgHover: background.hoverSoft,
        bgFocus: background.hover,
        color: Color.Grey900,
      },
    },
    toolbar: {
      viewTypesBg: background.secondary,
      selectedViewTypeBg: background.primary,
      button: {
        bg: background.secondary,
      },
    },
    button: {
      bg: background.secondary,
      bgHover: background.tertiary,
    },
    preview: {
      bg: Color.Grey100,
      swimlane: {
        bg: Color.Blue100,
        border: border.surprisingGrey,
      },
      avatar: {
        bg: Color.Grey300,
        enableUserColor: true,
      },
      group: {
        bg: Color.Grey200,
      },
      card: {
        bg: Color.White,
        title: {
          bg: background.tertiary,
        },
      },
    },
  },
  customProperty: {
    input: {
      bgHover: background.grey,
    },
  },
  buttonWarning: LegacyColor.Red,
  commandbar: {
    color: text.primary,
    bg: rgba(background.primary, 0.88),
    bgFilter: 'blur(25px)',
    outline: '1px solid rgba(0, 0, 0, 0.06)',
    borderFullProp: 'none',
    shadow: `
      0px 0px 120px rgba(43, 43, 43, 0.1),
      0px 34px 64px rgba(43, 43, 43, 0.12),
      0px 0px 32px rgba(43, 43, 43, 0.04),
      0px 24px 24px rgba(43, 43, 43, 0.04),
      0px 4px 24px rgba(43, 43, 43, 0.04),
      0px 4px 4px rgba(43, 43, 43, 0.04),
      0 0 0 1px rgba(0, 0, 0, 0.06)
    `,
    category: {
      color: text.disabled,
      bg: background.primary,
    },
    input: {
      bg: LegacyColor.Transparent,
      color: text.primary,
      placeholder: transparentize(0.7, text.primary),
      icon: transparentize(0.7, text.primary),
    },
    action: {
      color: Color.Grey800,
      colorHover: Color.Grey800,
      bgHover: background.tertiary,
    },
    actionIcon: {
      color: text.disabled,
    },
    tag: {
      bg: background.tertiary,
      color: text.primary,
    },
    shortCut: {
      color: text.disabled,
      bg: background.disabled,
    },
    highlight: {
      bg: background.highlight,
      color: 'inherit',
    },
    createDoc: {
      color: 'hsl(var(--cycle))',
    },
  },
  settings: {
    bg: background.primary,
    navigationItem: {
      bgHover: Color.Grey200,
      draggingBg: rgba(Color.White, 0.94),
      draggingColor: Color.Black,
      draggingBorderColor: Color.Grey300,
      menu: {
        bgHover: Color.White,
      },
    },
    row: {
      bgHover: transparentize(0.6, background.secondary),
    },
    sidebar: {
      grab: {
        color: Color.Grey700,
      },
    },
    section: {
      bg: background.primary,
      bgAlt: background.secondary,
      border: rgba(Color.Black, 0.06),
      info: text.secondary,
    },
    listItem: {
      bg: background.secondary,
      bgHover: background.tertiary,
    },
  },
  workflows: {
    status: {
      bg: Color.Grey100,
      bgHover: Color.Grey200,
    },
    button: {
      color: Color.Grey500,
      colorHover: Color.Grey700,
      bg: Color.Grey100,
      bgHover: Color.Grey200,
      bgActive: Color.Grey300,
    },
    buttonAlt: {
      color: Color.Grey700,
      colorHover: Color.Grey800,
      bg: Color.Grey200,
      bgHover: Color.Grey300,
      bgActive: darken(0.05, Color.Grey300),
    },
  },
  settingsModal: {
    leftPanel: {
      bg: background.secondary,
    },
    rightPanel: {
      bg: background.primary,
      appearance: {
        selectionColor: Color.Blue500,
      },
      notifications: {
        setupSlack: {
          bg: background.secondary,
        },
      },
    },
  },
  docMention: {
    bg: Color.Grey200,
  },
  appPreview: {
    border: border.greyLight,
  },
  emojiPicker: {
    theme: 'light',
  },
  docPanelSeparator: {
    color: Color.Grey300,
  },
  mark: {
    color: 'unset',
    bg: Color.Yellow300,
  },
  helpCenter: {
    button: {
      bg: Color.White,
    },
  },

  customers: {
    link: {
      text: Color.Black,
      textHover: Color.Black,
      bg: Color.White,
      bgHover: Color.Grey100,
      border: rgba(Color.Black, 0.06),
    },
  },

  ui: {
    info: {
      default: {
        title: Color.Grey800,
        text: Color.Grey700,
        bg: Color.Grey100,
        bgHover: Color.Grey200,
      },
      danger: {
        title: Color.Red800,
        text: Color.Red700,
        bg: Color.Red100,
        bgHover: Color.Red100,
      },
    },
  },
  logo: {
    main: '#2E48ED',
    alt: Color.Black,
  },
  popup: {
    bg: Color.White,
    separator: rgba(Color.Black, 0.1),
  },
  billing: {
    section: {
      bg: Color.White,
      border: 'rgba(0, 0, 0, 0.06)',
      shadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.03)',
      separator: Color.Grey200,
    },
    history: {
      bg: Color.Grey100,
    },
    plan: {
      free: {
        bg: 'rgba(132, 35, 255, 0.10)',
        color: '#8423FF',
        badge: {
          bg: '#8423FF',
        },
      },
      standard: {
        bg: Color.Blue200,
        color: Color.Blue500,
        badge: {
          bg: Color.Cycle,
        },
      },
      business: {
        bg: Color.Grey200,
        color: text.primary,
        badge: {
          bg: Color.Grey850,
        },
      },
    },
    discount: {
      annual: {
        bg: Color.Green200,
        color: Color.Green800,
        border: Color.Green800,
      },
    },
  },
  onboarding: {
    background: {
      body: background.primary,
      aside: Color.Grey200,
      backdrop: 'rgba(191, 191, 191, 0.68)',
      docPanel: background.primary,
    },
    integration: {
      circle: Color.Blue300,
    },
  },
  onboardingSlack: {
    congratulations: {
      tooltip: {
        bg: Color.Blue300,
        color: 'hsl(var(--cycle))',
      },
    },
  },
  lightOnboarding: {
    layout: {
      headline: {
        color: Color.Blue600,
      },
      card: {
        bg: Color.White,
      },
    },
    background: {
      body: 'linear-gradient(137.69deg, #FFFFFF 6%, #EEEFF7 97.55%)',
      main: background.primary,
      aside: `radial-gradient(70.29% 94.66% at 78.96% 52.95%,
        rgba(39, 91, 226, 0.10) 0%,
        rgba(255, 255, 255, 0.00) 100%),
        radial-gradient(51.53% 54.45% at 80.35% 45.83%,
        rgba(248, 206, 255, 0.00) 0%,
        rgba(242, 187, 251, 0.16) 56.77%,
        rgba(255, 255, 255, 0.00) 100%),
        linear-gradient(346deg, rgba(255, 255, 255, 0.00) 0%,
        rgba(171, 255, 225, 0.24) 35.91%,
        rgba(255, 255, 255, 0.00) 100%)`,
      quote: 'linear-gradient(139deg, #FFF 0%, rgba(255, 255, 255, 0.29) 77.48%, rgba(255, 255, 255, 0.51) 100%)',
    },
    themePreview: {
      light: {
        bg: Color.Grey200,
        main: Color.Grey100,
        bar: Color.White,
        outline: Color.Grey200,
      },
      dark: {
        bg: Color.Grey800,
        main: Color.Grey900,
        bar: Color.Black,
        outline: 'transparent',
      },
    },
    asideApp: {
      gradient: 'linear-gradient(164.2deg,rgba(36,36,36,0) 37.74%,rgb(255 255 255) 77.11%)',
      container: {
        border: border.primary,
        bg: 'rgba(255, 255, 255, 0.7)',
      },
      sidebar: {
        bg: Color.White,
      },
      sidebarItem: {
        color: Color.Grey400,
        bg: Color.Grey300,
      },
    },
    integration: {
      circle: Color.Blue400,
      installed: {
        color: Color.Green800,
      },
    },
    doneLoader: {
      color: Color.Grey300,
    },
    sources: {
      selectedBorder: border.primary,
      selected: 'hsl(var(--cycle100))',
      itemShadow: BOX_SHADOW_COLOR_Z1,
    },
  },
  home: {
    bg: Color.Grey100,
    separator: Color.Grey300,
    card: {
      bg: Color.White,
      bgHover: lighten(0.01, Color.Grey100),
    },
    search: {
      bg: Color.Grey200,
      text: Color.Grey700,
    },
    cmdk: {
      bg: Color.Grey300,
      text: Color.Grey500,
    },
    table: {
      header: Color.Grey100,
      hover: Color.Grey100,
      bg: Color.White,
    },
    integrations: {
      logo: {
        bg: Color.White,
      },
      slack: {
        bg: Color.Blue200,
      },
      modal: {
        border: Color.Grey300,
      },
    },
  },
  inbox: {
    sources: {
      bg: transparentize(1, Color.Grey200),
      bgHover: Color.Grey200,
    },
    zero: {
      item: {
        text: background.disabled,
        description: background.tertiary,
        bg: Color.White,
      },
    },
    collectMore: {
      bg: Color.Grey100,
    },
  },
  chrome: {
    skeleton: {
      bg: Color.White,
      title: background.tertiary,
    },
  },
  components: {
    AudioPlayer: {
      bg: background.primary,
      border: 'rgba(0, 0, 0, 0.06)',
      volumeBg: Color.Grey100,
      volumeProgressBg: Color.Grey300,
      volumeProgress: Color.Grey400,
    },
    EditorAiMenu: {
      bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.91) 0%, rgba(255, 255, 255, 0.91) 100%), linear-gradient(91deg, #A990FF 0%, #57CFEF 52.39%, #7FEA96 100%)',
      buttonColor: '#171618',
      buttonBg: Color.White,
      buttonBorder: 'rgba(0, 0, 0, 0.06)',
    },
    InsightCard: {
      card: {
        bg: Color.White,
        outline: Color.Blue400,
        border: Color.Grey300,
      },
      quote: {
        bg: background.secondary,
        bgHover: background.tertiary,
      },
    },
    InsightCardHieraryInsight: {
      card: {
        bg: Color.Grey100,
      },
      quote: {
        bg: Color.Grey200,
        bgHover: darken(0.05, Color.Grey100),
      },
    },
    EditorIntegrationCard: {
      border: Color.Grey300,
    },
    FeedbackStatus: {
      [StatusCategory.NotStarted]: {
        main: Color.Grey400,
        text: Color.Grey900,
        bg: Color.Grey100,
        bgAlt: Color.Grey100,
        bgGroup: Color.Grey200,
        bgHover: Color.Grey200,
        bgActive: Color.Grey300,
        bgOpaque: Color.Grey200,
      },
      [StatusCategory.Started]: {
        main: Color.Blue800,
        text: Color.Blue800,
        bg: Color.Blue200,
        bgAlt: Color.Blue100,
        bgGroup: Color.Blue200,
        bgHover: darken(0.05, Color.Blue200),
        bgActive: darken(0.1, Color.Blue200),
        bgOpaque: Color.Blue200,
      },
      [StatusCategory.Completed]: {
        main: Color.Green800,
        text: Color.Green800,
        bg: '#E1F3E5',
        bgAlt: 'rgb(240, 249, 240)',
        bgGroup: '#E1F3E5',
        bgHover: darken(0.05, '#E1F3E5'),
        bgActive: darken(0.1, '#E1F3E5'),
        bgOpaque: '#E1F3E5',
      },
      [StatusCategory.Canceled]: {
        main: Color.Red800,
        text: Color.Red800,
        bg: Color.OrangeRed200,
        bgAlt: 'rgb(254, 239, 236)',
        bgGroup: Color.OrangeRed200,
        bgHover: darken(0.05, Color.OrangeRed200),
        bgActive: darken(0.1, Color.OrangeRed200),
        bgOpaque: Color.OrangeRed200,
      },
    },
    FreePlan: {
      default: {
        bg: Color.Grey100,
        bgHover: Color.Grey200,
        text: {
          primary: Color.Black,
          secondary: Color.Grey700,
        },
        cta: {
          bg: Color.Cycle,
          bgHover: lighten(0.05, Color.Cycle),
          bgActive: lighten(0.1, Color.Cycle),
          text: Color.White,
        },
      },
      warning: {
        bg: Color.Orange200,
        text: {
          primary: Color.Black,
          secondary: Color.Orange600,
        },
        cta: {
          bg: Color.Orange600,
          bgHover: lighten(0.05, Color.Orange600),
          bgActive: lighten(0.1, Color.Orange600),
          text: Color.White,
        },
      },
      danger: {
        bg: Color.Red200,
        text: {
          primary: Color.Black,
          secondary: Color.Red600,
        },
        cta: {
          bg: rgba(Color.Red600, 0.1),
          bgHover: rgba(Color.Red600, 0.15),
          bgActive: rgba(Color.Red600, 0.2),
          text: Color.Red800,
        },
      },
    },
    Toaster: {
      bg: Color.White,
      border: rgba(0, 0, 0, 0.06),
      color: Color.Grey700,
      light: Color.Grey400,
    },
    ProductTourModal: {
      bg: Color.Blue500,
      text: Color.White,
    },
    LearningCenterModal: {
      bg: Color.White,
      bgHover: Color.Grey100,
    },
    MobileHeader: {
      border: Color.Grey200,
      closeButton: {
        color: 'hsl(var(--cycle500))',
      },
    },
    MobileFooter: {
      bg: Color.White,
      bgActive: Color.Grey200,
      border: transparentize(0.96, Color.Black),
    },
    StateToaster: {
      bg: Color.Grey800,
      text: Color.White,
    },
    OfflineBadge: {
      bg: Color.Grey200,
      text: Color.Grey700,
    },
    Badge: {
      bg: Color.Grey200,
      text: Color.Grey700,
    },
    Tab: {
      bg: Color.Transparent,
      bgHover: Color.Grey200,
      bgActive: darken(0.1, Color.Grey200),
      text: Color.Grey500,
      textHover: Color.Grey700,
      textActive: Color.Black,
    },
    TableOfContentNodeView: {
      text: Color.Cycle,
      bg: Color.Blue200,
    },
  },
  login: {
    bg: background.primary,
    shadow: '0px 3.25px 4px -1.5px rgba(56, 56, 138, 0.05), 0px 24.5px 70px -11.25px rgba(56, 56, 138, 0.15);',
    link: { userColor: true },
    wrapper: 'linear-gradient(137.69deg, #FFFFFF 6%, #EEEFF7 97.55%)',
  },
  icons: {
    CommentChatIcon: {
      primary: text.disabled,
      secondary: Color.Grey400,
    },
  },
  ugradeLayout: {
    bg: Color.White,
    desc: text.secondary,
    // already maker, current plan
    activeText: {
      color: text.blue,
    },
    mainBox: {
      bg: Color.White,
    },
    pricingToggle: {
      switch: {
        bg: 'rgba(0, 0, 0, 0.04)',
      },
      switchToggle: {
        bg: text.white,
        border: 'transparent',
        color: text.primary,
      },
      discount: {
        color: 'hsl(var(--cycle))',
        bg: 'hsla(var(--cycle), 0.1)',
      },
    },
  },
};

export const nuance: ColorNuance = {
  a: {
    bg: '#1557FF1A',
    main: '#1557FF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: '#E7EEFF',
  },
  b: {
    bg: '#1681FF1A',
    main: '#1681FF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: '#D9E8FD',
  },
  c: {
    bg: '#13CDA01A',
    main: '#00BA8D',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: '#DFF3DF',
  },
  d: {
    bg: '#13CD661A',
    main: '#0BBC5A',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: '#DFF3DF',
  },
  e: {
    bg: '#BE06FF1A',
    main: '#BE06FF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.Purple200,
  },
  f: {
    bg: '#9206FF1A',
    main: '#9206FF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.Purple200,
  },
  g: {
    bg: '#D91BF81A',
    main: '#D91BF8',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.Pink200,
  },
  h: {
    bg: '#611BF81A',
    main: '#611BF8',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.Violet300,
  },
  i: {
    bg: '#F15D3C1A',
    main: '#FF512B',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.OrangeRed200,
  },
  j: {
    bg: '#FBAA301A',
    main: '#FF9B05',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.Orange300,
  },
  k: {
    bg: '#FBCE301A',
    main: '#E5A500',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.Yellow300,
  },
  l: {
    bg: '#F13D3C1A',
    main: '#FC3331',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: Color.Red200,
  },
};

for (const key of Object.keys(nuance)) {
  const color = key as ColorApi;
  nuance[color].textHighlight = opacify(0.15, nuance[color].bg);
  nuance[color].markHighlight = opacify(0.12, nuance[color].bg);
  nuance[color].markHighlightBorder = opacify(0.18, nuance[color].bg);
}
