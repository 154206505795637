import { useApolloClient, useSubscription } from '@apollo/client';
import { NbActiveInsightsSubscription, NbActiveInsightsDocument, NbActiveInsightsSubscriptionVariables } from '@cycle-app/graphql-codegen';

import { useProduct } from './useProduct';

export const useNbActiveInsightsUpdatedSubscription = (productId?: string | null) => {
  const { cache } = useApolloClient();
  const { product } = useProduct('cache-only');

  return useSubscription<NbActiveInsightsSubscription, NbActiveInsightsSubscriptionVariables>(NbActiveInsightsDocument, {
    variables: {
      productId: productId as string,
    },
    onSubscriptionData({ subscriptionData: { data } }) {
      if (!productId || !data || !product) return;
      cache.modify({
        id: cache.identify(product),
        fields: {
          nbActiveInsights: () => data?.nbActiveInsights,
        },
      });
    },
  });
};
