import styled from 'styled-components';

import { DropdownLayer } from 'src/components/DropdownLayer';
import PortalModal from 'src/components/PortalModal/PortalModal';

export const StyledDropdownLayer = styled(DropdownLayer)`
  width: 350px;
`;

export const StyledPortalModal = styled(PortalModal)`
  padding: 0;
  border-radius: 0;
`;
