import { isSafari } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

export interface ShyScrollbarProps {
  $smooth?: boolean;
  disable?: boolean;
  $overflow?: string;
}

export const ShyScrollbarCss = css<ShyScrollbarProps>`
  ${p => p.$smooth && 'scroll-behavior: smooth'};
  scrollbar-color: transparent transparent;

  scrollbar-width: thin;
  ${p => (p.disable
    ? 'overflow: hidden;'
    : (p.$overflow ?? `
      overflow: auto;
      overflow: overlay; /* Wekbit and Blink based browsers */
    `))};

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    /* This is a hack to add space around the scrollbar */
    /* The scrollbar width will be (parent width) - (border * 2) */
    background-clip: padding-box;
    border: 2px solid transparent;
    background-color: ${p => (isSafari ? p.theme.colors.scrollbar.color : 'rgba(0, 0, 0, 0)')};
    border-radius: 40px;
  }

  &:hover {
    scrollbar-color: ${(p) => p.theme.colors.scrollbar.color} transparent;

    &::-webkit-scrollbar-thumb {
      background-color: ${(p) => p.theme.colors.scrollbar.color};

      &:hover {
        background-color: ${(p) => p.theme.colors.scrollbar.colorHover};
      }
    }
  }
`;

export const ShyScrollbar = styled.div<ShyScrollbarProps>`
  ${ShyScrollbarCss};
`;
