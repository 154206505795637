import { LINEAR_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { IntegrationType } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { Events, Methods } from 'src/constants/analytics.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useLinkLinearIssue } from 'src/hooks/api/mutations/integrations/useLinkLinearIssue';
import { useDocId } from 'src/hooks/usePathParams';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getLinearIssueId, getLinearProjectId } from 'src/utils/embeddable.util';

import { useLinearIssues } from '../api/queries/integrations/useLinearIssues';
import { useLinearProjects } from '../api/queries/integrations/useLinearProjects';

/**
 * Hook to embed a linear issue in a tiptap editor
  * Side effects: undo the last action, insert the issue, and link the issue to the doc
 * @param url - the url of the issue
 * @param isEmbedding - whether the user is currently trying to embed the issue
 * @param onCompleted - callback to run when the issue is embedded
 * @returns an object containing the loading state and whether the url matches
 * */
export const useEmbedLinearIssue = (url: string, isEmbedding: boolean, onCompleted: () => boolean) => {
  const docId = useDocId();
  const editor = useEditorContext(ctx => ctx.editor);
  const linearIntegrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  const linkMutation = useLinkLinearIssue();

  const issueIdFromUrl = getLinearIssueId(url);
  const projectIdFromUrl = getLinearProjectId(url);

  const {
    issues, isLoading: issuesLoading, error: issuesError,
  } = useLinearIssues(linearIntegrationId || '', issueIdFromUrl || '', { skip: !issueIdFromUrl });
  const {
    projects, isLoading: projectsLoading, error: projectsError,
  } = useLinearProjects(linearIntegrationId || '', projectIdFromUrl || '', { skip: !projectIdFromUrl });

  const issueId = issues.find(issue => !!issueIdFromUrl && issue.url?.includes(issueIdFromUrl))?.id;
  const projectId = projects.find(project => !!projectIdFromUrl && project.url.includes(projectIdFromUrl))?.id;

  useEffect(() => {
    const mentionId = issueId || projectId;
    if (!isEmbedding || !docId || !mentionId) return;

    queueMicrotask(() => {
      editor.commands.undo();
      editor
        .chain()
        .focus()
        .insertContent({
          type: LINEAR_EXTENSION_NAME,
          attrs: {
            id: mentionId,
            type: issueId ? 'issue' : 'project',
          },
        })
        .createParagraphNear()
        .command(onCompleted)
        .run();
    });

    if (issueId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      linkMutation.link({
        variables: {
          docId,
          issueId,
        },
      });
    }

    trackAnalytics(Events.IntegrationIssueMentioned, {
      type: IntegrationType.Linear,
      method: Methods.Paste,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmbedding, docId, issueId, projectId]);

  return {
    mentionId: issueId || projectId,
    isLoading: issuesLoading || projectsLoading,
    match: !!issueIdFromUrl || !!projectIdFromUrl,
    error: !!issuesError || (!!issueIdFromUrl && !issueId) || !!projectsError || (!!projectIdFromUrl && !projectId),
  };
};
