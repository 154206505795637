import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import { LocalKey } from 'src/types/localStorage.types';

type Options<T> = {
  key: LocalKey;
  schema?: z.Schema<T>;
};

/**
 * Hook to use state with localStorage persistence and optional schema validation
 * The state type is inferred from the default value and the schema if provided
 *
 * @example
 * const [count, setCount] = useStateWithStorage(null, {
 *  key: 'count',
 *  schema: z.number().int().nullable(),
 * });
 */
export const useStateWithStorage = <T>(defaultValue: T, {
  key, schema,
}: Options<T>) => {
  const [state, setState] = useState<T>(() => {
    const item = window.localStorage.getItem(key);
    if (item === null) return defaultValue;
    try {
      const value = JSON.parse(item);
      return schema ? schema.parse(value) : value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error parsing item from localStorage: ${key}`, error);
      return defaultValue;
    }
  });

  const setLocalStorageItem = debounce((value: T) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, 1000);

  useEffect(() => {
    setLocalStorageItem(state);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [state, setState] as const;
};

/**
 * Hook to use a boolean state with a toggle callback, localStorage persistence and schema validation
 *
 * @example
 * const [isDarkMode, toggleDarkMode] = useToggleWithStorage(false, { key: 'dark-mode });
 */
export const useToggleWithStorage = (defaultValue: boolean, { key }: Omit<Options<boolean>, 'schema'>) => {
  const [state, setState] = useStateWithStorage(defaultValue, {
    key,
    schema: z.boolean(),
  });
  const toggleState = () => setState(!state);
  return [state, toggleState, setState] as const;
};
