import { LinearIssueFullFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { InfosTitle, StyledSelectButton } from 'src/components/LinearEditorMentionView/LinearEditorMentionView.styles';
import { useUpdateLinearIssue } from 'src/hooks/api/mutations/integrations/useUpdateLinearIssue';
import { Layer } from 'src/types/layers.types';

import { PrioritySvg } from './DocLinear.styles';
import sprite from './linear-priority-sprite.svg';

export const IssuePriority = ({ issue }: { issue: LinearIssueFullFragment }) => {
  const { updateLinearIssue } = useUpdateLinearIssue();
  const selected = issue.priority != null ? priorityOptions[issue.priority] : null;
  return (
    <div>
      <InfosTitle>Priority</InfosTitle>
      <ToggleDropdown
        layer={Layer.DropdownModalZ4}
        placement="bottom-start"
        content={props => (
          <SelectPanel
            options={priorityOptions}
            hideSearch
            onOptionChange={o => {
              const priority = Number(o.value);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              updateLinearIssue(issue.id, { priority }, {
                ...issue,
                priority,
              });
              props.hide();
            }}
          />
        )}
        button={props => (
          <StyledSelectButton
            {...props}
          >
            {selected?.icon}
            {selected?.label}
          </StyledSelectButton>
        )}
      />
    </div>
  );
};

const priorityOptions: SelectOption[] = [{
  value: '0',
  label: 'No priority',
  icon: <PrioritySvg><use href={`${sprite}#priority0`} /></PrioritySvg>,
}, {
  value: '1',
  label: 'Urgent',
  icon: <PrioritySvg><use href={`${sprite}#priority1`} /></PrioritySvg>,
}, {
  value: '2',
  label: 'High',
  icon: <PrioritySvg><use href={`${sprite}#priority2`} /></PrioritySvg>,
}, {
  value: '3',
  label: 'Medium',
  icon: <PrioritySvg><use href={`${sprite}#priority3`} /></PrioritySvg>,
}, {
  value: '4',
  label: 'Low',
  icon: <PrioritySvg><use href={`${sprite}#priority4`} /></PrioritySvg>,
}];
