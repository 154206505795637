import { ProductBaseFragment } from '@cycle-app/graphql-codegen';
import { FC, HTMLAttributes } from 'react';

import {
  ContainerProps as ContainerPropsStyled,
  Container,
  Logo,
  ProductInfo,
  ProductName,
  Members,
  ActiveIcon,
} from './ProductItem.styles';

type ContainerProps = ContainerPropsStyled & HTMLAttributes<HTMLDivElement>;

interface Props extends ContainerProps {
  product: ProductBaseFragment;
  isActive: boolean;
  disableHover?: boolean;
  hideMembers?: boolean;
}

const ProductItem: FC<React.PropsWithChildren<Props>> = ({
  product,
  isActive,
  onClick,
  disableHover,
  hideMembers,
  ...containerProps
}) => (
  <Container
    onClick={onClick}
    $disableHover={disableHover}
    {...containerProps}
  >
    <Logo
      bgSrc={product.logo?.url}
    />
    <ProductInfo>
      <ProductName>
        {product.name}
      </ProductName>
      {!hideMembers && (
        <Members>
          {membersLabel(product.userCount)}
        </Members>
      )}
    </ProductInfo>
    {isActive && (
      <ActiveIcon />
    )}
  </Container>
);

export default ProductItem;

const membersLabel = (count: number) => {
  if (count === 0) return 'No members';
  if (count === 1) return '1 member';
  return `${count} members`;
};
