import { Emoji } from '@cycle-app/ui';

import { useIsMobile } from 'src/reactives/responsive.reactive';
import { DocTypeLabelData } from 'src/utils/docType.util';

import { Tag } from './NewDoc.styles';

export const NewDocPlaceholder = ({
  child, parent, canGoUp = false, canGoDown = false,
}: {
  child?: DocTypeLabelData;
  parent?: DocTypeLabelData;
  canGoUp?: boolean;
  canGoDown?: boolean;
}) => {
  const isMobile = useIsMobile();

  if (isMobile) return <>Untitled</>;

  if (canGoDown && canGoUp) {
    return (
      <>
        Enter a name, press
        {' '}
        <Tag>Tab</Tag>
        {' '}
        to create
        {' '}
        <DocTypeTag docType={child} />
        {' '}
        or press
        {' '}
        <Tag>Shift + Tab</Tag>
        {' '}
        to create
        {' '}
        <DocTypeTag docType={parent} />
      </>
    );
  }
  if (canGoDown) {
    return (
      <>
        Enter a name or press
        {' '}
        <Tag>Tab</Tag>
        {' '}
        to create
        {' '}
        <DocTypeTag docType={child} />
      </>
    );
  }
  if (canGoUp) {
    return (
      <>
        Enter a name or press
        {' '}
        <Tag>Shift + Tab</Tag>
        {' '}
        to create
        {' '}
        <DocTypeTag docType={parent} />
      </>
    );
  }
  return <>Untitled</>;
};

const DocTypeTag = ({ docType }: { docType?: DocTypeLabelData | null }) => {
  return (
    <Tag>
      <Emoji emoji={docType?.emoji} size={12} />
      {' '}
      {docType?.name}
    </Tag>
  );
};
