import { VerifyEmailDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

export const useVerifyEmail = () => {
  const [verifyEmailMutation, {
    loading: isLoading, error,
  }] = useSafeMutation(VerifyEmailDocument, {
    displayErrorMessages: false,
    preventToaster: true,
  });

  const verifyEmail = (email: string) => verifyEmailMutation({ variables: { email } });

  return {
    verifyEmail,
    isLoading,
    error,
  };
};
