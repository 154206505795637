import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GroupStartedIcon: FC<SvgProps> = (props) => (
  <Svg {...props} data-icon-status width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 1.16667C6.84529 1.16667 6.69691 1.22813 6.58752 1.33752C6.47812 1.44692 6.41666 1.59529 6.41666 1.75V2.91667C6.41666 3.07138 6.47812 3.21975 6.58752 3.32915C6.69691 3.43854 6.84529 3.5 7 3.5C7.15471 3.5 7.30308 3.43854 7.41248 3.32915C7.52187 3.21975 7.58333 3.07138 7.58333 2.91667V1.75C7.58333 1.59529 7.52187 1.44692 7.41248 1.33752C7.30308 1.22813 7.15471 1.16667 7 1.16667Z" fill="#081777" />
    <path d="M12.25 6.41667H11.0833C10.9286 6.41667 10.7803 6.47813 10.6709 6.58752C10.5615 6.69692 10.5 6.84529 10.5 7C10.5 7.15471 10.5615 7.30308 10.6709 7.41248C10.7803 7.52188 10.9286 7.58333 11.0833 7.58333H12.25C12.4047 7.58333 12.5531 7.52188 12.6625 7.41248C12.7719 7.30308 12.8333 7.15471 12.8333 7C12.8333 6.84529 12.7719 6.69692 12.6625 6.58752C12.5531 6.47813 12.4047 6.41667 12.25 6.41667Z" fill="#081777" />
    <path d="M3.5 7C3.5 6.84529 3.43854 6.69692 3.32914 6.58752C3.21975 6.47813 3.07137 6.41667 2.91666 6.41667H1.75C1.59529 6.41667 1.44691 6.47813 1.33752 6.58752C1.22812 6.69692 1.16666 6.84529 1.16666 7C1.16666 7.15471 1.22812 7.30308 1.33752 7.41248C1.44691 7.52188 1.59529 7.58333 1.75 7.58333H2.91666C3.07137 7.58333 3.21975 7.52188 3.32914 7.41248C3.43854 7.30308 3.5 7.15471 3.5 7Z" fill="#081777" />
    <path d="M3.62833 2.91842C3.5137 2.81627 3.36377 2.7628 3.21037 2.76936C3.05696 2.77592 2.91215 2.84199 2.80665 2.95356C2.70116 3.06512 2.64328 3.21341 2.64531 3.36694C2.64733 3.52047 2.7091 3.66717 2.8175 3.77592L3.6575 4.58675C3.71387 4.64118 3.78063 4.6837 3.85379 4.71176C3.92695 4.73982 4.00502 4.75286 4.08333 4.75008C4.16191 4.74978 4.23962 4.73361 4.31179 4.70254C4.38396 4.67146 4.44911 4.62613 4.50333 4.56925C4.61198 4.45996 4.67296 4.31211 4.67296 4.158C4.67296 4.00389 4.61198 3.85605 4.50333 3.74675L3.62833 2.91842Z" fill="#081777" />
    <path d="M9.91667 4.75008C10.0669 4.74948 10.211 4.69098 10.3192 4.58675L11.1592 3.77592C11.2616 3.66885 11.3195 3.52679 11.321 3.37859C11.3226 3.23039 11.2676 3.08717 11.1674 2.97801C11.0671 2.86886 10.9291 2.80195 10.7813 2.79088C10.6335 2.77981 10.4871 2.82541 10.3717 2.91842L9.53167 3.74675C9.42302 3.85604 9.36204 4.00389 9.36204 4.158C9.36204 4.31211 9.42302 4.45995 9.53167 4.56925C9.63267 4.67583 9.77015 4.74041 9.91667 4.75008Z" fill="#081777" />
    <path d="M7 10.5C6.84529 10.5 6.69691 10.5615 6.58752 10.6709C6.47812 10.7803 6.41666 10.9286 6.41666 11.0833V12.25C6.41666 12.4047 6.47812 12.5531 6.58752 12.6625C6.69691 12.7719 6.84529 12.8333 7 12.8333C7.15471 12.8333 7.30308 12.7719 7.41248 12.6625C7.52187 12.5531 7.58333 12.4047 7.58333 12.25V11.0833C7.58333 10.9286 7.52187 10.7803 7.41248 10.6709C7.30308 10.5615 7.15471 10.5 7 10.5Z" fill="#081777" />
    <path d="M10.3425 9.415C10.2311 9.30748 10.0816 9.24861 9.92677 9.25134C9.77197 9.25408 9.6246 9.31819 9.51708 9.42958C9.40956 9.54098 9.35069 9.69052 9.35342 9.84531C9.35616 10.0001 9.42027 10.1475 9.53166 10.255L10.3717 11.0833C10.4825 11.1904 10.6312 11.2493 10.7853 11.2472C10.9394 11.245 11.0864 11.1819 11.1942 11.0717C11.2487 11.0174 11.292 10.9528 11.3215 10.8817C11.351 10.8107 11.3662 10.7345 11.3662 10.6575C11.3662 10.5805 11.351 10.5043 11.3215 10.4333C11.292 10.3622 11.2487 10.2976 11.1942 10.2433L10.3425 9.415Z" fill="#081777" />
    <path d="M3.6575 9.41558L2.8175 10.2264C2.76282 10.2806 2.71943 10.3452 2.68981 10.4162C2.6602 10.4873 2.64495 10.5636 2.64495 10.6406C2.64495 10.7176 2.6602 10.7938 2.68981 10.8649C2.71943 10.936 2.76282 11.0005 2.8175 11.0547C2.87221 11.1106 2.93758 11.1548 3.00973 11.1849C3.08188 11.2149 3.15934 11.2302 3.2375 11.2297C3.3813 11.231 3.52048 11.179 3.62833 11.0839L4.46833 10.2731C4.52349 10.2198 4.56762 10.1563 4.5982 10.086C4.62878 10.0157 4.64522 9.94004 4.64657 9.86339C4.64793 9.78675 4.63417 9.71058 4.60609 9.63925C4.57801 9.56792 4.53616 9.50282 4.48292 9.44766C4.42968 9.39251 4.36609 9.34838 4.2958 9.3178C4.22551 9.28722 4.14988 9.27078 4.07323 9.26942C3.99658 9.26807 3.92042 9.28183 3.84909 9.30991C3.77776 9.33799 3.71266 9.37984 3.6575 9.43308V9.41558Z" fill="#081777" />
  </Svg>
);
