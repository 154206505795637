import { typo, truncate } from '@cycle-app/ui';
import { NodeViewWrapper } from '@tiptap/react';
import styled from 'styled-components';

import { DragHandle } from 'src/components/EditorDragMenu';
import { nodeCommonCss } from 'src/styles';

export const ContainerNodeView = styled(NodeViewWrapper)`
  ${nodeCommonCss}
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  position: relative;

  &:hover ${DragHandle} {
    display: flex;
  }
`;

export const SlotText = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  ${truncate}
`;

export const SelectLinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 145px;
`;

export const LineSpinnerContainer = styled.div`
  ${typo.body400}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 300px;
  height: 100%;
  padding: 8px 0;
  color: ${p => p.theme.colors.text.secondary};
`;

export const SelectWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 8px;
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 3px;
`;

export const FooterValueWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const FooterLabel = styled.label`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const FooterValue = styled.button<{ isReadOnly: boolean }>`
  ${typo.body400}
  border-radius: 4px;
  padding: 0px 4px;
  color: ${p => p.theme.colors.text.primary};
  background:  ${p => (p.isReadOnly ? 'transparent' : p.theme.colors.background.tertiary)};
  cursor:  ${p => (p.isReadOnly ? 'default' : 'pointer')};
  display: inline-flex;
  align-items: flex-start;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 4px;

  &:hover {
    background:  ${p => (p.isReadOnly ? 'transparent' : p.theme.colors.background.hover)};
  }
`;
