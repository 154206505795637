import { ButtonProps } from '@cycle-app/ui';
import { QuestionOutlineIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { StyledButton } from './LearnMoreButton.styles';

type Props = ButtonProps & {
  className?: string;
  full?: boolean;
  url: string;
  tooltip?: ReactNode;
};

export const LearnMoreButton: FC<React.PropsWithChildren<Props>> = ({
  full = false, url, children, className, size = 'S', variant = 'secondary', tooltip,
}) => (
  <StyledButton
    className={className}
    iconStart={<QuestionOutlineIcon />}
    onClick={() => window.open(url, '_blank')}
    tooltip={tooltip}
    tooltipPlacement="top"
    squared={!full}
    variant={variant}
    size={size}
  >
    {full && (children || 'Learn more')}
  </StyledButton>
);
