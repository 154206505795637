import { CreateReleaseNotePosition } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';

import { useNavigate } from 'src/hooks/useNavigate';

import { useGetPermission, setLimitationsModal } from '../../reactives';
import { openCreateReleaseNote } from '../../reactives/releases.reactive';


export const ReleaseNoteAddAction  = ({ releaseId }: { releaseId: string }) => {
  const { canCreateReleaseNote } = useGetPermission();
  const { navigateToRelease } = useNavigate();

  return (
    <ActionButton
      size="XL"
      tooltip="Add release note"
      tooltipPlacement="top"
      onClick={async () => {
        if (!canCreateReleaseNote) {
          setLimitationsModal({ action: 'RELEASE_UPDATE' });
          return;
        }
        navigateToRelease(releaseId);
        await nextFrame();
        openCreateReleaseNote(releaseId, false, CreateReleaseNotePosition.End);
      }}
    >
      <AddIcon size={12} />
    </ActionButton>
  );

};