import { MeFragment } from '@cycle-app/graphql-codegen';
import { toShortLocaleDateTimeString } from '@cycle-app/utilities';

import { DROPZONE_MAX_FILES } from 'src/constants/dropzone.constants';

export const getTitle = (isMaxFilesError: boolean, isLoading: boolean) => {
  if (isMaxFilesError) return `You can’t upload more than ${DROPZONE_MAX_FILES} files`;
  if (isLoading) return 'Feedback capture in progress';
  return 'Drop anything to capture feedback';
};

export const getProgressMessage = (progress: number, total: number) => {
  return `${progress}/${total} ${total > 1 ? 'files' : 'file'} uploaded`;
};

export const getAudioFileName = (me?: MeFragment | null) => {
  const from = me ? ` from ${me.firstName} ${me.lastName}` : '';
  return `Voice note ${from} on ${toShortLocaleDateTimeString()}`;
};

export const getCliboardFileName = () => `Clipboard image ${toShortLocaleDateTimeString()}`;
