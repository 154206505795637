import { Skeleton } from '@cycle-app/ui';
import { Container } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';

export const InsightSkeletons = ({ count = 1 }: { count?: number }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Container
          // eslint-disable-next-line react/no-array-index-key
          key={`skeleton-${index}`}
          $withBorder
          style={{ cursor: 'default' }}
        >
          <Skeleton height={14} width={200} />
          <Skeleton height={14} />
        </Container>
      ))}
    </>
  );
};
