import { SelectPanel } from '@cycle-app/ui';
import { useState } from 'react';

import { useProducts } from 'src/hooks/api/useProducts';

import { Logo } from './Product.styles';
import { useProductBase } from './useProductBase';
import { useSetProductSlug } from './useSetProductSlug';

export type ProductsPanelProps = {
  onDone: VoidFunction;
  onChange?: (slug?: string) => void;
  className?: string;
};

export const ProductsPanel = ({
  onDone, onChange, className,
}: ProductsPanelProps) => {
  const setNewDocProductSlug = useSetProductSlug();
  const { product: productBase } = useProductBase();
  const [searchText, setSearchText] = useState('');

  const {
    products, loading, hasNextPage, fetchNextPage,
  } = useProducts({ searchText });

  return (
    <SelectPanel
      className={className}
      autoFocusSearch
      debounceSearch
      onSearchChange={setSearchText}
      selectedValue={productBase?.slug}
      options={products.map(product => ({
        value: product.slug,
        label: product.name,
        icon: product.logo?.url && <Logo src={product.logo.url} />,
      }))}
      onOptionChange={option => {
        onChange?.(products.find(p => p.slug === option.value)?.slug);
        setNewDocProductSlug(option.value);
        onDone();
      }}
      infiniteScroll={{
        disabled: false,
        isLoading: loading,
        hasMoreData: hasNextPage,
        loadMore: fetchNextPage,
      }}
    />
  );
};
