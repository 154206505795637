/* eslint-disable react/destructuring-assignment */

import Tippy, { TippyProps } from '@tippyjs/react';
import { useState } from 'react';

export type LazyTippyProps = TippyProps;

/**
 * LazyTippy will only render the content elements if the tippy is mounted to the DOM.
 *
 * Replace <Tippy /> with <LazyTippy /> component and it should work the same.
 *
 * Useful in performance-sensitive scenarios or cases where mounting the component
 * should fire effects only when the tippy mounted.
 *
 * @see https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
 */
export const LazyTippy = (props: LazyTippyProps) => {
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const computedProps = { ...props };

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    const { render } = props; // let TypeScript safely derive that render is not undefined
    computedProps.render = (...args) => (mounted ? render(...args) : '');
  } else {
    computedProps.content = mounted ? props.content : '';
  }

  return <Tippy {...computedProps} />;
};
