import { Button } from '@cycle-app/ui';
import { RightIcon } from '@cycle-app/ui/icons';

export const OnboardingFooter = () => {
  return (
    <div className="flex items-center gap-2">
      <span className="text-secondary">
        Not quite ready for an onboarding?
      </span>
      <Button size="M" variant="nospace-primary" onClick={() => window.open('https://www.cycle.app/explore', '_blank')}>
        See Cycle in action
        <RightIcon />
      </Button>
    </div>
  );
};
