import { WarningIcon } from '@cycle-app/ui/icons';
import { useEffect } from 'react';

import { HELP_URL_INSIGHTS } from 'src/constants/help.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { getAttributeOptionLabel } from 'src/utils/boardConfig/boardConfig.util';
import { addToaster, closeToaster } from 'src/utils/toasters.utils';

import { GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW } from './useCrossGroupStrategy';
import { StyledButton, Container } from './usePreventDndToaster.styles';

const toasterId = 'dnd-prevent-insights';

export const usePreventDndToaster = () => {
  const boardConfigId = useBoardConfig(ctx => ctx.boardConfig?.id);
  const boardConfigGroupBy = useBoardConfig(ctx => ctx.boardConfigGroupBy);
  const isFeedbackView = useBoardConfig(ctx => ctx.isFeedbackView);

  const add = ({ isInsightInSelection }: { isInsightInSelection?: boolean }) => {
    const label = boardConfigGroupBy ? getAttributeOptionLabel(boardConfigGroupBy.property, isFeedbackView) : null;
    const isInsight =
      isInsightInSelection &&
      GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW.includes(boardConfigGroupBy?.property.__typename);

    addToaster({
      icon: <WarningIcon />,
      id: toasterId,
      closable: true,
      message: (
        <Container>
          <p>
            {label
              ? `Drag & drop from ${label.toLocaleLowerCase()} to ${label.toLocaleLowerCase()} is disabled`
              : 'Drag & drop is disabled'}
            {isInsight &&
              ' for insights'}
            .
          </p>
          {isInsight && (
            <StyledButton
              size="M"
              variant="nospace"
              onClick={() => {
                window.open(HELP_URL_INSIGHTS, '_blank');
              }}
            >
              Lean more
            </StyledButton>
          )}
        </Container>
      ),
    });
  };

  useEffect(() => {
    // Clean up when we change a board or a route.
    return () => closeToaster(toasterId);
  }, [boardConfigId]);

  return {
    add,
    close: () => closeToaster(toasterId),
  };
};
