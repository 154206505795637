const UNITS = ['B', 'kB', 'MB', 'GB', 'TB'];

type Unit = typeof UNITS[number];

type Options = {
  /** Unit to use, if not provided the best unit will be chosen */
  unit?: Unit;
  /** Hide the unit in the output */
  hideUnit?: boolean;
  formatOptions?: Intl.NumberFormatOptions;
};

/**
 * Format bytes to human readable string
 * @example formatBytes(15123) // { value: 15.1, unit: 'kB', toString: () => '15.1 kB' }
 * @example `${formatBytes(15123)}` // '15.1 kB'
 */
export const formatBytes = (bytes: number, options: Options = {
  formatOptions: { maximumFractionDigits: 1 },
}) => {
  const exponent = options.unit
    ? UNITS.indexOf(options.unit)
    : Math.min(Math.floor(Math.log10(bytes) / 3), UNITS.length - 1);
  const value = bytes / (1000 ** exponent);
  const unit = UNITS[exponent];
  return {
    value,
    unit,
    toString: () => {
      const localeString = value.toLocaleString('en-US', options.formatOptions);
      return options.hideUnit ? localeString : `${localeString} ${unit}`;
    },
  };
};
