import { LinearProject } from '@cycle-app/graphql-codegen';
import { EditorIntegrationCard, LinearProjectIcon } from '@cycle-app/ui';
import { FC } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { projectInfos } from 'src/utils/linear.utils';

import { ProjectHeaderRight, ProjectSkeleton } from './LinearEditorMentionView.styles';
import { Infos } from '../DocLinear/DocLinear.styles';
import { PreviewProjectIssues } from '../DocLinear/PreviewProjectIssues';

type Props = {
  id: LinearProject['id'];
  isSelected: boolean;
  issuesLength: number;
  notFound: boolean;
  progress: LinearProject['progress'];
  refetch: VoidFunction;
  color: string | null;
} & Pick<LinearProject, | 'icon' | 'name' | 'url'>;

export const LinearEditorMentionProject: FC<React.PropsWithChildren<Props>> = ({
  color,
  icon,
  id,
  isSelected,
  issuesLength,
  name,
  progress,
  url,
}) => {
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  return (
    <EditorIntegrationCard
      isSelected={isSelected}
      title={name || ''}
      preTitleSlot={(
        <LinearProjectIcon
          icon={icon}
          size={16}
          {...color && { color }}
        />
      )}
      actions={(
        <ProjectHeaderRight>
          {id && (
            <Infos>
              {projectInfos(progress, issuesLength)}
            </Infos>
          )}
        </ProjectHeaderRight>
      )}
      description={isLinearInstalled && !!issuesLength && <PreviewProjectIssues skeleton={<ProjectSkeleton />} />}
      hasExpandContent={isLinearInstalled && !!issuesLength}
      onClick={() => window.open(url, '_blank')}
    />
  );
};
