import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetBoardDnd,
  getValue: getBoardDnd,
  setValue: setBoardDnd,
} = make({
  defaultState: {
    dragging: false,
  },
});
