import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { Section } from './ChangelogBuilderSidebar.styles';
import { StyledButton } from './ChangelogBuilerDesignReset.styles';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';

export const ChangelogBuilerDesignReset = () => {
  const {
    watch, formState, resetField,
  } = useChangelogBuilderFormContext();
  // Logo
  const logoUrl = watch('logo_url');
  const shipBadgeColor = watch('shipBadgeColor');
  // Text
  const heroSection = watch('heroSection');
  const contentSection = watch('contentSection');
  // Colors
  const headersColor = watch('headersColor');
  const bodyColor = watch('bodyColor');
  const backgroundColor = watch('backgroundColor');
  const dividerColor = watch('dividerColor');
  const linkColor = watch('linkColor');
  // Release tags
  const releaseTagStyle = watch('releaseTagStyle');
  const releaseTagColor = watch('releaseTagColor');
  const tags = watch('tags');

  const values = {
    logo_url: logoUrl,
    shipBadgeColor,
    heroSection,
    contentSection,
    headersColor,
    bodyColor,
    backgroundColor,
    dividerColor,
    linkColor,
    releaseTagStyle,
    releaseTagColor,
    tags,
  };

  const defaultValues = pick(formState.defaultValues, Object.keys(values));
  const isDirty = !isEqual(values, defaultValues);

  return isDirty ? (
    <Section>
      <StyledButton
        size="M"
        variant="outlined-alt"
        onClick={() => {
          (Object.keys(values) as (keyof typeof values)[]).forEach(field => {
            resetField(field);
          });
          resetField('template');
        }}
      >
        Reset design changes
      </StyledButton>
    </Section>
  ) : null;
};
