import { ThemeType } from '@cycle-app/graphql-codegen';
import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const SearchText = styled.span`
  ${typo.body400}
  display: inline-block;
  background-color: ${p => (p.theme.themeType === ThemeType.Nightfall
    ? 'hsla(var(--cycle300), 0.4)'
    : 'hsla(var(--cycle), 0.15)'
  )};
  color: ${p => p.theme.colors.text.primary};
  padding: 0 4px;
  border-radius: 4px;
`;
