import { Variants } from 'framer-motion';

export const containerVariants: Variants = {
  rest: {
    height: 6,
    width: 180,
  },
  hover: {
    height: 74,
    width: 500,
  },
};

export const contentVariants: Variants = {
  rest: {
    opacity: 0,
    filter: 'blur(2px)',
    scale: 0.35,
    transition: {
      opacity: { duration: 0.3 },
      filter: { duration: 0.3 },
    },
  },
  hover: {
    opacity: 1,
    filter: 'blur(0px)',
    scale: 1,
  },
};

export const listVariants: Variants = {
  rest: {
    opacity: 0,
    height: 0,
    transition: {
      opacity: { duration: 0.2 },
      height: { duration: 0.2 },
    },
    transitionEnd: {
      display: 'none',
    },
  },
  hover: {
    display: 'block',
    opacity: 1,
    height: 'auto',
    transition: {
      opacity: { delay: 0.25 },
      height: { delay: 0.25 },
    },
  },
};
