import { InfoIconOutline } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal';
import { InsightViewSettingsModal } from 'src/components/InsightViewSettings';
import { ViewBreadcrumb } from 'src/components/ViewBreadcrumb';
import { ViewSettings, ViewActions } from 'src/components/ViewHeader';
import { AddViewButton } from 'src/components/ViewHeader/AddViewButton';
import { ViewsList } from 'src/components/ViewsList';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useInsightSection } from 'src/hooks/insight/useInsightSection';
import { useInsightTour } from 'src/hooks/useInsightTour';
import { useParentPage } from 'src/hooks/usePageId';
import { useBoardId, useBoardSlug } from 'src/hooks/usePathParams';
import { useGetPermission, useIsMobile } from 'src/reactives';
import { useBoardConfigAnalytics } from 'src/reactives/boardConfigAnalytics.reactive';

import {
  Title, Header, TopRight, TopLeft, Container, Action,
} from './Insight.styles';
import { InsightViewAnalyticsSettingsModal } from '../../InsightViewSettings/InsightViewAnalyticsSettingsModal';

export const Insight: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const boardSlug = useBoardSlug();
  const parent = useParentPage();
  if (parent !== 'insight') return null;
  if (!boardSlug) return <InsightViewsPage />;
  return <InsightViewPage>{children}</InsightViewPage>;
};

const InsightViewsPage = () => {
  const { open: openInsightTour } = useInsightTour();
  const isMobile = useIsMobile();
  const {
    isPermissionInit, canCreateView,
  } = useGetPermission();
  return (
    <>
      <Container $variant="primary">
        <Header>
          <TopLeft>
            <Title>
              Insights
            </Title>

            <Action
              tooltip="Learn more"
              tooltipPlacement="bottom"
              onClick={openInsightTour}
            >
              <InfoIconOutline size={14} />
            </Action>
          </TopLeft>

          <TopRight>
            {!isMobile && isPermissionInit && canCreateView && (
              <AddViewButton sectionParent="insight" />
            )}
          </TopRight>
        </Header>

        <ViewsList sectionParent="insight" />
      </Container>

      <BoardConfigContextProvider>
        <CreateDocModal />
      </BoardConfigContextProvider>
    </>
  );
};

const InsightViewPage = ({ children }: { children: ReactNode }) => {
  const { isPermissionInit } = useGetPermission();
  const isMobile = useIsMobile();
  return (
    <BoardConfigContextProvider>
      <Container>
        <Header>
          <ViewBreadcrumb sectionParent="insight" />

          <TopRight>
            {!isMobile && isPermissionInit && (
              <div className="flex items-center gap-1">
                <ViewSettings />
                <ViewActions />
              </div>
            )}
          </TopRight>
        </Header>

        {children}
      </Container>
      <SettingsModal />
      <SettingsAnalyticsModal />
    </BoardConfigContextProvider>
  );
};

const SettingsModal = () => {
  const { boards } = useInsightSection();
  const boardId = useBoardId();
  const boardWithConfig = boards.find(b => b.id === boardId);
  if (!boardWithConfig?.draftBoardConfig) return null;
  return (
    <InsightViewSettingsModal
      board={boardWithConfig}
      draftBoardConfigId={boardWithConfig.draftBoardConfig?.id || ''}
    />
  );
};

const SettingsAnalyticsModal = () => {
  const { boards } = useInsightSection();
  const boardId = useBoardId();
  const board = boards.find(b => b.id === boardId);
  const isOpen = useBoardConfigAnalytics();
  return (board && isOpen && (
    <InsightViewAnalyticsSettingsModal
      board={board}
    />
  )) || null;
};
