import { Editor, findParentNode } from '@tiptap/core';
import { ResolvedPos } from '@tiptap/pm/model';
import { TextSelection } from '@tiptap/pm/state';

import { NodePosition } from 'src/utils/NodePosition';

import { DetailsListOptions } from '../types';

export const insertNewDetailsList = (editor: Editor, options: DetailsListOptions) => {
  const { state } = editor;
  const { $anchor } = state.selection;
  const node = $anchor.node();

  const detailsData = findParentNode(currentNode => currentNode.type.name === options.wrapperNodeType)(state.selection);

  if (!detailsData) {
    return false;
  }

  const $contentNodePos = state.doc.resolve($anchor.pos + (node.content.size - $anchor.parentOffset) + 2);
  const contentNode = $contentNodePos.node();
  const $detailsNodePos = state.doc.resolve(detailsData.start);

  if (!contentNode || contentNode.type.name !== options.contentNodeType) {
    return false;
  }

  const targetPos = $contentNodePos.end() + 1;
  const contentDomElement = editor.view.domAtPos($contentNodePos.pos).node;

  // check if the content node of the current summary is hidden
  // if not, run the default enter behavior
  if (contentDomElement instanceof HTMLElement && !contentDomElement.hidden) {
    return false;
  }

  // if there is no content inside the summary
  // and the contentNode does not have content
  // we will remove the node
  // and insert an empty paragraph
  if (node.textContent.length === 0 && contentNode.content.size === 2) {
    return editor.chain().insertContentAt($detailsNodePos.end() + 1, {
      type: 'paragraph',
    }).deleteNode(options.wrapperNodeType).focus()
      .run();
  }

  const contentSliceStart = $anchor.pos;
  const contentSliceEnd = $anchor.start() + node.content.size;
  const sliceDiff = contentSliceEnd - contentSliceStart;

  const contentSlice = state.doc.slice(contentSliceStart, contentSliceEnd);

  return editor.chain()
    .command(({ tr }) => {
      tr.deleteRange(contentSliceStart, contentSliceEnd);
      return true;
    })
    .insertContentAt(targetPos - sliceDiff, {
      type: options.wrapperNodeType,
      content: [
        {
          type: options.summaryNodeType,
        },
        {
          type: options.contentNodeType,
          content: [
            {
              type: 'paragraph',
            },
          ],
        },
      ],
    })
    .insertContentAt(targetPos + 3 - sliceDiff, JSON.parse(JSON.stringify(contentSlice.content)))
    .focus(targetPos + 3 - sliceDiff)
    .run();
};

export const deleteDetailsItem = (editor: Editor, options: DetailsListOptions, forward: boolean) => {
  const { state } = editor;

  const detailsData = findParentNode(currentNode => currentNode.type.name === options.wrapperNodeType)(state.selection);

  if (!detailsData) {
    return false;
  }

  const $detailsPos = state.doc.resolve(detailsData.start);
  const $previousDetailsPos = state.doc.resolve(Math.max($detailsPos.pos - 2, 0));

  const previousDetailsNode = $previousDetailsPos.node();
  const previousNodeIsDetails = previousDetailsNode.type.name === options.wrapperNodeType;

  if ((!forward && !previousNodeIsDetails)) {
    return false;
  }

  const { $anchor } = state.selection;
  const node = $anchor.node();

  const contentSliceStart = $anchor.pos;
  const contentSliceEnd = $anchor.start() + node.content.size;

  const contentSlice = state.doc.slice(contentSliceStart, contentSliceEnd);

  const targetPos = forward ? undefined : $previousDetailsPos.start() + 1;
  const $summaryPos = state.doc.resolve(targetPos || 0);

  return editor.chain()
    .deleteNode(options.wrapperNodeType)
    .insertContentAt($summaryPos.end(), JSON.parse(JSON.stringify(contentSlice.content)))
    .focus($summaryPos.end())
    .run();
};

export const exitDetailsContent = (editor: Editor) => {
  const {
    $anchor, empty: emptySelection,
  } = editor.state.selection;

  const $content = editor.state.doc.resolve($anchor.start() - 1);

  const isAtBlockStart = $anchor.parentOffset === 0;
  const isLastBlock = $anchor.end() === $content.end() - 1;

  if (!isAtBlockStart || !emptySelection || !isLastBlock) {
    return false;
  }

  const slice = editor.state.doc.slice($anchor.start() - 1, $anchor.end() + 1);

  return editor.chain()
    .command(({ tr }) => {
      const $detailsNode = tr.doc.resolve(tr.selection.$anchor.end() + 2) as ResolvedPos;
      tr.setSelection(TextSelection.create(tr.doc, $detailsNode.end()));
      return true;
    })
    .insertContent(JSON.parse(JSON.stringify(slice.content)))
    .deleteRange({
      from: $anchor.start() - 1,
      to: $anchor.end() + 1,
    })
    .run();
};

export const moveNodeIntoContent = (editor: Editor, options: DetailsListOptions) => {
  const { $anchor } = editor.state.selection;
  const nodePos = new NodePosition($anchor);

  const { before } = nodePos;

  const isAtStart = $anchor.parentOffset === 0;

  if (!before || before.node.type.name !== options.contentNodeType || !isAtStart) {
    return false;
  }

  const contentSlice = editor.state.doc.slice(nodePos.from + 1, nodePos.to - 1);

  return editor
    .chain()
    .insertContentAt(before.to - 2, contentSlice.content.toJSON())
    .command(({ tr }) => {
      const newFrom = tr.mapping.map(nodePos.from);
      const newTo = tr.mapping.map(nodePos.to);
      tr.deleteRange(newFrom, newTo);
      return true;
    })
    .command(({ tr }) => {
      tr.setSelection(TextSelection.create(tr.doc, before.to - 2));
      return true;
    })
    // .insertContentAt(before.to - 2, contentSlice.content.toJSON())
    .run();
};
