import { Input, typo, Button } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { PortalModal } from '../PortalModal';

export const StyledSubscriptionContent = styled(motion.div)`
  overflow-y: clip;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledWebhookModalTrigger = styled.span`
  margin: 0;
  cursor: pointer;
  font-size: inherit;
  color: ${p => p.theme.colors.text.primary};
`;

export const StyledCreateButton = styled(Button)`
  margin-top: 8px;
  gap: 4px;
  font-weight: normal;
`;

/* webhook modal */

export const StyledModal = styled(PortalModal)`
  width: 400px;
`;

export const StyledModalTitle = styled.h2`
  ${typo.headerLight}
`;

export const StyledP = styled.p`
  margin: 16px 0;
  color: ${p => p.theme.colors.text.primary};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInput = styled(Input)`
  margin-top: 8px;
`;

export const StyledInformation = styled.p`
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledInformationLink = styled.a`
  color: ${p => p.theme.isDark ? ColorTheme.Blue400 : ColorTheme.Blue600};
  display: block;
  transition: color 0.2s ease-in;

  &:hover {
    color: ${p => p.theme.isDark ? ColorTheme.Blue300 : ColorTheme.Blue500};
  }
`;
