import { AddNewDocAttributeValue } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useAttributes } from 'src/hooks/api/useAttributes';

import {
  getDefaultCheckboxAttributeValue,
  getDefaultDateAttributeValue,
  getDefaultEmailAttributeValue,
  getDefaultNumberAttributeValue,
  getDefaultPhoneAttributeValue,
  getDefaultSingleSelectAttributeValue,
  getDefaultMultiSelectAttributeValue,
  getDefaultTextAttributeValue,
  getDefaultUrlAttributeValue,
} from './attributeDefaultValuesFromFilters.util';

export const useAttributesFromBoardConfig = (): Array<AddNewDocAttributeValue> => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const attributes = useAttributes();

  return useMemo(
    () => boardConfig?.filterProperties.edges.map(filterProperty => {
      // SingleSelect
      if (filterProperty.node.__typename === 'FilterPropertyRuleSingleSelect') {
        if (boardConfig.docQuery.__typename === 'BoardQueryWithGroupBy' &&
          boardConfig.docQuery.groupbyConfig.property.id === filterProperty.node.attribute.id) {
          return null;
        }

        const filterValue = getDefaultSingleSelectAttributeValue(filterProperty.node, attributes);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              select: filterValue,
            },
          };
        }
      }

      // MultiSelect
      if (filterProperty.node.__typename === 'FilterPropertyRuleMultiSelect') {
        if (boardConfig.docQuery.__typename === 'BoardQueryWithGroupBy' &&
          boardConfig.docQuery.groupbyConfig.property.id === filterProperty.node.attribute.id) {
          return null;
        }

        const filterValue = getDefaultMultiSelectAttributeValue(filterProperty.node, attributes);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              selects: filterValue,
            },
          };
        }
      }

      // Text
      if (filterProperty.node.__typename === 'FilterPropertyRuleText') {
        const filterValue = getDefaultTextAttributeValue(filterProperty.node);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              text: filterValue,
            },
          };
        }
      }
      // Email
      if (filterProperty.node.__typename === 'FilterPropertyRuleEmail') {
        const filterValue = getDefaultEmailAttributeValue(filterProperty.node);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              email: filterValue,
            },
          };
        }
      }
      // Phone
      if (filterProperty.node.__typename === 'FilterPropertyRulePhone') {
        const filterValue = getDefaultPhoneAttributeValue(filterProperty.node);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              phone: filterValue,
            },
          };
        }
      }
      // URL
      if (filterProperty.node.__typename === 'FilterPropertyRuleUrl') {
        const filterValue = getDefaultUrlAttributeValue(filterProperty.node);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              url: filterValue,
            },
          };
        }
      }
      // Number
      if (filterProperty.node.__typename === 'FilterPropertyRuleNumber') {
        const filterValue = getDefaultNumberAttributeValue(filterProperty.node);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              number: filterValue,
            },
          };
        }
      }
      // Date
      if (filterProperty.node.__typename === 'FilterPropertyRuleDate') {
        const filterValue = getDefaultDateAttributeValue(filterProperty.node);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              date: filterValue,
            },
          };
        }
      }
      // Checkbox
      if (filterProperty.node.__typename === 'FilterPropertyRuleCheckbox') {
        const filterValue = getDefaultCheckboxAttributeValue(filterProperty.node);
        if (filterValue !== undefined) {
          return {
            attributeDefinitionId: filterProperty.node.attribute.id,
            value: {
              checkbox: filterValue,
            },
          };
        }
      }

      return null;
    }).filter(isPresent) ?? [],
    [attributes, boardConfig?.docQuery, boardConfig?.filterProperties.edges],
  );
};
