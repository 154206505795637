import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export type LinearImportStep = 'intro' | 'projects' | 'issues' | 'automations';

export const {
  hookValue: useGetLinearImportModal,
  getValue: getLinearImportModal,
  setValue: setLinearImportModal,
} = make<{
  steps: LinearImportStep[];
  currentStep: LinearImportStep | null;
  isSkippable: boolean;
}>({
  defaultState: {
    steps: [],
    currentStep: null,
    isSkippable: false,
  },
});

export const {
  hookValue: useGetLinearImportSelection,
  getValue: getLinearImportSelection,
  setValue: setLinearImportSelection,
} = make<{
  selectedProjectIds: string[];
  selectedIssueIds: string[];
}>({
  defaultState: {
    selectedProjectIds: [],
    selectedIssueIds: [],
  },
});

export const {
  hookValue: useGetLinearImportDocTypes,
  getValue: getLinearImportDocTypes,
  setValue: setLinearImportDocTypes,
} = make<{
  issuesDocTypeId: string | null;
  projectsDocTypeId: string | null;
}>({
  localKey: LocalKey.LinearImportDocTypes,
  defaultState: {
    issuesDocTypeId: null,
    projectsDocTypeId: null,
  },
});

export const openLinearOnboarding = (state?: { isSkippable?: boolean }) => {
  setLinearImportModal({
    steps: ['intro', 'projects', 'issues', 'automations'],
    currentStep: 'intro',
    isSkippable: !!state?.isSkippable,
  });
  setLinearImportSelection({
    selectedProjectIds: [],
    selectedIssueIds: [],
  });
};

export const openLinearImport = () => {
  setLinearImportModal({
    steps: ['projects', 'issues'],
    currentStep: 'projects',
    isSkippable: true,
  });
  setLinearImportSelection({
    selectedProjectIds: [],
    selectedIssueIds: [],
  });
};

export const closeLinearModal = () => setLinearImportModal({
  currentStep: null,
});

export const setLinearImportNextStep = () => {
  const {
    currentStep, steps,
  } = getLinearImportModal();
  const nextStep = currentStep === null ? steps[0] : steps[steps.indexOf(currentStep) + 1];
  if (nextStep) {
    setLinearImportModal({ currentStep: nextStep });
  } else {
    closeLinearModal();
  }
};
