import { ReactNode, useRef, memo } from 'react';

import { useDocContext } from 'src/contexts/docContext';
import { DOC_ITEM_TARGET_CLASSNAME } from 'src/hooks/doc/useDocsNavigation';
import { useNavigate } from 'src/hooks/useNavigate';
import { useIsMobile } from 'src/reactives/responsive.reactive';

type Props = {
  children: ReactNode;
};

export const DocItemTouchContainer = memo((props: Props) => {
  const isMobile = useIsMobile();
  return isMobile
    ? <DocItemMobile {...props} />
    : <>{props.children}</>;
});

const DocItemMobile = ({ children }: Props) => {
  const docId = useDocContext(ctx => ctx.id);
  const docTitle = useDocContext(ctx => ctx.title);
  const isTouchMoving = useRef(false);
  const { navigateToDocFullPage } = useNavigate();
  return (
    <div
      className={DOC_ITEM_TARGET_CLASSNAME}
      onTouchMove={() => { isTouchMoving.current = true; }}
      onTouchStart={() => { isTouchMoving.current = false; }}
      onTouchEnd={() => {
        if (!isTouchMoving.current) {
          navigateToDocFullPage({
            title: docTitle,
            id: docId,
          }, {
            previousLocation: {
              pathname: window.location.pathname,
            },
          });
        }
      }}
    >
      {children}
    </div>
  );
};
