import { DocBaseFragment, ViewType } from '@cycle-app/graphql-codegen';
import {
  LinkIcon, PenIcon, TrashIcon, ImageIcon, ExpandIcon, QuoteIcon, ExportIcon2, UnlinkIcon,
} from '@cycle-app/ui/icons';
import { getDocSlug, useListNav, toLastUpdateDateTime, getDocFullUrl } from '@cycle-app/utilities';
import { FC } from 'react';

import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { Events, Methods, Sources } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useNavigateToDocFullOrPanel } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { usePageId } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';
import { useGetPermission } from 'src/reactives';
import { showExportQuotesModal } from 'src/reactives/docPanel.reactive';
import { Content } from 'src/reactives/docRightPanel.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { getEditCoverAction } from 'src/utils/doc.util';
import { isFeedback, isInsight, isParentOfInsight } from 'src/utils/docType.util';

import { StyledSelectLine, ActivityLine, PanelContent, PanelFooter } from './DocOptions.styles';
import { DocOptionsEditProperty } from './DocOptionsEditProperty';
import { isReleasePublished } from '../../utils/release.utils';
import { addToaster } from '../../utils/toasters.utils';

export interface Props {
  doc: DocBaseFragment;
  hide: VoidFunction;
  context?: 'doc-panel' | 'doc-item';
  onAddCoverClicked?: VoidFunction;
  onConfirmDelete?: VoidFunction;
  viewType?: ViewType;
  onSelectProperty?: (isNotCompatible: boolean) => void;
  onParentUpdated?: (parentDocId: string | undefined) => void;
  commonDoctypeParents?: string[];
  keepDropdownOnSelect?: boolean;
  onUpdateQuoteSelect?: VoidFunction;
  showUnlink?: boolean;
  showActivity?: boolean;
}

export const DocOptionsMenu: FC<React.PropsWithChildren<Props>> = ({
  doc,
  hide,
  context = 'doc-item',
  onAddCoverClicked,
  onConfirmDelete,
  viewType,
  onUpdateQuoteSelect,
  showUnlink = true,
  showActivity = true,
  ...editPropertyProps
}) => {
  const getUrl = useUrl();
  const pageId = usePageId();
  const navigateToDocPanel = useNavigateToDocFullOrPanel();
  const { canDeleteInsight } = useGetPermission();
  const { canDeleteReleaseNote } = useGetPermission();
  const changeDocParent = useChangeDocParent();
  const [modalDelete, {
    toggleCallback: toggleModalDelete,
    setFalseCallback: hideDeleteModal,
  }] = useOptimizedBooleanState(false);

  const onCopyLinkClicked = () => {
    const docUrl = getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
    const docFullUrl = getDocFullUrl(docUrl);
    copyToClipboard({
      text: docFullUrl,
      notification: `Link to ${doc?._docKey} copied to clipboard!`,
    });
    trackAnalytics(Events.DocShared, {
      method: Methods.UI,
      source: pageId === PageId.Board ? Sources.Board : Sources.DocPanel,
    });
    hide();
  };

  const [isEditPropertyShown, {
    setTrueCallback: showProperty, setFalseCallback: hideProperty,
  }] = useOptimizedBooleanState(false);

  const docType = useGetDocType(doc.doctype.id);
  const isDocInsight = isInsight(docType);

  const creator = doc?.creator ? `${doc.creator.firstName}` : null;
  const time = toLastUpdateDateTime(doc?.createdAt);

  const menuItems = [
    ...(onUpdateQuoteSelect ? [{
      id: 'edit-quote',
      icon: <QuoteIcon />,
      label: 'Update quote',
      onSelect: () => {
        onUpdateQuoteSelect();
        hide();
      },
    }] : []),
    ...(context === 'doc-item' ? [{
      id: 'edit-property',
      label: 'Edit property',
      icon: <PenIcon size={19} />,
      onSelect: showProperty,
      // Discourage accessing an insight.
    }, ...(!isDocInsight ? [{
      id: 'open',
      label: 'Open',
      icon: <ExpandIcon />,
      onSelect: () => {
        hide();
        navigateToDocPanel(doc);
      },
    }] : [])] : []),
    ...(viewType !== ViewType.List && onAddCoverClicked && !isFeedback(docType) ? [{
      id: 'edit-cover',
      label: getEditCoverAction(doc.cover?.url),
      icon: <ImageIcon />,
      onSelect: () => {
        onAddCoverClicked();
        hide();
      },
    }] : []),
    // Discourage accessing an insight.
    ...(!isDocInsight ? [{
      id: 'copyLink',
      label: 'Copy link',
      icon: <LinkIcon />,
      onSelect: onCopyLinkClicked,
    }] : []),
    ...(context === 'doc-panel' && isParentOfInsight(docType) ? [{
      id: 'export-quotes',
      label: 'Export quotes',
      icon: <ExportIcon2 />,
      onSelect: () => {
        showExportQuotesModal();
        hide();
      },
    }] : []),
    ...(isDocInsight && showUnlink ? [{
      id: 'unlink',
      label: 'Unlink',
      disabled: !doc.parent?.id,
      icon: <UnlinkIcon />,
      onSelect: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        changeDocParent({
          docId: doc.id,
          parentId: undefined,
        });
      },
    }] : []),
    {
      id: 'delete',
      label: 'Delete',
      variant: 'danger' as const,
      icon: <TrashIcon />,
      onSelect: () => {
        if (isReleasePublished(doc.releaseNote?.release)) {
          addToaster({
            title: 'Operation not allowed on published release',
            message: 'Unpublish the release to make changes',
          });
          hide();
          return;
        }
        if (doc.releaseNote && !canDeleteReleaseNote) {
          setLimitationsModal({ action: 'RELEASE_UPDATE' });
          hide();
          return;
        }
        if (isInsight(docType) && !canDeleteInsight) {
          setLimitationsModal({ action: 'INSIGHT_DELETE' });
          hide();
          return;
        }
        toggleModalDelete();
      },
    },
    ...(creator && time ? [{
      id: 'activity',
      label: `Created by ${creator} · ${time}`,
      onSelect: () => {
        hide();
        if (isDocInsight) return;
        navigateToDocPanel(doc, {
          rightPanel: Content.Activity,
        });
      },
    }] : []),
  ];

  const optionsValues = menuItems.map(item => item.id);

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    value: null,
    optionsValues,
    onSelect: async (itemId) => {
      const item = menuItems.find(i => i.id === itemId);
      if (!item?.onSelect) return;
      item.onSelect();
    },
  });

  const activityItem = menuItems[menuItems.length - 1];

  return (
    <>
      <div
        role="none"
        onClick={(e) => { e.stopPropagation(); }}
      >
        {isEditPropertyShown
          ? (
            <DocOptionsEditProperty
              doc={doc}
              hide={hide}
              goBack={hideProperty}
              {...editPropertyProps}
            />
          ) : (
            <div {...listProps}>
              <PanelContent>
                {menuItems.slice(0, -1).map(item => (
                  <StyledSelectLine
                    key={item.id}
                    isSelected={selected === item.id}
                    startSlot={item.icon}
                    label={item.label}
                    variant={item.variant}
                    isDisabled={item.disabled}
                    {...itemProps(item.id)}
                  />
                ))}
              </PanelContent>

              {activityItem && showActivity && (
                <PanelFooter>
                  <ActivityLine
                    isSelected={selected === activityItem.id}
                    label={activityItem.label}
                    isDisabled={isDocInsight}
                    {...itemProps(activityItem.id)}
                  />
                </PanelFooter>
              )}
            </div>
          )}
      </div>

      {modalDelete && (
        <DocDeleteWarningModal
          docId={doc.id}
          onHide={hideDeleteModal}
          onConfirm={onConfirmDelete}
        />
      )}
    </>
  );
};
