import {
  MouseEventHandler, useRef, ReactNode, RefObject, forwardRef, useImperativeHandle,
} from 'react';

import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AnyShortcut } from 'src/types/shortcuts.types';

import { DropdownLayer, DropdownLayerProps } from './DropdownLayer';

export const useToggleDropdown = (initial = false) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isDropdownVisible, {
    setTrueCallback: openDropdown,
    setFalseCallback: closeDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(initial);

  const hideDropdown = () => {
    buttonRef.current?.focus();
    closeDropdown();
  };

  return {
    buttonRef,
    isDropdownVisible,
    openDropdown,
    hideDropdown,
    toggleDropdown,
  };
};

export type ToggleButtonProps = {
  ref: RefObject<HTMLButtonElement>;
  onClick: MouseEventHandler;
  trigger: VoidFunction;
  'data-active': boolean;
  toggle: VoidFunction;
};

export type ToggleContentProps = {
  hide: VoidFunction;
  buttonRect: DOMRect | undefined;
};

export type ToggleDropdownProps = Omit<DropdownLayerProps, 'content'> & {
  button: ReactNode | ((props: ToggleButtonProps) => ReactNode);
  shortcutEnabled?: () => boolean;
  shortcut?: AnyShortcut;
  content: ReactNode | ((props: ToggleContentProps) => ReactNode);
  initialVisible?: boolean;
};

export const ToggleDropdown = forwardRef<{ hide: VoidFunction }, ToggleDropdownProps>(({
  button, content, shortcutEnabled, shortcut, initialVisible = false, ...props
}, forwardedRef) => {
  const {
    buttonRef: ref,
    isDropdownVisible,
    openDropdown,
    hideDropdown: hide,
    toggleDropdown: toggle,
  } = useToggleDropdown(initialVisible);

  useHotkeyListener({
    enabled: shortcutEnabled,
    callbacks: shortcut ? { [shortcut]: openDropdown } : {},
    shortcuts: shortcut ? [shortcut] : [],
  });

  useImperativeHandle(forwardedRef, () => ({
    hide,
  }), [hide]);

  return (
    <DropdownLayer
      {...props}
      visible={isDropdownVisible}
      hide={hide}
      content={typeof content === 'function' ? content({
        hide,
        buttonRect: ref.current?.getBoundingClientRect(),
      }) : content}
    >
      {typeof button === 'function' ? button({
        ref,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          openDropdown();
        },
        trigger: openDropdown,
        'data-active': isDropdownVisible,
        toggle,
      }) : button}
    </DropdownLayer>
  );
});
