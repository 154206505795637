import { Tooltip } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';

import { useDocPanelContext } from 'src/contexts/docPanelContext';

import { AddQuoteFromBlockButton } from './DocPanelBody.styles';
import { useQuoteFromBlock } from './useQuoteFromBlock';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
};

export const QuoteFromBlock = ({ containerRef }: Props) => {
  const editor = useDocPanelContext(ctx => ctx.editor);
  const {
    position, active, onClick,
  } = useQuoteFromBlock(containerRef.current);

  if (!editor || !position) return null;

  return (
    <Tooltip
      content="Extract quote"
      placement="top"
      withWrapper={false}
      withPortal
    >
      <AddQuoteFromBlockButton
        style={{
          left: position.left,
          top: position.top,
        }}
        onClick={onClick}
        forceFocus={active}
      >
        <QuoteIcon size={14} />
      </AddQuoteFromBlockButton>
    </Tooltip>
  );
};
