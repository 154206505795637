import { FC } from 'react';

import { Container } from './OfflineBadge.styles';
import { OfflineIcon } from '../../icons';

export const OfflineBadge: FC<React.PropsWithChildren<unknown>> = () => (
  <Container>
    <OfflineIcon />
    Offline
  </Container>
);
