import { DoctypeType, ViewType } from '@cycle-app/graphql-codegen';
import { AddIcon } from '@cycle-app/ui/icons';

import BoardConfigFormFilters from 'src/components/BoardConfigForm//BoardConfigFormFilters';
import { LabelSoon, LightButton, Label } from 'src/components/BoardConfigForm/BoardConfigForm.styles';
import BoardConfigFormProperties from 'src/components/BoardConfigForm/BoardConfigFormProperties';
import { SoonBadge } from 'src/components/SoonBadge/SoonBadge';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { openRoadmapsTemplateModal } from 'src/reactives/roadmaps.reactive';
import { getBoardConfigGroupBy, findFilterDoctypePropertyRule } from 'src/utils/boardConfig/boardConfig.util';

import BoardConfigFormDoctypes from '../BoardConfigForm/BoardConfigFormDoctypes';
import BoardConfigFormGroupby from '../BoardConfigForm/BoardConfigFormGroupby';
import { BoardConfigFormSortBy } from '../BoardConfigForm/BoardConfigFormSortBy';
import BoardConfigFormSwimlanes from '../BoardConfigForm/BoardConfigFormSwimlanes/BoardConfigFormSwimlanes';
import { BoardConfigFormViewType } from '../BoardConfigForm/BoardConfigFormViewType';
import { NewBadge } from '../NewBadge';

type Props = {
  boardId: string;
};

export const RoadmapViewBoardConfigFields = ({ boardId }: Props) => {
  const { data } = useDraftBoardConfig(boardId);
  const { docTypes } = useGetDocTypes();

  const draftBoardConfig = data?.node?.draftBoardConfig;
  const docTypesCount = Object.values(docTypes).filter(d => d.type === DoctypeType.Custom)?.length ?? 0;

  if (!draftBoardConfig) return null;

  return (
    <>
      <section>
        <Label>View type</Label>
        <BoardConfigFormViewType boardId={boardId} />
      </section>

      <section>
        <Label>Doc types</Label>
        <BoardConfigFormDoctypes
          draftBoardConfigId={draftBoardConfig.id}
          doctypesFilter={findFilterDoctypePropertyRule(draftBoardConfig)}
          boardID={boardId}
          showSelectAll={false}
        />
      </section>

      {docTypesCount < 2 && (
        <LightButton
          onClick={openRoadmapsTemplateModal}
          iconStart={<AddIcon size={10} />}
        >
          Advanced roadmaps
        </LightButton>
      )}

      <section>
        <Label>
          Filter
        </Label>
        <BoardConfigFormFilters
          boardConfigId={draftBoardConfig.id}
          filterProperties={draftBoardConfig.filterProperties}
          filterableProperties={draftBoardConfig.filterableProperties}
          boardID={boardId}
        />
      </section>

      <section>
        <Label>
          Sort
          <NewBadge />
        </Label>
        <BoardConfigFormSortBy
          boardConfigId={draftBoardConfig.id}
          sortByProperty={draftBoardConfig.sortByProperty}
          sortableProperties={draftBoardConfig.sortableProperties}
          filterableProperties={draftBoardConfig.filterableProperties}
        />
      </section>

      <section>
        <Label>
          Group
        </Label>
        <BoardConfigFormGroupby
          boardConfigId={draftBoardConfig.id}
          groupableProperties={draftBoardConfig?.groupableProperties}
          groupByConfig={getBoardConfigGroupBy(draftBoardConfig)}
          boardID={boardId}
          filterableProperties={draftBoardConfig.filterableProperties}
          availableSwimlaneByDoctypes={draftBoardConfig.availableSwimlaneByDoctypes}
        />
      </section>

      {draftBoardConfig?.viewType === ViewType.Kanban && (
        <section>
          <Label>
            Sub group
          </Label>
          <BoardConfigFormSwimlanes
            boardConfigId={draftBoardConfig?.id}
            availableSwimlaneByDoctypes={draftBoardConfig.availableSwimlaneByDoctypes}
            groupByConfig={getBoardConfigGroupBy(draftBoardConfig)}
            swimlaneByConfig={draftBoardConfig.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
              ? draftBoardConfig.docQuery.swimlanebyConfig
              : null}
            boardID={boardId}
            full
            filterableProperties={draftBoardConfig.filterableProperties}
          />
        </section>
      )}

      <section>
        <Label>
          Properties
        </Label>
        <BoardConfigFormProperties
          boardConfigId={draftBoardConfig.id}
          viewType={draftBoardConfig.viewType}
          properties={draftBoardConfig.properties}
          boardID={boardId}
        />
      </section>

      <LabelSoon>
        Aggregate metrics
        <SoonBadge />
      </LabelSoon>
    </>
  );
};
