import { useCallback, useEffect, useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { getDocItemHoverId } from 'src/reactives/docItem.reactive';
import { setSelection } from 'src/reactives/selection.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';

import { useDocsSelection } from '../useDocsSelection';

interface Props {
  docIds: (string | undefined)[];
}

export const useFeatureQuotesShortcutListener = ({ docIds }: Props) => {
  const { toggleSelectDoc } = useDocsSelection();

  const handleSelectDoc = useCallback(() => {
    const hoverDocId = getDocItemHoverId();
    if (hoverDocId) {
      toggleSelectDoc(hoverDocId);
    }
  }, [toggleSelectDoc]);

  const handleSelectAllDocs = useCallback(() => {
    const selected = docIds.filter(isPresent);
    if (!!selected.length) {
      setSelection({
        selected,
      });
    }
  }, [docIds]);

  const callbacks = useMemo(() => ({
    [ShortcutBoard.SelectDoc]: handleSelectDoc,
    [ShortcutBoard.SelectAllDocs]: handleSelectAllDocs,
  }), [handleSelectDoc, handleSelectAllDocs]);

  // Clear selection on umount
  useEffect(() => {
    return () => {
      setSelection({
        selected: [],
        lock: false,
      });
    };
  }, []);

  useHotkeyListener({
    callbacks,
    shortcuts: [ShortcutBoard.SelectDoc, ShortcutBoard.SelectAllDocs],
    disableOnLayers: [Layer.Dropdown, Layer.Modal, Layer.ModalZ2, Layer.ModalZ3],
  });
};
