import { boxShadowZ2, typo } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

import { StyledAvatar } from '../../AvatarList/AvatarList.styles';

export const Container = styled.div`
  ${boxShadowZ2}
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  overflow: hidden;
  ${p => (p.theme.isDark && `
    background-color: ${Color.Grey900};
    border: none;
  `)};
`;

export const Header = styled.div`
  background-color: ${p => (p.theme.isDark ? 'hsla(0, 0%, 0%, 0.16)' : Color.Blue100)};
  display: flex;
  height: 200px;
  justify-content: center;
  padding: 40px 0;
`;

export const Body = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: space-between;
`;

export const BodyCta = styled.div`
  align-items: center;
  display: flex;
  flex: none;
`;

export const HeaderImg = styled.img`
  width: 207px;
`;

export const Content = styled.div`
  padding: 20px 40px 20px 20px;
`;

export const Title = styled.h3`
  ${typo.body500}
`;

export const AvatarContainer = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  gap: 8px;
  margin-top: 17px;

  ${StyledAvatar} {
    --size: 24px;
    --font-size: 12px;
    --mask: radial-gradient(circle at -3px 12px,transparent 14px,white 14px);
  }
`;
