import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useUpdateDocTitle } from 'src/hooks/api/mutations/updateDocHooks';

import { ParentInput } from './VerifyQuoteModal.styles';

type Props = {
  parentId: string;
  defaultValue: string;
  hide: VoidFunction;
};

export const EditQuoteFeatureTitle = ({
  parentId, defaultValue, hide,
}: Props) => {
  const { updateDocTitle } = useUpdateDocTitle();
  const updateDocTitleDebounced = useDebouncedCallback(updateDocTitle, INPUT_ONCHANGE_DEBOUNCE);
  return (
    <ParentInput
      autoFocus
      defaultValue={defaultValue}
      onBlur={hide}
      onKeyDown={e => {
        e.stopPropagation();
      }}
      onKeyUp={e => {
        e.stopPropagation();
        if (['Enter', 'Escape'].includes(e.key)) hide();
      }}
      onChange={e => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateDocTitleDebounced({
          docId: parentId,
          title: e.target.value,
        });
      }}
    />
  );
};
