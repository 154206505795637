import { typo, Button, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  height: 115px;
  border-radius: 12px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 1px 3px 0px #0000000D;
  margin-bottom: 20px;
`;

export const Bg = styled.img`
  flex: none;
  display: block;
  position: absolute;
  inset: 0 0 12px;
  z-index: 0;
  left: 50%;
  transform: translate(-50%, 0);

  ${p => p.theme.isDark && 'opacity: 0.05;'}
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  padding: 12px;
`;

export const Title = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  font-size: 13px;
`;

export const Cta = styled(Button)`
  gap: 0px;
`;

export const ButtonClose = styled(ActionButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 3;

  svg {
    color: ${p => p.theme.baseColors.Grey400};
  }
`;
