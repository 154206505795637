import { useQuery } from '@apollo/client';
import { FetchGithubIssueByUrlDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

export const useGithubIssueByUrl = (issueUrl?: string, skip?: boolean) => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Github);
  const isActive = !!integration?.provider;
  const {
    data,
    loading: isLoading,
    refetch,
    error,
  } = useQuery(FetchGithubIssueByUrlDocument, {
    skip: skip || !issueUrl || !isActive || !integration?.id,
    variables: {
      integrationId: integration?.id ?? '',
      issueUrl: issueUrl ?? '',
    },
    onError: () => {
      addErrorToaster({ message: ErrorMessage._GENERIC });
    },
  });

  const githubIssue = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Github'
      ) return null;

      return data.node.provider.issues[0] || null;
    },
    [data],
  );

  return {
    isActive,
    isLoading,
    issue: githubIssue,
    refetch,
    error,
  };
};
