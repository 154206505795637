import { Skeleton } from '@cycle-app/ui';

import { Container } from './DocHierarchyInsightsSkeletons.styles';

export const DocHierarchyInsightsSkeletons = ({ count }: {
  count: number;
}) => {
  return (
    <>
      {[...Array(count).keys()].map(i => (
        <Container key={i}>
          <Skeleton height={20} />
          <Skeleton height={50} />
        </Container>
      ))}
    </>
  );
};
