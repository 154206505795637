import { FC, ReactNode } from 'react';

import { resetMeetingsModalInstalled, useGetMeetingsModalInstalled } from 'src/reactives/meetingsModalInstalled.reactive';
import { Layer } from 'src/types/layers.types';

import { StyledModal } from './MettingsModalInstalled.styles';
import { MettingsModalInstalledContent } from './MettingsModalInstalledContent';

type Props = {
  children?: ReactNode;
};

export const MettingsModalInstalled: FC<React.PropsWithChildren<Props>> = () => {
  const { isOpened } = useGetMeetingsModalInstalled();
  return isOpened ? (
    <StyledModal
      layer={Layer.DialogModal}
      hide={resetMeetingsModalInstalled}
    >
      <MettingsModalInstalledContent />
    </StyledModal>
  ) : null;
};
