import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { AnimatePresence, Variants } from 'framer-motion';
import { FC, useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { ReleasesPageTitle } from 'src/components/PageTitle/PageTitle';
import { useReleaseBase } from 'src/contexts/releasesContext';
import { useReleaseSubscription } from 'src/hooks/api/useReleaseSubscription';
import { useNavigate } from 'src/hooks/useNavigate';
import { resetReleasesAction } from 'src/reactives/releases.reactive';

import { ReleaseActionsMenu } from '../ReleaseActionsMenu';
import { ReleaseStatus } from '../ReleaseStatus';
import {
  Container, Header, Toggle, Content, Caret,
  TextContent, ReleaseTitle, ReleaseStatusIndicator,
  ReleaseNotesContainer,
} from './ReleaseItem.styles';
import { ReleaseNoteAddAction } from './ReleaseNoteAddAction';

export const ReleaseItem: FC<React.PropsWithChildren<{
  releaseId: string;
  isActive: boolean;
}>> = ({
  releaseId, isActive, children,
}) => {
  useReleaseSubscription(releaseId);
  const release = useReleaseBase(releaseId);
  const {
    navigateToReleases, navigateToRelease,
  } = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const scrollItemIntoView = () => {
    if (!ref.current) return;
    scrollIntoView(ref.current, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
    });
  };

  const date = release && release.date ? toShortLocaleDateString(release.date) : null;
  const isNoRelease = !release?.date;
  return (
    <>
      {isActive && date && <ReleasesPageTitle releaseDate={date} />}
      <Container $isActive={isActive} ref={ref}>
        <Header>
          <Toggle
            onClick={() => {
              resetReleasesAction();
              return isActive ? navigateToReleases() : navigateToRelease(releaseId);
            }}
          />
          <Content>
            <TextContent>
              <Caret direction={isActive ? 'bottom' : 'right'} />
              {date && <div className="w-[94px] whitespace-nowrap text-body font-medium tabular-nums">{date}</div>}
              {!isNoRelease && (
                <Tooltip
                  withWrapper={false}
                  placement="top"
                  content={release.publicStatus === ReleasePublicStatus.Unpublished ? 'Draft' : 'Published'}
                >
                  <ReleaseStatusIndicator $published={release.publicStatus !== ReleasePublicStatus.Unpublished} />
                </Tooltip>
              )}
              {release?.title && (
                <ReleaseTitle>{release.title}</ReleaseTitle>
              )}
            </TextContent>

            {!isNoRelease && release?.publicStatus && (
              <div className="flex items-center gap-1">
                {release.publicStatus !== ReleasePublicStatus.Published && (
                  <>
                    <ReleaseStatus publicStatus={release.publicStatus} releaseId={release.id} />
                    <ReleaseNoteAddAction releaseId={release.id} />
                  </>
                )}
                <ReleaseActionsMenu publicId={release.publicRelease?.id || null} publicStatus={release.publicStatus} releaseId={release.id} />
              </div>
            )}
          </Content>
        </Header>

        <AnimatePresence initial={false} presenceAffectsLayout>
          {isActive && (
            <ReleaseNotesContainer
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ bounce: 0 }}
              onAnimationStart={def => def === 'visible' && scrollItemIntoView()}
              onAnimationComplete={def => def === 'visible' && scrollItemIntoView()}
            >
              {children}
            </ReleaseNotesContainer>
          )}
        </AnimatePresence>
      </Container>
    </>
  );
};

const variants: Variants = {
  hidden: {
    opacity: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    height: 'auto',
  },
};
