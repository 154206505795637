/* eslint-disable no-nested-ternary */
import { GenerateSummaryFromQuotesDocument } from '@cycle-app/graphql-codegen';
import { Tooltip, ActionButton } from '@cycle-app/ui';
import { AiIcon, DuplicateIcon, CloseIcon } from '@cycle-app/ui/icons';
import React, { useState } from 'react';

import { AiIsWriting } from 'src/components/EditorAiMenu/EditorAiMenu.styles';
import { useSafeMutation } from 'src/hooks';
import { useCustomerDashboardAiSubscription } from 'src/hooks/api/useCustomerDashboardAiSubscription';
import {
  setCustomerDashboard,
  useCustomerDashboardStateValue,
} from 'src/reactives';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { Modal, Button, Summary, Actions } from './AnalyseSummaryModal.styles';

const removeHTMLCodBlock = (content: string) => content.replace('html', '').replace(/`/g, '');

export const AnalysesSummaryModal = ({
  productId, dashboardId,
}: { productId: string; dashboardId: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  useCustomerDashboardAiSubscription(dashboardId);
  const dashboardStateValue = useCustomerDashboardStateValue(dashboardId);
  const {
    dashboardManualFilters, aiSuggestion, isAiGenerating,
  } = dashboardStateValue || {};
  const [generateSummaryFromQuotes] = useSafeMutation(GenerateSummaryFromQuotesDocument);
  const handleGenerateSummaryFromQuotes = async () => {
    if (!dashboardId) return null;
    setCustomerDashboard(dashboardId, {
      aiSuggestion: '',
      isAiGenerating: true,
    });
    const res = await generateSummaryFromQuotes({
      variables: {
        productId,
        dashboardId,
        /**
         * Type Scalar JSON in codegen doesn't fit with the FilterGroup from
         * Luzmo
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        where: dashboardManualFilters || [],
      },
    });
    setCustomerDashboard(dashboardId, { mutationId: res.data?.generateSummaryFromQuotes });
    return null;
  };

  const button = (
    <Button
      disabled={!dashboardManualFilters?.length}
      onClick={async () => {
        setIsOpen(true);
        if (!aiSuggestion) {
          await handleGenerateSummaryFromQuotes();
        }
      }}
    >
      <AiIcon hasGradient isAnimated={isAiGenerating} />
      {!aiSuggestion ? 'Summarize Feedback' : isAiGenerating ? 'Writing summary...' : 'Open summary'}
    </Button>
  );

  return (
    <>
      {!dashboardManualFilters?.length
        ? <Tooltip content="Filter your dashboard to generate a summary">{button}</Tooltip>
        : button}
      {isOpen && (
        <Modal hide={() => setIsOpen(false)}>
          <Actions>
            <ActionButton
              tooltip="Copy summary"
              onClick={() => aiSuggestion && copyToClipboard({
                text: removeHTMLCodBlock(aiSuggestion),
                notification: 'Summary copied to clipboard',
                asHtml: true,
              })}
            >
              <DuplicateIcon size={16} />
            </ActionButton>
            <ActionButton
              tooltip="Close"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon size={16} />
            </ActionButton>
          </Actions>
          {aiSuggestion
            ? <Summary dangerouslySetInnerHTML={{ __html: removeHTMLCodBlock(aiSuggestion) }} />
            : (
              <Summary>
                <div className="flex items-center gap-1">
                  <AiIcon hasGradient isAnimated />
                  <AiIsWriting>Feedback summary in progress…</AiIsWriting>
                </div>
              </Summary>
            )}
        </Modal>
      )}
    </>
  );
};
