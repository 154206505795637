import {
  FetchDocLinkedDocsDocument,
  DocTargetFragment,
  FetchDocLinkedDocsQuery,
  AiState,
} from '@cycle-app/graphql-codegen/generated';
import { produce } from 'immer';
import { useCallback } from 'react';

import { useLazyQueryAsync } from 'src/hooks/useLazyQueryAsync';
import client from 'src/services/apollo/client';
import { getDocFromCache } from 'src/utils/cache.utils';

type AddParams = {
  feedbackId: string;
  docTarget: DocTargetFragment;
  docId: string;
};

export const useDocInsightsCache = () => {
  const fetchDocLinkedDocs = useLazyQueryAsync<FetchDocLinkedDocsQuery>(FetchDocLinkedDocsDocument);

  const add = useCallback(async ({
    feedbackId,
    docTarget,
    docId,
  }: AddParams) => {
    const addInsightsInCache = async (aiStates?: (AiState | null)[]) => {
      const feedbackDocTargets = client.cache.readQuery({
        query: FetchDocLinkedDocsDocument,
        variables: {
          id: feedbackId,
          aiStates,
        },
      }) ?? (await fetchDocLinkedDocs({ id: feedbackId })).data;

      if (feedbackDocTargets?.node?.__typename !== 'Doc') return;

      const { edges } = feedbackDocTargets.node.docTargets;
      if (!edges.some(e => e.node?.id === docTarget.id)) {
        const feedback = getDocFromCache(feedbackId);
        const insight = getDocFromCache(docId);
        if (!insight) return;

        const node = produce(feedbackDocTargets.node, recipe => {
          recipe.docTargets.edges.unshift({
            __typename: 'DocTargetEdge',
            node: {
              ...docTarget,
              doc: {
                ...insight,
                suggestedParentName: null,
                suggestedParentDoctype: null,
                docSource: insight.docSource
                  ? {
                    ...insight.docSource,
                    doc: feedback,
                  }
                  : null,
              },
            },
          });
        });

        client.cache.writeQuery({
          query: FetchDocLinkedDocsDocument,
          variables: {
            id: feedbackId,
            aiStates,
          },
          data: { node },
        });
      }
    };
    return Promise.all([
      // insert in parent insights list
      addInsightsInCache(),
      // insert in feedback insights list
      addInsightsInCache([AiState.UserValidated, AiState.AiCreated, null]),
    ]);
  }, [fetchDocLinkedDocs]);

  return {
    add,
  };
};
