import { LinearEntityType } from '@cycle-app/graphql-codegen';
import { Tooltip, TooltipProps, Button } from '@cycle-app/ui';
import { FC, useEffect } from 'react';

import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import { getDocType } from 'src/reactives/docTypes.reactive';

import { DocLinearAutoCreateCheckbox } from './DocLinearAutoCreateCheckbox';
import { setUserPreferences, useUserPreferencesValue } from '../../reactives/userPreferences.reactive';

export type DocLinearAutoCreateProps = {
  checked: boolean;
  className?: string;
  doctypeId: string;
  hideTooltip?: boolean;
  onChange: (checked: boolean) => void;
  style?: TooltipProps['style'];
  size?: 'S' | 'M';
  withPortal?: boolean;
};

const hideDiscover = () => setUserPreferences({ discoverLinearCreationAutomation: false });

// Check linear installed and doctype automation from the parent.
export const DocLinearAutoCreate: FC<React.PropsWithChildren<DocLinearAutoCreateProps>> = ({
  checked, className, doctypeId, onChange, hideTooltip, style, size, withPortal = true,
}) => {
  const doctype = getDocType(doctypeId);
  const {
    teams, isLoading: isTeamsLoading,
  } = useLinearTeams();
  const { discoverLinearCreationAutomation } = useUserPreferencesValue();

  useEffect(() => {
    const onKeyup = () => {
      // In instance: when the user starts to edit the doc title.
      setUserPreferences({ discoverLinearCreationAutomation: false });
    };
    document.addEventListener('keyup', onKeyup, { once: true });
    return () => {
      hideDiscover();
      document.removeEventListener('keyup', onKeyup);
    };
  }, []);

  const team = teams.find(t => t.id === doctype?.linearTeam);
  const type = doctype?.linearEntity === LinearEntityType.Issue ? 'issue' : 'project';

  const checkbox = (
    <DocLinearAutoCreateCheckbox
      id={`${doctypeId}-with-linear`}
      checked={checked}
      className={className}
      isLoading={isTeamsLoading}
      onChange={onChange}
      size={size}
      type={type}
      teamName={team?.name}
    />
  );

  return hideTooltip ? checkbox : (
    <Tooltip
      key={discoverLinearCreationAutomation ? 'shiny' : 'default'}
      content={discoverLinearCreationAutomation
        ? `Keep the box checked to automate the creation of a Linear ${type}`
        : `Automate Linear ${type} creation`}
      placement="top"
      style={style}
      withPortal={withPortal}
      {...discoverLinearCreationAutomation && {
        footer: <Button size="M" variant="nospace-primary" onClick={hideDiscover}>Got it</Button>,
        hide: hideDiscover,
        interactive: true,
        placement: 'bottom-start',
        title: 'Linear automation set up',
        variant: 'shiny',
        visible: true,
      }}
    >
      {checkbox}
    </Tooltip>
  );
};
