import { ApolloCache, gql } from '@apollo/client';
import { AttributeTextValueFragment } from '@cycle-app/graphql-codegen/generated';

/**
 * Add an new text value to the attribute definition
 */
export const addAttributeTextValue = (
  cache: ApolloCache<unknown>,
  attributeDefinitionId: string,
  attributeTextValue?: AttributeTextValueFragment | null,
) => {
  const newAttributeTextValueRef = cache.writeFragment({
    data: attributeTextValue,
    fragment: gql`
      fragment NewAttributeTextValue on AttributeTextValue {
        id
      }
    `,
  });

  if (!newAttributeTextValueRef) return;

  cache.modify({
    id: attributeDefinitionId,
    fields: {
      valuesV2: (values) => ({
        ...values,
        edges: values.edges.concat({
          __typename: 'AttributeTextValueEdge',
          node: newAttributeTextValueRef,
        }),
      }),
    },
  });
};
