import type { VideoViewAttributes } from '@cycle-app/utilities';
import { Node, mergeAttributes } from '@tiptap/core';

import { VIDEO_EXTENSION_NAME, VIDEO_EXTENSION_TAGNAME } from '../constants';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [VIDEO_EXTENSION_NAME]: {
      setVideo: (options: VideoViewAttributes) => ReturnType;
    };
  }
}

export const getEditorVideoNode = (data: VideoViewAttributes) => ({
  type: VIDEO_EXTENSION_NAME,
  attrs: {
    src: data.src,
    title: data.title || 'No title',
    dataId: data.dataId,
  },
});

export const videoExtension = Node.create({
  name: VIDEO_EXTENSION_NAME,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      src: {
        default: '',
      },
      title: {
        default: '',
      },
      dataId: {
        default: '',
      },
    };
  },

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'editor-video',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: VIDEO_EXTENSION_TAGNAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      VIDEO_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setVideo: (options: VideoViewAttributes) => ({ commands }) => commands
        .insertContent([
          getEditorVideoNode({
            src: options.src,
            title: options.title,
            dataId: options.dataId,
          }),
        ]),
    };
  },
});
