import { Tooltip } from '@cycle-app/ui';
import { useRef } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import MyAvatar from 'src/components/MyAvatar/MyAvatar';
import { UserMenu } from 'src/components/UserMenu';
import { useMe } from 'src/hooks/api/useMe';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetSidebarWidth, useGetSidebarCollapsed } from 'src/reactives/sidebar.reactive';
import { useIsEclipseTheme } from 'src/reactives/theme.reactive';

import { Item } from '../SidebarHeader.styles';

const FORCE_LEFT_MENU = 8;

export const SidebarHeaderUser = () => {
  const { me } = useMe();
  const isMobile = useIsMobile();
  const { width: sidebarWidth } = useGetSidebarWidth();
  const { collapsed } = useGetSidebarCollapsed();
  const isEclipseTheme = useIsEclipseTheme();

  const [dropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  const itemRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <DropdownLayer
        visible={dropdownVisible}
        hide={hideDropdown}
        content={<UserMenu onItemClicked={hideDropdown} />}
        getReferenceClientRect={isMobile || sidebarWidth > 275 ? undefined : () => {
          const rect = itemRef.current?.getBoundingClientRect();
          return new DOMRect(FORCE_LEFT_MENU, rect?.y ?? 0, rect?.width ?? 0, rect?.height ?? 0);
        }}
      >
        <Tooltip
          content={`${me.firstName} ${me.lastName} (me)`}
          placement={collapsed ? 'right' : 'bottom'}
          disabled={dropdownVisible}
          withPortal
        >
          <Item
            ref={itemRef}
            onClick={toggleDropdown}
            className={dropdownVisible ? 'force-focus' : ''}
            $collapsed={collapsed}
          >
            <MyAvatar
              pointer
              size={18}
              padding={0}
              oppositeColor={isEclipseTheme}
            />
          </Item>
        </Tooltip>
      </DropdownLayer>
    </>
  );
};
