import { GithubIssueBaseFragment } from '@cycle-app/graphql-codegen';
import { SelectLine, GithubStatus, SelectLineProps, TextHighlighter } from '@cycle-app/ui';
import { GithubIcon } from '@cycle-app/ui/icons';
import { GithubIssueStatus } from '@cycle-app/utilities';
import { FC } from 'react';

import { SlotText } from 'src/components/Integrations/IntegrationsCommon.styles';
import { parseGithubIssueLink } from 'src/utils/integrations.utils';

import { StartSlot } from './GithubIssueSelectLine.styles';

type GithubIssueSelectLineProps = {
  issue: GithubIssueBaseFragment;
  isSelected: boolean;
  searchText: string;
  extraProps: Omit<SelectLineProps, 'label' | 'isSelected' | 'startSlot'>;
};

export const GithubIssueSelectLine: FC<React.PropsWithChildren<GithubIssueSelectLineProps>> = ({
  issue, extraProps, isSelected, searchText = '',
}) => {
  const repository = (issue.url && parseGithubIssueLink(issue.url)?.repository) || '';

  return (
    <SelectLine
      label={issue.title
        ? <TextHighlighter className="highlight" searchWords={[searchText]} textToHighlight={issue.title} />
        : `${issue.title}`}
      isSelected={isSelected}
      startSlot={(
        <StartSlot>
          <GithubIcon />
          <GithubStatus status={((issue.state as GithubIssueStatus) || 'closed')} />
          <SlotText>{repository}</SlotText>
          <SlotText>{`#${issue.publicId}`}</SlotText>
        </StartSlot>
      )}
      {...extraProps}
    />
  );
};
