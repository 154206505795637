import { useProduct } from 'src/hooks/api/useProduct';
import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';
import { isSettingsPath } from 'src/utils/routing.utils';

interface LastViewState {
  boardId: string | null;
  boardSlug: string | null;
  section: 'feedback' | 'insights' | 'roadmaps' | 'custom' | 'releases' | null;
  productSlug: string | null;
  lastDoctypeIdsUsed: Record<string, string | null>;
  lastAssigneeIdsUsed: Record<string, string | null>;
  lastDocMentionDocTypeIdsUsed: Record<string, string | null>;
  settingsFromUrl: string | null;
  isNewInbox: boolean;
}

export const {
  hookState: useLastView,
  hookValue: useGetLastView,

  getValue: getLastView,
  setValue: setLastView,
  resetValue: resetLastView,
} = make<LastViewState>({
  defaultState: {
    productSlug: null,
    boardId: null,
    boardSlug: null,
    section: null,
    lastDoctypeIdsUsed: {},
    lastAssigneeIdsUsed: {},
    lastDocMentionDocTypeIdsUsed: {},
    settingsFromUrl: null,
    isNewInbox: false,
  },
  localKey: LocalKey.LastView,
});

export const setSettingsFromUrl = () => {
  const { pathname } = window.location;
  // We want to save the last non-settings page
  if (isSettingsPath(pathname)) return;
  setLastView({ settingsFromUrl: pathname });
};

export const setLastDoctypeIdUsed = (doctypeId: string) => {
  const {
    productSlug, lastDoctypeIdsUsed,
  } = getLastView();
  if (productSlug == null) return;
  setLastView({
    lastDoctypeIdsUsed: {
      ...lastDoctypeIdsUsed,
      [productSlug]: doctypeId,
    },
  });
};

export const setLastAssigneeIdUsed = (assigneeId: string | null) => {
  const {
    productSlug, lastAssigneeIdsUsed,
  } = getLastView();
  if (productSlug == null) return;
  setLastView({
    lastAssigneeIdsUsed: {
      ...lastAssigneeIdsUsed,
      [productSlug]: assigneeId,
    },
  });
};

/**
 * Returns the last used docType id.
 * If not defined or no longer part of the product
 * returns the first docType id of the product and sets it as the last one used.
 */
export const useLastDoctypeIdUsed = () => {
  const { product } = useProduct();
  const { lastDoctypeIdsUsed } = useGetLastView();
  if (!product?.slug) return undefined;
  const lastId = lastDoctypeIdsUsed[product.slug];
  const found = lastId && product.doctypes.edges.some(edge => edge.node.id === lastId);
  if (found) return lastId;
  const newLastId = product.doctypes.edges[0]?.node.id;
  if (!newLastId) return undefined;
  setLastDoctypeIdUsed(newLastId);
  return newLastId;
};

export const useLastAssigneeIdUsed = () => {
  const { product } = useProduct();
  const { lastAssigneeIdsUsed } = useGetLastView();
  if (!product?.slug) return undefined;
  return lastAssigneeIdsUsed[product.slug];
};

export const useLastDocMentionDocTypeIdUsed = () => {
  const {
    productSlug, lastDocMentionDocTypeIdsUsed,
  } = useGetLastView();
  if (productSlug == null) return null;
  return lastDocMentionDocTypeIdsUsed[productSlug] ?? null;
};

export const setLastDocMentionDocTypeIdUsed = (doctypeId: string) => {
  const {
    productSlug, lastDocMentionDocTypeIdsUsed,
  } = getLastView();
  if (productSlug == null) return;
  setLastView({
    lastDocMentionDocTypeIdsUsed: {
      ...lastDocMentionDocTypeIdsUsed,
      [productSlug]: doctypeId,
    },
  });
};

export const {
  hookValue: useGetLastInboxBoard,
  getValue: getLastInboxBoard,
  setValue: setLastInboxBoard,
  resetValue: resetLastInboxBoard,
} = make<{
  boardSlug?: string;
  boardId?: string;
}>({
  defaultState: {},
  localKey: LocalKey.LastInboxBoard,
});

export const {
  hookValue: useGetLastInsightBoard,
  getValue: getLastInsightBoard,
  setValue: setLastInsightBoard,
  resetValue: resetLastInsightBoard,
} = make<{
  boardSlug?: string;
  boardId?: string;
}>({
  defaultState: {},
  localKey: LocalKey.LastInsightBoard,
});

export const {
  hookValue: useGetLastRoadmapBoard,
  getValue: getLastRoadmapBoard,
  setValue: setLastRoadmapBoard,
  resetValue: resetLastRoadmapBoard,
} = make<{
  boardSlug?: string;
  boardId?: string;
}>({
  defaultState: {},
  localKey: LocalKey.LastRoadmapBoard,
});
