import { TABLET_MIN_WIDTH } from '@cycle-app/utilities';

export const RELEASES_INITIAL_PAGINATION_SIZE = 20;
export const RELEASES_NEXT_PAGINATION_SIZE = 10;

export const RELEASE_NOTES_INITIAL_PAGINATION_SIZE = 20;
export const RELEASE_NOTES_NEXT_PAGINATION_SIZE = 10;

export const RELEASES_PRODUCT_TOUR_SLIDE_WIDTH = window.innerWidth < TABLET_MIN_WIDTH ? 400 : 487;

export const RELEASE_NOTE_EDITOR = 'release-note-editor';
