import { typo, ring2 } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { DragHandle } from 'src/components/EditorDragMenu';
import { nodeCommonCss } from 'src/styles';

export const FileContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  position: relative;

  &:hover {
    background: ${p => p.theme.colors.editor.filePreview.bgHover};
    cursor: pointer;

    ${DragHandle} {
      display: flex;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  padding: 12px 16px;
  border-radius: 8px;
  background: ${p => p.theme.colors.background.tertiary};

  svg {
    margin-right: 8px;
  }

  path {
    fill: none;
    stroke: ${({ theme }) => theme.colors.editor.text};
  }
`;

export const FileName = styled.span`
  ${typo.body500}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.editor.text};
  line-height: 18px;
`;

export const FileSize = styled.span`
  ${typo.body400}
  margin: 0 8px;
  white-space: nowrap;
  color: ${Color.Grey700};
  line-height: 18px;
`;

type ContainerProps = {
  $isSelected: boolean;
};

export const Container = styled(NodeViewWrapper)<ContainerProps>`
  ${nodeCommonCss}
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  ${(p) => p.$isSelected && css`
    ${FileContainer} {
      ${ring2}
    }
  `}
`;
