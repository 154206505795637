import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;
  z-index: 8;
  pointer-events: none;
`;
