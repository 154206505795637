import {
  StepDetails,
  Tour,
  TourStep,
  TourName,
} from 'src/types/productTour.types';

import { SlackTourInvite } from '../components/ProductTour/SlackTourModal/SlackTourInvite';

export const SLACK_TOUR: Tour = {
  name: TourName.SLACK,
  step: 0,
  steps: [
    TourStep.SlackUserSync,
    TourStep.SlackInvite,
    TourStep.CreateFeedbackFromSlack,
    TourStep.CreateFeedbackFromSlackCommand,
    TourStep.SlackNotifications,
    TourStep.CloseLoopInSlack,
  ],
};

export const TOURS = [SLACK_TOUR];

export const STEP: Record<TourStep, StepDetails> = {
  [TourStep.SlackUserSync]: {
    title: 'Your Slack users are synchronized',
    text: 'Any Slack user in your workspace can be mapped to Cycle feedback. Slack users are all available as customers in Cycle.',
    images: ['/images/illustration-product-tour-slack-SlackUserSync.png'],
  },
  [TourStep.SlackInvite]: {
    component: <SlackTourInvite />,
  },
  [TourStep.CreateFeedbackFromSlack]: {
    title: 'Create detailed feedback with More actions',
    text: 'Click More actions on any message and select Add new Cycle to open a form and add properties to your feedback.',
    images: [
      '/images/illustration-product-tour-slack-CreateFeedbackFromSlack-1.png',
      '/images/illustration-product-tour-slack-CreateFeedbackFromSlack-2.png',
    ],
  },
  [TourStep.CreateFeedbackFromSlackCommand]: {
    title: 'Create feedback with /cycle-feedback',
    text: 'Type the /cycle-feedback command from anywhere in Slack, add a title for your feedback, and hit enter to open a feedback form.',
    images: ['/images/illustration-product-tour-slack-CreateFeedbackFromSlackCommand.png'],
  },
  [TourStep.SlackNotifications]: {
    title: 'Personalize your Slack notifications',
    text: 'Go to your account settings, open Notifications, and choose which notifications you want to receive directly in Slack.',
    images: ['/images/illustration-product-tour-slack-SlackNotifications.png'],
  },
  [TourStep.CloseLoopInSlack]: {
    title: 'Close the feedback loop in public',
    text: 'Slack users will receive public notifications about the progress on their feedback – these notifications can be customized in Slack integration settings (for Cycle Pro workspaces)',
    images: ['/images/illustration-product-tour-slack-CloseLoopInSlack.png'],
  },
};
