import { MateFragment } from '@cycle-app/graphql-codegen';
import { MemberCard, Emoji } from '@cycle-app/ui';
import { getDocSlug } from '@cycle-app/utilities';
import { CSSProperties, FC } from 'react';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { PageId } from 'src/constants/routing.constant';
import { useMemberViewing, useBoard, useDoc } from 'src/hooks';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useIsCurrentViewInboxType } from 'src/hooks/inbox';
import { useNavigate } from 'src/hooks/useNavigate';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { MemberViewing } from 'src/types/realTime.type';
import { getBoardSlug } from 'src/utils/slug.util';

type MemberProps = {
  user: MateFragment;
  style?: CSSProperties;
};

export const Member: FC<React.PropsWithChildren<MemberProps>> = ({
  user, style,
}) => {
  const {
    status, viewing, id,
  } = useMemberViewing(user);
  const board = useBoard(
    viewing === MemberViewing.BOARD ? id : undefined,
    viewing !== MemberViewing.BOARD,
  );
  const { doc } = useDoc(
    viewing === MemberViewing.DOC ? id : undefined,
    viewing !== MemberViewing.DOC,
  );
  const {
    navigate, navigateToInbox, navigateToInsightView, navigateToRoadmapView,
  } = useNavigate();
  const isCurrentViewInboxType = useIsCurrentViewInboxType();

  const memberName = (user.firstName || user.lastName)
    ? `${user.firstName} ${user.lastName}`
    : user.email;

  const sections = useBoardSections();

  const docType = useGetDocType(doc?.doctype?.id);

  if (status !== 'online') return null;

  return (
    <MemberCard
      key={user.id}
      email={user.email}
      mode={status}
      name={memberName}
      avatar={user.avatar?.url || undefined}
      color={user.color}
      viewing={renderViewing()}
      onClick={() => {
        if (viewing === MemberViewing.BOARD && board) {
          const boardSlug = getBoardSlug(board);
          const isInsightView = sections.insights.some(view => view.id === board.id);
          const isRoadmapView = sections.roadmaps.some(view => view.id === board.id);
          if (isCurrentViewInboxType(board.id)) {
            navigateToInbox(boardSlug);
          } else if (isInsightView) {
            navigateToInsightView(boardSlug);
          } else if (isRoadmapView) {
            navigateToRoadmapView(boardSlug);
          } else {
            navigate(PageId.Board, { boardSlug });
          }
        } else if (viewing === MemberViewing.DOC && doc) {
          navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
        }
      }}
      style={style}
    />
  );

  function renderViewing() {
    if (status === 'pending') return 'Not signed up';
    if (status === 'offline') return 'Offline';

    if (viewing && board) {
      return (
        <div>
          {!board.isBuiltIn && !!board?.emoji && <Emoji emoji={board.emoji} />}
          {' '}
          {board.name}
        </div>
      );
    }

    if (viewing && doc) {
      return (
        <div>
          <DocTypeIcon doctype={docType} />
          {' '}
          {doc.title}
        </div>
      );
    }

    return 'Online';
  }
};
