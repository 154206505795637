import { Tooltip } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';

import { useOptimizedBooleanState } from 'src/hooks';

import { SecondaryButton, StyledLinkInputContainer } from './Dropzone.styles';
import { LinkInput } from './LinkInput';

export const LinkButton = ({ onSubmit }: {
  onSubmit: (file: string) => void;
}) => {
  const [isOpen, {
    setTrueCallback: openDropdown,
    setFalseCallback: closeDropdown,
  }] = useOptimizedBooleanState(false);

  return (
    <Tooltip
      withPortal
      withWrapper={false}
      placement="top"
      variant="arrow"
      visible={isOpen}
      hide={closeDropdown}
      interactive
      controlled
      content={(
        <StyledLinkInputContainer>
          <LinkInput
            onSubmit={onSubmit}
            close={closeDropdown}
          />
        </StyledLinkInputContainer>
      )}
    >
      <SecondaryButton
        iconStart={<LinkIcon size={15} />}
        forceFocus={isOpen}
        onClick={e => {
          e.stopPropagation();
          openDropdown();
        }}
      >
        Link
      </SecondaryButton>
    </Tooltip>
  );
};
