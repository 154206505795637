import { Color, ViewType } from '@cycle-app/graphql-codegen/generated';
import { Emoji } from '@cycle-app/ui';
import { CommentIcon } from '@cycle-app/ui/icons';

import {
  StyledViewCard,
  AvatarSkeleton,
  TitleLineSkeleton,
  DescriptionLineSkeleton,
  HeaderContainerSkeleton,
  HeaderLineSkeleton,
  ActionsContainerSkeleton,
  AttributesContainerSkeleton,
  AttributeSkeleton,
} from './ViewPreview.styles';

export const PreviewCard = ({
  emoji, viewType, showComments = true, showAvatar = true,
}: { emoji?: string; viewType: ViewType; showComments?: boolean; showAvatar?: boolean }) => (
  <StyledViewCard
    disableHover
    preToolbar={(
      <>
        {viewType === ViewType.Kanban && <DescriptionLineSkeleton />}
        <AttributesContainerSkeleton>
          <AttributeSkeleton color={Color.A} />
          <AttributeSkeleton color={Color.L} />
          {viewType === ViewType.Kanban && (
            <>
              <AttributeSkeleton color={Color.K} />
              <AttributeSkeleton color={Color.C} />
            </>
          )}
        </AttributesContainerSkeleton>
      </>
      )}
    preTitle={(
      <HeaderContainerSkeleton>
        {emoji && <Emoji emoji={emoji} />}
        <HeaderLineSkeleton />
      </HeaderContainerSkeleton>
      )}
    sectionStart={(
      <ActionsContainerSkeleton>
        {showComments && <CommentIcon />}
      </ActionsContainerSkeleton>
    )}
    sectionEnd={showAvatar && <AvatarSkeleton />}
    title={<TitleLineSkeleton />}
    viewType={viewType}
  />
);
