import { mergeAttributes, Node } from '@tiptap/core';

import { LINEAR_EXTENSION_NAME, LINEAR_EXTENSION_TAGNAME } from '../constants';

export const getLinearMentionExtension = () => Node.create({
  name: LINEAR_EXTENSION_NAME,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'mention-linear',
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
      },
      type: {
        default: null,
        rendered: false,
      },
      publicId: {
        default: null,
      },
      status: {
        default: null,
        rendered: false,
      },
      url: {
        default: null,
        rendered: false,
      },
      title: {
        default: null,
        rendered: false,
      },
      teamKey: {
        default: null,
        rendered: false,
      },
      teamName: {
        default: null,
        rendered: false,
      },
      assignee: {
        default: null,
        rendered: false,
      },
      description: {
        default: null,
        rendered: false,
      },
      projectProgress: {
        default: null,
        rendered: false,
      },
      projectIssuesLength: {
        default: null,
        rendered: false,
      },
      icon: {
        default: null,
        rendered: false,
      },
      iconColor: {
        default: null,
        rendered: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: LINEAR_EXTENSION_TAGNAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      LINEAR_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },
});
