import { FC, ReactNode } from 'react';

import { useFeatureFlag } from 'src/hooks';
import { useIsMobile } from 'src/reactives';

import { Container } from './HomeViewSettings.styles';

export type HomeViewSettingsProps = {
  children?: ReactNode;
};

export const HomeViewSettings: FC<React.PropsWithChildren<HomeViewSettingsProps>> = ({ children }) => {
  const isMobile = useIsMobile();
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  // We cannot open user settings modal or go settings.
  if (isMobile) return null;
  return (
    <Container $cols={isAskEnabled ? 3 : 2}>
      {children}
    </Container>
  );
};
