import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const JiraProductDiscoveryIcon: FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <path d="M9.90826 7.75413C9.90826 7.75413 9.92156 7.99586 9.92156 8.33073C9.92156 9.57486 8.91251 10.5839 7.66838 10.5839C6.42425 10.5839 5.4152 9.57486 5.4152 8.33073C5.4152 7.0866 6.42425 6.07755 7.66838 6.07755C8.00325 6.07755 8.20728 6.08864 8.20728 6.08864V1.6865C8.02987 1.6732 7.85023 1.66211 7.66838 1.66211C3.98478 1.66211 0.999756 4.64935 0.999756 8.33073C0.999756 12.0121 3.98478 14.9994 7.66838 14.9994C11.352 14.9994 14.337 12.0143 14.337 8.33073C14.337 8.11784 14.3326 7.97147 14.3148 7.75191H9.90826V7.75413Z" fill="url(#paint0_linear_3264_74310)" />
    <path d="M9.22511 1.00631C11.0747 1.50751 12.9265 1.50751 14.776 1.00631C14.9091 0.97083 15.0288 1.09059 14.9934 1.22365C14.4922 3.07321 14.4922 4.92499 14.9934 6.77455C15.0288 6.90761 14.9091 7.02737 14.776 6.99189C12.9265 6.49069 11.0747 6.49069 9.22511 6.99189C9.09205 7.02737 8.97229 6.90761 9.00778 6.77455C9.50898 4.92499 9.50898 3.07321 9.00778 1.22365C8.97229 1.09059 9.09205 0.97083 9.22511 1.00631Z" fill="#0082FF" />
    <defs>
      <linearGradient id="paint0_linear_3264_74310" x1="6.84118" y1="9.15794" x2="11.4606" y2="4.53847" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0082FF" />
        <stop offset="1" stopColor="#0050D3" />
      </linearGradient>
    </defs>
  </Svg>
);
