import { history } from 'src/providers';
import { getSearchParams } from 'src/utils/routing.utils';

export const getProfileIdParam = () => {
  const params = getSearchParams();
  return params.get('profile');
};

export const closeProfileModal = () => {
  const params = getSearchParams();
  params.delete('profile');
  history.push({ search: params.toString() });
};

export const openProfileModal = (profileId: string) => {
  const params = getSearchParams();
  params.set('profile', profileId);
  history.push({ search: params.toString() });
};
