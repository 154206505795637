import { useCallback, useEffect } from 'react';

import { Layer } from 'src/types/layers.types';
import { getOpenedLayersAbove } from 'src/utils/layers.util';

interface UseEscape {
  (callback: VoidFunction | null | undefined, layer?: Layer): void;
}

export const useEscape: UseEscape = (callback, layer) => {
  const onEscape = useCallback(() => {
    if (layer && getOpenedLayersAbove(layer)) {
      // Abort
      return;
    }
    callback?.();
  }, [callback, layer]);

  const onKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      onEscape();
    }
  }, [onEscape]);

  useEffect(() => {
    if (callback) {
      document.addEventListener('keyup', onKeyUp);
    }
    return () => document.removeEventListener('keyup', onKeyUp);
  }, [onKeyUp, callback]);
};
