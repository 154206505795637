import { Editor } from '@tiptap/core';

import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetReleaseNoteEditor,
  getValue: getReleaseNoteEditor,
  setValue: setReleaseNoteEditor,
  resetValue: resetReleaseNoteEditor,
} = make<{
  editor: Editor | null;
}>({
  defaultState: {
    editor: null,
  },
});

export const focusEditor = () => getReleaseNoteEditor().editor?.commands.focus();
