import { ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

export const StyledDropdownLayer = styled(DropdownLayer)`
  ${ShyScrollbarCss}

  [data-scroll] & {
    max-height: calc(100vh - 48px);
  }
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 450px;
  max-width: 100%;
  padding: 16px;
`;
