import { TippyProps } from '@tippyjs/react';
import { useRef } from 'react';
import { Instance } from 'tippy.js';

import { adjustPopperPosition } from 'src/utils/popper.utils';

export const useTippyOffsetAdapter = () => {
  const instanceRef = useRef<Instance | null>(null);

  const forceUpdate = () => instanceRef.current?.popperInstance?.forceUpdate();

  const tippyProps: Pick<TippyProps, 'popperOptions' | 'onMount'> = {
    popperOptions: {
      modifiers: [{
        name: 'flip',
        enabled: false,
      }, {
        name: 'offsetAdapter',
        enabled: true,
        phase: 'read',
        requires: ['popperOffsets'],
        options: {
          offset: 24,
        },
        fn: adjustPopperPosition,
      }],
    },
    onMount: (tippyInstance) => {
      instanceRef.current = tippyInstance;
    },
  };

  return {
    tippyProps,
    forceUpdate,
  };
};
