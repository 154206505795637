import { UpdateDocCoverDocument, RemoveDocCoverDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from '../../useSafeMutation';

export default function useDocCoverMutations(docId: string, options?: { onUpdateStart?: VoidFunction; onUpdateSuccess?: VoidFunction }) {
  const [updateDocCoverMutation, { loading: isUploadingCover }] = useSafeMutation(UpdateDocCoverDocument, {
    onCompleted: (data) => {
      if (options?.onUpdateSuccess && data.updateDocCover?.id) {
        options.onUpdateSuccess();
      }
    },
  });
  const [removeDocCoverMutation] = useSafeMutation(RemoveDocCoverDocument);

  const updateDocCover = useCallback((cover: File | string) => {
    options?.onUpdateStart?.();
    return updateDocCoverMutation({
      variables: {
        docId,
        image: typeof cover === 'string'
          ? { avatarUrl: cover }
          : { avatar: cover },
      },
    });
  }, [updateDocCoverMutation, docId, options]);

  const removeDocCover = useCallback(() => removeDocCoverMutation({
    variables: { docId },
    optimisticResponse: {
      removeDocCover: {
        __typename: 'Doc',
        id: docId,
        cover: null,
      },
    },
  }), [removeDocCoverMutation, docId]);

  return {
    updateDocCover,
    removeDocCover,
    isUploadingCover,
  };
}
