import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const DashboardIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <Svg ref={ref} {...props} width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5700_5111)">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.0693 2.03066C2.04803 2.03066 2.03078 2.04791 2.03078 2.06918V6.38365C2.03078 6.40492 2.04803 6.42217 2.0693 6.42217H5.15106C5.17234 6.42217 5.18959 6.40492 5.18959 6.38365V2.06918C5.18959 2.04791 5.17234 2.03066 5.15106 2.03066H2.0693ZM0.875122 2.06918C0.875122 1.40965 1.40978 0.875 2.0693 0.875H5.15106C5.81059 0.875 6.34525 1.40965 6.34525 2.06918V6.38365C6.34525 7.04317 5.81059 7.57783 5.15106 7.57783H2.0693C1.40978 7.57783 0.875122 7.04317 0.875122 6.38365V2.06918Z" fill="#707070" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.84891 2.03066C8.82763 2.03066 8.81038 2.04791 8.81038 2.06918V3.91824C8.81038 3.93951 8.82763 3.95676 8.84891 3.95676H11.9307C11.9519 3.95676 11.9692 3.93951 11.9692 3.91824V2.06918C11.9692 2.04791 11.9519 2.03066 11.9307 2.03066H8.84891ZM7.65472 2.06918C7.65472 1.40965 8.18938 0.875 8.84891 0.875H11.9307C12.5902 0.875 13.1248 1.40965 13.1248 2.06918V3.91824C13.1248 4.57777 12.5902 5.11242 11.9307 5.11242H8.84891C8.18938 5.11242 7.65472 4.57777 7.65472 3.91824V2.06918Z" fill="#707070" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.84891 7.57753C8.82763 7.57753 8.81038 7.59478 8.81038 7.61606V11.9305C8.81038 11.9518 8.82763 11.969 8.84891 11.969H11.9307C11.9519 11.969 11.9692 11.9518 11.9692 11.9305V7.61606C11.9692 7.59478 11.9519 7.57753 11.9307 7.57753H8.84891ZM7.65472 7.61606C7.65472 6.95653 8.18938 6.42188 8.84891 6.42188H11.9307C12.5902 6.42188 13.1248 6.95653 13.1248 7.61606V11.9305C13.1248 12.59 12.5902 13.1247 11.9307 13.1247H8.84891C8.18938 13.1247 7.65472 12.59 7.65472 11.9305V7.61606Z" fill="#707070" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.0693 10.0434C2.04803 10.0434 2.03078 10.0606 2.03078 10.0819V11.9309C2.03078 11.9522 2.04803 11.9695 2.0693 11.9695H5.15106C5.17234 11.9695 5.18959 11.9522 5.18959 11.9309V10.0819C5.18959 10.0606 5.17234 10.0434 5.15106 10.0434H2.0693ZM0.875122 10.0819C0.875122 9.42235 1.40978 8.8877 2.0693 8.8877H5.15106C5.81059 8.8877 6.34525 9.42235 6.34525 10.0819V11.9309C6.34525 12.5905 5.81059 13.1251 5.15106 13.1251H2.0693C1.40978 13.1251 0.875122 12.5905 0.875122 11.9309V10.0819Z" fill="#707070" />
    </g>
    <defs>
      <clipPath id="clip0_5700_5111">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </Svg>
));
