import styled from 'styled-components';

import { IconContainer } from './Placeholder/DropPlaceholder.styles';

export const DropArea = styled.div<{ hasFullCoverage?: boolean; hideBorder?: boolean }>`
  position: absolute;
  top: 0;
  bottom: ${(p) => (p.hasFullCoverage ? '0' : '16px')};
  left: ${(p) => (p.hasFullCoverage ? '0' : '24px')};
  right: ${(p) => (p.hasFullCoverage ? '0' : '24px')};
  background-color: ${p => `${p.theme.colors.dropZone.bg}DD`};
  border: 1px dashed ${(p) => (p.hideBorder ? 'transparent' : p.theme.colors.border.grey)};
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.text.secondary};
  cursor: pointer;
  z-index: 1;

  &:hover {
    border: 1px dashed ${p => (p.hideBorder ? 'transparent' : 'hsl(var(--cycle))')};
    color: hsl(var(--cycle));

    ${IconContainer} {
      svg {
        fill: hsl(var(--cycle));
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -16px;
    bottom: ${(p) => (p.hasFullCoverage ? '0' : '-16px')};
    left: ${(p) => (p.hasFullCoverage ? '0' : '-24px')};
    right: ${(p) => (p.hasFullCoverage ? '0' : '-24px')};
    z-index: -1;
  }
`;
