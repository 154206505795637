import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

type Props = SvgProps & {
  isBump?: boolean;
};

export const TagIcon: FC<React.PropsWithChildren<Props>> = ({
  isBump, ...props
}) => {
  return isBump ? (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_3420_17321)">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.33337 3.33334C1.33337 2.22877 2.22881 1.33334 3.33337 1.33334H7.44777C7.97817 1.33334 8.48691 1.54405 8.86197 1.91912L14.0286 7.0858C14.8097 7.86687 14.8097 9.13314 14.0286 9.9142L9.91424 14.0286C9.13317 14.8097 7.86691 14.8097 7.08584 14.0286L1.91916 8.86194C1.54409 8.48687 1.33337 7.97814 1.33337 7.44774V3.33334ZM5.00004 6C5.55233 6 6.00004 5.55229 6.00004 5C6.00004 4.44772 5.55233 4 5.00004 4C4.44775 4 4.00004 4.44772 4.00004 5C4.00004 5.55229 4.44775 6 5.00004 6Z" fill="url(#paint0_linear_3420_17321)" />
      </g>
      <defs>
        <filter id="filter0_d_3420_17321" x="-0.666667" y="0" width="17.3333" height="17.3333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="0.333333" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3420_17321" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3420_17321" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_3420_17321" x1="7.97391" y1="1.33334" x2="7.97391" y2="14.6144" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.35484 2.58065C2.35484 2.45594 2.45594 2.35484 2.58065 2.35484H7.71259L13.4754 8.11762C13.5841 8.22731 13.6452 8.37553 13.6452 8.53002C13.6452 8.68449 13.5836 8.83328 13.4748 8.94297L8.94238 13.4754C8.83269 13.5841 8.68449 13.6452 8.53002 13.6452C8.37553 13.6452 8.22677 13.5836 8.11708 13.4748L2.35484 7.71259V2.58065ZM2.58065 1C1.70768 1 1 1.70768 1 2.58065V7.99318C1 8.17285 1.07137 8.34515 1.19841 8.47219L7.16049 14.4343L7.16192 14.4357C7.52551 14.7971 8.01735 15 8.53002 15C9.0427 15 9.53454 14.7971 9.89813 14.4357L9.89955 14.4343L14.4343 9.89955L14.4357 9.89813C14.7971 9.53454 15 9.0427 15 8.53002C15 8.01735 14.7971 7.52551 14.4357 7.16192L8.47219 1.19841C8.34515 1.07137 8.17285 1 7.99318 1H2.58065ZM4.83529 3.93195C4.33646 3.93195 3.93207 4.33633 3.93207 4.83517C3.93207 5.33401 4.33646 5.7384 4.83529 5.7384H4.84161C5.34045 5.7384 5.74484 5.33401 5.74484 4.83517C5.74484 4.33633 5.34045 3.93195 4.84161 3.93195H4.83529Z" fill="#171717" />
    </Svg>
  );
};
