import { typo, Button, boxShadowZ2 } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${boxShadowZ2}
  border-radius: 16px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: ${p => p.theme.colors.ugradeLayout.mainBox.bg};
  margin-top: 42px;
  position: relative;
  z-index: 2;
`;

export const TextContainer = styled.div`
  padding: 20px;
  ${typo.body500}
  color: ${p => p.theme.colors.ugradeLayout.activeText.color};

  p {
    display: inline-flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }
`;

export const TextBottomContainer = styled(TextContainer)`
  color: ${p => p.theme.colors.text.secondary};
  padding-top: 0;
`;

export const CTAContainer = styled.div`
  color: ${p => p.theme.colors.text.disabled};
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

interface UpgradeButtonProps { $isCurrent?: boolean }

export const UpgradeButton = styled(Button) <UpgradeButtonProps>`
  margin-top: 20px;
  width: 100%;
  cursor: pointer;
`;
