import { DocCommentFragment } from '@cycle-app/graphql-codegen';
import { FC, useRef } from 'react';

import { DocComment } from 'src/components/DocComment';
import { DocCommentMenu } from 'src/components/DocCommentMenu';
import { DocCommentNew } from 'src/components/DocCommentNew';

import { CommentsSkeletons } from './CommentSkeletons';
import { DocChatHeader } from './DocChatHeader';
import { DocChatContainer, Container, CommentsList } from './DocComments.styles';

type Props = {
  threadId?: string;
  docId: string;
  comments: DocCommentFragment[];
  maxHeight?: string;
  commentsCount?: number;
  isCommentsLoading: boolean;
  blockId: string | null;
  onSend?: (isReply: boolean) => void;
  autoFocus?: boolean;
  hide?: VoidFunction;
  showHeader?: boolean;
  isResolved: boolean;
  className?: string;
};

export const DocChat: FC<React.PropsWithChildren<Props>> = ({
  threadId,
  docId,
  comments,
  maxHeight,
  commentsCount,
  isCommentsLoading,
  blockId = null,
  onSend,
  autoFocus,
  hide,
  showHeader,
  isResolved,
  className,
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const firstComment = comments.at(-1);

  return (
    <DocChatContainer className={className}>
      {showHeader && firstComment && (
        <DocChatHeader
          threadId={threadId}
          docId={docId}
          blockId={blockId}
          firstCommentId={firstComment?.id}
          isDeletable={firstComment.creator.__typename === 'Me'}
          hide={hide}
          isResolved={isResolved}
        />
      )}
      <Container
        noComments={commentsCount === 0}
      >
        <CommentsList
          ref={listRef}
          $maxHeight={maxHeight}
        >
          {isCommentsLoading && <CommentsSkeletons count={commentsCount} />}

          {comments.map((comment, index) => (
            <DocComment
              // using index as key is not ideal but optimistic data has a temporary id
              // which causes a blinking effect when remounting the editor component
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              docId={docId}
              comment={comment}
              menu={(
                <DocCommentMenu
                  docId={docId}
                  blockId={blockId}
                  comment={comment}
                  isFirstComment={index === comments.length - 1}
                  showResolve={!showHeader}
                />
            )}
            />
          ))}
        </CommentsList>

        <DocCommentNew
          docId={docId}
          isReply={!!commentsCount}
          blockId={blockId}
          onSend={onSend}
          autoFocus={autoFocus}
          isResolved={isResolved}
        />
      </Container>
    </DocChatContainer>
  );
};
