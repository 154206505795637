import { MouseEvent } from 'react';

export const preventClick = (e: MouseEvent): void => {
  e.preventDefault();
  e.stopPropagation();
};

export const disablePointerEvents = (className: string): void => {
  Array.from(document.getElementsByClassName(className)).forEach((element) => {
    if (!(element instanceof HTMLElement)) return;
    // eslint-disable-next-line no-param-reassign
    element.style.pointerEvents = 'none';
  });
};

export const enablePointerEvents = (className: string): void => {
  Array.from(document.getElementsByClassName(className)).forEach((element) => {
    if (!(element instanceof HTMLElement)) return;
    // eslint-disable-next-line no-param-reassign
    element.style.pointerEvents = '';
  });
};
