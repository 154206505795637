import { useQuery } from '@apollo/client';
import { UserNodeDocument } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

export const useUser = (userId: string) => {
  const {
    data, loading,
  } = useQuery(UserNodeDocument, {
    fetchPolicy: 'cache-first',
    skip: !userId,
    variables: {
      userId: userId as string,
    },
  });
  const user = useMemo(() => (
    data?.node?.__typename === 'Me' || data?.node?.__typename === 'OtherUser' ? data.node : null
  ), [data]);
  return {
    user,
    isLoading: loading,
  };
};
