import styled from 'styled-components';

import { ColorPicker } from '../ColorPicker/ColorPicker';
import { ColorItem } from '../ColorPicker/ColorPicker.styles';
import { Dropdown as DropdownRaw } from '../Dropdown/Dropdown';

type Size = 'S' | 'M';

export interface ContainerProps {
  $size?: Size;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 6px;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;

  ${p => `
    border: 1px solid ${p.theme.colors.border.primary};
    color: ${p.theme.colors.body.color};
    background: ${p.theme.colors.card.bg};

    :hover {
      background: ${p.theme.colors.card.bgHover};
    }

    height: ${p.$size === 'S' ? 32 : 40}px;
    width: ${p.$size === 'S' ? 32 : 40}px;
  `};
`;

export const Dropdown = styled(DropdownRaw)`
  padding: 12px;
`;

export const ColorPickerStyled = styled(ColorPicker)`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(6, 1fr);
`;

export const ColorItemStyled = styled(ColorItem)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
`;
