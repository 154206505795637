import { DoctypeType, OperatorIsEmptyOrNot, OperatorIsInOrNot } from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useLastAssigneeIdUsed } from 'src/reactives/lastView.reactive';
import { isFeedback } from 'src/utils/docType.util';

export const useAssigneeFromBoardConfig = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const usersForAssignee = useBoardConfig(ctx => ctx.usersForAssignee);
  const lastAssigneeIdsUsed = useLastAssigneeIdUsed();
  const assigneeFilter = useMemo(() => boardConfig?.filterProperties.edges
    .find(filterProperty => filterProperty.node.__typename === 'FilterPropertyRuleAssignee'), [boardConfig]);

  const getAssigneeFromBoardConfig = useCallback(({
    groupId, doctypeType,
  }: { groupId?: string; doctypeType: DoctypeType }) => {
    if (
      boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' &&
      boardConfig.docQuery.groupbyConfig.property.__typename === 'AssigneeDefinition'
    ) {
      return boardConfig.docQuery.docGroups.edges
        .find(({ node }) => node.id === groupId)
        ?.node.propertyValue?.id;
    }

    if (
      boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy' &&
      boardConfig.docQuery.groupbyConfig.property.__typename === 'AssigneeDefinition'
    ) {
      return boardConfig.docQuery.swimlanes.edges[0]?.node.docGroups.edges
        .find(({ node }) => node.id === groupId)
        ?.node.propertyValue?.id;
    }

    const isDoctypeFeedback = isFeedback({ type: doctypeType });

    if (isDoctypeFeedback && !assigneeFilter) {
      // if null the back will handle the assignee.
      return usersForAssignee.find(user => user.id === lastAssigneeIdsUsed)?.id || null;
    }

    if (assigneeFilter?.node.__typename === 'FilterPropertyRuleAssignee') {
      if (assigneeFilter.node.assigneeRule.__typename === 'RuleUserMultipleValues') {
        const { operator } = assigneeFilter.node.assigneeRule;
        const filtered = assigneeFilter.node.assigneeRule.values
          .edges.filter(({ node }) => (operator === OperatorIsInOrNot.Is ? node.selected : !node.selected));

        // Assignee is mandatory for feedback.
        if (isDoctypeFeedback) {
          return (
            filtered.find(({ node }) => node.value.id === lastAssigneeIdsUsed)?.node.value.id ||
            filtered[0]?.node.value.id ||
            // if null the back will handle the assignee.
            null
          );
        }

        return operator === OperatorIsInOrNot.Is
          ? (
            filtered.find(({ node }) => node.value.id === lastAssigneeIdsUsed)?.node.value.id ||
            filtered[0]?.node.value.id
          )
          : null;
      }
      if (
        assigneeFilter.node.assigneeRule.__typename === 'RuleIsEmptyOrNot' &&
        assigneeFilter.node.assigneeRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty
      ) {
        return (
          usersForAssignee.find(user => user.id === lastAssigneeIdsUsed)?.id ||
          usersForAssignee[0]?.id
        );
      }
    }

    return null;
  }, [boardConfig, usersForAssignee, lastAssigneeIdsUsed, assigneeFilter]);

  const isAssigneeEmptyRuleOrGroup = useCallback((groupId?: string) => {
    if (
      boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' &&
      boardConfig.docQuery.groupbyConfig.property.__typename === 'AssigneeDefinition'
    ) {
      return !boardConfig.docQuery.docGroups.edges
        .find(({ node }) => node.id === groupId)
        ?.node.propertyValue?.id;
    }
    if (
      boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy' &&
      boardConfig.docQuery.groupbyConfig.property.__typename === 'AssigneeDefinition'
    ) {
      return !boardConfig.docQuery.swimlanes.edges[0]?.node.docGroups.edges
        .find(({ node }) => node.id === groupId)
        ?.node.propertyValue?.id;
    }
    if (
      assigneeFilter?.node.__typename === 'FilterPropertyRuleAssignee' &&
      assigneeFilter?.node.assigneeRule.__typename === 'RuleIsEmptyOrNot'
    ) {
      return assigneeFilter.node.assigneeRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty;
    }
    if (
      assigneeFilter?.node.__typename === 'FilterPropertyRuleAssignee' &&
      assigneeFilter.node.assigneeRule.__typename === 'RuleUserMultipleValues' &&
      assigneeFilter.node.assigneeRule.operator === OperatorIsInOrNot.IsNot
    ) {
      const filtered = assigneeFilter.node.assigneeRule.values
        .edges.filter(({ node }) => node.selected);
      return usersForAssignee.every((user) => filtered.find(({ node }) => user.id === node.value.id));
    }
    return false;
  }, [usersForAssignee, assigneeFilter, boardConfig]);

  return {
    getAssigneeFromBoardConfig,
    isAssigneeEmptyRuleOrGroup,
  };
};
