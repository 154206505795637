import { useBoardSlug } from 'src/hooks/usePathParams';

import { useInsightSection } from './useInsightSection';

export const useInsightViewCurrent = () => {
  const boardSlug = useBoardSlug();
  const { boards } = useInsightSection();
  if (!boardSlug) return undefined;
  return boards.find(board => boardSlug.includes(board.id));
};
