import { useQuery } from '@apollo/client';
import {
  AutopilotState, FindInsightState, DocStateDocument, ExtractQuotesDocument, DocTargetFragment, QuoteFragment,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { delay } from '@cycle-app/utilities/src/utils/async.utils';
import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import { FC, useMemo, useEffect, useState } from 'react';

import { useSafeMutation } from 'src/hooks';
import { useAutopilotStateSubscription } from 'src/hooks/api/useAutopilotStateSubscription';
import { useDocQuotesSubscription } from 'src/hooks/api/useDocQuotesSubscription';
import { useFindInsightStateSubscription } from 'src/hooks/api/useFindInsightStateSubscription';
import { useRequestExtractQuotes } from 'src/hooks/useRequestExtractQuotes';
import { closeVerifyQuotes, openVerifyQuotes } from 'src/reactives/docRightPanel.reactive';
import { setSummaryVisibleTrue } from 'src/reactives/summaryVisible.reactive';
import client from 'src/services/apollo/client';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { extract } from 'src/types/graphql.types';
import { isVerifiedQuote } from 'src/utils/quotes.utils';

type Status =
  // Quotes are being extracted
  | 'loading'
  // New quotes are found and need to be verified
  | 'verify'
  // All quotes are verified
  | 'verified'
  // No quotes are found, the user can retry the extraction
  | 'retry'
  // No quotes
  | 'extract';

type ExtractQuotesProviderProps = {
  docId: string;
  doc: FullDocWithPublicId | null;
};

type ExtractQuotesContextValue = {
  status: Status;
  quotes: QuoteFragment[];
  insights: DocTargetFragment[];
  extractQuotes: VoidFunction;
  setVerified: VoidFunction;
};

const ExtractQuotesContext = createContext<ExtractQuotesContextValue>({} as ExtractQuotesContextValue);

export const ExtractQuotesProvider: FC<React.PropsWithChildren<ExtractQuotesProviderProps>> = ({
  docId, doc, children,
}) => {
  const [autopilotState, setAutopilotState] = useState<AutopilotState | null | undefined>(null);
  const [findInsightState, setFindInsightState] = useState<FindInsightState | null | undefined>(null);

  useAutopilotStateSubscription(docId, state => {
    setAutopilotState(state);
  });

  useFindInsightStateSubscription(docId, state => {
    setFindInsightState(state);
  });

  useQuery(DocStateDocument, {
    fetchPolicy: 'network-only',
    skip: !docId,
    variables: { id: docId as string },
    onCompleted: (data) => {
      const docState = extract('Doc', data.node);

      if (docState?.findInsightState) {
        setFindInsightState(docState.findInsightState);
      }

      if (docState?.autopilotState) {
        setAutopilotState(docState?.autopilotState);
      }
    },
  });

  const docState = autopilotState ?? findInsightState;

  const quotes = nodeToArray(doc?.quotes);
  const insights = nodeToArray(doc?.docTargets).filter(isVerifiedQuote);

  const [isFetchingQuotes, setIsfetchingQuotes] = useState(false);

  // Used to show the retry button when no quote is found
  const [noQuote, setNoQuote] = useState(false);

  // Used to force “verified” status when verifying multiple quotes
  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    if (insights.length === 0) {
      setIsVerified(false);
    }
  }, [insights.length]);

  // Reset states when docId changes (e.g. when switching between docs with prev/next buttons)
  useEffect(() => {
    setAutopilotState(null);
    setFindInsightState(null);
    setIsfetchingQuotes(false);
    setIsVerified(false);
    setNoQuote(false);
    setSummaryVisibleTrue();
  }, [docId]);

  useDocQuotesSubscription(docId, {
    onSubscriptionData: async ({ subscriptionData: { data } }) => {
      if (!isFetchingQuotes) return;

      // Wait for the quotes to be updated in the cache, to avoid flickering
      await delay(500);

      setIsfetchingQuotes(false);
      if (data && data.docQuotes.length === 0) {
        setNoQuote(true);
      } else {
        openVerifyQuotes();
      }
    },
  });

  const [extractQuotes, extractResult] = useSafeMutation(ExtractQuotesDocument, {
    onCompleted: () => {
      client.cache.modify({
        id: client.cache.identify({ id: docId }),
        fields: {
          findInsightState: () => FindInsightState.Unprocessed,
          autopilotState: () => null,
        },
      });
    },
  });

  const status: Status = (() => {
    if (!!docState || extractResult.loading || isFetchingQuotes) return 'loading';
    if (isVerified) return 'verified';
    if (quotes.length > 0) return 'verify';
    if (insights.length > 0) return 'verified';
    if (noQuote) return 'retry';
    return 'extract';
  })();

  const requestExtractQuotes = useRequestExtractQuotes();

  // Close the verify quotes section when all quotes have been verified (e.g. from the summary of quotes)
  useEffect(() => {
    if (quotes.length === 0) {
      closeVerifyQuotes();
    }
  }, [quotes]);

  const value = useMemo<ExtractQuotesContextValue>(() => {
    return {
      status,
      quotes,
      insights,
      setVerified: () => setIsVerified(true),
      extractQuotes: () => {
        if (!docId) return;
        if (!requestExtractQuotes()) return;
        setIsfetchingQuotes(true);
        setNoQuote(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        extractQuotes({ variables: { docId } });
      },
    };
  }, [docId, extractQuotes, insights, quotes, requestExtractQuotes, status]);

  return (
    <ExtractQuotesContext.Provider value={value}>
      {children}
    </ExtractQuotesContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useExtractQuotesContext = <T extends unknown = ExtractQuotesContextValue>(selector?: ContextSelector<ExtractQuotesContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(ExtractQuotesContext);
  if (!isWrappedWithContext) throw new Error('useExtractQuotesContext must be used within a ExtractQuotesProvider');
  return useContextSelector(ExtractQuotesContext, selector ?? (ctx => ctx as T));
};
