import { SectionType } from '@cycle-app/graphql-codegen';
import { toObject, nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { isViewMyInbox } from 'src/utils/inbox.util';

import { useProduct } from './useProduct';

export const useBoardSections = () => {
  const {
    product,
    loading,
  } = useProduct();

  const sections = useMemo(
    () => nodeToArray(product?.boardSections)
      .filter(section => ![SectionType.Feedback, SectionType.Insights, SectionType.Roadmaps].includes(section.type)),
    [product],
  );
  const inboxSection = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type === SectionType.Feedback),
    [product],
  );
  const insightsSection = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type === SectionType.Insights),
    [product],
  );
  const roadmapsSection = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type === SectionType.Roadmaps),
    [product],
  );
  const defaultSection = useMemo(
    () => nodeToArray(product?.boardSections).find(section => section.type === SectionType.Default),
    [product],
  );
  const inbox = useMemo(() => nodeToArray(inboxSection[0]?.boards), [inboxSection]);
  const insights = useMemo(() => nodeToArray(insightsSection[0]?.boards), [insightsSection]);
  const roadmaps = useMemo(() => nodeToArray(roadmapsSection[0]?.boards), [roadmapsSection]);
  const inboxWitoutMyInbox = useMemo(() => inbox.filter(view => !isViewMyInbox(view)), [inbox]);
  const customSections = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type === SectionType.Custom),
    [product],
  );

  const mappingBoards = useMemo(
    () => Object.fromEntries(
      sections.flatMap(section => nodeToArray(section.boards)).map(board => [board.id, board]),
    ),
    [sections],
  );

  const mappingBoardLinks = useMemo(
    () => Object.fromEntries(
      sections.flatMap(section => nodeToArray(section.boardLinks).map(boardLink => ({
        ...boardLink,
        section,
      }))).map(boardLink => [boardLink.id, boardLink]),
    ),
    [sections],
  );

  const mappingSections = useMemo(() => toObject(sections), [sections]);

  return {
    sections,
    loading,
    mappingBoards,
    mappingSections,
    customSections,
    inbox,
    inboxWitoutMyInbox,
    insights,
    roadmaps,
    mappingBoardLinks,
    defaultSection,
  };
};
