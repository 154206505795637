export const filenamify = (text?: string | null): string | null => {
  if (!text) return null;
  return text
    .trim()
    .slice(0, 100)
    .replace(/[^\w ]/gi, '_') // Replace all non-alphanumeric characters with _
    .replace(/_{2,}/g, '_') // Replace multiple _ with single _
    .replace(/^_/, '') // Trim _ from start of text
    .replace(/_$/, ''); // Trim _ from end of text
};
