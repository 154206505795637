import { Button } from '@cycle-app/ui';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks';
import { useInsightTour } from 'src/hooks/useInsightTour';

import { Container, Content, LearnMoreButton } from './InsightsEmpty.styles';

export const InsightsEmpty = () => {
  const { open: openInsightTour } = useInsightTour();
  const { navigate } = useNavigate();
  return (
    <Container>
      <Content>
        <h1>Insights</h1>

        <p>
          Extract insights from your feedback.
          Highlight pieces of feedback and turn them into insights.
          Then come back here to visualize your insights as customer quotes.
        </p>

        <Button
          size="L"
          onClick={() => navigate(PageId.Inbox)}
          style={{
            height: 28,
            padding: '0 10px',
          }}
        >
          Go to feedback
        </Button>

        <LearnMoreButton onClick={openInsightTour}>
          Learn more about insights
        </LearnMoreButton>
      </Content>
    </Container>
  );
};
