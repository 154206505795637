import { ListPositionInput } from '@cycle-app/graphql-codegen';
import {
  CollisionDetection,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  SensorDescriptor,
  SensorOptions,
  TouchSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core';
import { useEffect, useState } from 'react';

import { reorder } from 'src/utils/dnd.util';

interface OnFinishArgs {
  items: string[];
  position: ListPositionInput;
  itemMovedId: string;
}

interface UseSortableDnd {
  (p: {
    initialItems: string[];
    onFinish?: ({
      items,
      position,
    }: OnFinishArgs) => void;
  }): {
    activeId: string | null;
    items: string[];
    dndContextProps: {
      onDragStart: (e: DragStartEvent) => void;
      onDragEnd: (e: DragEndEvent) => void;
      onDragCancel: () => void;
      collision?: CollisionDetection;
      sensors: SensorDescriptor<SensorOptions>[];
    };
  };
}

export const useSortableDnd: UseSortableDnd = ({
  initialItems,
  onFinish,
}) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems.join()]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  function onDragStart({ active }: DragStartEvent) {
    setActiveId(String(active.id));
  }

  function onDragEnd({
    active, over,
  }: DragEndEvent) {
    if (!over) {
      return;
    }

    const activeIdString = String(active.id);
    const activeIndex = items.indexOf(activeIdString);
    const overIndex = items.indexOf(String(over.id));

    if (activeIndex !== overIndex) {
      const itemsUpdated = reorder(
        items,
        activeIndex,
        overIndex,
      );
      setItems(itemsUpdated);

      if (onFinish) {
        const [afterIndex, beforeIndex] = activeIndex < overIndex
          ? [overIndex, overIndex + 1]
          : [overIndex - 1, overIndex];

        const after = !items[beforeIndex] ? items[afterIndex] || '' : '';
        const before = items[beforeIndex] ?? undefined;

        if (after || before) {
          onFinish({
            itemMovedId: activeIdString,
            items: itemsUpdated,
            position: {
              before,
              after,
            },
          });
        }
      }
    }

    setActiveId(null);
  }

  function onDragCancel() {
    setActiveId(null);
  }

  return {
    activeId,
    items,
    dndContextProps: {
      collisionDetection: rectIntersection,
      sensors,
      onDragStart,
      onDragCancel,
      onDragEnd,
      activeId,
    },
  };
};
