import { BoardWithMinimalConfigAndDraftConfigIdFragment, SectionType } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { BoardEditForm } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { Header, Title, CloseButtonStyled } from 'src/components/DialogModal/DialogModal.styles';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';

import { setBoardConfigAnalyticsClose } from '../../reactives/boardConfigAnalytics.reactive';
import { InsightViewBoardConfigFields } from '../InsightViewBoardConfigFields';
import { Modal, ModalFooter, ModalActions } from './InsightViewSettings.styles';

type Props = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
};

export const InsightViewAnalyticsSettingsModal = ({ board }: Props) => {
  const boardMutations = useBoardMutations();
  return (
    <Modal hide={setBoardConfigAnalyticsClose}>
      <Header>
        <Title>Dashboard settings</Title>
        <CloseButtonStyled size="L" onClick={setBoardConfigAnalyticsClose}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <BoardEditForm
        sectionType={SectionType.Insights}
        defaultValues={board}
        focusNameOnMount
        onSubmit={async (formData, isDirty) => {
          if (isDirty) {
            await boardMutations.updateBoard({
              ...formData,
              boardId: board.id,
            });
          }
          setBoardConfigAnalyticsClose();
        }}
      >
        <InsightViewBoardConfigFields boardId={board.id} />

        <ModalFooter>
          <ModalActions>
            <Button
              size="M"
              variant="secondary"
              onClick={setBoardConfigAnalyticsClose}
            >
              Cancel
            </Button>

            <Button
              size="M"
              type="submit"
              isLoading={boardMutations.loading}
            >
              Save
            </Button>
          </ModalActions>
        </ModalFooter>
      </BoardEditForm>
    </Modal>
  );
};
