import { DocumentEmptyIcon } from '@cycle-app/ui/icons';
import { FileViewAttributes } from '@cycle-app/utilities';
import { Content, NodeViewRendererProps } from '@tiptap/react';
import { useCallback } from 'react';

import { EditorNodeEmptyState } from 'src/components/EditorNodeEmptyState';
import { useEditorContext } from 'src/contexts/editorContext';
import { useTranscript } from 'src/hooks/editor/useTranscript';
import { hasEditorNodeNextAction } from 'src/reactives';
import { setTranscriptTooltip, useTranscriptTooltip } from 'src/reactives/transcript.reactive';
import { deleteNodeRange, insertFile } from 'src/utils/editor/editor.utils';
import { getFileSizeWithUnit, isDocumentTranscribable } from 'src/utils/files.util';

import { TranscriptTooltip } from '../Editor/TranscriptTooltip';
import { EditorMediaDragMenu } from '../EditorMediaDragMenu';
import { EditorTranscriptStatusState } from '../EditorTranscriptStatusState';
import { Container, FileContainer, FileName, FileSize, InfoContainer } from './EditorFileView.styles';

type EditorFileViewProps = NodeViewRendererProps & {
  selected: boolean;
  updateAttributes: (attr: FileViewAttributes) => void;
};

export const EditorFileView = ({
  node, selected, getPos, updateAttributes,
}: EditorFileViewProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const isInert = useEditorContext(ctx => ctx.isInert);

  const {
    file, dataId,
  } = node.attrs as FileViewAttributes;

  const {
    getTranscript, isLoading: isTranscriptLoading, insertTranscript,
  } = useTranscript({
    node,
    getPos,
    dataId,
  });

  const onDelete = () => deleteNodeRange({
    editor,
    node,
    getPos,
  });

  const onTranscript = useCallback(() => file?.src && getTranscript({
    src: file.src,
    type: 'file',
    insertContent: true,
  }), [getTranscript, file?.src]);

  const dataToTranscript = useTranscriptTooltip(dataId);

  return (
    <Container $isSelected={!isInert && selected && !isTranscriptLoading}>
      <FileContainer>
        {editor.isEditable && (
          <EditorMediaDragMenu
            onDelete={onDelete}
            src={file?.src}
            title={file?.title}
            type="file"
            {...file?.src && isDocumentTranscribable(file.src) && { onTranscript }}
          />
        )}
        <TranscriptTooltip
          dataId={dataId}
          transcript={() => {
            if (!dataToTranscript) return;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getTranscript(dataToTranscript).then(insertTranscript);
          }}
        >
          {file
            ? (

              <InfoContainer>
                <DocumentEmptyIcon />
                <FileName>{file?.title ?? 'No name'}</FileName>
                {file.size && <FileSize>{getFileSizeWithUnit(file.size)}</FileSize>}
              </InfoContainer>
            )
            : (
              <EditorNodeEmptyState
                type="file"
                dataId={dataId || crypto.randomUUID()}
                isLoading={isTranscriptLoading}
                onCancel={onDelete}
                onUploaded={async (fileData) => {
                  let transcriptContent: Content | null = null;
                  if (dataId && hasEditorNodeNextAction(dataId) === 'transcript') {
                    transcriptContent = await getTranscript(fileData);
                  } else {
                    setTranscriptTooltip(dataId, fileData);
                  }
                  if (fileData.type !== 'file') {
                    const from = typeof getPos === 'function' ? getPos() : null;
                    if (Number.isNaN(from)) return;
                    const to = from === null ? null : from + node.nodeSize;
                    insertFile({
                      editor,
                      file: fileData,
                      rangeToDelete: from !== null && to !== null ? {
                        from,
                        to,
                      } : undefined,
                      dataId,
                      content: transcriptContent,
                    });
                  } else {
                    updateAttributes({
                      file: fileData,
                      dataId,
                    });
                    if (transcriptContent) insertTranscript(transcriptContent);
                  }
                }}
              />
            )}
        </TranscriptTooltip>
      </FileContainer>
      {file?.src && isTranscriptLoading && <EditorTranscriptStatusState />}
    </Container>
  );
};
