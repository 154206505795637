import { useEffect, RefObject } from 'react';

import { getLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

export const useClickOutside = <T extends HTMLElement = HTMLDivElement>(
  ref: RefObject<T>,
  callback: (e: MouseEvent) => void,
  options: {
    disableOnLayers?: Layer[];
  } = {},
) => {
  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (!(e.target instanceof HTMLElement)) return;
      if (ref.current?.contains(e.target)) return;
      if (options.disableOnLayers?.some(layer => getLayer()[layer])) return;
      e.stopPropagation();
      callback(e);
    };
    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, [ref, callback, options.disableOnLayers]);
};
