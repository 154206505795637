import { DocBaseFragment, ViewType } from '@cycle-app/graphql-codegen';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

export const useGetDocItemProps = (viewType: ViewType) => {
  const isMobile = useIsMobile();
  const builtInDisplay = useBoardConfig(ctx => ctx.builtInDisplay);
  const isReleasesEnabled = useIsReleasesEnabled();
  return (doc?: DocBaseFragment | null) => ({
    viewType,
    showAssignee: builtInDisplay?.assignee,
    showComments: builtInDisplay?.comments && !(viewType === ViewType.List && isMobile),
    showCover: builtInDisplay?.cover,
    showDocParent: builtInDisplay?.parent && !(viewType === ViewType.List && isMobile),
    showChildren: builtInDisplay?.children,
    showInsights: builtInDisplay?.insights,
    showCustomer: builtInDisplay?.customer && !!getDocType(doc?.doctype.id)?.customer,
    showDocId: builtInDisplay?.docId && !doc?.isDraft,
    showDocType: builtInDisplay?.docType,
    showSource: builtInDisplay?.source && !doc?.isDraft && !!doc?.source,
    showStatus: builtInDisplay?.status,
    showRelease: isReleasesEnabled && builtInDisplay?.release,
    showLinear: builtInDisplay?.linear,
    showAiState: builtInDisplay?.aiState,
  });
};
