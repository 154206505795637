import { QueryFunctionOptions, useQuery } from '@apollo/client';
import { ProductCustomersCountDocument, ProductCustomersCountQuery, ProductCustomersCountQueryVariables } from '@cycle-app/graphql-codegen';

import { useProductBase } from 'src/hooks/api/useProduct';
import { defaultCustomersPagination } from 'src/utils/pagination.util';

type Options = QueryFunctionOptions<ProductCustomersCountQuery, ProductCustomersCountQueryVariables>;

export const useCustomersCount = (options?: Options) => {
  const product = useProductBase();
  const productId = product?.id;
  const {
    data, loading, refetch,
  } = useQuery(ProductCustomersCountDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: options?.skip || !productId,
    variables: {
      productId: productId as string,
      ...defaultCustomersPagination,
    },
  });
  return {
    count: data?.node?.__typename === 'Product' ? data?.node.customers.count : null,
    isLoading: loading,
    refetch,
  };
};
