import { ring2 } from '@cycle-app/ui';
import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { DragHandle } from 'src/components/EditorDragMenu';

type ContainerProps = {
  $isSelected: boolean;
};

export const Container = styled(NodeViewWrapper) <ContainerProps>`
  ${p => p.$isSelected && css`
    ${ring2}
  `}

  border-radius: 8px;
  position: relative;

  li & {
    padding: 0;
  }

  &:hover ${DragHandle} {
    display: flex;
  }
`;

export const ErrorContainer = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  background-color: ${p => p.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
`;
