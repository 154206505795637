import { Input } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { Range } from '@tiptap/react';
import { FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';

import MiniForm from 'src/components/Editor/MiniForm/MiniForm';
import { Events } from 'src/constants/analytics.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { addEmbed, isEmbedSupported } from 'src/utils/editor/iframely.util';

import { Caption } from './EmbedForm.styles';

interface Props {
  range?: Range;
  onCancel?: VoidFunction;
}

const EmbedForm: FC<React.PropsWithChildren<Props>> = ({
  range,
  onCancel,
}) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const { execute: addEmbedASync } = useAsyncCallback(addEmbed);

  const {
    handleSubmit, register, setError, formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      url: '',
    },
  });

  return (
    <MiniForm
      title="Embed"
      onCancel={onCancel}
      onSubmit={handleSubmit(async (values) => {
        await addEmbedASync({
          url: values.url,
          editor,
          onError: (message) => setError('url', { message }),
          range,
        });
        trackAnalytics(Events.LinkEmbeded);
      })}
      submitLabel="Embed"
      isSubmitDisabled={formState.isSubmitting || !!formState.errors.url || !formState.isDirty}
    >
      <Caption>You can embed any public link you want</Caption>
      <Input
        {...register('url', {
          setValueAs: (value) => value.trim(),
          validate: {
            valid: (url) => !url || isUrl(url, { strict: false }) || 'Invalid URL',
            supported: (url) => !url || isEmbedSupported(url) || 'Hmm, it doesn’t look a link we can process',
          },
        })}
        placeholder="URL to embed"
        autoFocus
        error={formState.errors.url?.message}
      />
    </MiniForm>
  );
};

export default EmbedForm;
