import mapValues from 'lodash/mapValues';

import { Layer } from 'src/types/layers.types';
import { make } from 'src/utils/reactives.util';

const variables = {
  [Layer.Root]: make({ defaultState: { visible: false } }),
  [Layer.Commandbar]: make({ defaultState: { visible: false } }),
  [Layer.DialogModal]: make({ defaultState: { visible: false } }),
  [Layer.DocItemDropdown]: make({ defaultState: { visible: false } }),
  [Layer.DocPanel]: make({ defaultState: { visible: false } }),
  [Layer.Dropdown]: make({ defaultState: { visible: false } }),
  [Layer.DropdownBoardConfig]: make({ defaultState: { visible: false } }),
  [Layer.DropdownCustomProperty]: make({ defaultState: { visible: false } }),
  [Layer.DropdownModal]: make({ defaultState: { visible: false } }),
  [Layer.DropdownModalZ1]: make({ defaultState: { visible: false } }),
  [Layer.DropdownModalZ2]: make({ defaultState: { visible: false } }),
  [Layer.DropdownModalZ3]: make({ defaultState: { visible: false } }),
  [Layer.DropdownModalZ4]: make({ defaultState: { visible: false } }),
  [Layer.DropdownZ1]: make({ defaultState: { visible: false } }),
  [Layer.DropdownZ2]: make({ defaultState: { visible: false } }),
  [Layer.Loader]: make({ defaultState: { visible: false } }),
  [Layer.LoaderFullpage]: make({ defaultState: { visible: false } }),
  [Layer.MaskCommandbar]: make({ defaultState: { visible: false } }),
  [Layer.MaskDocPanel]: make({ defaultState: { visible: false } }),
  [Layer.MaskModal]: make({ defaultState: { visible: false } }),
  [Layer.MaskModalOverDropdown]: make({ defaultState: { visible: false } }),
  [Layer.Modal]: make({ defaultState: { visible: false } }),
  [Layer.ModalZ2]: make({ defaultState: { visible: false } }),
  [Layer.ModalZ3]: make({ defaultState: { visible: false } }),
  [Layer.OfflineWarning]: make({ defaultState: { visible: false } }),
  [Layer.PreviewModal]: make({ defaultState: { visible: false } }),
  [Layer.ProductTour]: make({ defaultState: { visible: false } }),
  [Layer.Selection]: make({ defaultState: { visible: false } }),
  [Layer.Sidebar]: make({ defaultState: { visible: true } }),
  [Layer.Toaster]: make({ defaultState: { visible: false } }),
  [Layer.ToasterModal]: make({ defaultState: { visible: false } }),
  [Layer.Zoom]: make({ defaultState: { visible: false } }),
};

export const setLayer = (layer: Layer, value: boolean) => {
  variables[layer].setValue({ visible: value });
};

export const getLayer = () => mapValues(variables, layer => layer.getValue().visible);

export const useGetLayerCommandbar = variables[Layer.Commandbar].hookValue;
export const useGetLayerDialogModal = variables[Layer.DialogModal].hookValue;
export const useGetLayerDocPanel = variables[Layer.DocPanel].hookValue;
export const useGetLayerDropdown = variables[Layer.Dropdown].hookValue;
export const useGetLayerDropdownBoardConfig = variables[Layer.DropdownBoardConfig].hookValue;
export const useGetLayerDropdownCustomProperty = variables[Layer.DropdownCustomProperty].hookValue;
export const useGetLayerDropdownModal = variables[Layer.DropdownModal].hookValue;
export const useGetLayerDropdownModalZ1 = variables[Layer.DropdownModalZ1].hookValue;
export const useGetLayerDropdownModalZ2 = variables[Layer.DropdownModalZ2].hookValue;
export const useGetLayerDropdownModalZ3 = variables[Layer.DropdownModalZ3].hookValue;
export const useGetLayerDropdownModalZ4 = variables[Layer.DropdownModalZ4].hookValue;
export const useGetLayerDropdownZ1 = variables[Layer.DropdownZ1].hookValue;
export const useGetLayerDropdownZ2 = variables[Layer.DropdownZ2].hookValue;
export const useGetLayerLoader = variables[Layer.Loader].hookValue;
export const useGetLayerLoaderFullpage = variables[Layer.LoaderFullpage].hookValue;
export const useGetLayerMaskCommandbar = variables[Layer.MaskCommandbar].hookValue;
export const useGetLayerMaskDocPanel = variables[Layer.MaskDocPanel].hookValue;
export const useGetLayerMaskModal = variables[Layer.MaskModal].hookValue;
export const useGetLayerMaskModalOverDropdown = variables[Layer.MaskModalOverDropdown].hookValue;
export const useGetLayerModal = variables[Layer.Modal].hookValue;
export const useGetLayerModalZ2 = variables[Layer.ModalZ2].hookValue;
export const useGetLayerModalZ3 = variables[Layer.ModalZ3].hookValue;
export const useGetLayerSelection = variables[Layer.Selection].hookValue;
export const useGetLayerSidebar = variables[Layer.Sidebar].hookValue;
export const useGetLayerToaster = variables[Layer.Toaster].hookValue;
