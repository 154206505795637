import { body400, caption400 } from '@cycle-app/ui/theme/typo';
import styled from 'styled-components';

export const Container = styled.div<{ canClick: boolean }>`
  text-align: center;
  cursor: ${(p) => (p.canClick ? 'pointer' : 'default')};
`;

export const IconContainer = styled.div`
  svg {
    width: 33px;
    height: 33px;
    fill: ${(p) => p.theme.colors.text.disabled};

    path {
      fill: ${(p) => p.theme.colors.text.disabled};

      &.white {
        fill: white;
      }

      .stroke {
        stroke: ${(p) => p.theme.colors.text.disabled};
      }
    }
  }
`;

export const Heading = styled.h3`
  ${body400}
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const Text = styled.p`
  ${caption400}
  color: ${(p) => p.theme.colors.text.disabled};
`;
