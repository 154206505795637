import { LightOnboardingScreen } from 'src/types/onboarding.types';

export const LightOnboardingSteps = [
  LightOnboardingScreen.Welcome,
  LightOnboardingScreen.AccountVerify,
  LightOnboardingScreen.AccountInfos,
  LightOnboardingScreen.AccountPreferences,
  LightOnboardingScreen.Workspace,
  LightOnboardingScreen.Sources,
  LightOnboardingScreen.Linear,
];
