import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal';
import { CompanyProfile } from 'src/components/CustomerCompanyProfile';
import { CompanyPageTitle } from 'src/components/PageTitle';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import { useCompanyId } from 'src/hooks/usePathParams';

import { Container } from './CompanyPage.styles';

export const CompanyPage = () => {
  const companyId = useCompanyId();
  const { company } = useCompany(companyId);
  if (!companyId) return null;
  return (
    <Container>
      <CompanyPageTitle companyName={company?.name} />
      <CompanyProfile companyId={companyId} />
      <CreateDocModal />
    </Container>
  );
};
