import { truncate, typo } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ProgressBar = styled.div`
  flex: 1;
  height: 4px;
  border-radius: 20px;
  background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
  position: relative;
`;

export const Progress = styled.div<{ $hasError: boolean }>`
  height: 100%;
  border-radius: 20px;
  background: ${p => (p.$hasError ? ColorTheme.Red500 : 'hsl(var(--cycle))')};
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;
  color: ${p => p.theme.colors.text.primary};
  ${typo.body500}
  > span {
    ${truncate}
  }
  > svg {
    flex: none;
  }
`;

export const Infos = styled.span`
  flex: none;
  color: ${p => p.theme.colors.text.secondary};
  font-variant-numeric: tabular-nums;
`;

export const Actions = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;
