import { Reference } from '@apollo/client';
import {
  MoveBoardInSectionListDocument,
  MoveSectionInProductListDocument,
  RemoveProductSectionDocument,
  ChangeProductSectionNameDocument,
  ListPositionInput,
  BoardSectionFragment,
  SectionType,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { produce } from 'immer';
import { useCallback } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useProduct } from 'src/hooks/api/useProduct';
import { useLoader } from 'src/hooks/useLoader';
import useSafeMutation from 'src/hooks/useSafeMutation';

type MoveBoardInSectionsParams = {
  boardId: string;
  fromSectionId: string;
  toSectionId: string;
  position: ListPositionInput;
};

type MoveSectionParams = {
  sectionId: string;
  updatedGroupIds: string[];
  position: ListPositionInput;
};

export const useMoveBoardInSectionsList = () => {
  const [mutate] = useSafeMutation(MoveBoardInSectionListDocument);

  const moveBoardInSectionList = useCallback((
    variables: MoveBoardInSectionsParams,
  ) => {
    return mutate({
      variables: {
        boardId: variables.boardId,
        sectionId: variables.toSectionId,
        position: variables.position,
      },
    });
  },
  [mutate]);

  return { moveBoardInSectionList };
};

export const useMoveSection = () => {
  const { product } = useProduct();

  const [mutate] = useSafeMutation(MoveSectionInProductListDocument);

  const moveSection = useCallback((variables: MoveSectionParams) => mutate({
    variables: {
      productId: product?.id ?? '',
      sectionId: variables.sectionId,
      position: variables.position,
    },
  }), [mutate, product?.id]);

  return { moveSection };
};

// Add sections in arguments instead of using useBoardSections().
// Example in the side nav they are already in the <SidebarBoardSection /> props.
export const useRemoveSection = (sections: BoardSectionFragment[]) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [mutate, { loading: loadingRemoveSection }] = useSafeMutation(RemoveProductSectionDocument);
  const removeSection = useCallback((sectionId: string) => mutate({
    variables: { productSectionId: sectionId },
    optimisticResponse: {
      removeProductSection: {
        id: productId,
        boardSections: {
          __typename: 'BoardSectionsConnection',
          edges: sections.filter(section => section.id !== sectionId).map(section => ({
            __typename: 'BoardSectionEdge',
            node: section,
          })),
        },
      },
    },
    update: (cache, { data }) => {
      const defaultSection = sections.find(section => section.type === SectionType.Default);
      if (defaultSection && data?.removeProductSection?.id) {
        const boardLinks = nodeToArray(sections.find(section => section.id === sectionId)?.boardLinks);
        // Append deleted section boardlinks in default section.
        if (boardLinks.length) {
          cache.modify({
            id: cache.identify(defaultSection),
            fields: {
              boardLinks: (currentBoardLinks: { edges: { node?: Reference }[] }, { toReference }) => {
                return produce(currentBoardLinks, draft => {
                  draft.edges.push(...boardLinks.map(link => ({
                    __typename: 'BoardLinkEdge',
                    node: toReference(link),
                  })));
                });
              },
            },
          });
        }
      }
    },
  }), [mutate, productId, sections]);

  useLoader({ loading: loadingRemoveSection });

  return {
    removeSection,
    loadingRemoveSection,
  };
};

export const useRenameSection = () => {
  const [mutate] = useSafeMutation(ChangeProductSectionNameDocument);

  const renameSection = useCallback((sectionId: string, name: string) => mutate({
    variables: {
      sectionId,
      name,
    },
    optimisticResponse: {
      changeProductSectionName: {
        __typename: 'BoardSection',
        id: sectionId,
        name,
      },
    },
  }), [mutate]);

  return { renameSection };
};
