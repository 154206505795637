import { ActionButton, typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';

export const Container = styled.div<{
  asPlaceholder?: boolean;
  $collapsed?: boolean;
  $isOpen: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  opacity: ${p => (p.asPlaceholder ? 0 : 1)};
  row-gap: ${p => (p.$collapsed ? 4 : 2)}px;
  margin-bottom: 4px;
`;

export const DroppableArea = styled.div<{ isOver?: boolean }>`
  border-radius: 4px;
  background-color: ${p => (p.isOver ? p.theme.colors.sidebar.droppableArea.bg : 'transparent')};
`;

export const BoardSectionNavigationItem = styled(CollapsableNavigationItem)<{ $isOpen: boolean }>`
  ${typo.caption500};
  padding-left: 0 6px 0 14px;
  margin-bottom: ${p => (p.$isOpen ? '2px' : 0)};
  flex-grow: 0;

  &:active, &.force-focus, &:hover, &:hover:active {
    background: none;

    > span {
      color: ${p => p.theme.colors.sidebar.color};
    }

    button {
      color: ${p => p.theme.colors.sidebar.boardsSectionItem.color};

      &:hover, &.force-focus {
        background-color: ${p => p.theme.colors.sidebar.boardsSectionItem.bgHover};
        color: ${p => p.theme.colors.sidebar.boardsSectionItem.colorHover};
      }
    }
  }
`;

export const BoardNavigationItem = styled(CollapsableNavigationItem)`
  --color: ${p => p.theme.colors.sidebar.boardItem.color};

  ${mobile} {
    padding: 9px 6px 9px 14px;
  }
`;

export const FirstSection = styled.div`
  ${typo.caption500}
  color: ${p => p.theme.colors.text.secondary};
  padding-left: 8px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  margin-top: 4px;

  button {
    color: ${p => p.theme.colors.sidebar.boardsSectionItem.color};

    &:hover, &.force-focus {
      background-color: ${p => p.theme.colors.sidebar.boardsSectionItem.bgHover};
      color: ${p => p.theme.colors.sidebar.boardsSectionItem.colorHover};
    }
  }
`;

export const CollapseButton = styled(ActionButton)`
  flex: none;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: none;

  ${BoardSectionNavigationItem}:hover &,
  ${FirstSection}:hover & {
    display: flex;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
