import { VideoViewAttributes } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { useCallback } from 'react';

import { EditorNodeEmptyState } from 'src/components/EditorNodeEmptyState';
import { EditorTranscriptStatusState } from 'src/components/EditorTranscriptStatusState';
import { VideoPlayer } from 'src/components/VideoPlayer';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useTranscript } from 'src/hooks/editor/useTranscript';
import { setTranscriptTooltip, useTranscriptTooltip } from 'src/reactives/transcript.reactive';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import { TranscriptTooltip } from '../Editor/TranscriptTooltip';
import { EditorMediaDragMenu } from '../EditorMediaDragMenu';
import { Container, ErrorContainer } from './EditorVideoView.styles';

interface EditorVideoViewProps extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: VideoViewAttributes) => void;
}
export const EditorVideoView = ({
  node, getPos, selected, updateAttributes,
}: EditorVideoViewProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const isInert = useEditorContext(ctx => ctx.isInert);
  const {
    src, dataId,
  } = node.attrs as VideoViewAttributes;

  const {
    getTranscript, isLoading: isTranscriptLoading, insertTranscript,
  } = useTranscript({
    node,
    getPos,
    dataId,
  });

  const onTranscript = useCallback(() => getTranscript({
    src,
    type: 'video',
    insertContent: true,
  }), [getTranscript, src]);

  const onDelete = () => deleteNodeRange({
    editor,
    node,
    getPos,
  });

  const dataToTranscript = useTranscriptTooltip(dataId);

  return (
    <TranscriptTooltip
      dataId={dataId}
      transcript={() => {
        if (!dataToTranscript) return;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getTranscript(dataToTranscript).then(insertTranscript);
      }}
    >
      <Container $isSelected={!isInert && selected && !isTranscriptLoading}>
        {editor.isEditable && (
          <EditorMediaDragMenu
            onDelete={onDelete}
            onTranscript={onTranscript}
            isTranscriptLoading={isTranscriptLoading}
            src={src}
            title={undefined}
            type="video"
          />
        )}
        {src
          ? (
            <VideoPlayer
              src={src}
              dataId={dataId}
              errorFallback={(
                <ErrorContainer>
                  {ErrorMessage.EDITOR_VIDEO_ERROR}
                </ErrorContainer>
            )}
            />
          )
          : (
            <EditorNodeEmptyState
              type="video"
              dataId={dataId}
              onCancel={onDelete}
              onUploaded={(fileData) => {
                updateAttributes({
                  src: fileData.src,
                  title: fileData.title,
                  dataId,
                });
                setTranscriptTooltip(dataId, fileData);
              }}
            />
          )}
        {src && isTranscriptLoading && <EditorTranscriptStatusState />}
      </Container>
    </TranscriptTooltip>
  );
};
