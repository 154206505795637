import { make } from 'src/utils/reactives.util';

import { LocalKey } from '../types/localStorage.types';

export type ChangelogBuilderResult = {
  isAdvancedOpen: boolean;
};

export const {
  hookState: useChangelogBuilder,
  hookValue: useGetChangelogBuilder,
  getValue: getChangelogBuilder,
  setValue: setChangelogBuilder,
  resetValue: resetChangelogBuilder,
} = make<ChangelogBuilderResult>({
  defaultState: {
    isAdvancedOpen: false,
  },
  localKey: LocalKey.ChangelogBuilder,
});
