import { Button } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import ImageInput from 'src/components/ImageInput/ImageInput';

export const UploadButton = styled(Button)`
  --border: ${p => p.theme.colors.border.primary};
`;

export const AvatarContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const AvatarInput = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 40px;
  > button {
    ${mobile} {
      display: none;
    }
  }
`;

export const StyledImageInput = styled(ImageInput)`
  margin-right: auto;
`;

export const ThemePickerContainer = styled.div`
  max-width: 370px;
`;
