import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CollapseSidebarIcon: FC<SvgProps> = ({
  direction = 'bottom', ...props
}) => (
  <Svg
    {...props}
    direction={direction}
    width="16"
    height="16"
    viewBox="2 2 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7083 8.125L10.8333 10L12.7083 11.875"
      stroke="currentcolor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M6.875 3.125H5.625C4.24429 3.125 3.125 4.24429 3.125 5.625V14.375C3.125 15.7557 4.24429 16.875 5.625 16.875H6.875M6.875 3.125H14.375C15.7557 3.125 16.875 4.24429 16.875 5.625V14.375C16.875 15.7557 15.7557 16.875 14.375 16.875H6.875M6.875 3.125V16.875"
      stroke="currentcolor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
