import { MoreHorizIcon } from '@cycle-app/ui/icons';

import { StyledButton } from 'src/components/ViewHeader/ViewHeader.styles';
import { navigate } from 'src/hooks/useNavigate';
import { getSectionPageId, SectionParent } from 'src/utils/section.utils';

import { Caret } from './ViewBreadcrumb.styles';
import { BreadcrumbSize } from './ViewBreadcrumb.utils';

type Props = {
  size: BreadcrumbSize;
  sectionParent: SectionParent;
};

export const ViewsButton = ({
  size, sectionParent,
}: Props) => (
  <>
    <StyledButton
      $isSmall={size < 3}
      onClick={e => navigate(getSectionPageId(sectionParent), undefined, undefined, e.metaKey)}
    >
      {size < 3 ? <MoreHorizIcon /> : 'Views'}
    </StyledButton>
    <Caret />
  </>
);
