import { HIGHLIGHT_DATA_ID } from '@cycle-app/editor-extensions';
import { ThemeType } from '@cycle-app/graphql-codegen/generated';
import { ShyScrollbar, Modal } from '@cycle-app/ui';
import { EditorContent as TipTapEditorContent } from '@tiptap/react';
import styled, { css } from 'styled-components';

import ContainerDropFile from 'src/components/Editor/ContainerDropFile/ContainerDropFile';
import { EDITOR_WIDTH_DESKTOP } from 'src/constants/editor.constants';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { EditorContentCss, EditorPreview } from 'src/styles';
import { Layer } from 'src/types/layers.types';

export const BUBBLE_MENU_NOT_INTERACTIVE_CLASS = 'bubble-menu-not-interactive';

export const CommentEditorContainer = styled.div`
  ${EditorContentCss};

  .ProseMirror {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
`;

type ReadOnlyEditorContainerProps = {
  interactive: boolean;
};

export const ReadOnlyEditorContainer = styled(EditorPreview) <ReadOnlyEditorContainerProps>`
  ${(p) => !p.interactive && css`pointer-events: none;`};
`;

/**
 * This is a dirty overwrite
 * It was the easiest solution to avoid complete refactor of the current
 * implementation
 */
export const ReadOnlyEditorContainer2 = styled.div<ReadOnlyEditorContainerProps>`
  ${EditorContentCss}

  .ProseMirror table {
    width: calc(${EDITOR_WIDTH_DESKTOP}px - 1px);
    margin: 0 44px;
  }
  ${(p) => !p.interactive && css`pointer-events: none;`};
`;

export const EditorContainer = styled(ContainerDropFile)`
  ${EditorContentCss};

  &.${BUBBLE_MENU_NOT_INTERACTIVE_CLASS} {
    [data-tippy-root],
    [data-tippy-root] * {
      pointer-events: none;
    }
  }
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MarkColor = css`
  background-color: ${(p) => (p.theme.themeType === ThemeType.Nightfall
    ? 'hsla(var(--cycle300), 0.4)'
    : 'hsla(var(--cycle), 0.15)')};
`;

type ContentProps = {
  fullHeight?: boolean;
  $highlightId?: string;
};
export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;

  /* Dirty selector to set that necessary height in a div without classname rendered by the lib */
  /* > :first-child:last-child {
    height: 100%;
  } */
`;

type EmptyBlockProps = {
  $hasBigHeight: boolean;
};
export const EmptyBlock = styled.div<EmptyBlockProps>`
  width: 100%;
  min-height: ${(p) => (p.$hasBigHeight ? '30vh' : '24px')};
  flex: 1;
  cursor: text;
`;

type CommentEditorContentProps = {
  maxHeight?: number;
};
export const CommentEditorContent = styled(ShyScrollbar) <CommentEditorContentProps>`
  --editor-margin: 0px;
  ${EditorContentCss}
  ${p => p.maxHeight && css`max-height: ${p.maxHeight}px;`}

  .ProseMirror p {
    width: 100%;
    padding: 0;
  }

  /* Adjust the padding of the checkbox, as the line spacing is smaller in the comments editor */
  .ProseMirror ul[data-type="taskList"] li label {
    padding-top: 5px;
  }
`;

export const PreviewTemplateContainer = styled.div`
  position: absolute;
  right: 24px;
  width: 30vw;
  max-width: 586px;
  top: 24px;
  bottom: 24px;
  z-index: ${mappingZindex[Layer.Modal]};
`;

export const PreviewTemplateModal = styled(Modal)`
  max-width: 100%;
  max-height: 100%;
`;

type EditorContentWrapperProps = {
  $highlightId?: string;
  $isReadonly?: boolean;
  $openBlockId?: string | null;
  $hoverBlockId?: string | null;
  $section: 'open' | 'closed' | 'all';
};

export const EditorContentWrapper = styled.div<EditorContentWrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${(p) => p.$highlightId && css`
    .ProseMirror mark[${HIGHLIGHT_DATA_ID}="${p.$highlightId}"] {
      background-color: ${p.theme.themeType === ThemeType.Nightfall ? 'hsla(var(--cycle300), 0.4)' : 'hsla(var(--cycle), 0.15)'} !important;
    }
  `}

  ${(p) => p.$isReadonly && css`
    pointer-events: none;
  `};


  ${(p) => p.$hoverBlockId && p.$section !== 'all' && css`
    .ProseMirror mark[isresolved="${p.$section === 'open' ? 'false' : 'true'}"][${HIGHLIGHT_DATA_ID}="${p.$hoverBlockId}"] {
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 5px;
      text-decoration-color: hsl(var(--cycle400)) !important;
      --bg: hsl(var(--cycle100)) !important;
      ${p.theme.themeType === ThemeType.Nightfall && `
        text-decoration-color: hsl(var(--cycle600)) !important;
        --bg: hsla(var(--cycle300), 0.4) !important;
      `}
    }
  `}

  ${(p) => p.$hoverBlockId && p.$section === 'all' && css`
  .ProseMirror mark[${HIGHLIGHT_DATA_ID}="${p.$hoverBlockId}"] {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
    text-decoration-color: hsl(var(--cycle400)) !important;
    --bg: hsl(var(--cycle100)) !important;
    ${p.theme.themeType === ThemeType.Nightfall && `
      text-decoration-color: hsl(var(--cycle600)) !important;
      --bg: hsla(var(--cycle300), 0.4) !important;
    `}
  }
`}

  ${(p) => p.$openBlockId && css`
    .ProseMirror mark[${HIGHLIGHT_DATA_ID}="${p.$openBlockId}"] {
      text-decoration-color: hsla(var(--cycle500), 0.8) !important;
      --bg: ${p.theme.themeType === ThemeType.Nightfall ? 'hsla(var(--cycle300), 0.6)' : 'hsla(var(--cycle200), 0.8)'} !important;
    }
  `}
`;

export const EditorContent = styled(TipTapEditorContent)`
  height: 100%;
  ${EditorContentCss}
`;
