import { GithubIssueBaseFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { FC, useRef, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { GithubIssuesSearchList } from 'src/components/GithubIssuesSearchList/GithubIssuesSearchList';
import { Events, Methods } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { useLinkGithubIssue } from 'src/hooks/api/mutations/integrations/useLinkGithubIssue';
import { useGithubIssues } from 'src/hooks/api/queries/integrations/useGithubIssues';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { IntegrationMentionRendererProps } from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { parseGithubIssueLink } from 'src/utils/integrations.utils';

import {
  Container,
  LoadingState,
} from './GithubIssuesDropdown.styles';

const MIN_LENGTH_QUERY = 3;

const GithubIssuesDropdown: FC<React.PropsWithChildren<IntegrationMentionRendererProps>> = ({
  query, range, extensionName,
}) => {
  const {
    search,
    isSearchLoading,
    issues,
    isInstalled,
    isIntegrationLoading,
  } = useGithubIssues();
  const { link } = useLinkGithubIssue();
  const { doc } = useDocPanelProps();
  const editor = useEditorContext(ctx => ctx.editor);
  const searchDebounced = useDebouncedCallback(search, INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => {
    if (query.length < MIN_LENGTH_QUERY || !isInstalled) return;
    searchDebounced(query);
  }, [searchDebounced, query, isInstalled]);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => editor.chain().focus().deleteRange(range).run(), {
    disableOnLayers: [Layer.DropdownBoardConfig],
  });

  return (
    <Container ref={ref}>
      {isIntegrationLoading
        ? <LoadingState />
        : (
          <GithubIssuesSearchList
            search={query}
            results={issues}
            isIntegrationActive={isInstalled}
            onLink={onLink}
            onCreated={insertInEditor}
            isLoading={isSearchLoading}
            docId={doc?.id}
          />
        )}
    </Container>
  );

  function onLink(issue: GithubIssueBaseFragment) {
    const repository = (issue.url && parseGithubIssueLink(issue.url)?.repository) || '';

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    link({
      variables: {
        docId: doc?.id || '',
        publicId: issue.publicId,
        repo: repository,
      },
    })?.then(() => {
      trackAnalytics(Events.IntegrationIssueMentioned, {
        type: IntegrationType.Github,
        method: Methods.SlashCommand,
      });
    });
    insertInEditor(issue);
  }

  function insertInEditor(issue: GithubIssueBaseFragment, projectId?: string) {
    editor
      .chain()
      .focus()
      .deleteRange({
        from: range?.from ?? 0,
        to: range?.to ?? 0,
      })
      .insertContent({
        type: extensionName,
        attrs: {
          id: issue.id,
          projectId: projectId || null,
        },
      })
      .createParagraphNear()
      .run();
  }
};

export default GithubIssuesDropdown;
