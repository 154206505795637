import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SlackSimpleIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g clipPath="url(#clip0_394_19207)">
      <path d="M10.667 2.33398C10.667 1.7817 10.2193 1.33398 9.66699 1.33398C9.11471 1.33398 8.66699 1.7817 8.66699 2.33398V5.66732C8.66699 6.2196 9.11471 6.66732 9.66699 6.66732C10.2193 6.66732 10.667 6.2196 10.667 5.66732V2.33398Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.667 5.66602V6.66602H13.667C13.8648 6.66602 14.0581 6.60737 14.2226 6.49749C14.387 6.3876 14.5152 6.23143 14.5909 6.0487C14.6666 5.86597 14.6864 5.66491 14.6478 5.47093C14.6092 5.27694 14.514 5.09876 14.3741 4.95891C14.2342 4.81906 14.0561 4.72382 13.8621 4.68523C13.6681 4.64665 13.467 4.66645 13.2843 4.74214C13.1016 4.81782 12.9454 4.946 12.8355 5.11045C12.7256 5.2749 12.667 5.46823 12.667 5.66602Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.33301 10.334C7.33301 9.7817 6.88529 9.33398 6.33301 9.33398C5.78072 9.33398 5.33301 9.7817 5.33301 10.334V13.6673C5.33301 14.2196 5.78072 14.6673 6.33301 14.6673C6.88529 14.6673 7.33301 14.2196 7.33301 13.6673V10.334Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33301 10.334V9.33398H2.33301C2.13523 9.33398 1.94189 9.39263 1.77744 9.50251C1.61299 9.6124 1.48482 9.76857 1.40913 9.9513C1.33344 10.134 1.31364 10.3351 1.35222 10.5291C1.39081 10.7231 1.48605 10.9012 1.6259 11.0411C1.76575 11.1809 1.94394 11.2762 2.13792 11.3148C2.3319 11.3534 2.53297 11.3336 2.71569 11.2579C2.89842 11.1822 3.0546 11.054 3.16448 10.8896C3.27436 10.7251 3.33301 10.5318 3.33301 10.334Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.6663 8.66602H10.333C9.78072 8.66602 9.33301 9.11373 9.33301 9.66602C9.33301 10.2183 9.78072 10.666 10.333 10.666H13.6663C14.2186 10.666 14.6663 10.2183 14.6663 9.66602C14.6663 9.11373 14.2186 8.66602 13.6663 8.66602Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.333 12.666H9.33301V13.666C9.33301 13.8638 9.39166 14.0571 9.50154 14.2216C9.61142 14.386 9.7676 14.5142 9.95032 14.5899C10.1331 14.6656 10.3341 14.6854 10.5281 14.6468C10.7221 14.6082 10.9003 14.513 11.0401 14.3731C11.18 14.2333 11.2752 14.0551 11.3138 13.8611C11.3524 13.6671 11.3326 13.4661 11.2569 13.2833C11.1812 13.1006 11.053 12.9444 10.8886 12.8345C10.7241 12.7247 10.5308 12.666 10.333 12.666Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.66634 5.33398H2.33301C1.78072 5.33398 1.33301 5.7817 1.33301 6.33398C1.33301 6.88627 1.78072 7.33398 2.33301 7.33398H5.66634C6.21863 7.33398 6.66634 6.88627 6.66634 6.33398C6.66634 5.7817 6.21863 5.33398 5.66634 5.33398Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.66699 3.33398H6.66699V2.33398C6.66699 2.1362 6.60834 1.94286 6.49846 1.77841C6.38858 1.61397 6.2324 1.48579 6.04968 1.41011C5.86695 1.33442 5.66588 1.31461 5.4719 1.3532C5.27792 1.39178 5.09974 1.48703 4.95989 1.62688C4.82003 1.76673 4.72479 1.94491 4.68621 2.13889C4.64762 2.33288 4.66743 2.53394 4.74311 2.71667C4.8188 2.89939 4.94697 3.05557 5.11142 3.16545C5.27587 3.27534 5.46921 3.33398 5.66699 3.33398Z" stroke="currentcolor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_394_19207">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
