import { useSortable } from '@dnd-kit/sortable';
import { FC } from 'react';

import { getSortableStyle } from 'src/utils/dnd.util';

import SidebarItemBoard, { Props } from './SidebarItemBoard';

const SidebarItemBoardSortable: FC<React.PropsWithChildren<Props & { boardLinkId: string }>> = ({
  board,
  boardLinkId,
  ...props
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: boardLinkId,
    data: { type: 'item' },
  });
  const style = getSortableStyle({
    transform,
    transition,
  });

  return (
    <SidebarItemBoard
      setNodeRef={setNodeRef}
      board={board}
      style={style}
      asPlaceholder={isDragging}
      linkState={{ fromStarredBoard: false }}
      {...attributes}
      {...listeners}
      {...props}
    />
  );
};

export default SidebarItemBoardSortable;
