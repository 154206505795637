import { CycleLogo, typo } from '@cycle-app/ui';
import { GoogleMeetIcon } from '@cycle-app/ui/icons';
import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { PortalModalStyled } from 'src/components/DialogModal/DialogModal.styles';

import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: -50px;
`;

const Sentence = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  position: absolute;
  width: 450px;
  left: calc(100%);
  top: -20px;
  transform: rotate(-2deg) scale(0.8);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;

  svg {
    position: absolute;
    top: 0;
    left: -40px;
    transform: rotate(-0deg);
    stroke: currentcolor;
  }
`;

export const StyledButton = styled(NextButton)`
  margin-top: 48px;

  &:hover ${Sentence} {
    left: calc(100%);
    top: -30px;
    transform: rotate(-3deg) scale(0.8);
    opacity: 1;
  }
`;

export const StyledButtonSecondary = styled(NextButton).attrs({ variant: 'secondary' })`
  margin-top: 16px;
  font-weight: 500;
`;

interface StyledLogoLoaderProps {
  $isLoading?: boolean;
}

export const StyledLogoLoader = styled(CycleLogo) <StyledLogoLoaderProps>`
  ${p => p.$isLoading && css`--color1: ${p.theme.colors.lightOnboarding.doneLoader.color};`}
  margin-bottom: 36px;

  path {
    transition: color .6s;
  }
`;

export const Body = styled.div`
  position: relative;

`;

export const BodyDefault = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const Modal = styled(PortalModalStyled)`
  position: relative;
  width: 450px;
  height: 700px;
  box-sizing: border-box;
  overflow: hidden;

  ${mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    max-width: none;
    max-height: none;
  }
`;

type ContainerProps = {
  $isVisible: boolean;
};

export const CalendlyContainer = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${p => (p.$isVisible ? 1 : 0)};
  box-sizing: border-box;

  [data-container="booking-container"]{
    border: 1px solid red;
  }
`;

export const LoaderContainer = styled.div<ContainerProps>`
  display: ${p => (p.$isVisible ? 'flex' : 'none')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

export const SubTitle = styled.h2`
  ${typo.headerSmall}
  font-weight: normal;
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 8px;
  max-width: 394px;
`;

export const Thib = styled.img`
  object-fit: cover;
  border-radius: 48px;
`;

export const TooltipJoin = styled.div`
  ${typo.body500}
  min-width: 255px;
`;

export const StyledGoogleMeetIcon = styled(GoogleMeetIcon)`
  vertical-align: middle;
  margin-right: 4px;
`;
