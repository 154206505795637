import { Node, mergeAttributes } from '@tiptap/core';

import { MENTION_DOC_EXTENSION_NAME, MENTION_DOC_EXTENSION_TAGNAME } from '../constants';

export const getMentionDocExtension = () => Node.create({
  key: MENTION_DOC_EXTENSION_NAME,
  name: MENTION_DOC_EXTENSION_NAME,

  group: 'inline',

  inline: true,

  selectable: false,

  draggable: true,

  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: this.name,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: MENTION_DOC_EXTENSION_TAGNAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      MENTION_DOC_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },
});
