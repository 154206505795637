import { GithubAssignee, GithubOption } from '@cycle-app/graphql-codegen';
import { IntegrationCardSkeleton, EditorIntegrationCard, GithubStatus, Tooltip } from '@cycle-app/ui';
import { getComaSeparatedValue } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { useEffect, FC } from 'react';
import { isPresent } from 'ts-is-present';

import { GithubDescription } from 'src/components/GithubDescription/GithubDescription';
import { IntegrationEditorMenu } from 'src/components/Integrations/IntegrationEditorMenu/IntegrationEditorMenu';
import {
  ContainerNodeView, SlotText, FooterValue, Col, FooterLabel, FooterContentWrapper,
} from 'src/components/Integrations/IntegrationsCommon.styles';
import { useEditorContext } from 'src/contexts/editorContext';
import { useGithubIssue } from 'src/hooks/api/queries/integrations/useGithubIssue';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import { GithubIssuesCommandAttributes } from 'src/types/editor.types';
import { parseGithubIssueLink, isGithubAssignee, isGithubLabel } from 'src/utils/integrations.utils';

import { GithubAssignees } from './Assignees/GithubAssignees';
import { StyledIcon } from './GithubIssueView.styles';
import { GithubStatuses } from './Statuses/GithubStatuses';

interface GithubIssueViewProps extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: GithubIssuesCommandAttributes) => void;
}
export const GithubIssueView: FC<React.PropsWithChildren<GithubIssueViewProps>> = ({
  node, getPos, selected, updateAttributes,
}) => {
  const {
    issue, refetch,
  } = useGithubIssue(node.attrs.id, node.attrs.projectId);

  const editor = useEditorContext(ctx => ctx.editor);

  useEffect(() => {
    if (issue) {
      queueMicrotask(() => {
        updateAttributes({
          id: issue.id,
          url: issue.url,
          title: issue.title ?? node.attrs.title ?? '',
          state: issue.state ?? node.attrs.state ?? '',
          description: issue.description ?? node.attrs.description ?? '',
          creator: issue.creator ?? node.attrs.creator ?? '',
          cardStatus: issue.cardStatus ?? node.attrs.cardStatus ?? '',
          projectId: node.attrs.projectId ?? '',
          milestone: issue.milestone ?? node.attrs.milestone ?? '',
          labels: issue.labels?.filter(isGithubLabel) ?? node.attrs.labels ?? [],
          assignees: issue.assignees.filter(isGithubAssignee) ?? node.attrs.assignees ?? [],
        });
      });
    }
  }, [issue, updateAttributes]);

  return (
    <ContainerNodeView data-drag-handle>
      {renderContent()}
    </ContainerNodeView>
  );

  function renderContent() {
    const {
      url, state, title, description, assignees, projectId,
    } = node.attrs;
    const showSkeleton = !url && !state && !title;
    if (showSkeleton) return <IntegrationCardSkeleton />;

    const issueAssignees = (assignees ?? issue?.assignees ?? []) as GithubAssignee[];
    const issueDescription = description ?? issue?.description ?? 'No description';
    const urlToParse = url || issue?.url;
    const parsedUrl = urlToParse
      ? parseGithubIssueLink(urlToParse)
      : null;

    return (
      <EditorIntegrationCard
        isSelected={selected}
        title={title ?? issue?.title ?? ''}
        preTitleSlot={renderPreTitleSlot(parsedUrl?.repository, parsedUrl?.publicId, state)}
        actions={(
          <IntegrationEditorMenu
            editor={editor}
            getPos={getPos}
            node={node}
            url={url}
            onRefetch={refetch}
          />
        )}
        assignees={(
          <GithubAssignees
            issueAssignees={issueAssignees}
            issuePublicId={parsedUrl?.publicId}
            repository={parsedUrl?.repository}
          />
        )}
        description={<GithubDescription description={issueDescription} />}
        footerSlot={renderFooterSlot(projectId)}
        onClick={() => {
          if (urlToParse) {
            window.open(urlToParse, '_blank');
          }
        }}
      />
    );
  }

  function renderPreTitleSlot(repository?: string, publicId?: string, state?: string) {
    const status = (state || issue?.state) === 'OPEN' ? 'open' : 'closed';

    return (
      <>
        <StyledIcon />
        <GithubStatus status={status} />
        <SlotText>{repository}</SlotText>
        <SlotText>{`#${publicId}`}</SlotText>
      </>
    );
  }

  function renderFooterSlot(projectId: string) {
    const {
      creator, milestone, labels, cardStatus, id,
    } = node.attrs;

    const [firstLabel, ...otherLabels] = labels || [];
    const hasFooter = !!creator || !!firstLabel || !!milestone;

    if (!hasFooter) return null;

    return (
      <FooterContentWrapper>
        <FooterStatus id={id} cardStatus={cardStatus} projectId={projectId} />
        {creator && (
          <Col>
            <FooterLabel>Creator</FooterLabel>
            <FooterValue isReadOnly>{creator}</FooterValue>
          </Col>
        )}
        {!!firstLabel && (
          <Col>
            <FooterLabel>Labels</FooterLabel>
            <FooterValue isReadOnly>
              {firstLabel}
              {otherLabels?.length ? (
                <Tooltip content={getComaSeparatedValue({ array: otherLabels })}>
                  +
                  {otherLabels.length}
                </Tooltip>
              ) : null}
            </FooterValue>
          </Col>
        )}
        {milestone && (
          <Col>
            <FooterLabel>Milestones</FooterLabel>
            <FooterValue isReadOnly>{milestone}</FooterValue>
          </Col>
        )}
        <FooterProject projectId={projectId} />
      </FooterContentWrapper>
    );
  }
};

interface FooterStatusProps {
  id: string;
  cardStatus?: string;
  projectId?: string;
}
/**
 * Having statuses means that is there a GitHub project associated and
 * some statuses in there. Those statuses could be use to update the
 * GitHub issue status (From the associated project). An issue could
 * not have Status but if the project is associated we want to
 * display `No value` and nothing is no project at all.
 */
const FooterStatus: FC<React.PropsWithChildren<FooterStatusProps>> = ({
  id, cardStatus, projectId,
}) => {
  const {
    statusOptions, statusesByProjects,
  } = useGithubProject();

  if (!statusOptions?.length && !statusesByProjects) return null;

  const options: GithubOption[] = projectId && statusesByProjects
    ? statusesByProjects[projectId]?.options.filter(isPresent) || []
    : statusOptions || [];

  return (
    <Col>
      <FooterLabel>Status</FooterLabel>
      <GithubStatuses
        availableStatuses={options}
        currentStatus={cardStatus || 'No value'}
        issueId={id}
        projectId={projectId}
      />
    </Col>
  );
};

interface FooterProjectProps {
  projectId?: string;
}

const FooterProject: FC<React.PropsWithChildren<FooterProjectProps>> = ({ projectId }) => {
  const {
    projects, project,
  } = useGithubProject();

  const projectName = project ? project.title : projects?.find(p => p?.id === projectId)?.title;

  if (!projectName) return null;

  return (
    <Col>
      <FooterLabel>Project</FooterLabel>
      <FooterValue isReadOnly>{projectName}</FooterValue>
    </Col>
  );
};
