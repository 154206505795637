import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Emoji, Spinner } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { useState } from 'react';

import { Caret, InputContainer } from 'src/components/CustomPropertyFormField/CustomPropertyFormField.styles';
import { InsightDocSearchDropdown } from 'src/components/DocSearchDropdown/InsightDocSearchDropdown';
import { useDocTypeInsight } from 'src/hooks';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocTypeNames } from 'src/utils/docType.util';

import { ParentTitle } from './FeedbackQuoteCreateForm.styles';

type Props = {
  parent: DocBaseFragment | null | undefined;
  onChange: (parentId: string, isNewParentDoc: boolean) => void;
  isLoading: boolean;
};

export const FeatureField = ({
  parent,
  onChange,
  isLoading,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { insight } = useDocTypeInsight();
  const possibleDocTypes = nodeToArray(insight?.parents);
  return (
    <InsightDocSearchDropdown
      hideSearchDropdown={() => setIsVisible(false)}
      isSearchDropdownVisible={isVisible}
      onAdd={(parentId, options) => {
        onChange(parentId, !!options?.isNewDoc);
        setIsVisible(false);
      }}
      childDoctypeId={insight?.id}
      possibleDoctypes={possibleDocTypes}
      placeholder={getDocTypeNames(possibleDocTypes, {
        prefix: 'Search',
        suffix: '',
      })}
      dropdownProps={{
        placement: 'bottom-start',
        layer: Layer.DropdownModalZ4,
        withWrapper: false,
      }}
    >
      <InputContainer onClick={() => setIsVisible(true)}>
        {isLoading && <Spinner />}
        {!isLoading && !parent && 'None'}
        {!isLoading && parent && (
          <>
            <Emoji emoji={getDocType(parent.doctype.id)?.emoji} size={14} />
            <ParentTitle>{parent.title}</ParentTitle>
          </>
        )}
        <Caret size={11} direction={isVisible ? 'top' : 'bottom'} />
      </InputContainer>
    </InsightDocSearchDropdown>
  );
};