import { ModalTitle, NavigationItem, ShyScrollbar, typo } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

import { LearnMoreButton } from '../LearnMoreButton';

export const StyledLearnMoreButton = styled(LearnMoreButton)`
  margin-top: 16px;
`;

export const Icon = styled.div`
  min-width: 16px;
  display: flex;
  align-items: center;
`;

export const StyledPortalModal = styled(PortalModal)`
  display: flex;
  padding: 0;

  height: 615px;
  max-height: calc(100vh - 40px);
  width: 984px;
  max-width: calc(100vw - 40px);
`;

export const LeftPanel = styled.div`
  background-color: ${p => p.theme.colors.settingsModal.leftPanel.bg};
  padding: 16px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 190px;
`;

export const MenuItem = styled(NavigationItem)`
  font-weight: ${p => (p.isActive ? 500 : 400)};
`;

export const RightPanel = styled.div`
  background-color: ${p => p.theme.colors.settingsModal.rightPanel.bg};
  padding: 24px 32px;

  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(ModalTitle)`
  margin-right: auto;
`;

export const TabContent = styled(ShyScrollbar)`
  padding: 0 48px;
  margin-top: 34px;
  height: 100%;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentHeading = styled.h3`
  ${typo.headerLight}
`;

export const ContentBody = styled.div`
  margin-top: 24px;
`;
