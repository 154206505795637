import { addToaster } from 'src/utils/toasters.utils';

import { logError } from './errors.utils';
import { addErrorToaster } from './errorToasters.utils';

type CopyToClipboard = (params: {
  text: string;
  notification?: string;
  showNotification?: boolean;
  onTextCopied?: (textCopied: string) => void;
  asHtml?: boolean;
}) => void;

export const copyToClipboard: CopyToClipboard = async ({
  text,
  notification,
  showNotification,
  onTextCopied,
  asHtml = false,
}) => {
  try {
    if (asHtml) {
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([text], { type: 'text/plain' }),
        'text/html': new Blob([text], { type: 'text/html' }),
      });
      await navigator.clipboard.write([clipboardItem]);
    } else {
      await navigator.clipboard.writeText(text);
    }
    onTextCopied?.(text);
    if (notification || showNotification) {
      addToaster({
        title: 'Copied to clipboard',
        message: notification,
      });
    }
  } catch (error) {
    logError(error);
    addErrorToaster({ message: "Sorry we didn't manage to copy the content, try again" });
  }
};
