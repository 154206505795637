import { ContextBlock, ShowMore } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';
import styled from 'styled-components';

import { InsightCardReadOnly } from 'src/components/InsightCardReadOnly';

export const StyledInsightCard = styled(InsightCardReadOnly)<{
  $isSelected: boolean;
  $hasHighlight?: boolean;
}>`
  margin-top: 8px;

  :hover {
    background: ${p => p.theme.colors.selectItem.bgHover};
  }


  ${ContextBlock} {
    background: transparent;
    padding: 0 0 0 14px;
    .ProseMirror {
      padding: 0 !important;
    }
    p {
      white-space: wrap;
    }
  }

  ${ShowMore} {
    bottom: -5px;
    background-color: ${p => p.theme.colors.components.InsightCard.quote.bg};
    :before {
      box-shadow: -20px 0px 15px 0px ${p => p.theme.colors.components.InsightCard.quote.bg};
    }
    ${p => p.$hasHighlight && `
      display: none;
    `}
  }

  
  ${p => p.$isSelected && `
    background: ${p.theme.colors.selectItem.bgHover};
  `}

  * { cursor: pointer !important; }
`;

export const Content = styled.div`
  color: ${p => p.theme.colors.text.primary};
`;

export const Suggestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;
