import {
  DocAttributeFragment,
  CustomAttributeDefinition,
  DocBaseFragment,
  DocAttributeMultiSelect,
} from '@cycle-app/graphql-codegen';
import { Tag, TooltipProps } from '@cycle-app/ui';
import { nodeToArray, toTagDate } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import {
  forwardRef, MouseEvent, PropsWithChildren, useCallback, useMemo, useState,
} from 'react';
import { Placement } from 'tippy.js';

import { AiStateTag } from 'src/components/AiStateTag';
import { DocLinear } from 'src/components/DocLinear';
import { DocSource } from 'src/components/DocSource/DocSource';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { PropertyDropdownValue } from 'src/components/PropertyDropdownValue';
import { useAttributes } from 'src/hooks/api/useAttributes';
import { useGetDocType, useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { Layer } from 'src/types/layers.types';
import { ViewType } from 'src/types/viewType.types';
import {
  getCustomAttributeTypeData,
  getDocAttributeValue,
  getAttributeIdsOrder,
  AttributeIdsOrder,
  isAttributeScalar,
} from 'src/utils/attributes.util';
import { shouldShowSource } from 'src/utils/doc.util';
import { isBuiltIn, isFeedback } from 'src/utils/docType.util';

import {
  Container, Info, Url, TooltipSubtitle, StyledDocAssignee, StyledDocCustomer, StyledDocPrimaryAttributes,
} from './DocAttributes.styles';
import { DocAttributesMultiSelect } from './DocAttributesMultiSelect';

interface Props {
  className?: string;
  doc: Pick<DocBaseFragment,
  'assignee' |
  'attributes' |
  'childrenCount' |
  'threads' |
  'threadsCount' |
  'createdAt' |
  'creator' |
  'customer' |
  'docTargetsCount' |
  'docTargetsAiCount' |
  'doctype' |
  'id' |
  'insightsCount' |
  'isDraft' |
  'source' |
  'status' |
  'title' |
  'automationId' |
  'automationUrl' |
  'aiState'>;
  displayedPropertiesIds?: string[];
  isDragging?: boolean;
  viewType?: ViewType;
  displayPrimaryAttributes?: boolean;
  dropdownPlacement?: Placement;
  context?: 'doc-item' | 'doc-panel';
  showDocId?: boolean;
  showDocType?: boolean;
  showSource?: boolean;
  showStatus?: boolean;
  showAssignee?: boolean;
  showCustomer?: boolean;
  showRelease?: boolean;
  showLinear?: boolean;
  showAiState?: boolean;
  layer?: Layer;
  readOnly?: boolean;
  readOnlyStatus?: boolean;
  readOnlyAttributeDefinitionIds?: string[];
  limitSize?: boolean;
  isDocTypeReadOnly?: boolean;
  showCustomAttributes?: boolean;
  enableStatusShortcut?: boolean;
  hideStatusLabel?: boolean;
  hideIncompatibleValues?: boolean;
  disableCustomerCreation?: boolean;
  compatibleStatusIds?: string[];
  showLinearAutoCreate?: boolean;
  onWithLinearChange?: (checked: boolean) => void;
  withLinearChecked?: boolean;
  editor?: Editor;
  showDocIdWithDocType?: boolean;
}

export const DocAttributes = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({
  className,
  viewType,
  displayPrimaryAttributes = true,
  displayedPropertiesIds = [],
  dropdownPlacement = 'bottom',
  doc,
  isDragging,
  children,
  context = 'doc-item',
  showDocId = true,
  showDocType = true,
  showStatus = true,
  showAssignee,
  showCustomer,
  showRelease = true,
  showLinear = true,
  showAiState = true,
  layer = Layer.Dropdown,
  readOnly = false,
  readOnlyAttributeDefinitionIds,
  readOnlyStatus,
  limitSize,
  isDocTypeReadOnly = false,
  enableStatusShortcut,
  hideStatusLabel,
  hideIncompatibleValues,
  disableCustomerCreation,
  compatibleStatusIds,
  showCustomAttributes: showCustomAttributesFromProps,
  showLinearAutoCreate,
  onWithLinearChange,
  withLinearChecked,
  editor,
  showDocIdWithDocType,
  ...props
}, ref) => {
  const { docTypes } = useGetDocTypes();
  const docType = useGetDocType(doc?.doctype?.id);
  const isMobile = useIsMobile();
  const productAttributes = useAttributes();
  const [dropdownAttribute, setDropdownAttribute] = useState<{
    id: string;
    selectValue: string | null;
    isScalar: boolean;
  } | null>(null);

  const onHideDropdown = useCallback(() => setDropdownAttribute(null), []);
  const onAttributeClicked = useCallback((attribute: CustomAttributeDefinition, value: string) => (e: MouseEvent) => {
    e.preventDefault();
    if (attribute && value) {
      const isScalar = isAttributeScalar(attribute);
      setDropdownAttribute(dropdownAttribute ? null : {
        id: attribute.id,
        isScalar,
        selectValue: isScalar ? null : value,
      });
    }
  }, [dropdownAttribute]);

  const onRemoveValue = useCallback((attributeId: string, valueId: string) => {
    if (dropdownAttribute?.id === attributeId && (dropdownAttribute?.selectValue === valueId || dropdownAttribute.isScalar)) {
      setDropdownAttribute(null);
    }
  }, [dropdownAttribute]);

  // TODO: to remove once the api has implemented attributes order
  const doctypeAttributeDefIdsOrder: AttributeIdsOrder = useMemo(
    () => getAttributeIdsOrder(nodeToArray(docType?.attributeDefinitions)),
    [docType?.attributeDefinitions],
  );
  const globalAttributeDefIdsOrder: AttributeIdsOrder = useMemo(
    () => getAttributeIdsOrder(productAttributes),
    [productAttributes],
  );

  const attributesSortedByDoctypeOrder = useMemo(
    () => {
      if (!doc) return [];

      return nodeToArray(doc.attributes)
        .sort((attr1, attr2) => {
          const a = doctypeAttributeDefIdsOrder[attr1.definition.id];
          const b = doctypeAttributeDefIdsOrder[attr2.definition.id];
          if (a == null || b == null) return 0;
          return (a > b ? 1 : -1);
        });
    },
    [doc, doctypeAttributeDefIdsOrder],
  );
  const attributesSortedByGlobalOrder = useMemo(
    () => {
      if (!doc) return [];

      return nodeToArray(doc.attributes)
        .sort((attr1, attr2) => {
          const a = globalAttributeDefIdsOrder[attr1.definition.id];
          const b = globalAttributeDefIdsOrder[attr2.definition.id];
          if (a == null || b == null) return 0;
          return (a > b ? 1 : -1);
        });
    },
    [doc, globalAttributeDefIdsOrder],
  );
  const docAttributes = viewType === ViewType.List
    ? attributesSortedByGlobalOrder
    : attributesSortedByDoctypeOrder;

  const displayAttributesFilter = useCallback(({ definition }: DocAttributeFragment) => (
    context === 'doc-panel' ||
    // display all attributes when creating a doc in a group
    (doc.isDraft && !displayedPropertiesIds.length) ||
    displayedPropertiesIds.includes(definition.id)
  ),
  [context, doc.isDraft, displayedPropertiesIds]);

  const tooltipBaseProps: Partial<TooltipProps> = {
    placement: 'top',
    withPortal: true,
    disabled: isDragging,
  };

  const showSource = (props.showSource ?? true) && doc && shouldShowSource(doc, docType);

  const showPrimaryAttributes = displayPrimaryAttributes && (showDocId || showDocType || showStatus || showRelease || showLinear || showAiState);

  if (!showPrimaryAttributes && !showSource && docAttributes.length === 0 &&
      !(showLinear && context === 'doc-item') &&
      !(showAiState && context === 'doc-item')
  ) {
    return null;
  }

  const showCustomAttributes = viewType === ViewType.Kanban || showCustomAttributesFromProps || !isMobile;
  const docTypeType = docTypes[doc.doctype.id]?.type;

  return (
    <Container
      ref={ref}
      className={className}
      viewType={viewType}
      nbAttributes={docAttributes.length}
      readOnly={readOnly}
      $context={context}
    >
      {/* Empty assignee is only displayed in doc panel */}
      {showAssignee && docType && (context === 'doc-panel' || !!doc.assignee) && docTypeType && (
        <StyledDocAssignee
          assignee={doc.assignee}
          docId={doc.id}
          size="S"
          showLabel={context === 'doc-panel' && !isMobile}
          hideIncompatibleValues={hideIncompatibleValues}
          isRemovable={!isFeedback(docType)}
          docTypeName={docType.name}
          docTypeType={docTypeType}
          context={context}
        />
      )}
      {showCustomer && doc && (
        <StyledDocCustomer
          doc={doc}
          isCompact
          isRemovable={!isBuiltIn(docType)}
          hideIncompatibleValues={hideIncompatibleValues}
          context="doc-item"
          disableCreation={disableCustomerCreation}
        />
      )}
      {showSource && (
        <DocSource
          doctypeId={doc.doctype.id}
          docId={doc.id}
          source={doc.source}
          showName={context !== 'doc-item'}
        />
      )}
      {showPrimaryAttributes && doc && (
        <StyledDocPrimaryAttributes
          doc={doc}
          dropdownPlacement={dropdownPlacement}
          layer={layer}
          readOnly={readOnly}
          readOnlyStatus={readOnlyStatus}
          showDocId={showDocId}
          showDocType={showDocType}
          showStatus={showStatus}
          showRelease={showRelease}
          showLinear={showLinear && context === 'doc-panel'}
          showAiState={showAiState && context === 'doc-panel'}
          hideStatusLabel={hideStatusLabel}
          context={context}
          isDocTypeReadOnly={isDocTypeReadOnly || isBuiltIn(docType)}
          enableStatusShortcut={enableStatusShortcut}
          compatibleStatusIds={compatibleStatusIds}
          showLinearAutoCreate={showLinearAutoCreate}
          onWithLinearChange={onWithLinearChange}
          withLinearChecked={withLinearChecked}
          editor={editor}
          showDocIdWithDocType={showDocIdWithDocType}
        />
      )}

      {showCustomAttributes && docAttributes.filter(displayAttributesFilter).map((attribute) => {
        const { definition } = attribute;
        const isAttributeDisabled = readOnlyAttributeDefinitionIds?.includes(definition.id);

        if (attribute.__typename === 'DocAttributeMultiSelect') {
          return (
            <DocAttributesMultiSelect
              key={attribute.id}
              docId={doc.id}
              attribute={attribute as DocAttributeMultiSelect}
              disabled={isDragging || isAttributeDisabled}
              visible={dropdownAttribute?.id === definition.id}
              openDropdown={() => {
                setDropdownAttribute(dropdownAttribute ? null : {
                  id: definition.id,
                  selectValue: null,
                  isScalar: false,
                });
              }}
              hideDropdown={onHideDropdown}
              hideIncompatibleValues={hideIncompatibleValues}
            />
          );
        }

        const attributeData = getCustomAttributeTypeData(definition.__typename ?? 'AttributeTextDefinition');
        const attributeValueRaw = getDocAttributeValue(attribute as DocAttributeFragment);
        const attributeValues = Array.isArray(attributeValueRaw) ? attributeValueRaw : [attributeValueRaw];

        return attributeValues.map(attributeValue => {
          if (attributeValue == null) return null;

          const key = isAttributeScalar(attribute.definition as CustomAttributeDefinition)
            ? definition.id
            : `${definition.id}-${attributeValue}`;
          const isPropertyDropdownVisible =
              dropdownAttribute?.id === definition.id &&
              (dropdownAttribute.selectValue === null || dropdownAttribute.selectValue === String(attributeValue));

          return (
            <DropdownLayer
              key={key}
              closingArea
              placement={dropdownPlacement}
              content={(
                doc.id && (
                  <PropertyDropdownValue
                    docId={doc.id}
                    attributeDefinitionId={definition.id}
                    hide={onHideDropdown}
                    onRemoveValue={onRemoveValue}
                    hideIncompatibleValues={hideIncompatibleValues}
                  />
                )
              )}
              visible={isPropertyDropdownVisible}
              hide={onHideDropdown}
              disabled={isAttributeDisabled}
            >
              <Tag
                color={definition.color}
                {...!isAttributeDisabled && {
                  onClick: onAttributeClicked(definition as CustomAttributeDefinition, String(attributeValue)),
                }}
                limitSize={limitSize}
                tooltip={{
                  ...tooltipBaseProps,
                  content: (
                    <div>
                      <Info>
                        {attributeData.icon}
                        <span>{definition.name}</span>
                      </Info>
                      {definition.__typename === 'AttributeUrlDefinition' && (
                        <Url>
                          {attributeValue}
                        </Url>
                      )}
                      {!isAttributeDisabled && (
                        <TooltipSubtitle>
                          Click to update
                        </TooltipSubtitle>
                      )}
                    </div>
                  ),
                }}
                {...definition.__typename === 'AttributeUrlDefinition' && {
                  externalLink: String(attributeValue),
                }}
              >
                {(() => {
                  switch (definition.__typename) {
                    case ('AttributeUrlDefinition'): return definition.name;
                    case ('AttributeDateDefinition'): return toTagDate(String(attributeValue));
                    default: return attributeValue;
                  }
                })()}
              </Tag>
            </DropdownLayer>
          );
        });
      })}

      {showLinear && context === 'doc-item' && <DocLinear docId={doc.id} automationId={doc.automationId} automationUrl={doc.automationUrl} />}
      {showAiState && context === 'doc-item' && doc && (
        <AiStateTag
          docId={doc.id}
          docTypeId={doc.doctype.id}
          aiState={doc.aiState}
        />
      )}

      {children}
    </Container>
  );
});
