import { make } from 'src/utils/reactives.util';

type AiStateModalState = {
  docId: string | null;
  docTypeId: string | null;
};

const defaultState: AiStateModalState = {
  docId: null,
  docTypeId: null,
};

export const {
  hookValue: useGetAiStateModal,
  getValue: getAiStateModal,
  setValue: setAiStateModal,
} = make<AiStateModalState>({
  defaultState,
});

export const closeAiStateModal = () => setAiStateModal(defaultState);
