import { BillingFrequency } from '@cycle-app/graphql-codegen';
import { differenceInDays, parseISO } from 'date-fns';

type GetRemainingDaysParams = {
  frequency: BillingFrequency;
  invoicedAt: string;
};

export const getRemainingDays = (
  invoicedAt: GetRemainingDaysParams['invoicedAt'],
  frequency: GetRemainingDaysParams['frequency'],
) => {
  const currentDate = new Date();
  const invoicedDate = parseISO(invoicedAt);
  if (frequency === BillingFrequency.Monthly) {
    const daysInBillingPeriod = 30;
    const remainingDays = daysInBillingPeriod - differenceInDays(currentDate, invoicedDate);
    return remainingDays > 0 ? remainingDays : 0;
  }
  const invoicedDayOfMonth = invoicedDate.getDate();
  const dayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, invoicedDayOfMonth);
  const nextMonthDays = differenceInDays(
    dayOfNextMonth,
    currentDate,
  );

  return nextMonthDays >= 0 ? nextMonthDays : 0;
};

export const formatCustomAttributeDate = (isoString: string) => {
  const date = new Date(isoString);
  if (isNaN(date.getTime())) return null; // Invalid date
  return date.toLocaleDateString('en-us');
};