import { ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import { ellipsis } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export interface ChangelogTagStyleProps {
  $tagStyle: ReleaseTagStyle;
}

export const ChangelogTagStyle = css<ChangelogTagStyleProps>`
  align-items: center;
  border-radius: 4px;
  color: var(--tagColor);
  display: flex;
  gap: 6px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;

  ${p => p.$tagStyle === ReleaseTagStyle.Background && `
    background: var(--bg);
    padding-left: 8px;
    padding-right: 8px;
  `}

  ${p => p.$tagStyle === ReleaseTagStyle.Dot && `
    padding: 0;

    &::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 24px;
      background: var(--bg);
      flex: none;
    }
  `}
`;

export const ChangelogTagLabel = styled.span`
  ${ellipsis}
`;
