const keepUselessEvents = (eventKey: string) => (
  /^on/.test(eventKey) &&
  (
    eventKey.includes('click') ||
    eventKey.includes('key') ||
    eventKey.includes('contextmenu') ||
    eventKey.includes('touch')
  )
);

const eventKeys = Object
  .keys(window)
  .filter(keepUselessEvents)
  .map(k => k.slice(2));

export const addListeners = (callback: (event: Event) => void) => eventKeys.forEach(key => window.addEventListener(key, callback, { capture: true }));

export const removeListeners = (callback: (event: Event) => void) => eventKeys.forEach(key => window.removeEventListener(key, callback, { capture: true }));
