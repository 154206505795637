import { namedOperations } from '@cycle-app/graphql-codegen';

import { getCompanyIdParam } from 'src/hooks/modals/useCompanyModal';
import { getCustomerIdParam } from 'src/hooks/modals/useCustomerModal';
import client from 'src/services/apollo/client';
import { getParams } from 'src/utils/routing.utils';

export const refetchProfileDocsCount = () => {
  const queries = [];

  if (getParams().companyId || !!getCompanyIdParam()) {
    queries.push(namedOperations.Query.companyDocsCount);
  }

  if (getParams().customerId || !!getCustomerIdParam()) {
    queries.push(namedOperations.Query.customerDocsCount);
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  client.refetchQueries({ include: queries });
};

export const refetchProfileDocs = () => {
  const queries = [];

  if (getParams().companyId || !!getCompanyIdParam()) {
    queries.push(namedOperations.Query.companyDocs);
  }

  if (getParams().customerId || !!getCustomerIdParam()) {
    queries.push(namedOperations.Query.DocsByCustomer);
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  client.refetchQueries({ include: queries });
};
