import {
  ChangeDocAttributeValueDocument,
  ChangeDocAttributeValueMutationVariables,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

export const useDocAttributeUpdate = () => {
  const [update, { loading }] = useSafeMutation(ChangeDocAttributeValueDocument);

  const addAttributeToDoc = (variables: ChangeDocAttributeValueMutationVariables) => update({
    variables,
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify({
          id: variables.docId,
          __typename: 'Doc',
        }),
        fields: {
          attributes: attributes => ({
            ...attributes,
            edges: [...attributes.edges, data?.changeDocAttributeValue],
          }),
        },
      });
    },
  });

  return {
    addAttributeToDoc,
    isLoading: loading,
  };
};
