import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { isCustom } from 'src/utils/docType.util';

/**
 * If there is only one custom doc type, either the first or after deleting the others,
 * the user cannot add a single doc type without first adding a template.
 */
export const useMustAddRoadmapsTemplate = () => {
  const { docTypes } = useGetDocTypes();
  const docTypesCount = Object.values(docTypes).filter(isCustom).length ?? 0;
  return docTypesCount < 2;
};
