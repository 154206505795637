import { make } from 'src/utils/reactives.util';

type NextAction = 'transcript';

type State = {
  audioNodeOpen: string[];
  fileNodeOpen: string[];
  imageNodeOpen: string[];
  videoNodeOpen: string[];
  nextActions: {
    nodeId: string;
    action: NextAction;
  }[];
  isDragging: boolean;
  /**
   * This state is use to workaround a Tiptap issue:
   * After dragging a node, the selection is active and non-empty but nothing
   * is selected visually. Then the bubble menu appear
   * In order to prevent showing it, we use a isAfterDragging state to prevent
   * the bubble menu to popup at that specific moment
   */
  isAfterDragging: boolean;
};

export type KeyEditorNodeOpenState = keyof Omit<State, 'nextActions' | 'isDragging' | 'isAfterDragging'>;

const defaultState = {
  /**
   * Node open will store all node ids which should have default
   * open form
   */
  audioNodeOpen: [],
  fileNodeOpen: [],
  imageNodeOpen: [],
  videoNodeOpen: [],
  /**
   * Some comment in editor need to proceed many action in rows
   * Next action will help to trigger an action instantly after another one
   *
   * e.g.
   * /Transcript -> Upload file -> Get transcript
   */
  nextActions: [],
  isDragging: false,
  isAfterDragging: false,
};

const {
  getValue,
  setValue,
  hookValue,
} = make<State>({
  defaultState,
});

export const setEditorNodeOpen = (
  key: KeyEditorNodeOpenState,
  nodeId: string,
  nextAction?: NextAction,
) => setValue({
  [key]: [...getValue()[key], nodeId],
  nextActions: [
    ...getValue().nextActions,
    ...nextAction ? [{
      nodeId,
      action: nextAction,
    }] : [],
  ],
  isDragging: getValue().isDragging,
  isAfterDragging: getValue().isAfterDragging,
});

export const getIsEditorNodeOpen = (key: KeyEditorNodeOpenState, nodeId: string) => getValue()[key].includes(nodeId);

export const removeEditorNodeOpen = (key: KeyEditorNodeOpenState, nodeId: string) => setValue({
  [key]: getValue()[key].filter(id => id !== nodeId),
});

export const hasEditorNodeNextAction = (nodeId: string) => getValue().nextActions.find(n => n.nodeId === nodeId)?.action || null;

export const removeEditorNodeNextAction = (nodeId: string) => setValue({
  nextActions: getValue().nextActions.filter(n => n.nodeId !== nodeId),
});

export const useEditorIsDraggingState = () => {
  const state = hookValue();
  return state.isDragging || state.isAfterDragging;
};
export const setEditorIsDragging = (value: boolean) => setValue({
  isDragging: value,
  isAfterDragging: !value,
});
export const getEditorIsAfterDragging = () => getValue().isAfterDragging;
export const unsetEditorIsAfterDragging = () => setValue({ isAfterDragging: false });
