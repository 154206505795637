import { TagProps } from '@cycle-app/ui';
import { Editor } from '@tiptap/core';
import { memo } from 'react';

import { DocLinearProvider } from 'src/contexts/docLinearContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { MaybeUndefined } from 'src/utils/utils';

import { LinearTag } from './LinearTag';

export type DocLinearProps = TagProps & {
  docId: string;
  automationId: MaybeUndefined<string>;
  automationUrl: MaybeUndefined<string>;
  showSyncAutomationContent?: boolean;
  editor?: Editor;
};

export const DocLinear = memo(({
  docId,
  automationId,
  automationUrl,
  showSyncAutomationContent,
  editor,
  ...tagProps
}: DocLinearProps) => {
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  const linearIntegrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  if (!isLinearInstalled || !linearIntegrationId) return null;
  return (
    <DocLinearProvider
      docId={docId}
      automationId={automationId}
      automationUrl={automationUrl}
      integrationId={linearIntegrationId}
      showSyncAutomationContent={showSyncAutomationContent}
      editor={editor}
    >
      <LinearTag {...tagProps} />
    </DocLinearProvider>
  );
});
