import { UnrevealedProvider, FeatureToggler } from '@unrevealed/react';
import { FC } from 'react';

import { LocalKey } from '../types/localStorage.types';

const Toggler = () => {
  return import.meta.env.DEV && localStorage.getItem(LocalKey.FeatureToggler) === 'true'
    ? <FeatureToggler />
    : null;
};

export const FeatureFlagProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <UnrevealedProvider
      clientKey={import.meta.env.VITE_UNREVEALED_API_KEY}
      defaults={{
        /** This flag make sure the inbox is always visible in case of unrevealed latency */
        inbox: true,
        /** This flag make sure the roadmap views is always visible in case of unrevealed latency */
        'roadmap-view': true,
        /** This flag make sure the insight views is always visible in case of unrevealed latency */
        'insight-view': true,
        /** This flag make sure the maintenance state is not visible in case of unrevealed latency */
        'maintenance-off': true,
      }}
    >
      <Toggler />
      {children}
    </UnrevealedProvider>
  );
};
