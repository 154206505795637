import { UseFormRegister } from 'react-hook-form';

import { SkeletonInput } from 'src/components/Form/Form.styles';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useGetInsightForm } from 'src/reactives/insightForm.reactive';

import { FormTextArea, Label, FormRow } from './InsightCreateForm.styles';
import { InsightCreateFormData } from './InsightCreateForm.types';

type Props = {
  isLoading?: boolean;
  customerId?: string;
  error?: string;
  register: UseFormRegister<InsightCreateFormData>;
  autoFocus: boolean;
  disabled?: boolean;
};

export const QuoteField = ({
  isLoading = false, customerId, register, error, autoFocus, disabled,
}: Props) => {
  const { customer } = useCustomer(customerId);
  const { useFeedbackContentAsQuote } = useGetInsightForm();

  if (isLoading) {
    return (
      <FormRow>
        <Label>Title</Label>
        <SkeletonInput />
      </FormRow>
    );
  }

  return (
    <FormTextArea
      id="content"
      label="Quote"
      placeholder={customer?.name?.trim() || customer?.email?.trim()
        ? `Add a quote from ${customer?.name?.trim() || customer.email}`
        : 'Add a quote'}
      autoFocus={autoFocus}
      error={error}
      disabled={disabled}
      readOnly={useFeedbackContentAsQuote}
      {...register('content', {
        required: 'You must add a quote.',
      })}
    />
  );
};
