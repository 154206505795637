import { useQuery } from '@apollo/client';
import { FetchDocLinkedDocsCountDocument } from '@cycle-app/graphql-codegen/generated';
import { useMemo } from 'react';

type useDocInsightsCountParams = {
  docId?: string;
  skip?: boolean;
};

export const useDocInsightsCount = ({
  docId,
  skip,
}: useDocInsightsCountParams) => {
  const {
    data, loading,
  } = useQuery(FetchDocLinkedDocsCountDocument, {
    skip: !docId || skip,
    variables: {
      id: docId,
    },
  });

  const count = useMemo(() => {
    if (data?.node?.__typename !== 'Doc') return 0;

    return data.node.docTargetsCount || 0;
  }, [data]);

  return {
    count,
    isLoading: loading,
  };
};
