import { NotificationFragment, NotificationType, StatusCategory } from '@cycle-app/graphql-codegen';

export const welcomeNotificationText =
  "Welcome to your notification center. If someone mentions you or assigns you to a doc, you'll be notified here.";
export const COMMENT_SEARCH_PARAM = 'commentId';

export const shouldLinkToAComment = (type: NotificationType) => (
  type === NotificationType.UserMentionedInComment ||
  type === NotificationType.ThreadReplied ||
  type === NotificationType.ThreadUpdated ||
  type === NotificationType.ThreadCreated
);

const getNotificationLabel = (type: Exclude<NotificationType, NotificationType.WelcomeNotification>) => {
  switch (type) {
    case NotificationType.UserMentionedInComment:
      return 'mentioned you in';
    case NotificationType.UserMentionedInDoc:
      return 'mentioned you in';
    case NotificationType.ThreadReplied:
      return 'commented in';
    case NotificationType.ThreadUpdated:
      return 'modified a comment in';
    case NotificationType.ThreadCreated:
      return 'created a thread in';
    case NotificationType.AssignedToDoc:
      return 'assigned you on';
    case NotificationType.DocStatusChanged:
      return 'moved from';
    default:
      return '';
  }
};

const getNotificationDocTitle = (
  doc: NotificationFragment['doc'],
  type: Exclude<NotificationType, NotificationType.WelcomeNotification>,
) => {
  switch (type) {
    case NotificationType.DocStatusChanged:
      return doc?.title || '';
    case NotificationType.UserMentionedInComment:
    case NotificationType.UserMentionedInDoc:
    case NotificationType.ThreadReplied:
    case NotificationType.ThreadUpdated:
    case NotificationType.ThreadCreated:
    case NotificationType.AssignedToDoc:
    default:
      return doc?.title || 'a doc';
  }
};

export interface NotificationTitleProps {
  docTitle?: string;
  emoji?: string;
  label?: string;
  labelSecond?: string;
  name: string;
  next?: {
    statusName?: string;
    category?: StatusCategory;
  };
  prev?: {
    statusName?: string;
    category?: StatusCategory;
  };
}

export const getNotificationTitleProps = ({
  creator, type, doc, status, previousStatus,
}: NotificationFragment): NotificationTitleProps => {
  if (type === NotificationType.WelcomeNotification) {
    return {
      name: 'Cycle',
    };
  }

  const name = creator?.firstName || creator?.email || 'Someone';
  const label = getNotificationLabel(type);
  const labelSecond = type === NotificationType.DocStatusChanged ? ' to ' : '';
  const docTitle = getNotificationDocTitle(doc, type);
  const emoji = doc?.doctype.emoji;
  const statusSpecificData: Pick<NotificationTitleProps, 'emoji' | 'next' | 'prev'> = type === NotificationType.DocStatusChanged
    ? {
      emoji,
      prev: previousStatus ? {
        statusName: previousStatus.value,
        category: previousStatus.category,
      } : undefined,
      next: status ? {
        statusName: status.value,
        category: status.category,
      } : undefined,
    }
    : {};

  return {
    name,
    label,
    labelSecond,
    docTitle,
    ...statusSpecificData,
  };
};
