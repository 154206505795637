import { BoardWithMinimalConfigAndDraftConfigIdFragment, SectionType } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { BoardConfigSkeletons } from 'src/components/BoardConfigForm/BoardConfigSkeletons';
import { BoardEditForm } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { Header, Title, CloseButtonStyled } from 'src/components/DialogModal/DialogModal.styles';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { useNavigate } from 'src/hooks/useNavigate';
import { useGetPermission } from 'src/reactives';
import { useGetBoardConfigModal } from 'src/reactives/boardConfig.reactive';
import { getBoardSlug } from 'src/utils/slug.util';

import { RoadmapViewBoardConfigFields } from '../RoadmapViewBoardConfigFields';
import { RoadmapViewDelete } from './RoadmapViewDelete';
import { Modal, ModalFooter, ModalActions } from './RoadmapViewSettings.styles';

type Props = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
};

export const RoadmapViewSettingsModal = ({
  board, draftBoardConfigId,
}: Props) => {
  const { disabled } = useGetBoardConfigModal();
  const { canDeleteView } = useGetPermission();
  const { navigateToRoadmapView } = useNavigate();
  const boardMutations = useBoardMutations();
  const {
    revertUnsavedDraftConfig,
    publishBoardConfig,
    loading,
  } = useDraftBoardConfigMutations(draftBoardConfigId, board.id);

  const isLoadingDraftBoardConfig = !useDraftBoardConfig(board.id).data?.node?.draftBoardConfig;

  const revert = async () => {
    if (disabled || isLoadingDraftBoardConfig || loading.publish || boardMutations.loading) return;
    await revertUnsavedDraftConfig();
  };

  return (
    <Modal hide={revert}>
      <Header>
        <Title>Roadmap view settings</Title>
        <CloseButtonStyled size="L" onClick={revert}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      {disabled || isLoadingDraftBoardConfig ? <BoardConfigSkeletons /> : (
        <BoardEditForm
          sectionType={SectionType.Roadmaps}
          defaultValues={board}
          focusNameOnMount
          onSubmit={async (formData, isDirty) => {
            const [publishResult, boardResult] = await Promise.all([
              publishBoardConfig(),
              ...isDirty ? [boardMutations.updateBoard({
                ...formData,
                boardId: board.id,
              })] : [],
            ]);
            if (publishResult.errors || boardResult?.errors) return;
            const slug = getBoardSlug(boardResult?.data?.updateBoard);
            if (slug) navigateToRoadmapView(slug);
          }}
        >
          <RoadmapViewBoardConfigFields boardId={board.id} />

          <ModalFooter>
            <ModalActions>
              {canDeleteView && <RoadmapViewDelete boardId={board.id} />}
            </ModalActions>

            <ModalActions>
              <Button
                size="M"
                variant="secondary"
                onClick={revert}
                isLoading={loading.revertUnsaved}
              >
                Cancel
              </Button>

              <Button
                size="M"
                type="submit"
                isLoading={boardMutations.loading || loading.publish}
              >
                Save
              </Button>
            </ModalActions>
          </ModalFooter>
        </BoardEditForm>
      )}
    </Modal>
  );
};
