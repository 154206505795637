import { mappingZindex } from 'src/constants/zIndex.constant';
import { getLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

const IGNORED_LAYERS = [Layer.Sidebar];

export function getOpenedLayers(): Layer[] {
  const currentLayers = getLayer();
  return (Object
    .keys(currentLayers) as Layer[])
    .filter(layer => currentLayers[layer]);
}

export const getOpenedLayersAbove = (layer: Layer) => {
  const layers = getLayer();
  const currentZindex = mappingZindex[layer];

  const layersArray = Object.keys(layers) as Layer[];
  const aboveLayers = layersArray.filter((l) => !IGNORED_LAYERS.includes(l) && layers[l] && mappingZindex[l] > currentZindex);

  return !!aboveLayers.length;
};
