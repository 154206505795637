import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { HEADER_SECONDARY_HEIGHT } from 'src/components/MobileHeader/MobileHeader.styles';

export const MobileHeader = styled.header`
  border-bottom: 1px solid ${p => p.theme.colors.components.MobileHeader.border};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  height: ${HEADER_SECONDARY_HEIGHT}px;
`;

export const HeaderTitle = styled.h2`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const CloseButton = styled.button`
  ${typo.body500}
  color: ${p => p.theme.colors.components.MobileHeader.closeButton.color};
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`;

export const Loading = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0.1;
`;
