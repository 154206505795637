import { typo, ActionButton, DropdownSelect } from '@cycle-app/ui';
import { Container as SelectPanelContainer } from '@cycle-app/ui/components/Selects/SelectPanel/SelectPanel.styles';
import { CheckIcon, AddIcon } from '@cycle-app/ui/icons';
import { small } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { EXTRA_CARD_ID } from './BillingUpdateFeatures.constants';

export const Container = styled.section`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin: 48px auto 220px;
  max-width: 1052px;
  position: relative;
  z-index: 3;

  ${small} {
    display: flex;
    flex-direction: column;
  }
`;

export const CardTitle = styled.h3`
  ${typo.headerLight}
  width: 100%;
  margin-top: 16px;
`;

export const CardText = styled.p`
  ${typo.body400}
  width: 100%;
  margin-top: 8px;
  color: ${p => p.theme.colors.text.secondary};
`;

type CardProps = {
  $id: string;
};

export const Card = styled.article<CardProps>`
  border-radius: 16px;
  padding: 16px 12px;
  background: ${p => p.theme.colors.background.secondary};
  ${p => (p.$id === EXTRA_CARD_ID && css`grid-column: 1 / -1;`)}
`;

export const CardNewBadge = styled.span`
  ${typo.tiny600}
  background-color: ${p => p.theme.colors.background.blue};
  border-radius: 4px;
  color: ${p => p.theme.colors.text.white};
  display: inline-block;
  height: 17px;
  margin-left: 8px;
  padding: 0 4px;
  vertical-align: middle;
`;

export const CardIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 28px;
`;

export const AddOnButton = styled(ActionButton) <{ $isActive: boolean }>`
  gap: 7px;
  border-radius: 21px;
  ${typo.caption500}
  padding: 4px 6px;

  ${p => p.$isActive && css`
    color: ${p.theme.isDark ? p.theme.colors.text.white : 'hsl(var(--cycle))'};
    pointer-events: none;
  `}
`;

export const IconContainer = styled.div<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    background: ${p => (p.$isActive ? 'hsl(var(--cycle))' : 'hsl(var(--cycle), 0.2)')};
    border-radius: 50%;
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  > svg {
    z-index: 1;
    width: 11px;
    height: 11px;
    color: hsl(var(--cycle));
    color: ${p => (p.$isActive ? 'white' : 'hsl(var(--cycle))')};
  }
`;

export const Dropdown = styled(DropdownSelect)`
  ${SelectPanelContainer} {
    width: 250px;
  }
`;

export const AddIconStyled = styled(AddIcon)``;
export const CheckIconStyled = styled(CheckIcon)``;

export const LogoContainer = styled.div`
  > img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;
