import { useQuery } from '@apollo/client';
import { ProductChangelogDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { extract } from 'src/types/graphql.types';

export const useChangelog = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, loading: isLoading,
  } = useQuery(ProductChangelogDocument, {
    variables: {
      productId,
    },
  });
  const changelog = extract('Product', data?.node)?.changelog;
  return {
    changelog,
    isLoading,
  };
};
