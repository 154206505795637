import { RefCallback, useCallback, useEffect, useRef, useState } from 'react';

import { domRectToObject, Rect } from '../utils/domRect.utils';

export const useResizeObserver = <T extends HTMLElement>() => {
  const observer = useRef<ResizeObserver | null>(null);
  const ref = useRef<T | null>(null);
  const [rect, setRect] = useState<Rect>();

  const setRef: RefCallback<T> = useCallback((el) => {
    if (!el) return;
    ref.current = el;
    if (observer.current) observer.current.disconnect();
    observer.current = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setRect(domRectToObject(entry.contentRect));
      });
    });
    observer.current.observe(ref.current);
  }, []);

  useEffect(() => {
    return () => observer.current?.disconnect();
  }, []);

  return {
    ref,
    rect,
    setRef,
    observer: observer.current,
  };
};
