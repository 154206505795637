import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetWorkflows,
  setValue: setWorkflows,
} = make({
  defaultState: {
    lock: false,
  },
});

export const lockWorkflows = () => setWorkflows({ lock: true });
export const unlockWorkflows = () => setWorkflows({ lock: false });
