import { typo, Skeleton, ring2 } from '@cycle-app/ui';
import styled from 'styled-components';

export const AddProjectButton = styled.button<{ $isMuted: boolean }>`
  ${typo.body400}
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;

  &:active, &:focus {
    ${ring2}
  }
  
  span {
    display: inline-flex;
    color: ${p => (p.$isMuted ? p.theme.colors.text.disabled : p.theme.colors.text.primary)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
  }

  svg {
    fill: ${p => p.theme.colors.text.disabled};
    width: 8px;
    height: 8px;
  }
`;

export const LoadingState = styled(Skeleton)`
  width: 80px;
  margin: 8px 0;
`;

export const ProjectsList = styled.ul`
  padding: 8px 0;
`;

export const ProjectItem = styled.li`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  padding: 3px 12px;
`;
