import { MutationHookOptions } from '@apollo/client';
import { AddProductWithOrganizationDocument, AddProductWithOrganizationMutation, ProductBaseFragmentDoc } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

type Me = {
  id: string;
  __typename?: string;
};

type Options = MutationHookOptions<AddProductWithOrganizationMutation>;

export const useCreateWorkspace = (me?: Me, options?: Options) => {
  const [createWorkspace, { loading: isLoading }] = useSafeMutation(AddProductWithOrganizationDocument, {
    ...options,
    errorPolicy: 'all',
    update: (cache, { data }) => {
      // if me is provided (= invited), add to the workspace cache.
      if (me && data?.addProductWithOrganization?.id) {
        const productRef = cache.writeFragment({
          fragment: ProductBaseFragmentDoc,
          fragmentName: 'ProductBase',
          data: data.addProductWithOrganization,
        });
        if (productRef) {
          cache.modify({
            id: cache.identify(me),
            fields: {
              products: (products) => {
                return {
                  ...products,
                  edges: [
                    ...products.edges,
                    {
                      __typename: 'ProductEdge',
                      node: productRef,
                    },
                  ],
                };
              },
            },
          });
          cache.modify({
            id: cache.identify(productRef),
            fields: {
              users: (users, { toReference }) => {
                return {
                  ...users,
                  edges: [
                    {
                      __typename: 'UserEdge',
                      node: toReference(me.id),
                    },
                    ...users.edges,
                  ],
                };
              },
            },
          });
        }
      }
    },
  });

  return {
    createWorkspace,
    isLoading,
  };
};
