export const Spacing = {
  S1: 2,
  S2: 4,
  S3: 8,
  S4: 12,
  S5: 16,
  S6: 24,
  S7: 32,
  S8: 40,
  S9: 48,
  S10: 64,
  S11: 80,
  S12: 96,
  S13: 160,
} as const;
