import { typo, truncate } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Placeholder = styled.div`
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.body400}
  ${truncate}
`;

type ContentProps = {
  placeholder?: string;
  $isDisabled: boolean;
};

export const Content = styled.h1<ContentProps>`
  cursor: ${p => (p.$isDisabled ? 'not-allowed' : 'text')};

  ${p => p.placeholder && css`
    &:empty::before {
      content: '${p.placeholder}';
      color: ${p.theme.colors.text.disabled};
    }
  `}

  :not(:empty) + ${Placeholder} {
    display: none;
  }
`;
