import { LoginDocument, LoginGoogleDocument, LoginMutationVariables, LoginGoogleMutationVariables } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

export const useLogin = () => {
  const [loginMutation, { loading }] = useSafeMutation(LoginDocument, {
    errorPolicy: 'all',
  });
  const [loginGoogleMutation, {
    loading: loadingGoogle,
    error,
  }] = useSafeMutation(LoginGoogleDocument, {
    errorPolicy: 'all',
    displayErrorMessages: true,
  });

  const login = (variables: LoginMutationVariables) => loginMutation({ variables });
  const loginGoogle = (variables: LoginGoogleMutationVariables) => loginGoogleMutation({ variables });

  return {
    login,
    loginGoogle,
    isLoading: loading || loadingGoogle,
    error,
  };
};
