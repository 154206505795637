import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiShadowIcon = (props:SvgProps) => (
  <Svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_3286_19282)">
      <path d="M9.64773 4.85225C9.64773 4.55572 9.40733 4.31532 9.1108 4.31532C8.81426 4.31532 8.57386 4.55572 8.57386 4.85225C8.57386 6.09175 8.29959 6.86167 7.83065 7.33062C7.3617 7.79956 6.59177 8.07383 5.35227 8.07383C5.05573 8.07383 4.81534 8.31422 4.81534 8.61076C4.81534 8.90729 5.05573 9.14769 5.35227 9.14769C6.59177 9.14769 7.3617 9.42196 7.83065 9.8909C8.29959 10.3598 8.57386 11.1298 8.57386 12.3693C8.57386 12.6658 8.81426 12.9062 9.1108 12.9062C9.40733 12.9062 9.64773 12.6658 9.64773 12.3693C9.64773 11.1298 9.922 10.3598 10.3909 9.8909C10.8599 9.42196 11.6298 9.14769 12.8693 9.14769C13.1659 9.14769 13.4062 8.90729 13.4062 8.61076C13.4062 8.31422 13.1659 8.07383 12.8693 8.07383C11.6298 8.07383 10.8599 7.79956 10.3909 7.33062C9.922 6.86167 9.64773 6.09175 9.64773 4.85225Z" fill="url(#paint0_linear_3286_19282)" />
      <path d="M5.08381 1.63068C5.08381 1.33414 4.84341 1.09375 4.54688 1.09375C4.25034 1.09375 4.00994 1.33414 4.00994 1.63068C4.00994 2.40409 3.83821 2.83843 3.58833 3.08832C3.33844 3.3382 2.90409 3.50993 2.13068 3.50993C1.83414 3.50993 1.59375 3.75032 1.59375 4.04686C1.59375 4.3434 1.83414 4.58379 2.13068 4.58379C2.90409 4.58379 3.33844 4.75552 3.58833 5.0054C3.83821 5.25529 4.00994 5.68963 4.00994 6.46304C4.00994 6.75958 4.25034 6.99997 4.54688 6.99997C4.84341 6.99997 5.08381 6.75958 5.08381 6.46304C5.08381 5.68963 5.25554 5.25529 5.50542 5.0054C5.75531 4.75552 6.18966 4.58379 6.96307 4.58379C7.25961 4.58379 7.5 4.3434 7.5 4.04686C7.5 3.75032 7.25961 3.50993 6.96307 3.50993C6.18966 3.50993 5.75531 3.3382 5.50542 3.08832C5.25554 2.83843 5.08381 2.40409 5.08381 1.63068Z" fill="url(#paint1_linear_3286_19282)" />
    </g>
    <defs>
      <filter id="filter0_d_3286_19282" x="0.1875" y="0.25" width="14.625" height="14.625" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.5625" />
        <feGaussianBlur stdDeviation="0.28125" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3286_19282" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3286_19282" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_3286_19282" x1="7.5" y1="1.09375" x2="7.5" y2="12.9062" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient id="paint1_linear_3286_19282" x1="7.5" y1="1.09375" x2="7.5" y2="12.9062" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
    </defs>
  </Svg>
);
