import { FileUploadedData } from '@cycle-app/utilities';

import { isDocumentTranscribable } from 'src/utils/files.util';
import { make } from 'src/utils/reactives.util';

const {
  hookValue,
  getValue,
  setValue,
  resetValue,
} = make<{
  uploadedData: Record<string, FileUploadedData>;
}>({
  defaultState: {
    uploadedData: {},
  },
});

export const resetTranscriptTooltips = resetValue;

export const useTranscriptTooltip = (id?: string) => {
  const { uploadedData } = hookValue();
  if (!id) return null;
  return uploadedData[id] ?? null;
};

export const setTranscriptTooltip = (id: string | undefined, data: FileUploadedData) => {
  if (!id) return;
  if (!['image', 'video', 'audio'].includes(data.type) && !isDocumentTranscribable(data.src)) return;
  // Wait for the editor node to be ready
  setTimeout(() => {
    setValue({
      uploadedData: {
        ...getValue().uploadedData,
        [id]: data,
      },
    });
  }, 800);
};

export const removeTranscriptTooltip = (id?: string) => {
  if (!id) return;
  const {
    [id]: _, ...uploadedData
  } = getValue().uploadedData;
  setValue({ uploadedData });
};
