import { IntegrationType } from '@cycle-app/graphql-codegen';
import { SelectLine } from '@cycle-app/ui';
import { FC } from 'react';

import { InstallIntegrationButton } from 'src/components/Integrations/InstallIntegrationButton/InstallIntegrationButton';
import { useProductIntegrations } from 'src/hooks';
import { integrationNameTitles } from 'src/utils/integrations.utils';

type Props = {
  integrationType: IntegrationType;
};

export const IntegrationNotInstalledLine: FC<React.PropsWithChildren<Props>> = ({ integrationType }) => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(integrationType);
  return (
    <SelectLine
      label={`🙃 ${integrationNameTitles[integrationType]} integration is not installed`}
      endSlot={integration && <InstallIntegrationButton integration={integration} />}
    />
  );
};
