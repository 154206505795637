import { Tooltip } from '@cycle-app/ui';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate, useProduct, useRole } from 'src/hooks';
import { useMe } from 'src/hooks/api/useMe';

import { HomeMembersSection } from '../HomeMembersSection';
import { Header, Title, Description, Role } from './HomeHeader.styles';

export const HomeHeader = () => {
  const { product } = useProduct();
  const { me } = useMe();
  const {
    role, isLoading: isLoadingRole,
  } = useRole();
  const { navigate } = useNavigate();

  return (
    <Header>
      <div>
        <Title>
          {`Hello ${me.firstName} ❄️`}

          {role && !isLoadingRole && (
            <Tooltip
              content="Explore maker features"
              placement="top"
              withPortal
              withWrapper={false}
            >
              <Role onClick={() => navigate(PageId.RequestMakerAccess)}>
                {role}
              </Role>
            </Tooltip>
          )}
        </Title>

        {product?.name && (
          <Description>
            {`Welcome to your ${product.name}’s workspace`}
          </Description>
        )}
      </div>
      <HomeMembersSection />
    </Header>
  );
};
