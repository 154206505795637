import { CycleLoader, SelectPanel } from '@cycle-app/ui';
import {
  SearchIcon, AddIcon, CloseIcon, WheelIcon, LinkArrowIcon, DuplicateIcon,
} from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ReleaseNote } from 'src/components/ReleaseNote';
import { ReleasesEmpty } from 'src/components/ReleasesEmpty/';
import { EDITOR_MAX_CONTENT_WIDTH } from 'src/constants/editor.constants';
import { routing, PageId } from 'src/constants/routing.constant';
import { useReleasesContext } from 'src/contexts/releasesContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { usePageId } from 'src/hooks/usePageId';
import { openCreateRelease } from 'src/reactives/releases.reactive';
import { useIsSmallScreen } from 'src/reactives/responsive.reactive';
import { changelogUrl } from 'src/utils/changelog.utils';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import {
  PageContainer,
  Section,
  SectionContainer,
  Header,
  Title,
  HeaderButton,
  SearchInput,
  CreateChangelogBannerContainer,
  CreateChangelogBannerIcon,
  CreateChangelogBannerButton,
  LiveChangelogDropdownTrigger,
  ChangelogLiveIndicator,
  ChangelogLiveCaret,
  LiveChangelogDropdownUrlContent,
} from './ReleasesContent.styles';
import { ReleasesList } from './ReleasesList';
import { INPUT_ONCHANGE_DEBOUNCE } from '../../constants/inputs.constant';
import { useOptimizedBooleanState } from '../../hooks';
import { useChangelog } from '../../hooks/releases/useChangelog';
import { useChangelogUpdate } from '../../hooks/releases/useChangelogUpdate';
import { useNavigateToSettings } from '../../hooks/useNavigateToSettings';
import { getPermission, setLimitationsModal, useGetPermission } from '../../reactives';

export const ReleasesContent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isSmallScreen = useIsSmallScreen();
  const pageId = usePageId();
  const isLoading = useReleasesContext(ctx => ctx.isLoading);
  const releasesCount = useReleasesContext(ctx => ctx.releasesList.length);
  if (isLoading) return <CycleLoader />;
  if (releasesCount === 0) return <ReleasesEmpty />;
  return (
    <PageContainer>
      {(!isSmallScreen || pageId !== PageId.ReleaseNote) && <ReleasesSection>{children}</ReleasesSection>}
      <ReleaseNoteSection />
    </PageContainer>
  );
};

type SearchProps = {
  onSearch: (searchText: string) => void;
};

const Search = ({ onSearch }: SearchProps) => {
  const [searchText, setSearchText] = useState('');
  const [isSearching, {
    setTrueCallback: showSearch, setFalseCallback: hideSearch,
  }] = useOptimizedBooleanState(false);

  const handleSetSearchDebounced = useDebouncedCallback(onSearch, INPUT_ONCHANGE_DEBOUNCE);

  const reset = () => {
    onSearch('');
    setSearchText('');
    hideSearch();
  };

  return (
    <div className="flex items-center">
      {isSearching && (
        <div className="flex items-center gap-1">
          <SearchInput
            autoFocus
            onKeyDown={e => {
              if (e.key === 'Escape') {
                e.preventDefault();
                reset();
              }
            }}
            onChange={e => {
              setSearchText(e.currentTarget.value);
              handleSetSearchDebounced(e.currentTarget.value);
            }}
            value={searchText}
            iconBefore={<SearchIcon size={16} />}
            iconAfter={<CloseIcon size={12} />}
            onClickIcon={reset}
          />
        </div>
      )}
      {!isSearching && (
        <HeaderButton
          onClick={showSearch}
          tooltip="Search in releases"
          tooltipPlacement="bottom"
        >
          <SearchIcon size={16} />
        </HeaderButton>
      )}
    </div>
  );
};

const ReleasesSection: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [searchText, setSearchText] = useState('');
  const { navigate } = useNavigateToSettings();
  const { canUpdateRelease } = useGetPermission();

  return (
    <Section>
      <ErrorBoundary>
        <SectionContainer>
          <Header>
            <Title>
              <h1>Releases</h1>
            </Title>
            <div className="flex items-center gap-2">
              <LiveChangelogDropdown />
              <Search onSearch={setSearchText} />
              {canUpdateRelease && (
                <HeaderButton
                  tooltip="Customize your changelog URL and design"
                  tooltipPlacement="bottom"
                  onClick={() => navigate(PageId.SettingsReleases, undefined, {
                    changelogBuilder: {
                      previousPageId: PageId.Releases,
                    },
                  })}
                >
                  <WheelIcon size={16} />
                </HeaderButton>
              )}
              <HeaderButton
                onClick={() => {
                  if (getPermission().canCreateRelease) {
                    openCreateRelease();
                  } else {
                    setLimitationsModal({ action: 'RELEASE_UPDATE' });
                  }
                }}
                tooltip="Create new release"
                tooltipPlacement="bottom"
              >
                <AddIcon size={16} />
              </HeaderButton>
            </div>
          </Header>

          <CreateChangelogBanner />

          <ReleasesList searchText={searchText}>
            {children}
          </ReleasesList>
        </SectionContainer>
      </ErrorBoundary>
    </Section>
  );
};

const ReleaseNoteSection = () => {
  const location = useLocation();
  const pageId = usePageId();
  const isSmallScreen = useIsSmallScreen();
  const isReleaseNoteOpen = [PageId.ReleaseNote, PageId.ReleaseDoc].includes(pageId);
  return (
    <AnimatePresence>
      <Switch location={location} key={String(isReleaseNoteOpen)}>
        <Route path={routing[PageId.ReleaseNote]}>
          <Section
            style={{
              flex: 'none',
              maxWidth: EDITOR_MAX_CONTENT_WIDTH,
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              visible: { width: isSmallScreen ? '100%' : '50%' },
              hidden: { width: 0 },
            }}
            transition={{ duration: isSmallScreen ? 0 : 0.2 }}
          >
            <ErrorBoundary>
              <ReleaseNote />
            </ErrorBoundary>
          </Section>
        </Route>
      </Switch>
    </AnimatePresence>
  );
};

const CreateChangelogBanner = () => {
  const {
    changelog, isLoading,
  } = useChangelog();
  const {
    changelogUpdate, isLoading: isUpdating,
  } = useChangelogUpdate();
  if (!changelog || changelog.isPublished || isLoading) return null;

  const handlePublishChangelog = async () => {
    if (isUpdating) return;

    await changelogUpdate({
      id: changelog.id,
      isPublished: true,
    });
  };

  return (
    <CreateChangelogBannerContainer>
      <CreateChangelogBannerIcon size={24} />
      <div>Your public changelog is not created yet.</div>

      <CreateChangelogBannerButton
        variant="outlined-alt"
        size="M"
        onClick={handlePublishChangelog}
        isLoading={isUpdating}
      >
        Create public changelog
      </CreateChangelogBannerButton>
    </CreateChangelogBannerContainer>
  );
};

const LiveChangelogDropdown = () => {
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);
  const {
    changelog, isLoading,
  } = useChangelog();
  if (!changelog || !changelog.isPublished || isLoading) return null;

  const domainUrl = changelogUrl(productSlug, changelog.domain);

  return (
    <ToggleDropdown
      placement="bottom-end"
      button={props => (
        <LiveChangelogDropdownTrigger onClick={props.onClick}>
          <ChangelogLiveIndicator />
          Changelog is live

          <ChangelogLiveCaret direction={props['data-active'] ? 'top' : 'bottom'} />
        </LiveChangelogDropdownTrigger>
      )}
      content={({ hide }) => (
        <SelectPanel
          hideSearch
          options={[
            {
              value: 'open-changelog',
              icon: <LinkArrowIcon />,
              label: 'Open changelog',
              onSelect: () => { window.open(domainUrl, '_blank'); },
            },
            {
              value: 'copy-changelog',
              icon: <DuplicateIcon />,
              label: 'Copy link',
              onSelect: () => {
                copyToClipboard({
                  text: domainUrl,
                  notification: 'Changelog URL copied to clipboard',
                });
              },
            },
          ]}
          onOptionChange={({ onSelect }) => {
            onSelect?.();
            hide();
          }}
        >
          <LiveChangelogDropdownUrlContent>
            {domainUrl}
          </LiveChangelogDropdownUrlContent>
        </SelectPanel>
      )}
    />
  );
};
