import { Color, ContentSection, HeroSection, ReleaseTagStyle, Typography } from '@cycle-app/graphql-codegen';
import { Controller } from 'react-hook-form';

import { ChangelogBuilderSubmit } from './ChangelogBuilderSubmit';
import { Root, Tabs, Tab, TemplateContainer } from './ChangelogBuilderTopBar.styles';
import { ChangelogBuilderTemplateValue } from '../../hooks/releases/useChangelogBuilderForm';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';

export const templateCycleLight: ChangelogBuilderTemplateValue['theme'] = {
  heroSection: HeroSection.L,
  contentSection: ContentSection.Unique,
  backgroundColor: '#FFFFFF',
  headersColor: '#161B22',
  linkColor: '#2B44E7',
  bodyColor: '#000000',
  shipBadgeColor: Color.A,
  releaseTagStyle: ReleaseTagStyle.Background,
  releaseTagColor: '#000000',
  dividerColor: '#E6E6E6',
  tagsColors: [
    '#FDE2E2',
    '#F5F1FE',
    '#E2FDEE',
    '#E2E6FD',
    '#FDF8E2',
    '#F9E2FD',
    '#FDE2F2',
    '#FDE7E2',
  ],
};

export const templateCycleDark: ChangelogBuilderTemplateValue['theme'] = {
  heroSection: HeroSection.L,
  contentSection: ContentSection.Unique,
  backgroundColor: '#171717',
  headersColor: '#FFFFFF',
  linkColor: '#919FF7',
  bodyColor: '#FFFFFF',
  shipBadgeColor: Color.A,
  releaseTagStyle: ReleaseTagStyle.Background,
  releaseTagColor: '#FFFFFF',
  dividerColor: '#242424',
  tagsColors: [
    '#550000',
    '#29234A',
    '#004D33',
    '#282B59',
    '#5C5300',
    '#4A004A',
    '#59002B',
    '#5C1F00',
  ],
};

export const ChangelogBuilderTopBar = () => {
  const {
    watch, control, setValue,
  } = useChangelogBuilderFormContext();
  const template = watch('template');
  const isCycle = template?.startsWith('cycle_');
  const tags = watch('tags');
  return (
    <Root>
      {isCycle && (
        <TemplateContainer>
          <Controller
            control={control}
            name="template"
            render={({ field }) => (
              <Tabs>
                <Tab
                  onClick={() => {
                    field.onChange('cycle_light');
                    setValue('contentSection', templateCycleLight.contentSection);
                    setValue('heroSection', templateCycleLight.heroSection);
                    setValue('backgroundColor', templateCycleLight.backgroundColor);
                    setValue('headersColor', templateCycleLight.headersColor);
                    setValue('linkColor', templateCycleLight.linkColor);
                    setValue('bodyColor', templateCycleLight.bodyColor);
                    setValue('shipBadgeColor', templateCycleLight.shipBadgeColor);
                    setValue('releaseTagStyle', templateCycleLight.releaseTagStyle);
                    setValue('releaseTagColor', templateCycleLight.releaseTagColor);
                    setValue('dividerColor', templateCycleLight.dividerColor);
                    // Inter for all templates
                    setValue('typography', Typography.Inter);
                    tags.forEach((_, tagIndex) => {
                      const color = templateCycleLight.tagsColors[tagIndex] || templateCycleLight.tagsColors.at(-1);
                      if (color) {
                        setValue(`tags.${tagIndex}.color`, color);
                      }
                    });
                  }}
                  $isActive={template === 'cycle_light'}
                  variant="nospace"
                >
                  Light
                </Tab>
                <Tab
                  onClick={() => {
                    field.onChange('cycle_dark');
                    setValue('contentSection', templateCycleDark.contentSection);
                    setValue('heroSection', templateCycleDark.heroSection);
                    setValue('backgroundColor', templateCycleDark.backgroundColor);
                    setValue('headersColor', templateCycleDark.headersColor);
                    setValue('linkColor', templateCycleDark.linkColor);
                    setValue('bodyColor', templateCycleDark.bodyColor);
                    setValue('shipBadgeColor', templateCycleDark.shipBadgeColor);
                    setValue('releaseTagStyle', templateCycleDark.releaseTagStyle);
                    setValue('releaseTagColor', templateCycleDark.releaseTagColor);
                    setValue('dividerColor', templateCycleDark.dividerColor);
                    tags.forEach((_, tagIndex) => {
                      const color = templateCycleDark.tagsColors[tagIndex] || templateCycleDark.tagsColors.at(-1);
                      if (color) {
                        setValue(`tags.${tagIndex}.color`, color);
                      }
                    });
                  }}
                  $isActive={template === 'cycle_dark'}
                  variant="nospace"
                >
                  Dark
                </Tab>
              </Tabs>
            )}
          />
        </TemplateContainer>
      )}
      <ChangelogBuilderSubmit />
    </Root>
  );
};
