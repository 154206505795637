import {
  AddSwimlaneFilterRuleDocument,
  AddSwimlaneFilterRuleValueDocument,
  ChangeSwimlaneFilterRuleOperatorDocument,
  ChangeSwimlaneFilterRulePropertyDocument,
  FilterValueInput,
  Operator,
  PropertyFragment,
  RemoveSwimlaneFilterRuleDocument,
  RemoveSwimlaneFilterRuleValueDocument,
  AddSwimlaneFilterRuleMutation,
  ChangeSwimlaneFilterRulePropertyMutation,
  SwimlaneFilterPropertyRuleFragment,
  BoardWithDraftConfigDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useGetDraftBoardConfigFromCache } from 'src/hooks/api/cache/cacheBoardConfig';
import { useBoard } from 'src/hooks/api/useBoard';
import useSafeMutation from 'src/hooks/useSafeMutation';
import {
  getOptimisticNewFilter,
  mappingSwimlanePropertyFilterRule,
  getOptimisticFilterWithAddedValue,
  getOptimisticFilterWithRemovedValue,
} from 'src/utils/boardConfig/filtersOptimistic.util';
import { defaultPagination } from 'src/utils/pagination.util';

interface Props {
  swimlaneConfigId: string;
  boardConfigId: string;
  boardID?: string;
}
export default function useSwimlaneFilterMutations({
  swimlaneConfigId: swimlaneById, boardID,
}: Props) {
  const board = useBoard(boardID);
  const boardId = board?.id ?? '';
  const getDraftBoardConfigFromCache = useGetDraftBoardConfigFromCache(boardId);

  const [addFilterRuleMutation, { loading: loadingAddFilterRule }] = useSafeMutation(AddSwimlaneFilterRuleDocument);
  const [removeFilterRuleMutation, { loading: loadingRemoveFilterRule }] = useSafeMutation(RemoveSwimlaneFilterRuleDocument);
  const [changePropertyMutation, { loading: loadingChangeProperty }] = useSafeMutation(ChangeSwimlaneFilterRulePropertyDocument);
  const [changeOperatorMutation, { loading: loadingChangeOperator }] = useSafeMutation(ChangeSwimlaneFilterRuleOperatorDocument);
  const [addFilterValueMutation, { loading: loadingAddValue }] = useSafeMutation(AddSwimlaneFilterRuleValueDocument);
  const [removeFilterValueMutation, { loading: loadingRemoveValue }] = useSafeMutation(RemoveSwimlaneFilterRuleValueDocument);

  const addFilterRule = useCallback((property: PropertyFragment) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();

    if (draftBoardConfig?.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return null;

    const optimisticNewFilter = getOptimisticNewFilter(property, mappingSwimlanePropertyFilterRule);

    return addFilterRuleMutation({
      variables: {
        swimlaneById,
        propertyId: property.id,
        ...defaultPagination,
      },
      optimisticResponse: {
        addSwimlanebyFilterRule: {
          ...optimisticNewFilter,
          swimlanebyConfig: {
            ...draftBoardConfig.docQuery.swimlanebyConfig,
            filterProperties: {
              edges: [
                ...draftBoardConfig.docQuery.swimlanebyConfig.filterProperties.edges,
                {
                  __typename: 'SwimlaneByFilterPropertyRuleEdge',
                  node: optimisticNewFilter,
                },
              ],
            },
          },
        },
      } as AddSwimlaneFilterRuleMutation,
      update: (cache, { data }) => {
        if (!data?.addSwimlanebyFilterRule || draftBoardConfig.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return;

        /**
         * Sometimes (hard to reproduce) apollo can't reconcile the mutation result with the existing cache,
         * leading to an unwanted refetching of "BoardWithDraftConfigDocument" query.
         * Manually updating the cache prevents Apollo's default behaviour
         */
        cache.writeQuery({
          query: BoardWithDraftConfigDocument,
          data: {
            node: {
              id: boardId,
              draftBoardConfig: {
                ...draftBoardConfig,
                docQuery: {
                  ...draftBoardConfig.docQuery,
                  swimlanebyConfig: {
                    ...draftBoardConfig.docQuery.swimlanebyConfig,
                    ...data.addSwimlanebyFilterRule.swimlanebyConfig,
                  },
                },
              },
            },
          },
          overwrite: true,
        });
      },
    });
  }, [addFilterRuleMutation, swimlaneById, getDraftBoardConfigFromCache, boardId]);

  const removeFilterRule = useCallback((ruleId: string) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();
    if (draftBoardConfig?.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return null;

    return removeFilterRuleMutation({
      variables: {
        ruleId,
        ...defaultPagination,
      },
      optimisticResponse: {
        removeSwimlanebyFilterRule: {
          ...draftBoardConfig,
          docQuery: {
            ...draftBoardConfig.docQuery,
            swimlanebyConfig: {
              ...draftBoardConfig.docQuery.swimlanebyConfig,
              filterProperties: {
                edges: draftBoardConfig.docQuery.swimlanebyConfig.filterProperties.edges.filter(edge => edge.node.id !== ruleId),
              },
            },
          },
        },
      },
    });
  }, [removeFilterRuleMutation, getDraftBoardConfigFromCache]);

  const changeFilterRuleAttribute = useCallback((ruleId: string, property: PropertyFragment) => {
    const boardConfig = getDraftBoardConfigFromCache();
    if (boardConfig?.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return null;

    const optimisticNewFilter = getOptimisticNewFilter(property, mappingSwimlanePropertyFilterRule);

    return changePropertyMutation({
      variables: {
        ruleId,
        propertyId: property.id,
        ...defaultPagination,
      },
      optimisticResponse: {
        changeSwimlanebyFilterRuleProperty: {
          ...optimisticNewFilter,
          swimlanebyConfig: {
            ...boardConfig.docQuery.swimlanebyConfig,
            filterProperties: {
              __typename: 'SwimlaneByFilterPropertyRulesConnection',
              edges: boardConfig.docQuery.swimlanebyConfig.filterProperties.edges.map(edge => (edge.node.id === ruleId ? ({
                __typename: 'SwimlaneByFilterPropertyRuleEdge',
                node: optimisticNewFilter,
              }) : edge)),
            },
          },
        },
      } as ChangeSwimlaneFilterRulePropertyMutation,
    });
  }, [changePropertyMutation, getDraftBoardConfigFromCache]);

  const changeFilterRuleOperator = useCallback((ruleId: string, operator: Operator) => changeOperatorMutation({
    variables: {
      ruleId,
      operator,
      ...defaultPagination,
    },
  }), [changeOperatorMutation]);

  const addFilterValue = useCallback((ruleId: string, value: FilterValueInput) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();
    if (draftBoardConfig?.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return null;

    const filter: SwimlaneFilterPropertyRuleFragment | undefined = draftBoardConfig.docQuery.swimlanebyConfig.filterProperties.edges
      .find(({ node }) => node.id === ruleId)?.node;
    if (!filter) return null;

    const optimisticUpdatedFilter = getOptimisticFilterWithAddedValue(filter, value);

    return addFilterValueMutation({
      variables: {
        ruleId,
        value,
        ...defaultPagination,
      },
      optimisticResponse: {
        addSwimlanebyFilterRuleValue: {
          ...optimisticUpdatedFilter,
          swimlanebyConfig: {
            ...draftBoardConfig.docQuery.swimlanebyConfig,
            filterProperties: {
              __typename: 'SwimlaneByFilterPropertyRulesConnection',
              edges: draftBoardConfig.docQuery.swimlanebyConfig.filterProperties.edges.map(edge => (edge.node.id === ruleId ? ({
                __typename: 'SwimlaneByFilterPropertyRuleEdge',
                node: optimisticUpdatedFilter,
              }) : edge)),
            },
          },
        },
      },
      update: (cache, { data }) => {
        if (
          !data?.addSwimlanebyFilterRuleValue ||
          draftBoardConfig.docQuery.__typename !== 'BoardQueryWithSwimlaneBy'
        ) return;

        cache.writeQuery({
          query: BoardWithDraftConfigDocument,
          data: {
            node: {
              id: boardId,
              draftBoardConfig: {
                ...draftBoardConfig,
                docQuery: {
                  ...draftBoardConfig.docQuery,
                  swimlanebyConfig: {
                    ...draftBoardConfig.docQuery.swimlanebyConfig,
                    ...data.addSwimlanebyFilterRuleValue.swimlanebyConfig,
                  },
                },
              },
            },
          },
          overwrite: true,
        });
      },
    });
  }, [addFilterValueMutation, getDraftBoardConfigFromCache, boardId]);

  const removeFilterValue = useCallback((ruleId: string, valueId: string) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();
    if (draftBoardConfig?.docQuery.__typename !== 'BoardQueryWithSwimlaneBy') return null;

    const filter: SwimlaneFilterPropertyRuleFragment | undefined = draftBoardConfig.docQuery.swimlanebyConfig.filterProperties.edges
      .find(({ node }) => node.id === ruleId)?.node;
    if (!filter) return null;

    const optimisticFilter = getOptimisticFilterWithRemovedValue(filter, valueId);

    return removeFilterValueMutation({
      variables: {
        ruleId,
        valueId,
        ...defaultPagination,
      },
      optimisticResponse: {
        removeSwimlanebyFilterRuleValue: {
          ...optimisticFilter,
          swimlanebyConfig: {
            ...draftBoardConfig.docQuery.swimlanebyConfig,
            filterProperties: {
              __typename: 'SwimlaneByFilterPropertyRulesConnection',
              edges: draftBoardConfig.docQuery.swimlanebyConfig.filterProperties.edges.map(edge => (edge.node.id === ruleId ? ({
                __typename: 'SwimlaneByFilterPropertyRuleEdge',
                node: optimisticFilter,
              }) : edge)),
            },
          },
        },
      },
      update: (cache, { data }) => {
        if (
          !data?.removeSwimlanebyFilterRuleValue ||
          draftBoardConfig.docQuery.__typename !== 'BoardQueryWithSwimlaneBy'
        ) return;

        cache.writeQuery({
          query: BoardWithDraftConfigDocument,
          data: {
            node: {
              id: boardId,
              draftBoardConfig: {
                ...draftBoardConfig,
                docQuery: {
                  ...draftBoardConfig.docQuery,
                  swimlanebyConfig: {
                    ...draftBoardConfig.docQuery.swimlanebyConfig,
                    ...data.removeSwimlanebyFilterRuleValue.swimlanebyConfig,
                  },
                },
              },
            },
          },
          overwrite: true,
        });
      },
    });
  }, [removeFilterValueMutation, getDraftBoardConfigFromCache, boardId]);

  return {
    loadingChangeOperator,
    loadingChangeProperty,
    addFilterRule,
    removeFilterRule,
    changeFilterRuleAttribute,
    changeFilterRuleOperator,
    addFilterValue,
    removeFilterValue,
    loading:
      loadingAddFilterRule ||
      loadingRemoveFilterRule ||
      loadingChangeOperator ||
      loadingChangeProperty ||
      loadingAddValue ||
      loadingRemoveValue,
  };
}
