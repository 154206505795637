import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { DragHandle } from 'src/components/EditorDragMenu';
import { nodeCommonCss } from 'src/styles';

export const Container = styled(NodeViewWrapper)`
  ${nodeCommonCss}
`;

type ListProps = {
  $isActive: boolean;
};

export const List = styled.div<ListProps>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;

  ${p => p.$isActive && css`
    background-color: hsl(var(--cycle100));
    background-color: ${p.theme.colors.components.TableOfContentNodeView.bg};
  `}

  &:hover {
    ${DragHandle} {
      display: flex;
    }
  }
`;

type ListItemProps = {
  $level: number;
};

export const ListItem = styled.div<ListItemProps>`
  width: 100%;
  padding-left: ${(p) => (p.$level - 1) * 16}px;
`;

type StyledDataItemLinkProps = {
  isActive: boolean;
};

export const ItemLink = styled.span<StyledDataItemLinkProps>`
  color: ${p => p.theme.colors.components.TableOfContentNodeView.text};
  text-decoration: underline;
  opacity: 0.9;
  cursor: pointer;
`;

export const EmptyState = styled.p`
  padding-left: 6px !important;
  padding-right: 6px !important;
`;
