import { ActionButton } from '@cycle-app/ui';
import { InfoIconOutline, DuplicateIcon } from '@cycle-app/ui/icons';
import { getAbsoluteUrl } from '@cycle-app/utilities';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useProductBase } from 'src/hooks';
import { useBoardSlug } from 'src/hooks/usePathParams';
import { useUrl, PageId } from 'src/hooks/useUrl';
import { useIsSmallScreen } from 'src/reactives';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import {
  Panel, Dropdown, Title, Description, Info, Field,
} from './ShareBoard.styles';
import { StyledButton } from './ViewHeader.styles';

export const ShareBoard = () => {
  const boardSlug = useBoardSlug();
  const product = useProductBase();
  const getUrl = useUrl();
  const isSmallScreen = useIsSmallScreen();
  const notFound = useBoardConfig(ctx => ctx.notFound);
  if (isSmallScreen || notFound) return null;

  const viewUrl = getAbsoluteUrl(getUrl(PageId.Board, { boardSlug }));

  return (
    <Dropdown
      offsetY={8}
      button={props => (
        <StyledButton {...props}>
          Share
        </StyledButton>
      )}
      content={props => (
        <Panel>
          <Title>
            Share view
          </Title>

          <Description>
            Invite new team member to start collaborating
          </Description>

          <Field>
            <input
              disabled
              value={viewUrl}
            />
            <ActionButton
              onClick={() => {
                copyToClipboard({
                  text: viewUrl,
                  notification: 'View URL copied to clipboard!',
                });
                props.hide();
              }}
            >
              <DuplicateIcon size={16} />
            </ActionButton>
          </Field>

          {product && (
            <Info>
              <InfoIconOutline size={14} />
              {`Everyone at ${product.name} can access this view`}
            </Info>
          )}
        </Panel>
      )}
    />
  );
};
