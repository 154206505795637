import { ThemeType } from '@cycle-app/graphql-codegen/generated';
import { Button, Shortcut, typo } from '@cycle-app/ui';
import { Key } from '@cycle-app/ui/components/Shortcut/Shortcut.styles';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled, { css } from 'styled-components';

export const CreateDocIconContainer = styled.div`
  border-radius: 4px;
  background-color: ${p => (p.theme.themeType === ThemeType.Nightfall ? 'hsla(var(--cycle400), 0.2)' : 'hsla(var(--cycle), 0.2)')};
  padding: 4px;
  display: flex;

  svg {
    width: 8px;
    height: 8px;
  }
`;

export const CreateNewShortcut = styled(Shortcut)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  ${Key} {
    min-width: unset;
    width: 16px;
    height: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${p => p.theme.colors.sidebar.shortcut.bgHover};
    color: ${p => p.theme.colors.sidebar.shortcut.colorHover};
    ${typo.caption500}
    line-height: 1;
  }
`;

type CreateNewButtonProps = {
  $collapsed: boolean;
};

export const CreateNewButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'L',
}) <CreateNewButtonProps>`
  height: 28px;
  padding: 14px 7px;
  max-width: 100%;
  font-weight: 500;
  justify-content: flex-start;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    background: ${p => p.theme.colors.sidebar.item.bgHover};
  }
  &:active {
    background: ${p => p.theme.colors.sidebar.item.bgActive};
  }

  svg {
    flex: none;
    width: 19px;
    height: 19px;
   }

  ${p => p.$collapsed && css`
    margin: 8px 0 0 0;
    width: 32px;
    height: 32px;
    padding: 0;
    justify-content: center;
    span { display: none };
  `}

  ${p => p.theme.themeType === ThemeType.Nightfall && css`
    color: ${Color.Blue400};
  `}

  ${CreateNewShortcut} {
    display: none;
  }

  &:hover ${CreateNewShortcut} {
    display: flex;
  }
`;
