import { useHover } from '@cycle-app/utilities';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { Toaster, ToasterProps } from '../Toaster';
import { Container } from './Toasters.styles';

const TOASTER_MIN_HEIGHT = 48;
const TOASTERS_GAP = 1;

export type ToastersProps = {
  toasters: ToasterProps[];
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  filter?: (props: ToasterProps) => boolean;
  isInert?: (props: ToasterProps) => boolean;
};

export const Toasters: FC<React.PropsWithChildren<ToastersProps>> = ({
  toasters, onMouseEnter, onMouseLeave, filter, isInert,
}) => {
  const [isHover, ref] = useHover<HTMLDivElement>({
    onMouseEnter,
    onMouseLeave,
  });

  return (
    <Container ref={ref}>
      <AnimatePresence>
        {toasters
          .filter(props => !filter || filter(props))
          .map((props, index) => {
            const i = toasters.length - index - 1;
            const inert = isInert?.(props);
            return (
              (
                <motion.div
                  key={props.id}
                  className="toaster"
                  style={{
                    position: 'relative',
                    zIndex: index,
                    paddingTop: 8,
                    boxSizing: 'content-box',
                    ...(inert && {
                      pointerEvents: 'none',
                    }),
                  }}
                  initial={{
                    height: 'auto',
                  }}
                  animate={{
                    height: isHover || i === 0 ? 'auto' : TOASTER_MIN_HEIGHT + TOASTERS_GAP,
                    translateY: isHover ? 0 : i * (TOASTER_MIN_HEIGHT - TOASTERS_GAP),
                    scale: isHover ? 1 : Math.max(0, 1 - 0.03 * i),
                    translateX: inert ? '90%' : 0,
                    filter: inert ? 'brightness(0.5)' : 'brightness(1)',
                  }}
                  transition={{
                    duration: 0.15,
                  }}
                >
                  <Toaster {...props} />
                </motion.div>
              )
            );
          })}
      </AnimatePresence>
    </Container>
  );
};
