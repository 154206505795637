import { CheckIcon } from '@cycle-app/ui/icons';

import { useGetLinearImportModal, LinearImportStep } from 'src/reactives/linearImport.reactive';

import { Steps, Step, Index } from './LinearImportModal.styles';

const headerSteps: LinearImportStep[] = ['projects', 'issues', 'automations'];

export const LinearImportHeader = () => {
  const {
    steps, currentStep,
  } = useGetLinearImportModal();
  if (currentStep === null) return null;
  return (
    <Steps>
      {headerSteps.filter(step => steps.includes(step)).map((step, index) => {
        const isDone = headerSteps.indexOf(currentStep) > index;
        return (
          <Step key={step} $isActive={step === currentStep} $isDone={isDone}>
            <Index>{isDone ? <CheckIcon size={14} /> : index + 1}</Index>
            {getStepLabel(step)}
          </Step>
        );
      })}
    </Steps>
  );
};

const getStepLabel = (step: LinearImportStep) => {
  if (step === 'projects') return 'Import projects';
  if (step === 'issues') return 'Import issues';
  if (step === 'automations') return 'Automations';
  return null;
};
