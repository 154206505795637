import { useEffect } from 'react';

import { setBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { setBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';

/**
 * We need to ensure that these reactives are reset when a view is unmounted,
 * as the global 'C' key shortcut for creating new feedback depends on them.
 * In some cases they are not reset, for example when you leave a doc group without a mouseout event,
 * or when you leave a view without closing the doc creation form.
 */
export const useResetViewStateOnUnmount = () => {
  useEffect(() => {
    return () => {
      setBoardGroupState({ hoverGroupId: null });
      setBoardNewDocPositionState({ draftPosition: null });
    };
  }, []);
};
