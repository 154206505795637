import { useEffect } from 'react';

import { setScriptLoaded, getIsScriptLoaded } from 'src/reactives';
import { Script } from 'src/types/scripts.types';

export const useCloudinaryScript = () => {
  useEffect(() => {
    if (getIsScriptLoaded(Script.CLOUDINARY)) return;
    const script = document.createElement('script');
    script.src = Script.CLOUDINARY;
    script.async = true;
    document.body.appendChild(script);
    const cssLink = document.createElement('link');
    cssLink.href = 'https://unpkg.com/cloudinary-video-player@1.9.9/dist/cld-video-player.light.min.css';
    cssLink.rel = 'stylesheet';
    document.head.appendChild(cssLink);
    script.addEventListener('load', () => {
      setScriptLoaded(Script.CLOUDINARY);
    });
  }, []); // Empty dependency array ensures this effect runs only once
};
