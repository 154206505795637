import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookState: useCollapsedGroups,
  hookValue: useGetCollapsedGroups,
  getValue: getCollapsedGroups,
  setValue: setCollapsedGroups,
} = make<{ [K in string]: boolean }>({
  defaultState: {},
  localKey: LocalKey.Groups,
  crossTabSync: true,
});
