import { ChangeMyPasswordDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useChangeMyPassword = () => {
  const [changeMyPasswordMutation, { loading }] = useSafeMutation(ChangeMyPasswordDocument);

  const changeMyPassword = useCallback(async (oldPassword: string, newPassword: string) => changeMyPasswordMutation({
    variables: {
      oldPassword,
      newPassword,
    },
    errorPolicy: 'ignore', // To remove once the api handle correctly the wrong mdp error
  }), [changeMyPasswordMutation]);

  return {
    changeMyPassword,
    loading,
  };
};
