import { ThemeType } from '@cycle-app/graphql-codegen';
import { ShyScrollbarCss, typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { keyframes } from 'styled-components';

import { FOOTER_HEIGHT } from 'src/components/MobileFooter/MobileFooter.styles';
import { HEADER_PRIMARY_HEIGHT } from 'src/components/MobileHeader/MobileHeader.styles';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.home.bg};
  ${ShyScrollbarCss}
  z-index: 0;

  ${mobile} {
    padding-top: ${HEADER_PRIMARY_HEIGHT}px;
    padding-bottom: ${FOOTER_HEIGHT}px;
    ${ShyScrollbarCss}
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  padding-bottom: 80px;

  ${mobile} {
    gap: 16px;
    overflow: visible;
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

export const Background = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 25%);
  z-index: -1;
  opacity: ${p => (p.theme.isDark ? 1 : 0.6)};

  width: 100%;
  height: calc(100vw / 3);
  min-height: 300px;

  background-image: ${p => (p.theme.themeType === ThemeType.Nightfall
    ? 'url("/images/home-gradient-dark.svg")'
    : 'url("/images/home-gradient-light.svg")')};

  background-repeat: no-repeat;
  background-position: center;
  background-size: 200% 200%;
  animation: ${gradientAnimation} 15s ease infinite;
  filter: blur(40px);
`;

export const ChangeLogContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;

  ${mobile} {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ChangeLogLink = styled.a`
  ${typo.caption400}
  display: inline-block;
  color: ${p => p.theme.colors.text.secondary};

  svg {
    margin-left: 6px;
    vertical-align: middle;
  }
`;
