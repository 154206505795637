import { typo, SelectPanel } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledSelectPanel = styled(SelectPanel)`
  min-width: 300px;
  max-width: 500px;
`;

export const AddView = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 8px;
  svg  {
    margin: 0 2px;
  }
`;

export const Option = styled.span`
  color: ${p => p.theme.colors.text.secondary};
`;

export const Name = styled.span`
  color: ${p => p.theme.colors.text.primary};
`;

export const SectionName = styled.span`
  ${typo.caption400}
  margin-left: 8px;
`;
