import { ChangelogBuilderContentLogo } from './ChangelogBuilderContentLogo';
import { ChangelogBuilderContentNote } from './ChangelogBuilderContentNote';
import { ChangelogBuilderContentNoteBody } from './ChangelogBuilderContentNoteBody';
import { ChangelogBuilderContentNoteTags } from './ChangelogBuilderContentNoteTags';
import { ChangelogBuilderContentNoteTitle } from './ChangelogBuilderContentNoteTitle';
import { ChangelogBuilderContentRoot } from './ChangelogBuilderContentRoot';
import { ChangelogBuilderContentSocial } from './ChangelogBuilderContentSocial';
import { ChangelogBuilderContentSubtitle } from './ChangelogBuilderContentSubtitle';
import { ChangelogBuilderContentTitle } from './ChangelogBuilderContentTitle';

export const ChangelogBuilderContent = () => {
  return (
    <ChangelogBuilderContentRoot>
      <ChangelogBuilderContentLogo />
      <ChangelogBuilderContentTitle />
      <ChangelogBuilderContentSubtitle />
      <ChangelogBuilderContentSocial />

      <ChangelogBuilderContentNote>
        <h2 className="mb-12">Release title</h2>
        <ChangelogBuilderContentNoteTags />
        <ChangelogBuilderContentNoteTitle />
        <ChangelogBuilderContentNoteBody />
        <div className="mt-20 h-px bg-[var(--dividerColor)]" />
      </ChangelogBuilderContentNote>
    </ChangelogBuilderContentRoot>
  );
};
