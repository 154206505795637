import { Doc } from 'yjs';

import { make } from 'src/utils/reactives.util';

interface DocState {
  docId: string | null;
  isEditorSyncing: boolean;
  yDoc: Doc | null;
}

/**
 * This reactive will store the current open doc in the tab, so we can store
 * data such as the syncing for the editor in order to make other components
 * aware of that to show a loading element somewhere else on the app.
 */
export const {
  hookValue: useGetDoc,
  getValue: getDoc,
  setValue: setDoc,
  resetValue: resetDoc,
} = make<DocState>({
  defaultState: {
    docId: null,
    isEditorSyncing: false,
    yDoc: null,
  },
});
