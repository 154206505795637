import { IntegrationBaseFragment, IntegrationType } from '@cycle-app/graphql-codegen';

import type { IntegrationsData } from 'src/constants/integrations.constants';

export const integrationTypes = [
  IntegrationType.Notion,
  IntegrationType.Linear,
  IntegrationType.Github,
] as const;

export type QuickIntegrationType = typeof integrationTypes[number];

export type QuickIntegrationItem = {
  integration: IntegrationBaseFragment;
  data: IntegrationsData;
  isInstalled: boolean;
};

export type QuickIntegrationsByType = Record<QuickIntegrationType, QuickIntegrationItem>;
