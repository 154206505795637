import { RadioInput, RadioInputDot, typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledRadioInput = styled(RadioInput)`
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey850 : p.theme.baseColors.Grey100)};
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 4px 4px transparent;
  transition: background-color .1s, border-color .1s, box-shadow .1s;

  & + & {
    margin-top: 16px;
  }

  label {
    padding: 20px;
  }

  ${RadioInputDot} {
    display: none;
  }

  &:has(:checked) {
    border-color: hsl(var(--cycle));
    background-color: transparent;

    ${p => p.theme.isDark && `
      box-shadow: 0px 0px 4px 4px rgba(43, 68, 231, 0.2);
    `}
  }

  /* Focus by keyboard, hover */
  &:has(:focus-visible), &:hover:not(:has(:checked)) {
    background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.colors.input.hover)};
  }
`;

export const Title = styled.div`
  ${typo.body500}
  margin-bottom: 8px;
  text-align: left;
`;

export const Price = styled.span`
  ${typo.headerLarge600}
  display: inline-block;
  margin-right: 8px;
`;

export const Badge = styled.span`
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.baseColors.Blue100)};
  border-radius: 4px;
  color: ${p => (p.theme.isDark ? p.theme.colors.text.disabled : 'hsl(var(--cycle))')};
  display: inline-block;
  margin-left: 8px;
  padding: 0px 4px 0px 4px;
`;

export const StyledButtonSubmit = styled(Button)`
  margin-top: 24px;
`;
