import { StateToaster } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

export const VersionToaster = styled(StateToaster)`
  background: ${ColorTheme.Cycle};
  white-space: nowrap;

  &, button {
    color: ${ColorTheme.White};
  }
`;
