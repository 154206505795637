import { ReactNode } from 'react';

import { EmptyStateIllustration } from './EmptyStateIllustration';
import * as EmptyStateStyles from './ReleaseEmptyState.styles';

export const ReleaseEmptyState = ({
  children, withHeight,
}: { children: ReactNode; withHeight?: boolean }) => {
  return (
    <EmptyStateStyles.EmptyStateContainer $withHeight={withHeight}>
      <EmptyStateIllustration />

      <EmptyStateStyles.EmptyStateTitle>
        Releases
      </EmptyStateStyles.EmptyStateTitle>

      <EmptyStateStyles.EmptyStateDescription>
        Create releases to communicate with customers, prospects and team. Just add your first release date and start documenting your release notes.
      </EmptyStateStyles.EmptyStateDescription>

      {children}
    </EmptyStateStyles.EmptyStateContainer>
  );
};
