import { useEffect, useRef } from 'react';

import { CommentEditor } from 'src/components/Editor';

import { Content } from './VerifyQuoteModal.styles';

export const QuoteContentReadonly = ({
  content, onClick,
}: {
  content: string;
  onClick: VoidFunction;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isPenAdded = useRef(false);

  // Add a pen icon to the end of the last paragraph
  useEffect(() => {
    if (isPenAdded.current) return;
    const img = document.createElement('img');
    img.src = '/images/pen.svg';
    img.style.verticalAlign = 'middle';
    img.style.marginLeft = '6px';
    const paragraphs = ref.current?.querySelectorAll('.ProseMirror p');
    if (!paragraphs) return;
    const lastParagraph = paragraphs[paragraphs.length - 1];
    lastParagraph?.appendChild(img);
    isPenAdded.current = true;
  }, []);

  return (
    <Content
      ref={ref}
      onClick={onClick}
    >
      <CommentEditor
        isReadOnly
        content={content}
      />
    </Content>
  );
};
