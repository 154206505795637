import { SelectLine } from '@cycle-app/ui';
import { FC } from 'react';

interface Props {
  label?: string;
}

export const IntegrationEmptyStateLine: FC<React.PropsWithChildren<Props>> = ({ label = 'Type to search issues… (min 3 characters)' }) => (
  <SelectLine
    label={label}
    isDisabled
  />
);
