import {
  typo, card, Button, ActionButton, boxShadowZ1, SelectLine,
} from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Modal = styled(PortalModal)`
  width: 690px;
  padding: 64px 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

type ModalTitleProps = {
  $hasPaddingBottom: boolean;
};

export const ModalTitle = styled.h4<ModalTitleProps>`
  ${typo.headerLarge600}
  color: ${p => p.theme.colors.text.primary};
  margin-bottom: ${p => (p.$hasPaddingBottom ? '40px' : 0)};
  text-align: center;
`;

export const ModalDescription = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 16px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 356px;
`;

export const ModalItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  background-color: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey100)};

  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    background-color: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.White)};
    border-radius: 8px;
    color: ${p => p.theme.colors.text.primary};
  }

  > div:last-child {

    h5 {
      ${typo.body500}
      color: ${p => p.theme.colors.text.primary};
    }

    p {
      ${typo.body400}
      color: ${p => p.theme.colors.text.secondary};
    }
  }

  &:nth-of-type(1) div:first-child svg {
    color: transparent;
    stroke: ${p => p.theme.colors.text.primary};
  }
  &:nth-of-type(2) div:first-child svg {
    color: transparent;
    stroke: ${p => p.theme.colors.text.primary};
  }
`;

export const StatusItem = styled.div`
  ${card}
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 11px;
  padding-right: 25px;
  width: 250px;

  &:nth-of-type(1) {
    transform: translateX(5px);
    z-index: 10;
  }
  &:nth-of-type(2) {
    transform: translateX(-5px) rotate(2deg);
    margin-top: -6px;
    z-index: 9;
  }
  &:nth-of-type(3) {
    transform: translateX(5px) rotate(-2deg);
    margin-top: -6px;
    z-index: 8;
  }
  &:nth-of-type(4) {
    transform: translateX(-5px) rotate(2deg);
    margin-top: -6px;
    z-index: 7;
  }
  &:nth-of-type(5) {
    transform: translateX(5px) rotate(-2deg);
    margin-top: -6px;
    margin-bottom: 50px;
    z-index: 6;
  }
`;

export const StatusText = styled.span`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const StatusDescription = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
  margin-bottom: 32px;
`;

export const SelectButton = styled(Button).attrs({
  variant: 'outlined',
  size: 'L',
})`
  display: flex;
  padding: 8px 12px;
  width: 100%;
  height: 40px;
  justify-content: flex-start;
  color: ${p => p.theme.colors.text.primary};
  background-color: ${p => p.theme.colors.input.bgAlt};
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 4px;
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    cursor: wait;
  }
  > span > span {
    display: flex;
    overflow: hidden;
    flex-grow: 1;
  }
`;

export const LinearButton = styled(Button).attrs({
  variant: 'outlined-alt',
  size: 'L',
})`
  width: 100%;
  height: 32px;
  ${boxShadowZ1}
`;

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
  flex: none;
`;

export const PanelContainer = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  ${p => p.theme.isDark && `
    background-color: ${p.theme.colors.background.secondary};
    border: 1px solid ${p.theme.colors.border.primary};
  `}
`;

export const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 236px;
`;

export const TemplatePreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 450px;
  height: 280px;
  border-radius: 6px;
  overflow: hidden;
`;

export const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  border-radius: 20px;
  background-color: ${p => p.theme.colors.background.primary};
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.15), 0px 3px 4px -3px rgba(0, 0, 0, 0.15);

  > svg {
    width: 54px;
    height: 54px;
  }
`;

export const IconsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 0 24px;
`;

export const CaretContainer = styled.div`
  width: 16px;
  height: 16px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const StyledSelectLine = styled(SelectLine)`
  border-radius: 4px;
`;
