import { HIGHLIGHT_DATA_ID } from '@cycle-app/editor-extensions';
import { DocLinkDataFragment } from '@cycle-app/graphql-codegen';
import { getHighlightHash } from '@cycle-app/utilities';
import { Editor, JSONContent } from '@tiptap/core';
import { useEditor } from '@tiptap/react';
import { useState, useRef, FC } from 'react';

import { fullEditorExtensions } from 'src/editorExtensions/editorExtensions';
import { useOptimizedBooleanState } from 'src/hooks';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useNavigate } from 'src/hooks/useNavigate';

import {
  Container,
  FeedbackCard,
  Header,
  Body,
  Title,
  Caret,
  CaretButton,
  StyledReadOnlyEditor,
} from './FeedbackPreviewSection.styles';

type FeedbackPreviewSectionProps = {
  docSource: DocLinkDataFragment;
  isLoading: boolean;
  blockId?: string | null;
  docId: string;
};

export const FeedbackPreviewSection: FC<React.PropsWithChildren<FeedbackPreviewSectionProps>> = ({
  docSource,
  isLoading,
  blockId,
  docId,
}) => {
  const { navigateToDocFullPage } = useNavigate();

  if (isLoading) return null;

  return (
    <Container>
      <FeedbackCard
        onClick={() => navigateToDocFullPage({
          ...docSource,
          ...(blockId && {
            hash: getHighlightHash({
              docId,
              blockId,
            }),
          }),
        })}
      >
        <DocContentBody
          docId={docSource.id}
          blockId={blockId}
          title={docSource.title}
        />
      </FeedbackCard>
    </Container>
  );
};

type DocContentBodyProps = {
  blockId?: string | null;
  docId: string;
  title: string;
};

const DocContentBody: FC<React.PropsWithChildren<DocContentBodyProps>> = ({
  docId, blockId, title,
}) => {
  const {
    yDoc, provider,
  } = useRealtimeEditorConfig({ docId });
  const collaboration = yDoc ? { document: yDoc } : undefined;
  const [content, setContent] = useState<JSONContent | null>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [isExpand, { toggleCallback }] = useOptimizedBooleanState(!!blockId);
  const { isEnabled: isDnDEnabled } = useFeatureFlag('dnd-editor-node');

  useEditor({
    immediatelyRender: true,
    onUpdate: (attrs) => {
      setContent(attrs.editor.getJSON());
      if (provider?.synced) {
        provider.destroy();
      }
    },
    extensions: fullEditorExtensions({
      collaboration: { document: yDoc },
      disabledActions: [],
      onPastedFiles: () => new Promise(() => { }),
      onUserMentioned: () => undefined,
      userColor: '',
      isDnDEnabled,
    }),
  }, [collaboration?.document.clientID]);

  const handleEditorScroll = (editor: Editor) => {
    if (!blockId) return;
    const block = editor.view.dom.querySelector<HTMLElement>(`[${HIGHLIGHT_DATA_ID}="${blockId}"]`);
    // shy scroll bar
    const contentEditorScroll = bodyRef.current?.querySelector<HTMLElement>('.content-editor');
    if (contentEditorScroll && block) {
      // scrollIntoView scrolls the whole viwport.
      contentEditorScroll.scrollTop = block.offsetTop - contentEditorScroll.offsetTop - contentEditorScroll.offsetHeight / 2;
    }
  };

  return (
    <>
      <Header>
        <CaretButton
          $isRotated={isExpand}
          onClick={(e) => {
            e.stopPropagation();
            toggleCallback();
          }}
          variant="nospace"
        >
          <Caret />
        </CaretButton>
        <Title>{title}</Title>
      </Header>
      <Body
        ref={bodyRef}
        onClick={e => e.stopPropagation()}
      >
        {isExpand && content && (
          <StyledReadOnlyEditor
            $blockId={blockId}
            content={content}
            onEditorReady={handleEditorScroll}
          />
        )}
      </Body>
    </>
  );
};
