import { typo, boxShadowZ3, boxShadowZ4 } from '@cycle-app/ui';
import { Container as CardContainer } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';
import styled from 'styled-components';

import { RIGHT_PANEL_WIDTH } from 'src/constants/docPanel.constants';

export const Container = styled.div<{ $isCollapsed: boolean }>`
  flex: none;
  padding: 12px 24px 24px 24px;
  width: ${RIGHT_PANEL_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  ${p => p.$isCollapsed && `
    padding: 12px 24px 8px 24px;
  `}
`;

export const Header = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.disabled};
  padding: 8px 12px;
  margin: -8px -12px;
  border-radius: 6px;
  ${p => p.$isCollapsed && `
    cursor: pointer;
    :hover {
      background: ${p.theme.colors.background.hoverSoft};
    }
  `}
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.body500}
  user-select: text;
  > svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const Count = styled.div`
  color: ${p => p.theme.colors.text.disabled};
  ${typo.body400}
`;

export const Cards = styled.div`
  &, * {
    cursor: pointer !important;
  }

  > :nth-child(1) {
    ${boxShadowZ3}
  }

  > :nth-child(2) {
    height: 50px;
    width: calc(100% - 20px);
    left: 10px;
    margin-top: -42px;
    z-index: -1;
    box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
  }
  
  > :nth-child(3) {
    height: 50px;
    width: calc(100% - 40px);
    left: 20px;
    margin-top: -42px;
    z-index: -2;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }

  ${p => p.theme.isDark && `
    > :nth-child(1) {
      ${boxShadowZ4}
    }
  
    > :nth-child(2) {
      box-shadow: 0px 5.7px 14.25px 0px rgba(0, 0, 0, 0.2);
    }
    
    > :nth-child(3) {
      box-shadow: 0px 5.4px 13.5px 0px rgba(0, 0, 0, 0.2);
    }
  `}
`;

export const Card = styled(CardContainer).attrs({ $withBorder: true })`
  height: 100px;
  cursor: unset !important;
`;

export const EmptyContainer = styled.div`
  position: absolute;
  inset: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.caption400}
  p:first-child {
    color: ${p => p.theme.colors.text.primary};
    ${typo.caption500}
  }
`;
