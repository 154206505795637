import { boxShadowZ1, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';

export const Buttons = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: flex;
  gap: 4px;
`;

export const Button = styled(ActionButton)`
  ${boxShadowZ1}
  background-color: ${p => p.theme.colors.background.primary};
  &:hover {
    background-color: ${p => p.theme.colors.actionButton.bgHover};
  }
`;

export const Container = styled.div`
  position: relative;
  &:not(:hover) ${Button} {
    display: none;
  }
`;

export const StyledCustomerCompanyAction = styled(CustomerCompanyAction)`
  --bg: ${p => p.theme.colors.actionButton.bgHover};
  border-radius: 50%;
  height: var(--customer-company-logo-size);
  min-height: auto;
  width: 100%;

  svg {
    height: var(--customer-company-logo-icon-size);
    width: var(--customer-company-logo-icon-size);
  }
`;
