import { truncate, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

export const Container = styled.li`
  background-color: ${p => p.theme.colors.background.secondaryElevated};
  padding-left: 16px;
  padding-right: 16px;
  height: 52px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  gap: 16px;
  overflow: hidden;
`;

export const Text = styled.div`
  ${truncate}
  ${typo.body500}
`;

export const Action = styled.div`
  margin-left: auto;
`;
