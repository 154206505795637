import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { closeLinearModal, setLinearImportNextStep, useGetLinearImportModal } from 'src/reactives/linearImport.reactive';
import { Layer } from 'src/types/layers.types';

import { IntegrationLinearAutomation } from '../IntegrationLinearAutomation';
import { LinearAutomationsDescription } from '../IntegrationLinearAutomationModal';
import { LinearImportHeader } from './LinearImportHeader';
import { LinearImportIssues } from './LinearImportIssues';
import { Modal, CloseButton, Separator, Scrollable, Content } from './LinearImportModal.styles';
import { LinearImportProjects } from './LinearImportProjects';
import { LinearOnboardingIntro } from './LinearOnboardingIntro';

export const LinearImportModal = () => {
  const { currentStep } = useGetLinearImportModal();
  if (currentStep === null) return null;
  return (
    <Modal
      hide={closeLinearModal}
      layer={Layer.Modal}
      $autoFit={currentStep === 'intro'}
    >
      <CloseButton onClick={closeLinearModal}>
        <CloseIcon />
      </CloseButton>
      {currentStep === 'intro' && <LinearOnboardingIntro />}
      {currentStep === 'projects' && <LinearImportProjects />}
      {currentStep === 'issues' && <LinearImportIssues />}
      {currentStep === 'automations' && <LinearAutomations />}
    </Modal>
  );
};

const LinearAutomations = () => (
  <Content>
    <LinearImportHeader />
    <Separator />
    <LinearAutomationsDescription />
    <Scrollable>
      <IntegrationLinearAutomation />
    </Scrollable>
    <Button size="M" onClick={setLinearImportNextStep}>Done</Button>
  </Content>
);
