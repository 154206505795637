import { ring2 } from '@cycle-app/ui';
import styled from 'styled-components';

import { CommentEditor } from 'src/components/Editor/Editors/CommentEditor';
import MyAvatar from 'src/components/MyAvatar/MyAvatar';

export const Container = styled.div`
  margin-top: auto;
  padding: 16px;
`;

export const Content = styled.div<{ $focused: boolean }>`
  ${ring2}
  --ring-color: ${p => (p.$focused ? 'hsla(var(--cycle), 0.5)' : 'transparent')};
  
  border-radius: 4px;

  background-color: ${p => (p.$focused ? 'inherit' : p.theme.colors.input.bg)};
  :hover {
    background-color: ${p => (p.$focused ? 'inherit' : p.theme.colors.input.hover)};
  }
`;

export const AddComment = styled.div`
  margin-top: auto;
  display: flex;
  word-break: break-word;
  border-radius: 4px;
`;

export const StyledMyAvatar = styled(MyAvatar)`
  padding: 8px;
`;

export const StyledCommentEditor = styled(CommentEditor)`
  width: 100%;
  overflow: hidden;
  .ProseMirror {
    padding: 6px;
    padding-left: 0;
    cursor: text;
    p {
      padding: 0;
    }
  }
`;

export const Buttons = styled.div`
  padding: 3px 6px 6px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
