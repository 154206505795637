import { Container, ContainerProps } from './NewBadge.styles';

type NewBadgeProps = {
  size?: ContainerProps['$size'];
  className?: string;
};

export const NewBadge = ({
  size = 'S', className,
}: NewBadgeProps) => (
  <Container
    $size={size}
    className={className}
  >
    <p>New</p>
    <span>New</span>
  </Container>
);
