import { StatusCategory } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $category: StatusCategory;
  $withBackground?: boolean;
  $size: number;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.components.FeedbackStatus[p.$category].main};

  ${p => p.$withBackground && css`
    border-radius: 4px;
    width: ${p.$size}px;
    height: ${p.$size}px;
    background-color: ${p.theme.colors.components.FeedbackStatus[p.$category].bg};
  `}
`;
