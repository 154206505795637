import { CloseIcon } from '@cycle-app/ui/icons';

import {
  useGetOnboardingSlack,
  stopOnboardingSlack,
  useGetPermission,
  OnboardingSlackStateValue,
} from 'src/reactives';
import { Layer } from 'src/types/layers.types';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';

import { Modal, CloseButton } from './OnboardingSlack.styles';
import { StepCreateFeedback } from './StepCreateFeedback';
import { StepFeedbackCreated } from './StepFeedbackCreated';
import { StepInstall } from './StepInstall';
import { StepReacji } from './StepReacji';
import { useWorkspaceContext } from '../../contexts/workspaceContext';

export const OnboardingSlack = () => {
  const { canReadSettings } = useGetPermission();
  const productId = useWorkspaceContext(ctx => ctx.productId);
  // Use the hook here to make sure the localstorage is migrated.
  const onboarding = useGetOnboardingSlack(productId);

  if (!canReadSettings) return null;

  return (
    <OnboardingSlackModal
      productId={productId}
      onboarding={onboarding}
    />
  );
};

const OnboardingSlackModal = ({
  onboarding,
  productId,
}: { onboarding?:  OnboardingSlackStateValue; productId: string }) => {
  const { step } = onboarding || {};
  if (!step) return null;

  return (
    <Modal
      layer={Layer.ModalZ3}
      hide={() => stopOnboardingSlack(productId)}
    >
      <CloseButton onClick={() => stopOnboardingSlack(productId)}>
        <CloseIcon size={12} />
      </CloseButton>
      <OnboardingSlackContent step={step} />
    </Modal>
  );
};

type OnboardingSlackContentProps = {
  step: OnboardingSlackStep;
};

const OnboardingSlackContent = ({ step }: OnboardingSlackContentProps) => {
  switch (step) {
    case OnboardingSlackStep.ExtractInsight: // retro-compat
    case OnboardingSlackStep.FeedbackCreated:
      return <StepFeedbackCreated />;
    case OnboardingSlackStep.Reactji:
      return <StepReacji />;
    case OnboardingSlackStep.CreateFeedback:
      return <StepCreateFeedback />;
    case OnboardingSlackStep.Install:
    default:
      return <StepInstall />;
  }
};
