import { SetYcDealDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import useSafeMutation from '../useSafeMutation';

export const useProductYcDealUpdate = () => {
  const [setYcDealMutation, { loading: isLoading }] = useSafeMutation(SetYcDealDocument);
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const enableYcDeal = () => setYcDealMutation({
    variables: {
      productId,
    },
  });

  return {
    enableYcDeal,
    isLoading,
  };
};
