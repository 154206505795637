import styled, { css } from 'styled-components';

type ContainerProps = { $collapsed: boolean };

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${p => p.$collapsed && css`
    > div {
      width: 32px;
      height: 32px;
    }
  `}
`;

export const LearningCenterContainer = styled.div`
  position: relative;
`;
