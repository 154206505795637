import { FileIcon, MediaIcon, TxtIcon, VttIcon } from '@cycle-app/ui/icons';

import { MEDIA_EXTENSIONS, MEDIA_MIME_TYPES } from 'src/constants/dropzone.constants';

export type ImportFileIconProps = {
  mimeType?: string | null;
  extension?: string | null;
  size?:
  | 'S' // used doc source tag
  | 'M'; // used in dropzone card
};

/**
 * Display the right icon for a file imported with the dropzone
 * It can be determined by the mimeType of a dropped files
 * or by the extension (from the doc source url returned by the API)
 */
export const ImportFileIcon = ({
  mimeType, extension, size = 'M',
}: ImportFileIconProps) => {
  if (extension?.toLowerCase() === 'txt') return <TxtIcon size={size === 'M' ? 19 : 12} />;
  if (extension?.toLowerCase() === 'vtt') return <VttIcon size={size === 'M' ? 17 : 10} />;
  if (
    (mimeType && MEDIA_MIME_TYPES.some(s => mimeType.includes(s))) ||
    (extension && MEDIA_EXTENSIONS.includes(extension.toLowerCase()))
  ) return <MediaIcon size={size === 'M' ? 19 : 12} />;
  return <FileIcon size={size === 'M' ? 16 : 11} />;
};
