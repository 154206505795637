import { FC } from 'react';

import googleMeet from './google-meet.png';

type Props = {
  className?: string;
  size?: number;
};

export const GoogleMeetIcon: FC<React.PropsWithChildren<Props>> = ({ className }) => (
  <img className={className} width={16} src={googleMeet} alt="Google Meet" />
);
