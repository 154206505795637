import { AddFeatureDocument, AddFeatureMutationVariables, RemoveFeatureDocument, RemoveFeatureMutationVariables } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { addToaster } from 'src/utils/toasters.utils';

export const useFeatureFlagUpdate = () => {
  const product = useProductBase();
  const [activate, { loading: activateLoading }] = useSafeMutation(AddFeatureDocument, {
    errorPolicy: 'all',
    displayErrorMessages: true,
    onCompleted: () => addToaster({ message: 'Feature activated! 👌' }),
  });
  const [deactivate, { loading: deactivateLoading }] = useSafeMutation(RemoveFeatureDocument, {
    errorPolicy: 'all',
    displayErrorMessages: true,
    onCompleted: () => addToaster({ message: 'Feature deactivated! 👌' }),
  });

  const activateFeature = useCallback(async (
    feature: AddFeatureMutationVariables['feature'],
  ) => {
    if (!product) return false;
    const response = await activate({
      variables: {
        productId: product.id,
        feature,
      },
    });
    return response.data?.addFeature?.features;
  }, [product, activate]);

  const deactivateFeature = useCallback(async (
    feature: RemoveFeatureMutationVariables['feature'],
  ) => {
    if (!product) return false;
    const response = await deactivate({
      variables: {
        productId: product.id,
        feature,
      },
    });
    return response.data?.removeFeature?.features;
  }, [product, activate]);

  return {
    activateFeature,
    deactivateFeature,
    loading: activateLoading || deactivateLoading,
  };
};
