import { ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import { ActionButton, typo, Skeleton } from '@cycle-app/ui';
import styled from 'styled-components';

import { ChangelogTagStyle, ChangelogTagStyleProps } from '../ChangelogTag/ChangelogTag.styles';

export const ListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

interface ButtonProps extends ChangelogTagStyleProps {
  $isReadonly?: boolean;
}

export const TagItem = styled.li<{ $tagStyle: ReleaseTagStyle }>`
  overflow: hidden;
  border-radius: 4px;
  max-width: 100%;

  ${p => p.$tagStyle === ReleaseTagStyle.Background && `
    background-color: var(--layerBg);
  `}
`;

export const Tag = styled.button<ButtonProps>`
  ${typo.caption400}
  ${ChangelogTagStyle}
  height: 20px;
  overflow: hidden;

  ::before {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${p => p.$tagStyle === ReleaseTagStyle.Dot && `
    /* Do not use the changelog color, it may not look good on dark/light */
    color: ${p.theme.colors.text.primary};
  `}

  cursor: ${p => (p.$isReadonly ? 'default' : 'pointer')};
`;

export const StyledSkeleton = styled(Skeleton)`
  flex: none;
  width: 40px;
`;

export const AddButton = styled(ActionButton)`
  ${typo.caption400}
  --bg: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.baseColors.Grey100)};
  --color: ${p => p.theme.colors.text.secondary};
  border-radius: 4px;
  gap: 4px;
  height: 20px;
  padding: 3px 4px 3px 4px;

  svg {
    flex: none;
    color: ${p => p.theme.colors.text.disabled};
  }
`;
