import { nodeToArray } from '@cycle-app/utilities';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useProduct } from 'src/hooks';
import { useGetDoctypeFromCache } from 'src/hooks/api/cache/cacheDoctype';

export const useSortedDocTypesFromCache = (ids?: string[], currentDocTypeId?: string) => {
  const getDocType = useGetDoctypeFromCache();
  return useMemo(() => {
    const docTypes = ids
      ?.filter(id => id !== currentDocTypeId)
      .map(getDocType).filter(isPresent);
    const sortedDocTypes = sortBy(docTypes, 'name');
    if (!currentDocTypeId) return sortedDocTypes;
    const currentDocType = getDocType(currentDocTypeId);
    return currentDocType ? [currentDocType, ...sortedDocTypes] : sortedDocTypes;
  }, [ids, getDocType, currentDocTypeId]);
};

export const useSortedDocTypes = () => {
  const { product } = useProduct();
  return useMemo(() => {
    const docTypes = nodeToArray(product?.doctypes);
    return sortBy(docTypes, 'name');
  }, [product?.doctypes]);
};
