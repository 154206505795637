import { ThemeType } from '@cycle-app/graphql-codegen';
import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 8px
`;

export const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 24px;
  appearance: none;
  border: none;
  background: transparent;
  color: ${p => p.theme.colors.text.primary};
  ${typo.body400}

  ${p => p.theme.themeType === ThemeType.Nightfall && `
    font-weight: 500;
  `};

  :focus-visible {
    outline: none;
  }
`;

export const DropdownContainer = styled.div`
  width: 500px;
  padding: 8px 8px 0 8px;
`;

export const StyledButton = styled(Button)`
  --radius: 4px;
  padding: 1px 8px;
  font-weight: 600;
`;
