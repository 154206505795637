import { Emoji, EmojiPicker } from '@cycle-app/ui';
import { NextArrowIcon } from '@cycle-app/ui/icons';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { useSlackReacji } from 'src/hooks/slack/useSlackReacji';
import { useSlackReacjiUpdate } from 'src/hooks/slack/useSlackReacjiUpdate';
import { setOnboardingSlackStep } from 'src/reactives';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';

import { Container, Title } from '../OnboardingSlack.styles';
import { EmojiBox, StyledFooter, StyledNextButton, StyledSlackMessage, SubTitle } from './StepReacji.styles';

export const StepReacji = () => {
  const { isInstalled } = useSlackIntegration();
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { emoji } = useSlackReacji();
  const { reacjiUpdate } = useSlackReacjiUpdate();
  if (!isInstalled) {
    setOnboardingSlackStep(productId, OnboardingSlackStep.Install);
    return null;
  }
  return (
    <Container>
      <Title>Create feedback with reacjis</Title>
      <SubTitle>
        Just react to a Slack message with your favorite emoji and voila!
        <br />
        {' '}
        Your Cycle feedback has been submitted.
      </SubTitle>
      {emoji && <StyledSlackMessage emoji={emoji} />}
      <EmojiBox>
        <Emoji emoji={emoji} size={48} />
        <EmojiPicker
          size="M"
          variant="outlined-alt"
          dropdownProps={{ placement: 'bottom' }}
          onSelect={value => {
            if ('id' in value && !!value.id) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              reacjiUpdate(value.id);
            }
          }}
        >
          Change emoji
        </EmojiPicker>
      </EmojiBox>
      <StyledFooter>
        <StyledNextButton onClick={() => setOnboardingSlackStep(productId, OnboardingSlackStep.CreateFeedback)}>
          Next
          <NextArrowIcon />
        </StyledNextButton>
      </StyledFooter>
    </Container>
  );
};
