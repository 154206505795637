import { AddOn } from 'src/types/addOn.types';
import { PermissionAction } from 'src/types/permissionAction.types';
import { make } from 'src/utils/reactives.util';

const defaultState: {
  action: PermissionAction | null;
  brand: AddOn['name'] | null;
} = {
  action: null,
  brand: null,
};

type LimitationModalResult = typeof defaultState;

export const {
  getValue: getLimitationsModal,
  hookState: useLimitationsModal,
  hookValue: useLimitationsModalValue,
  resetValue: resetLimitationsModal,
  setValue: setLimitationsModal,
} = make<LimitationModalResult>({
  defaultState,
});
