import { Color } from '@cycle-app/graphql-codegen';
import { CSSProperties, FC, ReactNode } from 'react';

import {
  Container,
  UserName,
  AvatarContainer,
  Viewing,
} from './MemberCard.styles';
import { Avatar } from '../Avatar/Avatar';
import { Tooltip } from '../Tooltip/Tooltip';

export type MemberCardProps = {
  avatar?: string;
  color?: Color;
  email: string;
  mode: 'online' | 'offline' | 'pending';
  name?: string;
  viewing: ReactNode;
  onClick?: VoidFunction;
  style?: CSSProperties;
};

export const MemberCard: FC<React.PropsWithChildren<MemberCardProps>> = ({
  avatar,
  color = Color.A,
  email,
  mode,
  name,
  viewing,
  onClick = () => undefined,
  style,
}) => (
  <Container $color={color} $pending={mode === 'pending'} onClick={onClick} style={style}>
    <AvatarContainer>
      <Tooltip
        placement="top"
        content={(
          <>
            <UserName>{name}</UserName>
            <Viewing>{viewing}</Viewing>
          </>
        )}
      >
        <Avatar
          user={{
            color,
            email,
            firstName: name,
          }}
          size={24}
          src={avatar}
          pending={mode === 'pending'}
        />
      </Tooltip>
    </AvatarContainer>
  </Container>
);
