import { Tag, typo } from '@cycle-app/ui';
import styled from 'styled-components';

import DialogModal from 'src/components/DialogModal/DialogModal';

export const OptionTag = styled.span`
  ${typo.body500}
`;

export const AttributeTagStyled = styled(Tag)`
  ${typo.body500}
  > div {
    padding: 0;
  }
`;

export const AttributeTagIconContainer = styled.div`
  svg {
    vertical-align: text-top;
  }
`;

export const StyledDialogModal = styled(DialogModal)`
  width: 500px;
`;
