import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { addFakeAttributes } from '@cycle-app/front/tests/fakes/customerAttributes';
import { SearchProductCompaniesDocument } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';

import { defaultCompaniesPagination } from 'src/utils/pagination.util';

interface Options {
  defaultSearch?: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
  productId?: string;
}

export const useCompanies = (options?: Options) => {
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(SearchProductCompaniesDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !options?.productId || options?.skip,
    variables: {
      id: options?.productId ?? '',
      ...options?.defaultSearch && { searchText: options.defaultSearch },
      ...defaultCompaniesPagination,
    },
  });

  const companies = useMemo(() => {
    if (data?.node?.__typename !== 'Product') return [];
    if (addFakeAttributes) {
      return nodeToArray(data.node.companies).map(addFakeAttributes);
    }
    return nodeToArray(data.node.companies);
  }, [data]);

  const pageInfo = data?.node?.__typename === 'Product' ? data.node.companies.pageInfo : null;

  const fetchNextPage = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return;
    await fetchMore({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
  }, [pageInfo, fetchMore]);

  return {
    companies,
    isLoading: loading,
    fetchNextPage,
    pageInfo,
    refetch,
  };
};
