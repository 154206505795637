import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const H3Icon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M0 12.5938V3.40613H1.66437V7.29565H5.92176V3.40613H7.59062V12.5938H5.92176V8.69085H1.66437V12.5938H0Z" fill="#171717" />
    <path d="M12.6084 12.7194C11.9624 12.7194 11.3882 12.6088 10.8857 12.3875C10.3863 12.1661 9.99147 11.8581 9.70136 11.4633C9.41126 11.0685 9.25723 10.6124 9.23929 10.095H10.9261C10.941 10.3433 11.0233 10.5601 11.1728 10.7455C11.3224 10.928 11.5213 11.07 11.7695 11.1717C12.0177 11.2734 12.2959 11.3242 12.6039 11.3242C12.9329 11.3242 13.2245 11.2674 13.4787 11.1538C13.7329 11.0371 13.9318 10.8756 14.0754 10.6693C14.2189 10.4629 14.2892 10.2251 14.2862 9.95595C14.2892 9.67781 14.2175 9.43257 14.0709 9.22022C13.9244 9.00787 13.712 8.84189 13.4339 8.72225C13.1587 8.60262 12.8267 8.54281 12.4379 8.54281H11.6259V7.25976H12.4379C12.7579 7.25976 13.0376 7.20443 13.2769 7.09377C13.5191 6.98311 13.709 6.82759 13.8466 6.62721C13.9842 6.42383 14.0515 6.18906 14.0485 5.92288C14.0515 5.66268 13.9931 5.43687 13.8735 5.24546C13.7569 5.05106 13.5909 4.90003 13.3755 4.79236C13.1632 4.68469 12.9135 4.63086 12.6264 4.63086C12.3452 4.63086 12.085 4.6817 11.8458 4.78339C11.6065 4.88507 11.4136 5.03013 11.267 5.21855C11.1205 5.40398 11.0427 5.62529 11.0338 5.8825H9.43219C9.44416 5.36809 9.5922 4.91648 9.87633 4.52768C10.1634 4.13588 10.5463 3.83082 11.0248 3.6125C11.5033 3.39118 12.0402 3.28052 12.6353 3.28052C13.2484 3.28052 13.7808 3.39566 14.2324 3.62595C14.687 3.85325 15.0384 4.15981 15.2867 4.54562C15.5349 4.93143 15.659 5.35762 15.659 5.82418C15.662 6.34159 15.5095 6.77525 15.2014 7.12517C14.8964 7.4751 14.4956 7.70389 13.9991 7.81156V7.88334C14.6451 7.97306 15.1401 8.21232 15.4841 8.60113C15.831 8.98694 16.003 9.46696 16 10.0412C16 10.5556 15.8534 11.0162 15.5603 11.4229C15.2702 11.8267 14.8694 12.1437 14.358 12.374C13.8496 12.6043 13.2664 12.7194 12.6084 12.7194Z" fill="#171717" />
  </Svg>
);
