import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Modal = styled(PortalModal)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  width: 450px;
  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
  }
`;

export const Title = styled.div`
  ${typo.headerLight}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
`;
