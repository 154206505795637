import { TrashIcon, RefreshIcon, UnionIcon, LinkIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps } from '@tiptap/core';
import { FC } from 'react';

import { EditorDragMenu } from 'src/components/EditorDragMenu';
import { useEditorContext } from 'src/contexts/editorContext';
import { useUpdateDocAutomation } from 'src/hooks/doc/useUpdateDocAutomation';
import { AutomationType } from 'src/types/automation.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { isCustom } from 'src/utils/docType.util';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

interface Props {
  automationId: string | undefined;
  docId: string;
  editor: NodeViewRendererProps['editor'];
  getPos: NodeViewRendererProps['getPos'];
  node: NodeViewRendererProps['node'];
  onRefetch: VoidFunction;
  url: string | null | undefined;
  type: AutomationType;
}

export const EditorLinearDragMenu: FC<React.PropsWithChildren<Props>> = ({
  onRefetch, url, editor, getPos, node, automationId, type, docId,
}) => {
  const hasAutomation = useEditorContext(ctx => !!ctx.doc?.automationId);
  const isDoctypeCustom = useEditorContext(ctx => isCustom(ctx.doc?.doctype));
  const { updateDocAutomation } = useUpdateDocAutomation();
  return (
    <EditorDragMenu
      tooltip={{
        content: 'Click to open menu',
        title: 'Move to reorder',
      }}
      options={[
        ...(url && automationId && isDoctypeCustom && !hasAutomation ? [
          {
            label: `Link ${type}`,
            value: '',
            icon: <LinkIcon />,
            onSelect: () => updateDocAutomation(docId, url, automationId),
          },
        ] : []),
        ...(onRefetch ? [{
          label: 'Refresh',
          value: 'refresh',
          icon: <RefreshIcon />,
          onSelect: onRefetch,
        }] : []),
        ...(url ? [
          {
            label: 'Open',
            value: 'open',
            icon: <UnionIcon />,
            onSelect: () => window.open(url, '_blank'),
          },
          {
            label: 'Copy link',
            value: 'copy',
            icon: <DuplicateIcon />,
            onSelect: () => copyToClipboard({
              text: url,
              notification: 'Url copied to clipboard',
            }),
          },
        ] : []),
        {
          label: 'Delete',
          onSelect: () => deleteNodeRange({
            editor,
            node,
            getPos,
          }),
          value: 'delete',
          icon: <TrashIcon />,
          variant: 'danger',
        },
      ]}
    />
  );
};
