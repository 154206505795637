import { HIGHLIGHT_DATA_ID, HIGHLIGHT_TAG_NAME } from '@cycle-app/editor-extensions';
import { typo } from '@cycle-app/ui';
import styled, { css, keyframes } from 'styled-components';

import { DocAttributes } from 'src/components/DocAttributes';
import { MarkColor } from 'src/components/Editor/Editors/Editor.styles';
import EditorSkeletonEl from 'src/components/EditorSkeleton/EditorSkeleton';

const containerAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const Container = styled.div`
  min-height: 147px;
  max-height: calc(100vh - 147px);
  width: 567px;
  max-width: 40vw;
  opacity: 0;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  animation: ${containerAnimation} 0s 0.1s forwards;
`;

export const Header = styled.div`
  display: flex;
  gap: 4px;
  padding: 16px;
  padding-bottom: 0;
`;

export const Assignee = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  color: ${p => p.theme.colors.actionButton.color};
  ${typo.body400}
`;

export const Fullname = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Title = styled.h1`
  margin: 0;
  padding: 16px;
  outline: none;
  width: 100%;
  ${typo.headerLarge};
`;

export const StyledDocAttributes = styled(DocAttributes)`
  margin: 0 16px;
`;

interface ContentProps {
  $blockId?: string | null;
}

export const Content = styled.div<ContentProps>`
  overflow: hidden;
  padding: 16px;
  width: 100%;

  ${p => p.$blockId && css`
    ${HIGHLIGHT_TAG_NAME}:not([${HIGHLIGHT_DATA_ID}="${p.$blockId}"]) {
      pointer-events: none;
    }

    .ProseMirror ${HIGHLIGHT_TAG_NAME}[${HIGHLIGHT_DATA_ID}="${p.$blockId}"] {
      ${MarkColor}
    }
  `}
`;

export const EditorSkeleton = styled(EditorSkeletonEl)``;
