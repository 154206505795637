import styled from 'styled-components';

import DialogModal from 'src/components/DialogModal/DialogModal';

export const WsLogo = styled.img`
  width: 22px;
  height: 22px;
  min-width: 22px;
  border-radius: 5px;
  overflow: hidden;
`;

export const RemoveModalStyled = styled(DialogModal)`
  width: 548px;
`;
