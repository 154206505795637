import { make } from 'src/utils/reactives.util';

import { getSettingsModal } from './settingsModal.reactive';

export type MeetingsModalInstalledResult = {
  isOpened: boolean;
};

const {
  hookState: useMeetingsModalInstalled,
  hookValue: useGetMeetingsModalInstalled,
  getValue: getMeetingsModalInstalled,
  setValue,
  resetValue: resetMeetingsModalInstalled,
} = make<MeetingsModalInstalledResult>({
  defaultState: {
    isOpened: false,
  },
});

export const setMeetingsModalInstalled = (value: MeetingsModalInstalledResult) => {
  if (
    // The modal shows a cta to go to the recording settings.
    value.isOpened &&
    getSettingsModal().visible &&
    getSettingsModal().tab === 'recording'
  ) {
    return;
  }
  setValue(value);
};

export {
  useMeetingsModalInstalled,
  useGetMeetingsModalInstalled,
  getMeetingsModalInstalled,
  resetMeetingsModalInstalled,
};
