import { MotionProps } from 'framer-motion';

export const motionProps: MotionProps = {
  initial: {
    height: 0,
  },
  animate: {
    height: 'auto',
    overflow: 'clip',
    transitionEnd: {
      overflow: 'visible',
    },
  },
  exit: {
    height: 0,
    overflow: 'clip',
  },
  transition: {
    bounce: 0.2,
  },
};
