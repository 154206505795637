import { make } from 'src/utils/reactives.util';

export type PresenceResult = {
  memberIds: string[];
};

export const {
  hookState: usePresence,
  hookValue: useGetPresence,
  getValue: getPresence,
  setValue: setPresence,
  resetValue: resetPresence,
} = make<PresenceResult>({
  defaultState: {
    memberIds: [],
  },
});
