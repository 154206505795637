import { MAX_MONTHLY_AI_QUERIES } from 'src/constants/ai.constants';
import { useProductAddOn } from 'src/hooks/useProductAddOns';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';

export const useCheckMonthlyAiQueries = (nbAiQueries?: number) => {
  const addOnUnlimitedAiQueries = useProductAddOn('UNLIMITED_AI_QUERIES');

  const maxAiQueriesReached =
    !addOnUnlimitedAiQueries.isEnabled &&
    (nbAiQueries ?? 0) >= MAX_MONTHLY_AI_QUERIES;

  const openUnlimitedAiPrompt = () => {
    if (!maxAiQueriesReached) return;
    setLimitationsModal({
      action: 'USE_ADD_ON',
      brand: 'UNLIMITED_AI_QUERIES',
    });
  };

  return {
    maxAiQueriesReached,
    openUnlimitedAiPrompt,
  };
};
