import { typo, boxShadowZ3 } from '@cycle-app/ui';
import styled from 'styled-components';

import bg from './quote-bg.svg';

export const Container = styled.div`
  position: relative;
  margin: auto 56px;
  transform: translate(0, -30px);
  border-radius: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04);

  &::after {
    background-image: url("${bg}");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: contain;
    ${p => !p.theme.isDark && 'content: "";'}
    height: 83px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%,-36px);
    width: 120%;
    z-index: -1;
  }
`;

export const Content = styled.div`
  background-blend-mode: lighten;
  background: ${p => (p.theme.isDark
    ? 'linear-gradient(180deg, #404040 0%, #303030 100%)'
    : 'linear-gradient(269.66deg, rgba(255, 255, 255, 0) -14.58%, rgba(255, 255, 255, 0.82) 15.06%, rgba(255, 255, 255, 0.64726) 41.84%, rgba(255, 255, 255, 0) 99.71%)')};
  border-radius: 16px;
  ${p => (p.theme.isDark ? boxShadowZ3 : `
    box-shadow: 0px 0px 16px 2px rgba(255, 255, 255, 1) inset;
    border: 2px solid #FFF;
  `)}
  padding: 16px 16px 20px;
  transform: translate(0, 0);
`;

export const Author = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  gap: 12px;
  margin-top: 16px;
`;

export const Name = styled.div`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
`;
