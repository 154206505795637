import { SelectLine, ring2 } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { ImageSize } from '@cycle-app/utilities';
import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { DragHandle } from 'src/components/EditorDragMenu';
import { EditorTranscriptStatusState } from 'src/components/EditorTranscriptStatusState';
import { RELEASE_NOTE_EDITOR } from 'src/constants/releases.constants';

const WIDTH_BY_IMAGE_SIZE: Record<ImageSize, string> = {
  [ImageSize.SMALL]: '50%',
  [ImageSize.MEDIUM]: '75%',
  [ImageSize.LARGE]: '100%',
};

type ImageContainerProps = {
  $size?: ImageSize;
};

// Images are always displayed in full width in release notes
export const ImageContainer = styled.div<ImageContainerProps>`
  width: ${p => (p.$size ? WIDTH_BY_IMAGE_SIZE[p.$size] : '100%')};
  position: relative;
  .${RELEASE_NOTE_EDITOR} & {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Container = styled(NodeViewWrapper) <{
  $isSelected: boolean;
}>`
  display: flex;
  border-radius: 6px;
  flex-direction: column;

  ${(p) => p.$isSelected && css`
    ${ImageContainer} {
      img {
        ${ring2}
      }
    }
  `}

  position: relative;

  &:hover ${DragHandle} {
    display: flex;
  }

  &:hover .image-menu {
    opacity: 1;
  }

  li & {
    padding: 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: zoom-in;
  .${RELEASE_NOTE_EDITOR} & {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const MenuContent = styled.div`
  display: flex;
`;

export const SelectLineItem = styled(SelectLine)`
  width: 150px;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  margin-top: 4px;
`;

type EditorTranscriptStatusProps = {
  $size: ImageSize;
};

export const EditorTranscriptStatus = styled(EditorTranscriptStatusState) <EditorTranscriptStatusProps>`
  width: ${p => WIDTH_BY_IMAGE_SIZE[p.$size]};
`;
