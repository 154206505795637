const DOC_FULL_PAGE_URL_PATTERN = /\/app\/[\S]*\/doc\/[\S]*-[\S]*/g;

export function getAbsoluteUrl(path: string): string {
  return `${window.location.origin}${path}`;
}

export function getDocFullUrl(docUrl: string): string {
  return getAbsoluteUrl(docUrl);
}

export const HIGHLIGHT_HASH_PREFIX = 'doc-';

const highlightHashRegex = /^doc-(.*?)(?:-block-(.*))?$/;

export function getHighlightHashData(hash: string) {
  const matches = highlightHashRegex.exec(hash);
  return {
    docId: matches?.[1],
    blockId: matches?.[2],
  };
}

export function getHighlightHash({
  docId, blockId,
} : { docId: string; blockId?: string | null }) {
  return `${HIGHLIGHT_HASH_PREFIX}${docId}${blockId ? `-block-${blockId}` : ''}`;
}

export function getDocIdFromUrl(url: string): string | undefined {
  const isDocLink =
    url.startsWith(window.location.origin) &&
    !!url.match(DOC_FULL_PAGE_URL_PATTERN);

  if (!isDocLink) return undefined;

  const splittedLink = url.split('/') ?? [];
  const splittedDocSlug = splittedLink[splittedLink.length - 1]?.split('-');
  if (!splittedDocSlug) return undefined;

  return splittedDocSlug[splittedDocSlug.length - 1];
}
