import { BoardSectionFragment, SectionType } from '@cycle-app/graphql-codegen';
import { DotsMenuProps, SelectOption } from '@cycle-app/ui';
import { PenIcon, TrashIcon, AddIcon } from '@cycle-app/ui/icons';
import { useToggle } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetPermission, useGetBillingPermission } from 'src/reactives/permission.reactive';
import { openNewSectionForm } from 'src/reactives/sections.reactive';

import { SectionTag } from './SectionOptions.styles';
import DialogModal from '../DialogModal/DialogModal';

const openLimitationsModalCreate = () => setLimitationsModal({ action: 'VIEW_CUSTOM_SECTION_CREATE' });

interface Props extends Omit<DotsMenuProps, 'options'> {
  section: Pick<BoardSectionFragment, 'id' | 'type' | 'name'>;
  onEditSectionName: VoidFunction;
  onRemoveSection: (sectionId: string) => void;
}

const SectionOptions: FC<React.PropsWithChildren<Props>> = ({
  section,
  onEditSectionName,
  onRemoveSection,
  ...props
}) => {
  const [modalDelete, toggleDeleteModal] = useToggle();
  const {
    isPermissionInit, canDeleteViewCustomSection, canUpdateViewCustomSection, canCreateViewCustomSection,
  } = useGetPermission();
  const { canPlanCreateViewCustomSection } = useGetBillingPermission();

  if (!isPermissionInit) return null;

  const isDefaultSection = section.type === SectionType.Default;

  const options: SelectOption[] = [];

  if (canCreateViewCustomSection) {
    options.push({
      value: 'new',
      label: 'Create section',
      icon: (
        <AddIcon
          style={{
            width: 16,
            height: 12,
          }}
        />
      ),
      onSelect: () => {
        if (!canPlanCreateViewCustomSection) {
          openLimitationsModalCreate();
          return;
        }
        openNewSectionForm();
      },
    });
  }

  if (canUpdateViewCustomSection) {
    options.push({
      value: 'edit',
      label: 'Rename',
      icon: <PenIcon />,
      onSelect: onEditSectionName,
      disabled: isDefaultSection,
      tooltipContent: isDefaultSection ? "This section can't be edited" : undefined,
      tooltipPlacement: 'top',
      tooltipOffset: [0, 0],
    });
  }

  if (canDeleteViewCustomSection) {
    options.push({
      value: 'delete',
      label: 'Delete',
      icon: <TrashIcon />,
      variant: 'danger',
      onSelect: toggleDeleteModal,
      disabled: isDefaultSection,
      tooltipContent: isDefaultSection ? "This section can't be deleted" : undefined,
      tooltipPlacement: 'top',
      tooltipOffset: [0, 0],
    });
  }

  if (options.length === 0) return null;

  return (
    <>
      <DotsMenuLayer
        tooltip="Section actions"
        ariaLabel="Section actions"
        options={options}
        {...props}
      />

      <AnimatePresence>
        {modalDelete && (
          <DialogModal
            hide={toggleDeleteModal}
            title="Delete section"
            info={(
              <>
                {'Are you sure you want to delete '}
                <SectionTag>{section.name}</SectionTag>
                {' ? Views will move to '}
                <SectionTag>Other views</SectionTag>
                {' in your sidebar.'}
              </>
            )}
            confirmLabel="Delete"
            onConfirm={() => onRemoveSection(section.id)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default SectionOptions;
