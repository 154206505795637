import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { Layer } from 'src/types/layers.types';

type Props = {
  doc: DocBaseFragment;
  readOnly?: boolean;
};

export const QuoteCardAttributes = ({
  doc, readOnly = false,
}: Props) => {
  const isOptimisticData = doc.id.includes('temp');

  if (!doc || isOptimisticData) {
    return (
      <div className="flex items-center gap-2">
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={80} />
      </div>
    );
  }

  return (
    <DocPanelDocAttributes
      doc={doc}
      showDocIdWithDocType
      layer={Layer.DropdownModal}
      hideStatusLabel
      readOnly={readOnly}
      showAiState={false}
      showDocId={false}
      showDoctype={false}
      showLinear={false}
      showRelease={false}
      showSource={false}
      showStatus={false}
    />
  );
};