import { useCallback, useLayoutEffect, useRef, useState } from 'react';

/**
 * Hook to detect vertical overflow on a container
 */
export const useVerticalOverflow = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [overflowTop, setOverflowTop] = useState(false);
  const [overflowBottom, setOverflowBottom] = useState(false);

  const checkOverflows = useCallback(() => {
    const el = ref.current;
    if (!el) return;
    if (el.scrollTop > 0 && !overflowTop) setOverflowTop(true);
    if (el.scrollTop === 0 && overflowTop) setOverflowTop(false);

    const isOverflow = el.scrollHeight - el.scrollTop > el.clientHeight;
    if (isOverflow && !overflowBottom) setOverflowBottom(true);
    if (!isOverflow && overflowBottom) setOverflowBottom(false);
  }, [overflowBottom, overflowTop]);

  useLayoutEffect(() => {
    const element = ref.current;
    element?.addEventListener('scroll', checkOverflows);
    window.addEventListener('resize', checkOverflows);
    return () => {
      element?.removeEventListener('scroll', checkOverflows);
      window.removeEventListener('resize', checkOverflows);
    };
  }, [checkOverflows]);

  useLayoutEffect(() => {
    checkOverflows();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ref,
    overflowTop,
    overflowBottom,
  };
};
