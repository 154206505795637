import { SelectLine } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import { EmojiItem } from '@tiptap-pro/extension-emoji';
import React, { FC, useCallback } from 'react';

import { Container } from './EmojiList.styles';

interface Props {
  query: string;
  items: EmojiItem[];
  command: (p: { name: string }) => void;
  editor: Editor;
}

const EmojiList: FC<React.PropsWithChildren<Props>> = ({
  query,
  items,
  command,
  editor,
}) => {
  const onSelect = useCallback((emojiName: string | null) => {
    // Return line when user pressed 'Enter' on no items or content is `:`
    if (!items.length || !query.length) {
      editor.commands.enter();
      return;
    }
    if (emojiName) {
      command({ name: emojiName });
    }
  }, [command, editor.commands, items.length, query.length]);

  const {
    selected,
    listProps,
    itemProps,
    hoverDisabled,
  } = useListNav({
    optionsValues: items.map(item => item.name),
    autoFocus: true,
    onSelect,
    value: null,
  });

  if (items.length === 0 || query === '') return null;

  return (
    <Container
      {...listProps}
      disable={false}
    >
      {items.map(item => (
        <SelectLine
          key={item.name}
          label={`:${item.name}:`}
          isSelected={item.name === selected}
          hoverDisabled={hoverDisabled}
          startSlot={item.emoji}
          {...itemProps(item.name)}
        />
      ))}
    </Container>
  );
};

export default EmojiList;
