import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import LayoutFooter from 'src/components/LayoutFooter/LayoutFooter';
import ProtectedRoute from 'src/components/ProtectedRoute/ProtectedRoute';
import { PageId, routing } from 'src/constants/routing.constant';
import { usePageId } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';
import { useGetAuth } from 'src/reactives/auth.reactive';

import { Container, Content } from './Auth.styles';
import Authorize from './Authorize/Authorize';
import Login from './Login/Login';
import ResetPwd from './ResetPwd/ResetPwd';

const Auth = () => {
  const { token } = useGetAuth();
  const pageId = usePageId();
  const getUrl = useUrl();

  const queryParams = new URLSearchParams(useLocation().search);
  const from = queryParams.get('from');
  if (token && ![PageId.Welcome, PageId.GetStarted].includes(pageId) && pageId !== PageId.OAuth) {
    const defaultRedirect = from || getUrl(PageId.Main);
    return <Redirect to={decodeURIComponent(defaultRedirect)} />;
  }

  return (
    <Container role="main">
      <Content $hasResponsive={false}>
        <Switch>
          <Route path={routing[PageId.Login]}>
            <Login />
          </Route>
          <Route path={routing[PageId.ResetPwd]}>
            <ResetPwd />
          </Route>
          <ProtectedRoute path={routing[PageId.OAuth]}>
            <Authorize />
          </ProtectedRoute>
          <Redirect to={routing[PageId.Login]} />
        </Switch>

        <LayoutFooter />
      </Content>
    </Container>

  );
};

export default Auth;
