import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const Title = styled.h1`
  ${typo.headerLarge};
`;

export const FormTitle = styled(Title)`
  margin-bottom: 40px;
`;

export const ResetStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  text-align: center;
`;

export const Text = styled.p`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
`;

export const BackButton = styled(Button)`
  margin: auto;
`;
