import { Button, typo, ellipsis } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${p => p.theme.colors.releases.border};
  display: flex;
  gap: 10px;
  height: 48px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
`;

export const StyledButton = styled(Button)`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  flex: 1;
  justify-content: flex-start;
  overflow: hidden;
  position: unset;

  span {
    ${ellipsis}
    position: relative;
    z-index: 1;
    text-align: left;
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    transition: background-color .2s;
  }

  &:hover::before {
    background-color: ${p => p.theme.colors.button.secondary.bgHover};
  }
`;
