import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { typo } from '@cycle-app/ui';
import { NodeViewWrapper } from '@tiptap/react';
import styled from 'styled-components';

export const MentionNodeView = styled(NodeViewWrapper) <{ $color: ColorApi }>`
  display: inline-block;
  padding: 0 4px;
  background-color: ${p => (p.$color ? p.theme.nuances[p.$color].bg : p.theme.userColors.bg)};
  color: ${p => (p.$color ? p.theme.nuances[p.$color].main : p.theme.userColors.main)};
  border-radius: 50px;
  line-height: 24px;
`;

export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.caption500};
`;
