import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FieldsGroup = styled.div`
  display: flex;
  gap: 12px;
`;
