import { useHotkeys, HotkeyCallback, UseHotkeyParams } from '@cycle-app/utilities';
import { intersection } from 'ramda';
import { useCallback } from 'react';

import { getLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';
import { getOpenedLayers } from 'src/utils/layers.util';

interface Options extends UseHotkeyParams {
  enableOnLayers?: Layer[];
  disableOnLayers?: Layer[];
}

type UseAppHotKeys = (keys: string, callback: HotkeyCallback, options?: Options, deps?: Array<unknown>) => void;

/**
 * That hook will prevent any shortcut from being executed when the CommandBar layer
 * is opened. We don't want to set enable prop for that because it would trigger a re-render
 * each time layers is updated (dropdowns, modals, commandBar, etc)
 */
const useAppHotkeys: UseAppHotKeys = (keys, callback, opts, deps) => {
  const preventCallback = useCallback(() => {
    const currentLayers = getLayer();
    const openedLayers = getOpenedLayers();

    if (opts?.disableOnLayers && intersection(openedLayers, opts.disableOnLayers).length > 0) {
      return true;
    }
    if (opts?.preventCallback) {
      return opts.preventCallback();
    }

    // By default, all shortcuts must be disabled when the commandbar is visible
    return currentLayers[Layer.Commandbar];
  }, [opts]);

  return useHotkeys(keys, callback, {
    ...opts,
    preventCallback,
  }, deps);
};

export default useAppHotkeys;
