import { Color } from '@cycle-app/ui/theme/baseColors';
import { isIOS } from '@cycle-app/utilities';
import { useEffect, MutableRefObject, useRef } from 'react';
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js';

import { useIsMobile } from 'src/reactives/responsive.reactive';

type UseAudioPlayerParams = {
  container: MutableRefObject<HTMLDivElement | null>;
  url: string;
  listeners: {
    onReady?: (duration: number) => void;
    onPlay?: VoidFunction;
    onPause?: VoidFunction;
    onTimeUpdate?: (time: number) => void;
  };
  options?: {
    height?: number;
    backend?: WaveSurferOptions['backend'];
    getPeaks?: () => Promise<WaveSurferOptions['peaks']>;
    duration?: WaveSurferOptions['duration'];
  };
};

export const useAudioPlayer = ({
  container,
  url,
  listeners,
  options,
}: UseAudioPlayerParams): WaveSurfer | null => {
  const instance = useRef<WaveSurfer | null>(null);
  const isMobile = useIsMobile();
  useEffect(() => {
    let waveSurferInstance: WaveSurfer | undefined;
    let subscriptions: (() => void)[] = [];

    const create = async () => {
      if (!container.current || !url) return;
      container.current.childNodes.forEach(node => node.remove());
      waveSurferInstance = WaveSurfer.create({
        url,
        container: container.current,
        height: options?.height || (isMobile ? 20 : 32),
        barWidth: 2,
        barGap: 3,
        barRadius: 10,
        cursorColor: Color.Grey300,
        cursorWidth: 0,
        waveColor: Color.Grey300,
        progressColor: Color.Cycle,
        normalize: true,
        audioRate: 1,
        autoplay: false,
        backend: options?.backend ?? (isIOS ? 'MediaElement' : 'WebAudio'),
        peaks: options?.getPeaks ? await options.getPeaks() : undefined,
        duration: options?.duration,
      });
      subscriptions = [
        ...listeners?.onReady ? [waveSurferInstance.on('ready', (duration: number) => listeners.onReady?.(duration))] : [],
        ...listeners?.onPlay ? [waveSurferInstance.on('play', listeners.onPlay)] : [],
        ...listeners?.onPause ? [waveSurferInstance.on('pause', listeners.onPause)] : [],
        ...listeners?.onTimeUpdate ? [waveSurferInstance.on('timeupdate', listeners.onTimeUpdate)] : [],
      ];
      instance.current = waveSurferInstance;
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Safe promise
    create();

    return () => {
      waveSurferInstance?.destroy();
      subscriptions.forEach((unSubscribe) => unSubscribe());
    };
  /**
   * Intended: Only set the instance on component mounted
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return instance.current;
};
