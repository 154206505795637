import { ShyScrollbarCss, SelectLine, Input as InputUI, TextButton, typo } from '@cycle-app/ui';
import { Label } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine.styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 8px 0px;
  max-height: 49vh;
  ${ShyScrollbarCss};
`;

export const ReorderContainer = styled.div`
  display: flex;
  color: ${p => p.theme.colors.text.disabled};
  cursor: grab;
`;

interface SelectLineStyledProps {
  $isValueChanged?: boolean;
  $isFocus?: boolean;
  $isForceHover?: boolean;
}

export const SelectLineStyled = styled(SelectLine)<SelectLineStyledProps>`
  ${p => p.$isValueChanged && css`
    --lineEnd-flex-basis: 38px;
  `}
  ${p => p.$isFocus && css`
    border-radius: 6px;
    margin-left: 6px;
    margin-right: 6px;
    border-width: 2px;
    border-color: hsl(var(--cycle400));
    padding: 0 8px;
  `}
  ${p => p.$isForceHover && css`
    background-color: ${p.theme.colors.selectItem.bgHover};
  `}
  ${Label} {
    min-width: 150px;
    max-width: 150px;
  }
`;

export const EndSlotContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Input = styled(InputUI)`
  margin: 0 8px 8px 8px;

  input {
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 6px;
  }
`;

export const AddOptionButton = styled(TextButton)`
  margin-top: 4px;
  padding: 2px 13px;
  gap: 10px;
  ${typo.body400}
  border-radius: 0;

  &:hover, &:active, &:focus, &.force-focus {
    background-color: ${p => p.theme.colors.selectItem.bgHover} !important;
  }
`;
