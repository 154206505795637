import { CreateReleaseNotePosition } from '@cycle-app/graphql-codegen';
import { UniqueIdentifier } from '@dnd-kit/core';

import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetReleaseNotesDndItems,
  getValue: getReleaseNotesDndItems,
  setValue: setReleaseNotesDndItems,
} = make<{
  main: UniqueIdentifier[];
  other: UniqueIdentifier[];
}>({
  defaultState: {
    main: [],
    other: [],
  },
});

type OnCompleted = (releaseId: string) => void;

type ReleasesAction =
  | { type: null; id: null; onCompleted: null }
  | { type: 'productTour'; id: null }
  | { type: 'createRelease'; id: null; onCompleted?: OnCompleted }
  | { type: 'updateRelease'; id: string }
  | { type: 'createReleaseBulk'; id: null }
  | { type: 'removeRelease'; id: string }
  | { type: 'publishRelease'; id: string }
  | { type: 'unpublishRelease'; id: string }
  | { type: 'shareRelease'; id: string }
  | { type: 'createReleaseNote'; id: string; position: CreateReleaseNotePosition }
  | { type: 'createOtherReleaseNote'; id: string; position: CreateReleaseNotePosition }
  | { type: 'updateReleaseNote'; id: string }
  | { type: 'removeReleaseNote'; id: string };

export const {
  hookValue: useGetReleasesAction,
  getValue: getReleasesAction,
  setValue: setReleasesAction,
} = make<ReleasesAction>({
  defaultState: {
    type: null,
    id: null,
    onCompleted: null,
  },
});

export const resetReleasesAction = () => setReleasesAction({
  type: null,
  id: null,
  onCompleted: null,
});

export const openReleasesProductTour = () => setReleasesAction({
  type: 'productTour',
  id: null,
});

export const openCreateRelease = (onCompleted?: OnCompleted) => setReleasesAction({
  type: 'createRelease',
  id: null,
  onCompleted,
} as unknown as Record<string, string>);

export const openCreateReleaseBulk = () => setReleasesAction({
  type: 'createReleaseBulk',
  id: null,
});

export const openUpdateRelease = (id: string) => () => setReleasesAction({
  type: 'updateRelease',
  id,
});

export const openRemoveRelease = (id: string) => () => setReleasesAction({
  type: 'removeRelease',
  id,
});

export const openPublishRelease = (id: string) => () => setReleasesAction({
  type: 'publishRelease',
  id,
});

export const openUnpublishRelease = (id: string) => () => setReleasesAction({
  type: 'unpublishRelease',
  id,
});

export const openShareRelease = (id: string) => setReleasesAction({
  type: 'shareRelease',
  id,
});

export const openCreateReleaseNote = (id: string, isOther: boolean, position: CreateReleaseNotePosition) => setReleasesAction({
  type: isOther ? 'createOtherReleaseNote' : 'createReleaseNote',
  id,
  position,
});

export const openUpdateReleaseNote = (id: string) => () => setReleasesAction({
  type: 'updateReleaseNote',
  id,
});

export const openRemoveReleaseNote = (id: string) => () => setReleasesAction({
  type: 'removeReleaseNote',
  id,
});
