import { useRect } from '@cycle-app/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useState } from 'react';

import { useNavigate } from 'src/hooks';
import { useMoveBoardInSectionsList } from 'src/hooks/api/mutations/useBoardSectionsMutations';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { getViewPageId, SectionParent } from 'src/utils/section.utils';
import { getBoardSlug } from 'src/utils/slug.util';

import { ViewItem } from './ViewItem';
import { Container, Total } from './ViewsList.styles';
import { ViewSortableItem } from './ViewSortableItem';
import { ViewsSortableContext } from './ViewsSortableContext';

type Props = {
  sectionParent: SectionParent;
};

export const ViewsList = ({ sectionParent }: Props) => {
  const {
    boards, sectionId,
  } = useSectionBoards(sectionParent);
  const { navigate } = useNavigate();
  const viewsCount = boards.length;
  const [items, setItems] = useState<UniqueIdentifier[]>(boards.map(board => board.id));
  const { moveBoardInSectionList } = useMoveBoardInSectionsList();
  const [ref, isSmall] = useRect(rect => rect.width < 850);

  return (
    <Container ref={ref}>
      {isSmall !== undefined && (
        <>
          <ViewsSortableContext
            items={items}
            setItems={setItems}
            overlay={id => (
              <ViewItem
                sectionParent={sectionParent}
                boardId={id.toString()}
                isSmall={isSmall}
                isOverlay
              />
            )}
            onDragEnd={event => {
              if (!sectionId || !event.over) return;
              const activeId = event.active.id.toString();
              const overId = event.over.id.toString();
              if (activeId === overId) return;
              const boardIds = boards.map(board => board.id);
              const isAfter = boardIds.indexOf(overId) > boardIds.indexOf(activeId);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              moveBoardInSectionList({
                boardId: activeId,
                fromSectionId: sectionId,
                toSectionId: sectionId,
                position: isAfter ? {
                  after: overId,
                } : {
                  before: overId,
                },
              });
            }}
          >
            {props => items.map(id => (
              <ViewSortableItem
                id={id}
                key={id}
              >
                <ViewItem
                  sectionParent={sectionParent}
                  boardId={id.toString()}
                  isSmall={isSmall}
                  isDragging={props.isDragging}
                  onClick={event => {
                    const board = boards.find(b => b.id === id);
                    navigate(getViewPageId(sectionParent), { boardSlug: getBoardSlug(board) }, undefined, event?.metaKey);
                  }}
                />
              </ViewSortableItem>
            ))}
          </ViewsSortableContext>

          <Total>{`${viewsCount} views`}</Total>
        </>
      )}
    </Container>
  );
};
