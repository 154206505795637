import styled, { css } from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

type ContainerProps = {
  $isVisible: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${mappingZindex[Layer.DropdownModalZ3]};
  /**
   * Uncomment that line to debug
   */
  /* background: ${p => (p.$isVisible ? 'rgba(255, 0, 255, 0.3)' : 'rgba(0,255, 255, 0.3)')}; */

  ${p => !p.$isVisible && css`
    pointer-events: none;
  `}
`;
