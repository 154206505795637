import { DocCommentFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { getOS } from '@cycle-app/utilities';
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { CommentEditorOutput } from 'src/components/Editor/Editors/CommentEditor';
import { useOptimizedBooleanState } from 'src/hooks';
import { useEditComment } from 'src/hooks/api/mutations/comments/useEditComment';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { fixCommentContent } from 'src/utils/editor/editor.utils';

import { Container, StyledCommentEditor, Buttons } from './EditComment.styles';

const os = getOS();

type EditCommentProps = {
  docId: string;
  comment: Pick<DocCommentFragment, 'id' | 'content' | 'createdAt'>;
  close: VoidFunction;
  className?: string;
};

export const EditComment = ({
  docId, comment, close, className,
}: EditCommentProps) => {
  const [isFocused, {
    setTrueCallback: onFocus,
    setFalseCallback: onBlur,
  }] = useOptimizedBooleanState(false);

  const draft = useRef<CommentEditorOutput>();
  const mutation = useEditComment();

  const editComment = () => {
    if (mutation.isEditingComment || !draft.current || draft.current.editor.isEmpty) return;
    close();

    const fixedOutput = fixCommentContent(draft.current.editor);
    const output = fixedOutput || draft.current;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    mutation.editComment(docId, {
      commentId: comment.id,
      content: output.html,
      createdAt: comment.createdAt,
    });
  };

  // Scroll the comment into view when the editor is opened as it might be partially out of his container
  const isInViewChecked = useRef(false);
  const { ref: containerRef } = useInView({
    threshold: 1,
    onChange: (inView, entry) => {
      if (!inView && !isInViewChecked.current) {
        entry?.target.parentElement?.scrollIntoView({ block: 'nearest' });
      }
      // ScrollIntoView should be called only once at opening, and not when scrolling manually
      setTimeout(() => { isInViewChecked.current = true; }, 150);
    },
  });

  const onCmdEnter = (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    editComment();
  };

  // Checking isFocused avoids conflicts between the events.
  useAppHotkeys('enter', onCmdEnter, { enabled: () => isFocused && (os === 'macOS' || os === 'Windows') });
  useAppHotkeys('command+enter', onCmdEnter, { enabled: () => isFocused && os === 'macOS' });
  useAppHotkeys('control+enter', onCmdEnter, { enabled: () => isFocused && os === 'Windows' });

  return (
    <Container
      ref={containerRef}
      className={className}
      isFocused={isFocused}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <StyledCommentEditor
        placeholder=""
        onUpdate={output => { draft.current = output; }}
        maxHeight={128}
        autofocus
        content={comment.content}
      />

      <Buttons>
        <Button
          variant="secondary"
          onClick={close}
        >
          Cancel
        </Button>

        <Button
          onMouseDown={e => e.preventDefault()}
          onClick={editComment}
        >
          Save
        </Button>
      </Buttons>
    </Container>
  );
};
