import styled from 'styled-components';

export const Menu = styled.div<{
  forceVisible: boolean;
}>`
  position: absolute;
  display: flex;
  top: 8px;
  right: 8px;
  border-radius: 6px;
  background: rgba(34, 34, 34, 0.8);
  opacity: ${p => (p.forceVisible ? 1 : 0)};
  padding: 4px;
  gap: 4px;

  button {
    width: 20px;
    height: 20px;
  }

  > svg {
    stroke: white;
  }
`;
