import { DocResultFragment } from '@cycle-app/graphql-codegen';
import { TextHighlighter, SelectLineProps } from '@cycle-app/ui';

import { StatusTag } from 'src/components/DocStatus';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';

import {
  StyledSelectLine,
  ResultLabel,
  ResultTitle,
  SelectLinePreSlotWrapper,
  LabelContainer,
  Content,
} from './DocSearchDropdown.styles';

type Props = Omit<SelectLineProps, 'label'> & {
  doc: DocResultFragment;
  highlightTitle?: string | null;
  highlightContent?: string | null;
  search: string;
};

export const DocSearchLine = ({
  doc,
  highlightTitle,
  highlightContent,
  search,
  ...props
}: Props) => {
  const { docTypes } = useGetDocTypes();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  return (
    <StyledSelectLine
      {...props}
      label={(
        <LabelContainer>
          <ResultLabel>
            <SelectLinePreSlotWrapper>
              <DocTypeIcon doctype={docTypes[doc.doctype.id]} />
              {isRoadmapsEnabled && doc.status && (
                <StatusTag
                  category={doc.status.category}
                  value={doc.status.value}
                  isReadOnly
                  hideLabel
                />
              )}
            </SelectLinePreSlotWrapper>
            <ResultTitle>
              {highlightTitle ? (
                <TextHighlighter
                  className="highlight"
                  searchWords={[search]}
                  textToHighlight={doc.title}
                />
              ) : doc.title}
            </ResultTitle>
          </ResultLabel>

          {highlightContent && !highlightTitle && (
            <Content
              className="highlight"
              dangerouslySetInnerHTML={{
                __html: `<p>${highlightContent}</p>`,
              }}
            />
          )}
        </LabelContainer>
      )}
    />
  );
};
