import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const SelectionWarn = styled.div`
  padding: 12px;
  background-color: ${p => p.theme.colors.background.secondary};
  border-radius: 4px;
  ${typo.body400}
`;
