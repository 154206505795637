const CLOUDINARY_URL_REGEX = /https:\/\/res\.cloudinary\.com\/([^/]+)\/video\/upload\//;

export const getAppCloudNameFromUrl = (
  url: string,
): string | null => {
  const regex = CLOUDINARY_URL_REGEX;
  const match = url.match(regex);

  return match?.[1] || null;
};

/**
 * Create a waveform image URL from a cloudinary audio URL
 * Colors: white background, black waveform
 * Size: 1000px width, 100px height
 * https://cloudinary.com/documentation/audio_transformations
 */
export const getCloudinaryWaveformUrl = (audioUrl: string): string => {
  return audioUrl
    .replace(/upload\/v\d+\//, 'upload/fl_waveform,h_100,w_1000,co_black,b_white/')
    .replace(/\.\w+$/, '.png');
};
