/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Feature } from '@cycle-app/graphql-codegen';

import { CommandActionCategory } from 'src/types/commandbar.types';

import { useProductAddOns } from '..';
import { closeCommandBar } from '../modals/useCommandBarModal';
import { useFeatureFlagUpdate } from '../useFeatureFlagUpdate';

type SettingsItem = {
  id: Feature;
  name: string;
  disabled?: boolean;
};

export const useAddonsResult = (): Array<CommandActionCategory> => {
  const {
    activateFeature,
    deactivateFeature,
  } = useFeatureFlagUpdate();
  const addons = useProductAddOns();
  const settings: SettingsItem[] = [
    {
      id: Feature.Analyses,
      name: 'Analyses',
    },
    {
      id: Feature.CustomerAttributes,
      name: 'Customer attributes',
    },
    {
      id: Feature.Hubspot,
      name: 'Hubspot',
    },
    {
      id: Feature.Meetings,
      name: 'Meetings',
    },
    {
      id: Feature.Salesforce,
      name: 'Salesforce',
    },
    {
      id: Feature.UnlimitedAiQueries,
      name: 'Unlimited ai queries',
    },
    {
      id: Feature.UnlimitedDoctypes,
      name: 'Unlimited doctypes',
    },
    {
      id: Feature.UnlimitedProperties,
      name: 'Unlimited properties',
    },
  ];

  return [{
    id: 'toggle-addon',
    label: 'Addons',
    actions: settings
      .filter(setting => !setting.disabled)
      .map(setting => ({
        id: setting.id,
        label: `${setting.name}${addons.some(a => a.name === setting.id && a.isEnabled) ? ' (active)' : ''}`,
        onClick: async (e) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          addons.some(a => a.name === setting.id && a.isEnabled) ? await deactivateFeature(setting.id) : await activateFeature(setting.id);
          if (!e.metaKey) closeCommandBar();
        },
        onSelect: async () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          addons.some(a => a.name === setting.id && a.isEnabled) ? await deactivateFeature(setting.id) : await activateFeature(setting.id);
          closeCommandBar();
        },
      })),
  }];
};
