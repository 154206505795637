import { ThemeType } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';

import { headerSmall, caption400 } from '../../theme/typo';
import { truncate, boxShadowZ2, boxShadowZ1 } from '../../utils/styles.util';
import { CircleSkeleton, Skeleton } from '../Skeleton/Skeleton';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 12px;
  gap: 16px;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.border.surprisingGreyDark};
  background-color: ${p => p.theme.colors.modal.content.bg};
  border-radius: 8px;
  ${boxShadowZ2};
`;

export const Logos = styled.div`
  display: flex;
  gap: 10px;
`;

export const Logo = styled.div`
  height: 16px;
  flex: none;
  > svg {
    height: 100%;
  }
`;

export const IntegrationName = styled.h3`
  ${headerSmall}
  font-weight: 500;
`;

export const IntegrationTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WorkspaceLogo = styled.div<{ $src: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-image: ${p => `url(${p.$src})`};
  background-size: cover;
  background-position: center;
  flex: none;
`;

export const WorkspaceName = styled.div`
  ${caption400}
  user-select: text;
  color: ${p => p.theme.colors.text.secondary};
  flex: 1;
  ${truncate}
`;

export const Information = styled.div`
  ${caption400}
  user-select: text;
  color: ${p => p.theme.colors.text.disabled};
  flex: 1;
  ${truncate}
`;

export const CardSide = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 1px;
  margin: -1px;
`;

export const CardBody = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  ${IntegrationTitle} {
    flex-grow: 1;
    flex-shrink: 0;
  }
  ${CardSide} {
    flex-grow: 0;
    flex-shrink: 1;
  }
`;

export const LogoSkeleton = styled(CircleSkeleton)`
  width: 16px;
  height: 16px;
  flex: none;
`;

export const TitleSkeleton = styled(Skeleton)`
  height: 16px;
  margin: 4px 0;
  min-width: 120px;
  flex: 1;
`;

export const CardContainerBlock = styled(CardContainer)<{ $isSlack?: boolean }>`
  padding: 12px;
  gap: 16px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 12px;
  height: 120px;
  ${boxShadowZ1};

  ${p => p.$isSlack && css`
    background-color: ${p.theme.colors.home.integrations.slack.bg};
    box-shadow: none;
    border-color: transparent;
  `}

  ${p => (p.theme.themeType === ThemeType.Nightfall && css`
    box-shadow: none;
    border-color: transparent;
  `)};
`;

export const CardBodyBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 100%;
`;

export const CardBodyFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LogoBlock = styled(Logo)`
  display: flex;
  gap: 10px;
  height: 32px;
  min-width: 32px;
  background-color: ${p => p.theme.colors.home.integrations.logo.bg};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;

export const IntegrationTitleBlock = styled(IntegrationTitle)`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  height: 32px;
`;

export const IntegrationNameBlock = styled.h3`
  ${headerSmall}
  font-weight: 500;
`;
