import { StatusCategory } from '@cycle-app/graphql-codegen';
import { typo, Emoji } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { ReadOnlyEditor } from 'src/components/Editor';

export const Content = styled(ReadOnlyEditor) <{ isContentBoxed: boolean }>`
  margin: 0 16px 0 0;
  height: auto;

  p {
    cursor: pointer;
    user-select: none;
  }

  ${p => p.isContentBoxed && css`
    margin-right: 0;
    padding: 8px;
    background-color: ${p.theme.colors.notificationCenter.card.bgHover};
    border-radius: 4px;
  `}
`;

type ContainerProps = {
  isWelcomeNotification: boolean;
  isContentBoxed: boolean;
};

export const Container = styled.div<ContainerProps>`
  padding: 16px;
  display: flex;
  gap: 8px;

  ${p => (p.isWelcomeNotification ? css`
    background-color: ${p.theme.colors.background.bluelight};
  ` : css`
    &:hover {
      background-color: ${p.theme.colors.notificationCenter.card.bgHover};

      ${p.isContentBoxed && css`
        ${Content} {
          background-color: ${p.theme.colors.notificationCenter.card.boxBgHover};
        }
      `}
    }
  `)}
`;

export const ImageContainer = styled.div`
  margin-top: 2px;
`;

export const MainArea = styled.div`
  position: relative;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UnreadDot = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: hsl(var(--cycle));
`;

export const Title = styled.div`
  margin-right: 16px;
  gap: 4px;
  ${typo.body500}
  vertical-align: middle;
`;

export const TitleEmoji = styled(Emoji)`
  display: inline;
  margin-right: 4px;

  span {
    display: inline;
  }
`;

export const GreyPart = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Date = styled.div`
  color: ${p => p.theme.colors.text.disabled};
  ${typo.caption400};
`;

export const SettingsButton = styled.button`
  ${typo.body500}
  padding: 0;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
`;

type StatusContainerProps = {
  $statusCategory: StatusCategory;
};

export const StatusContainer = styled.div<StatusContainerProps>`
  ${typo.caption400}
  display: inline-flex;
  border-radius: 4px;
  padding: 0 4px;
  gap: 4px;
  transform: translateY(2px);

  ${p => p.$statusCategory && css`
    color: ${p.theme.colors.components.FeedbackStatus[p.$statusCategory].text};
    background-color: ${p.theme.colors.components.FeedbackStatus[p.$statusCategory].bgOpaque};
  `}
`;

export const ChildContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${p => p.theme.colors.background.tertiary};
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: none;
  
  :hover {
    background-color: ${p => p.theme.colors.background.hover};
  }

  > div:nth-child(1) {
    flex: 1;
    text-align: left;
    color: ${p => p.theme.colors.text.primary};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline;
  }
`;
