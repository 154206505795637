import { DocBaseFragment, DocTargetFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useState, FC } from 'react';

import { hideAddQuoteFromBlockButton } from 'src/app/Main/Board/DocPanel/DocPanelContent/useQuoteFromBlock';
import { InsightDocSearchDropdown } from 'src/components/DocSearchDropdown/InsightDocSearchDropdown';
import { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { FeedbackQuoteCreateModal } from 'src/components/InsightCreateModal';
import { useDocTypeInsight, useDocV2, useOptimizedBooleanState, useDocInsightCreate } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { getInheritedAttributeFromDoc, getSubmittableAttributeValues } from 'src/utils/attributes.util';
import { getDocTypeNames } from 'src/utils/docType.util';
import { getDefaultInsightTitle } from 'src/utils/insight.utils';

interface Props {
  blockId?: string;
  dropdownProps?: Partial<Omit<DropdownLayerProps, 'content'>>;
  defaultContent?: string;
  feedback: DocBaseFragment;
  docTarget: DocTargetFragment | null | undefined;
  isInitialVisible?: boolean;
  onHide?: VoidFunction;
  onInsightCreating?: VoidFunction;
  onInsightCreated?: VoidFunction;
  defaultCover?: string;
}

export const InsightDropdown: FC<React.PropsWithChildren<Props>> = ({
  blockId,
  dropdownProps,
  defaultContent,
  docTarget,
  feedback,
  isInitialVisible,
  onHide,
  onInsightCreating,
  onInsightCreated,
  defaultCover,
}) => {
  const [isDocSearchVisible, { setFalseCallback: hideDocSearch }] = useOptimizedBooleanState(!!isInitialVisible);
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const [withoutParent, setWithoutParent] = useState(false);
  const { insight } = useDocTypeInsight();
  const {
    doc: parentDoc, isLoading: isParentLoading,
  } = useDocV2(insightDocParentId);
  const changeDocParent = useChangeDocParent();
  const possibleDoctypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);
  const { create: createInsight } = useDocInsightCreate();

  const onOptionSelected = async (parentId?: string) => {
    const {
      common, specific,
    } = getInheritedAttributeFromDoc({
      originAttributes: nodeToArray(feedback?.attributes),
      targetAttributesDefinitions: nodeToArray(insight?.attributeDefinitions),
    });
    if (specific.length === 0) {
      const titleValue = getDefaultInsightTitle({
        customerName: feedback.customer?.name,
        docTitle: feedback.title,
      });
      await createInsight(
        {
          assignee: feedback.assignee?.id || '',
          contentJSON: '',
          customerId: feedback.customer?.id || '',
          docLinkContent: defaultContent || titleValue,
          docLinkSourceId: feedback.id,
          doctypeId: insight?.id || '',
          parentId,
          properties: getSubmittableAttributeValues(common),
          title: titleValue,
          blockId,
          sourceId: feedback.source?.id || '',
        },
        /**
         * If it's a new doc we pass the parent to set the common attributes
         */
        { parentDoc },
      );
      hideDocSearch();
    } else {
      if (parentId) {
        setInsightDocParentId(parentId);
      } else {
        setWithoutParent(true);
      }
      hideDocSearch();
    }
  };

  const hasParent = !!docTarget?.doc?.parent;

  if (!feedback) return null;

  return (
    <FeedbackQuoteCreateModal
      isOpen={!!parentDoc?.id || isParentLoading || withoutParent}
      hide={() => {
        hideAddQuoteFromBlockButton();
        if (!isDocSearchVisible) {
          onHide?.();
          return;
        }
        setInsightDocParentId('');
        setWithoutParent(false);
      }}
      parentDocId={parentDoc?.id}
      feedbackDoc={feedback}
      dropdownProps={dropdownProps}
      defaultContent={defaultContent}
      defaultCover={defaultCover}
      blockId={blockId}
      onInsightCreating={onInsightCreating}
      onInsightCreated={() => {
        hideAddQuoteFromBlockButton();
        onInsightCreated?.();
      }}
      hideTitleField
      isLoading={isParentLoading}
      withoutParent={withoutParent}
    >
      <InsightDocSearchDropdown
        showNoneOption={hasParent || !docTarget}
        childDoctypeId={insight?.id}
        possibleDoctypes={possibleDoctypes}
        dropdownProps={dropdownProps}
        hideSearchDropdown={() => {
          hideAddQuoteFromBlockButton();
          if (!insightDocParentId) {
            onHide?.();
            return;
          }
          hideDocSearch();
        }}
        isSearchDropdownVisible={isDocSearchVisible}
        onRemove={async () => {
          if (docTarget?.doc) {
            await changeDocParent({
              docId: docTarget.doc.id,
              parentId: undefined,
            });
            onHide?.();
            return;
          }

          await onOptionSelected();
        }}
        onAdd={async (docId) => {
          let parentId = docId;

          if (docTarget?.doc) {
            const result = await changeDocParent({
              docId: docTarget.doc.id,
              parentId: docId,
            });
            if (result?.data?.changeDocParent?.id) {
              parentId = result.data.changeDocParent.id;
            }
            onHide?.();
            return;
          }

          await onOptionSelected(parentId);
        }}
        placeholder={getDocTypeNames(possibleDoctypes, {
          prefix: 'Link to',
          suffix: '',
        })}
        semanticSearch={defaultContent}
      />
    </FeedbackQuoteCreateModal>
  );
};