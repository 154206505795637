import { ImageSize, FileData } from '@cycle-app/utilities/src/types/editor.types';
import Image from '@tiptap/extension-image';

import { IMAGE_EXTENSION_NAME } from '../constants';
import { getAttributeParser } from '../utils/extension.utils';

export { ImageSize };

export type GetImageNodeParams = {
  size?: ImageSize;
  src: FileData['src'];
  title?: FileData['title'];
  dataId?: string;
};

export const getImageExtension = () => Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      size: {
        default: ImageSize.LARGE,
        ...getAttributeParser('size'),
      },
      dataId: {
        default: '',
        ...getAttributeParser('dataId'),
      },
    };
  },
  addCommands() {
    return {
      setImage: (options: Omit<FileData, 'dataId'>) => ({ commands }) => commands.insertContent([getEditorImageNode({
        size: ImageSize.SMALL,
        src: options.src,
        title: options.title,
      })]),
    };
  },
});

export const getEditorImageNode = (data: GetImageNodeParams) => ({
  type: IMAGE_EXTENSION_NAME,
  attrs: {
    ...data,
    size: data.size || ImageSize.SMALL,
  },
});
