import { getOS } from '@cycle-app/utilities';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { AnyShortcut, MappingShortcut } from 'src/types/shortcuts.types';

const emptyMapping = {
  sequential: {},
  default: {},
};

const mod = getOS() === 'macOS' ? 'command' : 'control';

function reduceMapping<Shortcut extends AnyShortcut>(current: MappingShortcut<Shortcut>, shortcut: Shortcut): MappingShortcut<Shortcut> {
  const [first, second, third] = shortcuts[shortcut];

  let mapping = {};
  if (shortcuts[shortcut].length === 1) {
    mapping = {
      default: {
        ...current.default,
        [first]: shortcut,
      },
    };
  } else if (first === 'mod') {
    mapping = {
      default: {
        ...current.default,
        [`${mod}+${second}`]: shortcut,
      },
    };
  } else if (['shift', 'control'].includes(first)) {
    mapping = {
      default: {
        ...current.default,
        [shortcuts[shortcut].join('+')]: shortcut,
      },
    };
  } else if (second === ',' && third) {
    mapping = {
      sequential: {
        ...current.sequential,
        [first]: {
          ...current.sequential[first],
          [third]: shortcut,
        },
      },
    };
  }

  return {
    ...current,
    ...mapping,
  };
}

export function getShortcutMapping<Shortcut extends AnyShortcut>(shortcutKeys: Shortcut[]): MappingShortcut<Shortcut> {
  return shortcutKeys.reduce(reduceMapping, emptyMapping);
}

export const getPreShortcutsToListen = (
  shortcutKeys: Array<AnyShortcut>,
) => [...new Set(
  shortcutKeys.reduce((current: Array<string>, shortcut) => {
    const [first, second, third] = shortcuts[shortcut];

    if (second === ',' && third) {
      return [...current, first];
    }

    return current;
  }, []),
)];

export const getShortcutsToListen = (
  shortcutKeys: Array<AnyShortcut>,
) => shortcutKeys.reduce((current: Array<string>, shortcut) => {
  const [first, second, third] = shortcuts[shortcut];

  if (shortcuts[shortcut].length === 1) {
    return [...current, first];
  }
  if (first === 'mod') {
    return [...current, `${mod}+${second}`];
  }
  if (first === 'shift' || first === 'control') {
    return [...current, shortcuts[shortcut].join('+')];
  }
  if (second === ',' && third) {
    return [...current, third];
  }

  return current;
}, []);

interface GetHotkeyParamsResult<Shortcut extends AnyShortcut> {
  mapping: MappingShortcut<Shortcut>;
  shortcutsToListen: string[];
  preShortcutsToListen: string[];
}
export function getHotkeyParams<Shortcut extends AnyShortcut>(shortcutValues: Array<Shortcut>): GetHotkeyParamsResult<Shortcut> {
  return {
    mapping: getShortcutMapping(shortcutValues),
    shortcutsToListen: getShortcutsToListen(shortcutValues),
    preShortcutsToListen: getPreShortcutsToListen(shortcutValues),
  };
}
