import { Tooltip } from '@cycle-app/ui';
import { StarIcon, CheckIcon } from '@cycle-app/ui/icons';

import {
  Container, Footer, Title, Button, Badge, SocialProof, Count,
} from './RoadmapTemplate.styles';
import { Template } from './roadmapTemplates.data';
import { TemplateHierarchy } from './TemplateHierarchy';

export const RoadmapTemplate = ({
  template, isSelected, onSelect,
}: {
  template: Template;
  isSelected?: boolean;
  onSelect: VoidFunction;
}) => {
  return (
    <Container $isSelected={isSelected} onClick={onSelect}>
      <TemplateHierarchy template={template} />

      <Footer>
        <Title>{template.name}</Title>

        {template.socialProof && (
          <SocialProof>
            <Tooltip
              placement="top"
              withWrapper={false}
              content={template.socialProof.tooltip}
            >
              <img src={template.socialProof?.img} height={20} alt="" />
            </Tooltip>
            <Count>{`${template.socialProof.count}+`}</Count>
          </SocialProof>
        )}

        <Button
          onClick={e => {
            e.stopPropagation();
            onSelect();
          }}
          $isSelected={isSelected}
        >
          {isSelected && <CheckIcon size={14} />}
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </Footer>

      {template.isRecommended && (
        <Badge>
          <StarIcon size={12} />
          Recommended
        </Badge>
      )}
    </Container>
  );
};
