import { body400, caption400 } from '@cycle-app/ui/theme/typo';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.editor.filePreview.bg};
  text-align: center;
  padding: 16px 32px;
  border-radius: 9px;
`;

export const Heading = styled.p`
  ${body400}
  color: ${(p) => p.theme.colors.text.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
`;

export const Text = styled.p`
  ${caption400}
  color: ${(p) => p.theme.colors.text.disabled};
`;

export const IconContainer = styled.div`
  svg {
    width: 33px;
    height: 33px;
    
    path {
      stroke: ${(p) => p.theme.colors.border.grey};
      fill: none;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
