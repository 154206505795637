import styled, { keyframes, css } from 'styled-components';

import { rotateAnimation } from '../../utils/animation.utils';

const colorAnimation = keyframes`
  0% {
    color: var(--color1);
  }
  100% {
    color: var(--color2);
  }
`;

export const SvgLogo = styled.svg`
  color: var(--color1);
  width: 1em;
  height: 1em;
`;

export const SvgName = styled.svg`
  color: var(--color1);
  width: 2.52em;
  height: 0.93em;
  margin-top: 0.14em;
`;

export type ContainerProps = {
  $animation?: 'loop' | 'hover' | null;
  $animateColor?: boolean;
  $size: number;
  $color?: 'blue' | 'themed' | 'metal';
};

export const Container = styled.span<ContainerProps>`
  --color1: ${p => (p.$color === 'blue' ? '#2E48ED' : p.theme.colors.logo.main)};
  --color2: ${p => (p.$color === 'blue' ? '#2E48ED' : p.theme.colors.logo.alt)};
  font-size: ${p => p.$size}px;
  display: inline-flex;
  gap: 0.26em;

  ${p => p.$animation === 'loop' && css`
    ${SvgLogo} {
      animation: ${rotateAnimation} 4s linear infinite;
    }
  `}

  ${p => p.$animation === 'hover' && css`
    &:hover ${SvgLogo} {
      animation:
        ${rotateAnimation} 1s cubic-bezier(0.82, 0.21, 0.15, 0.88)${p.$animateColor && css`, ${colorAnimation} .5s ease-in 2 alternate;`}
    }
  `}
`;
