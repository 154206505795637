import { ActionButton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

export const ActionButtonStyled = styled(ActionButton)<{ context?: 'doc-item' }>`
  gap: 4px;
  white-space: nowrap;
  ${p => p.counter && css`
    border: 1px solid ${p.theme.colors.border.primary}
  `}

  ${p => p.context === 'doc-item' && css`
    height: 24px;
    min-width: 24px;
    border-radius: 6px;
    --color: ${p.theme.colors.docItem.actionButton.color};
    --colorHover: ${p.theme.colors.docItem.actionButton.colorHover};
    --bgHover: ${p.theme.colors.docItem.actionButton.bgHover};
    --bgActive: ${p.theme.colors.docItem.actionButton.bgActive};

    ${p.counter && `
      --color: ${p.theme.colors.docItem.actionButton.colorAlt};
    `};
  `}

  ${mobile} {
    border: none;
  }
`;
