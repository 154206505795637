import { make } from 'src/utils/reactives.util';

export type DocAutomationWatchResult = {
  docIds: string[];
};

export const {
  hookState: useDocAutomationWatch,
  hookValue: useGetDocAutomationWatch,
  getValue: getDocAutomationWatch,
  setValue: setDocAutomationWatch,
  resetValue: resetDocAutomationWatch,
} = make<DocAutomationWatchResult>({
  defaultState: {
    docIds: [],
  },
});

export const addDocAutomationWatch = (docId: string) => {
  setDocAutomationWatch({
    docIds: Array.from(new Set([...getDocAutomationWatch().docIds, docId])),
  });
};

export const removeDocAutomationWatch = (docId: string) => {
  setDocAutomationWatch({
    docIds: getDocAutomationWatch().docIds.filter(id => id !== docId),
  });
};
