import { CaretIcon } from '@cycle-app/ui/icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP } from 'src/constants/editor.constants';
import { useFeatureSectionOpen, updateFeatureCollapsedSection } from 'src/reactives/featureDoc.reactive';

type Props = {
  sectionId: string;
  title: ReactNode | ((open: boolean) => ReactNode);
  children: ReactNode | ((visibleAnimationComplete: boolean) => ReactNode);
  onChange?: (collapsed: boolean) => void;
  noMarginContent?: boolean;
  endSlot?: (open: boolean) => ReactNode;
};

export const FeatureDocCollapsible = ({
  sectionId, title, children, onChange, noMarginContent, endSlot,
}: Props) => {
  const open = useFeatureSectionOpen(sectionId);
  const [visibleAnimationComplete, setVisibleAnimationComplete] = useState(true);
  return (
    <Collapsible.Root
      open={open}
      onOpenChange={value => {
        updateFeatureCollapsedSection(sectionId, value);
        onChange?.(!value);
      }}
    >
      <div className="flex w-full items-center justify-between gap-4" style={widthStyle}>
        <Collapsible.Trigger
          className="-mx-2 flex h-6 max-w-full cursor-pointer items-center gap-2 overflow-hidden rounded px-2 outline-none focus-visible:ring"
        >
          <CaretIcon size={10} direction={open ? 'bottom' : 'right'} />
          <div className="truncate text-body font-medium">
            {typeof title === 'function' ? title(open) : title}
          </div>
        </Collapsible.Trigger>

        {endSlot?.(open)}
      </div>
      
      <AnimatePresence initial={false}>
        {open && (
          <Collapsible.Content asChild forceMount>
            <motion.div
              {...!noMarginContent && { style: widthStyle }}
              variants={{
                hidden: {
                  opacity: 0,
                  height: 0, 
                  overflow: 'hidden',
                },
                visible: {
                  opacity: 1,
                  height: 'auto',
                  overflow: 'visible',
                  transition: {
                  // transitionEnd does not always work
                    overflow: { delay: 1 },
                  },
                },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onAnimationStart={definition => {
                if (definition === 'visible') {
                  setVisibleAnimationComplete(false);
                }
              }}
              onAnimationComplete={definition => {
                setVisibleAnimationComplete(definition === 'visible');
              }}
            >
              <div className={twJoin(!noMarginContent && 'pb-2 pt-4')}>
                {typeof children === 'function' ? children(visibleAnimationComplete) : children}
              </div>
            </motion.div>
          </Collapsible.Content>
        )}
      </AnimatePresence>
    </Collapsible.Root>
  );
};

const widthStyle = {
  maxWidth: EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2,
  padding: `0 ${EDITOR_MARGIN}px`,
  margin: '0 auto',
};
