import { Color, ThemeType } from '@cycle-app/graphql-codegen';

import { DeepPartial } from './partial.types';
import { colors as eclipseColors } from '../theme/colorThemes/eclipse';

type BaseColorTheme = typeof eclipseColors;
export type SubColorTheme = DeepPartial<BaseColorTheme>;

export const COLOR_LETTERS = Object.values(Color);
export const COLOR_THEMES_TYPES: [ThemeType.Nightfall, ThemeType.Sunrise] = [ThemeType.Nightfall, ThemeType.Sunrise];
