import { DropdownCss } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  ${DropdownCss};

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 8px 0;
  width: 458px;
  max-width: 40vw;

  ${mobile} {
    min-width: 330px;
    max-height: 136px;
  }
`;
