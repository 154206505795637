import { produce } from 'immer';

import { make } from 'src/utils/reactives.util';

import { LocalKey } from '../types/localStorage.types';

interface CreateDocState {
  modalVisible: boolean;
  customerId: string | null;
  doctypeId: string | null;
  // { [doctypeId]: docId }
  drafts: Record<string, string>;
  draftTitles: Record<string, string>;
}

export const {
  hookValue: useGetCreateDoc,
  getValue: getCreateDoc,
  setValue: setCreateDoc,
  resetValue: resetCreateDoc,
} = make<CreateDocState>({
  defaultState: {
    modalVisible: false,
    customerId: null,
    doctypeId: null,
    drafts: {},
    draftTitles: {},
  },
  localKey: LocalKey.DocDraft,
  omitFromLocalKey: ['modalVisible', 'doctypeId', 'customerId'],
  crossTabSync: true,
});

export const hideCreateDocModal = () => setCreateDoc({
  modalVisible: false,
});

export const setDraftDocId = (docTypeId: string, docId: string) => setCreateDoc({
  drafts: produce(getCreateDoc().drafts, drafts => {
    // eslint-disable-next-line no-param-reassign
    drafts[docTypeId] = docId;
  }),
});

export const setDraftTitle = (docTypeId: string, title: string) => setCreateDoc({
  draftTitles: produce(getCreateDoc().draftTitles, draftTitles => {
    // eslint-disable-next-line no-param-reassign
    draftTitles[docTypeId] = title;
  }),
});

export const removeDraft = (doctypeId: string | null) => setCreateDoc({
  drafts: produce(getCreateDoc().drafts, drafts => {
    if (!doctypeId) return;
    // eslint-disable-next-line no-param-reassign
    delete drafts[doctypeId];
  }),
  draftTitles: produce(getCreateDoc().draftTitles, draftTitles => {
    if (!doctypeId) return;
    // eslint-disable-next-line no-param-reassign
    delete draftTitles[doctypeId];
  }),
});
