import { CreateReleaseNoteDocument, CreateReleaseNoteMutationVariables, ReleaseNoteBaseFragment } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCreateReleaseNote = (onCompleted?: (data: ReleaseNoteBaseFragment) => void) => {
  const [mutate, { loading }] = useSafeMutation(CreateReleaseNoteDocument, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (!data?.createReleaseNote) return;
      onCompleted?.(data.createReleaseNote);
    },
  });

  const createReleaseNote = (variables: CreateReleaseNoteMutationVariables) => mutate({
    variables,
  });

  return {
    createReleaseNote,
    isCreatingReleaseNote: loading,
  };
};
