import { LinearIssueFullFragment, LinearProjectFragment } from '@cycle-app/graphql-codegen';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

import { AutomationType } from '../types/automation.types';

export type LinearModalState = {
  integrationId: string | null;
  docId: string | null;
  type: AutomationType;
  searchText: string | null;
  lastTeamId: string | null;
  lastStatusId: string | null;
  lastAssigneeId: string | null;
  lastProjectId: string | null;
  teamIds: string[];
  onCreate: ((data: LinearProjectFragment | LinearIssueFullFragment) => void) | null;
};

export const {
  hookValue: useGetLinearModal,
  getValue: getLinearModal,
  setValue: setLinearModal,
} = make<LinearModalState>({
  localKey: LocalKey.Linear,
  omitFromLocalKey: ['integrationId', 'docId', 'type', 'searchText', 'onCreate'],
  defaultState: {
    integrationId: null,
    docId: null,
    type: null,
    searchText: null,
    lastTeamId: null,
    lastStatusId: null,
    lastAssigneeId: null,
    lastProjectId: null,
    teamIds: [],
    onCreate: null,
  },
});

export const closeLinearModal = () => setLinearModal({ type: null });
