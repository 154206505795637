import { CheckIcon, CloseIcon } from '@cycle-app/ui/icons';
import { ComponentProps, useReducer } from 'react';

import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import { setDocImport } from 'src/reactives/docImport.reactive';

import { CreateButton, DiscardButton } from './FileCard.styles';

type Props = ComponentProps<typeof CreateButton> & {
  docId: string;
  isCompact?: boolean;
};

export const CreateDocButton = ({
  docId, isCompact, onClick, ...props
}: Props) => {
  const [isLoading, setIsLoading] = useReducer(() => true, false);
  return (
    <CreateButton
      isLoading={isLoading}
      $isCompact={isCompact}
      onClick={() => {
        onClick?.();
        setDocImport({
          draftIdOpen: docId,
          onPublish: () => setIsLoading(),
        });
      }}
      {...props}
    >
      {isCompact ? <CheckIcon size={12} /> : 'Create'}
    </CreateButton>
  );
};

export const DiscardDocButton = ({
  docId, isCompact, onClick, ...props
}: Props) => {
  const { removeDoc } = useRemoveDoc({ toaster: false });
  return (
    <DiscardButton
      $isCompact={isCompact}
      onClick={() => {
        onClick?.();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        removeDoc(docId);
      }}
      {...props}
    >
      {isCompact ? <CloseIcon size={12} /> : 'Discard'}
    </DiscardButton>
  );
};
