import styled from 'styled-components';

import { body500 } from '../../theme/typo';
import { TextButton } from '../Buttons/TextButton/TextButton';

export const TriggerContainer = styled.div<{ $isDisabled: boolean }>`
  cursor: ${p => (p.$isDisabled ? 'default' : 'pointer')};
`;

export const SelectedValue = styled(TextButton)`
  display: flex;
  gap: 4px;
  padding-top: 0;
  padding-bottom: 0;
  ${body500};
  color: ${p => p.theme.colors.text.primary};

  svg {
    height: 8px;
    color: ${p => p.theme.colors.text.secondary};
  }
`;
