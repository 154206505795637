import { SectionType } from '@cycle-app/graphql-codegen';
import { InboxIcon, BulbIcon, RoadmapIcon } from '@cycle-app/ui/icons';

import { MainPageId, PageId } from 'src/constants/routing.constant';

export type SectionParent = 'inbox' | 'insight' | 'roadmap';

export const getSectionName = (sectionParent: SectionParent) => {
  if (sectionParent === 'inbox') return 'Feedback';
  if (sectionParent === 'insight') return 'Insights';
  return 'Roadmaps';
};

export const getSectionIcon = (sectionParent: SectionParent) => {
  if (sectionParent === 'inbox') return <InboxIcon />;
  if (sectionParent === 'insight') return <BulbIcon />;
  return <RoadmapIcon />;
};

export const getSectionType = (sectionParent: SectionParent) => {
  if (sectionParent === 'inbox') return SectionType.Feedback;
  if (sectionParent === 'insight') return SectionType.Insights;
  return SectionType.Roadmaps;
};

export const getViewPageId = (sectionParent: SectionParent): MainPageId => {
  if (sectionParent === 'inbox') return PageId.InboxView;
  if (sectionParent === 'insight') return PageId.InsightView;
  return PageId.RoadmapView;
};

export const getSectionPageId = (sectionParent: SectionParent): MainPageId => {
  if (sectionParent === 'inbox') return PageId.Inbox;
  if (sectionParent === 'insight') return PageId.Insight;
  return PageId.Roadmap;
};
