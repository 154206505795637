import { QueryHookOptions, useQuery } from '@apollo/client';
import { MeCustomerNodeDocument, MeCustomerNodeQuery } from '@cycle-app/graphql-codegen';

import { extract } from 'src/types/graphql.types';

import { useMaybeMeV2 } from '../../useMe';
import { useProduct } from '../../useProduct';

type Options = Pick<QueryHookOptions<MeCustomerNodeQuery>, 'fetchPolicy' | 'skip'>;

/** Get or fetch the customer node of the current user in the current product */
export const useMeCustomer = ({
  skip, ...options
}: Options) => {
  const { me } = useMaybeMeV2();
  const { product } = useProduct();

  const query = useQuery(MeCustomerNodeDocument, {
    skip: skip || !me?.id || !product?.id,
    fetchPolicy: 'cache-first',
    variables: {
      id: me?.id as string,
      productId: product?.id as string,
    },
    ...options,
  });

  return {
    me: extract('Me', query.data?.node)?.customer,
    ...query,
  };
};
