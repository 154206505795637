import { InfoIconOutline } from '@cycle-app/ui/icons';

import { Infos } from './DocLinear.styles';

export const PreviewAlert = ({ message }: { message: string }) => (
  <Infos>
    <InfoIconOutline />
    {message}
  </Infos>
);
