import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

import { getLastView } from './lastView.reactive';

type CustomFieldLastUsedState = {
  lastUsedCustomField: Record<string, string | boolean | null>;
};

const {
  getValue,
  setValue,
} = make<CustomFieldLastUsedState>({
  defaultState: {
    lastUsedCustomField: {},
  },
  localKey: LocalKey.LastUsedCustomField,
});

export const setLastCustomFieldUsed = (fieldId: string, fieldValue: string | boolean | null) => {
  const { lastUsedCustomField } = getValue();
  const { productSlug } = getLastView();

  if (!fieldId || !productSlug) return;

  setValue({
    lastUsedCustomField: {
      ...lastUsedCustomField,
      [`${productSlug}--${fieldId}`]: fieldValue,
    },
  });
};

export const getLastCustomFieldUsed = (fieldId: string) => {
  const { lastUsedCustomField } = getValue();
  const { productSlug } = getLastView();

  return lastUsedCustomField[`${productSlug}--${fieldId}`] ?? null;
};
