import { FC } from 'react';
import { useController, Control, useWatch } from 'react-hook-form';
import { isPresent } from 'ts-is-present';

import { GithubStatusesSelectDropdown } from 'src/components/GithubStatusesSelectDropdown/GithubStatusesSelectDropdown';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setLastGitHubDataUsed } from 'src/reactives/githubRepository.reactive';
import { CreateGithubIssueFormValues } from 'src/types/integrations.types';

import { Container, Label, Caret, Select } from './StatusesField.styles';

type Props = {
  control: Control<CreateGithubIssueFormValues>;
};

export const StatusesField: FC<React.PropsWithChildren<Props>> = ({ control }) => {
  const projectValue = useWatch({
    control,
    name: 'project',
  });

  const { statusesByProjects } = useGithubProject();
  const [isVisible, {
    toggleCallback, setFalseCallback,
  }] = useOptimizedBooleanState(false);
  const {
    field: {
      onChange, onBlur, name, ref, value,
    },
  } = useController({
    name: 'statusId',
    control,
    defaultValue: null,
  });

  const projectId = projectValue?.id;
  const statuses = projectId
    ? statusesByProjects?.[projectId]?.options.filter(isPresent)
    : null;

  return (
    <Container>
      <Label>Status</Label>
      <GithubStatusesSelectDropdown
        statuses={statuses || []}
        isVisible={isVisible}
        onClose={() => {
          onBlur();
          toggleCallback();
        }}
        selectedStatus={value || ''}
        onChange={(newStatus) => {
          const newStatusId = newStatus === 'none' ? null : newStatus.id;
          onChange(newStatusId);
          setLastGitHubDataUsed(newStatusId, 'statusId');
          setFalseCallback();
        }}
        isLoading={false}
      >
        <Select
          name={name}
          ref={ref}
          onClick={toggleCallback}
          type="button"
          forceFocus={isVisible}
          disabled={!statuses?.length}
        >
          {value ? statuses?.find(s => s.id === value)?.name ?? '' : 'None'}
          <Caret />
        </Select>
      </GithubStatusesSelectDropdown>
    </Container>
  );
};
