import {
  FetchCompanyLogoDocument,
  FetchCompanyLogoQueryVariables,
  FetchCompanyLogoQuery,
  CompanyLogoInput,
} from '@cycle-app/graphql-codegen';

import { useSafeAsyncLazyQuery } from '../useSafeAsyncLazyQuery';

export const useFetchCompanyLogo = () => {
  const [fetch, { loading }] = useSafeAsyncLazyQuery<FetchCompanyLogoQuery, FetchCompanyLogoQueryVariables>(FetchCompanyLogoDocument);

  return {
    fetch: (input: CompanyLogoInput) => fetch({
      variables: { input },
    }),
    isLoading: loading,
  };
};
