import { StatusFragment } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useGetStatus } from './useGetStatus';
import { useUpdateStatus } from './useUpdateStatus';

export const useUpdateStatusWithId = () => {
  const getStatus = useGetStatus();
  const { updateStatus } = useUpdateStatus();
  return useCallback((statusId: string, newStatus: Partial<StatusFragment>) => {
    const status = getStatus(statusId);
    if (!status) return null;
    return updateStatus({
      ...status,
      ...newStatus,
    });
  }, [getStatus, updateStatus]);
};
