import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FramerIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path d="M4.38887 0H20.262V7.99977H12.3255L4.38887 0Z" fill="currentColor" />
    <path d="M4.38887 7.99902H12.3255L20.262 15.9991H4.38887V7.99902Z" fill="currentcolor" />
    <path d="M4.38887 15.999H12.3255V23.9991L4.38887 15.999Z" fill="currentcolor" />
  </Svg>
);
