import { useBoardSlug } from 'src/hooks/usePathParams';

import { useRoadmapSection } from './useRoadmapSection';

export const useRoadmapViewCurrent = () => {
  const boardSlug = useBoardSlug();
  const { boards } = useRoadmapSection();
  if (!boardSlug) return undefined;
  return boards.find(board => boardSlug.includes(board.id));
};
