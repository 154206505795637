import { CustomAttributeDefinitionFragment, OperatorIsEmptyOrNot } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useCompatibility } from 'src/hooks/useCompatibility';

import { useGetGroup } from '../cache/cacheGroupHooks';
import { useFullDoc } from '../useDoc';
import {
  getDefaultCheckboxAttributeValue,
  getDefaultDateAttributeValue,
  getDefaultEmailAttributeValue,
  getDefaultNumberAttributeValue,
  getDefaultPhoneAttributeValue,
  getDefaultTextAttributeValue,
  getDefaultUrlAttributeValue,
} from './attributeDefaultValuesFromFilters.util';
import { useAttributesFromBoardConfig } from './useAttributesFromBoardConfig';

interface Props {
  docId: string | null | undefined;
}

export const useDocAttributesFromBoardConfig = ({ docId }: Props) => {
  const { doc } = useFullDoc({ docId });
  const getGroup = useGetGroup();
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const attributesFromBoardConfig = useAttributesFromBoardConfig();
  const { getCompatibleOptions } = useCompatibility();
  const docAttributeDefinitions: CustomAttributeDefinitionFragment[] = nodeToArray(doc?.doctype?.attributeDefinitions);

  const readOnlyAttributeDefinitionIds = useMemo(() => {
    const ids: string[] = [
      ...groupByProperty ? [groupByProperty.id] : [],
    ];

    attributesFromBoardConfig.forEach(attribute => {
      const attributeDefinition = docAttributeDefinitions.find(a => a.id === attribute.attributeDefinitionId);
      if (
        attributeDefinition?.__typename === 'AttributeSingleSelectDefinition' ||
        attributeDefinition?.__typename === 'AttributeMultiSelectDefinition'
      ) {
        const compatibleOptions = getCompatibleOptions(attributeDefinition).map(a => a.node.id);
        const compatibleValues = nodeToArray(attributeDefinition.values)
          .filter(({ id }) => compatibleOptions.includes(id));
        if (compatibleValues.length <= 1) {
          ids.push(attribute.attributeDefinitionId);
        }
      } else {
        // all other attributes types from board config are readonly.
        ids.push(attribute.attributeDefinitionId);
      }
    });

    return ids;
  }, [docAttributeDefinitions, attributesFromBoardConfig, getCompatibleOptions, groupByProperty]);

  const getHiddenAttributeDefinitionIds = useCallback((groupId?: string) => {
    const isGroupNoValue = !groupId || !getGroup(groupId)?.node.propertyValue?.id;
    const ids: string[] = [
      ...isGroupNoValue && groupByProperty ? [groupByProperty.id] : [],
    ];
    nodeToArray(boardConfig?.filterProperties).forEach((node) => {
      if (
        node.__typename === 'FilterPropertyRuleMultiSelect' &&
        node.multiSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        node.multiSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        // Hidden only of "is empty" rule.
        // "Is not" rule: we can display the attribute, but incompatible values are hidden.
        // Other rules: the draft doc will have a default value.
        ids.push(node.attribute.id);
      }
      if (
        node.__typename === 'FilterPropertyRuleSingleSelect' &&
        node.singleSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        node.singleSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty
      ) {
        ids.push(node.attribute.id);
      }
      if (node.__typename === 'FilterPropertyRuleText') {
        const filterValue = getDefaultTextAttributeValue(node);
        // Could not inherit any value from board config.
        if (filterValue === undefined) {
          ids.push(node.attribute.id);
        }
      }
      if (node.__typename === 'FilterPropertyRuleEmail') {
        const filterValue = getDefaultEmailAttributeValue(node);
        if (filterValue === undefined) {
          ids.push(node.attribute.id);
        }
      }
      if (node.__typename === 'FilterPropertyRulePhone') {
        const filterValue = getDefaultPhoneAttributeValue(node);
        if (filterValue === undefined) {
          ids.push(node.attribute.id);
        }
      }
      if (node.__typename === 'FilterPropertyRuleUrl') {
        const filterValue = getDefaultUrlAttributeValue(node);
        if (filterValue === undefined) {
          ids.push(node.attribute.id);
        }
      }
      if (node.__typename === 'FilterPropertyRuleNumber') {
        const filterValue = getDefaultNumberAttributeValue(node);
        if (filterValue === undefined) {
          ids.push(node.attribute.id);
        }
      }
      if (node.__typename === 'FilterPropertyRuleDate') {
        const filterValue = getDefaultDateAttributeValue(node);
        if (filterValue === undefined) {
          ids.push(node.attribute.id);
        }
      }
      if (node.__typename === 'FilterPropertyRuleCheckbox') {
        const filterValue = getDefaultCheckboxAttributeValue(node);
        if (filterValue === undefined) {
          ids.push(node.attribute.id);
        }
      }
    });

    return ids;
  }, [getGroup, groupByProperty, boardConfig]);

  return {
    getHiddenAttributeDefinitionIds,
    readOnlyAttributeDefinitionIds,
  };
};
