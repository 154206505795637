import { MateBaseFragment } from '@cycle-app/graphql-codegen';
import { Tooltip, AvatarProps } from '@cycle-app/ui';
import { ReactNode } from 'react';

import { List, StyledAvatar, StyledSkeleton } from './AvatarList.styles';

export interface AvatarListProps {
  avatars: Pick<MateBaseFragment, 'email' | 'firstName' | 'id' | 'jobTitle' | 'avatar' | 'color'>[];
  className?: string;
  isLoading: boolean;
  after?: ReactNode;
  skeletonCount: number;
  size?: AvatarProps['size'];
}

export const AvatarList = ({
  avatars, isLoading, skeletonCount, after, className, size = 32,
}: AvatarListProps) => {
  return (
    <List $size={size} className={className}>
      {!avatars.length && isLoading && [...Array(skeletonCount).keys()].map((_, i) => (
        <li
          key={i}
          style={{ zIndex: skeletonCount - i }}
        >
          <StyledSkeleton $size={size} $isMasked={i > 0} />
        </li>
      ))}
      {avatars.map((user, i) => (
        <li
          key={user.id}
          style={{ zIndex: avatars.length - i }}
        >
          <Tooltip placement="top" content={user.firstName || user.email}>
            <StyledAvatar
              $size={size}
              $isMasked={i > 0}
              src={user.avatar?.url}
              user={{
                color: user.color,
                email: user.email,
                firstName: user.firstName || user.email,
              }}
              pending={!user.jobTitle}
              size={size}
            />
          </Tooltip>
        </li>
      ))}
      {after}
    </List>
  );
};
