import { motion, AnimatePresence } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { motionProps, variantsContent } from 'src/components/StateToasters';
import { useIsOffline, useIncrementOfflineAction, useOfflineActionCount, useOptimizedBooleanState } from 'src/hooks';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { Toaster } from './OfflineWarning.styles';
import { addListeners, removeListeners } from './OfflineWarning.utils';

export const OfflineWarning = () => {
  const isOffline = useIsOffline();

  return (
    <AnimatePresence>
      {isOffline && <OfflineWarningContent />}
    </AnimatePresence>
  );
};

const OfflineWarningContent = () => {
  const incrementOfflineAction = useIncrementOfflineAction();

  const preventAllActions = useCallback((event: Event) => {
    event.stopImmediatePropagation();
    event.preventDefault();
    incrementOfflineAction();
  }, [incrementOfflineAction]);

  useEffect(() => {
    addListeners(preventAllActions);

    return () => removeListeners(preventAllActions);
    /**
     * We don't want to add the preventAllActions cause we want to run this function
     * only when offline status is changing, otherwise it might add extra unwanted
     * listeners
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      variants={variantsContent}
      layout
      {...motionProps}
    >
      <OfflineWrapperToaster />
    </motion.div>
  );
};

const OfflineWrapperToaster = () => {
  const isMobile = useIsMobile();
  const [shouldShake, {
    setTrueCallback,
    setFalseCallback: unsetShake,
  }] = useOptimizedBooleanState(false);
  const count = useOfflineActionCount();

  const setShake = useThrottledCallback(setTrueCallback, 300);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    /**
     * We don't want to trigger the shake the first time (when count === 0)
     * otherwise the shake will happen even the user doesn't do anything
     */
    if (count) {
      setShake();
      timeoutId = setTimeout(unsetShake, 500);
    }

    return () => {
      clearTimeout(timeoutId);
    };
    /**
     * Only trigger the function when count is changing
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <Toaster
      $hasShake={shouldShake}
      label={isMobile ? "Looks like you're offline" : undefined}
    />
  );
};
