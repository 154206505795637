import { Spinner } from '@cycle-app/ui';
import { FC, HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';

import { useEditorContext } from 'src/contexts/editorContext';
import { setTranscriptTooltip } from 'src/reactives/transcript.reactive';
import { insertFile } from 'src/utils/editor/editor.utils';

import { DropArea } from './ContainerDropFile.styles';
import Placeholder from './Placeholder/DropPlaceholder';

interface Props extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

const ContainerDropFile: FC<React.PropsWithChildren<Props>> = ({
  children,
  disabled,
  ...htmlProps
}) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const onUpload = useEditorContext(ctx => ctx.onUpload);
  const isUploading = useEditorContext(ctx => ctx.isUploading);
  const uploadingSource = useEditorContext(ctx => ctx.uploadingSource);
  const {
    getRootProps,
    isDragActive,
  } = useDropzone({
    onDropAccepted,
    disabled,
    noClick: true,
  });

  const isActive = !disabled &&
    (
      isDragActive ||
      (isUploading && uploadingSource && ['paste', 'drop'].includes(uploadingSource))
    );

  return (
    <div
      {...htmlProps}
      {...getRootProps()}
    >
      {children}
      {!!isActive && (
        <DropArea>
          {isUploading ? <Spinner /> : <Placeholder />}
        </DropArea>
      )}
    </div>
  );

  function onDropAccepted(acceptedFiles: File[]) {
    // Open discovered tooltip only once
    let transcriptOpened = false;
    for (const file of acceptedFiles) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onUpload?.(file, 'drop')
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        .then(uploadedFile => {
          if (!uploadedFile) return;

          const dataId = insertFile({
            editor,
            file: uploadedFile,
          });

          if (!transcriptOpened) {
            transcriptOpened = true;
            setTranscriptTooltip(dataId, uploadedFile);
          }
        });
    }
  }
};

export default ContainerDropFile;
