import { FC } from 'react';

import fs from './full-story.png';

type Props = {
  className?: string;
  size?: number;
};

export const FullStoryIcon: FC<React.PropsWithChildren<Props>> = ({
  className, size,
}) => (
  <img className={className} width={size || 16} src={fs} alt="FullStory" />
);
