import { typo, Button, ShyScrollbarCss } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Modal = styled(PortalModal)`
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  flex: none;
  padding: 22px 32pX;
  border-bottom: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey200)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Title = styled.h1`
  ${typo.headerMedium}
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(Button).attrs({
  size: 'M',
})`
  padding: 0 12px;
  height: 32px;
`;

export { StyledButton as Button };

export const Content = styled.div`
  ${ShyScrollbarCss}
  padding: 32px;
  flex: 1;
  overflow: auto;
  display: grid;
  align-content: flex-start;
  gap: 32px;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  
  grid-template-columns: repeat(3, minmax(416px, 450px));

  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, minmax(416px, 450px));
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(auto, 450px));
  }
`;

export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 416px;
`;
