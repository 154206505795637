import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetDocPanel,
  getValue: getDocPanel,
  setValue: setDocPanel,
} = make({
  defaultState: {
    isEditorInit: false,
  },
});

export const {
  hookValue: useGetExportQuotesModal,
  setValue: setExportQuotesModal,
} = make({
  defaultState: {
    isVisible: false,
  },
});

export const showExportQuotesModal = () => setExportQuotesModal({ isVisible: true });
export const hideExportQuotesModal = () => setExportQuotesModal({ isVisible: false });
