import { getTestId, TestId } from '@cycle-app/utilities';
import styled from 'styled-components';

import { body500 } from '../../theme/typo';

export const StateToaster = styled.div.attrs(getTestId(TestId.StateToaster))`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 60px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  background-color: ${p => p.theme.colors.components.StateToaster.bg};
  backdrop-filter: blur(12px);
  color: ${p => p.theme.colors.components.StateToaster.text};
  ${body500}

  /**
   * Look like the icon look better when it's bigger than the font-size
   */
  > svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
`;
