import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.colors.body.bg};
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  background: inherit;
`;
