import { ShyScrollbarCss, Button } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const Modal = styled(PortalModal)`
  ${ShyScrollbarCss}
  width: 450px;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const ModalActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DeleteButton = styled(Button)`
  padding: 3px 4px;
`;
