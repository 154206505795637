import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useDocTypeInsight } from 'src/hooks/api/queries/docTypes/useDocTypeInsight';
import { CustomPropertyFormData } from 'src/types/property.types';
import { getCustomPropertyValue, getPropertiesFromDocType } from 'src/utils/properties.util';

export const useDocCommonInsightProperties = (attributes: DocBaseFragment['attributes'] | null) => {
  const { insight: insightDocType } = useDocTypeInsight();

  const customPropertiesData = useMemo(() => {
    const insightDoctypeProperties = getPropertiesFromDocType(insightDocType);
    const specificProperties: CustomPropertyFormData[] = [];
    const commonProperties: CustomPropertyFormData[] = [];
    const feedbackDocAttributes = nodeToArray(attributes);

    insightDoctypeProperties.forEach(insightProperty => {
      const correspondingFeedbackProperty = feedbackDocAttributes.find(e => e.definition.id === insightProperty.id);

      if (correspondingFeedbackProperty) {
        commonProperties.push({
          id: insightProperty.id,
          definition: insightProperty,
          inheritValue: getCustomPropertyValue(correspondingFeedbackProperty),
        });
      } else {
        specificProperties.push({
          id: insightProperty.id,
          definition: insightProperty,
          inheritValue: null,
        });
      }
    });

    return {
      // common properties that were set in the feedback.
      common: commonProperties,
      specific: specificProperties,
    };
  }, [attributes, insightDocType]);

  return customPropertiesData;
};
