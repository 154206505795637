import { BoardWithDraftConfigFragment } from '@cycle-app/graphql-codegen';
import { useState, useCallback } from 'react';

import { InboxCreateViewModal } from 'src/components/InboxCreateView/InboxCreateViewModal';
import { InsightViewCreateModal } from 'src/components/InsightViewCreate/InsightViewCreateModal';
import { RoadmapViewCreateModal } from 'src/components/RoadmapViewCreate/RoadmapViewCreateModal';
import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import ThemeProvider from 'src/providers/ThemeProvider';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';

import { BoardConfigContextProvider } from '../../contexts/boardConfigContext';
import { useInboxSection } from '../inbox';
import { useInsightSection } from '../insight/useInsightSection';
import { useRoadmapSection } from '../roadmap/useRoadmapSection';

type State = {
  showModal: boolean;
  board: BoardWithDraftConfigFragment | null;
  sectionId: string | null;
  sidebarSectionId: string | null;
};

export const useBoardCreateFromSection = () => {
  const { canCreateView } = useGetPermission();
  const { section: inboxSection } = useInboxSection();
  const { section: insightSection } = useInsightSection();
  const { section: roadmapSection } = useRoadmapSection();
  const { canPlanCreateViewCustom } = useGetBillingPermission();
  const {
    addSectionDraftBoard,
    abortSectionDraftBoard,
  } = useProductMutations();

  const [draftBoard, setDraftBoard] = useState<State>({
    showModal: false,
    board: null,
    sectionId: null,
    sidebarSectionId: null,
  });

  const createBoard = useCallback(async ({
    sectionId, sidebarSectionId,
  }: { sectionId: string; sidebarSectionId: string }) => {
    if (!canPlanCreateViewCustom || !canCreateView) {
      setLimitationsModal({ action: 'VIEW_CUSTOM_CREATE' });
      return;
    }
    setDraftBoard({
      showModal: true,
      board: null,
      sectionId,
      sidebarSectionId,
    });

    // We can now only create views in built-in sections.
    const result = await addSectionDraftBoard({ sectionId }, true);
    if (!result.data?.createDraftBoard) return;

    setDraftBoard({
      showModal: true,
      board: result.data?.createDraftBoard,
      sectionId,
      sidebarSectionId,
    });
  }, [addSectionDraftBoard, canCreateView, canPlanCreateViewCustom]);

  const resetDraftBoard = () => setDraftBoard({
    showModal: false,
    board: null,
    sectionId: null,
    sidebarSectionId: null,
  });

  const hide = () => {
    abortSectionDraftBoard();
    resetDraftBoard();
  };

  const createBoardModal = draftBoard.showModal ? (
    <ThemeProvider>
      <BoardConfigContextProvider>
        {draftBoard.sectionId === inboxSection?.id && (
          <InboxCreateViewModal draftBoard={draftBoard.board} hide={hide} sidebarSectionId={draftBoard.sidebarSectionId} />
        )}
        {draftBoard.sectionId === insightSection?.id && (
          <InsightViewCreateModal draftBoard={draftBoard.board} hide={hide} sidebarSectionId={draftBoard.sidebarSectionId} />
        )}
        {draftBoard.sectionId === roadmapSection?.id && (
          <RoadmapViewCreateModal draftBoard={draftBoard.board} hide={hide} sidebarSectionId={draftBoard.sidebarSectionId} />
        )}
      </BoardConfigContextProvider>
    </ThemeProvider>
  ) : null;

  return {
    createBoard,
    createBoardModal,
  };
};
