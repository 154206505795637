import { useLocationSelector } from 'src/hooks';
import { history } from 'src/providers';

export const useIsBoardActive = ({
  boardId, isStarred = false,
}: {
  boardId?: string;
  isStarred?: boolean;
}) => {
  const isLocationActive = useLocationSelector(location => {
    if (!boardId) return false;
    if (!location.pathname.includes(boardId)) return false;
    return isStarred ? location.state?.fromStarredBoard : !location.state?.fromStarredBoard;
  });

  return isLocationActive;
};

export const isBoardActive = ({
  boardId, isStarred = false,
}: {
  boardId?: string;
  isStarred?: boolean;
}) => {
  if (!boardId) return false;
  const { location } = history;
  if (!location.pathname.includes(boardId)) return false;
  return isStarred ? location.state?.fromStarredBoard : !location.state?.fromStarredBoard;
};
