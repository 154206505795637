import { AddIcon } from '@cycle-app/ui/icons';

import { useCreateViewModal } from 'src/hooks/useCreateViewModal';
import { SectionParent } from 'src/utils/section.utils';

import { StyledButton } from './ViewHeader.styles';

type Props = {
  sectionParent: SectionParent;
};

export const AddViewButton = ({ sectionParent }: Props) => {
  const {
    openModal, modal,
  } = useCreateViewModal({ sectionParent });
  return (
    <>
      <StyledButton
        iconStart={<AddIcon size={14} />}
        onClick={() => openModal()}
      >
        Add view
      </StyledButton>
      {modal}
    </>
  );
};
