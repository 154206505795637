import { SyncDocAutomationContentDocument } from '@cycle-app/graphql-codegen';
import { format } from 'date-fns';

import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { addToaster } from 'src/utils/toasters.utils';

import useSafeMutation from '../useSafeMutation';

// eslint-disable-next-line max-len
const regex = /^Last sent to Linear on (January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4} - \d{2}:\d{2} (AM|PM)$/;

export const useDocSyncAutomationContent = () => {
  const [syncAutomationContentMutation, { loading }] = useSafeMutation(SyncDocAutomationContentDocument);
  const editor = useDocLinearContext(ctx => ctx.editor);
  const docId = useDocLinearContext(ctx => ctx.docId);
  const automationUrl = useDocLinearContext(ctx => ctx.automationUrl);
  // Do not handle all the cases, test only if the first paragraph matches the synced text.
  const node = editor?.state.doc.nodeAt(0);
  const isSynced = node?.toJSON().type === 'paragraph' && node.textContent?.match(regex);

  const syncAutomationContent = async () => {
    if (!docId) return null;
    const result = await syncAutomationContentMutation({ variables: { docId } });
    if (result.data?.syncDocAutomationContent?.id) {
      if (editor) {
        // eslint-disable-next-line max-len
        const html = `<p><mark data-mark-id="linear-synced-content" data-mark-type="linear-synced-content">Last sent to Linear on ${format(new Date(), 'MMMM dd, yyyy - hh:mm a')}</mark></p>`;
        if (isSynced) {
          // Replace the paragraph if the content contains a synced mark.
          editor.commands.insertContentAt({
            from: 0,
            to: node.nodeSize,
          }, html);
        } else {
          editor.commands.insertContentAt(0, html);
        }
      }
      if (automationUrl) {
        addToaster({
          id: automationUrl,
          title: 'Content successfully sent',
          message: <a target="_blank" href={automationUrl} rel="noreferrer">Click here to open in Linear</a>,
        });
      }
    }
    return result;
  };

  return {
    syncAutomationContent,
    isSynced,
    isLoading: loading,
  };
};
