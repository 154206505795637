import { useSubscription } from '@apollo/client';
import { AutopilotStateDocument, AutopilotState } from '@cycle-app/graphql-codegen';

export const useAutopilotStateSubscription = (
  docId?: string | null,
  onData?: (data: AutopilotState | null | undefined) => void,
) => useSubscription(
  AutopilotStateDocument,
  {
    skip: !docId,
    variables: {
      docId: docId as string,
    },
    async onSubscriptionData({ subscriptionData: { data } }) {
      if (!data) return;
      onData?.(data.autopilotState);
    },
  },
);
