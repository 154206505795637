import { useDocV2 } from 'src/hooks';

import { Doc } from './Ask.types';
import { QuoteCard } from '../CustomerCompanyProfile/QuoteCard';

export const AnswerDoc = ({ doc }: { doc: Doc }) => {
  // The ask subscription already includes the fragment in `DocNode` query,
  // so there should not be loading/request here.
  const data = useDocV2(doc.id);
  if (!data.doc) return null;
  return (
    <QuoteCard
      doc={data.doc}
      showCustomer
    />
  );
};
