import { DownIcon } from '@cycle-app/ui/icons';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useDocThreads } from 'src/hooks/api/queries/useDocThreads';
import { setMainThread, setThreadsPanel, useThreadsPanel } from 'src/reactives/comments.reactive';

import { Action } from './DocComments.styles';

export const DocChatNav = ({
  threadId, docId, blockId = null, hide,
}: {
  threadId?: string;
  docId: string;
  blockId: string | null;
  hide?: VoidFunction;
}) => {
  const { section } = useThreadsPanel();
  const {
    threads, resolvedIds,
  } = useDocThreads(docId, { section });

  const {
    threadCount, currIndex, prevIndex, nextIndex, openThread,
  } = useMemo(() => {
    // blockIds of the resolved threads
    const threadsBlockIds = threads
      .map(thread => ({
        blockId: thread.comments?.edges[0]?.node.blockId,
        threadId: thread.id,
      }));

    // blockIds of the comment marks in the editor
    const editorBlockIdsWithDupes = [...document.querySelectorAll('[data-mark-type="comment"]:not([resolved="true"])')]
      .map(el => el.getAttribute('data-mark-id'))
      .filter(isPresent);

    const editorBlockIds = [...new Set(editorBlockIdsWithDupes)];

    const threadsWithMark = editorBlockIds
      .map(id => ({
        blockId: id,
        threadId: threads.find(thread => thread.comments?.edges[0]?.node.blockId === id)?.id,
      })).filter(t => !!t.threadId);

    const mainThreads = threadsBlockIds.filter(t => t.blockId === null && !resolvedIds.includes(t.threadId));

    const mainThreadsWithoutMark = threadsBlockIds
      .filter(t => (t.blockId === null && resolvedIds.includes(t.threadId)));

    const threadsWithoutMark = threadsBlockIds
      .filter(t => (t.blockId === null && resolvedIds.includes(t.threadId)) || (t.blockId && !editorBlockIds.includes(t.blockId)));

    // eslint-disable-next-line no-nested-ternary
    const blockIds = section === 'open'
      ? [...mainThreads, ...threadsWithMark]
      : section === 'closed'
        ? [...threadsWithMark, ...mainThreadsWithoutMark]
        : [...mainThreads, ...threadsWithMark, ...threadsWithoutMark];

    const index = blockIds.findIndex(item => item.blockId === blockId && (!threadId || item.threadId === threadId));

    return {
      threadCount: blockIds.length,
      currIndex: index,
      prevIndex: index === 0 ? null : index - 1,
      nextIndex: index === blockIds.length - 1 ? null : index + 1,
      openThread: (i: number | null) => {
        if (i === null) return;
        const item = blockIds[i];
        if (!item) return;

        // main thread
        if (item.blockId === null && item.threadId && !resolvedIds.includes(item.threadId)) {
          hide?.();
          document.querySelector('.doc-panel-comments')?.scrollIntoView();
          setMainThread({ isVisible: true });
          setThreadsPanel({ openBlockId: item.threadId });
          return;
        }

        const element = document.querySelector(`[data-mark-id="${item.blockId}"]`);
        if (!item.blockId || !(element instanceof HTMLElement)) {
          hide?.();
          setThreadsPanel({
            visibleThreadId: item.threadId,
            openBlockId: item.threadId,
          });
          return;
        }

        hide?.();
        element.scrollIntoView();
        element.click();
        setThreadsPanel({ openBlockId: item.threadId });
      },
    };
  }, [threads, section, resolvedIds, blockId, threadId, hide]);

  return (
    <>
      <Action
        disabled={prevIndex === null || currIndex < 0}
        onClick={() => openThread(prevIndex)}
      >
        <DownIcon direction="left" size={16} />
      </Action>

      <Action
        disabled={nextIndex === null || currIndex < 0}
        onClick={() => openThread(nextIndex)}
      >
        <DownIcon direction="right" size={16} />
      </Action>

      {currIndex > -1 && (
        <div>
          {`${currIndex + 1} of ${threadCount}`}
        </div>
      )}
    </>
  );
};
