import { CheckboxInput, CheckboxSquare, typo, ellipsis } from '@cycle-app/ui';
import { LinearIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Label = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  max-width: 140px;

  svg {
    flex: none;
  }
`;

interface StyledCheckboxInputProps {
  $size?: 'S' | 'M';
}

export const StyledCheckboxInput = styled(CheckboxInput)<StyledCheckboxInputProps>`
  label {
    ${typo.caption400}
    background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.baseColors.Grey100)};
    border-radius: 4px;
    color: ${p => p.theme.colors.text.secondary};
    flex-direction: row-reverse;
    height: 20px;
    padding: 0 4px;

    ${p => p.$size === 'M' && `
      ${typo.body400}
      height: 24px;
    `}
  }

  ${CheckboxSquare} {
    height: 12px;
    width: 12px;
  }
`;

export const StyledLinearIcon = styled(LinearIcon)`
  height: 12px;
  width: 12px;
`;

export const Type = styled.span`
  :first-letter {
    text-transform: uppercase;
  }
`;

export const Team = styled.span`
  ${ellipsis}
`;
