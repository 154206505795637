import {
  HideBoardConfigPropertyDocument,
  ShowBoardConfigPropertyDocument,
  HideAllPropertiesDocument,
  ShowAllPropertiesDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events, Methods, Objects } from 'src/constants/analytics.constants';
import { useGetDraftBoardConfigFromCache } from 'src/hooks/api/cache/cacheBoardConfig';
import { useBoard } from 'src/hooks/api/useBoard';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';

const trackDisplayedPropertyUpdated = () => trackAnalytics(Events.BoardUpdated, {
  method: Methods.UI,
  object: Objects.ToolbarDisplayProperty,
});
const trackingOptions = {
  onCompleted: trackDisplayedPropertyUpdated,
};

export default function useManageDisplayedProperties(boardConfigId: string, boardID?: string) {
  const [hideBoardConfigPropertyMutation] = useSafeMutation(HideBoardConfigPropertyDocument, trackingOptions);
  const [showBoardConfigPropertyMutation] = useSafeMutation(ShowBoardConfigPropertyDocument, trackingOptions);
  const [hideAllPropertiesMutation, { loading: loadingHideAllProperties }] = useSafeMutation(HideAllPropertiesDocument, trackingOptions);
  const [showAllPropertiesMutation, { loading: loadingShowAllProperties }] = useSafeMutation(ShowAllPropertiesDocument, trackingOptions);

  const board = useBoard(boardID);
  const getDraftBoardConfigFromCache = useGetDraftBoardConfigFromCache(board?.id ?? '');

  const hideBoardConfigProperty = useCallback((propertyId: string) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();

    const property = draftBoardConfig?.properties.edges.find(({ node }) => node.property.id === propertyId)?.node;

    return hideBoardConfigPropertyMutation({
      variables: {
        propertyId,
      },
      ...draftBoardConfig && property && {
        optimisticResponse: {
          hideBoardConfigProperty: {
            ...property,
            displayed: false,
          },
        },
      },
    });
  }, [hideBoardConfigPropertyMutation, getDraftBoardConfigFromCache]);

  const showBoardConfigProperty = useCallback((propertyId: string) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();

    const property = draftBoardConfig?.properties.edges.find(({ node }) => node.property.id === propertyId)?.node;

    return showBoardConfigPropertyMutation({
      variables: {
        propertyId,
      },
      ...draftBoardConfig && property && {
        optimisticResponse: {
          showBoardConfigProperty: {
            ...property,
            displayed: true,
          },
        },
      },
    });
  }, [showBoardConfigPropertyMutation, getDraftBoardConfigFromCache]);

  const hideAllProperties = useCallback(() => hideAllPropertiesMutation({
    variables: {
      boardConfigId,
    },
  }), [boardConfigId, hideAllPropertiesMutation]);

  const showAllProperties = useCallback(() => showAllPropertiesMutation({
    variables: {
      boardConfigId,
    },
  }), [boardConfigId, showAllPropertiesMutation]);

  return {
    hideBoardConfigProperty,
    showBoardConfigProperty,
    hideAllProperties,
    showAllProperties,
    loading: {
      hideAllProperties: loadingHideAllProperties,
      showAllProperties: loadingShowAllProperties,
    },
  };
}
