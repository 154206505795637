import { IntegrationType } from '@cycle-app/graphql-codegen';

import { useProductIntegrations } from 'src/hooks';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { openSourcesModal } from 'src/reactives/integrations.reactive';
import { useUserPreferencesValue } from 'src/reactives/userPreferences.reactive';

import {
  Section, Header, Title, Content, IntegrationsGrid, StyledButton, StyledIntegrationItem, StyledIntegrationSkeleton,
} from './HomeViewSources.styles';

export const HomeViewSources = () => {
  const {
    sourcesByStatus, isLoading,
  } = useProductIntegrations();
  const { integration: slack } = useSlackIntegration();
  const { integration: meetings } = useMeetingsIntegration();
  const sources = sourcesByStatus.installed.filter((item) => (
    // Remove from the list, they are always discoverable first.
    IntegrationType.Slack !== item.integrationType &&
    IntegrationType.Meeting !== item.integrationType
  ));
  const {
    callRecordingHidden, slackHidden,
  } = useUserPreferencesValue();
  return (
    <Section>
      <Header>
        <Title>Sources</Title>
        <StyledButton onClick={openSourcesModal} variant="nospace" size="M">Manage sources</StyledButton>
      </Header>

      <Content>
        {isLoading && (
          <IntegrationsGrid>
            {[...Array(3).keys()].map(i => <StyledIntegrationSkeleton key={i} />)}
          </IntegrationsGrid>
        )}
        {!isLoading && (
          <IntegrationsGrid>
            {!slackHidden && slack && (
              <StyledIntegrationItem
                integration={slack}
                integrationType={IntegrationType.Slack}
              />
            )}
            {!callRecordingHidden && meetings && (
              <StyledIntegrationItem
                integration={meetings}
                integrationType={IntegrationType.Meeting}
              />
            )}
            {sources.map((item) => (
              <StyledIntegrationItem
                key={item.integrationType}
                integration={item.integration}
                integrationType={item.integrationType}
              />
            ))}
          </IntegrationsGrid>
        )}
      </Content>
    </Section>
  );
};
