import { CompanyLogo } from '@cycle-app/ui';
import { useState } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { CommandActionCategory } from 'src/types/commandbar.types';

import { useCompanies } from '../api/queries/customers/useCompanies';
import { openCompanyModal } from '../modals/useCompanyModal';

export const CATEGORY_ID = 'companies';

export const useCompaniesResult = (enabled: boolean) => {
  const [defaultSearch, setDefaultSearch] = useState('');
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const {
    companies, isLoading,
  } = useCompanies({
    defaultSearch,
    skip: !enabled,
    productId,
  });

  const formatResults = (results: typeof companies): CommandActionCategory[] => [{
    id: CATEGORY_ID,
    label: 'Companies',
    actions: [
      ...results
        .map(company => ({
          id: company.id,
          icon: <CompanyLogo size="S" company={company} />,
          label: company.name || '',
          onSelect: () => {
            openCompanyModal(company.id);
          },
          filtered: true,
        })),
    ],
  }];

  return {
    searchCompanies: setDefaultSearch,
    defaultResult: formatResults(companies),
    isLoading,
  };
};
