import { mergeAttributes, Node } from '@tiptap/core';

import { IFRAMELY_EXTENSION_NAME, IFRAMELY_EXTENSION_TAG_NAME } from '../constants';

interface IFramelyAttributes {
  savedHtml?: string;
  url: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [IFRAMELY_EXTENSION_NAME]: {
      setIframely: (options: IFramelyAttributes) => ReturnType;
    };
  }
}

export const getEditorIframelyNode = (data: IFramelyAttributes) => ({
  type: IFRAMELY_EXTENSION_NAME,
  attrs: {
    ...data,
  },
});

export const getIframelyExtension = () => Node.create({
  name: IFRAMELY_EXTENSION_NAME,

  group: 'block',
  atom: true,
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      savedHtml: {
        default: null,
        parseHTML: e => {
          const attr = e.getAttribute('savedHtml');
          if (attr) {
            try {
              return JSON.parse(attr);
            } catch {
              return '';
            }
          }
          return '';
        },
        renderHTML: e => ({
          savedHtml: e.savedHtml ? JSON.stringify(e.savedHtml) : '',
        }),
      },
      url: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: IFRAMELY_EXTENSION_TAG_NAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      IFRAMELY_EXTENSION_TAG_NAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setIframely: (options: IFramelyAttributes) => ({ commands }) => commands.insertContent([getEditorIframelyNode({
        url: options.url,
        savedHtml: options.savedHtml,
      })]),
    };
  },
});
