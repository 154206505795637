import { isValid, parse, isAfter } from 'date-fns';

export const emailRegex = /^\S+@\S+\.\S+$/i;

export const isEmail = (value: string, strict = true) => (strict ? emailRegex.test(value) : value.includes('@'));

export const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,63}(\/[-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/i;
export const shortUrlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,63}(\/[-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/i;

export const isUrl = (
  value: string,
  options: {
    /** Strict = Mandatory protocol */
    strict?: boolean;
  } = {
    strict: true,
  },
) => (options.strict ? urlRegex : shortUrlRegex).test(value);

const domainRegex = /^(?!www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

export const isDomain = (value: string) => domainRegex.test(value);

const startsWithWWW = /^(www\.)/;

export const getDomain = (value: string) => {
  if (isUrl(value, { strict: false })) {
    try {
      const url = new URL(isUrl(value) ? value : `https://${value}`);
      return url.hostname.replace(startsWithWWW, '');
    } catch {
      return null;
    }
  }
  return null;
};

export const isLoomUrl = (value: string) => value.includes('loom.com/');
export const isNotionUrl = (value: string) => (value.includes('notion.so/') || value.includes('notion.site/'));
export const isDriveUrl = (value: string) => value.includes('drive.google.com/');

export const isValidInputDate = (value: string) => value.length === 10;

export const isCompleteDate = (value: string) => {
  const date = parse(value, 'yyyy-MM-dd', new Date());
  return isValid(date) && isAfter(date, new Date('1000'));
};

export const formatBillingAmount = (n: number) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(n);

export const formatSecondsToTime = (timeInSeconds: number) => {
  const isNegative = timeInSeconds < 0;
  let seconds = Math.abs(Math.ceil(timeInSeconds));
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);
  const sign = isNegative ? '-' : '';
  const formattedTime = `${sign}${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  return formattedTime;
};
