import { useSortable } from '@dnd-kit/sortable';

import { getSortableStyle } from 'src/utils/dnd.util';

import { SelectOptionsEditableLine, SelectOptionsEditableLineProps } from './SelectOptionsEditableLine';

export const SelectOptionsEditableLineSortable = (props: SelectOptionsEditableLineProps) => {
  const {
    option,
    isSortable,
  } = props;
  const {
    setNodeRef,
    transform,
    transition,
    attributes,
    listeners,
  } = useSortable({
    id: option.value,
    disabled: !isSortable,
  });

  const style = getSortableStyle({
    transform,
    transition,
  });

  return (
    <SelectOptionsEditableLine
      {...props}
      {...attributes}
      setNodeRef={setNodeRef}
      style={style}
      listeners={listeners}
    />
  );
};
