import { ring } from '@cycle-app/ui';
import styled from 'styled-components';

import { Action } from './CommandAction/CommandAction.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NoResult = styled(Action)`
  pointer-events: none;
  color: ${p => p.theme.colors.commandbar.action.colorHover};
  border-width: 0;
`;

export const DaysInput = styled.input`
  border: none;
  border-radius: 4px;
  padding: 4px 6px;
  width: 50px;
  background: ${p => (p.theme.isDark ? p.theme.colors.input.bg : p.theme.colors.background.white)};
  :focus { ${ring} }
`;
