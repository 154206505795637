import { UpdateDocCoverDocument, UpdateDocCoverMutationVariables } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from '../useSafeMutation';

export const useDocCoverUpdate = () => {
  const [update, { loading }] = useSafeMutation(UpdateDocCoverDocument);

  const updateDocCover = useCallback((params: UpdateDocCoverMutationVariables) => {
    return update({ variables: params });
  }, [update]);

  return {
    isLoading: loading,
    update: updateDocCover,
  };
};
