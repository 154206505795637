import { useEffect } from 'react';

import { useGetNetwork, setNetwork, getNetwork, resetNetwork } from 'src/reactives';

interface UseNetworkParams {
  onDisconnect: VoidFunction;
  onReconnect: VoidFunction;
}

const useNetworkListeners = ({
  onDisconnect,
  onReconnect,
}: UseNetworkParams) => {
  useEffect(() => {
    window.addEventListener('offline', onDisconnect);
    window.addEventListener('online', onReconnect);

    return () => {
      window.removeEventListener('offline', onDisconnect);
      window.removeEventListener('online', onReconnect);
    };
  }, []);
};

const setNetworkOnline = () => setNetwork({ isOffline: true });

export const useNetwork = () => {
  useNetworkListeners({
    onDisconnect: setNetworkOnline,
    onReconnect: resetNetwork,
  });
};

export const useIsOffline = () => {
  const { isOffline } = useGetNetwork();

  return isOffline;
};

export const useIncrementOfflineAction = () => {
  return () => {
    const {
      actionsCount, isOffline,
    } = getNetwork();

    if (!isOffline) return;

    setNetwork({ actionsCount: actionsCount + 1 });
  };
};

export const useOfflineActionCount = () => {
  const { actionsCount } = useGetNetwork();

  return actionsCount;
};
