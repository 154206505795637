import { BoardType } from '@cycle-app/graphql-codegen';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useOpenBoardConfig } from 'src/hooks/boards/useOpenBoardConfig';
import { setViewDeleteModal, setViewDuplicateModal, setViewExportModal } from 'src/reactives/viewModals.reactive';
import { Layer } from 'src/types/layers.types';

import { setBoardConfigAnalyticsOpen } from '../../reactives/boardConfigAnalytics.reactive';
import { DropdownLayer } from '../DropdownLayer';
import { ViewMenu } from './ViewMenu';

interface Props {
  viewId: string | null;
  clientX: number | null;
  clientY: number | null;
  hide: VoidFunction;
  isDeleteDisabled?: boolean;
  type: BoardType;
}

export const ViewContextMenu = ({
  viewId, hide, isDeleteDisabled, clientX, clientY, type,
}: Props) => {
  const openBoardConfig = useOpenBoardConfig();
  const boardConfigId = useBoardConfig(ctx => ctx.boardConfig?.id);
  return (
    <DropdownLayer
      withWrapper={false}
      withPortal
      layer={Layer.Dropdown}
      content={viewId && (
        <ViewMenu
          hide={hide}
          onOpenBoardConfig={async () => {
            if (type === BoardType.Custom) {
              await openBoardConfig(viewId);
            } else {
              setBoardConfigAnalyticsOpen();
            }
          }}
          boardConfigId={boardConfigId}
          isDeleteDisabled={isDeleteDisabled}
          onDeleteView={id => setViewDeleteModal({ deleteViewId: id })}
          onDuplicateView={id => setViewDuplicateModal({ duplicateViewId: id })}
          onExportView={id => setViewExportModal({ exportViewId: id })}
          viewId={viewId}
          type={type}
        />
      )}
      hide={hide}
      overridePosition={(clientY && clientX && {
        bottom: clientY + 1,
        height: 1,
        left: clientX,
        right: clientX + 1,
        top: clientY,
        width: 1,
      }) || undefined}
      placement="bottom-start"
      visible
      width={192}
      style={{ position: 'absolute' }}
    />
  );
};
