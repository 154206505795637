import { ComponentPropsWithRef } from 'react';

import {
  CardContainer,
  CardBody,
  IntegrationTitle,
  LogoSkeleton,
  TitleSkeleton,
} from './IntegrationCard.styles';

export const IntegrationCardSkeleton = (props: ComponentPropsWithRef<'div'>) => (
  <CardContainer {...props}>
    <CardBody>
      <IntegrationTitle>
        <LogoSkeleton />
        <TitleSkeleton />
      </IntegrationTitle>
    </CardBody>
  </CardContainer>
);
