import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  height: 100%;
  overflow: hidden;
`;

export const ListContainer = styled.div<{ $withBorder: boolean }>`
  flex: 1;
  overflow: hidden;
  ${p => p.$withBorder && `
    border-bottom: 1px solid ${p.theme.colors.home.separator};
  `}
`;
