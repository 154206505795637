import { CustomerFragment } from '@cycle-app/graphql-codegen';
import {
  SelectOption,
  Warning,
  typo,
  CustomerAvatar,
  TextHighlighter,
  LineHover,
  CompanyLogo,
} from '@cycle-app/ui';
import styled from 'styled-components';

export const SelectionIcon = styled(CustomerAvatar)`
  margin-right: 10px;
`;

export const Slot = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
  display: flex;
  gap: 16px;
`;

export const SlotGroup = styled(Slot)`
  gap: 6px;
`;

export const Name = styled.div`
  color: ${p => p.theme.colors.text.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${typo.body500}
`;

interface CustomerOptionLabelOptions { customer: CustomerFragment; filterText: string; lineHover?: JSX.Element }

export const renderCustomerOptionLabel = ({
  customer, filterText, lineHover,
}: CustomerOptionLabelOptions) => (
  <Slot>
    {customer.name?.trim() && (
      <Name>
        <TextHighlighter
          searchWords={[filterText]}
          textToHighlight={customer.name}
          className="highlight"
        />
      </Name>
    )}
    {customer.company?.name?.trim() && (
      <SlotGroup>
        <CompanyLogo company={customer.company} size="S" />
        <Name>
          <TextHighlighter
            searchWords={[filterText]}
            textToHighlight={customer.company.name}
            className="highlight"
          />
        </Name>
      </SlotGroup>
    )}
    {customer.email && (
      <TextHighlighter
        searchWords={[filterText]}
        textToHighlight={customer.email}
        className="highlight"
      />
    )}
    {lineHover && <LineHover>{lineHover}</LineHover>}
  </Slot>
);

export const getCustomerOption = (customer: CustomerFragment, showWarnings = true, docTypeName?: string): SelectOption => ({
  value: customer.id,
  label: customer.displayName,
  renderLabel: (filterText) => renderCustomerOptionLabel({
    customer,
    filterText,
  }),
  icon: <CustomerAvatar customer={customer} size="S" hideCompany />,
  end: showWarnings && !customer._compatibleWithBoardConfig &&
    <Warning tooltip={`The ${docTypeName?.toLocaleLowerCase() ?? 'doc'} will leave the view if you choose this value`} />,
});

/**
 * A default customer is a customer from the workspace, so every user who has
 * a CycleProfile are inside the current workspace so are considered as
 * default.
 */
export const isDefaultCustomer = (customer: CustomerFragment) => !!customer.profiles?.find(profile => profile?.__typename === 'ProfileCycle');

/**
 * Customer name or company name if it's the main contact
 */
export const getFeedbackCustomerName = (customer?: Partial<CustomerFragment> | null) => {
  if (!customer) return null;
  if (customer.displayName === 'Main contact' && customer.company?.name) return customer.company.name;
  return customer.displayName?.trim() || customer.name?.trim() || customer.email?.trim();
};
