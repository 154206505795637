import { useIdentify } from '@unrevealed/react';
import { useEffect } from 'react';

import { useProductBase, useMaybeMe } from 'src/hooks';

export const useFeatureFlagIdentifier = () => {
  const me = useMaybeMe();
  const product = useProductBase();
  const { identify } = useIdentify();

  useEffect(() => {
    const userTeam = product?.id ? {
      team: {
        id: product.id,
        traits: {
          name: `${product.name} (${product.slug})`,
        },
      },
    } : {};

    if (me?.id && product?.id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      identify({
        user: {
          id: me.id,
          traits: {
            email: me.email,
            firstName: me.firstName ?? null,
            lastName: me.lastName ?? null,
            role: me.role || null,
            jobTitle: me.jobTitle || null,
          },
        },
        ...userTeam,
      });
    }
    /**
     * Only for meaningful data
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.id, me?.role, product?.id, identify]);
};
