import { typo, Button, ShyScrollbarCss } from '@cycle-app/ui';
import { small } from '@cycle-app/utilities';
import styled from 'styled-components';

export const StyledButtonBack = styled(Button)`
  --bg: transparent;

  color: ${p => p.theme.colors.text.primary};
  padding: 0px 8px;
  height: 32px;
  ${typo.body500}

  svg {
    color: ${p => p.theme.colors.text.disabled};
    transform: rotate(180deg);
  }
`;

export const Container = styled.div`
  flex: none;
  height: 100%;
  width: 300px;
  background-color: ${p => p.theme.colors.sidebar.bg};
  border-right: 1px solid ${p => p.theme.colors.border.primary};
  padding-bottom: 60px;

  ${ShyScrollbarCss}
  overflow-x: hidden;
  scrollbar-gutter: stable;

  ${small} {
    display: none;
  }
`;

export const Section = styled.div`
  padding: 24px 16px 24px 16px;

  &:not(:first-child) {
    border-top: 1px solid ${p => p.theme.colors.border.primary};
  }
`;

export const Tabs = styled.div`
  height: 32px;
  border-radius: 8px;
  padding: 2px;
  background-color: ${p => (p.theme.isDark ? p.theme.colors.body.bg : p.theme.baseColors.Grey200)};
  display: flex;
  margin: 24px 16px 0;
  gap: 2px;
`;

interface TabProps {
  $isActive: boolean;
}

export const Tab = styled(Button)<TabProps>`
  flex-grow: 1;
  ${typo.body500}

  &:active {
    --color: ${p => p.theme.colors.text.primary};
  }

  ${p => (p.$isActive ? `
    --bg: ${p.theme.isDark ? 'rgba(49, 49, 49, 1)' : p.theme.colors.background.primary};
    --color: ${p.theme.colors.text.primary};
  ` : `
    &:hover {
      --bg: ${p.theme.isDark ? p.theme.baseColors.Grey900 : 'transparent'};
    }
  `)}

  ${p => p.theme.isDark && `
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
    `}
`;
