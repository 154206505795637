import { IntegrationType, GithubRepositoryFragment, GithubAssignee, GithubProjectFragment } from '@cycle-app/graphql-codegen';
import { Range } from '@tiptap/core';
import { ReactRendererOptions } from '@tiptap/react';

import { AutomationType } from './automation.types';

export enum FrontEndIntegration {
  ASANA = 'ASANA',
  ATTIO = 'ATTIO',
  // CHROME = 'CHROME',
  CLICK_UP = 'CLICK_UP',
  CONFLUENCE = 'CONFLUENCE',
  CYCLE = 'CYCLE',
  CYCLE_API = 'CYCLE_API',
  DISCORD = 'DISCORD',
  FRESHDESK = 'FRESHDESK',
  FRONT = 'FRONT',
  FULL_STORY = 'FULL_STORY',
  GAINSIGHT = 'GAINSIGHT',
  GITLAB = 'GITLAB',
  GRAINS = 'GRAINS',
  JIRA = 'JIRA',
  MAKE = 'MAKE',
  MICROSOFT_TEAM = 'MICROSOFT_TEAM',
  MODJO = 'MODJO',
  MONDAY = 'MONDAY',
  PLANHAT = 'PLANHAT',
  SHORTCUT = 'SHORTCUT',
  SLITE = 'SLITE',
  SNOWFLAKE = 'SNOWFLAKE',
  VITALLY = 'VITALLY',
  WEBHOOKS = 'WEBHOOKS',
  ZAPIER_COLLAB = 'ZAPIER_COLLAB',
}

export type Integration = IntegrationType | FrontEndIntegration;

export type InstallCodeProviderParams =
  | IntegrationType.Linear
  | IntegrationType.Notion
  | IntegrationType.Figma
  | IntegrationType.Intercom
  | IntegrationType.Hubspot
  | IntegrationType.Meeting
  | IntegrationType.Gong;

export type CreateGithubIssueParams = {
  title?: string;
  repoName?: string;
  description?: string;
  assignees?: GithubAssignee[];
  statusId?: string | null;
  projectId?: string;
};

export type CreateGithubIssueFormValues = {
  title?: string;
  repository?: GithubRepositoryFragment;
  project?: GithubProjectFragment;
  description?: string;
  assignees?: GithubAssignee[];
  statusId?: string | null;
};

export type IntegrationMentionRendererProps = ReactRendererOptions & {
  extensionName: string;
  range: Range;
  query: string;
  type: AutomationType;
};
