import { ToasterAction, ToasterShortcut } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';

import { Shortcut, shortcuts } from 'src/constants/shortcuts.constants';
import { useParams, useNavigate } from 'src/hooks';
import { getStatus } from 'src/reactives/productStatus.reactive';
import { getDocFromCache } from 'src/utils/cache.utils';
import { addToaster, AddToasterParam } from 'src/utils/toasters.utils';

import { useAddDocToaster } from './useAddDocToaster';

export const useStatusToaster = () => {
  const addDocToaster = useAddDocToaster();
  const { docSlug } = useParams();
  const { navigateToDocPanelPage } = useNavigate();

  return (docIds: string[], statusId: string, undo?: VoidFunction) => {
    const label = getStatus(statusId)?.value.toLowerCase();
    if (!label || !undo) return;

    const firstDocId = docIds[0];
    if (!firstDocId) return;

    const undoAndClose = () => {
      undo?.();
      closeToaster?.();
      if (docSlug) {
        const doc = getDocFromCache(firstDocId);
        if (doc) navigateToDocPanelPage(doc);
      }
    };

    const params: AddToasterParam = {
      icon: <CheckIcon />,
      title: `Marked as ${label}`,
      actions: (
        <ToasterAction
          onClick={undoAndClose}
          iconEnd={(
            <ToasterShortcut
              keys={shortcuts[Shortcut.Undo]}
              className="shortcut shortcut--enabled-last-toaster"
            />
          )}
        >
          Undo
        </ToasterAction>
      ),
      shortcuts: [{
        key: Shortcut.Undo,
        action: undoAndClose,
        lastOnly: true,
      }],
    };

    let closeToaster: VoidFunction;

    if (docIds.length > 1) {
      closeToaster = addToaster({
        ...params,
        message: `Your feedback docs were successfully marked as ${label}`,
      });
    } else {
      closeToaster = addDocToaster({
        ...params,
        docId: firstDocId,
        message: () => (
          <span>
            {`Your feedback doc was successfully marked as ${label}`}
          </span>
        ),
      });
    }
  };
};
