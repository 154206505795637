import { forwardRef, ComponentProps } from 'react';

import { Container, Z } from './Card.styles';

export type CardProps = ComponentProps<typeof Container> & {
  disableHover?: boolean;
  z?: Z;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(({
  className,
  children,
  disableHover = false,
  z,
  ...otherProps
}, ref) => (
  <Container
    ref={ref}
    className={className}
    $disableHover={disableHover}
    $z={z}
    {...otherProps}
  >
    {children}
  </Container>
));
