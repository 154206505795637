import { IntegrationType, FetchNotionPageByIdDocument, FetchNotionPageByIdQueryVariables, NotionElementType } from '@cycle-app/graphql-codegen';
import { Input, Spinner, InputProps } from '@cycle-app/ui';
import { isUrl, isLoomUrl, isNotionUrl, isDriveUrl } from '@cycle-app/utilities';
import { useState, ReactNode, MouseEvent } from 'react';

import { useProductIntegrations, useInstallIntegration } from 'src/hooks';
import { useSearchNotion } from 'src/hooks/api/queries/integrations/useSearchNotion';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useSafeAsyncLazyQuery } from 'src/hooks/useSafeAsyncLazyQuery';

import { StyledLink } from './Dropzone.styles';

export type LinInputProps = Omit<InputProps, 'onSubmit' | 'children'> & {
  close: VoidFunction;
  onSubmit: (file: string) => void;
  children?: (props: {
    submit: VoidFunction;
    isDisabled: boolean;
  }) => ReactNode;
};

export const LinkInput = ({
  close,
  onSubmit,
  children,
  ...props
}: LinInputProps) => {
  const [link, setLink] = useState('');
  const [error, setError] = useState<ReactNode | string>('');
  const { isInstalled } = useSearchNotion();

  const [fetchNotionPage, { loading }] = useSafeAsyncLazyQuery<
  { node: { provider?:{ search:{ id: string }[] } } },
  FetchNotionPageByIdQueryVariables
  >(FetchNotionPageByIdDocument, { fetchPolicy: 'network-only' });
  const install = useInstallIntegration();
  const { getIntegration } = useProductIntegrations();
  const installInte = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const notionIntegration = getIntegration(IntegrationType.Notion);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    install(IntegrationType.Notion, notionIntegration);
    close();
  };
  const canSubmit = async (val: string) => {
    if (error) return false;
    if (isNotionUrl(val) && !isInstalled) {
      setError((
        <p>
          You need to install Notion to proceed:
          {' '}
          <StyledLink onClick={installInte}>Install now</StyledLink>
        </p>
      ));
      return false;
    } if (isNotionUrl(val) && isInstalled) {
      const notionIntegration = getIntegration(IntegrationType.Notion);
      const notionPageId = val.split('-').pop()?.split('?')[0] ?? '';
      const response = await fetchNotionPage({
        variables: {
          integrationId: notionIntegration?.id || '',
          input: {
            type: NotionElementType.Page,
            id: notionPageId,
          },
        },
      });
      const returnedPageId = response?.node?.provider?.search?.[0]?.id;
      if (!returnedPageId) {
        setError('This page could not be processed');
        return false;
      }
      return true;
    }
    return isLoomUrl(val) || isDriveUrl(val);
  };

  const submit = async () => {
    if (await canSubmit(link)) {
      onSubmit(link);
      setLink('');
      close();
    }
  };

  useAppHotkeys('enter', submit);

  return (
    <>
      <Input
        autoFocus
        value={link}
        onChange={(e) => {
          const val = e.target.value;
          setLink(val);
          if (val && !isUrl(val)) {
            setError('You should provide a valid URL');
            return;
          }
          if (
            val &&
            isUrl(val) &&
            (!isLoomUrl(val) && !isNotionUrl(val) && !isDriveUrl(val))
          ) {
            setError('We support only Loom, Notion or public Drive urls at the moment');
            return;
          }
          setError('');
        }}
        placeholder="Paste your Loom, Notion or public Drive link"
        helper="We only support Loom Notion and public Drive links (Gong, Descript & more coming soon)"
        error={error}
        endInput={loading ? <Spinner /> : undefined}
        {...props}
      />

      {children?.({
        submit,
        isDisabled: !link || !!error,
      })}
    </>
  );
};
