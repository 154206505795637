import { DropdownCss, SelectLine } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  ${DropdownCss};

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;

  ${mobile} {
    min-width: 250px;
    max-height: 190px;
  }
`;

export const StyledSelectLine = styled(SelectLine)`
  border-radius: 4px;
`;
