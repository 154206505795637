import { useQuery } from '@apollo/client';
import { CompanyDocsCountDocument } from '@cycle-app/graphql-codegen/generated';

export const useCompanyDocsCount = (companyId: string) => {
  const {
    data, loading,
  } = useQuery(CompanyDocsCountDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId,
    },
  });

  return {
    docsCount: data?.getCompanyDocsCount,
    isLoading: loading,
    isLoaded: !!data,
  };
};
