import { StatusCategory } from '@cycle-app/graphql-codegen';

import { useOptimizedBooleanState } from 'src/hooks';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useNotStartedStatusId, useCompletedStatusId } from 'src/reactives/productStatus.reactive';

import { useUpdateDocsStatus } from './useUpdateDocsStatus';

type Props = {
  docId: string;
  docStatusCategory: StatusCategory;
  onCompleted?: VoidFunction;
  quotesCount: number;
};

export const useToggleDocStatus = ({
  docId, docStatusCategory, onCompleted, quotesCount,
}: Props) => {
  const [isDialogOpen, {
    setTrueCallback: openDialog,
    setFalseCallback: closeDialog,
  }] = useOptimizedBooleanState(false);

  const { updateDocsStatus } = useUpdateDocsStatus();
  const notStartedId = useNotStartedStatusId();
  const completedId = useCompletedStatusId();
  const { canUpdateFeedbackStatus } = useGetPermission();

  const isCompleted = docStatusCategory === StatusCategory.Completed;

  const process = async () => {
    if (!completedId) return;
    await updateDocsStatus([docId], completedId);
    onCompleted?.();
  };

  const unprocess = async () => {
    if (!notStartedId) return;
    await updateDocsStatus([docId], notStartedId);
    onCompleted?.();
  };

  const toggleDocStatus = async () => {
    if (!canUpdateFeedbackStatus) {
      setLimitationsModal({ action: 'FEEDBACK_STATUS_UPDATE' });
      return;
    }

    if (isCompleted) {
      await unprocess();
      return;
    }

    if (quotesCount === 0) {
      await process();
      return;
    }

    openDialog();
  };

  return {
    isCompleted,
    toggleDocStatus,
    isDialogOpen,
    closeDialog,
    onConfirm: process,
  };
};
