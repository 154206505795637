import {
  differenceInDays, format, formatDistanceToNowStrict, parseISO, formatDistanceToNow, differenceInMinutes,
} from 'date-fns';

import { pad } from './number.utils';

export const toIsoString = (dateStr: string) => new Date(dateStr).toISOString();

export const toInputDate = (dateStr: string) => {
  if (!dateStr) return '';

  const d = new Date(dateStr);

  return [
    pad(d.getFullYear(), '0000'),
    d.getMonth() + 1,
    d.getDate(),
  ]
    .map(n => (n < 10 ? `0${n}` : `${n}`)).join('-');
};

export const toTagDate = (dateStr: string) => format(parseISO(dateStr), 'dd/MM/yyyy');

export const toNotificationDateTime = (date: string) => {
  const now = new Date();
  const isoDate = parseISO(date);
  const diffInDays = differenceInDays(now, isoDate);

  if (diffInDays <= 7) {
    const diffInMinutes = differenceInMinutes(now, isoDate);
    const distance = diffInMinutes >= 1 ? formatDistanceToNowStrict(isoDate) : formatDistanceToNow(isoDate);
    return `${distance} ago`;
  }
  return toTagDate(date);
};

export const toLastUpdateDateTime = (date?: string | null) => {
  if (!date) return null;
  const now = new Date();
  const isoDate = parseISO(date);
  const diffInDays = differenceInDays(now, isoDate);
  if (diffInDays === 0) {
    return 'Today';
  }
  if (diffInDays <= 3) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  }
  return format(isoDate, 'PPP');
};

export const toShortLocaleDateString = (date?: string | null) => (date ? new Date(date) : new Date()).toLocaleDateString('en-us', {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
});

export const toShortLocaleDateTimeString = (
  date?: string,
  options?: { month?: 'short' | 'long' },
) => (date ? new Date(date) : new Date()).toLocaleDateString('en-us', {
  month: options?.month || 'short',
  day: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export const distanceToNow = (date: string) => {
  if (differenceInMinutes(new Date(), new Date(date)) < 1) {
    return 'now';
  }
  return formatDistanceToNow(new Date(date), { addSuffix: true })
    .replace('about ', '')
    .trim();
};
