import { z } from 'zod';

import DialogModal, { DialogModalProps } from 'src/components/DialogModal/DialogModal';
import { useStateWithStorage } from 'src/hooks/useStateWithStorage';
import { LocalKey } from 'src/types/localStorage.types';

type Props = Pick<DialogModalProps, 'hide' | 'onConfirm'> & {
  quotesCount: number;
};

export const DocProcessModal = ({
  quotesCount, hide, onConfirm,
}: Props) => {
  const [isChecked, setIsChecked] = useStateWithStorage(false, {
    key: LocalKey.DocProcessModalChecked,
    schema: z.boolean(),
  });

  return (
    <DialogModal
      width="540px"
      type="default"
      variantCancelButton="secondary"
      variantSubmitButton="primary"
      title="Mark processed"
      cancelLabel="Cancel"
      confirmLabel="Mark processed"
      confirmMessage={getConfirmMessage(quotesCount)}
      info={(
        <>
          <p>Are you sure you want to mark this feedback as processed?</p>
          <p>{getInfoMessage(quotesCount)}</p>
        </>
      )}
      onToggleConfirmation={() => setIsChecked(!isChecked)}
      onConfirm={() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onConfirm?.();
      }}
      isConfirmDisabled={!isChecked}
      hide={() => {
        hide();
        setIsChecked(false);
      }}
    />
  );
};

const getConfirmMessage = (count: number) => (count === 1
  ? 'Discard 1 ai-generated quote'
  : `Discard ${count} ai-generated quotes`);

const getInfoMessage = (count: number) => (count === 1
  ? 'You still have 1 ai-generated quote to verify or discard.'
  : `You still have ${count} ai-generated quotes to verify or discard.`);
