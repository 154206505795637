import { CustomerFragment } from '@cycle-app/graphql-codegen';
import { TrashIcon, PenIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { CustomerRemoveModal } from 'src/components/CustomersList/CustomerRemoveModal';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { StyledDotsMenuLayer } from './Customers.styles';
import { CustomersAddCustomerModal } from './CustomersAddCustomerModal';

type Props = {
  customer: CustomerFragment | undefined | null;
};

export const CustomerActionsMenu: FC<React.PropsWithChildren<Props>> = ({ customer }) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [isUpdateModalOpen, {
    setFalseCallback: closeUpdateModal, setTrueCallback: openUpdateModal,
  }] = useOptimizedBooleanState(false);
  const [isRemoveModalOpen, {
    setFalseCallback: closeRemoveModal, setTrueCallback: openRemoveModal,
  }] = useOptimizedBooleanState(false);

  if (!customer) return null;

  const defaultCustomer = isDefaultCustomer(customer);

  return (
    <>
      <StyledDotsMenuLayer
        layer={Layer.DropdownModalZ3}
        tooltip="Customer actions"
        placement="bottom-end"
        options={[
          {
            value: 'edit',
            label: 'Edit',
            icon: <PenIcon />,
            onSelect: openUpdateModal,
            tooltipContent: defaultCustomer ? "You can't edit your own users from here" : undefined,
            disabled: defaultCustomer,
          },
          {
            value: 'delete',
            label: 'Delete',
            icon: <TrashIcon />,
            variant: 'danger',
            onSelect: openRemoveModal,
            tooltipContent: defaultCustomer ? "You can't delete your own users from here" : undefined,
            disabled: defaultCustomer,
          },
        ]}
      />
      {isUpdateModalOpen && customer && (
        <CustomersAddCustomerModal
          productId={productId}
          layer={Layer.ModalZ2}
          customer={customer}
          defaultValues={{
            email: customer.email || '',
            name: customer.name || '',
            companyId: customer.company?.id || '',
          }}
          defaultCompany={customer.company}
          onClose={closeUpdateModal}
        />
      )}
      {isRemoveModalOpen && customer && (
        <CustomerRemoveModal
          customer={customer}
          onClose={closeRemoveModal}
        />
      )}
    </>
  );
};
