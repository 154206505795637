import { mergeAttributes, Node } from '@tiptap/core';

import { MENTION_EXTENSION_NAME, MENTION_EXTENSION_TAGNAME } from '../constants';

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>;
};

export const getMentionExtension = () => Node.create<MentionOptions>({
  name: MENTION_EXTENSION_NAME,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: this.name,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: MENTION_EXTENSION_TAGNAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      MENTION_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

});
