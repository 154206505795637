import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const PolicyParagraph = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  margin-bottom: 24px;
  margin-top: 64px;
`;

export const Anchor = styled.a`
  color: inherit;
  text-decoration: underline;
`;
