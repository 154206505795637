import { make } from 'src/utils/reactives.util';

interface BoardGroup {
  hoverGroupId: string | null;
}

export const {
  hookValue: useGetBoardGroupState,
  getValue: getBoardGroupState,
  setValue: setBoardGroupState,
} = make<BoardGroup>({
  defaultState: {
    hoverGroupId: null,
  },
});

export const {
  hookValue: useGetBoardGroupEditing,
  setValue: setBoardGroupEditing,
} = make<{
  id: string | null;
}>({
  defaultState: {
    id: null,
  },
});
