import { useReleaseNoteBase } from 'src/contexts/releaseNotesContext';
import { useEnhancedForm } from 'src/hooks/form/useEnhancedForm';
import { useUpdateReleaseNote } from 'src/hooks/releases/useUpdateReleaseNote';
import { useEscape } from 'src/hooks/useEscape';
import { resetReleasesAction } from 'src/reactives/releases.reactive';
import { Layer } from 'src/types/layers.types';

import { Form, Input, StyledButton } from './ReleaseNoteForm.styles';

type FormData = {
  title: string;
};

export const ReleaseNoteTitleForm = ({ noteId }: { noteId: string }) => {
  const releaseNote = useReleaseNoteBase(noteId);
  const { updateReleaseNote } = useUpdateReleaseNote();

  const {
    handleSubmit, register,
  } = useEnhancedForm<FormData>({
    defaultValues: {
      title: releaseNote?.title ?? undefined,
    },
  });

  useEscape(resetReleasesAction, Layer.Root);

  return (
    <Form
      style={{ flex: 1 }}
      onSubmit={handleSubmit(values => {
        resetReleasesAction();
        if (!releaseNote) return;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateReleaseNote(releaseNote, { title: values.title });
      })}
    >
      <Input
        autoFocus
        {...register('title')}
      />

      <StyledButton
        type="reset"
        variant="secondary"
        onClick={resetReleasesAction}
      >
        Cancel
      </StyledButton>
    </Form>
  );
};
