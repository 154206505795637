import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetComments,
  setValue: setComments,
} = make<{
  editingId: string | null;
  focusedId: string | null;
}>({
  defaultState: {
    editingId: null,
    focusedId: null,
  },
});

export const {
  hookValue: useMainThread,
  setValue: setMainThread,
} = make({
  defaultState: {
    isVisible: false,
  },
});

export type ThreadsSection = 'open' | 'closed' | 'all';

type ThreadPanelProps = {
  onDelete?: (blockId: string | null) => void;
  onResolve?: (blockId: string | null, isResolved: boolean) => void;
  section: ThreadsSection;
  hoverBlockId: string | null | undefined;
  openBlockId: string | null | undefined;
  visibleThreadId: string | null | undefined;
};

export const {
  hookValue: useThreadsPanel,
  getValue: getThreadsPanel,
  setValue: setThreadsPanel,
} = make<ThreadPanelProps>({
  defaultState: {
    section: 'open',
    hoverBlockId: undefined,
    openBlockId: undefined,
    visibleThreadId: undefined,
  },
});

export const showResolvedThreads = () => setThreadsPanel({ section: 'closed' });

export const showOpenThreads = () => setThreadsPanel({ section: 'open' });

export const showAllThreads = () => setThreadsPanel({ section: 'all' });

export const hideRightPanelChat = () => setThreadsPanel({
  visibleThreadId: undefined,
  openBlockId: undefined,
});
