import { HIGHLIGHT_EXTENSION_NAME, HIGHLIGHT_VIEW_PLUGIN_NAME } from '@cycle-app/editor-extensions';
import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Instance } from 'tippy.js';

import { InsightDropdown } from 'src/components/Editor/InsightDropdown/InsightDropdown';
import { useEditorContext } from 'src/contexts/editorContext';
import { HighlightViewPlugin, HighlightViewPluginProps } from 'src/editorExtensions/HighlightMark/HighlightViewPlugin';
import { useDocInsights } from 'src/hooks';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { resetHighlight, setHighlight, useGetHighlight } from 'src/reactives/highlight.reactive';
import { ActionId, actions as editorActions } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';
import { getInsightContentFromSelection } from 'src/utils/editor';
import type { SelectionData } from 'src/utils/editor';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { HighlightQuotePopover } from './HighlightQuotePopover';

const DEFAULT_STATE = {
  blockId: null,
  defaultContent: '',
  files: [],
  isCreate: false,
  overridePosition: null,
  isVisible: false,
};

export type BubbleMenuProps = Omit<Partial<HighlightViewPluginProps>, 'element' | 'editor'> & {
  className?: string;
  children?: React.ReactNode;
  updateDelay?: number;
  isEnabled: boolean;
};

type State = {
  defaultContent?: string;
  blockId: string | null;
  isCreate: boolean;
  overridePosition: DOMRect | null;
  isVisible: boolean;
  files: SelectionData['files'];
};

export const HighlightMenu = ({
  pluginKey = HIGHLIGHT_VIEW_PLUGIN_NAME, tippyOptions = {}, updateDelay, className, isEnabled,
}: BubbleMenuProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const doc = useEditorContext(ctx => ctx.doc);
  const isActiveHighlight = editor.isActive(HIGHLIGHT_EXTENSION_NAME);
  const {
    insights, isLoading: isInsightsLoading,
  } = useDocInsights(doc?.id, {
    skip: !isEnabled || !doc?.docTargetsCount,
    // As we use 'cache-and-network', we need to get network data only once.
    // This prevents re-trigger the query when we update the cache. Eg: on an insight parent update.
    nextFetchPolicy: 'cache-first',
    // We do not have real-time on doc deletion so we have to fetch the fresh results each time.
    fetchPolicy: 'cache-and-network',
    // onCompleted: (data) => {
    //   // This logic won't work with pagination
    //   if (editor && data.node?.__typename === 'Doc' && data.node.docTargets.edges) {
    //     editor.commands.cleanPublishedHighlights?.({
    //       publishedIds: data.node.docTargets.edges.map(e => e.node?.blockId).filter(isNotEmpty),
    //       type: 'insight',
    //     });
    //   }
    // },
  });

  const { blockIdCreating } = useGetHighlight();

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [{
    overridePosition, isCreate, defaultContent, blockId, isVisible, files,
  }, setState] = useState<State>(DEFAULT_STATE);
  const docTarget = useMemo(() => (
    insights.find(insight => insight?.blockId === blockId)), [blockId, insights]);

  const docTargetRef = useRef<typeof docTarget | null>(null);
  const insightsRef = useRef<typeof insights | null>(null);
  const insightsLoadingRef = useRef<boolean>(true);
  const blockIdCreatingRef = useRef<typeof blockIdCreating | null>(null);

  // useEffect(() => {
  //   if (blockIdsToDelete?.length) {
  //     editor.chain().selectAll().unsetHighlightMark({ ids: blockIdsToDelete }).run();
  //   }
  // }, [blockIdsToDelete, editor]);

  const handleTrigger = useCallback((e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isActiveHighlight) {
      addToaster({
        message: 'Your selection is already linked to an insight',
      });
      return;
    }
    requestInsightCreate(() => {
      editorActions[ActionId.TurnTextIntoInsight].toggle?.(editor, 'bubble-menu');
    });
  }, [editor, isActiveHighlight]);

  insightsRef.current = insights;
  insightsLoadingRef.current = isInsightsLoading;
  docTargetRef.current = docTarget;
  blockIdCreatingRef.current = blockIdCreating;

  useAppHotkeys('command+1', handleTrigger, {
    enabled: () => isEnabled,
  }, [isEnabled]);
  useAppHotkeys('command+&', handleTrigger, {
    enabled: () => isEnabled,
  }, [isEnabled]);

  const onOpen: HighlightViewPluginProps['onOpen'] = (tippyInstance: Instance, {
    id, create, text,
  }) => {
    const result = getInsightContentFromSelection(editor.state);
    setHighlight({
      blockId: id,
      context: 'doc-content',
    });
    setState({
      blockId: id,
      /**
       * We keep the olg logic for text cause the new logic doesn't support
       * some edge case like command + A
       */
      defaultContent: text,
      /**
       * We use the logic only for the files for now
       */
      files: result.files,
      isCreate: !!create,
      overridePosition: tippyInstance.props.getReferenceClientRect?.() ?? null,
      isVisible: true,
    });
  };

  const onHideTooltip = () => {
    if (!docTargetRef.current && !blockIdCreatingRef.current) {
      // This will undo the draft mark that we set.
      editor.commands.undo();
    }
    resetHighlight();
    setState(s => ({
      ...s,
      isVisible: false,
    }));
  };

  useEffect(() => {
    if (!element || !editor) {
      return () => {};
    }
    if (editor.isDestroyed) {
      return () => editor.unregisterPlugin(pluginKey);
    }

    const plugin = HighlightViewPlugin({
      updateDelay,
      editor,
      element,
      pluginKey,
      tippyOptions,
      onOpen,
      onClose: onHideTooltip,
    });

    queueMicrotask(() => {
      editor.commands.cleanDraftHighlights?.();
      editor.registerPlugin(plugin);
    });

    return () => editor.unregisterPlugin(pluginKey);
    // Intended.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, element]);

  const onHideDropdown = () => {
    if (!docTargetRef.current && !blockIdCreatingRef.current) {
      // This will undo the draft mark that we set.
      editor.commands.undo();
    }
    resetHighlight();
    setState(DEFAULT_STATE);
  };

  return (
    <div
      ref={setElement}
      className={className}
      style={{
        pointerEvents: 'all',
        visibility: 'hidden',
      }}
    >
      {blockId && doc && (
        <InsightDropdown
          key={blockId}
          blockId={blockId}
          dropdownProps={{
            placement: 'bottom',
            layer: Layer.DropdownModalZ2,
            ...overridePosition && { overridePosition },
          }}
          defaultContent={defaultContent}
          defaultCover={files.length ? files[0]?.src : undefined}
          docTarget={docTarget}
          feedback={doc}
          isInitialVisible={isCreate}
          onHide={onHideDropdown}
          onInsightCreating={() => setHighlight({
            docId: null,
            blockIdCreating: blockId,
          })}
          onInsightCreated={() => editor.commands.publishHighlightMark({
            id: blockId,
            type: 'insight',
          })}
        />
      )}

      {blockId && doc && overridePosition && (
        <HighlightQuotePopover
          docTarget={docTarget}
          visible={isVisible && !isCreate}
          overridePosition={new DOMRect(overridePosition.x, overridePosition.y)}
        />
      )}

    </div>
  );
};

// function isNotEmpty(data?: string | null): data is string {
//   return !!data;
// }
