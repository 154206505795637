import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Spinner, Skeleton } from '@cycle-app/ui';
import { CloseIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import { DocPanelWithBoardConfig } from 'src/app/Main/Board/DocPanel/DocPanel';
import { ReleasesPageTitle } from 'src/components/PageTitle/PageTitle';
import { PageId, routing } from 'src/constants/routing.constant';
import { ReleaseNotesProvider } from 'src/contexts/releaseNotesContext';
import { useNavigate } from 'src/hooks';
import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { useParams } from 'src/hooks/router/useParams';
import { usePageId } from 'src/hooks/usePageId';
import { useGetPermission } from 'src/reactives';

import {
  Nav, Action, EditorContainer, EditorHeader, Alert, StyledDocPanelRealtime,
} from './ReleaseNote.styles';
import { ReleaseNoteDocFeature } from './ReleaseNoteDocFeature';
import { ReleaseNoteDocOpen } from './ReleaseNoteDocOpen';
import { ReleaseNoteEditor } from './ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from './ReleaseNoteEditorTitle';
import { ReleaseNotePrevNext } from './ReleaseNotePrevNext';
import { ReleaseNoteTags } from './ReleaseNoteTags';

const useCloseReleaseNote = () => {
  const { releaseId } = useParams();
  const { navigateToRelease } = useNavigate();
  return () => {
    if (!releaseId) return;
    navigateToRelease(releaseId);
  };
};

export const ReleaseNote = () => {
  const { canUpdateReleaseNote } = useGetPermission();
  const {
    releaseId, noteId,
  } = useParams();
  const {
    release, isReleaseLoading, isReadonly,
  } = useRelease(releaseId);
  const {
    releaseNote, isReleaseNoteLoading,
  } = useReleaseNote(noteId);
  const closeReleaseNote = useCloseReleaseNote();
  const showContent = usePageId(pageId => pageId !== PageId.ReleaseDoc);

  if (!noteId || !releaseId || !release) return null;

  const isLoading = isReleaseLoading || isReleaseNoteLoading;
  const releaseDate = toShortLocaleDateString(release.date);

  return (
    <>
      {releaseNote?.title && (
        <ReleasesPageTitle
          releaseDate={releaseDate}
          releaseNoteTitle={releaseNote?.title}
        />
      )}
      <motion.div
        className="relative flex h-full flex-col overflow-hidden"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: {
            opacity: 1,
            transition: {
              duration: 0.1,
              delay: 0.1,
            },
          },
          hidden: {
            opacity: 0,
            transition: {
              duration: 0.1,
            },
          },
        }}
      >
        <div className="flex flex-wrap items-start justify-between gap-4 border-b border-grey-200 px-5 py-4 dark:border-grey-850">
          {isLoading ? (
            <HeaderSkeleton />
          ) : (
            <>
              <div className="flex items-center gap-3">
                <Nav>
                  <Action onClick={closeReleaseNote}>
                    <CloseIcon />
                  </Action>
                  <ReleaseNotesProvider
                    isReadonly={isReadonly}
                    showBugAndImprovements={release.showBugAndImprovements}
                    publicStatus={release.publicStatus}
                    releaseId={releaseId}
                    isNoValue={null}
                  >
                    <ReleaseNotePrevNext />
                  </ReleaseNotesProvider>
                </Nav>
              </div>
              {noteId && (
                <div className="flex items-center gap-3">
                  <StyledDocPanelRealtime />
                  <ReleaseNoteDocOpen
                    isReadOnly={isReadonly}
                    releaseId={releaseId}
                    noteId={noteId}
                  />
                </div>
              )}
            </>
          )}
        </div>

        <ReleaseNotePublishedBanner releaseId={releaseId} />

        <EditorContainer>
          {showContent && (
            <>
              <EditorHeader>
                <ReleaseNoteAlert releaseNoteId={noteId} />
                {releaseNote?.doc && (
                  <ReleaseNoteDocFeature
                    releaseId={releaseId}
                    noteId={releaseNote.id}
                    doc={releaseNote.doc}
                  />
                )}
                <ReleaseNoteTags isReadonly={isReadonly} releaseNoteId={noteId} />
                <ReleaseNoteEditorTitle
                  releaseNoteId={noteId}
                  isReadOnly={isReadonly || !canUpdateReleaseNote}
                />
              </EditorHeader>

              <ReleaseNoteEditor
                releaseNoteId={noteId}
                isReadOnly={isReadonly || !canUpdateReleaseNote}
              />
            </>
          )}
        </EditorContainer>

        <Switch>
          <Route path={routing[PageId.ReleaseDoc]}>
            <DocPanelWithBoardConfig />
          </Route>
        </Switch>
      </motion.div>
    </>
  );
};

const ReleaseNotePublishedBanner = ({ releaseId }: { releaseId: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { release } = useRelease(releaseId);

  const {
    editPublished, publish,
  } = useReleasePublicStatusUpdate(releaseId);

  if (!release || release.publicStatus === ReleasePublicStatus.Unpublished) return null;

  const isPublished = release.publicStatus === ReleasePublicStatus.Published;
  const handleClick = async () => {
    setIsLoading(true);
    if  (isPublished) {
      await editPublished();
    } else {
      await publish();
    }
    setIsLoading(false);
  };

  return (
    // eslint-disable-next-line max-len
    <div className="flex items-center justify-center gap-4 border-b border-grey-200 bg-grey-100 py-1 text-center text-secondary dark:border-grey-850 dark:bg-grey-900">
      {isPublished ? 'This release note is published' : 'Editing this release note'}
      <button
        // eslint-disable-next-line max-len
        className="relative flex cursor-pointer items-center gap-1 rounded p-0 text-sm text-primary outline-offset-2 hover:text-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-cycle"
        onClick={handleClick}
      >
        {isPublished ? 'Make updates' : 'Publish changes'}
        <Spinner className={twJoin('absolute -right-4 size-3 transition', !isLoading && 'opacity-0')} />
      </button>
    </div>
  );
};

export const ReleaseNoteAlert = ({ releaseNoteId }: { releaseNoteId: string }) => {
  const { releaseNote } = useReleaseNote(releaseNoteId);
  if (!releaseNote?.isOther) return null;
  return (
    <Alert>
      <p>
        <InfoIconOutline />
      </p>
      <p>
        {'This release note is in the '}
        <strong>Other improvements & bugs section</strong>
        , the description will not appear in the public change log, only the title.
      </p>
    </Alert>
  );
};

const HeaderSkeleton = () => {
  const closeReleaseNote = useCloseReleaseNote();
  return (
    <>
      <div className="flex items-center gap-3 overflow-hidden">
        <Action onClick={closeReleaseNote}>
          <CloseIcon />
        </Action>
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={60} />
      </div>
      <div className="flex items-center gap-2">
        <Skeleton height={20} width={60} />
        <Skeleton height={20} width={60} />
      </div>
    </>
  );
};
