import { useCallback } from 'react';

import { useCreateDraftBoardConfig } from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import { setLimitationsModal, useGetPermission, isMobile } from 'src/reactives';
import { setBoardConfigModal } from 'src/reactives/boardConfig.reactive';

export const useOpenBoardConfig = () => {
  const { canUpdateView } = useGetPermission();
  const { createDraftBoardConfig } = useCreateDraftBoardConfig();

  return useCallback(async (boardId: string) => {
    if (isMobile()) return;
    if (!canUpdateView) {
      setLimitationsModal({ action: 'VIEW_UPDATE' });
      return;
    }

    setBoardConfigModal({
      visible: true,
      // disable form controls while displaying optimistic data
      disabled: true,
    });

    await createDraftBoardConfig(boardId);
  }, [canUpdateView, createDraftBoardConfig]);
};
