import { getTableOfContentNode } from '@cycle-app/editor-extensions';
import { ReactNodeViewRenderer } from '@tiptap/react';
import TableOfContent from '@tiptap-pro/extension-table-of-content';

import { TableOfContentNodeView } from './TableOfContentNodeView';

export const getTableOfContentExtension = () => {
  return [
    TableOfContent,
    getTableOfContentNode().extend({
      addNodeView: () => ReactNodeViewRenderer(TableOfContentNodeView),
    }),
  ];
};
