import { BoardSectionFragment, SectionType } from '@cycle-app/graphql-codegen';

type Section = Pick<BoardSectionFragment, 'name' | 'type'>;

export const getSectionName = (currentSection: Section, sectionsCount: number) => {
  if (currentSection.type === SectionType.Default) {
    // Display "Main section" when we only have the default section.
    return sectionsCount === 1 ? 'Main section' : 'Other';
  }
  return currentSection.name;
};
