import { Spinner } from '@cycle-app/ui';
import { emailRegex, ERROR_CODE } from '@cycle-app/utilities';

import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { ErrorMap, useEnhancedForm } from 'src/hooks/form/useEnhancedForm';
import { addToaster } from 'src/utils/toasters.utils';

import { ProfileInput } from './Profile.styles';

type FormData = { email: string };

const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    code: ERROR_CODE.CUSTOMER_EMAIL_ALREADY_USED,
    fieldName: 'email',
    renderMessage: () => 'A customer with this email already exists',
  },
];

type Props = {
  customerId: string;
  name: string | null | undefined;
  email: string | null | undefined;
  hide: VoidFunction;
};

export const CustomerEmailForm = ({
  customerId, name, email, hide,
}: Props) => {
  const { update } = useCustomerUpdate();

  const {
    handleSubmit, reset, register, displayFieldsErrors, formState: {
      errors, isSubmitting,
    },
  } = useEnhancedForm<FormData>({
    defaultValues: {
      email: email ?? '',
    },
  });

  return (
    <form onSubmit={handleSubmit(async formData => {
      if (isSubmitting) return;

      if (!formData.email && !name?.trim()) {
        addToaster({
          title: 'An error occured',
          message: 'Customers must have at least a name or an email',
        });
        return;
      }

      const result = await update({
        customerId,
        email: formData.email,
      });

      if (result.errors) {
        displayFieldsErrors(result.errors, mutationErrorsMap, true);
        return;
      }

      hide();
    })}
    >
      <ProfileInput
        data-1p-ignore
        autoFocus
        iconAfter={isSubmitting && <Spinner />}
        error={errors.email?.message}
        {...register('email', {
          setValueAs: value => value.trim(),
          pattern: {
            value: emailRegex,
            message: 'Email format is incorrect',
          },
          onBlur: () => {
            hide();
            reset();
          },
        })}
        onKeyUp={e => {
          if (e.key === 'Escape') {
            e.stopPropagation();
            hide();
            reset();
          }
        }}
      />
    </form>
  );
};
