import { Role, UpdateMeDocument, UserJourney } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useOptimizedBooleanState, useProductRole, useSafeMutation } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';
import { resetOnboarding } from 'src/reactives/lightOnboarding.reactive';

import {
  Body, BodyDefault, BodyLoading, Content, StyledButton, StyledLogoLoader, SubTitle,
} from './OnboardingStepDone.styles';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Title } from '../OnboardingLayout/OnboardingLayout.styles';

interface Props {
  products: { slug: string; nbMakers: number }[];
}

export const OnboardingStepDone: FC<React.PropsWithChildren<Props>> = ({ products }) => {
  const [isLoading, { setTrueCallback: setIsLoading }] = useOptimizedBooleanState(false);
  const { navigate } = useNavigate();
  const { role } = useProductRole();
  const [updateMe] = useSafeMutation(UpdateMeDocument);

  const prepareRedirect = async () => {
    setIsLoading();
    await updateMe({ variables: { userJourney: UserJourney.Done } });
    // Fake ws setup. will be removed with a subscription.
    window.setTimeout(() => {
      if (products.length) {
        const isFirstMaker = products[0]?.nbMakers === 1 && role === Role.Maker;
        if (isFirstMaker) {
          navigate(PageId.Inbox, {
            productSlug: products[0]?.slug,
          }, {
            isOnboarding: false,
          });
        } else {
          navigate(PageId.Main, {
            productSlug: products[0]?.slug,
          });
        }
      } else {
        navigate(PageId.Main);
      }
      resetOnboarding();
    }, 2000);
  };

  return (
    <OnboardingLayout
      hideLogo
      isContentGrow
      main={(
        <Content>
          <StyledLogoLoader
            size={100}
            animation={isLoading ? 'loop' : undefined}
            color={isLoading ? undefined : 'blue'}
            $isLoading={isLoading}
          />
          <Body>
            {isLoading && (
              <BodyLoading
                initial={{
                  opacity: 0,
                  translateY: 40,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                }}
              >
                <SubTitle>We’re setting up your workspace. It can take a few seconds...</SubTitle>
              </BodyLoading>
            )}
            <BodyDefault
              initial={{ opacity: 1 }}
              {...isLoading && { animate: { opacity: 0 } }}
            >
              <Title>Ready to enjoy the ride?</Title>
              <SubTitle>Capture product feedback and extract customer quotes – on autopilot.</SubTitle>
              <StyledButton
                onClick={prepareRedirect}
                size="M"
              >
                Let’s ride
              </StyledButton>
            </BodyDefault>
          </Body>
        </Content>
      )}
    />
  );
};
