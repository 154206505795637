import { useEffect, useRef } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { useEditorAi, resetEditorAi } from 'src/reactives/editorAi.reactive';

import { Container } from './AiOverlay.styles';

export const AiOverlay = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [{
    visible, suggestion, isWarningVisible, isLoading,
  }, setEditorAi] = useEditorAi();

  useEffect(() => {
    if (!ref.current) return;
    setEditorAi({ overlay: ref.current });
  /**
   * Intended mount
   * We want to set the ref only an the mounting moment
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        $isVisible={!!visible && (!!suggestion || isLoading)}
        ref={ref}
        onClick={() => setEditorAi({ isWarningVisible: true })}
      />
      {isWarningVisible && (
        <DialogModal
          type="default"
          confirmLabel="Discard"
          variantCancelButton="secondary"
          variantSubmitButton="primary"
          title="Are you sure you want to discard the AI response?"
          hide={() => setEditorAi({ isWarningVisible: false })}
          onConfirm={resetEditorAi}
          closeOnEscape={false}
        />
      )}
    </>
  );
};
