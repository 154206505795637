import { DoctypeFragment, SearchDocQueryVariables, SearchSuggestionDocsQueryVariables, ViewType } from '@cycle-app/graphql-codegen';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { TippyProps } from '@tippyjs/react';
import { MouseEvent, ReactNode, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { DropdownLayer, DropdownLayerProps } from 'src/components/DropdownLayer';
import { useOptimizedBooleanState, useDoc } from 'src/hooks';
import { useNavigateToDocFullOrPanel } from 'src/hooks/useNavigate';
import { setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocFromCache } from 'src/utils/cache.utils';
import { canHaveParent } from 'src/utils/doc.util';

import {
  EditParentBtnContainer,
  EditParentBtn,
  StyledDocParent,
} from './DocPanelParent.styles';
import { DocParentPanel } from './DocParentPanel';

type DocParentDropdownProps = {
  className?: string;
  docId?: string;
  docTypeId: string;
  showParentTitle?: boolean;
  showIcon?: boolean;
  showEmoji?: boolean;
  showStatus?: boolean;
  minimal?: boolean;
  placeholder?: string;
  absoluteEdit?: boolean;
  size?: 'XS' | 'S' | 'M';
  context?: 'doc-item' | 'doc-panel';
  viewType?: ViewType;
  children?: ReactNode;
  layer?: Layer;
  onParentClick?: VoidFunction;
  isLinkDisabled?: boolean;
  onChange?: (parentId: string | null) => void;
  searchPlaceholder?: string;
  initialParentId?: string | null;
  showNoneOption?: boolean;
  possibleDoctypeIds?: string[];
  searchVariables?: Partial<SearchDocQueryVariables>;
  searchSuggestionsVariables?: Partial<SearchSuggestionDocsQueryVariables>;
  initialSearch?: string;
  defaultLabel?: ReactNode;
  showLinearAutoCreate?: boolean;
  suggestionMax?: number;
  suggestedParentName?: string | null;
  placement?: TippyProps['placement'];
} & Pick<DropdownLayerProps, 'style'>;

export const DocParentDropdown = ({
  docTypeId, ...props
}: DocParentDropdownProps) => {
  const docType = useGetDocType(docTypeId);

  if (!canHaveParent(docType) || !isPresent(docType)) return null;

  return <DocParentDropdownContent docType={docType} {...props} />;
};

type DocParentDropdownContentProps = {
  docType: DoctypeFragment;
} & Omit<DocParentDropdownProps, 'docTypeId'>;

const DocParentDropdownContent = ({
  className,
  docId,
  showParentTitle = true,
  showIcon,
  showEmoji,
  showStatus,
  minimal,
  placeholder = 'Add',
  absoluteEdit = false,
  size,
  context = 'doc-panel',
  viewType,
  children,
  docType,
  layer = Layer.DropdownZ2,
  onParentClick,
  isLinkDisabled = false,
  onChange,
  searchPlaceholder,
  initialParentId = null,
  style,
  showNoneOption,
  possibleDoctypeIds,
  searchVariables,
  searchSuggestionsVariables,
  initialSearch,
  defaultLabel,
  showLinearAutoCreate,
  suggestionMax,
  suggestedParentName,
  placement = 'bottom-end',
}: DocParentDropdownContentProps) => {
  const [isParentBtnTooltipDisplayed, {
    setTrueCallback: showParentBtnTooltip,
    setFalseCallback: hideParentBtnTooltip,
  }] = useOptimizedBooleanState(false);
  const [isSearchDropdownVisible, {
    setFalseCallback: hideSearchDropdown,
    toggleCallback: toggleSearchDropdown,
  }] = useOptimizedBooleanState(false);
  const doc = docId ? getDocFromCache(docId) : null;

  const [parentId, setParentId] = useState<string | null>(initialParentId);
  const parentQuery = useDoc(parentId, !parentId);
  // eslint-disable-next-line no-nested-ternary
  const parent = docId
    ? doc?.parent
    : parentQuery.doc;

  return (
    <DropdownLayer
      placement={placement}
      visible={isSearchDropdownVisible}
      hide={hideSearchDropdown}
      layer={layer}
      style={style}
      content={(
        <DocParentPanel
          docId={docId}
          docType={docType}
          placeholder={searchPlaceholder ?? placeholder}
          hide={hideSearchDropdown}
          onChange={(id) => {
            setParentId(id);
            onChange?.(id);
          }}
          showNoneOption={showNoneOption}
          possibleDoctypeIds={possibleDoctypeIds}
          searchVariables={searchVariables}
          searchSuggestionsVariables={searchSuggestionsVariables}
          initialSearch={initialSearch}
          showLinearAutoCreate={showLinearAutoCreate}
          suggestionMax={suggestionMax}
          suggestedParentName={suggestedParentName}
        />
      )}
    >
      <DocParentLink
        onClick={onParentClick}
        docId={parent?.id}
        docTitle={parent?.title}
        isDisabled={isLinkDisabled}
      >
        <StyledDocParent
          className={className}
          doc={parent}
          minimal={minimal}
          showTitle={showParentTitle}
          showIcon={showIcon}
          showEmoji={showEmoji}
          showStatus={showStatus}
          size={size}
          onMouseEnter={() => {
            if (!parent) return;
            if (context === 'doc-item') setDocItemHoverId(parent.id);
            if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: parent.id });
          }}
          onMouseLeave={() => {
            if (context === 'doc-item') setDocItemHoverId(docId ?? null);
          }}
          context={context}
          viewType={viewType}
          onClick={e => {
            if (!parent || (isLinkDisabled && !e.metaKey)) toggleDropdown(e);
          }}
          onClickEdit={context === 'doc-item' ? toggleDropdown : undefined}
          tooltipProps={{
            placement: context === 'doc-item' ? 'top' : 'bottom',
            disabled: !parent || isParentBtnTooltipDisplayed,
            title: parent?.title,
            content: isLinkDisabled ? undefined : 'Open',
            withPortal: true,
          }}
          defaultLabel={defaultLabel ?? <span>{placeholder}</span>}
          forceFocus={isSearchDropdownVisible}
        >
          {context !== 'doc-item' && parent && (
            <EditParentBtnContainer $absolute={absoluteEdit}>
              <div style={{ position: 'fixed' }}>
                <EditParentBtn
                  tooltip="Edit"
                  tooltipPlacement="bottom"
                  onMouseEnter={showParentBtnTooltip}
                  onMouseLeave={hideParentBtnTooltip}
                  onClick={toggleDropdown}
                >
                  <PenFilledIcon />
                </EditParentBtn>
              </div>
            </EditParentBtnContainer>
          )}
          {children}
        </StyledDocParent>
      </DocParentLink>
    </DropdownLayer>
  );

  function toggleDropdown(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    toggleSearchDropdown();
  }
};

type DocParentLinkProps = {
  docTitle?: string;
  docId?: string;
  children: ReactNode;
  onClick?: VoidFunction;
  isDisabled?: boolean;
};

const DocParentLink = ({
  docId, docTitle, children, onClick, isDisabled, ...props
}: DocParentLinkProps) => {
  const navigateToDoc = useNavigateToDocFullOrPanel();

  if (!docId || !docTitle) return <>{children}</>;

  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        overflow: 'hidden',
        cursor: 'default',
      }}
      onClick={e => {
        if (isDisabled && !e.metaKey) return;
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
        navigateToDoc({
          id: docId,
          title: docTitle,
        }, {
          isDocRelative: true,
        }, e.metaKey);
      }}
      {...props}
    >
      {children}
    </div>
  );
};
