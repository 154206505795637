import { RessourceType, Webhook } from '@cycle-app/graphql-codegen';
import { Tooltip, ToggleInput, ButtonV2, ActionButton } from '@cycle-app/ui';
import { CloseIcon, AddIcon, QuestionOutlineIcon } from '@cycle-app/ui/icons';
import { isUrl } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import { useId, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { Section } from './ChangelogBuilderSidebar.styles';
import {
  StyledForm,
  StyledInput,
  StyledModal,
  StyledSubscriptionContent,
  StyledWebhookModalTrigger,
  StyledP,
  StyledModalTitle,
  StyledCreateButton,
  StyledInformationLink,
  StyledInformation,
} from './ChangelogBuilderSubscription.styles';
import { Title, Label } from './LabelWithReset.styles';
import { useCreateSubscribeWebhook } from '../../app/Main/Settings/SettingsAPI/WebhooksSection/useCreateWebhook';
import { useUpdateWebhook } from '../../app/Main/Settings/SettingsAPI/WebhooksSection/useUpdateWebhook';
import { useChangelog } from '../../hooks/releases/useChangelog';

export const ChangelogBuilderSubscription = () => {
  const { changelog } = useChangelog();

  const subscribeWebhook = changelog?.subscribeWebhook;

  const form = useChangelogBuilderFormContext();

  const subscribeToggled = form.watch('subscribeToggled');

  const handleToggleChange = (value: boolean) => {
    form.setValue('subscribeToggled', value);

    if (value && !subscribeWebhook) {
      setWebhookModalOpen(true);
    }
  };

  const [webhookModalOpen, setWebhookModalOpen] = useState(false);

  return (
    <Section>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <Title>Subscription system</Title>
          <Tooltip content="Activate to give users the option to submit their email" withWrapper={false} placement="top">
            <QuestionOutlineIcon className="text-disabled" />
          </Tooltip>
        </div>

        <ToggleInput
          variant="secondary"
          id="togglesubscription"
          checked={!!subscribeToggled}
          onChange={(e) => handleToggleChange(e.target.checked)}
        />
      </div>

      <AnimatePresence>
        {subscribeToggled && (
          <StyledSubscriptionContent
            style={{ overflowY: 'clip' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            <StyledInput
              error={form.formState.errors.subscribeLabel?.message}
              placeholder="Subscribe for updates"
              {...form.register('subscribeLabel', {
                validate: (value) => {
                  const parsed = z.string().trim().min(1).safeParse(value);

                  if (parsed.success) {
                    return undefined;
                  }

                  return 'Label is required';
                },
              })}
            />

            {subscribeWebhook ? (
              <p>
                Use
                {' '}
                <Tooltip content="Edit webhook" withWrapper={false} placement="top">
                  <StyledWebhookModalTrigger onClick={() => setWebhookModalOpen(true)}>
                    {subscribeWebhook?.label?.trim() || 'webhook'}
                  </StyledWebhookModalTrigger>
                </Tooltip>
                {' '}
                to fetch email addresses of people who subscribe
              </p>
            ) : (
              <StyledCreateButton
                variant="nospace"
                iconStart={<AddIcon />}
                size="M"
                type="button"
                onClick={() => setWebhookModalOpen(true)}
              >
                Create webhook

              </StyledCreateButton>
            )}
          </StyledSubscriptionContent>
        )}
      </AnimatePresence>

      {webhookModalOpen && changelog && (
        <ChangelogBuilderSubscriptionWebhookModal
          webhook={subscribeWebhook}
          onClose={() => setWebhookModalOpen(false)}
          changelogId={changelog.id}
        />
      )}
    </Section>
  );
};

const ChangelogBuilderSubscriptionWebhookModal = ({
  webhook, onClose, changelogId,
}: { webhook?: Webhook | null; onClose: VoidFunction; changelogId: string }) => {
  const formId = useId();
  const handleCreate = useCreateSubscribeWebhook(changelogId);
  const handleUpdate = useUpdateWebhook();

  const form = useForm({
    defaultValues: {
      url: webhook?.url ?? 'https://',
      label: webhook?.label,
    },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    if (webhook) {
      await handleUpdate(data, webhook);
    } else {
      await handleCreate({
        ...data,
        ressources: [RessourceType.ChangelogSubscribed],
      });
    }

    onClose();
  });

  return (
    <StyledModal hide={onClose}>
      <div className="flex items-center justify-between">
        <StyledModalTitle>{webhook ? 'Edit webhook' : 'Create webhook'}</StyledModalTitle>

        <ActionButton onClick={onClose}><CloseIcon /></ActionButton>
      </div>
      <StyledP>Use webhook to fetch email addresses of people who subscribe.</StyledP>

      <StyledForm onSubmit={handleSubmit} noValidate>
        <div>
          <Label htmlFor={`${formId}-label`}>Label</Label>
          <StyledInput
            id={`${formId}-label`}
            placeholder="My webhook label"
            {...form.register('label')}
            type="text"
          />
        </div>

        <div>
          <Label htmlFor={`${formId}-url`}>URL</Label>
          <StyledInput
            id={`${formId}-url`}
            placeholder="https://my-webhook-url.com"
            error={form.formState.errors.url?.message}
            {...form.register('url', {
              validate: (value) => {
                if (!isUrl(value)) {
                  return 'Please enter a valid URL';
                }
                return undefined;
              },
            })}
            type="url"
          />
        </div>

        <StyledInformation>
          Each time someone subscribes to the changelog, their email will be sent to the provided webhook.
          <StyledInformationLink
            href="https://docs.cycle.app/the-graphql-api/webhooks#changelog_subscribed"
            target="_blank"
          >
            More information
          </StyledInformationLink>
        </StyledInformation>

        <div className="flex items-center justify-end">
          <ButtonV2 type="submit" loading={form.formState.isSubmitting}>{webhook ? 'Save changes' : 'Create webhook'}</ButtonV2>
        </div>
      </StyledForm>
    </StyledModal>
  );
};
