import { useQuery } from '@apollo/client';
import { MeNodeDocument } from '@cycle-app/graphql-codegen';

import { useGetAuth } from 'src/reactives/auth.reactive';
import { useGetInitial } from 'src/reactives/initial.reactive';
import { extract } from 'src/types/graphql.types';

export const useMyColor = () => {
  const { loading } = useGetInitial();
  const { userId } = useGetAuth();

  const { data } = useQuery(MeNodeDocument, {
    skip: !userId || loading,
    fetchPolicy: 'cache-only',
    variables: {
      id: userId,
    },
  });

  return extract('Me', data?.node)?.color;
};
