import { DownIcon, UpIcon } from '@cycle-app/ui/icons';

import { useReleaseNotesContext } from 'src/contexts/releaseNotesContext';
import { useNavigate } from 'src/hooks';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useParams } from 'src/hooks/router/useParams';

import { PrevNextContainer, Action } from './ReleaseNote.styles';

export const ReleaseNotePrevNext = () => {
  const { noteId } = useParams();
  const { navigateToReleaseNote } = useNavigate();
  const { releaseNote } = useReleaseNote(noteId);
  const group = releaseNote?.isOther ? 'other' : 'main';
  const releaseId = useReleaseNotesContext(ctx => ctx.releaseId);
  const list = useReleaseNotesContext(ctx => ctx[group].list);
  const hasNextPage = useReleaseNotesContext(ctx => ctx[group].hasNextPage);
  const loadMore = useReleaseNotesContext(ctx => ctx[group].loadMore);
  const index = list.findIndex(note => note.id === noteId);

  const goPrev = () => {
    const prev = list[index - 1];
    if (prev) navigateToReleaseNote(releaseId, prev.id);
  };

  const goNext = async () => {
    if (index >= list.length - 1 && hasNextPage) await loadMore();
    const next = list[index + 1];
    if (next) navigateToReleaseNote(releaseId, next.id);
  };

  return (
    <PrevNextContainer>
      <Action onClick={goPrev} disabled={index === 0}>
        <UpIcon size={16} />
      </Action>

      <Action onClick={goNext} disabled={index === list.length - 1 && !hasNextPage}>
        <DownIcon size={16} />
      </Action>
    </PrevNextContainer>
  );
};
