import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GoogleDocIcon: FC<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <g clipPath="url(#clip0_3374_3621)">
      <mask id="mask0_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
        <path d="M13.7299 0H2.16064V16H13.7299V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3374_3621)">
        <mask id="mask1_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="white" />
        </mask>
        <g mask="url(#mask1_3374_3621)">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L11.1991 2.54546L9.39141 0Z" fill="#4285F4" />
        </g>
        <mask id="mask2_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="white" />
        </mask>
        <g mask="url(#mask2_3374_3621)">
          <path d="M9.7085 4.04492L13.7297 8.08855V4.36401L9.7085 4.04492Z" fill="url(#paint0_linear_3374_3621)" />
        </g>
        <mask id="mask3_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="white" />
        </mask>
        <g mask="url(#mask3_3374_3621)">
          <path d="M5.05322 11.6364H10.8378V10.9091H5.05322V11.6364ZM5.05322 13.0909H9.39169V12.3636H5.05322V13.0909ZM5.05322 8V8.72728H10.8378V8H5.05322ZM5.05322 10.1818H10.8378V9.45454H5.05322V10.1818Z" fill="#F1F1F1" />
        </g>
        <mask id="mask4_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="white" />
        </mask>
        <g mask="url(#mask4_3374_3621)">
          <path d="M9.39111 0V3.27272C9.39111 3.87546 9.87647 4.36363 10.4757 4.36363H13.7296L9.39111 0Z" fill="#A1C2FA" />
        </g>
        <mask id="mask5_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="white" />
        </mask>
        <g mask="url(#mask5_3374_3621)">
          <path d="M3.24526 0C2.64872 0 2.16064 0.490909 2.16064 1.09091V1.18182C2.16064 0.581818 2.64872 0.0909091 3.24526 0.0909091H9.39141V0H3.24526Z" fill="white" fillOpacity="0.2" />
        </g>
        <mask id="mask6_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="white" />
        </mask>
        <g mask="url(#mask6_3374_3621)">
          <path d="M12.6457 15.9102H3.24575C2.64921 15.9102 2.16113 15.4193 2.16113 14.8193V14.9102C2.16113 15.5102 2.64921 16.0012 3.24575 16.0012H12.6457C13.2423 16.0012 13.7304 15.5102 13.7304 14.9102V14.8193C13.7304 15.4193 13.2423 15.9102 12.6457 15.9102Z" fill="#1A237E" fillOpacity="0.2" />
        </g>
        <mask id="mask7_3374_3621" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="16">
          <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="white" />
        </mask>
        <g mask="url(#mask7_3374_3621)">
          <path d="M10.4757 4.36337C9.87647 4.36337 9.39111 3.8752 9.39111 3.27246V3.36337C9.39111 3.96611 9.87647 4.45429 10.4757 4.45429H13.7296V4.36337H10.4757Z" fill="#1A237E" fillOpacity="0.1" />
        </g>
        <path d="M9.39141 0H3.24526C2.64872 0 2.16064 0.490909 2.16064 1.09091V14.9091C2.16064 15.5091 2.64872 16 3.24526 16H12.6453C13.2418 16 13.7299 15.5091 13.7299 14.9091V4.36363L9.39141 0Z" fill="url(#paint1_radial_3374_3621)" />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_3374_3621" x1="11.7193" y1="4.39212" x2="11.7193" y2="8.08913" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1A237E" stopOpacity="0.2" />
        <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient id="paint1_radial_3374_3621" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.52716 0.31624) scale(18.6552)">
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3374_3621">
        <rect width="11.68" height="16" fill="white" transform="translate(2.16016)" />
      </clipPath>
    </defs>
  </Svg>
);
