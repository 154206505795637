import { useApolloClient, useQuery } from '@apollo/client';
import { FetchDocDocSourceDocument } from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';

type useDocDocSourceParams = {
  docId: string;
};

export const useDocDocSource = ({ docId }: useDocDocSourceParams) => {
  const {
    data, loading,
  } = useQuery(FetchDocDocSourceDocument, {
    skip: !docId,
    variables: { docId },
  });
  const doc = useMemo(() => (data?.node?.__typename === 'Doc' ? data.node : null), [data]);
  return {
    blockId: doc?.docSource?.blockId,
    docSource: doc?.docSource?.doc,
    isLoading: loading,
  };
};

export const useDocDocSourceLazy = () => {
  const {
    query, cache,
  } = useApolloClient();

  const getDocDocSource = useCallback(async (docId: string) => {
    const { data } = await query({
      query: FetchDocDocSourceDocument,
      variables: { docId },
    });
    return data.node?.__typename === 'Doc' ? data.node.docSource : null;
  }, []);

  const getDocDocSourceFromCache = useCallback((docId: string) => {
    const data = cache.readQuery({
      query: FetchDocDocSourceDocument,
      variables: { docId },
    });
    return data?.node?.__typename === 'Doc' ? data.node.docSource : null;
  }, []);

  return {
    getDocDocSource,
    getDocDocSourceFromCache,
  };
};
