import { DocBaseFragment, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { DocReleaseNote } from 'src/components/DocReleaseNote';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ReleaseNoteAlert } from 'src/components/ReleaseNote';
import { EditorContainer, EditorHeader, StyledDocPanelRealtime } from 'src/components/ReleaseNote/ReleaseNote.styles';
import { ReleaseNoteDocOpen } from 'src/components/ReleaseNote/ReleaseNoteDocOpen';
import { ReleaseNoteEditor } from 'src/components/ReleaseNote/ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from 'src/components/ReleaseNote/ReleaseNoteEditorTitle';
import { ReleaseNoteTags } from 'src/components/ReleaseNote/ReleaseNoteTags';
import { useGetPermission } from 'src/reactives';
import { closeFeatureReleaseNote } from 'src/reactives/docRightPanel.reactive';

type Props = {
  releaseNote: NonNullable<DocBaseFragment['releaseNote']>;
};

export const FeatureDocReleaseNotePanelContent = ({ releaseNote }: Props) => {
  const { canUpdateReleaseNote } = useGetPermission();
  const isReadonly = releaseNote.release.publicStatus === ReleasePublicStatus.Published;
  return (
    <div className="flex h-full flex-col">
      <div className="flex h-14 items-center justify-between border-b border-grey-200 px-5 dark:border-grey-850">
        <div className="flex items-center gap-3">
          <ActionButton
            className="size-6"
            onClick={closeFeatureReleaseNote}
          >
            <CloseIcon />
          </ActionButton>

          <DocReleaseNote
            releaseNote={releaseNote}
          />
        </div>

        <div className="flex items-center gap-3">
          <StyledDocPanelRealtime />

          <ReleaseNoteDocOpen
            isReadOnly={isReadonly}
            releaseId={releaseNote.release.id}
            noteId={releaseNote.id}
          />
        </div>
      </div>

      <ErrorBoundary>
        <EditorContainer>
          <EditorHeader>
            <ReleaseNoteAlert
              releaseNoteId={releaseNote.id}
            />
            <ReleaseNoteTags
              isReadonly={isReadonly}
              releaseNoteId={releaseNote.id}
            />
            <ReleaseNoteEditorTitle
              releaseNoteId={releaseNote.id}
              isReadOnly={isReadonly || !canUpdateReleaseNote}
            />
          </EditorHeader>

          <ReleaseNoteEditor
            releaseNoteId={releaseNote.id}
            isReadOnly={isReadonly || !canUpdateReleaseNote}
          />
        </EditorContainer>
      </ErrorBoundary>
    </div>
  );
};

