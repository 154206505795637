import { Skeleton } from '@cycle-app/ui';
import styled from 'styled-components';

export const SkeletonGroupName = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 20px;
`;

export const EmojiSkeleton = styled(Skeleton)`
  height: 20px;
  width: 20px;
`;

export const GroupNameSkeleton = styled(Skeleton)`
  width: 98px;
`;

export const NewDocContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
`;

export const NewDocContentSkeleton = styled(Skeleton)`
  width: 40px;
`;
