import { make } from 'src/utils/reactives.util';

const defaultState = {
  feedback: false,
  insights: false,
  releases: false,
  roadmaps: false,
  slack: false,
};

type State = typeof defaultState;

const {
  hookValue,
  setValue,
} = make<State>({
  defaultState,
});

const useGetLearnMorePopups = hookValue;

export const useGetLearnMorePopup = (key: keyof State) => {
  const state = useGetLearnMorePopups();
  return state[key];
};

export const setLearnMorePopup = (key: keyof State, value: boolean) => setValue({ [key]: value });
