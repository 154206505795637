import { memo } from 'react';

import { Notifications } from 'src/components/Notifications';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';

import { Container } from './SidebarHeader.styles';
import { SidebarHeaderProduct } from './SidebarHeaderProduct';
import { SidebarHeaderUser } from './SidebarHeaderUser';

export const SidebarHeader = memo(() => {
  const collapsed = useSidebarCollapsed();
  return (
    <Container $collapsed={collapsed}>
      <SidebarHeaderProduct />
      {!collapsed && <Notifications />}
      <SidebarHeaderUser />
    </Container>
  );
});
