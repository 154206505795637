import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import { Editor } from '@tiptap/core';
import { FC, useMemo } from 'react';

import { getAutomationType } from 'src/utils/embeddable.util';

import { AutomationType } from '../types/automation.types';
import { MaybeUndefined } from '../utils/utils';

type DocLinearProviderProps = {
  integrationId: string;
  docId?: string;
  automationId: MaybeUndefined<string>;
  automationUrl: MaybeUndefined<string>;
  showSyncAutomationContent?: boolean;
  editor?: Editor;
};

type DocLinearContextValue = DocLinearProviderProps & {
  automationType?: AutomationType;
};

const DocLinearContext = createContext<DocLinearContextValue>({} as DocLinearContextValue);

export const DocLinearProvider: FC<React.PropsWithChildren<DocLinearProviderProps>> = ({
  integrationId, docId, automationId, automationUrl, children, showSyncAutomationContent, editor,
}) => {
  const value = useMemo(() => {
    return {
      integrationId,
      docId,
      automationId,
      automationUrl,
      automationType: getAutomationType(automationUrl),
      showSyncAutomationContent,
      editor,
    };
  }, [integrationId, docId, automationId, automationUrl, showSyncAutomationContent, editor]);

  return (
    <DocLinearContext.Provider value={value}>
      {children}
    </DocLinearContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useDocLinearContext = <T extends unknown = DocLinearContextValue>(selector?: ContextSelector<DocLinearContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(DocLinearContext);
  if (!isWrappedWithContext) throw new Error('useDocLinearContext must be used within a DocLinearProvider');
  return useContextSelector(DocLinearContext, selector ?? (ctx => ctx as T));
};
