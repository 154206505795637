import { FileUploadedData, FileType } from '@cycle-app/utilities';
import { ReactNode, useEffect, useState } from 'react';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { FileForm } from 'src/components/Editor/SlashDropdown/FileForm/FileForm';
import { useEditorContext } from 'src/contexts/editorContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { getIsEditorNodeOpen, removeEditorNodeOpen } from 'src/reactives';
import type { KeyEditorNodeOpenState } from 'src/reactives';

import {
  Container, Button, DropdownContent, Label, StyledVideoIcon, StyledDocumentEmptyIcon, StyledImageIcon, StyledSpinner, StyledVolumeIcon,
} from './EditorNodeEmptyState.styles';
import { Layer } from '../../types/layers.types';


type EditorNodeEmptyStateProps = {
  type: FileType;
  onUploaded: (fileData: FileUploadedData) => void;
  onCancel: VoidFunction;
  dataId: string;
  isLoading?: boolean;
};

const stateKeyMap: Record<EditorNodeEmptyStateProps['type'], KeyEditorNodeOpenState> = {
  video: 'videoNodeOpen',
  file: 'fileNodeOpen',
  image: 'fileNodeOpen',
  audio: 'audioNodeOpen',
};

const iconMap: Record<EditorNodeEmptyStateProps['type'], ReactNode> = {
  video: <StyledVideoIcon />,
  file: <StyledDocumentEmptyIcon />,
  image: <StyledImageIcon />,
  audio: <StyledVolumeIcon />,
};

const labelMap: Record<EditorNodeEmptyStateProps['type'], string> = {
  video: 'Click to upload video',
  file: 'Click to upload file',
  image: 'Click to upload image',
  audio: 'Click to upload audio',
};

export const EditorNodeEmptyState = ({
  type, onCancel, dataId, onUploaded, isLoading,
}: EditorNodeEmptyStateProps) => {
  const [isOpen, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(getIsEditorNodeOpen(stateKeyMap[type], dataId));
  const onUpload = useEditorContext(ctx => ctx.onUpload);
  const editor = useEditorContext(ctx => ctx.editor);
  const isReadOnly = useEditorContext(ctx => ctx.isReadOnly);
  const [uploadingFile, setUploadingFile] = useState<File | null>(null);

  useEffect(() => {
    const handleCancel = () => {
      if (isReadOnly) {
        onCancel();
        editor.off('update', handleCancel);
      }
    };
    editor.on('update', handleCancel);
    return () => {
      editor.off('update', handleCancel);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, isReadOnly]);

  return (
    <Container>
      <DropdownLayer
        // This dropdown should have different layer from the SlashDropdown
        // to avoid conflicts between the reactive update.
        layer={Layer.DropdownZ1}
        visible={isOpen}
        hide={() => {
          setFalseCallback();
          removeEditorNodeOpen(stateKeyMap[type], dataId);
          editor.commands.focus();
        }}
        placement="bottom-start"
        offset={[0, 15]}
        content={(
          <DropdownContent>
            <FileForm
              type={type}
              onCancel={() => {
                setFalseCallback();
                removeEditorNodeOpen(stateKeyMap[type], dataId);
                onCancel();
              }}
              onFileSelected={async file => {
                setFalseCallback();
                setUploadingFile(file);
                const uploadedFile = await onUpload?.(file, 'slash-menu');
                if (uploadedFile) {
                  removeEditorNodeOpen(stateKeyMap[type], dataId);
                  onUploaded({
                    ...uploadedFile,
                    /**
                     * Backend is responding with type file when is audio file
                     * We overwrite it to fit our logic
                     */
                    type: uploadedFile.mime.includes('audio') ? 'audio' : uploadedFile.type,
                  });
                } else {
                  setUploadingFile(null);
                }
              }}
            />
          </DropdownContent>
        )}
      >
        <Button onClick={() => {
          if (uploadingFile || isLoading) return;
          setTrueCallback();
        }}
        >
          {(uploadingFile || isLoading) ? (
            <>
              {iconMap[type]}
              <Label>{uploadingFile?.name || 'Loading'}</Label>
              <StyledSpinner />
            </>
          ) : (
            <>
              {iconMap[type]}
              <Label>{labelMap[type]}</Label>
            </>
          )}
        </Button>
      </DropdownLayer>
    </Container>
  );
};
