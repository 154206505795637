import { ThemeType } from '@cycle-app/graphql-codegen/generated';
import { ShyScrollbarCss, typo, Button, InfiniteScroll, boxShadowZ4 } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

export const StyledDropdownLayer = styled(DropdownLayer)`
  width: 500px;
  /* With that min, the notif center shrinks for low height screens.
  100vh - 112px = 100vh - space between the top of the page and the NotificationCenter (100px) - 12px padding */
  max-height: min(745px, 100vh - 112px);

  display: flex;
  flex-direction: column;

  ${ShyScrollbarCss}

  background-color: ${p => p.theme.colors.notificationCenter.bg};
  color: ${p => p.theme.colors.notificationCenter.color};
  border: 1px solid ${p => p.theme.colors.border.primary};
  ${boxShadowZ4}

  ${mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: unset;
    max-height: unset;
    border-radius: 0;
  }
`;

export const EmptyState = styled.div`
  padding: 16px;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  header {
    z-index: 1;
    flex: none;
    position: sticky;
    top: 0;
    padding: 10px 20px;
    height: 56px;
    background: ${p => p.theme.colors.notificationCenter.bg};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${p => p.theme.colors.border.primary};
    ${mobile} {
      justify-content: center;
    }
  }
`;

export const Heading = styled.span`
  ${typo.headerLight}
  ${mobile} {
    ${typo.body500}
  }
`;

export const SettingsButton = styled(Button).attrs({
  size: 'L',
  variant: 'nospace',
  useUserColor: true,
})`
  padding: 3px 8px;
  ${p => (p.theme.themeType === ThemeType.Nightfall ? css`
    --color: ${p.theme.colors.text.white};
    :hover, :focus {
      --color: ${p.theme.colors.text.white};
      --bg: ${p.theme.colors.background.hoverSoft};
    }
    :active {
      --color: ${p.theme.colors.text.white};
      --bg: ${p.theme.colors.background.tertiary};
    }
  ` : css`
    --color: hsl(var(--cycle));
    :hover, :focus {
      --color: hsl(var(--cycle600));
    }
    :active {
      --color: hsl(var(--cycle700));
    }
  `)};
`;

export const SettingsCloseButton = styled(SettingsButton)`
  position: absolute;
  left: 20px;
  color: ${p => p.theme.colors.components.MobileHeader.closeButton.color};
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  > *:not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  }
`;
