import { make } from 'src/utils/reactives.util';

interface ClosingAreaState {
  displayed: boolean;
}

export const {
  hookValue: useClosingArea,
  getValue: getClosingArea,
  setValue: setClosingArea,
} = make<ClosingAreaState>({
  defaultState: {
    displayed: false,
  },
});
