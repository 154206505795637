import { BOX_SHADOW_COLOR_Z2 } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const BTN_SIZE = 42;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SourceContainer = styled(motion.div)`
  width: ${BTN_SIZE}px;
  height: ${BTN_SIZE}px;
  position: absolute;
  transform-origin: 50% 200%;
`;

export const Button = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${BTN_SIZE}px;
  height: ${BTN_SIZE}px;
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  background: ${p => p.theme.colors.inbox.zero.item.bg};
  box-shadow:
    0px 2px 5px ${BOX_SHADOW_COLOR_Z2},
    0px 5px 17px ${BOX_SHADOW_COLOR_Z2};
  ${p => p.onClick && css`
    cursor: pointer;
    :hover, :focus, &[data-active=true] {
      background: ${p.theme.colors.background.secondary};
    }
    :active {
      background: ${p.theme.colors.background.tertiary};
    }
  `}
`;

export const SourceButton = styled(Button)`
  > * {
    height: 21px;
    width: 21px
  }
`;

export const AddSourceButton = styled(Button)`
  color: ${p => p.theme.colors.text.disabled};
`;
