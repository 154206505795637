import { make } from 'src/utils/reactives.util';

interface HotkeyResult {
  mod: boolean;
  shift: boolean;
  last: string | null;
}

export const {
  hookState: useHotkey,
  hookValue: useGetHotkey,

  getValue: getHotkey,
  setValue: setHotkey,
} = make<HotkeyResult>({
  defaultState: {
    mod: false,
    shift: false,
    last: null,
  },
});
