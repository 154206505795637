import { AnimatePresence } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

import { useIntercom } from 'src/hooks/useIntercom';
import { openIntercom } from 'src/utils/intercom.utils';

import { Container, LogoContainer, Logo, Tooltip } from './Chat.styles';

interface Props {
  tooltip?: ReactNode;
  logo?: ReactNode;
}

export const Chat = ({
  tooltip, logo,
}: Props) => {
  useIntercom();
  const [showChat, setShowChat] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    window.Intercom?.('update', {
      // Hides intercom icon.
      hide_default_launcher: true,
    });

    const timeout = window.setTimeout(() => {
      setShowChat(true);
    }, 10_000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  return (
    <AnimatePresence>
      {showChat && (
        <Container>
          {showTooltip && (
            <Tooltip
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {tooltip ?? <>Psst... Want to skip the form and book demo now? 👀</>}
            </Tooltip>
          )}
          <LogoContainer
            initial={{
              opacity: 0,
            }}
            transition={{
              duration: 1,
              ease: [0.075, 0.82, 0.165, 1],
            }}
            animate={{
              opacity: 1,
            }}
            onClick={() => {
              setShowTooltip(false);
              openIntercom();
            }}
          >
            {logo ?? <Logo fill="#FFF" />}
          </LogoContainer>
        </Container>
      )}
    </AnimatePresence>
  );
};
