import { getDocFullUrl, getDocSlug } from '@cycle-app/utilities';

import { PageId } from 'src/constants/routing.constant';
import { useUrl } from 'src/hooks/useUrl';
import { copyToClipboard } from 'src/utils/clipboard.utils';

export const useCopyCommentLink = () => {
  const getUrl = useUrl();
  return (doc: Parameters<typeof getDocSlug>[0] | null | undefined, commentId: string) => {
    if (!doc) return;
    const docUrl = getDocFullUrl(getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) }));
    copyToClipboard({
      text: `${docUrl}?commentId=${commentId}`,
      notification: 'Link copied to clipboard',
    });
  };
};
