import { FilterGroup } from '@luzmo/react-embed';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

type StateValues = {
  lastRefreshRequested: number | null;
  requestId: string;
  progress: number;
  aiSuggestion: string;
  isAiGenerating: boolean;
  dashboardManualFilters: FilterGroup[];
  mutationId: string | null;
  dashboardId: string | null;
  token: string | null;
  key: string | null;
};

const defaultState: StateValues = {
  lastRefreshRequested: null,
  requestId: '',
  progress: 0,
  aiSuggestion: '',
  isAiGenerating: false,
  dashboardManualFilters: [],
  dashboardId: null,
  mutationId: null,
  token: null,
  key: null,
};

type State = {
  [dashboardId: string]: StateValues;
};

const {
  hookState,
  setValue,
  getValue,
  hookValue,
} = make<State>({
  defaultState: {},
  localKey: LocalKey.CustomerDashboardRefresh,
});

export const useCustomerDashboardState = (dashboardId: string) => {
  const [state] = hookState();
  return [state[dashboardId]] as const;
};

export const useCustomerDashboardStateValue = (dashboardId: string) => {
  return hookValue()[dashboardId];
};

export const setCustomerDashboard = (dashboardId: string, state: Partial<StateValues>) => {
  const values = getValue();
  setValue({
    ...values,
    [dashboardId]: {
      ...defaultState,
      ...values[dashboardId],
      ...state,
    },
  });
};

export const getCustomerDashboard = (dashboardId: string) => getValue()[dashboardId];

export const getCustomerDashboards = getValue;

export const resetCustomerDashboardRefresh = (dashboardId: string) => {
  setCustomerDashboard(dashboardId, {
    requestId: '',
    progress: 0,
    aiSuggestion: getValue()[dashboardId]?.aiSuggestion,
  });
};
