import { nodeToObject } from '@cycle-app/utilities';
import { usePresenceChannel } from '@harelpls/use-pusher';
import { useEffect, useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useProduct, useProductBase } from 'src/hooks/api/useProduct';
import { setPresence, useGetPresence } from 'src/reactives/presence.reactive';
import { Channel, getChannelName } from 'src/utils/presence.util';

import { useLocationEvent } from './useLocationEvent';
import { useTriggerLocation } from './useTriggerLocation';

const EVENT_NAME = 'client-location';

export const useCreateProductChannel = () => {
  const product = useProductBase();

  const {
    members, channel,
  } = usePresenceChannel(getChannelName(
    Channel.Product,
    product?.id ?? '',
  ));

  useEffect(() => {
    if (!members) return;
    setPresence({
      memberIds: Object.keys(members),
    });
  }, [members]);

  useLocationEvent(channel, EVENT_NAME);
  useTriggerLocation(channel, EVENT_NAME);
};

export const useProductPresence = () => {
  const { product } = useProduct();
  const { memberIds } = useGetPresence();
  return useMemo(() => {
    const productUsers = nodeToObject(product?.users);
    return memberIds.map(userId => productUsers[userId]).filter(isPresent);
  }, [memberIds, product?.users]);
};
