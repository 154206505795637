import { typo } from '@cycle-app/ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  align-items: center;
  background-color: ${p => p.theme.colors.home.card.bg};
  border-radius: 12px;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`;

export const Title = styled.div`
  ${typo.headerLight}
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const Label = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.secondary};
`;
