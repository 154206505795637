import { Tooltip } from '@cycle-app/ui';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useBoardLink } from 'src/hooks/boards/useBoardLink';
import { BoardLinkPanel } from 'src/hooks/boards/useBoardMenuOptions';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { getSectionName } from 'src/utils/viewSection.utils';

import { ViewAction } from './ViewsList.styles';

export const BoardLinkButton = ({ boardId }: { boardId: string }) => {
  const { canUpdateView } = useGetPermission();
  const {
    defaultSection, mappingBoardLinks, customSections, sections,
  } = useBoardSections();
  const {
    createBoardLink, removeBoardLink,
  } = useBoardLink();
  const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === boardId);
  const hasCustomSections = !!customSections.length;

  const placeholder = !hasCustomSections && defaultSection
    ? `Add to ${getSectionName(defaultSection, sections.length).toLowerCase()}`
    : 'Add to a section';

  const tooltipContent = !hasCustomSections && defaultSection
    ? `Remove from ${getSectionName(defaultSection, sections.length).toLowerCase()}`
    : 'Change section';

  return (
    <ToggleDropdown
      placement="bottom"
      withWrapper
      content={props => (
        <BoardLinkPanel
          viewId={boardId}
          onChange={() => props.hide()}
          boardLinkId={boardLink?.id}
          boardLinkSectionId={boardLink?.section.id}
          showHeader={false}
        />
      )}
      button={props => (
        <Tooltip
          content={tooltipContent}
          disabled={!boardLink}
          placement="top"
          withWrapper={false}
        >
          <ViewAction
            forceFocus={props['data-active']}
            $visible={!!boardLink}
            onClick={(e) => {
              e.stopPropagation();
              if (!canUpdateView) {
                setLimitationsModal({
                  action: 'VIEW_UPDATE',
                });
                return;
              }
              if (hasCustomSections) {
                props.onClick(e);
                return;
              }
              if (defaultSection && !boardLink) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                createBoardLink({
                  boardId,
                  sectionId: defaultSection.id,
                });
                return;
              }
              if (defaultSection && boardLink) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                removeBoardLink({
                  boardLinkId: boardLink.id,
                });
              }
            }}
          >
            {boardLink
              ? getSectionName(boardLink.section, sections.length)
              : placeholder}
          </ViewAction>
        </Tooltip>
      )}
    />
  );
};
