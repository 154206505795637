import { ThemeType } from '@cycle-app/graphql-codegen';
import { typo, Button, BOX_SHADOW_COLOR_Z4, ActionButton } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { PortalModalStyled } from 'src/components/DialogModal/DialogModal.styles';
import { RELEASES_PRODUCT_TOUR_SLIDE_WIDTH } from 'src/constants/releases.constants';

export const Modal = styled(PortalModalStyled)`
  display: flex;
  flex-direction: column;
  width: ${RELEASES_PRODUCT_TOUR_SLIDE_WIDTH}px;
  height: 513px;
  min-width: 400px;
  text-align: center;
  padding: 0;

  background: ${p => p.theme.colors.releases.gradient};
  
  border: 1px solid rgba(0, 0, 0, 0.06);
  ${p => p.theme.themeType === ThemeType.Nightfall && css`
    border: transparent;
    box-shadow:
      0px 6px 15px ${BOX_SHADOW_COLOR_Z4},
      inset 0 0 0 calc(1px) rgba(255, 255, 255, 0.06);
  `};

  h2 {
    ${typo.headerLight}
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    padding: 0 32px; 
    ${typo.body400}
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Carousel = styled.div`
  overflow: hidden;
  position: relative;
  flex: 1;
`;

export const Slides = styled(motion.div)`
  display: flex;
  position: absolute;
  height: 100%;
  cursor: grab;
`;

export const Slide = styled.div`
  flex: none;
  width: ${RELEASES_PRODUCT_TOUR_SLIDE_WIDTH}px;
  height: 100%;
  padding: 64px 0 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  p {
    user-select: none;
  }
`;

export const Actions = styled.div`
  padding-bottom: 64px;
  height: 138px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Bullets = styled.div`
  display: flex;
`;

export const Bullet = styled.button<{ $isActive: boolean }>`
  width: 16px;
  height: 16px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;

  :after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 4px;
    background: ${p => (p.$isActive ? p.theme.colors.releases.bulletActive : p.theme.colors.releases.bullet)};
  }
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  > div:first-child {
    justify-self: end;
  }
`;

const StyledButton = styled(Button).attrs({
  size: 'L',
})`
  height: 32px;
  padding: 0 12px;
`;

export { StyledButton as Button };

export const Badge = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  background: ${p => p.theme.colors.background.tertiary};
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  &, :hover, :focus, :active {
    background: transparent;
  }
`;
