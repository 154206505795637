import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';

import { Input, InputWrapper, TriggerWrapper } from './EditableText.styles';

interface Props {
  className?: string;
  value: string;
  placeholder?: string;
  startInEditMode?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  maxLength?: number;
}

export interface EditableTextHandle {
  focus: () => void;
}

export const EditableText = forwardRef<EditableTextHandle, Props>(({
  className,
  startInEditMode,
  disabled,
  onChange,
  onBlur,
  ...inputProps
}, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInEditMode, setIsInEditMode] = useState(startInEditMode);

  useImperativeHandle(ref, () => ({
    focus: () => {
      focusEndOfInput();
    },
  }));

  useEffect(() => {
    if (isInEditMode) {
      focusEndOfInput();
    }
  }, [isInEditMode]);

  return (
    <InputWrapper>
      <TriggerWrapper
        role="button"
        tabIndex={-1}
        onFocus={() => { setIsInEditMode(true); }}
      >
        <Input
          className={className}
          ref={inputRef}
          type="text"
          disabled={!isInEditMode || disabled}
          onChange={e => onChange(e.currentTarget.value)}
          onBlur={() => {
            setIsInEditMode(false);
            onBlur?.();
          }}
          {...inputProps}
        />

      </TriggerWrapper>
    </InputWrapper>
  );

  function focusEndOfInput() {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current?.value.length;
      inputRef.current.selectionEnd = inputRef.current?.value.length;
    }
  }
});
