import { MeFragment, SetupMyAccountPreferencesDocument } from '@cycle-app/graphql-codegen';
import { Avatar, ColorPicker, Button } from '@cycle-app/ui';
import { readFileSrc } from '@cycle-app/utilities';
import { useState, FC } from 'react';
import { useForm } from 'react-hook-form';

import defaultLogo from 'src/assets/workspace-default-logo.png';
import DefaultAvatarPicker from 'src/components/DefaultAvatarPicker/DefaultAvatarPicker';
import { FieldLabel } from 'src/components/Form/Form.styles';
import { ThemePicker } from 'src/components/ThemePicker';
import { Events, Objects } from 'src/constants/analytics.constants';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useSyncThemeWithSystem } from 'src/hooks/useHandleSystemTheme';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useGetOnboarding, setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { setThemeConfig } from 'src/reactives/theme.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import { AvatarContainer, AvatarInput, StyledImageInput, ThemePickerContainer, UploadButton } from './OnboardingStepCustomize.styles';
import { AsideApp } from '../OnboardingLayout/AsideApp';
import { Logo } from '../OnboardingLayout/Logo';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { ErrorHelper, Footer, Form, FormGrid } from '../OnboardingLayout/OnboardingLayout.styles';

interface Props {
  me: MeFragment;
  products: { slug: string }[];
  shouldBookCall: boolean;
}

interface FormData {
  avatarFile: File | null;
  avatarString: string;
}

export const OnboardingStepCustomize: FC<React.PropsWithChildren<Props>> = ({
  me, products, shouldBookCall,
}) => {
  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {
      avatarFile: null,
      avatarString: me.avatar?.url || '',
    },
  });
  const [previewSrc, setPreviewSrc] = useState(me.avatar?.url);
  const [updateMe, { loading: isLoading }] = useSafeMutation(SetupMyAccountPreferencesDocument, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data.updateMe?.id) {
        trackAnalytics(Events.UserAccountUpdated, {
          object: Objects.UserTheme,
        });
      }
    },
    onError: (apolloError) => {
      if (apolloError.message.match('code 413')) {
        setError('avatarFile', {
          type: 'manual',
          message: 'This image was not accepted. Please try another one.',
        });
      } else {
        addErrorToaster({
          message: ErrorMessage._GENERIC,
        });
      }
    },
  });
  const {
    theme, userColor, syncWithSystem,
  } = useGetOnboarding();

  useSyncThemeWithSystem({
    setTheme: value => setOnboarding({ theme: value }),
    isEnabled: syncWithSystem,
  });

  async function onSubmit(values: FormData) {
    // eslint-disable-next-line no-nested-ternary
    const avatarInput = values.avatarFile
      ? { avatar: values.avatarFile }
      : values.avatarString
        ? { avatarUrl: values.avatarString }
        : null;
    setThemeConfig({
      colorTheme: theme,
      syncWithSystem,
    });
    const result = await updateMe({
      variables: {
        avatar: avatarInput,
        color: userColor,
        theme,
      },
    });
    if (result.data?.updateMe?.id) {
      let nextScreen = LightOnboardingScreen.Workspace;
      if (products.length) nextScreen = LightOnboardingScreen.Done;
      if (products.length && shouldBookCall) nextScreen = LightOnboardingScreen.BookCall;
      setOnboarding({
        screen: nextScreen,
      });
    }
  }

  return (
    <OnboardingLayout
      title="Make yourself at home"
      subtitle="Pick your color, profile picture and theme"
      aside={(
        <AsideApp
          color={userColor}
          logo={<Logo src={defaultLogo} name="" />}
          avatar={(
            <Avatar
              user={me}
              size={18}
              userColor={userColor}
              src={previewSrc}
              pending={false}
            />
          )}
        />
      )}
      main={(
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGrid>
            <div>
              <FieldLabel>Choose your color</FieldLabel>
              <ColorPicker
                color={userColor}
                onClick={(newColor) => setOnboarding({ userColor: newColor })}
              />
            </div>
            <div>
              <FieldLabel>Choose your picture</FieldLabel>
              <StyledImageInput
                previewModalTitle="Confirm your profile picture"
                previewModalSubmitLabel="Set new profile picture"
                previewBorderColor={userColor}
                onChange={async (newAvatarFile) => {
                  clearErrors('avatarFile');
                  setValue('avatarFile', newAvatarFile);
                  setValue('avatarString', '');
                  const avatarSrc = await readFileSrc(newAvatarFile);
                  setPreviewSrc(avatarSrc);
                }}
              >
                {(inputRef) => (
                  <AvatarContainer>
                    <AvatarInput>
                      <Avatar
                        user={me}
                        size={40}
                        onClick={() => inputRef.current?.click()}
                        userColor={userColor}
                        src={previewSrc}
                        pending={false}
                      />
                      <UploadButton
                        variant="ternary"
                        size="M"
                        onClick={(e) => {
                          e.preventDefault();
                          inputRef.current?.click();
                        }}
                      >
                        Upload image
                      </UploadButton>
                    </AvatarInput>
                    <DefaultAvatarPicker
                      onSelect={(avatarUrl) => {
                        clearErrors('avatarFile');
                        setValue('avatarFile', null);
                        setValue('avatarString', avatarUrl);
                        setPreviewSrc(avatarUrl);
                      }}
                      selectedSrc={previewSrc}
                      hideDescription
                      color={userColor}
                    />
                  </AvatarContainer>
                )}
              </StyledImageInput>
              {errors.avatarFile?.message && <ErrorHelper $hasError $size="S">{errors.avatarFile.message}</ErrorHelper>}
            </div>
            <ThemePickerContainer>
              <FieldLabel>Choose your theme</FieldLabel>
              <ThemePicker
                onChange={selectedTheme => setOnboarding({
                  theme: selectedTheme,
                  syncWithSystem: false,
                })}
                theme={theme}
                syncWithSystem={syncWithSystem}
              />
            </ThemePickerContainer>
          </FormGrid>
          <Footer>
            <Button isLoading={isLoading} type="submit" size="M">Next</Button>
          </Footer>
        </Form>
      )}
    />
  );
};
