import styled, { css } from 'styled-components';

import { LoadingIcon } from '../../../icons';
import { body600 } from '../../../theme/typo';
import { rotationCss } from '../../../utils/animation.utils';

export type Size = 'XS' | 'S' | 'S-M' | 'M' | 'L';

type ContainerProps = {
  $size?: Size;
  $hasCursor?: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${p => p.$size === 'XS' && css`
    --company-logo-size: 16px;
    --company-logo-icon-size: 9px;
  `}

  ${p => p.$size === 'S' && css`
    --company-logo-size: 20px;
    --company-logo-icon-size: 11px;
  `}
  ${p => p.$size === 'S' && css`
    --company-logo-size: 20px;
    --company-logo-icon-size: 11px;
  `}

  ${p => p.$size === 'S-M' && css`
    --company-logo-size: 40px;
    --company-logo-icon-size: 16px;
  `}

  ${p => p.$size === 'M' && css`
    --company-logo-size: 64px;
    --company-logo-icon-size: 24px;
  `}

  ${p => p.$size === 'L' && css`
    --company-logo-size: 80px;
    --company-logo-icon-size: 30px;
  `}

  ${p => p.$hasCursor && css`cursor: pointer;`}

  grid-area: avatar;
`;

export const ImageContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: var(--company-logo-size);
  justify-content: center;
  position: relative;
  width: var(--company-logo-size);
  overflow: hidden;

  svg {
    width: var(--company-logo-icon-size);
    height: var(--company-logo-icon-size);
  }
`;

export const Spinner = styled(LoadingIcon)`
  ${rotationCss}
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  ${p => !p.src && css`
    background-color: hsla(var(--cycle), 0.15)}
  `};
  color: hsl(var(--cycle));

  ${body600}
  font-size: var(--company-logo-icon-size);
  text-transform: uppercase;
`;
