import { BillingUpdateLayout } from 'src/components/BillingUpdateLayout';
import { BillingUpdateFeatures } from 'src/components/BillingUpgradeFeatures';
import { RequestMakerAccessBox } from 'src/components/RequestMakerAccessBox';

export const RequestMakerAccessView = () => {
  return (
    <BillingUpdateLayout
      title="Request Maker Role"
      description="Makers can process feedback, create roadmaps & releases, use AI features, customize settings, and export docs."
      main={<RequestMakerAccessBox />}
      features={<BillingUpdateFeatures plan="maker" />}
    />
  );
};
