import { InfiniteScroll, Skeleton, Tooltip } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';

import { CustomerActionsMenu } from 'src/components/CustomersList/CustomerActionsMenu';
import { useCompanyCustomers } from 'src/hooks/api/queries/customers/useCompany';
import { openCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { PeopleTable, QuotesCell, PeopleButton, ListAvatar } from './Profile.styles';

type Props = {
  companyId: string;
};

export const PeopleList = ({ companyId }: Props) => {
  const {
    customers, isLoading, loadMoreCustomers, pageInfo,
  } = useCompanyCustomers(companyId);

  return (
    <InfiniteScroll
      isLoading={isLoading}
      hasMoreData={!!pageInfo?.hasNextPage}
      loadMore={loadMoreCustomers}
    >
      <PeopleTable>
        <tbody>
          {customers.map(customer => {
            const { email } = customer;
            return (
              <tr key={customer.id}>
                <td>
                  <Tooltip
                    content="Open customer"
                    title={customer.name}
                    placement="top-start"
                    withPortal
                    withWrapper={false}
                  >
                    <PeopleButton onClick={() => openCustomerModal(customer.id)}>
                      <ListAvatar customer={customer} hideCompany />
                      {customer.name}
                    </PeopleButton>
                  </Tooltip>
                </td>
                <td>
                  <Tooltip
                    content="Copy email"
                    title={email}
                    placement="top-start"
                    withPortal
                    withWrapper={false}
                  >
                    {email && (
                      <PeopleButton
                        onClick={() => {
                          copyToClipboard({
                            text: email,
                            notification: 'Email copied to clipboard',
                          });
                        }}
                      >
                        {email}
                      </PeopleButton>
                    )}
                  </Tooltip>
                </td>
                <td>
                  <Tooltip
                    content="Quotes"
                    placement="top"
                    withPortal
                    withWrapper={false}
                  >
                    <QuotesCell>
                      <QuoteIcon size={14} />
                      {customer.nbInsights}
                    </QuotesCell>
                  </Tooltip>
                </td>
                <td>
                  <CustomerActionsMenu customer={customer} />
                </td>
              </tr>
            );
          })}
          {isLoading && <Skeletons />}
        </tbody>
      </PeopleTable>
    </InfiniteScroll>
  );
};

const Skeletons = () => (
  <>
    {range(3).map(i => (
      <tr key={i}>
        {range(4).map(j => (
          <td key={j}>
            <Skeleton height={20} />
          </td>
        ))}
      </tr>
    ))}
  </>
);

const range = (length: number) => Array.from({ length }, (_, i) => i + 1);
