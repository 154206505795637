import { ThemeType } from '@cycle-app/graphql-codegen';
import { boxShadowZ1, ActionButton, typo, Tag, transitionCss } from '@cycle-app/ui';
import { Container as ActionButtonContainer } from '@cycle-app/ui/components/Buttons/ActionButton/ActionButton.styles';
import { hover } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';

export const swimlaneDocCSS = css`
  background: ${p => p.theme.colors.background.secondary};
  border-radius: 6px;
  ${boxShadowZ1};

  position: sticky;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;

  display: flex;
  span.content {
    background: hsla(var(--cycle), ${p => (p.theme.themeType === ThemeType.Nightfall ? 0.2 : 0.1)});
    display: flex;
    align-items: center;
    border-radius: 6px;
    gap: 8px;
    padding: 4px 6px;
    width: 100%;

    ${ActionButtonContainer} {
      --bgHover: hsla(var(--cycle600), 0.1);
      --bgActive: hsla(var(--cycle700), 0.1);
    }
  }
`;

export const IndicatorBtn = styled(ActionButton) <{ $collapsed: boolean }>`
  padding: 0;
  color: ${p => p.theme.colors.text.disabled};
  svg {
    width: 21px;
    height: 21px;
    transition: transform 100ms linear;

    ${p => p.$collapsed && css`
      transform: rotate(-90deg);
    `};
  }
`;

export const Menu = styled(DotsMenuLayer)`
  margin-left: auto;
`;

export const LinkTag = styled(Tag)`
  color: ${p => p.theme.colors.text.secondary};
  --bg: ${p => p.theme.colors.tag.bg};
  --bgHover: ${p => p.theme.colors.tag.bgHover};
  --bgActive: ${p => p.theme.colors.tag.bgActive};
  border: 1px solid ${p => p.theme.colors.tag.border};
  ${boxShadowZ1}
  opacity: 0;
  ${transitionCss(['opacity'])}
  > * { cursor: pointer; }
`;

export const Container = styled.div<{
  asPlaceholder?: boolean;
  noSwimlaneDoc?: boolean;
}>`
  ${swimlaneDocCSS};
  ${p => p.noSwimlaneDoc && css`
    background: ${p.theme.colors.card.bg};
  `};

  ${p => p.asPlaceholder && css`
    background-color: hsla(var(--cycle), 0.1);

    ${SwimlaneGroupName} {
      opacity: 0;
    }
    ${Menu} {
      opacity: 0;
    }
    ${IndicatorBtn} {
      opacity: 0;
    }
  `};

  ${hover} {
    :hover ${LinkTag} {
      opacity: 1;
    }
  }

`;

export const SwimlaneGroupName = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
  overflow: hidden;
`;

export const DocTitle = styled.div`
  margin-bottom: 0;
  ${typo.body500};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
