import { ActionButton } from '@cycle-app/ui';
import { CloseIcon, BuildingDashIcon, SmileyIcon, EnvelopePillIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';

import { HELP_URL_SLACK } from 'src/constants/help.constants';
import { useOptimizedBooleanState } from 'src/hooks';
import {
  closeSlackSettingsModal,
  // openSlackSettingsAutomations,
  openSlackSettingsNotifications,
  openSlackSettingsReacjis,
  openSlackSettingsWorkspace,
  useSlackGetSettingsModal,
} from 'src/reactives/slackSettingsModal.reactive';
import { SlackSettingsTab } from 'src/types/slackSettings.types';

import { LimitationModalSlack } from '../LimitationModalSlack';
// import { AutomationsTab } from './AutomationsTab/AutomationsTab';
import { NotificationsTab } from './NotificationsTab/NotificationsTab';
import { ReacjisTab } from './ReacjisTab/ReacjisTab';
import {
  LeftPanel,
  Menu,
  MenuItem,
  RightPanel,
  StyledPortalModal,
  TabContent,
  Header,
  Title,
  Icon,
  StyledLearnMoreButton,
} from './SlackSettingsModal.styles';
import { WorkspaceTab } from './WorkspaceTab/WorkspaceTab';

const TAB_TITLES: Record<SlackSettingsTab, string> = {
  ws: 'Slack workspaces',
  reacjis: 'Reacjis',
  notifications: 'Public notifications',
  automations: 'Automations',
};

export const SlackSettingsModal = () => {
  const {
    visible, tab,
  } = useSlackGetSettingsModal();
  const [isExploreOpen, { setFalseCallback: closeExplore }] = useOptimizedBooleanState(false);
  return (
    <AnimatePresence>
      {visible && (
        <StyledPortalModal>
          <LeftPanel>
            <div>
              <Menu>
                <MenuItem
                  icon={<Icon><BuildingDashIcon width={15} /></Icon>}
                  label={TAB_TITLES.ws}
                  isActive={tab === 'ws'}
                  onClick={openSlackSettingsWorkspace}
                />
                <MenuItem
                  icon={<Icon><SmileyIcon /></Icon>}
                  label={TAB_TITLES.reacjis}
                  isActive={tab === 'reacjis'}
                  onClick={openSlackSettingsReacjis}
                />
                <MenuItem
                  icon={<Icon><EnvelopePillIcon width={15} /></Icon>}
                  label={TAB_TITLES.notifications}
                  isActive={tab === 'notifications'}
                  onClick={openSlackSettingsNotifications}
                />
                {/* <MenuItem
                  icon={<Icon><GearIcon width={12} /></Icon>}
                  label={TAB_TITLES.automations}
                  isActive={tab === 'automations'}
                  onClick={openSlackSettingsAutomations}
                /> */}
              </Menu>
            </div>
            <div>
              <StyledLearnMoreButton url={HELP_URL_SLACK} variant="nospace" full>
                Learn more about Slack
              </StyledLearnMoreButton>
            </div>
          </LeftPanel>

          <RightPanel>
            <Header>
              <Title>{TAB_TITLES[tab]}</Title>
              <ActionButton onClick={closeSlackSettingsModal}>
                <CloseIcon />
              </ActionButton>
            </Header>

            <TabContent>
              {tab === 'ws' && <WorkspaceTab />}
              {tab === 'notifications' && <NotificationsTab />}
              {/* {tab === 'automations' && <AutomationsTab />} */}
              {tab === 'reacjis' && <ReacjisTab />}
            </TabContent>
          </RightPanel>
          {isExploreOpen && (
            <LimitationModalSlack
              hide={closeExplore}
              plan="business"
              description="Get advanced Slack features with Cycle Business+"
            />
          )}
        </StyledPortalModal>
      )}
    </AnimatePresence>
  );
};
