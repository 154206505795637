import { StartTrialDocument, StartTrialMutationVariables } from '@cycle-app/graphql-codegen';

import { addToaster } from 'src/utils/toasters.utils';

import useSafeMutation from './useSafeMutation';

export const useStartTrial = () => {
  const [mutate, { loading }] = useSafeMutation(StartTrialDocument, {
    onCompleted() {
      addToaster({ message: 'This workspace is successfully in trial for 3 days' });
    },
  });

  const startTrial = (params: StartTrialMutationVariables) => mutate({
    variables: params,
  });

  return {
    startTrial,
    isLoading: loading,
  };
};
