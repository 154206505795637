import { boxShadowZ5, typo, Button, CycleLoader } from '@cycle-app/ui';
import { SortIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { ProductSelect } from 'src/app/NewDoc/Settings/ProductSelect';

export const Root = styled.div`
  align-items: center;
  background: ${p => p.theme.colors.login.wrapper};
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 16px;
`;

export const Container = styled.div<{ $isLoading: boolean }>`
  background: ${p => p.theme.colors.login.bg};
  box-shadow: ${p => p.theme.colors.login.shadow};
  border-radius: 12px;
  width: ${p => (p.$isLoading ? 'min(100%, 455px)' : 'fit-content')};
  max-width: min(100%, 500px);
  min-height: 455px;
  padding: 80px 48px;
  ${boxShadowZ5};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
`;

export const Loader = styled(CycleLoader)`
  background: ${p => p.theme.colors.login.bg};
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 28px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const AppLogo = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 9999px;
  border: 2px solid hsl(var(--cycle));
  background: ${p => (p.theme.isDark ? 'hsla(var(--cycle), 0.4)' : 'hsla(var(--cycle), 0.2)')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  color:  ${p => (p.theme.isDark ? p.theme.colors.text.primary : 'hsl(var(--cycle))')};
`;

export const Title = styled.p`
  ${typo.headerLight}
  font-size: 20px;
  line-height: 34px;
  color: ${p => p.theme.colors.text.primary};
`;

export const Info = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledSortIcon = styled(SortIcon)`
  color: ${p => p.theme.colors.text.disabled};
  height: 30px;
  width: 30px;
`;

export const SubmitButton = styled(Button).attrs({ size: 'L' })`
  width: 100%;
  height: 32px;
`;

export const ProductButton = styled(Button).attrs({
  size: 'L',
  variant: 'outlined-alt',
})`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const StyledProductSelect = styled(ProductSelect)`
  min-width: min(300px, calc(100vw - 96px));
`;
