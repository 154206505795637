import { SlackReacjiUpdateDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';
import { useSlackIntegration } from './useSlackIntegration';

export const useSlackReacjiUpdate = () => {
  const {
    integration, provider,
  } = useSlackIntegration();
  const [reacjiUpdateMutation, { loading: isLoading }] = useSafeMutation(SlackReacjiUpdateDocument);

  const reacjiUpdate = (emoji: string) => provider?.id && integration?.id &&
    reacjiUpdateMutation({
      optimisticResponse: {
        updateSlackIntegration: {
          id: provider.id,
          emoji,
        },
      },
      variables: {
        integrationId: integration.id,
        emoji,
      },
    });

  return {
    isLoading,
    reacjiUpdate,
  };
};
