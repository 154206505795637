import { useQuery } from '@apollo/client';
import { MateWithProductRoleFragment, ProductMembersDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import sortBy from 'lodash/sortBy';
import { useMemo, useCallback } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { extract } from 'src/types/graphql.types';

import { useProduct, useProductBase } from './useProduct';

export const PAGINATION_SIZE = 20;

export const getProductMembersQueryVariables = (productId: string) => ({
  productId,
  cursor: '',
  size: PAGINATION_SIZE,
});

type User = MateWithProductRoleFragment & {
  isPending?: boolean;
};

export const useUsers = () => {
  const product = useProductBase();
  return useUsersByProductId(product?.id);
};

export const useUsersByProductId = (productId?: string, notSignedUpUsers = true) => {
  const {
    data,
    loading,
    fetchMore: fetchMoreUsers,
  } = useQuery(ProductMembersDocument, {
    notifyOnNetworkStatusChange: true,
    skip: !productId,
    variables: getProductMembersQueryVariables(productId as string),
  });

  const node = extract('Product', data?.node);

  const users = useMemo<User[]>(() => ([
    ...nodeToArray(node?.users),
    ...notSignedUpUsers ? sortByEmail(nodeToArray(node?.notSignedUpUsers).map(user => ({
      ...user,
      isPending: true,
    }))) : [],
  ]), [node?.users, node?.notSignedUpUsers, notSignedUpUsers]);

  const fetchMore = useCallback((cursor: string) => fetchMoreUsers({
    variables: {
      cursor,
    },
  }), [fetchMoreUsers]);

  return {
    users,
    pageInfo: node?.users?.pageInfo ?? {
      hasNextPage: false,
      endCursor: '',
    },
    loading,
    fetchMore,
  };
};

export const useAssignees = () => {
  const product = useProductBase();
  return useUsersByProductId(product?.id, false);
};

export const useSearchUsers = ({
  searchText = '', paginationSize = PAGINATION_SIZE,
}: {
  searchText?: string;
  paginationSize?: number;
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { product } = useProduct('cache-only');

  const query = useQuery(ProductMembersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      productId,
      cursor: '',
      size: paginationSize,
      searchText,
    },
  });

  const node = extract('Product', (query.data ?? query.previousData)?.node);

  const users = useMemo(() => {
    return nodeToArray(node?.users ?? product?.users).slice(0, paginationSize);
  }, [paginationSize, product?.users, node]);

  const hasMoreData = node?.users?.pageInfo?.hasNextPage ?? false;
  const cursor = node?.users?.pageInfo?.endCursor ?? '';

  const loadMore = useCallback(async () => {
    if (!hasMoreData) return;
    await query.fetchMore({
      variables: {
        cursor,
      },
    });
  }, [query, hasMoreData, cursor]);

  return {
    users,
    loadMore,
    hasMoreData,
    isLoading: query.loading,
  };
};

const sortByEmail = (userList: User[]) => sortBy(userList, user => user.email.split('@')[0]);
