import { ReleaseNoteBaseFragment, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { PenIcon, TrashIcon, CalendarEditIcon, AddIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { useMemo, useState } from 'react';

import { useUpdateReleaseNote } from 'src/hooks/releases/useUpdateReleaseNote';
import { getPermission, useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openUpdateReleaseNote, openRemoveReleaseNote, openCreateRelease } from 'src/reactives/releases.reactive';

import {
  StyledCalendarIconWrapper,
  StyledMenuOptionBullet,
  StyledMenuOptionFooter,
  StyledMenuOptionLabel,
  StyledMenuOptionLabelContent,
} from './ReleaseNoteActionMenu.styles';
import { StyledDotsMenu } from './ReleaseNoteCard.styles';
import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { usePaginatedReleases } from '../../hooks/releases/useReleases';
import { Layer } from '../../types/layers.types';
import { DropdownLayer } from '../DropdownLayer';
import { SubmenuIcon } from '../SectionViewOptions/SectionViewOptions.styles';

export const ReleaseNoteActionsMenu = ({
  releaseNote,
  disabled,
  releaseId,
}: {
  releaseNote: ReleaseNoteBaseFragment;
  disabled?: boolean;
  releaseId: string;
}) => {
  const {
    canUpdateReleaseNote,
    canDeleteReleaseNote,
  } = useGetPermission();

  const productId = useWorkspaceContext(ctx => ctx.productId);

  const {
    releasesList, isLoadingMore, hasNextPage, loadMore,
  } = usePaginatedReleases(productId);

  const { updateReleaseNote } = useUpdateReleaseNote();

  const [submenuOpen, setSubmenuOpen] = useState(false);

  const dropdownSubmenuOptions = useMemo<SelectOption[]>(() => releasesList
    .filter(release => release.id !== releaseId && release.publicStatus !== ReleasePublicStatus.Published)
    .map(release => {
      let label = release.date ? toShortLocaleDateString(release.date) : 'No release';
      const releaseTitle = release.title?.trim();
      if (release.date && releaseTitle) label += ` · ${releaseTitle}`;
      return {
        label,
        value: release.id,
        renderLabel: () => (
          <StyledMenuOptionLabel>
            {label !== 'No release' && (
              <StyledMenuOptionBullet />
            )}
            <StyledMenuOptionLabelContent>
              {label}
            </StyledMenuOptionLabelContent>
          </StyledMenuOptionLabel>
        ),
      };
    }), [releaseId, releasesList]);

  const dropdownOptions = useMemo(() => [
    {
      value: 'edit',
      label: 'Edit title',
      icon: <PenIcon />,
      onSelect: canUpdateReleaseNote ? openUpdateReleaseNote(releaseNote.id) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
      disabled,
      tooltipContent: disabled ? 'Make updates or unpublish first' : null,
    },
    {
      value: 'changeDate',
      label: '',
      icon: (
        <StyledCalendarIconWrapper>
          <CalendarEditIcon size={12} />
        </StyledCalendarIconWrapper>
      ),
      disabled,
      tooltipContent: disabled ? 'Make updates or unpublish first' : null,
      end: <SubmenuIcon $isActive={submenuOpen} />,
      renderLabel: () => (
        <DropdownLayer
          layer={Layer.DropdownZ2}
          visible={submenuOpen}
          placement="left-start"
          offset={[-10, 40]}
          content={(
            <SelectPanel
              hideSearch
              infiniteScroll={{
                hasMoreData: hasNextPage,
                isLoading: isLoadingMore,
                loadMore,
              }}
              options={dropdownSubmenuOptions}
              onOptionChange={async ({ value }) => {
                await updateReleaseNote(releaseNote, { releaseId: value });
              }}
              footer={(
                <StyledMenuOptionFooter
                  startSlot={<AddIcon size={10} />}
                  label="New release"
                  onClick={() => {
                    if (!getPermission().canCreateRelease) {
                      setLimitationsModal({ action: 'RELEASE_UPDATE' });
                      return;
                    }
                    openCreateRelease((newReleaseId) => updateReleaseNote(releaseNote, { releaseId: newReleaseId }));
                  }}
                />
              )}
            />
          )}
        >
          Edit release date
        </DropdownLayer>
      ),
    },
    {
      value: 'delete',
      label: 'Delete',
      icon: <TrashIcon />,
      onSelect: canDeleteReleaseNote ? openRemoveReleaseNote(releaseNote.id) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
      disabled,
      tooltipContent: disabled ? 'Make updates or unpublish first' : null,
    },
  ], [
    canDeleteReleaseNote,
    canUpdateReleaseNote,
    releaseNote,
    disabled,
    submenuOpen,
    hasNextPage,
    isLoadingMore,
    loadMore,
    dropdownSubmenuOptions,
    updateReleaseNote,
  ]);

  return (
    <StyledDotsMenu
      onMouseEnterItem={(itemId) => setSubmenuOpen(itemId === 'changeDate')}
      onHide={() => setSubmenuOpen(false)}
      $forceVisible={false}
      placement="bottom-end"
      options={dropdownOptions}
    />
  );
};
