import { useQuery } from '@apollo/client';
import {
  DoctypeFragment,
  DoctypeNodeDocument,
  DoctypeNodeQueryVariables,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { useDoctypeId } from 'src/hooks/usePathParams';
import { useSafeAsyncLazyQuery } from 'src/hooks/useSafeAsyncLazyQuery';
import { extract } from 'src/types/graphql.types';
import { findInsight } from 'src/utils/docType.util';

export const useDoctype = (doctypeId?: string | undefined, skip?: boolean): DoctypeFragment | undefined => {
  const doctypeIdRoute = useDoctypeId();

  const { data } = useQuery(DoctypeNodeDocument, {
    variables: {
      id: doctypeId || doctypeIdRoute,
    },
    skip: skip || (!doctypeId && !doctypeIdRoute),
  });

  return extract('Doctype', data?.node);
};

export const useFetchDocType = () => {
  const [fetchDocType, { loading }] = useSafeAsyncLazyQuery<DoctypeFragment, DoctypeNodeQueryVariables>(DoctypeNodeDocument);

  const fetch = useCallback((id: string) => fetchDocType({
    variables: { id },
  }), [fetchDocType]);

  return {
    fetch,
    isLoading: loading,
  };
};

export const useInsightDocType = () => {
  const docTypes = useProductDoctypes();
  const docTypeId = findInsight(docTypes)?.id;
  return useDoctype(docTypeId, !docTypeId);
};
