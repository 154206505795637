import { useProductAddOn } from 'src/hooks';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';

import { RecordingTabEmpty } from './RecordingTabEmpty';
import { RecordingTabSettings } from './RecordingTabSettings';

export const RecordingTab = () => {
  const { isInstalled } = useMeetingsIntegration();
  const meetings = useProductAddOn('MEETINGS');
  return meetings.isEnabled && isInstalled ? <RecordingTabSettings /> : <RecordingTabEmpty />;
};
