import { LinearProjectIcon } from '@cycle-app/ui';
import { LinearIcon, AddIcon } from '@cycle-app/ui/icons';

import { DocLinearProvider } from 'src/contexts/docLinearContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { getAutomationType } from 'src/utils/embeddable.util';
import { linearLabel } from 'src/utils/linear.utils';

import { Container, Label, LinearButton } from './CustomPropertyFormField.styles';
import { LinearSelect } from '../DocLinear/LinearSelect';

export const LinearField = ({
  id, url, isDisabled = false, onChange, docId,
}: {
  docId: string;
  id: string | null;
  url: string | null;
  isDisabled?: boolean;
  onChange: (params: {
    id: string | null;
    url: string | null;
  }) => void;
}) => {
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  const linearIntegrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  if (!isLinearInstalled || !linearIntegrationId) return null;
  const type = getAutomationType(url);

  return (
    <Container
      $isDisabled={isDisabled}
    >
      <Label>Linear</Label>
      <DocLinearProvider
        automationId={id}
        automationUrl={url}
        integrationId={linearIntegrationId}
      >
        <LinearSelect
          docId={docId}
          type={type}
          onSelect={data => {
            onChange(data);
          }}
          onCreate={data => {
            onChange({
              id: data.id,
              url: data.url ?? null,
            });
          }}
          onUnlink={() => {
            onChange({
              id: null,
              url: null,
            });
          }}
          button={buttonProps => (
            <LinearButton
              type="button"
              variant={id ? 'outlined-alt' : 'light'}
              size="M"
              disabled={isDisabled}
              $isEmpty={!id}
              {...buttonProps}
            >
              <Icon id={id} url={url} />
              <span className="truncate">
                {linearLabel(type, url)}
              </span>
            </LinearButton>
          )}
        />
      </DocLinearProvider>
    </Container>
  );
};

const Icon = ({
  id, url,
}: {
  id: string | null;
  url: string | null;
}) => {
  const icon = useWorkspaceContext(ctx => id ? ctx.linearProjects[id]?.icon : undefined);
  const color = useWorkspaceContext(ctx => id ? ctx.linearProjects[id]?.color : undefined);
  const type = getAutomationType(url);
  if (type === 'project' && id) {
    return (
      <LinearProjectIcon
        icon={icon}
        color={color}
        size={14}
      />
    );
  }
  if (id) {
    return <LinearIcon size={14} />;
  }

  return <AddIcon size={10} />;
};
