import { MotionProps } from 'framer-motion';

export const motionProps: MotionProps = {
  initial: {
    opacity: 0,
    translateY: 5,
  },
  animate: {
    opacity: 1,
    translateY: 0,
  },
  exit: {
    opacity: 0,
    translateY: 5,
  },
  transition: {
    duration: 0.1,
    delay: 0.2,
    ease: 'easeOut',
  },
};
