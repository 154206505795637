import { ChangeDocParentDocument, OperatorIsEmptyOrNot, OperatorIsInOrNot } from '@cycle-app/graphql-codegen';

import { Events } from 'src/constants/analytics.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useGetDocGroup, useUpdateDocsGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import { useUpdateChildCache } from 'src/hooks/api/cache/cacheHierarchy';
import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getDocFromCache } from 'src/utils/cache.utils';

interface Params {
  docId: string;
  parentId: string | undefined;
}

export const useChangeDocParent = () => {
  const [changeParentMutation] = useSafeMutation(ChangeDocParentDocument, {
    onCompleted: ({ changeDocParent }) => changeDocParent?.id && trackAnalytics(Events.HierarchyAdded),
  });
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const { groups } = useBoardGroups();

  const getDocGroup = useGetDocGroup();
  const updateChild = useUpdateChildCache();
  const updateGroup = useUpdateDocsGroup();

  async function changeParent({
    docId,
    parentId,
  }: Params) {
    const docParentId = getDocFromCache(docId)?.parent?.id;
    return changeParentMutation({
      variables: {
        docId,
        parentId,
      },
      update: async (_, { data }) => {
        if (data?.changeDocParent?.id) {
          await updateChild({
            newParentId: parentId,
            docs: [{
              docId,
              parentId: docParentId,
            }],
          });
        }

        if (!groups || !boardConfig) return;

        const parentFilter = boardConfig?.filterProperties.edges.find(({ node }) => node.__typename === 'FilterPropertyRuleDocParent');
        const parentRule = parentFilter?.node.__typename === 'FilterPropertyRuleDocParent'
          ? parentFilter.node.docParentRule
          : undefined;
        if (!parentRule) return;
        // There is a parent rule, doc in board might be affacted

        if (!shouldRemoveDocFromBoard(parentId)) return;

        const docGroup = getDocGroup(docId);
        if (!docGroup) return;

        updateGroup({
          groupData: docGroup,
          updatedDocs: docGroup.node.docs.edges
            .filter(({ node }) => node.id !== docId)
            .map(edge => edge.node),
          boardConfigId: boardConfig.id,
        });
      },
    });
  }

  function shouldRemoveDocFromBoard(parentId: string | null | undefined): boolean {
    const parentFilter = boardConfig?.filterProperties.edges.find(({ node }) => node.__typename === 'FilterPropertyRuleDocParent');
    const parentRule = parentFilter?.node.__typename === 'FilterPropertyRuleDocParent'
      ? parentFilter.node.docParentRule
      : undefined;
    if (!parentRule) return false;

    if (!parentId) {
      // A doc without parent only stay is the filter rule is "parent is empty"
      return !(parentRule.__typename === 'RuleIsEmptyOrNot' && parentRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty);
    }

    const selectedParents = parentRule.__typename === 'RuleDocParentMultipleValues'
      ? parentRule.values.edges
        .filter(({ node }) => node.selected)
        .map(e => e.node)
      : [];
    const shouldRemoveDocWithParent =
      // Board filter docs without parent
      (parentRule.__typename === 'RuleIsEmptyOrNot' && parentRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) ||
      // Doc parent is not in the parents whitelist
      (parentRule.__typename === 'RuleDocParentMultipleValues' &&
        parentRule.operator === OperatorIsInOrNot.Is &&
        !selectedParents.find(({ value }) => value.id === parentId)) ||
      // Doc parent is in the parents blacklist
      (parentRule.__typename === 'RuleDocParentMultipleValues' &&
        parentRule.operator === OperatorIsInOrNot.IsNot &&
        !!selectedParents.find(({ value }) => value.id === parentId)
      );
    return shouldRemoveDocWithParent;
  }

  return changeParent;
};
