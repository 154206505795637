import { make } from 'src/utils/reactives.util';

export interface HighlightState {
  /**
   * Will refer to highlight inside the editor and the doc link content
   */
  blockId: string | null;
  docId: string | null;
  blockIdCreating: string | null;
  blockIdsToDelete: string[] | null;
  /**
   * Will define if the selection by hover is happening inside a doc content
   * so on an mark element or inside an insight card on the doc right panel in
   * the list.
   */
  context: 'doc-link-list' | 'doc-content' | 'auto-focus' | null;
}

export const {
  hookValue: useGetHighlight,
  getValue: geHighlight,
  setValue: setHighlight,
  resetValue: resetHighlight,
} = make<HighlightState>({
  defaultState: {
    blockId: null,
    docId: null,
    blockIdCreating: null,
    blockIdsToDelete: null,
    context: null,
  },
});
