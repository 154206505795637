import { MotionProps } from 'framer-motion';

export const errorMotionProps: MotionProps = {
  initial: 'initial',
  exit: 'exit',
  animate: 'enter',
  variants: {
    initial: {
      y: 16,
      opacity: 0,
      transition: {
        duration: 0.15,
      },
    },
    exit: {
      y: 0,
      opacity: 0,
      transition: {
        duration: 0.15,
      },
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.15,
      },
    },
  },
};
