import styled from 'styled-components';

import EditorSkeleton from 'src/components/EditorSkeleton/EditorSkeleton';
import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP } from 'src/constants/editor.constants';

export const StyledEditorSkeleton = styled(EditorSkeleton)`
  margin: 0 auto;
  width: 100%;
  max-width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  padding: 16px ${EDITOR_MARGIN}px;
`;
