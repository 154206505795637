import { DocTargetFragment } from '@cycle-app/graphql-codegen';
import { ReactNode, useState } from 'react';
import { Placement } from 'tippy.js';

import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';

import { EditQuoteDropdown } from './EditQuoteDropdown';
import { FeatureDropdown } from './FeatureDropdown';
import { QuotePopover, QuotePopoverProps, RemoveQuote, EditQuote, ChangeFeature } from './QuotePopover';

type Props = Pick<QuotePopoverProps, 'onMount' | 'onHide' | 'placement' | 'disabled' | 'offset'> & {
  insight: DocTargetFragment;
  children: (active: boolean) => ReactNode;
  quoteContent?: string | null;
  editQuotePlacement?: Placement;
  featurePlacement?: Placement;
  featureContent?: ReactNode;
};

export const VerifiedQuotePopover = ({
  insight, children, onMount, onHide, placement, disabled, quoteContent, editQuotePlacement, featurePlacement, offset, featureContent,
}: Props) => {
  const [removeQuoteId, setRemoveQuoteId] = useState<string | null>(null);
  const [changeFeatureId, setChangeFeatureId] = useState<string | null>(null);
  const [updateQuoteId, setUpdateQuoteId] = useState<string | null>(null);
  return (
    <>
      <QuotePopover
        onMount={onMount}
        onHide={onHide}
        placement={placement}
        quoteContent={quoteContent}
        featureContent={featureContent}
        offset={offset}
        disabled={!insight.doc?.id || disabled}
        buttons={(
          <>
            <EditQuote
              onClick={() => {
                if (!insight.doc?.id) return;
                setUpdateQuoteId(insight.doc.id);
              }}
            />

            <ChangeFeature
              insightId={insight.id}
              onClick={() => {
                if (!insight.doc?.id) return;
                setChangeFeatureId(insight.doc.id);
              }}
            />

            <RemoveQuote
              onClick={() => {
                if (!insight.doc?.id) return;
                setRemoveQuoteId(insight.doc.id);
              }}
            />
          </>
        )}
      >
        <FeatureDropdown
          insightId={insight.id}
          placement={featurePlacement}
          visible={changeFeatureId === insight.doc?.id}
          hide={() => setChangeFeatureId(null)}
        >
          <EditQuoteDropdown
            insight={insight}
            visible={updateQuoteId === insight.doc?.id}
            hide={() => setUpdateQuoteId(null)}
            placement={editQuotePlacement}
          >
            {children(
              updateQuoteId === insight.doc?.id ||
              changeFeatureId === insight.doc?.id,
            )}
          </EditQuoteDropdown>
        </FeatureDropdown>
      </QuotePopover>

      {removeQuoteId && (
        <DocDeleteWarningModal
          docId={removeQuoteId}
          onHide={() => setRemoveQuoteId(null)}
        />
      )}
    </>
  );
};
