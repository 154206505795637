import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GoogleIcon: FC<SvgProps> = (props) => (
  <Svg width="14" height="14" viewBox="0 0 14 14" fill="none" data-no-fill xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2636_13088)">
      <path d="M13.8332 7.13126C13.8332 6.55769 13.7866 6.13913 13.6859 5.70508H7.13626V8.2939H10.9808C10.9033 8.93726 10.4847 9.90615 9.55457 10.5572L9.54154 10.6439L11.6124 12.2482L11.7559 12.2625C13.0735 11.0455 13.8332 9.25504 13.8332 7.13126Z" fill="#4285F4" />
      <path d="M7.13624 13.9522C9.01972 13.9522 10.6009 13.3321 11.7559 12.2625L9.55456 10.5572C8.96549 10.968 8.17486 11.2548 7.13624 11.2548C5.2915 11.2548 3.7258 10.0379 3.16767 8.35596L3.08586 8.3629L0.932533 10.0294L0.904373 10.1077C2.05152 12.3865 4.40785 13.9522 7.13624 13.9522Z" fill="#34A853" />
      <path d="M3.16769 8.35612C3.02042 7.92207 2.93519 7.45697 2.93519 6.97642C2.93519 6.49583 3.02042 6.03078 3.15994 5.59673L3.15604 5.50429L0.975729 3.81104L0.904393 3.84497C0.431599 4.79061 0.160309 5.85252 0.160309 6.97642C0.160309 8.10033 0.431599 9.16219 0.904393 10.1078L3.16769 8.35612Z" fill="#FBBC05" />
      <path d="M7.13624 2.69735C8.44615 2.69735 9.32975 3.26318 9.83359 3.73602L11.8024 1.81375C10.5932 0.689847 9.01972 0 7.13624 0C4.40785 0 2.05152 1.5657 0.904373 3.8445L3.15992 5.59626C3.7258 3.91428 5.2915 2.69735 7.13624 2.69735Z" fill="#EB4335" />
    </g>
    <defs>
      <clipPath id="clip0_2636_13088">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
