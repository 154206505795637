import { useGetLearnMorePopup, setLearnMorePopup } from 'src/reactives';

export const useRoadmapTour = () => {
  return {
    open: () => setLearnMorePopup('roadmaps', true),
    close: () => setLearnMorePopup('roadmaps', false),
  };
};

export const useIsRoadmapTourActive = () => {
  const isOpen = useGetLearnMorePopup('roadmaps');
  return {
    isActive: isOpen,
  };
};
