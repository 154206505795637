import {
  GenerateLuzmoCredentialsDocument,
  RefreshCustomerVoiceDocument,
  RefreshCustomerVoiceMutationVariables,
} from '@cycle-app/graphql-codegen';
import { CycleLoader, Spinner, Tooltip } from '@cycle-app/ui';
import { toShortLocaleDateTimeString } from '@cycle-app/utilities';
import { LuzmoDashboardComponent, type ChangedFiltersEvent } from '@luzmo/react-embed';
import { formatDistance, parseISO, isAfter, isBefore, subMinutes } from 'date-fns';
import { useRef, useEffect, useState } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { useNavigateToDocFullOrPanel, useProduct, useSafeMutation } from 'src/hooks';
import { useProductSlug } from 'src/hooks/usePathParams';
import {
  useCustomerDashboardState,
  resetCustomerDashboardRefresh,
  setCustomerDashboard,
  useCustomerDashboardStateValue,
  getCustomerDashboards,
} from 'src/reactives';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { AnalyseEmptyState } from './AnalyseEmptyState';
import {
  Container,
  LastUpdate,
  DashboardContainer,
  DashboardLoadingState,
} from './AnalysesPage.styles';
import { AnalysesSummaryModal } from './AnalyseSummaryModal/AnalysesSummaryModal';
import { openCompanyModal } from '../../hooks/modals/useCompanyModal';
import { openCustomerModal } from '../../hooks/modals/useCustomerModal';
import { logError } from '../../utils/errors.utils';
import { getPathParams } from '../../utils/routing.utils';

type Props = {
  dashboardId: string | null;
  boardId: string;
};

export const AnalysesPage = ({
  dashboardId, boardId,
}: Props) => {
  const productSlug = useProductSlug();
  const {
    product, refetch,
  } = useProduct();
  const timeRef = useRef<number | null>(null);

  useEffect(() => {
    trackAnalytics(Events.CustomerVoiceDashboardViewed, {
      isActive: dashboardId,
    });
    timeRef.current = Date.now();
    return () => {
      const timeDifference = Date.now() - (timeRef.current || 0);
      const timeDifferenceFormatted = formatDistance(0, timeDifference, { includeSeconds: true });
      trackAnalytics(Events.CustomerVoiceDashboardLeft, {
        isActive: !!dashboardId,
        duration: timeDifference,
        timeEstimation: timeDifferenceFormatted,
      });
      timeRef.current = null;
    };
    // Intended on mount and on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId]);

  if (!productSlug || !product?.id) return null;

  if (dashboardId) {
    const date = toShortLocaleDateTimeString(product?.lastCustomerVoiceRefresh || undefined, { month: 'long' });
    return (
      <Container>
        <Dashboard
          date={date}
          dashboardId={dashboardId}
          productId={product.id}
          refetchProduct={refetch}
          boardId={boardId}
        />
      </Container>
    );
  }
  return <AnalyseEmptyState />;
};

type LuzmoDashboardEvent = Event & DeepPartial<{
  detail: {
    data: {
      data: {
        extraData: {
          docId: { value: { id: string } };
          docTitle: { value: { id: string } };
          customerId: { value: { id: string } };
          companyId: { value: { id: string } };
          feedbackUrl: { value: string };
          feedbackId: { value: string };
        };
      };
    };
  };
}>;

const Dashboard = ({
  date,
  productId,
  dashboardId,
  refetchProduct,
  boardId,
}: {
  productId: string;
  dashboardId: string;
  boardId: string;
  date: string | null;
  refetchProduct: VoidFunction;
}) => {
  const productSlug = useProductSlug();
  const [generateCredentials] = useSafeMutation(GenerateLuzmoCredentialsDocument);
  const navigate = useNavigateToDocFullOrPanel();
  const [dashboardState] = useCustomerDashboardState(dashboardId);
  const {
    progress, token, key,
  } = dashboardState || {};

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (progress === 100) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refetchProduct();
      } catch (error) {
        handleLuzmoError(error);
      }
      resetCustomerDashboardRefresh(dashboardId);
    }
  }, [progress, dashboardId]);

  const { colorTheme } = useGetThemeConfig();

  useEffect(() => {
    async function generate() {
      const res = await generateCredentials({
        variables: {
          boardId,
          theme: colorTheme,
        },
      });
      setCustomerDashboard(dashboardId, {
        key: res.data?.generateLuzmoCredentials?.key ?? null,
        token: res.data?.generateLuzmoCredentials?.token ?? null,
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    generate();
    return () => setCustomerDashboard(dashboardId, {
      key: null,
      token: null,
    });
  }, [generateCredentials, productId, dashboardId, colorTheme, boardId]);

  const showDashboard = isVisible && key && token;

  return (
    <>
      <div className="flex items-center justify-between px-8">
        <RefreshDashboard date={date} productId={productId} dashboardId={dashboardId} />
        <AnalysesSummaryModal dashboardId={dashboardId} productId={productId} />
      </div>
      <DashboardContainer>
        {key && token && (
          <LuzmoDashboardComponent
            authKey={key}
            authToken={token}
            appServer="https://app.luzmo.com/"
            dashboardId={dashboardId}
            loaderBackground="transparent"
            loaderFontColor="transparent"
            loaderSpinnerColor="transparent"
            loaderSpinnerBackground="transparent"
            switchScreenModeOnResize
            load={() => setIsVisible(true)}
            itemsRendered={() => {
              setCustomerDashboard(dashboardId, {
                aiSuggestion: '',
                isAiGenerating: false,
                dashboardManualFilters: [],
              });
            }}
            changedFilters={(e) => {
              const event = e as CustomEvent<ChangedFiltersEvent>;
              const manualFilters = event.detail.data.filters.filter(f => f.origin === 'filterFromFilterItem' || f.origin === 'filterFromVizItem');
              if (manualFilters.length) {
                setCustomerDashboard(dashboardId, {
                  dashboardManualFilters: manualFilters,
                  aiSuggestion: '',
                  isAiGenerating: false,
                  mutationId: null,
                  dashboardId: event.detail.data.dashboardId ?? null,
                });
                return;
              }
              setCustomerDashboard(dashboardId, {
                aiSuggestion: '',
                isAiGenerating: false,
                dashboardManualFilters: [],
                mutationId: null,
              });
            }}
            customEvent={(e) => {
              const eventData = (e as LuzmoDashboardEvent)?.detail?.data?.data?.extraData;
              const docId = eventData?.docId?.value?.id;
              const docTitle = eventData?.docTitle?.value?.id;
              const customerId = eventData?.customerId?.value?.id;
              const companyId = eventData?.companyId?.value?.id;
              const feedbackUrl = eventData?.feedbackUrl?.value;
              const feedbackId = eventData?.feedbackId?.value;

              // clicked on doc
              if (docId && docTitle) {
                const parsedId = btoa(`Doc_${docId}`);
                navigate({
                  id: parsedId,
                  title: docTitle,
                });
                return;
              }

              // clicked on customer
              if (customerId) {
                openCustomerModal(btoa(`Customer_${customerId}`));
                return;
              }

              // clicked on company
              if (companyId) {
                openCompanyModal(btoa(`Company_${companyId}`));
                return;
              }

              // clicked on feedback
              if (feedbackId) {
                navigate({
                  id:  btoa(`Doc_${feedbackId}`),
                  title: 'feedback',
                });
                return;
              }

              if (feedbackUrl) {
                const url = new URL(feedbackUrl);
                const { docId: parsedId } = getPathParams(url.pathname);

                if (parsedId) {
                  navigate({
                    id: parsedId,
                    title: 'feedback',
                  });
                  return;
                }
              }

              // clicked on something that isn't implemented
              logError('Luzmo dashboard event not implemented', {
                productSlug,
                dashboardId,
                eventData,
              });
            }}
          />
        )}
      </DashboardContainer>
      {!showDashboard && (
        <DashboardLoadingState>
          <CycleLoader $noBackground />
        </DashboardLoadingState>
      )}
    </>
  );
};

const RefreshDashboard = ({
  date, productId, dashboardId,
}: {
  productId: string;
  dashboardId: string;
  date: string | null;
}) => {
  const [, setN] = useState(0);
  const dashboardState = useCustomerDashboardStateValue(dashboardId);
  const {
    requestId, lastRefreshRequested,
  } = dashboardState || {};
  const [refresh] = useSafeMutation<{ refreshCustomerVoice: string }, RefreshCustomerVoiceMutationVariables>(RefreshCustomerVoiceDocument);

  useEffect(() => {
    if (requestId && lastRefreshRequested && date && isAfter(parseISO(date), lastRefreshRequested)) {
      resetCustomerDashboardRefresh(dashboardId);
    }
    // Force-re-render every minutes for a more accurate tooltip every minutes
    const intervalId = setInterval(() => setN(prevN => prevN + 1), 60_000);
    return () => clearInterval(intervalId);
    /**
     * Intended deps
     * We want to clear requested refresh data in case it's in the past compare
     * to the last refresh date in product
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!date) return null;

  const isRefreshing = !!requestId;
  /**
   * We allow refresh only every 5min, button should be disable when the current
   * time is not 5 min ahead the last refresh
   */
  const lastRefreshDate = lastRefreshRequested ? new Date(lastRefreshRequested) : null;
  const fiveMinAgoDate = new Date(subMinutes(new Date(), 5));
  const canRequestRefresh = lastRefreshDate ? isBefore(lastRefreshDate, fiveMinAgoDate) : true;
  const refreshContent = isRefreshing ? (
    <>
      <Spinner size={14} />
      Refreshing the dashboard…
    </>
  ) : 'Refresh';
  const refreshWithTooltip = canRequestRefresh
    ? refreshContent
    : (
      // Not really 5 min but the goal is to reduce the frequency of requests
      <Tooltip content="Wait 5 min to refresh your data again" placement="top">
        {refreshContent}
      </Tooltip>
    );

  return (
    <LastUpdate $isDisable={isRefreshing || !canRequestRefresh}>
      {`Last refreshed on ${date} - `}
      <div
        data-refresh-button
        tabIndex={0}
        role="button"
        onClick={async () => {
          if (!canRequestRefresh) return;
          const response = await refresh({ variables: { productId } });
          if (!response.data?.refreshCustomerVoice) return;
          const { refreshCustomerVoice } = response.data;
          const dashboards = getCustomerDashboards();
          Object.entries(dashboards).forEach(state => {
            setCustomerDashboard(state[0], {
              requestId: refreshCustomerVoice,
              lastRefreshRequested: Date.now(),
            });
          });
        }}
      >
        {refreshWithTooltip}
      </div>
    </LastUpdate>
  );
};

const handleLuzmoError = (error: unknown) => {
  // eslint-disable-next-line no-console
  console.warn('error in Luzmo dashboard', error);
};
