import { useSubscription } from '@apollo/client';
import { FindInsightState, FindInsightStateDocument } from '@cycle-app/graphql-codegen';

export const useFindInsightStateSubscription = (
  docId?: string | null,
  onData?: (data: FindInsightState | null | undefined) => void,
) => useSubscription(
  FindInsightStateDocument,
  {
    skip: !docId,
    variables: {
      docId: docId as string,
    },
    async onSubscriptionData({ subscriptionData: { data } }) {
      if (!data) return;
      onData?.(data.findInsightState);
    },
  },
);
