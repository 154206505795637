import { normalizeString, isSearchMatching } from '@cycle-app/utilities';
import { mergeAttributes, Node } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import { Editor, ReactRenderer } from '@tiptap/react';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';
import { FC } from 'react';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import SlashDropdown from 'src/components/Editor/SlashDropdown/SlashDropdown';
import { COMMANDS } from 'src/constants/editor.constants';
import { slashActions } from 'src/services/editor/editorActions';
import { clientRectWithFallback } from 'src/utils/editor/editor.utils';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

import { Layer } from '../../types/layers.types';

export type MentionOptions = {
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

const extensionName = 'slash';

type SlashParams = {
  isMobile?: boolean;
};

export const getSlashExtension = (options?: SlashParams) => Node.create<MentionOptions>({
  name: extensionName,

  addOptions: () => ({
    suggestion: {
      char: COMMANDS.SLASH,

      startOfLine: false,

      command: ({
        editor,
        range,
      }) => {
        editor
          .chain()
          .focus()
          .deleteRange({
            from: range.from,
            to: range.to,
          })
          .run();
      },

      items: ({ query }) => filterItemsFromQuery(query),

      allow: ({
        editor,
        range,
      }) => editor.can().insertContentAt(range, { type: extensionName }),

      render: () => {
        let reactRenderer: ReactRenderer | undefined;
        let popup: TippyInstance<TippyProps> | undefined;

        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(SlashDropdown as FC<React.PropsWithChildren<unknown>>, {
              props: {
                ...props,
                hide: () => {
                  popup?.hide();
                },
              },
              editor: props.editor as Editor,
            });
            popup = getTippyPopup({
              props,
              reactRenderer,
              layer: Layer.DropdownModalZ4,
              ...(options?.isMobile && {
                options: {
                  placement: 'right-start',
                  maxWidth: 250,
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom', 'top'],
                        },
                      },
                    ],
                  },
                },
              }),
            })?.[0];
          },
          onUpdate(props) {
            reactRenderer?.updateProps(props);

            popup?.setProps({
              getReferenceClientRect: clientRectWithFallback(props.clientRect),
            });
          },
          onKeyDown({ event }) {
            if (!popup) return true;
            return onKeyDown({
              event,
              popupInstance: popup,
            });
          },
          onExit() {
            popup?.destroy();
            reactRenderer?.destroy();
          },
        };
      },
    },
  }),

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: { default: null },
    };
  },

  parseHTML() {
    return [
      { tag: 'react-component' },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'react-component',
      mergeAttributes(HTMLAttributes),
    ];
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${extensionName}`),
      }),
    ];
  },
});

function filterItemsFromQuery(query: string) {
  const searchQuery = normalizeString(query);

  return slashActions
    .map(({ actions }) => actions)
    .flat()
    .filter(({ label }) => {
      const headingShortcutRegExp = /h(1|2|3)/i;
      if (headingShortcutRegExp.test(searchQuery)) {
        const substitutionSearchQuery = `heading${searchQuery.replace('h', '')}`;

        return isSearchMatching(substitutionSearchQuery, label || '');
      }

      return isSearchMatching(searchQuery, label || '');
    })
    .map(({ id }) => id);
}
