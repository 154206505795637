import { make } from 'src/utils/reactives.util';

interface DocPreviewState {
  docIdPreview: string | null;
  enabled: boolean;
}

export const {
  getValue: getDocIdPreview,
  setValue: setDocIdPreview,
  hookValue: useGetDocIdPreview,
} = make<DocPreviewState>({
  defaultState: {
    enabled: true,
    docIdPreview: null,
  },
});
