import { FetchPolicy, useQuery } from '@apollo/client';
import { addFakeAttributesDefinitions } from '@cycle-app/front/tests/fakes/customerAttributes';
import {
  Maybe,
  ProductBaseFragment,
  ProductBySlugDocument,
  ProductBaseBySlugDocument,
  BillingPlan,
  ProductStatusBySlugDocument,
} from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useProductSlug } from 'src/hooks/usePathParams';
import { useGetInitial } from 'src/reactives/initial.reactive';

import { useProductStatus } from '../../reactives/product.reactive';
import { getRemainingDays } from '../../utils/date.utils';

export const useProduct = (fetchPolicy: FetchPolicy = 'cache-first') => {
  const productSlug = useProductSlug();

  const {
    data, loading, error, refetch,
  } = useQuery(ProductBySlugDocument, {
    skip: !productSlug,
    fetchPolicy,
    variables: {
      slug: productSlug as string,
    },
  });

  useQuery(ProductStatusBySlugDocument, {
    skip: !productSlug || !data,
    variables: {
      slug: productSlug as string,
    },
  });

  return useMemo(() => {
    const result = ({
      product: data?.product ?? null,
      error,
      loading,
      refetch: () => refetch({ slug: productSlug as string }),
    });

    if (addFakeAttributesDefinitions && result.product) {
      result.product = addFakeAttributesDefinitions(result.product);
    }

    return result;
  }, [data?.product, error, loading, productSlug, refetch]);
};

export const useProductBase = (productSlug?: string | null): Maybe<ProductBaseFragment> => {
  const paramsProductSlug = useProductSlug();
  const slug = productSlug || paramsProductSlug;

  // Check if the product is present in the initial product list
  const { products } = useGetInitial();
  const productBase = useMemo(() => products.find(p => p.slug === slug), [products, slug]);

  // If not, fetch it using his slug
  const { data } = useQuery(ProductBaseBySlugDocument, {
    skip: !slug,
    fetchPolicy: 'cache-first',
    variables: {
      slug: slug as string,
    },
  });

  return productBase ?? data?.product ?? null;
};

export const useCurrentBilling = () => {
  const { product } = useProduct();
  const { productStatus } = useProductStatus();
  const remainingDays = useMemo(() => {
    if (!product?.invoicedAt || !product?.frequency) return 0;
    return getRemainingDays(product.invoicedAt, product.frequency);
  }, [product?.frequency, product?.invoicedAt]);

  if (!product) return null;
  const {
    id, invoicedAt, frequency, nbMakers, nbMonthlyDocs, plan, nbActiveInsights, endOfFreeTrial, nbAiQueries, hasPaymentFailed,
  } = product;
  const isPro = productStatus === 'pro';
  const isBusinessPlus = plan === BillingPlan.BusinessPlus;

  return {
    id,
    invoicedAt,
    frequency,
    nbMonthlyDocs,
    nbMakers,
    plan,
    nbActiveInsights,
    endOfFreeTrial,
    nbAiQueries,
    isPro,
    isBusinessPlus,
    remainingDays,
    hasPaymentFailed,
  };
};

export const useIsStandardPlan = () => {
  const billing = useCurrentBilling();
  return billing?.plan === BillingPlan.Standard;
};

export const useIsFreePlan = () => {
  const billing = useCurrentBilling();
  return billing?.plan === BillingPlan.Free;
};
