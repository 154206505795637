import { useSubscription } from '@apollo/client';
import {
  BoardSubscriptionSubscription,
  BoardSubscriptionDocument,
  BoardSubscriptionSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

export const useBoardSubscription = (boardId?: string | null) => useSubscription<
BoardSubscriptionSubscription,
BoardSubscriptionSubscriptionVariables
>(
  BoardSubscriptionDocument,
  {
    variables: {
      boardId: boardId as string,
    },
    skip: !boardId,
  },
);
