import styled, { css } from 'styled-components';

import { CaretIcon } from '../../../icons';
import { body500, body400 } from '../../../theme/typo';
import { ring2 } from '../../../utils/styles.util';
import { ShyScrollbarCss } from '../../ShyScrollbar/ShyScrollbar.styles';
import { Skeleton } from '../../Skeleton/Skeleton';
import { Spinner } from '../../Spinner/Spinner.styles';

type ContainerProps = {
  $canHover?: boolean;
  $isSelected: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${p => p.theme.colors.components.EditorIntegrationCard.border};

  ${(p) => p.$isSelected && css`
    ${ring2}
  `}

  &:hover {
    background: ${p => (p.$canHover ? p.theme.colors.background.hoverSoft : 'none')};
    cursor: ${p => (p.$canHover ? 'pointer' : 'inherit')};
  }
`;

export const PrimaryContainer = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 8px 12px;
  height: 38px;
`;

type SecondaryContainerProps = {
  isVisible: boolean;
};
export const SecondaryContainer = styled(PrimaryContainer)<SecondaryContainerProps>`
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  padding: ${p => (p.isVisible ? '0 12px 8px 12px' : '0')};
  ${p => (!p.isVisible && 'display: none;')}
  ${ShyScrollbarCss}
`;

export const Title = styled.span`
  ${body500}
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.editor.text};
`;

export const LoadingState = styled(Spinner)`
  margin: 10px auto;
`;

export const SkeletonContainer = styled(Skeleton)`
  margin: 8px 12px;
  height: 20px;
  width: calc(100% - 24px);
  box-sizing: border-box;
`;

export const ButtonIcon = styled.button`
  display: flex;
  cursor: pointer;
  padding: 6px 8px;
  margin: -6px -8px;
`;

export const Footer = styled.footer`
  width: 100%;
`;

export const FooterLabel = styled.label`
  ${body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const FooterValue = styled.button<{ isReadOnly: boolean }>`
  ${body400}
  border-radius: 4px;
  padding: 0px 4px;
  color: ${p => p.theme.colors.text.primary};
  background:  ${p => (p.isReadOnly ? 'transparent' : p.theme.colors.background.tertiary)};
  cursor:  ${p => (p.isReadOnly ? 'default' : 'pointer')};

  &:hover {
    background:  ${p => (p.isReadOnly ? 'transparent' : p.theme.colors.background.hover)};
  }
`;

export const StyledCaretIcon = styled(CaretIcon)`
  width: 10px;
  color: ${p => p.theme.colors.text.disabled};
`;
