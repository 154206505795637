import { useEffect } from 'react';

import { CreateDocModalWithBoardConfig } from 'src/app/Main/Board/CreateDocModal';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ReleasesPageTitle } from 'src/components/PageTitle/PageTitle';
import { ReleaseEditModal, ReleaseNoteRemoveModal } from 'src/components/ReleaseModals';
import { ReleaseNotes } from 'src/components/ReleaseNotes';
import { ReleasesProvider } from 'src/contexts/releasesContext';
import { useNavigate, useProductBase } from 'src/hooks';
import { setLastView } from 'src/reactives/lastView.reactive';

import { ReleasesContent } from './ReleasesContent';

export const ReleasesPage = () => {
  const { navigateToRelease } = useNavigate();
  const product = useProductBase();

  useEffect(() => {
    setLastView({ section: 'releases' });
  }, []);

  if (!product) return null;

  return (
    <>
      <ReleasesPageTitle />
      <ErrorBoundary>
        <ReleasesProvider productId={product.id}>
          <ReleasesContent>
            <ReleaseNotes />
          </ReleasesContent>

          <ReleaseEditModal onReleaseCreated={navigateToRelease} />
          <ReleaseNoteRemoveModal />
          <CreateDocModalWithBoardConfig />
        </ReleasesProvider>
      </ErrorBoundary>
    </>
  );
};
