import { mergeAttributes, Node } from '@tiptap/core';

import { NOTION_EXTENSION_NAME, NOTION_EXTENSION_TAGNAME } from '../constants';
import { getAttributeParser } from '../utils/extension.utils';

export interface NotionAttributes {
  id: string;
  title: string;
  url: string;
  iconEmoji: string;
  typename: string;
}

export const getEditorNotionNode = (data: NotionAttributes) => ({
  type: NOTION_EXTENSION_NAME,
  attrs: {
    ...data,
  },
});

export const getNotionMentionExtension = () => Node.create({
  name: NOTION_EXTENSION_NAME,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: this.name,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        ...getAttributeParser('id'),
      },
      typename: {
        default: null,
        ...getAttributeParser('typename'),
      },
      title: {
        default: null,
        ...getAttributeParser('title'),
      },
      url: {
        default: null,
        ...getAttributeParser('url'),
      },
      iconEmoji: {
        default: null,
        ...getAttributeParser('iconEmoji'),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: NOTION_EXTENSION_TAGNAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      NOTION_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },
});
