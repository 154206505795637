import styled from 'styled-components';

export const JobsInput = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  /* break line after "Design" role */
  > :nth-child(3)::after {
    content: "";
    width: 100px;
    display: block;
  }
`;

export const RadioContainer = styled.div`
  width: 100%;
`;
