import { useSubscription } from '@apollo/client';
import {
  DocSubscriptionSubscription,
  DocSubscriptionDocument,
  DocSubscriptionSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

export const useDocSubscription = (docId?: string | null) => useSubscription<
DocSubscriptionSubscription,
DocSubscriptionSubscriptionVariables
>(
  DocSubscriptionDocument,
  {
    variables: {
      docId: docId as string,
    },
  },
);
