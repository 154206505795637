import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AttioIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g>
      <path d="M15.3568 8.96092L14.0605 6.88629C14.0605 6.88629 14.0557 6.87762 14.0528 6.87375L13.9505 6.71076C13.7577 6.40118 13.4239 6.21599 13.0593 6.21501L10.9712 6.20825L10.8256 6.4417L8.33047 10.4347L8.1925 10.6555L9.23803 12.326C9.43096 12.6366 9.76463 12.8218 10.1321 12.8218H13.0584C13.4191 12.8218 13.7606 12.6318 13.9515 12.327L14.0547 12.1621C14.0547 12.1621 14.0586 12.1573 14.0595 12.1553L15.3577 10.0778C15.5709 9.73832 15.5709 9.29945 15.3577 8.96092H15.3568ZM14.9613 9.82993L13.6631 11.9075C13.6573 11.9171 13.6506 11.9248 13.6448 11.9325C13.5995 11.9836 13.5406 11.9904 13.5146 11.9904C13.4847 11.9904 13.4114 11.9817 13.3641 11.9065L12.0659 9.829C12.0514 9.80583 12.0389 9.78172 12.0273 9.75566C12.0158 9.73057 12.0071 9.70554 11.9994 9.67948C11.9705 9.57532 11.9705 9.46343 11.9994 9.35927C12.0139 9.30817 12.036 9.25703 12.065 9.21073L13.3612 7.13517C13.3612 7.13517 13.3632 7.13228 13.3641 7.13032C13.395 7.08402 13.4336 7.06281 13.4673 7.05604C13.4809 7.05223 13.4924 7.05125 13.5021 7.04934C13.5069 7.04934 13.5117 7.04934 13.5165 7.04934C13.5464 7.04934 13.6207 7.05894 13.667 7.13419L14.9632 9.20882C15.0819 9.39783 15.0819 9.64092 14.9632 9.82993H14.9613Z" fill="currentcolor" />
      <path d="M11.5209 3.94109C11.7331 3.6006 11.7331 3.16271 11.5209 2.82418L10.2246 0.749563L10.1165 0.574987C9.92268 0.265387 9.58901 0.0802002 9.22245 0.0802002H6.29621C5.93068 0.0802002 5.59696 0.265387 5.40212 0.575952L0.162035 8.9622C0.0569053 9.13004 0 9.3239 0 9.52065C0 9.7174 0.0559407 9.91125 0.16107 10.0781L1.56634 12.3283C1.7602 12.6388 2.09391 12.823 2.45945 12.823H5.3857C5.75319 12.823 6.08692 12.6379 6.27979 12.3273L6.38689 12.1576C6.38689 12.1576 6.38689 12.1576 6.38689 12.1556L6.3888 12.1518L7.43334 10.4813L10.5293 5.52669L11.5189 3.94202L11.5209 3.94109ZM11.2151 3.38264C11.2151 3.48968 11.1852 3.59771 11.1244 3.69319L5.99143 11.9087C5.94514 11.983 5.87087 11.9917 5.84098 11.9917C5.81105 11.9917 5.73776 11.983 5.69053 11.9087L4.39326 9.83027C4.27559 9.6422 4.27559 9.40008 4.39326 9.21009L9.5263 0.996469C9.57259 0.921238 9.64686 0.912561 9.67675 0.912561C9.70663 0.912561 9.7809 0.921237 9.82818 0.997434L11.1244 3.07208C11.1852 3.16757 11.2151 3.27554 11.2151 3.38264Z" fill="currentcolor" />
    </g>
  </Svg>
);
