import { Accept } from 'react-dropzone';

export const ACCEPTED_IMAGE_FORMAT: Accept = {
  'image/jpeg': [],
  'image/webp': ['.webp'],
  'image/png': [],
};

export const ACCEPTED_COVER_IMAGE_FORMAT: Accept = {
  ...ACCEPTED_IMAGE_FORMAT,
  'image/gif': [],
};

export const FILE_SIZE_LIMIT = 96 * 1024 * 1024; // 100 Mo

export const IMAGE_SIZE_LIMIT = 20 * 1024 * 1024; // 20 Mo
