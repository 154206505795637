import { SelectOption } from '@cycle-app/ui/components/Selects';
import { AddIcon, TrashIcon } from '@cycle-app/ui/icons';
import { TippyProps } from '@tippyjs/react';
import { Editor } from '@tiptap/react';

type Func = (editor: Editor, position: TippyProps['placement']) => SelectOption[];

export const getRowTableActions: Func = (editor) => [
  {
    label: 'Add row below',
    value: 'addRowAfter',
    onSelect: () => editor.chain().addRowAfter().focus().run(),
    icon: <AddIcon size={16} />,
  },
  // {
  //   label: 'Toggle row header',
  //   value: 'toggleHeaderRow',
  //   onSelect: () => editor.chain().command(({
  //     state, dispatch,
  //   }) => toggleHeaderRow(state, dispatch)).focus().run(),
  //   icon: <TableIcon size={16} />,
  // },
  // {
  //   label: 'Toggle header cell',
  //   value: 'toggleHeaderCell',
  //   onSelect: () => editor.chain().toggleHeaderCell().focus().run(),
  //   icon: <TableIcon size={16} />,
  // },
  {
    label: 'Delete row',
    value: 'deleteRow',
    onSelect: () => editor.chain().deleteRow().focus().run(),
    icon: <TrashIcon size={16} />,
  },
  {
    label: 'Delete table',
    value: 'deleteTable',
    onSelect: () => editor.chain().deleteTable().focus().run(),
    icon: <TrashIcon size={16} />,
  },
];

export const getColumnTableActions: Func = (editor) => [
  {
    label: 'Add column to left',
    value: 'addColBefore',
    onSelect: () => editor.chain().addColumnBefore().focus().run(),
    icon: <AddIcon size={14} />,
  },
  {
    label: 'Add column to right',
    value: 'addColAfter',
    onSelect: () => editor.chain().addColumnAfter().focus().run(),
    icon: <AddIcon size={14} />,
  },
  // {
  //   label: 'Toggle header',
  //   value: 'toggleHeaderColumn',
  //   onSelect: () => editor.chain().command(({
  //     state, dispatch,
  //   }) => toggleHeaderColumn(state, dispatch)).focus().run(),
  //   icon: <TableIcon size={16} />,
  // },
  // {
  //   label: 'Toggle header cell',
  //   value: 'toggleHeaderCell',
  //   onSelect: () => editor.chain().toggleHeaderCell().focus().run(),
  //   icon: <TableIcon size={16} />,
  // },
  {
    label: 'Delete column',
    value: 'deleteCol',
    onSelect: () => editor.chain().deleteColumn().focus().run(),
    icon: <TrashIcon size={16} />,
  },
  {
    label: 'Delete table',
    value: 'deleteTable',
    onSelect: () => editor.chain().deleteTable().focus().run(),
    icon: <TrashIcon size={16} />,
  },
];
