import { FC } from 'react';

import {
  Wrapper,
  Root,
  Main,
  StyledLogo,
  Content,
} from './AuthLayout.styles';

export const AuthLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Wrapper>
    <Root>
      <Main>
        <Content>
          <StyledLogo animation="hover" animateColor={false} full size={32} />
          {children}
        </Content>
      </Main>
    </Root>
  </Wrapper>
);
