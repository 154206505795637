import { AskItem, AskItemAnswer } from 'src/components/Ask/Ask.types';
import { makeBooleanVar } from 'src/utils/makeVar.utils';
import { make } from 'src/utils/reactives.util';


const {
  hookValue: useGetAskPanel,
  setValue: setAskPanel,
} = make<{
  isOpen: boolean;
  question: string;
  screen: 'examples' | 'answers';
}>({
  defaultState: {
    isOpen: false,
    question: '',
    screen: 'examples',
  },
});

export {
  useGetAskPanel, setAskPanel,
};

export const closeAskPanel = () => {
  // Do not reset the screen
  setAskPanel({
    isOpen: false,
    question: '',
  });
};

export const openAsk = (search?: string) => setAskPanel({
  isOpen: true,
  question: search ?? '',
});

export const {
  hookValue: useGetAskItems,
  getValue: getAskItems,
  setValue: setAskItems,
} = make<{
  items: AskItem[];
}>({
  defaultState: {
    // Questions and answers ordered by newest first
    items: [],
  },
});

export const addAskItems = (newItems: AskItem[]) => setAskItems({
  items: [...newItems, ...getAskItems().items],
});

export const {
  hookValue: useGetAskAnswers,
  getValue: getAskAnswers,
  setValue: setAskAnswers,
} = make<{
  answers: Record<string, AskItemAnswer>;
}>({
  defaultState: {
    // Answers content indexed by answer id
    answers: {},
  },
});

export const updateAskAnswer = (id: string, answer: AskItemAnswer) => {
  setAskAnswers({
    answers: {
      ...getAskAnswers().answers,
      [id]: answer,
    },
  });
};

export const {
  hook: useIsAskExpanded,
  toggle: toggleIsAskExpanded,
} = makeBooleanVar(false);