import { MutationProgressSubscription, ProductFragment } from '@cycle-app/graphql-codegen';
import { isAfter, parseISO } from 'date-fns';

import { setCustomerDashboard, resetCustomerDashboardRefresh, getCustomerDashboards } from 'src/reactives';

export const onAnalyzeDashboardRefreshProgress = (
  result: NonNullable<MutationProgressSubscription['mutationProgress']>,
  lastRefresh: ProductFragment['lastCustomerVoiceRefresh'],
) => {
  const dashboards = Object.entries(getCustomerDashboards()).filter(state => {
    return state[1].requestId === result.mutationId;
  });
  dashboards.forEach(dashboard => {
    const {
      requestId, lastRefreshRequested,
    } = dashboard?.[1] || {};
    const dashboardId = dashboard?.[0];
    if (!dashboardId || !requestId || requestId !== result.mutationId || !lastRefreshRequested || !lastRefresh) return;
    const lastRefreshDate = parseISO(lastRefresh);
    if (isAfter(lastRefreshRequested, lastRefreshDate)) {
      setCustomerDashboard(dashboardId, { progress: result.progress });
    } else {
      resetCustomerDashboardRefresh(dashboardId);
    }
  });
};
