import { EditorEvents } from '@tiptap/core';
import { Mark } from '@tiptap/pm/model';
import { Transaction } from '@tiptap/pm/state';
import { useEffect } from 'react';

import { useEditorContext } from 'src/contexts/editorContext';
import { useResolveThread } from 'src/hooks/api/mutations/comments';
import { useUnresolveThread } from 'src/hooks/api/mutations/comments/useUnresolveThread';
import { useDocThreads } from 'src/hooks/api/queries/useDocThreads';
import { useThreadsPanel } from 'src/reactives/comments.reactive';

/**
 * When the user undoes a resolve/unresolve action, the mark's isresolved attribute is toggled.
 * The corresponding thread must also be resolved/unresolved.
 * */
export const useHandleUndoResolve = () => {
  const editor = useEditorContext(ctx => ctx.editor);
  const doc = useEditorContext(ctx => ctx.doc);
  const { section } = useThreadsPanel();
  const { resolveThread } = useResolveThread();
  const { unresolveThread } = useUnresolveThread();

  const openThreads = useDocThreads(doc?.id, {
    fetchPolicy: 'cache-only',
    section: 'open',
  });

  const closedThreads = useDocThreads(doc?.id, {
    fetchPolicy: 'cache-only',
    section: 'closed',
  });

  useEffect(() => {
    const handleTransaction = (e: EditorEvents['transaction']) => {
      if (!doc) return;

      // We check if the transaction contains a comment mark
      const result = findBlockIdInTransaction(e.transaction);
      if (!result?.blockId) return;

      // We check whether the corresponding thread is dirty by searching for it in the other threads section
      const thread = result.isResolved
        ? openThreads.threads.find(t => t?.comments?.edges[0]?.node.blockId === result.blockId)
        : closedThreads.threads.find(t => t?.comments?.edges[0]?.node.blockId === result.blockId);

      if (!thread) return;

      const firstComment = thread?.comments?.edges.at(-1)?.node;
      if (!firstComment) return;

      if (result.isResolved) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        resolveThread(doc.id, result.blockId, { commentId: firstComment.id });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        unresolveThread(doc.id, { commentId: firstComment.id });
      }
    };
    editor.on('transaction', handleTransaction);
    return () => {
      editor.off('transaction', handleTransaction);
    };
  }, [doc, editor, openThreads.threads, resolveThread, section, closedThreads.threads, unresolveThread]);
};

const findBlockIdInTransaction = (transaction: Transaction): {
  blockId?: string;
  isResolved?: boolean;
} | null => {
  const paragraphs = transaction.steps[0]?.toJSON()?.slice?.content ?? [];
  for (const paragraph of paragraphs) {
    for (const content of paragraph.content ?? []) {
      const mark = content.marks?.find((m: Mark) => m.attrs?.type === 'comment');
      if (mark) {
        return {
          blockId: mark.attrs?.id,
          isResolved: mark.attrs?.isResolved,
        };
      }
    }
  }
  return null;
};
