import { NextArrowIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { DEFAULT_AVATARS } from 'src/constants/avatars.constants';
import { STATUS_PAGE, REQUEST_ACCESS } from 'src/constants/contacts.constants';
import { HELP_URL } from 'src/constants/help.constants';

import {
  AvatarImg,
  Body,
  Container,
  Content,
  Contacts,
  Header,
  Logo,
  RequestButton,
  Title,
  Text,
  ContactTitle,
  ContactButton,
} from './Maintenance.styles';

type MaintenanceProps = {
  type?: 'maintenance-off' | 'create-workspace';
};

export const Maintenance: FC<React.PropsWithChildren<MaintenanceProps>> = ({ type = 'maintenance-off' }) => {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.3 },
      }}
    >
      <Header>
        <Logo animation="hover" full size={32} />
        <div />
      </Header>
      <Body>
        <Content>
          <AvatarImg src={DEFAULT_AVATARS[0]} alt="avatar" />
          <div>
            <Title>
              {type === 'maintenance-off'
                ? 'Hey 👋, Cycle is making big changes, come back in a few minutes 😊'
                : 'Hey 👋, looks like we are currently at capacity, come back in a few minutes 😊'}
            </Title>
            <Text>
              Want to get notified when we will be back up ? You can access our status page below 👇
            </Text>
            <RequestButton
              size="M"
              onClick={() => window.open(STATUS_PAGE, '_blank')}
            >
              Check status page
            </RequestButton>
            <Contacts>
              {type === 'create-workspace' && (
                <>
                  <ContactTitle>Request new workspace</ContactTitle>
                  <ContactButton
                    size="M"
                    variant="nospace"
                    onClick={() => window.open(REQUEST_ACCESS, '_blank')}
                  >
                    Form
                    <NextArrowIcon />
                  </ContactButton>
                </>
              )}
              <ContactTitle>Learn more about Cycle</ContactTitle>
              <ContactButton
                size="M"
                variant="nospace"
                onClick={() => window.open(HELP_URL, '_blank')}
              >
                Documentation
                <NextArrowIcon />
              </ContactButton>
            </Contacts>
          </div>
        </Content>
      </Body>
    </Container>
  );
};
