import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
  DragOverlay,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ReactNode, Dispatch, SetStateAction, useState } from 'react';

import { useIsMobile } from 'src/reactives';

type Props = {
  items: UniqueIdentifier[];
  setItems: Dispatch<SetStateAction<UniqueIdentifier[]>>;
  children: (props: {
    isDragging: boolean;
  }) => ReactNode;
  overlay: (id: UniqueIdentifier) => ReactNode;
  onDragEnd: (event: DragEndEvent) => void;
};

export const ViewsSortableContext = ({
  children, items, setItems, overlay, onDragEnd,
}: Props) => {
  const isMobile = useIsMobile();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  }));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        setActiveId(event.active.id);
      }}
      onDragEnd={(event) => {
        if (event.active.id !== event.over?.id) {
          setItems(state => {
            if (!event.over) return state;
            const oldIndex = state.indexOf(event.active.id);
            const newIndex = state.indexOf(event.over.id);
            return arrayMove(items, oldIndex, newIndex);
          });
        }
        setActiveId(null);
        onDragEnd(event);
      }}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
        disabled={isMobile}
      >
        {children({
          isDragging: !!activeId,
        })}
        <DragOverlay>
          {activeId && overlay(activeId)}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};
