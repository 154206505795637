import { ImageIcon, TrashIcon } from '@cycle-app/ui/icons';
import { ComponentPropsWithRef, FC } from 'react';

import { ActionButton } from '../Buttons';
import { CoverContainer, Menu, StyledCover } from './InsightCardCover.styles';

interface Props {
  src: string;
  onUpdate?: VoidFunction;
  onRemove?: VoidFunction;
  coverProps?: ComponentPropsWithRef<'img'>;
}

export const InsightCardCover: FC<React.PropsWithChildren<Props>> = ({
  src, onUpdate, onRemove, coverProps,
}) => (
  <CoverContainer
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {(onUpdate || onRemove) && (
      <Menu>
        {onUpdate && (
          <ActionButton
            tooltip="Update"
            tooltipPlacement="top"
            onClick={onUpdate}
          >
            <ImageIcon />
          </ActionButton>
        )}

        {onRemove && (
          <ActionButton
            tooltip="Delete"
            tooltipPlacement="top"
            onClick={onRemove}
          >
            <TrashIcon />
          </ActionButton>
        )}
      </Menu>
    )}
    <StyledCover src={src} {...coverProps} />
  </CoverContainer>
);
