import { produce } from 'immer';

import { useLocation } from 'src/hooks';
import { history } from 'src/providers';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { CommandSection } from 'src/types/commandbar.types';
import { Location } from 'src/types/routes.types';
import { getSearchParams } from 'src/utils/routing.utils';

const getState = (location: Location) => location.state?.isCommandBarOpen ?? false;

export const useIsCommandBarOpen = () => getState(useLocation());

export const isCommandBarOpen = () => getState(history.location);

export const closeCommandBar = () => {
  if (!isCommandBarOpen()) return;
  history.push(produce(history.location, draft => {
    // eslint-disable-next-line no-param-reassign
    delete draft.state?.isCommandBarOpen;
  }));
};

export const openCommandBar = (section: CommandSection | null = null) => {
  setCommandbar({ section });
  history.push({
    search: getSearchParams().toString(),
    state: {
      ...history.location.state,
      isCommandBarOpen: true,
    },
  });
};

export const toggleCommandBar = () => {
  setCommandbar({ section: null });
  history.push({
    search: getSearchParams().toString(),
    state: {
      ...history.location.state,
      isCommandBarOpen: !isCommandBarOpen(),
    },
  });
};
