import { CreateWebhookDocument, CreateWebhookMutationVariables } from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCreateWebhook = () => {
  const { product } = useProduct();
  const [createClient] = useSafeMutation(CreateWebhookDocument);
  return (variables: Omit<CreateWebhookMutationVariables, 'productId'>) => {
    if (!product?.id) return null;
    return createClient({
      variables: {
        productId: product.id,
        ...variables,
      },
      optimisticResponse: {
        createWebhook: {
          __typename: 'Webhook',
          id: 'temp-id',
          url: variables.url,
          label: variables.label,
          ressources: typeof variables.ressources === 'string'
            ? [variables.ressources]
            : variables.ressources,
          secret: '',
        },
      },
      update: (cache, { data }) => {
        const newWebhookId = data?.createWebhook?.id;
        if (!product || !newWebhookId) return;
        cache.modify({
          id: cache.identify(product),
          fields: {
            webhooks: (refs, { toReference }) => ({
              ...refs,
              edges: [
                { node: toReference(newWebhookId) },
                ...refs.edges,
              ],
            }),
          },
        });
      },
    });
  };
};

export const useCreateSubscribeWebhook = (changelogId: string) => {
  const { product } = useProduct();
  const [createClient] = useSafeMutation(CreateWebhookDocument);

  return (variables: Omit<CreateWebhookMutationVariables, 'productId'>) => {
    if (!product?.id) return null;

    return createClient({
      variables: {
        productId: product.id,
        ...variables,
      },
      optimisticResponse: {
        createWebhook: {
          __typename: 'Webhook',
          id: 'temp-id',
          url: variables.url,
          label: variables.label,
          ressources: typeof variables.ressources === 'string'
            ? [variables.ressources]
            : variables.ressources,
          secret: '',
        },
      },
      update: (cache, { data }) => {
        const newWebhookId = data?.createWebhook?.id;
        if (!newWebhookId) return;

        cache.modify({
          id: cache.identify({ id: changelogId }),
          fields: {
            subscribeWebhook: (refs, { toReference }) => ({
              ...refs,
              ...toReference(newWebhookId),
            }),
          },
        });
      },
    });
  };
};
