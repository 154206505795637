import { typo, CheckboxInput, ToggleInput, Button } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const SetupSlackInte = styled.div`
  border-radius: 8px;
  background-color: ${p => p.theme.colors.settingsModal.rightPanel.notifications.setupSlack.bg};

  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    flex: none;
  }

  button {
    margin-left: auto;
    white-space: nowrap;
  }
`;

export const Content = styled.div``;

export const SetupSlackInteContent = styled.div`
  ${typo.body500};
  line-height: 16px;

  small {
    ${typo.caption400}
  }
`;

export const Section = styled.div<{ $hasBorderBottom?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 42px 42px;
  grid-column-gap: 36px;
  grid-row-gap: 12px;

  ${p => p.$hasBorderBottom && css`
    padding-bottom: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${p.theme.colors.settingsModal.rightPanel.notifications.setupSlack.bg};
  `}
`;

export const SectionTitle = styled.h1`
  ${typo.body500};
`;

export const ChannelTitle = styled.h2`
  ${typo.body400};
  text-transform: lowercase;
  text-align: center;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const TriggerDescription = styled.p<{ $disabled?:boolean }>`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};

  ${p => p.$disabled && css`opacity: 0.5;`}
`;

export const ChannelValue = styled(CheckboxInput)`
  margin: auto;
`;

export const StyledToggleInput = styled(ToggleInput)`
  margin: auto;
`;

export const RoadmapSentence = styled.p`
  ${typo.caption400};
  margin-top: 16px;
`;

export const EnableButton = styled(Button).attrs({
  size: 'L',
  variant: 'secondary',
})`
  ${typo.caption400}
  --bg: transparent;
  gap: 4px;
  padding: 2px 6px;
  margin-left: -6px;
`;
