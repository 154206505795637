import { Color } from '@cycle-app/graphql-codegen';
import { ring } from '@cycle-app/ui/utils/styles.util';
import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { ColorPicker } from '../ColorPicker/ColorPicker';
import { Input } from '../Inputs/Input/Input';
import { ShyScrollbarCss } from '../ShyScrollbar/ShyScrollbar.styles';

const PADDING = 12;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  padding: ${PADDING}px;
  overflow: hidden;
  width: 386px;
  height: 324px;
`;

export const StyledColorPicker = styled(ColorPicker)`
  display: flex;
  justify-content: space-between;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${p => p.theme.colors.border.primary};
  margin: 0 -${PADDING}px;
`;

export const Icons = styled.div`
  ${ShyScrollbarCss}
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 9px;
  padding: 2px 0 2px 2px;
  margin: -2px -${PADDING}px -2px -2px;
`;

export const SearchInput = styled(Input)`
  input {
    height: 30px;
  }
`;

export const IconButton = styled.button<{ $color?: Color }>`
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  color: ${p => (p.$color ? p.theme.nuances[p.$color].main : 'unset')};

  background: ${p => p.theme.colors.background.secondary};

  &:focus-visible {
    outline: none;
    ${ring}
  }

  &:hover {
    background: ${p => (p.theme.isDark
    ? lighten(0.02, p.theme.colors.background.secondary)
    : darken(0.05, p.theme.colors.background.secondary))};
  }

  &:active {
    background: ${p => (p.theme.isDark
    ? lighten(0.04, p.theme.colors.background.secondary)
    : darken(0.08, p.theme.colors.background.secondary))};
  }
`;
