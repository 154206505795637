import { useSubscription } from '@apollo/client';
import {
  FileDocImportDocument,
  FileDocImportSubscription,
  FileDocImportSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { setDocImport, getDocImport } from 'src/reactives/docImport.reactive';

import { useAddDocImportToCache } from './cache/cacheDoc';
import { useMaybeMeV2 } from './useMe';
import { useProductBase } from './useProduct';

export const useDocCreatedfromFileSubscription = () => {
  const { me } = useMaybeMeV2();
  const product = useProductBase();
  const addDocImportToCache = useAddDocImportToCache();

  return useSubscription<FileDocImportSubscription, FileDocImportSubscriptionVariables>(
    FileDocImportDocument,
    {
      variables: {
        productId: product?.id || '',
      },
      onSubscriptionData: ({ subscriptionData }) => {
        const newDoc = subscriptionData.data?.fileDocImport;
        if (!me || !newDoc) return;
        // Remove a loading spinner from the list of imported docs in the dropzone
        setDocImport({
          loadingFiles: getDocImport().loadingFiles.slice(0, -1),
        });
        // Add the doc into the list of imported docs in the dropzone
        addDocImportToCache(newDoc);
      },
    },
  );
};
