import { useSubscription } from '@apollo/client';
import {
  IntegrationFullFragment,
  IntegrationType,
  ProductSubscriptionDocument,
  ProductSubscriptionSubscription,
  ProductSubscriptionSubscriptionVariables,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useRef } from 'react';

import { onProductUpdated } from 'src/components/RoadmapTemplatesModal/RoadmapConfirmModal';
import { Events } from 'src/constants/analytics.constants';
import { setDocTypes } from 'src/reactives/docTypes.reactive';
import { getOnboardingSlackStep, setOnboardingSlackStep } from 'src/reactives/onboardingSlack.reactive';
import { setStatuses } from 'src/reactives/productStatus.reactive';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { useMaybeMe, useProductIntegrations } from '..';

export const useProductSubscription = (productId?: string) => {
  const me = useMaybeMe();
  const { list } = useProductIntegrations();
  const slackInstall = useRef(hasSlack(list));

  /* eslint-disable @typescript-eslint/indent */
  return useSubscription<
    ProductSubscriptionSubscription,
    ProductSubscriptionSubscriptionVariables
  >(ProductSubscriptionDocument, {
    /* eslint-enable @typescript-eslint/indent */
    variables: {
      productId: productId as string,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) return;

      setDocTypes(data.product.doctypes);
      setStatuses(data.product.status);

      onProductUpdated();

      if (
        me &&
        !hasSlack(list) &&
        hasSlack(nodeToArray(data.product.integrations))
      ) {
        if (!slackInstall.current) {
          trackAnalytics(Events.IntegrationInstalled, {
            userId: me.id,
            productId: data.product.id,
            modelName: 'Slack',
          });
          slackInstall.current = true;
        }
        const slackStep = getOnboardingSlackStep(data.product.id);
        if (slackStep) {
          setOnboardingSlackStep(data.product.id, OnboardingSlackStep.Reactji);
        }
      }
    },
  });
};

const hasSlack = (list: IntegrationFullFragment[]) => list.some(item => item.type === IntegrationType.Slack && !!item.provider);
