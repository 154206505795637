import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Label = styled.label`
  ${typo.body500}

  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Title = styled.div`
  ${typo.body500}

  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;
