import { make } from 'src/utils/reactives.util';

export type UpgradeModalResult = {
  isOpened: boolean;
};

export const {
  hookState: useUpgradeModal,
  hookValue: useGetUpgradeModal,
  getValue: getUpgradeModal,
  setValue: setUpgradeModal,
  resetValue: resetUpgradeModal,
} = make<UpgradeModalResult>({
  defaultState: {
    isOpened: false,
  },
});
