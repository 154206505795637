import { UpdateProductTemplateDocument } from '@cycle-app/graphql-codegen';
import { useEffect, useReducer } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { useSafeMutation, useProductBase } from 'src/hooks';

import { Template } from './roadmapTemplates.data';
import { ConfirmContent } from './RoadmapTemplatesModal.styles';
import { TemplateHierarchy } from './TemplateHierarchy';

// eslint-disable-next-line import/no-mutable-exports
export let onProductUpdated: VoidFunction = () => {};

export const RoadmapConfirmModal = ({
  template, hide, done,
}: {
  template: Template;
  hide: VoidFunction;
  done: VoidFunction;
}) => {
  const product = useProductBase();
  const [update] = useSafeMutation(UpdateProductTemplateDocument);
  const [loading, setLoadingTrue] = useReducer(() => true, false);

  useEffect(() => {
    return () => {
      onProductUpdated = () => {};
    };
  }, []);

  return (
    <DialogModal
      title={`Select ${template.name}`}
      type="default"
      hide={hide}
      variantCancelButton="secondary"
      variantSubmitButton="primary"
      loading={loading}
      autoHide={false}
      onConfirm={async () => {
        if (!product?.id) return;

        setLoadingTrue();

        await update({
          variables: {
            productId: product?.id,
            templateType: template.type,
          },
        });

        onProductUpdated = done;
      }}
      info={(
        <ConfirmContent>
          <p>
            {'Are you sure you want to choose '}
            <strong>{template.name}</strong>
            ? Note that it’s just starting point, you will be able to update your configuration later.
          </p>
          <TemplateHierarchy template={template} />
        </ConfirmContent>
      )}
    />
  );
};
