import { make } from 'src/utils/reactives.util';

type MobileAsideMenuState = {
  isVisible: boolean;
};

export const {
  hookState: useMobileAsideMenu,
  setValue: setMobileAsideMenu,
} = make<MobileAsideMenuState>({
  defaultState: {
    isVisible: false,
  },
});

export const closeMobileAsideMenu = () => setMobileAsideMenu({ isVisible: false });
