import { DocInGroupFragment } from '@cycle-app/graphql-codegen';
import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import { FC, useMemo } from 'react';

import { useBoardConfig } from './boardConfigContext';
import { getDocType } from '../reactives/docTypes.reactive';
import { isInsight } from '../utils/docType.util';

type DocValue = DocInGroupFragment;

export type DocContextDocument = DocValue & {
  path: string[];
  isLastChild?: boolean;
  isInsight?: boolean;
  groupId?: string;
};

const DocContext = createContext<DocContextDocument>({} as DocContextDocument);

DocContext.displayName = 'DocContext';

type DocProviderProps = {
  value: DocValue;
  path?: string[];
  isLastChild?: boolean;
  groupId?: string;
};

export const DocProvider: FC<React.PropsWithChildren<DocProviderProps>> = ({
  children, path, groupId, isLastChild = false, ...props
}) => {
  const boardId = useBoardConfig(ctx => ctx.boardId) as string;
  const doctype = getDocType(props.value.doctype.id);
  const isDocInsight = !!doctype && isInsight({ type: doctype.type });

  const value = useMemo(() => ({
    ...props.value,
    path: path ? [...path, props.value.id] : [boardId, props.value.id],
    isLastChild,
    isInsight: isDocInsight,
    groupId,
  }), [props.value, path, boardId, isLastChild, groupId, isDocInsight]);

  return (
    <DocContext.Provider value={value}>
      {children}
    </DocContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useDocContext = <T extends unknown = DocContextDocument>(selector?: ContextSelector<DocContextDocument, T>) => {
  const isWrappedWithContext = useHasParentContext(DocContext);
  if (!isWrappedWithContext) throw new Error('useDocContext must be used within a DocProvider');
  return useContextSelector(DocContext, selector ?? (ctx => ctx as T));
};
