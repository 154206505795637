import { Skeleton, CircleSkeleton } from '@cycle-app/ui';

import { Comment, Content } from './DocComments.styles';

export const CommentsSkeletons = ({ count = 0 }: { count?: number }) => (
  <>
    {
        [...Array(count).keys()].map(i => (
          <Comment
            key={i}
            style={{
              height: 67,
              flexShrink: 0,
            }}
          >
            <CircleSkeleton width={20} height={20} />
            <Content>
              <Skeleton width={150} height={14} style={{ margin: '3px 0' }} />
              <Skeleton width={250} height={14} style={{ margin: '3px 0' }} />
            </Content>
          </Comment>
        ))
      }
  </>
);
