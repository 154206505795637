import { typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.caption400};
  color: ${p => p.theme.colors.text.disabled};

  ${mobile} {
    display: none;
  }
`;

export const Key = styled.div`
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 4px;
  padding: 0px 4px;
`;
