import { useRef, useEffect, useState, RefObject } from 'react';

export const useHover = <T extends HTMLElement>(props: {
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}): [boolean, RefObject<T>] => {
  const ref = useRef<T>(null);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const container = ref.current;
    const onMouseEnter = () => {
      setIsHover(true);
      props.onMouseEnter?.();
    };
    const onMouseLeave = () => {
      setIsHover(false);
      props.onMouseLeave?.();
    };
    container?.addEventListener('mouseenter', onMouseEnter);
    container?.addEventListener('mouseleave', onMouseLeave);
    return () => {
      container?.removeEventListener('mouseenter', onMouseEnter);
      container?.removeEventListener('mouseleave', onMouseLeave);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isHover, ref];
};
