import { Button, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const DefaultButton = styled(Button).attrs({
  size: 'L',
})`
  height: 28px;
  padding: 0 10px;
  ${typo.body500}
`;

export const SecondaryButton = styled(Button).attrs({
  size: 'L',
  variant: 'secondary',
})`
  height: 36px;
  padding: 0 10px;
  ${typo.body500}
`;
