import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const SlotContainer = styled.div<{ $isSpaced?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${p => (p.$isSpaced ? 'space-between' : 'flex-start')};
  width: 100%;
`;

export const SlotActionBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${p => p.theme.colors.text.secondary};
`;

export const HideButton = styled(ActionButton)`
  width: 24px;
  height: 24px;
`;
