import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ClickUpIcon = (props: SvgProps) => (
  <Svg {...props} width="16" height="16" viewBox="-2 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g clipPath="url(#clip0_10050_430)">
      <path d="M0.0400391 12.2964L2.42104 10.4136C3.68604 12.1178 5.03004 12.9034 6.52604 12.9034C8.01404 12.9034 9.32004 12.1271 10.528 10.4363L12.943 12.2737C11.2 14.7119 9.03404 16.0002 6.52604 16.0002C4.02604 16.0002 1.83904 14.7202 0.0400391 12.2964Z" fill="url(#paint0_linear_10050_430)" />
      <path d="M6.5178 4.1126L2.2798 7.88241L0.320801 5.53712L6.5268 0.0166016L12.6838 5.54125L10.7158 7.87828L6.5178 4.1126Z" fill="url(#paint1_linear_10050_430)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_10050_430" x1="0.0400391" y1="390.357" x2="12.943" y2="390.357" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8930FD" />
        <stop offset="1" stopColor="#49CCF9" />
      </linearGradient>
      <linearGradient id="paint1_linear_10050_430" x1="0.320801" y1="534.97" x2="12.6838" y2="534.97" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF02F0" />
        <stop offset="1" stopColor="#FFC800" />
      </linearGradient>
      <clipPath id="clip0_10050_430">
        <rect width="13" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
