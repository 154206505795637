import type { Color } from '@cycle-app/graphql-codegen';
import { getOS, isSafari } from '@cycle-app/utilities';
import { Emoji as EmojiMart } from 'emoji-mart';
import { forwardRef, ComponentProps } from 'react';

import { Icon, getIconName } from '../Icon';
import { Container, Size } from './Emoji.styles';

export type EmojiSize = Size;

type Props = ComponentProps<typeof Container> & {
  emoji?: string | null;
  inline?: boolean;
  size?: Size;
  color?: Color;
};

const USE_NATIVE_EMOJI = getOS() === 'macOS' && !isSafari;

export const Emoji = forwardRef<HTMLDivElement, Props>(({
  emoji,
  size = 16,
  inline = false,
  color,
  ...otherProps
}, ref) => {
  if (!emoji) return null;
  const icon = getIconName(emoji);
  return (
    <Container
      ref={ref}
      $size={size}
      $inline={inline}
      $native={USE_NATIVE_EMOJI}
      role="img"
      $color={color}
      $isIcon={!!icon}
      {...otherProps}
    >
      {icon
        ? <Icon name={icon} size={`${size}px`} />
        : (
          <EmojiMart
            emoji={emoji}
            set="apple"
            native={USE_NATIVE_EMOJI}
            size={size}
          />
        )}
    </Container>
  );
});

export const AnalysesIcon = (props: Omit<Props, 'emoji' | 'color'>) => (
  <Emoji
    emoji="lucide_layers-2"
    color="a"
    {...props}
  />
);
