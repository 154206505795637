import { z } from 'zod';

import { LocalKey } from 'src/types/localStorage.types';
import { makeVar } from 'src/utils/makeVar.utils';

const {
  set,
  selectorHook,
} = makeVar([], {
  key: LocalKey.FeatureCollapsedSections,
  crossTabSync: true,
  schema: z.array(z.string()),
});

export const updateFeatureCollapsedSection = (sectionId: string, open: boolean) => {
  if (open) {
    set(prev => prev.filter(id => id !== sectionId));
  } else {
    set(prev => [...prev, sectionId]);
  }
};

export const expandFeatureSection = (sectionId: string) => {
  updateFeatureCollapsedSection(sectionId, true);
};

export const useFeatureSectionOpen = (sectionId: string) => {
  return selectorHook(state => !state.includes(sectionId));
};