import { DROPZONE_ACCEPTED_EXTENSIONS } from 'src/constants/dropzone.constants';
import { getFileExtension, getFileMaxSize } from 'src/utils/files.util';

/**
 * Custom validation function for the useDropzone hook
 * It must return null if there's no errors
 */
export const fileValidator = (file: File) => {
  // Check file size
  const { size } = file;
  if (size != null && size > getFileMaxSize(file)) {
    return {
      code: 'file-too-large',
      message: 'The file is too large',
    };
  }
  if (size != null && size <= 0) {
    return {
      code: 'file-empty',
      message: 'The file is empty',
    };
  }

  // Check file format
  const { name } = file;
  const extension = getFileExtension(name)?.toLowerCase();
  if (name !== null && (!extension || !DROPZONE_ACCEPTED_EXTENSIONS.includes(extension))) {
    return {
      code: 'format-not-supported',
      message: 'Format not supported',
    };
  }
  return null;
};
