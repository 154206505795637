import { WIDTH_COLLAPSED_SIDEBAR } from '@cycle-app/ui';
// import { isTouchDevice } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';

import { ErrorToasters } from 'src/app/ErrorToasters/ErrorToasters';
import { AppToasters } from 'src/components/AppToasters';
import BulkActions from 'src/components/BulkActions/BulkActions';
// import DocPreviewHintPopup from 'src/components/DocPreviewHint/DocPreviewHintPopup';
import Portal from 'src/components/Portal/Portal';
import { PageId } from 'src/constants/routing.constant';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { usePageId } from 'src/hooks/usePageId';
import { useBoardSlug, useDocSlug } from 'src/hooks/usePathParams';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { useGetLayerModal, useGetLayerModalZ2 } from 'src/reactives/layer.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { useGetSidebarWidth } from 'src/reactives/sidebar.reactive';
import { PortalId } from 'src/types/portal.types';

import { BulkActionsContainer, Container, ToastersContainer, ErrorToastersContainer } from './LayoutFooter.styles';

const LayoutFooter: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { visible: isLayerModalVisible } = useGetLayerModal();
  const { visible: isLayerModalZ2Visible } = useGetLayerModalZ2();
  const { width } = useGetSidebarWidth();
  const collapsed = useSidebarCollapsed();
  const boardSlug = useBoardSlug();
  const docSlug = useDocSlug();
  const currentPage = usePageId();

  const { selected } = useGetSelection();

  const isModalOpen = isLayerModalVisible || isLayerModalZ2Visible;
  const showBulkActions = (
    !!boardSlug ||
    // We can bulk docs inside the right panel.
    !!docSlug ||
    currentPage === PageId.Main
  ) && selected.length > 0;

  const sidebarWidth = collapsed ? WIDTH_COLLAPSED_SIDEBAR : width;

  return (
    <Portal portalId={PortalId.LayoutFooter}>
      <AnimatePresence>
        <BoardConfigContextProvider>
          <Container>
            {children}

            {showBulkActions && (
              <BulkActionsContainer
                $sidebarWidth={sidebarWidth}
                $isModalOpen={isModalOpen}
              >
                <BulkActions />
              </BulkActionsContainer>
            )}

            {/* {!isTouchDevice() && <DocPreviewHintPopup />} */}

            <ToastersContainer>
              <AppToasters />
            </ToastersContainer>

            <ErrorToastersContainer
              placeLeft={showBulkActions}
              $sidebarWidth={sidebarWidth}
            >
              <ErrorToasters />
            </ErrorToastersContainer>
          </Container>
        </BoardConfigContextProvider>
      </AnimatePresence>
    </Portal>
  );
};

export default LayoutFooter;
