import { useQuery } from '@apollo/client';
import {
  SearchDocDocument, DoctypeRelativeFragment, AddNewDocDocument, AddNewDocAttributeValue,
  SearchDocQueryVariables,
  DocResultFragment,
  SearchSuggestionDocsQueryVariables,
} from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { AddIcon, AiIcon } from '@cycle-app/ui/icons';
import { nodeToArray, useListNav, useHotkeys } from '@cycle-app/utilities';
import { FC, useMemo, useState, useCallback, useEffect } from 'react';

import { useSearchSuggestionDocs } from 'src/hooks/api/queries/useSearchSuggestionDocs';
import { useFetchDocType } from 'src/hooks/api/useDocType';
import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useSuggestionStatusIds } from 'src/reactives/productStatus.reactive';
import { isInsight } from 'src/utils/docType.util';

import { addDocAutomationWatch } from '../../reactives/docAutomationWatch.reactive';
import { doctypeHasAutomation } from '../../utils/doctype.automation.util';
import { DocTypeIcon } from '../DocTypeIcon';
import { AddNewItemLine } from './AddNewItemLine';
import {
  CREATE_VALUE_PREFIX,
  REMOVE_VALUE,
  DOC_TYPES_AMOUNT_LIMIT,
  CREATE_VALUE_MORE,
} from './DocSearchDropdown.constants';
import {
  SearchResults,
  StyledSelectLine,
  ListContainer,
  ResultSpinnerContainer,
  SectionTitle,
  TitleSpinner,
  NotFound,
  CreateSelectLine,
  CreateContainer,
  SuggestDocTitle,
  DocTitle,
} from './DocSearchDropdown.styles';
import { cropDocTypes } from './DocSearchDropdown.utils';
import { DocSearchLine } from './DocSearchLine';
import { DocSuggestionLine } from './DocSuggestionLine';

const SUGGESTION_PREFIX = 'suggestion';

export type DocSearchResultProps = {
  onAdd?: (docId: string, options?: { isNewDoc?: boolean; remove?: boolean }) => void;
  onRemove?: VoidFunction;
  showNoneOption?: boolean;
  possibleDoctypes?: Array<DoctypeRelativeFragment> | null;
  childDoctypeId?: string;
  search?: string;
  inheritedAttributes?: AddNewDocAttributeValue[];
  customerId?: string;
  sourceId?: string;
  showLeaveBoardWarnings?: boolean;
  showAddSelectOption?: boolean;
  searchVariables?: Partial<SearchDocQueryVariables>;
  recentSearchVariables?: Partial<SearchDocQueryVariables>;
  searchSuggestionsVariables?: Partial<SearchSuggestionDocsQueryVariables>;
  isOptionDisabled?: (doc: DocResultFragment) => boolean;
  filterDoc?: (doc: DocResultFragment) => boolean;
  suggestionDocId?: string;
  onClickCreate?: (search?: string) => void;
  semanticSearch?: string;
  suggestionMax?: number;
  showLinearAutoCreate?: boolean;
  onOptionsChange?: VoidFunction;
  suggestedName?: string | null;
};

export const DocSearchResult: FC<React.PropsWithChildren<DocSearchResultProps>> = ({
  onAdd,
  onRemove,
  showNoneOption,
  possibleDoctypes: possibleDocTypesProps,
  childDoctypeId,
  search = '',
  inheritedAttributes,
  customerId,
  sourceId,
  searchVariables,
  recentSearchVariables = searchVariables,
  searchSuggestionsVariables,
  isOptionDisabled,
  filterDoc,
  suggestionDocId,
  onClickCreate,
  semanticSearch,
  suggestionMax,
  showLinearAutoCreate,
  onOptionsChange,
  suggestedName,
  ...props
}) => {
  const { product } = useProduct();
  const { fetch: fetchDocType } = useFetchDocType();
  const [createDoc, { loading: loadingCreateDoc }] = useSafeMutation(AddNewDocDocument);

  const statusIds = useSuggestionStatusIds();
  const {
    data,
    previousData,
    loading,
  } = useQuery(SearchDocDocument, {
    variables: {
      text: search,
      productId: product?.id ?? '',
      statusIds,
      childDoctypeId,
      size: 50,
      cursor: '',
      ...(!search ? recentSearchVariables : searchVariables),
    },
    fetchPolicy: 'cache-and-network',
  });

  const results = useMemo(
    () => nodeToArray((loading ? previousData : data)?.searchDoc).filter(result => !filterDoc || filterDoc(result.doc)),
    [data, filterDoc, loading, previousData],
  );

  const docTypes = nodeToArray(product?.doctypes);
  const possibleDocTypes = useMemo(
    () => (possibleDocTypesProps || docTypes).filter(doctype => !isInsight(doctype)),
    [docTypes, possibleDocTypesProps],
  );

  const isSuggestionsEnabled = !search && (!!suggestionDocId || !!semanticSearch || !!searchSuggestionsVariables);

  const suggestionQuery = useSearchSuggestionDocs(searchSuggestionsVariables ?? {
    docId: suggestionDocId as string,
    text: semanticSearch,
    childDoctypeId,
  }, {
    skip: !isSuggestionsEnabled,
  });

  const suggestionQueryDocs = suggestionQuery.docs.slice(0, suggestionMax);

  const optionsValues = useMemo(() => {
    const showCreate = !!(search || suggestedName);
    return [
      ...(!search.length && showNoneOption ? [REMOVE_VALUE] : []),
      ...suggestionQuery.docs.map((x) => `${SUGGESTION_PREFIX}${x.id}`),
      ...results.map((x) => (x.doc.id)),
      ...(showCreate && possibleDocTypes.length <= DOC_TYPES_AMOUNT_LIMIT
        ? possibleDocTypes.map(p => `${CREATE_VALUE_PREFIX}${p.id}`)
        : []
      ),
      ...(showCreate && possibleDocTypes.length > DOC_TYPES_AMOUNT_LIMIT
        ? cropDocTypes(possibleDocTypes).map(p => `${CREATE_VALUE_PREFIX}${p.id}`)
        : []
      ),
      ...((possibleDocTypesProps?.length || 0) > DOC_TYPES_AMOUNT_LIMIT ? [CREATE_VALUE_MORE] : []),
    ];
  }, [possibleDocTypes, possibleDocTypesProps?.length, results, search, showNoneOption, suggestionQuery.docs, suggestedName]);
  const [creatingDocTypeId, setCreatingDocTypeId] = useState('');

  // Record<doctypeId, withLinear>
  const [doctypeAutomations, setDoctypeAutomations] = useState<Record<string, boolean>>({});

  const onCreateDoc = useCallback(async (docTypeId: string) => {
    if (!docTypeId || !product?.id) return;

    setCreatingDocTypeId(docTypeId);
    /**
     * We fetch the doc type if not present to be sure we always have the id
     */
    const docType = docTypes.find(d => d.id === docTypeId) || (await fetchDocType(docTypeId));
    const docTypesAttributeIds: string[] = docType?.attributeDefinitions.edges.map(({ node }) => node.id) ?? [];

    const commonAttributes: AddNewDocAttributeValue[] =
      inheritedAttributes?.filter((attr) => docTypesAttributeIds.includes(attr.attributeDefinitionId)) ?? [];

    const withLinear =
      showLinearAutoCreate &&
      doctypeAutomations[docTypeId] !== false && // (undefined || true) = checked
      doctypeHasAutomation(docTypeId);

    const docId = (await createDoc({
      variables: {
        doctypeId: docTypeId,
        title: search || suggestedName || '',
        productId: product?.id,
        attributes: commonAttributes,
        ...(sourceId && {
          source: {
            sourceId,
          },
        }),
        customer: customerId ? { id: customerId } : undefined,
        withLinear,
      },
    })).data?.addNewDoc?.id;

    if (docId) {
      addDocAutomationWatch(docId);
      onAdd?.(docId, { isNewDoc: true });
    }
  }, [
    customerId,
    onAdd,
    createDoc,
    search,
    sourceId,
    fetchDocType,
    inheritedAttributes,
    docTypes,
    product?.id,
    doctypeAutomations,
    showLinearAutoCreate,
    suggestedName,
  ]);

  const onResultSelected = useCallback(async (optionId: string | null) => {
    if (!optionId || optionId === CREATE_VALUE_MORE) return;

    if (optionId === REMOVE_VALUE) {
      onRemove?.();
      return;
    }

    if (optionId.includes(CREATE_VALUE_PREFIX)) {
      if (onClickCreate) {
        onClickCreate(search);
        return;
      }
      await onCreateDoc(optionId.replace(CREATE_VALUE_PREFIX, ''));
      return;
    }

    onAdd?.(optionId.replace(SUGGESTION_PREFIX, ''), { isNewDoc: false });
  }, [onAdd, onRemove, onClickCreate, onCreateDoc, search]);

  const {
    listProps,
    itemProps,
    selected,
    hoverDisabled,
    select,
  } = useListNav({
    optionsValues,
    value: null,
    onSelect: onResultSelected,
    autoFocus: true,
    defaultIndex: 0,
  });

  useHotkeys('tab', e => {
    e.preventDefault();
    e.stopPropagation();

    if (!selected) return;

    if (selected.includes(CREATE_VALUE_PREFIX) || selected.includes(CREATE_VALUE_MORE)) {
      const firstOption = optionsValues[0];
      if (firstOption) select(firstOption);
    } else {
      const firstCreateOption = optionsValues.find(o => o.includes(CREATE_VALUE_PREFIX));
      if (firstCreateOption) select(firstCreateOption);
    }
  });

  useEffect(() => {
    onOptionsChange?.();
  }, [onOptionsChange, optionsValues]);

  const showLoader = !isSuggestionsEnabled && loading && results.length === 0;
  const showRemoveSelectOption = showNoneOption && !search;
  const showAddSelectOption = props.showAddSelectOption || !!search || !!suggestedName;
  const showResultsContainer = showLoader || !!results.length || showRemoveSelectOption || isSuggestionsEnabled;

  // Variant of the last line of the dropdown with buttons to create a new doc
  // 'multiple' - show one button for each possible doctype
  // 'single' - show one button for the first possible doctype, with the search or suggested name as title
  const createVariant = (!search && suggestedName) || (search && possibleDocTypes.length === 1) ? 'single' : 'multiple';

  return (
    <ListContainer {...listProps}>
      {showResultsContainer && (
        <SearchResults $hasMarginBottom={!showAddSelectOption}>
          {showRemoveSelectOption && (
            <StyledSelectLine
              isSelected={selected === REMOVE_VALUE}
              hoverDisabled={hoverDisabled}
              label="None"
              {...itemProps(REMOVE_VALUE)}
            />
          )}

          {isSuggestionsEnabled && (
            <>
              <SectionTitle>
                Recommended
                {suggestionQuery.isLoading && <TitleSpinner />}
              </SectionTitle>

              {!suggestionQuery.isLoading && suggestionQuery.docs.length === 0 && (
                <NotFound>No recommendation found</NotFound>
              )}

              {suggestionQueryDocs
                .filter(doc => !filterDoc || filterDoc(doc))
                .map(doc => (
                  <DocSuggestionLine
                    key={doc.id}
                    doc={doc}
                    isDisabled={isOptionDisabled?.(doc)}
                    isSelected={selected === `${SUGGESTION_PREFIX}${doc.id}`}
                    hoverDisabled={hoverDisabled}
                    {...itemProps(doc.id)}
                  />
                ))}

              <SectionTitle>
                Recent
                {loading && results.length === 0 && <TitleSpinner />}
              </SectionTitle>
            </>
          )}

          {showLoader && (
            <ResultSpinnerContainer>
              <Spinner />
            </ResultSpinnerContainer>
          )}

          {results.map(result => (
            <DocSearchLine
              key={result.doc.id}
              doc={result.doc}
              highlightContent={result.highlightContent}
              isDisabled={isOptionDisabled?.(result.doc)}
              isSelected={selected === result.doc.id}
              hoverDisabled={hoverDisabled}
              highlightTitle={result.highlightTitle}
              search={search}
              {...itemProps(result.doc.id)}
            />
          ))}
        </SearchResults>
      )}

      {createVariant === 'multiple' && showAddSelectOption && (
        <AddNewItemLine
          onDoctypeAutomationsChange={(doctypeId: string, withLinear: boolean) => setDoctypeAutomations(v => ({
            ...v,
            [doctypeId]: withLinear,
          }))}
          doctypeAutomations={doctypeAutomations}
          docTypes={possibleDocTypes}
          getItemProps={itemProps}
          selected={selected || suggestedName || ''}
          isHoverDisabled={hoverDisabled}
          search={search || suggestedName || ''}
          docTypeIdLoading={loadingCreateDoc && creatingDocTypeId ? creatingDocTypeId : ''}
          onSelect={onResultSelected}
          showLinearAutoCreate={showLinearAutoCreate}
        />
      )}

      {createVariant === 'single' && showAddSelectOption && possibleDocTypes[0] && (
        <CreateSelectLine
          {...itemProps(`${CREATE_VALUE_PREFIX}${possibleDocTypes[0].id}`)}
          isSelected={selected === `${CREATE_VALUE_PREFIX}${possibleDocTypes[0].id}`}
          hoverDisabled={hoverDisabled}
          endSlot={loadingCreateDoc && <Spinner />}
          label={(
            <CreateContainer>
              <AddIcon size={12} />
              <span>Create</span>
              {search && (
                <DocTitle>
                  <DocTypeIcon doctype={possibleDocTypes[0]} size={12} />
                  <span>{search}</span>
                </DocTitle>
              )}
              {!search && (
                <SuggestDocTitle>
                  <AiIcon size={12} hasGradient />
                  <span>
                    {suggestedName}
                  </span>
                </SuggestDocTitle>
              )}
            </CreateContainer>
          )}
        />
      )}
    </ListContainer>
  );
};
