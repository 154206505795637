import { Spinner } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { ComponentProps } from 'react';

import { LINEAR_IMPORT_TOASTER_ID } from 'src/constants/linearImport.constants';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks';
import { addToaster } from 'src/utils/toasters.utils';

import { ToasterLink } from './LinearImportModal.styles';

export const addLinearImportInProgressToaster = () => addToaster({
  id: LINEAR_IMPORT_TOASTER_ID,
  closable: true,
  title: 'Import of Linear issues and projects in progress',
  message: 'It may take a while, we’ll let you know once it’s ready.',
  duration: 'infinite',
  icon: <Spinner />,
});

export const addLinearImportDoneToaster = () => addToaster({
  closable: true,
  duration: 5000,
  icon: <CheckIcon size={14} />,
  title: 'Import of Linear projects and issues done',
  message: ({ close }) => <RoadmapsLink onClick={() => close()} />,
});

const RoadmapsLink = ({ onClick }: { onClick: ComponentProps<'button'>['onClick'] }) => {
  const { navigate } = useNavigate();
  return (
    <ToasterLink
      onClick={e => {
        onClick?.(e);
        navigate(PageId.Roadmap);
      }}
    >
      Click here to go to roadmaps
    </ToasterLink>
  );
};
