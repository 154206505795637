import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GoogleSheetsIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M7.66659 0.59259L11.3333 4.25926L9.49992 4.59259L7.66659 4.25926L7.33325 2.42592L7.66659 0.59259Z" fill="#188038" />
    <path d="M7.66663 4.25926V0.59259H1.66663C1.11413 0.59259 0.666626 1.04009 0.666626 1.59259V14.2593C0.666626 14.8118 1.11413 15.2593 1.66663 15.2593H10.3333C10.8858 15.2593 11.3333 14.8118 11.3333 14.2593V4.25926H7.66663Z" fill="#34A853" />
    <path d="M2.66663 6.25926V11.0926H9.33329V6.25926H2.66663ZM5.58329 10.2593H3.49996V9.09259H5.58329V10.2593ZM5.58329 8.25926H3.49996V7.09259H5.58329V8.25926ZM8.49996 10.2593H6.41663V9.09259H8.49996V10.2593ZM8.49996 8.25926H6.41663V7.09259H8.49996V8.25926Z" fill="white" />
  </Svg>
);
