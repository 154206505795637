import { Skeleton, ActionButton, LinearProjectIcon } from '@cycle-app/ui';
import { CaretIcon, PenIcon, UnlinkIcon, ArrowRightUpIcon } from '@cycle-app/ui/icons';
import { useEffect, useState } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { useLinearProject } from 'src/hooks/api/queries/integrations/useLinearProject';
import { usePrefetchLinearProjectIssues } from 'src/hooks/api/queries/integrations/useLinearProjectIssues';
import { Layer } from 'src/types/layers.types';
import { projectInfos } from 'src/utils/linear.utils';

import {
  PreviewContainer, PreviewHeader, HeaderLeft, HeaderRight, ProjectName, ProjectLink, Infos,
} from './DocLinear.styles';
import { PreviewAlert } from './PreviewAlert';
import { PreviewProjectIssues } from './PreviewProjectIssues';

export const PreviewProject = ({
  onClickChange, onUnlink, hide,
}: {
  onClickChange: VoidFunction;
  onUnlink: VoidFunction;
  hide: VoidFunction;
}) => {
  const projectId = useDocLinearContext(ctx => ctx.automationId);
  const integrationId = useDocLinearContext(ctx => ctx.integrationId);
  const query = useLinearProject(projectId, integrationId);
  const [isExpanded, setIsExpanded] = useState(false);
  const prefetchProjectIssues = usePrefetchLinearProjectIssues();
  const automationUrl = useDocLinearContext(ctx => ctx.automationUrl);

  useEffect(() => {
    if (projectId) prefetchProjectIssues(projectId, integrationId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = (
    <DotsMenuLayer
      layer={Layer.DropdownModalZ4}
      options={[...automationUrl ? [{
        value: 'open',
        label: 'Open in Linear',
        keepDropdownOnSelect: false,
        onSelect: () => {
          hide();
          window.open(automationUrl, '_blank');
        },
        icon: <ArrowRightUpIcon />,
      }] : [], {
        value: 'change',
        label: 'Change project',
        icon: <PenIcon style={iconStyle(18)} />,
        keepDropdownOnSelect: false,
        onSelect: () => {
          hide();
          onClickChange();
        },
      }, {
        value: 'unlink',
        label: 'Unlink project',
        icon: <UnlinkIcon style={iconStyle(15)} />,
        keepDropdownOnSelect: false,
        onSelect: () => {
          hide();
          onUnlink();
        },
      }]}
    />
  );

  if (query.isLoading && !query.project) {
    return (
      <PreviewContainer>
        <PreviewHeader>
          <Skeleton height={20} />
          {menu}
        </PreviewHeader>
      </PreviewContainer>
    );
  }

  if (!query.project) {
    return (
      <PreviewContainer>
        <PreviewHeader>
          <PreviewAlert message="It seems that this project no longer exists." />
          {menu}
        </PreviewHeader>
      </PreviewContainer>
    );
  }

  return (
    <PreviewContainer>
      <PreviewHeader>
        <HeaderLeft>
          <ActionButton onClick={() => setIsExpanded(!isExpanded)}>
            <CaretIcon
              size={11}
              direction={isExpanded ? 'bottom' : 'right'}
            />
          </ActionButton>

          <LinearProjectIcon
            icon={query.project.icon}
            color={query.project.color}
            size={16}
          />

          <ProjectName>
            {query.project.name}
          </ProjectName>
        </HeaderLeft>

        <HeaderRight>
          <Infos>
            {projectInfos(query.project.progress, query.project.issues.length)}
          </Infos>
          {menu}
        </HeaderRight>
      </PreviewHeader>

      {isExpanded && (
        <>
          <PreviewProjectIssues />
          <ProjectLink href={query.project.url} target="_blank" rel="noreferrer">
            <ArrowRightUpIcon size={12} />
            View more in Linear
          </ProjectLink>
        </>
      )}
    </PreviewContainer>
  );
};

const iconStyle = (height: number) => ({
  height,
  width: 18,
});
