import { useLazyQuery } from '@apollo/client';
import {
  IntegrationType,
  SearchNotionPagesDocument,
  NotionMinimalPageFragment,
  NotionMinimalDatabaseFragment,
} from '@cycle-app/graphql-codegen/generated';
import { useState } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';

interface State {
  searchText: string | undefined;
  results: (NotionMinimalPageFragment | NotionMinimalDatabaseFragment | undefined | null)[] | undefined;
}

export const useSearchNotion = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Notion);
  const [searchPagesResult, setSearchPageResult] = useState<State>({
    searchText: '',
    results: [],
  });
  const integrationId = integration?.id;
  const isInstalled = !!integration?.provider;

  const [refetchSearchPagesQuery, {
    loading: isLoading, variables: searchPagesVariables,
  }] = useLazyQuery(SearchNotionPagesDocument, {
    onCompleted(data) {
      // We need to sync the result with the corresponding search
      // as in the UI, we display: "no results for {searchText}".
      setSearchPageResult({
        searchText: searchPagesVariables?.searchText,
        results: (data?.node?.__typename === 'Integration' &&
          data.node.provider?.__typename === 'Notion' &&
          data.node.provider.search) || [],
      });
    },
  });

  const searchPages = (searchText: string) => (integrationId && refetchSearchPagesQuery({
    variables: {
      integrationId,
      searchText,
    },
  })) || null;

  return {
    isInstalled,
    isLoading,
    searchPages,
    searchPagesResult,
  };
};
