import { ActionButton, Shortcut, typo, Input } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div<{ $withSection: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 24px;
  padding-right: 24px;
  min-height: 60px;
  justify-content: center;
  background-color: ${p => p.theme.colors.commandbar.input.bg};
  border-bottom: 1px solid ${p => p.theme.colors.border.surprisingGreyDark};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Section = styled.div`
  top: 16px;
  left: 16px;
  display: flex;
`;

export const Loading = styled.div`
  position: absolute;
  right: 16px;
  bottom: 20px;
  opacity: 0.1;

  ${mobile} {
    display: none;
  }
`;

export const ShortcutStyled = styled(Shortcut)`
  position: absolute;
  right: 16px;
  bottom: 20px;
`;

export const LogoContainer = styled.div`
  display: flex;
  color: ${p => p.theme.colors.commandbar.input.icon};
`;

export const SearchInput = styled.input`
  width: 100%;
  color: ${p => p.theme.colors.commandbar.input.color};
  background-color: inherit;
  font-size: 18px;
  outline: none;
  border: none;
  ${typo.headerLight};

  ::placeholder {
    color: ${p => p.theme.colors.commandbar.input.placeholder};
  }
`;

export const TagStyled = styled(ActionButton)`
  border: 0;
  background-color: ${p => p.theme.colors.commandbar.tag.bg};
  color: ${p => p.theme.colors.commandbar.tag.color};
  gap: 8px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;

  svg {
    color: ${p => p.theme.colors.actionButton.color};
    height: 10px;
    width: 10px;
  }
`;

export const MobileInput = styled(Input)`
  margin: 20px 16px 8px;
  input {
    border-radius: 8px;
  }
`;
