import { css } from 'styled-components';

const duration = '.15s';
const easing = 'ease-in-out';

const getTransition = (properties: string[]) => properties.map(property => `${property} ${duration} ${easing}`).join(',');

export const transitionCss = (properties = ['color', 'background-color', 'opacity']) => css`
  transition: ${getTransition(properties)};
`;
