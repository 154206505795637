import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { QuoteIcon } from '@cycle-app/ui/icons';

import DocAction from 'src/components/DocAction/DocAction';
import { InsightCreate } from 'src/components/InsightCreate';

type Props = {
  feedbackDoc: DocBaseFragment;
};

export const AddQuote = ({ feedbackDoc }: Props) => {
  return (
    <InsightCreate
      feedbackDoc={feedbackDoc}
      dropdownProps={{
        placement: 'bottom-start',
      }}
      button={props => (
        <DocAction
          size="L"
          forceFocus={props.isFocus}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            props.onClick();
          }}
        >
          <QuoteIcon size={14} />
          Add quote
        </DocAction>
      )}
    />
  );
};
