import {
  ChangeBoardConfigViewTypeDocument,
  ViewType,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events, Methods, Objects } from 'src/constants/analytics.constants';
import { useGetDraftBoardConfigFromCache } from 'src/hooks/api/cache/cacheBoardConfig';
import { useBoard } from 'src/hooks/api/useBoard';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';

export default function useChangeViewType(boardConfigId: string, boardID?: string) {
  const [updateViewTypeMutation, { loading }] = useSafeMutation(ChangeBoardConfigViewTypeDocument, {
    onCompleted: () => trackAnalytics(Events.BoardUpdated, {
      method: Methods.UI,
      object: Objects.ToolbarViewType,
    }),
  });
  const board = useBoard(boardID);
  const getDraftBoardConfigFromCache = useGetDraftBoardConfigFromCache(board?.id ?? '');

  const updateViewType = useCallback(async (viewType: ViewType) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();

    return updateViewTypeMutation({
      variables: {
        boardConfigId,
        viewType,
      },
      ...draftBoardConfig && {
        optimisticResponse: {
          changeBoardConfigViewType: {
            __typename: 'BoardConfig',
            id: boardConfigId,
            viewType,
            docQuery: draftBoardConfig.docQuery,
          },
        },
      },
    });
  }, [updateViewTypeMutation, boardConfigId, getDraftBoardConfigFromCache]);

  return {
    updateViewType,
    loading,
  };
}
