import { LocalKey } from 'src/types/localStorage.types';
import { makeBooleanVar } from 'src/utils/makeVar.utils';

export const {
  hook: useChangelogDiscover,
  get: getChangelogDiscover,
  toggle: toggleChangelogDiscover,
  setTrue: setChangelogDiscoverShow,
  setFalse: setChangelogDiscoverHide,
} = makeBooleanVar(true, {
  key: LocalKey.ChangelogDiscover,
  crossTabSync: true,
});
