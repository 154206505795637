import { DocParentFragment } from '@cycle-app/graphql-codegen';
import { forwardRef } from 'react';

import { navigateToDocFullPage } from 'src/hooks';

import { FeatureCardAttributes } from './FeatureCardAttributes';
import { FeatureCardFooter } from './FeatureCardFooter';
import { FeatureTitle } from './FeatureTitle';
import { FeatureCardContainer } from './QuoteCards.styles';

type Props = {
  docParent: DocParentFragment;
  active: boolean;
};

export const FeatureCard = forwardRef<HTMLDivElement, Props>(({
  docParent, active,
}, ref) => {
  return (
    <FeatureCardContainer
      ref={ref}
      $active={active}
      onClick={e => {
        navigateToDocFullPage(docParent, {}, e.metaKey);
      }}
    >
      <FeatureTitle title={docParent.title} />

      <div onClick={e => e.stopPropagation()}>
        <FeatureCardAttributes
          doc={docParent}
        />
      </div>

      <FeatureCardFooter
        doc={docParent}
      />
    </FeatureCardContainer>
  );
});
