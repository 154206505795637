import { typo, Button, ToggleInput, ring } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

const focusCSS = css`
  border-color: hsl(var(--cycle));
  ${ring}
`;
export const SelectedValues = styled.button<{ forceFocus: boolean }>`
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 8px;

  width: 100%;
  ${typo.body400};

  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  ${p => p.forceFocus && focusCSS}
  &:focus {
    ${focusCSS};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.input.bg};
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const OtherDoctypesCount = styled.span`
  color: ${p => p.theme.colors.text.disabled};
`;

export const Caret = styled(CaretIcon)`
  margin-left: auto;
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const LightButton = styled(Button).attrs(p => ({
  variant: p.variant ?? 'ternary',
  size: 'S',
}))`
  color: ${p => p.theme.colors.text.secondary};
  --bg: transparent;
  :hover {
    --bg: ${p => p.theme.colors.boardConfig.button.bg};
  }
  :active {
    --bg: ${p => p.theme.colors.boardConfig.button.bgHover};
  }
  :focus {
    outline: none;
  }
  font-weight: 500;
  width: fit-content;
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const LabelSoon = styled(Label)`
  ${typo.body500};
  gap: 8px;
  margin-bottom: 0;
  color: ${p => p.theme.colors.text.secondary};
`;

export const FiltersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddAnotherFilterBtn = styled(Button).attrs({
  size: 'S',
  variant: 'secondary',
})`
  font-weight: 400;
  margin-top: 8px;
  width: fit-content;
`;

export const GroupByButtons = styled.div<{ $isFullWidth?: boolean }>`
  display: ${p => (p.$isFullWidth ? 'block' : 'flex')};
  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const groupbyButtonFocusCSS = css`
  background-color: ${p => p.theme.colors.background.hover};
  border-color: hsl(var(--cycle));
  outline: 1px solid hsl(var(--cycle));
  outline-offset: -2px;
`;

export const GroupByButton = styled.button<{ forceFocus: boolean; position?: 'left' | 'middle' | 'right' }>`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: transparent;
  padding: 7px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  ${typo.body400};
  overflow: hidden;

  &:hover {
    background-color: ${p => p.theme.colors.background.tertiary};
  }
  ${p => p.forceFocus && groupbyButtonFocusCSS};
  &:focus {
    ${groupbyButtonFocusCSS};
  }
`;

export const StyledToggleInput = styled(ToggleInput).attrs({
  variant: 'secondary',
})`
  margin-top: 10px;
  display: flex;
`;

export const PreventDnd = styled.p`
  ${typo.caption400}
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  gap: 6px;
  margin-top: 8px;

  svg {
    margin-top: -2px;
  }
`;
