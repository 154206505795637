/* eslint-disable no-console */
export const openIntercom = () => {
  if (!window.Intercom) {
    console.warn('Intercom not instanciated');
    return;
  }
  try {
    window.Intercom('show');
  } catch (error) {
    console.group();
    console.warn('Error trying to open Intercom');
    console.warn('Intercom error', error);
    console.warn('Intercom', window.Intercom);
    console.groupEnd();
  }
};
