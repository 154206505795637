/* eslint-disable max-len */
import { FC } from 'react';

import {
  FILL_NONE_CLASS_NAME,
  STROKE_CURRENT_COLOR_CLASS_NAME,
  Svg,
  SvgProps,
} from '../../components/Svg/Svg.styles';

export const OfflineIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 -1 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      d="M4 2L16 14"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${STROKE_CURRENT_COLOR_CLASS_NAME}`}
    />
    <path
      d="M13.9998 12H6.66645C4.93445 12 3.52979 10.6567 3.52979 9C3.52979 7.34333 4.93445 5.99999 6.66645 5.99999C6.74112 5.66666 6.86978 5.35133 7.04512 5.06133M8.44112 3.76266C8.66045 3.64666 8.89445 3.55 9.14112 3.476C10.4078 3.092 11.8058 3.34666 12.8078 4.14266C13.8098 4.93733 14.2645 6.15399 13.9998 7.33333H14.6665C15.148 7.33339 15.6178 7.48246 16.0113 7.76008C16.4048 8.0377 16.7027 8.43027 16.8643 8.88394C17.0258 9.33761 17.043 9.83014 16.9136 10.294C16.7841 10.7578 16.5143 11.1702 16.1411 11.4747"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${FILL_NONE_CLASS_NAME} ${STROKE_CURRENT_COLOR_CLASS_NAME}`}
    />
  </Svg>

);
