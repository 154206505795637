import { typo, Button, ActionButton, boxShadowZ3 } from '@cycle-app/ui';
import styled from 'styled-components';

export const HEADER_PRIMARY_HEIGHT = 60;
export const HEADER_SECONDARY_HEIGHT = 56;

const MobileHeaderContainer = styled.header`
  position: sticky;
  top: 0;
  width: 100vw;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background-color: ${p => p.theme.colors.home.card.bg};
  z-index: 10;
  pointer-events: initial;
`;

export const MobileHeaderHomeContainer = styled(MobileHeaderContainer)<{ $hasShadow?: boolean }>`
  height: ${HEADER_PRIMARY_HEIGHT}px;
  ${p => p.$hasShadow && `
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  `}
`;

export const MobileHeaderDocContainer = styled(MobileHeaderContainer)`
  height: ${HEADER_SECONDARY_HEIGHT}px;
  border-bottom: 1px solid ${p => p.theme.colors.components.MobileHeader.border};
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Middle = styled(Left)`
  justify-content: center;
`;

export const Right = styled(Left)`
  justify-content: flex-end;
  color: ${p => p.theme.colors.text.disabled};

  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CloseButton = styled(Button).attrs({ variant: 'nospace' })`
  ${typo.body500}
  color: ${p => p.theme.colors.components.MobileHeader.closeButton.color};
`;

export const HeaderTitle = styled.h2`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Action = styled(ActionButton)`
  overflow: hidden;
  padding: 8px;
  ${typo.caption400};
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  gap: 6px;
  &:focus, &.force-focus {
    background-color: transparent;
  }
`;

export const StyledActionButton = styled(ActionButton)<{
  highlighted?: boolean;
}>`
  width: 28px;
  height: 28px;
  ${p => p.highlighted && `
    border-radius: 50%;
    box-shadow: 0 0 0 2px hsla(var(--cycle), 0.3);
    background: hsla(var(--cycle), 0.1);
  `}
`;

export const CongratsCard = styled.div`
  position: absolute;
  left: 8px;
  top: calc(100% + 8px);
  width: calc(100% - 16px);
  border-radius: 12px;
  padding: 16px;
  color: ${p => p.theme.colors.components.ProductTourModal.text};
  background: ${p => p.theme.colors.components.ProductTourModal.bg};
  ${boxShadowZ3}
  
  ${typo.body400}
  line-height: 24px;

  [role=heading] {
    ${typo.headerLight}
    margin-bottom: 2px;
  }
`;

export const Count = styled.div`
  ${typo.caption400};
`;
