import {
  UpdateCompanyDocument,
  UpdateCompanyMutationVariables,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCompanyUpdate = () => {
  const [updateCompanyMutation, { loading }] = useSafeMutation(UpdateCompanyDocument, {
    errorPolicy: 'all',
  });

  const updateCompany = (variables: UpdateCompanyMutationVariables) => updateCompanyMutation({
    variables,
  });

  return {
    updateCompany,
    isLoading: loading,
  };
};
