import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GeneralIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.83333 3.91667C6.83333 5.5275 5.5275 6.83333 3.91667 6.83333C2.30584 6.83333 1 5.5275 1 3.91667C1 2.30584 2.30584 1 3.91667 1C5.5275 1 6.83333 2.30584 6.83333 3.91667Z" />
    <path d="M15 3.91667C15 5.5275 13.6942 6.83333 12.0833 6.83333C10.4725 6.83333 9.16667 5.5275 9.16667 3.91667C9.16667 2.30584 10.4725 1 12.0833 1C13.6942 1 15 2.30584 15 3.91667Z" />
    <path d="M12.0833 15C13.6942 15 15 13.6942 15 12.0833C15 10.4725 13.6942 9.16667 12.0833 9.16667C10.4725 9.16667 9.16667 10.4725 9.16667 12.0833C9.16667 13.6942 10.4725 15 12.0833 15Z" />
    <path d="M6.83333 12.0833C6.83333 13.6942 5.5275 15 3.91667 15C2.30584 15 1 13.6942 1 12.0833C1 10.4725 2.30584 9.16667 3.91667 9.16667C5.5275 9.16667 6.83333 10.4725 6.83333 12.0833Z" />
  </Svg>
);
