import { ComponentPropsWithRef, forwardRef } from 'react';

import { OutlinedButton, NakedButton, DefaultButton } from './Button.styles';
import { Variant, Size, Color } from './Button.types';
import { Spinner } from '../Spinner/Spinner.styles';

type ButtonProps = ComponentPropsWithRef<'button'> & {
  active?: boolean;
  color?: Color;
  loading?: boolean;
  size?: Size;
  variant?: Variant;
};

export const ButtonV2 = forwardRef<HTMLButtonElement, ButtonProps>(({
  active = false,
  children,
  color = 'primary',
  loading = false,
  size = 'M',
  variant = 'default',
  ...props
}, ref) => {
  const StyledButton = getStyledButton(variant);
  return (
    <StyledButton
      type="button"
      {...props}
      {...loading && { 'aria-busy': true }}
      $size={size}
      $color={color}
      $active={active}
      ref={ref}
    >
      {!loading && children}
      {loading && (
        <>
          <Spinner />
          <span>{children}</span>
        </>
      )}
    </StyledButton>
  );
});

const getStyledButton = (variant: Variant) => {
  if (variant === 'outlined') return OutlinedButton;
  if (variant === 'naked') return NakedButton;
  return DefaultButton;
};
