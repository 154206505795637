import { DocBaseFragment, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Tag, Tooltip, Skeleton } from '@cycle-app/ui';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { AnimatePresence, Variants } from 'framer-motion';
import { useEffect, useReducer, useRef } from 'react';

import { ReleaseNoteDraftDropdown } from 'src/components/ReleaseNotes/ReleaseNoteDraftTag';
import { ReleasesDropdown } from 'src/components/ReleasesDropdown';
import { useNavigate, useOptimizedBooleanState } from 'src/hooks';
import { useUpdateReleaseNote } from 'src/hooks/releases/useUpdateReleaseNote';
import { useGetPermission } from 'src/reactives';
import { useGetDocIdPreview } from 'src/reactives/docPreview.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { Layer } from 'src/types/layers.types';

import {
  StatusButton, ReadyBullet, DraftBullet, PreviewModal, PreviewContainer, PreviewHeader, StatusBullet,
} from './DocReleaseNote.styles';
import { ReleaseNoteEditor } from '../ReleaseNote/ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from '../ReleaseNote/ReleaseNoteEditorTitle';

export const DocReleaseNote = ({
  releaseNote, isPreviewEnabled,
}: {
  releaseNote: DocBaseFragment['releaseNote'];
  isPreviewEnabled?: boolean;
}) => {
  const { canUpdateRelease } = useGetPermission();
  const { navigateToReleaseNote } = useNavigate();
  const { docIdPreview } = useGetDocIdPreview();
  const pointerX = useRef(0);
  const [isPreviewVisible, {
    setFalseCallback: closePreview,
    setTrueCallback: showPreview,
  }] = useOptimizedBooleanState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useReducer(() => true, false);

  useEffect(() => {
    if (!isPreviewEnabled || !docIdPreview || !isPreviewVisible) return;
    closePreview();
  }, [isPreviewEnabled, docIdPreview, isPreviewVisible, closePreview]);

  useEffect(() => {
    const trackPointerX = (e: MouseEvent) => {
      pointerX.current = e.pageX;
    };
    window.addEventListener('mousemove', trackPointerX);
    window.addEventListener('blur', closePreview);
    return () => {
      window.removeEventListener('mousemove', trackPointerX);
      window.removeEventListener('blur', closePreview);
    };
  }, [closePreview]);

  const {
    updateReleaseNote, isUpdatingReleaseNote,
  } = useUpdateReleaseNote();
  if (!releaseNote) return null;

  const isReadonly = releaseNote.release.publicStatus === ReleasePublicStatus.Published;
  return (
    <>
      <ReleasesDropdown
        releaseId={releaseNote.release.id}
        releaseNoteId={releaseNote.id}
        placement="bottom-start"
        withWrapper={false}
        withPortal
        onChange={releaseId => {
          if (!releaseNote) return;
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          updateReleaseNote(releaseNote, { releaseId });
        }}
        button={props => (
          <Tag
            color="grey"
            tooltip={{
              placement: 'top',
              withPortal: true,
              content: 'Release date',
              withWrapper: false,
              disabled: !releaseNote.release.date,
            }}
            start={(
              <ReleaseNoteStatus
                releaseNoteId={releaseNote.id}
                isPublished={releaseNote.isPublished}
                isDisabled
                publicStatus={releaseNote.release.publicStatus}
              />
            )}
            // eslint-disable-next-line no-nested-ternary
            onClick={isReadonly
              ? undefined
              : canUpdateRelease
                ? props.onClick
                : () => setLimitationsModal({ action: 'RELEASE_UPDATE' })}
            onClickLink={() => navigateToReleaseNote(releaseNote.release.id, releaseNote.id)}
            linkTooltip="Open in releases"
            {...isPreviewEnabled && {
              onMouseEnter: showPreview,
              onMouseLeave: closePreview,
            }}
          >
            {(() => {
              if (isUpdatingReleaseNote) return <Skeleton height={10} width={70} />;
              if (!releaseNote.release.date) return 'No release';
              return toShortLocaleDateString(releaseNote.release.date);
            })()}
          </Tag>
        )}
      />

      <AnimatePresence>
        {isPreviewEnabled && isPreviewVisible && (
          <PreviewModal
            hide={closePreview}
            $alignLeft={window.innerWidth - pointerX.current <= window.innerWidth / 2}
            noMask
            disableOverlayPointerEvents
            layer={Layer.ModalZ3}
            motionVariants={modalVariants}
            onAnimationComplete={def => {
              if (def === 'enter') setIsAnimationComplete();
            }}
          >
            <PreviewContainer>
              <PreviewHeader>
                {isAnimationComplete && <ReleaseNoteEditorTitle releaseNoteId={releaseNote.id} isReadOnly />}
              </PreviewHeader>
              {isAnimationComplete && <ReleaseNoteEditor releaseNoteId={releaseNote.id} isReadOnly />}
            </PreviewContainer>
          </PreviewModal>
        )}
      </AnimatePresence>
    </>
  );
};

const ReleaseNoteStatus = ({
  releaseNoteId, isPublished, isDisabled, publicStatus,
}: {
  releaseNoteId: string;
  isPublished: boolean;
  isDisabled: boolean;
  publicStatus: ReleasePublicStatus | null;
}) => {
  const { canUpdateReleaseNote } = useGetPermission();
  let tooltip = isPublished ? 'Ready' : 'Draft';
  if (publicStatus) {
    if (publicStatus === ReleasePublicStatus.Editing) {
      tooltip = 'Editing';
    }
    if (publicStatus === ReleasePublicStatus.Published) {
      tooltip = 'Published';
    }
    if (publicStatus === ReleasePublicStatus.Unpublished) {
      tooltip = 'Unpublished';
    }
  }
  return (
    <ReleaseNoteDraftDropdown
      noteId={releaseNoteId}
      placement="bottom-start"
      button={props => (
        <Tooltip
          placement="top"
          withPortal
          withWrapper={false}
          content={tooltip}
        >
          <StatusButton
            role="button"
            tabIndex={0}
            $isDisabled={isDisabled}
            data-active={props['data-active']}
            // eslint-disable-next-line no-nested-ternary
            onClick={isDisabled
              ? undefined
              : canUpdateReleaseNote
                ? props.onClick
                : () => setLimitationsModal({ action: 'RELEASE_UPDATE' })}
          >
            {// eslint-disable-next-line no-nested-ternary
              publicStatus
                ? <StatusBullet $publicStatus={publicStatus} />
                : (isPublished ? <ReadyBullet /> : <DraftBullet />)
            }
          </StatusButton>
        </Tooltip>
      )}
    />
  );
};

const modalVariants: Variants = {
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.1,
      delay: 0.6,
    },
  },
  exit: {
    scale: 1,
    opacity: 0,
    transition: {
      duration: 0.15,
      delay: 0,
    },
  },
};
