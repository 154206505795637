import {
  CallSourceType,
  Language,
  RecordingMode,
  RecordingOption,
  UpdateMeetingIntegrationBotNameDocument,
  UpdateMeetingIntegrationRecordingLanguageDocument,
  UpdateMeetingIntegrationRecordingModeDocument,
  UpdateMeetingIntegrationRecordingOptionDocument,
  UpdateMeetingIntegrationSourcesDocument,
} from '@cycle-app/graphql-codegen';

import { useProductBase } from '../api/useProduct';
import { useMeetingsIntegration } from '../integration/useMeetingsIntegration';
import useSafeMutation from '../useSafeMutation';

export const useCallSettingsUpdate = () => {
  const product = useProductBase();
  const { provider } = useMeetingsIntegration();
  const [updateSourcesMutation] = useSafeMutation(UpdateMeetingIntegrationSourcesDocument);
  const [updateRecordingLanguageMutation] = useSafeMutation(UpdateMeetingIntegrationRecordingLanguageDocument);
  const [updateRecordingModeMutation] = useSafeMutation(UpdateMeetingIntegrationRecordingModeDocument);
  const [updateRecordingOptionMutation] = useSafeMutation(UpdateMeetingIntegrationRecordingOptionDocument);
  const [updateBotNameMutation] = useSafeMutation(UpdateMeetingIntegrationBotNameDocument);

  const updateSources = (sources: CallSourceType[]) => (
    !!product?.id && !!provider?.id && updateSourcesMutation({
      optimisticResponse: {
        updateMeetingIntegration: {
          id: provider.id,
          sources,
        },
      },
      variables: {
        productId: product.id,
        sources,
      },
    })
  );

  const updateRecordingLanguage = (recordingLanguage: Language | null) => (
    !!product?.id && !!provider?.id && updateRecordingLanguageMutation({
      optimisticResponse: {
        updateMeetingIntegration: {
          id: provider.id,
          recordingLanguage,
        },
      },
      variables: {
        productId: product.id,
        recordingLanguage,
      },
    })
  );

  const updateRecordingMode = (recordingMode: RecordingMode) => (
    !!product?.id && !!provider?.id && updateRecordingModeMutation({
      optimisticResponse: {
        updateMeetingIntegration: {
          id: provider.id,
          recordingMode,
        },
      },
      variables: {
        productId: product.id,
        recordingMode,
      },
    })
  );

  const updateRecordingOption = (recordingOption: RecordingOption) => (
    !!product?.id && !!provider?.id && updateRecordingOptionMutation({
      optimisticResponse: {
        updateMeetingIntegration: {
          id: provider.id,
          recordingOption,
        },
      },
      variables: {
        productId: product.id,
        recordingOption,
      },
    })
  );

  const updateRecordingBotName = (botName: string) => (
    !!product?.id && !!provider?.id && updateBotNameMutation({
      optimisticResponse: {
        updateMeetingIntegration: {
          id: provider.id,
          botName,
        },
      },
      variables: {
        productId: product.id,
        botName,
      },
    })
  );

  return {
    updateSources,
    updateRecordingLanguage,
    updateRecordingMode,
    updateRecordingOption,
    updateRecordingBotName,
  };
};
