import { useEffect, useRef } from 'react';

type Options = {
  disabled?: boolean;
};

export const useInterval = <T extends VoidFunction>(
  callback: T,
  delay: number,
  options: Options = {},
) => {
  const savedCallback = useRef<T>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (options.disabled) return;

    function tick() {
      savedCallback.current?.();
    }
    const id = setInterval(tick, delay);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [delay, options.disabled]);
};
