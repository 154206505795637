import { make } from 'src/utils/reactives.util';

interface NetworkState {
  isOffline: boolean;
  /**
   * Actions will store the amount of actions attempt by the user in order to
   * shake the toaster each time user is trying to make an action offline
   */
  actionsCount: number;
}

export const {
  hookValue: useGetNetwork,
  setValue: setNetwork,
  getValue: getNetwork,
  resetValue: resetNetwork,
} = make<NetworkState>({
  defaultState: {
    isOffline: false,
    actionsCount: 0,
  },
});
