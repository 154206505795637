import { typo } from '@cycle-app/ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const FOOTER_HEIGHT = 54;

export const MobileFooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 10;
  background-color: ${p => p.theme.colors.components.MobileFooter.bg};
  padding: 9px 16px;
  pointer-events: initial;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 64px;
  border-top: 1px solid ${p => p.theme.colors.components.MobileFooter.border};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ButtonProps = {
  $isActive?: boolean;
  $isHighlight?: boolean;
};

const baseButtonCss = css<ButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  align-items: center;
  border-radius: 8px;
  ${typo.caption500}
  color: ${p => (p.$isActive ? 'hsl(var(--cycle))' : p.theme.colors.text.secondary)};
  padding: 6px 8px 4px;
  ${p => p.$isActive && css`
    color: ${p.theme.colors.text.primary};
    background-color: ${p.theme.colors.components.MobileFooter.bgActive};
  `}
`;

export const ButtonLink = styled(Link) <ButtonProps>`
  ${baseButtonCss}
`;

export const Button = styled.button<ButtonProps>`
  ${baseButtonCss}
  ${p => p.$isHighlight && css`
    background: hsl(var(--cycle));
    color: ${p.theme.colors.text.white};
    width: 32px;
    height: 32px;
  `}
`;
