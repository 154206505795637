import { ThemeType } from '@cycle-app/graphql-codegen';
import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/roboto-mono/latin-400.css';
import { useEffect } from 'react';

import { AppRouter } from 'src/app/AppRouter';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { AppProviders } from 'src/providers';
import { isDevEnv } from 'src/utils/env.util';

import { Analytics } from './Analytics';
import { useGetThemeConfig } from '../reactives/theme.reactive';


const ThemeListener = () => {
  const { colorTheme } = useGetThemeConfig();
  useEffect(() => {
    document.body.classList.toggle('dark', colorTheme === ThemeType.Nightfall);
  }, [colorTheme]);
  return null;
};

export const App = () => (
  <>
    <ThemeListener />
    <AppProviders>
      <ErrorBoundary>
        {!isDevEnv() && <Analytics />}
        <AppRouter />
      </ErrorBoundary>
    </AppProviders>
  </>
);
