import { DoctypeType, MateFragment } from '@cycle-app/graphql-codegen';
import { FC, ReactNode } from 'react';
import { Placement } from 'tippy.js';

import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { AssigneeContent } from './AssigneeContent';
import { AssigneeDropdown, AssigneeDropdownProps } from './AssigneeDropdown';

type DocAssigneeProps = AssigneeDropdownProps & {
  assignee?: MateFragment | null;
  tooltipPlacement?: Placement;
  size?: 'S' | 'M' | 'L';
  showLabel?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  context?: 'doc-item' | 'doc-panel';
  docTypeType: DoctypeType;
  icon?: ReactNode;
};

const DocAssignee: FC<React.PropsWithChildren<DocAssigneeProps>> = ({
  assignee,
  tooltipPlacement = 'top',
  size = 'M',
  showLabel,
  isDisabled = false,
  context,
  docTypeName,
  docTypeType,
  icon,
  ...dropdownProps
}) => {
  const [isDropdownVisible, {
    setTrueCallback: showDropdown,
    setFalseCallback: hideDropdown,
  }] = useOptimizedBooleanState(false);

  if (!docTypeName) return null;

  const content = (
    <AssigneeContent
      assignee={assignee}
      forceFocus={isDropdownVisible}
      isDisabled={isDisabled}
      showLabel={showLabel}
      size={size}
      tooltipPlacement={tooltipPlacement}
      context={context}
      onOpenChange={showDropdown}
      onClick={e => {
        if (isDisabled) return;
        e.stopPropagation();
        e.preventDefault();
        showDropdown();
      }}
      docTypeType={docTypeType}
      icon={icon}
    />
  );

  if (isDropdownVisible) {
    return (
      <AssigneeDropdown
        {...dropdownProps}
        showDropdown={showDropdown}
        hideDropdown={hideDropdown}
        docTypeName={docTypeName}
        docTypeType={docTypeType}
        selectedId={assignee?.id}
      >
        {content}
      </AssigneeDropdown>
    );
  }

  return content;
};

export default DocAssignee;
