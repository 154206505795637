const surroundedCodeBlocks = /^```html\n|```$/g;

// Clean suggestion before display/insert in the content
export const formatSuggestion = (suggestion: string) => {
  return suggestion
    // Suggestion can be surrounded by ```html ``` when the input contains html tags.
    // In instance, try to summerize this content:
    //   paragraph
    //   - bullet
    .replace(surroundedCodeBlocks, '');
};
