import { IntegrationFullFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';

import { useInstallIntegration } from 'src/hooks';

export const InstallIntegrationButton = ({
  integration, onClick,
}: { integration: IntegrationFullFragment; onClick?: VoidFunction }) => {
  const install = useInstallIntegration();
  return (
    <Button
      onClick={async (e) => {
        e.stopPropagation();
        onClick?.();
        if (!integration.type) return;
        await install(integration.type, integration);
      }}
    >
      Install
    </Button>
  );
};
