import { LinearIcon, RightIcon, CameraIcon, MagicBox } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { HELP_URL_AUTOPILOT, HELP_URL_CALL_RECORDING, HELP_URL_DROPZONE, HELP_URL_LINEAR } from 'src/constants/help.constants';
import { useCarousel } from 'src/hooks/useCarousel';

import {
  Header, Title, SliderScroll, SliderItem, Slider, SliderContainer, ItemTitle, Icon, Link, ItemDesc, LeftIcon, SliderNav, DropIconContainer, DropIcon,
} from './HomeViewNews.styles';

const Item = ({
  icon, title, desc, url,
}: { icon: ReactNode; title: string; desc: string; url: string }) => {
  return (
    <SliderItem>
      <Icon>{icon}</Icon>
      <div>
        <ItemTitle>{title}</ItemTitle>
        <ItemDesc>{desc}</ItemDesc>
        <Link href={url} target="_blank" rel="noreferrer">
          Learn more
          <RightIcon />
        </Link>
      </div>
    </SliderItem>
  );
};

export const HomeViewNews = () => {
  const {
    container, handleScroll, handleScrollClick, isScrollableRight, isScrollableLeft, isMobile,
  } = useCarousel();
  return (
    <section>
      <Header>
        <Title>What’s new in Cycle</Title>
        {!isMobile && (
          <div className="flex items-center">
            <SliderNav onClick={() => handleScrollClick('left')} disabled={!isScrollableLeft} size="L"><LeftIcon /></SliderNav>
            <SliderNav onClick={() => handleScrollClick('right')} disabled={!isScrollableRight} size="L"><RightIcon /></SliderNav>
          </div>
        )}
      </Header>
      <SliderScroll onScroll={handleScroll} ref={container}>
        <SliderContainer>
          <Slider>
            <Item
              icon={<DropIconContainer><DropIcon /></DropIconContainer>}
              title="Introducing Drop island"
              desc="Drop images or video/audio/PDF files into the drop zone to create new feedback docs along with their transcript."
              url={HELP_URL_DROPZONE}
            />
            <Item
              icon={<MagicBox width={32} height={32} />}
              title="Your feedback on Autopilot"
              desc="Summarize, find insights, categorize, and mark processed new feedback - automatically. Customizable source by source."
              url={HELP_URL_AUTOPILOT}
            />
            <Item
              icon={<CameraIcon />}
              title="Introducing Call recording"
              desc="Record your calls and automatically fetch their transcripts as Cycle feedback."
              url={HELP_URL_CALL_RECORDING}
            />
            <Item
              icon={<LinearIcon width={32} height={32} />}
              title="Linear integration"
              desc="Map any Cycle feature/bug with a Linear project/issue, mirroring its status in real-time."
              url={HELP_URL_LINEAR}
            />
          </Slider>
        </SliderContainer>
      </SliderScroll>
    </section>
  );
};
