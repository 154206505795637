import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

import { getLastView, useGetLastView } from './lastView.reactive';

type GitHubDataDomain = 'repository' | 'project' | 'statusId' | 'assigneeIds';

interface GithubState {
  lastUsedRepositoryIds: Record<string, string | null>;
  lastUsedProjectIds: Record<string, string | null>;
  lastUsedStatusIds: Record<string, string | null>;
  lastUsedAssigneeIds: Record<string, string[]>;
}

export const {
  hookValue: useGetGithub,
  getValue: getGithub,
  setValue: setGithub,
} = make<GithubState>({
  defaultState: {
    lastUsedRepositoryIds: {},
    lastUsedProjectIds: {},
    lastUsedStatusIds: {},
    lastUsedAssigneeIds: {},
  },
  localKey: LocalKey.LastUsedGitHubRepository,
});

export const setLastGitHubDataUsed = (id: string | null, domain: GitHubDataDomain) => {
  const {
    lastUsedRepositoryIds, lastUsedProjectIds, lastUsedStatusIds,
  } = getGithub();
  const { productSlug } = getLastView();

  if (!id || !productSlug) return;

  if (domain === 'repository') {
    setGithub({
      lastUsedRepositoryIds: {
        ...lastUsedRepositoryIds,
        [productSlug]: id,
      },
    });
  }
  if (domain === 'project') {
    setGithub({
      lastUsedProjectIds: {
        ...lastUsedProjectIds,
        [productSlug]: id,
      },
    });
  }
  if (domain === 'statusId') {
    setGithub({
      lastUsedStatusIds: {
        ...lastUsedStatusIds,
        [productSlug]: id,
      },
    });
  }
};

export const setLastGitHubAssigneesUsed = (id: string[]) => {
  const { lastUsedAssigneeIds } = getGithub();
  const { productSlug } = getLastView();
  if (!id || !productSlug) return;
  setGithub({
    lastUsedAssigneeIds: {
      ...lastUsedAssigneeIds,
      [productSlug]: id,
    },
  });
};

type UseLastGitHubDataUsedParams = {
  domain: GitHubDataDomain;
};
export const useLastGitHubDataUsed = (options: UseLastGitHubDataUsedParams) => {
  const state = useGetGithub();
  const { productSlug } = useGetLastView();

  if (productSlug == null) return null;

  let key: keyof GithubState | null = null;
  if (options.domain === 'repository') key = 'lastUsedRepositoryIds';
  if (options.domain === 'project') key = 'lastUsedProjectIds';
  if (options.domain === 'statusId') key = 'lastUsedStatusIds';

  return key ? state[key][productSlug] : null;
};

export const useLastGitHubAssigneeUsed = () => {
  const state = useGetGithub();
  const { productSlug } = useGetLastView();
  if (productSlug == null) return null;
  return state.lastUsedAssigneeIds[productSlug];
};
