import styled from 'styled-components';

import { body500 } from '../../theme/typo';

export const Root = styled.div`
  padding: 16px;
  border-radius: 6px;

  .rcp-root {
    --rcp-background-color: transparent;
  }

  .rcp-saturation {
    border-radius: 6px;
  }

  .rcp-body {
    padding: 16px 0 0;
  }

  .rcp-body .rcp-section {
    gap: 16px;
  }

  [data-alpha]:hover::before {
    ${body500}
    background: ${p => p.theme.colors.tooltip.bg};
    border-radius: 6px;
    bottom: 100%;
    color: ${p => p.theme.colors.tooltip.color};
    content: attr(data-alpha);
    left: 50%;
    padding: 4px 8px;
    position: absolute;
    text-align: center;
    transform: translate(-50%, -8px);
  }
`;

export const SaveTitle = styled.div`
  ${body500}
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const SaveList = styled.ul`
  display: flex;
  gap: 12px;
  margin-top: 16px;
`;

interface SavedColorButtonProps {
  $isActive?: boolean;
}

export const SavedColorButton = styled.button<SavedColorButtonProps>`
  border-radius: 999px;
  color: transparent;
  cursor: pointer;
  height: 16px;
  position: relative;
  width: 16px;

  ${p => p.$isActive && `
    &::before {
      border-radius: 999px;
      border: .67px solid ${p.theme.baseColors.Grey700};
      content: '';
      height: 24px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
    }
  `}
`;
