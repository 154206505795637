import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { EyeIcon, EyeClosedIcon } from '@cycle-app/ui/icons';

import { useUpdateReleaseBugAndImprovements } from '../../hooks/releases/useUpdateReleaseBugAndImprovements';

export type ReleaseBugImprovementsVisibilityProps = {
  publicStatus: ReleasePublicStatus;
  releaseId: string;
  showBugAndImprovements: boolean;
};

export const ReleaseBugImprovementsVisibility = ({
  publicStatus, releaseId, showBugAndImprovements,
}: ReleaseBugImprovementsVisibilityProps) => {
  const { updateRelease } = useUpdateReleaseBugAndImprovements();

  let tooltip = '';
  if (publicStatus === ReleasePublicStatus.Published) {
    tooltip = showBugAndImprovements ? 'Visible from changelog' : 'Hidden from changelog';
  } else {
    // Display the action
    tooltip = showBugAndImprovements ? 'Hide from changelog' : 'Show from changelog';
  }

  return (
    <ActionButton
      tooltip={tooltip}
      tooltipPlacement="top"
      disabled={publicStatus === ReleasePublicStatus.Published}
      size="XL"
      onClick={() => updateRelease({
        id: releaseId,
        showBugAndImprovements: !showBugAndImprovements,
      })}
    >
      {showBugAndImprovements ? <EyeIcon /> : <EyeClosedIcon />}
    </ActionButton>
  );
};
