import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';

import { ACTIVE_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { useInstallIntegration, useProductIntegrations } from 'src/hooks';
import { useGetIntegrationPermission, useGetPermission } from 'src/reactives';
import { openSourcesModal } from 'src/reactives/integrations.reactive';

import { Container, AvailableSources, Text, Title, Buttons } from './CollectMoreFeedback.styles';

export const CollectMoreFeedback = () => {
  const {
    sourcesByStatus, getIntegration,
  } = useProductIntegrations();
  const { canReadSettings } = useGetPermission();
  const { canAddIntegration } = useGetIntegrationPermission();
  const install = useInstallIntegration();
  const slackIntegration = getIntegration(IntegrationType.Slack);
  const isSlackInstalled = !!slackIntegration?.provider;

  const sources = sourcesByStatus.uninstalled.filter(source => ACTIVE_INTEGRATIONS.includes(source.integrationType));

  if (!canReadSettings || !canAddIntegration || !sources.length) return null;

  return (
    <Container>
      <AvailableSources>
        {sources.map(source => {
          const data = integrationsDataMap[source.integrationType];
          return <div key={source.integrationType}>{data.icon}</div>;
        })}
      </AvailableSources>

      <Text>
        <Title>Collect more feedback</Title>
        <p>Improve your feedback system with more sources</p>
      </Text>

      <Buttons>
        {!isSlackInstalled && (
          <Button
            size="M"
            variant="outlined-alt"
            onClick={() => install(IntegrationType.Slack, slackIntegration)}
          >
            Install slack
          </Button>
        )}

        <Button
          size="M"
          variant="outlined-alt"
          onClick={openSourcesModal}
        >
          Add sources
        </Button>
      </Buttons>
    </Container>
  );
};
