import { make } from 'src/utils/reactives.util';

export const {
  hookState: useDnd,
  hookValue: useGetDnd,
  getValue: getDnd,
  setValue: setDnd,
} = make({
  defaultState: {
    enabled: true,
  },
});
