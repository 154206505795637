import { Input, Tooltip, LinearProjectIcon, TextHighlighter, ToggleInput } from '@cycle-app/ui';
import { SearchIcon } from '@cycle-app/ui/icons';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { usePrefetchLinearIssues } from 'src/hooks/api/queries/integrations/useLinearIssues';
import { useLinearProjects } from 'src/hooks/api/queries/integrations/useLinearProjects';
import {
  useGetLinearImportModal, setLinearImportNextStep,
  useGetLinearImportDocTypes, setLinearImportDocTypes,
  useGetLinearImportSelection, setLinearImportSelection,
} from 'src/reactives/linearImport.reactive';
import { issuesCount } from 'src/utils/linear.utils';

import { LinearImportHeader } from './LinearImportHeader';
import {
  Content, Separator, Scrollable, Line,
  Label, Name, InfoCaption, Info, InfoIcon,
  ImportButton, SkipButton,
} from './LinearImportModal.styles';
import { docsData } from './LinearImportModal.utils';
import { LinearImportSkeletons } from './LinearImportSkeletons';
import { SelectDocType } from './SelectDocType';

export const LinearImportProjects = () => {
  const { isSkippable } = useGetLinearImportModal();
  const { selectedProjectIds } = useGetLinearImportSelection();
  const { projectsDocTypeId } = useGetLinearImportDocTypes();
  const integrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useDebouncedCallback(setSearchText, INPUT_ONCHANGE_DEBOUNCE);
  const query = useLinearProjects(integrationId, searchText);
  const isLoading = query.isLoading && query.projects.length === 0;
  const selectedCount = Object.values(selectedProjectIds ?? {}).filter(Boolean).length;

  const prefetchIssues = usePrefetchLinearIssues(integrationId);
  useEffect(() => {
    prefetchIssues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save project names and links
  useEffect(() => {
    for (const project of query.projects) {
      docsData.set(project.id, {
        title: project.name ?? '',
        automationLink: project.url ?? '',
      });
    }
  }, [query.projects]);

  const isAllChecked = query.projects.length > 0 && query.projects.every(project => selectedProjectIds.includes(project.id));

  return (
    <Content>
      <LinearImportHeader />

      <Separator />

      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center">
          <Label style={{ flex: 'none' }}>Import as</Label>
        </div>

        <SelectDocType
          value={projectsDocTypeId}
          onChange={id => setLinearImportDocTypes({ projectsDocTypeId: id })}
        />
      </div>

      <Separator />

      <Input
        autoFocus
        iconBefore={<SearchIcon size={14} />}
        placeholder="Search"
        onChange={e => setSearchTextDebounced(e.target.value)}
      />

      <Line
        onClick={e => {
          e.stopPropagation();
          setLinearImportSelection({
            selectedProjectIds: !isAllChecked
              ? query.projects.map(project => project.id)
              : [],
          });
        }}
      >
        <div className="flex items-center gap-2">
          <Label>
            Select all

            <InfoCaption>
              {getProjectsLabel(query.projects.length)}
            </InfoCaption>

          </Label>
          {query.projects.length >= 50 && (
            <Tooltip
              content="We can only show 50 projects at once"
              placement="top"
              withWrapper={false}
            >
              <InfoIcon />
            </Tooltip>
          )}
        </div>

        <ToggleInput
          id="import-linear-select-all"
          disabled={query.projects.length === 0}
          checked={isAllChecked}
          onClick={e => e.stopPropagation()}
          onChange={() => {}}
          variant="secondary"
        />
      </Line>

      <Separator style={{ margin: '-8px 0' }} />

      <Scrollable>
        {isLoading && <LinearImportSkeletons />}
        {query.projects.map(project => (
          <Line
            style={{ opacity: query.isFetching ? 0.5 : 1 }}
            key={project.id}
            onClick={() => {
              setLinearImportSelection({
                selectedProjectIds: selectedProjectIds.includes(project.id)
                  ? selectedProjectIds.filter(id => id !== project.id)
                  : [...selectedProjectIds, project.id],
              });
            }}
          >
            <Label>
              <LinearProjectIcon
                icon={project.icon}
                color={project.color}
                size={14}
              />
              <Name>
                <TextHighlighter
                  searchWords={[searchText]}
                  textToHighlight={project.name ?? ''}
                  className="highlight"
                />
              </Name>
              <Info>{issuesCount(project.issues.length)}</Info>
            </Label>

            <ToggleInput
              id={`import-${project.id}`}
              checked={selectedProjectIds.includes(project.id)}
              onClick={e => e.stopPropagation()}
              onChange={() => {}}
              variant="secondary"
            />
          </Line>
        ))}
      </Scrollable>

      <ImportButton
        disabled={selectedCount === 0}
        onClick={() => {
          setLinearImportNextStep();
        }}
      >
        {selectedCount > 0 ? `Import ${getProjectsLabel(selectedCount)}` : 'Import'}
      </ImportButton>

      {isSkippable && (
        <SkipButton
          onClick={() => {
            setLinearImportSelection({ selectedProjectIds: [] });
            setLinearImportNextStep();
          }}
        >
          Skip
        </SkipButton>
      )}
    </Content>
  );
};

const getProjectsLabel = (count: number) => {
  if (count === 0) return 'No projects';
  if (count === 1) return '1 project';
  return `${count} projects`;
};
