import { DropdownCss, ShyScrollbarCss, typo, SelectLine } from '@cycle-app/ui';
import { VIDEO_ICON_CLASS_NAME, AUDIO_ICON_CLASS_NAME } from '@cycle-app/ui/constants/icons.constants';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

const containerPadding = 12;

export const Container = styled.div<{ large: boolean }>`
  ${DropdownCss};
  ${ShyScrollbarCss}
  overflow: auto;
  overflow: overlay; /* Wekbit and Blink based browsers */
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: ${containerPadding}px;
  min-width: ${p => (p.large ? 390 : 268)}px;
  max-height: max(310px, calc(50vh - 40px));

  ${mobile} {
    min-width: 250px;
    max-height: 190px;
  }
`;

export const Category = styled.div`
  & + & {
    margin-top: 12px;
  }
`;

export const CategoryTitle = styled.h3`
  margin-bottom: 8px;
  text-transform: uppercase;
  ${typo.caption500};
  color: ${p => p.theme.colors.text.disabled};
`;

export const Actions = styled.div`
  margin-left: -${containerPadding}px;
  margin-right: -${containerPadding}px;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledSelectLine = styled(SelectLine)`
  padding-left: ${containerPadding}px;
  padding-right: ${containerPadding}px;

  svg.${VIDEO_ICON_CLASS_NAME}, svg.${AUDIO_ICON_CLASS_NAME} {
   stroke: ${p => p.theme.colors.text.primary};
   fill: transparent;
   color: transparent;
  }
`;

export const TranscriptLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
