import { getMentionDocExtension as getExtension } from '@cycle-app/editor-extensions';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import { FC } from 'react';

import DocMentionView from 'src/components/Editor/NodeViews/DocMention/DocMentionView';
import { Events } from 'src/constants/analytics.constants';
import { COMMANDS } from 'src/constants/editor.constants';
import { pastedDocPlugin } from 'src/plugins/pastedDocPlugin';
import { suggestionPlugin } from 'src/plugins/suggestionPlugin';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { renderMentionDropdown } from 'src/utils/mention.utils';

import MentionDocDropdown from './MentionDocDropdown';

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

interface MentionDocParams {
  readOnly?: boolean;
  onDocMentioned?: (docId: string) => void;
  isMobile?: boolean;
}

export const getMentionDocExtension = ({
  readOnly = false,
  onDocMentioned,
  isMobile,
}: MentionDocParams = {}) => getExtension().extend<MentionOptions>({
  addOptions(): MentionOptions {
    return {
      ...this.parent?.(),
      suggestion: {
        char: COMMANDS.DOC_MENTION,

        allowSpaces: true,

        command: ({
          editor,
          range,
          props,
        }) => {
          if (!props.id) return;
          trackAnalytics(Events.DocMention);
          onDocMentioned?.(props.id);
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();
        },

        allow: ({
          editor,
          range,
        }) => editor.can().insertContentAt(range, { type: this.name }),

        render: () => renderMentionDropdown(MentionDocDropdown as FC<React.PropsWithChildren<unknown>>, {
          isMobile,
          readOnly,
        }),
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(DocMentionView);
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({
        tr, state,
      }) => {
        let isMention = false;
        const { selection } = state;
        const {
          empty, anchor,
        } = selection;

        if (!empty) {
          return false;
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true;
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize);

            return false;
          }
          return false;
        });

        return isMention;
      }),
    };
  },

  addProseMirrorPlugins() {
    return [
      suggestionPlugin({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKeyName: `suggestion-${this.name}`,
      }),
      pastedDocPlugin({
        editor: this.editor,
        extensionName: this.name,
      }),
    ];
  },
});
