import { ShyScrollbarCss } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { ActionButtonStyled } from 'src/components/DocAction/DocAction.styles';
import { PortalModal } from 'src/components/PortalModal';
import { boldCss, paragraphCss, h1Css, h2Css } from 'src/styles';

export const Modal = styled(PortalModal)`
  width: 556px;
  min-height: 36px;
  max-height: 70vh;
  padding: 24px;
  position: relative;

  ${mobile} {
    border-radius: 0px;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }

  &,
  * {
    user-select: text;
  }

`;

export const Button = styled(ActionButtonStyled)`
  padding: 0 4px;
`;

export const Summary = styled.div`
  ${ShyScrollbarCss}
  overflow-y: auto;

  strong {
    ${boldCss}
  }
  p {
    ${paragraphCss}
  }
  h1 {
    ${h1Css}
  }
  h2 {
    ${h2Css}
  }
  ul {
    margin: 6px 0;
    list-style-type: disc;
    box-sizing: border-box;
    padding-left: 24px;
  }
  li {
    box-sizing: border-box;
    padding-left: 4px;
  }
`;

export const Actions = styled.div`
  position: absolute;
  display: flex;
  gap: 12px;
  top: 24px;
  right: 24px;
`;
