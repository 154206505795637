import { CreateBoardLinkDocument, RemoveBoardLinkDocument } from '@cycle-app/graphql-codegen';

import { setLimitationsModal, useGetPermission } from '../../reactives';
import useSafeMutation from '../useSafeMutation';
import { useBoardLinkCache } from './useBoardLinkCache';

export const useBoardLink = () => {
  const [createBoardLinkMutation] = useSafeMutation(CreateBoardLinkDocument);
  const [removeBoardLinkMutation] = useSafeMutation(RemoveBoardLinkDocument);
  const {
    addBoardLinkInCache, removeBoardLinkFromCache,
  } = useBoardLinkCache();
  const {
    canUpdateView, canCreateView,
  } = useGetPermission();

  const createBoardLink = (variables: { boardId: string; sectionId: string }) => {
    if (!canCreateView) {
      setLimitationsModal({ action: 'VIEW_CUSTOM_CREATE' });
      return null;
    }
    return createBoardLinkMutation({
      variables,
      optimisticResponse: {
        createBoardLink: {
          id: 'temp-id',
          position: 0,
          board: {
            id: variables.boardId,
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.createBoardLink?.id) {
          addBoardLinkInCache(cache, {
            boardLinkId: data.createBoardLink.id,
            sectionId: variables.sectionId,
          });
        }
      },
    });
  };

  const removeBoardLink = (variables: { boardLinkId: string }) => {
    if (!canUpdateView) {
      setLimitationsModal({ action: 'VIEW_UPDATE' });
      return null;
    }
    return removeBoardLinkMutation({
      variables,
      optimisticResponse: {
        removeBoardLink: {
          id: variables.boardLinkId,
        },
      },
      update: (cache, { data }) => {
        if (data?.removeBoardLink?.id) {
          removeBoardLinkFromCache(cache, { boardLinkId: data.removeBoardLink.id });
        }
      },
    });
  };

  return {
    createBoardLink,
    removeBoardLink,
  };
};
