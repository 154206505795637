import { Tooltip } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { closeRoadmapsTemplateModal, useGetRoadmaps } from 'src/reactives/roadmaps.reactive';
import { Layer } from 'src/types/layers.types';

import { RoadmapConfirmModal } from './RoadmapConfirmModal';
import { RoadmapTemplate } from './RoadmapTemplate';
import { templates } from './roadmapTemplates.data';
import {
  Modal, Header, HeaderContent, Title, HeaderActions, Button,
  Subtitle, Content, IconContainer,
} from './RoadmapTemplatesModal.styles';

export const RoadmapTemplatesModal = () => {
  const { canCreateView } = useGetPermission();
  const { isTemplateModalOpen } = useGetRoadmaps();
  const [selected, setSelected] = useState(templates[0]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  if (!isTemplateModalOpen) return null;
  return (
    <>
      <Modal
        layer={Layer.ModalZ3}
        hide={closeRoadmapsTemplateModal}
      >
        <Header>
          <HeaderContent>
            <Title>
              <p>Advanced roadmaps</p>
            </Title>
            <div>
              <Subtitle>
                <p>Pick your favorite roadmap hierarchy</p>
                <Tooltip
                  placement="right"
                  withWrapper={false}
                  content="Note that it's just starting point, you will be able to update your configuration later."
                >
                  <IconContainer>
                    <InfoIconOutline />
                  </IconContainer>
                </Tooltip>
              </Subtitle>
            </div>
          </HeaderContent>

          <HeaderActions>
            <Button
              size="M"
              variant="outlined-alt"
              onClick={closeRoadmapsTemplateModal}
            >
              Cancel
            </Button>

            <Button
              size="M"
              variant="primary"
              onClick={() => {
                if (!canCreateView) {
                  setLimitationsModal({ action: 'VIEW_CUSTOM_CREATE' });
                  return;
                }
                setIsConfirmModalOpen(true);
              }}
            >
              Confirm
            </Button>
          </HeaderActions>
        </Header>

        <Content>
          {templates.map(template => (
            <RoadmapTemplate
              key={template.type}
              template={template}
              isSelected={template.type === selected?.type}
              onSelect={() => setSelected(template)}
            />
          ))}
        </Content>
      </Modal>

      {isConfirmModalOpen && selected && (
        <RoadmapConfirmModal
          template={selected}
          hide={() => setIsConfirmModalOpen(false)}
          done={() => {
            setIsConfirmModalOpen(false);
            closeRoadmapsTemplateModal();
          }}
        />
      )}
    </>
  );
};
