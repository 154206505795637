import { LinkGithubIssueDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import useSafeMutation from 'src/hooks/useSafeMutation';

type linkParams = {
  variables: {
    docId: string;
    publicId: number;
    repo: string;
  };
};

export const useLinkGithubIssue = () => {
  const { getIntegration } = useProductIntegrations();

  const isSearchActive = !!getIntegration(IntegrationType.Github)?.provider;

  const [linkGithubIssue] = useSafeMutation(LinkGithubIssueDocument);

  const link = useCallback((params: linkParams) => (isSearchActive
    ? linkGithubIssue(params)
    : undefined
  ), [isSearchActive, linkGithubIssue]);

  return {
    link,
  };
};
