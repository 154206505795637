import { make } from 'src/utils/reactives.util';

export interface TemplateState {
  modalVisible: boolean;
  admin: boolean;
  selectedTemplateId: null | string;
  mode: 'list' | 'edit';
  docTypeId?: string;
  forceCloseOnUpdate?: boolean;
  initialView: 'list' | 'creation';
}

export const {
  hookValue: useGetTemplate,
  getValue: getTemplate,
  setValue: setTemplate,
} = make<TemplateState>({
  defaultState: {
    modalVisible: false,
    admin: false,
    selectedTemplateId: null,
    mode: 'list',
    forceCloseOnUpdate: false,
    initialView: 'list',
  },
});
