import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { AddIcon, ExportIcon2, DuplicateIcon } from '@cycle-app/ui/icons';
import { FC, useEffect, useRef, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { InsightsSearch } from 'src/components/InsightsSearch';
import { useOptimizedBooleanState } from 'src/hooks';
import { useDocChildrenSubscription } from 'src/hooks/api/useDocChildrenSubscription';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useDocInsightChildren } from 'src/hooks/insight/useDocInsightChildren';
import { showExportQuotesModal } from 'src/reactives/docPanel.reactive';
import { useGetInsightSuggestions } from 'src/reactives/docRightPanel.reactive';
import { insightName } from 'src/utils/doc.util';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

import { InsightCreateModal } from '../InsightCreateModal';
import { InsightSuggestions } from '../InsightSuggestions';
import { Container, ListContainer } from './InsightChildrenList.styles';
import { InsightsList } from './InsightsList';
import { ActionButtons, AddInsightButton, AddButton, Action } from './InsightsList.styles';
import { copyQuotesToClipboard } from './InsightsList.utils';

interface Props {
  doc: DocBaseFragment;
  doctypeId: string;
}

export const InsightChildrenList: FC<React.PropsWithChildren<Props>> = ({
  doc, doctypeId,
}) => {
  const {
    insights, isLoading, loadMore, pageInfo, isPaginationLoading,
  } = useDocInsightChildren({
    docId: doc.id,
    doctypeId,
  });
  useDocChildrenSubscription(doc.id);
  const [isSearchDropdownVisible, {
    setFalseCallback: hideSearchDropdown,
    setTrueCallback: showSearchDropdown,
  }] = useOptimizedBooleanState(false);
  const doctype = useDoctype(doctypeId);

  const onAddClick = () => {
    requestInsightCreate(showSearchDropdown);
  };

  // Add a bottom border to the list if scrollable
  const listRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    const element = listRef.current?.children[0];
    const checkScrollable = () => {
      if (!(element instanceof HTMLDivElement)) return;
      setIsScrollable(element.scrollHeight > element.clientHeight);
    };
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [insights]);

  const { isCollapsed: isSuggestionsCollapsed } = useGetInsightSuggestions();

  if (!doctype) return null;

  return (
    <Container>
      <ListContainer ref={listRef} $withBorder={isScrollable || isSuggestionsCollapsed}>
        <InsightsList
          cursor={pageInfo?.endCursor}
          hasNextPage={pageInfo?.hasNextPage}
          insights={insights}
          isLoading={isLoading}
          isPaginationLoading={isPaginationLoading}
          isParent
          loadMore={loadMore}
          hideParent
          hideStatus
          modal={(
            <>
              {insights.length > 0 ? (
                <>
                  <ActionButtons>
                    <Action
                      size="L"
                      tooltip="Copy all quotes"
                      tooltipPlacement="top"
                      onClick={() => copyQuotesToClipboard(insights)}
                    >
                      <DuplicateIcon />
                    </Action>
                    <Action
                      tooltip="Export quotes"
                      tooltipPlacement="top"
                      onClick={showExportQuotesModal}
                    >
                      <ExportIcon2 />
                    </Action>
                  </ActionButtons>

                  <div>
                    <InsightsSearch
                      parentId={doc.id}
                      visible={isSearchDropdownVisible}
                      hide={hideSearchDropdown}
                      createModal={modalProps => (
                        <InsightCreateModal
                          hide={modalProps.hide}
                          hideParentDoc
                          isOpen={modalProps.visible}
                          parentDoc={doc}
                          defaultContent={modalProps.quote}
                        >
                          <AddButton
                            variant="light"
                            onClick={onAddClick}
                            forceFocus={isSearchDropdownVisible || modalProps.visible}
                          >
                            <AddIcon size={12} />
                            {`Add ${insightName()}`}
                          </AddButton>
                        </InsightCreateModal>
                      )}
                    />
                  </div>
                </>
              ) : (
                <InsightsSearch
                  parentId={doc.id}
                  visible={isSearchDropdownVisible}
                  hide={hideSearchDropdown}
                  createModal={modalProps => (
                    <InsightCreateModal
                      hide={modalProps.hide}
                      hideParentDoc
                      isOpen={modalProps.visible}
                      parentDoc={doc}
                      defaultContent={modalProps.quote}
                    >
                      <AddInsightButton
                        onClick={onAddClick}
                        forceFocus={isSearchDropdownVisible || modalProps.visible}
                        $isFocus={isSearchDropdownVisible || modalProps.visible}
                        $isFullWidth
                      >
                        {`Add ${insightName()}`}
                      </AddInsightButton>
                    </InsightCreateModal>
                  )}
                />
              )}
            </>
          )}
        />
      </ListContainer>

      <InsightSuggestions
        insightsIds={insights.map(d => d?.doc?.id).filter(isPresent)}
      />
    </Container>
  );
};
