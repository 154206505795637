import { DoctypeFragment, LinearEntityType } from '@cycle-app/graphql-codegen';
import { Emoji, DotsMenu, Spinner } from '@cycle-app/ui';
import type { SelectOption } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useCallback, useMemo, useState } from 'react';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import useDoctypesMutations from 'src/hooks/api/mutations/useDoctypesMutations';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { isCustom } from 'src/utils/docType.util';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import {
  DocTypeContainer,
  DocTypeName,
  DocTypeNameContainer,
  SetupAutomationButton,
  ActiveBadge,
  Select,
  Caret,
} from './IntegrationLinearAutomation.styles';

export const IntegrationLinearAutomation = () => {
  const { docTypes } = useGetDocTypes();
  const displayedDocTypes = Object.values(docTypes).filter(isCustom).reverse();

  return (
    <>
      {displayedDocTypes.map(docType => (
        <DocTypeContainer key={docType.id}>
          <Emoji emoji={docType.emoji} />
          <DocTypeNameContainer>
            <DocTypeName>{docType.name}</DocTypeName>
          </DocTypeNameContainer>
          <LinearAutomationForm docType={docType} />
        </DocTypeContainer>
      ))}
    </>
  );
};

type LinearAutomationFormProps = {
  docType: DoctypeFragment;
};

const LinearAutomationForm = ({ docType }: LinearAutomationFormProps) => {
  const [isFormOpen, setIsFormOpen] = useState(!!docType.linearEntity && !!docType.linearTeam);
  const [updatingField, setUpdatingField] = useState<'entity' | 'team' | null>(null);
  const [isLinearEntityVisible, {
    setFalseCallback: hideLinearEntityDropdown,
    setTrueCallback: showLinearEntityDropdown,
  }] = useOptimizedBooleanState(false);
  const [isLinearTeamVisible, {
    setFalseCallback: hideLinearTeamDropdown,
    setTrueCallback: showLinearTeamDropdown,
  }] = useOptimizedBooleanState(false);
  const {
    teams, isLoading,
  } = useLinearTeams();
  const {
    loading: isUpdateDocTypeLoading,
    updateDoctype,
  } = useDoctypesMutations(docType.id);

  const setAutomation = useCallback(async (team: string, type: LinearEntityType) => {
    try {
      await updateDoctype({
        linearAutomation: {
          team,
          type,
        },
      });
      if (!isFormOpen) {
        setIsFormOpen(true);
      }
      setUpdatingField(null);
    } catch (e) {
      addErrorToaster({ message: 'Oops something went wrong during the automation update, retry later' });
    }
  }, [isFormOpen, updateDoctype]);

  const teamOptions: SelectOption[] = useMemo(() => teams.map(team => ({
    label: team.name,
    value: team.id,
    onSelect: () => {
      if (docType.linearTeam === team.id) {
        hideLinearTeamDropdown();
        return;
      }
      hideLinearTeamDropdown();
      setUpdatingField('team');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setAutomation(team.id, docType.linearEntity || LinearEntityType.Project);
    },
  })), [docType.linearEntity, docType.linearTeam, hideLinearTeamDropdown, setAutomation, teams]);

  const entitiesOptions: SelectOption[] = useMemo(() => ([
    {
      label: 'Project',
      value: LinearEntityType.Project,
      onSelect: () => {
        if (!docType.linearTeam) return;
        if (docType.linearEntity === LinearEntityType.Project) {
          hideLinearEntityDropdown();
          return;
        }
        hideLinearEntityDropdown();
        setUpdatingField('entity');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        setAutomation(docType.linearTeam, LinearEntityType.Project);
      },
    },
    {
      label: 'Issue',
      value: LinearEntityType.Issue,
      onSelect: () => {
        if (!docType.linearTeam) return;
        if (docType.linearEntity === LinearEntityType.Issue) {
          hideLinearEntityDropdown();
          return;
        }
        hideLinearEntityDropdown();
        setUpdatingField('entity');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        setAutomation(docType.linearTeam, LinearEntityType.Issue);
      },
    },
  ]), [docType.linearEntity, docType.linearTeam, hideLinearEntityDropdown, setAutomation]);

  return (
    <>
      {isFormOpen
        ? (
          <>
            <DropdownSelectLayer
              layer={Layer.DropdownModal}
              withPortal
              hideSearch
              options={entitiesOptions}
              visible={isLinearEntityVisible}
              hide={hideLinearEntityDropdown}
              placement="bottom-start"
              panelStyles={{ minWidth: '230px' }}
              selectedValue={docType.linearEntity || undefined}
            >
              <Select
                onClick={isLinearEntityVisible ? hideLinearEntityDropdown : showLinearEntityDropdown}
                type="button"
                forceFocus={false}
                disabled={isUpdateDocTypeLoading}
              >
                {docType.linearEntity === LinearEntityType.Project ? 'Project' : 'Issue'}
                {isUpdateDocTypeLoading && updatingField === 'entity' && ' '}
                {isUpdateDocTypeLoading && updatingField === 'entity' && <Spinner />}
                <Caret direction={isLinearEntityVisible ? 'top' : 'bottom'} />
              </Select>
            </DropdownSelectLayer>
            <DropdownSelectLayer
              layer={Layer.DropdownModal}
              withPortal
              hideSearch
              options={teamOptions}
              visible={isLinearTeamVisible}
              hide={hideLinearTeamDropdown}
              placement="bottom-start"
              panelStyles={{ minWidth: '230px' }}
              selectedValue={docType.linearTeam || undefined}
            >
              <Select
                onClick={isLinearTeamVisible ? hideLinearTeamDropdown : showLinearTeamDropdown}
                type="button"
                forceFocus={false}
                disabled={isUpdateDocTypeLoading}
              >
                {isLoading
                  ? <Spinner />
                  : (
                    <>
                      {teams.find(t => t.id === docType.linearTeam)?.name}
                      {isUpdateDocTypeLoading && updatingField === 'team' && ' '}
                      {isUpdateDocTypeLoading && updatingField === 'team' && <Spinner />}
                    </>
                  )}
                <Caret direction={isLinearTeamVisible ? 'top' : 'bottom'} />
              </Select>
            </DropdownSelectLayer>
            <ActiveBadge />
            <DotsMenu
              placement="bottom-end"
              options={[{
                label: 'Disable automation',
                icon: <CloseIcon size={10} />,
                value: 'remove',
                onSelect: async () => {
                  await updateDoctype({
                    linearAutomation: null,
                  });
                  setIsFormOpen(false);
                },
              }]}
            />
          </>
        )
        : (
          <SetupAutomationButton onClick={() => {
            const team = docType.linearTeam || teams[0]?.id;
            if (!team) return;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            setAutomation(team, docType.linearEntity || LinearEntityType.Project);
          }}
          >
            New automation
            {isUpdateDocTypeLoading && <Spinner />}
          </SetupAutomationButton>
        )}
    </>
  );
};
