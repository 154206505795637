import { useLocationSelector } from 'src/hooks/router/useLocation';
import { getParams } from 'src/utils/routing.utils';

import { closeProfileModal, getProfileIdParam, openProfileModal } from './useProfileModal';

const COMPANY_ID_REGEX = /company_([\w=]+)/;

export const useCompanyModal = () => useLocationSelector(getCompanyIdParam);

export const getCompanyIdParam = () => {
  const profile = getProfileIdParam();
  return profile?.match(COMPANY_ID_REGEX)?.[1];
};

export const closeCompanyModal = closeProfileModal;

export const openCompanyModal = (companyId: string) => {
  // For the same companyId, the modal must not be opened if the full page is already open
  if (getParams().companyId === companyId) {
    closeProfileModal();
    return;
  }
  openProfileModal(`company_${companyId}`);
};
