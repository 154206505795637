import { GoogleMeetIcon, ZoomIcon, MicrosoftTeamsIcon } from '@cycle-app/ui/icons';

import { IconsGroup } from './CallRecordingIcons.styles';

export const CallRecordingIcons = () => (
  <IconsGroup>
    <ZoomIcon />
    <GoogleMeetIcon />
    <MicrosoftTeamsIcon />
  </IconsGroup>
);
