import { StatusCategory } from '@cycle-app/graphql-codegen';
import { z } from 'zod';

import { LocalKey } from 'src/types/localStorage.types';
import { makeBooleanVar, makeVar } from 'src/utils/makeVar.utils';

export const {
  hook: useCompanyTabId,
  set: setCompanyTabId,
} = makeVar('people', {
  key: LocalKey.CompanyTabId,
  schema: z.string(),
});

export const {
  hook: useCustomerTabId,
  set: setCustomerTabId,
} = makeVar('quotes', {
  key: LocalKey.CustomerTabId,
  schema: z.string(),
});

const statusCategorySchema = z.enum([
  StatusCategory.NotStarted,
  StatusCategory.Started,
  StatusCategory.Completed,
  StatusCategory.Canceled,
]).nullable();

export const {
  hook: useCustomerStatusCategory,
  set: setCustomerStatusCategory,
} = makeVar(null, {
  key: LocalKey.CustomerStatusCategory,
  schema: statusCategorySchema,
});

export const {
  hook: useCompanyStatusCategory,
  set: setCompanyStatusCategory,
} = makeVar(null, {
  key: LocalKey.CompanyStatusCategory,
  schema: statusCategorySchema,
});

export const {
  hook: useCustomerExportQuotes,
  setTrue: openCustomerExportQuotes,
  setFalse: closeCustomerExportQuotes,
} = makeBooleanVar(false);

export const {
  hook: useCompanyExportQuotes,
  setTrue: openCompanyExportQuotes,
  setFalse: closeCompanyExportQuotes,
} = makeBooleanVar(false);

export const {
  hook: useCustomerSummary,
  setTrue: openCustomerSummary,
  setFalse: closeCustomerSummary,
} = makeBooleanVar(false);

export const {
  hook: useCompanySummary,
  setTrue: openCompanySummary,
  setFalse: closeCompanySummary,
} = makeBooleanVar(false);
