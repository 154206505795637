import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';

import { useBoardLink } from 'src/hooks/boards/useBoardLink';

import { DropdownLayer } from '../DropdownLayer';
import { CreateLabel } from './SectionViewOptionsSubmenu.styles';

interface Props {
  hideMenu: VoidFunction;
  hideSubmenu: VoidFunction;
  label: string;
  onCreate: VoidFunction;
  options: SelectOption[];
  sidebarSectionId: string;
  visible: boolean;
}

export const SectionViewOptionsSubmenu = ({
  options, visible, hideMenu, hideSubmenu, onCreate, label, sidebarSectionId,
}: Props) => {
  const { createBoardLink } = useBoardLink();
  return (
    <DropdownLayer
      content={(
        <SelectPanel
          hideSearch
          options={options}
          onOptionChange={async ({ value }) => {
            // Hide this view menu.
            hideSubmenu();
            // Hide sections menu.
            hideMenu();
            await createBoardLink({
              boardId: value,
              sectionId: sidebarSectionId,
            });
          }}
          onCreateOption={async () => {
            hideSubmenu();
            hideMenu();
            onCreate();
          }}
          defaultCreateOptionLabel={(
            <CreateLabel>
              <AddIcon />
              {' '}
              Create view
            </CreateLabel>
          )}
          showCreateOption
        />
      )}
      hide={hideSubmenu}
      placement="right-start"
      visible={visible}
      offset={[-5, 30]}
      width={182}
      closingArea={false}
    >
      {label}
    </DropdownLayer>
  );
};
