import { SelectPanel, SelectOption } from '@cycle-app/ui';

import { ToggleDropdown } from '../DropdownLayer';
import { Caret, StyledButton } from './ChangelogBuilderTypography.styles';

export type ChangelogBuilderSelectOption = SelectOption;


export const ChangelogBuilderSelect = ({
  value, options, onChange,
}: {
  options: ChangelogBuilderSelectOption[];
  onChange: (option: ChangelogBuilderSelectOption) => void;
  value: string;
}) => {
  return (
    <ToggleDropdown
      content={contentProps => (
        <SelectPanel
          options={options}
          selectedValue={value}
          onOptionChange={option => {
            onChange(option);
            contentProps.hide();
          }}
          hideSearch
          style={{ width: `${contentProps.buttonRect?.width}px` }}
        />
      )}
      button={(buttonProps) => (
        <StyledButton
          ref={buttonProps.ref}
          onClick={buttonProps.trigger}
          variant="outlined-alt"
          full
          size="L"
          iconEnd={<Caret direction={buttonProps['data-active'] ? 'right' : 'left'} />}
        >
          {options.find(o => o.value === value)?.label}
        </StyledButton>
      )}
    />
  );
};