import { FC } from 'react';

import { Container, Key } from './DocPreviewHint.styles';

interface Props {
  className?: string;
}
const DocPreviewHint: FC<Props> = ({ className }) => (
  <Container className={className}>
    <span>Hold</span>
    <Key>Space</Key>
    <span>on a doc to preview</span>
  </Container>
);

export default DocPreviewHint;
