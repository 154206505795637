import { useSensor, useSensors, MouseSensor } from '@dnd-kit/core';
import type { UniqueIdentifier } from '@dnd-kit/core';

import { TabItem } from './Tabs.types';


type GetDefaultValue = (items: TabItem[], defaultId?: string) => string | null;

export const getDefaultValue: GetDefaultValue = (items, defaultId) => items.find(i => i.id === defaultId)?.id || items[0]?.id || null;

export const useTabsDnDSensors = () => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10, // Require the mouse to move by 10 pixels before activating
    },
  });
  const sensors = useSensors(mouseSensor);

  return sensors;
};

export const convertIdToString = (id: UniqueIdentifier) => (typeof id === 'number' ? id.toString() : id);
