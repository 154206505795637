import { ShyScrollbarCss, boxShadowZ2Z3, typo, Button, ProgressBar } from '@cycle-app/ui';
import { Color, LegacyColor } from '@cycle-app/ui/theme/baseColors';
import { smallHeight } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${p => (p.theme.isDark ? Color.Transparent : Color.Grey100)};
  min-height: 100%;
  padding-bottom: 25vh;
  ${ShyScrollbarCss}
  
  &::-webkit-scrollbar-thumb {  
    background-color: ${LegacyColor.DirtyTransparentGrey};
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${LegacyColor.DirtyTransparentGrey};
      &:hover {
        background-color: ${LegacyColor.DirtyTransparentGrey};
      }
    }
  }
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 32px 60px;
  ${p => !p.theme.isDark && `
    background: url("/images/analyses-preview-light.png") no-repeat center -60px;
  `}
`;

export const PreviewContainer = styled.div`
  display: flex;
  padding: 350px 48px 48px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background-image: ${p => (p.theme.isDark ? 'url("/images/analyses-dark.png")' : 'url("/images/analyses-light.png")')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-radius: 24px;
  width: 674px;
  max-width: 100%;

  ${p => (p.theme.isDark ? `
    border: 1px solid ${Color.Grey850};
  ` : boxShadowZ2Z3
  )}
`;

export const Title = styled.h2`
  ${typo.headerMedium}
  color: ${p => p.theme.colors.text.primary};
`;

type ParagraphProps = {
  $small?: boolean;
};

export const Paragraph = styled.p<ParagraphProps>`
  ${p => (p.$small
    ? typo.caption400
    : typo.body400)}
  color: ${p => p.theme.colors.text.secondary};
  width: 325px;
`;

export const EnableButton = styled(Button).attrs({
  variant: 'primary',
  size: 'M',
})`
  width: 100%;
  max-width: 330px;
`;

export const LastUpdate = styled.div<{ $isDisable: boolean }>`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  line-height: 18px;
  display: inline-flex;
  align-items: center;

  > [data-refresh-button] {
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    border-radius: 4px;
    gap: 4px;
    cursor: ${p => (p.$isDisable ? 'not-allowed' : 'pointer')};

    &:hover {
      background-color: ${p => (p.$isDisable ? 'none' : p.theme.colors.background.hoverSoft)};
    }
  }

  > [data-refresh-button] div {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
`;

export const DashboardContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 85px);

  ${smallHeight} {
    padding-bottom: 10vh;
  }
`;

export const DashboardLoadingState = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
`;

export const InsightsProgressBar = styled(ProgressBar)`
  height: 6px;
  width: 248px;
`;

export const InsightsCount = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Link = styled.a`
  color: ${p => p.theme.colors.text.secondary};
`;
