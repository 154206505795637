import { MeFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { useOptimizedBooleanState } from 'src/hooks';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { InfosForm } from './InfosForm';
import { PasswordForm } from './PasswordForm';

interface Props {
  me: MeFragment;
  isPasswordless: boolean;
}

export const OnboardingStepPassword: FC<React.PropsWithChildren<Props>> = ({
  isPasswordless, me,
}) => {
  const [isPasswordForm, { setTrueCallback: showPasswordForm }] = useOptimizedBooleanState(false);
  return isPasswordForm ? <PasswordForm /> : (
    <InfosForm
      onSuccess={() => {
        if (isPasswordless) {
          setOnboarding({
            screen: LightOnboardingScreen.AccountPreferences,
          });
        } else {
          showPasswordForm();
        }
      }}
      me={me}
    />
  );
};
