import { Events } from 'src/constants/analytics.constants';
import { Analytics, Params, UnknownParams, TrackAnalyticsParams } from 'src/types/segment.types';

declare global {
  interface Window {
    analytics?: Analytics;
  }
}

const sessionId = `${Math.round(Date.now() / 1000)}`;

const integrations = {
  Amplitude: {
    session_id: sessionId,
  },
};

export const identifyAnalytics = (id: string, params: Params): void => {
  if (import.meta.env.VITE_SEGMENT_KEY) {
    window.analytics?.identify(id, params, {
      integrations,
    });
  }
};

export const groupAnalytics = (groupId: string, params: UnknownParams): void => {
  if (import.meta.env.VITE_SEGMENT_KEY) {
    window.analytics?.group(groupId, params);
  }
};

export const trackAnalytics = (
  event: Events,
  params?: TrackAnalyticsParams,
): void => {
  if (import.meta.env.VITE_SEGMENT_KEY) {
    window.requestIdleCallback(() => window.analytics?.track(event, params, { integrations }));
  }
};

type GetScriptElParams = { src: string } | { innerText: string };

const getScriptEl = (params: GetScriptElParams) => {
  const scriptEl = document.createElement('script');
  scriptEl.type = 'text/javascript';
  scriptEl.async = true;
  if ('src' in params) {
    scriptEl.src = params.src;
  }
  if ('innerText' in params) {
    scriptEl.innerText = params.innerText;
  }
  return scriptEl;
};

const insertScript = (scriptEl: HTMLScriptElement | HTMLElement) => {
  const firstScriptEl = document.getElementsByTagName('script')[0];
  firstScriptEl?.parentNode?.insertBefore(scriptEl, firstScriptEl);
};

export const loadAnalytics = (): void => {
  if (import.meta.env.VITE_SEGMENT_KEY) {
    const t = getScriptEl({
      src: `https://cdn.segment.com/analytics.js/v1/${import.meta.env.VITE_SEGMENT_KEY}/analytics.min.js`,
    });
    insertScript(t);
  }
};

export const pageAnalytics = (url?: string): void => {
  if (import.meta.env.VITE_SEGMENT_KEY) {
    window.requestIdleCallback(() => window.analytics?.page(url));
  }
};
