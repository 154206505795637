import { DropdownCss, Spinner } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${DropdownCss};

  padding: 4px 0;
  width: 660px;
`;

export const LoadingState = styled(Spinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  min-height: 16px;
  margin: auto;
`;
