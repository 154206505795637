import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ParentAltIcon = (props: SvgProps) => (
  <Svg width="16" height="16" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentcolor"
      // eslint-disable-next-line max-len
      d="M3.86.9a.5.5 0 0 1 .361-.15m-.36.15L.897 3.864a.508.508 0 0 0 .719.718l2.096-2.096V8.37a2.879 2.879 0 0 0 2.88 2.879h4.149a.508.508 0 0 0 0-1.016h-4.15A1.863 1.863 0 0 1 4.73 8.37V2.485L6.826 4.58a.508.508 0 0 0 .718-.718L4.581.899A.506.506 0 0 0 4.223.75"
    />
  </Svg>
);
