import { useApolloClient, useSubscription } from '@apollo/client';
import { NbAiQueriesDocument, NbAiQueriesSubscription, NbAiQueriesSubscriptionVariables } from '@cycle-app/graphql-codegen';

import { useProduct } from './useProduct';

export const useNbAiQueriesUpdatedSubscription = (productId?: string | null) => {
  const { cache } = useApolloClient();
  const { product } = useProduct('cache-only');

  return useSubscription<NbAiQueriesSubscription, NbAiQueriesSubscriptionVariables>(NbAiQueriesDocument, {
    variables: {
      productId: productId as string,
    },
    onSubscriptionData({ subscriptionData: { data } }) {
      if (!productId || !data || !product) return;
      cache.modify({
        id: cache.identify(product),
        fields: {
          nbAiQueries: () => data?.nbAiQueries,
        },
      });
    },
  });
};
