import { IntegrationType } from '@cycle-app/graphql-codegen';
import { CycleLoader } from '@cycle-app/ui';
import { normalizeString } from '@cycle-app/utilities';
import { useEffect, useCallback } from 'react';

import { INTEGRATIONS } from 'src/constants/integrations.constants';
import { PageId } from 'src/constants/routing.constant';
import { useCreateIntegration } from 'src/hooks/api/mutations/integrations/useCreateIntegration';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useProvider } from 'src/hooks/usePathParams';
import useQueryParams from 'src/hooks/useQueryParams';
import { useGetLastView } from 'src/reactives/lastView.reactive';
import { InstallCodeProviderParams } from 'src/types/integrations.types';

import { setMeetingsModalInstalled } from '../../reactives/meetingsModalInstalled.reactive';

let lastCreatedIntegration: string | null = null;

const IntegrationCallback = () => {
  const provider = useProvider();
  const queryParams = useQueryParams();
  const { productSlug } = useGetLastView();
  const productBase = useProductBase(productSlug);
  const productId = productBase?.id;
  const { navigate } = useNavigateToSettings();
  const {
    createIntegration, isLoading,
  } = useCreateIntegration();

  const onSetupIntegrationError = useCallback((error: unknown) => {
    if (provider) window.opener?.onIntegrationEnd?.[provider]?.('error', error);
  }, [provider]);

  const onSetupIntegrationSuccess = useCallback(() => {
    if (provider) {
      if (window.opener) {
        window.opener.onIntegrationEnd?.[provider]?.('success');
      } else {
        if (provider === 'calendar') {
          setMeetingsModalInstalled({ isOpened: true });
        }
        navigate(PageId.SettingsIntegrations);
      }
    }
  }, [navigate, provider]);

  const installGithubProvider: VoidFunction = useCallback(async () => {
    if (!productId) return;
    try {
      const EXPECTED_SETUP_ACTION = 'install';
      const installationId = Number(queryParams.get('installation_id'));
      const setupAction = queryParams.get('setup_action');

      if (setupAction !== EXPECTED_SETUP_ACTION || Number.isNaN(installationId)) {
        throw new Error(`Error with setup action or installation id: ${setupAction} ${installationId}`);
      }

      const response = await createIntegration({
        productId,
        installationId,
        type: IntegrationType.Github,
      });

      if (response == null || response.data === null) {
        throw new Error('Something went wrong during the installation, make sure to install it on an organization');
      }

      onSetupIntegrationSuccess();
    } catch (error) {
      onSetupIntegrationError(error);
    }
  }, [queryParams, productId]);

  const installProvider: (type: IntegrationType) => void = useCallback(async (type) => {
    if (!productId) return;
    try {
      const installationCode = queryParams.get('code');
      if (!installationCode) throw new Error(`Installation code missing: ${installationCode}`);
      if (isLoading || !!lastCreatedIntegration) return;

      const response = await createIntegration({
        productId,
        installationCode,
        type: type as InstallCodeProviderParams,
        ...type === IntegrationType.Zendesk && {
          subdomain: window.opener.subdomain,
        },
        ...type === IntegrationType.Salesforce && {
          subdomain: window.opener.subdomain,
        },
      });

      if (response == null || response.data === null) throw new Error('Something went wrong during the installation');
      lastCreatedIntegration = Object.keys(response?.data || {}).toString();
      onSetupIntegrationSuccess();
    } catch (error) {
      onSetupIntegrationError(error);
    }
  }, [productId, queryParams, isLoading, createIntegration, onSetupIntegrationSuccess, onSetupIntegrationError]);

  useEffect(() => {
    if (isLoading || !productId) return;
    switch (provider) {
      case IntegrationType.Github.toLowerCase():
        installGithubProvider();
        break;
      case IntegrationType.Linear.toLowerCase():
        installProvider(IntegrationType.Linear);
        break;
      case IntegrationType.Notion.toLowerCase():
        installProvider(IntegrationType.Notion);
        break;
      case IntegrationType.Figma.toLowerCase():
        installProvider(IntegrationType.Figma);
        break;
      case IntegrationType.Intercom.toLowerCase():
        installProvider(IntegrationType.Intercom);
        break;
      case IntegrationType.Hubspot.toLowerCase():
        installProvider(IntegrationType.Hubspot);
        break;
      case IntegrationType.Meeting.toLowerCase():
        installProvider(IntegrationType.Meeting);
        break;
      case IntegrationType.Gong.toLowerCase():
        installProvider(IntegrationType.Gong);
        break;
      case IntegrationType.Zendesk.toLowerCase():
        installProvider(IntegrationType.Zendesk);
        break;
      case IntegrationType.Salesforce.toLowerCase():
        installProvider(IntegrationType.Salesforce);
        break;
      default:
        break;
    }
  }, [provider, installGithubProvider, installProvider, isLoading, productId]);

  const isProviderSupported = !!provider && (provider === 'calendar'
    ? INTEGRATIONS.some(integration => integration.includes(IntegrationType.Meeting))
    : INTEGRATIONS.some(integration => normalizeString(integration).includes(provider)));

  if (!isProviderSupported && provider) {
    window.opener?.onIntegrationEnd?.[provider]?.('error');
  }

  return <CycleLoader />;
};

export default IntegrationCallback;
