import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MediaIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M12.5 7.5V4.16667C12.5 3.24619 11.7538 2.5 10.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V10.8333C2.5 11.7538 3.24619 12.5 4.16667 12.5H7.5M2.5 10L4.0755 8.94967C4.63533 8.57645 5.36467 8.57645 5.9245 8.94967L7.5 10M9.16667 7.5H15.8333C16.7538 7.5 17.5 8.24619 17.5 9.16667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H9.16667C8.24619 17.5 7.5 16.7538 7.5 15.8333V9.16667C7.5 8.24619 8.24619 7.5 9.16667 7.5Z"
      stroke="currentcolor"
      strokeWidth="1.19048"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="5.20832"
      cy="5.20866"
      r="1.04167"
      fill="currentcolor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.8333 13.8961V11.1039C10.8333 10.6181 11.3633 10.318 11.7799 10.5679L14.1068 11.9641C14.5114 12.2068 14.5114 12.7932 14.1068 13.0359L11.7799 14.4321C11.3633 14.682 10.8333 14.3819 10.8333 13.8961Z"
      fill="currentcolor"
    />
  </Svg>
);
