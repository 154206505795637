import { Color } from '@cycle-app/graphql-codegen';
import { ReactNode } from 'react';

import { Container, Title, Content } from './Tooltip.styles';

export type TooltipContentProps = {
  className?: string;
  title?: ReactNode;
  content?: ReactNode;
  displayFullTitle?: boolean;
  width?: number | string;
  color?: Color;
  size?: 'S';
};

export const TooltipContent = ({
  className,
  title,
  content,
  displayFullTitle = false,
  width,
  color,
  size,
}: TooltipContentProps) => (
  <Container className={className} $width={width} $color={color} $size={size}>
    {title ? (
      <>
        <Title>{title}</Title>
        {content && <Content>{content}</Content>}
      </>
    ) : (
      <Title showEllipsis={!displayFullTitle}>
        <span>
          {content}
        </span>
      </Title>
    )}
  </Container>
);
