import { OperationVariables, useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';

export function useLazyQueryAsync<TData = any, TVariables = OperationVariables>(query: DocumentNode) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) => client.query<TData, TVariables>({
      query,
      variables,
    }),
    [client, query],
  );
}
