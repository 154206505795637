import { QueryHookOptions, useApolloClient, useQuery } from '@apollo/client';
import { LinearIssuesQuery, LinearIssuesDocument } from '@cycle-app/graphql-codegen';
import { useEffect, useState } from 'react';
import { isPresent } from 'ts-is-present';

export const useLinearIssues = (
  integrationId: string | null | undefined,
  searchText: string,
  options?: QueryHookOptions,
) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
  }, [searchText]);

  const query = useQuery(LinearIssuesDocument, {
    fetchPolicy: 'cache-and-network',
    ...options,
    skip: !integrationId || options?.skip,
    variables: {
      integrationId: integrationId as string,
      searchText: searchText.trim() || null,
    },
    onCompleted: () => {
      setIsFetching(false);
    },
  });

  return {
    issues: getIssues(query.data ?? query.previousData),
    isLoading: query.loading,
    error: query.error,
    isFetching,
  };
};

export const usePrefetchLinearIssues = (integrationId: string | null | undefined) => {
  const client = useApolloClient();
  return () => {
    if (!integrationId) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.query({
      query: LinearIssuesDocument,
      variables: {
        integrationId,
        searchText: null,
      },
    });
  };
};

const getIssues = (data?: LinearIssuesQuery) => {
  if (data?.node?.__typename !== 'Integration') return [];
  if (data.node.provider?.__typename !== 'Linear') return [];
  return data.node.provider.issues.filter(isPresent);
};
