import { ChangeBoardDescriptionDocument } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { useRef, useLayoutEffect, useState } from 'react';

import { useSafeMutation } from 'src/hooks';
import { getPermission, setLimitationsModal } from 'src/reactives';

import { TextButton, StyledInput } from './ViewsList.styles';

type ViewDescriptionProps = {
  boardId: string;
  description: string | undefined | null;
};

export const ViewDescription = ({
  boardId, description,
}: ViewDescriptionProps) => {
  const [edit, setEdit] = useState(false);

  if (edit) {
    return (
      <DescriptionInput
        boardId={boardId}
        description={description ?? ''}
        closeInput={() => setEdit(false)}
      />
    );
  }

  return (
    <Tooltip
      content="Edit description"
      placement="top"
      disabled={!description}
      withPortal
      withWrapper={false}
    >
      <TextButton
        $visible={!!description}
        onClick={e => {
          e.stopPropagation();
          if (!getPermission().canUpdateView) {
            setLimitationsModal({ action: 'VIEW_UPDATE' });
            return;
          }
          setEdit(true);
        }}
      >
        <span>{description || 'Add description'}</span>
      </TextButton>
    </Tooltip>
  );
};

type DescriptionInputProps = {
  boardId: string;
  description: string;
  closeInput: VoidFunction;
};

const DescriptionInput = ({
  boardId, description, closeInput,
}: DescriptionInputProps) => {
  const [mutate] = useSafeMutation(ChangeBoardDescriptionDocument);
  const [value, setValue] = useState(description);
  const formRef = useRef<HTMLFormElement>(null);

  useLayoutEffect(() => {
    formRef.current?.querySelector('input')?.focus();
  }, []);

  return (
    <form
      ref={formRef}
      onReset={closeInput}
      onSubmit={e => {
        e.preventDefault();
        closeInput();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        mutate({
          variables: {
            boardId,
            description: value,
          },
          optimisticResponse: {
            updateBoard: {
              id: boardId,
              description: value,
            },
          },
        });
      }}
    >
      <StyledInput
        autoFocus
        placeholder="Description"
        defaultValue={description}
        onClick={e => {
          e.stopPropagation();
        }}
        onChange={e => {
          setValue(e.target.value);
        }}
        onKeyDown={e => {
          if (e.key !== 'Escape') return;
          e.stopPropagation();
          formRef.current?.reset();
        }}
        onBlur={closeInput}
        data-1p-ignore
      />
    </form>
  );
};
