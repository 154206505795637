import { Button, Input } from '@cycle-app/ui';
import { ERROR_CODE } from '@cycle-app/utilities';
import { format, isValid, parse, isAfter, isBefore } from 'date-fns';

import { Actions } from 'src/components/DialogModal/DialogModal.styles';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useProductBase } from 'src/hooks';
import { ErrorMap, useEnhancedForm } from 'src/hooks/form/useEnhancedForm';
import { useCreateRelease } from 'src/hooks/releases/useCreateRelease';
import { useRelease } from 'src/hooks/releases/useRelease';
import { useUpdateRelease } from 'src/hooks/releases/useUpdateRelease';
import { resetReleasesAction, useGetReleasesAction } from 'src/reactives/releases.reactive';

import { Form } from './ReleaseForm.styles';

type FormData = {
  date: string;
  title?: string;
};

const errorsMap: ErrorMap<FormData>[] = [
  {
    fieldName: 'date',
    code: ERROR_CODE.RELEASE_ALREADY_EXISTS,
    renderMessage: () => 'A release with this date already exists',
  },
  {
    fieldName: 'date',
    code: ERROR_CODE.RELEASE_WITH_DATE_ALREADY_EXISTS,
    renderMessage: () => 'A release with this date already exists',
  },
];

export const ReleaseForm = ({ onReleaseCreated }: {
  onReleaseCreated?: (releaseId: string) => void;
}) => {
  const product = useProductBase();
  const action = useGetReleasesAction();
  const { release } = useRelease(action.id);

  const {
    createRelease, isCreatingRelease,
  } = useCreateRelease(product?.id);

  const {
    updateRelease, isUpdatingRelease,
  } = useUpdateRelease();

  const defaultDate = release?.date ? format(new Date(release.date), 'yyyy-MM-dd') : undefined;

  const {
    handleSubmit, register, displayFieldsErrors, formState: {
      errors, isDirty, dirtyFields,
    },
  } = useEnhancedForm<FormData>({
    defaultValues: {
      date: defaultDate,
      title: release?.title ?? undefined,
    },
  });

  if (!product) return null;

  return (
    <Form
      onSubmit={handleSubmit(async values => {
        let result;

        if (action.type === 'updateRelease') {
          result = await updateRelease({
            id: action.id,
            date: dirtyFields.date ? values.date : undefined,
            title: dirtyFields.title ? values.title : undefined,
          });
        } else {
          result = await createRelease({
            productId: product?.id,
            ...values,
          });
          const releaseId = result.data?.createRelease?.id;
          if (releaseId) onReleaseCreated?.(releaseId);
        }

        if (result.errors) {
          displayFieldsErrors(result.errors, errorsMap);
        } else {
          resetReleasesAction();
        }
      })}
    >
      <Input
        autoFocus
        type="date"
        label="Release date"
        error={errors.date?.message}
        {...register('date', {
          required: 'You must select a release date',
          validate: {
            valid: value => isValid(parse(value, 'yyyy-MM-dd', new Date())) || ErrorMessage.DATE_INVALID,
            min: value => isAfter(parse(value, 'yyyy-MM-dd', new Date()), new Date('1900')) || ErrorMessage.DATE_TOO_EARLY,
            max: value => isBefore(parse(value, 'yyyy-MM-dd', new Date()), new Date('3000')) || ErrorMessage.DATE_TOO_LATE,
          },
        })}
      />

      <Input
        label="Release title"
        error={errors.title?.message}
        {...register('title', {
          setValueAs: value => value.trim(),
        })}
      />

      <Actions>
        <Button
          size="M"
          variant="secondary"
          onClick={resetReleasesAction}
        >
          Cancel
        </Button>
        <Button
          size="M"
          type="submit"
          isLoading={isCreatingRelease || isUpdatingRelease}
          disabled={action.type === 'updateRelease' && !isDirty}
        >
          Save
        </Button>
      </Actions>
    </Form>
  );
};
