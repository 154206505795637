import { twJoin } from 'tailwind-merge';

import { SuggestionsModal } from 'src/components/InsightSuggestions/SuggestionsModal';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { useSearchSuggestionDocTargets } from 'src/hooks/api/queries/useSearchSuggestionDocTargets';
import { useDocInsightChildren } from 'src/hooks/insight/useDocInsightChildren';
import { useDocId } from 'src/hooks/usePathParams';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';

export const RecommendedQuotes = () => {
  const docId = useDocId();
  const doctypeId = useDocPanelContext(ctx => ctx.doc?.doctype.id);
  const insightDoctypeId = useInsightDocType()?.id;
  if (!docId || !doctypeId || !insightDoctypeId) return null;
  return (
    <RecommendedQuotesButton
      docId={docId}
      doctypeId={doctypeId}
      insightDoctypeId={insightDoctypeId}
    />
  );
};

type Props = {
  docId: string;
  doctypeId: string;
  insightDoctypeId: string;
};

export const RecommendedQuotesButton = ({
  docId, doctypeId, insightDoctypeId,
}: Props) => {

  const insightsQuery = useDocInsightChildren({
    docId,
    doctypeId,
  });

  const insightsIds = insightsQuery.insights.map(insight => insight.id);

  const suggestionQuery = useSearchSuggestionDocTargets({
    docId,
    doctypeIds: [insightDoctypeId],
    hasParent: false,
  });

  const docs = suggestionQuery.docs.filter(doc => !insightsIds.includes(doc.id));

  const [
    isModalOpen, {
      setTrueCallback: openModal,
      setFalseCallback: closeModal,
    },
  ] = useOptimizedBooleanState(false);

  if (insightsQuery.isLoading || suggestionQuery.isLoading) return null;

  return (
    <>
      <button
        className={twJoin(
          'rounded p-0 text-body font-medium text-disabled outline-none focus-visible:ring',
          docs.length > 0 && 'cursor-pointer hover:text-secondary',
        )}
        onClick={openModal}
        disabled={docs.length === 0}
      >
        {getButtonLabel(docs.length)}
      </button>
    
      {isModalOpen && (
        <SuggestionsModal
          hide={closeModal}
          docs={docs}
        />
      )}
    </>
  );
};

const getButtonLabel = (count: number) => {
  if (count === 0) return 'No recommendation';
  if (count === 1) return '1 recommended quote';
  return `${count} recommended quotes`;
};