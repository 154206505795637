import { ContentSection } from '@cycle-app/graphql-codegen';
import styled from 'styled-components';

export const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

interface CopyProps {
  $contentSection: ContentSection;
}

export const Copy = styled.div<CopyProps>`
  align-items: center;
  display: flex;
  gap: 8px;
  color: var(--bodyColor);

  ${p => p.$contentSection === ContentSection.Discreet && `
    font-size: 14px;
  `}
  ${p => p.$contentSection === ContentSection.Uniform && `
    font-size: 16px;
  `}
  ${p => p.$contentSection === ContentSection.Classic && `
    font-size: 16px;
  `}
  ${p => p.$contentSection === ContentSection.Sober && `
    font-size: 16px;
  `}
  ${p => p.$contentSection === ContentSection.ShowOff && `
    font-size: 18px;
  `}
  ${p => p.$contentSection === ContentSection.Unique && `
    font-size: 14px;
  `}
`;
