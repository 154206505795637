import { useState } from 'react';

import { mapAddOnsData } from 'src/constants/addOn.constants';
import { useMe } from 'src/hooks';
import { useProductBase } from 'src/hooks/api/useProduct';
import { resetLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { addErrorToaster } from 'src/utils/errorToasters.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { REQUEST_ADD_ON_URL } from '../components/LimitationModal/LimitationModal.constants';
import { AddOn } from '../types/addOn.types';

export const useProductAddOnRequest = () => {
  const product = useProductBase();
  const { me } = useMe();
  const [isRequesting, setIsRequesting] = useState(false);

  const request = (brand: AddOn['name']) => {
    setIsRequesting(true);
    return fetch(REQUEST_ADD_ON_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        productId: product?.id,
        productName: product?.name,
        productSlug: product?.slug,
        userId: me.id,
        userEmail: me.email,
        addOnName: brand,
      }),
    })
      .then(() => {
        setIsRequesting(false);
        resetLimitationsModal();
        addToaster({ message: `${brand ? mapAddOnsData[brand].name : 'The add-on'} successfully requested` });
      })
      .catch(() => {
        setIsRequesting(false);
        addErrorToaster({ message: 'Error while requesting the add-on' });
      });
  };

  return {
    request,
    isRequesting,
  };
};
