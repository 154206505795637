import { getIframelyExtension as getExtension } from '@cycle-app/editor-extensions';
import { ReactNodeViewRenderer } from '@tiptap/react';

import IframelyView from 'src/components/Editor/NodeViews/Iframely/IframelyView';

export const getIframelyExtension = () => getExtension().extend({
  addNodeView() {
    return ReactNodeViewRenderer(IframelyView);
  },
});
