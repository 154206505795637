/**
 * Type the node field of a GraphQL response by its typename
 * @param typename The typename to match
 * @param node The node to type
 * @returns The typed node or undefined
 * @example const product = extract('Product', data?.node)
 */
export const extract = <T extends string, N extends { __typename?: string }>(
  typename: T, node?: N | null,
) => (isTypename(typename)(node) ? node : undefined);

/**
 * GraphQL type guards
 * @see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @example attributes.filter(isDocAttributeEmail).map(attribute => attribute.emailValue)
 */
export const isTypename = <T extends string>(...typenames: T[]) => <
  N extends { __typename?: string },
>(node?: N | null): node is Extract<N, { __typename?: T }> => typenames.some(t => node?.__typename === t);

export const isStatus = isTypename('Status');
export const isStatusDefinition = isTypename('StatusDefinition');
