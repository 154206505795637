import { DoctypeType } from '@cycle-app/graphql-codegen/generated';
import { useMemo } from 'react';

import { useDocTypes } from './useDocTypes';
import { useProductBase } from '../../useProduct';

export const useDocTypeInsight = () => {
  const product = useProductBase();
  const {
    docTypes, isLoading,
  } = useDocTypes(product?.id);

  const insight = useMemo(() => {
    return docTypes.find(d => d.type === DoctypeType.Insight);
  }, [docTypes]);

  return {
    insight,
    isLoading,
  };
};
