import {
  StarBoardDocument,
  StarBoardMutationVariables,
  UnStarBoardDocument,
  UnStarBoardMutationVariables,
} from '@cycle-app/graphql-codegen';
import { useHistory } from 'react-router-dom';

import { useCacheBoardStarred } from 'src/hooks';
import { isBoardActive } from 'src/hooks/useIsBoardActive';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setStarredSection } from 'src/reactives/starredSection.reactive';

export const useBoardStar = () => {
  const history = useHistory();

  const {
    add, remove,
  } = useCacheBoardStarred();

  const [starBoardMutation, { loading: isStarLoading }] = useSafeMutation(StarBoardDocument, {
    onCompleted: (result) => {
      if (result.starBoard) {
        add(result.starBoard);
      }
    },
  });
  const [unStarBoardMutation, { loading: isUnStarLoading }] = useSafeMutation(UnStarBoardDocument, {
    onCompleted: (result) => {
      if (result.unStarBoard?.id) {
        remove(result.unStarBoard.id);
      }
    },
  });

  const star = async (boardId: StarBoardMutationVariables['boardId']) => {
    await starBoardMutation({
      variables: {
        boardId,
      },
    });
    history.replace(history.location.pathname, { fromStarredBoard: true });
    setStarredSection({ isOpen: true });
  };

  const unStar = async (boardId: UnStarBoardMutationVariables['boardId']) => {
    await unStarBoardMutation({
      variables: {
        boardId,
      },
    });

    if (isBoardActive({
      boardId,
      isStarred: true,
    })) {
      history.replace(history.location.pathname, {});
    }
  };

  return {
    star,
    unStar,
    isLoading: isStarLoading || isUnStarLoading,
  };
};
