import { MeFragment } from '@cycle-app/graphql-codegen';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { Welcome } from './Welcome';
import { WelcomeSso } from './WelcomeSso';
import { OnboardingFooter } from '../OnboardingLayout/OnboardingFooter';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';

type OnboardingStepWelcomeProps = {
  showOnboardingSentence: boolean;
  me?: MeFragment | null;
  products: { slug: string }[];
};

export const OnboardingStepWelcome = ({
  showOnboardingSentence, me, products,
}: OnboardingStepWelcomeProps) => {
  const hasProduct = products.length > 0;
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  return (
    <OnboardingLayout
      isAnimated
      hideLogo={isWelcomeScreen}
      isContentGrow
      footer={isWelcomeScreen && !hasProduct && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2 }}
        >
          <OnboardingFooter />
        </motion.div>
      )}
      main={isWelcomeScreen
        ? (
          <Welcome
            showOnboardingSentence={showOnboardingSentence}
            onContinue={() => setIsWelcomeScreen(false)}
            products={products}
          />
        )
        : <WelcomeSso me={me ?? null} />}
    />
  );
};
