import { useQuery } from '@apollo/client';
import { CompanyDocsDocument, CompanyDocsQueryVariables } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { extract } from 'src/types/graphql.types';
import { getDocKey } from 'src/utils/doc.util';
import { defaultCustomerDocsPagination } from 'src/utils/pagination.util';

export const useCompanyDocs = (variables: Omit<CompanyDocsQueryVariables, 'size' | 'cursor'>, skip?: boolean) => {
  const product = useProductBase();

  const {
    data, loading: isLoading, fetchMore,
  } = useQuery(CompanyDocsDocument, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      ...variables,
      ...defaultCustomerDocsPagination,
    },
    skip: skip || !variables.doctypeId,
  });

  const [isPaginationLoading, {
    setTrueCallback: setPaginationLoading,
    setFalseCallback: unsetPaginationLoading,
  }] = useOptimizedBooleanState(false);

  const company = extract('Company', data?.node);

  const docs = useMemo(() => nodeToArray(company?.docs).map(doc => ({
    ...doc,
    _docKey: getDocKey(product?.key, doc.publicId) || '',
  })), [company?.docs, product?.key]);

  const loadMore = useCallback(async (cursor: string) => {
    setPaginationLoading();
    await fetchMore({
      variables: {
        ...variables,
        ...defaultCustomerDocsPagination,
        cursor,
      },
    });
    unsetPaginationLoading();
  }, [fetchMore, variables, setPaginationLoading, unsetPaginationLoading]);

  return {
    docs,
    loadMore,
    isLoading,
    isLoaded: !!data,
    isPaginationLoading,
    pageInfo: company?.docs?.pageInfo,
  };
};
