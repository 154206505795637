import { Role, RequestProductMakerAccessDocument } from '@cycle-app/graphql-codegen';
import { CheckIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useProductRole, useSafeMutation } from 'src/hooks';
import { LocalKey } from 'src/types/localStorage.types';
import { logError } from 'src/utils/errors.utils';
import { addToaster } from 'src/utils/toasters.utils';

import {
  Container,
  TextContainer,
  TextBottomContainer,
  CTAContainer,
  UpgradeButton,
} from './RequestMakerAccessBox.styles';

export const RequestMakerAccessBox = () => {
  const [hasRequested, setHasRequested] = useState(window.localStorage.getItem(LocalKey.RequestMakerAccess) === 'true');
  const {
    role, product,
  } = useProductRole();
  const [request, { loading }] = useSafeMutation(RequestProductMakerAccessDocument, {
    onCompleted: ({ requestMakerAccess }) => {
      if (requestMakerAccess) {
        window.localStorage.setItem(LocalKey.RequestMakerAccess, 'true');
        setHasRequested(true);
      } else {
        addToaster({ message: 'An error occurred while requesting access, we are looking at it' });
        logError(Error('Error on requesting maker access'));
      }
    },
  });

  return (
    <Container>
      {[Role.Maker, Role.Admin].includes(role) && (
        <TextContainer>
          <p>
            <CheckIcon />
            {{
              [Role.Admin]: 'You are already an admin',
              [Role.Collaborator]: '',
              [Role.Maker]: 'You are already a maker',
            }[role]}
          </p>
        </TextContainer>
      )}
      {[Role.Collaborator].includes(role) && (
        <>
          <CTAContainer>
            <UpgradeButton
              variant="primary"
              size="L"
              onClick={() => {
                if (!product?.id) return;
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                request({ variables: { productId: product?.id } });
              }}
              isLoading={loading}
              disabled={hasRequested}
            >
              {hasRequested ? 'Makers received your request' : 'Request maker access'}
            </UpgradeButton>
          </CTAContainer>
          <TextBottomContainer>
            <p>
              <InfoIconOutline />
              The request will be sent to the other makers.
            </p>
          </TextBottomContainer>
        </>
      )}
    </Container>
  );
};
