import { DataProxy } from '@apollo/client';
import { produce } from 'immer';

import { cache } from 'src/services/apollo/cache';

type UpdateQueryOptions<QueryType, TVariables> = DataProxy.ReadQueryOptions<QueryType, TVariables> & {
  update: (draft: QueryType) => void;
};

/**
 * Update a query in the cache using Immer
 * cache.updateQuery is available in Apollo Client 3.5 or later
 * @example
 * updateQuery({
 *   query: ProductDocument,
 *   variables: { id: productId },
 *   update: draft => { draft.product.name = 'New name' },
 * });
 */
export const updateQuery = <QueryType, TVariables>({
  update, ...options
}: UpdateQueryOptions<QueryType, TVariables>) => {
  const data = cache.readQuery(options);
  // When data is null, can show "update-query.util.ts:16 Missing field 'node' while writing result {}".
  // Can happen from delete doc subscription with useRemoveDocsFromCache.
  if (!data) return;
  cache.writeQuery({
    ...options,
    data: produce(data, update),
  });
};
