import { KeyboardEventHandler } from 'react';

import { getSearchPreview, setSearchPreview } from 'src/reactives/commandbar.reactive';

// When user press Shift + Space and hover on a doc result,
// we enable the preview and don't print the space in the search input
const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
  if (e.code === 'Space' && e.shiftKey) {
    const { docIdHover } = getSearchPreview();
    if (docIdHover) {
      e.preventDefault();
      setSearchPreview({
        isPreviewEnabled: true,
        docIdPreview: docIdHover,
      });
    }
  }
};

// When user release Shift + Space, we disable the preview
const onKeyUp = () => {
  setSearchPreview({
    isPreviewEnabled: false,
    docIdPreview: null,
  });
};

// When user hover on a doc result and shortcut is active, we open the preview
const onMouseEnter = (docId: string) => {
  setSearchPreview({ docIdHover: docId });
  if (getSearchPreview().isPreviewEnabled) {
    setSearchPreview({ docIdPreview: docId });
  }
};

// When user leave a doc result, we close the preview if it was open
const onMouseLeave = () => {
  setSearchPreview({ docIdHover: null });
};

export default {
  onKeyDown,
  onKeyUp,
  onMouseEnter,
  onMouseLeave,
};
