import { useMemo } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';

import { Integration } from '../types/integrations.types';

type UseIntegrationParams = {
  type: Integration;
};

export const useIntegration = ({ type }: UseIntegrationParams) => {
  const { list } = useProductIntegrations();

  const integration = useMemo(() => list.find(i => i.type === type), [list, type]);

  return {
    integration,
    isCustomerSynced:
      (integration?.provider?.__typename === 'Hubspot' && integration.provider.customerSync) ||
      (integration?.provider?.__typename === 'Intercom' && integration.provider.customerSync) ||
      (integration?.provider?.__typename === 'Slack' && integration.provider.customerSync),
  };
};
