import { Tab } from 'src/types/notification.types';
import { make } from 'src/utils/reactives.util';

interface UserModal {
  visible: boolean;
  tab: Tab;
}

export const {
  hookState: useSettingsModal,
  hookValue: useGetSettingsModal,
  getValue: getSettingsModal,
  setValue: setSettingsModal,
} = make<UserModal>({
  defaultState: {
    visible: false,
    tab: 'account',
  },
});

export const closeSettingsModal = () => setSettingsModal({ visible: false });

export const toggleSettingsModal = () => {
  setSettingsModal({
    visible: !getSettingsModal().visible,
    tab: 'account',
  });
};

export const openSettingsAccount = () => setSettingsModal({
  visible: true,
  tab: 'account',
});

export const openSettingsAppearance = () => setSettingsModal({
  visible: true,
  tab: 'appearance',
});

export const openSettingsNotifications = () => setSettingsModal({
  visible: true,
  tab: 'notifications',
});

export const openSettingsSecurity = () => setSettingsModal({
  visible: true,
  tab: 'security',
});

export const openSettingsRecording = () => setSettingsModal({
  visible: true,
  tab: 'recording',
});

export const openMeetingsEventsRecording = () => setSettingsModal({
  visible: true,
  tab: 'meetings-events',
});
