import { CloseIcon } from '@cycle-app/ui/icons';
import { useCallback, useState, ChangeEvent, useRef } from 'react';

import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { closeNewSectionForm } from 'src/reactives/sections.reactive';

import { BoardSectionInput } from '../../SidebarBoard.styles';

const SidebarBoardNewSection = () => {
  const [name, setName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { addProductSection } = useProductMutations();

  const handleSubmit = useCallback(async () => {
    if (!name) return;
    closeNewSectionForm();
    await addProductSection(name);
    setName('');
  }, [addProductSection, name]);

  const handleEscape = useCallback(() => {
    setName('');
    closeNewSectionForm();
  }, []);

  useAppHotkeys('enter', handleSubmit);
  useAppHotkeys('escape', handleEscape);

  return (
    <BoardSectionInput
      ref={inputRef}
      value={name}
      placeholder="Your section name"
      onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
      iconAfter={<CloseIcon />}
      onBlur={handleEscape}
      onClickIcon={handleEscape}
      autoFocus
    />
  );
};

export default SidebarBoardNewSection;
