import { QueryFunctionOptions, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  MeNodeDocument, MeFragment, MeNodeQuery, MeNodeQueryVariables, Language, Role,
} from '@cycle-app/graphql-codegen';
import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';

import { useGetAuth } from 'src/reactives/auth.reactive';
import { extract } from 'src/types/graphql.types';

import { getLanguage, setLanguage } from '../../reactives/language.reactive';

export const useMe = () => {
  const { userId } = useGetAuth();
  const {
    data, refetch,
  } = useQuery<{ node: MeFragment }>(MeNodeDocument, {
    skip: !userId,
    fetchPolicy: 'cache-only',
    variables: {
      id: userId,
    },
  });

  useEffect(() => {
    Sentry.setUser({ id: userId ?? undefined });
    if (userId && typeof getLanguage(userId) === 'undefined') {
      // navigator.languages = [en-US, en, fr]. Find the first matches two chars.
      const language = navigator.languages.find(ln => ln.length === 2);
      const value = Object.values(Language).find(ln => ln === language);
      setLanguage(userId, value || Language.En);
    }
  }, [userId]);

  if (!data) {
    throw new Error(`Cache with key ${userId} do not exists`);
  }

  return {
    me: data?.node,
    refetch,
  };
};

export const useMaybeMe = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const { userId } = useGetAuth();

  const { data } = useQuery<{ node: MeFragment }>(MeNodeDocument, {
    skip: !userId,
    fetchPolicy: fetchPolicy ?? 'cache-first',
    variables: {
      id: userId,
    },
  });

  return data?.node;
};

type UseMaybeMeV2Options = QueryFunctionOptions<MeNodeQuery, MeNodeQueryVariables>;

export const useMaybeMeV2 = (options?: UseMaybeMeV2Options) => {
  const { userId } = useGetAuth();

  const {
    data, error, loading: isLoading,
  } = useQuery(MeNodeDocument, {
    skip: !userId,
    fetchPolicy: 'cache-first',
    variables: {
      id: userId as string,
    },
    ...options,
  });

  const me = extract('Me', data?.node) ?? null;
  return {
    me,
    isSuperAdmin: me?.role === Role.SuperAdmin,
    isLoading,
    error,
  };
};
