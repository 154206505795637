import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { Title } from './ChangelogBuilderContent.styles';

export const ChangelogBuilderContentTitle = () => {
  const { watch } = useChangelogBuilderFormContext();
  return (
    <Title>
      {watch('title').trim() || 'Changelog'}
    </Title>
  );
};
