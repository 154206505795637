import { Spinner } from '@cycle-app/ui';
import { ReleaseNoteIcon } from '@cycle-app/ui/icons';

import { Container, Content } from './EditorTranscriptStatusState.styles';

type EditorTranscriptStatusStateProps = {
  errorMessage?: string;
  className?: string;
};

export const EditorTranscriptStatusState = ({
  errorMessage, className,
}:EditorTranscriptStatusStateProps) => (
  <Container className={className}>
    <Content>
      {errorMessage ? '🧐 ' : <ReleaseNoteIcon />}
      {errorMessage || 'Generating transcript…'}
      {!errorMessage && <Spinner />}
    </Content>
  </Container>
);
