import { z } from 'zod';

import { LocalKey } from 'src/types/localStorage.types';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';

import { getLocalStorageItem } from '../utils/localStorage.utils';
import { makeVar } from '../utils/makeVar.utils';

export type OnboardingSlackStateValue = {
  step?: OnboardingSlackStep | null;
  /**
   * Doc id will be the doc created from Slack during the onboarding
   */
  docId?: string | null;
};

export type OnboardingSlackState = {
  [productId: string]: OnboardingSlackStateValue;
};

const {
  get,
  hook,
  produce,
} = makeVar<OnboardingSlackState>({}, {
  schema: z.record(
    z.string(),
    z.object({
      step: z.custom<OnboardingSlackStep>().nullish(),
      docId: z.string().nullish(),
    }),
  ),
  key: LocalKey.OnboardingSlackNew,
  crossTabSync: true,
});

const legacy = z.object({
  step: z.custom<OnboardingSlackStep>().nullish(),
  docId: z.string().nullish(),
});

export const useGetOnboardingSlack = (productId: string) => {
  // getLocalStorageItem always returns a default value, we need to check the key before.
  const legacyKey = localStorage.getItem(LocalKey.OnboardingSlack);
  if (legacyKey) {
    const legacyValue = getLocalStorageItem({
      key: LocalKey.OnboardingSlack,
      schema: legacy,
      defaultValue: {
        step: undefined,
        docId: undefined,
      },
    });
    localStorage.removeItem(LocalKey.OnboardingSlack);
    produce(current => {
      // eslint-disable-next-line no-param-reassign
      current[productId] = legacyValue;
    });
  }
  return hook()[productId];
};

export const startOnboardingSlack = (productId: string) => {
  produce(current => {
    // eslint-disable-next-line no-param-reassign
    current[productId] = {
      step: OnboardingSlackStep.Install,
      docId: null,
    };
  });
};

export const stopOnboardingSlack = (productId: string) => {
  produce(current => {
    // eslint-disable-next-line no-param-reassign
    current[productId] = {
      step: null,
      docId: null,
    };
  });
};

export const getOnboardingSlackStep = (productId: string) => {
  return get()[productId]?.step;
};

export const getOnboardingSlackDocId = (productId: string) => {
  return get()[productId]?.docId;
};

export const setOnboardingSlackDocId = (productId: string, docId: string) => {
  produce(current => {
    const value = current[productId];
    if (value) {
      value.docId = docId;
    }
  });
};

export const setOnboardingSlackStep = (productId: string, step: OnboardingSlackStep) => {
  produce(current => {
    const value = current[productId];
    if (value) {
      value.step = step;
    }
  });
};
