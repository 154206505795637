import { typo, Button, IntegrationCardSkeleton } from '@cycle-app/ui';
import { CardContainer } from '@cycle-app/ui/components/IntegrationCard/IntegrationCard.styles';
import { mobile, tablet } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { IntegrationItem } from 'src/app/Main/Settings/SettingsIntegrations/IntegrationItem/IntegrationItem';

export const Section = styled.section`
  border-radius: 12px;
  margin: 0 32px;

  ${mobile} {
    margin: 0 16px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h2`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
`;

export const Content = styled.div`
  margin-top: 16px;
`;

export const StyledButton = styled(Button)`
  font-weight: normal;
  padding: 0;
  &:hover, &:focus {
    --bg: transparent;
  }
`;

export const IntegrationsGrid = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  ${tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mobile} {
    grid-template-columns: 1fr;
  }

  h3 {
    ${typo.body500}
  }
`;

const cardStyles = css`
  ${CardContainer} {
    border-radius: 12px;
    background-color: ${p => p.theme.colors.home.card.bg};
    box-shadow: none;
    border: none;
  }
`;

export const StyledIntegrationItem = styled(IntegrationItem)`
  ${cardStyles}
`;

export const StyledIntegrationSkeleton = styled(IntegrationCardSkeleton)`
  ${cardStyles}
  min-width: max(calc(25% - 15px), 230px);
  width: auto;
  border-radius: 12px;
  box-shadow: none;
  border: none;
`;
