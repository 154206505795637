import { AddNewDocAttributeValue, DocChildFragment } from '@cycle-app/graphql-codegen';
import { Badge, ActionButton } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';

import DocHierarchyItem from 'src/components/DocHierarchy/DocHierarchyItem/DocHierarchyItem';
import { DocHierarchySkeletons } from 'src/components/DocHierarchy/DocHierarchyItem/DocHierarchySkeletons';
import InputLinkDoc from 'src/components/InputLinkDoc/InputLinkDoc';
import LoadMore from 'src/components/LoadMore/LoadMore';
import { useDocChildren } from 'src/hooks/api/useDoc';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { expandFeatureSection } from 'src/reactives/featureDoc.reactive';
import { getDocTypeName } from 'src/utils/docType.util';
import { SIZE_ITEMS_HIERARCHY } from 'src/utils/pagination.util';

import { FeatureDocCollapsible } from './FeatureDocCollapsible';

type Props = {
  parentId?: string;
  doctypeId: string;
  showInput: boolean;
  setInputDoctypeId?: (doctypeId: string | null) => void;
  onDocLinked: (childrenId: string, doctypeId: string, created: boolean, doc?: DocChildFragment) => Promise<void>;
  docAttributes: AddNewDocAttributeValue[];
  sourceId?: string;
  refefresDeleteQueries?: boolean;
};

export const FeatureDocHierarchyGroup = ({
  parentId,
  doctypeId,
  showInput,
  setInputDoctypeId,
  onDocLinked,
  docAttributes,
  sourceId,
  refefresDeleteQueries,
}: Props) => {
  const docType = useGetDocType(doctypeId);

  const {
    docs,
    pageInfo,
    isPaginationLoading,
    loading,
    loadMore,
    count,
  } = useDocChildren({
    doctypeId,
    docId: parentId,
  });

  const hasChildren = count > 0;
  const showInputDoc = showInput || hasChildren;
  const docTypesAttributeIds = nodeToArray(docType?.attributeDefinitions).map(def => def.id);
  const inheritedAttributes = docAttributes.filter(attr => docTypesAttributeIds.includes(attr.attributeDefinitionId));

  if (!docType) return null;

  return (
    <FeatureDocCollapsible
      sectionId={doctypeId}
      title={open => (
        <div className="flex items-center gap-2">
          <div className="truncate">
            {getDocTypeName(docType, true)}
          </div>

          {hasChildren && (
            <Badge>
              {count}
            </Badge>
          )}

          {!showInputDoc && !loading && !(open && !hasChildren) && (
            <ActionButton
              variant="secondary"
              tooltip={`Add a new ${docType?.name}`}
              tooltipPlacement="top"
              onClick={e => {
                e.stopPropagation();
                expandFeatureSection(doctypeId);
                setInputDoctypeId?.(doctypeId);
              }}
            >
              <AddIcon size={10} />
            </ActionButton>
          )}
        </div>
      )}
      onChange={(collapsed) => {
        if (collapsed) setInputDoctypeId?.(null);
      }}
    >
      <div className="flex flex-col gap-2">
        {docs.map(doc => (
          <DocHierarchyItem
            key={doc.id}
            doc={doc}
            parentId={parentId}
            refefresDeleteQueries={refefresDeleteQueries}
            hideDocCustomer
            showDocId
            showStatus
          />
        ))}

        {(loading || isPaginationLoading) && (
          <DocHierarchySkeletons
            count={3}
            showDocId
          />
        )}

        {!isPaginationLoading && pageInfo?.hasNextPage && (
          <LoadMore
            className="!w-fit"
            onClick={() => loadMore(pageInfo.endCursor ?? '')}
            count={Math.min((count - docs.length), SIZE_ITEMS_HIERARCHY)}
          />
        )}

        {!pageInfo?.hasNextPage && (showInputDoc || !hasChildren) && (
          <InputLinkDoc
            autoFocus={!hasChildren}
            label="Add"
            placeholder={`Add a new ${docType.name}`}
            hideInput={showInput ? () => setInputDoctypeId?.(null) : undefined}
            onDocLinked={onDocLinked}
            doctype={docType}
            parentId={parentId}
            inheritedAttributes={inheritedAttributes}
            sourceId={sourceId}
          />
        )}
      </div>
    </FeatureDocCollapsible>
  );
};
