import { capitalize } from '@cycle-app/utilities';

import { AutomationType } from 'src/types/automation.types';
import { LINEAR_ISSUE_KEY_REGEX, LINEAR_PROJECT_REGEX } from 'src/utils/embeddable.util';

export const progressLabel = (progress: number) => {
  return `${Math.round(progress * 100)}%`;
};

export const issuesCount = (count: number) => {
  if (count === 0) return 'No issues';
  if (count === 1) return '1 issue';
  return `${count} issues`;
};

export const projectInfos = (progress: number, count: number) => {
  return `${issuesCount(count)} · ${progressLabel(progress)}`;
};

export const projectName = (url: string | null | undefined) => {
  const slug = url?.match(LINEAR_PROJECT_REGEX)?.[1];
  const name = slug?.split('-').slice(0, -1).join(' ');
  if (!name) return null;
  return capitalize(name);
};

export const issueKey = (url: string | null | undefined) => {
  return url?.match(LINEAR_ISSUE_KEY_REGEX)?.[1];
};

export const issueInfos = (url: string | null | undefined, teamName?: string | null | undefined) => {
  return [issueKey(url), teamName].filter(Boolean).join(' · ');
};

export const linearLabel = (
  type: AutomationType | undefined,
  url: string | null | undefined,
) => {
  if (type === 'project') return projectName(url) ?? 'Project';
  if (type === 'issue') return issueKey(url) ?? 'Issue';
  return 'Link';
};
