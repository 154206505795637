import { LinearIssueFullFragment, LinearStatus } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';
import { useMemo } from 'react';

import { LinearStatus as LinearStatusIcon } from 'src/components/LinearStatus/LinearStatus';
import { useUpdateLinearIssue } from 'src/hooks/api/mutations/integrations/useUpdateLinearIssue';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { LinearIssuesCommandAttributes } from 'src/types/editor.types';
import { Layer } from 'src/types/layers.types';
import { isLinearStatus } from 'src/utils/integrations.utils';

import { InfosTitle, StyledSelectButton } from './LinearEditorMentionView.styles';
import DropdownLayer from '../DropdownLayer/DropdownLayer';

export const IssueStatus = ({
  issue,
  status = issue?.status,
  updateAttributes,
}: {
  issue: LinearIssueFullFragment;
  status?: LinearStatus | null;
  updateAttributes?: (attr: Partial<LinearIssuesCommandAttributes>) => void;
}) => {
  const {
    teams, isLoading: isTeamsLoading,
  } = useLinearTeams();

  const [isStatusSelectOpened, { toggleCallback: toggleStatusSelect }] = useOptimizedBooleanState(false);

  const teamStatus = useMemo(() => teams
    .find(team => team?.id === issue?.team?.id)?.availableStatus.filter(isLinearStatus) ?? [], [teams, issue]);

  const statusOptions = useMemo(() => teamStatus.map(s => ({
    value: s.id,
    label: s.name,
    icon: <LinearStatusIcon status={s} />,
  })) ?? [], [teamStatus]);

  const { updateLinearIssue } = useUpdateLinearIssue();

  const onStatusChange = async (statusId: string) => {
    if (!issue) return;
    updateAttributes?.({
      status: teamStatus.find(s => s.id === statusId),
    });
    toggleStatusSelect();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateLinearIssue(issue.id, { stateId: statusId }, {
      ...issue,
      status: teamStatus.find(s => s.id === statusId),
    });
  };

  return (
    <div>
      <InfosTitle>Status</InfosTitle>
      {status && (
        <DropdownLayer
          layer={Layer.DropdownModalZ4}
          visible={isStatusSelectOpened}
          hide={toggleStatusSelect}
          placement="bottom-start"
          content={(
            <SelectPanel
              options={statusOptions}
              hideSearch
              isLoading={!issue && isTeamsLoading}
              onOptionChange={({ value }) => onStatusChange(value)}
            />
        )}
        >
          <StyledSelectButton onClick={e => { e.stopPropagation(); toggleStatusSelect(); }}>
            <LinearStatusIcon status={status} size={14} />
            {status.name}
          </StyledSelectButton>
        </DropdownLayer>
      )}
    </div>
  );
};
