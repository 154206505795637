import { ThemeType } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';
import { Placement } from 'tippy.js';

import { Color } from '../../theme/baseColors';
import { body400, body500 } from '../../theme/typo';
import { boxShadowZ5 } from '../../utils/styles.util';

const isVertical = (p: Placement) => p.startsWith('top') || p.startsWith('bottom');
const isCentered = (p: Placement) => !p.includes('-');

// border radius
const r = 8;
// arrow size
const a = 8;
// arrow position
const d = 15;

// b = border width
const clipPath = (rect: DOMRect, placement: Placement, b: number) => {
  const h = Math.round(isVertical(placement) ? rect.height : rect.width);
  const w = Math.round(isVertical(placement) ? rect.width : rect.height);
  const center = Math.round((w - (r + a) * 2) / 2);
  const pos = isCentered(placement) ? center : Math.min(d, center);
  return `path('
    M ${r + b},${a + b}
    h ${pos - b / 2}
    l ${a - b / 2},-${a - b / 2}
    l ${a - b / 2},${a - b / 2}
    h ${w - pos - a * 2 - r * 2 + b / 2}
    a ${r - b},${r - b} 0 0 1 ${r - b},${r - b}
    v ${h - r * 2}
    a ${r - b},${r - b} 0 0 1 -${r - b},${r - b}
    h -${w - r * 2}
    a ${r - b},${r - b} 0 0 1 -${r - b},-${r - b}
    v -${h - r * 2}
    a ${r - b},${r - b} 0 0 1 ${r - b},-${r - b}
    z')
`;
};

export const Container = styled.div<{ placement: Placement }>`
  width: fit-content;
  position: relative;
  border-radius: 8px;
  z-index: 1;

  ${p => p.placement.includes('top') && `margin-bottom: ${a}px`};
  ${p => p.placement.includes('right') && `margin-left: ${a}px`};
  ${p => p.placement.includes('bottom') && `margin-top: ${a}px`};
  ${p => p.placement.includes('left') && `margin-right: ${a}px`};

  ${boxShadowZ5}
`;

const bgLayer = css<{
  rect?: DOMRect;
  placement: Placement;
}>`
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;

  ${p => p.rect && p.placement.includes('bottom') && `
    height: ${p.rect.height + 8}px;
    left: 0;
    top: -8px;
  `};

  ${p => p.placement === 'bottom-end' && `
    transform: scale(-1, 1);
  `};

  ${p => p.rect && p.placement.includes('top') && `
    height: ${p.rect.height + 8}px;
    left: 0;
    bottom: -8px;
    transform: scale(1, -1);
  `};

  ${p => p.rect && p.placement === 'top-end' && `
    transform: scale(-1, -1);
  `};

  ${p => p.rect && p.placement.includes('left') && `
    transform: rotate(90deg);
    width: ${p.rect.height}px;
    height: ${p.rect.width + 8}px;
    left: 8px;
    top: 0px;
    transform-origin: ${p.rect.width / 2}px ${p.rect.width / 2}px;
  `};

  ${p => p.rect && p.placement.includes('right') && `
    left: -8px;
    bottom: -8px;
    width: ${p.rect.height}px;
    height: ${p.rect.width + 8}px;
    transform: rotate(-90deg);
    transform-origin: ${p.rect.width / 2}px ${p.rect.width / 2}px;
  `};
`;

export const Layers = styled.div<{
  rect?: DOMRect;
  placement: Placement;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${p => p.rect && css`
    :before {
      ${bgLayer}
      background: ${p.theme.themeType === ThemeType.Nightfall ? Color.Grey850 : Color.White};
      clip-path: ${clipPath(p.rect, p.placement, 0)};
    }
  `}

  ${p => (p.placement === 'left-end' || p.placement === 'right-start') && `
    transform: scale(1, -1);
  `};
`;

export const Content = styled.div`
  position: relative;
  max-width: min(650px, 100vw);
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${body400}
  z-index: 1;

  header {
    display: flex;
    align-items: center;
    gap: 8px;
    ${body500}
    color: ${p => p.theme.colors.text.primary};

    svg {
      width: 14px;
      height: 14px;
    }
  }

  footer {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 5px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${p => p.theme.colors.text.disabled};
  :focus { outline: none; }
  :hover, :focus-within {
    background: ${p => (p.theme.themeType === ThemeType.Nightfall ? Color.Grey800 : Color.Grey100)};
  }
`;
