import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
`;

export const FiltersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
