import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { WIDTH_COLLAPSED_SIDEBAR } from '../../constants/sidebar.constants';
import { NavigationItemContainer } from '../NavigationItem/NavigationItem';
import { Actions, Container as Item, Name, Icon, NavigationShortcut } from '../NavigationItem/NavigationItem.styles';
import { NavigationSection } from '../NavigationSection/NavigationSection';

export interface NavProps {
  $width?: number;
  $collapsed?: boolean;
  resizing?: boolean;
  animate?: boolean;
  $hasFullWidth?: boolean;
}

export const HandleResize = styled.div`
  cursor: ew-resize;

  position: absolute;
  top: 0;
  right: 0px;

  width: 2px;
  height: 100%;
`;

const NavCollapsedCss = css`
  ${Item} {
    justify-content: center;
    padding: 0;
    &.item--group {
      display: none;
    }
  }

  ${NavigationSection} {
    display: flex;
    flex-direction: column;
  }

  ${NavigationItemContainer} {
    padding: 8px;
  }

  /* stylelint-disable-next-line */
  ${Name}, ${NavigationShortcut}, ${Actions} {
    display: none;
  }

  ${Icon} {
    margin-right: auto;
  }
`;

export const Navigation = styled.nav.attrs((p: NavProps) => ({
  style: {
    width: p.$hasFullWidth ? '100%' : `${p.$collapsed ? WIDTH_COLLAPSED_SIDEBAR : p.$width}px`,
  },
})) <NavProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: ${p => (p.$collapsed ? '16px 16px 0 16px' : '8px 8px 0 8px')};

  ${p => p.animate && 'transition: width .2s ease-in-out'};
  height: 100%;

  color: ${(props) => props.theme.colors.sidebar.color};
  background-color: ${(props) => props.theme.colors.sidebar.bg};
  ${mobile} {
    background-color: ${p => p.theme.colors.sidebar.mobile.bg};
  }

  flex-shrink: 0;

  ${(props) => props.$collapsed && NavCollapsedCss};

  ${mobile} {
    padding-top: 0px;
  }
`;
