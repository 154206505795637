import { NewDocPosition } from '@cycle-app/ui';

import { make } from 'src/utils/reactives.util';

interface BoardNewDocPosition {
  // Root group id when navigating through inputs in nested lists (tab/shift-tab)
  initialGroupId: string | null;
  groupId: string | null;
  draftPosition: NewDocPosition | null;
}

export const {
  hookValue: useBoardNewDocPositionState,
  getValue: getBoardNewDocPositionState,
  setValue: setBoardNewDocPositionState,
} = make<BoardNewDocPosition>({
  defaultState: {
    initialGroupId: null,
    groupId: null,
    draftPosition: null,
  },
});
