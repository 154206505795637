import { IntegrationType } from '@cycle-app/graphql-codegen';

import { useProductIntegrations } from '../api/useProductIntegrations';

export const useSlackIntegration = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Slack);
  const provider = integration?.provider?.__typename === 'Slack' ? integration?.provider : null;
  return {
    integration,
    isInstalled: !!provider,
    provider,
  };
};
