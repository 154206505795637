import { InfiniteScroll } from '@cycle-app/ui';
import { useRect } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import { ComponentPropsWithRef } from 'react';

import { discardError, useGetDocImport } from 'src/reactives/docImport.reactive';
import { getProgressMessage, getTitle } from 'src/utils/dropzone.utils';
import { getFileBaseName, getFileExtension } from 'src/utils/files.util';

import classes from './DropZone.module.css';
import {
  Details, List, Container, DropContainer, Drop, Content, Body, Header, Description, DescItem, Buttons, Counter, Title,
} from './Dropzone.styles';
import { DropzoneBackground } from './DropzoneBackground';
import { CreateDocButton, DiscardDocButton } from './DropzoneButtons';
import { DropzoneIcon } from './DropzoneIcon';
import { FileCard } from './FileCard';
import { DiscardButton } from './FileCard.styles';
import { LinkButton } from './LinkButton';
import { RecordButton } from './RecordButton';
import { UploadButton } from './UploadButton';
import { useDropzoneProps } from './useDropzoneProps';

type DropZoneProps = ComponentPropsWithRef<'div'> & {
  direction?: 'row' | 'column';
  onDiscardLast?: VoidFunction;
  headerTop?: number;
};

export const Dropzone = ({
  direction: defaultDirection = 'row',
  onDiscardLast,
  headerTop = 70,
  ...props
}: DropZoneProps) => {
  const [containerRef, direction] = useRect(
    rect => (rect.width < 600 ? 'column' : defaultDirection),
  );

  const {
    loadingFiles, acceptedTotal, fileRejections,
  } = useGetDocImport();

  const {
    isIdle,
    isLoading,
    isDetails,
    isDragActive,
    isMaxFilesError,
    onDrop,
    onMouseEnter,
    getRootProps,
    getInputProps,
    open,
    rootRef,
    processCount,
    docs,
    isLoadingDocImport,
    hasMoreDocImport,
    loadMoreDocImport,
    handleFiles,
    handleLink,
  } = useDropzoneProps({
    onDiscardLast,
  });

  return (
    <Container {...props} ref={containerRef}>
      {direction !== undefined && (
        <Details
          $isDetails={isDetails}
          $direction={direction}
        >
          <DropContainer
            className={classes.dropContainer}
            initial={false}
            animate={{
              width: direction === 'row' && isDetails ? 'min(42%, 360px)' : '100%',
            }}
          >
            <Drop
              $isDragActive={isDragActive}
              $isError={isMaxFilesError}
              {...getRootProps({
                onMouseEnter,
                onDrop,
              })}
            >
              <DropzoneBackground
                rootRef={rootRef}
                isDragActive={isDragActive}
                isLoading={isLoading}
                isError={isMaxFilesError}
              />

              <Content>
                <Header
                  initial={false}
                  animate={{
                    top: isIdle ? headerTop : 'unset',
                  }}
                >
                  <DropzoneIcon
                    isMaxFilesError={isMaxFilesError}
                    isLoading={isLoading}
                  />

                  <Title>
                    {getTitle(isMaxFilesError, isLoading)}
                  </Title>

                  {isLoading && (
                    <Counter>
                      {getProgressMessage(processCount, acceptedTotal)}
                    </Counter>
                  )}
                </Header>

                <Body
                  initial={false}
                  animate={{
                    opacity: isIdle ? 1 : 0,
                    display: 'flex',
                    transitionEnd: { pointerEvents: isIdle ? 'all' : 'none' },
                  }}
                >
                  <Description
                    className={classes.description}
                  >
                    <DescItem>
                      Images (JPG, PNG, GIF), docs (PDF, TXT, VTT), videos (MOV, MP4), audio (MP3, WAV)
                    </DescItem>
                  </Description>

                  <Buttons
                    className={classes.buttons}
                  >
                    <UploadButton
                      {...getInputProps()}
                      open={open}
                    />

                    <RecordButton onRecorded={file => handleFiles([file])} />

                    <LinkButton onSubmit={url => handleLink(url)} />
                  </Buttons>
                </Body>
              </Content>
            </Drop>
          </DropContainer>

          <AnimatePresence>
            {isDetails && (
              <List
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  // TODO: fix the exit animation
                  transition: { duration: 0 },
                }}
              >
                <InfiniteScroll
                  isLoading={isLoadingDocImport}
                  hasMoreData={hasMoreDocImport}
                  loadMore={loadMoreDocImport}
                >
                  <AnimatePresence initial={false}>
                    {loadingFiles.map((id, index) => (
                      <FileCard
                        key={id}
                        isLoading
                        isLast={index === loadingFiles.length - 1 && !fileRejections.length && !docs.length}
                      />
                    ))}

                    {fileRejections.map((item, index) => (
                      <FileCard
                        key={item.id}
                        name={getFileBaseName(item.rejection.file.name)}
                        extension={getFileExtension(item.rejection.file.name)}
                        mimeType={item.rejection.file.type}
                        size={item.rejection.file.size}
                        errorCode={item.rejection.errors[0]?.code}
                        errorMessage={item.rejection.errors[0]?.message}
                        discardButton={(
                          <DiscardButton onClick={() => discardError(item.id)}>
                            Discard
                          </DiscardButton>
                        )}
                        isLast={index === fileRejections.length - 1 && !docs.length}
                      />
                    ))}

                    {docs.map((doc, index) => (
                      <FileCard
                        key={doc.id}
                        name={doc.title}
                        extension={getFileExtension(doc.source?.url)}
                        url={doc.source?.url}
                        size={doc.source && 'fileSize' in doc.source ? doc.source.fileSize : null}
                        discardButton={<DiscardDocButton docId={doc.id} />}
                        createButton={<CreateDocButton docId={doc.id} />}
                        isLast={index === docs.length - 1}
                      />
                    ))}
                  </AnimatePresence>
                </InfiniteScroll>
              </List>
            )}
          </AnimatePresence>
        </Details>
      )}
    </Container>
  );
};
