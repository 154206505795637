import { ThemeType } from '@cycle-app/graphql-codegen';

import {
  colors as eclipseColors,
  nuance as eclipseNuance,
} from './eclipse';
import {
  colors as nightfallColors,
  nuance as nightfallNuance,
} from './nightfall';
import { colors as sunriseColors } from './sunrise';

export const COLOR_THEMES = {
  [ThemeType.Eclipse]: eclipseColors,
  [ThemeType.Nightfall]: nightfallColors,
  [ThemeType.Sunrise]: sunriseColors,
};

export const COLOR_NUANCES = {
  [ThemeType.Eclipse]: eclipseNuance,
  [ThemeType.Nightfall]: nightfallNuance,
  [ThemeType.Sunrise]: eclipseNuance,
};
