import { DOMSerializer } from '@tiptap/pm/model';
import { Selection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';

export const getHTMLFromSelection = (editor: Editor, selection: Selection) => {
  const slice = selection.content();
  const serializer = DOMSerializer.fromSchema(editor.schema);
  const fragment = serializer.serializeFragment(slice.content);
  const div = document.createElement('div');
  div.appendChild(fragment);

  return div.innerHTML;
};
