import { ShyScrollbarCss } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { FOOTER_HEIGHT } from '../MobileFooter/MobileFooter.styles';
import { HEADER_SECONDARY_HEIGHT } from '../MobileHeader/MobileHeader.styles';

export const Container = styled(motion.aside)`
  ${ShyScrollbarCss}
  position: absolute;
  top: ${HEADER_SECONDARY_HEIGHT}px;
  width: 100vw;
  z-index: 8;
  bottom: ${FOOTER_HEIGHT}px;
  pointer-events: initial;
`;
