import {
  ActionButton, ShyScrollbar, typo, Skeleton, CircleSkeleton, Button,
} from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import ContentEditable from 'src/components/ContentEditable/ContentEditable';
import { StyledActionButton } from 'src/components/DocCustomer/DocCustomer.styles';
import { ErrorPage } from 'src/components/ErrorPage';
import PortalModal from 'src/components/PortalModal/PortalModal';
import { EDITOR_WIDTH_DESKTOP, EDITOR_MARGIN } from 'src/constants/editor.constants';

import DocPanelCover from '../DocPanel/DocPanelCover/DocPanelCover';

const LATERAL_PADDING = 24;

export const PortalModalStyled = styled(PortalModal)`
  padding: 0;
  border-radius: 11px;

  ${mobile} {
    border-radius: 0px;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
`;

export const Container = styled.div`
  width: 750px;
  max-width: 80vw;

  ${mobile} {
    display: grid;
    grid-template-rows: 56px 1fr;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
`;

export const Actions = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
`;

export const BigActionButton = styled(ActionButton) <{ incredibleExceptionSize?: boolean }>`
  width: 32px;
  height: 32px;
  ${p => p.incredibleExceptionSize && css`
    svg {
      width: 14px;
      height: 14px;
    }
  `};
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  padding: 0 ${EDITOR_MARGIN}px;
`;

export const DocTop = styled.div`
  ${mobile} {
    overflow: hidden;
  }
`;

export const DocContainer = styled.div<{ $isDisabled: boolean }>`
  ${p => p.$isDisabled && css`
    pointer-events: none;
    opacity: 0.25;
  `};

  min-height: 360px;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 28px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

export const ContentEditableStyled = styled(ContentEditable)`
  margin-top: 8px;
  margin-bottom: 10px;
  outline: none;
  width: 100%;

  ${typo.headerLarge};

  ${mobile} {
    margin-top: 20px;
  }

  ${p => p.isDisabled && css`
    pointer-events: none;
    opacity: 0.25;
  `};
`;

export const Attributes = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;

  ${p => p.$isDisabled && css`
    pointer-events: none;
    opacity: 0.25;
  `};
`;

export const Buttons = styled.div<{ $isDisabled: boolean }>`
  padding: 0 ${LATERAL_PADDING}px ${LATERAL_PADDING}px ${LATERAL_PADDING}px;
  margin-top: 16px;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid ${p => p.theme.colors.border.primary};

  ${mobile} {
    border-bottom: 1px solid ${p => p.theme.colors.border.primary};
    justify-content: space-between;
    margin-top: 0;
    padding: 0 12px;
    border: none;
  }

  ${p => p.$isDisabled && css`
    pointer-events: none;
    opacity: 0.25;
  `};
`;

export const DocPanelCoverStyled = styled(DocPanelCover)`
  margin-top: 32px;
`;

export const ScrollableContent = styled(ShyScrollbar)`
  max-height: calc(100vh - 325px);
  padding-top: 24px;

  ${mobile} {
    max-height: 100%;
    &:focus-within {
      max-height: calc(100% - var(--keyboard-height));
    }
    padding-top: 32px;
  }
`;

export const DocTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SkeletonAvatar = styled(CircleSkeleton)`
  width: 22px;
  height: 22px;
`;

export const SkeletonUsername = styled(Skeleton)`
  width: 90px;
  height: 14px;
`;

export const SkeletonCustomer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const customerButtonCss = css`
  --bg: ${p => p.theme.colors.docItem.actionButton.bgHover};
  --color: ${p => p.theme.colors.text.secondary};
  ${typo.body400}
  padding: 0 8px;
  height: 28px;
  border-radius: 6px;
`;

export const CustomerButton = styled(StyledActionButton)`
  ${customerButtonCss}
  min-width: 200px;
`;

export const CustomerSelect = styled.div`  
  ${StyledActionButton} {
    ${customerButtonCss}
    min-width: 200px;
    svg {
      color: ${p => p.theme.colors.text.disabled};
    }
  }
`;

export const CustomerContainer = styled(StyledActionButton)`
  ${customerButtonCss}
  padding: 0 4px;
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TooltipButton = styled(Button).attrs({
  variant: 'nospace',
})`
  border: none;
  ${typo.caption400}
  color: ${p => p.theme.colors.tooltip.textAlt};
  :hover {
    color: ${p => p.theme.colors.tooltip.color};
  }
`;

export const StyledErrorPage = styled(ErrorPage)`
  min-height: 600px;
`;
