import styled, { css } from 'styled-components';

import { body400 } from '../../theme/typo';
import { Skeleton } from '../Skeleton/Skeleton';

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: inherit;
`;

export const TBody = styled.tbody`
  background-color: inherit;
`;

export const Th = styled.th`
  ${body400}
  color: ${p => p.theme.colors.text.secondary};
  text-align: left;
  padding: 8px 10px;
  white-space: nowrap;
`;

export const Td = styled.td`
  padding: 8px 10px;
  border: none;
`;

export const Tr = styled.tr<{ $isHoverable?: boolean }>`
  --bgHover: ${p => p.theme.colors.background.secondary};
  border-radius: 6px;
  background-color: inherit;

  > td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  > td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  ${p => p.$isHoverable && css`
    &:hover, &:focus-within {
      background-color: var(--bgHover);
    }
  `}
`;

export const THead = styled.thead`
  background: inherit;
  position: sticky;
  top: 0;
  z-index: 2;

  > ${Tr} {
    &:hover, &:focus-within {
      background-color: unset;
    }
  }
`;

export const CellContent = styled.div<{ $isClickbable?: boolean; $limitSize?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${p => p.$isClickbable && css`cursor: pointer;`}
  ${p => p.$limitSize && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const CellText = styled.span<{ $isCompact?: boolean }>`
  ${body400}
  color: ${p => p.theme.colors.text.primary};
  white-space: nowrap;
  ${p => p.$isCompact && css`
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;

export const CellSkeleton = styled(Skeleton)`
  height: 20px;
`;
