import { DoctypeType } from '@cycle-app/graphql-codegen';
import { SelectOption, EmojiSize } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';

import { DocTypeIcon } from 'src/components/DocTypeIcon';

import { getDocTypeName, isInsight } from './docType.util';

interface DoctypeBasicInfo {
  name: string;
  id: string;
  emoji: string;
  type: DoctypeType;
}

interface SelectableDoctype {
  id: string;
  selected: boolean;
  value: DoctypeBasicInfo;
}

export const getOptionFromDoctype = (doctype: DoctypeBasicInfo, emojiSize?: EmojiSize): SelectOption => ({
  label: getDocTypeName(doctype),
  value: doctype.id,
  icon: <DocTypeIcon doctype={doctype} size={emojiSize} />,
  selected: undefined,
});

export const getOptionFromSelectableDoctype = (data: SelectableDoctype): SelectOption => ({
  selected: data.selected,
  label: isInsight(data.value) ? 'Quote' : data.value.name,
  value: data.value.id,
  icon: isInsight(data.value) ? <QuoteIcon /> : <DocTypeIcon doctype={data.value} />,
});

export const disableLastSelectedOption = (options: SelectOption[]): SelectOption[] => {
  const selectedCount = options.filter(option => option.selected).length;
  return options.map(option => ({
    ...option,
    disabled: selectedCount > 1 ? false : option.selected,
  }));
};
