import { Button, boxShadowZ1 } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Content = styled(motion.div)`
  flex: 1;
  max-width: 408px;
`;

export const Body = styled(motion.div)`
  text-align: center;
  width: 100%;
`;

export const ButtonOutline = styled(Button)`
  ${boxShadowZ1}
`;
