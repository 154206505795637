import { ellipsis, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const List = styled.ul`
  margin-top: 8px;

  li:not(:first-child) {
    margin-top: 8px;
  }
`;

export const AddButton = styled(Button)`
  margin-top: 8px;
  gap: 4px;
  font-weight: normal;
`;

export const ReadItem = styled.div`
  position: relative;
`;

export const ReadItemButton = styled(Button)`
  --bg: ${p => p.theme.colors.input.bg};
  padding-left: 12px;
  padding-right: 30px;
  height: 40px;
  ${ellipsis}

  > span {
    display: block;
    text-align: left;
    ${ellipsis}
  }
`;

export const ReadItemActions = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1;
`;
