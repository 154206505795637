import { make } from 'src/utils/reactives.util';

type DownloadResult = {
  baseName: string | null;
  startTime: number | null;
  total: number | null;
  loaded: number | null;
  cancel: VoidFunction | null;
  hasError: boolean;
};

const defaultResult: DownloadResult = {
  baseName: null,
  startTime: null,
  total: null,
  loaded: null,
  cancel: null,
  hasError: false,
};

const {
  hookValue,
  getValue,
  setValue,
} = make<{
  downloads: Record<string, DownloadResult>;
}>({
  defaultState: {
    downloads: {},
  },
});

export const useGetDownloads = () => hookValue().downloads;

export const getDownloads = () => getValue().downloads;

export const setDownload = (url: string, result: Partial<DownloadResult>) => {
  setValue({
    downloads: {
      ...getDownloads(),
      [url]: {
        ...defaultResult,
        ...getDownloads()[url],
        ...result,
      },
    },
  });
};

export const removeDownload = (url: string) => {
  const {
    [url]: _,
    ...newDownloads
  } = getDownloads();

  setValue({
    downloads: newDownloads,
  });
};
