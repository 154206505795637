import { Button, typo, boxShadowZ1, ring } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled, { css } from 'styled-components';

export const DocTypeContainer = styled.div`
  padding: 18px;
  border-radius: 8px;
  background: ${p => p.theme.colors.background.secondaryElevated};
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`;

export const DocTypeNameContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const DocTypeName = styled.span`
  display: inline-block;
  max-width: 154px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SetupAutomationButton = styled(Button).attrs({
  size: 'M',
  variant: 'outlined-alt',
})`
  ${boxShadowZ1}
`;

export const ActiveBadge = styled.span`
  display: inline-flex;
  padding: 0 4px 0 6px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: ${p => (p.theme.isDark ? Color.Green800 : Color.Green200)};

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background-color: ${p => (p.theme.isDark ? Color.Green600 : Color.Green800)};
    border-radius: 50%;
  }

  &:after {
    content: 'Active';
    ${typo.caption400}
    color: $${p => (p.theme.isDark ? Color.Grey600 : Color.Grey900)};
  }
`;

const focusCSS = css`
  border-color: hsl(var(--cycle));
  ${ring}
`;
export const Select = styled.button<{ forceFocus: boolean }>`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: ${p => (p.theme.isDark ? Color.Grey850 : Color.White)};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  height: 36px;
  min-width: 200px;
  cursor: pointer;

  width: 100%;
  ${typo.body400};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  ${p => p.forceFocus && focusCSS}
  &:focus {
    ${focusCSS};
  }
`;

export const Caret = styled(CaretIcon)`
  margin-left: auto;
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;
