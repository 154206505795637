import styled from 'styled-components';

export const ImgGradient = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 640px;
  max-height: 700px;
  object-fit: cover;
`;
