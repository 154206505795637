import { UpdateDocStatusDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateDocStatus = () => {
  const [mutate] = useSafeMutation(UpdateDocStatusDocument);

  const updateDocStatus = (docId: string, statusId: string) => {
    return mutate({
      variables: {
        docId,
        statusId,
      },
    });
  };

  return {
    updateDocStatus,
  };
};
