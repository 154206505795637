import { OpenIcon } from '@cycle-app/ui/icons';
import { useEffect, FC } from 'react';

import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal/CreateDocModal';
import { Dropzone } from 'src/components/Dropzone';
import { HomeHeader } from 'src/components/HomeHeader/HomeHeader';
import { HomeViewSources } from 'src/components/HomeViewSources';
import { HomeViewsSection } from 'src/components/HomeViewsSection';
import { CHANGELOG_URL } from 'src/constants/contacts.constants';
import { setLastView } from 'src/reactives/lastView.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { Container, Body, Background, ChangeLogLink, ChangeLogContainer } from './HomeView.styles';
import { HomeViewAsk } from '../../HomeViewAsk';
import { HomeViewMeetings } from '../../HomeViewMeetings';
import { HomeViewNews } from '../../HomeViewNews';
import { HomeViewSettings } from '../../HomeViewSettings';
import { HomeViewSettingsAi } from '../../HomeViewSettingsAi';

export const HomeView: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    setLastView({ section: null });
  }, []);

  return (
    <Container>
      <Background />
      <HomeHeader />
      <Body>
        {isMobile ? <HomeViewsSection /> : <Dropzone />}
        <HomeViewSettings>
          <HomeViewSettingsAi />
          <HomeViewAsk />
          <HomeViewMeetings />
        </HomeViewSettings>
        <HomeViewSources />
        <HomeViewNews />
        <ChangeLogContainer>
          <ChangeLogLink href={CHANGELOG_URL} target="_blank">
            Have a look at Cycle’s changelog – we’ve been shipping weirdly fast
            <OpenIcon />
          </ChangeLogLink>
        </ChangeLogContainer>
      </Body>
      <CreateDocModal />
      {children}
    </Container>
  );
};
