import { Skeleton } from '@cycle-app/ui';

import { Card } from './ReleaseNoteCard.styles';

export const ReleaseNoteSkeleton = ({ style }: { style?: React.HTMLAttributes<HTMLDivElement>['style'] }) => (
  <Card style={style}>
    <Skeleton height={20} width={20} />
    <Skeleton
      height={20}
      style={{
        maxWidth: 200,
        flex: 1,
      }}
    />
    <div style={{ flex: 1 }} />
    <Skeleton height={20} width={40} />
    <Skeleton height={20} width={40} />
  </Card>
);
