import { DocumentAddHoverIcon, ImageAddHoverIcon } from '@cycle-app/ui/icons';
import { ReactNode, FC } from 'react';

import { FILE_SIZE_LIMIT, IMAGE_SIZE_LIMIT } from 'src/constants/upload.constant';
import { getFileSizeWithUnit } from 'src/utils/files.util';

import { Container, Heading, Text, IconContainer } from './DropPlaceholder.styles';

type Props = {
  children?: ReactNode;
  hasClickOption?: boolean;
  isImage?: boolean;
  hasIcon?: boolean;
  extraSubLabel?: string;
};

const DropPlaceholder: FC<React.PropsWithChildren<Props>> = ({
  children,
  hasClickOption = false,
  isImage = false,
  hasIcon = true,
  extraSubLabel = '',
}) => (
  <Container canClick={hasClickOption}>
    {hasIcon && (
      <IconContainer>
        {isImage ? <ImageAddHoverIcon /> : <DocumentAddHoverIcon />}
      </IconContainer>
    )}
    <Heading>
      {hasClickOption && 'Click or '}
      drop here to upload
    </Heading>
    <Text>
      {extraSubLabel}
      Maximum size:
      {' '}
      {getFileSizeWithUnit(isImage ? IMAGE_SIZE_LIMIT : FILE_SIZE_LIMIT, true)}
    </Text>
    {children}
  </Container>
);

export default DropPlaceholder;
