import { useGetLearnMorePopup, setLearnMorePopup } from 'src/reactives';

export const useInsightTour = () => {
  return {
    open: () => setLearnMorePopup('insights', true),
    close: () => setLearnMorePopup('insights', false),
  };
};

export const useIsInsightTourActive = () => {
  const isOpen = useGetLearnMorePopup('insights');

  return {
    isActive: isOpen,
  };
};
