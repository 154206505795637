import { typo, Button, boxShadowZ1 } from '@cycle-app/ui';
import styled from 'styled-components';

export const WorkspaceBox = styled.div`
  align-items: center;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-top: 24px;
  gap: 12px;
`;

export const Name = styled.div`
  ${typo.body500}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const StyledCreateButton = styled(Button)`
  ${boxShadowZ1}
  margin-top: 24px;
  height: 36px;
`;
