import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const Content = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  background-color: ${p => p.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
`;
