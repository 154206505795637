import { ViewType } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { KanbanIcon, ListIcon, DashboardIcon } from '@cycle-app/ui/icons';
import { forwardRef } from 'react';

import { ViewTypeLabel } from './ViewsList.styles';

type Props = {
  viewType: ViewType | 'dashboard';
  isSmall: boolean;
};

export const ItemViewType = ({
  viewType, isSmall,
}: Props) => (
  <>
    <Tooltip
      content={viewTypeName(viewType)}
      placement="top"
      withWrapper={false}
      disabled={!isSmall}
    >
      <ViewTypeIcon viewType={viewType} />
    </Tooltip>
    <ViewTypeLabel>{viewTypeName(viewType)}</ViewTypeLabel>
  </>
);

const viewTypeName = (viewType: ViewType | 'dashboard') => {
  if (viewType === ViewType.Kanban) return 'Kanban';
  if (viewType === ViewType.List) return 'List';
  return 'Dashboard';
};

const ViewTypeIcon = forwardRef<SVGSVGElement, { viewType: ViewType | 'dashboard' }>(({ viewType }, ref) => {
  if (viewType === ViewType.Kanban) return <KanbanIcon ref={ref} size={14} />;
  if (viewType === ViewType.List) return <ListIcon ref={ref} size={14} />;
  return <DashboardIcon ref={ref} size={14} />;
});
