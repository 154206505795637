import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CommentAltIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4 2.60039C3.52261 2.60039 3.06477 2.79003 2.72721 3.1276C2.38964 3.46516 2.2 3.923 2.2 4.40039V9.20039C2.2 9.67778 2.38964 10.1356 2.72721 10.4732C3.06477 10.8107 3.52261 11.0004 4 11.0004H5.6C5.75913 11.0004 5.91174 11.0636 6.02426 11.1761L8 13.1519L9.97574 11.1761C10.0883 11.0636 10.2409 11.0004 10.4 11.0004H12C12.4774 11.0004 12.9352 10.8107 13.2728 10.4732C13.6104 10.1356 13.8 9.67778 13.8 9.20039V4.40039C13.8 3.923 13.6104 3.46516 13.2728 3.1276C12.9352 2.79003 12.4774 2.60039 12 2.60039H4ZM1.87868 2.27907C2.44129 1.71646 3.20435 1.40039 4 1.40039H12C12.7956 1.40039 13.5587 1.71646 14.1213 2.27907C14.6839 2.84168 15 3.60474 15 4.40039V9.20039C15 9.99604 14.6839 10.7591 14.1213 11.3217C13.5587 11.8843 12.7956 12.2004 12 12.2004H10.6485L8.42426 14.4247C8.18995 14.659 7.81005 14.659 7.57574 14.4247L5.35147 12.2004H4C3.20435 12.2004 2.44129 11.8843 1.87868 11.3217C1.31607 10.7591 1 9.99604 1 9.20039V4.40039C1 3.60474 1.31607 2.84168 1.87868 2.27907Z" fill="#C4C4C4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.20003 5.20059C4.20003 4.86922 4.46866 4.60059 4.80003 4.60059H11.2C11.5314 4.60059 11.8 4.86922 11.8 5.20059C11.8 5.53196 11.5314 5.80059 11.2 5.80059H4.80003C4.46866 5.80059 4.20003 5.53196 4.20003 5.20059Z" fill="#C4C4C4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.20003 8.40078C4.20003 8.06941 4.46866 7.80078 4.80003 7.80078H9.60003C9.9314 7.80078 10.2 8.06941 10.2 8.40078C10.2 8.73215 9.9314 9.00078 9.60003 9.00078H4.80003C4.46866 9.00078 4.20003 8.73215 4.20003 8.40078Z" fill="#C4C4C4" />
  </Svg>
);
