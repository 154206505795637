import { Tooltip } from '@cycle-app/ui';

import { getDocType } from 'src/reactives/docTypes.reactive';

import { doctypeHasAutomation } from '../../utils/doctype.automation.util';
import { DocTypeIconInline } from '../DocTypeIcon';
import { AddNewItemLineButton } from './AddNewItemLineButton';
import {
  CREATE_VALUE_PREFIX,
  DOC_TYPES_AMOUNT_LIMIT,
} from './DocSearchDropdown.constants';
import { NewItemContainer } from './DocSearchDropdown.styles';
import { cropDocTypes } from './DocSearchDropdown.utils';
import { OtherDocTypeButton, OtherDocTypeButtonProps } from './OtherDocTypesButton';

export type AddNewItemLineProps = {
  docTypeIdLoading: string;
  doctypeAutomations: Record<string, boolean>;
  search: string;
  onDoctypeAutomationsChange: (doctypeId: string, withLinear: boolean) => void;
  showLinearAutoCreate?: boolean;
} & Omit<OtherDocTypeButtonProps, 'isLoading'>;

export const AddNewItemLine = ({
  docTypeIdLoading,
  docTypes,
  doctypeAutomations,
  getItemProps,
  isHoverDisabled,
  search,
  selected,
  onSelect,
  onDoctypeAutomationsChange,
  showLinearAutoCreate,
}: AddNewItemLineProps) => {
  const docTypesToDisplay = docTypes.length > DOC_TYPES_AMOUNT_LIMIT
    ? cropDocTypes(docTypes)
    : docTypes;
  const otherDocTypes = docTypes.length > DOC_TYPES_AMOUNT_LIMIT
    ? cropDocTypes(docTypes, true)
    : [];

  return (
    <NewItemContainer $hasFlexNone={!!otherDocTypes.length}>
      {docTypesToDisplay.map(docType => {
        const doctype = getDocType(docType.id);
        const value = `${CREATE_VALUE_PREFIX}${docType.id}`;
        return doctype && (
          <Tooltip
            key={docType.id}
            placement="top"
            content={(
              <>
                Create
                <DocTypeIconInline doctype={doctype} size={14} style={{ margin: '0 4px' }} />
                {search}
              </>
            )}
          >
            <AddNewItemLineButton
              doctype={doctype}
              getItemProps={getItemProps}
              isHoverDisabled={isHoverDisabled}
              isLoading={docTypeIdLoading === docType.id}
              isSelected={selected === value}
              onWithLinearChange={onDoctypeAutomationsChange}
              search={search}
              showLinearAutoCreate={showLinearAutoCreate && doctypeHasAutomation(docType.id)}
              value={value}
              // (undefined || true) = checked
              withLinearChecked={doctypeAutomations[docType.id] !== false}
            />
          </Tooltip>
        );
      })}
      {!!otherDocTypes.length && (
        <OtherDocTypeButton
          docTypes={otherDocTypes}
          getItemProps={getItemProps}
          isHoverDisabled={isHoverDisabled}
          isLoading={!!docTypeIdLoading && !!otherDocTypes.find(d => d.id === docTypeIdLoading)}
          selected={selected}
          onSelect={onSelect}
          doctypeAutomations={doctypeAutomations}
          onDoctypeAutomationsChange={onDoctypeAutomationsChange}
          showLinearAutoCreate={showLinearAutoCreate}
        />
      )}
    </NewItemContainer>
  );
};
