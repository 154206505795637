import { ToggleScheduledBotDocument, ToggleScheduledBotMutation } from '@cycle-app/graphql-codegen';

import { useProductBase } from '../api/useProduct';
import useSafeMutation from '../useSafeMutation';

interface ToggleBotParams {
  eventId: string;
  scheduled: boolean;
  force?: boolean;
  optimistic?: boolean;
}

export const useMeetingToggleBot = (options?: { onCompleted?: (result: ToggleScheduledBotMutation) => void }) => {
  const product = useProductBase();
  const [toggleBotMutation, { loading: isLoading }] = useSafeMutation(ToggleScheduledBotDocument, {
    onCompleted: result => {
      if (options?.onCompleted && result) {
        options.onCompleted(result);
      }
    },
  });

  const toggleBot = ({
    eventId, scheduled, force, optimistic = true,
  }: ToggleBotParams) => !!product?.id && toggleBotMutation({
    variables: {
      productId: product.id,
      eventId,
      scheduled,
      force,
    },
    ...optimistic && {
      optimisticResponse: {
        toggleScheduledBot: {
          id: eventId,
          botScheduled: scheduled,
        },
      },
    },
  });

  return {
    toggleBot,
    isLoading,
  };
};
