import { ring2 } from '@cycle-app/ui';
import styled from 'styled-components';

import { CommentEditor } from 'src/components/Editor/Editors/CommentEditor';

export const Container = styled.div<{ isFocused: boolean }>`
  ${ring2}
  --ring-color: ${p => (p.isFocused ? 'hsla(var(--cycle), 0.5)' : 'transparent')};

  margin: 0px 16px 8px;
  border-radius: 4px;

  background: ${p => (p.isFocused ? p.theme.colors.docPanel.bg : p.theme.colors.input.bg)};
  :hover {
    background: ${p => (p.isFocused ? p.theme.colors.docPanel.bg : p.theme.colors.input.hover)};
  }
`;

export const StyledCommentEditor = styled(CommentEditor)`
  width: 100%;
  overflow: hidden;
  .ProseMirror {
    padding: 6px;
    cursor: text;
  }
`;

export const Buttons = styled.div`
  padding: 3px 6px 6px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
