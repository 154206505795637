import { CycleLogo, typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(NextButton).attrs({ variant: 'secondary' })`
  margin-top: 30px;
  font-weight: 500;
`;

interface StyledLogoLoaderProps {
  $isLoading?: boolean;
}

export const StyledLogoLoader = styled(CycleLogo) <StyledLogoLoaderProps>`
  ${p => p.$isLoading && css`--color1: ${p.theme.colors.lightOnboarding.doneLoader.color};`}
  margin-bottom: 36px;

  path {
    transition: color .6s;
  }
`;

export const Body = styled.div`
  position: relative;
`;

export const BodyDefault = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const SubTitle = styled.h2`
  ${typo.headerSmall}
  font-weight: normal;
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 8px;
  max-width: 488px;
`;
