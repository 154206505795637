import {
  typo, Tag, Button, ring, ring2, Spinner, truncate,
} from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  ${typo.body400};
  width: 100%;
  padding: 8px 30px 8px 8px;
  height: 32px;
  border-radius: 4px;
  border: none;
  background: inherit;

  ${ring}
  --ring-color: ${p => p.theme.colors.border.primary};
  
  &:focus {
    ${ring2}
    --ring-color: hsla(var(--cycle), 0.15);
  }
`;

export const Clear = styled.div`
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
`;

export const DropdownLayerStyled = styled(DropdownLayer)`
  border-radius: 8px;
  overflow: hidden;
`;

export const DropdownResult = styled.div`
  padding: 8px 0;
  width: 550px;
  background-color: ${p => p.theme.colors.background.primary};
`;

export const TagStyled = styled(Tag)`
  ${typo.caption500};
`;

export const Line = styled.div<{ selected: boolean }>`
  padding: 4px 12px;
  min-height: 32px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.colors.background.secondary};
  }

  ${p => p.selected && css`
    background-color: ${p.theme.colors.background.secondary};
  `};

  .highlight {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    mark {
      background-color: ${p => p.theme.colors.mark.bg};
    }
  }
`;

export const CreateSpan = styled.span`
  ${typo.body500};
`;

export const MarkNewDoc = styled.mark`
  background-color: hsla(var(--cycle), 0.1);
  white-space: nowrap;
`;

export const AddButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${typo.caption400}
  align-self: flex-start;
  color: ${p => p.theme.colors.text.disabled};
  padding: 2px 8px;

  &:hover, &:focus {
    background: ${p => p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200};
    &, > svg {
      color: ${p => p.theme.isDark ? ColorTheme.Grey200 : ColorTheme.Grey700};
    }
  }

  &:active {
    background: ${p => p.theme.isDark ? ColorTheme.Grey750 : ColorTheme.Grey300};
  }
`;

export const SectionTitle = styled.div`
  padding: 0 12px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 10px;
  ${typo.caption500}
  color: ${p => p.theme.colors.text.disabled};
`;

export const TitleSpinner = styled(Spinner)`
  width: 12px;
  height: 12px;
`;

export const NotFound = styled.div`
  padding: 0 12px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 10px;
  ${typo.caption400}
  color: ${p => p.theme.colors.text.disabled};
`;

export const DocTitle = styled.div`
  ${truncate}
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ResultLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Content = styled.div`
  color: ${p => p.theme.colors.text.secondary};
`;
