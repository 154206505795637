import { GroupsFragment } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { defaultPagination } from 'src/utils/pagination.util';

import { useMoreDocsInGroupNoFiltered } from './useMoreDocsInGroup';

interface MoreData {
  exec: () => Promise<void> | null;
  loading: boolean;
  pageInfo: GroupsFragment['docGroups']['pageInfo'] | null;
}

interface UseMoreBoardData {
  moreDocs: MoreData;
  moreGroups: MoreData;
}

export default function useMoreBoardData(): UseMoreBoardData {
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const fetchMore = useBoardConfig(ctx => ctx.fetchMore);

  const groupIdFromWithoutGroup =
    boardConfig?.docQuery.__typename === 'BoardQuery'
      ? boardConfig.docQuery.docGroup.id
      : null;

  const { moreDocs } = useMoreDocsInGroupNoFiltered(groupIdFromWithoutGroup ?? '_');

  const {
    execute: executeMoreDocs,
    loading: loadingMoreDocs,
  } = useAsyncCallback(async () => {
    if (!boardId || boardConfig?.docQuery.__typename !== 'BoardQuery') return;

    const cursor = boardConfig?.docQuery.docGroup.docs.pageInfo?.endCursor ?? null;
    if (!cursor) return;

    await moreDocs(cursor);
  });

  const {
    execute: moreGroups,
    loading: loadingMoreGroups,
  } = useAsyncCallback(async () => {
    if (!boardId || loadingMoreGroups || boardConfig?.docQuery.__typename !== 'BoardQueryWithGroupBy') return;

    const cursorGroups = boardConfig?.docQuery.docGroups.pageInfo?.endCursor ?? null;
    if (!cursorGroups) return;
    await fetchMore({
      variables: {
        ...defaultPagination,
        id: boardId,
        cursorGroups,
      },
    });
  });

  return useMemo(() => ({
    moreDocs: {
      exec: () => {
        if (loadingMoreDocs) return null;
        return executeMoreDocs();
      },
      loading: loadingMoreDocs,
      pageInfo: boardConfig?.docQuery.__typename === 'BoardQuery'
        ? boardConfig?.docQuery.docGroup.docs.pageInfo ?? null
        : null,
    },
    moreGroups: {
      exec: () => {
        if (loadingMoreGroups) return null;
        return moreGroups();
      },
      loading: loadingMoreGroups,
      pageInfo: boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy'
        ? boardConfig?.docQuery.docGroups.pageInfo ?? null
        : null,
    },
  }), [executeMoreDocs, loadingMoreDocs, boardConfig, moreGroups, loadingMoreGroups]);
}
