import { AddDocUserMentionDocument } from '@cycle-app/graphql-codegen';
import { EditorState } from '@tiptap/pm/state';
import { useCallback } from 'react';

import {
  BULLET_LIST_TYPE, CHECK_LIST_TYPE, ORDERED_LIST_TYPE, PARAGRAPH_TYPE, BLOCKQUOTE, HEADING_TYPE,
} from 'src/constants/editor.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import useUploadFile from 'src/hooks/useUploadFile';
import { addToaster } from 'src/utils/toasters.utils';

const SUPPORTED_TURN_INTO_MARKS = [BULLET_LIST_TYPE, CHECK_LIST_TYPE, ORDERED_LIST_TYPE, HEADING_TYPE];
const SUPPORTED_TURN_INTO_MULTI_LINES = [HEADING_TYPE, PARAGRAPH_TYPE];

export interface SelectedTextData {
  selectionType: string | null;
  selectionValues: string[];
  error?: string;
}

export default function useEditorLogic(docId?: string | null | undefined) {
  const {
    onUpload, isUploading,
  } = useUploadFile();

  const [addDocUserMention] = useSafeMutation(AddDocUserMentionDocument);

  const onError = useCallback((message: string) => addToaster({ message }), []);

  const onUserMentioned = useCallback(async (userId: string) => {
    if (docId) {
      await addDocUserMention({
        variables: {
          docId,
          userId,
        },
      });
    }
  }, [docId, addDocUserMention]);

  const getDocTitlesFromSelectedText: (state: EditorState) => SelectedTextData = useCallback((state) => {
    const selectedSlice = state.selection.content();
    /**
     * If no children -> No selection
     */
    if (!selectedSlice.content.childCount) {
      return {
        selectionType: null,
        selectionValues: [],
        error: 'no-selection',
      };
    }

    /**
     * If many nodes it means that user select many marks at the same time
     * e.g.
     * - a paragraph + a bullet-list
     * - an image
     */
    const selectionNodesCount = selectedSlice.content.childCount || 0;

    if (selectionNodesCount > 1) {
      const textContents: string[] = [];
      selectedSlice.content.forEach((node) => {
        if (SUPPORTED_TURN_INTO_MULTI_LINES.includes(node.type.name)) {
          const text = node.textContent.trim();
          if (text) textContents.push(text);
        }
      });
      return textContents.length === selectionNodesCount
        ? {
          selectionType: PARAGRAPH_TYPE,
          // By design get one value from multi-line.
          selectionValues: [textContents.join(' ')],
        } : {
          selectionType: null,
          selectionValues: [],
          error: 'not-supported-multi-selection',
        };
    }

    const selectedNode = selectedSlice.content.firstChild;

    if (!selectedNode) {
      return {
        selectionType: null,
        selectionValues: [],
        error: 'not-valid-selection',
      };
    }

    const titles: string[] = [];

    if (selectedNode.type.name === PARAGRAPH_TYPE || selectedNode.type.name === BLOCKQUOTE) {
      const text = state.doc.textBetween(
        state.selection.from,
        state.selection.to,
      ).trim();
      if (text) titles.push(text);
    } else if (SUPPORTED_TURN_INTO_MARKS.includes(selectedNode.type.name)) {
      let errorInChildren = '';
      selectedNode.content.forEach(node => {
        if (node.content.childCount > 1) {
          errorInChildren = 'no-nested-lists';
        }
        const text = node.textContent.trim();
        if (text) titles.push(text);
      });

      if (errorInChildren) {
        return {
          selectionType: null,
          selectionValues: [],
          error: errorInChildren,
        };
      }
    }

    return {
      selectionType: selectedNode.type.name,
      selectionValues: titles,
    };
  }, []);

  return {
    getDocTitlesFromSelectedText,
    isUploading,
    onError,
    onUpload,
    onUserMentioned,
  };
}
