import { MateFragment, MeFragment, UserJourney } from '@cycle-app/graphql-codegen';
import { Avatar, Warning } from '@cycle-app/ui';
import { capitalize } from '@cycle-app/utilities';
import { isPresent } from 'ts-is-present';

export const getUserOptions = (users: MateFragment[], docTypeName: string | undefined, me?: MeFragment, showWarnings = true) => users
  .map(user => ({
    label: getUserLabel(user, user.id === me?.id),
    value: user.id,
    icon: <Avatar user={user} size={20} />,
    end: showWarnings && !user._compatibleWithBoardConfig && docTypeName &&
      <Warning tooltip={`The ${docTypeName ? docTypeName.toLowerCase() : 'doc'} will leave the view if you choose this value`} />,
  }));

export const getUserLabel = (user?: MateFragment, isMe?: boolean) => {
  const label = (
    user?.firstName || user?.lastName
      ? [user?.firstName, user?.lastName].filter(isPresent).join(' ')
      : user?.email ?? ''
  );
  return isMe ? [label, '(me)'].join(' ') : label;
};

export const getUserLabelShortname = (user?: MateFragment, isMe?: boolean) => {
  const lastName = user?.lastName?.trim()?.[0];
  const label = (
    user?.firstName || lastName
      ? [user?.firstName, lastName ? `${lastName}.` : ''].filter(isPresent).join(' ')
      : user?.email ?? ''
  );
  return isMe ? [label, '(me)'].join(' ') : label;
};

export const isCycleUser = (
  user: { email?: string | null } | undefined,
  { exclude }: { exclude: string[] } = { exclude: [] },
) => user?.email?.endsWith('@cycle.app') && !exclude.some(s => user.email?.includes(s));

export const isUserOnboarded = (user?: { userJourney?: UserJourney | null } | null) => (
  !!user?.userJourney && user.userJourney !== UserJourney.Onboarding
);

export const hasUserAppAccess = (user?: { userJourney?: UserJourney | null } | null) => (
  !!user?.userJourney && user.userJourney === UserJourney.Done
);

export const isUserOnboardedWithoutWorkspace = (
  me: MeFragment | null | undefined,
) => me?.products?.edges.length === 0 && me?.userJourney === UserJourney.Done;

export const assigneeLabel = ({
  isFeedback, uppercase, plural,
}: {
  isFeedback?: boolean;
  uppercase?: boolean;
  plural?: boolean;
} = {}) => {
  let label = isFeedback ? 'reporter' : 'assignee';
  if (plural) label += 's';
  return uppercase ? capitalize(label) : label;
};
