import { textGradient } from '@cycle-app/ui';
import styled from 'styled-components';

import DocAction from 'src/components/DocAction/DocAction';

import { DocHierarchyInsight } from '../DocHierarchy/DocHierarchyInsight';

export const StyledDocHierarchyInsight = styled(DocHierarchyInsight)`
  padding: 16px;
  width: 430px;
  max-height: 416px;
`;

export const QuoteAction = styled(DocAction)`
  --bgHover: var(--bg);
  --bgActive: var(--bg);
  background: linear-gradient(90.4deg, rgba(174, 143, 255, 0.13) 0.38%, rgba(0, 210, 243, 0.13) 63.15%, rgba(77, 237, 141, 0.13) 120.18%);
  span {
    ${textGradient}
  }
`;
