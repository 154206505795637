import { useHistory } from 'react-router-dom';

import useQueryParams from 'src/hooks/useQueryParams';

export const useSetProductSlug = () => {
  const history = useHistory();
  const query = useQueryParams();
  return (productSlug?: string) => {
    if (!productSlug) return;
    query.set('product', productSlug);
    history.replace({ search: query.toString() });
  };
};
