import { OrderByDirection } from '@cycle-app/graphql-codegen';

export const SIZE_GROUPS_PAGE = 10; // TODO: change to 8 when pagination is implemented
export const SIZE_DOCS_PAGE = 15;
export const SIZE_ITEMS_HIERARCHY = 5;
export const SIZE_CUSTOMERS = 20;
export const SIZE_CUSTOMER_DOCS = 10;
export const SIZE_COMPANIES = 20;
export const SIZE_COMPANY_DOCS = 10;

export const SIZE_SWIMLANES_PAGE = 6;
export const SIZE_DOCS_SWIMLANGE_GROUP = 10;

export const SIZE_NOTIFICATIONS_PAGE = 10;

export const defaultPagination = {
  size: SIZE_DOCS_PAGE,
  sizeSwimlaneGroupDocs: SIZE_DOCS_SWIMLANGE_GROUP,
  sizeGroups: SIZE_GROUPS_PAGE,
  sizeSwimlaneGroups: SIZE_SWIMLANES_PAGE,
  cursor: '',
  cursorGroup: '',
  cursorGroups: '',
  cursorSwimlaneGroups: '',
};

export const defaultGroupPagination = {
  size: SIZE_DOCS_PAGE,
  cursorGroup: '',
};

export const defaultHierarchyPagination = {
  size: SIZE_ITEMS_HIERARCHY,
  cursor: '',
  direction: OrderByDirection.Asc,
};

export const hierarchyPaginationMostRecent = {
  size: SIZE_ITEMS_HIERARCHY,
  cursor: '',
  direction: OrderByDirection.Desc,
};

export const defaultCustomerDocsPagination = {
  size: SIZE_CUSTOMER_DOCS,
  cursor: '',
};

export const defaultCompaniesPagination = {
  size: SIZE_COMPANIES,
  cursor: '',
};

export const defaultCustomersPagination = {
  size: SIZE_CUSTOMERS,
  cursor: '',
};

export const defaultPageInfo = {
  hasPreviousPage: false,
  hasNextPage: false,
  startCursor: null,
  endCursor: null,
};
