import { typo, ActionButton, Input, Button } from '@cycle-app/ui';
import { DownIcon, WebPageCloseIcon } from '@cycle-app/ui/icons';
import { ColorTheme } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const PageContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  > * + * {
    border-left: 1px solid ${p => p.theme.colors.releases.border};
  }
`;

export const Section = styled(motion.div)`
  flex: 1;
  height: 100%;
  overflow: hidden;
  background: ${p => p.theme.colors.releases.bg};
`;

export const SectionContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 16px 24px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  h1 {
    ${typo.headerLight}
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderButton = styled(ActionButton)`
  width: 28px;
  height: 28px;
`;

export const SearchInput = styled(Input)`
  input {
    height: 28px;
  }
`;

// Create changelog banner
export const CreateChangelogBannerContainer = styled.div`
  background-color: ${p => p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Blue100};
  color: ${p => p.theme.colors.text.primary};
  border-radius: 8px;
  padding: 12px;
  margin: 8px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  flex-wrap: wrap;
`;

export const CreateChangelogBannerIcon = styled(WebPageCloseIcon)`
  flex-shrink: 0;
`;

export const CreateChangelogBannerButton = styled(Button)`
  margin-left: auto;
`;

export const LiveChangelogDropdownTrigger = styled.button`
  padding: 2px 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: ${p => p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey100};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${p => p.theme.colors.text.primary};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${p => p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200};
  }
`;

export const ChangelogLiveIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${ColorTheme.Green700};
  flex-shrink: 0;
`;

export const ChangelogLiveCaret = styled(DownIcon)`
  color: ${p => p.theme.colors.text.disabled}
`;

export const LiveChangelogDropdownUrlContent = styled.div`
  min-width: 250px;
  color: ${p => p.theme.colors.text.secondary};
  border-top: 1px solid  ${p => p.theme.colors.dropdown.sep};
  margin: 0px -8px;
  padding-top: 8px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
`;
