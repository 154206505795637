import { DoctypeFragment } from '@cycle-app/graphql-codegen/generated';
import { Input, Button } from '@cycle-app/ui';
import { getPairSplitArray } from '@cycle-app/utilities';
import { useRef, FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { CreateDocMentionFormValues, CreateDocMentionFormCustomField } from 'src/types/doc.types';
import { FormGroup } from 'src/utils/form.util.styles';

import { AssigneeField } from './AssigneeField/AssigneeField';
import { CustomField } from './CustomField/CustomField';
import { Form, FormFooter, SelectionWarn } from './DocMentionCreationForm.styles';
import { ParentField } from './ParentField/ParentField';

const getCustomFieldFormValues = (customFields: CreateDocMentionFormCustomField[]) => {
  const values: Record<string, CreateDocMentionFormCustomField['inheritAttributeValueFromDocContext']> = {};

  customFields.forEach(customField => {
    values[customField.id] = customField.inheritAttributeValueFromDocContext || undefined;
  });

  return values;
};

type Props = {
  docType: DoctypeFragment;
  isLoading: boolean;
  onSubmit: (values: CreateDocMentionFormValues) => void;
  submitLabel?: string;
  titles: string[];
  customFields: CreateDocMentionFormCustomField[];
  description?: string;
  hiddenFields?: ('assignee' | 'parent' | 'description')[];
  onCancel?: VoidFunction;
};

export const DocMentionCreationForm: FC<React.PropsWithChildren<Props>> = ({
  docType,
  isLoading,
  onSubmit,
  submitLabel = 'Create',
  titles,
  customFields,
  description,
  hiddenFields = [],
  onCancel,
}) => {
  const isDisabled = !titles.length;
  const showAssignee = !hiddenFields.includes('assignee');
  const showParent = !hiddenFields.includes('parent');
  const showDescription = !hiddenFields.includes('description');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateDocMentionFormValues>({
    defaultValues: {
      parentDocId: undefined,
      title: titles[0] || '',
      assignee: undefined,
      description: description ?? '',
      ...getCustomFieldFormValues(customFields),
    },
  });
  const submitButton = useRef<HTMLButtonElement>(null);

  useAppHotkeys('enter', () => {
    submitButton.current?.click();
  });

  const fieldsByPairs = useMemo(() => {
    if (!customFields.length) return [];

    return getPairSplitArray(customFields);
  }, [customFields]);

  if (isDisabled) {
    return <SelectionWarn>We are not able to create docs from your current selection.</SelectionWarn>;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {titles.length === 1 && (
        <>
          {showParent && (
            <ParentField
              control={control}
              docType={docType}
            />
          )}
          <Input
            id="title"
            label="Title"
            autoComplete="off"
            {...register('title')}
            error={errors.title?.message}
          />
        </>
      )}

      {showAssignee && (
        <AssigneeField
          control={control}
          docTypeName={docType.name}
          docTypeType={docType.type}
        />
      )}

      {showDescription && (
        <Input
          id="description"
          label="Description"
          autoComplete="off"
          {...register('description')}
          error={errors.description?.message}
        />
      )}

      {fieldsByPairs.map((fieldsPair) => (
        <FormGroup key={fieldsPair[0]?.id}>
          {fieldsPair.map((field) => (
            <CustomField key={field.id} control={control} field={field} />
          ))}
        </FormGroup>
      ))}

      <FormFooter>
        {onCancel && (
          <Button
            size="M"
            onClick={onCancel}
            variant="secondary"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          size="M"
          isLoading={isLoading}
          autoFocus
          ref={submitButton}
          disabled={isDisabled}
        >
          {submitLabel}
        </Button>
      </FormFooter>
    </Form>
  );
};
