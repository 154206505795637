import { useEffect, useState } from 'react';

import { Loader } from './DropzoneMini.styles';

const ESTIMATED_LOADING_TIME = 3000;
let t0: number | null = null;

const exponentialProgress = (dt: number) => 1 - Math.exp(-dt / ESTIMATED_LOADING_TIME);

type Props = {
  isLoading: boolean;
};

export const IslandLoader = ({ isLoading }: Props) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let rafId: number | null = null;

    const loop = () => {
      if (t0 === null) return;
      setProgress(exponentialProgress(Date.now() - t0));
      rafId = requestAnimationFrame(loop);
    };

    if (isLoading) {
      if (t0 === null) t0 = Date.now();
      loop();
    } else {
      t0 = null;
    }

    return () => {
      if (rafId) cancelAnimationFrame(rafId);
    };
  }, [isLoading]);

  if (!isLoading) return null;

  return (
    <Loader
      style={{
        width: `${progress * 100}%`,
      }}
    />
  );
};
