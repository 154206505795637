import { ActionButton, typo, boxShadowZ1, SelectLine } from '@cycle-app/ui';
import styled from 'styled-components';

export const CreateCustomerLine = styled(SelectLine)`
  color: ${p => p.theme.colors.text.secondary};
  margin-left: -8px;
  margin-right: -8px;

  svg {
    height: 8px;
    width: 8px;
  }
`;

export const OpenCustomerButton = styled(ActionButton)`
  --size: 10px;
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  color: ${p => p.theme.colors.text.secondary};
  gap: 4px;
  height: 20px;
  ${typo.caption400}
  ${boxShadowZ1}
`;
