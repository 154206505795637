import { AddOn } from '../types/addOn.types';

// Monthly
export const FEE_AMOUNT_M_FLAT = 160;
export const FEE_AMOUNT_M_PER_UNIT = 60;
export const FEE_AMOUNT_M_ADDON = 500;

// Annual
// You can find the rates in stripe dashboard > product > "Pro".
export const FEE_AMOUNT_Y_FLAT = 1560;
export const FEE_AMOUNT_Y_PER_UNIT = 600;
export const FEE_AMOUNT_Y_ADDON = 200;

export const getAmountFlat = ({
  isAnnual, isYC,
}: { isAnnual: boolean; isYC: boolean }) => {
  if (isYC) {
    return isAnnual ? 108 : 135;
  }
  return (
    isAnnual ? FEE_AMOUNT_Y_FLAT / 12 : FEE_AMOUNT_M_FLAT
  );
};

export const getAmountAddon = (isAnnual: boolean) => (
  isAnnual ? FEE_AMOUNT_Y_ADDON : FEE_AMOUNT_M_ADDON
);

export const getAmountPerUnit = (isAnnual: boolean) => (
  isAnnual ? FEE_AMOUNT_Y_PER_UNIT / 12 : FEE_AMOUNT_M_PER_UNIT
);

type Params = {
  billing: { nbMakers: number };
  addOns: AddOn[];
  isAnnual: boolean;
  isYC: boolean;
};

export const getExpectedStandardFee = (parms: Params) => {
  const {
    billing, addOns, isAnnual, isYC,
  } = parms;
  // 3 addons are free in YC deal.
  const addOnsCount = isYC ? Math.max(0, addOns.length - 3) : addOns.length;
  return (
    getAmountFlat({
      isAnnual,
      isYC,
    }) +
    billing.nbMakers * getAmountPerUnit(isAnnual) +
    addOnsCount * getAmountAddon(isAnnual)
  );
};
