import { OperatorIsInOrNot } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const useFirstStatusinView = () => {
  const filterProperties = useBoardConfig(ctx => ctx.boardConfig?.filterProperties);
  for (const p of nodeToArray(filterProperties)) {
    if (p.__typename === 'FilterPropertyRuleStatus') {
      const values = nodeToArray(p.statusRule.values);
      return values.find(v => (p.statusRule.operator === OperatorIsInOrNot.Is ? v.selected : !v.selected))?.value;
    }
  }
  return undefined;
};
