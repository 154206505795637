import { ShinyItem } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Top = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px; /* Just in case the RealtimeUsers are huge and touch the title */
  height: 52px; /* Prevents a layout jump as the content load asynchronously */

  padding: 12px 24px 0;
  flex-grow: 0;
  overflow-x: auto;

  ${mobile} {
    background-color: ${p => p.theme.colors.background.primary};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    padding: 6px 22px 10px 16px;
    margin-bottom: 8px;
  }
`;

export const Side = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  > svg {
    display: none;
    transform: rotate(90deg);
    color: ${p => p.theme.colors.text.disabled};

    ${mobile} {
      display: initial;
      width: 16px;
      height: 16px;
    }
  }
`;

export const RightSide = styled(Side)`
  justify-content: flex-end;
  align-items: center;
  overflow: auto; /* This is required in order to let Realtime handle the overflow */
  gap: 12px;
`;

export const TitleSkeleton = styled(ShinyItem)`
  width: 200px;
`;

export const BoardConfigSkeleton = styled(ShinyItem)`
  width: 400px;
`;

