import { SearchIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import {
  useMemo, ReactNode, useCallback, useRef, CSSProperties, MouseEvent,
} from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { useFilteredOptions } from './SelectPanel.hooks';
import {
  Footer,
  Container,
  InputStyled,
  List,
  Title,
  FooterButton,
  containerPadding,
  titleLineHeight,
  Separator,
  CreateOptionLabel,
  StickyHeader,
} from './SelectPanel.styles';
import { InfiniteScroll, InfiniteScrollProps } from '../../InfiniteScroll/InfiniteScroll';
import { Spinner } from '../../Spinner/Spinner.styles';
import { TextHighlighter } from '../../TextHighlighter/TextHighlighter';
import { Tooltip } from '../../Tooltip/Tooltip';
import { Warning } from '../../Warning/Warning';
import { SelectOption } from '../option.type';
import { SelectLine, SelectType } from '../SelectLine/SelectLine';

export type OnSelectOptionChange<T = unknown> = (selectedOption: SelectOption<T>, event?: MouseEvent) => void;

export type OnSelectOptionsChangeMetaData = {
  addedOptions?: never;
  removedOptions: SelectOption[];
} | {
  addedOptions: SelectOption[];
  removedOptions?: never;
};
export type OnSelectOptionsChange = (newOptions: SelectOption[], metaData: OnSelectOptionsChangeMetaData) => void;

type SelectProps<T = unknown> =
  | {
    isMulti?: false;
    isRequired?: never;
    selectedValue?: string;
    onOptionChange: OnSelectOptionChange<T>;
    selectedOptions?: never;
    selectedCount?: never;
    onOptionsChange?: never;
    onOptionRemoved?: never;
    withSelectAll?: never;
    selectAllLabel?: never;
    onSelectAll?: never;
    onSelectOption?: never;
    onUnselectOption?: never;
    onRemoveOption?: never;
    withUnselectAll?: never;
    unselectAllLabel?: never;
    onUnselectAll?: never;
    toggleAllValuesVariant?: never;
    selectType?: SelectType;
    hideChecks?: boolean;
    clearSearchOnCreate?: boolean;
  }
  | {
    isMulti: true;
    isRequired?: boolean;
    selectedValue?: never;
    onOptionChange?: never;
    selectedCount?: number;
    onSelectOption?: (option: SelectOption, index: number) => void;
    onUnselectOption?: (option: SelectOption, index: number) => void;
    onOptionsChange?: OnSelectOptionsChange;
    selectAllLabel?: string;
    onSelectAll?: (values: SelectOption[]) => void;
    unselectAllLabel?: string;
    onUnselectAll?: (values: SelectOption[]) => void;
    toggleAllValuesVariant?: 'toggle' | 'button';
    selectType?: SelectType;
    hideChecks?: boolean;
    clearSearchOnCreate?: never;
  };

export type SelectPanelProps<T = unknown> = {
  className?: string;
  style?: CSSProperties;
  dataTestId?: string;
  title?: ReactNode;
  header?: ReactNode | ((props: { inputRef: React.RefObject<HTMLInputElement> }) => ReactNode);
  options: SelectOption<T>[];
  searchPlaceholder?: string;
  autoFocusSearch?: boolean;
  hideSearch?: boolean;
  onSearchChange?: (value: string) => void;
  size?: 'S' | 'M';
  listNavDefaultIndex?: number;
  onClearValue?: VoidFunction;
  listMaxHeight?: string;
  warningOnNoneValue?: boolean;
  docTypeName?: string;
  onCreateOption?: (label: string) => Promise<void>;
  showCreateOptionIfEmpty?: boolean;
  defaultCreateOptionLabel?: ReactNode;
  filterOptionsOnInputChange?: boolean;
  onMouseEnterItem?: (itemId: string) => void;
  onMouseLeaveItem?: (itemId: string) => void;
  noPointerEvents?: boolean;
  isLoading?: boolean;
  showCreateOption?: boolean;
  debounceSearch?: boolean;
  infiniteScroll?: InfiniteScrollProps;
  footer?: ReactNode | ((props: { inputRef: React.RefObject<HTMLInputElement> }) => ReactNode);
  clearLabel?: string;
  defaultFilter?: string;
  children?: ReactNode;
} & SelectProps<T>;

export const SELECT_PANEL_CLEAR_VALUE = 'clear';
export const SELECT_PANEL_CREATE_VALUE = 'create';

export const SelectPanel = <T = unknown>({
  className,
  style,
  dataTestId,
  title,
  header,
  options,
  searchPlaceholder = 'Search...',
  autoFocusSearch = true,
  hideSearch = false,
  onSearchChange,
  size = 'M',
  isMulti,
  // Single
  selectedValue,
  onOptionChange,
  // Multi
  onSelectOption,
  onUnselectOption,
  // TODO: remove onOptionsChange once all multiSelect use unified lists
  onOptionsChange,
  listNavDefaultIndex = -1,
  onClearValue,
  listMaxHeight: listMaxHeightFromProps,
  warningOnNoneValue = false,
  docTypeName,
  onCreateOption,
  showCreateOptionIfEmpty,
  defaultCreateOptionLabel,
  filterOptionsOnInputChange = true,
  selectAllLabel = 'Select all',
  onSelectAll,
  unselectAllLabel = 'Clear all',
  onUnselectAll,
  toggleAllValuesVariant,
  children,
  onMouseEnterItem,
  onMouseLeaveItem,
  noPointerEvents = false,
  selectedCount,
  selectType,
  isLoading = false,
  hideChecks,
  debounceSearch = false,
  infiniteScroll = {
    disabled: true,
    isLoading: false,
    hasMoreData: false,
  },
  isRequired,
  footer,
  clearSearchOnCreate,
  clearLabel,
  defaultFilter,
  showCreateOption: showCreateOptionProps,
}: SelectPanelProps<T>) => {
  const onCreateOptionAsync = useAsyncCallback(onCreateOption ?? Promise.resolve);
  const onSelectAllAsync = useAsyncCallback(onSelectAll ?? Promise.resolve);
  const onUnSelectAllAsync = useAsyncCallback(onUnselectAll ?? Promise.resolve);
  const {
    filteredOptions, filterText, resetFilter, onChange,
  } = useFilteredOptions({
    isDebounceEnabled: debounceSearch,
    isDisabled: !filterOptionsOnInputChange,
    onSearchChange,
    options,
    defaultFilter,
  });
  const selectedOptions = useMemo(() => options.filter(o => o.selected), [options]);
  const unselectedOptions = useMemo(
    () => options.filter(o => !(selectedOptions ?? []).find(ov => ov.value === o.value)),
    [selectedOptions, options],
  );

  const showCreateOption = ((onCreateOptionAsync.loading || (!!onCreateOption && filteredOptions.length === 0)) &&
    (!!filterText.length || (showCreateOptionIfEmpty && filteredOptions.length === 0))) ||
    (showCreateOptionProps && !filteredOptions.some(o => o.label === filterText));
  const showClearOption = !!onClearValue && !filterText;

  const optionsValues = useMemo(() => [
    ...(showClearOption ? [SELECT_PANEL_CLEAR_VALUE] : []),
    ...filteredOptions.map(o => o.value),
    ...(showCreateOption ? [SELECT_PANEL_CREATE_VALUE] : []),
  ], [showClearOption, showCreateOption, filteredOptions]);

  const {
    listProps,
    itemProps,
    selected,
    hoverDisabled,
  } = useListNav({
    optionsValues,
    value: selectedValue,
    onSelect: selectOption,
    autoFocus: true,
    defaultIndex: getDefaultIndex(),
  });

  const getLineProps = useCallback((itemVal: string) => {
    const listNavProps = itemProps(itemVal);
    return {
      onClick: listNavProps.onClick,
      style: listNavProps.style,
      onMouseEnter: () => {
        listNavProps.onMouseEnter();
        onMouseEnterItem?.(itemVal);
      },
      onMouseLeave: () => {
        listNavProps.onMouseLeave();
        onMouseLeaveItem?.(itemVal);
      },
    };
  }, [itemProps, onMouseEnterItem, onMouseLeaveItem]);

  const listMaxHeight: string = useMemo(() => {
    if (listMaxHeightFromProps) return listMaxHeightFromProps;
    const additionalHeight: number =
      (title ? titleLineHeight : 0) +
      (hideSearch ? 0 : 32) +
      (containerPadding * 2);
    return `calc(40vh - ${additionalHeight}px)`;
  }, [listMaxHeightFromProps, title, hideSearch]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const warning = docTypeName
    ? <Warning tooltip={`The ${docTypeName.toLowerCase()} will leave the view if you choose this value`} />
    : <Warning />;

  return (
    <Container
      className={className}
      style={style}
      noPointerEvents={noPointerEvents}
      onClick={e => e.stopPropagation()}
    >
      {typeof header === 'function' ? header({ inputRef }) : header}
      {title && (
        <Title>
          {title}
          {isMulti && ` (${selectedCount ?? ((selectedOptions ?? []).length)})`}
        </Title>
      )}

      {!hideSearch && (
        <InputStyled
          ref={inputRef}
          type="text"
          iconBefore={<SearchIcon />}
          placeholder={searchPlaceholder}
          onChange={onChange}
          defaultValue={filterText}
          autoFocus={autoFocusSearch}
          disabled={isLoading}
        />
      )}

      <List
        listMaxHeight={listMaxHeight}
        data-testid={dataTestId}
        {...listProps}
      >
        <InfiniteScroll {...infiniteScroll}>
          {showClearOption && (
            <SelectLine
              label={clearLabel || 'None'}
              hoverDisabled={hoverDisabled && selected !== SELECT_PANEL_CLEAR_VALUE}
              isSelected={selected === SELECT_PANEL_CLEAR_VALUE}
              size={size}
              endSlot={warningOnNoneValue
                ? warning
                : undefined}
              isDisabled={isLoading}
              {...getLineProps(SELECT_PANEL_CLEAR_VALUE)}
            />
          )}

          {(onSelectAll || onUnselectAll) && toggleAllValuesVariant === 'toggle' && (
            <StickyHeader>
              <SelectLine
                id="toggle-all"
                label={selectAllLabel}
                hoverDisabled={hoverDisabled}
                size={size}
                checked={unselectedOptions.length === 0}
                onClick={() => (unselectedOptions.length === 0
                  ? onUnSelectAllAsync.execute(selectedOptions ?? [])
                  : onSelectAllAsync.execute(options))}
                isDisabled={isLoading}
              />
              <Separator />
            </StickyHeader>
          )}

          {filteredOptions.map(o => {
            const isDisabled = o.disabled || isLoading || (isRequired && o.selected && selectedOptions.length === 1);
            const {
              style: lineStyle, ...lineProps
            } = getLineProps(o.value);
            return (
              <Tooltip
                key={o.value}
                content={o.tooltipContent}
                disabled={!o.tooltipContent}
                placement={o.tooltipPlacement}
                offset={o.tooltipOffset}
                withPortal
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'unset',
                }}
              >
                <SelectLine
                  id={o.value}
                  label={o.renderLabel?.(filterText) ?? (
                    <TextHighlighter
                      searchWords={[filterText]}
                      textToHighlight={o.label}
                      className="highlight"
                    />
                  )}
                  startSlot={o.icon}
                  hoverDisabled={(hoverDisabled && selected !== o.value) || isDisabled}
                  isSelected={o.value === selected}
                  isDisabled={isDisabled}
                  size={size}
                  variant={o.variant}
                  endSlot={o.end}
                  shortcut={o.shortcut}
                  selectType={selectType}
                  {...!hideChecks && isMulti && {
                    checked: o.selected ?? false,
                  }}
                  {...lineProps}
                  style={{
                    ...lineStyle,
                    ...o.style,
                  }}
                />
              </Tooltip>
            );
          })}
          {showCreateOption && (filterText || defaultCreateOptionLabel) && (
            <SelectLine
              label={filterText && <CreateOptionLabel>{filterText}</CreateOptionLabel>}
              size={size}
              startSlot={filterText ? <div>Create</div> : defaultCreateOptionLabel}
              endSlot={onCreateOptionAsync.loading ? <Spinner /> : undefined}
              isSelected={selected === SELECT_PANEL_CREATE_VALUE}
              hoverDisabled={hoverDisabled && selected !== SELECT_PANEL_CREATE_VALUE}
              isDisabled={isLoading}
              {...getLineProps(SELECT_PANEL_CREATE_VALUE)}
            />
          )}
        </InfiniteScroll>
      </List>

      {children}

      {(onSelectAll || onUnselectAll) && toggleAllValuesVariant !== 'toggle' && (
        <Footer>
          {onSelectAll && (
            <FooterButton
              disabled={unselectedOptions.length === 0 || isLoading}
              onClick={() => onSelectAllAsync.execute(options)}
              isLoading={onSelectAllAsync.loading}
            >
              {selectAllLabel}
            </FooterButton>
          )}
          {onUnselectAll && (
            <FooterButton
              disabled={(selectedOptions ?? []).length === 0 || isLoading || isRequired}
              onClick={() => onUnSelectAllAsync.execute(selectedOptions ?? [])}
              isLoading={onUnSelectAllAsync.loading}
            >
              {unselectAllLabel}
            </FooterButton>
          )}
        </Footer>
      )}
      {typeof footer === 'function' ? footer({ inputRef }) : footer}
    </Container>
  );

  function selectOption(value: string | null, event?: MouseEvent): void {
    if (value === SELECT_PANEL_CLEAR_VALUE) {
      onClearValue?.();
      return;
    }

    if (value === SELECT_PANEL_CREATE_VALUE) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onCreateOptionAsync.execute(filterText);
      if (clearSearchOnCreate && inputRef.current) {
        inputRef.current.value = '';
        resetFilter();
      }
      if (isMulti) {
        resetFilter();
      }
      return;
    }

    const optionIndex = options.findIndex(o => o.value === value);
    const option = options[optionIndex];
    if (!option) return;

    if (isMulti) {
      const isOptionChecked = option.selected;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isOptionChecked
        ? removeMultiSelectOption(optionIndex, option)
        : addMultiSelectOption(optionIndex, option);
    } else {
      onOptionChange?.(option, event);
      resetFilter();
    }
  }

  function addMultiSelectOption(index: number, option: SelectOption) {
    onSelectOption?.({
      ...option,
      selected: !option.selected,
    }, index);
    onOptionsChange?.([...selectedOptions ?? [], option], {
      addedOptions: [option],
    });
  }

  function removeMultiSelectOption(index: number, unselectedOption: SelectOption) {
    onUnselectOption?.({
      ...unselectedOption,
      selected: !unselectedOption.selected,
    }, index);
    onOptionsChange?.(
      (selectedOptions ?? []).filter(v => v.value !== unselectedOption.value),
      { removedOptions: [unselectedOption] },
    );
  }

  function getDefaultIndex() {
    if (listNavDefaultIndex > -1) {
      return listNavDefaultIndex;
    }

    return (isMulti && onSelectAll) || showClearOption ? 1 : 0;
  }
};
