import { CheckboxInput } from '@cycle-app/ui';

import { useGetInsightForm, setInsightForm } from 'src/reactives/insightForm.reactive';

export const FeedbackContentAsQuote = () => {
  const { useFeedbackContentAsQuote } = useGetInsightForm();
  return (
    <CheckboxInput
      id="feedback-content-as-quote"
      label="Use feedback as quote"
      checked={useFeedbackContentAsQuote}
      onChange={e => {
        setInsightForm({ useFeedbackContentAsQuote: e.target.checked });
      }}
    />
  );
};
