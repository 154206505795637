import { InputComponents, Tooltip } from '@cycle-app/ui';
import { AddPictureIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { readFileSrc, readImageSize } from '@cycle-app/utilities';

import { ACCEPTED_IMAGE_FORMAT } from '../../constants/upload.constant';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { ImageInput } from '../ImageInput';
import {
  Overlay, OverlayHover, Preview, UploadButton, UploadIconContainer, UploadPreview,
} from './ChangelogBuilderLogo.styles';
import { Section } from './ChangelogBuilderSidebar.styles';
import { LabelWithReset } from './LabelWithReset';

const Icon = (
  <UploadIconContainer>
    <AddPictureIcon />
  </UploadIconContainer>
);

const accept = {
  ...ACCEPTED_IMAGE_FORMAT,
  'image/svg+xml': [],
};

export const ChangelogBuilderFavicon = () => {
  const {
    formState, watch, setValue, setError, clearErrors,
  } = useChangelogBuilderFormContext();
  const url = watch('favicon_url');
  return (
    <Section>
      <LabelWithReset
        label={(
          <div className="flex items-center gap-1">
            Favicon
            <Tooltip
              content="Minimum 48x48 square image"
              placement="top"
              withPortal
              withWrapper={false}
            >
              <InfoIconOutline />
            </Tooltip>
          </div>
        )}
        reset={() => {
          setValue('favicon_url', formState.defaultValues?.favicon_url || '');
          setValue('favicon_file', null);
          clearErrors('favicon_url');
        }}
        showReset={url !== formState.defaultValues?.favicon_url}
      />
      <ImageInput
        previewModalSubmitLabel="Update favicon"
        onChange={async file => {
          const fileUrl = await readFileSrc(file);
          const size = await readImageSize(file);
          const ratio = size.width / size.height;
          if (size.width < 48 || ratio > 1.2 || ratio < 0.8) {
            setError('favicon_url', { message: 'Favicon should be minimum 48x48 square image' });
            return;
          }
          clearErrors('favicon_url');
          setValue('favicon_file', file);
          setValue('favicon_url', fileUrl);
        }}
        accept={accept}
        renderPreview={src => <UploadPreview src={src} />}
      >
        {(inputRef) => (
          <UploadButton
            type="button"
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            {!url && <Overlay>{Icon}</Overlay>}
            {url && <OverlayHover>{Icon}</OverlayHover>}
            {url && <Preview src={url} />}
          </UploadButton>
        )}
      </ImageInput>
      {formState.errors.favicon_url?.message && (
        <InputComponents.Helper $hasError $size="M" style={{ marginTop: '8px' }}>
          {formState.errors.favicon_url.message}
        </InputComponents.Helper>
      )}
    </Section>
  );
};
