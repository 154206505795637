import { Tooltip } from '@cycle-app/ui';
import { ReactNode } from 'react';

import { Shortcut, shortcuts } from 'src/constants/shortcuts.constants';
import { useParentPage } from 'src/hooks/usePageId';

import { InlineShortcut } from './ViewBreadcrumb.styles';

export const SectionTooltip = ({ children }: { children: ReactNode }) => {
  const parent = useParentPage();
  return (
    <Tooltip
      title="Get to inbox zero!"
      content={(
        <div>
          Use
          <InlineShortcut keys={shortcuts[Shortcut.ProcessDoc]} />
          shortcut to mark as processed
        </div>
      )}
      placement="bottom-start"
      withPortal
      withWrapper={false}
      disabled={parent !== 'inbox'}
    >
      {children}
    </Tooltip>
  );
};
