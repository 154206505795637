import { ProductBaseFragment } from '@cycle-app/graphql-codegen';

import { make } from 'src/utils/reactives.util';

interface InitialState {
  loading: boolean;
  products: Array<ProductBaseFragment>;
}

export const {
  hookState: useInitialState,
  hookValue: useGetInitial,
  getValue: getInitial,
  setValue: setInitial,
  resetValue: resetInitial,
} = make<InitialState>({
  defaultState: {
    loading: true,
    products: [],
  },
});
