import { Badge } from '@cycle-app/ui';
import { FC, RefObject } from 'react';
import { twJoin } from 'tailwind-merge';

import DocEditor from 'src/components/DocEditor/DocEditor';
import { StyledEditorSkeleton } from 'src/components/DocEditor/DocEditor.styles';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useIsOffline, useIsRoadmapsEnabled, useDoc } from 'src/hooks';
import useDocCoverDropzone from 'src/hooks/doc/useCoverDropzone';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useDocId } from 'src/hooks/usePathParams';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { isParentOfInsight } from 'src/utils/docType.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import { FeatureDocCollapsible } from './FeatureDocCollapsible';
import { FeatureDocHierarchy } from './FeatureDocHierarchy';
import { FeatureDocQuotes, QuotesSkeleton } from './FeatureDocQuotes';
import { FeatureDocReleaseNote } from './FeatureDocReleaseNote';
import { QuotesActions } from './QuotesActions';
import { RecommendedQuotes } from './RecommendedQuotes';
import {
  HeaderContainer,
  ScrollableContent,
  DocPanelActions,
  DocPanelDocAttributes,
  CoverContainer,
  DocPanelEditableTitle,
  EditorWrapper,
  DocParentTitle,
  StyledDocParentDropdown,
  PreTitle,
  MainContent,
} from '../DocPanelContent/DocPanelContent.styles';
import DocPanelCover from '../DocPanelCover/DocPanelCover';

interface Props extends Omit<DocPanelBaseProps, 'getDropzonePropsTopBar' | 'doc'> {
  containerRef: RefObject<HTMLDivElement>;
  isDraggingCover: boolean;
  isUploadingCover: boolean;
  onEscapeEdition?: VoidFunction;
  focusOnMount?: boolean;
}

/**
 * Fork of `DocPanelContent` for feature docs
 */
export const FeatureDocPanelContent: FC<React.PropsWithChildren<Props>> = ({
  containerRef,
  getDropzonePropsCover,
  mode = 'edit-doc',
  onOpenCoverInputFile,
  onTitleUpdated,
  onEscapeEdition,
  focusOnMount,
  isDraggingCover: isDraggingCoverFromTopOrCover,
  isUploadingCover: isUploadingCoverFromTopOrCover,
}) => {
  const isMobile = useIsMobile();
  const isOffline = useIsOffline();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const isReleasesEnabled = useIsReleasesEnabled();

  const docId = useDocId();
  const { doc: docBase } = useDoc(docId);
  const docType = useGetDocType(docBase?.doctype.id);

  const {
    getRootProps: getDropzonePropsTitleAndAttrs,
    isDragActive: isDragActiveFromTitleAndAttrs,
    isUploadingCover: isUploadingCoverTitleAndAttrs,
  } = useDocCoverDropzone({ docId: docId ?? '' });
  const isDraggingCover = isDraggingCoverFromTopOrCover || isDragActiveFromTitleAndAttrs;
  const isUploadingCover = isUploadingCoverFromTopOrCover || isUploadingCoverTitleAndAttrs;

  const focusToContent = () => {
    const editor = containerRef.current?.querySelector('.ProseMirror');
    if (!(editor instanceof HTMLElement)) return;
    editor?.focus();
  };

  const setEditor = useDocPanelContext(ctx => ctx.setEditor);
  const docPanelEditor = useDocPanelContext(ctx => ctx.editor);

  if (!docBase) return <StyledEditorSkeleton />;

  return (
    <ScrollableContent>
      <MainContent>
        {docId && docBase?.cover?.url && (
          <CoverContainer>
            <DocPanelCover
              docId={docId}
              coverUrl={docBase.cover.url}
              isDragActive={isDraggingCover}
              getDropzoneProps={getDropzonePropsCover}
              onUpdateCoverClicked={onOpenCoverInputFile}
            />
          </CoverContainer>
        )}

        <HeaderContainer
          {...getDropzonePropsTitleAndAttrs()}
          // Intentional empty function to prevent the dropzone to open the finder on `Enter`
          onKeyDown={undefined}
          coloredTopBorder={!docBase?.cover?.url && (isDraggingCover || isUploadingCover)}
        >
          {docBase && (
            <>
              <PreTitle>
                {isMobile && (
                  <StyledDocParentDropdown
                    docId={docBase.id}
                    docTypeId={docBase.doctype.id}
                    size="S"
                    showIcon
                    showParentTitle={false}
                    minimal
                    absoluteEdit
                    showLinearAutoCreate
                  >
                    {docBase.parent?.title && (
                      <DocParentTitle>
                        {` · ${docBase.parent.title}`}
                      </DocParentTitle>
                    )}
                  </StyledDocParentDropdown>
                )}

                <DocPanelActions
                  onAddCoverClicked={onOpenCoverInputFile}
                  isDraggingCover={isDraggingCover}
                  isUploadingCover={isUploadingCover}
                />
              </PreTitle>

              <DocPanelEditableTitle
                id={docBase.id}
                title={docBase.title}
                onTitleUpdated={onTitleUpdated}
                onNext={() => containerRef.current?.click()}
                onEscape={onEscapeEdition}
                onEnter={focusToContent}
                focusEndOnMount={focusOnMount}
              />

              <DocPanelDocAttributes
                doc={docBase}
                showDoctype
                showDocId
                showAssignee
                editor={docPanelEditor}
                showStatus={isRoadmapsEnabled}
              />
            </>
          )}
        </HeaderContainer>

        <div className="mb-48 mt-8 space-y-6">
          {isParentOfInsight(docType) && (
            <FeatureDocCollapsible
              sectionId="quotes"
              title={(
                <div className="flex items-center gap-2">
                  Quotes
                  {docBase.insightsCount > 0 && (
                    <Badge>
                      {docBase.insightsCount}
                    </Badge>
                  )}
                </div>
              )}
              endSlot={open => (
                <div className={twJoin(
                  'flex items-center gap-4 transition-opacity',
                  open ? 'opacity-100' : 'opacity-0',
                )}
                >
                  <RecommendedQuotes />
                  <QuotesActions />
                </div>
              )}
            >
              {animationComplete => animationComplete ? <FeatureDocQuotes /> : <QuotesSkeleton />}
            </FeatureDocCollapsible>
          )}

          {isReleasesEnabled && docBase?.releaseNote && (
            <FeatureDocCollapsible
              sectionId="release-notes"
              title="Release note"
            >
              <FeatureDocReleaseNote
                releaseNote={docBase.releaseNote}
              />
            </FeatureDocCollapsible>
          )}

          <FeatureDocCollapsible
            sectionId="content"
            title="Content"
            noMarginContent
          >
            {visibleAnimationComplete => (
              <EditorWrapper
                $isDisabled={isOffline}
                onClick={undefined}
              >
                <DocEditor
                  docId={docId}
                  parentRef={containerRef}
                  displayLoader={mode === 'edit-doc'}
                  onEscape={onEscapeEdition}
                  showAddTemplate
                  showIntegrations
                  onEditorReady={setEditor}
                  hideEmptyBlock
                  loading={!visibleAnimationComplete}
                />
              </EditorWrapper>
            )}
          </FeatureDocCollapsible>

          {docId && <FeatureDocHierarchy docId={docId} />}
        </div>
      </MainContent>
    </ScrollableContent>
  );
};
