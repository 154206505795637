import { typo, Button, TextButton, EditableText, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
`;

export const CategoryButton = styled(ActionButton)`
  padding: 4px 8px;
`;

export const Title = styled.div`
  ${typo.body400}
`;

const TextButtonStyled = styled(TextButton)`
  width: auto;
  padding: 4px 8px;

  > svg {
    width: 14px;
    height: 14px;
  }
`;

export const EditButton = styled(TextButtonStyled)`
  margin-left: auto;
`;

export const RemoveButton = styled(TextButtonStyled)``;

export const TitleInput = styled(EditableText)`
  ${typo.body400};
`;

export const CancelButton = styled(Button)`
  margin-left: auto;
`;

export const SaveButton = styled(Button)``;
