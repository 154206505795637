import { rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';

interface ShinyProps {
  $sidebar?: boolean;
}

const shine = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const GradientCss = css`
  background-image: linear-gradient(
    90deg,
    ${p => rgba(p.theme.colors.skeleton.bg, 0)} 0,
    ${p => rgba(p.theme.colors.skeleton.bg, 0.3)} 20%,
    ${p => rgba(p.theme.colors.skeleton.bg, 0.6)} 60%,
    ${p => rgba(p.theme.colors.skeleton.bg, 0)} 100%
  );
`;

const GradientSidebarCss = css`
  background-image: linear-gradient(
    90deg,
    ${p => rgba(p.theme.colors.sidebar.color, 0)} 0,
    ${p => rgba(p.theme.colors.sidebar.color, 0.1)} 20%,
    ${p => rgba(p.theme.colors.sidebar.color, 0.2)} 60%,
    ${p => rgba(p.theme.colors.sidebar.color, 0)} 100%
  );
`;

export const ShinyCss = css<ShinyProps>`
  position: relative;
  overflow: hidden;

  border-radius: 4px;
  min-width: 60px;
  height: 34px;
  background-color: ${p => (p.$sidebar ? rgba(p.theme.colors.sidebar.color, 0.1) : rgba(p.theme.colors.skeleton.bg, 0.4))};

  ::before {
    position: absolute;
    inset: 0;
    ${p => (p.$sidebar ? GradientSidebarCss : GradientCss)};
    animation: ${shine} 1500ms infinite;
    content: '';
  }
`;

export const ShinyItem = styled.div<ShinyProps>`
  ${ShinyCss};
`;
