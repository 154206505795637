import { css } from 'styled-components';

// HEADER

export const header = css`
  font-weight: 600;
`;

export const headerSmall = css`
  ${header};
  font-size: 16px;
  line-height: 24px;
`;

export const headerLight = css`
  ${headerSmall}
  font-weight: 500;
`;

export const headerMedium = css`
  ${header};
  font-size: 18px;
  line-height: 24px;
`;

export const headerLarge = css`
  ${header};
  font-size: 24px;
  line-height: 32px;
`;

export const headerLarge600 = css`
  ${headerLarge};
  font-weight: 600;
`;

// BODY

export const body = css`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
`;

export const body400 = css`
  ${body}
  font-weight: 400;
`;

export const body400i = css`
  ${body400}
  font-style: italic;
`;

export const body500 = css`
  ${body}
  font-weight: 500;
`;

export const body600 = css`
  ${body}
  font-weight: 600;
`;

// CAPTION

export const caption = css`
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
`;

export const caption400 = css`
  ${caption}
  font-weight: 400;
`;

export const caption400i = css`
  ${caption400}
  font-style: italic;
`;

export const caption500 = css`
  ${caption}
  font-weight: 500;
`;

export const caption600 = css`
  ${caption}
  font-weight: 600;
`;

// TINY

export const tiny = css`
  font-size: 10px;
  font-style: normal;
  line-height: 18px;
`;

export const tiny400 = css`
  ${tiny}
  font-weight: 400;
`;

export const tiny400i = css`
  ${tiny400}
  font-style: italic;
`;

export const tiny500 = css`
  ${tiny}
  font-weight: 500;
`;

export const tiny600 = css`
  ${tiny}
  font-weight: 600;
`;
