import { ThemeType } from '@cycle-app/graphql-codegen';
import { InfoColorType } from '@cycle-app/utilities';
import { transparentize } from 'polished';
import styled, { DefaultTheme } from 'styled-components';

import { Color } from '../../theme/baseColors';
import { getColorFromType } from '../../utils/colors.utils';
import { transitionCss } from '../../utils/transition.util';

const getBackground = (theme: DefaultTheme, colorType: InfoColorType | undefined, isTransparent = false) => {
  let color;

  if (!colorType) {
    color = theme.colors.text.secondary;
  } else if (colorType === 'default') {
    color = Color.Cycle;
  } else {
    color = getColorFromType(colorType);
  }
  return isTransparent ? transparentize(0.9, color) : color;
};

export const Circle = styled.circle<{ $colorType?: InfoColorType }>`
  fill: transparent;
  stroke:  ${p => (p.theme.themeType === ThemeType.Nightfall ? Color.Grey800 : Color.Grey300)};
`;

export const ProgressCircle = styled.circle<{
  $colorType?: InfoColorType;
  $lineCap: 'round' | 'butt';
}>`
  fill: transparent;
  stroke: ${p => getBackground(p.theme, p.$colorType)};
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: ${p => p.$lineCap};
`;

type ProgressBarProps = {
  $percentage: number;
  $colorType?: InfoColorType;
};
export const ProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 30px;
  background-color: ${p => (p.theme.themeType === ThemeType.Nightfall ? Color.Grey800 : Color.Grey300)};
  overflow: hidden;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: ${p => `${100 - p.$percentage}%`};
    background-color: ${p => getBackground(p.theme, p.$colorType)};
    border-radius: 30px;
    ${transitionCss(['right'])}
  }
`;
