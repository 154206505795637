import { ShyScrollbar, ActionButton, typo } from '@cycle-app/ui';
import { ColorTheme, mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import PortalModal from 'src/components/PortalModal/PortalModal';
import { NEW_COMMENT_MAX_HEIGHT } from 'src/constants/comments.constants';

export const StyledDropdownLayer = styled(DropdownLayer)`
  width: 348px;
`;

export const DocChatContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  ${mobile} {
    height: 100%; 
  }
`;

export const Container = styled.div<{ noComments: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${p => (p.noComments ? 'auto' : '100%')};
  min-width: min(100%, 340px);
  cursor: default;
  overflow: hidden;
`;

export const Comment = styled.div`
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  &:last-child {
    padding-top: 16px;
  }
`;

export const CommentsList = styled(ShyScrollbar)<{ $maxHeight?: string }>`
  display: flex;
  flex-direction: column-reverse;

  ${p => p.$maxHeight && css`
    max-height: calc(${p.$maxHeight} - ${NEW_COMMENT_MAX_HEIGHT + 70}px);
  `};
  word-break: break-word;

  ${mobile} {
    max-height: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  flex: 1;
  padding: 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};

  ${mobile} {
    padding: 16px;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.caption500}
  ${mobile} {
    gap: 8px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Action = styled(ActionButton)`
  :disabled {
    opacity: 0.5;
  }
`;

export const CloseButton = styled.button`
  ${typo.body500}
  color: ${p => p.theme.colors.components.MobileHeader.closeButton.color};
  padding: 0;
`;

export const StyledPortalModal = styled(PortalModal)`
  padding: 0;
  border-radius: 0;
`;
