import { useCallback, useLayoutEffect, useRef, RefObject, MouseEvent as ReactMouseEvent } from 'react';

import { getSidebarCollapsed, getSidebarWidth, setSidebarCollapsed, setSidebarResising, toggleSidebar } from '../reactives/sidebar.reactive';

interface UseSidebarResize {
  (p: UseSidebarResizeParams): {
    startListen: (e: ReactMouseEvent) => void;
  };
}
interface UseSidebarResizeParams {
  resizing: boolean;
  containerRef: RefObject<HTMLDivElement>;
  minWidth: number;
  maxWidth: number;
  onResizeStart?: () => void;
  onResizeEnd?: (sidebarWidth: number) => void;
  onResizeMove?: (sidebarWidth: number) => void;
  disabled?: boolean;
}

export const HANDLE_RESIZE_DIV_ID = 'resize-bar';

export const useSidebarResize: UseSidebarResize = ({
  resizing,
  containerRef,
  minWidth,
  maxWidth,
  onResizeStart,
  onResizeEnd,
  onResizeMove,
  disabled = false,
}) => {
  const time = useRef(0);
  const origin = useRef<number | null>(null);
  const initialWidth = useRef(getSidebarWidth().width);

  const listenMove = useCallback((e: MouseEvent) => {
    if (disabled) return;
    if (!origin.current) {
      origin.current = e.clientX;
    }

    const delta = e.clientX - origin.current;
    const widthUpdated = initialWidth.current + delta;

    if (containerRef.current && widthUpdated >= minWidth && widthUpdated <= maxWidth) {
      onResizeMove?.(widthUpdated);
      // eslint-disable-next-line no-param-reassign
      containerRef.current.style.width = `${widthUpdated}px`;
    }

    // The sidebar should close automatically when you want to reduce it even further
    if (widthUpdated < minWidth - 30 && !getSidebarCollapsed().collapsed) {
      setSidebarResising({ resizing: false });
      setSidebarCollapsed({ collapsed: true });
    }
  }, [containerRef, minWidth, maxWidth, onResizeMove, disabled]);

  const startListen = useCallback((e: ReactMouseEvent) => {
    time.current = Date.now();
    if (!(e.target instanceof HTMLElement)) return;
    if (disabled || e.target?.id !== HANDLE_RESIZE_DIV_ID) return;
    initialWidth.current = getSidebarWidth().width;
    onResizeStart?.();
    origin.current = e.clientX;
  }, [disabled, onResizeStart]);

  const listenEnd = useCallback(() => {
    if (!containerRef.current) return;
    if (Date.now() - time.current < 200) toggleSidebar();
    const sidebarWidth = parseInt(containerRef.current.style.width.replace('px', ''), 10);
    if (sidebarWidth >= minWidth) onResizeEnd?.(sidebarWidth);
  }, [containerRef, minWidth, onResizeEnd]);

  useLayoutEffect(() => {
    if (!resizing) {
      return () => {
        // Intentional empty function
      };
    }
    document.addEventListener('mousemove', listenMove);
    document.addEventListener('mouseup', listenEnd);
    return () => {
      document.removeEventListener('mousemove', listenMove);
      document.removeEventListener('mouseup', listenEnd);
    };
  }, [resizing, listenMove, listenEnd]);

  return {
    startListen,
  };
};
