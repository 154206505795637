import { getBreakpoint, Breakpoint } from '@cycle-app/utilities';

import { make } from 'src/utils/reactives.util';

export interface ResponsiveState {
  breakpoint: Breakpoint;
  isTouchDevice: boolean;
}

export const {
  hookState: useResponsive,
  hookValue: useGetResponsive,
  getValue: getResponsive,
  setValue: setResponsive,
} = make<ResponsiveState>({
  defaultState: {
    breakpoint: getBreakpoint(),
    isTouchDevice: window.matchMedia('(pointer: coarse)').matches,
  },
});

export const isMobile = () => {
  const {
    breakpoint, isTouchDevice,
  } = getResponsive();
  return breakpoint === 'mobile' && isTouchDevice;
};

export const useIsMobile = () => {
  const {
    breakpoint, isTouchDevice,
  } = useGetResponsive();
  return breakpoint === 'mobile' && isTouchDevice;
};

export const useIsSmallScreen = () => {
  const { breakpoint } = useGetResponsive();
  return breakpoint === 'mobile';
};

export const useIsTablet = () => {
  const { breakpoint } = useGetResponsive();
  return breakpoint === 'tablet';
};

export const useIsDesktop = () => {
  const { breakpoint } = useGetResponsive();
  return breakpoint === 'desktop';
};
