import { useDropzone } from 'react-dropzone';

import { ACCEPTED_COVER_IMAGE_FORMAT, IMAGE_SIZE_LIMIT } from 'src/constants/upload.constant';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { addToaster } from 'src/utils/toasters.utils';

import { getFileSizeWithUnit } from '../../utils/files.util';

interface UseCoverDropzoneArgs {
  docId: string;
  disabled?: boolean;
  onSuccess?: VoidFunction;
}

export default function useDocCoverDropzone({
  docId,
  disabled = false,
  onSuccess,
}: UseCoverDropzoneArgs) {
  const {
    updateDocCover,
    isUploadingCover,
  } = useDocCoverMutations(docId, { onUpdateSuccess: onSuccess });

  const {
    getRootProps,
    isDragActive,
  } = useDropzone({
    accept: ACCEPTED_COVER_IMAGE_FORMAT,
    maxSize: IMAGE_SIZE_LIMIT,
    maxFiles: 1,
    noClick: true,
    disabled,
    onDropAccepted: (files) => {
      // When multiple files are droped, as long as one file is accepted
      // we don't show any error notification
      const dropedFile: File | undefined = files[0];
      if (dropedFile) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateDocCover(dropedFile);
      }
    },
    onDropRejected: () => {
      addToaster({
        title: 'An error occured',
        message: `Only one image or GIF file can be droped, maximum size ${getFileSizeWithUnit(IMAGE_SIZE_LIMIT, true)}`,
      });
    },
  });

  return {
    updateDocCover,
    getRootProps,
    isDragActive,
    isUploadingCover,
  };
}
