import { Avatar, typo } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const Input = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  padding: 0 12px;
`;

export const InputContent = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const StyledAvatar = styled(Avatar)`
  margin: -1px;
`;

export const Assignee = styled.div<{ showAssigneeName: boolean; forceFocus: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  ${typo.body400}
`;
