import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { SelectOption, Emoji, SelectPanel } from '@cycle-app/ui';
import { useEffect } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useGetCustomDocTypes } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';

import { DocTypeButton, Caret } from './LinearImportModal.styles';

export const SelectDocType = ({
  value, onChange,
}: {
  value: string | null;
  onChange: (value: string) => void;
}) => {
  const docTypes = useGetCustomDocTypes();

  const options: SelectOption<DoctypeFragment>[] = Object.values(docTypes)
    .reverse()
    .map(docType => ({
      value: docType.id,
      label: docType.name,
      icon: <Emoji emoji={docType.emoji} size={14} />,
      data: docType,
    }));

  const selectedOption = options.find(option => option.value === value);

  // Set first option as default value
  useEffect(() => {
    const firstOption = options[0];
    if (firstOption && (!value || !options.some(o => o.value === value))) {
      onChange(firstOption.value);
    }
  }, [onChange, options, value]);

  return (
    <ToggleDropdown
      layer={Layer.DropdownModal}
      placement="bottom-end"
      button={props => (
        <DocTypeButton
          {...props}
          iconStart={selectedOption?.icon}
          iconEnd={<Caret size={13} direction={props['data-active'] ? 'top' : 'bottom'} />}
        >
          {selectedOption?.label}
        </DocTypeButton>
      )}
      content={props => (
        <SelectPanel
          options={options}
          onOptionChange={option => {
            onChange(option.value);
            props.hide();
          }}
        />
      )}
    />
  );
};
