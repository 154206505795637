import client from 'src/services/apollo/client';

export const removeAllQuotesFromCache = (docId: string) => {
  client.cache.modify({
    id: client.cache.identify({ id: docId }),
    fields: {
      quotes: (refs) => ({
        ...refs,
        edges: [],
      }),
      quotesCount: () => 0,
    },
  });
};
