import { useEffect, useLayoutEffect, useRef, useState } from 'react';

/**
 * React hook that observes the dimensions of a DOM element using the ResizeObserver API
 *
 * The component using this hook will only be re-rendered when the value returned by the selector changes
 *
 * @param selector - A function that receives the DOMRect object and returns the computed value
 * @param options.disabled - If true, the element will no longer be observed
 * @returns The element ref and the value returned by the selector function or undefined if the element is not available
 * @example
 * ```tsx
 * const [ref, isSmall] = useRect(rect => rect.width < 400);
 * ```
 */
export const useRect = <T, S extends HTMLElement = HTMLDivElement>(
  selector: (rect: DOMRect) => T,
  options: { disabled?: boolean } = {},
) => {
  const ref = useRef<S>(null);

  const [value, setValue] = useState<T | undefined>(undefined);

  const selectorRef = useRef(selector);
  const { disabled } = options;

  useEffect(() => {
    selectorRef.current = selector;
  }, [selector]);

  useLayoutEffect(() => {
    if (!ref.current || disabled) return;

    const observer = new ResizeObserver(([entry]) => {
      if (!entry) return;
      setValue(selectorRef.current(entry.contentRect));
    });

    observer.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [disabled]);

  return [ref, value] as const;
};
