import { Input } from '@cycle-app/ui';
import { Helper } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { FileType, blobUrlToFile, toShortLocaleDateTimeString } from '@cycle-app/utilities';
import { Range } from '@tiptap/react';
import { FC, useEffect, useState } from 'react';
import { useDropzone, Accept } from 'react-dropzone';

import { AudioRecorder } from 'src/components/AudioRecorder';
import DropPlaceholder from 'src/components/Editor/ContainerDropFile/Placeholder/DropPlaceholder';
import MiniForm from 'src/components/Editor/MiniForm/MiniForm';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { logError } from 'src/utils/errors.utils';
import { addErrorToaster } from 'src/utils/errorToasters.utils';
import { getFileError, getUrlError } from 'src/utils/files.util';

import { DropContainer, Or, DropArea } from './FileForm.styles';
import FilePreview from './FilePreview/FilePreview';

const AUTHORIZED_FILES: { [type in FileType]: Accept | undefined } = {
  image: {
    'image/jpeg': [],
    'image/png': [],
    'image/gif': [],
    'image/webp': ['.webp'],
  },
  // Controls are done server-side.
  file: undefined,
  video: {
    'video/mp4': [],
    'video/quicktime': [],
  },
  audio: {
    'audio/mpeg': [],
    'audio/ogg': [],
    'audio/opus': [],
    'audio/wav': [],
    'audio/webm': [],
  },
};

const IMAGE_LINK_TITLE = 'External image';

interface Props {
  onCancel?: VoidFunction;
  onFileSelected?: (file: File) => void;
  type: FileType;
  title?: string;
  range?: Range;
}

export const FileForm: FC<React.PropsWithChildren<Props>> = ({
  onCancel,
  onFileSelected,
  type,
  title,
  range,
}) => {
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [url, setUrl] = useState('');
  const editor = useEditorContext(ctx => ctx.editor);
  const isUploading = useEditorContext(ctx => ctx.isUploading);
  const onError = useEditorContext(ctx => ctx.onError);
  const setDragFileIsDisabled = useEditorContext(ctx => ctx.setDragFileIsDisabled);
  const hasInputUrl = !currentFile && type === 'image';
  const {
    getInputProps,
    getRootProps,
    isDragActive,
  } = useDropzone({
    autoFocus: !hasInputUrl,
    accept: AUTHORIZED_FILES[type],
    onDropAccepted: (files) => {
      const file = files[0];
      if (!file) return;
      if (onFileSelected) {
        onFileSelected(file);
        return;
      }
      setCurrentFile(file);
    },
    onDropRejected: () => {
      onError?.('🧐 Oops, looks like something went wrong on our side, we’re on it!');
    },
  });

  useEffect(() => {
    setDragFileIsDisabled?.(true);

    return () => setDragFileIsDisabled?.(false);
  }, []);

  const currentFileError = currentFile ? getFileError(currentFile) : null;
  const urlError = url ? getUrlError(url) : null;
  const isFileOK = currentFile ? !currentFileError : false;
  const isUrlOK = url ? !urlError : false;
  const isSubmitDisabled = !isFileOK && !isUrlOK;
  const dropContainerStyle = type === 'video' || type === 'audio' ? { height: '86px' } : undefined;

  return (
    <MiniForm
      onCancel={onCancel}
      onSubmit={submit}
      isSubmitDisabled={isSubmitDisabled}
      submitLabel={type === 'audio' ? 'Add audio' : 'Upload'}
      title={title}
    >
      <DropContainer style={dropContainerStyle}>
        <DropArea
          {...getRootProps()}
          isLoading={isUploading}
          isActive={isDragActive}
          hasFullCoverage
          hideBorder={!!currentFile}
        >
          {currentFile
            ? (
              <FilePreview file={currentFile} />
            )
            : (
              <DropPlaceholder
                hasClickOption
                hasIcon={['image', 'file'].includes(type)}
                isImage={type === 'image'}
                extraSubLabel={type === 'video'
                  ? 'MOV, MP4 · '
                  : type === 'audio'
                    ? 'MP3, WAV · '
                    : ''}
              >
                <input {...getInputProps()} />
              </DropPlaceholder>
            )}
        </DropArea>
      </DropContainer>

      {currentFileError && <Helper $hasError>{currentFileError.message}</Helper>}

      {hasInputUrl && (
        <>
          <Or>
            Or add via url
          </Or>
          <Input
            value={url}
            onChange={({ target: { value } }) => setUrl(value)}
            placeholder="Image URL"
            autoFocus
            onKeyDown={(e) => e.code === 'Enter' && submit()}
            error={urlError?.message}
          />
        </>
      )}
      {type === 'audio' && (
        <AudioRecorder
          onAudioReady={async (audioUrl) => {
            try {
              const fileToUpload = await blobUrlToFile({
                blobUrl: audioUrl,
                fileName: `Cycle recording from ${toShortLocaleDateTimeString()}`,
                mime: 'audio/mpeg',
              });
              setCurrentFile(fileToUpload);
            } catch (e) {
              logError(e);
              addErrorToaster({ message: ErrorMessage.AUDIO_CONVERSION_FAILED });
            }
          }}
          onAudioRemoved={() => {
            setCurrentFile(null);
          }}
        />
      )}
    </MiniForm>
  );

  async function submit() {
    if (currentFile) {
      onFileSelected?.(currentFile);
    }

    if (url) {
      editor
        .chain()
        .focus()
        .deleteRange({
          from: range?.from ?? 0,
          to: range?.to ?? 0,
        })
        .setImage({
          src: url,
          title: IMAGE_LINK_TITLE,
        })
        .createParagraphNear()
        .run();
    }
  }
};
