import { CircleSkeleton } from '@cycle-app/ui';
import styled from 'styled-components';

export const MembersContainerWrapper = styled.div`
  display: flex;
`;

export const MemberAvatarSkeleton = styled(CircleSkeleton)`
  width: 24px;
  height: 24px;

  &:not(:first-child) {
    margin-left: -9px;
  }
`;
