import { make } from 'src/utils/reactives.util';

export type IntegrationSyncState = Record<string, { isSyncing: boolean }>;

export const {
  hookValue: useGetIntegrationSync,
  getValue: getIntegrationSync,
  setValue: setIntegrationSync,
} = make<IntegrationSyncState>({
  defaultState: {},
});
