import styled from 'styled-components';

import { Container as TabContainer } from './Tab/Tab.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${TabContainer} {
    /** This is helping to keep the bottom active line at the same bottom place */
    transform: translateY(1px);
  }
`;
