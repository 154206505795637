import { EditorView } from '@tiptap/pm/view';

import { logError } from 'src/utils/errors.utils';

// https://github.com/cycle-app/cycle-tesla/issues/3647
EditorView.prototype.updateState = function updateState(state) {
  if (!(this as any).docView) return;
  try {
    (this as any).updateStateInner(state, this.state.plugins !== state.plugins);
  } catch (error) {
    logError(error);
  }
};
