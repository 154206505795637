import { UserJourney } from '@cycle-app/graphql-codegen';
import { CycleLoader } from '@cycle-app/ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks';
import { usePageId } from 'src/hooks/usePageId';
import { useLogin } from 'src/hooks/user/useLogin';
import { useUrl } from 'src/hooks/useUrl';
import { setAuth } from 'src/reactives/auth.reactive';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';

import { LightOnboardingScreen } from '../../types/onboarding.types';

const SSOCallback = () => {
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = useRef(new URLSearchParams(useLocation().search));
  const from = queryParams.current.get('from');
  const pageId = usePageId();
  const getUrl = useUrl();
  const { navigate } = useNavigate();

  const { loginGoogle } = useLogin();

  const login: () => void = useCallback(async () => {
    const installationCode = queryParams.current.get('code');
    if (!installationCode) {
      navigate(PageId.Login);
      return;
    }

    const { data } = await loginGoogle({
      code: installationCode,
    });

    if (!data?.loginGoogle) {
      navigate(PageId.Login);
      return;
    }

    const { me } = data.loginGoogle;

    // Main view will handle the right redirections (see <MainRoot/>).
    if (me.userJourney !== UserJourney.Done) {
      setOnboarding({
        screen: LightOnboardingScreen.AccountInfosPasswordless,
      });
    }

    setAuth({
      token: data.loginGoogle.token,
      userId: me.id,
    });

    setIsLoading(false);
  }, [loginGoogle, navigate]);

  useEffect(() => {
    login();
  }, []);

  if (!isLoading && ![PageId.Welcome, PageId.GetStarted].includes(pageId) && pageId !== PageId.OAuth) {
    const defaultRedirect = from || getUrl(PageId.Main);
    return <Redirect to={decodeURIComponent(defaultRedirect)} />;
  }

  return <CycleLoader />;
};

export default SSOCallback;
