import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';

import { SWIMLANE_NO_VALUE_ID } from 'src/constants/boardGroups.constants';
import useManageSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageSwimlanes';
import { useMoveDocs } from 'src/hooks/api/mutations/useMoveDoc';
import useBoardWithSwimlane from 'src/hooks/api/useBoardWithSwimlane';
import { setBoardDnd } from 'src/reactives/boardDnd.reactive';
import { Items } from 'src/types/item.types';
import { getSwimlanesCollisionDetection } from 'src/utils/dnd.util';

import { useCrossGroupStrategy } from './useCrossGroupStrategy';
import { OnItemsMovedParams, OnSwimlaneSortedParams, useGroupsDnd } from './useGroupsDnd';
import { usePreventDndToaster } from './usePreventDndToaster';
import { useShouldPreventDnD } from './useShouldPreventDnD';

export default function useSwimlaneDocsDnd() {
  const {
    boardConfigId,
    swimlanes,
    swimlaneFlattenGroups,
    getDoc,
    groupByProperty,
  } = useBoardWithSwimlane();

  const { moveSwimlane } = useManageSwimlanes(boardConfigId);
  const { moveDocs } = useMoveDocs();

  const initialItems: Items = useMemo(() => swimlaneFlattenGroups
    .reduce(
      (acc, docGroup) => ({
        ...acc,
        [docGroup.node.id]: docGroup.node.docs.edges.map(e => e.node.id),
      }),
      {},
    ), [swimlaneFlattenGroups]);

  const initialSwimlaneItems = useMemo(() => (
    swimlanes.edges.map(e => e.node.swimlaneDoc?.id ?? SWIMLANE_NO_VALUE_ID)
  ), [swimlanes]);

  /**
   * Callbacks
   */
  const onItemsMoved = useCallback(async ({
    groupId,
    previousGroupIds,
    itemsId,
    updatedItems,
    position,
  }: OnItemsMovedParams) => {
    setBoardDnd({ dragging: false });
    if (!boardConfigId) return;
    await moveDocs({
      groupId,
      previousGroupIds,
      docsMoved: itemsId.map(itemId => getDoc(itemId)),
      docsListUpdated: updatedItems[groupId]?.map(itemId => getDoc(itemId)),
      groupByProperty,
      position,
      boardConfigId,
    });
  }, [getDoc, moveDocs, groupByProperty, boardConfigId]);

  const onSwimlaneSorted = useCallback(async ({
    swimlaneActiveId,
    position,
    swimlaneUpdated,
  }: OnSwimlaneSortedParams) => {
    setBoardDnd({ dragging: false });

    const swimlane = swimlanes.edges.find(e => e.node.swimlaneDoc?.id === swimlaneActiveId);
    if (!swimlane || !boardConfigId) return;

    const swimlaneBefore = 'before' in position ? swimlanes.edges.find(e => e.node.swimlaneDoc?.id === position.before)?.node : undefined;
    const swimlaneAfter = 'after' in position ? swimlanes.edges.find(e => e.node.swimlaneDoc?.id === position.after)?.node : undefined;
    const itemsUpdated = swimlaneUpdated
      .map(id => nodeToArray(swimlanes).find(node => node.swimlaneDoc?.id === id)?.id);

    await moveSwimlane({
      swimlaneId: swimlane.node.id,
      position: swimlaneBefore?.swimlaneDoc
        ? { before: swimlaneBefore.id }
        : { after: swimlaneAfter?.id ?? '' },
      itemsUpdated,
    });
  }, [boardConfigId, swimlanes, moveSwimlane]);

  const onDndStart = useCallback(() => setBoardDnd({ dragging: true }), []);
  // const shouldPreventDragOver = useCallback((e: DragOverEvent): boolean => {
  //   const canHaveSwimlaneDoctypeParent: boolean = e.active.data.current?.canHaveSwimlaneDoctypeParent ?? true;
  //   if (canHaveSwimlaneDoctypeParent) return false;
  //   return e.over?.data.current?.swimlaneDocId !== SWIMLANE_NO_VALUE_ID;
  // }, []);

  const crossGroupStrategy = useCrossGroupStrategy(groupByProperty.__typename);
  const shouldPreventDnD = useShouldPreventDnD(groupByProperty.__typename, getDoc);

  const dndToaster = usePreventDndToaster();

  const {
    dndContextProps,
    direction,
    activeId,
    activeType,
    items,
    swimlaneItems,
  } = useGroupsDnd({
    initialItems,
    initialSwimlaneItems,
    withVoidItems: true,
    crossGroupStrategy,
    collisionDetection: getSwimlanesCollisionDetection,
    onStart: onDndStart,
    // shouldPreventDragOver,
    onItemsMoved,
    onSwimlaneSorted,
    shouldPreventDnD,
    onOver: ({
      sameGroup, isInsightInSelection, prevent: preventDnd,
    }) => {
      if (!sameGroup && (preventDnd || crossGroupStrategy === 'disabled')) {
        dndToaster.add({ isInsightInSelection });
      }
    },
  });

  return {
    dndContextProps,
    direction,
    activeId,
    activeType,
    items,
    swimlaneItems,

    activeSwimlaneDoc: activeId && activeType === 'swimlane' ? getDoc(activeId) : null,
  };
}
