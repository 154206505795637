import { DocsCountByStatusFragment } from '@cycle-app/graphql-codegen';
import { ColorTheme } from '@cycle-app/utilities';
import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { statusColors } from './Profile.constants';

type Props = {
  count?: DocsCountByStatusFragment;
  size?: number;
};

export const CountDonutChart = ({
  count, size = 18,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();

  const gapCount = [count?.notStarted, count?.started, count?.completed, count?.canceled].filter(Boolean).length;
  const gap = gapCount > 1 ? 4 : 0;
  const max = gapCount > 1 ? 100 - gap * (gapCount) : 101;

  const items = [
    {
      color: statusColors.notStarted,
      percent: count ? (count.notStarted * max) / count.total : 0,
    },
    {
      color: statusColors.started,
      percent: count ? (count.started * max) / count.total : 0,
    },
    {
      color: statusColors.completed,
      percent: count ? (count.completed * max) / count.total : 0,
    },
    {
      color: statusColors.canceled,
      percent: count ? (count.canceled * max) / count.total : 0,
    },
    {
      color: theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200,
      percent: count ? 0 : max,
    },
  ].filter(item => item.percent > 0);

  return (
    <svg
      xmlns="http//www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 40"
      style={{
        flex: 'none',
      }}
    >
      <g transform="rotate(-90 20 20)">
        {items.map((item, i) => (
          <circle
            key={item.color}
            cx="20"
            cy="20"
            r="17"
            pathLength="100"
            strokeDasharray={`${item.percent} 100`}
            strokeDashoffset={-(gap * i + items.reduce((acc, it, index) => (index < i ? acc + it.percent : acc), 0))}
            style={{
              stroke: item.color,
              strokeLinecap: 'butt',
              strokeWidth: 5,
              fill: 'none',
            }}
          />
        ))}
      </g>
    </svg>
  );
};
