import { IntegrationType, LinkNotionPageDocument, LinkNotionPageMutationVariables } from '@cycle-app/graphql-codegen/generated';

import { handleNotionError } from 'src/hooks/api/queries/integrations/useNotionPage';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useLinkNotionPage = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Notion);
  const integrationId = integration?.id;
  const isActive = !!integration?.provider;

  const [linkNotationPageMutation, { loading }] = useSafeMutation(LinkNotionPageDocument, {
    onError: handleNotionError,
  });

  const linkNotionPage = (variables: LinkNotionPageMutationVariables) => (
    (!!integrationId && isActive && linkNotationPageMutation({ variables })) || null);

  return {
    isLoading: loading,
    linkNotionPage,
  };
};
