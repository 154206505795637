import { MoreHorizIcon } from '@cycle-app/ui/icons';
import { TippyProps } from '@tippyjs/react';
import { BubbleMenu, Editor } from '@tiptap/react';

import { Layer } from 'src/types/layers.types';

import { getColumnTableActions } from './TableActions';
import { ColumnMenuWrapper, StyledDotsMenuLayer } from './TableMenu.styles';
import { getDomRect } from './TableMenu.utils';

export type ColumnTableMenuProps = {
  editor: Editor;
  position: TippyProps['placement'];
};

export const ColumnTableMenu = ({
  editor, position,
}: ColumnTableMenuProps) => {
  const shouldShow = () => editor.isActive('table');

  return (
    <BubbleMenu
      tippyOptions={{
        placement: position,
        interactive: true,
        getReferenceClientRect: () => getDomRect(editor, 'column'),
        offset: [0, 0],
      }}
      shouldShow={shouldShow}
      editor={editor}
    >
      <ColumnMenuWrapper position={position}>
        <StyledDotsMenuLayer
          withPortal
          options={getColumnTableActions(editor, position)}
          icon={(<MoreHorizIcon size={20} />)}
          layer={Layer.DropdownModalZ3}
        />
      </ColumnMenuWrapper>
    </BubbleMenu>
  );
};
