import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { openNotifications } from 'src/reactives/notifications.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutSidebar } from 'src/types/shortcuts.types';

export const useSidebarShortcutListener = () => {
  useHotkeyListener({
    shortcuts: [
      ShortcutSidebar.OpenNotifCenter,
    ],
    callbacks: {
      [ShortcutSidebar.OpenNotifCenter]: openNotifications,
    },
    disableOnLayers: [
      Layer.Dropdown,
      Layer.Modal,
      Layer.ModalZ2,
      Layer.ModalZ3,
    ],
  });
};
