import { useQuery } from '@apollo/client';
import { FetchMeDocument, MateFragment, ProductBaseBySlugDocument } from '@cycle-app/graphql-codegen';
import { CycleLogo, Spinner } from '@cycle-app/ui';
import { CaretIcon, ZapierIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { ProductLabel } from 'src/app/NewDoc/Product';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useProducts } from 'src/hooks/api/useProducts';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import useQueryParams from 'src/hooks/useQueryParams';
import { useGetLastView } from 'src/reactives/lastView.reactive';
import { extract } from 'src/types/graphql.types';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import {
  Loader, Root, Container, Header, AppLogo, StyledSortIcon,
  Body, Title, Info, SubmitButton, ProductButton, StyledProductSelect,
} from './Authorize.styles';

const endpoint = `${(import.meta.env.VITE_HTTP_API_URI as string)}/oauth/authorize`;

const Authorize = () => {
  useProducts();
  const queryParams = useQueryParams();
  const clientId = queryParams.get('client_id');
  const {
    data: user, loading,
  } = useQuery(FetchMeDocument, {
    variables: { id: clientId },
    skip: !clientId,
  });
  const client = extract('OtherUser', user?.node);

  return (
    <Root>
      <Container $isLoading={!client?.id}>
        {loading && <Loader />}
        {!loading && client?.id && <AuthorizeContent client={client} />}
        {!loading && !client?.id && <p style={{ textAlign: 'center' }}>Application not found</p>}
      </Container>
    </Root>
  );
};

const AuthorizeContent = ({ client }: { client: MateFragment }) => {
  const { productSlug } = useGetLastView();
  const [selectedProductSlug, setSelectedProductSlug] = useState(productSlug);

  useProducts({
    onCompleted: (data) => {
      if (selectedProductSlug) return;
      const firstProductSlug = data.node.products.edges[0]?.node.slug;
      if (!firstProductSlug) return;
      setSelectedProductSlug(firstProductSlug);
    },
  });

  const queryParams = useQueryParams();

  const productQuery = useQuery(ProductBaseBySlugDocument, {
    fetchPolicy: 'cache-first',
    skip: !selectedProductSlug,
    variables: {
      slug: selectedProductSlug,
    },
  });
  const product = productQuery.data?.product;

  const [isSubmitting, { toggleCallback: setToggleSubmitting }] = useOptimizedBooleanState(false);

  const onAuthorize = async () => {
    const clientId = queryParams.get('client_id');
    const state = queryParams.get('state');
    const redirectUri = queryParams.get('redirect_uri');
    const responseType = queryParams.get('response_type');
    if (
      !product ||
      !clientId ||
      !redirectUri ||
      !responseType ||
      !state
    ) return;
    setToggleSubmitting();
    const url = new URL(endpoint);
    url.searchParams.append('client_id', clientId);
    url.searchParams.append('redirect_uri', redirectUri);
    url.searchParams.append('product_id', product.id);
    url.searchParams.append('response_type', responseType);
    url.searchParams.append('state', state);
    try {
      const data = await fetch(url.toString(), { headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth') || '{}').token}` } });
      const res = await data.text();
      // eslint-disable-next-line no-restricted-globals
      location.href = res;
    } catch {
      setToggleSubmitting();
      addErrorToaster({ message: ErrorMessage._GENERIC });
    }
  };

  return (
    <>
      <Header>
        {client.firstName?.trim().toLowerCase() === 'zapier'
          ? <ZapierIcon size={48} />
          : <AppLogo>{client.firstName?.at(0)}</AppLogo>}
        <StyledSortIcon size={20} direction="right" />
        <CycleLogo size={48} animation="hover" />
      </Header>

      <Body>
        <Title>
          {`${client.firstName} is requesting access to Cycle`}
        </Title>
        <Info>
          {`${client.firstName} will have permissions to write and read access`}
        </Info>
      </Body>

      <StyledProductSelect
        placement="bottom-start"
        onChange={slug => {
          if (slug) setSelectedProductSlug(slug);
        }}
        button={props => (
          <ProductButton
            {...props}
            iconEnd={<CaretIcon size={14} direction={props['data-active'] ? 'top' : 'bottom'} />}
          >
            {product ? <ProductLabel product={product} /> : <Spinner /> }
          </ProductButton>
        )}
      />

      <SubmitButton
        isLoading={isSubmitting}
        onClick={onAuthorize}
        disabled={!selectedProductSlug}
      >
        {`Authorize ${client.firstName}`}
      </SubmitButton>
    </>
  );
};

export default Authorize;
