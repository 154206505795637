import { getPermission, setLimitationsModal } from '../reactives';

/**
 * @example
 * ```js
 * requestInsightDelete(callback);
 * // or
 * const can = requestInsightDelete();
 * if (!can) return;
 * callback();
 * ```
 */
export const requestInsightDelete = (callback?: VoidFunction) => {
  if (!getPermission().canDeleteInsight) {
    setLimitationsModal({ action: 'INSIGHT_DELETE' });
    return false;
  }
  callback?.();
  return true;
};
