import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

type LinearProps = SvgProps & {
  overwriteColor?: string;
};

export const LinearIcon = ({
  overwriteColor, ...props
}: LinearProps) => (
  <Svg
    style={{ color: overwriteColor || '#5E6AD2' }}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
    {...props}
  >
    <path
      fill="currentcolor"
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M7.995 1a6.99 6.99 0 0 0-5.312 2.438l9.88 9.879A7.005 7.005 0 0 0 7.995 1Zm-6.44 4.245 9.2 9.2c.335-.144.657-.313.963-.505L2.06 4.282a6.985 6.985 0 0 0-.505.963ZM8.375 15 1 7.625a7 7 0 0 1 .188-1.28l8.467 8.467c-.414.1-.842.165-1.28.188ZM1.088 9.181l5.73 5.73a7.011 7.011 0 0 1-5.73-5.73Z"
    />
  </Svg>
);
