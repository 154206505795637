import { useGetLearnMorePopup, setLearnMorePopup } from 'src/reactives';

export const useFeedbackTour = () => {
  return {
    open: () => setLearnMorePopup('feedback', true),
    close: () => setLearnMorePopup('feedback', false),
  };
};

export const useIsFeedbackTourActive = () => {
  const isOpen = useGetLearnMorePopup('feedback');

  return {
    isActive: isOpen,
  };
};
