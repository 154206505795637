import { useLazyQuery } from '@apollo/client';
import { SearchGithubIssuesDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';

import { Events, Methods } from 'src/constants/analytics.constants';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { trackAnalytics } from 'src/utils/analytics/analytics';

export const useGithubIssues = () => {
  const {
    getIntegration, isLoading,
  } = useProductIntegrations();
  const [refetch, {
    data, loading: isSearchLoading,
  }] = useLazyQuery(SearchGithubIssuesDocument, {
    onCompleted() {
      trackAnalytics(Events.IntegrationIssueMentioned, {
        type: IntegrationType.Github,
        method: Methods.SlashCommand,
      });
    },
  });

  const integration = getIntegration(IntegrationType.Github);
  const integrationId = integration?.id;
  const issues = useMemo(() => {
    if (
      data?.node?.__typename !== 'Integration' ||
      data?.node.provider?.__typename !== 'Github'
    ) return [];

    return data.node.provider.issues;
  }, [data]);

  const searchGithubIssues = useCallback((query: string) => {
    if (!integrationId) return;

    refetch({
      variables: {
        integrationId,
        query,
      },
    });
  }, [integrationId, refetch]);

  return {
    isIntegrationLoading: isLoading,
    isInstalled: !!integration?.provider,
    isSearchLoading,
    issues,
    search: searchGithubIssues,
  };
};
