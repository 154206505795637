import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const UsersIcon: FC<SvgProps> = (props) => (
  <Svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M11.4556 9.86038C13.5018 9.36289 15.81 10.5475 16.6086 13.4142C16.8421 14.2522 16.1353 15 15.2654 15H12.7499M7.87496 5.25003C7.87496 6.49267 6.8676 7.50003 5.62496 7.50003C4.38232 7.50003 3.37496 6.49267 3.37496 5.25003C3.37496 4.00739 4.38232 3.00003 5.62496 3.00003C6.8676 3.00003 7.87496 4.00739 7.87496 5.25003ZM14.625 5.25003C14.625 6.49267 13.6176 7.50003 12.375 7.50003C11.1323 7.50003 10.125 6.49267 10.125 5.25003C10.125 4.00739 11.1323 3.00003 12.375 3.00003C13.6176 3.00003 14.625 4.00739 14.625 5.25003ZM8.5155 15H2.73442C1.86446 15 1.15806 14.2497 1.39184 13.4117C2.75389 8.52947 8.49603 8.52947 9.85809 13.4117C10.0919 14.2497 9.38546 15 8.5155 15Z"
      stroke="currentcolor"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
