// Stolen from https://github.com/ueberdosis/tiptap/issues/214#issuecomment-964557956

import { PluginKey, Plugin } from '@tiptap/pm/state';
import { DecorationSet, Decoration, EditorView } from '@tiptap/pm/view';
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';

import { COMMANDS } from 'src/constants/editor.constants';

import { findSuggestionMatch } from './findSuggestionMatch';

const suggestionChars: string[] = [COMMANDS.DOC_MENTION, COMMANDS.USER_MENTION];

type PluginState = {
  active: boolean;
  key: string | null;
  range: {
    from?: number;
    to?: number;
  };
  query: string | null;
  text: string | null;
  composing: boolean;
  decorationId?: string;
};

type SuggestionParams = SuggestionOptions<PluginState> & {
  pluginKeyName?: string;
  prefixSpace?: boolean;
};

export type SuggestionPluginProps = SuggestionProps<PluginState> & {
  pluginKey?: PluginKey<PluginState>;
};

export const suggestionPlugin = ({
  pluginKeyName,
  editor,
  char = COMMANDS.USER_MENTION,
  allowSpaces = false,
  prefixSpace = true,
  startOfLine = false,
  decorationTag = 'span',
  decorationClass = 'suggestion',
  command,
  items,
  render,
  allow,
}: SuggestionParams) => {
  const pluginKey = new PluginKey<PluginState>(pluginKeyName);
  const renderer = render?.();

  const exitSuggestion = (view: EditorView) => {
    const state = {
      active: false,
      key: null,
      range: {},
      query: null,
      text: null,
      composing: false,
    };
    view.dispatch(view.state.tr.setMeta(pluginKey, state));
  };

  return new Plugin<PluginState>({
    key: pluginKey,
    view() {
      return {
        update: async (view, prevState) => {
          const prev = this.key?.getState(prevState);
          const next = this.key?.getState(view.state);
          // See how the state changed
          const moved =
            prev.active &&
            next.active &&
            !!prev.range.from &&
            prev.range.from !== next.range.from;
          const started = !prev.active && next.active;

          const stopped = prev.active && !next.active;
          const changed = !started && !stopped && prev.query !== next.query;
          const handleStart = started || moved;
          const handleChange = changed && !moved;
          const handleExit = stopped || moved;

          // Cancel when suggestion isn't active
          if (!handleStart && !handleChange && !handleExit) {
            return;
          }

          const state = handleExit && !handleStart ? prev : next;
          const decorationNode = document.querySelector(
            `[data-decoration-id="${state.decorationId}"]`,
          );
          const props: SuggestionPluginProps = {
            editor,
            pluginKey,
            range: state.range,
            query: state.query,
            text: state.text,
            items:
              handleChange || handleStart
                ? await items?.({
                  editor,
                  query: state.query,
                }) ?? []
                : [],
            command: (commandProps) => {
              if (!commandProps) {
                exitSuggestion(props.editor.view);
                return;
              }
              command?.({
                editor,
                range: state.range,
                props: commandProps,
              });
            },
            decorationNode,
            // virtual node for popper.js or tippy.js
            // this can be used for building popups without a DOM node
            clientRect: decorationNode
              ? () => {
                // because of `items` can be asynchrounous we’ll search for the current decoration node
                const decorationId = this.key?.getState(editor.state)?.decorationId;
                const currentDecorationNode = document.querySelector(
                  `[data-decoration-id="${decorationId}"]`,
                );

                return currentDecorationNode?.getBoundingClientRect() ?? null;
              }
              : null,
          };
          if (handleExit) {
            exitSuggestion(view);
            renderer?.onExit?.(props);
          }
          if (handleChange) {
            renderer?.onUpdate?.(props);
          }
          if (handleStart) {
            renderer?.onStart?.(props);
          }
        },
      };
    },
    state: {
      // Initialize the plugin's internal state.
      init() {
        return {
          active: false,
          key: null,
          range: {},
          query: null,
          text: null,
          composing: false,
        };
      },
      // Apply changes to the plugin state from a view transaction.
      apply(transaction, prev, _, newState) {
        let next;

        const lastChar = newState.selection.$head.parent.textContent.slice(-1);
        const hasSuggestionChar = suggestionChars.includes(lastChar);

        if (transaction.doc.textContent !== char && transaction.getMeta(pluginKey)) {
          next = {
            ...transaction.getMeta(pluginKey),
          };
        } else if (hasSuggestionChar) {
          // To handle suggestion char being inserted programmatically by the editor
          next = {
            ...prev,
            active: true,
          };
        } else {
          next = {
            ...prev,
          };
        }

        // when clicking in prev.text === null and prev.range emptyish
        const { composing } = editor.view;
        const { selection } = transaction;
        const {
          empty, from,
        } = selection;
        next.composing = composing;

        // Don't advance with suggesiton if not active and not the open character.
        if (!next.active && next.key !== char) {
          return next;
        }
        if (next.active && next.key === char) {
          next.key = null;
          return next;
        }

        // We can only be suggesting if there is no selection
        // or a composition is active (see: https://github.com/ueberdosis/tiptap/issues/1449)
        if (empty || editor.view.composing) {
          // Reset active state if we just left the previous suggestion range
          if (
            prev.range.from &&
            prev.range.to &&
            (from < prev.range.from || from > prev.range.to) &&
            !composing &&
            !prev.composing
          ) {
            next.active = false;
          }

          // Try to match against where our cursor currently is
          const match = findSuggestionMatch({
            char,
            allowSpaces,
            prefixSpace,
            startOfLine,
            $position: selection.$from,
          });
          const decorationId = `id_${Math.floor(Math.random() * 0xffffffff)}`;

          // If we found a match, update the current state to show it
          if (match && allow?.({
            editor,
            state: newState,
            range: match.range,
          })) {
            next.active = true;
            next.decorationId = prev.decorationId
              ? prev.decorationId
              : decorationId;
            next.range = match.range;
            next.query = match.query;
            next.text = match.text;
          } else {
            next.active = false;
          }
        } else {
          next.active = false;
        }
        // Make sure to empty the range if suggestion is inactive
        if (!next.active) {
          next.decorationId = null;
          next.range = {};
          next.query = null;
          next.text = null;
        }
        return next;
      },
    },
    props: {
      // Call the keydown hook if suggestion is active.
      handleKeyDown(view, event) {
        const { tr } = view.state;
        const state = this.getState(view.state);

        // Set state and handle start
        if (!state?.active && event.key === char) {
          const updatedState = { ...state };
          updatedState.active = true;
          updatedState.key = event.key;
          view.dispatch(tr.setMeta(pluginKey, updatedState));
          return undefined;
        }
        if (!state?.active) {
          // Ignore everything else if it is inactive.
          return false;
        }
        // Reset state on Escape and handle exit.
        if (event.key === 'Escape') {
          event.stopPropagation();
          exitSuggestion(view);
          return undefined;
        }

        if (state.range.from == null || state.range.to == null) return false;

        return (
          renderer?.onKeyDown?.({
            view,
            event,
            range: {
              from: state.range.from,
              to: state.range.to,
            },
          }) || false
        );
      },
      // Setup decorator on the currently active suggestion.
      decorations(editorState) {
        const state = this.getState(editorState);
        if (!state?.active || !state.range.from || !state.range.to) {
          return null;
        }

        return DecorationSet.create(editorState.doc, [
          Decoration.inline(state.range.from, state.range.to, {
            nodeName: decorationTag,
            class: decorationClass,
            'data-decoration-id': state.decorationId,
            ...state.text === COMMANDS.DOC_MENTION && { 'data-doc-mention-empty': 'true' },
          }),
        ]);
      },
    },
  });
};
