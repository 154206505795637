import { DotsMenu, DotsMenuProps } from '@cycle-app/ui';
import React, { FC, useCallback, useState, ReactNode } from 'react';

import Portal from 'src/components/Portal/Portal';
import { mappingZindex } from 'src/constants/zIndex.constant';
import useDropdown from 'src/hooks/useDropdown';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';

import { ClosingArea } from '../DropdownLayer/DropdownLayer.styles';

interface Props extends DotsMenuProps {
  layer?: Layer;
  icon?: ReactNode;
}

const DotsMenuLayer: FC<React.PropsWithChildren<Props>> = ({
  layer = Layer.Dropdown,
  visible: visibleFromProps,
  setVisible: setVisibleFromProps,
  hide: hideFromProps,
  onHide: onHideProps,
  onMount: onMountProps,
  icon,
  options,
  ...props
}) => {
  const [visible, setIsVisible] = useState(false);
  const setVisible = setVisibleFromProps ?? setIsVisible;

  const hideDropdown = useCallback(() => setVisible(false), [setVisible]);

  const dropdownProps = useDropdown({
    layer,
    hide: hideFromProps ?? hideDropdown,
    onDropdownMounted: onMountProps,
    onDropdownHidden: onHideProps,
  });

  const isDropdownVisible = visibleFromProps ?? visible;

  return (
    <>
      {!!options.length && (
        <DotsMenu
          {...props}
          {...dropdownProps}
          options={options}
          icon={icon}
          visible={isDropdownVisible}
          forceFocus={isDropdownVisible}
          setVisible={setVisibleFromProps ?? setIsVisible}
          zIndex={mappingZindex[layer]}
        />
      )}
      {isDropdownVisible && (
        <Portal portalId={PortalId.DropdownClosingArea}>
          <ClosingArea onClick={e => { e.stopPropagation(); hideDropdown(); }} layer={layer} />
        </Portal>
      )}
    </>
  );
};

export default DotsMenuLayer;
