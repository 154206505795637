import { QueryHookOptions, useQuery } from '@apollo/client';
import { CustomerDocument, CustomerFragment, CustomerWithDoctypeCountFragment } from '@cycle-app/graphql-codegen';

type Options = Omit<QueryHookOptions<{ node: CustomerFragment }>, 'variables'>;

export const useCustomer = (customerId: string | undefined, options?: Options) => {
  const {
    data, loading,
  } = useQuery<{ node: CustomerWithDoctypeCountFragment }>(CustomerDocument, {
    fetchPolicy: 'cache-first',
    skip: !customerId,
    ...options,
    variables: {
      id: customerId,
    },
  });
  
  return {
    customer: data?.node,
    loading,
  };
};
