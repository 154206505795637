import { ColorTheme } from '@cycle-app/utilities';
import styled, { css, keyframes } from 'styled-components';

import { body400 } from '../../../theme/typo';
import { BOX_SHADOW_COLOR_Z2, BOX_SHADOW_COLOR_Z1 } from '../../../utils/styles.util';
import { Label } from '../CheckboxInput/CheckboxInput.styles';

export type ToggleVariant = 'primary' | 'secondary' | 'ai';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

const ToggleHeight = 16;
const DotSize = 14;
const ToggleWidth = 26;
const DotMargin = 2;

export const Toggle = styled.div`
  background: var(--bg);
  border-radius: ${ToggleHeight}px;
  width: ${ToggleWidth + DotMargin}px;
  height: ${ToggleHeight + DotMargin}px;
  position: relative;
  transition: background-color 0.15s ease;
  background-size: 200% 200%;
  animation: ${gradientAnimation} 5s ease infinite;
`;

export const ToggleDot = styled.div`
  background: var(--dotBg);
  position: absolute;
  top: ${ToggleHeight - DotSize}px;
  left: ${ToggleHeight - DotSize}px;
  border-radius: ${ToggleHeight}px;
  width: ${DotSize}px;
  height: ${DotSize}px;
  transition: transform 0.15s ease;
  box-shadow: var(--shadowDot);
`;

export const StyledInput = styled.input<{ $variant?: ToggleVariant }>`
  display: none;

  &:checked + ${Label} {
    --bg: var(--bgChecked);

    ${ToggleDot} {
      transform: translateX(${ToggleWidth - DotSize - DotMargin}px);
    }
  }

  ${p => p.$variant === 'ai' && css`
    :checked + ${Label} ${LabelText} {
      background: var(--aiGradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 200%;
      animation: ${gradientAnimation} 5s ease infinite;
    }
  `}
`;

export const LabelText = styled.span`
  ${body400};
  color: ${p => p.theme.colors.text.secondary};
`;

interface ContainerProps {
  $isDisabled: boolean;
  $variant?: ToggleVariant;
}

export const Container = styled.div<ContainerProps>`
  --bg: ${p => p.theme.colors.background.disabled};
  --bgChecked: hsl(var(--cycle));
  --bgHover: hsl(var(--cycle600));
  --dotBg: ${p => p.theme.colors.background.white};
  --color: ${p => p.theme.colors.text.primary};
  --shadow: ${BOX_SHADOW_COLOR_Z2};
  --shadowDot: ${BOX_SHADOW_COLOR_Z1};
  --aiGradient:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(134.84deg, #AE8FFF 3.13%, #00D2F3 57.65%, #4DED8D 107.19%);

  ${p => p.$isDisabled && css`
    --color: ${p.theme.colors.text.disabled};
    opacity: 0.75;

    ${Label} {
      cursor: not-allowed;
    }
  `};

  ${p => !p.$isDisabled && css`
    &:hover {
      ${Toggle} {
        filter: drop-shadow(var(--shadow));
      }

      ${StyledInput}:checked + ${Label} {
        --bg: var(--bgHover);
      }
    }
  `}

  ${p => (p.$variant === 'secondary' || p.$variant === 'ai') && p.theme.isDark && `
    --bg: ${p.theme.colors.background.primary};
    --dotBg: ${ColorTheme.Grey400};
  `}

  ${p => p.$variant === 'ai' && `
    --bgChecked: var(--aiGradient);
    --bgHover: var(--aiGradient);
  `}
`;
