import { StatusCategory } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { CaretIcon, AiIcon, CheckCircleAltIcon } from '@cycle-app/ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { CommentEditor } from 'src/components/Editor';
import { VerifiedQuotePopover, VerifyQuoteModal, DiscardQuote, QuotePopover, VerifyQuote } from 'src/components/QuotePopover';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useExtractQuotesContext } from 'src/contexts/extractQuotesContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { openVerifyQuotes } from 'src/reactives/docRightPanel.reactive';
import { resetHighlight, setHighlight, useGetHighlight } from 'src/reactives/highlight.reactive';

import {
  Container, Header, Title, Content, MainButton, LoadingButton, DisabledButton, CounterButton,
  Section, SectionTitle, List, ListItem, RetryButton,
} from './QuotesSummary.styles';
import { setSummaryVisibleFalse, setSummaryVisibleTrue } from '../../reactives/summaryVisible.reactive';

type QuotesSummaryProps = {
  docId: string;
  docContentElement?: HTMLElement | null;
  initialExpanded: boolean;
};

export const QuotesSummary = ({
  docId, docContentElement, initialExpanded,
}: QuotesSummaryProps) => {
  // Used to highlight the quote in the list when his popover is open
  const [activeItem, setActiveItem] = useState<string | null>(null);
  // Used to open the verify quote modal
  const [verifyQuoteId, setVerifyQuoteId] = useState<string | null>(null);
  // Used to open the remove quote modal
  const [removeQuoteId, setRemoveQuoteId] = useState<string | null>(null);

  const [isExpanded, {
    toggleCallback: toggleExpanded,
    setTrueCallback: expand,
  }] = useOptimizedBooleanState(initialExpanded);

  // Prev/next navigation: expand the summary if there is a quote to verify
  useEffect(() => {
    if (!isExpanded && initialExpanded) {
      expand();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  const {
    status, extractQuotes, quotes, insights,
  } = useExtractQuotesContext();

  const disabled = status !== 'verify' && status !== 'verified';

  const docStatusCategory = useDocPanelContext(ctx => ctx.doc?.status?.category);
  const isExtractDisabled = docStatusCategory === StatusCategory.Completed || docStatusCategory === StatusCategory.Canceled;

  const { ref: headerRef } = useInView({
    root: docContentElement,
    threshold: 0.5,
    initialInView: true,
    onChange: inView => {
      if (inView) {
        setSummaryVisibleTrue();
      } else {
        setSummaryVisibleFalse();
      }
    },
  });

  const highlight = useGetHighlight();

  return (
    <Container $disabled={disabled}>
      <Header
        onClick={toggleExpanded}
        $disabled={disabled}
        ref={headerRef}
      >
        <Title>
          {!disabled && (
            <CaretIcon
              size={11}
              direction={isExpanded ? 'bottom' : 'right'}
            />
          )}
          Summary of quotes
        </Title>

        {status === 'extract' && !isExtractDisabled && (
          <MainButton onClick={extractQuotes}>
            <AiIcon size={14} />
            Extract quotes
          </MainButton>
        )}

        {status === 'extract' && isExtractDisabled && (
          <Tooltip
            withPortal
            withWrapper={false}
            placement="top"
            content={getExtractDisabledMessage(docStatusCategory)}
          >
            <DisabledButton onClick={e => { e.stopPropagation(); }}>
              <AiIcon size={14} />
              Extract quotes
            </DisabledButton>
          </Tooltip>
        )}

        {status === 'loading' && (
          <LoadingButton onClick={e => { e.stopPropagation(); }}>
            <AiIcon isAnimated size={14} />
            Looking for quotes…
          </LoadingButton>
        )}

        {status === 'retry' && (
          <DisabledButton onClick={e => { e.stopPropagation(); }}>
            <AiIcon size={14} />
            No quote to verify
            <RetryButton onClick={extractQuotes}>
              Retry
            </RetryButton>
          </DisabledButton>
        )}

        {status === 'verified' && (
          <Tooltip
            withPortal
            withWrapper={false}
            placement="top"
            content={isExtractDisabled
              ? getExtractDisabledMessage(docStatusCategory)
              : 'Remove existing AI-generated quotes if you want to extract new ones'}
          >
            <DisabledButton onClick={e => { e.stopPropagation(); }}>
              <AiIcon size={14} />
              {verifyQuotesLabel()}
            </DisabledButton>
          </Tooltip>
        )}

        {status === 'verify' && quotes.length > 0 && (
          <CounterButton
            variant="naked"
            onClick={e => {
              e.stopPropagation();
              openVerifyQuotes();
            }}
          >
            {verifyQuotesLabel(quotes.length)}
          </CounterButton>
        )}
      </Header>
      <AnimatePresence>
        {isExpanded && !disabled && (
          <motion.div
            initial={{
              opacity: 0,
              height: 0,
            }}
            animate={{
              opacity: 1,
              height: 'auto',
            }}
            exit={{
              opacity: 0,
              height: 0,
            }}
          >
            <Content>
              {quotes.length > 0 && (
                <Section>
                  <SectionTitle>
                    <AiIcon hasGradient size={14} />
                    To verify
                  </SectionTitle>
                  <List>
                    {quotes.map(quote => (
                      <QuotePopover
                        key={quote.id}
                        onMount={() => setActiveItem(quote.id)}
                        onHide={() => setActiveItem(null)}
                        buttons={(
                          <>
                            <VerifyQuote onClick={() => setVerifyQuoteId(quote.id)} />
                            <DiscardQuote docId={docId} quoteId={quote.id} />
                          </>
                        )}
                      >
                        <ListItem $active={activeItem === quote.id}>
                          {quote.content && (
                            <CommentEditor
                              isReadOnly
                              content={quote.content}
                            />
                          )}
                        </ListItem>
                      </QuotePopover>
                    ))}
                  </List>
                </Section>
              )}

              {insights.length > 0 && (
                <Section>
                  <SectionTitle>
                    <CheckCircleAltIcon />
                    Verified
                  </SectionTitle>
                  <List>
                    {insights.map((insight) => {
                      const highlighted = highlight.blockId === insight.id;
                      return (
                        <VerifiedQuotePopover
                          key={insight.id}
                          insight={insight}
                          placement="top-start"
                          featurePlacement="bottom-start"
                          editQuotePlacement="bottom-start"
                          onMount={() => setActiveItem(insight.id)}
                          onHide={() => setActiveItem(null)}
                        >
                          {active => (
                            <ListItem
                              key={insight.id}
                              $active={activeItem === insight.id || active || highlighted}
                              onMouseEnter={() => {
                                setHighlight({
                                  docId: null,
                                  blockId: insight.id,
                                  context: 'doc-link-list',
                                });
                              }}
                              onMouseLeave={() => {
                                resetHighlight();
                              }}
                            >
                              {insight.content && (
                                <CommentEditor
                                  isReadOnly
                                  content={insight.content}
                                />
                              )}
                            </ListItem>
                          )}
                        </VerifiedQuotePopover>
                      );
                    })}
                  </List>
                </Section>
              )}
            </Content>
          </motion.div>
        )}
      </AnimatePresence>

      {verifyQuoteId && (
        <VerifyQuoteModal
          docId={docId}
          quoteId={verifyQuoteId}
          hide={() => setVerifyQuoteId(null)}
        />
      )}

      {removeQuoteId && (
        <DocDeleteWarningModal
          docId={removeQuoteId}
          onHide={() => setRemoveQuoteId(null)}
        />
      )}
    </Container>
  );
};

export const verifyQuotesLabel = (count = 0) => {
  if (count === 0) return 'No quote to verify';
  if (count === 1) return '1 quote to verify';
  return `${count} quotes to verify`;
};

export const getExtractDisabledMessage = (docStatusCategory?: StatusCategory) => {
  if (docStatusCategory === StatusCategory.Completed) return 'Quote extraction is disabled when feedback is processed';
  if (docStatusCategory === StatusCategory.Canceled) return 'Quote extraction is disabled when feedback is canceled';
  return '';
};
