import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CapterraIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 345 345" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path fill="#FF9D28" d="m8.905 127.38 135.181.027 82.199.014V45.987z" />
    <path fill="#68C5ED" d="M226.285 45.987V333.49L328.95 7.534z" />
    <path fill="#044D80" d="m226.285 127.421-82.199-.014 82.199 206.083z" />
    <path fill="#E54747" d="m8.905 127.38 156.26 52.905-21.079-52.878z" />
  </Svg>
);
