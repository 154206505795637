import { ThemeType } from '@cycle-app/graphql-codegen';
import { typo, ActionButton, Button } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Modal = styled(PortalModal)`
  position: relative;
  padding: 0;
  width: 770px;
  max-width: 770px;
  max-height: 80%;
  ${p => p.theme.themeType === ThemeType.Nightfall && css`background-color: #171618;`}
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  height: 24px;
  width: 24px;
  right: 16px;
  top: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--s-3);
  padding: var(--s-9) 0 0;
`;

export const Title = styled.h2`
  ${typo.headerLarge600}
  text-align: center;
`;

export const SubTitle = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
`;

export const Text = styled.p`
  ${typo.body400}
  text-align: center;
  color: ${p => p.theme.colors.text.secondary};
`;

export const ButtonsGroup = styled.div`
  width: 450px;
  max-width: 100%;
  display: flex;
  gap: 16px;
`;

export const PrevButton = styled(Button).attrs({
  variant: 'outlined-alt',
  size: 'M',
})`
  flex: 1;
`;

export const NextButton = styled(Button).attrs({ size: 'M' })`
  justify-content: center;
  flex: 1;
`;

export const Footer = styled.div`
  ${p => `--bg: ${p.theme.themeType === ThemeType.Nightfall ? '#171618' : p.theme.colors.modal.content.bg};`}
  background-color: var(--bg);
  box-shadow: 0 0 10px 10px var(--bg);
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: var(--s-6) 0 var(--s-9);
  position: sticky;
  width: 100%;
  z-index: 3;

`;
