import { useApolloClient } from '@apollo/client';
import { DoctypeNodeDocument, DoctypeAttributeDefinitionsFragmentDoc } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { extract } from 'src/types/graphql.types';

export const useGetDoctypeFromCache = () => {
  const { cache } = useApolloClient();

  return useCallback((doctypeId: string) => {
    const result = cache.readQuery({
      query: DoctypeNodeDocument,
      variables: {
        id: doctypeId,
      },
    });
    return extract('Doctype', result?.node);
  }, [cache]);
};

export const useGetDoctypeAttributeDefinitions = () => {
  const { cache } = useApolloClient();

  return useCallback((doctypeId: string) => cache.readFragment({
    fragment: DoctypeAttributeDefinitionsFragmentDoc,
    fragmentName: 'DoctypeAttributeDefinitions',
    id: doctypeId,
  })?.attributeDefinitions, [cache]);
};
