import { BOX_SHADOW_COLOR_Z1, typo, ActionButton, Skeleton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

const TIME_WIDTH = 48;
const GAP = 16;
const PLAYER_WIDTH = 150;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 12px;
  overflow: hidden;
  cursor: default;
  padding: 12px 16px;
  gap: ${GAP}px;
  border: 1px solid ${p => p.theme.colors.components.AudioPlayer.border};
  background-color: ${p => p.theme.colors.components.AudioPlayer.bg};
  box-shadow: 0px 1px 2px ${BOX_SHADOW_COLOR_Z1};

  ${mobile} {
    padding: 8px 10px;
  }
`;

export const PlayerContainer = styled.div<{ $isLoading: boolean }>`
  height: 32px;
  width: ${PLAYER_WIDTH}px;
  cursor: pointer;
  z-index: 1;

  ${mobile} {
    height: 18px;
    width: 100px;
  }

  ${p => p.$isLoading && `
    display: none;
  `}
`;

export const PlayButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: hsla(var(--cycle), 1);
  flex-shrink: 0;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${mobile} {
    width: 28px;
    height: 28px;
  }
`;

export const Time = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};
  display: inline-block;
  width: ${TIME_WIDTH}px;
`;

export const VolumeContainer = styled.div`
  display: flex;
  position: relative;

  ${mobile} {
    display: none;
  }
`;

export const VolumeButton = styled(ActionButton)`
  height: 24px;

  ${mobile} {
    display: none;
  }
`;

export const VolumeClick = styled.div`
  cursor: pointer;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VolumeHiddenContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  padding: 0 16px;
  background: brown;
  gap: 16px;
  align-items: center;
  border-radius: 68px;
  background-color: ${p => p.theme.colors.components.AudioPlayer.volumeBg};
`;

export const VolumeProgressContainer = styled.div`
  position: relative;
  height: 8px;
  width: 225px;
  border-radius: 30px;
  background-color: ${p => p.theme.colors.components.AudioPlayer.volumeProgressBg};
  cursor: pointer;
  overflow: hidden;
`;

export const VolumeProgress = styled.span`
  position: absolute;
  width: 50%;
  height: 8px;
  border-radius: 30px;
  background-color: ${p => p.theme.colors.components.AudioPlayer.volumeProgress};
  pointer-events: none;
`;

export const PlayerSkeleton = styled(Skeleton)`
  height: 20px;
  width: ${TIME_WIDTH * 2 + GAP * 2 + PLAYER_WIDTH}px;
`;
