/* eslint-disable no-nested-ternary */
import { Badge, Spinner } from '@cycle-app/ui';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import { RefObject } from 'react';

import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import { useCompanyDocsCount } from 'src/hooks/api/queries/customers/useCompanyDocsCount';
import {
  setCompanyTabId,
  setCompanyStatusCategory,
  useCompanyTabId,
  useCompanyStatusCategory,
} from 'src/reactives/customerCompanyProfile.reactive';
import { useInsightParentDocTypes } from 'src/reactives/docTypes.reactive';

import { FeaturesCounters } from './FeaturesCounters';
import { FeaturesListCompany } from './FeaturesListCompany';
import { PeopleList } from './PeopleList';
import {
  Tabs, TabsScrollable, TabList, Tab, TabPanels, TabPanel, FeaturesHeader,
} from './Profile.styles';
import { QuotesListCompany } from './QuotesListCompany';
import { StatusCategorySelect } from './StatusCategorySelect';

type Props = {
  companyId: string;
  tabPanelsRef?: RefObject<HTMLDivElement>;
};

export const TabsCompany = ({
  companyId, tabPanelsRef,
}: Props) => {
  const {
    docsCount, isLoaded: isCountLoaded,
  } = useCompanyDocsCount(companyId);

  const {
    company, isLoading: isLoadingCompany,
  } = useCompany(companyId);

  const tabId = useCompanyTabId();
  const statusCategory = useCompanyStatusCategory();

  const docTypes = useInsightParentDocTypes();
  if (!docTypes.length) return null;

  const quotesCount = sumBy(docsCount, 'totalInsights');
  const sortedDocTypes = sortBy(docTypes, 'name');

  const tabIds = ['people', 'quotes', ...sortedDocTypes.map(docType => docType.id)];
  const tabIndex = tabIds.indexOf(tabId);

  return (
    <Tabs
      index={tabIndex < 0 ? 0 : tabIndex}
      onChange={index => setCompanyTabId(tabIds[index] ?? 'people')}
    >
      <>
        <TabsScrollable>
          <TabList>
            <Tab
              $selected={tabId === 'people'}
            >
              People
              {isLoadingCompany ? <Spinner /> : <Badge>{company?.countCustomers ?? 0}</Badge>}
            </Tab>

            <Tab
              $selected={tabId === 'quotes'}
            >
              Quotes
              {!docsCount ? <Spinner /> : <Badge>{quotesCount}</Badge>}
            </Tab>

            {sortedDocTypes.map(docType => {
              const count = docsCount?.find(c => c.doctype?.id === docType.id)?.total ?? 0;
              return (
                <Tab
                  key={docType.id}
                  $selected={tabId === docType.id}
                >
                  {docType.name}
                  {!docsCount ? <Spinner /> : <Badge>{count}</Badge>}
                </Tab>
              );
            })}
          </TabList>
        </TabsScrollable>

        <TabPanels ref={tabPanelsRef}>
          <TabPanel>
            {tabId === 'people' && <PeopleList companyId={companyId} />}
          </TabPanel>
          <TabPanel>
            {tabId === 'quotes' && <QuotesListCompany companyId={companyId} />}
          </TabPanel>
          {sortedDocTypes.map(docType => {
            const count = docsCount?.find(c => c.doctype?.id === docType.id);
            return (
              <TabPanel key={docType.id}>
                {tabId === docType.id && (
                  <>
                    <FeaturesHeader>
                      <StatusCategorySelect
                        value={statusCategory}
                        onChange={setCompanyStatusCategory}
                      />
                      {isCountLoaded && (<FeaturesCounters count={count} />)}
                    </FeaturesHeader>

                    <FeaturesListCompany
                      companyId={companyId}
                      doctypeId={docType.id}
                    />
                  </>
                )}
              </TabPanel>
            );
          })}
        </TabPanels>
      </>
    </Tabs>
  );
};
