import { makeVar } from '@apollo/client';

import {
  getLocalState,
  resetLocalState,
  setLocalState,
  hookGetLocalState,
  hookLocalState,
} from '../hooks/useLocalState';
import { Setter, ParamsState, StateHookResult } from '../types/state.types';

type MakeResult<T> = {
  hookState: () => StateHookResult<T>;
  hookValue: () => T;
  getValue: () => T;
  setValue: Setter<Partial<T>>;
  resetValue: VoidFunction;
};

const parseContent = (content: string) => {
  try {
    return JSON.parse(content);
  } catch (e) {
    return {};
  }
};

export const make = <T>({
  defaultState, localKey, mergeTarget,
}: ParamsState<T>): MakeResult<T> => {
  const fromLocalStorage = localKey ? localStorage.getItem(localKey) : undefined;

  const reactive = makeVar<T>({
    ...defaultState,
    ...(fromLocalStorage ? parseContent(fromLocalStorage) : {}),
  });

  return {
    hookState: hookLocalState({
      reactive,
      localKey,
      defaultState,
    }),
    hookValue: hookGetLocalState({ reactive }),
    getValue: getLocalState({ reactive }),
    setValue: setLocalState({
      reactive,
      localKey,
      mergeTarget,
      defaultState,
    }),
    resetValue: resetLocalState({
      defaultState,
      reactive,
      localKey,
    }),
  };
};
