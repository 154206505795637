import { Input as InputUI } from '@cycle-app/ui';
import { SearchIcon as SearchIconUI } from '@cycle-app/ui/icons';
import styled from 'styled-components';

const GAP_SIZE = 8;

export const SearchContainer = styled.div<{ $isSearching: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 ${GAP_SIZE}px;
  width: 500px;
  max-width: 100%;
  gap: ${GAP_SIZE}px;
`;

export const Input = styled(InputUI)`
  padding-top: ${GAP_SIZE}px;
  border-radius: 6px;

  input {
    max-height: 30px;
  }
`;

export const SearchIcon = styled(SearchIconUI)`
  width: 12px;
  height: 12px;
`;
