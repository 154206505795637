import { ToasterProps } from '@cycle-app/ui';

import { AnyShortcut } from 'src/types/shortcuts.types';
import { ErrorToasterData } from 'src/types/toaster.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookState: useErrorToasters,
  hookValue: useGetErrorToasters,
  getValue: getErrorToasters,
  setValue: setErrorToasters,
  resetValue: resetErrorToasters,
} = make<{
  queue: ErrorToasterData[];
}>({
  defaultState: {
    queue: [],
  },
});

export type ToasterItem = Omit<ToasterProps, 'shortcutKeys'> & {
  duration?: number;
  shortcuts?: {
    key: AnyShortcut;
    action: VoidFunction;
    lastOnly?: boolean;
  }[];
};

export const {
  hookState: useToasters,
  hookValue: useGetToasters,
  getValue: getToasters,
  setValue: setToasters,
  resetValue: resetToasters,
} = make<{
  areShortcutsEnabled: boolean;
  areTimersEnabled: boolean;
  queue: ToasterItem[];
}>({
  defaultState: {
    areShortcutsEnabled: true,
    areTimersEnabled: true,
    queue: [],
  },
});
