import { useQuery } from '@apollo/client';
import { ReleaseNoteTagsDocument } from '@cycle-app/graphql-codegen';

import { extract } from '../../types/graphql.types';

export const useReleaseNoteTags = (releaseNoteId: string) => {
  const {
    data, loading: isLoading,
  } = useQuery(ReleaseNoteTagsDocument, {
    variables: {
      id: releaseNoteId,
    },
  });
  const note = extract('ReleaseNote', data?.node);
  return {
    isLoading,
    tags: note?.tags ?? [],
  };
};
