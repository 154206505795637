import {
  ChangeSelectStringAttributeValueDocument,
  ChangeSelectStringAttributeValueMutationVariables,
} from '@cycle-app/graphql-codegen';

import { useLoader } from 'src/hooks/useLoader';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateSelectStringAttributeValue = () => {
  const [update, { loading }] = useSafeMutation(ChangeSelectStringAttributeValueDocument);

  useLoader({ loading });

  return async (variables: ChangeSelectStringAttributeValueMutationVariables) => {
    await update({ variables });
  };
};
