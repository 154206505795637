import { typo, ShyScrollbar, TextButton, TextButtonCss } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { MakersAvatar } from '../MakersAvatar';

export const Title = styled.div`
  ${typo.body500}
  text-align: center;
`;

export const StyledMakersAvatar = styled(MakersAvatar)`
  ${typo.body500}
  justify-content: center;
  color: ${p => p.theme.colors.text.disabled};
  gap: 6px;
  margin-top: 12px;
  cursor: pointer;
`;

export const UsersListContainer = styled(ShyScrollbar)`
  max-height: 390px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const UserItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const UserName = styled.div<{ $isDisabled?: boolean }>`
  ${typo.body500}
  align-items: center;
  display: flex;
  gap: 6px;
  text-align: left;
  overflow: hidden;
`;

export const UserNameLabel = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const RoleButtonLabel = styled.span`
  ${typo.body400}
  display: block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledTextButtonCss = css`
  ${TextButtonCss}
  ${typo.body400}
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
  width: auto;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const StyledTextButton = styled(TextButton)`
  ${StyledTextButtonCss}
`;
