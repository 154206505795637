import { ring2 } from '@cycle-app/ui';
import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { DragHandle } from 'src/components/EditorDragMenu';

type ContainerProps = {
  $isSelected: boolean;
  $hasFullWidth: boolean;
  $isEmpty: boolean;
};

export const Container = styled(NodeViewWrapper) <ContainerProps>`
  ${p => p.$isSelected && css`
    ${ring2}
  `}
  border-radius: ${p => (p.$isEmpty ? '8px' : '12px')};
  position: relative;
  width: ${p => (p.$hasFullWidth ? 'auto' : 'fit-content')};

  &:hover .image-menu {
    opacity: 1;
  }

  li & {
    padding: 0;
  }

  &:hover ${DragHandle} {
    display: flex;
  }
`;
