import {
  Container,
  TitleSkeleton,
} from './InsightCard.styles';

export const InsightCardSkeleton = () => (
  <Container>
    <TitleSkeleton />
  </Container>
);
