import { useQuery } from '@apollo/client';
import { BillingFrequency, StripePortalDocument } from '@cycle-app/graphql-codegen';

import { useProductBase } from 'src/hooks/api/useProduct';
import { extract } from 'src/types/graphql.types';

import { Events } from '../../../constants/analytics.constants';
import { trackAnalytics } from '../../../utils/analytics/analytics';

export const useStripePortal = (options?: {
  isAnnual?: boolean;
  pollInterval?: number;
}) => {
  const product = useProductBase();
  const query = useQuery(StripePortalDocument, {
    skip: !product?.id,
    pollInterval: options?.pollInterval,
    variables: {
      productId: product?.id as string,
      ...options?.isAnnual && { frequency: BillingFrequency.Yearly },
    },
    onCompleted: () => trackAnalytics(Events.BillingUpgradeViewed, {
      slug: product?.slug,
      productId: product?.id,
    }),
  });

  return {
    stripePortal: extract('Product', query.data?.node)?.stripePortal,
    loading: query.loading,
  };
};
