/* stylelint-disable no-descending-specificity */
import { GroupHeader } from '@cycle-app/ui/components/GroupHeader';
import { hover } from '@cycle-app/utilities';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { StatusCategory, ViewType } from '../../../../graphql-codegen/generated';
import { caption400 } from '../../theme/typo';
import { boxShadowZ4 } from '../../utils/styles.util';
import { TextButton, ActionButton } from '../Buttons';
import { Container as ActionButtonContainer } from '../Buttons/ActionButton/ActionButton.styles';
import { ShyScrollbarCss, ShyScrollbarProps } from '../ShyScrollbar/ShyScrollbar.styles';

interface WithViewType {
  viewType: ViewType;
}

interface CollapseProps {
  collapse: boolean;
}

export type Variant = 'default' | 'swimlane';

interface ContainerProps extends WithViewType, CollapseProps {
  isLoading?: boolean;
  isDragging?: boolean;
  asPlaceholder?: boolean;
  isDraggable?: boolean;
  viewType: ViewType;
  variant?: Variant;
}

export const IndicatorBtn = styled(ActionButton)`
  padding: 0;
  color: ${p => p.theme.colors.text.disabled};
  svg {
    width: 21px;
    height: 21px;
  }
`;

export const GroupTitle = styled.div`
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  gap: 8px;
`;

const HeaderKanbanCollapse = css`
  padding-top: 8px;
  flex-direction: column;
  width: 40px;
  height: 100%;
  border-bottom: 0;

  ${GroupTitle} {
    margin-top: 8px;
    position: absolute;
    top: 28px;
    left: 11px;
    display: flex;
    justify-content: flex-end;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: 0px 0px;

    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const HeaderKanbanCss = css<CollapseProps>`
  border-radius: 6px;
  margin: ${p => (p.collapse ? '0' : '0 5px 0 11px')};
  ${(props) => props.collapse && HeaderKanbanCollapse};
`;

const HeaderListCss = css<CollapseProps>`
  position: sticky;
  z-index: 2;
  top: 0;
  border-radius: 6px;
`;

const HeaderSwimlaneCss = css<CollapseProps>`
  border-radius: 6px;
  padding: 4px 6px;

  ${p => p.collapse && css`
    ${IndicatorBtn} {
      transform: rotate(90deg);
    }
  `};
`;

export const Toolbar = styled.div<{ $withGroupBy?: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: ${p => (p.$withGroupBy ? 0 : 1)};
  :has([aria-pressed=true]) {
    opacity: 1;
  }
`;

export const Header = styled.div<CollapseProps & WithViewType & {
  $statusCategory?: StatusCategory;
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: ${p => (p.collapse && p.viewType === ViewType.Kanban ? '4px' : '4px 6px')};
  flex-shrink: 0;

  z-index: 2;
  min-height: 32px;

  ${p => p.viewType === ViewType.Kanban && `
    width: 262px;
  `};

  background: ${p => p.theme.colors.group.bg};
  ${p => p.$statusCategory && p.$statusCategory !== StatusCategory.NotStarted && css`
    background: ${p.theme.colors.components.FeedbackStatus[p.$statusCategory].bgOpaque};
    ${ActionButtonContainer} {
      --bgHover: ${darken(0.05, p.theme.colors.components.FeedbackStatus[p.$statusCategory].bgOpaque)};
      --bgActive: ${darken(0.1, p.theme.colors.components.FeedbackStatus[p.$statusCategory].bgOpaque)};
    }
  `}

  h2, input {
    /* Inconsistency design, cannot use theme/typo */
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: ${(props) => props.theme.colors.text.primary};
    background: transparent;
  }

  h2 {
    padding:  ${p => (p.collapse && p.viewType === ViewType.Kanban ? 0 : '4px 0')};
  }

  ${ActionButtonContainer} {
    width: 20px;
    height: 20px;
  }

  ${hover} {
    :hover ${Toolbar} {
      opacity: 1;
    }
  }
`;

export const Content = styled.div<WithViewType & ShyScrollbarProps & { variant?: Variant }>`
  ${p => p.variant !== 'swimlane' && css`
    padding: 8px 0 0 0;
  `};

  ${p => p.viewType === ViewType.Kanban && p.variant !== 'swimlane' && css`
    ${ShyScrollbarCss}
    padding: 36px 2px 0 11px;
    margin-top: -28px;
    margin-right: -4px;
    scrollbar-gutter: stable;
  `};
`;

const ContainerKanbanCssCollapse = css`
  width: auto;
  align-self: stretch;
`;

export const NewDocButton = styled(TextButton)`
  width: 100%;
  padding: 2px 8px;
  border-radius: 6px;
  ${caption400};
  --bgHover: ${p => p.theme.colors.board.textButton.bgHover};
  --bgActive: ${p => p.theme.colors.board.textButton.bgHover};
  --colorHover: ${p => p.theme.colors.board.textButton.colorHover};
`;

const ContainerKanbanCss = css<CollapseProps>`
  width: 280px;
  flex-shrink: 0;
  border-radius: 6px;

  ${NewDocButton} {
    width: 262px;
  }

  ${p => p.collapse && ContainerKanbanCssCollapse};
`;

const ContainerListCss = css<CollapseProps>`
  width: 100%;
  border-radius: 8px;
`;

export const NewDocContainer = styled.div<WithViewType & { variant?: Variant }>`
  padding: ${p => (p.viewType === ViewType.Kanban && p.variant !== 'swimlane' ? '0px 5px 0 11px' : '1px 0')};
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: transparent;

  ${p => p.viewType === ViewType.Kanban && p.variant !== 'swimlane' && css`
    padding: 9px 0;
  `};

  ${p => p.viewType === ViewType.Kanban && p.collapse && p.variant !== 'swimlane' && css`
    margin: 0 9px;
  `};

  ${p => p.viewType === ViewType.Kanban && p.isDragging && p.collapse && css`
    padding: 0;
    margin: 9px 0;
    max-height: 340px;
  `};

  ${p => p.isLoading && css`
    pointer-events: none;
    ${NewDocButton} {
      opacity: 0;
    }
  `};

  ${(props) => (props.viewType === ViewType.Kanban
    ? ContainerKanbanCss
    : ContainerListCss)};

  ${p => p.isDragging && css`
    background-color: ${p.theme.colors.body.bg};
    opacity: 0.95;
    ${boxShadowZ4}
    cursor: grabbing;
    ${Header} {
      cursor: grabbing;
    }
    ${Content} {
      /* @todo get max-height from initial measure of <Content /> */
      max-height: calc(100vh - 195px);
    }
  `};

  ${p => p.asPlaceholder && css`
    margin: ${p.collapse && p.viewType === ViewType.Kanban ? '9px 9px' : '9px 0'};
    background-color: ${p.theme.colors.placeholder.bg};
    /* stylelint-disable-next-line */
    ${Header}, ${Content}, ${NewDocContainer} {
      opacity: 0;
    }
  `};

  ${p => p.variant === 'swimlane' && css`
    min-width: 52px;
    margin-bottom: auto;
  `};

  ${Header} {
    ${p => p.isDraggable && 'cursor: grab'};
    ${p => p.variant === 'default' && p.viewType === ViewType.Kanban && HeaderKanbanCss};
    ${p => p.variant === 'default' && p.viewType === ViewType.List && HeaderListCss};
    ${p => p.variant === 'swimlane' && HeaderSwimlaneCss};
  }
`;

export const SortableElement = styled.div<CollapseProps>`
  display: flex;
  height: ${p => (p.collapse ? '100%' : 'auto')};
`;

export const Count = styled.span`
  margin-left: 4px;
  font-size: 14px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const GroupStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3px;
`;

export const GroupHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledGroupHeader = styled(GroupHeader)<{
  $statusCategory?: StatusCategory;
}>`
  --headerBg: ${p => p.theme.colors.group.feedbackList.bg};
  --headerBgHover: ${p => p.theme.colors.group.feedbackList.bgHover};
  --headerBgActive: ${p => p.theme.colors.group.feedbackList.bgActive};
  ${p => p.$statusCategory && p.$statusCategory !== StatusCategory.NotStarted && css`
    --headerBg: ${p.theme.colors.components.FeedbackStatus[p.$statusCategory].bgAlt};
    --headerBgHover: ${darken(0.03, p.theme.colors.components.FeedbackStatus[p.$statusCategory].bgAlt)};
    --headerBgActive: ${darken(0.06, p.theme.colors.components.FeedbackStatus[p.$statusCategory].bgAlt)};
  `}
`;
