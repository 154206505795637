import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const StyledPortalModal = styled(PortalModal)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  max-height: calc(100vh - 40px);
  width: 450px;
  max-width: calc(100vw - 40px);
  `;

export const HeaderModal = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Title = styled.h3`
  ${typo.headerLight}
`;
