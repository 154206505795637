import { useQuery } from '@apollo/client';
import { InitialNodeDocument } from '@cycle-app/graphql-codegen';

import { useMaybeMe } from './useMe';

export const useProductFromInitial = () => {
  const me = useMaybeMe();
  const {
    data,
    loading,
    error,
  } = useQuery(InitialNodeDocument, {
    fetchPolicy: 'cache-first',
    skip: !me?.id,
    variables: {
      id: me?.id as string,
    },
  });

  return {
    product: data?.me?.__typename === 'Me'
      ? data.me.product
      : undefined,
    loading,
    error,
  };
};
