import { QueryFunctionOptions, useQuery } from '@apollo/client';
import { ProductCompaniesCountDocument, ProductCompaniesCountQuery, ProductCompaniesCountQueryVariables } from '@cycle-app/graphql-codegen/generated';

import { defaultCompaniesPagination } from 'src/utils/pagination.util';

import { useProductBase } from '../../useProduct';

type Options = QueryFunctionOptions<ProductCompaniesCountQuery, ProductCompaniesCountQueryVariables>;

export const useCompaniesCount = (options?: Options) => {
  const product = useProductBase();
  const productId = product?.id;
  const {
    data, loading, refetch,
  } = useQuery(ProductCompaniesCountDocument, {
    fetchPolicy: options?.fetchPolicy ?? 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !productId || options?.skip,
    variables: {
      id: productId as string,
      ...defaultCompaniesPagination,
    },
  });
  return {
    count: data?.node?.__typename === 'Product' ? data?.node.companies.count : null,
    isLoading: loading,
    refetch,
  };
};
