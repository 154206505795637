import {
  DiscardRecommendationsDocument,
  DiscardRecommendationsMutationVariables,
  SearchSuggestionDocTargetsDocument,
} from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';
import { useSuggestionStatusIds } from 'src/reactives/productStatus.reactive';
import { updateQuery } from 'src/utils/update-cache/update-query.util';

export const useDiscardRecommendations = (docId: string) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const statusIds = useSuggestionStatusIds();
  const insightDoctypeId = useInsightDocType()?.id;

  const [mutate] = useSafeMutation(DiscardRecommendationsDocument);

  const discardRecommendations = async (variables: DiscardRecommendationsMutationVariables) => mutate({
    variables,
    optimisticResponse: {
      discardRecommendations: {
        __typename: 'Doc',
        id: variables.docId,
      },
    },
    update: (_, { data }) => {
      if (!data?.discardRecommendations) return;

      const recommendationIds = variables.docRecommendationIds;
      if (!Array.isArray(recommendationIds)) return;

      // Remove the discarded recommendations from the list of recommendations
      updateQuery({
        query: SearchSuggestionDocTargetsDocument,
        variables: {
          productId,
          docId,
          statusIds,
          doctypeIds: [insightDoctypeId as string],
          hasParent: false,
        },
        update: draft => {
          if (!draft?.suggestDoc) return;
          // eslint-disable-next-line no-param-reassign
          draft.suggestDoc.edges = draft.suggestDoc.edges.filter(
            edge => !recommendationIds.some(id => id === edge.node.id),
          );
        },
      });
    },
  });

  return { discardRecommendations };
};
