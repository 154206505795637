import { useApolloClient, gql, Reference } from '@apollo/client';
import {
  DocInGroupFragment,
  GroupFilteredFragment,
  GroupNodeDocument,
  GroupNoFilteredFragment,
  GroupNoFilteredNodeDocument,
  Order,
  PageInfo,
} from '@cycle-app/graphql-codegen';
import { produce } from 'immer';
import { useCallback } from 'react';
import { isPresent } from 'ts-is-present';

import { getSelection, setSelection } from 'src/reactives/selection.reactive';
import { defaultPagination, defaultGroupPagination } from 'src/utils/pagination.util';

import { useBoardGroups } from '../useBoardGroups';
import { useGetBoardConfigFromCache } from './cacheBoardConfig';

export const useGetGroup = () => {
  const { cache } = useApolloClient();
  return useCallback((id: string) => {
    const result = cache.readQuery<{ node: GroupFilteredFragment }>({
      query: GroupNodeDocument,
      variables: {
        groupId: id,
        ...defaultGroupPagination,
      },
    });
    // node can be null
    if (result?.node?.__typename !== 'DocGroupWithPropertyValue') return undefined;
    return result;
  }, [cache]);
};

export const useGetDocGroup = () => {
  const { groups } = useBoardGroups();

  const getGroup = useGetGroup();
  const getGroupNoGroupby = useGetGroupNoFiltered();

  return useCallback((docId: string) => {
    if (!groups) return null;

    const originalGroupId = Object.keys(groups).find(groupId => {
      const group = groups[groupId];
      const docsId = Object.keys(group?.docs ?? {});
      return docsId.includes(docId);
    });

    if (!originalGroupId) return null;

    return groups[originalGroupId]?.typeName === 'DocGroupWithPropertyValue'
      ? getGroup(originalGroupId)
      : getGroupNoGroupby(originalGroupId);
  }, [getGroup, getGroupNoGroupby, groups]);
};

export const useGetGroupNoFiltered = () => {
  const { cache } = useApolloClient();
  return useCallback((id: string) => {
    const result = cache.readQuery<{ node: GroupNoFilteredFragment }>({
      query: GroupNoFilteredNodeDocument,
      variables: {
        groupId: id,
        ...defaultPagination,
      },
    });
    // node can be null
    if (result?.node?.__typename !== 'DocGroupWithoutPropertyValue') return undefined;
    return result;
  }, [cache]);
};

type Doc = Pick<DocInGroupFragment, 'id' | 'publicId'>;

type UpdateDocsGroup = { cleanSelection?: boolean; groupData: { node: GroupFilteredFragment | GroupNoFilteredFragment }; boardConfigId: string }
& ({
  updatedDocs: Array<Doc>;
} | {
  addedDoc: Doc;
});

type DocCache = { pageInfo: PageInfo; edges: Array<{ node: Reference }> };

const sortDocsByPublicId = <T extends Doc>(docs: T[], asc: boolean) => {
  return [...docs].sort((a, b) => {
    // publicId can be null for drafts, we can fallback to 0.
    // used publicId as more deterministic than date: they are uniq and greater is the most recent.
    const p1 = a.publicId ?? 0;
    const p2 = b.publicId ?? 0;
    return asc ? p1 - p2 : p2 - p1;
  });
};

export const useUpdateDocsGroup = () => {
  const { cache } = useApolloClient();
  const getBoardConfig = useGetBoardConfigFromCache();

  return useCallback((params: UpdateDocsGroup) => {
    const {
      groupData, boardConfigId, cleanSelection,
    } = params;
    const boardConfig = getBoardConfig(boardConfigId);
    const isSortByDate = boardConfig?.sortByProperty?.__typename === 'SortByPropertyCreatedAt';
    const isSortByOldest =
      boardConfig?.sortByProperty?.__typename === 'SortByPropertyCreatedAt' &&
      boardConfig?.sortByProperty.order === Order.Asc;

    const getEdges = (docs: Doc[]) => {
      return docs.map(doc => {
        const node = cache.writeFragment({
          data: doc,
          fragment: gql`fragment Doc on Doc { id }`,
        });
        return node ? { node } : null;
      }).filter(isPresent);
    };

    // lastPublicId: publicId of the last doc in the group.
    const getSortedDocs = (list: Doc[], endCursor: string | null | undefined, lastPublicId: Doc['publicId']) => {
      const sortedDocs = sortDocsByPublicId(list, isSortByOldest).filter(doc => {
        if (
          // No doc in the group
          !lastPublicId ||
          // No pagination
          !groupData.node.docs.pageInfo.hasNextPage
        ) {
          return true;
        }
        const publicId = doc.publicId ?? 0;
        // Keep only docs in the range of the current pagination.
        return isSortByOldest ? publicId <= lastPublicId : publicId >= lastPublicId;
      });
      let cursor: string | null = null;
      const lastDocId = sortedDocs.at(-1)?.id;
      if (endCursor && lastDocId) {
        const [, groupUUID] = window.atob(endCursor).split('_');
        const [, lastDocUUID] = window.atob(lastDocId).split('_');
        cursor = window.btoa(`${lastDocUUID}_${groupUUID}`);
      }
      return {
        sortedDocs,
        cursor,
        edges: getEdges(sortedDocs),
      };
    };

    // Update happens on origin and target group (in instance on a dnd cross group).
    // We need to clean the selection if a doc that we updated is out of the pagination.
    // clearSelection only once on the target group (see in useBoardConfigDocsSubscription).
    const clearSelection = (docs: Doc[]) => {
      const { selected } = getSelection();
      if (selected.length) {
        setSelection({
          selected: selected.filter(selectedId => docs.some(doc => doc.id === selectedId)),
        });
      }
    };

    // lastPublicId: publicId of the last doc in the group.
    const modifyCachedDocs = (cachedDocs: DocCache, paramDocs: Doc[], endCursor: string | null | undefined, lastPublicId: Doc['publicId']) => {
      return produce(cachedDocs, draft => {
        if (isSortByDate) {
          const {
            edges, cursor, sortedDocs,
          } = getSortedDocs(paramDocs, endCursor, lastPublicId);
          if (cursor) {
            // eslint-disable-next-line no-param-reassign
            draft.pageInfo.endCursor = cursor;
          }
          // eslint-disable-next-line no-param-reassign
          draft.edges = edges;
          if (cleanSelection) {
            clearSelection(sortedDocs);
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          draft.edges = getEdges(paramDocs);
        }
      });
    };

    cache.modify({
      id: groupData.node.id,
      fields: {
        docs: (docs: DocCache, { readField }) => {
          const lastPublicId = readField<Doc['publicId']>('publicId', docs.edges.at(-1)?.node);
          if ('updatedDocs' in params) {
            return modifyCachedDocs(docs, params.updatedDocs, docs.pageInfo.endCursor, lastPublicId);
          }
          const list = [
            // By default addDoc is always updated on top.
            params.addedDoc,
            ...docs.edges.map(({ node }) => {
              const id = readField<Doc['id']>('id', node);
              const publicId = readField<Doc['publicId']>('publicId', node);
              return id && publicId ? {
                id,
                publicId,
              } : null;
            }).filter(isPresent),
          ];
          return modifyCachedDocs(docs, list, docs.pageInfo.endCursor, lastPublicId);
        },
      },
    });
  }, [cache, getBoardConfig]);
};
