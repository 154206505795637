import { useApolloClient } from '@apollo/client';
import {
  BoardConfigDocument,
  BoardWithDraftConfigDocument,
  BoardWithConfigDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { extract } from 'src/types/graphql.types';
import { defaultPagination } from 'src/utils/pagination.util';

export const useGetBoardWithConfigFromCache = (boardId: string) => {
  const { cache } = useApolloClient();
  return useCallback(() => {
    const result = cache.readQuery({
      query: BoardWithConfigDocument,
      variables: {
        id: boardId,
        ...defaultPagination,
      },
    });
    return extract('Board', result?.node);
  }, [cache, boardId]);
};

export const useGetBoardConfigFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((id: string) => {
    const result = cache.readQuery({
      query: BoardConfigDocument,
      variables: {
        id,
        ...defaultPagination,
      },
    });
    return extract('BoardConfig', result?.node);
  }, [cache]);
};

export const useGetDraftBoardConfigFromCache = (boardId: string) => {
  const { cache } = useApolloClient();
  return useCallback(() => {
    const result = cache.readQuery({
      query: BoardWithDraftConfigDocument,
      variables: {
        boardId,
      },
    });
    return extract('Board', result?.node)?.draftBoardConfig;
  }, [cache, boardId]);
};
