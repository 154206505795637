import { transitionCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;

  > div {
    max-width: 100%;
  }
`;

export const Caret = styled.div`
  display: flex;
  color: ${p => p.theme.colors.sidebar.header.item.caret.color};
  ${transitionCss()};
  svg {
    width: 12px;
  }
`;

export const Button = styled.button<{ $collapsed: boolean }>`
  background-color: ${p => p.theme.colors.sidebar.header.item.bg};
  min-width: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 6px;
  padding: ${p => (p.$collapsed ? '6px' : '5px 8px')};
  cursor: pointer;
  max-width: 100%;
  ${transitionCss()};

  &.force-focus, &:hover {
    background-color: ${p => p.theme.colors.sidebar.header.item.bgHover};
    ${Caret} {
      color: ${p => p.theme.colors.sidebar.header.item.caret.colorHover};
    }
  }
`;
