import { PenIcon, DuplicateIcon, TrashIcon, ForwardIcon } from '@cycle-app/ui/icons';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';

export const useBoardContextActions = (): CommandActionCategory => {
  const {
    canCreateView, canDeleteView, canUpdateView,
  } = useGetPermission();
  return ({
    id: 'board-context-actions',
    label: 'View actions',
    actions: [
      ...canUpdateView ? [{
        id: 'update-board',
        label: 'Update info',
        shortcut: shortcuts[ShortcutBoard.Update],
        icon: <PenIcon />,
      }] : [],
      ...canCreateView ? [{
        id: 'duplicate-board',
        label: 'Duplicate',
        shortcut: shortcuts[ShortcutBoard.Duplicate],
        icon: <DuplicateIcon />,
      }] : [],
      ...canDeleteView ? [{
        id: 'delete-board',
        label: 'Delete',
        shortcut: shortcuts[ShortcutBoard.Delete],
        icon: <TrashIcon />,
      }] : [],
      ...canUpdateView ? [{
        id: 'publish-board',
        label: 'Publish changes',
        shortcut: shortcuts[ShortcutBoard.Publish],
        icon: <ForwardIcon />,
      }] : [],
    ],
  });
};
