import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const UploadIcon = (props: SvgProps) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M13.3333 8.50033V11.3337C13.3333 12.4382 12.4379 13.3337 11.3333 13.3337H4.66666C3.56209 13.3337 2.66666 12.4382 2.66666 11.3337V8.50033M7.99998 2.66699V10.167M7.99998 2.66699L11 5.66699M7.99998 2.66699L4.99999 5.66699"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
