import { FC, ComponentProps } from 'react';

import { Menu } from './ImageMenu.styles';

export type ImageMenuProps = ComponentProps<typeof Menu> & {
  className?: string;
  forceVisible?: boolean;
};

export const ImageMenu: FC<React.PropsWithChildren<ImageMenuProps>> = ({
  forceVisible = false,
  className,
  ...props
}) => (
  <Menu
    forceVisible={forceVisible}
    className={`${className || ''} image-menu`}
    {...props}
  />
);
