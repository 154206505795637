import { Skeleton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 125px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const AvatarSkeleton = styled(Skeleton)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const LineSkeleton = styled(Skeleton)`
  height: 16px;
`;
