import { FetchResult } from '@apollo/client';
import {
  RemoveIntegrationDocument,
  RemoveIntegrationMutation,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export type RemoveIntegrationOptions = { isCalendar?: boolean };

export const useRemoveIntegration = () => {
  const [removeIntegration, { loading }] = useSafeMutation(RemoveIntegrationDocument);

  const remove = (
    integrationId: string,
  ): Promise<FetchResult<RemoveIntegrationMutation | null>> => {
    return removeIntegration({
      variables: {
        integrationId,
      },
      optimisticResponse: {
        removeIntegration: {
          id: integrationId,
          provider: null,
        },
      },
    });
  };

  return {
    remove,
    isLoading: loading,
  };
};
