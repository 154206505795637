import { useEffect } from 'react';

import { variantsContent } from 'src/components/DialogModal/DialogModal.motion';
import { OfflineState } from 'src/components/OfflineState';
import PortalModal from 'src/components/PortalModal/PortalModal';
import { useIsOffline } from 'src/hooks';
import { closeCommandBar, useIsCommandBarOpen } from 'src/hooks/modals/useCommandBarModal';
import { setLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

import { Container } from './CommandBarModal.styles';
import CommandK from './CommandK/CommandK';

export const CommandBarModal = () => {
  const isCommandbarOpen = useIsCommandBarOpen();
  const isOffline = useIsOffline();

  useEffect(() => {
    setLayer(Layer.Commandbar, isCommandbarOpen);
  }, [isCommandbarOpen]);

  if (!isCommandbarOpen) return null;

  return (
    <PortalModal
      layer={Layer.Commandbar}
      hide={closeCommandBar}
      naked
      invisibleMask
      animate
      motionVariants={variantsContent}
      isFullOnMobile
    >
      <Container>
        {isOffline ? <OfflineState hideRetry /> : <CommandK />}
      </Container>
    </PortalModal>
  );
};
