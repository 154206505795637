import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CompassIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 -1 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M9.99187 5.54767L6.91347 6.38723C6.65726 6.45711 6.45711 6.65726 6.38724 6.91347L5.54767 9.99187C5.47132 10.2718 5.72819 10.5287 6.00813 10.4523L9.08653 9.61276C9.34274 9.54289 9.54289 9.34274 9.61276 9.08653L10.4523 6.00813C10.5287 5.72819 10.2718 5.47132 9.99187 5.54767Z" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
  </Svg>
);
