import { AiIcon } from '@cycle-app/ui/icons';
import { plural } from '@cycle-app/utilities';

import { MAX_MONTHLY_AI_QUERIES } from 'src/constants/ai.constants';
import { PageId } from 'src/constants/routing.constant';
import { useCurrentBilling, useProduct, useProductAddOn } from 'src/hooks';
import { useUrl } from 'src/hooks/useUrl';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { setSettingsFromUrl } from 'src/reactives/lastView.reactive';

import classes from './HomeViewSettingsAi.module.css';
import { Container, Label, Title } from './HomeViewSettingsAi.styles';

export const HomeViewSettingsAi = () => {
  const getUrl = useUrl();
  const { canReadSettings } = useGetPermission();
  const { product } = useProduct();
  const billing = useCurrentBilling();
  const unlimitedAi = useProductAddOn('UNLIMITED_AI_QUERIES');
  const numberQueries = product?.nbAiQueries || 0;
  return (
    <Container
      className={classes.container}
      onClick={(e) => {
        if (!canReadSettings) {
          e.preventDefault();
          setLimitationsModal({ action: 'SETTINGS_READ' });
          return;
        }
        setSettingsFromUrl();
      }}
      to={getUrl(PageId.Settings)}
    >
      <Title>
        <AiIcon hasGradient />
        Customize your AI
      </Title>
      {billing && unlimitedAi.isLoaded && !unlimitedAi.isEnabled && (
        <Label className={classes.label}>
          <span>
            {`${numberQueries}/${MAX_MONTHLY_AI_QUERIES}`}
          </span>
          <span>
            {`${plural(`${billing.remainingDays} day`, billing.remainingDays)} left`}
          </span>
        </Label>
      )}
    </Container>
  );
};
