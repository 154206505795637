import { FilterPropertyRuleDoctypeFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const useAllDoctypesFromBoardConfig = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  const {
    doctypes,
    notCompatibleValues,
  } = useMemo(
    () => {
      const doctypeFilters = nodeToArray(boardConfig?.filterProperties)
        .find(f => f.__typename === 'FilterPropertyRuleDoctype') as FilterPropertyRuleDoctypeFragment | undefined;
      const allDoctypes = nodeToArray(doctypeFilters?.doctypeRule.values);
      const notSelectedDoctypes = allDoctypes.filter(f => !f.selected);

      return {
        doctypes: allDoctypes.map(({ value }) => value),
        notCompatibleValues: notSelectedDoctypes.length === allDoctypes.length ? [] : notSelectedDoctypes.map(({ value }) => value.id),
      };
    },
    [boardConfig?.filterProperties],
  );

  return {
    doctypes,
    notCompatibleValues,
  };
};
