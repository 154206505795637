import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.body400}
  font-weight: inherit;
`;

export const Logo = styled.img`
  height: 20px;
`;
