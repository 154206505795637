import { FC, memo, useCallback } from 'react';

import { useDocContext } from 'src/contexts/docContext';
import { DOC_ITEM_TARGET_CLASSNAME } from 'src/hooks/doc/useDocsNavigation';
import { isCommandBarOpen } from 'src/hooks/modals/useCommandBarModal';
import { DOCS_BULK_SELECTION_TARGET_CLASSNAME } from 'src/hooks/useDocsSelection';
import { getClosingArea } from 'src/reactives/closingArea.reactive';
import { getDocItem, setDocItem, setDocItemHoverId } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useIsSelectionLocked } from 'src/reactives/selection.reactive';
import { enablePointerEvents } from 'src/utils/mouse.util';

import { Container } from './DocItem.styles';

type Props = {
  isDragging?: boolean;
  className?: string;
  groupId?: string;
  isSelectable?: boolean;
};

export const DocItemInView: FC<React.PropsWithChildren<Props>> = memo(({
  children,
  className,
  groupId,
  isDragging,
  isSelectable,
}) => {
  const docId = useDocContext(ctx => ctx.id);
  const isLocked = useIsSelectionLocked();

  const onMouseEnter = useCallback(() => {
    const { disableHover } = getDocItem();
    const { displayed: isClosingAreaDisplayed } = getClosingArea();
    if (!disableHover && !isClosingAreaDisplayed && isSelectable) {
      setDocItemHoverId(docId);
      if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: docId });
    }
  }, [docId, isSelectable]);

  const onMouseLeave = useCallback(() => {
    const { disableHover } = getDocItem();
    if (!isCommandBarOpen() && !disableHover) {
      setDocItemHoverId(null);
    }
  }, []);

  const onMouseMove = useCallback(() => {
    const {
      disableHover, unfocusCurrentDoc,
    } = getDocItem();

    if (disableHover) {
      unfocusCurrentDoc();
      enablePointerEvents(DOC_ITEM_TARGET_CLASSNAME);
      setDocItem({
        disableHover: false,
      });
    }
  }, []);

  return (
    <Container
      data-id={docId}
      data-testid={docId}
      data-groupid={groupId}
      className={!docId?.includes('-void') && isSelectable ? `${className} ${DOCS_BULK_SELECTION_TARGET_CLASSNAME}` : className}
      grabbing={isDragging}
      locked={isLocked}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {children}
    </Container>
  );
});
