type Options = {
  url: string;
  baseName: string;
  onStart?: (total: number) => void;
  onProgress?: (loaded: number) => void;
  onError?: (error: unknown) => void;
};

/**
 * Download a file with a progress callback
 * @returns A function to cancel the download
 */
export const download = (options: Options) => {
  let type: string | undefined;

  let canceled = false;
  const abortController = new AbortController();
  const cancel = () => {
    canceled = true;
    abortController.abort();
  };

  fetch(options.url, { signal: abortController.signal })
    .then((response) => {
      if (!response.body) throw new Error('No body');
      const contentLength = response.headers.get('content-length');
      const total = contentLength ? parseInt(contentLength, 10) : null;
      if (total) options.onStart?.(total);
      const reader = response.body.getReader();
      type = response.headers.get('content-type') ?? undefined;
      const stream = new ReadableStream({
        start: async (controller) => {
          let loaded = 0;
          for (;;) {
            if (canceled) break;
            // eslint-disable-next-line no-await-in-loop
            const chunk = await reader.read();
            if (chunk.done) break;
            loaded += chunk.value.byteLength;
            if (total) {
              options.onProgress?.(loaded);
            }
            controller.enqueue(chunk.value);
          }
          controller.close();
        },
      });
      const res = new Response(stream);
      return res.blob();
    })
    .then((blobPart) => {
      const blob = new Blob([blobPart], { type });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = options.baseName;
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    })
    .catch((error) => {
      if (canceled) return;
      cancel();
      options.onError?.(error);
    });

  return cancel;
};
