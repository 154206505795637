import { Tooltip } from '@cycle-app/ui';
import { RecordOutlineIcon, CheckIcon } from '@cycle-app/ui/icons';
import { blobUrlToFile } from '@cycle-app/utilities';
import { useRef, useState } from 'react';

import { AudioRecorderButton } from 'src/components/AudioRecorder';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useMaybeMeV2, useOptimizedBooleanState } from 'src/hooks';
import { getAudioFileName } from 'src/utils/dropzone.utils';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import { SecondaryButton, StyledAudioRecorder } from './Dropzone.styles';

export const RecordButton = ({ onRecorded }: {
  onRecorded: (file: File) => void;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isOpen, {
    setTrueCallback: openDropdown,
    setFalseCallback,
  }] = useOptimizedBooleanState(false);

  const closeDropdown = () => {
    buttonRef.current?.focus();
    setFalseCallback();
  };

  const [file, setFile] = useState<File | null>(null);
  const { me } = useMaybeMeV2();

  return (
    <Tooltip
      withPortal
      withWrapper={false}
      placement="top"
      variant="arrow"
      visible={isOpen}
      hide={closeDropdown}
      interactive
      controlled
      content={(
        <div style={{ width: 400 }}>
          <StyledAudioRecorder
            showDefaultButton={!file}
            onAudioReady={async (audioUrl: string) => {
              try {
                const recordedFile = await blobUrlToFile({
                  blobUrl: audioUrl,
                  fileName: getAudioFileName(me),
                  mime: 'audio/mpeg',
                });
                setFile(recordedFile);
              } catch {
                addErrorToaster({ message: ErrorMessage.AUDIO_CONVERSION_FAILED });
              }
            }}
            onAudioRemoved={() => {
              setFile(null);
            }}
            buttons={() => file && (
              <AudioRecorderButton
                onClick={() => {
                  onRecorded(file);
                  closeDropdown();
                }}
              >
                <CheckIcon />
              </AudioRecorderButton>
            )}
          />
        </div>
      )}
    >
      <SecondaryButton
        ref={buttonRef}
        iconStart={<RecordOutlineIcon size={16} />}
        forceFocus={isOpen}
        onClick={e => {
          e.stopPropagation();
          setFile(null);
          openDropdown();
        }}
      >
        Record
      </SecondaryButton>
    </Tooltip>
  );
};
