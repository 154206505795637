import { Feature as FeatureApi } from '@cycle-app/graphql-codegen';

import { Feature } from 'src/types/features.types';
import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

type State = {
  queue: (Feature)[];
  enablingFeature: FeatureApi | null;
};

const defaultState = {
  queue: [
    Feature.UniversalFeedbackCapture,
  ],
  enablingFeature: null,
};

const {
  hookState: useFeatures,
  hookValue: useGetFeatures,
  setValue,
  getValue,
} = make<State>({
  defaultState,
  localKey: LocalKey.Features,
});

export {
  useFeatures, useGetFeatures,
};

export const setFeaturesShowed = (feature: Feature) => {
  setValue({
    // remove features from the queue
    queue: getValue().queue.filter(q => q !== feature),
  });
};

export const setFeatureToEnable = (enablingFeature: State['enablingFeature']) => setValue({ enablingFeature });
