import { SelectPanel } from '@cycle-app/ui';
import { PinIcon } from '@cycle-app/ui/icons';
import { useEffect } from 'react';


import { SelectedValues, Caret } from 'src/components/BoardConfigForm/BoardConfigForm.styles';
import { Label } from 'src/components/BoardEditCommonModal/BoardEditCommonModal.styles';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { SubmenuTitle } from 'src/hooks/boards/useBoardMenuOptions.styles';
import { Layer } from 'src/types/layers.types';
import { getSectionName } from 'src/utils/viewSection.utils';

type Props = {
  boardId?: string;
  value: string | null;
  onChange: (value: string | null) => void;
};
    
export const BoardLinkSectionField = ({
  boardId, value, onChange,
}: Props) => {
  const { mappingBoardLinks } = useBoardSections();
  const {
    customSections, sections, defaultSection, mappingSections,
  } = useBoardSections();
  const isDefaultSectionEmpty = defaultSection?.boardLinks.edges.length === 0;
  const sectionOptions = (isDefaultSectionEmpty ? customSections : sections)
    .map(section => ({
      value: section.id,
      label: getSectionName(section, sections.length),
    }));
  const boardLinksection = sectionOptions.find(option => option.value === value);
  
  // Set initial value on mount
  useEffect(() => {
    if (value !== null) return;
    const originalBoardLinkSection = Object.values(mappingBoardLinks).find(link => link.board.id === boardId)?.section;
    const defaultBoardLinkSection = Object.values(mappingSections)[0];
    const initialValue = originalBoardLinkSection?.id ?? defaultBoardLinkSection?.id;
    if (!initialValue) return;
    onChange(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div>
      <Label>
        <PinIcon size={14} />
        Add to sidebar
      </Label>
      <ToggleDropdown
        withPortal
        withWrapper={false}
        layer={Layer.DropdownModal}
        placement="bottom-start"
        content={contentProps => (
          <SelectPanel
            hideSearch
            header={<SubmenuTitle>Sections</SubmenuTitle>}
            options={sectionOptions}
            onOptionChange={(option) => {
              onChange(option.value);
              contentProps.hide();
            }}
            onClearValue={() => {
              onChange(null);
              contentProps.hide();
            }}
          />
        )}
        button={buttonProps => (
          <SelectedValues
            forceFocus={buttonProps['data-active']}
            onClick={buttonProps.onClick}
          >
            {boardLinksection?.label ?? 'None'}
            <Caret direction={buttonProps['data-active'] ? 'top' : 'bottom'} />
          </SelectedValues>
        )}
      />
    </div>
  );
};