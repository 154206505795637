import { addToaster } from 'src/utils/toasters.utils';

import { useIsOffline } from './useNetwork';

interface UseOfflineGuardParams {
  message?: string;
}

export const useOfflineGuard = (params?: UseOfflineGuardParams) => {
  const isOffline = useIsOffline();

  return (callback: VoidFunction) => {
    if (isOffline) {
      addToaster({ message: params?.message || '🔌 You need access to network to proceed' });
      return;
    }
    callback();
  };
};
