import { AiState } from '@cycle-app/graphql-codegen';
import { TagProps } from '@cycle-app/ui';
import { AiIcon, CheckAltIcon } from '@cycle-app/ui/icons';

import { setAiStateModal } from 'src/reactives/aiStateModal.reactive';

import { StyledTag } from './AiStateTag.styles';

export type AiStateTagProps = TagProps & {
  docId?: string;
  docTypeId?: string;
  aiState: AiState | null | undefined;
  full?: boolean;
  isDisabled?: boolean;
};

export const AiStateTag = ({
  docId,
  docTypeId,
  aiState,
  full = false,
  isDisabled = false,
  ...props
}: AiStateTagProps) => {
  if (!aiState) return null;
  const isVerifiable = aiState === AiState.AiCreated && docId && docTypeId && !isDisabled;
  return (
    <StyledTag
      color="grey"
      icon={(
        <>
          <AiIcon size={13} />
          {aiState === AiState.UserValidated && <CheckAltIcon size={8} />}
        </>
        )}
      tooltip={{
        placement: 'top',
        title: getAiStateTooltip(aiState),
        content: isVerifiable ? 'Click to verify' : null,
        withPortal: true,
        withWrapper: false,
      }}
      onClick={isVerifiable && docId && docTypeId ? e => {
        e.preventDefault();
        e.stopPropagation();
        setAiStateModal({
          docId,
          docTypeId,
        });
      } : undefined}
      {...props}
    >
      {full && getAiStateLabel(aiState)}
    </StyledTag>
  );
};

const getAiStateLabel = (aiState: AiState) => {
  if (aiState === AiState.UserValidated) return 'User-verified';
  return 'AI-generated';
};

const getAiStateTooltip = (aiState: AiState) => {
  if (aiState === AiState.UserValidated) return 'AI-generated, user-verified';
  return 'AI-generated, not verified';
};
