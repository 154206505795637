import { RightIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

interface SubmenuIconProps {
  $isActive?: boolean;
}

export const SubmenuIcon = styled(RightIcon) <SubmenuIconProps>`
  color: ${p => (p.$isActive ? p.theme.colors.body.color : p.theme.colors.text.disabled)};
  vertical-align: middle;
  width: 14px;
  height: 14px;
`;
