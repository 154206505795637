import { Color } from '@cycle-app/graphql-codegen';
import { Input, ActionButton } from '@cycle-app/ui';
import { CloseIcon, AddIcon } from '@cycle-app/ui/icons';
import isEqual from 'lodash/isEqual';
import { forwardRef, InputHTMLAttributes, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { changelogTemplates } from './ChangelogBuilderPreset';
import { Section } from './ChangelogBuilderSidebar.styles';
import { ReadItem, ReadItemActions, ReadItemButton, List, AddButton } from './ChangelogBuilderTags.styles';
import { LabelWithReset } from './LabelWithReset';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';

export const ChangelogBuilderTags = () => {
  const {
    formState, control, resetField, watch,
  } = useChangelogBuilderFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'tags',
    control,
    // Avoid conflicting with data id.
    keyName: '_id',
  });
  const [resetKey, setResetKey] = useState(0);
  const tags = watch('tags');
  const theme = useTheme();
  const template = watch('template');
  const colors = template
    ? changelogTemplates.find(t => t.value === template)?.theme.tagsColors ?? []
    : [];
  return (
    <Section>
      <LabelWithReset
        label="Release tags"
        reset={() => {
          resetField('tags');
          // Each field won't update on reset.
          setResetKey(r => r + 1);
        }}
        showReset={!isEqual(tags, formState.defaultValues?.tags)}
      />
      {!!fields.length && (
        <List key={resetKey}>
          {fields.map((item, i) => (
            <li key={item.id || item._id}>
              <Controller
                render={({ field }) => (
                  <Item
                    id={item.id}
                    remove={() => remove(i)}
                    {...field}
                  />
                )}
                name={`tags.${i}.value`}
                control={control}
              />
            </li>
          ))}
        </List>
      )}
      <AddButton
        onClick={() => append({
          id: '',
          value: '',
          color: colors[tags.length] || tags.at(-1)?.color || theme.nuances[Color.A].bg,
        })}
        variant="nospace"
        iconStart={<AddIcon />}
        size="M"
      >
        Add tag
      </AddButton>
    </Section>
  );
};

const Item = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & { remove: VoidFunction }>(({
  onBlur, remove, value, id, ...rest
}, ref) => {
  const [isEditing, setIsEditing] = useState(!id);
  return isEditing ? (
    <Input
      ref={ref}
      autoFocus
      value={value}
      onBlur={e => {
        setIsEditing(false);
        onBlur?.(e);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === 'Escape') {
          setIsEditing(false);
        }
      }}
      {...rest}
    />
  ) : (
    <ReadItem>
      <ReadItemButton
        full
        onClick={() => setIsEditing(true)}
        size="L"
        variant="ternary"
      >
        {value}
      </ReadItemButton>
      <ReadItemActions>
        <ActionButton
          size="L"
          onClick={remove}
          tooltip="Remove"
          tooltipPlacement="top"
        >
          <CloseIcon size={12} />
        </ActionButton>
      </ReadItemActions>
    </ReadItem>
  );
});
