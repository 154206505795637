import styled from 'styled-components';

import { ActionButton } from '../Buttons/ActionButton/ActionButton';

export const StyledActionButton = styled(ActionButton)`
  svg {
    width: 16px;
    height: 16px;
    flex: none;
  }
`;
