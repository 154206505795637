import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${typo.body500};
  align-items: flex-start;
  color: ${p => p.theme.colors.text.primary};
  display: flex;
`;

export const StyledButton = styled(Button)`
  color: hsl(var(--cycle));
  white-space: nowrap;
`;
