import { MobileAsideMenu } from 'src/components/MobileAsideMenu';
import { MobileFooter } from 'src/components/MobileFooter';
import { MobileHeader } from 'src/components/MobileHeader';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { Container } from './MobileNavigation.styles';

export const MobileMobileNavigation = () => {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return <MobileMobileNavigationContainer />;
};

const MobileMobileNavigationContainer = () => (
  <Container>
    <MobileHeader />
    <MobileAsideMenu />
    <MobileFooter />
  </Container>
);
