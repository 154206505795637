import styled from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const ClosingArea = styled.div<{ layer: Layer }>`
  position: absolute;
  z-index: ${p => mappingZindex[p.layer] - 1};
  inset: 0;
`;
