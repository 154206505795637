import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LinearStatusInProgressIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 14 14" fill="none" aria-label="In Progress" data-no-fill>
    <rect x="1" y="1" width="12" height="12" rx="6" stroke="#f2c94c" strokeWidth="2" fill="none" />
    <path fill="#f2c94c" stroke="none" d="M 3.5,3.5 L3.5,0 A3.5,3.5 0 0,1 3.5, 7 z" transform="translate(3.5,3.5)" />
  </Svg>
);
