import { DoctypeTemplateCategory, TemplateFragment } from '@cycle-app/graphql-codegen';
import { useEffect, useState } from 'react';

import { useDoctype } from 'src/hooks/api/useDocType';

interface UseEditTemplateParams {
  template?: TemplateFragment;
  modeAdmin?: boolean;
  docTypeId?: string;
}

export default function useEditTemplate({
  template, modeAdmin, docTypeId,
}: UseEditTemplateParams) {
  const doctype = useDoctype(docTypeId);

  const defaultTitle = doctype && !modeAdmin ? `${doctype?.name} template` : '';

  const [title, setTitle] = useState<string>(template?.title ?? defaultTitle);
  const [content, setContent] = useState<string>(template?.content ?? '<p></p>');
  const [contentJSON, setContentJSON] = useState<string | undefined | null>(template?.contentJSON ?? '{}');
  const [category, setCategory] = useState<string | undefined | null>(template?.category ?? DoctypeTemplateCategory.Bug);

  // Updating the state value once template is loaded
  useEffect(() => {
    if (template) {
      setTitle(template.title);
      setContent(template.content);
      setContentJSON(template.contentJSON);
      setCategory(template.category);
    }
  }, [template]);

  return {
    editingTemplate: {
      title,
      content,
      contentJSON,
      category: category as DoctypeTemplateCategory,
    },
    setTitle,
    setContent,
    setContentJSON,
    setCategory,
  };
}
