import styled from 'styled-components';

import DocSearchDropdown from 'src/components/DocSearchDropdown/DocSearchDropdown';

export const Trigger = styled.div`
  cursor: pointer;
`;

export const DocSearchDropdownStyled = styled(DocSearchDropdown)`
  padding: 8px;
`;
