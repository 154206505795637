import { DoctypeType } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { isFeedback, isInsight } from 'src/utils/docType.util';

import { useGetGroup } from '../api/cache/cacheGroupHooks';
import { useAssigneeFromBoardConfig } from '../api/useAssigneeFromBoardConfig';
import { useCustomerFromBoardConfig } from '../api/useCustomerFromBoardConfig';
import { useDoctypesFromBoardConfig } from '../api/useDoctypesFromBoardConfig';
import { useProductDoctypes } from '../api/useProductDoctypes';

interface Props {
  possibleDoctypesId?: string[];
  groupId?: string;
}

export const usePossibleDoctypes = () => {
  const getGroup = useGetGroup();
  const doctypesFromProduct = useProductDoctypes();
  const doctypesFromBoardConfig = useDoctypesFromBoardConfig();
  const { isAssigneeEmptyRuleOrGroup } = useAssigneeFromBoardConfig();
  const { isCustomerEmptyGroup } = useCustomerFromBoardConfig();

  // Even if the back auto assigne a doc when we send a feedback without an assignee,
  // we still need get the assignee from the boardConfig so it won't leave the board.
  // Eg. if a board is filterd with "is empty assignee", we should filter out the feedbacks.
  const filterFeedbacks = useCallback(({
    groupId, type,
  }: { groupId?: string; type: DoctypeType }) => {
    return !isInsight({ type }) && (
      (
        !isFeedback({ type }) ||
        !isCustomerEmptyGroup(groupId) ||
        !isAssigneeEmptyRuleOrGroup(groupId)
      )
    );
  }, [isAssigneeEmptyRuleOrGroup, isCustomerEmptyGroup]);

  const getPossibleDoctypes = useCallback(({
    groupId, possibleDoctypesId,
  }: Props) => {
    if (possibleDoctypesId && possibleDoctypesId.length) {
      return doctypesFromProduct
        .filter(doctype => possibleDoctypesId.includes(doctype.id))
        .filter(doctype => filterFeedbacks({
          type: doctype.type,
          groupId,
        })) ?? [];
    }

    const group = getGroup(groupId ?? '');

    if (group?.node.propertyValue?.__typename === 'Doctype') {
      const groupDoctype = doctypesFromProduct.find(doctype => doctype.type !== DoctypeType.Insight && doctype.id === group?.node.propertyValue?.id);
      if (groupDoctype) {
        return [groupDoctype].filter(doctype => filterFeedbacks({
          type: doctype.type,
          groupId,
        }));
      }
    }

    return [
      ...doctypesFromBoardConfig.length ? doctypesFromBoardConfig : doctypesFromProduct,
    ].filter(doctype => doctype.type !== DoctypeType.Insight)
      .filter(doctype => filterFeedbacks({
        type: doctype.type,
        groupId,
      }));
  }, [doctypesFromBoardConfig, doctypesFromProduct, filterFeedbacks, getGroup]);

  return { getPossibleDoctypes };
};
