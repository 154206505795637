import { Spinner, Button } from '@cycle-app/ui';
import { ERROR_CODE } from '@cycle-app/utilities';
import { useState, FC } from 'react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { PageId } from 'src/constants/routing.constant';
import { useConfirmEmail, useVerifyEmail } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';
import { setAuth } from 'src/reactives/auth.reactive';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { addToaster } from 'src/utils/toasters.utils';
import { isUserOnboarded } from 'src/utils/users.util';

import {
  CodeInputContainer, StyledCodeInput, ConfirmFooter, VerifySpinner, NospaceButton, ConfirmError,
} from './OnboardingStepAccount.styles';
import { Description } from '../OnboardingLayout/OnboardingLayout.styles';

interface Props {
  email: string;
  onBack: VoidFunction;
}

export const ConfirmEmailForm: FC<React.PropsWithChildren<Props>> = ({
  email, onBack,
}) => {
  const { navigate } = useNavigate();
  const {
    confirmEmail, isLoading: isConfirmLoading,
  } = useConfirmEmail();
  const {
    verifyEmail, isLoading: isVerifyLoading,
  } = useVerifyEmail();
  const [error, setError] = useState('');

  const onChange = async (code: string) => {
    setError('');
    const result = await confirmEmail(email, code);
    if (result.errors) {
      setError(
        result.errors.find(err => err.message === ERROR_CODE.WRONG_CODE)
          ? 'Looks like you entered the wrong code, please try again.'
          : ErrorMessage._GENERIC,
      );
      return;
    }
    if (result.data?.confirmEmail?.me) {
      const {
        me, token,
      } = result.data.confirmEmail;
      const isOnboarded = isUserOnboarded(me);
      setAuth({
        token,
        userId: me.id,
      });
      if (isOnboarded) {
        const productSlug = me.products.edges[0]?.node.slug;
        if (productSlug) {
          navigate(PageId.Inbox, { productSlug });
        } else {
          navigate(PageId.Main);
        }
        return;
      }
      setOnboarding({ screen: LightOnboardingScreen.AccountInfos });
    }
  };

  return (
    <>
      <Description>
        We&apos;ve sent an email with a code to
        {' '}
        {email}
        , please enter it below to create your Cycle account.
      </Description>
      {error && <ConfirmError>{error}</ConfirmError>}
      <CodeInputContainer>
        <StyledCodeInput autoFocus disabled={isConfirmLoading} onChange={onChange} />
        {isConfirmLoading && <Spinner />}
      </CodeInputContainer>
      <ConfirmFooter>
        Didn&apos;t receive a code?
        {' '}
        <NospaceButton
          size="M"
          variant="nospace"
          onClick={async () => {
            if (isVerifyLoading) return;
            const verify = await verifyEmail(email);
            if (verify.data?.verifyEmail) {
              addToaster({
                message: `We've sent an email with a code to ${email}`,
              });
            }
          }}
        >
          Send new code
          {isVerifyLoading && <VerifySpinner />}
        </NospaceButton>
      </ConfirmFooter>
      <Button
        onClick={onBack}
        size="M"
        variant="nospace"
        style={{ margin: '24px auto 0' }}
      >
        Back
      </Button>
    </>
  );
};
