import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const WorkspaceIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33333 1C2.04467 1 1 2.04467 1 3.33333C1 4.622 2.04467 5.66667 3.33333 5.66667H4.5C5.78866 5.66667 6.83333 4.622 6.83333 3.33333C6.83333 2.04467 5.78866 1 4.5 1H3.33333Z" />
    <path d="M11.5 1C10.2113 1 9.16667 2.04467 9.16667 3.33333V6.83333C9.16667 8.122 10.2113 9.16667 11.5 9.16667H12.6667C13.9553 9.16667 15 8.122 15 6.83333V3.33333C15 2.04467 13.9553 1 12.6667 1H11.5Z" />
    <path d="M9.16667 12.6667C9.16667 11.378 10.2113 10.3333 11.5 10.3333H12.6667C13.9553 10.3333 15 11.378 15 12.6667C15 13.9553 13.9553 15 12.6667 15H11.5C10.2113 15 9.16667 13.9553 9.16667 12.6667Z" />
    <path d="M3.33333 6.83333C2.04467 6.83333 1 7.878 1 9.16667V12.6667C1 13.9553 2.04467 15 3.33333 15H4.5C5.78866 15 6.83333 13.9553 6.83333 12.6667V9.16667C6.83333 7.878 5.78866 6.83333 4.5 6.83333H3.33333Z" />
  </Svg>
);
