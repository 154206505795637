import { typo, Button } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const IntegrationIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${p => p.theme.colors.text.primary};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  width: 350px;
`;

export const PanelItem = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  ${typo.body400}

  :hover, :focus {
    background: ${p => p.theme.colors.selectItem.bgHover};
  }

  ${p => !p.$disabled && css`
    cursor: pointer;
    :active {
      background: ${p.theme.colors.selectItem.bgActive};
    }
  `};
`;

export const ItemLabel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ItemTitle = styled.div`
  ${typo.body500};
  line-height: 1;
`;

export const ItemDesc = styled.div`
  color: ${p => p.theme.colors.text.disabled};
  line-height: 1;
`;

export const InstallButton = styled(Button).attrs({
  size: 'S',
  variant: 'primary',
})`
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
`;
